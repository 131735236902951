<template>
  <template v-if="isQuizListShow">
    <!-- popup-inner > popup-content -->
    <div class="popup-content">
      <!-- popup-section:퀴즈목록 -->
      <article class="popup-section">
        <header v-if="paging.totalCount > 0" class="section-header">
          <h4 class="title">퀴즈목록 <span class="subtitle">{{ paging.totalCount }}</span></h4>
        </header>

        <div v-if="paging.totalCount === 0" class="section-content">
          <div class="kb-form-fields">
            <div class="kb-form-row">
              <div class="kb-form-column kb-form-column-title">
                <label class="kb-form-label">
                  <span class="kb-form-label-text">퀴즈문제지명</span>
                </label>
              </div>
              <div class="kb-form-column">
                <span class="kb-form-text">-</span>
              </div>
              <div class="kb-form-column column-last">
                <a href="javascript:" class="kb-btn-link" @click="addQuiz">
                  <span class="text">문제지 추가</span>
                </a>
              </div>
            </div>
          </div>
          <div class="kb-form-fields-bottom"><span class="text">등록된 퀴즈 문제지가 없습니다. [문제지추가]를 클릭하여 퀴즈 문제지를 추가하세요.</span></div>
        </div>

        <div v-if="paging.totalCount > 0" class="section-content">
          <div class="kb-table kb-table-striped">
            <table>
              <colgroup>
                <col style="width:88px">
                <col style="width:auto">
                <col style="width:160px">
                <col style="width:160px">
                <col style="width:160px">
                <col style="width:138px">
              </colgroup>
              <thead>
              <tr>
                <th><span class="th-title">NO</span></th>
                <th><span class="th-title">퀴즈문제지명</span></th>
                <th><span class="th-title">문항수</span></th>
                <th><span class="th-title">작성자</span></th>
                <th><span class="th-title">등록일자</span></th>
                <th><button class="th-title th-actions" @click="checkAllQuiz">전체선택</button></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, idx) in quizItems" :key="idx">
                <td><strong class="td-text">{{ idx+1 }}</strong></td>
                <td><strong class="td-text kb-mouse-cursor" @click="quizDetail(item)">{{ item.lrnEvlNm }}</strong></td>
                <td><strong class="td-text">{{ item.qstnCnt }}</strong></td>
                <td><strong class="td-text">{{ item.rgtrNm }}</strong></td>
                <td><strong class="td-text">{{ item.regDt }}</strong></td>
                <td class="td-center">
                  <div class="td-cell">
                    <div class="kb-form-check">
                      <input type="checkbox"
                             class="kb-form-check-input"
                             :id="`chk_01_${item.lrnTyEvlMstSn}`"
                             :name="`chk_${idx}`"
                             v-model="targetQuiz"
                             :value="item.lrnTyEvlMstSn"
                      />
                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </article>
    </div>
    <div v-if="paging.totalCount > 0" class="popup-buttons">
      <button class="kb-btn kb-btn-outline kb-btn-lg" @click="deleteQuiz"><span class="text">삭제</span></button>
      <button class="kb-btn kb-btn-primary kb-btn-lg" @click="addQuiz"><span class="text">추가</span></button>
    </div>
  </template>

  <QuizAddModal
      v-if="quizAddModal"
      v-model="quizAddModal"
      v-model:click-add="insertQuiz"
  />
  <QuizMngView
      v-if="isQuizViewShow"
      v-model:is-quiz-view-show="isQuizViewShow"
      v-model:is-quiz-list-show="isQuizListShow"
      :quiz-item="quizItem"
      :quiz-dtl-sn="quizDtlSn"
      :getQuizList="getQuizList"
  />
</template>

<script>
import QuizAddModal from "@/components/quiz/QuizAddModal";
import QuizMngView from "@/components/quiz/QuizMngView";
import {quizListSetup} from "@/assets/js/modules/quiz/quiz-list-setup";

export default {
  name: "QuizList",
  components: {QuizMngView, QuizAddModal},
  setup: quizListSetup
}
</script>