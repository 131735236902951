<template>
    <template v-if="isMobile">
        <article class="content-section">
            <header class="section-header">
                <h4 class="title">인증분야</h4>
            </header>
            <!-- badge-content-container -->
            <div class="badge-content-container">
                <div class="badge-content-item" v-for="(certificationConfig, index) in myCertifications" :key="index">
                    <div class="item-row row-content">
                        <template v-if="certificationConfig.key === '개인금융'">
                            <div class="item-badge badge" :class="specialIndividualYn === 'Y' ? 'badge-primary' : 'badge-silver'">
                                <span class="badge-text">{{ specialIndividualYn === 'Y' ? '인증' : '미인증' }}</span>
                            </div>
                            <h5 class="item-title">{{ certificationConfig.name }}</h5>
                            <strong class="item-text text-gold">{{ certficationFiledItem.j1FnshCnt ? certficationFiledItem.j1FnshCnt : 0 }}/5</strong>
                        </template>
                        <template v-else>
                            <div class="item-badge badge" :class="specialEnterpriseYn === 'Y' ? 'badge-primary' : 'badge-silver'">
                                <span class="badge-text">{{ specialEnterpriseYn === 'Y' ? '인증' : '미인증' }}</span>
                            </div>
                            <h5 class="item-title">{{ certificationConfig.name }}</h5>
                            <strong class="item-text text-gold">{{ certficationFiledItem.j2FnshCnt ? certficationFiledItem.j2FnshCnt : 0 }}/5</strong>
                        </template>
                    </div>
                    <div class="item-row row-buttons">
                        <button class="kb-btn kb-btn-outline" @click="showCertificationCourseMobile(certificationConfig.key)">
                            <span class="text">교과목 보기</span>
                        </button>
                    </div>
                </div>
            </div>
            <!-- //badge-content-container -->
        </article>
        <article class="content-section section-divider-m">
            <header class="section-header">
                <h4 class="title">부점장 승진 자격요건</h4>
            </header>
            <!-- badge-content-container -->
            <div class="badge-content-container">
                <div class="badge-content-item" v-for="(certificationConfig, index) in myCertifications" :key="index">
                    <div class="item-row row-content">
                        <template v-if="certificationConfig.key === '개인금융'">
                            <div class="badge" :class="individualCdNms.length > 0 ? 'badge-primary' : 'badge-silver'">
                                <span class="badge-text">{{ individualCdNms.length > 0 ? '충족' : '미충족' }}</span>
                            </div>
                        </template>
                        <template v-else>
                            <div class="badge" :class="enterpriseCdNms.length > 0 ? 'badge-primary' : 'badge-silver'">
                                <span class="badge-text">{{ enterpriseCdNms.length > 0 ? '충족' : '미충족' }}</span>
                            </div>
                        </template>
                        <h5 class="item-title">{{ certificationConfig.name }}</h5>
                        <template v-if="certificationConfig.key === '개인금융'">
                            <span class="td-text">{{ individualCdNms.length > 0 ? individualCdNms : '-' }}</span>
                        </template>
                        <template v-else>
                            <span class="td-text">{{ enterpriseCdNms.length > 0 ? enterpriseCdNms : '-' }}</span>
                        </template>
                    </div>
                </div>
            </div>
            <div v-if="certficationFiledItem.j1Flag === 'Y' && certficationFiledItem.j2Flag === 'Y'" class="section-bottom">
                <div class="texticon">
                    <i class="icon-check"></i>
                    <strong class="text">부점장 승진 자격요건을 <em class="text-blue">충족</em>하였습니다.</strong>
                </div>
            </div>
            <!-- //badge-content-container -->
        </article>
        <article class="content-section">
            <div class="section-tabs">
                <template v-for="(certificationConfig, index) in certificationConfigs" class="kb-nav-item" :key="index">
                    <a href="javascript:" class="tab" :class="{'is-active': courseKey === certificationConfig.key}"
                       @click="changeCertification(certificationConfig.key)">{{ certificationConfig.name }}</a>
                </template>
            </div>
            <header class="section-header">
              <h4 class="title"><i class="mdi mdi-clipboard-text-outline" style="margin-right:0.1em;"></i> 필수 교과목</h4>
            </header>
            <!-- text-item-container -->
            <div class="text-item-container"  id="certificationsSubjList_mob">
                <!-- list-top -->
                <div class="text-item-wrapper">
                    <!-- text-item -->
                    <div class="text-item" v-for="(item, index) in items" :key="index">
                      <p class="item-subtitle">{{ item.crseWorkDivNm }}</p>
                      <h5 class="item-title">{{ item.trgtNm }}</h5>
<!--                        <div class="item-row">-->
<!--                            <span class="text-title">부문</span>-->
<!--                            <span class="text-description">{{ item.crseWorkDivNm }}</span>-->
<!--                        </div>-->
                        <template v-if="item.distCrseNm">
                          <div class="item-row">
                            <span class="text-title">수료여부</span>
                            <template v-if="item.fnshYn === 'Y'">
                              <span class="text-description">{{ item.grd2Cd }}</span>
                            </template>
                            <template v-else>
                              <span class="text-description">{{ item.grd2Cd }}</span>
                            </template>
                          </div>
                          <div class="item-row">
                            <span class="text-title">차수명</span>
                            <span class="text-description">{{ item.distCrseNm }}</span>
                          </div>
                          <div class="item-row">
                              <span class="text-title">연수기간</span>
                              <span class="text-description">
                              <template v-if="item.eduBgngYmd || item.eduEndYmd">
                                {{ item.eduBgngYmd.split(' ')[0] }} -
                                {{ item.eduEndYmd.split(' ')[0] }}
                              </template>
                              </span>
                          </div>
                          <div class="item-row">
                            <span class="text-title">점수</span>
                            <span class="text-description">{{ item.getScore }}</span>
                          </div>
                        </template>
                        <template v-else>
                          <div class="item-row row-buttons">
                            <button class="kb-btn kb-btn-outline" @click="applyTrain(item.trgtSn)"><span class="text">수강신청</span></button>
                          </div>
                        </template>

                    </div>
                    <!-- //text-item -->
                </div>
            </div>
        </article>

      <article class="content-section">
        <header class="section-header">
          <h4 class="title"><i class="mdi mdi-clipboard-text-outline" style="margin-right:0.1em;"></i> 자격증 특례인정</h4>
          <span class="p-2 text-success"><i class="mdi mdi-information-outline"></i> 자격증 복수 보유시에도 1 개 과목 특례인정</span>
        </header>
        <!-- text-item-container -->
        <div class="text-item-container">
          <!-- list-top -->
          <div class="text-item-wrapper">
            <!-- text-item -->
            <div class="text-item" v-for="(item, index) in items_lcsns" :key="index">
              <p class="item-subtitle">{{ item.crseWorkDivNm }}</p>
              <h5 class="item-title">{{ item.trgtNm }}</h5>
              <template v-if="item.fnshYn">
                <div class="item-row">
                  <span class="text-title">인정여부</span>
                  <template v-if="item.fnshYn === 'Y'">
                    <span class="text-description">인정</span>
                  </template>
                  <template v-else>
                    <span class="text-description">미인정</span>
                  </template>
                </div>
                <div class="item-row">
                  <span class="text-title">수료차수명</span>
                  <span class="text-description">{{ item.distCrseNm }}</span>
                </div>
              </template>
            </div>
            <!-- //text-item -->
          </div>
        </div>
      </article>




    </template>
    <template v-else>
        <div class="technician-level-container">
            <!-- content-section-group:인증분야, 직무인증제 -->
            <div class="content-section-group">
                <!-- content-section:인증분야 -->
                <article class="content-section section-lft">
                    <header class="section-header">
                        <h4 class="title">인증분야</h4>
                    </header>
                    <div class="roundedbox-wrap">
                        <!-- roundedbox -->
                        <div v-for="(certificationConfig, index) in myCertifications" class="roundedbox" :key="index">
                            <div class="roundedbox-top">
                                <div class="top-row">
                                    <h5 class="roundedbox-title">{{ certificationConfig.name }}</h5>
                                    <template v-if="certificationConfig.key === '개인금융'">
                                        <div class="badge" :class="specialIndividualYn === 'Y' ? 'badge-primary' : 'badge-silver'">
                                            <span class="badge-text">{{ specialIndividualYn === 'Y' ? '인증' : '미인증' }}</span>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="badge" :class="specialEnterpriseYn === 'Y' ? 'badge-primary' : 'badge-silver'">
                                            <span class="badge-text">{{ specialEnterpriseYn === 'Y' ? '인증' : '미인증' }}</span>
                                        </div>
                                    </template>
                                </div>
                                <div class="top-row">
                                    <template v-if="certificationConfig.key === '개인금융'">
                                        <h5 class="roundedbox-text">{{ certficationFiledItem.j1FnshCnt ? certficationFiledItem.j1FnshCnt : 0 }}/5</h5>
                                    </template>
                                    <template v-else>
                                        <h5 class="roundedbox-text">{{ certficationFiledItem.j2FnshCnt ? certficationFiledItem.j2FnshCnt : 0 }}/5</h5>
                                    </template>
                                </div>
                            </div>
                            <div class="roundedbox-bottom">
                                <a href="javascript:" class="kb-btn kb-btn-wide rounded-lg"  :class="{'text-is-active': courseKey == certificationConfig.key, 'kb-btn-outline': courseKey != certificationConfig.key}"
                                   @click="changeCertification(certificationConfig.key)"><span class="text">교과목 보기</span></a>
                            </div>
                        </div>
                    </div>
                </article>
                <!-- content-section:직무인증제 -->
                <article class="content-section section-rgt">
                    <header class="section-header">
                        <h4 class="title">부점장 승진 자격요건</h4>
                    </header>
                    <div class="kb-table kb-table-round">
                        <table>
                            <tbody>
                            <tr v-for="(certificationConfig, index) in myCertifications" :key="index">
                                <th>
                                    <div class="badge-wrap">
                                        <template v-if="certificationConfig.key === '개인금융'">
                                            <div class="badge" :class="individualCdNms.length > 0 ? 'badge-primary' : 'badge-silver'">
                                                <span class="badge-text">{{ individualCdNms.length > 0 ? '충족' : '미충족' }}</span>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="badge" :class="enterpriseCdNms.length > 0 ? 'badge-primary' : 'badge-silver'">
                                                <span class="badge-text">{{ enterpriseCdNms.length > 0 ? '충족' : '미충족' }}</span>
                                            </div>
                                        </template>
                                        <strong class="th-title">{{ certificationConfig.name }}</strong>
                                    </div>
                                </th>
                                <td>
                                    <template v-if="certificationConfig.key === '개인금융'">
                                        <span class="td-text">{{ individualCdNms.length > 0 ? individualCdNms : '-' }}</span>
                                    </template>
                                    <template v-else>
                                        <span class="td-text">{{ enterpriseCdNms.length > 0 ? enterpriseCdNms : '-' }}</span>
                                    </template>
                                </td>
                            </tr>
                            <tr v-if="certficationFiledItem.j1Flag === 'Y' && certficationFiledItem.j2Flag === 'Y'" class="border-0">
                                <td colspan="2">
                                    <div class="td-cell texticon">
                                        <i class="icon-check"></i>
                                        <strong class="text">부점장 승진 자격요건을 <em class="text-blue">충족</em>하였습니다.</strong>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </article>
            </div>

            <article class="content-section section-spacing" id="certificationsSubjList">
              <header class="section-header">
                <h4 class="title">필수 교과목 <span class="text-success" style="margin-left: 1em;"><i class="mdi mdi-information-outline"></i> 교과목명 클릭 시 교과목 수강신청 화면으로 이동합니다.</span></h4>
              </header>
                <div class="tab-penel">
                    <div class="kb-table kb-table-bordered-v2 kb-table-row">
                        <table>
                            <colgroup>
                                <!--                <col style="width:96px">-->
                                <col style="width:150px">
                                <col style="width:300px">
                                <col style="width:120px">
                                <col style="width:auto">
                                <col style="width:120px">
                                <col style="width:109px">
                            </colgroup>
                            <thead>
                            <tr>
                                <!--                <th><span class="th-title">분야</span></th>-->
                                <th><span class="th-title">부문</span></th>
                                <th><span class="th-title">교과목</span></th>
                                <th><span class="th-title">수료여부</span></th>
                                <th><span class="th-title">수료과정명</span></th>
                                <th><span class="th-title">주관처</span></th>
                                <th><span class="th-title">점수</span></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-if="items.length === 0">
                                <td colspan="6">
                                    <div class="text-truncate text-center"><strong class="td-text">연수 이력이 없습니다.</strong></div>
                                </td>
                            </tr>

                            <tr v-for="(item, idx) in items" :key="idx">
                                <td><span class="text">{{ item.crseWorkDivNm }}</span></td>
                                <td><div class="text-truncate">
                                  <router-link :to="{name: 'ApplyTrain', query: {crseMstSn: item.trgtSn}}" class="td-link td-link-line">
                                    <strong class="text">{{item.trgtNm}}</strong>
                                  </router-link>
                                </div></td>
                                <template v-if="item.fnshYn === 'Y'">
                                  <td><strong class="text text-primary">{{ item.grd2Cd }}</strong></td>
                                </template>
                                <template v-else>
                                  <td>{{ item.grd2Cd }}</td>
                                </template>
                                <td>
                                    <template v-if="item.distCrseNm">
                                        <span class="text">
                                          {{item.distCrseNm}}<br/>
                                          {{ item.eduBgngYmd.split(' ')[0] }} ~ {{ item.eduEndYmd.split(' ')[0] }}</span>
                                    </template>
                                    <template v-else>
                                        <span class="text">-</span>
                                    </template>
                                </td>
                                <td><strong class="text">{{item.aplcntDeptNm}}</strong></td>
                                <td><strong class="text">{{item.getScore > 0 ? item.getScore : "-" }}</strong></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </article>

          <article class="content-section section-spacing">
            <header class="section-header">
              <h4 class="title">자격증 특례인정 <span class="text-success" style="margin-left: 1em;"><i class="mdi mdi-information-outline"></i> 자격증 복수 보유시에도 1 개 과목 특례인정</span></h4>
            </header>
            <div class="tab-penel">
              <div class="kb-table kb-table-bordered-v2 kb-table-row">
                <table>
                  <colgroup>
                    <!--                <col style="width:96px">-->
                    <col style="width:150px">
                    <col style="width:300px">
                    <col style="width:120px">
                    <col style="width:auto">
                  </colgroup>
                  <thead>
                  <tr>
                    <!--                <th><span class="th-title">분야</span></th>-->
                    <th><span class="th-title">부문</span></th>
                    <th><span class="th-title">대상 자격증</span></th>
                    <th><span class="th-title">인정여부</span></th>
                    <th><span class="th-title">수료과정명</span></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, idx) in items_lcsns" :key="idx">
                    <td><span class="text">{{ item.crseWorkDivNm }}</span></td>
                    <td><div class="text-truncate"><strong class="text">{{item.trgtNm}}</strong></div></td>
                    <template v-if="item.fnshYn === 'Y'">
                      <td><strong class="text text-primary">인정</strong></td>
                    </template>
                    <template v-else>
                      <td>-</td>
                    </template>
                    <td><span class="text">{{item.distCrseNm}}</span></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </article>

        </div>
    </template>
</template>

<script>

import {certificationConfigs} from '@/assets/js/modules/mypage/mypage-common';
import {computed, onMounted, reactive, ref, watch} from 'vue';
import {getItems, goToTop, lengthCheck, timestampToDateFormat} from '@/assets/js/util';
import navigationUtils from '@/assets/js/navigationUtils';
import {
    ACT_GET_LEARN_MY_CERTIFICATION_TEMP_LIST,
} from '@/store/modules/learn/learn';
import {useStore} from 'vuex';
import {
    enterpriseAllCds,
    enterpriseCds,
    getJmJobCertList,
    getVicePromotionRequirementList, individualAllCds,
    individualCds
} from "@/assets/js/modules/learn/learn-common";
import router from "@/router";

export default {
    name: 'MyPageTempCertification',
    props: {
        // works => 개인 금융, 기업 금융
        works: Array,
        learns: Array,
        // certifications: Array
    },
    setup(props) {
        const store = useStore();

        const isMobile = navigationUtils.any();
        const courseKey = ref('개인금융');
        const courseModalKey = ref('');
        const courseToggle = ref(false);
        const isModal = ref(false);
        const items = ref([]);
        const items_lcsns = ref([]);
        const jmLcsns = ref([]);
        const specialIndividualYn = ref('N');
        const specialEnterpriseYn = ref('N');

        const certificationSubjList = ref([]);

        const search = reactive({
            jobCertificationYn: 'Y',
        })
        const getCertCount = (item) => {
            if (item) {
                const codes = item.courses.map(x => x.code);
                return props.learns.filter(x => x.fnshYn === 'Y' && codes.includes(x.befCrseCd)).length;
            }
            return 0;
        };

        const certLearns = computed(() => {
            const codes = certificationConfigs[courseKey.value === '개인금융' ? 0 : 1].courses.map(x => x.code);
            return props.learns.filter(x => x.fnshYn === 'Y' && codes.includes(x.befCrseCd));
        });

        const myCertifications = computed(() => certificationConfigs.map(x => ({...x, certCount: getCertCount(x)})));

        const changeCertification = (key) => {
            courseKey.value = key;
            let targetDiv = isMobile ?  "certificationsSubjList_mob" :  "certificationsSubjList";
            let topAdjust = isMobile ?  50 :  0;
            goToTop(targetDiv, 200, topAdjust);
        };

        const showCertificationCourse = (key) => {
            courseModalKey.value = key;
            courseToggle.value = true;
        };

        const showCertificationCourseMobile = (key) => {
          changeCertification(key);
          // router.push({name: 'MyPageCert', params: {courseKey: key}});
        };

        const getCertificationList = () => {
            search.jobCertificationYn = courseKey.value === '개인금융' ? 'Y' : 'N';
            //과정명세
            items.value = certificationSubjList.value.filter( x => courseKey.value === x.crseWorkDtlNm && x.grd1Cd <= "3");
            //특례인정
            items_lcsns.value  = certificationSubjList.value.filter( x => courseKey.value === x.crseWorkDtlNm && x.grd1Cd > "3");
        }

        const applyTrain = (code) => {
          router.push({name: 'ApplyTrain', query : {'crseMstSn': code}});
        }

        const isToggled = (toggle, key) => {
            if (toggle) {
                isModal.value = true;
            } else {
                isModal.value = false;
            }
            console.log(toggle, key);
        };

        watch(() => courseKey.value, () => {
            if (courseKey.value === '개인금융')
                search.jobCertificationYn = 'Y'
            else
                search.jobCertificationYn = 'N';
            getCertificationList();
        })

        const certficationFiledItem = ref({});

        getJmJobCertList(certficationFiledItem);
        const vicePromotionRequirementItems = ref([]);
        getVicePromotionRequirementList(vicePromotionRequirementItems);

        const individualCdNms = computed(() => {
            const filtered = vicePromotionRequirementItems.value.filter(x => individualCds.includes(x.lcsnCd)).map(y => y.lcsnCd)
            if(filtered.length > 0){
                let individualCdArr = [];
                filtered.forEach(x => {
                    if('J211' === x) specialIndividualYn.value = 'Y'
                    individualAllCds.forEach(y => {
                        if(x === y.cd){
                            if(!individualCdArr.includes(y.type)){
                                individualCdArr.push(y.type)
                            }
                        }
                    })
                })
                return individualCdArr.join();
            }
            return '';
        });

        const enterpriseCdNms = computed(() => {
            const filtered = vicePromotionRequirementItems.value.filter(x => enterpriseCds.includes(x.lcsnCd)).map(y => y.lcsnCd)
            if(filtered.length > 0){
                let enterpriseCdArr = [];
                filtered.forEach(x => {
                    if('J221' === x || 'J220' === x) specialEnterpriseYn.value = 'Y'
                    enterpriseAllCds.forEach(y => {
                        if(x === y.cd){
                            if(!enterpriseCdArr.includes(y.type)){
                                enterpriseCdArr.push(y.type)
                            }
                        }
                    })
                })
                return enterpriseCdArr.join();
            }
            return '';
        });

        onMounted(async ()=>{
          search.jobCertificationYn = courseKey.value === '개인금융' ? 'Y' : 'N';
          store.dispatch(`learn/${ACT_GET_LEARN_MY_CERTIFICATION_TEMP_LIST}`, {
            jobCertificationYn: search.jobCertificationYn,
          }).then(res => {
            if (lengthCheck(res)) {
              certificationSubjList.value = getItems(res);
            }else{
              certificationSubjList.value = [];
            }
          }).catch(e => {
            console.error(e);
          }).finally(()=>{
            getCertificationList();
          })

        })

        return {
            courseKey,
            courseModalKey,
            courseToggle,
            showCertificationCourse,
            changeCertification,
            certLearns,
            myCertifications,
            certificationConfigs,
            timestampToDateFormat,
            isMobile,
            isModal,
            items,items_lcsns,
            isToggled,
            showCertificationCourseMobile,
            getCertificationList,
            certficationFiledItem,jmLcsns,
            enterpriseCdNms,individualCdNms,
            specialIndividualYn,specialEnterpriseYn,
            certificationSubjList,
            applyTrain
        };
    },
};
</script>

<style scoped>

.text-is-active {
  background-color: var(--kb-background-brown);
}
</style>