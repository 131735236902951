<template>
  <ul class="symbol-item-wrap symbol-item-margin" data-content="row">
    <!-- symbol-item -->
    <li v-for="(item, idx) in items" :key="idx" class="symbol-item">
      <div class="item-row row-content">
        <div class="item-avatar">
          <div class="avatar">
            <SSLProfileImg :target="items.map(x => ({lrnerId : x.leaderId}))[idx]" />
          </div>
        </div>
        <div class="item-learning">
          <div class="content-subtitle">{{ item.distCrseLrnFldNm }}</div>
          <div class="content-title">{{ item.crseNm }}</div>
          <div class="content-texts text-break">
            <span v-if="$route.name === 'GroupView'" class="text">{{ item.boardSumup }}</span>
            <span v-else class="text">참여 {{ item.lrnTrgtCnt }} / 정원 {{ item.peoplCnt }} ・ {{ item.boardSumup }}</span>
            <span class="text">{{ item.leaderNm }} ・ {{ item.deptNm }} ・ {{ item.jbgdNm }}{{ item.jbpsNm }}</span>
          </div>
        </div>
      </div>
      <div class="item-row row-buttons">
        <GroupDetailButton :item="item" :click-group-detail="clickGroupDetail" :go-group-lrn="goGroupLrn" />
      </div>
    </li>
    <!-- //symbol-item -->
  </ul>
  <div class="kb-alert-container" :class="{'is-active' : alertShow}">
    <div class="kb-alert kb-alert-prompt">
      <div class="kb-alert-contents">
        <strong class="kb-alert-title"></strong>
        <p class="kb-alert-text">{{!pwdFail ?  '비밀번호가 일치하지 않습니다.' : '해당 그룹의 비밀번호를 입력하세요.' }}</p>
        <div class="kb-alert-form">
          <template v-if="!pwdFail">
            <input v-model="pwd" type="password" class="kb-form-control" maxlength="4" required pattern="'[0-9]+'">
          </template>
          <template v-else>
            <input v-model="pwd" type="password" class="kb-form-control" maxlength="4">
          </template>
        </div>
      </div>
      <div class="kb-alert-actions">
        <button @click="okConfirm" class="kb-btn-alert"><span class="text">확인</span></button>
        <button @click="closeConfirm" class="kb-btn-alert"><span class="text">취소</span></button>
      </div>
    </div>
  </div>
</template>

<script>
import {groupCardSetup} from "@/assets/js/modules/groupLearing/group-card-setup";
import SSLProfileImg from "@/components/ssl/common/SSLProfileImg";
import GroupDetailButton from "@/components/prep/group/GroupDetailButton";

export default {
  name: "MobileGroupCard",
  components: {GroupDetailButton, SSLProfileImg},
  props: {
    items: Array,
    isViewPage: Boolean
  },
  setup: groupCardSetup
}
</script>