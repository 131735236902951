<template>
  <HrdCloudMobileHeader :show-back="true" :go-back-func="closeModal" />
  <main class="kb-main" id="kb-hrdcloud">
    <div class="main-content main-component">
      <article class="content-section">
        <div class="calendar">
          <div class="calendar-top">
            <h2 class="title">{{ currentMonth }}월 출석</h2>
            <div class="actions">
              <button class="kb-btn-month kb-btn-month-prev" type="button" @click="changeMonth(false)">
                <span class="text">이전</span>
              </button>
              <button class="kb-btn-month kb-btn-month-next" type="button" @click="changeMonth(true)">
                <span class="text">다음</span>
              </button>
            </div>
          </div>
          <div class="calendar-body">
            <table class="calendar-table">
              <thead>
              <tr>
                <th scope="col"><span class="day-header">일</span></th>
                <th scope="col"><span class="day-header">월</span></th>
                <th scope="col"><span class="day-header">화</span></th>
                <th scope="col"><span class="day-header">수</span></th>
                <th scope="col"><span class="day-header">목</span></th>
                <th scope="col"><span class="day-header">금</span></th>
                <th scope="col"><span class="day-header">토</span></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(week, idx) in currentWeeks" :key="idx">
                <td v-for="(day, i) in week" :key="`${idx}-${i}`">
                  <template v-if="day.month === currentMonth">
                    <div v-if="checkMlg(day) == 1" class="day kb-att-day">
                      <img v-if="day.isCouponDay === 2"  style="width:35px;" src="@/assets/images/ssl/ic_mlg_coupon1.svg" alt="" />
                      <i v-else class="icon-mileage"></i>
                    </div>
                    <div v-else-if="checkMlg(day) >= 2" class="day kb-att-day">
                      <img v-if="day.isCouponDay === 2"  style="width:35px;"  src="@/assets/images/ssl/ic_mlg_coupon2.svg" alt="" />
                      <i v-else class="icon-mileage-2"></i>
                    </div>
                    <div v-else class="day kb-att-day day-deactivate" :class="{ 'text-red' : isWeekend(day) }">
                      <span v-if ="day.isCouponDay === 0">{{ day.day }}</span>
                      <img v-else-if="day.isCouponDay === 1" class="day" src="@/assets/images/ssl/ic_coupon_deactive.svg" style="width:30px;" alt="" />
                      <span v-if="isHolyDay(day)" class="text">{{ isHolyDay(day) ? getHolyDay(day) : '' }}</span>
                    </div>
                  </template>
                  <template v-else>
                    <div class="day blank"></div>
                  </template>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="calendar-bottom" style="text-align: left; margin-top:0px;">
            <p class="bottom-text" style="text-align: left; margin-top:0px;">동영상 및 전산실습 Easy(실습모드) <span class="text-yellow">1마일리지 이상 취득 </span> 시 출석이 인정됩니다.  </p>
            <p class="bottom-text" style="text-align: left; margin-top:0px;"><span class="text-yellow"> 2주 단위 매영업일(주말 및 공휴일 제외)</span>  출석체크 달성 시 커피쿠폰이 제공됩니다.</p>
          </div>
          <div class="calendar-bottom">
            <button class="kb-btn-attendance" @click="closeModal">
              <span class="text">닫기</span>
            </button>
          </div>
        </div>
      </article>
    </div>
  </main>
</template>

<script>
import HrdCloudMobileHeader from '@/components/hrdcloud/mobile/HrdCloudMobileHeader';
import {computed, reactive, ref} from 'vue';
import {ACT_GET_HRDCLOUD_HISTORY_ATTENDANCE_LIST} from '@/store/modules/history/history';
import {getListFunc} from '@/assets/js/ui.init';
import {holyDay} from '@/assets/js/holy-day';
import {getCalendar, timestampToDatePart} from '@/assets/js/util';
import {HrdCloudCouponDays} from "@/assets/js/coupon-day";

export default {
  name: 'HrdCloudHistoryMobileAttendance',
  components: {HrdCloudMobileHeader},
  props: {
    modelValue: Boolean
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const isLoading = ref(true);
    const currentDate = new Date();
    const currentYear = ref(currentDate.getFullYear());
    const currentMonth = ref(currentDate.getMonth() + 1);

    const today = reactive({
      year: currentDate.getFullYear(),
      month: (currentDate.getMonth() + 1),
      day: currentDate.getDate(),
    });
    const items = ref([]);
    const todayYmd = `${today.year}${('0' + today.month).slice(-2)}${('0' + today.day).slice(-2)}`;

    getListFunc(`history/${ACT_GET_HRDCLOUD_HISTORY_ATTENDANCE_LIST}`, {year: currentYear.value}, items, null, () => {
      items.value = items.value.map(x => ({...x, datePart: timestampToDatePart(x.regDt)}));
      isLoading.value = false;
    });

    const currentWeeks = computed(() => getCalendar(currentYear.value, currentMonth.value).map(week => week.map((day, idx) => ({...day, isCouponDay: isCouponDay(day.ymd, idx)}))));

    const nextYearMonth = computed(() => {
      if(currentMonth.value === 12){
        return `${currentYear.value + 1}년 1월`
      }else{
        return `${currentYear.value}년 ${currentMonth.value + 1}월`
      }
    });

    const prevYearMonth = computed(() => {
      if(currentMonth.value === 1){
        return `${currentYear.value - 1}년 12월`
      }else{
        return `${currentYear.value}년 ${currentMonth.value - 1}월`
      }
    });



    const isHolyDay = ({year, month, day}) => {
      return !!holyDay[`${year}${('0' + month).slice(-2)}${('0' + day).slice(-2)}`];
    }

    const getHolyDay = ({year, month, day}) => {
      return holyDay[`${year}${('0' + month).slice(-2)}${('0' + day).slice(-2)}`];
    }
    const isCouponDay = (ymd, idx) => {
      if(idx === 5 || idx === 4){
        // 이미 획득...
        const filtered = HrdCloudCouponDays.filter(x => (x.indexOf(ymd) > -1));
        if(filtered.length > 0){
          const lastDate = filtered[0][(filtered[0].length - 1)];
          // console.log("lastDate:",lastDate)
          if(ymd === lastDate){
            // 1. 이미 지남...
            if(lastDate < todayYmd){
              if(items.value.filter(x => (x.datePart.ymd >= filtered[0][0] && x.datePart.ymd <= lastDate)&& filtered[0].includes(x.datePart.ymd)).length >= filtered[0].length){
                return 2;
              }else{
                return 1;
              }
            }
            // 2. 아직 안지남
            else if(lastDate > todayYmd){
              const index = filtered[0].indexOf(todayYmd);
              if(items.value.filter(x => (x.datePart.ymd >= filtered[0][0] && x.datePart.ymd <= lastDate)).length >= index){
                return 1;
              }else{
                return 2;
              }
            }
            // 3. 당일
            else if(lastDate === todayYmd){
              const todayAttend = items.value.filter(x => x.datePart.ymd === todayYmd);
              const attendCount = items.value.filter(x => (x.datePart.ymd >= filtered[0][0] && x.datePart.ymd <= lastDate)&& filtered[0].includes(x.datePart.ymd)).length
              // console.log("todayAttend,attendCount :",todayAttend,attendCount,filtered[0].length)
              // 오늘 출석한 정보가 있을 경우
              if(todayAttend.length > 0){
                if(attendCount >= filtered[0].length ){
                  return 2;
                }

              }else{
                if(attendCount < (filtered[0].length)){
                  return 1;
                }
              }
            }
          }
        }
      }
      return 0;
    }

    const isWeekend = (date) => {
      const week = ['일', '월', '화', '수', '목', '금', '토'];

      const dayOfWeek = week[new Date(date.ymdformat).getDay()];

      if(dayOfWeek === '토' || dayOfWeek === '일') return true;

      return holyDay[date.ymd];
      // return false;
    }

    const changeMonth = (isNext) => {
      if(isNext){
        if(currentMonth.value === 12){
          currentYear.value++;
          currentMonth.value = 1;
        }else{
          currentMonth.value++;
        }
      }else{
        if(currentMonth.value === 1){
          currentYear.value--;
          currentMonth.value = 12;
        }else{
          currentMonth.value--;
        }
      }
    };


    const checkMlg = ({year, month, day}) => {
      let mlgValue = 0;
      items.value.forEach(x => {
        if (x.cmptnYear == year && x.cmptnMonth == month && x.cmptnDay == day) return mlgValue = x.mlgValue;
      });
      return mlgValue;
    };

    const closeModal = () => {
      emit('update:modelValue', false);
    };

    return {
      isLoading,
      items, today, currentWeeks, currentMonth, currentYear, nextYearMonth, prevYearMonth,
      changeMonth, checkMlg, isHolyDay, getHolyDay, closeModal, isWeekend,isCouponDay,
    }
  }
};
</script>