<template>
  <MobileEvlAnswer
      v-if="isAnswer"
      :evl="evl"
      @closeEvl="closeModal"
  />
  <template v-else>
    <MobileEvlMain
        v-if="isStart"
        :evl="evl"
        :is-end="isEnd"
        :is-mock="isMock"
        :init-dt="initDt"
        :can-take="canTake"
        :current-time="currentTime"
        :current-time-text="currentTimeText"
        :stopEvl="stopEvl"
        @closeEvl="closeModal"
        @endEvl="endEvaluation"
    />
    <MobileEvlIntro
        v-else
        :evl="evl"
        :is-ready="isReady"
        :is-end="isEnd"
        :can-take="canTake"
        :current-time-text="currentTimeText"
        @closeEvl="closeModal"
        @readyEvl="readyEvaluation"
        @initEvl="initEvaluation"
        @initMockEvl="initMockEvaluation"
    />
    <div v-if="scoreToggle" class="kb-alert-container score-alert is-active">
      <div class="kb-alert">
        <div class="kb-alert-contents">
          <strong class="kb-alert-title">평가종료</strong>
<!--          <p class="score">{{ score }}점</p>-->
          <p class="kb-alert-text">종료시간 이전에 비정상 종료된 경우 새로고침 후<br>다시 참여하실 수 있습니다.</p>
        </div>
        <div class="kb-alert-actions">
          <button class="kb-btn-alert" @click="closeModal"><span class="text">확인</span></button>
        </div>
      </div>
    </div>
  </template>
</template>
<script>


import MobileEvlIntro from '@/components/evaluation/mobile/MobileEvlIntro';
import MobileEvlMain from '@/components/evaluation/mobile/MobileEvlMain';
import {learnEvlSetup} from '@/assets/js/modules/learn/learn-evl-setup';
import MobileEvlAnswer from '@/components/evaluation/mobile/MobileEvlAnswer';

export default {
  name: 'MobileLearnEvaluation',
  components: {MobileEvlAnswer, MobileEvlMain, MobileEvlIntro},
  props: {
    evl: Object,
    isAnswer: Boolean,
    toggleEnd: Function
  },
  emits: ['renewInit', 'renewEnd'],
  setup: learnEvlSetup
}
</script>
