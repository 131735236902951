import {useRouter} from "vue-router";
import {useStore} from "vuex";
import {computed, reactive, ref} from "vue";
import {ACT_GET_NOTI_HRD_SUBSCRIBE_LST, ACT_GET_NOTI_TRSM} from "@/store/modules/notification/notification";
import {clearTargetTimeout, getCheckItems, setParams, timestampToDateFormat} from "@/assets/js/util";

export const hrdSubscribePopupSetup = (props, {emit}) => {
  const store = useStore();
  const router = useRouter();
  // eslint-disable-next-line no-unused-vars
  const isMobile = computed(() => store.state.auth.isMobile);
  const notifications = ref([]);
  const paging = reactive({
    pageNo: 1,
    pageSize: 1,
    totalCount: 0,
    hasMore: false
  });
  const idx = computed(() => paging.pageNo - 1);

  const show = ref(false);

  const getSubscribes = () => {
    store.dispatch(`notification/${ACT_GET_NOTI_HRD_SUBSCRIBE_LST}`, {...paging}).then(res => {
      notifications.value = getCheckItems(res);
      setParams(paging, res.paging);
      if (notifications.value && notifications.value.length > 0) {
        show.value = true;
      } else {
        show.value = false;
        closeModal();
      }
    });
  };

  const goNext = () => {
    if (paging.hasMore) {
      if (paging.pageNo < paging.totalCount) paging.pageNo += 1;
      else paging.pageNo -= 1;
    }
  };

  const goDebounce = ref(0);

  const handleGo = () => {
    goDebounce.value = setTimeout(() => {
      clearTargetTimeout(goDebounce);

      store.dispatch(`notification/${ACT_GET_NOTI_TRSM}`,notifications.value[idx.value].notiTrsmSn).then(() => {
        if (notifications.value && notifications.value[idx.value] && notifications.value[idx.value].notiCn) {
          router.push('hrdcloud?'+notifications.value[idx.value].notiCn);
          getSubscribes();
        }
        closeModal();
      });
    },500);

  };

  getSubscribes();

  const closeModal = () => {emit('update:modelValue', false)};

  return {
    notifications,
    paging,
    handleGo,
    goNext,
    idx,
    closeModal,
    timestampToDateFormat,
    show
  }
};