<template>
  <header class="kb-header" id="kb-header">
    <div class="header-component">
      <!-- header-util -->
      <div class="header-column header-util left">
        <div class="util util-back">
          <a href="javascript:" class="util-actions util-actions-back" @click="goBack">
            <i class="icon-history-back"></i>
          </a>
        </div>
        <div class="util util-search-input">
          <input v-model="search.lcsnNm" type="text" placeholder="자격증명 검색">
        </div>
      </div>
      <!-- //header-util -->
      <!-- header-util -->
      <div class="header-column header-util right">
        <div class="util util-search">
          <a href="javascript:" class="util-actions util-actions-search" @click="clickSearch">
            <i class="icon-search"></i>
          </a>
        </div>
      </div>
      <!-- //header-util -->
    </div>
  </header>
  <main class="kb-main" id="kb-support">
    <!-- main-content -->
    <div class="main-content main-component">
      <div class="search-result">
        <div class="view-contents">

          <!-- content-section -->
          <section v-for="(item, idx) in items" :key="idx" class="content-section">
            <div class="content-item bd-clear pd-clear">
              <div class="title">{{ item.lcsnCd }}</div>
              <div class="desc">
                <p><b>{{ item.lcsnNm }}</b></p>
              </div>
              <ul class="desc-type-title baseline">
                <li>
                  <h5>주관처</h5>
                  <p>{{ item.pblcnInstNm }}</p>
                </li>
                <li>
                  <h5>등급</h5>
                  <p>{{ item.grd1Cd }} </p> <!-- | {{ item.grd2Cd }} -->
                </li>
<!--                <li>-->
<!--                  <h5>투자자보호 교육비</h5>-->
<!--                  <p>{{ numberComma(item.invProtEduGrpAmt) || 0}} | {{ numberComma(item.invProtEduCyberAmt) || 0}}</p>-->
<!--                </li>-->
<!--                <li>-->
<!--                  <h5>응시료 지원한도</h5>-->
<!--                  <p>{{ numberComma(item.examFeeSprtAmt) || 0}}</p>-->
<!--                </li>-->
<!--                <li>-->
<!--                  <h5>교재비 지원한도</h5>-->
<!--                  <p>{{ numberComma(item.bookCostSprtAmt)|| 0 }}</p>-->
<!--                </li>-->
                <li>
                  <h5>지원한도</h5>
                  <p>{{  numberComma(item.trnAidAmt) || 0 }}</p>
                </li>
<!--                <li>-->
<!--                  <h5>신청가능 유효 응시기간</h5>-->
<!--                  <template v-if="item.aplyVldBgngYmd != null && item.aplyVldBgngYmd != ''">-->
<!--                    <p>-->
<!--                          {{item.aplyVldBgngYmd}} ~-->
<!--                            <template v-if="item.aplyVldEndYmd != '' && item.aplyVldEndYmd != null && item.aplyVldEndYmd != '9999-12-31'">{{item.aplyVldEndYmd}}</template>-->
<!--                    </p>-->
<!--                  </template>-->
<!--                  <template v-else>-->
<!--                    <p>-->
<!--                      <template v-if="item.aplyVldEndYmd != '' && item.aplyVldEndYmd != null && item.aplyVldEndYmd != '9999-12-31'">~ {{item.aplyVldEndYmd}}</template>-->
<!--                      <template v-else>신청가능</template>-->
<!--                    </p>-->
<!--                  </template>-->
<!--                </li>-->
              </ul>
              <div class="kb-btn-actions">
                <button class="kb-btn-support" @click="selectedLicense(idx)">자격증 취득 지원 신청</button>
              </div>
            </div>

          </section>

          <template v-if="items.length == 0">
            <div class="section-content">
              <div class="content-item">
                <div class="desc">
                  <p>검색 결과가 없습니다.</p>
                  <br>
                  <p>대상 자격증이 없는 경우 담당자에게 문의주시기 바랍니다.</p>
                  <p>(IT 계열 등급외 자격증은 평가비 지원신청에서 등록)</p>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <!-- //main-content -->
  </main>
</template>

<script>
import {ref, reactive} from "vue";
import {useStore} from "vuex";
import {MUT_SHOW_LICENSE_MAIN} from "@/store/modules/support/support";
import {getLcsnList} from "@/assets/js/modules/support/support-common";
import {initParams, numberComma} from "@/assets/js/util";


export default {
  name: "MobileLicenseSearch",
  props: {
    keyword: String,
    modelValue: Boolean,
    selectedValue: Object
  },
  emits: ['update:modelValue', 'update:selectedValue'],
  setup(props, {emit}) {

    const store = useStore();

    const isLoading = ref(false);
    const search = reactive({lcsnNm: props.keyword});
    const items = ref([]);
    const paging = reactive({
      pageNo: 1,
      pageSize: 999999,
      totalCount: 0
    });

    const clickSearch = () => {
      getLcsnList({...search, ...paging}, items, paging);
    }

    const goBack = () => {
      initParams(search);
      emit('update:modelValue', false);
      store.commit(`support/${MUT_SHOW_LICENSE_MAIN}`);
    }

    if(search.lcsnNm) {
        getLcsnList({...search, ...paging}, items, paging);
    }

    const selectedLicense = (idx) => {
      emit('update:selectedValue', items.value[idx]);
      goBack();
    }

    return {
      isLoading,
      search,
      items,
      paging,

      clickSearch,
      selectedLicense,
      goBack,
      numberComma
    }
  }
}
</script>