<template>
  <div class="popup-container" :class="{ 'is-active' : modelValue }">
    <div class="popup roadmap-tree-modal">
      <div class="popup-inner">
        <section class="popup-section">
          <header class="section-header mb-6">
            <span class="title">학습 로드맵</span>
          </header>
          <div class="popup-section">
            <div class="section-data">
              <table class="w-100">
                <colgroup><col style="width: 30rem" /><col style="width: 30rem" /><col /></colgroup>
                <tr>
                  <td><span class="me-2 fw-bold">현재직무:</span><span v-if="lastJobInfo" class="">{{lastJobInfo.jobNm}}</span></td>
                  <td><span class="me-2 fw-bold">AI가 추천하는 직무(영업점):</span><span v-if="aiResult.length > 0" class="">{{aiResult[0].dtyNm}}</span></td>
                  <td rowspan="2" class="text-end"><button class="recmd-btn" @click="goRecommendSystem"><span class="text text-red">인재추천시스템</span></button></td>
                </tr>
                <tr>
                  <td>
                    <span class="me-2 fw-bold">UpSkill 추천로드맵:</span>
                    <template v-if="upSkill">
                      <span v-for="(data,idx) in upSkill" :key="idx" class="fw-bold text-red">
                        {{data}}<span v-if="idx+1 < upSkill.length" class="me-1">,</span>
                      </span>
                    </template>
                    <span v-else>-</span>
                  </td>
                  <td>
                    <span class="me-2 fw-bold">ReSkill 추천로드맵:</span>
                    <template v-if="reSkill">
                      <span v-for="(data,idx) in reSkill" :key="idx" class="fw-bold text-primary">
                        {{data}}<span v-if="idx+1 < reSkill.length" class="me-1">,</span>
                      </span>
                    </template>
                    <span v-else>-</span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="popup-section">
            <div class="section-data">
              <span class="fw-bold">AI 직무적합도 분석</span>
              <div class="ai-result-area">
                <div class="info">
                  <span>나의 정보 데이터를 기반으로 분석한 직무적합도입니다.</span><br>
                  <span>※ AI적합률 100~85%: 적극추천 / 85~70%: 추천 / 70~60%: 적합 / 60% 이하: 보통</span>
                </div>
                <div v-if="aiResult" class="d-flex flex-wrap my-2 w-100">
                  <div class="ai-result" v-for="(item,idx) in aiResult" :key="idx">
                    <span class="text">{{item.dtyNm}}</span>
                    <div class="sub-info">
                      <div class="text">{{item.dtyScr}}%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="popup-section" v-if="false">
            <div class="section-data">
              <span class="fw-bold">AI가 본 나를 표현하는 키워드</span>
              <div class="my-3">
                <span class="me-2 text-primary fw-bold">#HRD 클라우드</span>
                <span class="me-2 text-primary fw-bold">#SSL프로그램</span>
                <span class="me-2 text-primary fw-bold">#자산관리</span>
              </div>
            </div>
          </div>
          <div class="popup-section content-section">
            <div class="section-header">
              <div class="title"></div>
              <div class="header-side">
                <button class="kb-btn kb-btn-secondary kb-btn-collapse kb-collapse-toggle" @click="showAllList = !showAllList">
                  <span class="text">{{showAllList ? '접기' : '펼치기'}}</span><i class="icon-arrow" :class="{'rotate': showAllList}" />
                </button>
              </div>
            </div>
            <div class="map-container">
              <ul class="map-list" data-depth="1">
                <li class="map-item" v-for="cate in nodes" :key="cate.hrdCloudCateSn">
                  <div class="item-box" :class="{'is-active': cate.isActive}" :cate-sn="cate.hrdCloudCateSn" @click="cate.isActive = !cate.isActive">
                    <span class="title text-skip">{{cate.hrdCloudCateNm}}</span>
                    <div class="d-flex align-items-center">
                      <div v-if="cate.isCompleted" class="badge"><span class="badge-text">완료</span></div>
                      <div class="box-column"><i class="icon-mark" /></div>
                    </div>
                  </div>
                  <ul class="map-list" data-depth="2" v-if="cate.isActive">
                    <li class="map-item" v-for="level in cate.levels" :key="level.hrdCloudCateLevel">
                      <div class="item-box" :class="{'is-active': level.isActive}" :cate-sn="cate.hrdCloudCateSn" @click.stop="toggleLevel(level)">
                        <span class="title text-skip" @click.stop="moveToDtl($router, cate.hrdCloudCateSn, level.hrdCloudCateLevel)" title="로드맵 이동하기">{{level.roadmapTitle}}</span>
                        <div class="d-flex align-items-center">
                          <div v-if="level.isCompleted" class="badge"><span class="badge-text">완료</span></div>
                          <template v-else-if="level.upskillRcmdYn == 'Y' || level.reskillRcmdYn == 'Y'">
                            <div>
                              <div v-if="level.upskillRcmdYn == 'Y'" class="badge badge-red"><span class="badge-text">UpSkill추천</span></div>
                              <div v-if="level.reskillRcmdYn == 'Y'" class="badge badge-primary"><span class="badge-text">ReSkill추천</span></div>
                            </div>
                          </template>
                          <div class="box-column"><i class="icon-mark" /></div>
                        </div>
                      </div>
                      <ul class="map-list" data-depth="3" v-if="level.isActive && level.cttList.length > 0">
                        <li class="map-item" v-for="item in level.cttList" :key="item.cttMstSn" title="콘텐츠 이동하기" @click.stop="moveToContentBridge($router, item.lrnObjDtlDistSn)">
                          <div class="item-box" :cate-sn="cate.hrdCloudCateSn">
                            <span class="title text-skip">{{item.objNm}}</span>
                            <div v-if="item.cmptnYn == 'Y'" class="badge"><span class="badge-text">완료</span></div>
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-bottom bottom-center">
            <button class="kb-btn kb-btn-lg kb-btn-secondary" @click="closeModal"><strong class="text">닫기</strong></button>
          </div>
        </section>
      </div>
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close"></i></button>
      </div>
    </div>
  </div>
</template>

<script>
import {hrdCloudRoadmapMainModalSetup, moveToDtl} from "@/assets/js/modules/hrd/hrd-cloud-roadmap-setup";

export default {
  name: 'HrdCloudRoadmapMainModal',
  methods: {moveToDtl},
  props: {
    modelValue: Boolean,
    lastJobInfo: Object,
    dtyInfo: Object,
  },
  setup: hrdCloudRoadmapMainModalSetup
}
</script>

<style>
.roadmap-tree-modal .section-header .header-side .kb-btn-collapse {width: 125px; height: 34px; justify-content: space-between; padding: 0 20px;}
.roadmap-tree-modal .section-header .header-side .kb-btn-collapse .icon-arrow.rotate {transform: rotate(180deg);}
.roadmap-tree-modal .map-container .map-list .map-item:after {content: ''; clear: both; display: block;}
.roadmap-tree-modal .map-container .map-list .map-item .item-box {display: flex; align-items: center; justify-content: space-between; width: 252px; height: 50px; margin: 2px; padding: 14px 12px 16px; float: left; cursor: pointer;}
.roadmap-tree-modal .map-container .map-list .map-item .item-box .title {font: normal 16px/20px var(--kb-font-KBFGTextB);}
.roadmap-tree-modal .map-container .map-list .map-item .item-box .badge .badge-text {word-break: keep-all;}
.roadmap-tree-modal .map-container .map-list .map-item .item-box .box-column .icon-mark {width: 16px; height: 16px; background-image: url(../../assets/lxp/images/hrdcloud/ic_map_open.svg); margin-left: 5px;}
.roadmap-tree-modal .map-container .map-list .map-item .item-box.is-active .box-column .icon-mark {width: 16px; height: 16px; background-image: url(../../assets/lxp/images/hrdcloud/ic_map_close.svg);}
.roadmap-tree-modal .map-container .map-list[data-depth='1'] .map-item .item-box {background-color: rgba(114,0,255,.15);}
.roadmap-tree-modal .map-container .map-list[data-depth='2'] .map-item .item-box {background-color: rgba(255,188,0,.2); width: 400px;}
.roadmap-tree-modal .map-container .map-list[data-depth='3'] .map-item .item-box {background-color: #FBF9F7; width: 100%;}
.roadmap-tree-modal .map-container .map-list[data-depth='1'] {display: flex; flex-direction: column;}
.roadmap-tree-modal .map-container .map-list[data-depth='2'] {display: block; float: left; width: calc(100% - 256px);}
.roadmap-tree-modal .map-container .map-list[data-depth='3'] {display: block; float: left; width: calc(100% - 404px);}
.roadmap-tree-modal .map-container .map-list[data-depth='2'] .item-box .title:hover {color: var(--kb-primary);}
.roadmap-tree-modal .map-container .map-list[data-depth='3'] .item-box:hover .title {color: var(--kb-primary);}
</style>