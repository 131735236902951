<template>
  <div class="myssl-container">
    <SSLMyTop/>
    <div class="board-list-container">
      <div class="board-list-wrapper">
        <div v-if="subscribes.length === 0" class="board-search-wrapper" style="margin-top: 56px;">
          <div class="search-empty">
            <img src="@/assets/lxp/images/ssl/main/empty_image.png" alt>
          </div>
        </div>
        <ul v-else class="member-list">
          <template v-for="(item, idx) in subscribes">
            <li v-if="item.lrnerId" class="member-item" :key="idx">
              <div class="member">
                <router-link :to="{name:'SSLLearnerBoard', params: {lrnerId: item.lrnerId}}" class="member-link"></router-link>
                <div class="member-avatar">
                  <SSLProfileImg :target="item"/>
                </div>

                <div class="member-content">
                  <strong class="name">{{ item.trgtLrnerNm }}</strong>
                  <p class="team">{{ item.trgtDeptNm }}</p>
                </div>
                <div v-if="!isSubscribed" class="member-follow" :class="{'is-active': item.subscribeSts > 0}">
                  <button class="kb-btn-follow" :class="{'kb-btn-follow-friends': item.subscribeSts === 1, 'kb-btn-follow-mate': item.subscribeSts === 2}" @click="unSubscribe(item)">
                    <span class="text">{{ item.subscribeSts === 2 ? '메이트' : '프렌즈' }}</span>
                  </button>
                </div>
              </div>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import SSLMyTop from '@/components/ssl/my/SSLMyTop';
import SSLProfileImg from '@/components/ssl/common/SSLProfileImg';
import {sslMySubscribeSetup} from '@/assets/js/modules/ssl/setup/ssl-my-setup';

export default {
  name: 'SSLMySubscribe',
  components: {
    SSLProfileImg,
    SSLMyTop,
  },
  props: {
    lrnTrgtGrpMstSn: Number
  },
  setup: sslMySubscribeSetup
}
</script>
