<template>
  <!-- page-container -->
  <div v-if="isReady" class="page-container">
    <div class="page-top">
      <div class="page-component">
        <div class="top-nav">
          <router-link :to="{name: 'SSLMain'}" class="top-kb-nav-link">
            <span class="ic-bg"><i class="icon-type-back"></i></span>
            <span class="text">{{ distCrseNm }} 홈</span>
          </router-link>
        </div>
        <div class="top-links">
          <SSLTrial
              trial-class="top-link"
              text-class="text"
              :show-arrow="true"
          />
          <SSLManual
              manual-class="top-link"
              text-class="text"
              :show-arrow="true"
          />
        </div>
      </div>
    </div>

    <div class="page-body">
      <SSLMyStatus v-if="viewRoute === 'status'"/>
      <SSLMyBoard
          v-else-if="['complete', 'temporary', 'save'].includes(viewRoute)"
          :lrn-trgt-grp-mst-sn="lrnTrgtGrpMstSn"
      />
      <SSLMySubscribe
          v-else-if="['subscribe', 'subscribed'].includes(viewRoute)"
          :lrn-trgt-grp-mst-sn="lrnTrgtGrpMstSn"
      />
    </div>
    <!-- //page-footer -->
  </div>
  <!-- //page-container -->

</template>

<script>
import SSLManual from '@/components/ssl/common/SSLManual';
import SSLTrial from '@/components/ssl/common/SSLTrial';
import {sslMySetup} from '@/assets/js/modules/ssl/setup/ssl-my-setup';
import SSLMyStatus from '@/components/ssl/my/SSLMyStatus';
import SSLMyBoard from '@/components/ssl/my/SSLMyBoard';
import SSLMySubscribe from '@/components/ssl/my/SSLMySubscribe';

export default {
  name: 'SSLMy',
  components: {
    SSLMySubscribe,
    SSLMyBoard,
    SSLMyStatus,
    SSLTrial,
    SSLManual,
  },
  setup: sslMySetup
}
</script>
