<template>
  <div class="popup-container" :class="{ 'is-active' : modelValue }">
    <div class="popup" id="popup-hrdcolud-h0211">
      <div class="popup-inner">
        <header class="popup-header">
          <h3 class="title">본문 요약</h3>
        </header>
        <div class="popup-content">
          <div class="popup-section section-01">
            <div class="section-header">
              <div class="header-side2">
                <!--                <button class="kb-btn kb-btn-secondary" @click="func"><i class="icon-view"></i><strong class="text">최근학습 상세보기</strong></button>-->
                <span>Chat GPT가 자동으로 요약한 내용입니다. 자동 요약 기술의 특성상 콘텐츠의 중요한 내용이 제외 되거나 잘못 요약될 수 있으니 참고용으로만 활용해주시기 바랍니다.</span>
              </div>
            </div>
            <div class="kb-form-fields field-01">
              <div class="kb-form-row">
                <div class="kb-form-column kb-form-column-title">
                  <label class="kb-form-label">
                    <span class="kb-form-label-text">콘텐츠명</span>
                  </label>
                </div>
                <div class="kb-form-column">
                  <div class="kb-form-text">{{ title }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="popup-section section-divider">
            <div class="kb-table kb-table-striped">
              <h3>요약내용</h3><br>
              <div v-if="item.cttSummScript !== null" class="td-text">
                <span >{{item.cttSummScript}}</span>
              </div>
              <div v-else class="td-text">
                <span >요약 작업이 미처리 되었습니다. 관리자에게 문의해주세요(☎5473)</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'HrdCloudSttModal',
  props: {
    modelValue: Boolean,
    title: String,
    item: Object,
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {

    const closeModal = () => { emit('update:modelValue', false); }
    return {
      closeModal,
    }
  }
};
</script>