<template>
  <header class="section-header  header-divider">
    <h4 class="title">지원 신청시 유의사항</h4>
  </header>
  <div class="section-content">
    <div class="content-item">
      <p class="text font-body14B">본인은 아래 사항에 대해 모두 확인 및 동의합니다.</p>
      <div class="desc" v-for="(item, idx) in chkAgrList" :key="idx">
        <div class="text">
          <input v-model="checked"
                 class="kb-form-check-input"
                 type="checkbox"
                 :value="item.val"
                 @change="allChk"
          /> {{ item.txt }}</div>
<!--        <div class="col-xl-10 col-12"></div>-->
        <div class="text">&nbsp;</div>
      </div>
    </div>
  </div>
</template>

<script>

import {ref} from "vue";

export default {
  name: "UserAgreementMobile",
  props:{
    userAgreement:Boolean,
  },
  emits:['update:userAgreement','applyBtnEnable'],
  setup(props, {emit}){

    const checked = ref([]); // 기능체크여부
    const chkAgrList = [
      { txt: '자기주도학습비는 직원 본인의 자기개발 및 직무역량향상을 위해 지원하고 있습니다. 본 제도의 취지에 맞는 지원임을 확인합니다.', val:'1' },
      { txt: '허위정보 등록 또는 부당수령 확인시 소속부점 통보, 기지원금 환입, 향후 자기주도학습비 지원 제한, 각종 연수참가 제한, 인사조치 등을 실시할 예정입니다.', val:'2' },
      { txt: '신청 내용 검증을 위해 추가자료 제출 요청 및 개인정보 조회를 실시할 수 있습니다.' , val:'3'},
      { txt: '교육훈련비 미환입자의 경우, 자기주도학습비 지원이 제한됩니다.' , val:'4'},
      { txt: '지급된 자기주도학습비는 기소득 처리 대상으로 HR부(인재개발)에서 일괄 과표처리합니다.' , val:'5'},
    ];

    const allAgreement = ref(false);

    const allChk=()=>{
      if (checked.value.length == chkAgrList.length) {
        allAgreement.value = true;
      }else{
        allAgreement.value = false;
      }

      emit('update:userAgreement', allAgreement.value);
      emit('applyBtnEnable', allAgreement.value);
    }

    const allUnChk=()=>{
      checked.value = [];
    }

    return {
      allAgreement,
      allChk,
      chkAgrList,
      checked,
      allUnChk

    }

  }
}
</script>