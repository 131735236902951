<template>
  <template v-if="isMobile">
    <div class="text-item">
      <h5 class="item-title">{{ title }} <button class="tool-tip-button" @click="tooltip = !tooltip"><img src="@/assets/lxp/images/common/ic_tooltip.svg" alt=""></button></h5>
      <div class="tool-tip" :class="{'is-active': tooltip}" style="top:-30px; width: 310px;">
        <div class="tool-tip-desc">
          진도율과 학습확인(Cold Call)을 모두 충족한 동영상<br> <span style="color:rgb(255,0,0);">학습완료건에 한해 합산</span>합니다.<br>
          ※학습완료 시 마다 실제 재생시간을 기준으로 합산
        </div>
      </div>
      <div class="item-row">
        <span class="text-title">당월{{title === '학습시간' ? ' (완료기준)':''}}</span>
        <span class="text-description text-gold">{{ item.month }}</span>
      </div>
      <div class="item-row">
        <span class="text-title">연간{{title === '학습시간' ? ' (완료기준)':''}}</span>
        <span class="text-description text-gold">{{ item.year }}</span>
      </div>
      <div class="item-row">
        <button v-if="showAll && func" class="kb-btn kb-btn-outline kb-btn-content-item" @click="func"><span class="text">상세보기</span></button>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="roundedbox">
      <div class="roundedbox-top">
        <div class="top-row">
          <h5 class="roundedbox-title">{{ title }} <button class="tool-tip-button" @click="tooltip = !tooltip"><img src="@/assets/lxp/images/common/ic_tooltip.svg" alt=""></button></h5>
          <div class="tool-tip" :class="{'is-active': tooltip}">
            <div class="tool-tip-desc is-left" style="left:80px; width: 310px;">
              진도율과 학습확인(Cold Call)을 모두 충족한 동영상<br> <span style="color:rgb(255,0,0);">학습완료건에 한해 합산</span>합니다.<br>
              ※학습완료 시 마다 실제 재생시간을 기준으로 합산
            </div>
          </div>
          <a href="javascript:" v-if="showAll && func" class="roundedbox-link" @click="func">상세보기</a>
        </div>
      </div>
      <div class="roundedbox-bottom">
        <div class="roundedbox-subtitle">당월{{title === '학습시간' ? ' (완료기준)':''}}</div>
        <span class="roundedbox-text">{{ item.month }}</span>
      </div>
      <div class="roundedbox-bottom">
        <div class="roundedbox-subtitle">연간{{title === '학습시간' ? ' (완료기준)':''}}</div>
        <span class="roundedbox-text">{{ item.year }}</span>
      </div>
    </div>
  </template>
</template>

<script>
import {computed, ref} from 'vue';
import {secondToKrFormat} from '@/assets/js/util';

export default {
  name: 'RoundedBoxToolTip',
  props: {
    title: String,
    data: {type: [Object, Number], default: () => { return {month: 0, year: 0}; }},
    unit: {type: String, default: null},
    showAll: {type: Boolean, default: false},
    func: Function,
    isMobile: Boolean,
  },
  setup(props) {
    const tooltip = ref(false);

    const item = computed(() => {
      let data = {month: 0, year: 0};
      if (props.data instanceof Object) {
        if (props.unit === 'time') {
          data.month = secondToKrFormat(props.data.month);
        } else if (props.unit === 'day') {
          data.month = `${props.data.month} 일`;
        } else if (props.unit === 'mileage') {
          data.month = `${props.data.month} 마일리지`;
        } else {
          data.month = `${props.data.month} 개`;
        }

        if (props.unit === 'time') {
          data.year = secondToKrFormat(props.data.year);
        } else if (props.unit === 'day') {
          data.year = `${props.data.year} 일`;
        } else if (props.unit === 'mileage') {
          data.year = `${props.data.year} 마일리지`;
        } else {
          data.year = `${props.data.year} 개`;
        }
      }
      return data;
    });

    return {
      item,
      tooltip
    }
  }
};
</script>