<template>
  <main class="kb-main" id="kb-prep-group">
    <lxp-main-header :show-back="true" :show-custom-title="true" title="학습계획">
    </lxp-main-header>
    <!-- main-content -->
    <div class="main-content min-component">
      <div>
        <!-- content-section -->
        <section class="content-section section-forms">
          <div class="kb-form-fields">
            <!-- kb-form-row:학습목표 -->
            <div class="kb-form-row">
              <div class="kb-form-column kb-form-column-title">
                <label class="kb-form-label">
                  <span class="kb-form-label-text">학습목표</span>
                </label>
              </div>
              <div class="kb-form-column column-whole">
                <textarea v-model="params.lrnGoals" name="" id="" cols="30" rows="4" :placeholder="lrnGoalPlaceholder"  class="kb-form-control" :readonly="!isLeader"></textarea>
                <div class="textarea-bottom divider">
                  <div class="bottom-column">
                    <span class="counter">{{lrnGoalsCnt}} / 500</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- //kb-form-row -->
            <!-- kb-form-row:학습계획 -->
            <div class="kb-form-row">
              <div class="kb-form-column kb-form-column-title">
                <label class="kb-form-label">
                  <span class="kb-form-label-text">학습계획</span>
                </label>
              </div>
              <div class="kb-form-column column-whole">
                <textarea v-model="params.lrnPlan" name="" id="" cols="30" rows="4" :placeholder="lrnPlanPlaceholder"  class="kb-form-control" :readonly="!isLeader"></textarea>
                <div class="textarea-bottom divider">
                  <div class="bottom-column">
                    <span class="counter">{{lrnPlanCnt}} / 1000</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- //kb-form-row -->
            <!-- kb-form-row:리더의 노력 -->
            <div class="kb-form-row">
              <div class="kb-form-column kb-form-column-title">
                <label class="kb-form-label">
                  <span class="kb-form-label-text">리더의 노력</span>
                </label>
              </div>
              <div class="kb-form-column column-whole">
                <textarea v-model="params.lrnEtcCn" name="" id="" cols="30" rows="4" :placeholder="lrnEtcCnPlaceholder" class="kb-form-control" :readonly="!isLeader"></textarea>
                <div class="textarea-bottom divider">
                  <div class="bottom-column">
                    <span class="counter">{{lrnEtcCnCnt}} / 1000</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- //kb-form-row -->
          </div>
          <div class="kb-form-fields-bottom mt-3"><strong class="text">학습계획은 리더가 언제든지 작성/수정할 수 있습니다.</strong></div>
        </section>
        <div v-if="isLeader" class="page-buttons">
          <button class="kb-btn kb-btn-primary kb-btn-lg" @click="clickSave"><span class="text">저장</span></button>
        </div>
      </div>
    </div>
    <!-- //main-content -->
  </main>
</template>

<script>
import LxpMainHeader from "@/components/common/LxpMainHeader";
import {groupStudyPlanSetup} from "@/assets/js/modules/groupLearing/group-study-plan-setup";

export default {
  name: "GroupStudyPlan",
  components: {LxpMainHeader},
  setup: groupStudyPlanSetup
}
</script>