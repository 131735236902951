<template>
  <HrdCloudMobileHeader :show-back="true" :go-back-func="modelValue.toggle" />
    <main class="kb-main" id="kb-hrdcloud">
      <div class="main-content min-component">
        <article class="content-section">
          <video
              class="vjs-matrix video-js vjs-default-skin vjs-big-play-centered kb-mob-preview"
              ref="videoDom"
              oncontextmenu="return false;"
              playsinline
          ></video>
        </article>
      </div>
    </main>
</template>

<script>
import {useStore} from 'vuex';
import {computed, onBeforeUnmount, onMounted, ref} from 'vue';
import {getType, getVideoUrl} from '@/assets/js/util';
import videojs from 'video.js';
import HrdCloudMobileHeader from '@/components/hrdcloud/mobile/HrdCloudMobileHeader';

export default {
  name: 'HrdCloudMobilePreview',
  components: {HrdCloudMobileHeader},
  props: {
    modelValue: Object,
    item: Object,
  },
  setup(props) {
    const store = useStore();
    const session = computed(() => store.state.auth.session);
    const videoDom = ref(null), video_instance = ref(null);

    onMounted(() => {
      if (session.value && videoDom.value) {
        const vjsOptions = {
          fluid: true,
          preload: 'metadata',
          autoplay: false,
          crossOrigin: 'anonymous',
          language: 'ko',
          // width: 1200,
          // height: 675,
          controls: true,
          controlBar: {
            subsCapsButton: false,
            audioTrackButton: false,
          },
          sources: [{
            src: getVideoUrl(session.value.itn, props.item),
            type: getType(getVideoUrl(session.value.itn, props.item))
          }]
        };
        video_instance.value = videojs(videoDom.value, vjsOptions,function() {});
      }
    });

    onBeforeUnmount(() => {
      // console.log('video disposed onbeforeunmount');
      if (video_instance.value) video_instance.value.dispose();
    });

    return {
      videoDom
    }
  }
};
</script>