<template>
  <div class="header-tab">
    <nav class="kb-nav-round-tabs">
      <ul class="kb-nav-list">
        <li v-for="(viewRoute, index) in viewRoutes" class="kb-nav-item" :key="index">
          <a href="javascript:" class="kb-nav-link" :class="{'is-active': viewRoute.view === $route.query.view}" @click="changeView(viewRoute.view)">
            <span class="kb-nav-text">{{viewRoute.name}}</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import {computed} from 'vue';
import {supportMenu} from '@/assets/js/modules/support/support-common';
import {useRouter} from 'vue-router';

export default {
  name: 'SupportStatusRoute',
  props: {
    view: String
  },
  emits: ['changeView'],
  setup() {
    const router = useRouter();
    const viewRoutes = computed(() => {
      const menu = supportMenu;
      return menu;
    });

    const changeView = (view) => {
      router.push({query: {view: view}});
    }

    return {
      viewRoutes,
      changeView
    }
  }
};
</script>