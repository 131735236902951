<template>
  <div class="popup-buttons">
    <template v-if="stt !== '00' && stt !== '61' && stt !== '78' && stt !== '99' && aplySttCdDcd !== '2106012'">
      <button class="kb-btn kb-btn-primary kb-btn-lg" :disabled="applyBtnEnable(userAgreement)" @click="apply">
        <span v-if="!stt || stt === '23'" class="text">{{ type === 'renewal' ? '갱신비용 신청' : '지원신청' }}</span>
        <span v-else-if="stt === '81'" class="text">수정</span>
      </button>
    </template>
    <template v-else-if="type === 'academy' || type === 'school'">
      <button v-if="stt === '00'" class="kb-btn kb-btn-primary kb-btn-lg" :disabled="applyBtnEnable(userAgreement)" @click="apply">
        <span class="text">수정</span>
      </button>
    </template>
    <button v-if="stt === '81'" class="kb-btn kb-btn-outline kb-btn-lg" @click="cancel">
      <span class="text">지원신청 취소</span>
    </button>
    <button class="kb-btn kb-btn-outline kb-btn-lg" @click="closeModal"><span class="text">닫기</span></button>
  </div>
</template>

<script>
export default {
  name: "SupportPopupButton",
  props: {
    stt: String,
    aplySttCdDcd: String,
    type: String,
    applyBtnEnable: Function,
    userAgreement: Boolean,
    apply: Function,
    cancel: Function,
    closeModal: Function
  }
}
</script>