<template>
    <div class="main-header">
      <!-- main-header > header-ssl -->
      <div class="header-ssl">
        <div class="header-row main-component">
          <SSLNav
              :effect-nav="effectNav"
          />


          <div class="header-column"  style="flex:auto;" v-if="lrnTrgtGrpMstSn == 0 && !isSslSearch">
            <div class="board-list-top" style="float: right">
              <div class="kb-form-field field-search">
                <input type="text" class="kb-form-control" v-model.trim="searchKeyword" placeholder="학습노트 검색" @keyup.prevent.stop.enter="goBoardSearch()">
                <button type="button" class="kb-btn-search" @click="goBoardSearch()" ><i class="icon-search">검색</i></button>
                <button class="kb-btn-search-delete" @click="deleteKeyword">
                  <i class="icon-delete"></i>
                </button>
              </div>
            </div>
          </div>

          <div class="header-column column-visitors">
            <div class="types">
              <button v-if="lrnTrgtGrpMstSn > 0 && !isMyLrnTrgt && !isNoneReward" class="badge" @click="enrollCourse">
                <span class="text">체험모드중</span>
              </button>
            </div>
            <div class="textgroup">
              <span class="text">방문자수 Today {{ numberComma(uv.todayCount) }}명 ({{numberComma(uv.todayBoardCount)}}건)  </span>
              <span class="text">Total {{ numberComma(uv.totalCount) }}명 ({{numberComma(uv.totalBoardCount)}}건)</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
<script>

import {computed, reactive, ref, watch} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {useStore} from 'vuex';
import {
  enrollSSLAlert,
  getRouteLrnTrgtGrpMstSn,
  isMyLrnTrgtGrpMstSn,
  noneRewardCds,
  sslRoutes,
} from '@/assets/js/modules/ssl/ssl-common';
import {numberComma} from '@/assets/js/util';
import {getSSLUVList} from '@/assets/js/modules/ssl/ssl-home';
import {goToSignin} from '@/assets/js/modules/common/auth';
import SSLNav from '@/components/ssl/SSLNav';
import {useAlert} from "@/assets/js/modules/common/alert";

export default {
  name: 'SSLHeader2',
  components: {SSLNav},
  props: {
    effectNav: {
      required: false,
      type: Number,
      default: 0
    }
  },
  setup(props){
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const {showMessage} = useAlert();

    const session = computed(() => store.state.auth.session);
    const isIntro = computed(() => route.path.includes('intro'));
    const isSslSearch = computed(()=> route.name === 'SSLBoardSearch');

    const distCrseCd = computed(() => route.params.distCrseCd);
    const distCrseNm = computed(() => sslRoutes[distCrseCd.value] ? sslRoutes[distCrseCd.value].name : '' );

    const lrnTrgtGrpMstSn = computed(() => getRouteLrnTrgtGrpMstSn(route.params.lrnTrgtGrpMstSn));
    const isMyLrnTrgt = computed(() => {
      if (lrnTrgtGrpMstSn.value > 0) {
        return isMyLrnTrgtGrpMstSn(lrnTrgtGrpMstSn.value)
      }
      return false;
    });
    const isNoneReward = computed(() => noneRewardCds.includes(route.params.distCrseCd));

    const dataBg = computed(() => {
      if(route.name === 'SSLHome' || route.name === 'SSLWrite'){
        return 'true';
      }
      return '';
    });

    const searchKeyword = ref('');

    const visibleProfileNav = ref(false);

    const toggleProfileNav = () => {
      visibleProfileNav.value = !visibleProfileNav.value;
    }

    const uv = reactive({
      totalCount: 0,
      todayCount: 0,
      totalBoardCount: 0,
      todayBoardCount: 0
    });

    const enrollCourse = () => {
      enrollSSLAlert(distCrseCd, distCrseNm, lrnTrgtGrpMstSn)
    }

    const deleteKeyword = () => {
      searchKeyword.value = '';
      // props.searchFunc(searchKeyword.value);
    }
    const signs = ['Signin', 'SSO']

    const goBoardSearch=()=>{
      if(searchKeyword.value == ''|| searchKeyword.value == null){
        showMessage('검색어를 입력해주세요');
        return;
      }
      router.push({name: 'SSLBoardSearch', params: {keyword: searchKeyword.value}})
    }

    watch(() => lrnTrgtGrpMstSn.value, () => {
      if(signs.indexOf(route.name) === -1){
        getSSLUVList(uv, lrnTrgtGrpMstSn.value);
      }
    });

    watch(() => props.effectNav, () => {visibleProfileNav.value = false;});
    getSSLUVList(uv, lrnTrgtGrpMstSn.value);

    return {
      session,
      lrnTrgtGrpMstSn,
      isMyLrnTrgt,
      isIntro,
      dataBg,
      visibleProfileNav,
      uv,
      isNoneReward,
      goToSignin,
      toggleProfileNav,
      enrollCourse,
      numberComma,
      goBoardSearch,
      searchKeyword,
      isSslSearch,
      deleteKeyword,
    }
  }

}
</script>