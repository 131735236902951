export const holyDay = {
  '20210101': '새해',
  '20210211': '설날',
  '20210212': '설날',
  '20210213': '설날',
  '20210301': '삼일절',
  '20210505': '어린이날',
  '20210519': '석가탄신일',
  '20210606': '현충일',
  '20210815': '광복절',
  '20210920': '추석',
  '20210921': '추석',
  '20210922': '추석',
  '20211003': '개천절',
  '20211009': '한글날',
  '20211225': '크리스마스',

  '20220101': '새해',
  '20220201': '설날',
  '20220202': '설날',
  '20220203': '설날',
  '20220301': '삼일절',
  '20220309': '대통령 선거일',
  '20220505': '어린이날',
  '20220508': '석가탄신일',
  '20220601': '지방 선거일',
  '20220606': '현충일',
  '20220815': '광복절',
  '20220909': '추석',
  '20220910': '추석',
  '20220911': '추석',
  '20220912': '대체공휴일',
  '20221003': '개천절',
  '20221009': '한글날',
  '20221010': '대체공휴일',
  '20221225': '크리스마스',

  '20230101': '새해',
  '20230121': '설날',
  '20230122': '설날',
  '20230123': '설날',
  '20230124': '대체공휴일',
  '20230301': '삼일절',
  '20230501': '근로자의날',
  '20230505': '어린이날',
  '20230527': '석가탄신일',
  '20230529': '대체공휴일',
  '20230606': '현충일',
  '20230815': '광복절',
  '20230928': '추석',
  '20230929': '추석',
  '20230930': '추석',
  '20231002': '임시공휴일',
  '20231003': '개천절',
  '20231009': '한글날',
  '20231225': '크리스마스',

  '20240101': '새해',
  '20240209': '설날',
  '20240210': '설날',
  '20240211': '설날',
  '20240212': '대체공휴일(설날)',
  '20240301': '삼일절',
  '20240410': '국회의원 선거일',
  '20240501': '근로자의날',
  '20240505': '어린이날',
  '20240506': '대체공휴일(어린이날)',
  '20240515': '석가탄신일',
  '20240606': '현충일',
  '20240815': '광복절',
  '20240916': '추석',
  '20240917': '추석',
  '20240918': '추석',
  '20241001': '국군의날',
  '20241003': '개천절',
  '20241009': '한글날',
  '20241225': '크리스마스',

  '20250101': '신정',
  '20250128': '설날',
  '20250129': '설날',
  '20250130': '설날',
  '20250301': '3·1절',
  '20250303': '대체공휴일(3·1절)',
  '20250501': '근로자의날',
  '20250505': '어린이날, 석가탄신일',
  '20250506': '대체공휴일(석가탄신일)',
  '20250606': '현충일',
  '20250815': '광복절',
  '20251003': '개천절',
  '20251005': '추석',
  '20251006': '추석',
  '20251007': '추석',
  '20251008': '대체공휴일(추석)',
  '20251009': '한글날',
  '20251225': '성탄절',

  '20260101': '신정',
  '20260216': '설날',
  '20260217': '설날',
  '20260218': '설날',
  '20260301': '3·1절',
  '20260302': '대체공휴일(3·1절)',
  '20260501': '근로자의날',
  '20260505': '어린이날',
  '20260524': '석가탄신일',
  '20260525': '대체공휴일(석가탄신일)',
  '20260606': '현충일',
  '20260815': '광복절',
  '20260817': '대체공휴일(광복절)',
  '20260924': '추석',
  '20260925': '추석',
  '20260926': '추석',
  '20261003': '개천절',
  '20261005': '대체공휴일(개천절)',
  '20261009': '한글날',
  '20261225': '성탄절'
}

/**
 * 입력한 날짜가 공휴일인지 검사를 한다.
 * 공휴일인 경우에는 경고창 후 멈춘다.
 * 입력형식 : 2023년 11월 21일 --> 20231121
 *
 * @param string
 */
export function isHoliday(yyyymmdd) {
  // 검사년도
  var yyyy = yyyymmdd.substring(0, 4);
  var holidays = new Array();
  // 음력 공휴일을 양력으로 바꾸어서 입력
  var tmp01 = lunerCalenderToSolarCalenger(yyyy + "0101"); // 음력설날
  var tmp02 = lunerCalenderToSolarCalenger(yyyy + "0815"); // 음력추석
  holidays[0] = getDateNextPre(tmp01, "pre", 1); //String(Number(tmp01) - 1); // 음력설 첫째날
  holidays[1] = tmp01;   // 음력설 둘째날
  holidays[2] = getDateNextPre(tmp01, "next", 1);//Number(tmp01) + 1; // 음력설 셋째날
  holidays[3] = getDateNextPre(tmp02, "pre", 1);//Number(tmp02) - 1; // 추석 첫째날
  holidays[4] = tmp02;   // 추석 둘째날
  holidays[5] = getDateNextPre(tmp02, "next", 1);//Number(tmp02) + 1; // 추석 셋째날
  holidays[6] = lunerCalenderToSolarCalenger(yyyy + "0408"); // 석가탄신일

  // 양력 공휴일 입력
  holidays[7] = yyyy + "0101";  // 양력설날
  holidays[8] = yyyy + "0301";  // 삼일절
  holidays[9] = yyyy + "0405";  // 식목일
  holidays[10] = yyyy + "0505";  // 어린이날
  holidays[11] = yyyy + "0606";  // 현충일
  //holidays[12] = yyyy + "0717";  // 제헌절
  holidays[12] = yyyy + "0815";  // 광복절
  holidays[13] = yyyy + "1003";  // 개천절
  holidays[14] = yyyy + "1009";  // 한글날
  holidays[15] = yyyy + "1225";  // 성탄절
  for (var i = 0; i < holidays.length; i++) {
    if (holidays[i] == yyyymmdd) {
      return true;
    }
  }
}




/**
 * 음력을 양력으로 바꾸어서 반환한다.
 *
 * @param string
 * return string
 */
function lunerCalenderToSolarCalenger(input_day) {
  var kk = [[1, 2, 4, 1, 1, 2, 1, 2, 1, 2, 2, 1],   /* 1841 */
    [2, 2, 1, 2, 1, 1, 2, 1, 2, 1, 2, 1],
    [2, 2, 2, 1, 2, 1, 4, 1, 2, 1, 2, 1],
    [2, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2],
    [1, 2, 1, 2, 2, 1, 2, 1, 2, 1, 2, 1],
    [2, 1, 2, 1, 5, 2, 1, 2, 2, 1, 2, 1],
    [2, 1, 1, 2, 1, 2, 1, 2, 2, 2, 1, 2],
    [1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 2, 1],
    [2, 1, 2, 3, 2, 1, 2, 1, 2, 1, 2, 2],
    [2, 1, 2, 1, 1, 2, 1, 1, 2, 2, 1, 2],
    [2, 2, 1, 2, 1, 1, 2, 1, 2, 1, 5, 2],   /* 1851 */
    [2, 1, 2, 2, 1, 1, 2, 1, 2, 1, 1, 2],
    [2, 1, 2, 2, 1, 2, 1, 2, 1, 2, 1, 2],
    [1, 2, 1, 2, 1, 2, 5, 2, 1, 2, 1, 2],
    [1, 1, 2, 1, 2, 2, 1, 2, 2, 1, 2, 1],
    [2, 1, 1, 2, 1, 2, 1, 2, 2, 2, 1, 2],
    [1, 2, 1, 1, 5, 2, 1, 2, 1, 2, 2, 2],
    [1, 2, 1, 1, 2, 1, 1, 2, 2, 1, 2, 2],
    [2, 1, 2, 1, 1, 2, 1, 1, 2, 1, 2, 2],
    [2, 1, 6, 1, 1, 2, 1, 1, 2, 1, 2, 2],
    [1, 2, 2, 1, 2, 1, 2, 1, 2, 1, 1, 2],   /* 1861 */
    [2, 1, 2, 1, 2, 2, 1, 2, 2, 3, 1, 2],
    [1, 2, 2, 1, 2, 1, 2, 2, 1, 2, 1, 2],
    [1, 1, 2, 1, 2, 1, 2, 2, 1, 2, 2, 1],
    [2, 1, 1, 2, 4, 1, 2, 2, 1, 2, 2, 1],
    [2, 1, 1, 2, 1, 1, 2, 2, 1, 2, 2, 2],
    [1, 2, 1, 1, 2, 1, 1, 2, 1, 2, 2, 2],
    [1, 2, 2, 3, 2, 1, 1, 2, 1, 2, 2, 1],
    [2, 2, 2, 1, 1, 2, 1, 1, 2, 1, 2, 1],
    [2, 2, 2, 1, 2, 1, 2, 1, 1, 5, 2, 1],
    [2, 2, 1, 2, 2, 1, 2, 1, 2, 1, 1, 2],   /* 1871 */
    [1, 2, 1, 2, 2, 1, 2, 1, 2, 2, 1, 2],
    [1, 1, 2, 1, 2, 4, 2, 1, 2, 2, 1, 2],
    [1, 1, 2, 1, 2, 1, 2, 1, 2, 2, 2, 1],
    [2, 1, 1, 2, 1, 1, 2, 1, 2, 2, 2, 1],
    [2, 2, 1, 1, 5, 1, 2, 1, 2, 2, 1, 2],
    [2, 2, 1, 1, 2, 1, 1, 2, 1, 2, 1, 2],
    [2, 2, 1, 2, 1, 2, 1, 1, 2, 1, 2, 1],
    [2, 2, 4, 2, 1, 2, 1, 1, 2, 1, 2, 1],
    [2, 1, 2, 2, 1, 2, 2, 1, 2, 1, 1, 2],
    [1, 2, 1, 2, 1, 2, 5, 2, 2, 1, 2, 1],   /* 1881 */
    [1, 2, 1, 2, 1, 2, 1, 2, 2, 1, 2, 2],
    [1, 1, 2, 1, 1, 2, 1, 2, 2, 2, 1, 2],
    [2, 1, 1, 2, 3, 2, 1, 2, 2, 1, 2, 2],
    [2, 1, 1, 2, 1, 1, 2, 1, 2, 1, 2, 2],
    [2, 1, 2, 1, 2, 1, 1, 2, 1, 2, 1, 2],
    [2, 2, 1, 5, 2, 1, 1, 2, 1, 2, 1, 2],
    [2, 1, 2, 2, 1, 2, 1, 1, 2, 1, 2, 1],
    [2, 1, 2, 2, 1, 2, 1, 2, 1, 2, 1, 2],
    [1, 5, 2, 1, 2, 2, 1, 2, 1, 2, 1, 2],
    [1, 2, 1, 2, 1, 2, 1, 2, 2, 1, 2, 2],   /* 1891 */
    [1, 1, 2, 1, 1, 5, 2, 2, 1, 2, 2, 2],
    [1, 1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 2],
    [1, 2, 1, 2, 1, 1, 2, 1, 2, 1, 2, 2],
    [2, 1, 2, 1, 5, 1, 2, 1, 2, 1, 2, 1],
    [2, 2, 2, 1, 2, 1, 1, 2, 1, 2, 1, 2],
    [1, 2, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1],
    [2, 1, 5, 2, 2, 1, 2, 1, 2, 1, 2, 1],
    [2, 1, 2, 1, 2, 1, 2, 2, 1, 2, 1, 2],
    [1, 2, 1, 1, 2, 1, 2, 5, 2, 2, 1, 2],
    [1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 2, 1],   /* 1901 */
    [2, 1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 2],
    [1, 2, 1, 2, 3, 2, 1, 1, 2, 2, 1, 2],
    [2, 2, 1, 2, 1, 1, 2, 1, 1, 2, 2, 1],
    [2, 2, 1, 2, 2, 1, 1, 2, 1, 2, 1, 2],
    [1, 2, 2, 4, 1, 2, 1, 2, 1, 2, 1, 2],
    [1, 2, 1, 2, 1, 2, 2, 1, 2, 1, 2, 1],
    [2, 1, 1, 2, 2, 1, 2, 1, 2, 2, 1, 2],
    [1, 5, 1, 2, 1, 2, 1, 2, 2, 2, 1, 2],
    [1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 2, 1],
    [2, 1, 2, 1, 1, 5, 1, 2, 2, 1, 2, 2],   /* 1911 */
    [2, 1, 2, 1, 1, 2, 1, 1, 2, 2, 1, 2],
    [2, 2, 1, 2, 1, 1, 2, 1, 1, 2, 1, 2],
    [2, 2, 1, 2, 5, 1, 2, 1, 2, 1, 1, 2],
    [2, 1, 2, 2, 1, 2, 1, 2, 1, 2, 1, 2],
    [1, 2, 1, 2, 1, 2, 2, 1, 2, 1, 2, 1],
    [2, 3, 2, 1, 2, 2, 1, 2, 2, 1, 2, 1],
    [2, 1, 1, 2, 1, 2, 1, 2, 2, 2, 1, 2],
    [1, 2, 1, 1, 2, 1, 5, 2, 2, 1, 2, 2],
    [1, 2, 1, 1, 2, 1, 1, 2, 2, 1, 2, 2],
    [2, 1, 2, 1, 1, 2, 1, 1, 2, 1, 2, 2],   /* 1921 */
    [2, 1, 2, 2, 3, 2, 1, 1, 2, 1, 2, 2],
    [1, 2, 2, 1, 2, 1, 2, 1, 2, 1, 1, 2],
    [2, 1, 2, 1, 2, 2, 1, 2, 1, 2, 1, 1],
    [2, 1, 2, 5, 2, 1, 2, 2, 1, 2, 1, 2],
    [1, 1, 2, 1, 2, 1, 2, 2, 1, 2, 2, 1],
    [2, 1, 1, 2, 1, 2, 1, 2, 2, 1, 2, 2],
    [1, 5, 1, 2, 1, 1, 2, 2, 1, 2, 2, 2],
    [1, 2, 1, 1, 2, 1, 1, 2, 1, 2, 2, 2],
    [1, 2, 2, 1, 1, 5, 1, 2, 1, 2, 2, 1],
    [2, 2, 2, 1, 1, 2, 1, 1, 2, 1, 2, 1],   /* 1931 */
    [2, 2, 2, 1, 2, 1, 2, 1, 1, 2, 1, 2],
    [1, 2, 2, 1, 6, 1, 2, 1, 2, 1, 1, 2],
    [1, 2, 1, 2, 2, 1, 2, 2, 1, 2, 1, 2],
    [1, 1, 2, 1, 2, 1, 2, 2, 1, 2, 2, 1],
    [2, 1, 4, 1, 2, 1, 2, 1, 2, 2, 2, 1],
    [2, 1, 1, 2, 1, 1, 2, 1, 2, 2, 2, 1],
    [2, 2, 1, 1, 2, 1, 4, 1, 2, 2, 1, 2],
    [2, 2, 1, 1, 2, 1, 1, 2, 1, 2, 1, 2],
    [2, 2, 1, 2, 1, 2, 1, 1, 2, 1, 2, 1],
    [2, 2, 1, 2, 2, 4, 1, 1, 2, 1, 2, 1],   /* 1941 */
    [2, 1, 2, 2, 1, 2, 2, 1, 2, 1, 1, 2],
    [1, 2, 1, 2, 1, 2, 2, 1, 2, 2, 1, 2],
    [1, 1, 2, 4, 1, 2, 1, 2, 2, 1, 2, 2],
    [1, 1, 2, 1, 1, 2, 1, 2, 2, 2, 1, 2],
    [2, 1, 1, 2, 1, 1, 2, 1, 2, 2, 1, 2],
    [2, 5, 1, 2, 1, 1, 2, 1, 2, 1, 2, 2],
    [2, 1, 2, 1, 2, 1, 1, 2, 1, 2, 1, 2],
    [2, 2, 1, 2, 1, 2, 3, 2, 1, 2, 1, 2],
    [2, 1, 2, 2, 1, 2, 1, 1, 2, 1, 2, 1],
    [2, 1, 2, 2, 1, 2, 1, 2, 1, 2, 1, 2],   /* 1951 */
    [1, 2, 1, 2, 4, 2, 1, 2, 1, 2, 1, 2],
    [1, 2, 1, 1, 2, 2, 1, 2, 2, 1, 2, 2],
    [1, 1, 2, 1, 1, 2, 1, 2, 2, 1, 2, 2],
    [2, 1, 4, 1, 1, 2, 1, 2, 1, 2, 2, 2],
    [1, 2, 1, 2, 1, 1, 2, 1, 2, 1, 2, 2],
    [2, 1, 2, 1, 2, 1, 1, 5, 2, 1, 2, 2],
    [1, 2, 2, 1, 2, 1, 1, 2, 1, 2, 1, 2],
    [1, 2, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1],
    [2, 1, 2, 1, 2, 5, 2, 1, 2, 1, 2, 1],
    [2, 1, 2, 1, 2, 1, 2, 2, 1, 2, 1, 2],   /* 1961 */
    [1, 2, 1, 1, 2, 1, 2, 2, 1, 2, 2, 1],
    [2, 1, 2, 3, 2, 1, 2, 1, 2, 2, 2, 1],
    [2, 1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 2],
    [1, 2, 1, 2, 1, 1, 2, 1, 1, 2, 2, 1],
    [2, 2, 5, 2, 1, 1, 2, 1, 1, 2, 2, 1],
    [2, 2, 1, 2, 2, 1, 1, 2, 1, 2, 1, 2],
    [1, 2, 2, 1, 2, 1, 5, 2, 1, 2, 1, 2],
    [1, 2, 1, 2, 1, 2, 2, 1, 2, 1, 2, 1],
    [2, 1, 1, 2, 2, 1, 2, 1, 2, 2, 1, 2],
    [1, 2, 1, 1, 5, 2, 1, 2, 2, 2, 1, 2],   /* 1971 */
    [1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 2, 1],
    [2, 1, 2, 1, 1, 2, 1, 1, 2, 2, 2, 1],
    [2, 2, 1, 5, 1, 2, 1, 1, 2, 2, 1, 2],
    [2, 2, 1, 2, 1, 1, 2, 1, 1, 2, 1, 2],
    [2, 2, 1, 2, 1, 2, 1, 5, 2, 1, 1, 2],
    [2, 1, 2, 2, 1, 2, 1, 2, 1, 2, 1, 1],
    [2, 2, 1, 2, 1, 2, 2, 1, 2, 1, 2, 1],
    [2, 1, 1, 2, 1, 6, 1, 2, 2, 1, 2, 1],
    [2, 1, 1, 2, 1, 2, 1, 2, 2, 1, 2, 2],
    [1, 2, 1, 1, 2, 1, 1, 2, 2, 1, 2, 2],   /* 1981 */
    [2, 1, 2, 3, 2, 1, 1, 2, 2, 1, 2, 2],
    [2, 1, 2, 1, 1, 2, 1, 1, 2, 1, 2, 2],
    [2, 1, 2, 2, 1, 1, 2, 1, 1, 5, 2, 2],
    [1, 2, 2, 1, 2, 1, 2, 1, 1, 2, 1, 2],
    [1, 2, 2, 1, 2, 2, 1, 2, 1, 2, 1, 1],
    [2, 1, 2, 2, 1, 5, 2, 2, 1, 2, 1, 2],
    [1, 1, 2, 1, 2, 1, 2, 2, 1, 2, 2, 1],
    [2, 1, 1, 2, 1, 2, 1, 2, 2, 1, 2, 2],
    [1, 2, 1, 1, 5, 1, 2, 1, 2, 2, 2, 2],
    [1, 2, 1, 1, 2, 1, 1, 2, 1, 2, 2, 2],   /* 1991 */
    [1, 2, 2, 1, 1, 2, 1, 1, 2, 1, 2, 2],
    [1, 2, 5, 2, 1, 2, 1, 1, 2, 1, 2, 1],
    [2, 2, 2, 1, 2, 1, 2, 1, 1, 2, 1, 2],
    [1, 2, 2, 1, 2, 2, 1, 5, 2, 1, 1, 2],
    [1, 2, 1, 2, 2, 1, 2, 1, 2, 2, 1, 2],
    [1, 1, 2, 1, 2, 1, 2, 2, 1, 2, 2, 1],
    [2, 1, 1, 2, 3, 2, 2, 1, 2, 2, 2, 1],
    [2, 1, 1, 2, 1, 1, 2, 1, 2, 2, 2, 1],
    [2, 2, 1, 1, 2, 1, 1, 2, 1, 2, 2, 1],
    [2, 2, 2, 3, 2, 1, 1, 2, 1, 2, 1, 2],   /* 2001 */
    [2, 2, 1, 2, 1, 2, 1, 1, 2, 1, 2, 1],
    [2, 2, 1, 2, 2, 1, 2, 1, 1, 2, 1, 2],
    [1, 5, 2, 2, 1, 2, 1, 2, 2, 1, 1, 2],
    [1, 2, 1, 2, 1, 2, 2, 1, 2, 2, 1, 2],
    [1, 1, 2, 1, 2, 1, 5, 2, 2, 1, 2, 2],
    [1, 1, 2, 1, 1, 2, 1, 2, 2, 2, 1, 2],
    [2, 1, 1, 2, 1, 1, 2, 1, 2, 2, 1, 2],
    [2, 2, 1, 1, 5, 1, 2, 1, 2, 1, 2, 2],
    [2, 1, 2, 1, 2, 1, 1, 2, 1, 2, 1, 2],
    [2, 1, 2, 2, 1, 2, 1, 1, 2, 1, 2, 1],   /* 2011 */
    [2, 1, 6, 2, 1, 2, 1, 1, 2, 1, 2, 1],
    [2, 1, 2, 2, 1, 2, 1, 2, 1, 2, 1, 2],
    [1, 2, 1, 2, 1, 2, 1, 2, 5, 2, 1, 2],
    [1, 2, 1, 1, 2, 1, 2, 2, 2, 1, 2, 2],
    [1, 1, 2, 1, 1, 2, 1, 2, 2, 1, 2, 2],
    [2, 1, 1, 2, 3, 2, 1, 2, 1, 2, 2, 2],
    [1, 2, 1, 2, 1, 1, 2, 1, 2, 1, 2, 2],
    [2, 1, 2, 1, 2, 1, 1, 2, 1, 2, 1, 2],
    [2, 1, 2, 5, 2, 1, 1, 2, 1, 2, 1, 2],
    [1, 2, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1],   /* 2021 */
    [2, 1, 2, 1, 2, 2, 1, 2, 1, 2, 1, 2],
    [1, 5, 2, 1, 2, 1, 2, 2, 1, 2, 1, 2],
    [1, 2, 1, 1, 2, 1, 2, 2, 1, 2, 2, 1],
    [2, 1, 2, 1, 1, 5, 2, 1, 2, 2, 2, 1],
    [2, 1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 2],
    [1, 2, 1, 2, 1, 1, 2, 1, 1, 2, 2, 2],
    [1, 2, 2, 1, 5, 1, 2, 1, 1, 2, 2, 1],
    [2, 2, 1, 2, 2, 1, 1, 2, 1, 1, 2, 2],
    [1, 2, 1, 2, 2, 1, 2, 1, 2, 1, 2, 1],
    [2, 1, 5, 2, 1, 2, 2, 1, 2, 1, 2, 1],   /* 2031 */
    [2, 1, 1, 2, 1, 2, 2, 1, 2, 2, 1, 2],
    [1, 2, 1, 1, 2, 1, 5, 2, 2, 2, 1, 2],
    [1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 2, 1],
    [2, 1, 2, 1, 1, 2, 1, 1, 2, 2, 1, 2],
    [2, 2, 1, 2, 1, 4, 1, 1, 2, 1, 2, 2],
    [2, 2, 1, 2, 1, 1, 2, 1, 1, 2, 1, 2],
    [2, 2, 1, 2, 1, 2, 1, 2, 1, 1, 2, 1],
    [2, 2, 1, 2, 5, 2, 1, 2, 1, 2, 1, 1],
    [2, 1, 2, 2, 1, 2, 2, 1, 2, 1, 2, 1],
    [2, 1, 1, 2, 1, 2, 2, 1, 2, 2, 1, 2],   /* 2041 */
    [1, 5, 1, 2, 1, 2, 1, 2, 2, 2, 1, 2],
    [1, 2, 1, 1, 2, 1, 1, 2, 2, 1, 2, 2]];
  // var gan = new Array("甲", "乙", "丙", "丁", "戊", "己", "庚", "辛", "壬", "癸");
  // var jee = new Array("子", "丑", "寅", "卯", "辰", "巳", "午", "未", "申", "酉", "戌", "亥");
  // var ddi = new Array("쥐", "소", "범", "토끼", "용", "뱀", "말", "양", "원숭이", "닭", "개", "돼지");
  // var week = new Array("일", "월", "화", "수", "목", "금", "토");
  var md = new Array(31, 0, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31);
  var year = input_day.substring(0, 4);
  var month = input_day.substring(4, 6);
  var day = input_day.substring(6, 8);

  // 음력에서 양력으로 변환
  var lyear, lmonth, lday, leapyes;
  var syear, smonth, sday;
  var mm, y1, y2, m1;
  var i, j;
  var td;
  lyear = get_year(year);        // 년도 check
  lmonth = get_month(month);     // 월 check
  y1 = lyear - 1841;
  m1 = lmonth - 1;
  leapyes = 0;
  var byoonchecked = false;
  if (kk[y1][m1] > 2) {
    if (byoonchecked) {
      leapyes = 1;
      switch (kk[y1][m1]) {
        case 3:
        case 5:
          mm = 29;
          break;
        case 4:
        case 6:
          mm = 30;
          break;
      }
    } else {
      switch (kk[y1][m1]) {
        case 1:
        case 3:
        case 4:
          mm = 29;
          break;
        case 2:
        case 5:
        case 6:
          mm = 30;
          break;
      } // end of switch
    } // end of if
  } // end of if
  lday = get_day(day, mm);
  td = 0;
  for (i = 0; i < y1; i++) {
    for (j = 0; j < 12; j++) {
      switch (kk[i][j]) {
        case 1:
          td = td + 29;
          break;
        case 2:
          td = td + 30;
          break;
        case 3:
          td = td + 58;    // 29+29
          break;
        case 4:
          td = td + 59;    // 29+30
          break;
        case 5:
          td = td + 59;    // 30+29
          break;
        case 6:
          td = td + 60;    // 30+30
          break;
      } // end of switch
    } // end of for
  } // end of for
  for (j = 0; j < m1; j++) {
    switch (kk[y1][j]) {
      case 1:
        td = td + 29;
        break;
      case 2:
        td = td + 30;
        break;
      case 3:
        td = td + 58;    // 29+29
        break;
      case 4:
        td = td + 59;    // 29+30
        break;
      case 5:
        td = td + 59;    // 30+29
        break;
      case 6:
        td = td + 60;    // 30+30
        break;
    } // end of switch
  } // end of for
  if (leapyes == 1) {
    switch (kk[y1][m1]) {
      case 3:
      case 4:
        td = td + 29;
        break;
      case 5:
      case 6:
        td = td + 30;
        break;
    } // end of switch
  } // end of switch
  td = td + parseFloat(lday) + 22;
  // td : 1841 년 1 월 1 일 부터 원하는 날짜까지의 전체 날수의 합
  y1 = 1840;
  do {
    y1 = y1 + 1;
    if ((y1 % 400 == 0) || ((y1 % 100 != 0) && (y1 % 4 == 0))) {
      y2 = 366;
    }
    else {
      y2 = 365;
    }
    if (td <= y2) {
      break;
    }
    else {
      td = td - y2;
    }
  } while (m1 < 1000); // end do-While
  syear = y1;
  md[1] = Number(y2) - 337;
  m1 = 0;
  do {
    m1 = m1 + 1;
    if (td <= md[m1 - 1]) {
      break;
    }
    else {
      td = td - md[m1 - 1];
    }
  } while (m1 < 1000); // end of do-While
  smonth = Number(m1);
  sday = Number(td);
  // 월이 한자리인경우에는 앞에 0을 붙혀서 반환
  if (smonth < 10) {
    smonth = "0" + smonth;
  }
  // 일이 한자리인경우에는 앞에 0을 붙혀서 반환
  if (sday < 10) {
    sday = "0" + sday;
  }
  //return new String(syear + smonth + sday);
  return new String(String(syear) + String(smonth) + String(sday));
}





/**
 * 작성일 : 2023.11.21
 * 년도검사를 한다.
 * 1841~2043년 까지만 검사가 가능하다.
 * 년도가 검사 범위를 벗어나면 경고창 후 멈춘다.
 *
 * @param int
 * @return int
 */
function get_year(src) {
  if ((src < 1841) || (src > 2043)) {
    alert('연도 범위는 1841 ~ 2043 까지입니다.');
    return;
  } else {
    return src;
  }
}




/**
 * 달이 12보다 크거나 1보다 작은지 검사한다.
 * 날짜가 잘못된 경우에는 경고창 후 멈춘다.
 *
 * @param int
 * @return int
 */
function get_month(src) {
  if ((src < 1) || (src > 12)) {
    alert('월 범위는 1 ~ 12 까지입니다.');
    return;
  } else {
    return src;
  }
}




/**
 * 날짜가 1일보다 크고 src보다 작은 경우는 날짜를 반환한다.
 * 날짜가 잘못된 경우에는 경고창 후 멈춘다.
 *
 * @param int
 * @param int
 * @return int
 */
function get_day(src, day) {
  if ((src < 1) || (src > day)) {
    alert('일 범위가 틀립니다.');
    return;
  } else {
    return src;
  }
}




/*
* 날짜의 입력된 일수 만큼의 날짜 return
* pyyyymmdd : 20231121
* pgubn : pre, next
* pday  : 1 2
* return : yyyymmdd
*/
function getDateNextPre(pyyyymmdd, pgubn, pday) {
  var ndate = new Date(pyyyymmdd.substring(0, 4), pyyyymmdd.substring(4, 6), pyyyymmdd.substring(6, 8));
  var chkdate = new Date(pyyyymmdd.substring(0, 4), pyyyymmdd.substring(4, 6), pyyyymmdd.substring(6, 8));
  switch (pgubn) {
    case "pre":
      chkdate.setDate(ndate.getDate() - pday);
      break;
    case "next":
      chkdate.setDate(ndate.getDate() + pday);
      break;
  }
  //날짜포맷맞추기
  var szyyyymmdd = String(chkdate.getFullYear());
  if (String("0" + String(chkdate.getMonth())).length > 2) {
    szyyyymmdd = szyyyymmdd + "" + String(chkdate.getMonth());
  } else {
    szyyyymmdd = szyyyymmdd + "0" + String(chkdate.getMonth());
  }
  if (String("0" + String(chkdate.getDate())).length > 2) {
    szyyyymmdd = szyyyymmdd + "" + String(chkdate.getDate());
  } else {
    szyyyymmdd = szyyyymmdd + "0" + String(chkdate.getDate());
  }
  var rtnDate = szyyyymmdd;
  return rtnDate;
}