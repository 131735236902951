<template>
  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-reinstatement-support">
    <!-- main-header -->
    <div class="main-header">
      <!-- main-header > header-title -->
      <div class="header-title">
        <h2 class="title">휴직자 복직지원</h2>
      </div>
    </div>
    <!-- //main-header -->
    <!-- main-content -->
    <div class="main-content min-component">

      <!-- begin:intro-container -->
      <div class="intro-container">
        <!-- intro-my -->
        <div class="intro-my">
          <div class="my-avatar">
            <ProfileImg :target="session"/>
          </div>
          <div class="my-content">
            <h3 class="my-name">{{ session.lrnerNm }}</h3>
            <p class="my-team">{{ session.jbgdNm }} {{ session.jbpsNm }}</p>
          </div>
          <div class="my-info">
            <div class="info-row">
              <strong class="title">휴직종류</strong>
              <p class="text">{{ item.restRsnNm ? item.restRsnNm : '-'}}</p>
            </div>
            <div class="info-row">
              <strong class="title">휴직일</strong>
              <p class="text">{{ item.strDt ? ymdStrToFormat(item.strDt, '.') : '' }} - {{ (item.endDt && timestampToDateFormat(item.endDt,'yyyy') === '9999') ? ymdStrToFormat(item.endDt, '.') : ''}}</p>
            </div>
          </div>
        </div>
        <!-- intro-board -->
        <div class="intro-board">
          <!-- board-section:공지사항 -->
          <article class="board-section">
            <header class="section-title">
              <h4 class="title">
                공지사항
                <router-link  class="add" :to="{name: 'TakeNotice'}" ><i class="icon-add"></i> 더보기</router-link>
              </h4>

            </header>
            <ul class="board-list">
              <template v-if="noticeTotalCnt > 0">
                <li v-for="(item, idx) in noticeItems" :key="idx" class="board-item">
                  <p class="board-title">
                    <router-link :to="{ name: 'TakeNoticeView', params: {comNtcSn: item.comNtcSn}}" class="link">{{ item.ntcTitle }}</router-link>
                  </p>
                  <span class="board-date">{{ timestampToDateFormat(item.regDt, 'yyyy.MM.dd')}}</span>
                </li>
              </template>
              <li v-else>
                <p class="board-title">
                  <a class="link">등록 된 공지사항이 없습니다.</a>
                </p>
              </li>
            </ul>
          </article>
          <!-- board-section:자료실 -->
          <article class="board-section">
            <header class="section-title">
              <h4 class="title">
               자료실
                <router-link  class="add" :to="{name: 'TakeLib'}" ><i class="icon-add"></i> 더보기</router-link>
              </h4>
            </header>
            <ul class="board-list">
              <template v-if="libTotalCnt > 0">
                <li v-for="(item, idx) in libItems" :key="idx" class="board-item">
                  <p class="board-title">
                    <router-link :to="{name: 'TakeLibView', params: {comPostSn: item.comPostSn}}" class="link">{{ item.postTitle }}</router-link>
                  </p>
                  <span class="board-date">{{ timestampToDateFormat(item.regDt, 'yyyy.MM.dd') }}</span>
                </li>
              </template>
              <li v-else>
                <p class="board-title">
                  <a class="link">등록 된 자료가 없습니다.</a>
                </p>
              </li>
            </ul>
          </article>
        </div>
      </div>
      <!-- end:intro-container -->

    </div>
    <!-- //main-content -->
  </main>
  <!-- end::kb-main -->
</template>

<script>
import {computed, ref} from "vue";
import {useStore} from "vuex";
import ProfileImg from "@/components/common/ProfileImg";
import ApiService from "@/assets/js/api.service";
import {getItem, getItems, lengthCheck, timestampToDateFormat, ymdStrToFormat} from "@/assets/js/util";
import {ACT_GET_HELP_LIB_LIST, ACT_GET_HELP_NOTICE_LIST} from "@/store/modules/help/help";
import {expsrLocCds, libTyCdDcds} from "@/assets/js/modules/help/help-common";

export default {
  name: "TakeMain",
  components: {ProfileImg},
  setup(){
    const store = useStore();
    const session = computed(() => store.state.auth.session);

    const item = ref({});

    const noticeItems = ref([]);
    const noticeTotalCnt = ref(0);

    const libItems = ref([]);
    const libTotalCnt = ref(0);

    const getTakeUser = () => {
      ApiService.get('/v1/app/auth/take').then(res => {
        if(lengthCheck(res)) {
          item.value = getItem(res);
        }
      });
    }

    const getTakeNoticeList = () => {
      store.dispatch(`help/${ACT_GET_HELP_NOTICE_LIST}`, {
        expsrLocCd: expsrLocCds.EXPSR_LOC_CD_TAKE,
        pageSize: 4,
        topFixYn: 'N'
      }).then(res => {
        noticeTotalCnt.value = res.paging.totalCount;
        if (lengthCheck(res)) {
          noticeItems.value = getItems(res);
        } else {
          noticeItems.value = [];
        }
      }).catch(e => {
        console.error(e);
      })
    }

    const getTakeLibList = () => {
      store.dispatch(`help/${ACT_GET_HELP_LIB_LIST}`, {
        libTyCdDcd: libTyCdDcds.LIB_TY_CD_TAKE_LIB,
        pageSize: 2
      }).then(res => {
        libTotalCnt.value = res.paging.totalCount;
        if (lengthCheck(res)) {
          libItems.value = getItems(res);
        } else {
          libItems.value = [];
        }
      }).catch(e => {
        console.error(e);
      })
    }

    getTakeUser();
    getTakeNoticeList();
    getTakeLibList();

    return {
      session,
      item,
      noticeItems,
      noticeTotalCnt,
      libItems,
      libTotalCnt,
      timestampToDateFormat,
      ymdStrToFormat
    }
  }
}
</script>