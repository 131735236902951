<template>
  <LxpMobileHeader title="학습계획">
    <template v-slot:left>
      <div class="util util-back">
        <router-link :to="{name: 'GroupView', params: {distCrseSn: params.distCrseSn}}" class="util-actions util-actions-back">
          <i class="icon-history-back"></i>
        </router-link>
      </div>
    </template>
    <template v-slot:right>
      <div v-if="isLeader" class="util util-apply">
        <a href="javascript:" class="util-actions util-actions-yellow" @click="clickSave">
          저장
        </a>
      </div>
    </template>
  </LxpMobileHeader>
  <main class="kb-main" id="kb-prep-group">
    <!-- main-content -->
    <div class="main-content">
      <div>
        <section class="content-section section-forms">
          <!-- kb-form-fields -->
          <div class="kb-form-fields kb-form-fields-v2">
            <div class="kb-form-field field-first">
              <div class="kb-form-row">
                <label class="kb-form-label">
                  <span class="kb-form-label-text">학습목표</span>
                  <span class="kb-form-byte-text">{{lrnGoalsCnt}} / 500</span>
                </label>
              </div>
              <div class="kb-form-row">
                <textarea v-model="params.lrnGoals" name="" id="" class="kb-form-control" :placeholder="lrnGoalPlaceholder" :readonly="!isLeader"></textarea>
              </div>
            </div>
            <div class="kb-form-field">
              <div class="kb-form-row">
                <label class="kb-form-label">
                  <span class="kb-form-label-text">학습계획</span>
                  <span class="kb-form-byte-text">{{lrnPlanCnt}} / 1000</span>
                </label>
              </div>
              <div class="kb-form-row">
                <textarea v-model="params.lrnPlan" name="" id="" class="kb-form-control" :placeholder="lrnPlanPlaceholder" :readonly="!isLeader"></textarea>
              </div>
            </div>
            <div class="kb-form-field">
              <div class="kb-form-row">
                <label class="kb-form-label">
                  <span class="kb-form-label-text">리더의 노력</span>
                  <span class="kb-form-byte-text">{{lrnEtcCnCnt}} / 1000</span>
                </label>
              </div>
              <div class="kb-form-row">
                <textarea v-model="params.lrnEtcCn" name="" id="" class="kb-form-control" :placeholder="lrnEtcCnPlaceholder" :readonly="!isLeader"></textarea>
              </div>
            </div>
          </div>
          <!-- //kb-form-fields -->
          <div class="kb-form-fields-bottom">
            <span class="text">학습계획은 리더가 언제든지 작성/수정할 수 있습니다.</span>
          </div>
        </section>
      </div>

    </div>

  </main>
</template>

<script>
import LxpMobileHeader from "@/views/layout/lxp/header/mobile/LxpMobileHeader.vue";
import {groupStudyPlanSetup} from "@/assets/js/modules/groupLearing/group-study-plan-setup";

export default {
  name: "MobileGroupStudyPlan",
  components: {LxpMobileHeader},
  setup: groupStudyPlanSetup
}
</script>