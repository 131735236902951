const currentAgent = navigator.userAgent;
// const currentAgent = 'Mozilla/5.0 (IPhone; CPU iPhone OS 13_3 like Mac OS X) AppleWebKit/605.1.15(KHTML, like Gecko) CriOS/80.0.3987.95 Mobile/15E148 Safari/604.1';
// const currentAgent = 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.0 Safari/605.1.15'
// const currentAgent = 'Mozilla/5.0 (Linux; Android 7.0; SM-T585 Build/NRD90M) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/64.0.3282.137 Safari/537.36'

const mobileUtils = {

  isTablet: function() {
    // FOLD 등 native에서 우선 지정한 phone 모드는 tablet 아닌것으로 제공
    if(/devtype=(and-phone|ios-phone)/.test(currentAgent.toLowerCase())){
      // console.log("native setting phone");
      return false;
    }
    return  /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(currentAgent.toLowerCase()) || (currentAgent.match(/Macintosh/i) && 'ontouchend' in document);

  },
  Android: function() {
    return currentAgent.match(/Android/i);
  },
  BlackBerry: function() {
    return currentAgent.match(/BlackBerry|BB10/i);
  },
  iOS: function() {
    // return (navigator.userAgent.match(/iPhone|iPad|iPod/i) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) && !window.MSStream;
    return (currentAgent.match(/iPhone|iPad|iPod|ios-tablet/i) || navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) && !window.MSStream;
    // return (currentAgent.match(/iPhone|iPad|iPod/i));
  },
  Opera: function() {
    return currentAgent.match(/Opera Mini/i);
  },
  Windows: function() {
    return currentAgent.match(/IEMobile/i);
  },
  any: function() {
    // 테블릿이 아니고, 모바일만 디바이스만
    return !this.isTablet() && (this.Android() || this.BlackBerry() || this.iOS() || this.Opera() || this.Windows());
  },
  isApplePortable: function() {
    return (currentAgent.match(/iPhone|iPad|iPod|ios-tablet/i) || currentAgent.match(/Macintosh/i) && 'ontouchend' in document) && !window.MSStream;
  },
  isAppleTablet: function() {
    return (currentAgent.match(/iPad|ios-tablet/i) || currentAgent.match(/Macintosh/i) && 'ontouchend' in document) && !window.MSStream;
  }
}

export const isInApp = (navigator && currentAgent && currentAgent.indexOf('eHRD_app') > -1);

export const isInAppAnd = (navigator && currentAgent.indexOf('eHRD_app_devtype=and') > -1);
export const isInAppIOS = (navigator && currentAgent.indexOf('eHRD_app_devtype=ios') > -1);
export const isInAppFlutter = (navigator && currentAgent.indexOf('eHRD_app_devtype=flutter-') > -1);

export default mobileUtils;