<template>

  <div  v-if="feedback" ref="dom" class="layer-container" :class="{ 'is-active' : feedback }" style="background-color:unset;    margin-top: -5em;">
    <div class="layer" id="layer-completed" style="padding:0px; height: 450px !important; background-color: rgba(247,244,240,0.85);">
      <div v-if="feedback_que">
      <div class="layer-contents" style="padding-top: 40px !important;">
        <strong class="layer-title">학습완료! 축하합니다.</strong>
        <div style="padding-top: 15px; padding-bottom: 15px !important; font-family: var(--kb-font-KBFGText); font-size: 20px;">
          본 콘텐츠의 난이도는 <span v-html="levelString" style="font-size: 24px; font-family: var(--kb-font-KBFGDisplayB);"></span> 입니다.<br/>
          여러분이 직접 난이도를 선택한다면?
        </div>

        <div class="layer-icon"><img src="../../assets/lxp/images/icon/level_feedback_que.svg" alt="" style="max-width: 120px; height:90% !important;">
        </div>

<!--      <button class="layer-close-btn" @click="handleClose"><i class="icon-close"></i></button>-->
        <div class="kb-form-column flex-fill" style="margin-top:1em;">
          <div class="kb-form-check-group">
            <div class="kb-form-check kb-form-check-inline">
              <input type="radio"  class="kb-form-check-input" id="chk_group01_01" name="chk_group01" value="1" v-model="userFeedback" />
              <label for="chk_group01_01" class="kb-form-check-label"><span class="text">초급</span></label>
            </div>
            <div class="kb-form-check kb-form-check-inline">
              <input type="radio"  class="kb-form-check-input" id="chk_group01_02" name="chk_group01" value="3" v-model="userFeedback"  />
              <label for="chk_group01_02" class="kb-form-check-label"><span class="text">중급</span></label>
            </div>
            <div class="kb-form-check kb-form-check-inline">
              <input type="radio"  class="kb-form-check-input" id="chk_group01_03" name="chk_group01" value="5" v-model="userFeedback" />
              <label for="chk_group01_03" class="kb-form-check-label"><span class="text">고급</span></label>
            </div>
          </div>
        </div>
        <div class="popup-buttons" style="margin-top: 1em;">
          <button class="kb-btn kb-btn-primary kb-btn-lg" @click="submitFeedback"><span class="text">확인</span></button>
        </div>
        </div>
      </div>
      <!-- feedback 완료 -->
      <div v-else>
        <div class="layer-contents">
          <div class="layer-icon"><img src="../../assets/lxp/images/icon/level_feedback_complete.svg" alt="" style="max-width: 120px; height:90% !important;">
          </div>
          <strong class="layer-title">제출완료! 감사합니다.</strong>
<!--          <div style="padding-top: 15px; padding-bottom: 15px !important; font-family: var(&#45;&#45;kb-font-KBFGText); font-size: 20px;">-->
<!--            HRD클라우드 11월 이벤트-->
<!--          </div>-->

          <button class="layer-close-btn" @click="handleClose"><i class="icon-close"></i></button>
        </div>
      </div>


    </div>
  </div>

  <div v-else-if="show" ref="dom" class="layer-container" :class="{ 'is-active' : show }" style="background-color:unset;">
    <div class="layer" id="layer-completed" style="padding:0px">
      <div class="layer-contents">
        <div class="layer-icon"><img src="../../assets/lxp/images/icon/icon_completed.svg" alt=""></div>
        <strong class="layer-title">학습완료! 축하합니다.</strong>
      </div>
      <button class="layer-close-btn" @click="handleClose"><i class="icon-close"></i></button>
    </div>
  </div>
</template>

<script>
import {computed, ref, watch} from 'vue';
import {ACT_INSERT_HRDCLOUD_LVL_FEEDBACK} from "@/store/modules/hrdcloud/hrdcloud";
import {isSuccess} from "@/assets/js/util";
import {useStore} from "vuex";
import {useAlert} from "@/assets/js/modules/common/alert";


export default {
  name: 'HrdCloudLrnComplete',
  props: {
    modelValue: [Boolean, Object],
    wrapperDom: Object,
    hrdObj: Object,
  },
  emits: ['update:modelValue','update:wrapperDom'],
  setup(props, {emit}) {
    const store = useStore();

    const show = computed(() => {
      if (props.modelValue instanceof Object) return props.modelValue.show;
      else return props.modelValue;
    });
    const dom = ref(null);
    const {showMessage} = useAlert()
    const userFeedback = ref(null);
    const feedback_que = ref(true);

    const feedback = computed(() => {
      if (props.modelValue instanceof Object) return props.modelValue.feedback;
      else return false;
    });

    const handleClose = () => {
      if (props.modelValue && props.modelValue.toggle) {
        props.modelValue.toggle(false);
      } else {
        emit('update:modelValue', false);
      }
    };

    const levelString = computed(()=>{
      let lvlStr ={
        "1": "초급",
        "3": "중급",
        "5": "고급"
      };

      return props.hrdObj.cttDifi ? lvlStr[props.hrdObj.cttDifi] : "미분류";
    });


    const submitFeedback = async () =>{

      if(userFeedback.value){
        await store.dispatch(`hrdcloud/${ACT_INSERT_HRDCLOUD_LVL_FEEDBACK}`, {
          cttMstSn: props.hrdObj.objSn,
          lrnObjDtlDistSn: props.hrdObj.lrnObjDtlDistSn,
          params: {
            lvl: userFeedback.value,
            cmnt: "학습자 난이도 평가",

          },
        }).then(res => {
          if(isSuccess(res)){
            feedback_que.value = false;
          }else{
            feedback_que.value = true;
            showMessage("평가 제출 중 오류입니다. 다시 시도 해 주세요!!");
            return;
          }
        })
      }else{
        showMessage('난이도를 선택 해 주세요!!');
        return;
      }

    };


    watch(() => dom.value, () => {
      if (dom.value) {
        emit('update:wrapperDom',dom.value);
      }
    });

    return {
      dom, handleClose, show, feedback, levelString, submitFeedback, userFeedback, feedback_que
    }
  }
};
</script>
<style scoped>
div{ color:#151515}
.lxp-layout .layer-container .text {font-size: 24px; font-family: var(--kb-font-KBFGDisplayB);}
</style>