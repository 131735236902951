// import LxpLayout from "@/views/layout/lxp/LxpLayout";
// import navigationUtils from "@/assets/js/navigationUtils";

const wiseRoutes = [
    {
        path: "/wise",
        children: [
            {
                path: "trust",
                name: "KbTrust",
                component: () => import("../../views/pages/wise/KbTrust.vue"),
            },
        ],
    },
];

export const setWiseRoutes = (routes) => {

    routes.push(...wiseRoutes);

};
