<template>
  <!-- main-content -->
  <div v-if="!isActive" class="main-content main-component">
    <div class="support-view-container">
      <div class="view-contents">
        <!-- section: 갱신 대상 자격증 -->
        <section class="content-section">
          <header class="section-header">
            <h4 class="title">갱신 대상 자격증</h4>
            <small>총 {{ paging.totalCount }}건</small>
          </header>
          <template v-if="!isLoading && (items && items.length > 0)">
            <div v-for="(item,idx) in items" class="section-content" :key="idx">
              <div class="content-item">
                <h5 class="title">{{ item.lcsnNm }}</h5>
                <div class="desc">
                  <div class="separate-texts">
                    <!-- 등재완료 : is-complete, 수료 : is-completion, 승인요청중 : is-progress -->
                    <strong v-if="item.stt === '81'" class="is-progress">승인요청중</strong>
                    <span v-else-if="item.stt === '00'" class="is-complete">등재완료</span>
                    <span v-else-if="item.stt === '78'">반려</span>
                    <span v-else>-</span>
                    <span>{{item.grd1Cd ? item.grd1Cd+'등급' : '-'}}</span>
                    <span>{{ item.pblcnInstNm }}</span>
                    <span>{{ item.lcsnRegYmd ? item.lcsnRegYmd+' 자격적용' : '-'}}</span>
                    <!--                    <span>110672</span>-->
                    <span>{{ item.lcsnCd }}</span>
                    <span>지원금 {{ item.sprtAmt || 0 }}</span>
                    <span>신청금 {{ item.updtCost || 0 }}</span>
                  </div>
                  <div class="renew-btn-cont">
                    <button class="renew-btn" @click="renewLicense(idx)">갱신하기</button>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="search-container">
              <div class="result-empty">
                <img src="../../../assets/lxp/images/common/img_empty.png" alt="">
                <p class="text">갱신대상 자격증이 없습니다.</p>
              </div>
            </div>
          </template>
        </section>
        <!-- section -->
        <section class="content-section">
          <header class="section-header">
            <h4 class="title">지원대상</h4>
          </header>
          <div class="section-content">
            <div class="content-item">
              <div class="desc">
                <p>지급일 현재 재직중인 직원 (일반/계약)</p>
                <p class="sub-text">※ 지급일 현재 정직자 및 파트타이머 제외</p>
              </div>
            </div>
          </div>
        </section>
        <!-- section -->
        <section class="content-section">
          <header class="section-header">
            <h4 class="title">신청기한</h4>
          </header>
          <div class="section-content">
            <div class="content-item">
              <div class="desc">
                <span><span style="font-weight: bold">갱신비 납부일</span>로부터 <span class="text-red">1년 이내</span></span>
                <p class="sub-text">입행 전 발생한 갱신비용은 신청기한과 관계없이 지원 불가</p>
              </div>
            </div>
          </div>
        </section>



        <section class="content-section">
          <header class="section-header">
            <h4 class="title">지원 절차 및 시기</h4>
          </header>
          <div class="section-content">
            <div class="content-item">
              <h5 class="b-title">Step1.</h5>
              <p class="desc">자격증 갱신 후 필요서류 준비</p>
            </div>
            <div class="content-item">
              <h5 class="b-title">Step2.</h5>
              <p class="desc">스타런에서 신청</p>
            </div>
            <div class="content-item">
              <h5 class="b-title">Step3.</h5>
              <p class="desc">HR부(인재개발) 심사</p>
            </div>
            <div class="content-item">
              <h5 class="b-title">Step4.</h5>
              <p class="desc">급여계좌로 지급(전산 신청일 기준 <span class="text-red">익월 15일경</span>)</p>
            </div>
          </div>
        </section>
        <!-- section -->
        <section class="content-section">
          <header class="section-header">
            <h4 class="title">지원내용</h4>
          </header>
          <div class="section-content">
            <div class="content-item">
              <div class="accordion" :class="{'is-active' : toggle.a}">
                <a class="accordion-toggle-action" @click="clickToggle('partA')">
                  <div class="accordion-row">
                    <div class="accordion-column">
                      <div class="accordion-title">갱신비용<sup>주1)</sup> 지원대상 등급 자격증 현황</div>
                    </div>
                    <div class="accordion-icon"><i class="icon-arrow"></i></div>
                  </div>
                </a>
                <div class="accordion-target">
                  <p class="title mt-3 mb-2">2023년 8월 기준</p>
                  <div class="kb-table kb-table-v2">
                    <table>
                      <colgroup>
                        <col />
                        <col style="width:17%;" />
                        <col style="width:17%;" />
                        <col style="width:17%;" />
                        <col style="width:17%;" />
                      </colgroup>
                      <thead>
                      <tr>
                        <th class="px-1"><span class="th-title fs-8">자격증명</span></th>
                        <th class="px-1"><span class="th-title fs-8">자격증코드</span></th>
                        <th class="px-1"><span class="th-title fs-8">등급</span></th>
                        <th class="px-1"><span class="th-title fs-8">갱신주기(년)</span></th>
                        <th class="px-1"><span class="th-title fs-8">갱신비용(원)</span></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td><span class="td-text fs-8">국제공인재무분석사(CFA)</span></td>
                        <td><span class="td-text fs-8">WR70</span></td>
                        <td><span class="td-text fs-8">A</span></td>
                        <td><span class="td-text fs-8">1</span></td>
                        <td><span class="td-text fs-8">USD350</span></td>
                      </tr>
                      <tr>
                        <td><span class="td-text fs-8">기술사</span></td>
                        <td><span class="td-text fs-8">WA01</span></td>
                        <td><span class="td-text fs-8">A</span></td>
                        <td><span class="td-text fs-8">5</span></td>
                        <td><span class="td-text fs-8">50,000</span></td>
                      </tr>
                      <tr>
                        <td><span class="td-text fs-8">CAMS</span></td>
                        <td><span class="td-text fs-8">WR9A</span></td>
                        <td><span class="td-text fs-8">B</span></td>
                        <td><span class="td-text fs-8">3</span></td>
                        <td><span class="td-text fs-8">USD250</span></td>
                      </tr>
                      <tr>
                        <td><span class="td-text fs-8">건축사</span></td>
                        <td><span class="td-text fs-8">AA06</span></td>
                        <td><span class="td-text fs-8">B</span></td>
                        <td><span class="td-text fs-8">5</span></td>
                        <td><span class="td-text fs-8">100,000</span></td>
                      </tr>
                      <tr>
                        <td><span class="td-text fs-8">공인국제제재전문가(CGSS)</span></td>
                        <td><span class="td-text fs-8">WR3I</span></td>
                        <td><span class="td-text fs-8">B</span></td>
                        <td><span class="td-text fs-8">3</span></td>
                        <td><span class="td-text fs-8">USD250</span></td>
                      </tr>
                      <tr>
                        <td><span class="td-text fs-8">공인대체투자분석사(CAIA)</span></td>
                        <td><span class="td-text fs-8">WR7L</span></td>
                        <td><span class="td-text fs-8">B</span></td>
                        <td><span class="td-text fs-8">1</span></td>
                        <td><span class="td-text fs-8">USD100</span></td>
                      </tr>
                      <tr>
                        <td><span class="td-text fs-8">국제공인내부감사사(CIA)</span></td>
                        <td><span class="td-text fs-8">WR84</span></td>
                        <td><span class="td-text fs-8">B</span></td>
                        <td><span class="td-text fs-8">1</span></td>
                        <td><span class="td-text fs-8">USD120</span></td>
                      </tr>
                      <tr>
                        <td><span class="td-text fs-8">국제공인재무설계사(CFP)</span></td>
                        <td><span class="td-text fs-8">WR71</span></td>
                        <td><span class="td-text fs-8">B</span></td>
                        <td><span class="td-text fs-8">2</span></td>
                        <td><span class="td-text fs-8">220,000</span></td>
                      </tr>
                      <tr>
                        <td><span class="td-text fs-8">美공인회계사(AICPA)</span></td>
                        <td><span class="td-text fs-8">WR53</span></td>
                        <td><span class="td-text fs-8">B</span></td>
                        <td><span class="td-text fs-8">3</span></td>
                        <td><span class="td-text fs-8">USD200</span></td>
                      </tr>
                      <tr>
                        <td><span class="td-text fs-8">美변호사(LL.M)</span></td>
                        <td><span class="td-text fs-8">WR58</span></td>
                        <td><span class="td-text fs-8">B</span></td>
                        <td><span class="td-text fs-8">2</span></td>
                        <td><span class="td-text fs-8">USD375</span></td>
                      </tr>
                      <tr>
                        <td><span class="td-text fs-8">국제거래용 은행보증서 실무전문가(CSDG)</span></td>
                        <td><span class="td-text fs-8">WR7M</span></td>
                        <td><span class="td-text fs-8">C</span></td>
                        <td><span class="td-text fs-8">3</span></td>
                        <td><span class="td-text fs-8">GBP200</span></td>
                      </tr>
                      <tr>
                        <td><span class="td-text fs-8">국제공인 무역금융 컴플라이언스(CTFC)</span></td>
                        <td><span class="td-text fs-8">WR9C</span></td>
                        <td><span class="td-text fs-8">C</span></td>
                        <td><span class="td-text fs-8">3</span></td>
                        <td><span class="td-text fs-8">GBP200</span></td>
                      </tr>
                      <tr>
                        <td><span class="td-text fs-8">국제공인신용장전문가(CDCS)</span></td>
                        <td><span class="td-text fs-8">WR4D</span></td>
                        <td><span class="td-text fs-8">C</span></td>
                        <td><span class="td-text fs-8">3</span></td>
                        <td><span class="td-text fs-8">GBP200</span></td>
                      </tr>
                      <tr>
                        <td><span class="td-text fs-8">금융투자분석사</span></td>
                        <td><span class="td-text fs-8">WR3A</span></td>
                        <td><span class="td-text fs-8">C</span></td>
                        <td><span class="td-text fs-8">2</span></td>
                        <td><span class="td-text fs-8">34,000</span></td>
                      </tr>
                      <tr>
                        <td><span class="td-text fs-8">재무위험관리사(FRM,금융투자협회)</span></td>
                        <td><span class="td-text fs-8">WR68</span></td>
                        <td><span class="td-text fs-8">C</span></td>
                        <td><span class="td-text fs-8">2</span></td>
                        <td><span class="td-text fs-8">34,000</span></td>
                      </tr>
                      <tr>
                        <td><span class="td-text fs-8">정보시스템관리사</span></td>
                        <td><span class="td-text fs-8">WR0J</span></td>
                        <td><span class="td-text fs-8">C</span></td>
                        <td><span class="td-text fs-8">5</span></td>
                        <td><span class="td-text fs-8">12,000</span></td>
                      </tr>
                      <tr>
                        <td><span class="td-text fs-8">정보시스템감사사(CISA)</span></td>
                        <td><span class="td-text fs-8">NR04</span></td>
                        <td><span class="td-text fs-8">C</span></td>
                        <td><span class="td-text fs-8">1</span></td>
                        <td><span class="td-text fs-8">USD85</span></td>
                      </tr>
                      <tr>
                        <td><span class="td-text fs-8">투자자산운용사</span></td>
                        <td><span class="td-text fs-8">WR2Y</span></td>
                        <td><span class="td-text fs-8">C</span></td>
                        <td><span class="td-text fs-8">2</span></td>
                        <td><span class="td-text fs-8">38,000</span></td>
                      </tr>
                      <tr>
                        <td><span class="td-text fs-8">한국재무설계사(AFPK,한국 FPSB)</span></td>
                        <td><span class="td-text fs-8">WR67</span></td>
                        <td><span class="td-text fs-8">C</span></td>
                        <td><span class="td-text fs-8">2</span></td>
                        <td><span class="td-text fs-8">66,000</span></td>
                      </tr>
                      <tr>
                        <td><span class="td-text fs-8">AWS Solution Archict-Professional</span></td>
                        <td><span class="td-text fs-8">WR3L</span></td>
                        <td><span class="td-text fs-8">D</span></td>
                        <td><span class="td-text fs-8">3</span></td>
                        <td><span class="td-text fs-8">USD150</span></td>
                      </tr>
                      <tr>
                        <td><span class="td-text fs-8">경영지도사</span></td>
                        <td><span class="td-text fs-8">WR4C</span></td>
                        <td><span class="td-text fs-8">D</span></td>
                        <td><span class="td-text fs-8">5</span></td>
                        <td><span class="td-text fs-8">50,000</span></td>
                      </tr>
                      <tr>
                        <td><span class="td-text fs-8">공인기능점수전문가(CFPS)</span></td>
                        <td><span class="td-text fs-8">WR0W</span></td>
                        <td><span class="td-text fs-8">D</span></td>
                        <td><span class="td-text fs-8">3</span></td>
                        <td><span class="td-text fs-8">EUR235</span></td>
                      </tr>
                      <tr>
                        <td><span class="td-text fs-8">애자일전문가(ACP)</span></td>
                        <td><span class="td-text fs-8">WR3J</span></td>
                        <td><span class="td-text fs-8">D</span></td>
                        <td><span class="td-text fs-8">3</span></td>
                        <td><span class="td-text fs-8">USD150</span></td>
                      </tr>
                      <tr>
                        <td><span class="td-text fs-8">정보시스템보안전문가(CISSP)</span></td>
                        <td><span class="td-text fs-8">WR0C</span></td>
                        <td><span class="td-text fs-8">D</span></td>
                        <td><span class="td-text fs-8">3</span></td>
                        <td><span class="td-text fs-8">USD125</span></td>
                      </tr>
                      <tr>
                        <td><span class="td-text fs-8">프로젝트관리전문가(PMP/P.M.I)</span></td>
                        <td><span class="td-text fs-8">HR18</span></td>
                        <td><span class="td-text fs-8">D</span></td>
                        <td><span class="td-text fs-8">3</span></td>
                        <td><span class="td-text fs-8">USD150</span></td>
                      </tr>
                      <tr>
                        <td><span class="td-text fs-8">AWS Solution Archict-Associate</span></td>
                        <td><span class="td-text fs-8">WR6U</span></td>
                        <td><span class="td-text fs-8">E</span></td>
                        <td><span class="td-text fs-8">3</span></td>
                        <td><span class="td-text fs-8">USD75</span></td>
                      </tr>
                      <tr>
                        <td><span class="td-text fs-8">CCNA</span></td>
                        <td><span class="td-text fs-8">WR9Q</span></td>
                        <td><span class="td-text fs-8">E</span></td>
                        <td><span class="td-text fs-8">3</span></td>
                        <td><span class="td-text fs-8">USD295</span></td>
                      </tr>
                      <tr>
                        <td><span class="td-text fs-8">LPIC-1</span></td>
                        <td><span class="td-text fs-8">WR6T</span></td>
                        <td><span class="td-text fs-8">E</span></td>
                        <td><span class="td-text fs-8">5</span></td>
                        <td><span class="td-text fs-8">USD188</span></td>
                      </tr>
                      <tr>
                        <td><span class="td-text fs-8">기술지도사</span></td>
                        <td><span class="td-text fs-8">NR02</span></td>
                        <td><span class="td-text fs-8">E</span></td>
                        <td><span class="td-text fs-8">5</span></td>
                        <td><span class="td-text fs-8">50,000</span></td>
                      </tr>
                      <tr>
                        <td><span class="td-text fs-8">신용관리사</span></td>
                        <td><span class="td-text fs-8">WR95</span></td>
                        <td><span class="td-text fs-8">E</span></td>
                        <td><span class="td-text fs-8">3</span></td>
                        <td><span class="td-text fs-8">3,000</span></td>
                      </tr>
                      <tr>
                        <td><span class="td-text fs-8">증권투자권유자문인력</span></td>
                        <td><span class="td-text fs-8">WR85</span></td>
                        <td><span class="td-text fs-8">E</span></td>
                        <td><span class="td-text fs-8">2</span></td>
                        <td><span class="td-text fs-8">34,000</span></td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="desc">
                <p class="sub-text">주1) 갱신과 직접적인 관련이 없는 연회비, 연체료, 계속학점취득비(세미나 참석으로 대체가능한 학점취득비) 등은 지원금액에서 제외됨</p>
              </div>
            </div>
          </div>
        </section>
        <!-- section -->
        <!-- section -->
        <section class="content-section">
          <header class="section-header">
            <h4 class="title">필요서류</h4>
          </header>
          <div class="section-content">
            <div class="content-item">
              <div class="desc">
                <p>1. 갱신 자격증, 청구서, 수료증 등(자격증별 상이)</p>
                <p>2. 결제 영수증 - 본인 명의 KB신용・체크카드(가족愛복지 포함) 결제분만 인정</p>
                <p class="sub-text">※ AFPK 및 CFP는 납부증빙서류 첨부 불필요</p>
              </div>
            </div>
          </div>
        </section>
        <!-- section -->

        <section class="content-section">
          <header class="section-header">
            <h4 class="title">유의사항</h4>
          </header>
          <div class="section-content">
            <div class="content-item">
              <div class="desc">
                <p>- 갱신비용 지원은 투명하고 정당한 절차에 의한 방법으로 지원받으시기 바랍니다.</p>
                <p>- 지급된 갱신비는 기소득 처리 대상으로 HR부(인재개발)에서 일괄 과표 처리합니다.</p>
              </div>
            </div>
          </div>
        </section>
        <!-- section -->
        <section class="content-section">
          <header class="section-header">
            <h4 class="title">AFPK/CFP 윤리교육</h4>
          </header>
          <div class="section-content">
            <div class="content-item">
              <p class="warn">해당 자격증 갱신은 한국FPSB의 윤리교육/계속교육(무료수강) 이수 후<br/>신청 가능합니다. <br/>자세한 사항은 아래 링크(외부망에서만 접속가능)를 참고하시기 바랍니다.('22.12.27~)</p>
            </div>
            <div class="content-item">
              <div class="contact">
                <div class="left">
                  <h5 class="title">한국FPSB</h5>
                  <p class="name">AFPK 자격갱신안내</p>
                  <p class="sub-text"></p>
                </div>
                <div class="right">
                  <button>
                    <a href="https://fpsbkorea.org/?mnu_usn=53" target="_blank">바로가기</a>
                  </button>
                </div>
              </div>
            </div>
            <div class="content-item">
              <div class="contact">
                <div class="left">
                  <h5 class="title">한국FPSB</h5>
                  <p class="name">CFP 자격갱신안내</p>
                  <p class="sub-text"></p>
                </div>
                <div class="right">
                  <button>
                    <a href="https://fpsbkorea.org/?mnu_usn=54" target="_blank" >바로가기</a>
                  </button>
                </div>
              </div>
            </div>
            <div class="content-item">
              <div class="contact">
                <div class="left">
                  <h5 class="title">한국FPSB</h5>
                  <p class="name">계속교육학점인정기준</p>
                  <p class="sub-text"></p>
                </div>
                <div class="right">
                  <button>
                    <a href="https://fpsbkorea.org/?mnu_usn=124" target="_blank">바로가기</a>
                  </button>
                </div>
              </div>
            </div>
            <div class="content-item">
              <div class="contact">
                <div class="left">
                  <h5 class="title">한국FPSB</h5>
                  <p class="name">교육센터</p>
                  <p class="sub-text"></p>
                </div>
                <div class="right">
                  <button>
                    <a href="https://on.fpsbkorea.org/course/course_list.jsp" target="_blank">바로가기</a>
                  </button>
                </div>
              </div>
            </div>
            <div class="content-item">
              <p class="warn">※기존 HRD클라우드에서 제공되던 윤리교육 영상은 미제공</p>
            </div>
          </div>
        </section>
      </div>
    </div>
    <LoadingDiv v-if="isLoading" />
  </div>

  <!-- //main-content -->
  <SupportLicenseRenewMobileModal
      v-if="isActive"
      :data="items[idx]"
      mode="renewal"
  />
</template>

<script>
import {computed, reactive, ref} from 'vue';
import {getDsjChk, getExprYear, getLcsnRenewList2, getRefundChk} from '@/assets/js/modules/support/support-common';
import {
  dateToDateFormat,
  getItems,
  goToTop,
  timestampToDateFormat,
  ymdStrToFormat
} from '@/assets/js/util';
import LoadingDiv from '@/components/common/LoadingDiv';
import SupportLicenseRenewMobileModal from "@/components/support/mobile/SupportLicenseRenewMobileModal";
import {MUT_SHOW_SUPPORT} from "@/store/modules/support/support";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {useAlert} from "@/assets/js/modules/common/alert";

export default {
  name: 'SupportLcnsMobileRenewal',
  components: {SupportLicenseRenewMobileModal, LoadingDiv},
  setup() {
    const store = useStore();

    const router = useRouter();
    const isLoading =ref(true);
    const items = ref([]);
    const paging = reactive({pageNo: 1, pageSize: 10, totalCount: 0, hasMore: false});
    const param = ref(null);
    const today = computed(() => new Date());
    const idx = ref(0);
    const {showMessage} = useAlert();

    const appDsjYn = ref(0);
    const refndYn = ref(0);

    const toggle = reactive({
      a: false,
    })

    const clickToggle = (part) => {
      if(part == 'partA') toggle.a = !toggle.a;
    }

    const chkAppDsj = () => {
      let chkVal = getDsjChk();
      appDsjYn.value = getItems(chkVal);
    }
    const chkRefund = () => {
      let chkVal = getRefundChk();
      refndYn.value = getItems(chkVal);
    }

    const renewLicense = (index) => {
      if (appDsjYn.value > 0){
        showMessage('자격증 갱신비 지원 신청 대상이 아닙니다.');
        return false;
      }else {
        if (refndYn.value > 0){
          showMessage(`연수과정 미수료에 의한 <br>교육훈련비 환입 대상자 입니다.<br>환입 완료 전까지 <span style=color:#FF0000!important;>연수신청(자기주도학습지원 포함)이 제한</span>됩니다.<br><br> 연수비용 환입은 HR부(인재개발)<br>최슬비대리(☎7880)에게<br>문의 부탁드립니다.`);
          return false;
        }else {
          if(items.value[index].costLcsnSn > 0) {
            showMessage('동일 자격증으로 종료되지 않은 신청내역이 있습니다.<br>완료 후 신청해 주시기 바랍니다.');
            return false;
          }else{
            idx.value = index;
            items.value[index].updtVldYmd = null;
            store.commit(`support/${MUT_SHOW_SUPPORT}`);
            goToTop('app', 100);
          }
        }
      }
    }

    const isActive = computed(() => store.state.support.isSupport);
    const isLicenseMain = computed(() => store.state.support.isLicenseMain);

    const goBack = () => {
      router.go(-1);
    }

    const convertTime = (timestamp) => {
      if (timestamp) {
        return timestampToDateFormat(timestamp, 'yyyy.MM.dd');
      } else {
        return '';
      }
    };

    const getList = () => {
      try {
        getLcsnRenewList2(items, paging, () => {
          items.value = items.value.map(x => {
            const now = dateToDateFormat(today.value, 'yyyyMMdd');
            // 유효 연도
            const exprYear = getExprYear(x.lcsnCd);
            if (x.lcsnRegYmd && exprYear > 0) {
              const regYmd = new Date(ymdStrToFormat(x.lcsnRegYmd, '-'));
              const exprYmd = new Date(regYmd).setFullYear(regYmd.getFullYear() + exprYear);
              if (now >= timestampToDateFormat(exprYmd, 'yyyyMMdd')) {
                x.isExpired = true;
              }
            }
            return x;
          });
          isLoading.value = false;
        });
      } catch {
        isLoading.value = false;
      }
    };

    getList();
    chkAppDsj();
    chkRefund();

    return {
      isLoading, items, paging, param,idx,
      renewLicense,
      convertTime, getList,goBack,isActive, isLicenseMain,
      toggle, clickToggle,
    }
  }
};
</script>