<template>
  <div v-if="confirm.show" class="kb-alert-container" :class="{'is-active': confirm.show}">
    <div v-if="confirm.show" class="kb-alert">
      <div class="kb-alert-contents">
        <strong class="kb-alert-title" v-html="confirm.title"></strong>
        <p class="kb-alert-text" v-html="confirm.text"></p>
      </div>
      <div class="kb-alert-actions">
        <button @click="okConfirm" class="kb-btn-alert"><span class="text" v-html="confirm.okBtnTitle"></span></button>
        <button @click="cancelConfirm" class="kb-btn-alert"><span class="text">취소</span></button>
      </div>
    </div>
  </div>
</template>
<style scoped>
.lxp-mobile-layout .alert-container .alert-contents {justify-content: center;}
</style>
<script>
import {useStore} from 'vuex';
import {computed} from 'vue';
import {MUT_CLOSE_CONFIRM} from '@/store/modules/common/common-index';

export default {
  name: 'LxpConfirm',
  props: {},
  emits: [],
  setup(){
    const store = useStore();
    const confirm = computed(() => store.state.common.confirm);
    // 확인콜백
    const okConfirm = () => {
      if(confirm.value.callback instanceof Function) confirm.value.callback();
      store.commit(`common/${MUT_CLOSE_CONFIRM}`);
    }
    // 취소콜백
    const cancelConfirm = () => {
      if(confirm.value.cancelCallback instanceof Function) confirm.value.cancelCallback();
      if(confirm.value.closeCallback instanceof Function) confirm.value.closeCallback();
      store.commit(`common/${MUT_CLOSE_CONFIRM}`);
    }

    return {
      confirm,
      okConfirm,
      cancelConfirm
    }
  }
}
</script>
