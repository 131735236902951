<template>
  <header class="kb-header" id="kb-header">
    <div class="header-component">
      <!-- header-title -->
      <div class="header-column header-title" :class="{ 'me-2' : headerClass }">
        <slot name="title"></slot>
        {{ useRouteName ? currentMenuName : title }}
      </div>
      <div v-if="caption" class="caption"> {{caption}} </div>
      <!-- //header-title -->
      <!-- header-util -->
      <div class="header-column header-util left">
<!--        <div class="util util-back">-->
<!--          <a href="javascript:" class="util-actions util-actions-back" @click="goBack">-->
<!--            <i class="icon-history-back"></i>-->
<!--          </a>-->
<!--        </div>-->
        <slot name="left"></slot>
      </div>

      <div class="header-column header-util right">
        <slot name="right"></slot>
      </div>
      <!-- //header-util -->
    </div>
  </header>
</template>

<script>
import {useRoute, useRouter} from 'vue-router';
import {computed} from 'vue';
import {useStore} from 'vuex';

export default {
  name: "LxpMobileHeader",
  props: {
    useRouteName: {
      type:Boolean,
      default:false},
    title: String,
    caption: [String, Number],
    headerClass: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {

    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const currentMenuName = computed(() => {
      if (props.useRouteName) {
        let currentMenu = null;

        for(let i = 0; i < store.state.menu.menus.length; i++){
          if(store.state.menu.menus[i].children && store.state.menu.menus[i].children.length > 0){
            for(let j = 0; j < store.state.menu.menus[i].children.length; j++){
              if(store.state.menu.menus[i].children[j].menuUrl === route.path){
                currentMenu = store.state.menu.menus[i].children[j];
                break;
              }
            }
          }
        }
        return currentMenu !== null ? currentMenu.menuNm : '';
      }
      return '';
    });

    const goBack = () => {
      router.go(-1);
    }

    return {
      currentMenuName,
      goBack
    }




  }
}
</script>
