<template>
  <template v-if="modelValue">
    <HrdCloudMobileHeader
        title="HRD 클라우드 콘텐츠별 학습이력"
        :show-back="true"
        :go-back-func="closeModal"
    >
      <template v-slot:right>
        <a href="javascript:" @click="latestHistoryModal=true">
          <img src="../../../assets/lxp/mobile/images/hrdcloud/ic_recent.svg">
        </a>
      </template>
    </HrdCloudMobileHeader>
    <main class="kb-main" id="kb-hrdcloud">
      <div class="main-content main-component">
        <article class="content-section">
          <div class="text-item-container">
            <div v-for="(item, idx) in items" class="text-item" :key="idx">
              <p class="item-subtitle">콘텐츠명</p>
              <h5 class="item-title">{{title}}</h5>
              <div class="item-row">
                <span class="text-title">학습일시</span>
                <span class="text-description">{{ getLrnDt(item, 'yyyy.MM.dd') }}</span>
              </div>
              <div class="item-row">
                <span class="text-title">학습진도율</span>
                <span class="text-description">{{ getProgressPercent(duration, item.accumPlaySec, cttTyCdDcd) }} %</span>
              </div>
              <div class="item-row">
                <span class="text-title">학습완료여부</span>
                <span class="text-description">{{ item.cmptnYn == 'Y' ? '완료' : '미완료' }}</span>
              </div>
              <div class="item-row">
                <span class="text-title">획득마일리지</span>
                <span class="text-description">{{ item.mlgScore }}</span>
              </div>
              <div class="item-row">
                <span class="text-title">비고</span>
                <span class="text-description" :style="item.mlgTyCd === '1001' || item.mlgTyCd === '1003' || item.mlgTyCd === '1004' || item.mlgTyCd === '1005' ? {color:'#ff8000'} : {}">
                {{ item.mlgGiveRsn != null || item.mlgGiveRsn != '' ? item.mlgGiveRsn : '-' }} {{ getMlgGiveDt(item) }}
              </span>
              </div>
            </div>
          </div>
          <div v-if="(items && items.length === 0)" class="search-container">
            <div class="result-empty">
              <img src="../../../assets/lxp/images/common/img_empty.png" alt="">
              <p class="text">학습 이력이 없습니다.</p>
            </div>
          </div>
        </article>
      </div>
    </main>

    <HrdCloudHistoryMobileDtl v-if="latestHistoryModal" v-model="latestHistoryModal" :lrnObjDtlDistSn="lrnObjDtlDistSn" :year="new Date().getFullYear()" :month="new Date().getMonth()+1" />
  </template>
</template>

<script>
import HrdCloudMobileHeader from '@/components/hrdcloud/mobile/HrdCloudMobileHeader';
import {getLrnDt, lightMode, toggleConcentrationMode} from '@/assets/js/modules/hrd/hrd-common';
import {ref, toRefs, watch} from 'vue';
import HrdCloudHistoryMobileDtl from '@/components/history/mobile/HrdCloudHistoryMobileDtl.vue';
import {getMlgGiveDt, getProgressPercent} from '@/assets/js/modules/hrd/hrd-history-common';

export default {
  name: 'HrdCloudMobileHistory',
  components: {HrdCloudHistoryMobileDtl, HrdCloudMobileHeader},
  props: {
    modelValue: Boolean,
    title: String,
    duration: Number,
    items: Array,
    lrnObjDtlDistSn: [Number, String],
    func: Function,
    cttTyCdDcd: String
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const {modelValue : show} = toRefs(props);
    const latestHistoryModal = ref(false);

    const showLatestHistory = () => {
      latestHistoryModal.value = true;
    };

    const closeModal = () => {
      if (latestHistoryModal.value) latestHistoryModal.value = false;
      emit('update:modelValue', false);
    };

    watch(() => show.value,() => {
      if (show.value) {
        lightMode();
      } else {
        toggleConcentrationMode();
      }
    });

    return {
      closeModal,
      getLrnDt,
      getProgressPercent,
      latestHistoryModal,
      showLatestHistory,
      getMlgGiveDt
    }
  }
};
</script>