<template>
  <div class="popup-container p-0 d-block" id="kb-mobile-popup" :class="{ 'is-active' : show }">
    <HrdCloudMobileHeader title="HRD 콘텐츠 담기" :show-back="true" :go-back-func="goBackFunc" />
    <main class="kb-main" id="kb-hrdcloud">
      <div class="main-content main-component">
        <article class="content-section mt-0">
          <header class="section-header header-divider">
            <h3 class="title text-gold">{{ objNm }}</h3>
          </header>
          <div class="kb-form-check-group">
            <div class="kb-form-check">
              <input type="checkbox" v-model="put"  class="kb-form-check-input" name="chk_01" id="chk_01_1" :checked="put" >
              <label for="chk_01_1" class="kb-form-check-label"><p class="text">MY 콘텐츠</p></label>
            </div>
            <div class="kb-form-check">
              <input type="checkbox" v-model="glPut" class="kb-form-check-input" name="chk_01" id="chk_01_2" :checked="glPut" :disabled="isPdf" >
              <label for="chk_01_2" class="kb-form-check-label"><p class="text" :class="{'text-muted': isPdf}">그룹러닝 콘텐츠</p></label>
            </div>
          </div>
          <p class="text-muted pt-3">
            ※ 그룹러닝 콘텐츠 담기는 동영상, 전산실습(실습모드)만 가능합니다.(PDF 제외)
          </p>
        </article>
        <div class="page-buttons mt-0">
          <button class="kb-btn kb-btn-primary" @click.stop="handlePut">확인</button>
        </div>
      </div>
    </main>
  </div>
</template>

<script>

import HrdCloudMobileHeader from '@/components/hrdcloud/mobile/HrdCloudMobileHeader.vue';
import {hrdLeaderAddSetup} from '@/assets/js/modules/hrd/hrd-leader-add-setup';

export default {
  name: 'HrdCloudMobileLeaderAddModal',
  components: {HrdCloudMobileHeader},
  setup: hrdLeaderAddSetup
};
</script>