import ApiService from "@/assets/js/api.service";

export const ACT_GET_USERS_LIST = 'getUsersList';

const actions = {
  [ACT_GET_USERS_LIST](context, params) {
    return new Promise(resolve => {
      ApiService.query('/v1/app/users', params).then(response => resolve(response))
    });
  }
}


export default {
  namespaced: true,
  actions
};
