<template>
  <footer class="kb-footer" id="kb-footer">
    <!-- 공지사항 숨김처리 footer-divider -->
    <div class="footer-row footer-divider">
      <nav class="footer-fnb">
        <div class="fnb-column column-left">
          <router-link to="/main"  class="logo"><i class="kb-logo-long"></i></router-link>
          <div v-if="lrnTrgtGrpMstSn > 0 && !isMyLrnTrgt && !isNoneReward" class="badge" @click="enrollCourse"><span class="badge-text">체험모드중</span></div>
        </div>

        <ol class="fnb-list">
          <li class="fnb-item"><a href="javascript:" class="fnb-link fnb-profile"><SSLProfileImg :target="session"/></a></li>
          <li class="fnb-item"><a href="javascript:" class="fnb-link fnb-menu" id="open-menu-button" @click="toggleMenu"><i class="icon-menu icon-menu-hamburger" ></i></a></li>
        </ol>
      </nav>
      <LxpMobileFooterMenu v-model="isMenuOpen" :toggle-menu="toggleMenu"/>
    </div>
  </footer>
</template>
<script>

import {computed, ref} from 'vue';
import {useStore} from 'vuex';
import SSLProfileImg from '@/components/ssl/common/SSLProfileImg';
import LxpMobileFooterMenu from '@/views/layout/lxp/footer/mobile/LxpMobileFooterMenu';
import {
  enrollSSLAlert,
  getRouteLrnTrgtGrpMstSn,
  isMyLrnTrgtGrpMstSn,
  noneRewardCds, sslRoutes,
} from '@/assets/js/modules/ssl/ssl-common';
import {useRoute} from 'vue-router';

export default {
  name: 'SSLMobileGnb',
  components: {LxpMobileFooterMenu, SSLProfileImg},
  props: {
    effectNav: {
      required: false,
      type: Number,
      default: 0
    },
  },
  setup(){
    const store = useStore();
    const route = useRoute();

    const session = computed(() => store.state.auth.session);
    const distCrseCd = computed(() => route.params.distCrseCd);
    const distCrseNm = computed(() => sslRoutes[distCrseCd.value] ? sslRoutes[distCrseCd.value].name : '' );

    const lrnTrgtGrpMstSn = computed(() => getRouteLrnTrgtGrpMstSn(route.params.lrnTrgtGrpMstSn));
    const isMyLrnTrgt = computed(() => {
      if (lrnTrgtGrpMstSn.value > 0) {
        return isMyLrnTrgtGrpMstSn(lrnTrgtGrpMstSn.value)
      }
      return false;
    });
    const isNoneReward = computed(() => noneRewardCds.includes(route.params.distCrseCd));

    const isMenuOpen = ref(false);
    const toggleMenu = () => {
      isMenuOpen.value = !isMenuOpen.value;
    };

    const enrollCourse = () => {
      enrollSSLAlert(distCrseCd, distCrseNm, lrnTrgtGrpMstSn)
    }

    return {
      session,
      isMenuOpen,
      lrnTrgtGrpMstSn,
      isMyLrnTrgt,
      isNoneReward,
      toggleMenu,
      enrollCourse
    }
  }

}
</script>
