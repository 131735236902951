import LxpLayout from "@/views/layout/lxp/LxpLayout";
import LxpMobileLayout from "@/views/layout/lxp/mobile/LxpMobileLayout";
import navigationUtils from "@/assets/js/navigationUtils";
const isMobile = navigationUtils.any();

const dailyRoutes = [
  {
    path: '/daily',
    component: isMobile ? LxpMobileLayout : LxpLayout,
    children: [
      {
        path: 'apply',
        name: "DailyApply",
        component: () => isMobile ? import('../../views/pages/daily/mobile/DailyMobileApply') : import('../../views/pages/daily/DailyApply'),
      },
      {
        path: 'apply/step0',
        name: "DailyMobileApplyStep0",
        component: () => import('../../views/pages/daily/mobile/DailyMobileApplyStep0'),
      },
      {
        path: 'apply/step0/date',
        name: "DailyMobileApplyStep0Date",
        component: () => import('../../views/pages/daily/mobile/DailyMobileApplyStep0Date'),
      },
      {
        path: 'apply/step1',
        name: "DailyMobileApplyStep1",
        component: () => import('../../views/pages/daily/mobile/DailyMobileApplyStep1'),
      },
      {
        path: 'apply/step2',
        name: "DailyMobileApplyStep2",
        component: () => import('../../views/pages/daily/mobile/DailyMobileApplyStep2'),
      },
      {
        path: 'apply/step3',
        name: "DailyMobileApplyStep3",
        component: () => import('../../views/pages/daily/mobile/DailyMobileApplyStep3'),
      },
      {
        path: 'apply/detail',
        name: "DailyMobileApplyDetail",
        component: () => import('../../views/pages/daily/mobile/DailyMobileApplyDetail'),
      },
      {
        path: 'apply/sports/step0',
        name: "DailyMobileSportsApplyStep0",
        component: () => import('../../views/pages/daily/mobile/DailyMobileSportsApplyStep0'),
      },
      {
        path: 'apply/sports/step0/date',
        name: "DailyMobileSportsApplyStep0Date",
        component: () => import('../../views/pages/daily/mobile/DailyMobileSportsApplyStep0Date'),
      },
      {
        path: 'apply/sports/step1',
        name: "DailyMobileSportsApplyStep1",
        component: () => import('../../views/pages/daily/mobile/DailyMobileSportsApplyStep1'),
      },
      {
        path: 'apply/sports/step2',
        name: "DailyMobileSportsApplyStep2",
        component: () => import('../../views/pages/daily/mobile/DailyMobileSportsApplyStep2'),
      },
      {
        path: 'apply/sports/step3',
        name: "DailyMobileSportsApplyStep3",
        component: () => import('../../views/pages/daily/mobile/DailyMobileSportsApplyStep3'),
      },
      {
        path: 'apply/sports/step4',
        name: "DailyMobileSportsApplyStep4",
        component: () => import('../../views/pages/daily/mobile/DailyMobileSportsApplyStep4'),
      },
      {
        path: 'apply/sports/detail',
        name: "DailyMobileSportsApplyDetail",
        component: () => import('../../views/pages/daily/mobile/DailyMobileSportsApplyDetail'),
      },
      {
        // path variable 을 생성해야 된다. 임시 라우터 처리
        path: 'apply/list',
        name: "DailyList",
        component: () => import('../../views/pages/daily/DailyApplyList'),
      },
      {
        // path variable 을 생성해야 된다. 임시 라우터 처리
        path: 'history',
        name: "DailyHistory",
        component: () => isMobile ? import('../../views/pages/daily/mobile/DailyMobileApplyHistory') : import('../../views/pages/daily/DailyApplyHistory'),
      },
      {
        // path variable 을 생성해야 된다. 임시 라우터 처리
        path: 'inquiry',
        name: "Inquiry",
        component: () => isMobile ? import('../../views/pages/daily/mobile/InquiryMobile') : import('../../views/pages/daily/Inquiry'),
      },
      {
        // path variable 을 생성해야 된다. 임시 라우터 처리
        path: 'notice',
        name: "TrnctNotice",
        component: () => isMobile ? import('../../views/pages/daily/mobile/TrnctMobileNotice') : import('../../views/pages/daily/TrnctNotice'),
      },
      {
        // path variable 을 생성해야 된다. 임시 라우터 처리
        path: 'notice/:trnctNtcSn',
        name: "TrnctNoticeView",
        component: () => isMobile ? import('../../views/pages/daily/mobile/TrnctMobileNoticeView') : import('../../views/pages/daily/TrnctNoticeView'),
      },
      {
        // path variable 을 생성해야 된다. 임시 라우터 처리
        path: 'pearl',
        name: "Pearl",
        component: () => import('../../views/pages/daily/Pearl'),
      },
    ],
  }
];

export const setDailyRoutes = (routes) => {
  routes.push(...dailyRoutes);
}
