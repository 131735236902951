<template>
  <article class="home-section section-myactivity">
    <header class="section-header">
      <h3 class="title">내 활동</h3>
    </header>

    <div class="section-body">
      <div ref="slider" class="myactivity-container swiper" data-swiper="swiperMyactivity">
        <div class="activity-wrapper swiper-wrapper">
          <!-- activity -->
          <div class="activity swiper-slide">
            <div class="activity-icon">
              <i class="icon-activity-note"></i>
            </div>
            <div class="activity-content">
              <strong class="title">학습노트 작성</strong>
              <span class="text">{{ socStat.boardCnt }}</span>
            </div>
          </div>
          <!-- activity -->
          <div class="activity swiper-slide kb-mouse-cursor" @click="showAttendance">
            <div class="activity-icon">
              <i class="icon-activity-att"></i>
            </div>
            <div class="activity-content">
              <strong class="title" :class="{'is-new': !todayAttended}">총 출석일</strong>
              <span class="text">{{ attendanceCnt }}</span>
            </div>
          </div>
          <!-- activity -->
          <div class="activity swiper-slide kb-mouse-cursor" @click="showActFeed('cmntRec')">
            <div class="activity-icon">
              <i class="icon-activity-cmt-in"></i>
            </div>
            <div class="activity-content">
              <strong class="title" :class="{'is-new': (socStat.cmntTodayReceiveCnt > 0)}">받은 댓글</strong>
              <span class="text">{{ socStat.cmntReceiveCnt }}</span>
            </div>
          </div>
          <!-- activity -->
          <div class="activity swiper-slide kb-mouse-cursor" @click="showActFeed('likeGet')">
            <div class="activity-icon">
              <i class="icon-activity-like-in"></i>
            </div>
            <div class="activity-content">
              <strong class="title" :class="{'is-new': (socStat.likeTodayGetCnt > 0)}">받은 좋아요</strong>
              <span class="text">{{ socStat.likeGetCnt }}</span>
            </div>
          </div>

          <div class="activity swiper-slide">
            <div class="activity-icon">
              <i class="icon-activity-badge"></i>
            </div>
            <div class="activity-content">
              <strong class="title">취득배지수</strong>
              <span class="text">{{ myBadges.length }}</span>
            </div>
          </div>
          <!-- activity -->
          <div class="activity swiper-slide">
            <div class="activity-icon">
              <i class="icon-activity-rm"></i>
            </div>
            <div class="activity-content">
              <strong class="title">러닝메이트</strong>
              <span class="text">{{ mateCount }}</span>
            </div>
          </div>

          <div class="activity swiper-slide">
            <div class="activity-icon">
              <i class="icon-activity-note-in"></i>
            </div>
            <div class="activity-content">
              <strong class="title">내 학습노트 조회수</strong>
              <span class="text">{{ socStat.boardInqCnt }}</span>
            </div>
          </div>

          <div class="activity swiper-slide">
            <div class="activity-icon">
              <i class="icon-activity-share"></i>
            </div>
            <div class="activity-content">
              <strong class="title">내 학습노트 공유수</strong>
              <span class="text">{{ socStat.sharedCnt }}</span>
            </div>
          </div>
          <!-- activity -->
          <div class="activity swiper-slide">
            <div class="activity-icon">
              <i class="icon-activity-cmt"></i>
            </div>
            <div class="activity-content">
              <strong class="title">남긴 댓글</strong>
              <span class="text">{{ socStat.cmntWriteCnt }}</span>
            </div>
          </div>
          <!-- activity -->
          <div class="activity swiper-slide">
            <div class="activity-icon">
              <i class="icon-activity-like"></i>
            </div>
            <div class="activity-content">
              <strong class="title">남긴 좋아요</strong>
              <span class="text">{{ socStat.likeGiveCnt }}</span>
            </div>
          </div>

          <div class="activity swiper-slide kb-mouse-cursor" @click="showShareList">
            <div class="activity-icon">
              <i class="icon-activity-share-b"></i>
            </div>
            <div class="activity-content">
              <strong class="title">내가 공유한 노트수</strong>
              <span class="text">{{ socStat.shareGivCnt }}</span>
            </div>
          </div>

        </div>
        <button class="kb-btn-activity-prev">
          <i class="icon-arrow-prev">prev</i>
        </button>
        <button class="kb-btn-activity-next">
          <i class="icon-arrow-next">next</i>
        </button>
      </div>
    </div>
  </article>
  <SSLActFeedModal v-model="isFeedModal" ref="actFeedModal" />
  <SSLShareModal v-model="isShareModal" ref="shareModal" />
</template>
<script>
import {sslHomeActivitySetup} from '@/assets/js/modules/ssl/ssl-home';
import SSLActFeedModal from '@/components/ssl/home/SSLActFeedModal';
import SSLShareModal from '@/components/ssl/home/SSLShareModal';

export default {
  name: 'SSLActivity',
  components:{SSLActFeedModal, SSLShareModal},
  setup: sslHomeActivitySetup
}
</script>
