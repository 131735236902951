<template>
  <article class="content-section mt-7 section-margin">
    <header class="section-header header-divider d-flex">
      <h3 class="title">구독채널</h3>
      <div class="header-side">
        <button v-if="!checkAll" class="kb-btn-text" @click.stop="clickCheckAll"><span class="text">전체선택</span></button>
        <button v-else class="kb-btn-text" @click.stop="clickUnCheckAll"><span class="text">전체해제</span></button>
      </div>
    </header>
    <div class="text-item-container" data-content="check">
      <LoadingDiv v-if="isLoading"/>
      <template v-else>
        <template v-if="items && items.length > 0">
          <div v-for="(item,idx) in items" class="text-item" :key="idx">
            <label for="chk_01" class="kb-form-check-label" style="z-index:0 !important;"></label>
            <div class="item-column" @click.stop="goSubsPage(item)">
              <h4 class="item-title">{{ item.cloudNmLst }}</h4>
              <div class="item-subtext">{{ `구독 ${item.subscribedCnt}명` }}</div>
            </div>
            <div class="item-column column-check">
              <div class="kb-form-check">
                <input type="checkbox" class="kb-form-check-input" :id="`chk_01_${idx}`" name="chk"
                       v-model="model"
                       :value="item.hrdCloudCateSn"
                       @change.stop="handleClick"
                >
              </div>
            </div>
          </div>
        </template>
        <template v-else-if="items && items.length === 0">
          <div class="search-container">
            <div class="result-empty">
              <img src="../../../assets/lxp/images/common/img_empty.png" alt="">
              <p class="text">구독중인 채널이 없습니다.</p>
            </div>
          </div>
        </template>
      </template>
    </div>
    <CommonPaginationFront :paging="paging" :page-func="pagingFunc" />
  </article>
</template>

<script>
import {ref} from 'vue';
import CommonPaginationFront from "@/components/CommonPaginationFront.vue";
import LoadingDiv from "@/components/common/LoadingDiv.vue";

export default {
  name: "HrdMobileSubscribeList",
  components: {LoadingDiv, CommonPaginationFront},
  props: {
    isLoading: Boolean,
    items: Array,
    hrdCloudCateSnList: Array,
    checkAll: Boolean,
    clickCheckAll: Function,
    clickUnCheckAll: Function,
    goSubsPage: Function,
    paging: Object,
    pagingFunc: Function
  },
  emits: ['changed'],
  setup(props, {emit}) {
    const model = ref([]);
    const handleClick = () => {
      emit('changed',model.value);
    };
    return {
      model,
      handleClick
    }
  }
}
</script>