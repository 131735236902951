<template>
  <aside class="eTest-aside">
    <!-- aside-row -->
    <div class="aside-row row-contents">
      <ul class="test-list">
        <li v-for="(answer, index) in answers" class="test-item"
            :class="getAsideClass(answer, index)" :key="index">
          <a href="javascript:" class="item-link" @click="selectQuestion(index)"><span class="item-text">{{ index + 1 }}</span></a>
        </li>
      </ul>

      <div class="test-labels">
        <div class="label">
          <div class="label-marker marker-solve"></div>
          <span class="label-text">푼문제</span>
        </div>
        <div class="label">
          <div class="label-marker"></div>
          <span class="label-text">안푼문제</span>
        </div>
        <div class="label">
          <div class="label-marker marker-check"></div>
          <span class="label-text">체크</span>
        </div>
        <div class="label">
          <div class="label-marker marker-save"></div>
          <span class="label-text">저장중</span>
        </div>
      </div>
    </div>
    <!-- //aside-row -->
    <!-- aside-row -->
    <div class="aside-row row-actions">
<!--      <p class="subtext">답안 저장중에 상관없이 계속 응시하실 수 있습니다.</p>-->
      <div class="d-flex flex-column">
        <button class="kb-btn kb-btn-primary" @click="submitEvl"><span class="text">답안 제출하기</span></button>
        <button class="kb-btn kb-btn-secondary" @click="closeEvl" style="margin-top: 10px"><span class="text">닫기</span></button>
      </div>
    </div>
    <!-- //aside-row -->
  </aside>
</template>

<script>

import {evlAsideSetup} from '@/assets/js/modules/evaluation/evl-setup';

export default {
  name: "EvlAside",
  components:{},
  props: {
    modelValue: Number,
    answers: Array
  },
  emits: ['update:modelValue', 'submitEvl', 'closeEvl'],
  setup: evlAsideSetup
}
</script>
