<template>
  <div class="popup-container" :class="{'is-active': modelValue}">
    <!-- popup -->
    <div class="popup" id="popup-eTest-vows">
      <!-- popup > popup-inner -->
      <div class="popup-inner">
        <div>
          <div class="popup-header">
            <h3 class="title">사이버 평가 서약서</h3>
          </div>
          <!-- popup-inner > popup-content -->
          <div class="popup-content">
            <article class="popup-section section-agree">
              <div class="section-content agree-content">
                <div class="vows-contents">
                  <h4 class="title">서약 1</h4>
                  <p class="text">하나, 본인은 평가중에 대리평가 등 다른 직원의 도움을 받는 부정행위를 절대 하지 않겠습니다.</p>
                  <h4 class="title">서약 2</h4>
                  <p class="text">하나, 본 평가는 1회 응시만 가능하며, 답안 제출시 재평가가 불가능한 점을 잘 알고 있습니다.</p>
                  <h4 class="title">서약 3</h4>
                  <p class="text">하나, WIFI, LTE, 5G 응시자의 네트워크 환경에 따라 시간이 지연될 수 있음을 잘 알고 있습니다.</p>
                  <h4 class="title">서약 4</h4>
                  <p class="text">하나, 수료기준 점수 미달시 별도로 정한 과정(평가)을 제외하고, 연수 비용이 환입됨을 잘 알고 있습니다.</p>
                </div>
                <div class="vows-meta">
                  <dl class="meta-dl">
                    <dt class="title">서약정보</dt>
                    <dd class="text">{{ session.deptNm }} ・ {{ session.lrnerNm }}</dd>
                    <dt class="title">직원번호</dt>
                    <dd class="text">{{ session.lrnerId }}</dd>
                  </dl>
                  <span class="meta-date">{{ currentTimeText }} 서약</span>
                </div>
              </div>
              <div class="vows-agree agree-check">
                <div class="kb-form-check-notice"><span class="text">본인은 평가 대상자로서 다음의 사항을 이해하고 숙지하였음을 서약합니다.</span></div>
                <div class="kb-form-check">
                  <input v-model="isChecked" type="checkbox" class="kb-form-check-input" id="chk_agree_01">
                  <label class="kb-form-check-label" for="chk_agree_01"><span class="text">{{ isChecked ? '동의완료' : '미동의' }}</span></label>
                </div>
              </div>
            </article>
          </div>
          <!-- popup-inner > popup-buttons -->
          <div class="popup-buttons">
            <button class="kb-btn kb-btn-primary kb-btn-lg" @click="clickPledge"><span class="text">서약하기</span></button>
          </div>
        </div>
      </div>
      <!-- popup > popup-close -->
      <div class="popup-close">
        <button @click="closeModal" class="kb-btn-popup-close"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
    <!-- //popup -->
  </div>
</template>

<script>
import {evlPledgeSetup} from '@/assets/js/modules/evaluation/evl-setup';

export default {
  name: 'EvlPledge',
  props: {
    modelValue: Boolean,
    currentTimeText: String
  },
  emits: ['update:modelValue', 'pledge'],
  setup: evlPledgeSetup
};
</script>
