<template>
  <template v-if="isMobile">
    <div class="schedule-container">
      <div class="calendar-picker-container">
        <div class="calendar-swiper swiper js-swiper-calendar">
          <div class="calendar-swiper-wrapper swiper-wrapper">
            <swiper
                :slides-per-group="1"
                :slides-per-view="1"
                :initial-slide="(initMonth[0] && initMonth[0].idx) ? initMonth[0].idx : 0"
                :navigation="{
                      nextEl: '.kb-btn-datepicker-next',
                      prevEl: '.kb-btn-datepicker-prev'
                    }"
            >
              <swiper-slide v-for="(item , idx) in eCalendars" :key="idx">
                <div class="calendar-picker swiper-slide">
                  <div class="picker-body">
                    <div class="datepicker-header">
                      <button class="kb-btn-datepicker-nav kb-btn-datepicker-prev">이전</button>
                      <div class="title">2023년 {{ item.month }}월</div>
                      <button class="kb-btn-datepicker-nav kb-btn-datepicker-next">다음</button>
                    </div>
                    <div class="datepicker-table datepicker-table-week">
                      <table>
                        <thead>
                        <tr>
                          <th>일</th>
                          <th>월</th>
                          <th>화</th>
                          <th>수</th>
                          <th>목</th>
                          <th>금</th>
                          <th>토</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(week, idx) in item.tbody" :key="idx">
                          <template v-for="(day, i) in week" :key="`${idx}-${i}`">
                            <td v-if=" day.month === item.month">
                              <button class="kb-btn-day" :class="{'is-active' : dataYmds.includes(day.ymd), 'is-warning' : evaluationYmds.includes(day.ymd)}">
                                <span class="text">{{ day.day }}</span>
                              </button>
                            </td>
                            <td v-else>&nbsp;</td>
                          </template>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
      <div class="calendar-contents">
        <div class="datepicker-labels">
          <div class="label"><i class="label-mark mark-primary"></i><span class="label-text">학습자료 제공일</span>
          </div>
          <div class="label"><i class="label-mark mark-red"></i><span class="label-text">평가일</span></div>
        </div>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="calendar-picker-container calendar-swiper-container">
      <div class="calendar-swiper-nav swiper-nav js-swiper-kb-nav-calendar">
        <button class="kb-btn-nav kb-btn-swiper-prev kb-btn-calendar-swiper-prev"><i class="icon-arrow-prev"></i></button>
        <button class="kb-btn-nav kb-btn-swiper-prev kb-btn-calendar-swiper-next"><i class="icon-arrow-next"></i></button>
      </div>
      <div class="calendar-swiper swiper js-swiper-calendar">
        <div class="calendar-swiper-wrapper swiper-wrapper">
          <swiper
              :slides-per-group="3"
              :slides-per-view="3"
              :navigation="{
                  nextEl: '.kb-btn-calendar-swiper-next',
                  prevEl: '.kb-btn-calendar-swiper-prev'
                }"
              :initial-slide="(initMonth[0] && initMonth[0].idx) ? initMonth[0].idx : 0"
          >
            <swiper-slide v-for="(item , idx) in eCalendars" :key="idx">
              <div class="calendar-picker swiper-slide">
                <div class="picker-body">
                  <div class="datepicker-header">
                    <button class="kb-btn-datepicker-nav kb-btn-datepicker-prev"></button>
                    <div class="title">2023년 {{ item.month }}월</div>
                    <button class="kb-btn-datepicker-nav kb-btn-datepicker-next"></button>
                  </div>
                  <div class="datepicker-table datepicker-table-week">
                    <table>
                      <thead>
                      <tr>
                        <th>일</th>
                        <th>월</th>
                        <th>화</th>
                        <th>수</th>
                        <th>목</th>
                        <th>금</th>
                        <th>토</th>
                      </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(week, idx) in item.tbody" :key="idx">
                          <template v-for="(day, i) in week" :key="`${idx}-${i}`">
                            <td v-if=" day.month === item.month">
                              <button class="kb-btn-day" :class="{'is-active' : dataYmds.includes(day.ymd), 'is-warning' : evaluationYmds.includes(day.ymd)}">
                                <span class="text">{{ day.day }}</span>
                              </button>
                            </td>
                            <td v-else>&nbsp;</td>
                          </template>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import {getCalendar} from '@/assets/js/util';
import navigationUtils from '@/assets/js/navigationUtils';
import {computed} from "vue";

export default {
  name: 'KnowledgeELearningCalendar',
  components: {
    Swiper,
    SwiperSlide
  },
  setup() {
    const month = computed(() => (new Date()).getMonth()+1);

    const dataYmds = ['20230327','20230407','20230421','20230512','20230526','20230609','20230828','20230908','20231006','20231020']; // 학습자료 등재일
    const evaluationYmds = ['20230420','20230525','20230622','20230921','20231102']; // 평가일

    const eCalendars = [
      // {idx:0, month: 1, tbody: getCalendar(2023, 1)},
      // {idx:1, month: 2, tbody: getCalendar(2023, 2)},
      {idx:0, month: 3, tbody: getCalendar(2023, 3)},
      {idx:1, month: 4, tbody: getCalendar(2023, 4)},
      {idx:2, month: 5, tbody: getCalendar(2023, 5)},
      {idx:3, month: 6, tbody: getCalendar(2023, 6)},
      {idx:4, month: 7, tbody: getCalendar(2023, 7)},
      {idx:5, month: 8, tbody: getCalendar(2023, 8)},
      {idx:6, month: 9, tbody: getCalendar(2023, 9)},
      {idx:7, month: 10, tbody: getCalendar(2023, 10)},
      {idx:8, month: 11, tbody: getCalendar(2023, 11)},
      // {idx:11, month: 12, tbody: getCalendar(2023, 12)},
    ];

    const initMonth = computed(() => eCalendars.filter(x => x.month === month.value));

    return {
      eCalendars,
      initMonth,
      dataYmds,
      evaluationYmds,
      isMobile: !!navigationUtils.any(),
    }
  }
};
</script>