<template>
  <!-- cloud-header -->
  <LxpMobileHeader title="퀴즈관리" id="kb-header">
    <template v-slot:left>
        <div class="util util-back">
            <a href="javascript:" class="util-actions util-actions-back" @click="closeLayer">
                <i class="icon-history-back"></i>
            </a>
        </div>
    </template>
    <template v-slot:right>
        <div v-if="isQuizListShow" class="util util-apply">
            <a href="javascript:" class="util-actions util-actions-yellow" @click="quizAddLayer">
                퀴즈추가
            </a>
        </div>
      <button v-if="isQuizDetailShow" @click="qstnAddLayer()" class="util-text-button"><span class="util-actions util-actions-yellow">문항추가</span></button>
    </template>
  </LxpMobileHeader>
  <!-- //cloud-header -->

  <!-- cloud-main -->
  <main class="kb-main" id="kb-prep-quiz">
    <!-- main-view -->
    <div class="main-header">
      <div class="header-snb">
          <nav class="snb">
              <ul class="snb-list">
                  <li class="snb-item">
                      <button href="javascript:" @click="choiceQuizTab('list')"  :class="{'is-active': isQuizListShow || isQuizDetailShow}">퀴즈목록</button>
                  </li>
                  <li class="snb-item">
                      <button href="javascript:"  @click="choiceQuizTab('result')" :class="{'is-active': isQuizResultListShow}">퀴즈출제결과</button>
                  </li>
              </ul>
          </nav>
      </div>
      <div class="main-content">
        <!--  퀴즈목록  -->
        <MobileQuizMngList v-if="isQuizListShow" ref="quizMngListEl" :quizDetailFunc="quizDetail"/>
        <!--  퀴즈출제결과 탭  -->
        <MobileQuizMngResultList v-if="isQuizResultListShow" :rsltAddLayer="rsltAddLayer"/>
        <!-- 퀴즈상세: 문항리스트 -->
        <MobileQuizMngListDetail v-if="isQuizDetailShow" ref="quizDetailEl"
                                 v-model:isQuizDetailShow="isQuizDetailShow" :quizDtlSn="quizDtlSn"
                                 :quizItem="quizItem" :qstnAddLayer="qstnAddLayer" :rsltAddLayer="rsltAddLayer"/>
      </div>
    </div>

    <div  v-if="isQuizDetailShow" class="page-buttons">
        <button class="kb-btn kb-btn-primary" @click="quizDetailEl.updateQuiz"><span class="text">저장</span></button>
    </div>
  </main>
  <!-- //cloud-main -->

  <!-- 퀴즈 문제지 추가 -->
  <MobileQuizMngListAdd v-if="isQuizListAddLayerShow" v-model:isQuizListAddLayerShow="isQuizListAddLayerShow"
                        v-model:insertQuiz="insertQuiz"/>
  <!-- 퀴즈결과 상세 -->
  <MobileQuizMngResultDetail v-if="isQuizMngRsltDtlShow"
                       v-model:isQuizMngRsltDtlShow="isQuizMngRsltDtlShow" :rsltItem="rsltItem"/>

  <!-- 문항추가 레이어 -->
  <MobileQuizMngQstnAdd v-if="isQstnAddShow" v-model:isQstnAddShow="isQstnAddShow"
                        :qstnSn="qstnItem.lrnTyEvlQstnSn" :quizSn="qstnItem.lrnTyEvlMstSn" :qstnIdx="qstnItem.qstnNo"
                        :distCnt="quizItem.distCnt" :getQstnList="quizDetailEl.getQstnList"/>
</template>

<script>
import {computed, onMounted, ref} from 'vue';
import {useStore} from 'vuex';
import MobileQuizMngResultList from '@/components/quiz/mobile/MobileQuizMngResultList.vue';
import MobileQuizMngList from '@/components/quiz/mobile/MobileQuizMngList.vue';
import MobileQuizMngListAdd from '@/components/quiz/mobile/MobileQuizMngListAdd.vue';
import MobileQuizMngResultDetail from '@/components/quiz/mobile/MobileQuizMngResultDetail.vue';
import MobileQuizMngListDetail from '@/components/quiz/mobile/MobileQuizMngListDetail.vue';
import MobileQuizMngQstnAdd from '@/components/quiz/mobile/MobileQuizMngQstnAdd.vue';
import LxpMobileHeader from '@/views/layout/lxp/header/mobile/LxpMobileHeader.vue';
import {useAlert} from '@/assets/js/modules/common/alert';
import {ACT_INSERT_QUIZ} from '@/store/modules/quiz/quiz';

export default {
  name: "MobileQuizMng",
  components: {
    MobileQuizMngResultList,
    MobileQuizMngList,
    MobileQuizMngListAdd,
    MobileQuizMngResultDetail,
    MobileQuizMngListDetail,
    MobileQuizMngQstnAdd,
    LxpMobileHeader
  },
  props:{
    isQuizMngShow: Boolean,
  },
  emits:['update:isQuizMngShow'],
  setup(props, {emit}) {

    onMounted(() => {
      isQuizListShow.value = true;
    })

    const store = useStore();
    const isQuizListShow = ref(false);
    const isQuestionListShow = ref(false);
    const isQuizResultListShow = ref(false);
    const isQuizListAddLayerShow = ref(false);
    const isQstnAddShow = ref(false);
    const isQuizMngRsltDtlShow = ref(false);
    const isQuizDetailShow = ref(false);

    const distCrseSn = computed(() => store.state.quiz.distCrseSn);
    const {showMessage} = useAlert();
    const rsltItem = ref({});
    const quizItem = ref([]);
    const quizDtlSn = ref(0);
    const quizMngListEl = ref('');
    const quizDetailEl = ref('');
    const qstnItem = ref();
    let qstnDomain = {lrnTyEvlQstnSn: 0, lrnTyEvlMstSn: 0, qstnNo: 0};

    const choiceQuizTab = (type) => {
      if(type === 'list'){ // 퀴즈목록
        isQuizListShow.value = true;
        isQuestionListShow.value = false;
        isQuizResultListShow.value = false;
        isQuizDetailShow.value = false;
      }else if(type === 'question'){ // 문제지추가
        isQuestionListShow.value = true;
        isQuizListShow.value = false;
        isQuizResultListShow.value = false;
        isQuizDetailShow.value = false;
      }else if(type === 'result'){ // 퀴즈출제결과
        isQuizResultListShow.value = true;
        isQuizListShow.value = false;
        isQuestionListShow.value = false;
        isQuizDetailShow.value = false;
      }
    }

    // 퀴즈추가
    const insertQuiz = (quizTitle) => {
      if(quizTitle === ''){
        showMessage('퀴즈명을 입력해주세요.');
        return false;
      }
      store.dispatch(`quiz/${ACT_INSERT_QUIZ}`, {
        lrnEvlNm: quizTitle,
        evlKindCdDcd: '2048098',
        lrnEvlMstSn: distCrseSn.value, // 차수번호
      }).then(res => {
        document.getElementById('kb-header').style.display = '';
        document.getElementById('kb-prep-quiz').style.display = '';
        if(res.status.code === 200){
          isQuizListAddLayerShow.value = false;
          quizMngListEl.value.getQuizList();
        }
      }).catch(e => {
        console.error(e);
      });
    }

    // 퀴즈상세 화면변환
    const quizDetail = (item, seq) => {
      quizItem.value = item;
      quizDtlSn.value = seq; // 퀴즈번호
      qstnDomain.lrnTyEvlMstSn = seq;
      isQuizListShow.value = false; // 퀴즈목록 화면 비노출
      isQuizDetailShow.value = true; // 퀴즈 상세화면 노출
    }

    // 퀴즈결과 상세 레이어
    const rsltAddLayer = (item) => {
      rsltItem.value = item;
      isQuizMngRsltDtlShow.value = !isQuizMngRsltDtlShow.value;

      if(isQuizMngRsltDtlShow.value){
        document.getElementById('kb-header').style.display = 'none';
        document.getElementById('kb-prep-quiz').style.display = 'none';
      }else{
        quizMngListEl.value.getQuizList();
      }
    }

    // 퀴즈추가 레이어
    const quizAddLayer = () => {
      isQuizListAddLayerShow.value = !isQuizListAddLayerShow.value
      if(isQuizListAddLayerShow.value){
        document.getElementById('kb-header').style.display = 'none';
        document.getElementById('kb-prep-quiz').style.display = 'none';
      }
    }

    // 문항추가 레이어
    const qstnAddLayer = (item) => {
      if(item === undefined) qstnItem.value = qstnDomain
      else qstnItem.value = item;

      isQstnAddShow.value = true
      document.getElementById('kb-header').style.display = 'none'
      document.getElementById('kb-prep-quiz').style.display = 'none'
    }

    const closeLayer = () => {
      if(isQuizListShow.value){
        emit('update:isQuizMngShow', false);
      }else{
        isQuizListShow.value = true;
        isQuizDetailShow.value = false;
      }
    }

    return{
      isQuizListShow,
      isQuestionListShow,
      isQuizResultListShow,
      isQuizListAddLayerShow,
      isQstnAddShow,
      isQuizMngRsltDtlShow,
      isQuizDetailShow,
      rsltItem,
      quizMngListEl,
      quizDetailEl,
      quizItem,
      quizDtlSn,
      qstnItem,
      insertQuiz,
      quizDetail,
      quizAddLayer,
      qstnAddLayer,
      rsltAddLayer,
      choiceQuizTab,
      closeLayer,
    }
  }
}
</script>