<template>
  <div class="popup-container p-0 d-block" id="kb-mobile-popup" :class="{ 'is-active' : show }">
    <HrdCloudMobileHeader title="구독채널 상세" :show-back="true" :go-back-func="closeSubscribed">
      <template v-slot:right>
        <div class="util util-search">
          <a href="javascript:" class="util-actions util-actions-yellow util-actions-search" @click="handleUnsubscribe">
            구독해지
          </a>
        </div>
      </template>
    </HrdCloudMobileHeader>
    <main class="kb-main" id="kb-hrdcloud">
      <div class="main-content main-component">
        <HrdMobileSubscribeSettings />
        <HrdMobileSubscribeList
            :isLoading="isLoading"
            :items="items"
            :hrdCloudCateSnList="hrdCloudCateSnList"
            :checkAll="checkAll"
            :clickCheckAll="clickCheckAll"
            :clickUnCheckAll="clickUnCheckAll"
            :goSubsPage="goSubsPage"
            :paging="paging"
            :pagingFunc="pagingFunc"
            @changed="handleClick"
        />
      </div>
    </main>
  </div>
</template>

<script>
import HrdCloudMobileHeader from "@/components/hrdcloud/mobile/HrdCloudMobileHeader.vue";
import HrdMobileSubscribeSettings from "@/components/history/mobile/HrdMobileSubscribeSettings.vue";
import HrdMobileSubscribeList from "@/components/history/mobile/HrdMobileSubscribeList.vue";
import {hrdSubscriptionSetup} from "@/assets/js/modules/hrd/hrd-subscription-setup";

export default {
  name: "HrdCloudHistoryMobileSubscribedModal",
  components: {HrdMobileSubscribeList, HrdMobileSubscribeSettings, HrdCloudMobileHeader},
  setup: hrdSubscriptionSetup
}
</script>