<template>
  <div class="popup-container" :class="{ 'is-active' : modelValue }">
    <div class="popup" id="popup-wmpd-fund-item">
      <div class="popup-inner">
        <div class="popup-header">
          <h3 class="title">{{astDivNm}} 상품조회</h3>
        </div>
        <div class="popup-section">
          <div class="kb-form-fields">
            <div class="kb-form-row">
              <div class="kb-form-column kb-form-column-title">
                <label class="kb-form-label">
                  <span class="kb-form-label-text">상품코드</span>
                </label>
              </div>
              <div class="kb-form-column">
                <input v-model="search.krCode" type="text" class="kb-form-control" placeholder="상품코드를 입력하세요." @keyup.enter="searchFundItem">
              </div>
              <div class="kb-form-column kb-form-column-title">
                <label class="kb-form-label">
                  <span class="kb-form-label-text">상품명</span>
                </label>
              </div>
              <div class="kb-form-column">
                <input v-model="search.itemName" type="text" class="kb-form-control" placeholder="상품명을 입력하세요." @keyup.enter="searchFundItem">
              </div>
            </div>
          </div>
          <div class="section-bottom bottom-center">
            <button class="kb-btn kb-btn-lg kb-btn-primary" @click="searchFundItem"><strong class="text">검색</strong></button>
            <button class="kb-btn kb-btn-lg kb-btn-secondary" @click="closeModal"><strong class="text">닫기</strong></button>
          </div>
        </div>
        <div class="popup-section">
          <div class="kb-table kb-table-striped">
            <table>
              <thead>
                <tr>
                  <th>투자지역</th>
                  <th>상품코드</th>
                  <th>상품명</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="fundItemList.length > 0">
                  <tr v-for="(item, idx) in fundItemList" :key="idx" @click="clickData(item)">
                    <td>{{item.rgDivNm}}</td>
                    <td>{{item.krCode}}</td>
                    <td>{{item.itemName}}</td>
                  </tr>
                </template>
                <template v-else>
                  <tr><td colspan="3" class="td-center"><span class="td-text text-muted">검색 결과가 없습니다.</span></td></tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
  </div>
</template>

<script>
import {onMounted, reactive, ref} from "vue";
import {ACT_GET_ITEM_LIST} from "@/store/modules/otherdept/wmportfolio";
import {getItems, lengthCheck} from "@/assets/js/util";
import {useStore} from "vuex";
import {useAlert} from "@/assets/js/modules/common/alert";

export default {
  name: 'SearchFundItemModal',
  props: {
    modelValue: Boolean,
    astDivCd: String,
    astDivNm: String,
    krCode: String,
    itemName: String,
    itemDivCd: String,
    atdDivCd: String,
    setDataFunc: Function,
  },
  emits: ['update:modelValue', 'update:krCode', 'update:itemName'],
  setup(props, {emit}) {
    const { showMessage } = useAlert();
    const store = useStore();
    const fundItemList = ref([]);
    const search = reactive({
      astDivCd: props.astDivCd,
      atdDivCd: props.atdDivCd,
      itemDivCd: props.itemDivCd,
      krCode: '',
      itemName: ''
    });

    const closeModal = () => {
      emit('update:modelValue', false);
    }

    const clickData = (item) => {
      emit('update:krCode', item.krCode);
      emit('update:itemName', item.itemName);
      props.setDataFunc();
      closeModal();
    }

    const searchFundItem = () => {
        store.dispatch(`wmportfolio/${ACT_GET_ITEM_LIST}`,{
            itemDivCd: search.itemDivCd,
            krCode: search.krCode,
            itemName: search.itemName,
            astDivCd: search.astDivCd,
            atdDivCd: search.atdDivCd
        }).then(res => {
          if (lengthCheck(res)) {
            fundItemList.value = getItems(res);
          }else{
            showMessage('상품코드 또는 상품명을 확인해주십시오.');
          }
        }).catch(e => {
          console.error(e);
        });
    }

    onMounted(() => {
      searchFundItem();
    })

    return {
      fundItemList,
      search,
      closeModal,
      clickData,
      searchFundItem,
    }
  }
}
</script>