<template>
  <div class="home-section-row" style="flex-wrap: wrap;">
    <!-- home-section:section-learning -->
    <article v-for="(moveRoute, index) in sslMoveLink" class="home-section section-learning" style="flex: 1 1 auto;" :key="index">
      <div class="learning-box">
        <a href="javascript:" class="learning-link" @click="goLearn(moveRoute)"></a>
        <div class="learning-image">
          <img v-if="moveRoute.key === 'selfGa'" src="@/assets/lxp/images/ssl/home/quick/2024/artboard_bk_1.png" alt="">
          <img v-if="moveRoute.key === 'selfGi'" src="@/assets/lxp/images/ssl/home/quick/2024/artboard_bk_2.png" alt="">
          <img v-if="moveRoute.key === 'selfJa'" src="@/assets/lxp/images/ssl/home/quick/2024/artboard_bk_3.png" alt="">
          <img v-if="moveRoute.key === 'selfIct'" src="@/assets/lxp/images/ssl/home/quick/2024/artboard_bk_4.png" alt="">
          <img v-if="moveRoute.key === 'group'" src="@/assets/lxp/images/ssl/home/quick/2024/artboard_bk_5.png" alt="">
          <img v-if="moveRoute.key === 'book'" src="@/assets/lxp/images/ssl/home/quick/2024/artboard_bk_7.png" alt="">
          <img v-if="moveRoute.key === 'trivia'" src="@/assets/lxp/images/ssl/home/quick/2024/artboard_bk_6.png" alt="">
<!--          <img v-if="moveRoute.key === 'selfGa'" src="@/assets/lxp/images/ssl/home/img_quick_self.jpg" alt="">-->
<!--          <img v-else-if="moveRoute.key === 'selfGi'" src="@/assets/lxp/images/ssl/home/img_quick_alssl.jpg" alt="">-->
<!--          <img v-else-if="moveRoute.key === 'selfJa'" src="@/assets/lxp/images/ssl/home/img_quick_learning.jpg" alt="">-->
<!--          <img v-else-if="moveRoute.key === 'selfIct'" src="@/assets/lxp/images/ssl/home/img_quick_e.jpg" alt="">-->
<!--          <img v-else-if="moveRoute.key === 'group'" src="@/assets/lxp/images/ssl/home/img_quick_group.jpg" alt="">-->
<!--&lt;!&ndash;          <img v-else-if="moveRoute.key === 'hrd'" src="@/assets/lxp/images/ssl/home/img_quick_hrd.jpg" alt="">&ndash;&gt;-->
<!--          <img v-else-if="moveRoute.key === 'book'" src="@/assets/lxp/images/ssl/home/img_quick_book.jpg" alt="">-->
<!--          <img v-else-if="moveRoute.key === 'trivia'" src="@/assets/lxp/images/ssl/home/img_quick_college.jpg" alt="">-->
        </div>
      </div>
      <div>
<!--        <span class="ssl-bottom-title" v-if="moveRoute.key === 'selfGa'"><span style="font-size: 14px;-->
<!--    font-family: var(&#45;&#45;kb-font-KBFGDisplayB);">[셀프러닝]</span>&nbsp;가계여신</span>-->
<!--        <span class="ssl-bottom-title" v-else-if="moveRoute.key === 'selfGi'"><span style="font-size: 14px;-->
<!--    font-family: var(&#45;&#45;kb-font-KBFGDisplayB);">[셀프러닝]</span>&nbsp;기업여신/외환</span>-->
<!--        <span class="ssl-bottom-title" v-else-if="moveRoute.key === 'selfJa'"><span style="font-size: 14px;-->
<!--    font-family: var(&#45;&#45;kb-font-KBFGDisplayB);">[셀프러닝]</span>&nbsp;자산관리/퇴직연금</span>-->
<!--        <span class="ssl-bottom-title" v-else-if="moveRoute.key === 'selfIct'"><span style="font-size: 14px;-->
<!--    font-family: var(&#45;&#45;kb-font-KBFGDisplayB);">[셀프러닝]</span>&nbsp;ICT</span>-->
<!--        <span class="ssl-bottom-title" v-else>{{moveRoute.name}}</span>-->
        <p class="ssl-bottom-title" style="color:#545045;">{{moveRoute.name}}</p>
      </div>
    </article>
  </div>
  <SSLEnroll
      v-if="enroll.toggle"
      v-model:toggle="enroll.toggle"
      :dist-crse-cd="enroll.key"
  />

</template>
<script>

import {homeLinkSetup} from '@/assets/js/modules/ssl/ssl-home';
import SSLEnroll from '@/components/ssl/common/SSLEnroll';

export default {
  name: 'SSLLink',
  components: {SSLEnroll},
  setup: homeLinkSetup
}
</script>
