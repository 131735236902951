<template>
  <div class="ssl">
    <template v-if="!quizModal">
      <LxpMobileHeader style="z-index:1!important;" :headerClass="false">
        <template v-slot:title>
          <SSLMobileNav child-dist-crse-cd="group" />
        </template>
        <template v-slot:left>
          <div class="util util-history-back">
            <a href="javascript:" class="util-actions util-actions-history-back" @click="goBack"><i class="icon-history-back"></i></a>
          </div>
        </template>
        <template v-slot:right>
          <template v-if="activeMenu === 'groupInfo' && isLeader">
            <div class="util util-history-pencil">
              <router-link :to="{name: 'GroupLearningMng', params: {boardSn: items[0].boardSn}}" class="util-actions util-actions-pencil">
                관리
              </router-link>
            </div>
          </template>
          <template v-if="activeMenu === 'groupClass' && isLeader">
            <div class="util util-history-pencil">
              <a href="javascript:" class="util-actions util-actions-pencil" @click="clickContentsMng">
                <i class="icon-pencil"></i>
              </a>
            </div>
            <div class="util util-history-setting">
              <a href="javascript:" class="util-actions util-actions-setting" @click="clickQuiz">
                <i class="icon-setting"></i>
              </a>
            </div>
          </template>
        </template>
      </LxpMobileHeader>
      <main class="kb-main" id="kb-prep">
        <!-- main-content -->
        <div class="main-content">
          <article class="content-section section-padding" style="padding-top:unset !important;">
            <SSLMobileMyTop child-dist-crse-cd="group"/>
          </article>

          <div class="content-section">
            <div class="section-tabs justify-content-center">
              <a href="javascript:" class="tab" :class="{'is-active' : activeMenu === 'groupInfo'}" @click="clickStatusTab('groupInfo')">그룹현황</a>
              <a href="javascript:" class="tab" :class="{'is-active' : activeMenu === 'groupClass'}" @click="clickStatusTab('groupClass')">모임현황</a>
            </div>
          </div>
          <MobileGroupStatus v-if="activeMenu === 'groupInfo'" :items="items" :is-leader="isLeader"/>
          <MobileMeetingStatus v-else-if="activeMenu === 'groupClass'" />
        </div>
        <!-- //main-content -->
      </main>
    </template>
      <!-- 퀴즈관리 -->
      <MobileQuizMng v-if="quizModal" v-model:isQuizMngShow="quizModal"/>
  </div>
</template>
<style scoped>
@import '../../../../assets/custom/ssl.custom2.css';
</style>
<script>
import LxpMobileHeader from '@/views/layout/lxp/header/mobile/LxpMobileHeader.vue';
import {groupViewSetup} from '@/assets/js/modules/groupLearing/group-view-setup';
import MobileGroupStatus from '@/components/prep/group/mobile/MobileGroupStatus.vue';
import MobileMeetingStatus from '@/components/prep/group/mobile/MobileMeetingStatus.vue';
import SSLMobileNav from '../../../../components/ssl/mobile/SSLMobileNav.vue';
import SSLMobileMyTop from '../../../../components/ssl/my/mobile/SSLMobileMyTop.vue';
import MobileQuizMng from '@/components/quiz/mobile/MobileQuizMng.vue';

export default {
  name: "MobileGroupView",
  components: {
    MobileQuizMng,
    SSLMobileMyTop,
    SSLMobileNav,
    MobileMeetingStatus, MobileGroupStatus, LxpMobileHeader},
  setup: groupViewSetup
}
</script>