<template>
  <input v-if="maxQty > 1"
         ref="uploadFiles"
         type="file"

         class="kb-form-file-input"
         :accept="mimeType"
         multiple @change="addFile"
  >
  <input v-else
         ref="uploadFiles"
         type="file"
         class="kb-form-file-input"
         :accept="mimeType"
         @change="addFile"
  >
</template>
<script>
import {useAlert} from '@/assets/js/modules/common/alert';
import {computed, ref, toRefs} from 'vue';
import {checkExtension, fileLengthCheck, getTotalSize} from '@/assets/js/file-util';

export default {
  name: 'InputUpload',
  props: {
    modelValue: {
      type: Object,
      default: () => ({
        files: [],
        removed: []
      })
    },
    maxQty: {
      type: Number,
      required: false,
      default: 0
    },
    maxSize: {
      type: Number,
      required: false,
      default: 0
    },
    sequenceKey: {
      type: String,
      required: true
    },
    extensions: {
      type: Array,
      required: false,
      default: () => ([])
    },
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const {showMessage} = useAlert();
    const {modelValue, extensions, maxQty, maxSize, sequenceKey} = toRefs(props);

    const mimeType = computed(() => {
      // if (props.type === 'image')
      return (props.extensions.length > 0) ? props.extensions.map(ext => '.'+ext).join(', ') : 'image/*';
    });

    const uploadFiles = ref(null);

    const totalFileSize = computed(() => { return getTotalSize(modelValue.value.files); });

    const fileLimitTxt = computed(() => {
      const texts = [];
      if(maxSize.value > 0){
        texts.push(`파일크기 최대 ${maxSize.value}MB`)
      }
      if(maxQty.value > 0){
        texts.push(`${maxQty.value}개의 파일을 업로드 할 수 있습니다.`)
      }
      return texts.join(', ');
    });

    const addFile = () => {
      if (fileLengthCheck(uploadFiles)) {
        if (extensions.value.length > 0) {
          if (checkExtension(uploadFiles.value.files, extensions.value)) {
            showMessage(`확장자가 ${extensions.value.join(', ')} 인 파일만 등록 가능합니다.`);
            return;
          }
        }

        if(maxQty.value > 0){
          if ((modelValue.value.files.length + uploadFiles.value.files.length) > maxQty.value) {
            showMessage(`첨부파일은 최대 ${maxQty.value}개까지 등록 가능합니다.`);
            uploadFiles.value.value = null;
            return;
          }
        }

        if(maxSize.value > 0){
          // 파일 사이즈 체크
          if (totalFileSize.value + getTotalSize(uploadFiles.value.files) > 1024 * 1024 * maxSize.value) {
            showMessage(`파일 사이즈는 최대 ${maxSize.value}MB 입니다.`);
            uploadFiles.value.value = null;
            return;
          }
        }

        const files = [];
        for(let i=0; i < uploadFiles.value.files.length; i++){
          files.push(uploadFiles.value.files[i]);
        }
        const params = {
          files: [...modelValue.value.files, ...files],
          removed: modelValue.value.removed
        };
        params['binaries'] = params.files.filter(x => !x[sequenceKey.value]);

        emit('update:modelValue', params);
        uploadFiles.value.value = null;
      }
    };

    return {
      mimeType,
      uploadFiles, fileLimitTxt,
      addFile
    }
  }
};
</script>