<template>
  <div v-if="isMobile" class="kb-footer lxp-mobile-layout" style="margin-top:1em; margin-bottom:1em;">
    <div class="footer-row">
      <div class="footer-notice footer-component section-notice">
        <ul class="notice-list swiper-wrapper" style="width: 80%;">
          <swiper
              :slides-per-view="1"
              :initial-slide="0"
              :modules="modules"
              :navigation="{ nextEl: '.kb-btn-hrdnotice-kb-nav-next'}"
              :loop="false"
              :autoplay="{delay: 3000, disableOnInteraction: false}"
              @swiper="onSwiper"
              @slideChange="onSwiperSlide"
          >
            <swiper-slide v-for="(item, idx) in items" :key="idx" class="notice-item swiper-slide"
                          style="margin: 0; display:flex;align-items:center;height:68px;">
              <div class="notice-date">
                <a href="javascript:" class="link">{{ item.regDt }}</a>
              </div>
              <div class="notice-title">
                <router-link :to="{name: 'HelpNoticeView', params: {ntcSn: item.comNtcSn}}" class="link">
                  {{ item.ntcTitle }}
                </router-link>
              </div>
            </swiper-slide>
            <!--            </swiper-slide>-->
          </swiper>
        </ul>
        <div class="notice-more">
          <router-link :to="{name: 'pubNotice', params:{expsrLoc:expsrLocCds.EXPSR_LOC_CD_HRDCLOUD_MAIN}}">전체보기</router-link>
          <div class="nav">
<!--            <button class="kb-btn-nav kb-btn-kb-nav-next kb-btn-hrdnotice-kb-nav-next"><i class="icon-arrow-next"></i></button>-->
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="!isMobile" class="notice-box">
      <div class="footer-row">
        <div class="footer-notice footer-component">
          <ul class="notice-list swiper-wrapper" style="width: 80%;">
            <swiper
                :slides-per-view="1"
                :initial-slide="0"
                :modules="modules"
                :navigation="{nextEl: '.kb-btn-hrdnotice-kb-nav-next',prevEl: '.kb-btn-hrdnotice-kb-nav-prev'}"
                :loop="false"
                :autoplay="{delay: 3000, disableOnInteraction: false}"
                @swiper="onSwiper"
                @slideChange="onSwiperSlide"
            >
              <swiper-slide v-for="(item, idx) in items" :key="idx" class="notice-item swiper-slide"
                            style="margin: 0; display:flex;align-items:center;height:68px;">
                <div class="notice-date">
                  <a href="javascript:" class="link">{{ item.regDt }}</a>
                </div>
                <div class="notice-title">
                  <router-link :to="{name: 'HelpNoticeView', params: {ntcSn: item.comNtcSn}}" class="link">
                    {{ item.ntcTitle }}
                  </router-link>
                </div>
              </swiper-slide>
              <!--            </swiper-slide>-->
            </swiper>
          </ul>
          <div class="notice-nav">
            <!--              <router-link to="" class="more-link">공지 전체보기</router-link>-->
<!--            TODO: slide nav 2회 반영으로 1,3,5 형태로 보여짐 수정필요-->
            <router-link :to="{name: 'pubNotice', params:{expsrLoc:expsrLocCds.EXPSR_LOC_CD_HRDCLOUD_MAIN}}">전체보기</router-link>
            <div class="nav">
              <button class="kb-btn-nav kb-btn-kb-nav-prev kb-btn-hrdnotice-kb-nav-prev"><i class="icon-arrow-prev"></i></button>
              <button class="kb-btn-nav kb-btn-kb-nav-next kb-btn-hrdnotice-kb-nav-next"><i class="icon-arrow-next"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import {useStore} from "vuex";
import {computed, ref} from "vue";
import {dateToDateFormat, getItems, lengthCheck, timestampToDateFormat} from "@/assets/js/util";
import {expsrLocCds} from "@/assets/js/modules/help/help-common";
import {Navigation, Autoplay} from "swiper";
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import {ACT_GET_HELP_NOTICE_LIST} from "@/store/modules/help/help";
export default {
  name: "HrdCloudNotice",
  components: {
    Swiper, SwiperSlide
  },
  setup() {
    const store = useStore();
    const items = ref([]);
    const currentIdx = ref(0);
    const currentNotice = computed(() => {
      if(items.value[currentIdx.value]){
        return items.value[currentIdx.value];
      }
      return null;
    });
    // SwiperCore.use([Navigation, Autoplay]);
    const isMobile = computed(() => store.state.auth.isMobile);

    // const session = computed(() => store.state.auth.session);

    // const slideHeight = ref(0);
    // const slideStyle = computed(() => {
    //   if(isMobile.value){
    //     return {'height': `${slideHeight.value}px`}
    //   }
    //   return {}
    // });


    const getHomeNoticeList = () => {

      store.dispatch(`help/${ACT_GET_HELP_NOTICE_LIST}`, {
        nowDate: dateToDateFormat(new Date(), 'yyyy-MM-dd'),
        expsrLocCd: expsrLocCds.EXPSR_LOC_CD_HRDCLOUD_MAIN,
        pageNo: 0,
        pageSize: 5
      }).then(res => {
        // console.log("THEN", res)
        if(lengthCheck(res)){
          items.value = getItems(res).map(x => ({
            ...x,
            regDt: timestampToDateFormat(x.regDt, 'yyyy.MM.dd')
          }));
          // console.log(currentNotice);
        }else{
          items.value = [];
        }
      }).catch(() => {
      });
    }
    //
    // const slideButton = (isNext) => {
    //   if(isNext){
    //     const nextIdx = currentIdx.value + 1;
    //     if(nextIdx > (items.value.length - 1)){
    //       currentIdx.value = 0;
    //     }else{
    //       currentIdx.value = nextIdx;
    //     }
    //   }else{
    //     const prevIdx = currentIdx.value -1;
    //     if(prevIdx < 0){
    //       currentIdx.value = (items.value.length - 1);
    //     }else{
    //       currentIdx.value = prevIdx;
    //     }
    //   }
    // }


    const onSwiper = (swiper) =>{
      console.log('init swiper', swiper);
    }
    const onSwiperSlide = () =>{
      console.log('slide change');
    }

    getHomeNoticeList();

    return {
      currentNotice,
      modules: [Navigation, Autoplay],
      items,
      onSwiper,
      onSwiperSlide,
      isMobile,
      expsrLocCds
    }

  }

}
</script>
<style scoped>
.notice-box {border: 1px solid var(--kb-border-color); border-radius: 12px; margin-top:-2em; margin-bottom:1em;}
.footer-notice {display:flex;align-items:center;}
.footer-notice .notice-list {flex:1 1 auto}
.notice-item {display:flex;align-items:center;height:68px;}
.footer-notice .notice-date {margin-right:25px}
.footer-notice .notice-title {overflow:hidden;text-overflow:ellipsis;white-space:nowrap;max-width:1290px}
.footer-notice .link, .footer-notice .more-link {font:normal 1rem/1.25 var(--kb-font-KBFGTextB);color:var(--kb-black)}
.footer-notice .link:hover, .footer-notice .more-link:hover {color:var(--kb-yellow)}
.footer-notice .notice-nav {flex:0.2 0 auto;display:flex;align-items:center;}
.footer-notice .notice-nav .nav {margin-left:var(--kb-spacer-5);display:flex;}
.footer-notice .notice-nav .kb-btn-nav {width:32px;height:32px;background-color:transparent;border-radius:var(--kb-border-radius)}
.footer-notice .notice-nav .icon-arrow-prev {width:24px;height:24px;background:url(../../assets/lxp/images/common/ic_arrow24_left.svg)}
.footer-notice .notice-nav .icon-arrow-next {width:24px;height:24px;background:url(../../assets/lxp/images/common/ic_arrow24_right.svg)}
.footer-notice .notice-nav .kb-btn-nav:hover {background-color:var(--kb-light-silver)}
.footer-notice .notice-nav .kb-btn-nav ~ .kb-btn-nav {margin-left:var(--kb-spacer-2)}

.notice-list-link {text-align: center; margin-top:4px;}
.notice-list-link a {color:var(--ssl-yellow); font-weight: bold;line-height:18px}
.notice-more-link {color:#545045;font-family: var(--kb-font-KBFGTextB);transition:color .3s;}
.notice-more-link:hover {color:var(--kb-gold)}
/*.lxp-mobile-layout .footer-notice .notice-more {flex:0 0 60px;font-size:12px; font-weight:bold; color:var(--kb-silver)}*/
.lxp-mobile-layout .footer-notice .more-link {font-size:12px; font-weight:bold; color:var(--kb-yellow); margin-top:2px;}
.lxp-mobile-layout .section-notice .notice-more {display: inline-flex;width:75px;height:34px; align-items: center; padding: 0 8px;justify-content: center; letter-spacing: -1px; font-weight: bold; color: var(--ssl-dark-gray); background-color: var(--ssl-background-gray); border-radius: 17px; }
.lxp-mobile-layout .footer-notice .link, .footer-notice .more-link {font-size:14px; color:var(--kb-dark-brown)}
.lxp-mobile-layout .footer-notice .link:hover, .footer-notice .more-link:hover {color:var(--kb-yellow)}
.lxp-mobile-layout .footer-notice .notice-date {margin-right:8px; font-size:10px; color:var(--kb-yellow)}

</style>