<template>
  <div class="content-source">
    <template v-if="distCrseCd === 'self'">
      <p class="source-text">{{ myLrnTrgtGrp.lrnTrgtGrpNm }}</p>
    </template>

    <template v-else-if="distCrseCd === 'group'">
      <p v-if="relInfos.length > 1" class="source-text">{{ myLrnTrgtGrp.lrnTrgtGrpNm }} {{ relInfos[0] ? `・ ${relInfos[0]}` : ''}}</p>
      <p v-if="relInfos.length > 2" class="source-text text-muted">{{ relInfos[1] }}, {{ relInfos[2] }}</p>
    </template>

    <template v-else-if="distCrseCd === 'knowledge'">
      <div v-if="relInfos.length > 0" class="image-box">
        <div class="image kb-mouse-cursor" @click="clickOpenRel">
          <img src="@/assets/lxp/images/ssl/myssl/@img_jisike.png" alt="">
        </div>
        <p class="source-text"><span class="text">{{ relInfos[0] }}</span></p>
      </div>
    </template>

    <template v-else-if="distCrseCd === 'hrd'">
      <div v-if="relInfos.length > 2" class="image-box">
        <div class="image kb-mouse-cursor">
          <CommonTypeImage
              :sequence="relInfos[3]"
              :img-src="relInfos[0]"
              :target="relInfos"
              @clickImage="goToHrd"
          />
        </div>
        <p class="source-text">
          <span class="text">{{ relInfos[2] }}</span>
          <span class="text">{{ relInfos[1] }}</span>
        </p>
      </div>
    </template>

    <template v-else-if="distCrseCd === 'book'">
      <div v-if="relInfos.length > 0" class="image-box">
        <template v-if="relInfos[0] === '자유도서'">
          <div class="image image-book">
            <img src="@/assets/lxp/images/ssl/myssl/img_book_kb.jpg" alt="">
          </div>
          <p class="source-text"><span class="text">자유도서</span></p>
        </template>
        <template v-else>
          <div v-if="relInfos.length > 2" class="image image-book">
            <img :src="relInfos[2]" alt="">
          </div>
          <p class="source-text"><span class="text">{{ relInfos[0] }}</span></p>
        </template>
      </div>
    </template>

    <template v-else-if="distCrseCd === 'live'">
      <p v-if="relInfos.length > 0" class="source-text">{{ relInfos[0]}}</p>
      <p v-if="relInfos.length > 2" class="source-text text-muted">{{ relInfos[2] }}</p>
    </template>

    <template v-else-if="relInfos.length > 4">
      <div class="image-box" >
        <div class="image kb-mouse-cursor" :class="{'image-book': relInfos[0] === 'ssl'}" title="콘텐츠 보기" @click.stop="$router.push({path:refUrl})">
          <CommonTypeImage :img-src="relInfos[1]" :theme="relInfos[0]" />
        </div>
        <p class="source-text">
          <span class="text">{{relInfos[2]}}</span>
          <span class="text text-muted">{{relInfos[3]}}</span>
        </p>
        <button class="btn" @click.stop="clickDeleteRel" title="연결 삭제"><i class="icon-tag-delete" /></button>
      </div>
    </template>

  </div>
</template>
<script>


import {writeRelSetup} from "@/assets/js/modules/ssl/ssl-write";
import CommonTypeImage from '@/components/common/CommonTypeImage';

export default {
  name: 'SSLWriteRel',
  components: {CommonTypeImage},
  props: {
    socLrnPostSn: Number,
    refPost: String,
    refUrl: String
  },
  emits: ['update:refPost', 'update:refUrl'],
  setup: writeRelSetup
}
</script>
