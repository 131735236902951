<template>
  <div class="header-snb">
    <nav class="snb">
      <ul class="snb-list">
        <li class="snb-item" v-if="showSelectAll">
          <a href="javascript:" class="snb-link" :class="{ 'is-active' : selectedYear === '' }"
             @click="handleSelectYear('')">
            <span class="snb-text">전체</span>
          </a>
        </li>
        <li class="snb-item" v-for="(item,idx) in getLastCntYears(yearCnt)" :key="idx">
          <button v-if="isMobile" :class="{ 'is-active' : selectedYear == item}"  @click="handleSelectYear(item)">{{ item }}</button>
          <a v-else href="javascript:" class="snb-link" :class="{ 'is-active' : selectedYear == item }">
            <span class="snb-text" @click="handleSelectYear(item)">{{ item }}</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import {getLastCntYears, initProgressBar, setParamsAndRemoveEmptyOfQueryString} from '@/assets/js/util';
import {computed, onBeforeMount, ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import navigationUtils from '@/assets/js/navigationUtils';
import {setYearShare} from "@/assets/js/modules/hrd/hrd-common";

export default {
  name: 'HistoryYearSelector',
  props: {
    modelValue: {
      type: [String, Number]
    },
    showSelectAll: Boolean,
    yearCnt: Number,
    useQueryString: {
      type:Boolean,
      default: true
    }
  },
  setup(props, {emit}) {
    const route = useRoute();
    const router = useRouter();
    const selectedYear = computed(() => props.modelValue);

    const years = ref([]);

    const handleSelectYear = (year) => {
      /**
       * QueryString에 연도추가
       * */
      if (props.useQueryString) {
        let queryString = {};
        setParamsAndRemoveEmptyOfQueryString(route, queryString, false);
        if (queryString) {
          if (year > 0) {
            queryString.year = year;
          } else {
            delete queryString['year'];
          }
        } else {
          queryString = null;
        }
        router.push({'query': queryString});
      }

      emit('update:modelValue', year);
      setYearShare(year);
      initProgressBar();
    };

    onBeforeMount(() => {
      if (props.useQueryString && route.query && route.query.year) {
        emit('update:modelValue', route.query.year);
        setYearShare(route.query.year);
      }
    });

    return {
      selectedYear,
      years,
      getLastCntYears,
      handleSelectYear,
      isMobile: navigationUtils.any()
    }
  }
};
</script>