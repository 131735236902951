import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {useAlert} from "@/assets/js/modules/common/alert";
import {computed, onMounted, reactive, ref, watch} from "vue";
import {notiPfmCds} from "@/assets/js/modules/noti/noti-common";
import {
    ACT_DELETE_GROUP_LRN_BOARD,
    ACT_GET_GROUP_BOARD_TRGT,
    ACT_GET_GROUP_LRN_BOARD_MNG,
    ACT_INSERT_GROUP_LRN_BOARD,
    ACT_UPDATE_GROUP_LRN_BOARD
} from "@/store/modules/board/board";
import {getItem, getItems, getResult, isSuccess, lengthCheck, setParams, timestampToDateFormat} from "@/assets/js/util";
import {ACT_GET_AUTH_INFO} from "@/store/modules/auth/auth";

export const groupClassEditSetup = () => {

    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const {showMessage, showConfirm} = useAlert();


    const toggles = reactive({
        works: false,
        now: false,
        hope: false,
        date: false,
    });

    const distCrseSn = computed(()=> store.state.board.distCrseSn);

    const useNotiTextYn = ref(false);

    const hourToggle = ref(false);
    const minToggle = ref(false);
    const lrnDtToggle = ref(false);

    const classDate = ref(null);
    const notiMsgLengthCnt = ref(0);
    // const lrnRvwUseYn = ref(false);

    const classStartHour = ref([
        {bgngHourNm: '18시', bgngHour: '18'},
        {bgngHourNm: '19시', bgngHour: '19'},
        {bgngHourNm: '20시', bgngHour: '20'},
        {bgngHourNm: '21시', bgngHour: '21'}
    ]);

    const classStartMin = ref([
        {bgngMinNm: '00분', bgngMin: '00'},
        {bgngMinNm: '10분', bgngMin: '10'},
        {bgngMinNm: '20분', bgngMin: '20'},
        {bgngMinNm: '30분', bgngMin: '30'},
        {bgngMinNm: '40분', bgngMin: '40'},
        {bgngMinNm: '50분', bgngMin: '50'},
    ]);

    const lrnDt = ref([
        {lrnDtNm: '40분', lrnDt: '40'},
        {lrnDtNm: '1시간', lrnDt: '60'},
        {lrnDtNm: '1시간 30분', lrnDt: '90'},
    ]);

    const notiPfmCd = ref([notiPfmCds.liiv , notiPfmCds.wiseNet]);

    const notiParams = reactive({
        notiMngSn: 0,
        notiTitle: '그룹 모임 공유',
        notiSbjt: '',
        notiDesc: '그룹 모임 공유',
        sndrNm: 'HR부(인재개발)',
        notiTrprCdDcd : '2039001',
        notiTyCdDcd: '2032001', //'2032002',
        retrInclYn: 'N', // 퇴직자 포함 여부
        rsvtTrsmYn: 'N', // 예약발송여부
        rsvtTrsmDt: 0,
        aprvrId: '',
        aprvrNm: '',

        smsNotiYn: 'N',
        insdMemoYn: 'Y', //사내쪽지
        pushNotiYn: 'N',
        wiseEmlNotiYn: 'N', // 사내 메일
        emlNotiYn: 'N',
        kakaoNotiYn: 'N',
        chatBotNotiYn: 'N',
        liivNotiYn: 'Y',
        trnNotiYn: 'N',
        tmplYn: 'N',
        stt: '00',
        channelName: 'study',
        imgUrl: '',
        division: 'groupLrn',
        moveUrl: '',
        distCrseSn: 0,
        notiMsgDomains : [],
        notiTrgtDomains : [],
    })

    const params = reactive({
        boardSn: route.params.boardSn,
        distCrseSn: 0,
        lrnObjDtlDistSn: 0,
        objNm: '',
        lrnObjTyCd: '100020',
        bgngYmd: '',
        bgngHour: '18',
        bgngMin: '00',
        lrnDt: '40',
        // lrnRvwUseYn: '',
        notiMsg: '',
        notiMngDomain: notiParams
    });

    const mblTelNo = ref('');
    const item = ref({});
    const isLoading = ref(false);

    const getBoard = () => {
        store.dispatch(`board/${ACT_GET_GROUP_LRN_BOARD_MNG}`, {
            boardSn:route.params.boardSn,
            lrnPostSn: route.params.lrnPostSn
        }).then(res => {
            if(lengthCheck(res)) {
                item.value = getItem(res);
                setParams(params, item.value);

                if(item.value.bgngLrn) {
                    showMessage('이미 학습이 시작 된 모임입니다.', ()=>{
                        router.push({name: 'GroupView', params: {distCrseSn: distCrseSn.value}});
                    });
                }

                const ymdArray = item.value.bgngYmd.split('-');

                classDate.value ={
                    year: ymdArray[0],
                    month: ymdArray[1],
                    day: ymdArray[2]
                };
                isLoading.value = false;
            }
        }).catch(e => {
            console.error(e);
        });
    }

    // 멤버현황
    const getMemberInfoList = () => {
        store.dispatch(`board/${ACT_GET_GROUP_BOARD_TRGT}`, distCrseSn.value).then(res => {
            if (lengthCheck(res)) {

                const items = getItems(res);
                items.forEach(x => {
                    notiParams.notiTrgtDomains.push({
                        trprId: x.lrnerId,
                        trprSn: 0,
                        trprNm: x.lrnerNm,
                        trprTelno: null
                    });
                });

            } else {
                notiParams.notiTrgtDomains = [];
            }
        }).catch(e => {
            console.error(e);
        })
    }

    const getAuthInfo = () => {
        store.dispatch(`auth/${ACT_GET_AUTH_INFO}`).then(res => {
            if(lengthCheck(res)) {
                const item = getItem(res);
                mblTelNo.value = item.mblTelno;
            }
        }).catch(e => {
            console.error(e);
        });
    }

    onMounted(()=>{
        if(route.params.lrnPostSn > 0) getBoard();
        getAuthInfo();
        getMemberInfoList();
    });

    const notiMsg = (title, msg, type) => {
        if(type === 'ins') {
            notiParams.imgUrl = `${process.env.VUE_APP_CLOUDFRONT_URL}/contents/group/moim_insert.jpg`;
        } else if(type === 'ups') {
            notiParams.imgUrl = `${process.env.VUE_APP_CLOUDFRONT_URL}/contents/group/moim_update.jpg`;
        }

        params.bgngYmd = classDate.value.ymdformat ? classDate.value.ymdformat : params.bgngYmd;
        params.objBgngDt = new Date(`${params.bgngYmd} ${params.bgngHour}:${params.bgngMin}`).getTime();

        const bgngTime = new Date(`${params.bgngYmd} ${params.bgngHour}:${params.bgngMin}:00`);
        lrnTime.value = timestampToDateFormat(bgngTime.setMinutes(bgngTime.getMinutes() + Number(params.lrnDt ? params.lrnDt : '40')), 'hh:mm');

        notiPfmCd.value.forEach(x => {
            const lineBreak = x === notiPfmCds.wiseNet ? '<br/>' : '\\n';

            if(x === notiPfmCds.wiseNet) {
                notiParams.notiTitle = `[그룹러닝] ${title}`;
                msg = msg.replaceAll('\\n','<br/>');
            }

            const notiMessage = `${msg}되었습니다.${lineBreak}${lineBreak}- 모임주제: ${params.objNm} ${lineBreak}- 모임일시: ${params.bgngYmd} ${params.bgngHour}:${params.bgngMin}~${lrnTime.value}`
                + `${params.notiMsg ? `${lineBreak}${lineBreak}${params.notiMsg}` : ''}`;

            notiParams.distCrseSn = distCrseSn.value;
            notiParams.notiMsgDomains.push({
                notiTitle: notiParams.notiTitle,
                notiPfmCdDcd : x,
                notiCn: notiMessage
            });
        });
    };

    const lrnTime = ref('');

    const insertGroupLrnMntr =  () => {
        notiMsg('모임 등록 안내','[모임 등록]\\n새로운 모임이 등록', 'ins');

        store.dispatch(`board/${ACT_INSERT_GROUP_LRN_BOARD}`, params).then(res => {
            if(isSuccess(res)){
                showMessage({
                    text: '모임이 등록되었습니다.',
                    callback: () => {
                        router.push({name: 'GroupView', params: {distCrseSn: distCrseSn.value}})
                    }
                });
            } else {
                showMessage(getResult(res).message);
            }
        }).catch(e => {
            console.error(e);
        });
    };

    const updateGroupLrnMntr = () => {
        notiMsg('모임 변경 안내','[모임 변경]\\n모임 일정이 변경','ups');

        store.dispatch(`board/${ACT_UPDATE_GROUP_LRN_BOARD}`, {
            lrnPostSn: route.params.lrnPostSn,
            params: params
        }).then(res => {
            if(isSuccess(res)){
                showMessage({
                    text: '모임이 저장되었습니다.',
                    callback: () => {
                        router.push({name: 'GroupView', params: {distCrseSn: distCrseSn.value}})
                    }
                });
            } else {
                showMessage(getResult(res).message);
            }
        }).catch(e => {
            console.error(e);
        });
    };

    const deleteGroupLrnMntr = () => {
        notiMsg('모임 취소 안내','[모임 취소]\\n모임 일정이 취소','ups');

        store.dispatch(`board/${ACT_DELETE_GROUP_LRN_BOARD}`, {
            lrnPostSn: route.params.lrnPostSn,
            params: params
        }).then(res => {
            if(isSuccess(res)){
                showMessage({
                    text: '모임이 삭제되었습니다.',
                    callback: () => {
                        router.push({name: 'GroupView', params: {distCrseSn: distCrseSn.value}})
                    }
                });
            }
        });
    }

    const validate = () => {
        let result = false;
        if(!params.objNm || !params.lrnObjTyCd || !classDate.value) {
            showMessage('모임 등록을 위한<br/> 필수 입력 내용을 작성해주세요.');
        } else {
            result = true;
        }
        return result;
    }

    const clickSave = () => {
        if(validate()) {
            showConfirm({
                text: `입력한 정보로 모임을<br/> ${route.params.lrnPostSn > 0 ? '저장' : '등록'} 하시겠습니까?<br/>(멤버들에게 해당 모임 정보가 발송됩니다.)`,
                callback: () => {
                    if(route.params.lrnPostSn > 0) {
                        updateGroupLrnMntr();
                    } else {
                        insertGroupLrnMntr();
                    }
                }
            });
        }
    };

    const clickDelete = () => {
        showConfirm({
            text: `해당 모임을 삭제 하시겠습니까? <br/>(멤버들에게 해당 모임 삭제 안내가 발송됩니다.)`,
            callback: () => {
                deleteGroupLrnMntr();
            }
        });
    };


    watch(() => params.notiMsg, () => {
        notiMsgLengthCnt.value = params.notiMsg.length;

        if(notiMsgLengthCnt.value > 300) {
            params.notiMsg.substring(0, 300);
        }
    });

    return {
        params,
        toggles,
        classDate,
        classStartHour,
        classStartMin,
        lrnDt,
        hourToggle,
        minToggle,
        lrnDtToggle,
        useNotiTextYn,
        mblTelNo,
        notiMsgLengthCnt,
        // lrnRvwUseYn,
        distCrseSn,
        clickSave,
        clickDelete
    }
}