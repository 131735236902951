import LxpLayout from "@/views/layout/lxp/LxpLayout";

const evaluationRoutes = [
  {
    path: '/evaluation',
    component: LxpLayout,
    children: [
      {
        path: 'sample',
        name: "EvaluationSample",
        component: () => import('../../views/pages/evaluation/EvaluationSample'),
      }
    ],
  }
];

export const setEvaluationRoutes = (routes) => {
  routes.push(...evaluationRoutes);
}