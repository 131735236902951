<template>
  <aside class="page-aside">
    <section class="aside-member">
      <header class="aside-member-header">
        <h3 class="title">참가{{ isGroup ? '그룹' : '인원' }} <em class="count">{{ refinedItems.length }}</em></h3>
      </header>
      <div class="aside-member-body">
        <div class="member-search">
          <div class="member-search-field">
            <input v-model.trim="keyword" type="text" class="kb-form-control" :placeholder="`참가${isGroup ? '그룹' : '인원'} 검색`" />
            <button class="kb-btn-search">
              <i class="icon-search"></i>
            </button>
          </div>
        </div>

        <div class="member-mask">
          <ul ref="membersEl" class="member-list">
            <template v-if="isGroup">
              <li v-for="(item, index) in renderItems" class="member-item" :key="index">
                <div class="member">
                  <router-link :to="{name:'SSLLearnerBoard', params: {lrnerId: item.leaderLrnerId}}" class="member-link"></router-link>
                  <div v-if="myGroup.distCrseSn > 0 && myGroup.distCrseSn === item.distCrseSn" class="member-badge">
                    <div class="badge badge-primary"><span class="badge-text">MY</span></div>
                  </div>
                  <div class="member-avatar">
                    <CommonTypeImage
                        :img-src="item.leaderImgUrl"
                        theme="profile"
                    />
                  </div>
                  <div class="member-content">
                    <strong class="name">{{ item.crseNm }}</strong>
                    <p class="team">{{ item.distCrseLrnFldNm }} · {{ item.leaderLrnerNm }}</p>
                  </div>

                  <div class="member-follow" :class="{'is-active': item.subscribeSts > 0}">
                    <button class="kb-btn-follow" :class="{'kb-btn-follow-friends': item.subscribeSts === 1, 'kb-btn-follow-mate': item.subscribeSts === 2}" @click="unSubscribe(item)">
                      <span class="text">{{ item.subscribeSts === 2 ? '메이트' : '프렌즈' }}</span>
                    </button>
                  </div>

                </div>
              </li>
            </template>
            <template v-else>
              <li v-for="(item, index) in renderItems" class="member-item" :key="index">
                <div class="member">
                  <router-link :to="{name:'SSLLearnerBoard', params: {lrnerId: item.lrnerId}}" class="member-link"></router-link>
                  <div class="member-avatar">
                    <CommonTypeImage
                        :img-src="item.imgUrl"
                        theme="profile"
                    />
                  </div>

                  <div class="member-content">
                    <strong class="name">{{ item.lrnerNm }}</strong>
                    <p class="team">{{ item.deptNm }}</p>
                  </div>
                  <div class="member-follow" :class="{'is-active': item.subscribeSts > 0}">
                    <button class="kb-btn-follow" :class="{'kb-btn-follow-friends': item.subscribeSts === 1, 'kb-btn-follow-mate': item.subscribeSts === 2}" @click="unSubscribe(item)">
                      <span class="text">{{ item.subscribeSts === 2 ? '메이트' : '프렌즈' }}</span>
                    </button>
                  </div>
                </div>
              </li>
            </template>

          </ul>
        </div>
      </div>
    </section>
  </aside>
</template>
<script>

import {sslMemberSetup} from '@/assets/js/modules/ssl/setup/ssl-learn-setup';
import CommonTypeImage from '@/components/common/CommonTypeImage';

export default {
  name: 'SSLMember',
  components: {CommonTypeImage},
  props: {
    lrnTrgtGrpMstSn: Number
  },
  setup: sslMemberSetup
}
</script>
