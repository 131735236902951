<template>
  <article class="home-section section-activitypointer">
    <header class="section-header">
      <h3 class="title">활동지표</h3>
    </header>

    <div class="section-body">

<!--      <SSLMobileStatus1/>-->
      <SSLSelfGaStatus :ssl-division-cd="selfGa" />
      <SSLSelfGiStatus :ssl-division-cd="selfGi" />
      <SSLSelfJaStatus :ssl-division-cd="selfJa" />
      <SSLSelfIctStatus :ssl-division-cd="selfIct" />

      <!-- learning : note -->
<!--      <SSLMobileStatus1/>-->
      <!-- //leaning -->
      <!-- learning : group -->
      <SSLMobileStatus2/>
      <!-- //leaning -->
      <!-- learning : jisike -->
<!--      <SSLMobileStatus4/>-->
      <!-- //leaning -->
      <!-- learning : cloud -->
<!--      <SSLMobileStatus3/>-->
      <!-- //leaning -->
      <!-- learning : book -->
<!--      <SSLMobileStatus5/>-->
      <!-- //leaning -->
    </div>
  </article>
</template>
<script>

// import SSLMobileStatus1 from "@/components/ssl/home/mobile/status/SSLMobileStatus1";
import SSLMobileStatus2 from "@/components/ssl/home/mobile/status/SSLMobileStatus2";
// import SSLMobileStatus4 from "@/components/ssl/home/mobile/status/SSLMobileStatus4";
// import SSLMobileStatus3 from "@/components/ssl/home/mobile/status/SSLMobileStatus3";
// import SSLMobileStatus5 from "@/components/ssl/home/mobile/status/SSLMobileStatus5";
import SSLSelfGiStatus from "@/components/ssl/home/mobile/status/SSLMobileSelfStatus.vue";
import SSLSelfIctStatus from "@/components/ssl/home/mobile/status/SSLMobileSelfStatus.vue";
import SSLSelfJaStatus from "@/components/ssl/home/mobile/status/SSLMobileSelfStatus.vue";
import SSLSelfGaStatus from "@/components/ssl/home/mobile/status/SSLMobileSelfStatus.vue";
import {ref} from "vue";

export default {
  name: 'SSLMobileHomeStatus',
  components: {
    SSLSelfGaStatus, SSLSelfJaStatus, SSLSelfIctStatus, SSLSelfGiStatus,
    // SSLMobileStatus5,
    // SSLMobileStatus3,
    // SSLMobileStatus4,
    SSLMobileStatus2,
    // SSLMobileStatus1,
  },
  setup() {
    const selfGa = ref('selfGa');
    const selfGi = ref('selfGi');
    const selfJa = ref('selfJa');
    const selfIct = ref('selfIct');

    return{
      selfGa,
      selfGi,
      selfJa,
      selfIct,
    }
  }
}
</script>
