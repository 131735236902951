`<template>
  <main class="kb-main" id="kb-prep-group">
    <!-- main-header -->
    <lxp-main-header :show-back="true" />
    <div class="main-header">
      <div class="header-title">
        <h2 class="title">{{item.objNm}}</h2>
      </div>
    </div>
    <!-- //main-header -->
    <!-- main-content -->
    <div class="main-content min-component">
      <!-- content-section:모임정보 -->
      <section class="content-section section-tablelist">
        <header class="section-header">
          <h4 class="title">모임정보</h4>
        </header>
        <div class="kb-table kb-table-striped">
          <table>
            <colgroup>
              <col style="width:212px" />
              <col style="width:auto" />
            </colgroup>
            <tbody>
            <tr>
              <th><span class="th-title">그룹명</span></th>
              <td><span class="td-text">{{item.distCrseNm}}</span></td>
            </tr>
            <tr>
              <th><span class="th-title">분야</span></th>
              <td><span class="td-text">{{item.distCrseLrnFldNm}}</span></td>
            </tr>
            <tr>
              <th><span class="th-title">구분</span></th>
              <td><span class="td-text">{{ item.lrnObjTyCd === '100020' ? '온라인' : '오프라인' }}</span></td>
            </tr>
            <tr>
              <th><span class="th-title">모임일시</span></th>
              <td><span class="td-text">{{item.bgngYmd}} {{timestampToDateFormat(item.bgngLrn, 'hh:mm')}}-{{timestampToDateFormat(item.endLrn, 'hh:mm')}}</span></td>
            </tr>
            </tbody>
          </table>
        </div>
      </section>
      <!-- content-section:학습한 콘텐츠 -->
      <section class="content-section section-learned section-divider">
        <header class="section-header">
          <h4 class="title">학습한 콘텐츠</h4>
        </header>
        <div v-if="cttItems && cttItems.length === 0" class="search-container">
          <div class="search-result">
            <div class="result-empty">
              <img src="../../../assets/lxp/images/common/img_empty.png">
              <p class="text">학습한 콘텐츠가 없어요</p>
            </div>
          </div>
        </div>
        <div v-else class="learned-list-container">
          <div class="learned-list-nav swiper-nav">
            <button class="kb-btn-nav kb-btn-swiper-prev"><i class="icon-arrow-prev"></i></button>
            <button class="kb-btn-nav kb-btn-swiper-next"><i class="icon-arrow-next"></i></button>
          </div>
          <div class="learn-list-swiper swiper">
            <swiper
                :slides-per-view="3"
                :navigation="{
                    nextEl: '.kb-btn-swiper-next',
                    prevEl: '.kb-btn-swiper-prev'
                  }"
            >
              <swiper-slide v-for="(item, idx) in cttItems" :key="idx" >
                <HrdCloudContent
                    :item="item"
                    :session="session"
                    :idx="idx"
                    :show-count="false"
                />
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </section>
      <!-- content-section:출석 -->
      <section class="content-section section-attendance section-divider">

        <header class="section-header">
          <h4 class="title">출석 <span class="text text-muted">{{ cmptnCnt }}/{{ paging.totalCount }}</span></h4>
          <div class="header-sub d-flex">
            <div class="text-group">
              <strong class="text text-brown">평균학습시간 {{ secondToKrFormat(Math.round(item.avgLrnTime), false) }}</strong>
              <strong class="text text-brown">평균콜드콜응답률 {{ (coldCallRspnsCnt > 0 && coldCallRound > 0)? Math.round((coldCallRspnsCnt/coldCallRound)*100) : 0}}%</strong>
            </div>
            <div class="badge ms-2" :class="item.mtgAcptYn === 'Y' ? 'badge-primary' : 'badge-silver'"><span class="badge-text">{{item.mtgAcptYn === 'Y' ? '인정' : '미인정'}}</span></div>
          </div>
        </header>

        <div class="kb-table kb-table-bordered attendance-tablelist">
          <table>
            <colgroup>
              <col style="width:auto">
              <col style="width:240px">
              <col style="width:140px">
              <col style="width:140px">
              <col style="width:140px">
              <col style="width:140px">
              <col style="width:120px">
            </colgroup>
            <thead>
              <tr>
                <th><span class="th-title">직원명</span></th>
                <th><span class="th-title">부점명</span></th>
                <th><span class="th-title">구분</span></th>
                <th><span class="th-title">학습시작시간</span></th>
                <th><span class="th-title">학습종료시간</span></th>
                <th><span class="th-title">학습누적시간</span></th>
                <th><span class="th-title">콜드콜응답</span></th>
                <th><span class="th-title">출석여부</span></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, idx) in attnItems" :key="idx">
                <td><strong class="td-text">{{ item.lrnerNm }}</strong></td>
                <td><span class="td-text">{{ item.deptNm }}・{{ item.jbpsNm }}</span></td>
                <td><span class="td-text">{{item.grpRoleCdDcd === '2010001' ? '리더' : '멤버' }}</span></td>
                <td><span class="td-text">{{ item.rsltYn === 'Y' ? item.regDt : '-' }}</span></td>
                <td><span class="td-text">{{ item.rsltYn === 'Y' ? item.mdfcnDt : '-'}}</span></td>
                <td><span class="td-text">{{ item.lrnTime ? item.lrnTime : '-'}}</span></td>
                <td><span class="td-text">{{ item.rsltYn === 'Y' ? item.coldCallRspnsCnt+'/'+item.coldCallRound : '-' }}</span></td>
                <td><strong class="td-text text-primary" :class="{'text-red' : item.rsltYn !== 'Y' }">{{ item.rsltYn === 'Y' ? '출석' : '결석' }}</strong></td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import LxpMainHeader from "@/components/common/LxpMainHeader";
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import HrdCloudContent from "@/components/hrdcloud/HrdCloudContent";
import {groupLearningJournalSetup} from "@/assets/js/modules/groupLearing/group-learning-journal-setup";

export default {
  name: "GroupLearningJournal",
  components: {HrdCloudContent, LxpMainHeader, Swiper, SwiperSlide},
  setup: groupLearningJournalSetup
}
</script>`