<template>
    <div class="kb lxp-layout ssl-layout" id="kb" :data-bg="isFill ? 'fill' : ''">
      <vue-progress-bar/>
      <LxpHeader :effect-nav="touchBodyCount" :func="plusEffectNav" />

<!--        <SSLBoardSearch v-if="isSSLSearch"/>-->
<!--        <template v-else>-->
          <template v-if="isReady">
          <!-- begin::ssl-header -->
            <!-- end::ssl-header -->
            <!-- begin::ssl-body -->
            <main class="kb-main" id="kb-main" @click="plusEffectNav">
              <SSLHeader2 :effect-nav="touchBodyCount" />
              <div class="main-content" :class="{'main-component': isHome}" :id="mainId">
                <div :class="mainClass">
                  <router-view/>
                </div>
              </div>

              <div v-if="!hideFooter" class="floating-btn-area">
                <SSLWriteButton :effect-nav="touchBodyCount" :effect-func="plusEffectNav" />
                <div class="floating-btn bg-white-btn">
                  <button class="floating-btn-link" @click="goTop">
                    <i class="icon-scrolltop"></i>
                  </button>
                </div>
              </div>

            </main>

            <SSLCalendar v-if="calendar.show"/>
            <SSLPopup/>
            <SSLFooter v-if="!hideFooter"/>

          </template>
      <SSLEventStatusPopup v-if="eventStatusPopup" v-model="eventStatusPopup"/>
<!--        </template>-->
    </div>
</template>
<script>
import SSLFooter from '@/views/layout/ssl/footer/SLLFooter';
import SSLCalendar from '@/components/ssl/common/SSLCalendar';
import SSLPopup from '@/components/ssl/common/SSLPopup';
import {sslLayoutSetup} from '@/assets/js/modules/ssl/setup/ssl-layout-setup';
import LxpHeader from '@/views/layout/lxp/header/LxpHeader';
import SSLHeader2 from '@/views/layout/ssl/header/SSLHeader2';
import SSLWriteButton from "@/components/ssl/SSLWriteButton.vue";
import SSLEventStatusPopup from "@/components/ssl/SSLEventStatusPopup.vue";
// import SSLBoardSearch from "@/components/ssl/home/SSLBoardSearch.vue";

export default {
  name: 'SSLLayout',
  components: {
    SSLEventStatusPopup,
    // SSLBoardSearch,
    SSLHeader2,
    LxpHeader,
    SSLPopup,
    SSLCalendar,
    SSLWriteButton,
    SSLFooter
  },
  setup: sslLayoutSetup
}
</script>