<template>
  <!-- [begin::popup-container] -->
  <div class="popup-container is-active">
    <!-- popup -->
    <div class="popup" id="popup-tuition-support-p057">
      <!-- popup > popup-inner -->
      <div class="popup-inner">
        <div class="popup-header">
          <h3 class="title">자격증 검색</h3>
        </div>
        <!-- popup-inner > popup-content -->
        <div class="popup-content">
          <div class="survey-container">
            <!-- popup-section:자격증 검색영역 -->
            <article class="popup-section section-form">
              <div class="section-content">
                <!-- kb-form-fields -->
                <div class="kb-form-fields">
                  <!-- kb-form-row:자격증명 -->
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label"><strong class="kb-form-label-text">자격증명</strong></label>
                    </div>
                    <div class="kb-form-column column-whole kb-form-search">
                      <input type="text" class="kb-form-control column-whole" v-model="search.lcsnNm" placeholder="검색어를 입력하세요" @keydown.enter="handleEnter" />
                      <button class="kb-form-search-btn" @click="pagingFunc(1)"><i class="icon-search"></i></button>
                    </div>
                  </div>
                </div>
                <!-- //kb-form-fields -->
              </div>
            </article>
            <!-- popup-section:자격증 결과 영영 -->
            <div class="certificate-table-container">
              <!-- table -->
              <div class="kb-table kb-table-bordered">
                <table>
                  <colgroup>
                    <col style="width:72px;"/>
                    <col style="width:300px;"/>
                    <col style="width:250px;"/>
                    <col style="width:70px;"/>
<!--                    <col style="width:130px;"/>-->
<!--                    <col style="width:130px;"/>-->
<!--                    <col style="width:130px;"/>-->
<!--                    <col style="width:130px;"/>-->
                    <col style="width:130px;"/>
                    <col style="width:80px"/>
                  </colgroup>
                    <thead>
                    <tr>
                      <th><span class="th-title">자격증코드</span></th>
                      <th><span class="th-title">자격증명</span></th>
                      <th><span class="th-title">주관처</span></th>
                      <th><span class="th-title">등급</span></th>
<!--                      <th><span class="th-title">투자자보호<br>교육비</span></th>-->
<!--                      <th><span class="th-title">응시료<br>지원한도</span></th>-->
<!--                      <th><span class="th-title">교재비<br>지원한도</span></th>-->
                      <th><span class="th-title">지원한도</span></th>
<!--                      <th><span class="th-title">신청가능<br>유효응시기간</span></th>-->
                      <th><span class="th-title">선택</span></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="isLoading"><td colspan="9"><LoadingDiv /></td></tr>
                    <tr v-for="(item,idx) in items" :key="idx">
                      <td><span class="td-text">{{ item.lcsnCd }}</span></td>
                      <td><strong class="td-text">{{ item.lcsnNm }}</strong></td>
                      <td><span class="td-text">{{ item.pblcnInstNm }}</span></td>
                      <td><span class="td-text">{{ item.grd1Cd }}</span></td> <!--  | {{ item.grd2Cd }} -->
<!--                      <td><span class="td-text">{{ item.invProtEduGrpAmt }} | {{ item.invProtEduCyberAmt }}</span></td>-->
<!--                      <td><span class="td-text">{{ item.examFeeSprtAmt }}</span></td>-->
<!--                      <td><span class="td-text">{{ item.bookCostSprtAmt }}</span></td>-->
                      <td><span class="td-text">{{ numberComma(item.trnAidAmt) || 0 }}</span></td>
<!--                      <template v-if="item.aplyVldBgngYmd != null && item.aplyVldBgngYmd != ''">-->
<!--                        <td>-->
<!--                          <span class="td-text">{{item.aplyVldBgngYmd}} ~-->
<!--                            <template v-if="item.aplyVldEndYmd != '' && item.aplyVldEndYmd != null && item.aplyVldEndYmd != '9999-12-31'">{{item.aplyVldEndYmd}}</template></span>-->
<!--                        </td>-->
<!--                      </template>-->
<!--                      <template v-else>-->
<!--                        <td>-->
<!--                          <span class="td-text" v-if="item.aplyVldEndYmd != '' && item.aplyVldEndYmd != null && item.aplyVldEndYmd != '9999-12-31'">~ {{item.aplyVldEndYmd}}</span>-->
<!--                          <span class="td-text" v-else>신청가능</span>-->
<!--                        </td>-->
<!--                      </template>-->
                      <td>
                        <div class="kb-form-check"><input v-model="selectedItem" type="radio" class="kb-form-check-input" name="chk" :id="`chk_01_${idx}`" :value="idx"/></div>
                      </td>
                    </tr>
                    <tr v-if="items.length === 0 && !isLoading">
                      <td style="text-align:center !important;" colspan="6"><span class="td-text">검색 결과가 없습니다.<br><br>
                      대상 자격증이 없는 경우 담당자에게 문의주시기 바랍니다. <br>
                      (IT 계열 등급외 자격증은 평가비 지원신청에서 등록)</span></td>
                    </tr>
                    </tbody>
                </table>
              </div>
              <!-- //table -->
            </div>
          </div>
        </div>
        <CommonPaginationFront style="height:50px;" :paging="paging" :page-func="pagingFunc"/>
        <!-- popup-inner > popup-buttons -->
        <div class="popup-buttons">
          <button class="kb-btn kb-btn-outline kb-btn-lg" @click="closeModal"><span class="text">취소</span></button>
          <button class="kb-btn kb-btn-primary kb-btn-lg" @click="confirm"><span class="text">확인</span></button>
        </div>
      </div>
      <!-- popup > popup-close -->
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
    <!-- //popup -->
  </div>
  <!-- //[begin::popup-container] -->
</template>

<script>
import { onBeforeUnmount, reactive, ref} from 'vue';
import CommonPaginationFront from '@/components/CommonPaginationFront';
import {getLcsnList} from '@/assets/js/modules/support/support-common';
import {initParams, numberComma} from '@/assets/js/util';
import LoadingDiv from '@/components/common/LoadingDiv';

export default {
  name: 'SupportSearchLicenseModal',
  components: {LoadingDiv, CommonPaginationFront},
  props: {
    modelValue: Boolean,
    selectedValue: Object
  },
  emits:['update:modelValue', 'update:selectedValue'],
  setup(props, {emit}) {
    const isLoading = ref(false);
    const search = reactive({ lcsnNm: '' });
    const items = ref([]);
    const paging = reactive({
      pageNo: 1,
      pageSize: 999999,
      totalCount: 0
    });
    const selectedItem = ref(null);

    const confirm = () => {
      emit('update:selectedValue',items.value[selectedItem.value]);
      closeModal();
    };

    const closeModal = () => {
      initParams(search);
      emit('update:modelValue', false);
    };

    const cbFunc = () => {
      isLoading.value = false;
    };

    const pagingFunc = async (pageNo) => {
      if (isLoading.value) return;
      isLoading.value = true;
      paging.pageNo = pageNo;
      getLcsnList({...search, ...paging}, items, paging, cbFunc, cbFunc);
    };

    const handleEnter = (e) => {
      if (e.isComposing || e.keyCode === 229) return;
      pagingFunc(1);
    };

    // onBeforeMount(() => {
    //   pagingFunc(1);
    // });

    onBeforeUnmount(() => {
      initParams(search);
      // items.value = [];
    });

    return {
      isLoading, closeModal,
      search, items, selectedItem,
      paging, pagingFunc,
      handleEnter, confirm, numberComma
    };
  },
};
</script>