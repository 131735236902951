import {useStore} from 'vuex';
import {useAlert} from '@/assets/js/modules/common/alert';
import {computed, ref, watch} from 'vue';
import {
  clearTargetTimeout,
  getItems, getResult, isSuccess,
  lengthCheck,
  timestampToDateFormat,
} from '@/assets/js/util';
import {
  ACT_GET_LRN_TY_EVL_QSTN_DIST_ANS_LIST,
  ACT_GET_LRN_TY_EVL_QSTN_DIST_LIST, ACT_GET_LRN_TY_EVL_QSTN_DIST_MOCK_LIST,
  ACT_GET_LRN_TY_EVL_RSLT_LIST, ACT_UPDATE_LRN_TY_EVL_RSLT,
  ACT_UPDATE_LRN_TY_EVL_FNSH, ACT_GET_LRN_TY_EVL_QSTN_RSLT_LIST
} from '@/store/modules/evaluation/evaluation';
import { debounce } from 'lodash-es';

export const evlPledgeSetup = (props, {emit}) => {
  const store = useStore();
  const {showMessage} = useAlert();

  const session = computed(() => store.state.auth.session);
  const currentTime = ref(timestampToDateFormat(new Date(), 'yyyy.MM.dd'));
  const isChecked = ref(false);

  const clickPledge = () => {
    if(isChecked.value){
      emit('pledge');
      closeModal();
    }else{
      showMessage('동의를 체크해주세요.');
    }
  }

  const closeModal = () => {
    emit('update:modelValue', false);
  }

  return {
    session,
    currentTime,
    isChecked,
    clickPledge,
    closeModal
  }
}

export const evlAsideSetup = (props, {emit}) => {
  const store = useStore();
  const session = computed(() => store.state.auth.session);

  const getAsideClass = (answer, index) => {
    if(index === props.modelValue){
      return 'is-save';
    }else if(answer.checked){
      return 'is-check';
    }else if(answer.model){
      if(answer.model instanceof Array){
        if(answer.model.length > 0) {
          return 'is-solve'
        }
      }else{
        return 'is-solve'
      }
    }
    return '';
  }

  const selectQuestion = (index) => {
    if(index !== props.modelValue){
      emit('update:modelValue', index)
    }
  }

  const submitEvl = debounce (() => {
      emit('submitEvl');
  }, 300)

  const closeEvl = () => {
    emit('closeEvl');
  }

  return {
    session,
    getAsideClass,
    selectQuestion,
    submitEvl,
    closeEvl
  }
}

export const evlMainSetup = (props, {emit}) => {
  const store = useStore();
  const {showMessage, showConfirm} = useAlert();
  const isInit = ref(false);

  const questions = ref([]);
  const answers = ref([]);
  const currentIndex = ref(0);
  const endCount = ref(0);
  const showRemain = ref(false);

  const endDt = computed(() => {
    if (props.isMock) {
      return props.initDt + (1000 * 60 * 20);
    } else {
      const endEvlTm = props.initDt + (1000 * 60 * props.evl.evlReqHr);
      if (props.evl.evlEndDt < endEvlTm) {
        return props.evl.evlEndDt;
      }
      return endEvlTm;
    }
  });
  const remainTime = computed(() => {
    // if (endDt.value <= props.currentTime.getTime()) {
    if (endDt.value <= props.currentTime) {
      return '00:00';
    } else {
      // const remain = new Date(endDt.value - props.currentTime.getTime());
      // return `${('0' + remain.getMinutes()).slice(-2)}:${('0' + remain.getSeconds()).slice(-2)}`;
      const remain = endDt.value - props.currentTime;
      return timestampToDateFormat(remain, 'hh:mm:ss').slice(-5);
    }
  });

  const targetQuestion = computed(() => {
    if (questions.value[currentIndex.value]) {
      return questions.value[currentIndex.value];
    }
    return {};
  });

  const targetAnswerChecked = computed(() => {
    if (answers.value[currentIndex.value]) {
      return answers.value[currentIndex.value].checked;
    }
    return false;
  });

  const getDefaultModel = (answer) => {
    if (answer.qstnTyCdDcd === '2019003') {
      return answer.inptAns ? answer.inptAns.split(',') : [];
    }
    return answer.inptAns ? answer.inptAns : '';
  };

  const prevQuestion = () => {
    if (currentIndex.value > 0) {
      setTimeout(() => {
        currentIndex.value--;
      }, 0);
    }
  };

  const nextQuestion = () => {
    if (currentIndex.value < (answers.value.length - 1)) {
      setTimeout(() => {
        currentIndex.value++;
      }, 0);
    }
  };

  const fontSize = ref(0);

  const resizeFont = (resize) => {
    if (fontSize.value + resize < 0) return;
    if (fontSize.value + resize > 3) return;

    fontSize.value = (fontSize.value + resize);
  };

  const getEvaluationQuestionList = () => {
    Promise.all([
      store.dispatch(`evaluation/${ACT_GET_LRN_TY_EVL_QSTN_DIST_LIST}`, props.evl.lrnTyEvlDistSn),
      store.dispatch(`evaluation/${ACT_GET_LRN_TY_EVL_QSTN_RSLT_LIST}`, props.evl.lrnTyEvlDistSn),
    ]).then(([qRes, aRes]) => {
      let canTake = false;
      if (lengthCheck(qRes) && lengthCheck(aRes)) {
        const qItems = getItems(qRes);
        const aItems = getItems(aRes);
        if (qItems.length === aItems.length) {
          questions.value = qItems;
          answers.value = aItems.map(x => ({...x, model: getDefaultModel(x), checked: false, debounce: null}));
          canTake = true;
        }
        isInit.value = canTake;
      }
    });
  };

  const getEvaluationQuestionMockList = () => {
    store.dispatch(`evaluation/${ACT_GET_LRN_TY_EVL_QSTN_DIST_MOCK_LIST}`).then(res => {
      if (lengthCheck(res)) {
        const mockMap = {};
        getItems(res).forEach(x => {
          if (!mockMap[x.lrnTyEvlDistSn]) mockMap[x.lrnTyEvlDistSn] = [];
          mockMap[x.lrnTyEvlDistSn].push(x);
        });

        const keys = Object.keys(mockMap);
        const num = Math.floor(Math.random() * keys.length);
        const items = mockMap[keys[num]];
        questions.value = items;
        answers.value = items.map(x => ({model: getDefaultModel(x), checked: false, debounce: null}));
        isInit.value = true;
      }
    });
  };

  const updateEvaluationQuestions = (answers, completeYn) => {
    const params = {answers, completeYn};
    // 학습 결과 적용
    if (props.evl.lrnRsltSn > 0) params.lrnRsltSn = props.evl.lrnRsltSn;

    store.dispatch(`evaluation/${ACT_UPDATE_LRN_TY_EVL_RSLT}`,
        {lrnTyEvlDistSn: props.evl.lrnTyEvlDistSn, params: params}).then((res) => {
      // if (completeYn === 'Y') emit('endEvl');
      if (!isSuccess(res)) {
        showMessage(getResult(res).message, () => {
          emit('endEvl');
        });
      }
    }).catch(e => {
      // if (completeYn === 'Y') emit('endEvl');
      console.error(e);
    });
  };

  const completeEvaluation = () => {
    store.dispatch(`evaluation/${ACT_UPDATE_LRN_TY_EVL_FNSH}`,{
      lrnTyEvlDistSn: props.evl.lrnTyEvlDistSn
    }).then((res) => {
      if (isSuccess(res)) {
        emit('endEvl');
      } else {
        showMessage(getResult(res).message, () => {
          emit('endEvl');
        });
      }
    }).catch(e => {
      emit('endEvl');
      console.error(e);
    });
  };

  const saveAnswer = () => {
    if (props.isMock) return;

    clearTargetTimeout(answers.value[currentIndex.value].debounce);
    const saveAnswer = {
      lrnTyEvlRsltSn: answers.value[currentIndex.value].lrnTyEvlRsltSn,
      qstnNo: answers.value[currentIndex.value].qstnNo,
      inptAns: getInputAnswer(answers.value[currentIndex.value].model),
    };
    answers.value[currentIndex.value].debounce = setTimeout(() => {
      updateEvaluationQuestions([saveAnswer], 'N');
    }, 500);
  };

  const getInputAnswer = (model) => {
    if (model instanceof Array) {
      return model.sort().join(',');
    }
    return model;
  };

  const getSelectId = (index) => {
    return `chk-${currentIndex.value}-${index}`;
  };

  const getSelectName = (index) => {
    if (targetQuestion.value.qstnTyCdDcd === '2019003') {
      return `chk-${currentIndex.value}-${index}`;
    }
    return `chk-${currentIndex.value}`;
  };

  const checkQuestion = () => {
    answers.value[currentIndex.value].checked = !answers.value[currentIndex.value].checked;
  };

  const checkBlankAnswer = (model) => {
    if (model instanceof Array) {
      return model.length === 0;
    }
    return !model;
  };

  const submitAnswers = () => {
    if (props.isMock) {
      const blankAnswers = answers.value.filter(x => checkBlankAnswer(x.model));
      if (blankAnswers.length === 0) {
        emit('endEvl');
      } else {
        showMessage(`아직 풀지 않는 문제가 ${blankAnswers.length}문제 있습니다.<br>모든 문제를 푸신 뒤 답안을 제출해주세요.`);
      }
    } else {
      if (props.evl.mwySbmsnPsbltYn === 'Y') {
        const blankAnswers = answers.value.filter(x => checkBlankAnswer(x.model));
        if (blankAnswers.length === 0) {
          showConfirm(
              {
                title: '답안제출',
                text: '확인을 누르시면 답안이 전송되고 더이상 수정할 수<br>없습니다. 지금 평가를 마치시겠습니까?',
                callback: () => {
                  completeEvaluation();
                },
              },
          );

        } else {
          showMessage(`아직 풀지 않는 문제가 ${blankAnswers.length}문제 있습니다.<br>모든 문제를 푸신 뒤 답안을 제출해주세요.`);
          return false;
        }
      } else {
        showMessage({
          title: '중도 제출 불가',
          text: '해당 시험은 중도 제출이 불가능합니다.',
        });
      }
    }
  };

  const closeAnswers = () => {
    props.stopEvl();
  }

  const showRemainToast = () => {
    showRemain.value = true;
    setTimeout(() => {
      showRemain.value = false;
    }, 8000);
  };

  watch(() => props.currentTime, () => {
    if (isInit.value) {
      // if (endDt.value <= props.currentTime.getTime()) {
      if (endDt.value <= props.currentTime) {

        if (props.isMock) {
          emit('endEvl');
        } else {
          endCount.value++;
          if (endCount.value === 1) {
            completeEvaluation();
          }
        }
      }

      // const remainSec = (endDt.value - props.currentTime.getTime()) / 1000;
      const remainSec = (endDt.value - props.currentTime) / 1000;
      // 5분
      if (remainSec >= 299 && remainSec <= 300) {
        showRemainToast();
      }
      // 3분
      if (remainSec >= 179 && remainSec <= 180) {
        showRemainToast();
      }
      // 1분
      if (remainSec >= 59 && remainSec <= 60) {
        showRemainToast();
      }
    }
  });

  if (props.isMock) {
    getEvaluationQuestionMockList();
  } else {
    getEvaluationQuestionList();
  }

  return {
    isInit,
    currentIndex,
    targetQuestion,
    targetAnswerChecked,
    questions,
    answers,
    remainTime,
    fontSize,
    showRemain,
    prevQuestion,
    nextQuestion,
    saveAnswer,
    getSelectId,
    getSelectName,
    checkQuestion,
    resizeFont,
    submitAnswers,
    closeAnswers,
  };
}

export const evlAnswerSetup = (props, {emit}) => {
  const store = useStore();
  const {showLoading, hideLoading} = useAlert();
  const session = computed(() => store.state.auth.session);
  showLoading();

  const questions = ref(false);
  const answers = ref(false);
  const multiSelectType = '2019003';

  const getDefaultModel = (answer) => {
    if(answer.qstnTyCdDcd === '2019003'){
      return answer.inptAns ? answer.inptAns.split(',') : [];
    }
    return answer.inptAns ? answer.inptAns : '';
  }

  const getEvaluationQuestionAnswerList = () => {
    Promise.all([
      store.dispatch(`evaluation/${ACT_GET_LRN_TY_EVL_QSTN_DIST_ANS_LIST}`, props.evl.lrnTyEvlDistSn),
      store.dispatch(`evaluation/${ACT_GET_LRN_TY_EVL_RSLT_LIST}`, props.evl.lrnTyEvlDistSn)
    ]).then(([qRes, aRes]) => {
      if(lengthCheck(qRes) && lengthCheck(aRes)){
        const qItems = getItems(qRes);
        const aItems = getItems(aRes);
        if(qItems.length === aItems.length){
          questions.value = qItems;
          answers.value = aItems.map(x => ({...x, model: getDefaultModel(x), checked: false, debounce: null}));
        }
      }
      hideLoading();
    });
  }

  const isCorrect = (index) => {
    return answers.value[index].crctAnsYn === 'Y';
  }

  const getCorrectText = (index) => {
    return (isCorrect(index) ? 'correct' : 'incorrect');
  }

  const getAnswer = (index) => {
    return answers.value[index].inptAns;
  }

  const isOptionAnswer = (option, index) => {
    // 멀티 셀렉트의 경우
    if(answers.value[index].inptAns != null){
      if(multiSelectType === questions.value[index].qstnTyCdDcd){
        return answers.value[index].inptAns.split(',').includes(option.optNo);
      }else{
        return answers.value[index].inptAns === option.optNo;
      }
    }
    return false;
  }

  const closeEvl = () => {
    emit('closeEvl');
  }

  getEvaluationQuestionAnswerList();

  return {
    session,
    questions,
    answers,
    isCorrect,
    getCorrectText,
    isOptionAnswer,
    getAnswer,
    closeEvl,
    timestampToDateFormat
  }

}
