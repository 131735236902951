
<!--<div class="activity-icon">-->
<!--<i class="icon-activity-note"></i>-->
<!--</div>-->

<template>
  <template v-if="isMobile">
    <div class="symbol-item">
      <div class="item-row row-content">
        <div class="item-icon">
          <i v-if="type==='time'" class="icon-time"></i>
          <i v-else-if="type==='day'" class="icon-day"></i>
          <i v-else-if="type==='memo'" class="icon-memo"></i>
          <i v-else-if="type==='complete'" class="icon-complete"></i>
        </div>
        <div class="item-body">
          <div class="content-title"> {{ title }} </div>
          <div v-if="type==='time'" class="content-num text-gold">{{ data }}</div>
          <div v-if="type==='day'" class="content-num text-gold">{{ numberComma(data) }} 일</div>
          <div v-if="type==='memo'" class="content-num text-gold">{{ numberComma(data) }} 개</div>
          <div v-if="type==='complete'" class="content-num text-gold">{{ numberComma(data) }} 권</div>
        </div>
      </div>
      <div class="item-row row-buttons">
        <button v-if="showDetail && func" class="kb-btn kb-btn-outline kb-btn-content-item" @click="func"><span class="text">상세보기</span></button>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="symbol-card" style="width: 287px; height: 200px;">
      <div class="card-icon">
        <i v-if="type==='time'" class="icon-time" style="width: 500px;"></i>
        <i v-else-if="type==='day'" class="icon-day"></i>
        <i v-else-if="type==='memo'" class="icon-memo"></i>
        <i v-else-if="type==='complete'" class="icon-complete"></i>
      </div>
      <div class="card-content">
        <div v-if="type==='time'" class="content-text">{{ data }}</div>
        <div v-if="type==='day'" class="content-text">{{ numberComma(data) }} 일</div>
        <div v-if="type==='memo'" class="content-text">{{ numberComma(data) }} 개</div>
        <div v-if="type==='complete'" class="content-text">{{ numberComma(data) }} 권</div>
        <div class="content-title" style="font-size: 15px; font-weight: bold;"> {{ title }} <a href="javascript:" v-if="showDetail && func" class="content-link" @click="func">상세보기</a>
        </div>
      </div>
    </div>
  </template>

</template>

<script>
import {numberComma} from '@/assets/js/util';

export default {
  name: 'SymbolBookRoutineCard',
  props: {
    title: String,
    type: String,
    data: {type: [Object, String], default: '0'},
    showDetail: Boolean,
    func: Function,
    isMobile: Boolean,
  },
  setup() {
    return {
      numberComma
    }
  }
};
</script>