<template>
  <main class="kb-main" id="kb-reinstatement-board">
    <!-- main-header -->
    <lxp-main-header :show-custom-title="true" :show-back="true" title="공지사항"></lxp-main-header>
    <!-- //main-header -->
    <!-- main-content -->
    <div class="main-content min-component">
      <div class="search-container">
        <div class="list-top">
          <div class="top-column pt-4">
            <p class="title text-muted">총 {{ paging.totalCount }}건</p>
          </div>
          <div class="top-column">
            <div>
              <div class="kb-form-search">
                <div class="kb-form-search-field">
                  <input type="text" class="kb-form-search-input" placeholder="검색"
                         v-model="search.ntcTitle"
                         @keyup.enter="clickSearch"
                  />
                  <button class="kb-form-search-btn" @click="clickSearch"><i class="icon-search">검색</i></button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="board-list-container">
          <ul class="board-list">
            <li v-for="(item, idx) in items" class="board-list-item" :key="idx">
              <article class="board-row">
                <router-link :to="{name: 'TakeNoticeView', params: {comNtcSn: item.comNtcSn}}" class="board-link"></router-link>
                <div v-if="item.topFixYn === 'Y'" class="board-column column-index"><em class="text text-gold">필독</em></div>
                <div v-else class="board-column column-index">{{idx+1+(paging.pageSize*(paging.pageNo-1))}}</div>
                <div v-if="item.ntcFileAtchYn === 'Y'" class="board-column column-file"><a href="javascript:" class="file-link"><i class="icon-file"></i></a></div>
                <div class="board-column column-title">
                  <span class="title">{{item.ntcTitle}}</span>
                  <i class="icon-new"></i>
                </div>
                <div class="board-column column-date"><span class="text">{{ timestampToDateFormat( item.regDt, 'yyyy-MM-dd')}}</span></div>
                <div class="board-column column-hit"><span class="text">{{item.inqCnt}} 조회</span></div>
              </article>
            </li>
          </ul>
          <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>
        </div>
        <div v-if="items.length === 0" class="search-result">
          <div class="result-empty">
            <img src="../../../assets/lxp/images/common/img_empty.png">
            <p class="text">검색결과가 없어요</p>
          </div>
        </div>

      </div>
    </div>
    <!-- //main-content -->
  </main>
  <!-- end::kb-main -->
</template>

<script>
import {onBeforeMount, reactive, ref, watch} from "vue";
import {useStore} from "vuex";
import {ACT_GET_HELP_NOTICE_LIST} from "@/store/modules/help/help";
import {
  getItems,
  getPaging,
  initPaging,
  initProgressBar,
  lengthCheck,
  setParamsByQueryString,
  timestampToDateFormat
} from "@/assets/js/util";
import {useRoute, useRouter} from "vue-router";
import CommonPaginationFront from "@/components/CommonPaginationFront";
import {expsrLocCds} from "@/assets/js/modules/help/help-common";
import LxpMainHeader from "@/components/common/LxpMainHeader";

export default {
  name: "TakeNotice",
  components: {LxpMainHeader, CommonPaginationFront},
  setup() {

    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const paging = ref(initPaging(route));

    const items = ref([]);

    const search = reactive({
      ntcTitle: ''
    });

    const getTakeNoticeList = () => {
      store.dispatch(`help/${ACT_GET_HELP_NOTICE_LIST}`, {
        ntcTitle: search.ntcTitle,
        expsrLocCd: expsrLocCds.EXPSR_LOC_CD_TAKE,
        pageNo: paging.value.pageNo,
        pageSize: paging.value.pageSize
      }).then(res => {
        if (lengthCheck(res)) {
          items.value = getItems(res);
          paging.value = getPaging(res);
        } else {
          items.value = [];
          paging.value.totalCount = 0;
        }
      }).catch(e => {
        console.error(e);
      })
    }

    const pagingFunc = (pageNo, pageSize) => {
      const query = {pageNo: pageNo};
      if (pageSize !== paging.value.pageSize) {
        query.pageSize = pageSize;
      }
      if(search.ntcTitle) query.ntcTitle = search.ntcTitle;
      router.push({query: query});
    };

    const clickSearch = () => {
      pagingFunc(1, 10);
    };

    getTakeNoticeList();

    watch(() => route.query, () => {
      setParamsByQueryString(route, 'TakeNotice', paging, search, true);
      getTakeNoticeList();
      initProgressBar();
    });

    onBeforeMount(() => {
      setParamsByQueryString(route, 'TakeNotice', paging, search, true);
    });

    return {
      items,
      search,
      paging,
      pagingFunc,
      timestampToDateFormat,
      clickSearch
    }
  }
}
</script>