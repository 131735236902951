<template>
  <template v-if="files.length > 0">
    <template v-if="isMobile">
      <div class="util util-download">
        <a href="javascript:" class="util-actions util-actions-download" @click="isFileAtchListShow = true">
          첨부파일
          <i class="icon-download"></i>
        </a>
      </div>
      <HrdCloudMobileFileAtch v-if="isFileAtchListShow" v-model:isFileAtchListShow="isFileAtchListShow" :fileItems="files"/>
    </template>
    <template v-else>
      <div v-for="(file,idx) in files" :key="idx" class="detail-row detail-file">
        <a href="javascript:" class="file-link kb-btn kb-btn-outline rounded-lg" @click.stop="downloadFile(file)">
          <span class="filename">{{ file.orgFileNm }}</span>
          <span class="filesize">{{ file.fileSz > 0 ? convertToStorageBytes(file.fileSz) : '' }}</span>
          <i class="icon-download"></i>
        </a>
      </div>
    </template>
  </template>
</template>

<script>
import {computed, ref} from 'vue';
import {useRoute} from 'vue-router';
import {useStore} from 'vuex';
import {ACT_GET_LEARN_ATCH_FILE} from '@/store/modules/learn/learn';
import {
  commonDownloadFile,
  convertToStorageBytes,
  getCheckItems,
  lengthCheck,
} from '@/assets/js/util';
import {cttTyCdDcds} from '@/assets/js/modules/hrd/hrd-common';
import HrdCloudMobileFileAtch from '@/components/hrdcloud/mobile/HrdCloudMobileFileAtch';
import {insertCourseAct} from "@/assets/js/modules/course/course-common";
import {actTyCdDcds} from "@/assets/js/modules/common/common";

export default {
  name: 'HrdCloudAtchFile',
  components: {
    HrdCloudMobileFileAtch
  },
  props: {
    isMobile: Boolean,
    hrd: {type:Object,required:false},
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const lrnObjDtlDistSn = computed(() => route.params.lrnObjDtlDistSn);
    const files = ref([]);
    const isFileAtchListShow = ref(false);

    const getAtchFileList = async () => {
      const res = await store.dispatch(`learn/${ACT_GET_LEARN_ATCH_FILE}`,{lrnObjDtlDistSn: lrnObjDtlDistSn.value});
      if (lengthCheck(res)) {
        files.value = getCheckItems(res);
      }

      // PDF일 경우 props.hrd로 자기자신을 넣어주어 첨부파일을 만든다.
      if (props.hrd && props.hrd.cttTyCdDcd === cttTyCdDcds.CTT_TY_CD_PDF) {
        files.value.unshift({
          orgFileNm: props.hrd.objNm,
          fileNm: props.hrd.objNm,
          buketNm: props.hrd.buketNm,
          etnUrl: props.hrd.etnUrl,
          itnUrl: props.hrd.itnUrl,
          secuKey: props.hrd.secuKey
        });
      }
    };

    const downloadFile = (file) => {
      insertCourseAct(actTyCdDcds.ACT_TY_CD_DOWNLOAD, {
        crseMstSn: props.hrd.crseMstSn,
        distCrseSn: props.hrd.distCrseSn,
        distCrseNm: props.hrd.distCrseNm,
        lrnObjDtlDistSn: props.hrd.lrnObjDtlDistSn,
        objSn: props.hrd.objSn,
        objNm: props.hrd.objNm
      }, () => {});
      commonDownloadFile(file);
    };

    if (lrnObjDtlDistSn.value > 0) {
      getAtchFileList();
    }

    return {
      files,
      downloadFile,
      isFileAtchListShow,
      convertToStorageBytes
    };
  }
};
</script>