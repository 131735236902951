<template>
  <nav class="header-column header-nav">
    <ul class="nav">
      <template v-for="(menu, idx) in menus" :key="idx">
        <li v-if="!webHiddenMenu.includes(menu.menuNm)" class="kb-nav-item">
          <a href="javascript:" class="kb-nav-link">
            <h3 class="kb-nav-text sitemap-group-title">{{ menu.menuNm }}</h3>
          </a>
          <div v-if="menu.children.length > 0" class="header-subnav">
            <div class="subkb-nav-group">
              <ul class="subnav">
                <li v-for="(child, i) in menu.children" class="subkb-nav-item" :key="`child-${idx}-${i}`">
                  <template v-if="child.menuUrl">
                    <a v-if="isTempMenu(child)" href="javascript:" class="subkb-nav-link"><span class="subkb-nav-text">{{ child.menuNm }}</span></a>
                    <a v-else class="subkb-nav-link kb-mouse-cursor" @click="goMenu(child, $event)">
                      <span class="subkb-nav-text">{{ child.menuNm }}</span>
                    </a>
                  </template>
  <!--                <a v-else-if="child.menuUrl === '/ssl/home'" href="javascript:" @click="targetBlackFunc" class="subkb-nav-link"><span class="subkb-nav-text">{{ child.menuNm }}</span></a>-->
                  <a v-else href="javascript:" class="subkb-nav-link"><span class="subkb-nav-text">{{ child.menuNm }}</span></a>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </template>
      <!-- Hot Tip -->
      <li class="kb-nav-item">
        <router-link :to="{name:'HotTipMain'}" class="kb-nav-link">
          <h3 class="kb-nav-text sitemap-group-title">Hot Tip[핫;팁]</h3>
        </router-link>
      </li>
    </ul>
  </nav>
</template>
<script>

import {computed} from 'vue';
import {useStore} from 'vuex';
import {
  customPageMove,
  devAsisMoveLinkMenus,
  isTempMenu,
  prdAsisMoveLinkMenus,
  webHiddenMenu,
} from '@/assets/js/modules/common/common';
import {useRouter} from 'vue-router';
import {winOpen} from "@/assets/js/util";
// import {useAlert} from "@/assets/js/modules/common/alert";

export default {
  name: 'LxpHeaderMenu',
  setup() {
    const store = useStore();
    const menus = computed(() => store.state.menu.menus);
    const router = useRouter();
    // const {showMessage} = useAlert();
    const goMenu = (menu, e) => {
      // 2022-11-22 : SSL : 11, 그룹러닝: 12, 지식e러닝 : 13 (2023년 3월 오픈) [김보희 대리 요청]
      // if(menu.menuSn === 11 || menu.menuSn === 12 || menu.menuSn === 13) {
      // if (menu.menuUrl === '/ssl/home' || menu.menuUrl === '/prep/group') {
      //     showMessage('학습기간이 아닙니다.');
      // }else
      if(process.env.NODE_ENV === 'production' ? prdAsisMoveLinkMenus.includes(menu.menuUrl) : devAsisMoveLinkMenus.includes(menu.menuUrl)) {
        customPageMove(menu.menuUrl);
      } else {
        if(e.ctrlKey){
          winOpen(menu.menuUrl);
        } else {
          router.push({path: menu.menuUrl});
        }

      }
    }

    return {
      menus,
      webHiddenMenu,
      isTempMenu,
      goMenu,
    }
  }
}
</script>
