<template>
  <div class="myssl-top">
    <div class="top-my">
      <div class="my-avatar">
        <div class="avatar">
          <SSLProfileImg :target="lrner" />
        </div>
      </div>
      <div class="my-title">
        <h2 class="title">
          <img src="@/assets/lxp/images/ssl/myssl/ic_myssl.svg" alt/>
        </h2>
      </div>

      <div class="my-category" :class="categoryClass">
        <span v-for="(category, index) in categories" class="category" :key="index">{{ category }}</span>
      </div>

      <div class="my-name">
        <span class="name" style="margin-right: 5px">{{ lrner.lrnerNm }}</span>
        <span class="name text-muted">{{ distCrseCd === 'group' ? myGroup.crseNm : lrner.deptNm }}</span>
      </div>

      <div v-if="distCrseCd === 'group'" class="my-links">
        <a href="javascript:" class="link">
          <i class="icon-my-ssl"></i>
        </a>
        <router-link v-if="myGroup.distCrseSn > 0" :to="{name: 'GroupView', params: {distCrseSn: myGroup.distCrseSn}}" class="link is-active"><i class="icon-my-group"></i></router-link>
        <router-link v-else :to="{name: 'GroupLearning'}" class="link is-active"><i class="icon-my-group"></i></router-link>
      </div>
      <div v-else-if="distCrseCd === 'knowledge'" class="my-links">
        <a href="javascript:" class="link">
          <i class="icon-my-ssl"></i>
        </a>
        <router-link :to="{name: 'KnowledgeELearning'}" class="link is-active">
          <i class="icon-my-test"></i>
        </router-link>
      </div>
      <div v-else-if="distCrseCd === 'book'" class="mt-3">
        <button class="link" @click="showMyPortraitModal"><span class="text text-gold" style="font:var(--kb-display16-fontB)">프로필 설정</span><i class="icon-arrow20-right-gold"/></button>
      </div>
    </div>

    <SSLMyRoute/>
    <SSLMyWrite v-if="showWrite"/>
    <MyPortraitModal userProfileTyCdDcd="2161002" />
  </div>
</template>

<script>
import SSLMyRoute from '@/components/ssl/my/SSLMyRoute';
import SSLMyWrite from '@/components/ssl/my/SSLMyWrite';
import SSLProfileImg from '@/components/ssl/common/SSLProfileImg';
import MyPortraitModal from '@/components/mypage/MyPortraitModal.vue';
import {sslMyTopSetup} from '@/assets/js/modules/ssl/setup/ssl-my-setup';

export default {
  name: 'SSLMyTop',
  props: {
    showWrite: Boolean
  },
  components: {
    SSLProfileImg,
    SSLMyRoute,
    SSLMyWrite,
    MyPortraitModal,
  },
  setup: sslMyTopSetup
}
</script>
