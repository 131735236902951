<template>
  <div class="ssl lxp-mobile-layout ssl-mobile-layout">
    <template v-if="isReady">
      <router-view @click="plusEffectNav"/>
      <div v-if="!calendar.show" class="page-scrolltop page-component">
        <a href="javascript:" class="page-scrolltop-btn" @click.stop="goTop">
          <i class="icon-scrolltop"></i>
        </a>
      </div>
      <LxpMobileFooter v-if="!hideFooter" :items="notices"/>
<!--      <SSLMobileGnb v-if="!mobileHideFooter" :effect-nav="touchBodyCount"/>-->
      <SSLPopup/>
    </template>
  </div>
</template>
<script>
import SSLPopup from '@/components/ssl/common/SSLPopup';
import {sslApplyLayoutSetup} from '@/assets/js/modules/ssl/setup/ssl-layout-setup';
import LxpMobileFooter from '@/views/layout/lxp/footer/mobile/LxpMobileFooter';

export default {
  name: 'SSLMobileApplyLayout',
  components: {
    SSLPopup,
    LxpMobileFooter,
  },
  setup: sslApplyLayoutSetup
}
</script>
