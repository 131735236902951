<template>
  <section class="myssl-section section-mybadge">
    <header class="section-header">
      <h3 class="title">내 배지 <span class="text-muted">{{ myBadges.length }}/{{activeBadges.length}}</span></h3>
    </header>
    <div class="mybadge-wrapper">

      <div class="sbadge-wrapper my-sbadge-wrapper">
        <!-- badge -->
        <SSLBadgeTitle v-for="(badge, idx) in activeBadges"
                  :key="idx"
                  :badge-ty-cd-dcd="badge.badgeTyCdDcd"
                  :is-active="badge.isActive"
                  :is-new="badge.isNew"
                  :hidden-yn="badge.hiddenYn"
                  :badge-data="badge"
                  :show-title="true"
                  :show-tooltip="true"
        />
      </div>
    </div>
  </section>
</template>

<script>

import {getBadgeList} from "@/assets/js/modules/ssl/ssl-badge";
import SSLBadgeTitle from "@/components/ssl/badge/SSLBadgeTitle";
import {computed, ref} from "vue";
import {useStore} from "vuex";
import {getListFunc} from "@/assets/js/ui.init";
import {ACT_GET_COM_BADGE_TY_LIST} from "@/store/modules/badge/badge";

export default {
  name: 'SSLMyBadge',
  components: {
    SSLBadgeTitle
  },
  setup() {
    const store = useStore();
    const currentYear = new Date().getFullYear().toString();
    const myBadges = computed(() => store.state.ssl.myBadges);
    const currentTimestamp = new Date().getTime();
    const newLimit = 1000 * 60 * 60 * 24 * 7;
    const sslBadges = ref([]);

    getListFunc(`badge/${ACT_GET_COM_BADGE_TY_LIST}`, {year: currentYear, badgeKindCdDcd: "2065001"}, sslBadges, null);

    const activeBadges = computed(() => {
      return sslBadges.value.filter(x => x.year === currentYear).map(x => {
        // return sslBadges.value.filter(x => !x.showYears || x.showYears.includes(currentYear)).map(x => {
        const targetBadge = {
          badgeTySn: x.badgeTySn,
          badgeTyCdDcd: x.badgeTyCdDcd,
          isActive: false,
          isNew: false,
          hiddenYn: x.hiddenYn,
          badgeName: x.badgeTyNm,
          badgeDesc: x.cdDesc
        };

        const found = myBadges.value.filter(y => x.badgeTyCdDcd === y.badgeTyCdDcd);

        if(found.length > 0){
          targetBadge.isActive = true;
          targetBadge.isNew = found.getDt + newLimit >= currentTimestamp;
        }else{
          if (targetBadge.hiddenYn === 'Y') {
            targetBadge.badgeName = x.hiddenBadgeTyNm;
            targetBadge.badgeDesc = x.hiddenBadgeCn;
          }
        }

        return targetBadge;
      }).sort((a,b) => {
        if (a.badgeTySn < b.badgeTySn) { return -1; }
        else {return 1;}
      }).sort((a) => {
        if (a.isActive) { return -1; }
        else {return 1;}
      }).sort((a) => {
        if (a.isNew) { return -1; }
        else {return 1;}
      });
    });

    getBadgeList();

    return {
      sslBadges,
      myBadges,
      activeBadges
    }
  }
}
</script>
