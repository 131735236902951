<template>
  <div v-if="isMobile" class="kb-alert-container is-active">
    <div class="kb-alert">
      <div class="kb-alert-contents">
        <strong class="kb-alert-title">{{ distCrseNm }}</strong>

<!--        <p v-if="distCrseCd === 'book'" class="kb-alert-text">북러닝 과정의 신청기간이 종료되었습니다.<br>다음 신청기간을 이용해주시기 바랍니다. <br>(하반기 신청기간: 8월 中 별도 안내 예정)</p>-->
<!--        <p v-else class="kb-alert-text">아직 신청하지 않은 과정입니다. <br>체험하기를 통해 둘러볼 수 있습니다.</p>-->
        <p v-if="distCrseCd === 'trivia'">SSL 참여직원 모두를 위한 코너로 신청 없이 참여가 가능합니다.</p>
<!--        <p v-else-if="distCrseCd === 'group'">그룹러닝 신청화면으로 이동합니다.</p>-->

        <div v-if="options.length > 1" class="checkbox-group my-3">
          <div class="kb-form-check-group kb-form-check-block">
            <div v-for="(option, index) in options" class="kb-form-check" :key="index">
              <input type="radio" v-model="lrnTrgtGrpMstSn" class="kb-form-check-input" name="chk_option" :id="`chk_${index}`" :value="option.lrnTrgtGrpMstSn">
              <label :for="`chk_${index}`" class="kb-form-check-label"><span class="text">{{ option.lrnTrgtGrpNm }}</span></label>
            </div>
          </div>
        </div>
      </div>
      <div class="kb-alert-actions">
        <a v-if="distCrseCd !== 'trivia'" class="kb-btn-alert" @click="enrollLearn"><span class="text">신청하기</span></a>
      </div>
      <button class="kb-alert-close" @click="closeModal"><i class="icon-alert-close"></i></button>
    </div>
  </div>

  <div v-else class="layer-container is-active">
    <div class="layer" id="layer-self-learning">
      <div class="layer-contents">
        <h3 class="layer-title title-lg">{{ distCrseNm }}</h3>
        <div class="layer-content mt-3">

<!--          <p v-if="distCrseCd === 'book'" class="text text-lg">북러닝 과정의 신청기간이 종료되었습니다.<br>다음 신청기간을 이용해주시기 바랍니다. <br>(하반기 신청기간: 8월 中 별도 안내 예정)</p>-->
<!--          <p v-else class="text text-lg">아직 신청하지 않은 과정입니다. 체험하기를 통해 둘러볼 수 있습니다.</p>-->
          <p v-if="distCrseCd === 'trivia'">SSL 참여직원 모두를 위한 코너로 신청 없이 참여가 가능합니다.</p>
<!--          <p v-else-if="distCrseCd === 'group'">그룹러닝 신청화면으로 이동합니다.</p>-->

          <div v-if="options.length > 1" class="checkbox-group mt-3">
            <div class="kb-form-check-group kb-form-check-block">
              <div v-for="(option, index) in options"  class="kb-form-check" :key="index">
                <input type="radio" v-model="lrnTrgtGrpMstSn"  class="kb-form-check-input" name="chk_option" :id="`chk_${index}`" :value="option.lrnTrgtGrpMstSn">
                <label :for="`chk_${index}`" class="kb-form-check-label"><span class="text">{{ option.lrnTrgtGrpNm }}</span></label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="layer-actions">
        <button v-if="distCrseCd !== 'trivia'" class="kb-btn-layer" @click="enrollLearn">
          <strong class="text">신청하기</strong>
        </button>
      </div>
      <button class="layer-close-btn" @click="closeModal"><i class="icon-close"></i></button>
    </div>
    <!-- //[end::layer-container] -->
  </div>
</template>
<script>

import {sslApplyCompSetup} from '@/assets/js/modules/ssl/setup/ssl-apply-comp-setup';

export default {
  name: 'SSLEnroll',
  props: {
    toggle: Boolean,
    distCrseCd: String, // distCrseCd
    myLrnTrgtGrpMstSn:Number,
    stt:Boolean,
  },
  setup: sslApplyCompSetup
}
</script>
