<template>
  <main class="kb-main" id="kb-reinstatement-board">
    <!-- main-header -->
    <lxp-main-header :show-custom-title="true" :show-back="true" title="자료실"></lxp-main-header>
    <!-- //main-header -->
    <!-- main-content -->
    <div class="main-content min-component">
      <div class="search-container">
        <div class="list-top">
          <div class="top-column pt-4">
            <p class="title text-muted">총 {{ paging.totalCount }}건</p>
          </div>
          <div class="top-column">
            <div>
              <div class="kb-form-search">
                <div class="kb-form-search-field">
                  <input type="text" class="kb-form-search-input" placeholder="검색"
                         v-model="search.postTitle"
                         @keyup.enter="clickSearch"
                  />
                  <button class="kb-form-search-btn" @click="clickSearch"><i class="icon-search">검색</i></button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="board-list-container">
          <ul class="board-list">
            <li v-for="(item, idx) in items" class="board-list-item" :key="idx">
              <article class="board-row">
                <router-link :to="{name: 'TakeLibView', params: {comPostSn: item.comPostSn}}" class="board-link"></router-link>
                <div class="board-column column-index">{{idx+1+(paging.pageSize*(paging.pageNo-1))}}</div>
                <div class="board-column column-file"><a href="javascript:" class="file-link" v-if="item.postFileAtchYn === 'Y'"><i class="icon-file"></i></a></div>
                <div class="board-column column-title"><span class="title">{{item.postTitle}}</span><i class="icon-new"></i></div>
                <div class="board-column column-date"><span class="text">{{timestampToDateFormat(item.regDt, 'yyyy.MM.dd hh:mm')}}</span></div>
                <div class="board-column column-hit"><span class="text">{{item.inqCnt}} 조회</span></div>
              </article>
            </li>
          </ul>
          <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>
        </div>
        <div v-if="items.length === 0" class="search-result">
          <div class="result-empty">
            <img src="../../../assets/lxp/images/common/img_empty.png">
            <p class="text">검색결과가 없어요</p>
          </div>
        </div>

      </div>
    </div>
    <!-- //main-content -->
  </main>
  <!-- end::kb-main -->
</template>

<script>
import {onBeforeMount, reactive, ref, watch} from "vue";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {
  getItems,
  getPaging,
  initPaging,
  initProgressBar,
  lengthCheck,
  setParamsByQueryString,
  timestampToDateFormat
} from "@/assets/js/util";
import {ACT_GET_HELP_LIB_LIST} from "@/store/modules/help/help";
import {libTyCdDcds} from "@/assets/js/modules/help/help-common";
import LxpMainHeader from "@/components/common/LxpMainHeader";
import CommonPaginationFront from "@/components/CommonPaginationFront";

export default {
  name: "TakeLib",
  components: {CommonPaginationFront, LxpMainHeader},
  setup() {

    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const paging = ref(initPaging(route));

    const items = ref([]);

    const search = reactive({
      postTitle: ''
    });

    const getTakeLibList = () => {
      store.dispatch(`help/${ACT_GET_HELP_LIB_LIST}`, {
        postTitle: search.postTitle,
        libTyCdDcd: libTyCdDcds.LIB_TY_CD_TAKE_LIB,
        pageNo: paging.value.pageNo,
        pageSize: paging.value.pageSize
      }).then(res => {
        if (lengthCheck(res)) {
          items.value = getItems(res);
          paging.value = getPaging(res);
        } else {
          items.value = [];
          paging.value.totalCount = 0;
        }
      }).catch(e => {
        console.error(e);
      })
    }

    const pagingFunc = (pageNo, pageSize) => {
      const query = {pageNo: pageNo};
      if (pageSize !== paging.value.pageSize) {
        query.pageSize = pageSize;
      }
      if(search.postTitle) query.postTitle = search.postTitle;
      router.push({query: query});
    };

    const clickSearch = () => {
      pagingFunc(1, 10);
    };

    getTakeLibList();

    watch(() => route.query, () => {
      setParamsByQueryString(route, 'TakeLib', paging, search, true);
      getTakeLibList();
      initProgressBar();
    });

    onBeforeMount(() => {
      setParamsByQueryString(route, 'TakeLib', paging, search, true);
    });

    return {
      items,
      search,
      paging,
      pagingFunc,
      timestampToDateFormat,
      clickSearch
    }
  }
}
</script>