import ApiService from '@/assets/js/api.service';


export const ACT_GET_CRSE_DIST_INFO = 'getCrseDistInfo';
export const ACT_GET_CRSE_DIST_LIST_BY_OPER_ID = 'getCrseDistListByOperId';
export const ACT_GET_CRSE_DIST_CHECKLIST = 'getCrseDistChecklist';
export const ACT_INSERT_CRSE_DIST_CHECKLIST_TASK_NOTE = 'insertCrseDistChecklistTaskNote';
export const ACT_GET_CRSE_SURVEY_LIST = 'getCrseSurveyList';
export const ACT_INSERT_SRV_NOTI_SEND = 'insertSrvNotiSend';
export const ACT_GET_QR_ATTEND_OBJ_LIST = 'qrAttendObjList';

export const ACT_GET_QR_ATTEND_USER_STATUS_LIST = 'getQrAttendUserStatusList';

export const ACT_GET_NOW_USER_STATUS_LIST = 'getNowUserStatusList'
export const ACT_INSERT_TEST_NOTI_SEND_QR_ATTEND = 'actInsertTestNotiSendQrAttend';
export const ACT_INSERT_NOTI_SEND_QR_ATTEND = 'actInsertNotiSendQrAttend';

const state = {};


const mutations = {};

const actions= {
    [ACT_GET_CRSE_DIST_INFO](context, distCrseSn) {
        return new Promise(resolve => {
            ApiService.get('/v1/app/crses/dists/info', distCrseSn).then(response => resolve(response))
        });
    },
    [ACT_GET_CRSE_DIST_LIST_BY_OPER_ID](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/crses/dists/oper', params).then(response => resolve(response))
        });
    },
    [ACT_GET_CRSE_DIST_CHECKLIST](context, distCrseSn) {
        return new Promise(resolve => {
            ApiService.get('/v1/app/crses/dists/checklist', distCrseSn).then(response => resolve(response))
        });
    },
    [ACT_INSERT_CRSE_DIST_CHECKLIST_TASK_NOTE](context, params) {
        return new Promise(resolve => {
            ApiService.post('/v1/app/crses/dists/checklist/task/note', params).then(response => resolve(response))
        });
    },
    [ACT_GET_CRSE_SURVEY_LIST](context, distCrseSn) {
        return new Promise(resolve => {
            ApiService.get('/v1/app/checklist/crseSurveyList', distCrseSn).then(response => resolve(response))
        });
    },
    [ACT_INSERT_SRV_NOTI_SEND](context, {distSvySn, params}) {
        return new Promise(resolve => {
            ApiService.post(`/v1/app/checklist/sendSvy/${distSvySn}`, params).then(response => resolve(response))
        });
    },
    [ACT_GET_QR_ATTEND_OBJ_LIST](context, distCrseSn) {
        return new Promise(resolve => {
            ApiService.get('/v1/app/checklist/qrAttendObjList', distCrseSn).then(response => resolve(response))
        });
    },
    [ACT_GET_QR_ATTEND_USER_STATUS_LIST](context, {distCrseSn, objDistSn}){
        return new Promise(resolve => {
            ApiService.post(`/v1/app/checklist/qr/attend/users/status/${distCrseSn}/${objDistSn}`).then(response => resolve(response))
        });
    },
    [ACT_GET_NOW_USER_STATUS_LIST](context, {distCrseSn, objDistSn}){
        return new Promise(resolve => {
            ApiService.post(`/v1/app/checklist/now/users/status/${distCrseSn}/${objDistSn}`).then(response => resolve(response))
        });
    },
    [ACT_INSERT_NOTI_SEND_QR_ATTEND](context, params){
        return new Promise(resolve => {
            ApiService.post('/v1/app/checklist/notis/send/qrattend', params).then(response => resolve(response))
        });
    },
    [ACT_INSERT_TEST_NOTI_SEND_QR_ATTEND](context, params){
        return new Promise(resolve => {
            ApiService.post('/v1/app/checklist/test/notis/send/qrattend', params).then(response => resolve(response))
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
