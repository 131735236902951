<template>
  <div class="kb-form-column kb-form-column-title">
    <label class="kb-form-label">
      <strong class="kb-form-label-text">{{ title }}</strong>
    </label>
  </div>
  <div class="kb-form-column" :class="classLst">
    <template v-if="isNumber">
      <input v-if="max > 0"
             ref="inputEl"
             type="text"
             class="kb-form-control"
             :class="classLst"
             :value="modelValue"
             :maxlength="max"
             :placeholder="placeholder"
             :disabled="disabled"
             @input="updateValue"
             @focusin="handleFocus"
             @focusout="handleFocus"
      />
      <input v-else-if="isMoney"
             ref="inputEl"
             type="text"
             class="kb-form-control"
             :class="classLst"
             :value="numberComma(modelValue)"
             @input="updateValue"
             :placeholder="placeholder"
             :disabled="disabled"
             @focusin="handleFocus"
             @focusout="handleFocus"
      />
      <input v-else
             ref="inputEl"
             type="text"
             class="kb-form-control"
             :class="classLst"
             :value="numberComma(modelValue)"
             @input="updateValue"
             :placeholder="placeholder"
             :disabled="disabled"
             @focusin="handleFocus"
             @focusout="handleFocus"
      />
    </template>
    <input v-else
           ref="inputEl"
           type="text"
           class="kb-form-control"
           :class="classLst"
           :value="modelValue"
           @input="updateValue"
           :placeholder="placeholder"
           :disabled="disabled"
           @focusin="handleFocus"
           @focusout="handleFocus"
    />
  </div>
</template>
<script>

import {emptyNumberOnClick, numberComma, removeComma} from '@/assets/js/util';
import {ref} from 'vue';

export default {
  name: 'InputText',
  props: {
    modelValue: [String, Number],
    title: String,
    classLst: String,
    isNumber: {  type: Boolean, default: false },
    isMoney: { type: Boolean, default: false },
    disabled: { type:Boolean,  default: false },
    max: Number,
    placeholder: String,
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const inputEl = ref(null);
    const updateValue = (e) => {
      let val = e.target.value;
      if (props.isNumber) {
        if (props.isMoney) {
          val = removeComma(val);
        }
        val = Number(val);
      }
      emit('update:modelValue',val);
    };

    const handleFocus = (e) => {
      if (props.isMoney) {
        emptyNumberOnClick(e);
      }
    };

    return {
      inputEl, updateValue, handleFocus, numberComma
    }
  }
};
</script>