const descItems = (items) => {
  return items.sort((a,b) => {if(a > b){ return -1} return 0;});
};

export const removeMultiItems = (items, removes) => {
  removes = descItems(removes);
  const target = [...items];

  removes.forEach(remove => {
    target.splice(remove, 1);
  });
  return target;
};

export const fileLengthCheck = (files) => {
  return !!(files.value && files.value.files && files.value.files.length > 0);
};

export const getTotalSize = (files) => {
  let size = 0;
  for(let i = 0; i < files.length; i++){
    size += files[i].size;
  }
  return size;
};

export const getExtension = (fileName) => {
  return (/[.]/.exec(fileName)) ? /[^.]+$/.exec(fileName) : null;
};

export const checkExtension = (files, extensions) => {
  let result = false;
  for(let i = 0; i < files.length; i++){
    const extensionRex = getExtension(files[i].name);
    if(extensionRex && extensionRex[0]){
      if(!extensions.includes(extensionRex[0].toLowerCase())){
        result = true;
        break;
      }
    }else{
      result = true;
      break;
    }
  }
  return result;
};

const MIME = [
  { type: "image/bmp", ext: 'bmp' },
  { type: "image/cis-cod", ext: 'cod' },
  { type: "image/gif", ext: 'gif' },
  { type: "image/ief", ext: 'ief' },
  { type: "image/jpeg", ext: 'jpe' },
  { type: "image/jpeg", ext: 'jpeg' },
  { type: "image/jpeg", ext: 'jpg' },
  { type: "image/pipeg", ext: 'jfif' },
  { type: "image/svg+xml", ext: 'svg' },
  { type: "image/tiff", ext: 'tif' },
  { type: "image/tiff", ext: 'tiff' },
  { type: "image/x-cmu-raster", ext: 'ras' },
  { type: "image/x-cmx", ext: 'cmx' },
  { type: "image/x-icon", ext: 'ico' },
  { type: "image/x-portable-anymap", ext: 'pnm' },
  { type: "image/x-portable-bitmap", ext: 'pbm' },
  { type: "image/x-portable-graymap", ext: 'pgm' },
  { type: "image/x-portable-pixmap", ext: 'ppm' },
  { type: "image/x-rgb", ext: 'rgb' },
  { type: "image/x-png", ext: 'png' },
];

export const getMimeTypeByExtension = (ext) => {
  if (ext && ext.length > 0) {
    MIME.filter(x => {
      if (x.ext === ext) {
        return x;
      }
    });
  }
};

// CMS 첨부파일 허용 확장자
export const allowedCmsAtchFile = ['application/pdf', 'image/*', 'application/vnd.ms-excel', 'application/vnd.ms-powerpoint', 'application/msword'];

export const allowedKbtubeAtchFile = ['application/pdf', 'application/vnd.ms-powerpoint', 'application/msword'];

export const allowedThumbFile = ['image/bmp','image/gif','image/jpeg','image/svg+xml', 'image/x-png'];

export const atchFileExts = ['gif' ,'jpg' ,'jpeg' ,'png' ,'webp' ,'bmp' ,'doc' ,'docx' ,'pdf' ,'xls' ,'xlsx' ,'txt' ,'ppt' ,'pptx'];

export const kbtubeAtchFileExts = ['ppt', 'pptx', 'pdf', 'doc', 'docx'];

export const allowedThumbExts = ['bmp','gif','jpe','jpeg','jpg','svg', 'png'];

