import {ref} from "vue";

export const quizMngModalSetup = (props, {emit}) => {

    const activeTab = ref('quiz');
    const closeModal = () => {
        emit('update:modelValue', false);
    };

    return {
        activeTab,
        closeModal
    }

}