import {useStore} from 'vuex';
import {useAlert} from '@/assets/js/modules/common/alert';
import {computed, reactive, ref, watch} from 'vue';
import {actTyCdDcds} from '@/assets/js/modules/common/common';
import {
  goToGroupBtnTitle,
  goToHrdBtnTitle,
  hrdCancelPut,
  hrdGroupPutGoToGroupPage,
  hrdPutGoToMyPage,
  hrdPutGoToMyPageBtnTitle,
  hrdPutGoToPage,
  hrdPutTitle,
} from '@/assets/js/modules/hrd/hrd-message';
import {
  closeHrdLeaderAdd, cttTyCdDcds, darkMode,
  duringRoadmapEventPeriod,
  enableMyAddChanged,
  getGroupLeader,
  lightMode,
  showAdded,
} from '@/assets/js/modules/hrd/hrd-common';
import {
  ACT_GET_CRSE_ACT_ADD,
  ACT_INSERT_CRSE_ACT_LOG,
} from '@/store/modules/course/course';
import {ACT_GET_HRDCLOUD_DETAIL} from '@/store/modules/prep/prep';
import {getItem, initParams, isSuccess, lengthCheck, paramCheck, setParams} from '@/assets/js/util';
import {
  hrdCloudRoadmapCateLevels,
  hrdCloudRoadmapCateSns
} from "@/assets/js/modules/cloud/cloud-common";
import {ACT_GET_HRDCLOUD_ROADMAP_CTT, ACT_INSERT_HRDCLOUD_ROADMAP_CTT, MUT_TOGGLE_HRD_ROADMAP} from "@/store/modules/hrdcloud/hrdcloud";
import {closeToggles} from "@/assets/js/ui.init";
import {useRoute} from "vue-router";

export const hrdLeaderAddSetup = () => {
  const store = useStore();
  const route = useRoute();
  const {showLoading, hideLoading, showCustomConfirm, showConfirmCustomBtn, showMessage} = useAlert();
  const session = computed(() => store.state.auth.session);
  const isMobile = computed(() => store.state.auth.isMobile);
  const show = computed(() => store.state.hrdcloud.leaderAdd.show);

  const leaderAdd = computed(() => store.state.hrdcloud.leaderAdd);
  const lrnObjDtlDistSn = computed(() => leaderAdd.value.lrnObjDtlDistSn);
  const objNm = computed(() => leaderAdd.value.objNm);
  const isPdf = computed(() => leaderAdd.value.cttTyCdDcd === cttTyCdDcds.CTT_TY_CD_PDF);

  const params = reactive({
    actTyCdDcd: actTyCdDcds.ACT_TY_CD_ADD,
    crseMstSn: 0,
    distCrseSn: 0,
    lrnObjDtlDistSn: 0,
    objSn: 0,
    distCrseNm: null,
    objNm: 0
  });

  const isPut = ref(false), isLeaderPut = ref(false), isRoadmapSaved = ref(false);
  const put = ref(false), glPut = ref(false), roadmap = ref(false);
  const putChanged = ref(false), glPutChanged = ref(false);
  const myAddResult = ref(null), groupAddResult = ref(null), roadmapAddResult = ref(null);

  const roadmapCates = computed(() => store.state.hrdcloud.hrdcloudCates.filter(x => hrdCloudRoadmapCateSns.includes(x.hrdCloudCateSn)));
  const roadmapToggle = reactive({
    cate: false,
    level: false,
  })
  const roadmapParam = reactive({
    hrdCloudRoadmapCateSn: 0,
    hrdCloudRoadmapCateNm: '',
    hrdCloudRoadmapCateLevel: 0,
    hrdCloudRoadmapCateLevelNm: '',
  })

  const showRoadmapOption = ref(false)
  const showGlPutOption = ref(false)

  const checkAndInsert = async () => {
    // 개인 담기
    if (isPut.value !== put.value) {
      let payload = {...params};
      putChanged.value = true;

      put.value ? payload.actTyCdDcd = actTyCdDcds.ACT_TY_CD_ADD : payload.actTyCdDcd = actTyCdDcds.ACT_TY_CD_CANCEL_ADD;

      myAddResult.value = await insertCourseAct(payload);
    }

    // 그룹담기
    if (isLeaderPut.value !== glPut.value) {
      glPutChanged.value = true;

      let payload = {...params};
      payload.crseMstSn = leaderAdd.value.crseMstSn;
      payload.distCrseSn = leaderAdd.value.distCrseSn;
      payload.distCrseNm = leaderAdd.value.distCrseNm;
      glPut.value ? payload.actTyCdDcd = actTyCdDcds.ACT_TY_CD_ADD : payload.actTyCdDcd = actTyCdDcds.ACT_TY_CD_CANCEL_ADD;

      groupAddResult.value = await insertCourseAct(payload);
    }

    // 로드맵 공모
    roadmapAddResult.value = null;
    if(roadmap.value && !isRoadmapSaved.value){
      await store.dispatch(`hrdcloud/${ACT_INSERT_HRDCLOUD_ROADMAP_CTT}`, {
        cttMstSn: params.objSn,
        lrnObjDtlDistSn: params.lrnObjDtlDistSn,
        params: {
          hrdCloudCateSn: roadmapParam.hrdCloudRoadmapCateSn,
          hrdCloudCateLevel: roadmapParam.hrdCloudRoadmapCateLevel,
        },
      }).then(res => {
        if(isSuccess(res)){
          roadmapAddResult.value = `콘텐츠가 로드맵 목록에 담겼습니다.<br>지금 확인하시겠습니까?`;
        }else if(res.result.message == 'SUBMITTED'){
          roadmapAddResult.value = `이미 제출된 로드맵입니다.<br>제출 취소는 로드맵공모 화면에서 가능합니다.`;
        }else{
          roadmapAddResult.value = `로드맵 담기에 실패하였습니다.<br>잠시 후 다시 시도해주세요.`;
        }
      })
    }
  };

  const initChanged = () => {
    putChanged.value = false;
    glPutChanged.value = false;
    put.value = false;
    glPut.value = false;
  };

  const checkConfirm = () => {
    if (!paramCheck(roadmapAddResult.value)) {
      showConfirmCustomBtn({
        title: '로드맵 공모',
        text: roadmapAddResult.value,
        okBtnTitle: '보러가기',
        callback: () => {
          store.commit(`hrdcloud/${MUT_TOGGLE_HRD_ROADMAP}`, true);
        }
      })
    }
    else if (putChanged.value || glPutChanged.value) {
      if (put.value && glPut.value) {
        enableMyAddChanged();
        // 3개 HRD, GROUP, 취소
        showCustomConfirm(hrdPutGoToPage, goToHrdBtnTitle, goToGroupBtnTitle,
            () => {
              showAdded();
            }, () => {
              showAdded(null, 'grouplearning');
            });
      } else if (!put.value && !glPut.value) {
        enableMyAddChanged();
        // 담기 취소되었습니다. 확인
        showMessage(hrdCancelPut);
        // 리시트에서 노출하는 담기 버튼 아이콘 업데이트하기 위한 함수
      } else if (put.value || glPut.value) {
        // 둘중 하나만
        if (putChanged.value && put.value) {
          enableMyAddChanged();
          showConfirmCustomBtn({
            title: hrdPutTitle,
            text: hrdPutGoToMyPage,
            okBtnTitle: hrdPutGoToMyPageBtnTitle,
            callback: () => {
              showAdded();
            },
          });
        } else if (glPutChanged.value && glPut.value) {
          showConfirmCustomBtn({
            title: hrdPutTitle,
            text: hrdGroupPutGoToGroupPage,
            okBtnTitle: hrdPutGoToMyPageBtnTitle,
            callback: () => {
              showAdded(null, 'grouplearning');
            },
          });
        } else {
          if (!put.value) enableMyAddChanged();
          showMessage(hrdCancelPut);
        }
      }
    }
    closeHrdLeaderAdd(put.value);
    initChanged();
  };

  const insertCourseAct = (payload) => {
    return store.dispatch(`course/${ACT_INSERT_CRSE_ACT_LOG}`, {...payload});
  };

  const handlePut = async () => {
    if(roadmap.value && (paramCheck(roadmapParam.hrdCloudRoadmapCateSn)|| paramCheck(roadmapParam.hrdCloudRoadmapCateLevel))){
      showMessage('로드맵 구분을 선택해주세요.');
      return;
    }
    // 그룹러닝 PDF 담기 불가
    if(glPut.value && isPdf.value){
      showMessage('PDF 자료는 그룹러닝 콘텐츠로 담을 수 없습니다.');
      glPut.value = false;
      return;
    }
    showLoading();
    await checkAndInsert();
    try {
      hideLoading();
      checkConfirm();
    } catch(e) {
      hideLoading();
      showMessage('오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
    }
  };

  const roadmapToggleChange = (target) => {
    if(!isRoadmapSaved.value){
      Object.keys(roadmapToggle).forEach(k => {
        if(k == target){
          roadmapToggle[k] = !roadmapToggle[k];
        }else{
          roadmapToggle[k] = false;
        }
      })
    }else{
      showConfirmCustomBtn({
        title: '로드맵 공모',
        text: `로드맵<span class="text-muted">(${roadmapParam.hrdCloudRoadmapCateNm}-${roadmapParam.hrdCloudRoadmapCateLevelNm})</span>에 이미 담긴 콘텐츠입니다.<br>변경 및 삭제는 로드맵공모 화면에서 가능합니다.`,
        okBtnTitle: '보러가기',
        callback: () => {
          closeHrdLeaderAdd();
          store.commit(`hrdcloud/${MUT_TOGGLE_HRD_ROADMAP}`, true);
        }
      })
    }
  }

  const setRoadmapParam = (idx, type) => {
    if(type == 'cate'){
      roadmapParam.hrdCloudRoadmapCateSn = roadmapCates.value[idx].hrdCloudCateSn;
      roadmapParam.hrdCloudRoadmapCateNm = roadmapCates.value[idx].hrdCloudCateNm;
    }
    if(type == 'level'){
      roadmapParam.hrdCloudRoadmapCateLevel = hrdCloudRoadmapCateLevels[idx].level;
      roadmapParam.hrdCloudRoadmapCateLevelNm = hrdCloudRoadmapCateLevels[idx].label;
    }
    roadmapToggleChange('');
  }

  const goBackFunc = () => {
    closeHrdLeaderAdd();
  };

  watch(() => show.value, async () => {
    if (show.value) {
      if (isMobile.value) {
        lightMode();
      }
      showLoading();
      try {
        // 그룹러닝 담기 값
        const trgtRes = await getGroupLeader(session.value.lrnerId);
        if(trgtRes && trgtRes.trgt) {
          showGlPutOption.value = true;
          let trgt = trgtRes.trgt;
          if (leaderAdd.value.objSn > 0) {
            const addRes = await store.dispatch(`course/${ACT_GET_CRSE_ACT_ADD}`, {distCrseSn:trgt.distCrseSn,objSn:leaderAdd.value.objSn});
            isLeaderPut.value = (addRes && addRes.put ? addRes.put : false);
            glPut.value = (addRes && addRes.put ? addRes.put : false);
          }
        }
        // 개인 담기 값
        const res = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_DETAIL}`, {lrnObjDtlDistSn: lrnObjDtlDistSn.value});
        if (lengthCheck(res)) {
          const hrd = getItem(res);
          setParams(params, hrd);
          isPut.value = hrd.put;
          put.value = hrd.put;
          // 2:지식 클라우드, 5:부점장전용 제외
          // 이벤트 기간동안 노출: 2023.11.1 ~ 2023.11.19
          showRoadmapOption.value = !hrd.cloudKeyLst.startsWith('2') && !hrd.cloudKeyLst.startsWith('5') && duringRoadmapEventPeriod();
        }
        // 로드맵 공모 여부
        if(showRoadmapOption.value){
          await store.dispatch(`hrdcloud/${ACT_GET_HRDCLOUD_ROADMAP_CTT}`, params.objSn).then((res) => {
            if(lengthCheck(res)){
              const roadmapRes = getItem(res);
              roadmapParam.hrdCloudRoadmapCateSn = roadmapRes.hrdCloudCateSn;
              roadmapParam.hrdCloudRoadmapCateLevel = roadmapRes.hrdCloudCateLevel;
              roadmapParam.hrdCloudRoadmapCateNm = roadmapCates.value.find(x => x.hrdCloudCateSn == roadmapParam.hrdCloudRoadmapCateSn).hrdCloudCateNm;
              roadmapParam.hrdCloudRoadmapCateLevelNm = hrdCloudRoadmapCateLevels.find(x => x.level == roadmapParam.hrdCloudRoadmapCateLevel).label;
              roadmap.value = true;
              isRoadmapSaved.value = true;
            }
          })
        }

        hideLoading();
      } catch (e) {
        hideLoading();
      }
    } else {
      if (isMobile.value && route.name === 'HrdCloudContentBridge') {
        darkMode();
      }
      initParams(params);
      initParams(roadmapParam);
      closeToggles(roadmapToggle);
      roadmap.value = false;
      isRoadmapSaved.value = false;
      showGlPutOption.value = false;
      showRoadmapOption.value = false;
    }
  });

  return {
    show,
    isPut,
    isLeaderPut,
    put,
    glPut,
    showGlPutOption,
    roadmap,
    isRoadmapSaved,
    handlePut,
    objNm,
    isPdf,
    goBackFunc,
    roadmapCates,
    hrdCloudRoadmapCateLevels,
    roadmapToggle,
    roadmapToggleChange,
    roadmapParam,
    setRoadmapParam,
    showRoadmapOption,
  }
}