<template>
  <LxpConfirm/>
  <template v-if="isMobile">
    <LxpMobileToast />
  </template>
  <template v-else>
    <LxpToast/>
  </template>
  <LxpCustomConfirm/>
  <LxpMessage/>
  <LxpLoading
      v-if="loading"
      :loading="loading"
  />  
</template>
<script>
import {useStore} from 'vuex';
import {computed} from 'vue';
import LxpConfirm from '@/components/common/LxpConfirm';
import LxpMessage from '@/components/common/LxpMessage';
import LxpLoading from '@/components/common/LxpLoading';
import LxpCustomConfirm from '@/components/common/LxpCustomConfirm.vue';
import LxpToast from "@/components/common/LxpToast.vue";
import LxpMobileToast from "@/components/common/mobile/LxpMobileToast.vue";
import navigationUtils from "@/assets/js/navigationUtils";

export default {
  name: 'LxpPopup',
  components: {LxpMobileToast, LxpToast, LxpCustomConfirm, LxpLoading, LxpMessage, LxpConfirm},
  props: {},
  setup(){
    const store = useStore();
    const loading = computed(() => store.state.common.loading);
    return {
      loading,
      isMobile: navigationUtils.any()
    }
  }
}
</script>
