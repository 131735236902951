<template>
  <!-- [begin::popup-container] -->
  <div class="popup-container" :class="{'is-active': modelValue}">
    <!-- popup -->
    <div class="popup" id="popup-quiz">
      <!-- popup > popup-inner -->
      <div class="popup-inner">
        <div>
          <!-- popup-inner > popup-header -->
          <header class="popup-header">
            <h3 class="title">퀴즈 추가</h3>
          </header>
          <!-- popup-inner > popup-content -->
          <div class="popup-content">
            <!-- popup-section:퀴즈문제지 -->
            <article class="popup-section">
              <div class="section-content">
                <div class="kb-form-fields">
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <span class="kb-form-label-text">퀴즈명</span>
                      </label>
                    </div>
                    <div class="kb-form-column">
                      <input type="text" v-model="quizTitle" class="kb-form-control" placeholder="퀴즈명을 입력하세요" />
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
          <!-- popup-inner > popup-buttons -->
          <div class="popup-buttons">
            <button class="kb-btn kb-btn-outline kb-btn-lg" @click="closeModal"><span class="text">취소</span></button>
            <button class="kb-btn kb-btn-primary kb-btn-lg" @click="add"><span class="text">추가</span></button>
          </div>
        </div>
      </div>
      <!-- popup > popup-close -->
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
    <!-- //popup -->
  </div>
  <!-- //[begin::popup-container] -->
</template>

<script>
import {ref} from "vue";

export default {
  name: "QuizAddModal",
  props: {
    modelValue: Boolean,
    clickAdd: Function
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {

    const quizTitle = ref('');

    const add = () => {
      props.clickAdd(quizTitle.value);
    };

    const closeModal = () => {
      emit('update:modelValue', false);
    };

    return {
      quizTitle,
      add,
      closeModal
    }
  }
}
</script>