<template>
  <div class="popup-container" data-popup="popup-viewer"  :class="{'is-active': isVisible}">
    <div class="popup kb-preview-width">
      <div class="board-view-container">
        <!-- board-view-body -->
        <div class="board-view-body">
          <div class="board-view-header view-component" style="margin-bottom: 80px;margin-top: 24px;">
            <h6 class="page-title"><span class="author">{{ session.lrnerNm }}</span><span>&nbsp;&nbsp;</span><span class="office">{{ session.deptNm }}</span></h6>
            <h2 class="title">{{ socBoard.title }}</h2>
            <div class="page-info">
              <span class="date">{{ todayDate }}</span>
            </div>
          </div>
          <div class="board-view-body view-component">
            <div class="editor-area">
              <div v-html="currentPage.cn"></div>
            </div>
            <SSLViewRel
                :hash-tags="hashTags"
                :ref-url="socBoard.refUrl"
                :ref-post="socBoard.refPost"
            />
          </div>
        </div>
      </div>
      <button class="kb-btn-popup-close"  @click="toggleVisible(false)">
        <span class="kb-btn-background"></span>
        <i class="icon-close"></i>
      </button>
    </div>
  </div>
</template>
<style>
.kb-preview-width{
  width: 1200px;
}
</style>

<script>
import {computed, ref} from "vue";
import {useStore} from "vuex";
import {dateToDateFormat} from "@/assets/js/util";
import SSLViewRel from "@/components/ssl/view/SSLViewRel";

export default {
  name: 'SSLPreview',
  components: {SSLViewRel},
  props: {
    isVisible: Boolean,
    toggleVisible: Function,
    socBoard: Object,
    currentPageIdx: Number,
    socPages: Array,
    hashTags: Array
  },
  setup(props) {
    const store = useStore();
    const session = computed(() => store.state.auth.session ? store.state.auth.session : {});

    const currentPage = computed(() => {
      if(props.socPages.length > 0 && props.socPages[props.currentPageIdx]){ return props.socPages[props.currentPageIdx]; }
      return {
        title: '',
        cn: ''
      };
    });

    const todayDate = ref(dateToDateFormat(new Date(), 'yyyy.MM.dd'));

    return {
      session,
      currentPage,
      todayDate,
    }
  }
}
</script>
