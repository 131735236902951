import {
    ACT_ENROLL_SSL,
    ACT_GET_MY_LEARN_TARGET_LIST,
    ACT_GET_REL_LIST,
    ACT_GET_SOC_ACT_LIST,
    ACT_GET_SOC_ACT_STAT_LIST,
    ACT_GET_SOC_ACTS_ATTEND_LIST, ACT_GET_SOC_MY_REL,
    ACT_INSERT_SOC_ACT, MUT_INIT_MY_REL,
    MUT_SET_MY_ATTENDANCE_LIST,
    MUT_SET_MY_LEARN_TARGET_LIST, MUT_SET_MY_REL,
    MUT_SET_STATICS,
    MUT_SET_SUBSCRIBE,
    MUT_SHOW_ATTENDANCE,
    MUT_SHOW_BADGE,
} from '@/store/modules/ssl/ssl-index';
import store from '@/store';
import router from '@/router';
import {
    // dateToDateFormat,
    dateToDatePart,
    getCheckItems, getItem, lengthCheck,
    timestampToDateFormat,
    timestampToDatePart,
} from '@/assets/js/util';
import {getBadgeList} from '@/assets/js/modules/ssl/ssl-badge';
import {holyDay} from '@/assets/js/holy-day';
import {ACT_KB_EHRD_SSO_AUTH, ACT_KB_SSO_AUTH} from '@/store/modules/auth/auth';
import {popupBlock} from '@/assets/js/modules/ssl/ssl-message';
import {actTyCdDcds} from '@/assets/js/modules/common/common';
import mobileUtils, {isInApp, isInAppIOS} from '@/assets/js/navigationUtils';
import {getToken} from '@/assets/js/localstorage.service';
import {useAlert} from '@/assets/js/modules/common/alert';
import {getLearnTargetList} from '@/assets/js/modules/ssl/ssl-learn';
import {expsrLocCds} from '@/assets/js/modules/help/help-common';
// import { ACT_GET_PROFILE_IMG_URL_BY_ID } from '@/store/modules/my/my';
import {isSSLPeriod} from '@/assets/js/modules/ssl/ssl-write';
// import {isAdmin} from "@/assets/js/modules/auth/auth-common";

export const currentYear = '2024';
// 상반기 하반기 기준 정책
export const currentYmd = dateToDatePart(new Date()).ymd;
export const halfYmd = '20230827';
export const bookHalfYmd = '20230816'
export const halfNo = (currentYmd > halfYmd) ? 2 : 1;
export const calendarKey = 'kb-ssl-calendar';
export const sslOpenYmd = '20240319';
export const sslOpenDate = '202403191500';

export const noteLimit = {
    self: 32,
    selfGa:32,
    selfGi:32,
    selfJa:32,
    selfIct:32,
    group: 24,
    knowledge: 24,
    hrd: 24,
    book: 8, //(currentYmd > halfYmd) ? 8:8,
    trivia: 0,
    college: 0,
    live: 0
}

export const sslStatusStandard = {
    self: {
        finish: 7,
        limit: 32
    },
    selfGa: {
        finish: 7,
        limit: 32
    },
    selfGi: {
        finish: 7,
        limit: 32
    },
    selfJa: {
        finish: 7,
        limit: 32
    },
    selfIct: {
        finish: 7,
        limit: 32
    },
    group: {
        finish: 5,
        limit: 24
    },
    knowledge: {
        finish: 6,
        limit: 24
    },
    hrd: {
        finish: 6,
        limit: 24
    },
    book: {
        finish: 2,
        limit: 8
    },
    'book-first': {
        finish: 2,
        limit: 8
    },
    'book-second': {
        finish: 2,
        limit: 8
    }
}

export const enableExperiencePeriod = {
    self: '202303231600',
    selfGa: '202402201600',
    selfGi: '202402201600',
    selfJa: '202402201600',
    selfIct: '202402201600',
    group: '202402201600',
    knowledge: '202303231600',
    hrd: '202303231600',
    trivia: '202402201600',
    live: '202303231600',
    book: '202407011700',
    // book: '202406011600',
    college: '202304281600',
}

export const sslCodes={2022:{self:{distCrseSns:[100001,100002,100003],name:"셀프러닝",group:[{lrnTrgtGrpMstSn:300001},{lrnTrgtGrpMstSn:300002},{lrnTrgtGrpMstSn:300003}]},group:{distCrseSns:[100004,100005,100006],name:"그룹러닝",group:[{lrnTrgtGrpMstSn:100004},{lrnTrgtGrpMstSn:100005},{lrnTrgtGrpMstSn:100006}]},knowledge:{distCrseSns:[100007],name:"지식e러닝",group:[{lrnTrgtGrpMstSn:100007,lrnTrgtGrpNm:"지식e-러닝"}]},hrd:{distCrseSns:[100008],name:"HRD클라우드러닝",group:[{lrnTrgtGrpMstSn:100008}]},book:{distCrseSns:[100010,100011],name:"북러닝",group:[{lrnTrgtGrpMstSn:100010},{lrnTrgtGrpMstSn:100011}]}},2023:{self:{distCrseSns:[202376,202377,202378,202379],name:"셀프러닝",group:[{lrnTrgtGrpMstSn:112116},{lrnTrgtGrpMstSn:112117},{lrnTrgtGrpMstSn:112118},{lrnTrgtGrpMstSn:112119}]},group:{distCrseSns:[202380],name:"그룹러닝",group:[{lrnTrgtGrpMstSn:112120}]},knowledge:{distCrseSns:[202381],name:"지식e-러닝",group:[{lrnTrgtGrpMstSn:112121,lrnTrgtGrpNm:"지식e-러닝"}]},hrd:{distCrseSns:[202382],name:"HRD클라우드러닝",group:[{lrnTrgtGrpMstSn:112122}]},book:{distCrseSns:[202383,202387],name:"북러닝",group:[{lrnTrgtGrpMstSn:112123},{lrnTrgtGrpMstSn:112127}]}},2024:{selfGa:{distCrseSns:[206129],name:"셀프러닝-가계여신",group:[{lrnTrgtGrpMstSn:115614}]},selfGi : { distCrseSns:[206130],name:"셀프러닝-기업여신/외환",group:[{lrnTrgtGrpMstSn:115615}]},selfJa : { distCrseSns:[206128],name:"셀프러닝-자산관리/퇴직연금",group:[{lrnTrgtGrpMstSn:115613}]},selfIct : { distCrseSns:[206131],name:"셀프러닝-자산관리/퇴직연금",group:[{lrnTrgtGrpMstSn:115616}]},group : { distCrseSns:[206132],name:"그룹러닝",group:[{lrnTrgtGrpMstSn:115617}]},book : { distCrseSns:[206133],name:"북러닝",group:[{lrnTrgtGrpMstSn:115618}]},trivia : { distCrseSns:[206500],name:"알SSL신잡",group:[{lrnTrgtGrpMstSn:115985}]}}};
export const sslRoutes = (() => {
    const r = {
        // self: {
        //     expsrLocCd: 2100003, //SSL_셀프러닝
        //     distCrseSns: [206129, 206130, 206128, 206131],
        //     lrnTrgtGrpMstSns: [115614, 115615, 115613, 115616],
        //     key: 'self',
        //     name: '셀프러닝',
        //     group: [
        //         {lrnTrgtGrpMstSn: 115614, lrnTrgtGrpNm: '가계여신'},
        //         {lrnTrgtGrpMstSn: 115615, lrnTrgtGrpNm: '기업여신/외환'},
        //         {lrnTrgtGrpMstSn: 115613, lrnTrgtGrpNm: '자산관리/퇴직연금'},
        //         {lrnTrgtGrpMstSn: 115616, lrnTrgtGrpNm: 'ICT'}],
        // },
        selfGa: {
            expsrLocCd: 2100003, //SSL_셀프러닝
            distCrseSns: [206129],
            lrnTrgtGrpMstSns: [115614],
            key: 'selfGa',
            name: '셀프러닝-가계여신',
            group: [{lrnTrgtGrpMstSn: 115614, lrnTrgtGrpNm: '가계여신'}],
        },
        selfGi: {
            expsrLocCd: 2100003, //SSL_셀프러닝
            distCrseSns: [206130],
            lrnTrgtGrpMstSns: [115615],
            key: 'selfGi',
            name: '셀프러닝-기업여신/외환',
            group: [{lrnTrgtGrpMstSn: 115615, lrnTrgtGrpNm: '기업여신/외환'}],
        },
        selfJa: {
            expsrLocCd: 2100003, //SSL_셀프러닝
            distCrseSns: [206128],
            lrnTrgtGrpMstSns: [115613],
            key: 'selfJa',
            name: '셀프러닝-자산관리/퇴직연금',
            group: [{lrnTrgtGrpMstSn: 115613, lrnTrgtGrpNm: '자산관리/퇴직연금'}],
        },
        selfIct: {
            expsrLocCd: 2100003, //SSL_셀프러닝
            distCrseSns: [206131],
            lrnTrgtGrpMstSns: [115616],
            key: 'selfIct',
            name: '셀프러닝-ICT',
            group: [{lrnTrgtGrpMstSn: 115616, lrnTrgtGrpNm: 'ICT'}],
        },
        group: {
            expsrLocCd: 2100004, //SSL_그룹러닝
            distCrseSns: [206132],
            lrnTrgtGrpMstSns: [115617],
            key: 'group',
            name: '그룹러닝',
            group: [{lrnTrgtGrpMstSn: 115617, lrnTrgtGrpNm: '그룹러닝'}],
        },
        book: {
            expsrLocCd: 2100007,
            distCrseSns: [206133], // [202383], -- 202383 : 상반기 / 202387 : 하반기
            lrnTrgtGrpMstSns: [115618], // [112123], -- 112123 : 상반기 / 112127 : 하반기
            key: 'book',
            name: '북러닝',
            group: [{lrnTrgtGrpMstSn: 115618, lrnTrgtGrpNm: '북러닝'}], //[{lrnTrgtGrpMstSn: 112123, lrnTrgtGrpNm: '북러닝'}],
            refGroup: [{lrnTrgtGrpMstSn: 115618, lrnTrgtGrpNm: '북러닝'}]
        },
        // knowledge: {
        //     expsrLocCd: 2100005,
        //     distCrseSns: [202381],
        //     lrnTrgtGrpMstSns: [112121],
        //     key: 'knowledge',
        //     name: '지식e러닝',
        //     group: [{lrnTrgtGrpMstSn: 112121, lrnTrgtGrpNm: '지식e-러닝'}],
        // },
        // hrd: {
        //     expsrLocCd: 2100006,
        //     distCrseSns: [202382],
        //     lrnTrgtGrpMstSns: [112122],
        //     key: 'hrd',
        //     name: 'HRD클라우드러닝',
        //     group: [{lrnTrgtGrpMstSn: 112122, lrnTrgtGrpNm: 'HRD클라우드러닝'}],
        // },
        // book: {
        //     expsrLocCd: 2100007,
        //     distCrseSns: [206133], // [202383], -- 202383 : 상반기 / 202387 : 하반기
        //     lrnTrgtGrpMstSns: [115618], // [112123], -- 112123 : 상반기 / 112127 : 하반기
        //     key: 'book',
        //     name: '북러닝',
        //     group: [{lrnTrgtGrpMstSn: 115618, lrnTrgtGrpNm: '북러닝'}], //[{lrnTrgtGrpMstSn: 112123, lrnTrgtGrpNm: '북러닝'}],
        //     refGroup: [
        //         {lrnTrgtGrpMstSn: 115618, lrnTrgtGrpNm: '북러닝'},
        //         {lrnTrgtGrpMstSn: 112127, lrnTrgtGrpNm: '북러닝'}]
        // },
        //SSL과정 기본정의
        // college:{
        //     expsrLocCd: 2100014,
        //     distCrseSns: [202384],
        //     lrnTrgtGrpMstSns: [112124],
        //     key: 'college',
        //     name: '직무 College',
        //     group: [{lrnTrgtGrpMstSn: 112124, lrnTrgtGrpNm: '직무college'}],
        // },
        // live: {
        //     expsrLocCd: 2100015,
        //     distCrseSns: [202385],
        //     lrnTrgtGrpMstSns: [112125],
        //     key: 'live',
        //     name: '러닝라이브리뷰',
        //     group: [{lrnTrgtGrpMstSn: 112125, lrnTrgtGrpNm: '러닝라이브리뷰'}],
        // },
        trivia:{
            expsrLocCd: 2100008,
            distCrseSns: [206500],
            lrnTrgtGrpMstSns: [115985],
            key: 'trivia',
            name: '알SSL신잡',
            group: [{lrnTrgtGrpMstSn: 115985, lrnTrgtGrpNm: '알SSL신잡'}],
        }


    };
    return r;
    //
    // return 1 === halfNo ?
    //     r.book = {
    //         expsrLocCd: 2100007,
    //         distCrseSns: [202383],
    //         lrnTrgtGrpMstSns: [112123],
    //         key: 'book',
    //         name: '북러닝',
    //         group: [{lrnTrgtGrpMstSn: 112123, lrnTrgtGrpNm: '북러닝'}],
    //         refGroup: [
    //             {lrnTrgtGrpMstSn: 112123, lrnTrgtGrpNm: '북러닝'},
    //             {lrnTrgtGrpMstSn: 112127, lrnTrgtGrpNm: '북러닝'}],
    //     } :
    //     2 === halfNo && (r.book = {
    //         expsrLocCd: 2100007,
    //         distCrseSns: [202387],
    //         lrnTrgtGrpMstSns: [112127],
    //         key: 'book',
    //         name: '북러닝',
    //         group: [{lrnTrgtGrpMstSn: 112127, lrnTrgtGrpNm: '북러닝'}],
    //         refGroup: [
    //             {lrnTrgtGrpMstSn: 112123, lrnTrgtGrpNm: '북러닝'},
    //             {lrnTrgtGrpMstSn: 112127, lrnTrgtGrpNm: '북러닝'}],
    //     })
    //     , r.college = {
    //     expsrLocCd: 100012,
    //     distCrseSns: [202384],
    //     lrnTrgtGrpMstSns: [112124],
    //     key: 'college',
    //     name: '직무 College',
    //     group: [{lrnTrgtGrpMstSn: 112124, lrnTrgtGrpNm: '직무college'}],
    // }, r.live = {
    //     expsrLocCd: 100013,
    //     distCrseSns: [202385],
    //     lrnTrgtGrpMstSns: [112125],
    //     key: 'live',
    //     name: '러닝라이브리뷰',
    //     group: [{lrnTrgtGrpMstSn: 112125, lrnTrgtGrpNm: '러닝라이브리뷰'}],
    // }, r.trivia = {
    //     expsrLocCd: 100009,
    //     distCrseSns: [202386],
    //     lrnTrgtGrpMstSns: [112126],
    //     key: 'trivia',
    //     name: '알SSL신잡',
    //     group: [{lrnTrgtGrpMstSn: 112126, lrnTrgtGrpNm: '알SSL신잡'}],
    // }, r;


    // return 1 === bookHalfYmd ?
    //     r.book = {
    //         expsrLocCd: 2100007,
    //         distCrseSns: [206133],
    //         lrnTrgtGrpMstSns: [115618],
    //         key: 'book',
    //         name: '북러닝',
    //         group: [{lrnTrgtGrpMstSn: 115618, lrnTrgtGrpNm: '북러닝'}],
    //         refGroup: [
    //             {lrnTrgtGrpMstSn: 115618, lrnTrgtGrpNm: '북러닝'},
    //             {lrnTrgtGrpMstSn: 112127, lrnTrgtGrpNm: '북러닝'}],
    //     } :
    //     r.book = {
    //         expsrLocCd: 2100007,
    //         distCrseSns: [202387],
    //         lrnTrgtGrpMstSns: [112127],
    //         key: 'book',
    //         name: '북러닝',
    //         group: [{lrnTrgtGrpMstSn: 112127, lrnTrgtGrpNm: '북러닝'}],
    //         refGroup: [
    //             {lrnTrgtGrpMstSn: 112123, lrnTrgtGrpNm: '북러닝'},
    //             {lrnTrgtGrpMstSn: 112127, lrnTrgtGrpNm: '북러닝'}],
    //     }, r;
})();

export const sslNavRoutes= (myLrnTrgtGrpMstSns) =>{

    // if (getTodayDate('yyyyMMdd') < '20240701'){
    //     return Object.values(sslRoutes).filter(x => x.key != 'book').filter(x=>(x.lrnTrgtGrpMstSns.filter(y=>myLrnTrgtGrpMstSns.value.includes(y)) != '' || x.key == 'trivia'));
    // }else{
        return Object.values(sslRoutes).filter(x=>(x.lrnTrgtGrpMstSns.filter(y=>myLrnTrgtGrpMstSns.value.includes(y)) != '' || x.key == 'trivia'));
    // }
}

export const noneRewardCds = ['college', 'live', 'trivia']

export const sslLearnGroups = Object.values(sslRoutes).map((route) => route.group).flat()

export const divCdDcds = {
    DIV_CD_NORMAL: '2013001',
    DIV_CD_EVNET: '2013002',
}

export const getExpsrLocCd = (key) => {
    if(sslRoutes[key]){
        return sslRoutes[key].expsrLocCd;
    }
    return 0;
}

export const getDistCrseSns = (key) => {
    if(sslRoutes[key]){
        return sslRoutes[key].distCrseSns || [];
    }
    return [];
}

export const getDistCrseNm = (key) => {
    if(sslRoutes[key]){
        return sslRoutes[key].name;
    }
    return null;
}

export const getDistCrseCd = (distCrseSn) => {
    // console.log('getDistCrseCd('+distCrseSn+')')
    let key = '';
    if(['206129', '100011'].includes(distCrseSn)) {
        key = 'selfGa';
    }else if(['206130'].includes(distCrseSn)) {
        key = 'selfGi';
    }else if(['206128'].includes(distCrseSn)) {
        key = 'selfJa';
    }else if(['206131'].includes(distCrseSn)) {
        key = 'selfIct';
    // }else if([206128, 206129, 206130, 206131].includes(distCrseSn)) {
    //     key = 'self';
    }else if(['206132'].includes(distCrseSn)) {
        key = 'group';
    // }else if([202381].includes(distCrseSn)) {
    //     key = 'knowledge';
    // }else if(['202382'].includes(distCrseSn)) {
    //     key = 'hrd';
    // }else if([202384].includes(distCrseSn)) {
    //     key = 'college';
    // }else if([202385].includes(distCrseSn)) {
    //     key = 'live';
    }else if(['206500'].includes(distCrseSn)) {
        key = 'trivia';
    }else if(['206133'].includes(distCrseSn)) {
        key = 'book';
    }
    return key;
}


export const getMyLrnTrgtGrp = (distCrseSns) => {
    const filtered = store.state.ssl.myLearnTargets.filter(x => distCrseSns.includes(x.distCrseSn));
    if(filtered.length > 0){
        return filtered.map(x => ({...x, lrnTrgtGrpNm: getLrnTrgtGrpNm(sslLearnGroups, x.lrnTrgtGrpMstSn)}))[0];
    }
    return {
        lrnTrgtGrpMstSn: 0,
        distCrseSn: 0,
        lrnTrgtGrpNm: ''
    };
}

export const getLrnTrgtGrpNm = (group, lrnTrgtGrpMstSn) => {
    const target = group.find(x => x.lrnTrgtGrpMstSn === lrnTrgtGrpMstSn)
    if(target){
        return target.lrnTrgtGrpNm;
    }
    return '';
}

export const isMyLrnTrgtGrpMstSn = lrnTrgtGrpMstSn => {
    return store.state.ssl.myLearnTargets.some(x => x.lrnTrgtGrpMstSn === lrnTrgtGrpMstSn);
}

export const getRouteLrnTrgtGrpMstSn = lrnTrgtGrpMstSn => {
    return lrnTrgtGrpMstSn > 0 ? parseInt(lrnTrgtGrpMstSn) : 0;
}

export const isRightSSL = (distCrseCd, lrnTrgtGrpMstSn) => {
    return sslRoutes[distCrseCd] && sslRoutes[distCrseCd].group && sslRoutes[distCrseCd].group.some(x => x.lrnTrgtGrpMstSn === lrnTrgtGrpMstSn);
}

const noneLrnTrgtGrp = {lrnTrgtGrpMstSn: 0, lrnTrgtGrpNm: null};

export const getRouteLrnTrgtGrp = lrnTrgtGrpMstSn => {
    const found = sslLearnGroups.find(x => x.lrnTrgtGrpMstSn === getRouteLrnTrgtGrpMstSn(lrnTrgtGrpMstSn));
    return found || noneLrnTrgtGrp;
}

export const getLrnTrgtGrpMstSn = (distCrseSns) => {
    return getMyLrnTrgtGrp(distCrseSns).lrnTrgtGrpMstSn;
}

export const getLrnTrgtGrps = (distCrseCd) => {
    if(sslRoutes[distCrseCd]){
        return sslRoutes[distCrseCd].group;
    }
    return [];
}

const getSubscribe = (targetYn) => store.dispatch(`ssl/${ACT_GET_SOC_ACT_LIST}`, {
    actTyCdDcd: actTyCdDcds.ACT_TY_CD_SUBSCRIBE,
    targetYn
});

export const getAllSubscribes = () => {
    if(store.state.ssl.subscribe.expiredTime < new Date().getTime()){
        Promise.all([getSubscribe('N'), getSubscribe('Y')]).then(([res1, res2]) => {
            store.commit(`ssl/${MUT_SET_SUBSCRIBE}`, {
                my: getCheckItems(res1),
                target: getCheckItems(res2)
            });
        }).catch(() => {});
    }
};

export const getStatistics = () => {
    if(store.state.ssl.statistics.expiredTime < new Date().getTime()){
        store.dispatch(`ssl/${ACT_GET_SOC_ACT_STAT_LIST}`, {
            actTyCdDcds: [actTyCdDcds.ACT_TY_CD_LIKE, actTyCdDcds.ACT_TY_CD_ADD]
        }).then(res => {
            store.commit(`ssl/${MUT_SET_STATICS}`, getCheckItems(res));
        }).catch(() => {});
    }
};

// export const getProfileImg = (lrnerId) => {
//     if(!lrnerId) return;
//     return `https://lxpcms.kbstar.com/contents/emp_img/current/${lrnerId}.jpg` //`https://hrd2.kbstar.com/images/profile/1/${lrnerId}.jpg`;  // 프로필 정보
// }

export const getMateCount = () => {
    return Array.from(store.state.ssl.subscribe.my.filter(m =>
        store.state.ssl.subscribe.target.filter(t => t.lrnTrgtGrpMstSn === m.lrnTrgtGrpMstSn && m.trgtLrnerId === t.lrnerId).length > 0
    ).map(x => x.trgtLrnerId)).length;
}



export const getSubscribes = (items, lrnTrgtGrpMstSn) => {
    if (isMyLrnTrgtGrpMstSn(lrnTrgtGrpMstSn)) {
        const refined = items.map(item => {
            let follow = 0;
            // 내가 구독한 사용자
            if(store.state.ssl.subscribe.my.filter(x => x.lrnTrgtGrpMstSn === lrnTrgtGrpMstSn && x.trgtLrnerId === item.lrnerId).length > 0){
                follow++;
                // 나를 구독한 사용자
                if(store.state.ssl.subscribe.target.filter(x => x.lrnTrgtGrpMstSn === lrnTrgtGrpMstSn && x.lrnerId === item.lrnerId).length > 0){
                    follow++;
                }
            }
            return {
                ...item,
                subscribeSts: follow
            }
        });
        return refined.sort( (a, b) => {
            if (a.subscribeSts < b.subscribeSts) { return 1; }
            if (a.subscribeSts > b.subscribeSts) { return -1; }
            // a must be equal to b
            return 0;
        });
    }
    return items;
}

const insertSocActLog = (params, callback, errorCallback) => {
    store.dispatch(`ssl/${ACT_INSERT_SOC_ACT}`, params).then(res => {
        if(callback instanceof Function) callback();
        if(res.badges && res.badges.length > 0){
            store.commit(`ssl/${MUT_SHOW_BADGE}`, res.badges);
            getBadgeList();
        }

        if (res.renews && res.renews.length > 0) {
            res.renews.forEach(renew => {
                // 현재 재갱신 요소는 출석만 있음
                if (renew === 'attendance') {
                    initAttendCalendar();
                }
            });
        }
    }).catch(() => {
        if(errorCallback instanceof Function) errorCallback();
    });
}


export const insertSocAct = (actTyCdDcd, targetSn, key, callback) => {
    const params = {actTyCdDcd: actTyCdDcd}
    params[key ? key : 'socLrnPostSn'] = targetSn;
    insertSocActLog(params, callback);
}

export const insertSocActByParams = (params, callback, errorCallback) => {
    insertSocActLog(params, callback, errorCallback);
}

export const insertSocActPv = (actTyCdDcd, {lrnTrgtGrpMstSn, lrnTrgtGrpNm}) => {
    store.dispatch(`ssl/${ACT_INSERT_SOC_ACT}`, {actTyCdDcd: actTyCdDcd, lrnTrgtGrpMstSn, lrnTrgtGrpNm}).then(() => {}).catch(() => {});
}

// badge 트리거
export const actSubscribe = (actTyCdDcd, {trgtLrnerId, lrnTrgtGrpMstSn, lrnTrgtGrpNm}) => {
    store.dispatch(`ssl/${ACT_INSERT_SOC_ACT}`, {
        trgtLrnerId,
        lrnTrgtGrpMstSn,
        lrnTrgtGrpNm,
        actTyCdDcd
    }).then(res => {
        if(res.badges && res.badges.length > 0) {
            store.commit(`ssl/${MUT_SHOW_BADGE}`, res.badges);
        }
    }).catch(() => {});
}

export const nextMonth = (year, month) => {
    const currentDate = new Date(year, month + 1, 1);
    return (currentDate.getFullYear() + '-' + (currentDate.getMonth() < 9 ? `0${currentDate.getMonth() + 1}` : currentDate.getMonth() + 1));
}

export const goBack = () => {
    router.go(-1);
}


export const getCircleDeg = (count, finish) => {
    const correction = count/finish * 180;
    if(correction >= 90){
        if(correction > 180){
            return 90;
        }
        return correction - 90;
    }else{
        return 270 + correction
    }
}

// 주말이 아니고 공휴일이 아닌 경우...
export const checkDayOn = (currentDatePart) => {
    if(currentDatePart.dow !== '일' && currentDatePart.dow !== '토'){
        if(!holyDay[`${currentDatePart.year}${currentDatePart.mm}${currentDatePart.dd}`]){
            return true;
        }
    }
    return false;
}

// 오늘 출석 햇는지 체크
export const checkTodayNotAttend = (currentDatePart, items) => {
    return items.filter(x => x.datePart.year === currentDatePart.year && x.datePart.mm === currentDatePart.mm && x.datePart.dd === currentDatePart.dd).length === 0;

}

const getSocActAttendList = (callback) => {
    const date = new Date();
    const currentDatePart = dateToDatePart(date);
    store.dispatch(`ssl/${ACT_GET_SOC_ACTS_ATTEND_LIST}`, {
        regStartYmd: date.getFullYear() + '-01-01',
        regEndYmd: nextMonth(date.getFullYear(), date.getMonth()) + '-01',
    }).then(res => {
        const items = getCheckItems(res).map(x => ({...x, datePart: timestampToDatePart(x.regDt)}));
        store.commit(`ssl/${MUT_SET_MY_ATTENDANCE_LIST}`, items);
        if(callback instanceof Function) callback(currentDatePart, items);
    });
}

// initAttendCalendar
// export const initAttendCalendar = () => {
//     getSocActAttendList((currentDatePart, items) => {
//         if(checkDayOn(currentDatePart)){
//             if(checkTodayNotAttend(currentDatePart, items)){
//                 if (isSSLAttendance()) {
//                     store.commit(`ssl/${MUT_SHOW_ATTENDANCE}`);
//                 }
//             }
//         }
//     });
// }

export const checkAndShowCalendar = () => {
    if(!isSSLPeriod()) return;  //기간종료시 출석부 미노출
    const check = getToken(calendarKey);
    if(check && check === currentYmd) return;
    store.commit(`ssl/${MUT_SHOW_ATTENDANCE}`);
}

export const initAttendCalendar = () => {
    getSocActAttendList(checkAndShowCalendar);
}


export const showAttendance = () => {
    // 2022 정책 : 오늘 출석하지 않았으면 캘린더 노출
    // const date = new Date();
    // const currentDatePart = dateToDatePart(date);
    // if(store.state.ssl.calendar.myAttends.filter(x => x.datePart.ymd === currentDatePart.ymd).length === 0){
    //     getSocActAttendList(() => {
    //         store.commit(`ssl/${MUT_SHOW_ATTENDANCE}`);
    //     });
    // }else{
    //     store.commit(`ssl/${MUT_SHOW_ATTENDANCE}`);
    // }
    // 2023 => 더이상 보지 않기 정책 처리
    // console.log("chk attend")
    store.commit(`ssl/${MUT_SHOW_ATTENDANCE}`);
}

export const openPopUp = (fnUrl, popupOption) => {
    const popUpObj = popupOption ? window.open( fnUrl, popupOption) : window.open(fnUrl);
    if (popUpObj === null || popUpObj === undefined){
        alert(popupBlock);
    }
    return popUpObj;
}

export const kbEhrdSSO = (url) => {
    if(isInApp && (mobileUtils.iOS() || isInAppIOS)){
        const jobJson= {
            actionUrl :url,
            popupType : "notice",
            notiDeny :"hidden",
            popupDirection: "up",
            titlefontColor : "#2d2d2d",
            titleBGColor : "#FFFFFF",
            title:"닫기",
            notiDenyChkUrl :""
        }
        location.href='iOSNativeCall://LayerCall?'+JSON.stringify(jobJson);
    }else{
        openPopUp(url);
    }
}

export const ssoKB = (code, isLoading) => {
    if(isLoading) return;
    store.dispatch(`auth/${ACT_KB_SSO_AUTH}`, code).then(res => {
        if(res){
            if(isInApp && mobileUtils.iOS()){
                kbEhrdSSO(res);
            }else{
                openPopUp(res);
            }
        }else{
            isLoading = false;
        }

    }).catch(() => {
        isLoading = false;
    });
}

export const ssoEhrd = (paramKey, paramCode) => {
    store.dispatch(`auth/${ACT_KB_EHRD_SSO_AUTH}`, {
        key: paramKey,
        code: paramCode
    }).then(res => {
        if(res){
            kbEhrdSSO(res);
        }
    }).catch((e) => {
        console.error(e)
    });
}


export const getTrgtLrnerParams = (lrner) => {
    return {
        trgtLrnerId: lrner.lrnerId,
        trgtLrnerNm: lrner.lrnerNm,
        trgtDeptNm: lrner.deptNm,
        lrnTrgtGrpMstSn: lrner.lrnTrgtGrpMstSn,
        lrnTrgtGrpNm: lrner.lrnTrgtGrpNm,
    }
}

const getParamKey = (distCrseCd, relInfos) => {
    if(distCrseCd === 'knowledge'){
        return relInfos[1];
    }else if(distCrseCd === 'hrd') {
        return relInfos[3];
    }
    return 0;
}

const getRefUrl = (target, distCrseCd) => {
    if(distCrseCd === 'knowledge'){
        return target.materialUrl;
    }else if(distCrseCd === 'hrd') {
        return target.hrdUrl;
    }
    return null;
}

export const openRel = (openLoading, distCrseCd, relInfos, refUrl, currentRel) => {
    if(openLoading) return;
    openLoading = true;
    // 0부터 시작
    // knowledge => 1
    // hrd => 3

    if(distCrseCd === 'knowledge' || distCrseCd === 'hrd'){
        const params = {p_api_name: currentRel.p_api_name};
        params[currentRel.paramKey] = getParamKey(distCrseCd, relInfos);

        store.dispatch(`ssl/${ACT_GET_REL_LIST}`, params).then(res => {
            if(res && res[currentRel.key]){
                const url = getRefUrl(res[currentRel.key], distCrseCd);
                if(url) {
                    openPopUp(url);
                    // window.open(url);
                }
            }
            openLoading = false;
        }).catch(() => {
            openLoading = false;
        });
    }else{
        if(refUrl){
            openPopUp(refUrl);
            // window.open(refUrl);
        }
        openLoading = false;
    }
}

export const getTargetDt = (item) => {
    // 발행일 기준...
    if(item.stt === '00'){
        return item.pblcnDt;
    }else{
        return item.mdfcnDt ? item.mdfcnDt : item.regDt;
    }
}

// export const isSSLAttendance = () => {
//     // 출석 가능 기간
//     // 3월 28일 부터 7월 1일까지
//     // 8월 16일 부터 11월 4일까지
//     return (currentYmd > '20220327' && currentYmd < '20220702') || (currentYmd > '20220815' && currentYmd < '20221105');
//     // return (currentYmd > '20220327' && currentYmd < '20220702') || (currentYmd > '20220809' && currentYmd < '20221105');
// }

export const getMyLearnTargetList = async callback => {
    try {
        const res = await store.dispatch(`ssl/${ACT_GET_MY_LEARN_TARGET_LIST}`, {customCd: 'ssl'});
        await store.commit(`ssl/${MUT_SET_MY_LEARN_TARGET_LIST}`,getCheckItems(res));


        if (callback instanceof Function) callback();
    } catch (e) {
        console.error(e)
    }

}

// SSL 입과 처리
export const enrollSSL = (lrnTrgtGrpMstSn, callback) => {
    if (lrnTrgtGrpMstSn > 0) {

        store.dispatch(`ssl/${ACT_ENROLL_SSL}`, lrnTrgtGrpMstSn).then(res => {
            const result = res.result || 0;
            if (result > 0) getLearnTargetList(lrnTrgtGrpMstSn);
            if (callback instanceof Function) callback(result);
            if(res.badges && res.badges.length > 0){
                store.commit(`ssl/${MUT_SHOW_BADGE}`, res.badges);
                getBadgeList();
            }
        }).catch(() => {
            if (callback instanceof Function) callback(0);
        })
    } else {
        if (callback instanceof Function) callback(0);
    }
}

export const goHome = () => {
    router.push({name: 'SSLHome'}).then(() => {});
}

// SSL_Main 과 SSL_MY 에서 사용되는 validation function
export const checkSSLAccess = (isMyLrnTrgt, distCrseCd, lrnTrgtGrpMstSn, myGroup, callback) => {
    // const nowDate = dateToDateFormat(new Date(), 'yyyyMMddhhmm');
    const {showLoading, hideLoading} = useAlert();
    // isAdmin();

    // 올바른 ssl 코드인지 체크
    if (isRightSSL(distCrseCd.value, lrnTrgtGrpMstSn.value)) {
        if (isMyLrnTrgt.value) {
            let isReturn = false;

            if (distCrseCd.value === 'college' && !isMyLrnTrgtGrpMstSn(lrnTrgtGrpMstSn.value)) {    //직무college 학습자만 통과
                isReturn = true;
                showMessage({title: '4월 중 오픈 예정'});
            }
            // else if (distCrseCd.value === 'book'){
            //     if (enableExperiencePeriod[distCrseCd.value] > nowDate && !store.state.auth.session.isGeneralAdmin) {
            //         isReturn = true;
            //         showMessage({
            //             title: '북러닝',
            //             text: '도서 신청 완료 후 7.1(월) 17시 오픈 예정'
            //         });
            //     }
            // }

            if (isReturn) {
                router.go(-1);
                // router.push({name: 'SSLHome'}).then(() => {});
            }else {
                if (callback instanceof Function) callback(true);
            }

        } else {
            // if (enableExperiencePeriod[distCrseCd.value] > nowDate) {
            //     if (distCrseCd.value === 'book') {
            //         showMessage({
            //             title: '북러닝',
            //             text: '도서 신청 완료 후 7.1(월) 17시 오픈 예정'
            //         });
            //     }
            //     router.go(-1);
            // }else {
                if (distCrseCd.value === 'group') {
                    // SSL 그룹러닝에는 입과되어 있지않지만 그룹이 있는 경우
                    if (myGroup.value.distCrseSn > 0) {
                        showLoading();
                        // 강제 입과를 시키고 처리한다.
                        enrollSSL(lrnTrgtGrpMstSn.value, result => {
                            if (result > 0) {
                                getMyLearnTargetList(() => {
                                    hideLoading();
                                    if (callback instanceof Function) callback(true);
                                    // isReady.value = true;
                                }).then(() => {});
                            } else {
                                hideLoading();
                                callback(false);
                            }
                        });
                    } else {
                        callback(false);
                    }
                }else if (noneRewardCds.includes(distCrseCd.value)) {
                    callback(true);
                } else {
                    // 나머지 과정은 홈으로 보낸다.
                    callback(false);
                }
            // }
        }
    } else {
        callback(true);
        //router.push({name: 'SSLHome'}).then(() => {}); // 미입과 과정도 조회가능
    }



}

const {showMessage, showConfirm, showLoading, hideLoading} = useAlert();

const enrollLearn = (distCrseCd, distCrseNm, lrnTrgtGrpMstSn) => {
    let confirmText = distCrseNm.value;
    if (sslRoutes[distCrseCd.value].group.length > 1) {
        const found = sslRoutes[distCrseCd.value].group.find(x => x.lrnTrgtGrpMstSn === lrnTrgtGrpMstSn.value);
        if (found) confirmText += `(${found.lrnTrgtGrpNm})`;
    }
    // confirmText += ' 과정을 신청하시겠습니까?'
    confirmText = '환영합니다! SSL에 참여하시겠습니까?<br>(미수료 불이익 없음)';

    showConfirm({
        title: distCrseNm.value,
        text : confirmText,
        callback: () => {
            if (distCrseCd.value === 'group') {
                router.push({name: 'GroupLearning'});
            } else {
                showLoading();
                enrollSSL(lrnTrgtGrpMstSn.value, result => {
                    if (result > 0) {
                        if (result === 3) {
                            getMyLearnTargetList(() => {
                                hideLoading();
                                showMessage('다른 분야에 입과된 이력이 존재합니다.');
                            }).then(() => {});
                        } else {
                            getMyLearnTargetList(() => {
                                hideLoading();
                                showMessage({
                                    title: '입과완료',
                                    text: '입과 완료되었습니다.'
                                });
                            }).then(() => {});
                        }
                    } else {
                        hideLoading();
                        showMessage('서버처리 중 일시적인 장애가 발생하였습니다.<br>잠시 후 다시 시도 해 주시기 바랍니다.');
                    }
                });
            }
        }
    });
}

export const enrollSSLAlert = (distCrseCd, distCrseNm, lrnTrgtGrpMstSn) => {
    console.log(distCrseCd.value);
    console.log(distCrseNm.value);
    console.log(lrnTrgtGrpMstSn.value);
    if (isRightSSL(distCrseCd.value, lrnTrgtGrpMstSn.value)) {
        if (distCrseCd.value === 'book') {
            // 체험 모드
            showMessage({
                title: distCrseNm.value,
                text: '북러닝 과정의 신청기간이 종료되었습니다.<br>다음 신청기간을 이용해주시기 바랍니다.' // <br>(하반기 신청기간: 8월 中 별도 안내 예정)
            });
        } else {
            // isLoading = true;
            enrollLearn(distCrseCd, distCrseNm, lrnTrgtGrpMstSn);
        }
    }
}

export const getTargetDistCrseSn = distCrseCd => {
    if (distCrseCd === 'group') {
        return store.state.ssl.group.distCrseSn;
    } else if (distCrseCd === 'knowledge') {
        return store.state.ssl.knowledge.distCrseSn;
    } else if (distCrseCd === 'hrd') {
        return store.state.ssl.hrd.distCrseSn;
    }
    // 임시 하드코딩 => 추후 정책 듣고 처리
    else if (distCrseCd === 'live') {
        return sslRoutes[distCrseCd].group[0].lrnTrgtGrpMstSn;
    }
    return 0;
}

export const getTimeText = (beginDt, endDt) => {
    const begin = timestampToDatePart(beginDt);
    const end = timestampToDatePart(endDt);
    return (begin.ymd === end.ymd) ? `${begin.year}.${begin.mm}.${begin.dd} ${begin.hour}:${begin.min}~${end.hour}:${end.min}` : `${timestampToDateFormat(beginDt, 'yyyy.MM.dd hh:mm')}~${timestampToDateFormat(endDt, 'yyyy.MM.dd hh:mm')}`;
}

export const getCategoryClass = distCrseCd => {
    if(distCrseCd === 'self'){
        return 'category-self'
    }else if(distCrseCd === 'group'){
        return 'category-group'
    }else if(distCrseCd === 'knowledge'){
        return 'category-jisike';
    }else if(distCrseCd === 'hrd'){
        return 'category-cloud'
    }else if(distCrseCd === 'book'){
        return 'category-book'
    }else if(distCrseCd === 'trivia'){
        return 'category-trivia'
    }else if(distCrseCd === 'college'){
        return 'category-college'
    }else if(distCrseCd === 'live'){
        return 'category-review'
    }
    return '';
}

export const noticeExposureLocationCodes = [expsrLocCds.EXPSR_LOC_CD_SSL_COMMON,expsrLocCds.EXPSR_LOC_CD_SSL_MAIN].join(',');

export const getNecessaries = () => {
    store.commit(`ssl/${MUT_INIT_MY_REL}`);

    Promise
        .all([
            store.dispatch(`ssl/${ACT_GET_MY_LEARN_TARGET_LIST}`,{customCd: 'ssl'}),
            store.dispatch(`ssl/${ACT_GET_SOC_MY_REL}`, 'group'),
        ])
        .then(([res1, res2]) => { //, res3, res4
            let items = getCheckItems(res1);
            let noGroup = true;

            // 그룹러닝을 위한 해당 년도 그룹 조회
            if (lengthCheck(res2)) {
                noGroup = false;
                store.commit(`ssl/${MUT_SET_MY_REL}`, {division: 'group', items: getItem(res2)});
            }

            if (noGroup) {
                // 그룹러닝 입과정보를 제거한다.
                items = items.filter(x => !sslRoutes['group'].lrnTrgtGrpMstSns.includes(x.lrnTrgtGrpMstSn));
            }

            store.commit(`ssl/${MUT_SET_MY_LEARN_TARGET_LIST}`,items);

        });
};