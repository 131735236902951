<template>
  <div v-if="lrnTrgtGrps.length > 1 && !calendar.show" class="kb-nav-round-tabs mb-0" data-tabs="sticky" data-visible="true">
    <ul class="kb-nav-list hide-scroll-bar">
      <li v-for="(lrnTrgtGrp, index) in lrnTrgtGrps" class="kb-nav-item" :key="index">
        <router-link :to="{name: 'SSLMain', params: {lrnTrgtGrpMstSn : lrnTrgtGrp.lrnTrgtGrpMstSn}}" class="kb-nav-link" :class="{'is-active': lrnTrgtGrp.lrnTrgtGrpMstSn === lrnTrgtGrpMstSn}">
          <span class="kb-nav-text">{{ lrnTrgtGrp.lrnTrgtGrpNm }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import {computed} from 'vue';
import {getRouteLrnTrgtGrpMstSn, sslRoutes} from '@/assets/js/modules/ssl/ssl-common';
import {useRoute} from 'vue-router';
import {useStore} from 'vuex';

export default {
  name: 'SSLMobileSubNav',
  props: {
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const calendar = computed(() => store.state.ssl.calendar);

    const lrnTrgtGrpMstSn = computed(() => getRouteLrnTrgtGrpMstSn(route.params.lrnTrgtGrpMstSn));
    const lrnTrgtGrps = computed(() => lrnTrgtGrpMstSn.value > 0 && sslRoutes[route.params.distCrseCd] ? sslRoutes[route.params.distCrseCd].group : []);




    return {
      calendar,
      lrnTrgtGrpMstSn,
      lrnTrgtGrps,
    }
  }
}
</script>
