<template>

  <main id="ssl-home">
    <header class="kb-header" id="kb-header">
      <div class="header-component">
        <div  class="header-column header-title">
<!--          <slot name="title">Self & Social Learning (SSL) 신청</slot>-->
          <slot name="title">북러닝(Book Learning) 신청</slot>
        </div>

        <div class="header-column header-util left">

              <router-link  to="/main" class="util-actions util-actions-back">
                <i class="icon-history-back"></i>
              </router-link>

        </div>

      </div>
    </header>

    <div class="segment-box" style="margin-top:1.5em;">
      <p class="text" style="text-align:center; font-weight:bold;">선착순 신청이 마감되었습니다. 감사합니다.</p>
      <p class="text" style="text-align:center; font-weight:bold;">신청자 앞 도서신청 및 연수실시 관련</p>
      <p class="text" style="text-align:center; font-weight:bold;">별도 안내 예정입니다.</p>
      <p class="text" style="text-align:center; font-weight:bold;">[문의] 권현진(☎ 5357), 김보희(☎ 5473)</p>
<!--      <p class="text" style="text-align:center; font-weight:bold;">신청 기간: <span class="text" style="text-align:center; font-weight:bold; color:red">6.24.(월) 17:00  ~ 6.26.(수)</span></p>-->
<!--      <p class="text" style="text-align:center; font-weight:bold;"><span class="text" style="text-align:center; font-weight:bold; color:red">선착순 2,700명</span></p>-->
<!--      <p class="text" style="text-align:center; font-weight:bold;">신청기간 종료 후 도서신청 진행 및 </p>-->
<!--      <p class="text" style="text-align:center; font-weight:bold;"><span class="text" style="text-align:center; font-weight:bold; color:red">7.1.(월) 17시</span> 연수 실시 예정입니다.</p>-->
    </div>

    <div class="page-buttons" style="margin-top:10px;">
<!--      <router-link to="/prep/hrdcloud/270223/detail" class=" kb-btn kb-btn-secondary kb-btn-sm rounded-lg"><span class="aplytext">소개영상</span><i class="icon-arrow-next"></i></router-link>-->
<!--      <button class=" kb-btn kb-btn-secondary rounded-lg" style="padding:0" @click="downloadFile('SSL_2023_Guide')"><span class="aplytext">학습가이드 보기</span><i class="icon-arrow-next"></i></button>-->
      <button class=" kb-btn kb-btn-secondary rounded-lg" style="padding:0" @click="downloadFile('Book_2024_Paper')"><span class="aplytext">시행문 보기</span><i class="icon-arrow-next"></i></button>
    </div>
    <div class="section-content" v-if="1==2">
      <ApplyGoal :apply-cnt="totAppNonDupCnt"/>
    </div>


    <article  class="home-section section-learning" style="padding-top:20px;justify-content: space-evenly;">
<!--      <div class="section-header learning-image">-->

<!--        <img src="@/assets/lxp/images/ssl/home/book_apply_banner.jpg" style="width:300px">-->
<!--      </div>-->
      <div class="learning-box" v-for="(moveRoute, index) in sslMoveLink" :style="moveRoute.dp" :key="index" style="width:340px;margin-left: 0">
        <!--      <router-link :to="{name: 'SSLMain', params: {distCrseCd: moveRoute.key, lrnTrgtGrpMstSn: moveRoute.lrnTrgtGrpMstSn}}" class="learning-link"></router-link>-->
<!--        <a href="javascript:" class="learning-link" @click="goLearn(moveRoute)"></a>-->
        <div class="learning-image">
          <img v-if="moveRoute.key === 'selfGa'" src="@/assets/lxp/mobile/images/ssl/home/img_quick_self.jpg" alt="">
          <img v-else-if="moveRoute.key === 'selfGi'" src="@/assets/lxp/mobile/images/ssl/home/img_quick_e.jpg" alt="">
          <img v-else-if="moveRoute.key === 'selfJa'" src="@/assets/lxp/mobile/images/ssl/home/img_quick_hrd.jpg" alt="">
          <img v-else-if="moveRoute.key === 'selfIct'" src="@/assets/lxp/mobile/images/ssl/home/img_quick_review.jpg" alt="">
          <img v-else-if="moveRoute.key === 'group'" src="@/assets/lxp/mobile/images/ssl/home/img_quick_group.jpg" alt="">
<!--          <img v-else-if="moveRoute.key === 'knowledge'" src="@/assets/lxp/mobile/images/ssl/home/img_quick_e.jpg" alt="">-->
<!--          <img v-else-if="moveRoute.key === 'hrd'" src="@/assets/lxp/mobile/images/ssl/home/img_quick_hrd.jpg" alt="">-->
          <img v-else-if="moveRoute.key === 'book'" src="@/assets/lxp/mobile/images/ssl/home/img_quick_book.jpg" alt="">
<!--          <img v-else-if="moveRoute.key === 'trivia'" src="@/assets/lxp/mobile/images/ssl/home/img_quick_alssl.jpg" alt="">-->
<!--          <img v-else-if="moveRoute.key === 'live'" src="@/assets/lxp/mobile/images/ssl/home/img_quick_review.jpg" alt="">-->
<!--          <img v-else-if="moveRoute.key === 'college'" src="@/assets/lxp/mobile/images/ssl/home/img_quick_college.jpg" alt="">-->
        </div>
        <div class="learning-contents" style="top:-10px">
          <span class="title"  v-html="moveRoute.name"></span>
          <p class="contents-text" style="font-size:14px" v-if="moveRoute.key === 'selfGa'">
            가계여신</p>
          <p class="contents-text" style="font-size:14px" v-if="moveRoute.key === 'selfGi'">
            기업여신/외환</p>
          <p class="contents-text" style="font-size:14px" v-if="moveRoute.key === 'selfJa'">
            자산관리/퇴직연금</p>
          <p class="contents-text" style="font-size:14px" v-if="moveRoute.key === 'selfIct'">
            ICT</p>
          <p class="contents-text" v-else-if="moveRoute.key === 'group'">
            - 가계여신<br>
            - 기업여신/외환<br>
            - 자산관리/퇴직연금<br>
            - ICT<br>
            - 자격증취득
          </p>
<!--          <p class="contents-text" v-else-if="moveRoute.key === 'knowledge'" if="moveRoute.key === 'self'">-->
<!--            알찬 내용을 담은 학습자료를 공부하고<br>-->
<!--            진단평가를 통해 점검해요-->
<!--          </p>-->
<!--          <p class="contents-text"  v-else-if="moveRoute.key === 'hrd'" if="moveRoute.key === 'self'">-->
<!--            HRD클라우드의 다양한 콘텐츠를 <br>-->
<!--            학습 후 학습노트를 작성해요-->
<!--          </p>-->
          <p class="contents-text" style="font-size:14px"  v-else-if="moveRoute.key === 'book'">
            선착순 2,700명
<!--            희망도서를 읽고 서로 독서내용을 공유해요-->
          </p>
        </div>
        <div class="onCount">
          <span class="countTxt" v-if="moveRoute.key === 'selfGa'">{{ numberComma(selfAppGaCnt) }}명</span>
          <span class="countTxt" v-if="moveRoute.key === 'selfGi'">{{ numberComma(selfAppGiCnt) }}명</span>
          <span class="countTxt" v-if="moveRoute.key === 'selfJa'">{{ numberComma(selfAppJaCnt) }}명</span>
          <span class="countTxt" v-if="moveRoute.key === 'selfIct'">{{ numberComma(selfAppIctCnt) }}명</span>
          <span class="countTxt" v-else-if="moveRoute.key === 'group'">{{ numberComma(groupCnt) }}명</span>
<!--          <span class="countTxt" v-else-if="moveRoute.key === 'knowledge'">{{ numberComma(jisikeCnt) }}명</span>-->
<!--          <span class="countTxt" v-else-if="moveRoute.key === 'hrd'">{{ numberComma(cloudCnt) }}명</span>-->
          <span class="countTxt" v-else-if="moveRoute.key === 'book'">{{ numberComma(bookCnt)}}명</span>
<!--          <span class="countTxt" v-else-if="moveRoute.key === 'trivia'">신청불필요</span>-->
        </div>
        <div>
<!--          <div v-if="moveRoute.lrnTrgtGrpMstSn > 0 && moveRoute.key === 'self'" class="on"><span class="contents-text"><i class="icon-read"></i>신청완료({{moveRoute.lrnTrgtGrpNm}})</span></div>-->
          <div v-if="moveRoute.lrnTrgtGrpMstSn > 0" class="on"><span class="contents-text"><i class="icon-read"></i>신청완료</span></div>
          <div v-if="moveRoute.key === 'trivia'" class="on2"> <span class="contents-text">SSL 참여직원 모두를 위한 코너</span> </div>

          <div v-if="moveRoute.lrnTrgtGrpMstSn > 0 && moveRoute.key !== 'trivia' && 1==2" class="onBtn">
<!--            <button  v-if="moveRoute.key !== 'book'"  class="kb-btn-secondary kb-btn-xs rounded-lg kb-btn-ssl-aply" >-->
<!--              <span class="aplytext">신청 완료</span>-->
<!--            </button>-->
            <button  class="kb-btn-secondary kb-btn-xs rounded-lg kb-btn-ssl-aply"  >
              <span class="aplytext"  >취 소</span>
            </button>
<!--            <button  class="kb-btn-secondary kb-btn-xs rounded-lg kb-btn-ssl-aply"  >-->
<!--              <span class="aplytext"  >마 감</span>-->
<!--            </button>-->
          </div>

          <div v-else-if="moveRoute.key !== 'trivia' && moveRoute.lrnTrgtGrpMstSn === 0 && bookCnt < bookLimitCnt" class="onBtn">
<!--            <button v-if=" moveRoute.key !== 'book'" class="kb-btn-secondary kb-btn-xs rounded-lg kb-btn-ssl-aply" >-->
<!--              <span class="aplytext" >마 감</span>-->
<!--            </button>-->
            <button  class="kb-btn-secondary kb-btn-xs rounded-lg kb-btn-ssl-aply"  >
              <span class="aplytext" >신 청</span>
            </button>
<!--            <button  class="kb-btn-secondary kb-btn-xs rounded-lg kb-btn-ssl-aply"  style="">-->
<!--              <span class="aplytext" >마 감</span>-->
<!--            </button>-->
          </div>
          <div v-else-if="moveRoute.key === 'book' " class="onBtn"> <!-- && bookCnt >= bookLimitCnt -->
                <button  class="kb-btn-secondary kb-btn-xs rounded-lg kb-btn-ssl-aply">
                  <span class="aplytext" >마 감</span>
                </button>
          </div>

        </div>
      </div>
    </article>

    <SSLEnroll
        v-if="enroll.toggle"
        v-model:toggle="enroll.toggle"
        :stt="enroll.stt"
        :dist-crse-cd="enroll.key"
    />
    <LxpMobileFooter :items="notices"/>

  </main>


</template>

<script>

import SSLEnroll from '@/components/ssl/apply/SSLEnroll';
import ApplyGoal from '@/components/ssl/apply/ApplyGoal';
import {sslApplySetup} from "@/assets/js/modules/ssl/setup/ssl-apply-setup";
import LxpMobileFooter from '@/views/layout/lxp/footer/mobile/LxpMobileFooter';

export default {
  name: 'SSLMobileApply',
  components: {
    SSLEnroll,
    ApplyGoal,
    LxpMobileFooter


  },
  setup: sslApplySetup
  // setup() {
  //   getAllSubscribes();
  //   return {
  //     // getProfileImg,
  //     sslRoutes,
  //
  //   }
  // }
}
</script>

<style scoped>

.contents-text{
  color:#444444;
  line-height:20px;
  font-size: 10px;
  padding:15px 10px;
  font-weight: 600;

}
.kb-btn-ssl-aply{
  background-color: var(--kb-light-silver);
  border-color: var(--kb-light-silver);
}
.aplytext{
  align-items: center;
  justify-content: right;
  padding: 8px 8px;
  font: normal 0.9rem/1.25 var(--kb-font-KBFGTextB);
  font-weight:bold;
  color:#545045;

}
.icon-read {width:20px;height:20px; margin-bottom:10px;  background:url(../../../assets/lxp/images/ssl/main/ic_check.svg)}

.on {  position: absolute; top: 25px; left:10px; font: normal 0.8rem/1.25 var(--kb-font-KBFGTextB);color:#444444;font-weight: 600; text-align: center}
.on2 {  position: absolute;  width:100%; top: 130px; font: normal 0.8rem/1.25 var(--kb-font-KBFGTextB);color:#444444;font-weight: 600; text-align: center}
.onCount { position: absolute; top: 20px; right:10px;}
.countTxt {display: inline-flex;
  align-items: center;
  justify-content: right;
  background-color: rgba(247, 244, 240, 1);
  letter-spacing: 1px;
  height: 20px;
  width:85px;
  padding: 7px 13px;
  border-radius : 50px ;
  transition: var(--btn-transition);
  font: normal 0.7rem/1.25 var(--kb-font-KBFGTextB);
  font-weight:bold;
  color:#545045;
  vertical-align: middle;
  border: 1px solid transparent;
  transition: var(--kb-btn-transition);}
.onBtn{ position: absolute; top: 120px; isplay:inline-block;width: 100%; text-align: center;}
.uBtn{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 7px 35px;
  border-radius: var(--kb-border-radius-pill);
  transition: var(--btn-transition);
  font: normal 0.9rem/1.25 var(--kb-font-KBFGTextB);
  vertical-align: middle;
  border: 1px solid transparent;
  transition: var(--kb-btn-transition);
}
/*.icon-download {margin-left:4px;width:20px;height:20px;background-image:url(../../assets/lxp/images/common/ic_download.svg)}*/
</style>
