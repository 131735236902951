<template>
  <section class="popup-section">
    <header class="section-header"><h4 class="title">구독 알림설정</h4></header>
    <div class="kb-form-fields">
      <SwitchCheckbox
          :is-checked="lrnerNotiMng.hrdSubscbNotiYn === 'Y'"
          title="팝업알림"
          @saved="savePopup"
      />
      <SwitchCheckbox
          :is-checked="lrnerNotiMng.liivNotiYn === 'Y'"
          title="리브똑똑알림"
          @saved="saveLiiv"
      />
    </div>
    <div class="kb-form-fields-bottom mt-3">
      <p class="text-notice text-muted">ON으로 설정 시 구독중인 채널에 신규 콘텐츠가 업데이트되면 알림 수신이 가능합니다.</p>
    </div>
  </section>
</template>
<script>
import SwitchCheckbox from "@/components/history/module/SwitchCheckbox.vue";
import {hrdSubscribeSettingsSetup} from "@/assets/js/modules/hrd/hrd-subscribe-settings-setup";

export default {
  name: "HrdSubscribeSettings",
  components: {SwitchCheckbox},
  setup: hrdSubscribeSettingsSetup
}
</script>