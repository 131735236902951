import store from '../../../../store';
import router from '../../../../router';
import {useAlert} from '@/assets/js/modules/common/alert';
import {
  ACT_CHECK_GROUP_LEADER,
  ACT_GET_LEARN_PROGRESS,
  ACT_GET_LEARN_PROGRESS_LOG_LIST,
  ACT_INSERT_LEARN_PROGRESS,
} from '@/store/modules/learn/learn';
import {
  getCheckItems,
  getItems,
  getItem,
  getVideoUrl,
  initParams,
  isSuccess,
  lengthCheck,
  secondToFormat,
  timestampToDateFormat, paramCheck, setParams,
} from '@/assets/js/util';
import {
  hrdCancelLike,
  hrdCancelPut,
  hrdLike,
  hrdLikeTitle,
  hrdPut,
  hrdPutGoToMyPage,
  hrdPutGoToMyPageBtnTitle,
  hrdPutTitle,
  hrdSubscribe,
  hrdSubscribeComplete,
  hrdSubscribeGoToMyPageBtnTitle,
  hrdSubscribeTitle,
  viewCommentDelete,
  viewCommentEditConfirm,
  viewCommentUpdate,
} from '@/assets/js/modules/hrd/hrd-message';
import {
  ACT_DELETE_CTT_CMNT,
  ACT_DOWNLOAD_ATCH_FILE,
  ACT_INSERT_CTT_CMNT,
  ACT_UPDATE_CTT_CMNT,
  ACT_GET_STT_TEXT,
} from '@/store/modules/contents/contents';
import {insertCourseAct} from '@/assets/js/modules/course/course-common';
import {actTyCdDcds, goBackFunc} from '@/assets/js/modules/common/common';
import {
  ACT_GET_HRDCLOUD_CATE_LIST,
  ACT_GET_HRDCLOUD_MAIN_CATE_LIST,
  ACT_GET_HRDCLOUD_UV_LIST,
  MUT_CLOSE_ADDED,
  MUT_CLOSE_HRD_LEADER_ADD,
  MUT_CLOSE_MAIN,
  MUT_CLOSE_SUBSCRIBED,
  MUT_DISABLE_MY_ADD_CHANGED,
  MUT_ENABLE_MY_ADD_CHANGED,
  MUT_SET_HRDCLOUD_CATE_LIST,
  MUT_SHOW_ADDED,
  MUT_SHOW_HRD_BADGE,
  MUT_SHOW_HRD_LEADER_ADD,
  MUT_SHOW_MAIN,
  MUT_SHOW_SUBSCRIBED,
  MUT_TOGGLE_CONCENTRATION_MODE,
  MUT_SHOW_SHARE,
  MUT_CLOSE_SHARE, MUT_SET_YEAR_SHARE,
} from '@/store/modules/hrdcloud/hrdcloud';
import {
  ACT_GET_UTIL_UUID,
  ACT_INSERT_FILE_ATCH,
  ACT_INSERT_QA,
  MUT_DISABLE_NOSLEEP,
  MUT_ENABLE_NOSLEEP,
} from '@/store/modules/common/common-index';
import {
  ACT_GET_CHECK_HRDCLOUD_OBJECT,
  ACT_GET_HRDCLOUD_LATEST_LIST,
  ACT_GET_HRDCLOUD_LIST,
  ACT_GET_HRDCLOUD_RECENT_LIST,
  ACT_GET_HRDCLOUD_RECENT_POPULAR_LIST,
  ACT_GET_HRDCLOUD_RECOMMEND_LIST,
} from '@/store/modules/prep/prep';
import {
  deleteCrseActLrnerSumup,
} from '@/assets/js/modules/hrd/hrd-history-common';
import {ACT_INSERT_PEARL_FILE_ATCH} from '@/store/modules/trnct/trnct';
import mobileUtils from '@/assets/js/navigationUtils';
import navigationUtils, {
  isInAppAnd,
  isInAppIOS,
} from '@/assets/js/navigationUtils';
import {getThumbImg} from '@/assets/js/modules/baner/common-baner';
import {TOKEN_KEY} from '@/store/modules/auth/auth';
import {
  ACT_GET_COLDCALL_TIME,
  ACT_INSERT_COLDCALL,
} from '@/store/modules/common/coldcall';
import {getHrdBadgeList} from '@/assets/js/modules/hrd/hrd-badge';
import {sslRoutes} from '@/assets/js/modules/ssl/ssl-common';
import {ACT_GET_MY_LEARN_TARGET_LIST} from '@/store/modules/ssl/ssl-index';

const {showMessage, showConfirm, showConfirmCustomBtn, hideLoading, showLoading} = useAlert();

export const eventCdDcds = {
  Play : '2064001',
  Timeupdate: '2064002',
  Ended : '2064003',
  Coldcall : '2064004',
  ColdcallStop : '2064005',
  AdminColdcall : '2064006',
  Entrance : '2064007',
  Pause : '2064008',
};

export const sortTyCdDcds = {
  SORT_TY_CD_LATEST: '2073001',
  SORT_TY_CD_POPULAR: '2073002',
};

export const servTyCdDcds = {
  SERV_TY_CD_LMS: '2014001',
  SERV_TY_CD_CLOUD: '2014002',
  SERV_TY_CD_EASY: '2014003',
  SERV_TY_CD_KBTUBE: '2014004',
  SERV_TY_CD_WITH: '2014005'
};

export const cttTyCdDcds = {
  CTT_TY_CD_VOD         : '2034001',
  CTT_TY_CD_YOUTUBE     : '2034002',
  CTT_TY_CD_MP3         : '2034003',
  CTT_TY_CD_HTML        : '2034004',
  CTT_TY_CD_PDF         : '2034005',
  CTT_TY_CD_EDUTOR      : '2034006',
  CTT_TY_CD_ATTACHMENTS : '2034007',
  CTT_TY_CD_IMAGE       : '2034008',
  CTT_TY_CD_ZIP         : '2034009',
};

export const mlgSaveCdDcds = {
  MLG_SAVE_CD_HRDCLOUD : '2108001',
  MLG_SAVE_CD_KBTUBE : '2108002',
  MLG_SAVE_CD_CLOUDEASY : '2108003',
};

export const mainCustomList = [
  { name: 'recommend', title: '지금 나에게 필요한 콘텐츠', params: {category1:1}, isLoading: true, items:[], url: `prep/${ACT_GET_HRDCLOUD_RECOMMEND_LIST}` },
  { name: 'latest', title: '따끈따끈 신규 콘텐츠',params: {category1:2}, isLoading: true, items:[], url: `prep/${ACT_GET_HRDCLOUD_LATEST_LIST}`},
  { name: 'latestPopularDuty', title: '최근 직무 분야 인기 콘텐츠', params: {category1:1}, isLoading: true, items:[], url: `prep/${ACT_GET_HRDCLOUD_RECENT_POPULAR_LIST}` },
  { name: 'latestPopularKnowledge', title: '최근 지식 분야 인기 콘텐츠', params: {category1:2}, isLoading: true, items:[], url: `prep/${ACT_GET_HRDCLOUD_RECENT_POPULAR_LIST}` },
  { name: 'recent', title: '나의 최근 학습 콘텐츠', params: {}, isLoading: true, items:[], url:  `prep/${ACT_GET_HRDCLOUD_RECENT_LIST}` }
];

export const cttTableNm = 'CMS_CTT_MST';

export const validateComment = (payload) => {
  if(!payload.cmnt){
    showMessage('댓글 내용을 입력해주세요.');
    return false;
  }
  return true;
};

const insertComment = (isLoading, objSn, params, callback) => {
  if (isLoading) return;
  isLoading = true;
  store.dispatch(`contents/${ACT_INSERT_CTT_CMNT}`, {cttMstSn: objSn, ...params}).then(res => {
    if (isSuccess(res) && res.cttCmntSn > 0) {
      if(callback instanceof Function) callback(res.cttCmntSn);
    }
    if (res.badges && res.badges.length > 0) {
      store.commit(`hrdcloud/${MUT_SHOW_HRD_BADGE}`,res.badges);
      getHrdBadgeList();
    }
    isLoading = false;
  }).catch(e => {
    console.error(e);
    isLoading = false;
  });
};

const updateComment = (isLoading, comment) => {
  if (isLoading) return;
  isLoading = true;
  store.dispatch(`contents/${ACT_UPDATE_CTT_CMNT}`, {cttCmntSn: comment.cttCmntSn, params: {cmnt: comment.cmnt}}).then(res => {
    if (isSuccess(res) && res.cttCmntSn > 0) {
      comment.editMode = false;
      showMessage(viewCommentUpdate);
    }
    isLoading = false;
  }).catch(e => {
    console.error(e);
    isLoading = false;
  });
};

const deleteComment = (isLoading, comments, idx) => {
  if(isLoading) return;
  isLoading = true;
  store.dispatch(`contents/${ACT_DELETE_CTT_CMNT}`, comments[idx].cttCmntSn).then(res => {
    if(isSuccess(res)){
      showMessage(viewCommentDelete);
      comments.splice(idx, 1);
    }
    isLoading = false;
  }).catch(() => {
    isLoading = false;
  });
};

export const confirmInsertComment = (isLoading, objSn, payload, callback) => {
  if (validateComment(payload)) {
    showConfirm(viewCommentEditConfirm('등록'),() => {
      insertComment(isLoading, objSn, payload, callback);
    });
  }
};

export const confirmUpdateComment = (isLoading, comment) => {
  if (validateComment(comment)) {
    showConfirm(viewCommentEditConfirm('수정'), () => {
      updateComment(isLoading, comment);
    });
  }
};

export const confirmDeleteComment = (isLoading, comments, idx) => {
  showConfirm(viewCommentEditConfirm('삭제'), () => {
    deleteComment(isLoading, comments, idx);
  });
};

export const downloadAtch = (atch) => {
  store.dispatch(`contents/${ACT_DOWNLOAD_ATCH_FILE}`, {atchFileSn: atch.atchFileSn, fileName: atch.orgFileNm});
};

export const insertVodLearnProgress = (learn, params, callback) => {
  console.log('[LOGGED] ',params.eventNm);
  store.dispatch(`learn/${ACT_INSERT_LEARN_PROGRESS}`, {
    crseMstSn: learn.crseMstSn,
    distCrseSn: learn.distCrseSn,
    distCrseNm: learn.distCrseNm,
    distChapSn: learn.distChapSn,
    distChapNm: learn.distChapSn,
    lrnObjDtlDistSn: learn.lrnObjDtlDistSn,
    objSn: learn.objSn,
    objNm: learn.objNm,
    division: 'vod',
    cloudKeyLst: learn.cloudKeyLst,
    ...params
  }).then(res => {
    if(callback instanceof Function) callback(res);
    if (res.badges && res.badges.length > 0) {
      store.commit(`hrdcloud/${MUT_SHOW_HRD_BADGE}`,res.badges);
      getHrdBadgeList();
    }
  }).catch(e => {
    console.error(e);
  });
};

// seek 할때는 debounce
// pause play  할떄는 throttle

// export const debounceInsertLog = (timeout, learn, params, callback) => {
// };

// 첫번째 카테고리의 5레벨 가져옴
export const getTop1CategoryByLevel = (categories) => {
  let result = {
    category1: null,
    category2: null,
    category3: null,
    category4: null,
    category5: null,
  };

  let cateLst = [];
  if (categories != null) {
    if (categories.indexOf(',') > -1) cateLst = categories.split(',');
    for (let i=0; i<cateLst.length; i++) {
      if (i===0 && cateLst[i]) result.category1 = cateLst[i];
      if (i===1 && cateLst[i]) result.category2 = cateLst[i];
      if (i===2 && cateLst[i]) result.category3 = cateLst[i];
      if (i===3 && cateLst[i]) result.category4 = cateLst[i];
      if (i===4 && cateLst[i]) result.category5 = cateLst[i];
    }
  }
  return result;
};

export const getLast3CateKeyByCommaList = (cate) => {
  let result = {
    category3: null,
    category4: null,
    category5: null,
  };

  let cateLst = [];
  if (cate != null && cate.indexOf(',') > -1) {
    cateLst = cate.split(',');
    for (let i=0; i<cateLst.length; i++) {
      if (i===2 && cateLst[i]) result.category3 = cateLst[i];
      if (i===3 && cateLst[i]) result.category4 = cateLst[i];
      if (i===4 && cateLst[i]) result.category5 = cateLst[i];
    }
  }
  return result;
};

export const detailCategoryDisplay = (hrd) => {
  let res = '';
  if (hrd) {
    if (hrd.category3) res = hrd.category3;
    if (hrd.category4) res += ` > ${hrd.category4}`;
    if (hrd.category5) res += ` > ${hrd.category5}`;
  }
  return res;
};

export const makeTags = (tag) => {
  let result = '';
  if (tag && tag.length > 0) {
    let tagArr = [];

    if (tag.indexOf(',') > -1) {
      tagArr = tag.split(',');
    } else {
      tagArr.push(tag);
    }

    for (let i=0; i<tagArr.length; i++) {
      result += ' #'+tagArr[i];
    }
  }
  return result;
};

export const handleActions = (item, player) => {
  let params = {
    crseMstSn: 0,
    distCrseSn: 0,
    lrnObjDtlDistSn: 0,
    objSn: 0,
    distCrseNm: null,
    objNm: 0,
  };

  // 데이터 세팅
  setParams(params, item);

  const pause = () => {
    if (player != null) player.pause();
  };

  return {
    actLike: () => {
      if (item.liked) {
        showMessage(hrdCancelLike);
      } else {
        showConfirm({
          title: hrdLikeTitle,

          text: hrdLike,
          callback: () => {
            item.liked = !item.liked;
            item.lkCnt++;
            insertCourseAct(actTyCdDcds.ACT_TY_CD_LIKE, params);
          }
        });
      }
    },
    actPut: () => {
      pause();
      if (item.put) {
        showConfirm({
          text : `담기 취소하시겠습니까?`,
          callback: () => {
            showLoading();
            item.put = !item.put;
            insertCourseAct(actTyCdDcds.ACT_TY_CD_CANCEL_ADD, params, () => {
              hideLoading();
              showMessage(hrdCancelPut);
            });
          }
        });
      } else {
        showConfirm({
          title: hrdPutTitle,
          text: hrdPut,
          callback: () => {
            showLoading();
            item.put = !item.put;
            insertCourseAct(actTyCdDcds.ACT_TY_CD_ADD, params, () => {
              hideLoading();
              showConfirmCustomBtn({
                title: hrdPutTitle,
                text: hrdPutGoToMyPage,
                okBtnTitle: hrdPutGoToMyPageBtnTitle,
                callback: () => {
                  showAdded();
                }
              })
            });
          }
        });
      }
    },
    actShare: (shareModal) => {
      // showMessage("준비중입니다.");
      pause();
      shareModal.value = true;
    },
    actStt: (sttModal, sttText, callback) => {
      pause();
      store.dispatch(`contents/${ACT_GET_STT_TEXT}`, item.objSn).then(res => {
        if (lengthCheck(res)) {
          sttText.value = getItem(res);
        }
        if (callback instanceof Function) callback();
      }).catch(() => {
        console.error("데이터 가져오는중 오류가 발생했습니다.");
      });
      sttModal.value = true;
    },
    actSubscribe: (cateNmLst) => {
      pause();
      if (item.subscribed) {
        showConfirm({
          text : `구독 취소하시겠습니까?`,
          callback: () => {
            item.subscribed = !item.subscribed;
            deleteCrseActLrnerSumup({
              actTyCdDcd: actTyCdDcds.ACT_TY_CD_CANCEL_SUBSCRIBE,
              hrdCloudCateSns: [item.hrdCloudCateSn]
            }).then(res => {
              if (isSuccess(res)) {
                showMessage('구독 해제되었습니다.')
              }
            });
          }
        });
      } else {
        showConfirm({
          title: hrdSubscribeTitle,
          text: `${cateNmLst}${hrdSubscribe}`,
          callback: () => {
            item.subscribed = !item.subscribed;
            insertCourseAct(actTyCdDcds.ACT_TY_CD_SUBSCRIBE, {...params, hrdCloudCateSn: item.hrdCloudCateSn},() => {
              showConfirm({
                title: hrdSubscribeTitle,
                text: hrdSubscribeComplete,
                okBtnTitle: hrdSubscribeGoToMyPageBtnTitle,
                callback: () => {
                  showSubscribed();
                }
              });
            });
          }
        });
      }
    },
    actSSL: () => {
      pause();
      showConfirm({
        text: '쓸[SSL] 학습노트를 작성하시겠습니까?<br/>입과되지 않은 경우 체험모드로 안내됩니다.',
        callback: () => {
          const hrdTrgtGrpMstSn = sslRoutes['hrd'].group[0].lrnTrgtGrpMstSn;
          store.dispatch(`ssl/${ACT_GET_MY_LEARN_TARGET_LIST}`,{customCd: 'ssl'}).then(res => {
            const myLrnTrgtGrpMstSns = getCheckItems(res);
            const isEnrolled = myLrnTrgtGrpMstSns.some(x => x.lrnTrgtGrpMstSn === hrdTrgtGrpMstSn);
            if (isEnrolled) {
              const targetLrnTrgtGrpMstSn = myLrnTrgtGrpMstSns.filter(x => x.lrnTrgtGrpMstSn === hrdTrgtGrpMstSn)[0].lrnTrgtGrpMstSn;
              router.push({name: 'SSLWrite', params: {socLrnPostSn: 0, distCrseCd: 'hrd', lrnTrgtGrpMstSn: targetLrnTrgtGrpMstSn}, query: {relSn: params.lrnObjDtlDistSn}});
            } else {
              router.push({name: 'SSLMain', params: {distCrseCd: 'hrd', lrnTrgtGrpMstSn: hrdTrgtGrpMstSn}}).then(() => {});
            }
          }).catch(e => {
            console.error('[ERROR] e : ',e);
            showMessage('오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
          });
        }
      });
    },
    actHistory: (historyModal, histories, callback) => {
      pause();
      store.dispatch(`learn/${ACT_GET_LEARN_PROGRESS_LOG_LIST}`, {objSn: item.objSn}).then(res => {
        if (lengthCheck(res)) {
          histories.value = getItems(res);
        }
        if (callback instanceof Function) callback();
      }).catch(() => {
        console.error("데이터 가져오는중 오류가 발생했습니다.");
      });
      historyModal.value = true;
    },
    actOpinion: (modal) => {
      pause();
      modal.value = true;
    },
    // 개인 담기, 그룹러닝 담기 모달창 열기
    actMultiPut: async (lrnerId) => {
      pause();

      let trgt = {
        distCrseSn: 0,
        leaderPut: false,
        put: item.put || false
      };

      const trgtRes = await getGroupLeader(lrnerId);
      if(trgtRes && trgtRes.trgt) {
        trgt = trgtRes.trgt;
        params.crseMstSn = trgt.crseMstSn;
        params.distCrseSn = trgt.distCrseSn;
        params.distCrseNm = trgt.distCrseNm;
        trgt.lrnObjDtlDistSn = params.lrnObjDtlDistSn;
        trgt.objSn = params.objSn
        trgt.objNm = params.objNm;
        trgt.cttTyCdDcd = item.cttTyCdDcd;
      }

      // 개인과 그룹러닝 담기를 할 수 있는 모달창 열기
      // 행위는 모달창 안에서 처리
      if(duringRoadmapEventPeriod() && !navigationUtils.any()) {
        // 로드맵 공모기간동안 항상 팝업
        if(paramCheck(trgt.distCrseSn)){
          trgt.distCrseSn = item.distCrseSn;
          trgt.objSn = item.objSn;
          trgt.objNm = item.objNm;
          trgt.lrnObjDtlDistSn = item.lrnObjDtlDistSn;
          params.crseMstSn = item.crseMstSn;
          params.distCrseSn = item.distCrseSn;
          params.distCrseNm = item.distCrseNm;
        }
        showHrdLeaderAdd(trgt);
      } else if (trgt.distCrseSn > 0) {
        showHrdLeaderAdd(trgt);
      } else {
        handleActions(item, player).actPut();
      }
    },
  }
};

export const insertHrdUvCrseAct = () => {
  insertCourseAct(actTyCdDcds.ACT_TY_CD_HRDCLOUD);
};

export const getHrdUvList = (uv) => {
  store.dispatch(`hrdcloud/${ACT_GET_HRDCLOUD_UV_LIST}`, {actTyCdDcd: actTyCdDcds.ACT_TY_CD_HRDCLOUD})
  .then(res => {
    if (lengthCheck(res) && res.items.length === 2) {
      const items = getItems(res);
      uv.totalUvCnt = items[0].totalCnt;
      uv.todayUvCnt = items[0].todayCnt;
      uv.totalCmptnCnt = items[1].totalCnt;
      uv.todayCmptnCnt = items[1].todayCnt;
    } else {
      initParams(uv);
    }
  }).catch(() => {
    initParams(uv);
  });
};

export const insertQa = (params) => {
  return store.dispatch(`common/${ACT_INSERT_QA}`, params);
};

export const insertFileAtch = (payload, uploadFiles) => {
  return store.dispatch(`common/${ACT_INSERT_FILE_ATCH}`, {params: payload, files: uploadFiles});
};

export const insertPearlFileAtch = (payload, uploadFiles) => {
  return store.dispatch(`trnct/${ACT_INSERT_PEARL_FILE_ATCH}`, {params: payload, files: uploadFiles});
};

export const getCateNm = (sn) => {
  if (Number(sn) > 0) {
    let cate = store.state.hrdcloud.hrdcloudCates.filter(x => x.hrdCloudCateSn == sn)[0];
    if (cate && cate.hrdCloudCateNm) {
      return cate.hrdCloudCateNm;
    }
    return null;
  }
  return null;
};

export const setMainItems = (mainList, res, idx) => {
  if (res && res.items) {
    mainList.value[idx].items = getItems(res);
    mainList.value[idx].isLoading = false;
  }
};

export const generateUUID = () => {
  return store.dispatch(`common/${ACT_GET_UTIL_UUID}`, {});
};

export const getCateList = (payload) => {
  return store.dispatch(`hrdcloud/${ACT_GET_HRDCLOUD_MAIN_CATE_LIST}`, payload);
};

export const getHrdCates = () => {
  store.dispatch(`hrdcloud/${ACT_GET_HRDCLOUD_CATE_LIST}`,{}).then(res => {
    store.commit(`hrdcloud/${MUT_SET_HRDCLOUD_CATE_LIST}`, getCheckItems(res));
  });
};

export const getHrdCloudList = (payload) => {
  return store.dispatch(`prep/${ACT_GET_HRDCLOUD_LIST}`, payload);
};

export const getLrnDt = (item, format) => {
  let dt;
  if (item.mdfcnDt && item.mdfcnDt.length > 0) {
    dt = item.mdfcnDt;
  } else {
    dt = item.regDt;
  }
  if (format === 'yyyy.MM.dd') {
    return timestampToDateFormat(dt, 'yyyy.MM.dd');
  } else {
    return timestampToDateFormat(dt, 'yyyy.MM.dd hh:mm:ss');
  }
};

export const removeUpload = (file, key, idx) => {
  if (file.files.length > 0) {
    const removed = [];

    // insert 되어있는것중
    if(file.files[idx][key]) removed.push({[key]: file.files[idx][key]});

    const target = [...file.files];
    target.splice(idx,1);

    return {
      files: target,
      removed: [...file.removed, ...removed],
      binaries: target.filter(x => !x[key])
    };
  }
};

export const showAdded = (yearStr, type = 'hrdcloud') => {
  store.commit(`hrdcloud/${MUT_SHOW_ADDED}`, {yearStr, type});
};

export const closeAdded = () => {
  store.commit(`hrdcloud/${MUT_CLOSE_ADDED}`);
};

export const showSubscribed = (yearStr) => {
  store.commit(`hrdcloud/${MUT_SHOW_SUBSCRIBED}`,yearStr);
};

export const closeSubscribed = () => {
  store.commit(`hrdcloud/${MUT_CLOSE_SUBSCRIBED}`);
};

export const showShareList = (yearStr) => {
  store.commit(`hrdcloud/${MUT_SHOW_SHARE}`,yearStr);
};

export const setYearShare = (yearStr) =>{
  store.commit(`hrdcloud/${MUT_SET_YEAR_SHARE}`,yearStr);
}

export const closeShareList = () => {
  store.commit(`hrdcloud/${MUT_CLOSE_SHARE}`);
};

export const showMain = () => {
  store.commit(`hrdcloud/${MUT_SHOW_MAIN}`);
};

export const closeMain = () => {
  store.commit(`hrdcloud/${MUT_CLOSE_MAIN}`);
};
export const pageView = (hrd) => {
  insertCourseAct(actTyCdDcds.ACT_TY_CD_INQUIRY, {
    crseMstSn: hrd.value.crseMstSn,
    distCrseSn: hrd.value.distCrseSn,
    distCrseNm: hrd.value.distCrseNm,
    lrnObjDtlDistSn: hrd.value.lrnObjDtlDistSn,
    objSn: hrd.value.objSn,
    objNm: hrd.value.objNm
  }, () => { hrd.value.inqCnt++; });
};

export const getUserProgress = (hrd) => {
  return store.dispatch(`learn/${ACT_GET_LEARN_PROGRESS}`, {distCrseSn: hrd.value.distCrseSn, lrnObjDtlDistSn: hrd.value.lrnObjDtlDistSn});
};

export const setLearnPercent = (playSec, duration) => {
  let percent = 0;
  if (playSec > 0) {
    percent = Math.floor((playSec / duration) * 100);
    if (percent > 100 || percent >= 95) percent = 100;
  }
  return percent;
};

export const doPlay = (player) => {
  if (player) {
    if (player.pause()) {
      player.pause().then(() => {
        player.play();
      });
    } else {
      player.play();
    }
  }
};

export const doPause = (player) => {
  if (player) {
    if (player.play()) {
      player.play().then(()=> {
        player.pause();
      });
    } else {
      player.pause();
    }
  }
}

const getColdCallTime = (objSn, duration, currentTime) => {
  return store.dispatch(`coldcall/${ACT_GET_COLDCALL_TIME}`,{
    objSn: objSn,
    duration: duration || 0,
    coldCallRound: 1,
    lastPlaySec: currentTime
  });
};

const insertColdCall = async (hrd, uuid) => {
  let progLogSns = '';
  if (!isFinite(hrd.cttSz)) {
    hrd.cttSz = 0;
  }

  let firstColdCall = await getColdCallTime(hrd.objSn, hrd.cttSz, 0);
  let coldCallTime = firstColdCall.time;

  let firstcc = await store.dispatch(`coldcall/${ACT_INSERT_COLDCALL}`,{
    distCrseSn: hrd.distCrseSn,
    distChapSn: hrd.distChapSn,
    lrnObjDtlDistSn: hrd.lrnObjDtlDistSn,
    objSn: hrd.objSn,
    progSesnKey: uuid.value,
    lastPlaySec: coldCallTime,
    duration: hrd.cttSz,
    playSpeed: 1,
    // cmptnYn: props.progress.cmptnYn,
    // progRflYn: 'Y',
    coldCallRound: 1,
    eventCdDcd: eventCdDcds.Coldcall
  });
  // 두번째 콜드콜 INSERT
  let secondcc = await store.dispatch(`coldcall/${ACT_INSERT_COLDCALL}`,{
    distCrseSn: hrd.distCrseSn,
    distChapSn: hrd.distChapSn,
    lrnObjDtlDistSn: hrd.lrnObjDtlDistSn,
    objSn: hrd.objSn,
    progSesnKey: uuid.value,
    lastPlaySec: coldCallTime+75, // 2번째 콜드콜은 1번째 콜드콜 종료 후 1분 뒤
    duration: hrd.cttSz,
    playSpeed: 1,
    // cmptnYn: props.progress.cmptnYn,
    // progRflYn: 'Y',
    coldCallRound: 2,
    eventCdDcd: eventCdDcds.Coldcall
  });
  // 보내야할 PROG_LOG_SN
  progLogSns += firstcc.progLogSn;
  progLogSns += `,${secondcc.progLogSn}`;

  return {
    progLogSns,
    coldCallTime
  };
}

export const callNative = async (hrd, progress, uuid, token, session) => {
  const targetMode = (process.env.NODE_ENV === 'production' ? "PRD" : "DEV");
  // 진도 및 콜드콜 필요 파라미터
  let coldCallMissImgUrl = 'https://hrdcs.kbstar.com/app/img/coldcall_miss_and.png';
  let coldCallResult = await insertColdCall(hrd, uuid);

  let progRsltParam = {
    progSesnKey: uuid.value,
    distCrseSn: hrd.distCrseSn,
    lrnObjDtlDistSn: hrd.lrnObjDtlDistSn,
    objSn: hrd.objSn,
    authName: TOKEN_KEY,
    authToken: token.value,
    progRsltSn: progress.progRsltSn,
    eventtype: '{{eventtype}}' ,
    timeline: '{{timeline}}',
    playsec: '{{playsec}}',
    round: '{{round}}',
    playSpeed:'{{playspeed}}',
    targetMode: targetMode,
    ...coldCallResult // {progLogSns, coldCallTime}
  };

  if (mobileUtils.iOS()) {
    coldCallMissImgUrl = 'https://hrdcs.kbstar.com/app/img/coldcall_miss.png';
  }

  if (targetMode === 'DEV') {
    coldCallMissImgUrl = 'https://hrdcs.kbstar.com/app/img/coldcall_miss_and_dev.png';
  }

  let initial = {
    streamUrl: getVideoUrl(session.value.itn, hrd),
    thumbUrl: getThumbImg(hrd.thumb),
    myCloudID: progRsltParam.lrnObjDtlDistSn,
    viewMode: 'landscape',
    contentTitle: hrd.objNm || '',
    contentPlayTime: hrd.cttSz || 0,
    checkUrl: 'https://ehrt.kbstar.com/lxp_progress.api',
    seekEnable: 'true',
    educhkInterval: '30',
    educhkFunc: 'progress',
    progJson: JSON.stringify(progRsltParam),
    coldcallTime: coldCallResult.coldCallTime,
    coldcallAtypeNotiImgUrl: 'https://hrdcs.kbstar.com/app/img/coldcall_popup.png',
    coldcallAtypeSuccImgUrl: 'https://hrdcs.kbstar.com/app/img/coldcall_success.png',
    coldcallWtypeNotiImgUrl: coldCallMissImgUrl,
    studySuccImgUrl: 'https://hrdcs.kbstar.com/app/img/coldcall_final.png'
  };

  let jsonString = JSON.stringify(initial);

  if (isInAppAnd) window.Android.EhrdVideoInfoReceiveLXP(jsonString);
  else if (isInAppIOS) window.location = "iOSNativeCall://EhrdVideoInfoReceiveLXP?" + (jsonString);
};

export const getConvertTime = (type, time) => {
  if (type === cttTyCdDcds.CTT_TY_CD_PDF) {
    return '-';
  } else {
    return secondToFormat(time, 'hh:mm:ss');
  }
};

// 집중모드
export const toggleConcentrationMode = () => {
  let mode = store.state.hrdcloud.mode;
  store.commit(`hrdcloud/${MUT_TOGGLE_CONCENTRATION_MODE}`,{ 'mode' : (mode === 'dark' ? '' : 'dark')});
};

export const darkMode = () => {
  store.commit(`hrdcloud/${MUT_TOGGLE_CONCENTRATION_MODE}`,{ 'mode' : 'dark'});
};

export const lightMode = () => {
  store.commit(`hrdcloud/${MUT_TOGGLE_CONCENTRATION_MODE}`,{ 'mode' : ''});
};

export const showAlertAndGoBack = (msg, path) => {
 let title = '더 이상 재생할 수 없는 콘텐츠입니다.';
 if (msg) title = msg;
 showMessage(title, () => {
   goBackFunc(path);
 });
};

// 해당 연도 차수 인지 체크
export const checkHrdCloudObject = (lrnObjDtlDistSn) => {
  return store.dispatch(`prep/${ACT_GET_CHECK_HRDCLOUD_OBJECT}`,{lrnObjDtlDistSn: lrnObjDtlDistSn});
};

export const getHrdCloudLatestLrnObjDtlDistSn = async (lrnObjDtlDistSn) => {
  if (lrnObjDtlDistSn > 0) {
    const res = await store.dispatch(`prep/${ACT_GET_CHECK_HRDCLOUD_OBJECT}`,{lrnObjDtlDistSn: lrnObjDtlDistSn});
    if (lengthCheck(res)) {
      let item = res.items[0];
      if (navigationUtils.any()) {
        if (servTyCdDcds.SERV_TY_CD_EASY === item.servTyCdDcd) {
          showMessage('실습에 최적화된 PC에서 학습하세요.');
          return;
        }
      }
      if (lrnObjDtlDistSn != res.items[0].lrnObjDtlDistSn) {
        await router.push({name: 'HrdCloudContentBridge', params: {lrnObjDtlDistSn: res.items[0].lrnObjDtlDistSn}});
      }
    }
  }

  return;
}

export const showHrdLeaderAdd = (trgt) => {
  store.commit(`hrdcloud/${MUT_SHOW_HRD_LEADER_ADD}`, trgt);
};
export const closeHrdLeaderAdd = (put) => {
  store.commit(`hrdcloud/${MUT_CLOSE_HRD_LEADER_ADD}`, put);
};

export const getGroupLeader = (lrnerId) => {
  return store.dispatch(`learn/${ACT_CHECK_GROUP_LEADER}`, lrnerId);
};

export const enableMyAddChanged = () => {
  store.commit(`hrdcloud/${MUT_ENABLE_MY_ADD_CHANGED}`);
};
export const disableMyAddChanged = () => {
  store.commit(`hrdcloud/${MUT_DISABLE_MY_ADD_CHANGED}`);
};

export const enableNoSleep = () => {
  const isMobile = store.state.auth.isMobile,
      isTablet = store.state.auth.isTablet;

  if (isMobile || isTablet || navigationUtils.isApplePortable()) store.commit(`common/${MUT_ENABLE_NOSLEEP}`);
};

export const disableNoSleep = () => {
  store.commit(`common/${MUT_DISABLE_NOSLEEP}`);
};
export const encodequareBrackets = (str) => {
  let encodedStr = str.replace(/\[/g, '%5B').replace(/\]/g, '%5D');
  return encodedStr;
};

export const duringRoadmapEventPeriod = () => {
  let now = new Date().getTime();
  if(process.env.NODE_ENV == 'development'){
    return now < new Date(2023,10,20).getTime();
  }else{
    return now >= new Date(2023,10,1).getTime() && now < new Date(2023,10,20).getTime();
  }
}
