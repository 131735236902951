<template>
  <main class="kb-main" id="kb-support">
    <div class="main-content main-component">
      <div class="kb-form-layer is-active">
        <div class="layer-container" @click="closeLayer">
          <div class="layer">
            <div class="kb-form-layer-options">
              <div class="layer-option">
                <ul class="layer-option-list">
                  <li v-for="(item, idx) in fileItems" :key="idx" class="layer-option-item">
                    <a class="layer-option-link" :title="item.fileNm" @click.stop="downloadFile(item)">
                      <strong class="layer-option-text">{{item.orgFileNm}}</strong>
                      <span class="text-muted text-nowrap">{{ convertToStorageBytes(item.fileSz) }}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {commonDownloadFile, convertToStorageBytes} from '@/assets/js/util';

export default {
  name: "HrdCloudMobileFileAtch",
  props:{
    isFileAtchListShow: Boolean,
    fileItems: Array,
  },
  emits: ['update:isFileAtchListShow'],
  setup(props, {emit}){
    const downloadFile = (file) => {
      commonDownloadFile(file);
    };

    const closeLayer = () => {
      emit('update:isFileAtchListShow', false)
    }

    return{
      downloadFile,
      closeLayer,
      convertToStorageBytes
    }
  }
}
</script>

<style scoped>

</style>