<template>
  <div class="playlist-container" ref="roadmapListEl">
    <template v-if="isMobile">
      <ul v-if="!isLoading" class="hrdcloud-list mt-2">
        <template v-for="(item, index) in roadmap" :key="index">
          <h3 class="roadmap-list-title">학습 로드맵 - {{item.hrdCloudCateNm}} {{hrdCloudRoadmapCateLevels.find(x => x.level == item.hrdCloudCateLevel).label}}</h3>
          <template v-for="(ctt,idx) in item.cttList" :key="idx">
            <li v-if="ctt.cttMstSn != crse.objSn" class="hrdcloud-item p-3" @click="moveToDtl(ctt.lrnObjDtlDistSn)">
              <article class="hrdcloud-card">
                <div class="hrdcloud-image">
                  <div class="image">
                    <a class="image-link">
                      <CommonImage :img-src="getThumbUrl(ctt.thumb)" />
                    </a>
                  </div>
                  <div class="badges">
                    <div v-if="ctt.cmptnYn == 'Y'" class="badge-blue">
                      <span class="text">완료</span>
                      <i class="icon-check"></i>
                    </div>
                  </div>
                </div>
                <div class="hrdcloud-content">
                  <div class="content-category">
                    <span class="text">{{ getCateNm(ctt.category3) }}</span>
                  </div>
                  <div class="content-title">
                    <h5 class="title"><a class="title-link">{{idx+1}}. {{ ctt.objNm }}</a></h5>
                  </div>
                  <div class="content-info-and-meta">
                    <div class="content-info">
                      <div class="info">
                        <i class="icon-views"></i>
                        <span class="text">{{ ctt.inqCnt }}조회</span>
                      </div>
                      <div class="info">
                        <i class="icon-like"></i>
                        <span class="text">{{ ctt.lkCnt }}</span>
                      </div>
                    </div>
                    <div class="content-meta">
                      <span class="text">{{ secondToFormat(ctt.cttSz, 'hh:mm:ss') }}</span>
                    </div>
                  </div>
                </div>
              </article>
            </li>
          </template>
        </template>
      </ul>
      <div class="divider"></div>
    </template>
    <template v-else>
      <ul v-if="!isLoading" class="playlist">
        <template v-for="item in roadmap" :key="item.hrdCloudRoadmapSn">
          <h3 class="text-white-50 my-2">학습 로드맵 - {{item.hrdCloudCateNm}} {{hrdCloudRoadmapCateLevels.find(x => x.level == item.hrdCloudCateLevel).label}}</h3>
          <template v-for="(ctt,idx) in item.cttList" :key="idx">
            <li v-if="ctt.cttMstSn != crse.objSn" class="playlist-item kb-mouse-cursor" @click="moveToDtl(ctt.lrnObjDtlDistSn)">
              <div class="video-thumbnail">
                <div class="image">
                  <CommonImage :img-src="getThumbUrl(ctt.thumb)" />
                </div>
              </div>
              <div class="badges">
                <div v-if="ctt.cmptnYn == 'Y'" class="badge badge-blue">
                  <span class="text">완료</span>
                  <i class="icon-check"></i>
                </div>
              </div>
              <div class="video-details">
                <div class="video-title">{{idx+1}}. {{ ctt.objNm }}</div>
                <div class="metadata">
                  <span class="text">{{ ctt.inqCnt }}조회</span>
                  <span class="text">{{ secondToFormat(ctt.cttSz, 'hh:mm:ss') }}</span>
                </div>
              </div>
            </li>
          </template>
        </template>
      </ul>
      <button class="kb-btn-close" @click="closeFunc"><i class="icon-close"></i></button>
    </template>
  </div>
</template>

<script>
import {computed, onMounted, ref, watch} from 'vue';
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import CommonImage from '@/components/common/CommonImage';
import {hrdCloudRoadmapCateLevels} from "@/assets/js/modules/cloud/cloud-common";
import {getThumbUrl} from "@/assets/js/modules/course/course-common";
import {getCateNm} from '@/assets/js/modules/hrd/hrd-common';
import {getItem, getItems, lengthCheck, secondToFormat} from "@/assets/js/util";
import {
  ACT_GET_HRDCLOUD_LEARNING_ROADMAP_CTT_LIST,
  ACT_GET_HRDCLOUD_LEARNING_ROADMAP_LIST
} from "@/store/modules/hrdcloud/hrdcloud";

export default {
  name: 'HrdCloudRoadmapPlayList',
  components: {CommonImage},
  props: {
    crse: Object,
    roadmapSns: Array,
    closeFunc: Function,
  },
  setup(props) {
    const store = useStore()
    const router = useRouter()
    const roadmap = ref([])
    const roadmapListEl = ref(null)
    const isLoading = ref(true)
    const isMobile = computed(() => store.state.auth.isMobile)

    const moveToDtl = (lrnObjDtlDistSn) => {
      if(props.crse.lrnObjDtlDistSn == lrnObjDtlDistSn){
        props.closeFunc();
        return;
      }
      router.push({name: 'HrdCloudContentBridge', params: {lrnObjDtlDistSn: lrnObjDtlDistSn, 'mode': 'uebung'}});
    }

    const getLearningRoadmapCttList = () => {
      isLoading.value = true;
      roadmap.value = [];
      if(props.roadmapSns.length > 0){
        props.roadmapSns.map(roadmapSn => {
          store.dispatch(`hrdcloud/${ACT_GET_HRDCLOUD_LEARNING_ROADMAP_LIST}`, {hrdCloudRoadmapSn: roadmapSn}).then(res => {
            if(lengthCheck(res)){
              roadmap.value.push(getItem(res));
              store.dispatch(`hrdcloud/${ACT_GET_HRDCLOUD_LEARNING_ROADMAP_CTT_LIST}`, roadmapSn).then(cttRes => {
                roadmap.value.find(x => x.hrdCloudRoadmapSn == roadmapSn).cttList = getItems(cttRes);
                roadmap.value.find(x => x.hrdCloudRoadmapSn == roadmapSn).isCompleted = roadmap.value.find(x => x.hrdCloudRoadmapSn == roadmapSn).cttList.filter(x => x.cmptnYn != 'Y').length == 0;
              })
            }
          }).finally(() => {isLoading.value = false;})
        })
      }
    }

    watch(() => [roadmap.value,isLoading.value], () => {
      if(roadmap.value.length > 0 && isMobile.value){
        setTimeout(() => {roadmapListEl.value.scrollIntoView({block:'start',behavior:'smooth'});}, 100);
      }
    })

    watch(() => props.roadmapSns, getLearningRoadmapCttList)

    onMounted(getLearningRoadmapCttList)

    return {
      roadmap,
      roadmapListEl,
      isLoading,
      isMobile,
      hrdCloudRoadmapCateLevels,
      moveToDtl,
      getThumbUrl,
      getCateNm,
      secondToFormat,
    }
  }
}
</script>
