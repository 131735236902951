<template>
  <img v-if="badgeSrc" v-bind:id="badgeId" :src="badgeSrc" alt="" class="icon" :class="{'disActive': !isActive}">
</template>
<script>

import {ref, computed} from 'vue';

export default {
  name: 'HrdCloudBadge',
  props: {
    badgeTyCdDcd: [Number, String],
    isActive: Boolean,
    hiddenYn: String
  },
  setup(props) {
    const badgeId = ref(`badge_${props.badgeTyCdDcd.trim()}`);
    const badgeSrc = computed(() => {
      if (props.badgeTyCdDcd) {
        if (props.hiddenYn === 'Y' && !props.isActive) {
          return require(`@/assets/lxp/images/questionmark.svg`);
        } else {
          // return require(`@/assets/lxp/images/hrdcloudbadge/${props.isActive ? 'active' : 'deactive'}/${props.badgeTyCdDcd.trim()}.svg`);
          return require(`@/assets/lxp/images/hrdcloudbadge/active/${props.badgeTyCdDcd.trim()}.svg`);
        }
      }
      return null;
    });

    // setTimeout(()=>{
    //   document.getElementById(badgeId.value).className = "icon onAct"
    //   console.log(badgeId.value);
    // }, getRandom(0,5)*1000);
    // console.log(document.getElementById(badgeId.value));
    // console.log(badgeId.value);
    // // document.getElementById(badgeId.value).className.baseVal = "onAct"
    return {
      badgeSrc,
      badgeId
    }
  }
};
</script>
<style scoped>
.disActive {filter:grayscale(1)}
.onAct {-webkit-animation: 2s}
.icon {width:104px;height:104px}
@-webkit-keyframes  onAct {
  0% {filter: grayscale(1)}
  100% {filter:grayscale(0)}
}
</style>