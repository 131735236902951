<template>
  <swiper
      data-card="group"
      class="learning"
      :loop="false"
      slides-per-view="auto"
      :centered-slides="false"
      :space-between="10"
      :pagination="{
        el: '.learning-pagination',
        clickable: true,
      }"
      :modules="modules"
  >

    <swiper-slide class="learning-item" :class="(params.secondCnt >= targetStandard.finish)?'is-active':((params.secondCnt > 0)?'is-active_ing':'')">
      <a href="javascript:" @click="goLearn('group', params.targetLrnTrgtGrpMstSn)">
        <header class="item-header">
          <h3 class="title">그룹러닝</h3>
        </header>
        <div class="learning-contents">
          <div class="content">
            <div class="content-left">
              <p class="content-title">모임횟수</p>
              <p class="content-subtext">연간 최대 {{ targetStandard.limit }}회</p>
            </div>
            <div class="content-text">{{ params.secondCnt }}/{{ targetStandard.finish }}회</div>
          </div>
        </div>
        <div class="learning-visual">
          <div class="visual-item">
            <SSLDayGraph
                :target-count="params.secondCnt"
                :target-max-count="targetStandard.finish"
            />
          </div>
        </div>
      </a>
    </swiper-slide>

<!--    <swiper-slide class="learning-item">-->
<!--      <header class="item-header">-->
<!--        <h3 class="title">그룹러닝</h3>-->
<!--      </header>-->
<!--      <div class="learning-contents">-->
<!--        <div class="content">-->
<!--          <div class="content-left">-->
<!--            <p class="content-title">나의 출석률</p>-->
<!--            <p class="content-subtext">연간</p>-->
<!--          </div>-->
<!--          <div class="content-text">{{ params.firstCnt > 0 ? (params.secondCnt/params.firstCnt * 100).toFixed(1) : '0.0' }}%</div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="learning-visual">-->
<!--        <div class="visual-item">-->
<!--          <SSLBarGraph-->
<!--              :target-count="params.secondCnt"-->
<!--              :target-standard-count="params.firstCnt"-->
<!--          />-->
<!--        </div>-->
<!--      </div>-->
<!--    </swiper-slide>-->

<!--    <swiper-slide class="learning-item" :class="{'is-active': groupSocBoardCnt >= targetStandard.finish}">-->
<!--      <header class="item-header">-->
<!--        <h3 class="title">그룹러닝</h3>-->
<!--      </header>-->
<!--      <div class="learning-contents">-->
<!--        <div class="content">-->
<!--          <div class="content-left">-->
<!--            <p class="content-title">학습노트 작성</p>-->
<!--            <p class="content-subtext">연간 최대 {{ targetStandard.limit }}회</p>-->
<!--          </div>-->
<!--          <div class="content-text">{{ groupSocBoardCnt }}/{{ targetStandard.finish }}회</div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="learning-visual">-->
<!--        <div class="visual-item">-->
<!--          <SSLStarGraph-->
<!--              v-if="groupSocBoardCnt >= targetStandard.finish"-->
<!--          />-->
<!--          <SSLCircleGraph-->
<!--              v-else-->
<!--              :target-count="groupSocBoardCnt"-->
<!--          />-->
<!--        </div>-->
<!--      </div>-->
<!--    </swiper-slide>-->

    <div class="learning-pagination swiper-pagination"></div>
  </swiper>
</template>
<script>
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
// import SSLCircleGraph from '@/components/ssl/graph/SSLCircleGraph';
// import SSLBarGraph from '@/components/ssl/graph/SSLBarGraph';
import SSLDayGraph from '@/components/ssl/graph/SSLDayGraph';
// import SSLStarGraph from '@/components/ssl/graph/SSLStarGraph';
import {sslStatus2Setup} from '@/assets/js/modules/ssl/setup/ssl-status-setup';

export default {
  name: 'SSLMobileStatus2',
  components: {
    // SSLStarGraph,
    SSLDayGraph,
    // SSLBarGraph,
    // SSLCircleGraph,
    Swiper,
    SwiperSlide
  },
  setup: sslStatus2Setup
}
</script>
