<template>
  <!-- main-content -->
  <div class="main-content main-component">
    <div class="support-view-container">
      <div class="view-contents">
        <!-- section: 상품판매 필수 자격증 -->
        <section class="content-section">
          <header class="section-header">
            <h4 class="title">상품판매 필수 자격증</h4>
          </header>
          <div class="section-content section-content-divider">
            <div class="content-scrollwrap">
              <div class="kb-table kb-table-v2">
                <table style="min-width:1075px">
                  <colgroup>
                    <col style="width:10.41%">
                    <col style="width:8.5%">
                    <col style="width:8.5%">
                    <col style="width:12.27%">
                    <col style="width:11.16%">
                    <col style="width:10.41%">
                    <col style="width:10.41%">
                    <col style="width:auto">
                  </colgroup>
                  <thead>
                    <tr>
                      <th><span class="th-title">자격증명</span></th>
                      <th><span class="th-title">세부사항</span></th>
                      <th><span class="th-title">취득여부</span></th>
                      <th><span class="th-title">주관처</span></th>
                      <th><span class="th-title">지원금액</span></th>
                      <th><span class="th-title">등재일</span></th>
                      <th><span class="th-title">자격취득일</span></th>
                      <th><span class="th-title">비고</span></th>
                    </tr>
                  </thead>
                  <tbody class="align-center">
                    <tr>
                      <td rowspan="3"><span class="td-text">펀드투자 <br>권유자문인력</span></td>
                      <td><span class="td-text">증권</span></td>
                      <td><span class="td-text"><i :class="{'icon-o': isStockFundLcsn}">{{isStockFundLcsn ? '' : '-'}}</i></span></td>
                      <td><span class="td-text">한국금융투자협회</span></td>
                      <td><span class="td-text">{{stockFund.sprtAmt ? stockFund.sprtAmt : '-'}}</span></td>
                      <td><span class="td-text">{{stockFund.lcsnRegYmd ? stockFund.lcsnRegYmd : '-'}}</span></td>
                      <td><span class="td-text">{{stockFund.passYmd}}</span></td>
                      <td><span class="td-text">{{stockFund.lcsnNm ? stockFund.lcsnNm+' 취득' : '-'}}</span></td>
                    </tr>
                    <tr>
                      <td class="cell-base"><span class="td-text">파생상품</span></td>
                      <td><span class="td-text"><i :class="{'icon-o': isDerivationFundLcsn}">{{isDerivationFundLcsn ? '' : '-'}}</i></span></td>
                      <td><span class="td-text">한국금융투자협회</span></td>
                      <td><span class="td-text">{{derivationFund.sprtAmt ? derivationFund.sprtAmt : '-'}}</span></td>
                      <td><span class="td-text">{{derivationFund.lcsnRegYmd ? derivationFund.lcsnRegYmd : '-'}}</span></td>
                      <td><span class="td-text">{{derivationFund.passYmd}}</span></td>
                      <td><span class="td-text">{{derivationFund.lcsnNm ? derivationFund.lcsnNm+' 취득' : '-'}}</span></td>
                    </tr>
                    <tr>
                      <td class="cell-base"><span class="td-text">부동산</span></td>
                      <td><span class="td-text"><i :class="{'icon-o': isRealEstateFundLcsn}">{{isRealEstateFundLcsn ? '' : '-'}}</i></span></td>
                      <td><span class="td-text">한국금융투자협회</span></td>
                      <td><span class="td-text">{{realEstateFund.sprtAmt ? realEstateFund.sprtAmt : '-'}}</span></td>
                      <td><span class="td-text">{{realEstateFund.lcsnRegYmd ? realEstateFund.lcsnRegYmd : '-'}}</span></td>
                      <td><span class="td-text">{{realEstateFund.passYmd}}</span></td>
                      <td><span class="td-text">{{realEstateFund.lcsnNm ? realEstateFund.lcsnNm+' 취득' : '-'}}</span></td>
                    </tr>
                    <tr>
                      <td><span class="td-text">파생상품투자 <br>권유자문인력</span></td>
                      <td><span class="td-text">-</span></td>
                      <td><span class="td-text"><i :class="{'icon-o': isDerivationLcsn}">{{isDerivationLcsn ? '' : '-'}}</i></span></td>
                      <td><span class="td-text">금융투자협회</span></td>
                      <td><span class="td-text">{{derivation.sprtAmt ? derivation.sprtAmt : '-'}}</span></td>
                      <td><span class="td-text">{{derivation.lcsnRegYmd ? derivation.lcsnRegYmd : '-'}}</span></td>
                      <td><span class="td-text">{{derivation.passYmd}}</span></td>
                      <td><span class="td-text">{{derivation.lcsnNm ? derivation.lcsnNm+' 취득' : '-'}}</span></td>
                    </tr>
                    <tr>
                      <td rowspan="4"><span class="td-text">보험대리점</span></td>
                      <td><span class="td-text">생명보험</span></td>
                      <td><span class="td-text"><i :class="{'icon-o': isLifeInsuranceLcsn}">{{isLifeInsuranceLcsn ? '' : '-'}}</i></span></td>
                      <td><span class="td-text">보험연수원</span></td>
                      <td><span class="td-text">{{lifeInsurance.sprtAmt ? lifeInsurance.sprtAmt : '-'}}</span></td>
                      <td><span class="td-text">{{lifeInsurance.lcsnRegYmd ? lifeInsurance.lcsnRegYmd : '-'}}</span></td>
                      <td><span class="td-text">{{lifeInsurance.passYmd}}</span></td>
                      <td><span class="td-text">{{lifeInsurance.lcsnNm ? lifeInsurance.lcsnNm+' 취득' : '-'}}</span></td>
                    </tr>
                    <tr>
                      <td class="cell-base"><span class="td-text">제3보험</span></td>
                      <td><span class="td-text"><i :class="{'icon-o': isThirdInsuranceLcsn}">{{isThirdInsuranceLcsn ? '' : '-'}}</i></span></td>
                      <td><span class="td-text">보험연수원</span></td>
                      <td><span class="td-text">{{thirdInsurance.sprtAmt ? thirdInsurance.sprtAmt : '-'}}</span></td>
                      <td><span class="td-text">{{thirdInsurance.lcsnRegYmd ? thirdInsurance.lcsnRegYmd : '-'}}</span></td>
                      <td><span class="td-text">{{thirdInsurance.passYmd}}</span></td>
                      <td><span class="td-text">{{thirdInsurance.lcsnNm ? thirdInsurance.lcsnNm+' 취득' : '-'}}</span></td>
                    </tr>
                    <tr>
                      <td class="cell-base"><span class="td-text">손해보험</span></td>
                      <td><span class="td-text"><i :class="{'icon-o': isGeneralInsuranceLcsn}">{{isGeneralInsuranceLcsn ? '' : '-'}}</i></span></td>
                      <td><span class="td-text">보험연수원</span></td>
                      <td><span class="td-text">{{generalInsurance.sprtAmt ? generalInsurance.sprtAmt : '-'}}</span></td>
                      <td><span class="td-text">{{generalInsurance.lcsnRegYmd ? generalInsurance.lcsnRegYmd : '-'}}</span></td>
                      <td><span class="td-text">{{generalInsurance.passYmd}}</span></td>
                      <td><span class="td-text">{{generalInsurance.lcsnNm ? generalInsurance.lcsnNm+' 취득' : '-'}}</span></td>
                    </tr>
                    <tr>
                      <td class="cell-base"><span class="td-text">변액보험</span></td>
                      <td><span class="td-text"><i :class="{'icon-o': isVariableInsuranceLcsn}">{{isVariableInsuranceLcsn ? '' : '-'}}</i></span></td>
                      <td><span class="td-text">생명보험협회</span></td>
                      <td><span class="td-text">{{variableInsurance.sprtAmt ? variableInsurance.sprtAmt : '-'}}</span></td>
                      <td><span class="td-text">{{variableInsurance.lcsnRegYmd ? variableInsurance.lcsnRegYmd : '-'}}</span></td>
                      <td><span class="td-text">{{variableInsurance.passYmd}}</span></td>
                      <td><span class="td-text">{{variableInsurance.lcsnNm ? variableInsurance.lcsnNm+' 취득' : '-'}}</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
        <!-- section: 자격증 이력 -->
        <section class="content-section">
          <header class="section-header d-flex">
            <h4 class="title">자격증 이력</h4>
<!--            <div class="header-side">
              <button class="kb-btn kb-btn-outline kb-btn-year">
                <span class="text">2022년</span>
                <i class="icon-arrow"></i>
              </button>
              <button class="kb-btn kb-btn-outline kb-btn-search">
                <i class="icon-search"></i>
              </button>
            </div>-->
          </header>
          <template v-if="licenses.length > 0">
            <div class="section-content section-content-divider">
              <!-- content-item -->
              <div v-for="(item, index) in licenses" class="content-item bd-clear pd-clear" :key="index">
                <div class="title">{{item.pblcnInstNm}}</div>
                <div class="desc">
                  <p><b>{{item.lcsnNm}}</b></p>
                </div>
                <ul class="desc-type-title baseline">
                  <li>
                    <h5>자격증코드</h5>
                    <p>{{item.lcsnCd}}</p>
                  </li>
                  <li>
                    <h5>자격적용일</h5>
                    <p>{{item.passYmd}}</p>
                  </li>
                  <li>
                    <h5>등급</h5>
                    <p>{{item.grd1Cd}}</p>
                  </li>
                  <li>
                    <h5>지원금액</h5>
                    <p>{{item.sprtAmt}}</p>
                  </li>
                </ul>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="result-empty">
              <img src="@/assets/lxp/mobile/images/_common/img_empty.png">
              <p class="text">자격증 이력이 없어요</p>
            </div>
          </template>
        </section>
      </div>
    </div>
  </div>
  <!-- //main-content -->
</template>

<script>
import {useStore} from "vuex";
import {reactive, ref, onMounted} from "vue";
import {closeToggles} from '@/assets/js/ui.init';
import {
  ACT_GET_SUPPORT_LICENSE_ALL_HISTORY_LIST,
  ACT_GET_SUPPORT_LICENSE_HISTORY_LIST, MUT_SHOW_MAIN
} from "@/store/modules/support/support";
import {
  getItems,
  getPaging,
  lengthCheck,
  numberComma,
  timestampToDateFormat, ymdStrToDateFormat
} from "@/assets/js/util";
import {
  derivationFundLcsn,
  derivationLcsn,
  generalInsuranceLcsn,
  lifeInsuranceLcsn,
  realEstateFundLcsn,
  stockFundLcsn,
  thirdInsuranceLcsn,
  variableInsuranceLcsn
} from "@/assets/js/modules/support/support-common";
import {useRouter} from "vue-router";

export default {
  name: 'SupportLicenseMobileHistory',
  components: {},
  setup(){
    const store = useStore();
    const router = useRouter();
    const licenses = ref([]);
    const allLicenses = ref([]);

    const stockFund = ref([]);
    const derivationFund = ref([]);
    const realEstateFund = ref([]);
    const derivation = ref([]);
    const lifeInsurance = ref([]);
    const generalInsurance = ref([]);
    const thirdInsurance = ref([]);
    const variableInsurance = ref([]);
    const currentDate = new Date();

    const yearOptions = (() => {
      const options = [];
      for(let i = currentDate.getFullYear(); i >= 1978 ; i--){
        options.push({value: i, display: `${i}년`});
      }
      return options;
    })();

    const toggles = reactive({
      year: false,
    });

    const filters = reactive({
      year: '',
      keyword: '',
    });

    const paging = ref({pageNo: 1, pageSize: 9999, totalCount: 0});
    const pagingFunc = (pageNo, pageSize) => {
      const query = {pageNo: pageNo};
      if (pageSize !== paging.value.pageSize) {
        query.pageSize = pageSize;
      }
      router.push({query: query});
    };

    const clickSearch = () => {
      pagingFunc(paging.value.pageNo,6);
    };

    const isStockFundLcsn = ref(false);
    const isDerivationFundLcsn = ref(false);
    const isRealEstateFundLcsn = ref(false);
    const isDerivationLcsn = ref(false);
    const isLifeInsuranceLcsn = ref(false);
    const isGeneralInsuranceLcsn = ref(false);
    const isThirdInsuranceLcsn = ref(false);
    const isVariableInsuranceLcsn = ref(false);

    const getAllLcsnList = () => {
      store.dispatch(`support/${ACT_GET_SUPPORT_LICENSE_ALL_HISTORY_LIST}`, {
      }).then(res => {
        if(lengthCheck(res)){
          allLicenses.value.push(...getItems(res));
          allLicenses.value.forEach(x => {
            if(stockFundLcsn.includes(x.lcsnCd)) {
              isStockFundLcsn.value = true;
              stockFund.value.sprtAmt = x.sprtAmt ? numberComma(x.sprtAmt) : '-';
              stockFund.value.lcsnRegYmd = x.lcsnRegYmd ? timestampToDateFormat(x.lcsnRegYmd, 'yyyy.MM.dd') : '-';
              if(x.passAnnonYmd){
                stockFund.value.passYmd = ymdStrToDateFormat(x.passAnnonYmd, 'yyyy.MM.dd');
              }else if(x.lcsnExamYmd){
                stockFund.value.passYmd = ymdStrToDateFormat(x.lcsnExamYmd, 'yyyy.MM.dd');
              }else if(x.lcsnRegYmd){
                stockFund.value.passYmd =  ymdStrToDateFormat(x.lcsnRegYmd, 'yyyy.MM.dd');
              }else{
                stockFund.value.passYmd =  x.regDt ? timestampToDateFormat(x.regDt,'yyyy.MM.dd') : '-';
              }
              stockFund.value.lcsnNm = x.lcsnNm ? x.lcsnNm : '-';
            }
            if(derivationFundLcsn.includes(x.lcsnCd)) {
              isDerivationFundLcsn.value = true;
              derivationFund.value.sprtAmt = x.sprtAmt ? numberComma(x.sprtAmt) : '-';
              derivationFund.value.lcsnRegYmd = x.lcsnRegYmd ? timestampToDateFormat(x.lcsnRegYmd, 'yyyy.MM.dd') : '-';
              if(x.passAnnonYmd){
                derivationFund.value.passYmd = ymdStrToDateFormat(x.passAnnonYmd, 'yyyy.MM.dd');
              }else if(x.lcsnExamYmd){
                derivationFund.value.passYmd = ymdStrToDateFormat(x.lcsnExamYmd, 'yyyy.MM.dd');
              }else if(x.lcsnRegYmd){
                derivationFund.value.passYmd =  ymdStrToDateFormat(x.lcsnRegYmd, 'yyyy.MM.dd');
              }else{
                derivationFund.value.passYmd =  x.regDt ? timestampToDateFormat(x.regDt,'yyyy.MM.dd') : '-';
              }
              derivationFund.value.lcsnNm = x.lcsnNm ? x.lcsnNm : '-';
            }
            if(realEstateFundLcsn.includes(x.lcsnCd)) {
              isRealEstateFundLcsn.value = true;
              realEstateFund.value.sprtAmt = x.sprtAmt ? numberComma(x.sprtAmt) : '-';
              realEstateFund.value.lcsnRegYmd = x.lcsnRegYmd ? timestampToDateFormat(x.lcsnRegYmd, 'yyyy.MM.dd') : '-';
              if(x.passAnnonYmd){
                realEstateFund.value.passYmd = ymdStrToDateFormat(x.passAnnonYmd, 'yyyy.MM.dd');
              }else if(x.lcsnExamYmd){
                realEstateFund.value.passYmd = ymdStrToDateFormat(x.lcsnExamYmd, 'yyyy.MM.dd');
              }else if(x.lcsnRegYmd){
                realEstateFund.value.passYmd =  ymdStrToDateFormat(x.lcsnRegYmd, 'yyyy.MM.dd');
              }else{
                realEstateFund.value.passYmd =  x.regDt ? timestampToDateFormat(x.regDt,'yyyy.MM.dd') : '-';
              }
              realEstateFund.value.lcsnNm = x.lcsnNm ? x.lcsnNm : '-';
            }
            if(derivationLcsn.includes(x.lcsnCd)) {
              isDerivationLcsn.value = true;
              derivation.value.sprtAmt = x.sprtAmt ? numberComma(x.sprtAmt) : '-';
              derivation.value.lcsnRegYmd = x.lcsnRegYmd ? timestampToDateFormat(x.lcsnRegYmd, 'yyyy.MM.dd') : '-';
              if(x.passAnnonYmd){
                derivation.value.passYmd = ymdStrToDateFormat(x.passAnnonYmd, 'yyyy.MM.dd');
              }else if(x.lcsnExamYmd){
                derivation.value.passYmd = ymdStrToDateFormat(x.lcsnExamYmd, 'yyyy.MM.dd');
              }else if(x.lcsnRegYmd){
                derivation.value.passYmd =  ymdStrToDateFormat(x.lcsnRegYmd, 'yyyy.MM.dd');
              }else{
                derivation.value.passYmd =  x.regDt ? timestampToDateFormat(x.regDt,'yyyy.MM.dd') : '-';
              }
              derivation.value.lcsnNm = x.lcsnNm ? x.lcsnNm : '-';
            }
            if(lifeInsuranceLcsn.includes(x.lcsnCd)) {
              isLifeInsuranceLcsn.value = true;
              lifeInsurance.value.sprtAmt = x.sprtAmt ? numberComma(x.sprtAmt) : '-';
              lifeInsurance.value.lcsnRegYmd = x.lcsnRegYmd ? timestampToDateFormat(x.lcsnRegYmd, 'yyyy.MM.dd') : '-';
              if(x.passAnnonYmd){
                lifeInsurance.value.passYmd = ymdStrToDateFormat(x.passAnnonYmd, 'yyyy.MM.dd');
              }else if(x.lcsnExamYmd){
                lifeInsurance.value.passYmd = ymdStrToDateFormat(x.lcsnExamYmd, 'yyyy.MM.dd');
              }else if(x.lcsnRegYmd){
                lifeInsurance.value.passYmd =  ymdStrToDateFormat(x.lcsnRegYmd, 'yyyy.MM.dd');
              }else{
                lifeInsurance.value.passYmd =  x.regDt ? timestampToDateFormat(x.regDt,'yyyy.MM.dd') : '-';
              }
              lifeInsurance.value.lcsnNm = x.lcsnNm ? x.lcsnNm : '-';
            }
            if(generalInsuranceLcsn.includes(x.lcsnCd)) {
              isGeneralInsuranceLcsn.value = true;
              generalInsurance.value.sprtAmt = x.sprtAmt ? numberComma(x.sprtAmt) : '-';
              generalInsurance.value.lcsnRegYmd = x.lcsnRegYmd ? timestampToDateFormat(x.lcsnRegYmd, 'yyyy.MM.dd') : '-';
              if(x.passAnnonYmd){
                generalInsurance.value.passYmd = ymdStrToDateFormat(x.passAnnonYmd, 'yyyy.MM.dd');
              }else if(x.lcsnExamYmd){
                generalInsurance.value.passYmd = ymdStrToDateFormat(x.lcsnExamYmd, 'yyyy.MM.dd');
              }else if(x.lcsnRegYmd){
                generalInsurance.value.passYmd =  ymdStrToDateFormat(x.lcsnRegYmd, 'yyyy.MM.dd');
              }else{
                generalInsurance.value.passYmd =  x.regDt ? timestampToDateFormat(x.regDt,'yyyy.MM.dd') : '-';
              }
              generalInsurance.value.lcsnNm = x.lcsnNm ? x.lcsnNm : '-';
            }
            if(thirdInsuranceLcsn.includes(x.lcsnCd)) {
              isThirdInsuranceLcsn.value = true;
              thirdInsurance.value.sprtAmt = x.sprtAmt ? numberComma(x.sprtAmt) : '-';
              thirdInsurance.value.lcsnRegYmd = x.lcsnRegYmd ? timestampToDateFormat(x.lcsnRegYmd, 'yyyy.MM.dd') : '-';
              if(x.passAnnonYmd){
                thirdInsurance.value.passYmd = ymdStrToDateFormat(x.passAnnonYmd, 'yyyy.MM.dd');
              }else if(x.lcsnExamYmd){
                thirdInsurance.value.passYmd = ymdStrToDateFormat(x.lcsnExamYmd, 'yyyy.MM.dd');
              }else if(x.lcsnRegYmd){
                thirdInsurance.value.passYmd =  ymdStrToDateFormat(x.lcsnRegYmd, 'yyyy.MM.dd');
              }else{
                thirdInsurance.value.passYmd =  x.regDt ? timestampToDateFormat(x.regDt,'yyyy.MM.dd') : '-';
              }
              thirdInsurance.value.lcsnNm = x.lcsnNm ? x.lcsnNm : '-';
            }
            if(variableInsuranceLcsn.includes(x.lcsnCd)) {
              isVariableInsuranceLcsn.value = true;
              variableInsurance.value.sprtAmt = x.sprtAmt ? numberComma(x.sprtAmt) : '-';
              variableInsurance.value.lcsnRegYmd = x.lcsnRegYmd ? timestampToDateFormat(x.lcsnRegYmd, 'yyyy.MM.dd') : '-';
              if(x.passAnnonYmd){
                variableInsurance.value.passYmd = ymdStrToDateFormat(x.passAnnonYmd, 'yyyy.MM.dd');
              }else if(x.lcsnExamYmd){
                variableInsurance.value.passYmd = ymdStrToDateFormat(x.lcsnExamYmd, 'yyyy.MM.dd');
              }else if(x.lcsnRegYmd){
                variableInsurance.value.passYmd =  ymdStrToDateFormat(x.lcsnRegYmd, 'yyyy.MM.dd');
              }else{
                variableInsurance.value.passYmd =  x.regDt ? timestampToDateFormat(x.regDt,'yyyy.MM.dd') : '-';
              }
              variableInsurance.value.lcsnNm = x.lcsnNm ? x.lcsnNm : '-';
            }
          });
        }
      }).catch(e => {
        console.error(e);
      });
    }

    const getLcsnList = () => {
      store.dispatch(`support/${ACT_GET_SUPPORT_LICENSE_HISTORY_LIST}`, {
        year: filters.year > 0 ? filters.year : '',
        lcsnNm: filters.keyword,
        acqsUpdtDiv: 'N',
        pageNo: paging.value.pageNo,
        pageSize: paging.value.pageSize
      }).then(res => {
        if(lengthCheck(res)){
          licenses.value = getItems(res);
          paging.value = getPaging(res);
          licenses.value.forEach(x => {
            if(x.passAnnonYmd){
              x.passYmd = ymdStrToDateFormat(x.passAnnonYmd, 'yyyy.MM.dd');
            }else if(x.lcsnExamYmd){
              x.passYmd = ymdStrToDateFormat(x.lcsnExamYmd, 'yyyy.MM.dd');
            }else if(x.lcsnRegYmd){
              x.passYmd = ymdStrToDateFormat(x.lcsnRegYmd, 'yyyy.MM.dd');
            }else{
              x.passYmd = x.regDt ? timestampToDateFormat(x.regDt,'yyyy.MM.dd') : '-';
            }
            x.sprtAmt = x.sprtAmt ? numberComma(x.sprtAmt) : '-';
          })
        }else{
          licenses.value = [];
          paging.value.totalCount = 0;
        }
      }).catch(e => {
        console.error(e);
      });
    }

    onMounted(() => {
      store.commit(`support/${MUT_SHOW_MAIN}`);
    });

    getAllLcsnList();
    getLcsnList();

    return{
      licenses,filters,yearOptions,closeToggles,toggles,allLicenses,
      isStockFundLcsn,isDerivationFundLcsn,isRealEstateFundLcsn,isDerivationLcsn,isLifeInsuranceLcsn,isGeneralInsuranceLcsn,isThirdInsuranceLcsn,isVariableInsuranceLcsn,
      stockFund,derivationFund,realEstateFund,derivation,lifeInsurance,generalInsurance,thirdInsurance,variableInsurance,
      paging,pagingFunc,
      clickSearch,
      getLcsnList
    }
  }
};
</script>