import ApiService from '@/assets/js/api.service';

export const ACT_GET_ATCH_FILE_MANAGE_NO = 'actGetFileManageNo';
export const ACT_DOWNLOAD_ATCH_FILE = 'actDownloadAtchFile';
export const ACT_INSERT_CMS_ATCH_FILE = 'actInsertCmsAtchFile';
export const ACT_UPLOAD_CMS_ATCH_FILE = 'actUploadCmsAtchFile';
export const ACT_UPLOAD_CMS_THUMBNAIL = 'actUploadCmsThumbnail';

export const ACT_GET_CONTENTS_MANAGE_NO = 'actGetContentsManageNo';
export const ACT_GET_CTT_MST_LIST = 'actGetCttMstList';
export const ACT_INSERT_CTT_MST = 'actInsertCttMst';
export const ACT_UPDATE_CTT_MST = 'actUpdateCttMst';
export const ACT_UPLOAD_CTT_MST = 'actUploadCttMst';
export const ACT_GET_CTT_TRFM_STT = 'actGetCttTrfmStt';
export const ACT_GET_STT_TEXT = 'actGetSttText';
export const ACT_DELETE_CTT_MST = 'actDeleteCttMst';


export const ACT_INSERT_CTT_CMNT = 'actInsertCttCmnt';
export const ACT_UPDATE_CTT_CMNT = 'actUpdateCttCmnt';
export const ACT_DELETE_CTT_CMNT = 'actDeleteCttCmnt';
export const ACT_GET_CTT_CMNT_LST = 'actGetCttCmntLst';

const state = {
};

const mutations = {
};

const actions = {
  [ACT_GET_CONTENTS_MANAGE_NO](context, params) {
    return new Promise(resolve => {
      ApiService.query('/v1/app/contents/mng', params).then(response => resolve(response))
    });
  },
  [ACT_INSERT_CTT_MST](context, params) {
    return new Promise(resolve => {
      ApiService.post('/v1/app/contents', params).then(response => resolve(response))
    });
  },
  [ACT_UPDATE_CTT_MST](context, {cttMstSn, params}) {
    return new Promise(resolve => {
      ApiService.put(`/v1/app/contents/${cttMstSn}`, params).then(response => resolve(response))
    });
  },
  [ACT_UPLOAD_CTT_MST](context, {params, files}) {
    return new Promise(resolve => {
      ApiService.upload('/v1/app/contents/file', files, params, false).then(response => resolve(response))});
  },
  [ACT_GET_CTT_MST_LIST](context, params) {
    return new Promise(resolve => {
      ApiService.query('/v1/app/contents', params).then(response => resolve(response))});
  },

  [ACT_GET_ATCH_FILE_MANAGE_NO](context, params) {
    return new Promise(resolve => {
      ApiService.query('/v1/app/atch/mng', params).then(response => resolve(response))
    });
  },
  [ACT_DOWNLOAD_ATCH_FILE](context, {atchFileSn, fileName}) {
    ApiService.download('/v1/app/atch',atchFileSn,null, fileName)
  },
  [ACT_INSERT_CMS_ATCH_FILE](context, params) {
    return new Promise(resolve => {
      ApiService.post('/v1/app/atch', params).then(response => resolve(response))});
  },
  [ACT_UPLOAD_CMS_ATCH_FILE](context, {params, files}) {
    return new Promise(resolve => {
      ApiService.upload('/v1/app/atch/file', files, params, false).then(response => resolve(response))});
  },

  [ACT_INSERT_CTT_CMNT](context, params){
    return new Promise(resolve => {
      ApiService.post('/v1/app/ctt-cmnt', params).then(response => resolve(response))
    });
  },
  [ACT_UPDATE_CTT_CMNT](context, {cttCmntSn, params}){
    return new Promise(resolve => {
      ApiService.put(`/v1/app/ctt-cmnt/${cttCmntSn}`, params).then(response => resolve(response))
    });
  },
  [ACT_DELETE_CTT_CMNT](context, cttCmntSn){
    return new Promise(resolve => {
      ApiService.delete(`/v1/app/ctt-cmnt`,cttCmntSn).then(response => resolve(response))
    });
  },
  [ACT_GET_CTT_CMNT_LST](context, {lrnObjDtlDistSn, params}){
    return new Promise(resolve => {
      ApiService.query(`/v1/app/ctt-cmnt/${lrnObjDtlDistSn}/cmnts`, params).then(response => resolve(response))});
  },
  [ACT_UPLOAD_CMS_THUMBNAIL](context, {cttMngNo, fileList}) {
    return ApiService.upload(`/v1/app/contents/thumb/upload/${cttMngNo}`, fileList);
  },
  [ACT_GET_CTT_TRFM_STT](context, cttMstSn) {
    return new Promise(resolve => {
      ApiService.get(`/v1/app/contents/trfm/${cttMstSn}`,).then(response => resolve(response));
    });
  },
  [ACT_DELETE_CTT_MST](context, cttMstSn) {
    return new Promise(resolve => {
      ApiService.delete(`/v1/app/contents`, cttMstSn).then(response => resolve(response));
    });
  },
  [ACT_GET_STT_TEXT](context, cttMstSn) {
    return new Promise(resolve => {
      ApiService.get(`/v1/app/contents/stt/${cttMstSn}`,).then(response => resolve(response));
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};