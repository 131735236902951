<template>
  <div>
    <video ref="videoPlayer" style="width: 1238px;"></video>
  </div>
</template>
<script>

import 'video.js/dist/video-js.min.css';
import videojs from 'video.js';
import {computed, onBeforeUnmount, onMounted, ref} from "vue";

export default {
  name: 'SSLVideo',
  props: {
    videoSrc: String
  },
  setup(props) {
    const videoPlayer = ref(null);
    const player = ref(null);

    const options = computed(() => (
        {
          playsinline: true,
          loop: true,
          autoplay: true,
          muted: true,
          sources: [
            {
              src: props.videoSrc,
              type: "video/mp4"
            }
          ]
        }
    ));

    onMounted(() => {
      if(videoPlayer.value){
        player.value = videojs(videoPlayer.value, options.value);
      }
    })

    onBeforeUnmount(() => {
      if (player.value) {
        player.value.dispose()
      }
    });

    return {
      videoPlayer
    }

  }
}
</script>
