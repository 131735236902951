<template>
  <template v-if="isModalActive">
    <HrdCloudHistoryMobileDtl v-if="modals.historyDtlModal" v-model="modals.historyDtlModal" v-model:lrn-obj-dtl-dist-sn="lrnObjDtlDistSn" :year="yearStr" />
    <HrdCloudHistoryMobileAttendance v-if="modals.attendanceModal" v-model="modals.attendanceModal" />
    <HrdCloudHistoryMobileMileage v-if="modals.mlgModal" v-model="modals.mlgModal" :year="yearStr" />
    <HrdCloudHistoryMobileKbTube v-if="modals.kbTubeModal" v-model="modals.kbTubeModal" />
  </template>
  <template v-else>
    <HrdCloudMobileHeader title="My 클라우드" />
    <main class="kb-main" id="kb-hrdcloud">
      <div class="main-header">
        <template v-if="new Date().getFullYear() == 2023">
          <HistoryYearSelector :show-select-all="false" :yearCnt="1" v-model="yearStr"/>
        </template>
        <template v-else>
          <HistoryYearSelector :show-select-all="false" :yearCnt="2" v-model="yearStr"/>
        </template>
      </div>
      <div class="main-content main-component">
        <Total :year="yearStr" v-model:mlg-modal="modals.mlgModal" :focus-func="historyEvents"/>
        <BadgeList :year="yearStr" :is-mobile="true"/>
<!--        <MyLearnStatus :year="yearStr" :is-mobile="true"/>-->
<!--        <LearnRoutine :year="yearStr" :is-mobile="true"/>-->
        <FullHistory :year="yearStr" v-model="lrnObjDtlDistSn" v-model:target-ref="fullHistory" :is-mobile="true" :openDtlFunc="historyEvents.openHistoryDtlModal"/>
        <KBTube :year="yearStr" :func="historyEvents.openKbTubeModal" :is-mobile="true"/>
      </div>
    </main>
  </template>
</template>

<script>
import HrdCloudMobileHeader from '@/components/hrdcloud/mobile/HrdCloudMobileHeader';
import {computed, reactive, ref} from 'vue';
import HistoryYearSelector from '@/components/history/HistoryYearSelector';
import Total from '@/components/history/hrdcloud/Total';
import BadgeList from '@/components/history/hrdcloud/BadgeList';
// import MyLearnStatus from '@/components/history/hrdcloud/MyLearnStatus';
// import LearnRoutine from '@/components/history/hrdcloud/LearnRoutine';
import FullHistory from '@/components/history/hrdcloud/FullHistory';
import KBTube from '@/components/history/hrdcloud/KBTube';
import HrdCloudHistoryMobileDtl from '@/components/history/mobile/HrdCloudHistoryMobileDtl';
import HrdCloudHistoryMobileAttendance from '@/components/history/mobile/HrdCloudHistoryMobileAttendance';
import HrdCloudHistoryMobileMileage from '@/components/history/mobile/HrdCloudHistoryMobileMileage';
import HrdCloudHistoryMobileKbTube from '@/components/history/mobile/HrdCloudHistoryMobileKbTube';
import {showAdded, showShareList, showSubscribed} from '@/assets/js/modules/hrd/hrd-common';
import {useStore} from 'vuex';

export default {
  name: 'HistoryMobileHrdCloud',
  components: {
    HrdCloudHistoryMobileKbTube,
    HrdCloudHistoryMobileMileage,
    HrdCloudHistoryMobileAttendance,
    HrdCloudHistoryMobileDtl,
    KBTube,
    FullHistory,
    // LearnRoutine, MyLearnStatus,
    BadgeList, Total, HistoryYearSelector, HrdCloudMobileHeader},
  setup() {
    const store = useStore();
    const yearStr = ref(new Date().getFullYear());
    const addedModal = computed(() => store.state.hrdcloud.added.show);
    const isModalActive = computed(() => {
      if (modals.historyDtlModal) return true;
      else if (modals.attendanceModal) return true;
      else if (modals.mlgModal) return true;
      else if (modals.addModal) return true;
      else if (modals.kbTubeModal) return true;
      else if (addedModal.value) return true;
      else return false;
    });

    const modals = reactive({
      historyDtlModal:false,
      attendanceModal:false,
      mlgModal:false,
      addModal:false,
      kbTubeModal:false,
      previewModal:false
    });

    const fullHistory = ref(null);

    const lrnObjDtlDistSn = ref(0);

    const historyEvents = {
      fullHistory : () => {
        fullHistory.value.scrollIntoView({behavior: 'smooth'});
      },
      openAttendanceModal: () => {
        modals.attendanceModal = true;
      },
      openMlgModal: () => {
        modals.mlgModal = true;
      },
      openAddModal: () => {
        showAdded(yearStr);
      },
      openHistoryDtlModal: (key) => {
        lrnObjDtlDistSn.value = key;
        modals.historyDtlModal = true;
      },
      openKbTubeModal: () => {
        modals.kbTubeModal = true;
      },
      openSubscriptionModal: () => {
        showSubscribed();
      },
      openShareModal: ()=>{
        showShareList(yearStr);
      }
    };

    return {
      isModalActive,
      modals, yearStr, lrnObjDtlDistSn,
      historyEvents, fullHistory,
    }
  }
};
</script>