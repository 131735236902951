<template>
  <div class="kb-form-row">
    <div class="kb-form-column kb-form-column-title">
      <label class="kb-form-label">
        <strong class="kb-form-label-text">{{ title }}</strong>
      </label>
    </div>
    <div class="kb-form-column kb-form-dropdown-group">
      <div class="kb-form-dropdown" :class="{ 'is-active' : toggle }">
        <button class="kb-form-dropdown-btn" @click.stop="$emit('update:toggle', !toggle);" :disabled="disabled">
          <span class="text">{{ bgngValue || '날짜 선택' }}</span><i class="icon-arrow"></i>
        </button>
        <div :class="[toggle ? 'is-active' : 'is-inactive']" class="kb-form-period-date dropdown" data-id="dropdown-calendar-picker">
          <div class="dropdown-target" @click.stop>
            <div class="dropdown-box">
              <div class="calendar-picker-container">
                <div class="calendar-picker">
                  <div class="picker-body">
                    <div class="datepicker-header">
                      <button class="kb-btn-datepicker-nav kb-btn-datepicker-prev" @click="changeMonth(false)"><i class="icon-prev"></i></button>
                      <div class="title">{{ currentYearMonth }}</div>
                    </div>
                    <div class="datepicker-table datepicker-table-week">
                      <table>
                        <thead>
                        <tr>
                          <th>일</th>
                          <th>월</th>
                          <th>화</th>
                          <th>수</th>
                          <th>목</th>
                          <th>금</th>
                          <th>토</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(week, idx) in currentWeeks" :key="idx">
                          <td v-for="(day, i) in week" :key="`${idx}-${i}`">
                            <template v-if="day.month === currentMonth">
                              <button class="kb-btn-day"
                                      :class="{ 'is-active' : bgngValue === day.ymdformat || endValue === day.ymdformat }"
                                      @click.prevent.stop="handleSelectDate(day)">
                                <span class="text">{{ day.day }}</span></button>
                            </template>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="calendar-picker">
                  <div class="picker-body">
                    <div class="datepicker-header">
                      <div class="title">{{ nextYearMonth }}</div>
                      <button class="kb-btn-datepicker-nav kb-btn-datepicker-next" @click="changeMonth(true)"><i
                          class="icon-next"></i>
                      </button>
                    </div>
                    <div class="datepicker-table datepicker-table-week">
                      <table>
                        <thead>
                        <tr>
                          <th>일</th>
                          <th>월</th>
                          <th>화</th>
                          <th>수</th>
                          <th>목</th>
                          <th>금</th>
                          <th>토</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(week, idx) in nextWeeks" :key="idx">
                          <td v-for="(day, i) in week" :key="`${idx}-${i}`">
                            <template v-if="day.month === nextMonth">
                              <button class="kb-btn-day"
                                      :class="{ 'is-active' : bgngValue === day.ymdformat || endValue === day.ymdformat }"
                                      :disabled="isPastDay(day)"
                                      @click.prevent.stop="handleSelectDate(day)">
                                <span class="text">{{ day.day }}</span></button>
                            </template>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="kb-form-dropdown">
        <button class="kb-form-dropdown-btn" @click.stop="$emit('update:toggle', !toggle);" :disabled="disabled">
          <span class="text">{{ endValue || '날짜 선택' }}</span><i class="icon-arrow"></i>
        </button>
      </div>
    </div>
    <div class="kb-form-column column-last">
      <span class="subtext">{{ `${bgngValue}~${endValue}` }}</span>
    </div>
  </div>
</template>

<script>
import {computed, reactive, ref, toRefs, watch} from 'vue';
import {getCalendar, hyphenSeparatedToYmdStr, ymdStrToDate} from '@/assets/js/util';

export default {
  name: 'DatepickerRange',
  props: {
    bgngValue: String,
    endValue: String,
    toggle: Boolean,
    title: String,
    disabled: { type:Boolean, default: false },
  },
  emits: ['update:bgngValue', 'update:endValue', 'update:toggle'],
  setup(props, {emit}) {
    // eslint-disable-next-line no-unused-vars
    const {bgngValue, endValue, toggle} = toRefs(props);

    const currentDate = new Date();
    // 현재 연월일 데이트 피커 데이터
    const currentYear = ref(currentDate.getFullYear());
    const currentMonth = ref(currentDate.getMonth() + 1);
    const currentWeeks = computed(() => getCalendar(currentYear.value, currentMonth.value));
    const startDt = ref(''), endDt = ref('');
    const today = reactive({
      year: currentDate.getFullYear(),
      month: (currentDate.getMonth() + 1),
      day: currentDate.getDate(),
    });

    // 다음 달이 내년일 경우 연도+1 아니면 이번년도
    const getNextYear = computed(() => {
      if (currentMonth.value === 12) {
        return currentYear.value + 1;
      } else {
        return currentYear.value;
      }
    });

    // 다음달이 내년일 경우 1월 아니면 다음달
    const getNextMonth = computed(() => {
      if (currentMonth.value === 12) {
        return 1;
      } else {
        return currentMonth.value + 1;
      }
    });

    const nextYear = ref(getNextYear.value);
    const nextMonth = ref(getNextMonth.value);
    const nextWeeks = computed(() => getCalendar(getNextYear.value, getNextMonth.value));
    const currentYearMonth = computed(() => {
      if (currentMonth.value === 12) {
        return `${currentYear.value + 1}년 1월`;
      } else {
        return `${currentYear.value}년 ${currentMonth.value}월`;
      }
    });

    const nextYearMonth = computed(() => {
      if (currentMonth.value === 12) {
        return `${currentYear.value + 1}년 1월`;
      } else {
        return `${currentYear.value}년 ${currentMonth.value + 1}월`;
      }
    });

    // 다음, 이전 연 월 버튼 기능
    const changeMonth = (isNext) => {
      if (isNext) {
        if (currentMonth.value === 12) {
          currentYear.value++;
          currentMonth.value = 1;
        } else {
          currentMonth.value++;
        }

        if (nextMonth.value === 12) {
          nextYear.value++;
          nextMonth.value = 1;
        } else {
          nextMonth.value++;
        }
      } else {
        if (currentMonth.value === 1) {
          currentYear.value--;
          currentMonth.value = 12;
        } else {
          currentMonth.value--;
        }

        if (nextMonth.value === 1) {
          nextYear.value--;
          nextMonth.value = 12;
        } else {
          nextMonth.value--;
        }
      }
    };

    const init = () => {
      currentYear.value = currentDate.getFullYear();
      currentMonth.value = currentDate.getMonth() + 1;
      nextYear.value = getNextYear.value;
      nextMonth.value = getNextMonth.value;
    };

    const handleSelectDate = (date) => {
      if (!startDt.value) {
        startDt.value = date;
      } else if (startDt.value && ymdStrToDate(startDt.value.ymd) > ymdStrToDate(date.ymd) || startDt.value && endDt.value) {
        startDt.value = date;
        endDt.value = '';
      } else if (startDt.value && ymdStrToDate(startDt.value.ymd) < ymdStrToDate(date.ymd)) {
        endDt.value = date;
      }
    };

    const isPastDay = (date) => {
      if (currentDate < ymdStrToDate(date.ymd)) return false;
      return true;
    };

    watch(() => toggle.value, () => {
      if (toggle.value) {
        init();
      }
    });

    watch(() => [startDt.value, endDt.value], () => {
      emit('update:bgngValue', startDt.value.ymdformat);
      emit('update:endValue', endDt.value.ymdformat);

      if (startDt.value && endDt.value) {
        emit('update:toggle', !toggle.value);
      }
    });

    return {
      currentYear, currentMonth, currentWeeks, nextMonth, nextWeeks,
      today, startDt, endDt,
      currentYearMonth, nextYearMonth,
      changeMonth, isPastDay, handleSelectDate,
      hyphenSeparatedToYmdStr
    }
  }
};
</script>