<template>
  <div class="popup-container" :class="{ 'is-active' : modelValue }">
    <div class="popup" id="popup-wmpd-dtl">
      <div class="popup-inner" id="wmpd-print-area">
        <div class="popup-header">
          <h3 v-if="!isPrint" class="title">포트폴리오 등록/수정</h3>
          <h3 v-else class="title">{{otherInfo.year}}-{{otherInfo.seq}} 포트폴리오</h3>
        </div>
        <div v-if="isPrint" class="popup-section" style="size:A4 landscape; margin:0">
          <div class="kb-table kb-table-bordered-v2">
            <table class="w-312">
              <tr>
                <th>성명</th>
                <td>{{otherInfo.lrnerNm}}</td>
                <th>직원번호</th>
                <td>{{otherInfo.lrnerId}}</td>
                <th>소속</th>
                <td>{{otherInfo.deptNm}}</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="popup-section">
          <h4 class="section-header">{{portfolioTypeMap.pfa}}</h4>
          <div class="kb-table kb-table-bordered-v2 kb-form-fields">
            <table>
              <colgroup><col style="width:15%"/><col style="width:15%"/><col /><col style="width:15%"/></colgroup>
              <thead>
                <tr>
                  <th><span class="th-title">자산군</span></th>
                  <th><span class="th-title">상품코드</span></th>
                  <th><span class="th-title">상품명</span></th>
                  <th><span class="th-title">비중</span></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><span class="td-text">{{portfolioAstMap.S}}</span></td>
                  <td>
                    <input v-model="portfolio.pfaSKrcd" type="text" class="kb-form-control" placeholder="상품코드" disabled />
                  </td>
                  <td>
                    <div class="kb-form-search">
                      <input v-model="portfolio.pfaSItemName" type="text" class="kb-form-control" placeholder="상품을 선택하세요" readonly />
                      <div v-if="!isPrint">
                        <button class="kb-btn-search" @click="clickSearch('pfa','S')"><i class="icon-search" /></button>
                        <button class="kb-btn-delete me-6" @click="clickDelete('pfa','S')"><i class="icon-delete" /></button>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div v-if="!isPrint">
                      <select v-model="portfolio.pfaSRt"  class="kb-form-control kb-form-select">
                        <option v-for="n in 8" :key="n" :value="n*10">{{n*10}}%</option>
                      </select>
                    </div>
                    <div v-else><span>{{portfolio.pfaSRt}}%</span></div>
                  </td>
                </tr>
                <tr>
                  <td><span class="td-text">{{portfolioAstMap.B}}</span></td>
                  <td>
                    <input v-model="portfolio.pfaBKrcd" type="text" class="kb-form-control" placeholder="상품코드" disabled />
                  </td>
                  <td>
                    <div class="kb-form-search">
                      <input v-model="portfolio.pfaBItemName" type="text" class="kb-form-control" placeholder="상품을 선택하세요" readonly />
                      <div v-if="!isPrint">
                        <button class="kb-btn-search" @click="clickSearch('pfa','B')"><i class="icon-search" /></button>
                        <button class="kb-btn-delete me-6" @click="clickDelete('pfa','B')"><i class="icon-delete" /></button>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div v-if="!isPrint">
                      <select v-model="portfolio.pfaBRt"  class="kb-form-control kb-form-select">
                        <option v-for="n in 8" :key="n" :value="n*10">{{n*10}}%</option>
                      </select>
                    </div>
                    <div v-else><span>{{portfolio.pfaBRt}}%</span></div>
                  </td>
                </tr>
                <tr>
                  <td><span class="td-text">{{portfolioAstMap.E}}</span></td>
                  <td>
                    <input v-model="portfolio.pfaEKrcd" type="text" class="kb-form-control" placeholder="상품코드" disabled />
                  </td>
                  <td>
                    <div class="kb-form-search">
                      <input v-model="portfolio.pfaEItemName" type="text" class="kb-form-control" placeholder="상품을 선택하세요" readonly />
                      <div v-if="!isPrint">
                        <button class="kb-btn-search" @click="clickSearch('pfa','E')"><i class="icon-search" /></button>
                        <button class="kb-btn-delete me-6" @click="clickDelete('pfa','E')"><i class="icon-delete" /></button>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div v-if="!isPrint">
                      <select v-model="portfolio.pfaERt" class="kb-form-control kb-form-select">
                        <option v-for="n in 8" :key="n" :value="n*10">{{n*10}}%</option>
                      </select>
                    </div>
                    <div v-else><span>{{portfolio.pfaERt}}%</span></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="popup-section">
          <h4 class="section-header">{{portfolioTypeMap.pfb}}</h4>
          <div class="kb-table kb-table-bordered-v2 kb-form-fields">
            <table>
              <colgroup><col style="width:15%"/><col style="width:15%"/><col /><col style="width:15%"/></colgroup>
              <thead>
              <tr>
                <th><span class="th-title">자산군</span></th>
                <th><span class="th-title">상품코드</span></th>
                <th><span class="th-title">상품명</span></th>
                <th><span class="th-title">비중</span></th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td><span class="td-text">{{portfolioAstMap.S}}</span></td>
                <td>
                  <input v-model="portfolio.pfbSKrcd" type="text" class="kb-form-control" placeholder="상품코드" disabled/>
                </td>
                <td>
                  <div class="kb-form-search">
                    <input v-model="portfolio.pfbSItemName" type="text" class="kb-form-control" placeholder="상품을 선택하세요" readonly />
                    <div v-if="!isPrint">
                      <button class="kb-btn-search" @click="clickSearch('pfb','S')"><i class="icon-search" /></button>
                      <button class="kb-btn-delete me-6" @click="clickDelete('pfb','S')"><i class="icon-delete" /></button>
                    </div>
                  </div>
                </td>
                <td>
                  <div v-if="!isPrint">
                    <select v-model="portfolio.pfbSRt" class="kb-form-control kb-form-select">
                      <option v-for="n in 8" :key="n" :value="n*10">{{n*10}}%</option>
                    </select>
                  </div>
                  <div v-else><span>{{portfolio.pfbSRt}}%</span></div>
                </td>
              </tr>
              <tr>
                <td><span class="td-text">{{portfolioAstMap.B}}</span></td>
                <td>
                  <input v-model="portfolio.pfbBKrcd" type="text" class="kb-form-control" placeholder="상품코드" disabled />
                </td>
                <td>
                  <div class="kb-form-search">
                    <input v-model="portfolio.pfbBItemName" type="text" class="kb-form-control" placeholder="상품을 선택하세요" readonly />
                    <div v-if="!isPrint">
                      <button class="kb-btn-search" @click="clickSearch('pfb','B')"><i class="icon-search" /></button>
                      <button class="kb-btn-delete me-6" @click="clickDelete('pfb','B')"><i class="icon-delete" /></button>
                    </div>
                  </div>
                </td>
                <td>
                  <div v-if="!isPrint">
                    <select v-model="portfolio.pfbBRt" class="kb-form-control kb-form-select">
                      <option v-for="n in 8" :key="n" :value="n*10">{{n*10}}%</option>
                    </select>
                  </div>
                  <div v-else><span>{{portfolio.pfbBRt}}%</span></div>
                </td>
              </tr>
              <tr>
                <td><span class="td-text">{{portfolioAstMap.E}}</span></td>
                <td>
                  <input v-model="portfolio.pfbEKrcd" type="text" class="kb-form-control" placeholder="상품코드" disabled />
                </td>
                <td>
                  <div class="kb-form-search">
                    <input v-model="portfolio.pfbEItemName"  type="text" class="kb-form-control" placeholder="상품을 선택하세요" readonly />
                    <div v-if="!isPrint">
                      <button class="kb-btn-search" @click="clickSearch('pfb','E')"><i class="icon-search" /></button>
                      <button class="kb-btn-delete me-6" @click="clickDelete('pfb','E')"><i class="icon-delete" /></button>
                    </div>
                  </div>
                </td>
                <td>
                  <div v-if="!isPrint">
                    <select v-model="portfolio.pfbERt" class="kb-form-control kb-form-select">
                      <option v-for="n in 8" :key="n" :value="n*10">{{n*10}}%</option>
                    </select>
                  </div>
                  <div v-else><span>{{portfolio.pfbERt}}%</span></div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
<!--        <div class="popup-section">-->
<!--          <h4 class="section-header">{{portfolioTypeMap.pfc}}</h4>-->
<!--          <div class="kb-table kb-table-bordered-v2 kb-form-fields">-->
<!--            <table>-->
<!--              <colgroup><col style="width:15%"/><col style="width:15%"/><col /><col style="width:15%"/></colgroup>-->
<!--              <thead>-->
<!--              <tr>-->
<!--                <th><span class="th-title">자산군</span></th>-->
<!--                <th><span class="th-title">상품코드</span></th>-->
<!--                <th><span class="th-title">상품명</span></th>-->
<!--                <th><span class="th-title">비중</span></th>-->
<!--              </tr>-->
<!--              </thead>-->
<!--              <tbody>-->
<!--              <tr>-->
<!--                <td><span class="td-text">{{portfolioAstMap.S}}</span></td>-->
<!--                <td>-->
<!--                  <input v-model="portfolio.pfcSKrcd" type="text" class="kb-form-control" placeholder="상품코드" disabled />-->
<!--                </td>-->
<!--                <td>-->
<!--                  <div class="kb-form-search">-->
<!--                    <input v-model="portfolio.pfcSItemName"  type="text" class="kb-form-control" placeholder="상품을 선택하세요" readonly />-->
<!--                    <div v-if="!isPrint">-->
<!--                      <button class="kb-btn-search" @click="clickSearch('pfc','S')"><i class="icon-search" /></button>-->
<!--                      <button class="kb-btn-delete me-6" @click="clickDelete('pfc','S')"><i class="icon-delete" /></button>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </td>-->
<!--                <td>-->
<!--                  <div v-if="!isPrint">-->
<!--                    <select v-model="portfolio.pfcSRt"  class="kb-form-control kb-form-select">-->
<!--                      <option v-for="n in 2" :key="n" :value="n*10">{{n*10}}%</option>-->
<!--                    </select>-->
<!--                  </div>-->
<!--                  <div v-else><span>{{portfolio.pfcSRt}}%</span></div>-->
<!--                </td>-->
<!--              </tr>-->
<!--              <tr>-->
<!--                <td><span class="td-text">{{portfolioAstMap.B}}</span></td>-->
<!--                <td>-->
<!--                  <input v-model="portfolio.pfcBKrcd" type="text" class="kb-form-control" placeholder="상품코드" disabled />-->
<!--                </td>-->
<!--                <td>-->
<!--                  <div class="kb-form-search">-->
<!--                    <input v-model="portfolio.pfcBItemName" type="text" class="kb-form-control" placeholder="상품을 선택하세요" readonly />-->
<!--                    <div v-if="!isPrint">-->
<!--                      <button class="kb-btn-search" @click="clickSearch('pfc','B')"><i class="icon-search" /></button>-->
<!--                      <button class="kb-btn-delete me-6" @click="clickDelete('pfc','B')"><i class="icon-delete" /></button>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </td>-->
<!--                <td>-->
<!--                  <div v-if="!isPrint">-->
<!--                    <select v-model="portfolio.pfcBRt" class="kb-form-control kb-form-select">-->
<!--                      <option v-for="n in 2" :key="n" :value="(n+6)*10">{{(n+6)*10}}%</option>-->
<!--                    </select>-->
<!--                  </div>-->
<!--                  <div v-else><span>{{portfolio.pfcBRt}}%</span></div>-->
<!--                </td>-->
<!--              </tr>-->
<!--              <tr>-->
<!--                <td><span class="td-text">{{portfolioAstMap.E}}</span></td>-->
<!--                <td>-->
<!--                  <input v-model="portfolio.pfcEKrcd" type="text" class="kb-form-control" placeholder="상품코드" disabled />-->
<!--                </td>-->
<!--                <td>-->
<!--                  <div class="kb-form-search">-->
<!--                    <input v-model="portfolio.pfcEItemName" type="text" class="kb-form-control" placeholder="상품을 선택하세요" readonly />-->
<!--                    <div v-if="!isPrint">-->
<!--                      <button class="kb-btn-search" @click="clickSearch('pfc','E')"><i class="icon-search" /></button>-->
<!--                      <button class="kb-btn-delete me-6" @click="clickDelete('pfc','E')"><i class="icon-delete" /></button>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </td>-->
<!--                <td>-->
<!--                  <div v-if="!isPrint">-->
<!--                    <select v-model="portfolio.pfcERt" class="kb-form-control kb-form-select">-->
<!--                      <option v-for="n in 2" :key="n" :value="n*10">{{n*10}}%</option>-->
<!--                    </select>-->
<!--                  </div>-->
<!--                  <div v-else><span>{{portfolio.pfcERt}}%</span></div>-->
<!--                </td>-->
<!--              </tr>-->
<!--              </tbody>-->
<!--            </table>-->
<!--          </div>-->
<!--        </div>-->
      </div>
      <div class="popup-buttons mb-6" id="buttonBox">
        <button v-if="!isPrint" class="kb-btn kb-btn-lg kb-btn-primary" @click="clickSave"><strong class="text">저장</strong></button>
        <button v-else class="kb-btn kb-btn-lg kb-btn-primary" @click="printContent"><strong class="text">인쇄</strong></button>
<!--        <button v-else class="kb-btn kb-btn-lg kb-btn-primary" v-print="'#wmpd-print-area'"><strong class="text">인쇄</strong></button>-->
        <button class="kb-btn kb-btn-lg kb-btn-secondary" @click="closeModal"><strong class="text">닫기</strong></button>
      </div>
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
  </div>
  <search-fund-item-modal
      v-if="showSearchFundItem"
      v-model="showSearchFundItem"
      v-model:kr-code="searchFundItem.krCode"
      v-model:item-name="searchFundItem.itemName"
      :ast-div-cd="searchFundItem.astDivCd"
      :ast-div-nm="searchFundItem.astDivNm"
      :item-div-cd="searchFundItem.itemDivCd"
      :atdDivCd="searchFundItem.atdDivCd"
      :set-data-func="setFundItemFunc"/>
</template>

<script>
import {onMounted, reactive, ref} from "vue";
import {getResult, initParams, isSuccess, getItem, lengthCheck, setParams} from "@/assets/js/util";
import {useAlert} from "@/assets/js/modules/common/alert";
import SearchFundItemModal from "@/components/otherdept/wm/SearchFundItemModal";
import {
  ACT_GET_LAST_PORTFOLIO,
  ACT_GET_PORTFOLIO,
  ACT_INSERT_PORTFOLIO,
  ACT_UPDATE_PORTFOLIO
} from "@/store/modules/otherdept/wmportfolio";
// import {ACT_GET_USERS_INFO} from "@/store/modules/prep/prep";
import {useStore} from "vuex";


export default {
  name: "PortfolioPage",
  components: {SearchFundItemModal},
  props: {
    modelValue: Boolean,
    pfSeq: Number,
    isPrint: Boolean,
    atdDivCd: String,
    schdlSn: Number,
    func: Function,
  },
  emits: ['update:modelValue', 'update:pfSeq', 'update:isPrint'],
  setup(props, {emit}) {
    const { showMessage, showConfirm } = useAlert();
    const store = useStore();
    const portfolioTypeMap = {'pfa':'퇴직연금 추천상품','pfb':'온국민투자 추천상품'}; // ,'pfc':'안정추구형'
    const portfolioAstMap = {'S':'주식형','B':'채권형','E':'기타'};
    const portfolio = reactive({
      schdlSn: props.schdlSn,
      atdDivCd: props.atdDivCd,
      // 유형 A
      pfaSKrcd: '',
      pfaSItemName: '',
      pfaSRt: 0,
      pfaBKrcd: '',
      pfaBItemName: '',
      pfaBRt: 0,
      pfaEKrcd: '',
      pfaEItemName: '',
      pfaERt: 0,
      // 유형 B
      pfbSKrcd: '',
      pfbSItemName: '',
      pfbSRt: 0,
      pfbBKrcd: '',
      pfbBItemName: '',
      pfbBRt: 0,
      pfbEKrcd: '',
      pfbEItemName: '',
      pfbERt: 0,
      // // 유형 C
      // pfcSKrcd: '',
      // pfcSItemName: '',
      // pfcSRt: 0,
      // pfcBKrcd: '',
      // pfcBItemName: '',
      // pfcBRt: 0,
      // pfcEKrcd: '',
      // pfcEItemName: '',
      // pfcERt: 0,
    })
    const otherInfo = reactive({
      year: '',
      seq: 0,
      lrnerId: '',
      lrnerNm: '',
      deptCd: '',
      deptNm: '',
    })

    const showSearchFundItem = ref(false);
    const searchFundItem = ref({
      astDivCd: '',
      astDivNm: '',
      krCode: '',
      itemName: '',
      targetKrcd: '',
      targetItemName: '',
      itemDivCd: '',
      atdDivCd: props.atdDivCd,
    });

    const closeModal = () => {
      initParams(portfolio);
      initParams(otherInfo);
      emit('update:pfSeq', null);
      emit('update:isPrint', false);
      emit('update:modelValue', false);
    }

    const clickSearch = (type, ast) => {
      searchFundItem.value.astDivCd = ast;
      searchFundItem.value.astDivNm = portfolioAstMap[ast];
      searchFundItem.value.targetKrcd = type + ast + 'Krcd';
      searchFundItem.value.targetItemName = type + ast + 'ItemName';
      searchFundItem.value.itemDivCd = type;
      showSearchFundItem.value = true;
    }

    const setFundItemFunc = () => {
      portfolio[searchFundItem.value.targetKrcd] = searchFundItem.value.krCode;
      portfolio[searchFundItem.value.targetItemName] = searchFundItem.value.itemName;
    }

    const clickDelete = (type, ast) => {
      Object.keys(portfolio).forEach(key => {
        if(key.includes(type) && key.includes(ast) && !key.includes('Rt')){
          portfolio[key] = '';
        }
      })
    }

    const clickSave = async () => {
      // 전체 입력 확인
      let msg = '';
      await Object.keys(portfolio).forEach(key => {
        if(msg == '') {
          if (portfolio[key] == null || portfolio[key] == 0 || portfolio[key] == '') {
            // console.log('key : ' + key);
            msg += portfolioTypeMap[key.substring(0, 3)];
            msg += " " + portfolioAstMap[key.substring(3, 4)];
            msg += key.includes('Rt') ? " 비중을 선택하세요." : " 상품 정보를 선택하세요."
            showMessage(msg);
          }
        }
      });

      if(msg != '') return;

      // 비중 합계
      let pfaRtSum = portfolio.pfaSRt + portfolio.pfaBRt + portfolio.pfaERt;
      let pfbRtSum = portfolio.pfbSRt + portfolio.pfbBRt + portfolio.pfbERt;
      // let pfcRtSum = portfolio.pfcSRt + portfolio.pfcBRt + portfolio.pfcERt;

      if(pfaRtSum != 100) {
        showMessage(portfolioTypeMap.pfa + " 자산군 비율합은<br>100%가 되어야 합니다.");
      }else if(pfbRtSum != 100) {
        showMessage(portfolioTypeMap.pfb + " 자산군 비율합은<br>100%가 되어야 합니다.");
      // }else if(pfcRtSum != 100) {
      //   showMessage(portfolioTypeMap.pfc + " 자산군 비율합은 100%가 되어야 합니다.");
      }else{
        showConfirm("저장하시겠습니까?", savePortFolio);
      }
    }

    const savePortFolio = () => {

        if (props.pfSeq > 0) {
            store.dispatch(`wmportfolio/${ACT_UPDATE_PORTFOLIO}`, {pfSeq:props.pfSeq, params:portfolio}
            ).then(res => {
              if (isSuccess(res)) {
                showMessage('처리되었습니다.');
                props.func();
              } else {
                showMessage(getResult(res).message);
              }
            }).catch(e => {
              console.error(e);
              showMessage('오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
            });

        }else {
            store.dispatch(`wmportfolio/${ACT_INSERT_PORTFOLIO}`, portfolio
            ).then(res => {
              if (isSuccess(res)) {
                showMessage('처리되었습니다.');
                emit('update:pfSeq', res.pfSeq);
                props.func();
              } else {
                showMessage(getResult(res).message);
              }
            }).catch(e => {
              console.error(e);
              showMessage('오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
            });
        }
    }

    const getPortFolio = () => {
      store.dispatch(`wmportfolio/${ACT_GET_PORTFOLIO}`, props.pfSeq).then(res => {
        if (lengthCheck(res)) {
          setParams(portfolio,  getItem(res));
          setParams(otherInfo,  getItem(res));
        }
      }).catch(e => {
        console.error(e);
      });
    }

    const getLastPortFolio = () => {
      store.dispatch(`wmportfolio/${ACT_GET_LAST_PORTFOLIO}`).then(res => {
        if (lengthCheck(res)) {
          setParams(portfolio,  getItem(res));
          setParams(otherInfo,  getItem(res));
        }

        portfolio.schdlSn = props.schdlSn;
        portfolio.atdDivCd = props.atdDivCd;
        otherInfo.seq = 0;
        otherInfo.year = '';
      }).catch(e => {
        console.error(e);
      });
    }

    const printContent = () => {
      // const printablContent = document.getElementById('wmpd-print-area');
      // const printWindow = window.open('', '', 'height=1000, width=1000');
      // printWindow.document.write(printablContent.innerHTML);
      // printWindow.print();
      document.getElementById('buttonBox').style.display="none";
      window.print();
      document.getElementById('buttonBox').style.display="";
    }

    onMounted(() => {
      if(props.pfSeq > 0){
        getPortFolio();
      }else{ // 당해년도 마지막 등록회차 정보가 있을 경우 해당 내용으로 셋팅함.
        getLastPortFolio();
      }
    })

    return {
      portfolio,
      otherInfo,
      portfolioTypeMap,
      portfolioAstMap,
      showSearchFundItem,
      searchFundItem,
      closeModal,
      clickSearch,
      clickDelete,
      clickSave,
      setFundItemFunc,
      getLastPortFolio,
      printContent
    }
  }
}
</script>