<template>
  <div class="kb-nav-round-tabs" :class="{'is-active':showRoute}" data-tabs="fixed">
    <ul class="kb-nav-list">
      <li v-for="(category,idx) in categories" class="kb-nav-item" :key="idx">
        <template v-if="category.hrdCloudCateSn === 5">
          <template v-if="checkVice()">
            <a href="javascript:" class="kb-nav-link"
               :class="{'is-active':category.hrdCloudCateSn === modelValue}"
               @click="handleCategory1(category)"
            >
              {{ category.hrdCloudCateNm }}
            </a>
          </template>
        </template>
        <template v-else>
          <a href="javascript:" class="kb-nav-link"
             :class="{'is-active':category.hrdCloudCateSn === modelValue}"
             @click="handleCategory1(category)"
          >
            {{ category.hrdCloudCateNm }}
          </a>
        </template>
      </li>
    </ul>
  </div>
</template>

<script>
import {computed, ref} from 'vue';
import {getCateList} from '@/assets/js/modules/hrd/hrd-common';
import {lengthCheck} from '@/assets/js/util';
import {useStore} from 'vuex';
import {useRouter} from "vue-router";

export default {
  name: 'HrdCloudMobileRoute',
  props: {
    showRoute: Boolean,
    modelValue: Number,
    func: Function
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const session = computed(() => store.state.auth.session);
    const hrdCloudCateSn = ref(0),
          categories = ref([
            {
              hrdCloudCateNm: '홈',
              hrdCloudCateSn: 0,
              lvl: 1
            },
            {
              hrdCloudCateNm: '학습 로드맵',
              hrdCloudCateSn: -1,
              lvl: 1
            }
          ]);

    const getMainCategories = async () => {
      let res = await getCateList({lvl: 1});
      if (lengthCheck(res)) {
        categories.value = categories.value.concat(res.items);
      }
    };

    const handleCategory1 = (menu) => {
      if(menu.hrdCloudCateSn == -1){
        router.push({name: 'HrdCloudRoadmapMain'});
      }else{
        hrdCloudCateSn.value = menu.hrdCloudCateSn;
        props.func(menu.hrdCloudCateSn);
      }
    };

    const checkVice = () => {
      if (session.value) {
        if (session.value.vice) {
          return true;
        } else if (session.value.jbpsCd) {
          if (session.value.jbpsCd.indexOf('A') === 0 || session.value.jbpsCd.indexOf('B') === 0) {
            return true;
          }
        }
      }
      return false;
    };

    getMainCategories();
    return {
      categories,
      getMainCategories,
      handleCategory1,
      session,
      checkVice
    }
  }
};
</script>