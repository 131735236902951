<template>
  <LxpMobileHeader v-if="isActiveModal" title="프로필 사진 변경">
    <template v-slot:left>
      <div class="util util-back">
        <a class="util-actions util-actions-back" @click="closeModal">
          <i class="icon-history-back"></i>
        </a>
      </div>
    </template>
    <template v-slot:right>
      <div class="util">
        <button class="util-actions" :class="isLoading ? 'util-actions-silver':'util-actions-yellow'" @click="setCurrentProfileImg" :disabled="isLoading">저장</button>
      </div>
    </template>
  </LxpMobileHeader>
  <main v-if="isActiveModal" id="popup-mypage-z06">
    <div v-if="isLoading" class="position-fixed w-100 h-100 z-3 pt-6">
      <LoadingDiv />
    </div>
    <nav v-if="userProfileTyCdDcds.length > 0" class="kb-nav-round-tabs pb-1 mb-3">
      <ul class="kb-nav-list mt-1">
        <template v-for="code in userProfileTyCdDcds" :key="code.cd">
          <li v-if="isValidProfile(code.cd)" class="kb-nav-item">
            <a class="kb-nav-link" :class="{'is-active': activeProfileTy.cd == code.cd}" @click="activeProfileTy.cd = code.cd">{{code.cdNm}}</a>
          </li>
        </template>
      </ul>
    </nav>
    <div class="main-content min-component">
      <div class="content-section">
        <div class="image-list-container">
          <ul class="image-list">
            <li class="image-item">
              <div class="image-circle" :class="{ 'is-active': profileParam.empImgSn == 0 }" >
                <div class="image"><a @click="profileParam.empImgSn = 0" class="kb-btn-image"><img :src="defaultImgUrl" @error="setGDefaultImg" /></a></div>
              </div>
            </li>
            <li class="image-item" v-for="(item, idx) in empImgList" :key="idx">
              <div class="image-circle" :class="{ 'is-active': profileParam.empImgSn == item.empImgSn }" >
                <div class="image"><a @click="profileParam.empImgSn = item.empImgSn" class="kb-btn-image"><img :src="item.url" :alt="'프로필 이미지_' + idx" /></a></div>
                <button class="kb-btn-delete" @click="deleteProfileImage(item.empImgSn)" ><i class="icon-delete"></i></button>
              </div>
            </li>
            <li class="image-item">
              <div class="kb-form-upload">
                <label for="uploadFile" class="kb-form-upload-label">
                  <input type="file" class="kb-form-upload-input" id="uploadFile" accept="image/*" @change="newProfileImgFile" />
                  <i class="icon-add"></i><span class="text">직접등록</span>
                </label>
              </div>
            </li>
          </ul>
        </div>
        <div v-if="activeProfileTy.cd != mainProfileTyCdDcd" class="m-3">
          <div class="kb-form-fields kb-form-fields-v2">
            <div class="kb-form-row">
              <div class="kb-form-column">
                <label class="kb-form-label"><strong class="kb-form-label-text">{{activeProfileTy.cdNm}} 닉네임(필명)</strong></label>
              </div>
            </div>
            <div class="kb-form-row">
              <div class="kb-form-column">
                <input v-model="profileParam.lrnerNickNm" type="text" class="kb-form-control" :placeholder="session.deptNm + ' ' + session.lrnerNm" />
              </div>
            </div>
            <div class="kb-form-bottomtext text-start">
              <i class="icon-notice" />필명 설정 유의사항<br>
              - 필명 설정 시 {{activeProfileTy.cdNm}} 과정 내에서는 필명으로만 활동하게 됩니다. (성명/부점명 비노출)<br>
              - 필명 변경 시 기존 학습노트 및 활동 이력에도 변경된 필명이 적용됩니다.
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {myPortraitSetup} from "@/assets/js/modules/mypage/profile/my-portrait-setup";
import LxpMobileHeader from "@/views/layout/lxp/header/mobile/LxpMobileHeader.vue";
import LoadingDiv from "@/components/common/LoadingDiv.vue";

export default {
  name: 'MobileMyPortraitModal',
  components: {LxpMobileHeader, LoadingDiv},
  props: {
    userProfileTyCdDcd: String,
  },
  setup: myPortraitSetup
}
</script>