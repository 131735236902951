<template>
  <div class="popup" id="popup-answer-sheet">
    <!-- popup > popup-inner -->
    <div class="popup-inner">
      <form action="">
        <div class="popup-header">
          <h3 class="title">개인별 답안지</h3>
        </div>
        <!-- popup-inner > popup-content -->
        <div class="popup-content">
          <div class="sheet-contaeinr">
            <div class="sheet-meta">
              <dl class="metadata">
                <dt class="title">평가명</dt>
                <dd class="text">{{ evl.lrnEvlNm }}</dd>
                <dt class="title">응시자정보</dt>
                <dd class="text">{{ session.lrnerNm }}({{ session.lrnerId }})  | {{ session.deptNm }}</dd>
                <dt class="title">응시기간</dt>
                <dd class="text">{{ timestampToDateFormat(evl.evlExamBgngDt, 'yyyy.MM.dd hh:mm') }} - {{ timestampToDateFormat(evl.evlExamEndDt, 'yyyy.MM.dd hh:mm') }}</dd>
              </dl>
            </div>
            <div class="sheet-content">

              <article v-for="(question, index) in questions" class="content-group" :data-result="getCorrectText(index)" :key="index">
<!--                <h3 class="content-title">{{ index + 1 }}. {{question.qstnNm}}</h3>-->
                <div class="d-flex align-content-between">
                  <div><h3 class="content-title">{{ index + 1 }}.&nbsp;&nbsp;</h3></div>
                  <div style="padding-top: 2px; font-size: 14px" v-html="question.qstnNm"></div>
                </div>

                <template v-if="['2019003', '2019002', '2019001', '2019005'].includes(question.qstnTyCdDcd)">
                  <ul class="content-list">
                    <li v-for="(option, idx) in question.options" class="content-item" :class="{'is-active': isOptionAnswer(option, index), 'is-correct': (option.crctAnsYn === 'Y')}" :key="`${index}-${idx}`">
                      <p class="text">{{idx + 1}}. {{ option.optNm }}</p>
                    </li>
                  </ul>
                </template>
                <template v-else>
                  <div class="content-item" :class="{'is-active': isCorrect(index)}"><p class="text">{{getAnswer(index)}}</p></div>
                  <div class="content-bottom">
                    <p class="text">정답  | {{ question.crctAns }}</p>
                  </div>
                </template>
                <div v-if="question.qstnDesc" class="content-bottom">
                  <p class="text">해설  | {{ question.qstnDesc }}</p>
                </div>
              </article>
            </div>
          </div>
        </div>
      </form>
    </div>
    <!-- popup > popup-close -->
    <div class="popup-close">
      <button class="kb-btn-popup-close" @click="closeEvl"><i class="icon-popup-close">팝업닫기</i></button>
    </div>
  </div>
</template>

<script>

import {evlAnswerSetup} from '@/assets/js/modules/evaluation/evl-setup';

export default {
  name: "EvlAnswer",
  props: {
    evl: Object,
  },
  emits: ['closeEvl'],
  setup: evlAnswerSetup
}
</script>

<style scoped>

.lxp-layout .fw-bold {
  font-weight: 500 !important;
}

</style>

