<template>
  <template v-if="isMobile">
    <div v-if="series.length > 0" class="kb-player-menu" :class="{'is-active' : isActive}">
      <button class="kb-controller-actions" :class="{'kb-btn-series-close' : isActive}" @click="toggleSeries">시리즈
        <i :class="[isActive ? 'icon-series-close' : 'icon-series']"></i>
      </button>
    </div>
  </template>
  <template v-else>
    <div v-if="series.length > 0" class="player-menu" :class="{'is-active' : isActive}">
      <button class="kb-btn-menu" @click="toggleSeries"><i class="icon-menu"></i></button>
      <div class="playlist-container">
        <ul class="playlist">
          <template v-for="(item, idx) in series" :key="idx">
            <li v-if="item.objSn != thisObj" class="playlist-item">
              <router-link :to="{ name: 'HrdCloudContentBridge',params: {lrnObjDtlDistSn: item.lrnObjDtlDistSn} }">
                  <div class="video-thumbnail">
                    <div class="image">
                      <CommonImage :img-src="getThumbUrl(item.thumb)" />
                    </div>
                  </div>
                </router-link>
                <div class="badges">
                  <div v-if="item.cmptnYn == 'Y'" class="badge badge-blue"><span class="text">완료</span></div>
                </div>
                <div class="video-details">
                  <router-link :to="{ name: 'HrdCloudContentBridge',params: {lrnObjDtlDistSn: item.lrnObjDtlDistSn} }" class="video-title">{{ item.objNm }}</router-link>
                  <div class="metadata">
                    <span class="text">{{ item.inqCnt }}조회</span>
                    <span class="text">{{ secondToFormat(item.cttSz, 'hh:mm:ss') }}</span>
                  </div>
                </div>
            </li>
          </template>
        </ul>
        <button class="kb-btn-close" @click="toggleSeries"><i class="icon-close"></i></button>
      </div>
    </div>
  </template>
</template>

<script>
import {ACT_GET_HRDCLOUD_SERIES_LIST} from '@/store/modules/prep/prep';
import {getCheckItems, secondToFormat} from '@/assets/js/util';
import {useStore} from 'vuex';
import {onMounted, ref} from 'vue';
import CommonImage from '@/components/common/CommonImage';
import navigationUtils from "@/assets/js/navigationUtils";
import {getThumbUrl} from '@/assets/js/modules/course/course-common';

export default {
  name: 'HrdCloudSeries',
  components: {CommonImage},
  props: {
    isActive: {type: Boolean, default: false},
    crse: Object
  },
  emits: ['toggle'],
  setup(props, {emit}) {
    const store = useStore();
    const series = ref([]);
    const thisObj = ref([]);

    const getHrdCloudSeriesList = () => {
      store.dispatch(`prep/${ACT_GET_HRDCLOUD_SERIES_LIST}`,{
        cttSnLst: props.crse.cttSnLst
      }).then(res => {
        series.value = getCheckItems(res);
        thisObj.value = props.crse.objSn;
      }).catch(e => {
        console.error(e);
      })
    };


    const toggleSeries = () => {
      emit('toggle');
    };

    // watch(() => props.crse.cttSnLst, () => {
    //   console.log('watch series : ',props.crse.cttSnLst);
    //   getHrdCloudSeriesList();
    // });

    onMounted(() => {
      getHrdCloudSeriesList();
    });

    return {
      secondToFormat,
      series,
      toggleSeries,
      isMobile: navigationUtils.any(),
      getThumbUrl,
      thisObj
    }
  }
};
</script>
