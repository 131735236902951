<template>
  <div class="kb lxp-layout ssl-layout" id="kb" :data-bg="isHome ? 'fill' : ''">
    <vue-progress-bar/>
    <LxpHeader :effect-nav="touchBodyCount" :func="plusEffectNav" />

    <template v-if="isReady">
      <!-- begin::ssl-header -->
      <!-- end::ssl-header -->
      <!-- begin::ssl-body -->
      <main class="kb-main" id="kb-main" @click="plusEffectNav">
<!--        <SSLHeader2 :effect-nav="touchBodyCount"/>-->
        <div class="main-content" :class="{'main-component': isHome}" :id="mainId" style="background-color:#FFF !important;">
          <div :class="mainClass">
            <router-view/>
          </div>
        </div>

        <div class="page-scrolltop page-component" style="width: 144px; left: 90%">
          <a href="javascript:" class="page-scrolltop-btn" @click.stop="goTop">
            <i class="icon-scrolltop"></i>
          </a>
        </div>
      </main>

<!--      <SSLCalendar v-if="calendar.show"/>-->
      <SSLPopup/>
      <LxpFooter/>

    </template>
  </div>
</template>
<script>
import LxpFooter from '@/views/layout/lxp/footer/LxpFooter';
import SSLPopup from '@/components/ssl/common/SSLPopup';
import {sslApplyLayoutSetup} from '@/assets/js/modules/ssl/setup/ssl-layout-setup';
import LxpHeader from '@/views/layout/lxp/header/LxpHeader';
// import SSLHeader2 from '@/views/layout/ssl/header/SSLHeader2';

export default {
  name: 'SSLApplyLayout',
  components: {
    // SSLHeader2,
    LxpHeader,
    SSLPopup,
    LxpFooter
  },
  setup: sslApplyLayoutSetup
}
</script>
