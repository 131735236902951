<template>
  <section class="content-section">
    <header class="section-header">
      <div class="title-list">
        <h4 class="title" :class="{'is-active': isMode === 'schl'}" @click="changeMode('schl')">학비 신청이력</h4>
        <h4 class="title" :class="{'is-active': isMode === 'officer'}" @click="changeMode('officer')">부점장 학비 신청이력</h4>
      </div>
      <div class="sub-desc">
        <p class="total">총 {{ isMode === 'schl'? schlPaging.totalCount : ofcrPaging.totalCount }}건</p>
        <p class="cc">신청현황만 표시됩니다.</p>
      </div>
    </header>
    <template v-if="isMode==='schl'">
      <template v-if="!isSchlLoading && (schools && schools.length > 0)">
        <div class="section-content">
          <div v-for="(schl, idx) in schools" class="content-item history-list" :key="idx">
            <div class="content-item-header">
              <h5 class="item-title">{{ schl.schlNm }}</h5>
              <h5 v-if="schl.stt === '81'" class="item-state">승인요청중</h5>
              <h5 v-else-if="schl.stt === '00'" class="item-state">지급완료</h5>
              <h5 v-else-if="schl.stt === '78'" class="item-state">반려</h5>
            </div>
            <div class="meta-content">
              {{ schl.schlDivNm }} | {{ timestampToDateFormat(schl.regDt, 'yyyy.MM.dd') }} 신청 | {{ schl.costTuitFeeSn }} | 지원금 {{ schl.sprtAmt > 0 ? numberComma(schl.sprtAmt) : '-' }}
              <span v-if="schl.rtnYn=='Y' && schl.addAmtYn=='Y'">(환입 및 추가지원)</span>
              <span v-else-if="schl.rtnYn=='Y' && schl.addAmtYn!='Y'">(환입)</span>
              <span v-else-if="schl.rtnYn!='Y' && schl.addAmtYn=='Y'">(추가지원)</span>
            </div>
            <div class="kb-btn-actions">
              <template v-if="timestampToDateFormat(schl.regDt, 'yyyyMMdd') < '20230901'">
              <button class="kb-btn-content-item" @click="detailFunc('school', schl.costTuitFeeSn)">상세</button>
              </template>
              <template v-else>
                <button class="kb-btn-content-item" @click="detailFunc('school_202309', schl.costTuitFeeSn)">상세</button>
              </template>
            </div>
          </div>
          <div v-if="schlPaging.hasMore" class="content-item">
            <div class="kb-btn-more-container">
              <button class="kb-btn kb-btn-more" @click.stop="moreFunc(schlPaging)">신청이력 더보기</button>
            </div>
          </div>
        </div>
      </template>
      <div v-else-if="!isSchlLoading && (schools && schools.length === 0)" class="search-container">
        <div class="result-empty">
          <img src="../../../assets/lxp/images/common/img_empty.png" alt="">
          <p class="text">신청이력이 없습니다.</p>
        </div>
      </div>
      <LoadingDiv v-if="isSchlLoading"/>
    </template>
    <template v-else-if="isMode==='officer'">
      <template v-if="!isOfcrLoading && (officers && officers.length > 0)">
        <div class="section-content">
          <div v-for="(officer, idx) in officers" class="content-item history-list" :key="idx">
            <div class="content-item-header">
              <h5 class="item-title">{{ officer.eduInstNm }}</h5>
              <h5 v-if="officer.stt === '81'" class="item-state">승인요청중</h5>
              <h5 v-else-if="officer.stt === '00'" class="item-state">지급완료</h5>
              <h5 v-else-if="officer.stt === '78'" class="item-state">반려</h5>
            </div>
            <div class="meta-content">
              {{ timestampToDateFormat(officer.regDt, 'yyyy.MM.dd') }} 신청 | {{ officer.costOffcrSn }} | 지원금 {{ officer.sprtAmt > 0 ? numberComma(officer.sprtAmt) : '-' }} | 신청금 {{ officer.sprtExptAmt > 0 ? numberComma(officer.sprtExptAmt) : '-' }}
              <span v-if="officer.rtnYn=='Y' && officer.addAmtYn=='Y'">(환입 및 추가지원)</span>
              <span v-else-if="officer.rtnYn=='Y' && officer.addAmtYn!='Y'">(환입)</span>
              <span v-else-if="officer.rtnYn!='Y' && officer.addAmtYn=='Y'">(추가지원)</span>
            </div>
            <div class="kb-btn-actions">
              <button class="kb-btn-content-item" @click="detailFunc('officer', officer.costOffcrSn)">상세</button>
            </div>
          </div>
          <div v-if="ofcrPaging.hasMore" class="content-item">
            <div class="kb-btn-more-container">
              <button class="kb-btn kb-btn-more" @click.stop="moreFunc(ofcrPaging)">신청이력 더보기</button>
            </div>
          </div>
        </div>
      </template>
      <div v-else-if="!isOfcrLoading && (officers && officers.length === 0)" class="search-container">
        <div class="result-empty">
          <img src="../../../assets/lxp/images/common/img_empty.png" alt="">
          <p class="text">신청이력이 없습니다.</p>
        </div>
      </div>
      <LoadingDiv v-if="isOfcrLoading"/>
    </template>
  </section>
</template>

<script>
import {numberComma, timestampToDateFormat} from '@/assets/js/util';
import LoadingDiv from '@/components/common/LoadingDiv';
import {ref} from 'vue';

export default {
  name: 'SupportStatusMobileSchool',
  components: {LoadingDiv},
  props: {
    schools: Array,
    officers: Array,
    schlPaging: Object,
    ofcrPaging: Object,
    moreFunc: Function,
    isSchlLoading: Boolean,
    isOfcrLoading: Boolean,
    detailFunc: Function,
    modeFunc: Function
  },
  setup(props) {

    const isMode = ref('schl');

    const changeMode = (mode) => {
      isMode.value = mode;
      props.modeFunc(isMode.value);
    }

    const ofcrMoreFunc = (paging) => {
      props.moreFunc(paging, 'officer');
    };

    return {
      timestampToDateFormat, numberComma, ofcrMoreFunc,isMode,changeMode
    }
  }
};
</script>