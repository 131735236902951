<template>
  <main v-if="!isEvlMain" class="kb-main" id="kb-support">
    <!-- main-content -->
    <div class="main-content main-component">
      <div class="support-view-container">
        <div class="view-contents">
          <!-- content-section -->
          <section class="content-section">
            <header class="section-header">
              <h4 class="title">신청정보</h4>
            </header>
            <div class="section-content">
              <!-- kb-form-fields -->
              <div class="kb-form-fields kb-form-fields-v2 kb-form-fields-divider">
                <!--신청직원 정보-->
                <MobileApplicant />
                <div class="kb-form-field">
                  <InputCustomChainSelect
                      v-model:value1="param.lrnCostDivSn"
                      v-model:toggle1="toggles.lrnCostDivs"
                      title="평가구분 및 평가명"
                      :options1="lrnCostDivs"
                      sequence-key1="lrnCostDivSn"
                      name-key1="lrnCostDivNm"
                      placeholder1="평가구분을 선택해주세요"
                      :disabled="isReadOnly"
                      @update:toggle1="closeToggles(toggles, 'lrnCostDivs')"
                  />
                </div>

                  <div class="content-item">
                    <div class="kb-form-fields">
                      <!-- kb-form-item:자격증명 -->
                      <div class="kb-form-item">
                        <div class="kb-form-column kb-form-column-title">
                          <label class="kb-form-label">
                            <span class="kb-form-label-text" v-if="param.lrnCostDivSn == '4'">자격증명 검색</span>
                            <span class="kb-form-label-text" v-else>평가명 검색</span>
                          </label>
                        </div>
                        <div class="kb-form-column column-whole">
                          <input v-model="license.lrnCostFldNm" type="text" class="kb-form-control" placeholder="자격증명을 검색하세요" v-if="param.lrnCostDivSn == '4'">
                          <input v-model="license.lrnCostFldNm" type="text" class="kb-form-control" placeholder="평가명을 검색하세요" v-else>
                        </div>
                      </div>
                    </div>
                    <div class="kb-btn-actions">
                      <button class="kb-btn-content-item" @click="searchModalFunc">
                        <span v-if="param.lrnCostDivSn == '4'">자격증명 검색</span>
                        <span v-else>평가명 검색</span>
                      </button>
                    </div>
                  </div>

                <!-- kb-form-field:평가 응시일 -->
                <div class="kb-form-field">
                  <RowCustomDatepicker
                      v-model="param.evlExamDd"
                      v-model:toggle="toggles.evlExamDd"
                      title="평가응시일"
                      sequence-key="nm"
                      name-key="nm"
                      placeholder1="평가응시일을 선택해주세요"
                      :disabled="isReadOnly"
                      @update:toggle="closeToggles(toggles, 'evlExamDd')"
                  />
                </div>
                <!-- kb-form-field:성적 -->
                <template v-if="param.lrnCostDivSn < 4">
                  <template v-if="param.lrnCostFldSn == '106'">
                    <div class="kb-form-field">
                      <div class="kb-form-row">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">성적</span>
                        </label>
                      </div>
                      <div class="kb-form-row">LC: <input type="text" class="kb-form-control w-25" v-model="toeic.evlLcScore"  @input="numChk" /> / RC: <input type="text" class="kb-form-control w-25" v-model="toeic.evlRcScore"  @input="numChk" />
                      </div>
                    </div>
                  </template>
                  <template v-else-if="param.lrnCostFldSn == '243'">
                    <div class="kb-form-field">
                      <div class="kb-form-row">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">성적</span>
                        </label>
                      </div>
                      <div class="kb-form-column">Speaking : <input type="text" class="kb-form-control w-25" v-model="toeic.evlLcScore" @input="numChk" /> / Writing : <input type="text" class="kb-form-control w-25" v-model="toeic.evlRcScore"  @input="numChk" />
                      </div>
                    </div>
                    <div class="kb-form-field">
                      <div class="kb-form-row">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">등급</span>
                        </label>
                      </div>
                      <div class="kb-form-row">Speaking : <input type="text" class="kb-form-control w-25" v-model="toeic.evlSGrade"/> / Writing : <input type="text" class="kb-form-control w-25" v-model="toeic.evlWGrade" />
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="kb-form-field">
                      <LabelCustomInputText v-model="param.evlScore" class-lst="column-whole" title="성적" placeholder="성적을 입력해주세요(숫자만 가능)" :isNumber="true" :disabled="isReadOnly" />
                    </div>
                    <div class="kb-form-field">
                      <LabelCustomInputText v-model="param.evlGrade" class-lst="column-whole" title="등급" placeholder="등급이 있는 평가인 경우 입력해주세요" :disabled="isReadOnly" />
                    </div>
                  </template>
                  <!-- kb-form-field:성적 증명서 사본 -->
                  <div class="kb-form-field">
                    <MobileUpload
                        v-model="lrnCertFiles"
                        title="성적증명서"
                        placeholder="성적증명서를 등록해주세요"
                        btn-title="성적증명서 등록하기"
                        :extensions="extensions"
                        :max-qty="10"
                        sequence-key="proofDataSn"
                        :disabled="isReadOnly"
                    />
                  </div>
                </template>
                <template v-else-if="param.lrnCostDivSn === 4 && (param.lrnCostFldSn == '268' || param.lrnCostFldSn == '269')">
                  <div class="kb-form-field">
                    <LabelCustomInputText v-model="param.evlScore" class-lst="column-whole" title="성적" placeholder="성적을 입력해주세요(숫자만 가능)" :isNumber="true" :disabled="isReadOnly" />
                  </div>
                  <div  class="kb-form-field">
                    <InputCustomSelect v-if="param.lrnCostFldSn == '268'"
                        v-model:value1="param.evlGrade"
                        v-model:toggle1="toggles.gradePccp"
                        title="등급"
                        :options1="gradePccp"
                        sequence-key1="val"
                        nameKey1="nm"
                        placeholder1="등급을 선택해주세요"
                        :disabled="isReadOnly"
                        @update:toggle1="closeToggles(toggles, 'gradePccp')"
                    />
                    <InputCustomSelect v-else
                       v-model:value1="param.evlGrade"
                       v-model:toggle1="toggles.gradePcce"
                       title="등급"
                       :options1="gradePcce"
                       sequence-key1="val"
                       nameKey1="nm"
                       placeholder1="등급을 선택해주세요"
                       :disabled="isReadOnly"
                       @update:toggle1="closeToggles(toggles, 'gradePcce')"
                    />
                  </div>
                  <div class="kb-form-field">
                    <RowCustomDatepicker
                        v-model="param.lcsnAcqsDd"
                        v-model:toggle="toggles.lcsnAcqsDd"
                        :select-box="true"
                        title="자격증취득일"
                        placeholder1="자격증취득일을 선택해주세요"
                        :disabled="isReadOnly"
                        @update:toggle="closeToggles(toggles, 'lcsnAcqsDd')"
                    />
                  </div>
                  <div class="kb-form-field">
                    <MobileUpload
                        v-model="lrnCertFiles"
                        title="자격증 사본"
                        placeholder="자격증 사본을 등록해주세요"
                        btn-title="자격증 사본 등록하기"
                        sequence-key="proofDataSn"
                        :extensions="extensions"
                        :disabled="isReadOnly"
                        :max-qty="10"
                    />
                  </div>
                </template>
                <template v-else>
                  <div class="kb-form-field">
                  <RowCustomDatepicker
                      v-model="param.lcsnAcqsDd"
                      v-model:toggle="toggles.lcsnAcqsDd"
                      :select-box="true"
                      title="자격증취득일"
                      placeholder1="자격증취득일을 선택해주세요"
                      :disabled="isReadOnly"
                      @update:toggle="closeToggles(toggles, 'lcsnAcqsDd')"
                  />
                  </div>

                  <div class="kb-form-field">
                  <MobileUpload
                      v-model="lrnCertFiles"
                      title="자격증 사본"
                      placeholder="자격증 사본을 등록해주세요"
                      btn-title="자격증 사본 등록하기"
                      sequence-key="proofDataSn"
                      :extensions="extensions"
                      :disabled="isReadOnly"
                      :max-qty="10"
                  />
                  </div>
<!--                  <div class="kb-form-field">-->
<!--                  <LabelCustomInputText v-model="param.examFee" class-lst="column-whole" :is-number="true" :is-money="true" title="응시료" placeholder="응시료를 입력하세요" :disabled="isReadOnly" />-->
<!--                  </div>-->
                </template>
                <!-- kb-form-field:비용지원신청여부 -->
                <div class="kb-form-field">
                  <InputCustomSelect
                      v-model:value1="param.costAplyYn"
                      v-model:toggle1="toggles.costAplies"
                      title="비용지원신청여부"
                      :options1="costAplies"
                      sequence-key1="val"
                      nameKey1="nm"
                      placeholder1="비용지원신청여부를 선택해주세요"
                      :disabled="isReadOnly"
                      @update:toggle1="closeToggles(toggles, 'costAplies')"
                  />
                </div>
                <!-- kb-form-field:응시료 -->
                <div class="kb-form-field">
                  <LabelCustomInputText
                      v-if="param.costAplyYn === 'Y'"
                      v-model="param.examFee"
                      title="응시료"
                      placeholder="응시료를 입력하세요."
                      :disabled="isReadOnly"
                  />
                </div>
                <!-- //kb-form-field -->
              </div>
              <!-- //kb-form-fields -->
            </div>
          </section>

          <section class="content-section">
            <header class="section-header header-divider">
              <h4 class="title">지원정보</h4>
            </header>
            <div class="section-content">
              <div class="text-item-container">
                <!-- text-item -->
                <div class="text-item">
                  <div class="item-row row-contents">
                    <div class="item-column">
                      <h4 class="item-subtitle">지원 한도액</h4>
                      <div class="item-meta fsize-m">
                        <strong class="text">{{ numberComma(supportEvlYearlyLimit) }}</strong>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- text-item -->
                <div class="text-item">
                  <div class="item-row row-contents">
                    <div class="item-column">
                      <h4 class="item-subtitle">지원 누계액</h4>
                      <div class="item-meta fsize-m">
                        <strong class="text">{{ numberComma(sprtAmtInfo.sprtAmt+sprtAmtInfo.sprtExptAmt) }}</strong>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- text-item -->
                <div class="text-item">
                  <div class="item-row row-contents">
                    <div class="item-column">
                      <h4 class="item-subtitle">현재신청중 누계액</h4>
                      <div class="item-meta fsize-m">
                        <strong class="text">{{ numberComma(sprtAmtInfo.examFee) }}</strong>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- //text-item -->
              </div>
            </div>
          </section>

          <!-- content-section -->
          <MobilePayment
              v-if="isInit && param.costAplyYn === 'Y'"
              v-model="paymentInfo"
              v-model:card-kinds-toggle="toggles.cardKind"
              v-model:card-aprv-dt-toggle="toggles.cardAprvDt"
              v-model:files="receiptFiles"
              :disabled="isReadOnly"
              @update:modelValue="updatePaymentInfo(paymentInfo)"
          />

          <!-- content-section -->
<!--          <section class="content-section">
            <header class="section-header header-divider">
              <h4 class="title">지원정보</h4>
            </header>
            <div class="section-content">
              <div class="text-item-container">
                &lt;!&ndash; text-item &ndash;&gt;
                <div class="text-item">
                  <div class="item-row row-contents">
                    <div class="item-column">
                      <h4 class="item-subtitle">지원 한도액</h4>
                      <div class="item-meta fsize-m">
                        <strong class="text">960,000</strong>
                      </div>
                    </div>
                  </div>
                </div>
                &lt;!&ndash; text-item &ndash;&gt;
                <div class="text-item">
                  <div class="item-row row-contents">
                    <div class="item-column">
                      <h4 class="item-subtitle">지원신청 누계액</h4>
                      <div class="item-meta fsize-m">
                        <strong class="text">450,000</strong>
                      </div>
                    </div>
                  </div>
                </div>
                &lt;!&ndash; text-item &ndash;&gt;
                <div class="text-item">
                  <div class="item-row row-contents">
                    <div class="item-column">
                      <h4 class="item-subtitle">지원 누계액</h4>
                      <div class="item-meta fsize-m">
                        <strong class="text">450,000</strong>
                      </div>
                    </div>
                  </div>
                </div>
                &lt;!&ndash; //text-item &ndash;&gt;
              </div>
            </div>
          </section>-->

          <!-- content-section -->
<!--          <section class="content-section">
            <header class="section-header header-divider">
              <h4 class="title">결과등록</h4>
            </header>
            <div class="section-content">
              <div class="kb-form-fields kb-form-fields-v2">
                <div class="kb-form-field field-first">
                  <div class="kb-form-row">
                    <label class="kb-form-label">
                      <span class="kb-form-label-text">수료증</span>
                    </label>
                  </div>
                  <div class="kb-form-row row-filelist">
                    <div class="file-text">
                      <p class="text">교육 수료증.jpg</p>
                      <div class="close">
                        <span class="close-text">1.0MB</span><i class="icon-del"></i>
                      </div>
                    </div>
                  </div>
                  <div class="kb-form-row row-buttons">
                    <button class="kb-btn-content-item">수료증 등록하기</button>
                  </div>
                </div>
              </div>
            </div>
          </section>-->


          <section class="content-section" v-if="param.rtnYn=='Y' || param.addAmtYn=='Y'">
            <header class="section-header header-divider">
              <h4 class="title">환입 및 추가지원금액</h4>
            </header>
            <div class="section-content">
              <div class="kb-form-fields kb-form-fields-v2">
                <LabelInputText v-model="param.rtnAmt" title="환입금액"  :is-number="true" :is-money="true" :disabled="true" v-if="param.rtnYn=='Y'" />
              </div>
              <div class="kb-form-fields kb-form-fields-v2">
                <LabelInputText v-model="param.addAmt" title="추가지원금액"  :is-number="true" :is-money="true" :disabled="true" v-if="param.addAmtYn=='Y'" />
              </div>
            </div>
          </section>

          <section class="content-section" v-if="data == null">
            <UserAgreementMobile
                ref="usrAgrmntRef"
                v-model:user-agreement="userAgreement"
                @applyBtnEnable="applyBtnEnable"
            />
<!--            <header class="section-header">-->
<!--              <h4 class="title">증빙서류 제출시 유의사항</h4>-->
<!--            </header>-->
<!--            <div class="section-content">-->
<!--              <div class="content-item">-->
<!--                <div class="desc">-->
<!--                  <p>1. 자기주도학습비는 직원의 자기계발 및 업무능력향상을 위해 은행재원으로 지원되고 있습니다. 본 제도의 취지에 맞지 않는 부당수령사례(환불/대리수강/증명서위변조/사은품 수령 등) 확인시 소속부점통보, 자기주도학습비 지원제한 및 각종 연수 참가 제한 조치되며 사안이 중대한 경우 인사조치 될 수 있습니다. 상기 내용을 숙지 하였음을 확인합니다.-->
<!--                    <br><br>-->
<!--                    2. 신청내용 검증을 위한 담당부서의 자료제출 및 개인정보 조회에 동의합니다.-->
<!--                  </p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="content-item">-->
<!--              <label for="chk_agree_03" class="item-agree">-->
<!--                상기내용을 숙지하였으며 동의합니다.-->
<!--                <div class="kb-form-check">-->
<!--                  <input type="checkbox" v-model="userAgreement" class="kb-form-check-input" id="chk_agree_03" :value="true" />-->
<!--                </div>-->
<!--              </label>-->
<!--            </div>-->
          </section>
        </div>
        <MobileSupportButton
            :stt="data && data.stt"
            :aply-stt-cd-dcd="data && data.aplySttCdDcd"
            :apply-btn-enable="applyBtnEnable"
            :user-agreement="userAgreement"
            :apply="applyCheck"
            :cancel="cancel"
            :close-modal="closeModal"
        />
      </div>
    </div>
    <!-- //main-content -->
  </main>

  <MobileEvlSearch
      v-if="isEvlMain"
      v-model="isEvlMain"
      v-model:lrn-cost-div-sn="param.lrnCostDivSn"
      v-model:selected-value="license"
      :keyword="license.lcsnNm"
      @update:modelValue="changeFld"
  />
</template>

<script>
import {computed, onMounted, reactive, ref, watch} from 'vue';
import {
  applyBtnEnable,
  cancelSupport,
  checkUploadBinaries,
  getFileUploadFailResults,
  getLrnCostFld,
  getLrnCostTy,
  paymentDomain,
  paymentInfoValidation,
  proofDivCdDcds,
  saveSupport,
  setCostProof,
  showComplete,
  uploadCostproof,
  extensions, SUPPORT_EVL_YEARLY_LIMIT
} from '@/assets/js/modules/support/support-common';
import {closeToggles} from '@/assets/js/ui.init';
import {
  getItem,
  initParams,
  isSuccess,
  nullEmpty,
  numberOnlyChk,
  paramCheck,
  setParams,
  numberComma,
  lengthCheck
} from '@/assets/js/util';
import {
  ACT_DELETE_CANCEL_LRN_SPRT_EVL,
  ACT_INSERT_LRN_SPRT_EVL,
  ACT_UPDATE_LEARN_SUPPORT_EVALUATION,
  MUT_SHOW_MAIN,
  MUT_HIDE_EVL_MAIN, ACT_GET_EVL_SPRT_AMT_INFO, ACT_GET_EVL_LCSN_DUP_CHK
} from '@/store/modules/support/support';
import {useAlert} from '@/assets/js/modules/common/alert';
import MobileApplicant from "@/components/support/mobile/MobileApplicant";
import InputCustomChainSelect from "@/components/support/common/InputCustomChainSelect";
import MobileUpload from "@/components/support/common/MobileUpload";
import InputCustomSelect from "@/components/support/common/InputCustomSelect";
import LabelCustomInputText from "@/components/support/common/LabelCustomInputText";
import RowCustomDatepicker from "@/components/support/common/RowCustomDatepicker";
import LabelInputText from "@/components/support/common/LabelInputText";
import MobilePayment from "@/components/support/common/MobilePayment";
import {useStore} from "vuex";
import MobileSupportButton from "@/components/support/common/mobile/MobileSupportButton";
import UserAgreementMobile from "@/components/support/common/mobile/UserAgreementMobile";
import MobileEvlSearch from "@/components/support/mobile/MobileEvlSearch";
import {ACT_DIGITAL_REQUIREMENT_CHECK} from "@/store/modules/my/my";

export default {
  name: 'SupportEvalFeeMobileModal',
  components: {
    LabelInputText,
    RowCustomDatepicker,
    LabelCustomInputText,
    MobilePayment,
    InputCustomSelect,
    MobileUpload,
    InputCustomChainSelect,
    MobileApplicant,
    MobileSupportButton,
    UserAgreementMobile,
    MobileEvlSearch
  },
  props: {
    modelValue: Boolean,
    data: { type: Object, default: null },
    reList : Function
  },
  setup(props) { // , {emit}
    const store = useStore();
    const {showMessage, showLoading, hideLoading, showConfirm} = useAlert();
    const session = computed(() => store.state.auth.session);
    const userAgreement = ref(false), isInit = ref(false), isLoading = ref(false);
    const isReadOnly = computed(() => props.data && props.data.aplySttCdDcd === '2106006');
    const lrnCostDivs = ref([]), lrnCostFldLst = ref([]);
    const lrnCostFlds = computed(() => lrnCostFldLst.value.filter(x => x.lrnCostDivSn === param.lrnCostDivSn));
    const evlLocs = [{nm:'국내'}, {nm:'국외'}];
    const costAplies = [{nm:'신청', val:'Y'}, {nm:'신청 안함', val:'N'}];
    const gradePcce = [{nm:'1등급(LV.1)', val:'1'}, {nm:'2등급(LV.2)', val:'2'},{nm:'3등급(LV.3)', val:'3'},{nm:'4등급(LV.4)', val:'4'}];
    const gradePccp = [{nm:'1등급(LV.1)', val:'1'}, {nm:'2등급(LV.2)', val:'2'},{nm:'3등급(LV.3)', val:'3'},{nm:'4등급(LV.4)', val:'4'},{nm:'5등급(LV.5)', val:'5'}];
    const isEvlMain = ref(false);

    // const extensions = ref(['bmp','gif','jpe','jpeg','jpg','svg', 'png', 'tif']);

    const license = ref({
      lrnCostDivSn: null,
      lrnCostFldSn: null,
      lrnCostFldNm: null,
      lrnCostGrpCdDcd:null
    });

    const param = reactive({
      costEvlSn: 0,
      lrnCostDivSn: 0,
      lrnCostFldSn: 0,
      evlNm: '',
      evlExamDd: '',
      lcsnAcqsDd: '',
      evlGrade: '',
      evlScore:'',
      evlLoc: '',
      costAplyYn: '',
      examFee: '',
      sprtExptAmt: '',
      sprtAmt: '',
      rtnYn:'N',
      rtnAmt:0,
      addAmtYn:'N',
      addAmt:0,
      aprvrId: '',
      aprvrNm: '',
      aprvDt: ''
    });

    const supportEvlYearlyLimit = ref(SUPPORT_EVL_YEARLY_LIMIT);

    const sprtAmtInfo = reactive({
      examFee: 0,
      sprtExptAmt: 0,
      sprtAmt: 0,
    })

    const changeFld = () => {
      param.lrnCostFldSn = license.value.lrnCostFldSn;
    };

    const toeic = reactive({
      evlLcScore:'',
      evlRcScore:'',
      evlSGrade:'',
      evlWGrade:'',
    })

    const paymentInfo = reactive({...paymentDomain});

    const lrnCertFiles = ref({ files: [], removed: [], binaries: [] }), // 성적증명서 || 합격증 사본
          receiptFiles = ref({ files: [], removed: [], binaries: [] }); // 결제 영수증

    const toggles = reactive({
      cardKinds: false,
      cardAprvDt: false,
      lrnCostDivs: false,
      lrnCostFlds: false,
      evlExamDd: false,
      evlLocs: false,
      costAplies: false,
      lcsnAcqsDd: false,
    });

    const numChk = (e) => {
      if ( !numberOnlyChk( e.target.value) ) {
        showMessage('숫자입력만 가능합니다.');
        e.target.value = '';
        return false;
      }

    };

    const searchModalFunc = () => {
      isEvlMain.value = true;
      store.commit(`support/${MUT_HIDE_EVL_MAIN}`);
    }

    const closeModal = () => {
      if (props.reList instanceof Function) props.reList();
      initLocalParams();
      store.commit(`support/${MUT_SHOW_MAIN}`);
      // emit('update:modelValue',false);
    };

    const applyYnSelect = ref(false);
    const applyYn = ref(true);
    const paymentType = ref('card');

    const handleSelectBox = (yn) => {
      applyYn.value = yn;
      applyYnSelect.value = false;
    };

    const usrAgrmntRef = ref(null);
    const initLocalParams = () => {
      initParams(param);
      initParams(paymentInfo);
      initParams(lrnCertFiles.value);
      initParams(receiptFiles.value);
      // closeModal();
      userAgreement.value = false;
      if(props.data == null) {
        usrAgrmntRef.value.allUnChk();
      }
      isLoading.value = false;
    };

    const validate = () => {
      if (paramCheck(param.lrnCostDivSn)) {
        showMessage('평가 구분을 선택해주세요');
        return false;
      }
      if (paramCheck(param.lrnCostFldSn)) {
        showMessage('평가 분야를 선택해주세요');
        return false;
      }
      if (paramCheck(param.evlExamDd)) {
        showMessage('평가응시일을 선택해주세요');
        return false;
      }
      // if (paramCheck(param.evlLoc)) {
      //   showMessage('평가장소를 입력해주세요');
      //   return false;
      // }
      // 평가구분 == 외국어 or ICT
      if (param.lrnCostDivSn == 1 || param.lrnCostDivSn == 2) {
        if (paramCheck(param.evlScore)) {
          showMessage('성적을 입력해주세요');
          return false;
        }
        if (paramCheck(param.costAplyYn)) {
          showMessage('비용신청여부를 선택해주세요');
          return false;
        }
        // 성적사본 업로드
      } else if (param.lrnCostDivSn === 4) { // 평가구분 == DITY 인것만
        if (paramCheck(param.lcsnAcqsDd)) {
          showMessage('자격증취득일을 입력해주세요');
          return false;
        }
      }
      if (param.costAplyYn === 'Y') {

        if(supportEvlYearlyLimit.value-(sprtAmtInfo.sprtAmt+sprtAmtInfo.sprtExptAmt) <= 0){
          showMessage('이미 지원 한도액이 소진되어<br>비용지원이 불가능합니다.');
          param.costAplyYn = 'N';
          return false;
        }

        // 응시료
        if (paramCheck(param.examFee)) {
          showMessage('응시료를 입력해주세요');
          return false;
        }

        let result = paymentInfoValidation(paymentInfo);
        if (!result.isValid) {
          showMessage(result.txt);
          return result.isValid;
        }
      }
      // 결제 영수증
      return true;
    };

    const result = reactive({
      alreadyUse: '',
      useYn: '',
    });

    const applyCheck = async () => {
      // console.log("ajsiodsa", param.lrnCostDivSn); // IT는 4
      let confirmTxt = `해당 신청 건은<br> 직무 필수과정으로 인정되지 않습니다.`;
      // let applyRes = null;

      if(param.lrnCostFldSn == '268' || param.lrnCostFldSn == '269') { // 268 : PCCP / 269 : PCCE
        await store.dispatch(`my/${ACT_DIGITAL_REQUIREMENT_CHECK}`).then(res => {
          if (lengthCheck(res)) {
            setParams(result, getItem(res))
          }
          // menuView.value = 'overallResult';
        }).catch(e => {
          console.error(e);
        })
        console.log(result);
        if (result.alreadyUse == '0' && result.useYn == 'Y' && (param.lrnCostFldSn == '268' || param.evlGrade != 1)) {
          confirmTxt = `해당 신청 건은<br> 직무 필수과정으로 인정됩니다.`;
        }

        showConfirm({
          title: '직무 필수과정 안내',
          text: confirmTxt,
          callback: () => {
            apply();
          }
        })
      } else {
        apply();
      }
    };

    const apply = async () => {
      nullEmpty(param);
      nullEmpty(paymentInfo);
      if (param.lcsnAcqsDd && param.lcsnAcqsDd.indexOf('-') > -1) {
        param.lcsnAcqsDd = param.lcsnAcqsDd.replaceAll('-', '');
      }
      showLoading();
      let applyRes = null, lrnCertRes=null, receRes=null;
      let hasLrnCertFile = checkUploadBinaries(lrnCertFiles), hasReceiptFile = checkUploadBinaries(receiptFiles);

      if(param.lrnCostFldSn == '106'){// 토익 LC/RC점수
        param.evlGrade = toeic.evlLcScore+'_'+toeic.evlRcScore;
        param.evlScore = parseInt(toeic.evlLcScore)+parseInt(toeic.evlRcScore);
      }else if(param.lrnCostFldSn == '243') {// 토익 S+W
        param.evlGrade = toeic.evlLcScore+'_'+toeic.evlRcScore+'|'+toeic.evlSGrade+'_'+toeic.evlWGrade;
        param.evlScore = parseInt(toeic.evlLcScore)+parseInt(toeic.evlRcScore);
      }

      if (param.costEvlSn > 0) {
        applyRes = await saveSupport(isLoading, `support/${ACT_UPDATE_LEARN_SUPPORT_EVALUATION}`, {
          costEvlSn: param.costEvlSn,
          params: { ...param, ...paymentInfo,
            removedFiles: lrnCertFiles.value.removed.concat(receiptFiles.value.removed)
          }}, userAgreement, validate);
      } else {
        applyRes = await saveSupport(isLoading, `support/${ACT_INSERT_LRN_SPRT_EVL}`, {...param, ...paymentInfo}, userAgreement, validate);
        if (applyRes) {
          param.costEvlSn = applyRes.costEvlSn;
          // store.commit(`support/${MUT_SHOW_MAIN}`);
        }
      }

      if (param.costEvlSn > 0) {
        if (hasLrnCertFile) {
          lrnCertRes = await uploadCostproof({
            params: {costEvlSn: param.costEvlSn, proofDivCdDcd: proofDivCdDcds.PROOF_DIV_CD_EVL_TRANSCRIPT},
            files: lrnCertFiles.value.files
          });
        }
        if(hasReceiptFile) {
          receRes = await uploadCostproof({
            params: {costEvlSn: param.costEvlSn, proofDivCdDcd: proofDivCdDcds.PROOF_DIV_CD_EVL_RECEIPT},
            files: receiptFiles.value.files
          });
        }
      }

      // let failStr = getFileUploadFailResults({title: '성적증명서 또는 합격증 사본', res: lrnCertRes}, {title: '결제 영수증', res: receRes});

      let failed = getFileUploadFailResults(lrnCertRes, receRes);

      if (isSuccess(applyRes)) {
        showComplete(isLoading, applyRes, failed, ()=>{
          closeModal();
          // initLocalParams();
          // store.commit(`support/${MUT_SHOW_MAIN}`);
        });
      } // 위에 validate 함수가 있어서 else 조건 넣으면 안됨.
      hideLoading();
    };

    const updatePaymentInfo = (emitted) => {
      setParams(paymentInfo, emitted);
    };

    const tmpYear = ref('');

    watch(() => param.evlExamDd, () => {
      if (!paramCheck(param.evlExamDd)) {
        let strYear = param.evlExamDd.substring(0, 4);

        if (tmpYear.value == '' || tmpYear.value != strYear) {

          store.dispatch(`support/${ACT_GET_EVL_SPRT_AMT_INFO}`, strYear).then(res => {
            if (res.status && res.status.code == 200) {

              sprtAmtInfo.sprtAmt = getItem(res).sprtAmt;
              sprtAmtInfo.sprtExptAmt = getItem(res).sprtExptAmt;
              sprtAmtInfo.examFee = getItem(res).examFee;

            } else {
              sprtAmtInfo.examFee = 0;
              sprtAmtInfo.sprtExptAmt = 0;
              sprtAmtInfo.sprtAmt = 0;
            }
          });

        }
      }
    });

    watch(() => param.costAplyYn, () => {
      if(param.costAplyYn == "Y"){
        if (paramCheck(param.evlExamDd)) {
          showMessage('평가응시일을 선택해주세요');
          param.costAplyYn = "";
        }

        if(supportEvlYearlyLimit.value <= (sprtAmtInfo.sprtAmt+sprtAmtInfo.sprtExptAmt)){
          showMessage('이미 지원 한도액이 소진되어<br>비용지원이 불가능합니다.');
          param.costAplyYn = "N";
        }
        // else{
        //   if(supportEvlYearlyLimit.value-(sprtAmtInfo.sprtAmt+sprtAmtInfo.sprtExptAmt) < param.examFee){
        //     showMessage('지원가능한 한도금액이 초과되어<br>일부 금액만 지원이 가능합니다.');
        //   }
        // }

      }
    });

    watch(() => param.lrnCostDivSn, () =>{

      if (session.value.jbpsNm ==='사무직원' && (param.lrnCostDivSn !== 1 && param.lrnCostDivSn !== null)){
        showMessage("해당 구분 신청 대상자가 아닙니다.");
        param.lrnCostDivSn = null;
        return;
      }

      if(param.lrnCostDivSn != license.value.lrnCostDivSn) {
        license.value.lrnCostDivSn = param.lrnCostDivSn;
        license.value.lrnCostFldNm = '';
        param.lrnCostFldSn = null;
        license.value.lrnCostFldSn = null;
        license.value.lrnCostGrpCdDcd = null;
      }
    });

    watch(() => param.examFee, () => {
      param.sprtExptAmt = param.examFee;
    });

    watch( () => param.lrnCostFldSn, () => {
      if (param.lrnCostDivSn == 4 && param.lrnCostFldSn != null && license.value.lrnCostGrpCdDcd != '2110101' && props.data == null){

        store.dispatch(`support/${ACT_GET_EVL_LCSN_DUP_CHK}`, param.lrnCostFldSn).then(res => {
          if (res.status && res.status.code == 200) {
            // console.log('evlLcsnDupChk : ' + res.evlLcsnDupChk);
            if (res.evlLcsnDupChk != 'OK'){
              showMessage('이미 해당 건으로 신청중이거나 등재된 자격증입니다.');
              license.value.lrnCostFldSn = null;
              license.value.lrnCostFldNm = null;
              license.value.lrnCostGrpCdDcd = null;
              param.lrnCostFldSn = null;
              return;
            }
          } else {
            showMessage('신청중복체크중 오류');
            return false;
          }
        });
      }
    });

    getLrnCostTy({evlUseYn: 'Y'}, lrnCostDivs);
    getLrnCostFld({evlUseYn: 'Y'}, lrnCostFldLst);


    const initToeic = () => {
      toeic.evlLcScore = '';
      toeic.evlRcScore = '';
      toeic.evlSGrade = '';
      toeic.evlWGrade = '';
    };

    onMounted(() => {
      if (props.data != null) {
        setParams(license.value, props.data);
        setParams(param, props.data);
        initToeic();

        if(param.lrnCostFldSn == '106'){// 토익 LC/RC점수
          if(param.evlGrade.indexOf('_') > 0) {
            toeic.evlLcScore = param.evlGrade.substring(0, param.evlGrade.indexOf('_'));
            toeic.evlRcScore = param.evlGrade.substring(param.evlGrade.indexOf('_') + 1);
          }else{
            toeic.evlLcScore = param.evlGrade;
          }
        }else if(param.lrnCostFldSn == '243'){// 토익 S+W
          let scr = param.evlGrade.substring(0, param.evlGrade.indexOf('|'));
          let grd = param.evlGrade.substring(param.evlGrade.indexOf('|') + 1);

          toeic.evlLcScore = scr.substring(0, scr.indexOf('_'));
          toeic.evlRcScore = scr.substring(scr.indexOf('_')+1);
          toeic.evlSGrade = grd.substring(0, grd.indexOf('_'));
          toeic.evlWGrade = grd.substring(grd.indexOf('_')+1);

          // console.log('scr : '+scr+' / grd : '+grd);
          // console.log('evlLcScore : '+toeic.evlLcScore+' / evlRcScore : '+toeic.evlRcScore+' / evlSGrade : '+toeic.evlSGrade+' / evlWGrade : '+toeic.evlWGrade);
        }

        setCostProof(props.data.proofs, lrnCertFiles, proofDivCdDcds.PROOF_DIV_CD_EVL_TRANSCRIPT);
        setCostProof(props.data.proofs, receiptFiles, proofDivCdDcds.PROOF_DIV_CD_EVL_RECEIPT);
        setParams(paymentInfo, props.data);
        userAgreement.value = true;
      }
      isInit.value = true;
    });

    const cancel = () => {
      cancelSupport(ACT_DELETE_CANCEL_LRN_SPRT_EVL, param.costEvlSn,'평가비 지원', () => {
        // store.commit(`support/${MUT_SHOW_MAIN}`);
        closeModal();
      });
    }

    return {
      isInit, isReadOnly, lrnCostDivs, lrnCostFlds, evlLocs, costAplies,
      param, paymentInfo, toggles, lrnCertFiles, receiptFiles,
      applyYn, applyYnSelect, paymentType, userAgreement,
      handleSelectBox, applyBtnEnable, apply, applyCheck, closeToggles, closeModal,
      updatePaymentInfo, cancel, toeic, numChk, extensions, usrAgrmntRef,
      searchModalFunc, changeFld, license, isEvlMain,
      sprtAmtInfo, supportEvlYearlyLimit, numberComma,gradePcce, gradePccp
    }
  }
};
</script>