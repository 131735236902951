<template>
  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-support">
    <!-- main-header -->
    <LxpMobileHeader v-if="isLicenseMain" title="자격증 등록/지원 신청">
      <template v-slot:right>
        <div v-if="guideFile.length > 0 && !isLicense" class="util util-download">
          <a href="javascript:" class="util-actions util-actions-download" @click="isFileAtchListShow = true">
            첨부파일
            <i class="icon-download"></i>
          </a>
        </div>
      </template>
      <template v-slot:left>
        <div v-if="isLicense" class="util util-back">
          <a href="javascript:" class="util-actions util-actions-back" @click="goBack">
            <i class="icon-history-back"></i>
          </a>
        </div>
      </template>
    </LxpMobileHeader>
    <div v-if="!isLicense" class="main-header main-header-visual">
      <!-- main-header-nav -->
      <div class="header-snb">
        <nav class="snb">
          <ul class="snb-list">
            <li class="snb-item">
              <button :class="{ 'is-active' : !$route.query.view || 'acquisition'===$route.query.view }" @click="changeView('acquisition')">취득지원 안내</button>
            </li>
            <li class="snb-item">
              <button :class="{ 'is-active' : 'renewal'===$route.query.view }" @click="changeView('renewal')">갱신지원 안내</button>
            </li>
            <li class="snb-item">
              <button :class="{ 'is-active' : 'schedule'===$route.query.view }" @click="changeView('schedule')">자격증 시험일정</button>
            </li>
            <li class="snb-item">
              <button :class="{ 'is-active' : 'history'===$route.query.view }" @click="changeView('history')">자격증 보유현황</button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <!-- main-content -->
    <SupportLicenseAcquisitionMobileGuide v-if="!$route.query.view || $route.query.view==='acquisition'"/>
    <SupportLicenseMobileRenewal v-else-if="$route.query.view==='renewal'"/>
    <SupportLicenseExamMobileSchedule v-else-if="$route.query.view==='schedule'"/>
    <SupportLicenseMobileHistory v-else-if="$route.query.view==='history'"/>
    <!-- //main-content -->
  </main>
  <!-- end::kb-main -->
  <SupportMobileFileAtch v-if="isFileAtchListShow" v-model:isFileAtchListShow="isFileAtchListShow" :file-key="guideFile"/>

</template>

<script>
import {computed, ref, onMounted} from 'vue';
import SupportLicenseAcquisitionMobileGuide from '@/components/support/mobile/SupportLicenseAcquisitionMobileGuide';
import LxpMobileHeader from "@/views/layout/lxp/header/mobile/LxpMobileHeader";
import {downloadGuideFiles} from '@/assets/js/modules/support/support-common';
import SupportLicenseExamMobileSchedule from "@/components/support/mobile/SupportLicenseExamMobileSchedule";
import SupportLicenseMobileRenewal from "@/components/support/mobile/SupportLicenseMobileRenewal";
import SupportMobileFileAtch from "@/views/pages/support/mobile/SupportMobileFileAtch";
import SupportLicenseMobileHistory from "@/views/pages/support/mobile/SupportLicenseMobileHistory";
import {useRouter} from 'vue-router';
import {useStore} from "vuex";
import {MUT_CLOSE_SUPPORT, MUT_SHOW_MAIN} from "@/store/modules/support/support";

export default {
  name: 'SupportLicenseMobileMain',
  components: {
    SupportLicenseMobileHistory,
    SupportMobileFileAtch,
    LxpMobileHeader,
    SupportLicenseExamMobileSchedule,
    SupportLicenseMobileRenewal,
    SupportLicenseAcquisitionMobileGuide
  },
  setup() {

    const store = useStore();
    const isLicense = computed(() => store.state.support.isSupport);
    const isLicenseMain = computed(() => store.state.support.isLicenseMain);

    const view = ref('acquisition');
    const router = useRouter();
    const changeView = (change) => {
      view.value = change;
      router.push({query: {view: view.value}});
    }

    const guideFile = computed(() => downloadGuideFiles.find(x => x.page === 'license').fileKey);
    const isFileAtchListShow = ref(false);

    const goBack = () => {
      store.commit(`support/${MUT_CLOSE_SUPPORT}`);
    }

    onMounted(() => {
      store.commit(`support/${MUT_SHOW_MAIN}`);
    });

    return {
      view,
      guideFile,
      isFileAtchListShow, isLicense, isLicenseMain,
      changeView,
      goBack
    };
  }
};
</script>