import ApiService from "@/assets/js/api.service";

// ACTION
export const ACT_GET_MY_BADGE_LIST = 'actGetMyBadgeList';
export const ACT_GET_MY_SUBSCRIBE_LIST = 'actGetMySubscribeList';
export const ACT_GET_MY_PROFILE_LIST = 'actGetMyProfileList';
export const ACT_GET_MY_KEEP_LIST = 'actGetMyKeepList';

export const ACT_GET_JUNIOR_TRANING_MANDATORY_LCSN_LIST = 'actGetJuniorTraningMandatoryLcsnList';
export const ACT_GET_JUNIOR_TRANING_ELECTIVE_LCSN_LIST = 'actGetJuniorTraningElectiveLcsnList';
export const ACT_GET_MY_ABILITY_RESULT = 'actGetMyAbilityResult';
export const ACT_GET_MY_ABILITY_CREDIT_GRADE_LIST = 'actGetMyAbilityCreditGradeList';
export const ACT_GET_MY_ABILITY_CREDIT_FICJIKMU_LIST = 'actGetMyAbilityCreditFicjikmuList';
export const ACT_GET_MY_ABILITY_CREDIT_BANK_LIST = 'actGetMyAbilityBankList'
export const ACT_GET_MY_HISTORY_BEFORE_BANK_LIST = 'actGetMyHistoryBeforeBankList';
export const ACT_GET_MY_ABILITY_ETC_LSCN_LIST = 'actGetMyAbilityEtcLcsnList';
export const ACT_GET_MY_ABILITY_SELL_LSCN = 'actGetMyAbilitySellLcsn';
export const ACT_GET_MY_ABILITY_LSCN_LIST = 'actGetMyAbilityLscnList';
export const ACT_GET_MY_ABILITY_CREDIT_LIST = 'actGetMyAbilityCreditList';
export const ACT_GET_MY_ABILITY_NEW_CRSE_SUMUP = 'actGetMyAbilityNewCrseSumup';

export const ACT_GET_MY_PAGE_BANK_HISTORY_LIST = 'actGetMyPageBankHistoryList'

export const ACT_GET_ABILITY_RESULT = 'getAbilityResult';

export const ACT_DIGITAL_REQUIREMENT_CHECK = 'getDigitalRequirementCheck';

export const ACT_GET_ABILITY_BANK_LIST = 'getAbilityBankList';
export const ACT_GET_ABILITY_REQUIREMENT_LIST = 'getAbilityRequirementList';
export const ACT_GET_ABILITY_GRADE_LIST = 'getAbilityGradeList';
export const ACT_GET_ABILITY_REQSUBJ_LIST = 'getAbilityReqSubjList';
export const ACT_GET_PROFILE_IMG_URL = 'getProfileImageUrl';
export const ACT_GET_ALL_PROFILE_IMGS = 'getAllProfileImages';
export const ACT_UPLOAD_PROFILE_IMG = 'uploadProfileImage';
export const ACT_DELETE_PROFILE_IMG = 'deleteProfileImage';
export const ACT_GET_PROFILE_IMG_URL_BY_ID = 'getProfileImageUrlById';
export const ACT_GET_USER_PROFILE = 'getUserProfile';
export const ACT_SAVE_USER_PROFILE = 'saveUserProfile'
export const ACT_GET_USER_RECOMMEND_LSIT = 'getUserRecommendList';
export const ACT_GET_USER_WORK_PERIOD = 'getUserWorkPeriod';

// MUTATION
export const MUT_SHOW_MY_PROFILE_MAIN = 'showMyProfileMain';
export const MUT_HIDE_MY_PROFILE_MAIN = 'hideMyProfileMain';
export const MUT_SET_PROFILE_IMG_URL = 'setProflieImageUrl';
export const MUT_SET_ABILITY_REQSUBJ_LIST = "setAbilityReqsubjList";
export const MUT_TOGGLE_MY_PORTRAIT_MODAL = "toggleMyPortraitModal";

const state = {
    isMyProfileMain: true,
    profileImgUrl: '',
    AbilityReqSubjList : [],
    showMyPortraitModal: false,
};

const mutations = {
    [MUT_SHOW_MY_PROFILE_MAIN](state){
        state.isMyProfileMain = true;
    },
    [MUT_HIDE_MY_PROFILE_MAIN](state){
        state.isMyProfileMain = false;
    },
    [MUT_SET_PROFILE_IMG_URL](state, url) {
        state.profileImgUrl = url
    },
    [MUT_SET_ABILITY_REQSUBJ_LIST](state, subjList){
        state.AbilityReqSubjList = subjList;
    },
    [MUT_TOGGLE_MY_PORTRAIT_MODAL](state, value){
        if(value != undefined){
            state.showMyPortraitModal = value;
        }else{
            state.showMyPortraitModal = !state.showMyPortraitModal;
        }
    }
};

const actions = {

    [ACT_GET_MY_BADGE_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa031as', params).then(response => resolve(response))
        });
    },
    [ACT_GET_MY_SUBSCRIBE_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa032as', params).then(response => resolve(response))
        });
    },
    [ACT_GET_MY_PROFILE_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa033as', params).then(response => resolve(response))
        });
    },
    [ACT_GET_MY_KEEP_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/faz001', params).then(response => resolve(response))
        });
    },
    [ACT_GET_JUNIOR_TRANING_MANDATORY_LCSN_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/crse/works/junior/mandatory/lcsn', params).then(response => resolve(response))
        });
    },
    [ACT_GET_JUNIOR_TRANING_ELECTIVE_LCSN_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/crse/works/junior/elective/lcsn', params).then(response => resolve(response))
        });
    },
    [ACT_GET_MY_ABILITY_RESULT](context, ficjkwbh) {
        return new Promise(resolve => {
            ApiService.get('/v1/app/kbCdpJkwon/result', ficjkwbh).then(response => resolve(response))
        });
    },
    [ACT_GET_MY_ABILITY_CREDIT_GRADE_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/credit/grade/list', params).then(response => resolve(response))
        });
    },
    [ACT_GET_MY_ABILITY_CREDIT_FICJIKMU_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/credit/ficjikmu/list', params).then(response => resolve(response))
        });
    },
    [ACT_GET_MY_ABILITY_CREDIT_BANK_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/credit/bank/list', params).then(response => resolve(response))
        });
    },
    [ACT_GET_MY_HISTORY_BEFORE_BANK_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/history/before/bank/list', params).then(response => resolve(response))
        });
    },
    [ACT_GET_MY_ABILITY_ETC_LSCN_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/ability/etc/lcsns', params).then(response => resolve(response))
        });
    },
    [ACT_GET_MY_ABILITY_SELL_LSCN](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/ability/sell/lcsns', params).then(response => resolve(response))
        });
    },
    [ACT_GET_MY_ABILITY_LSCN_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/crse/works/ability/lcsn', params).then(response => resolve(response))
        });
    },
    [ACT_GET_MY_ABILITY_CREDIT_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/crse/works/ability/credit', params).then(response => resolve(response))
        });
    },
    [ACT_GET_MY_ABILITY_NEW_CRSE_SUMUP](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/ability/lrns/rslts', params).then(response => resolve(response))
        });
    },

    // 2023-01-01 이후
    [ACT_GET_ABILITY_RESULT]() {
        return new Promise(resolve => {
            ApiService.query('/v1/app/kbCdpJkwon/results', {}).then(response => resolve(response))
        });
    },
    [ACT_GET_ABILITY_BANK_LIST](context, cdpResultId) {
        return new Promise(resolve => {
            ApiService.query(`/v1/app/kbCdpJkwon/${cdpResultId}/banks`, {}).then(response => resolve(response))
        });
    },
    [ACT_GET_ABILITY_REQUIREMENT_LIST](context, {cdpResultId, params}) {
        return new Promise(resolve => {
            ApiService.query(`/v1/app/kbCdpJkwon/${cdpResultId}/reqs`, params).then(response => resolve(response))
        });
    },
    [ACT_DIGITAL_REQUIREMENT_CHECK]() {
        return new Promise(resolve => {
            ApiService.query('/v1/app/kbCdpJkwon/digital/reqs', {}).then(response => resolve(response))
        });
    },
    [ACT_GET_ABILITY_GRADE_LIST](context, cdpResultId) {
        return new Promise(resolve => {
            ApiService.query(`/v1/app/kbCdpJkwon/${cdpResultId}/grades`, {}).then(response => resolve(response))
        });
    },
    [ACT_GET_ABILITY_REQSUBJ_LIST](context, {cdpResultId, params}) {
        return new Promise(resolve => {
            ApiService.query(`/v1/app/kbCdpJkwon/${cdpResultId}/reqSubj`, params).then(response => resolve(response))
        });
    },
    [ACT_GET_PROFILE_IMG_URL](context, userProfileTyCdDcd) {
        return new Promise(resolve => {
            ApiService.get('/v1/app/emp_img/profile/src', userProfileTyCdDcd).then(response => resolve(response))
        });
    },
    [ACT_GET_PROFILE_IMG_URL_BY_ID](context, {userProfileTyCdDcd,lrnerId} ) {
        return new Promise(resolve => {
            ApiService.get('/v1/app/emp_img/profile/src/' + userProfileTyCdDcd, lrnerId).then(response => resolve(response))
        });
    },
    [ACT_GET_ALL_PROFILE_IMGS]() {
        return new Promise(resolve => {
            ApiService.query('/v1/app/emp_img/private/all', {}).then(response => resolve(response))
        });
    },
    [ACT_UPLOAD_PROFILE_IMG](context, files) {
        return ApiService.upload('/v1/app/emp_img/add', files, null, false)
    },
    [ACT_DELETE_PROFILE_IMG](context, empImgSn) {
        return new Promise(resolve => {
            ApiService.delete('/v1/app/emp_img/delete' , empImgSn).then(response => resolve(response))
        });
    },
    [ACT_GET_USER_PROFILE](context, userProfileTyCdDcd) {
        return new Promise(resolve => {
            ApiService.get('/v1/app/emp_img/profile', userProfileTyCdDcd).then(response => resolve(response))
        });
    },
    [ACT_SAVE_USER_PROFILE](context, params) {
        return new Promise(resolve => {
            ApiService.post('/v1/app/emp_img/profile', params).then(response => resolve(response))
        });
    },
    [ACT_GET_MY_PAGE_BANK_HISTORY_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/mypage/bank/history/list', params).then(response => resolve(response))
        });
    },
    [ACT_GET_USER_RECOMMEND_LSIT](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/users/recommend', params).then(response => resolve(response))
        });
    },
    [ACT_GET_USER_WORK_PERIOD](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/users/work', params).then(response => resolve(response))
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};

