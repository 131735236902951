<template>
  <div class="popup-container" :class="{ 'is-active' : modelValue }" > <!-- @click="closeToggles(toggles)" -->
    <div class="popup">
      <div class="popup-inner">
        <div class="popup-header"><h3 class="title">스케줄 등록</h3></div>
        <div class="popup-content">
          <div class="survey-container">

            <article class="popup-section section-form">
              <header class="section-header header-divider-v2"></header>
              <div class="section-content">
                <div class="kb-form-fields kb-form-fields-v2">
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <strong class="kb-form-label-text">년도-회차</strong>
                      </label>
                    </div>
                    <div class="kb-form-column">
                      {{ params.year }} - {{params.seq}}
                    </div>
                  </div>
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <strong class="kb-form-label-text">등록기간</strong>
                      </label>
                    </div>
                    <div class="kb-form-column kb-form-dropdown-group align-items-center">
                      <Datepicker
                          v-model="startYmd"
                          v-model:toggle="toggles.startYmd"
                          :disabled="isReadOnly"
                          :div="'S'"
                          @setYmd="setYmd"
                      />
                      <select v-model="params.startH"  class="kb-form-control kb-form-select" style="width:100px; float:left">
                        <option v-for="n in 24" :key="fillZero(n-1)" :value="fillZero(n-1)">{{fillZero(n-1)}}</option>
                      </select> <span class="vertical-m" style="line-height:70px;float:left">시  ~  </span>
                      <Datepicker
                          v-model="endYmd"
                          v-model:toggle="toggles.endYmd"
                          :disabled="isReadOnly"
                          :div="'E'"
                          @setYmd="setYmd"
                      />
                      <select v-model="params.endH"  class="kb-form-control kb-form-select" style="width:100px; float:left">
                        <option v-for="n in 24" :key="n-1" :value="fillZero(n-1)">{{fillZero(n-1)}}</option>
                      </select><span style="float:left">시</span>
                    </div>
                  </div>
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <strong class="kb-form-label-text">성적게시여부</strong>
                     </label>
                    </div>
                    <div class="kb-form-column kb-form-dropdown-group align-items-center">
                      <input
                        type="radio" class="kb-form-check-input" name="chk_01"
                        id="chk_01_01"
                        :checked="params.scoreYn==='Y'"
                        v-model="params.scoreYn"
                        value="Y"
                      />
                     <label for="chk_01_01" class="kb-form-check-label"><span class="text">게시</span></label>
                      <input
                          type="radio" class="kb-form-check-input" name="chk_01"
                          id="chk_01_02"
                          :checked="params.scoreYn==='N'"
                          v-model="params.scoreYn"
                          value="N"
                      />
                      <label for="chk_01_02" class="kb-form-check-label"><span class="text">미게시</span></label>
                    </div>
                  </div>
                </div>
                <div class="button-row text-center">
                  <button class="kb-btn kb-btn-primary kb-btn-lg m-2" @click="saveSchedule">
                    <span class="text" v-if="params.schdlSn==0">등록</span>
                    <span class="text" v-else>수정</span>
                  </button>
                  <button class="kb-btn kb-btn-silver kb-btn-text-silver kb-btn-lg m-2" @click="closeModal">
                    <span class="text">닫기</span>
                  </button>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
  </div>
</template>

<script>
import {getTodayDate, getResult, isSuccess} from '@/assets/js/util';
import {onMounted, reactive, ref} from 'vue';
import Datepicker from '@/components/otherdept/common/Datepicker';
import {ACT_GET_NEW_SEQ, ACT_GET_SCHEDULE, ACT_INSERT_SCHEDULE, ACT_UPDATE_SCHEDULE} from "@/store/modules/otherdept/wmportfolio";
import {useStore} from "vuex";
import {getItem, lengthCheck} from "@/assets/js/util";
import {useAlert} from "@/assets/js/modules/common/alert";


export default {
  name: "ScheduleInsert",
  props:{
    modelValue: Boolean,
    schdlSn: Number,
    func: Function,
  },
  emits: ['update:modelValue'],
  components:{
    Datepicker
  },
  setup(props, {emit}) {

    const store = useStore();

    const {showMessage, showConfirm} = useAlert();
    const params = reactive({
      year: getTodayDate('yyyy'),
      seq: 0,
      schdlSn: 0,
      startH: '00',
      endH: '23',
      scoreYn: 'N',
      atdDivCd: 'C',
    });

    const today = new Date();
    const startYmd = reactive({
          year: today.getFullYear(),
          month: today.getMonth()+1,
          day: today.getDate()
        }
    );

    const endYmd = reactive({
      year: today.getFullYear(),
      month: today.getMonth()+1,
      day: today.getDate()
        }
    );
    const setYmd=(div, y, m, d)=>{
      console.log('div : '+div);
      if(div === 'S'){
        startYmd.year = y;
        startYmd.month= m;
        startYmd.day = d;
      }else{
        endYmd.year = y;
        endYmd.month= m;
        endYmd.day = d;
      }
    }

    const toggles = reactive({
      startYmd: false,
      endYmd: false,
    });

    const closeModal = () => {
      emit('update:modelValue', false);
      props.func('list');
    };

    const fillZero = (n) =>{
      let val= n.toString();
      if(val.length < 2){
        val = "0"+val;
      }
      return val;
    }
    const isReadOnly = ref(false);

    const saveSchedule = () => {
      if (props.schdlSn > 0){
        showConfirm("수정하시겠습니까?", modiSchedule);
      }else{
        showConfirm("저장하시겠습니까?", regSchedule);
      }

    }

    const regSchedule = () => {
      store.dispatch(`wmportfolio/${ACT_INSERT_SCHEDULE}`, {
        seq: params.seq,
        startYmdh: startYmd.year+fillZero(startYmd.month)+fillZero(startYmd.day)+params.startH,
        endYmdh: endYmd.year+fillZero(endYmd.month)+fillZero(endYmd.day)+params.endH,
        scoreYn: params.scoreYn,
        atdDivCd: params.atdDivCd,
      }).then(res => {
        if (isSuccess(res)) {
          showMessage('일정이 등록되었습니다.');

          emit('schdlSn', res.schdlSn);
          getSchedule(res.schdlSn);
        } else {
          showMessage(getResult(res).message);
        }
      }).catch(e => {
        console.error(e);
        showMessage('오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
      });
    }

    const modiSchedule = () => {
      console.log(startYmd.year+' / '+startYmd.month+' / '+startYmd.day);
      console.log(endYmd.year+' / '+endYmd.month+' / '+endYmd.day);
      store.dispatch(`wmportfolio/${ACT_UPDATE_SCHEDULE}`, {
        schdlSn: props.schdlSn,
        startYmdh: startYmd.year+fillZero(startYmd.month)+fillZero(startYmd.day)+params.startH,
        endYmdh: endYmd.year+fillZero(endYmd.month)+fillZero(endYmd.day)+params.endH,
        scoreYn: params.scoreYn,
        atdDivCd: params.atdDivCd,
      }).then(res => {
        if (isSuccess(res)) {
          showMessage('수정되었습니다.');

          emit('schdlSn', res.schdlSn);
          getSchedule(res.schdlSn);
        } else {
          showMessage(getResult(res).message);
        }
      }).catch(e => {
        console.error(e);
        showMessage('오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
      });
    }

    const getSchedule = (sn) => {
      params.schdlSn = sn;
      store.dispatch(`wmportfolio/${ACT_GET_SCHEDULE}`,params.schdlSn).then(res => {
        if (lengthCheck(res)) {
          params.year = getItem(res).year;
          params.seq = getItem(res).seq;
          params.schdlSn = getItem(res).schdlSn;
          params.atdDivCd = getItem(res).atdDivCd;
          params.scoreYn = getItem(res).scoreYn;
          params.endH = getItem(res).endYmdh.substr(-2);
          params.startH = getItem(res).startYmdh.substr(-2);
          startYmd.year = getItem(res).startYmdh.substr(0,4);
          startYmd.month = getItem(res).startYmdh.substr(4, 2);
          startYmd.day = getItem(res).startYmdh.substr(6,2);
          endYmd.year = getItem(res).endYmdh.substr(0,4);
          endYmd.month = getItem(res).endYmdh.substr(4, 2);
          endYmd.day = getItem(res).endYmdh.substr(6,2);
        }else{
          showMessage('오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
        }
      }).catch(e => {
        console.error(e);
      });

    }

    onMounted(() => {
      if (props.schdlSn > 0){
        getSchedule(props.schdlSn);
      }else{

        store.dispatch(`wmportfolio/${ACT_GET_NEW_SEQ}`, "C").then(res => {
          if (lengthCheck(res)) {
            params.seq = getItem(res);
          }else{
            params.seq = 0;
          }
        }).catch(e => {
          console.error(e);
        });
      }
    });

    return {
      params, toggles, fillZero, isReadOnly, closeModal, startYmd, endYmd,
      setYmd, saveSchedule
    };
  }
}
</script>

<style scoped>

</style>