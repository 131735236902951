import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import {computed, reactive, ref} from "vue";
import {useAlert} from "@/assets/js/modules/common/alert";
import {
    ACT_GET_GROUP_BOARD_TRGT,
    ACT_GET_GROUP_LRN_BOARD_LIST,
    ACT_GET_GROUP_LRN_BOARD_STATUS,
    ACT_GET_GROUP_LRN_MTQ_ACPT_LIST,
    ACT_UPDATE_PROG_LOG_LRN_STATUS,
    MUT_SET_GROUP_DIST_CRSE_SN
} from "@/store/modules/board/board";
import {
    getItem,
    getItems,
    getTodayDate,
    isSuccess,
    lengthCheck,
    setParams,
    timestampToDateFormat
} from "@/assets/js/util";
import {entranceCloudNowOn} from "@/assets/js/modules/cloud/cloud-common";

export const groupStatusSetup = () => {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const distCrseSn = computed(() => route.params.distCrseSn);
    const session = computed(() => store.state.auth.session);

    const {showMessage} = useAlert();

    const isClassSubActive = ref(false);

    const groupItems = ref([]);

    const item = reactive({
        boardSn: 0,
        lrnPostSn: 0,
        lrnObjTyCd: '',
        objBgngDt: '',
        objEndDt: '',
        availTime: '',
        objNm: '',
        lrnObjDtlDistSn: 0,
        grpRoleCdDcd: '',
        progLogSn: 0,
        expsrBgngDt: '',
        expsrEndDt: ''
    });



    //임시
    const grpCpItems = ref([]);

    const moimTotalCnt = ref(0);


    // 러닝닭컴
    const getGroupCouponInfoList = () => {
        store.dispatch(`board/${ACT_GET_GROUP_LRN_MTQ_ACPT_LIST}`, route.params.distCrseSn).then(res => {

            if (lengthCheck(res)) {
                grpCpItems.value = getItems(res);
                let couponCnt = 24 - grpCpItems.value.length;

                if(couponCnt <= 24) {
                    for(let i = 0; i < couponCnt; i++){
                        grpCpItems.value.push({acptCnt: 0, acptYn: 'N'});
                    }

                    for(let j = 0; j < grpCpItems.value[0].acptCnt; j++) {
                        grpCpItems.value[j].acptYn = 'Y'
                    }
                }
            } else {
                grpCpItems.value = [];
            }
        }).catch(e => {
            console.error(e);
        });

    }

    // 그룹러닝 모임 일정 조회
    const getGroupLrnBoard = () => {
        store.dispatch(`board/${ACT_GET_GROUP_LRN_BOARD_LIST}`, distCrseSn.value).then(res => {
            if(lengthCheck(res)){
                setParams(item, getItem(res));
            }
        })
    };

    const currentTime = ref(new Date());

    /*
        (정책)
        - 학습시작/종료 여부와는 상관없이 22:00까지 노출
         (모임등록시에만 새 모임데이터로 노출)
    */

    /*
        오늘 날짜 기준 모임
        1.학습시작 Y, 학습종료 Y : (item.expsrBgngDt && item.expsrEndDt)
        2.학습시작 Y, 학습종료 N : (item.expsrBgngDt && !item.expsrEndDt)
        3.학습시작 N, 학습종료 N : (!item.expsrBgngDt && !item.expsrEndDt)
        오늘 22:00:00 보다 현재 시간이 작으면 true(등록 된 모임 노출), 오늘 22:00:00 보다 현재 시간이 크면 false(등록 된 모임 비노출)

        모임이 1개라도 등록 되어 있을 때
        해당 모임(시작일)이 오늘 보다 크면 true(미리 등록 한 모임), 해당 모임(시작일)이 오늘 보다 작으면 false(이전 모임)
        모임이 하나 라도 없을 때 모임 등록 버튼만 노출(현재 진행중인 모임이 없습니다.)
    */
    const existsBoard = computed(() => {
        const today = getTodayDate();
        const objBgngDt = timestampToDateFormat(item.objBgngDt, 'yyyyMMdd');

        if (today === objBgngDt) {
            return `${today}220000` >= getTodayDate('yyyyMMddhhmmss');
        }

        return item.lrnObjDtlDistSn > 0 && objBgngDt >= today;
    });

    const enterClass = (item) => {
        const params = {
            distCrseSn : distCrseSn.value,
            objDistSn: item.lrnObjDtlDistSn,
            objType: item.lrnObjTyCd,
            lrnerId: session.value.lrnerId
        }

        // 테스트 위해 S사번만 시간체크 안하고 입장하도록 임시 조치
        if(session.value.lrnerId.indexOf('S017') > -1){
            entranceCloudNowOn(params)
        }else{
            if(timestampToDateFormat(item.objBgngDt, 'hhmm') === '1800' || timestampToDateFormat(item.objBgngDt, 'hhmm') === '1810') {
                if(timestampToDateFormat(currentTime.value.getTime(), 'hhmm') >= '1800') {
                    entranceCloudNowOn(params)
                } else {
                    showMessage('러닝룸 입장은 18시 이후부터 가능합니다.');
                }
            } else if(item.availTime <= currentTime.value.getTime()){
                entranceCloudNowOn(params)
            } else {
                showMessage('러닝룸 입장은 모임 시작 20분 전부터 가능합니다.');
            }
        }

    };

    const clickClassSub = () => {
        isClassSubActive.value = !isClassSubActive.value;
    };

    const clickClassEdit = (boardSn, lrnPostSn) => {
        store.commit(`board/${MUT_SET_GROUP_DIST_CRSE_SN}`, distCrseSn.value);
        router.push({name: 'GroupClassEdit', params: {boardSn: boardSn, lrnPostSn: lrnPostSn}});
    }

    // 멤버현황
    const getMemberInfoList = () => {
        store.dispatch(`board/${ACT_GET_GROUP_BOARD_TRGT}`, route.params.distCrseSn).then(res => {
            if (lengthCheck(res)) {
                groupItems.value = getItems(res);
                const isGroupMember = groupItems.value.filter(x => x.lrnerId === session.value.lrnerId);
                if(isGroupMember.length === 0) {
                    showMessage({
                        text:'잘못 된 접근입니다.',
                        callback: () => {
                            router.push({name: 'GroupLearning'});
                        }
                    })
                }
            } else {
                groupItems.value = [];
            }
        }).catch(e => {
            console.error(e);
        })
    }

    const statusItem = ref({});
    const getGroupLrnBoardStatus = () => {
        store.dispatch(`board/${ACT_GET_GROUP_LRN_BOARD_STATUS}`, route.params.distCrseSn).then(res => {
            if(lengthCheck(res)){
                statusItem.value = getItem(res);
            }
        }).catch(e => {
            console.error(e);
        });
    }

    const lrnStatusUpdate = (boardSn, item) => {
        let params = {
            lrnObjDtlDistSn: item.lrnObjDtlDistSn,
            eventCdDcd: '2064003',
            stt: '99'
        }
        store.dispatch(`board/${ACT_UPDATE_PROG_LOG_LRN_STATUS}`,params)
            .then((res) => {
                if(isSuccess(res)){
                    showMessage('학습종료가 취소되었습니다.')
                }else{
                    showMessage('종료된 학습이 아닙니다.');
                }
            }).catch(e => {
            console.error(e)
            showMessage('서버처리 중 일시적인 장애가 발생하였습니다.<br>잠시 후 다시 시도 해 주시기 바랍니다.');
        })
    }

    getMemberInfoList();
    getGroupLrnBoard();
    getGroupCouponInfoList();
    getGroupLrnBoardStatus();

    return {
        grpCpItems,
        item,
        groupItems,
        statusItem,
        isClassSubActive,
        existsBoard,
        moimTotalCnt,

        timestampToDateFormat,
        enterClass,
        clickClassSub,
        clickClassEdit,
        lrnStatusUpdate,
        getTodayDate,
    }
}