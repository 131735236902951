<template>
  <div class="popup-container" :class="{ 'is-active' : modelValue }">
    <div class="popup" id="popup-wmpd-result">
      <div class="popup-inner">

        <div class="popup-header">
          <h3 class="title">WM 동영상 학습 실적</h3>
        </div>
        <p v-if="searchInfo!=null && lrnerId!=''" class="font-body16B color-red">조회대상 : {{searchInfo.lrnerNm}}({{searchInfo.lrnerId}}/{{searchInfo.jbgdNm}}/{{searchInfo.deptNm}})</p>
        <div class="popup-section">
           <div class="kb-table kb-table-bordered-v2">
            <table>
              <colgroup>
                <col style="width:auto"/>
                <col style="width:12%"/>
                <col style="width:12%"/>
                <col style="width:15%"/>
              </colgroup>
              <thead>
                <tr>
                  <th><span class="th-title">학습분야</span></th>
                  <th><span class="th-title">총 동영상 수</span></th>
                  <th><span class="th-title">완료 동영상 수</span></th>
                  <th><span class="th-title">총 학습시간</span></th>
                </tr>
              </thead>
              <tbody>
                <template v-if="studyList.length > 0">
                  <template v-for="(item, index) in studyList" :key="index">
                    <template v-if="item.cttTitle=='소계'">
                      <tr>
                        <td><span class="td-text">{{item.cateNm}}  <span :id="'cateMP_'+item.cateSn" @click="openDetail(item.cateSn, item.cnt);" style="cursor:pointer">[+]</span>
                          <span :id="'cateMM_'+item.cateSn" @click="closeDetail(item.cateSn, item.cnt);" style="cursor:pointer;display:none">[-]</span></span></td>
                        <td><span class="td-text">{{item.cnt}}</span></td>
                        <td><span class="td-text">{{item.endCnt}}</span></td>
                        <td><span class="td-text">{{item.viewSzNm}}</span></td>
                      </tr>
                    </template>
                    <template v-else-if="item.cttTitle=='총계'">
                      <tr>
                        <td style="background-color:rgba(255, 245, 194, 1)"><span class="th-title">{{item.cttTitle}}</span></td>
                        <td style="background-color:rgba(255, 245, 194, 1)"><span class="td-text">{{item.cnt}}</span></td>
                        <td style="background-color:rgba(255, 245, 194, 1)"><span class="td-text">{{item.endCnt}}</span></td>
                        <td style="background-color:rgba(255, 245, 194, 1)"><span class="td-text">{{item.viewSzNm}}</span></td>
                      </tr>
                    </template>
                    <template v-else>
                      <tr :id="'cate_'+item.cateSn+'_0'" v-if="item.sortOrd=='1'" style="display: none">
                        <th colspan="2" style="background-color:rgba(222, 222, 222, 1)"><span class="th-title">[{{item.cateNm}}]콘텐츠명{{item.sortOrd}}</span></th>
                        <th style="background-color:rgba(222, 222, 222, 1)"><span class="th-title">완료여부</span></th>
                        <th style="background-color:rgba(222, 222, 222, 1)"><span class="th-title">학습시간</span></th>
                      </tr>
                      <tr :id="'cate_'+item.cateSn+'_'+item.sortOrd" style="display: none">
                        <td colspan="2" style="text-align:left; padding-left:50px;background-color: rgba(227, 227, 227, 1)"><span class="td-text">{{item.sortOrd}}.{{item.cttTitle}}</span></td>
                        <td style="background-color: rgba(227, 227, 227, 1)"><span class="td-text" v-if="item.viewSzNm == ''">N</span>
                          <span class="td-text" v-if="item.viewSzNm != ''">Y</span></td>
                        <td style="background-color: rgba(227, 227, 227, 1)"><span class="td-text">{{item.viewSzNm}}</span></td>
                      </tr>
                    </template>
                  </template>
                </template>
              </tbody>
            </table>
          </div>
        </div>

      </div>

      <div class="popup-buttons mb-6">
        <button class="kb-btn kb-btn-lg kb-btn-secondary" @click="closeModal"><strong class="text">닫기</strong></button>
      </div>
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>

    </div>
  </div>
</template>

<script>

import {ref, computed} from "vue";
import {ACT_GET_HRD_CLOUD_STUDY_LIST} from "@/store/modules/otherdept/wmportfolio";
import {getItems, lengthCheck} from "@/assets/js/util";
import {useStore} from "vuex";

export default {
  name: "CloudContentStudy",
  props: {
    modelValue: Boolean,
    searchId:String
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {

      const store = useStore();
      const searchInfo = ref(null);
      const lrnerId = computed(()=> props.searchId);

      const closeModal = () => {
        emit('update:modelValue', false);
      }

      const studyList = ref([]);
      const getStudyList = () => {
        store.dispatch(`wmportfolio/${ACT_GET_HRD_CLOUD_STUDY_LIST}`, {lrnerId:lrnerId.value}).then(res => {
          if (lengthCheck(res)) {
            studyList.value = getItems(res);
          }else{
            studyList.value = [];
            searchInfo.value = res.lrner;
          }

          if(res.lrner){
            searchInfo.value = res.lrner;
          }else{
            searchInfo.value = null;
          }

        }).catch(e => {
          console.error(e);
        });
      };

      getStudyList();

      const openCateSn = ref('');
      const openCateCnt = ref(0);

      const openDetail = (sn, cnt) => {
        if (openCateCnt.value > 0){
          closeDetail(openCateSn.value, openCateCnt.value);
        }
        openCateSn.value = sn;
        openCateCnt.value = cnt;
        document.getElementById('cateMP_'+sn).style.display = 'none';
        document.getElementById('cateMM_'+sn).style.display = '';
        for(let i=0; i < cnt+1; i++){
          document.getElementById('cate_'+sn+'_'+i).style.display = '';
        }
      }

      const closeDetail = (sn, cnt) => {
        if (openCateSn.value == sn){
          openCateSn.value = '';
          openCateCnt.value = 0;
        }
        document.getElementById('cateMP_'+sn).style.display='';
        document.getElementById('cateMM_'+sn).style.display='none';
        for(let i=0; i < cnt+1; i++){
          document.getElementById('cate_'+sn+'_'+i).style.display = 'none';
        }
      }

      return {
        closeModal,
        studyList,
        openDetail,
        closeDetail,
        searchInfo,
        lrnerId
      }

  }
}
</script>
