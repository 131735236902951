<template>
  <div class="player-viewport">
    <div
        ref="videoContainer"
        class="player-video kb-video-wrapper"
        :class="{'kb-size-100': !isFullscreen, 'kb-size-100-padding-0': !isMobile}"
    >
      <div class="vsc-controller vsc-nosource"></div>
      <video class="vjs-matrix video-js vjs-default-skin vjs-big-play-centered vjs-fullscreen vjs-fade-out"
             ref="videoDom"
             oncontextmenu="return false;"
             playsinline
             :loop="noSleep"
      ></video>
      <button v-show="isMobile || isPortableiOS" ref="fullscreenBtn" class="vjs-fullscreen-control vjs-control vjs-button" type="button" title="Fullscreen" aria-disabled="false" @click="toggleFullScreen">
        <span class="vjs-icon-placeholder" aria-hidden="true"></span>
        <span class="vjs-control-text" aria-live="polite">Fullscreen</span>
      </button>
      <!--      </template>-->
      <HrdCloudSeries v-if="crse.cttSnLst != null && crse.cttSnLst.length > 0" :is-active="isSeriesActive" :crse="crse" @toggle="toggleSeries" />
      <div ref="coldcallDom">
        <template v-if="isMobile">
          <HrdCloudMobileColdCall
              v-if="videoInstance && uuid"
              :uuid="uuid"
              :player-cont="videoContainer"
              :player="videoInstance"
              :objectParam="objectParam"
              :progress="progress"
              :meta="metadata"
              :lastPlaySec="resumePlaySec"
              :popups="popups"
          />
        </template>
        <template v-else>
          <HrdCloudColdCall
              v-if="videoInstance && uuid && !activeContinue"
              :uuid="uuid"
              :player-cont="isAppleTablet ? videoContainer : videoDom"
              :player="videoInstance"
              :objectParam="objectParam"
              :progress="progress"
              :meta="metadata"
              :lastPlaySec="resumePlaySec"
              :popups="popups"
          />
        </template>
        <LxpMessage v-model="localMessage.show" :options="localMessage" />
      </div>

    </div>
  </div>
</template>
<style scoped>
.lxp-mobile-layout .player .player-video video {width:100%;height:100%;object-fit:contain;}
/*@media (orientation: landscape) {*/
/*  !* Landscape 모드*!*/
/*  .lxp-mobile-layout .kb-size-100 .video-js {*/
/*    position: relative!important;*/
/*    width: 100%!important;*/
/*    height: 100vh !important;*/
/*  }*/
/*}*/
</style>
<script>
import videojs from 'video.js';
// import 'videojs-youtube';
import {useStore} from 'vuex';
import {computed, onBeforeUnmount, onMounted, reactive, ref, toRefs, watch} from 'vue';
import {callNative, doPause, doPlay, eventCdDcds, insertVodLearnProgress} from '@/assets/js/modules/hrd/hrd-common';
import HrdCloudColdCall from '@/components/hrdcloud/HrdCloudColdCall';
import HrdCloudSeries from '@/components/hrdcloud/HrdCloudSeries';
import {TOKEN_KEY} from '@/store/modules/auth/auth';
import localstorageService from '@/assets/js/localstorage.service';
import navigationUtils, {isInApp} from '@/assets/js/navigationUtils';
import {getThumbImg} from '@/assets/js/modules/baner/common-baner';
import LxpMessage from '@/components/common/LxpMessage.vue';
import {hideFooter, hideHeader, showFooter, showHeader} from '@/assets/js/modules/common/common';
import HrdCloudMobileColdCall from "@/components/hrdcloud/mobile/HrdCloudMobileColdCall";

export default {
  name: 'LXPYoutubePlayer',
  components: { LxpMessage, HrdCloudSeries, HrdCloudMobileColdCall, HrdCloudColdCall},
  props: {
    modelValue: Object, // player instance
    isSeriesActive: {type: Boolean, default: false},
    container: Object,
    coldcall: Boolean, // 콜드콜 응답여부
    options: Object, // videojs options
    enableProgress: Boolean, // progress activate
    crse: Object, // learn object (play-info)
    progress: Object,
    uuid: String,
    popups: Object,
    wrapperDom1: Object,
    wrapperDom2: Object,
    wrapperDom3: Object,
  },
  emits:['update:modelValue', 'toggleFull', 'update:isSeriesActive'],
  setup(props,{emit}) {

    const isDev = (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'local');

    const store = useStore(),
        isMobile = computed(() => store.state.auth.isMobile),
        session = computed(() => store.state.auth.session),
        isPortableiOS = navigationUtils.isApplePortable(),
        fullscreenBtn = ref(null),
        isAppleTablet = navigationUtils.isAppleTablet();


    const videoDom = ref(null),
        coldcallDom = ref(null),
        videoInstance = ref(null),
        activeContinue = ref(false), // 이어보기 최초 한번 노출하기 위한 플래그
        resumePlaySec = ref(0),
        completeActive = ref(true), // false 이후 노출 안되도록 막음
        videoContainer = ref(null),
        completePause = ref(false),
        isFullscreen = ref(false),
        {uuid} = toRefs(props),
        {popups} = toRefs(props);

    // 이어보기 알럿 응답여부
    const resumeResponded = ref(false);
    const noSleep = ref(false);

    const enableNoSleep = () => {
      if (isMobile.value && isPortableiOS) noSleep.value = true;
    };

    const disableNoSleep = () => {
      if(noSleep.value) noSleep.value = false;
    };

    const objectParam = computed(() => {
      return {
        crseMstSn: props.crse.crseMstSn,
        distCrseSn: props.crse.distCrseSn,
        distCrseNm: props.crse.distCrseNm,
        distChapSn: props.crse.distChapSn,
        distChapNm: props.crse.distChapNm,
        lrnObjDtlDistSn: props.crse.lrnObjDtlDistSn,
        objSn: props.crse.objSn,
        objNm: props.crse.objNm,
        // division: 'vod'
      }
    });

    const metadata = reactive({
      duration: 0,
      lastPlaySec: 0
    });

    const log = reactive({
      max: 0,
      completeYn: 'N',
      logSec: 0,
    });

    const localMessage = reactive({
      show: false,
      center: true,
      title: '',
      text: '학습이 종료되었습니다.',
      callback: null
    });

    const showMessage = () => {
      localMessage.show = true;
    };

    // 실제 재생 초
    const getPlaySec = (ct) => {
      return (ct - log.logSec < 0 ? 0 : Math.round((ct - log.logSec )/ videoInstance.value.playbackRate()));
    };

    const continuePlay = () => {

      activeContinue.value = false;

      if (props.progress.lastPlaySec > 0 && props.progress.lastPlaySec <= (metadata.duration - 3)) {
        const latestDt = (props.progress.mdfcnDt ? props.progress.mdfcnDt : props.progress.regDt) || 0;
        // 이어보기 활성화, 이어보기 시간이 0초 아닐때, 최종학습일이 7일이내일때
        if (activeContinue.value && (props.progress.lastPlaySec > 0) && (new Date().getTime() - latestDt < (7 * 1000 * 60 * 60 * 24))) {
          videoInstance.value.pause();
          popups.value.resume.toggle(true);
        } else {
          resumeResponded.value = true;
        }
      } else {
        resumeResponded.value = true;
      }
      activeContinue.value = false
    };

    const exitFullscreen = () => {
      if (isFullscreen.value) {
        if (isMobile.value || isPortableiOS) videoInstance.value.exitFullWindow();
        else videoInstance.value.exitFullscreen();
      }
    }

    const showComplete = async (res) => {
      await popups.value.getUUID();
      // if (res.complete && completeActive.value) {
      if (res.complete) {
        completePause.value = true;
        videoInstance.value.pause();
        popups.value.complete.toggle(true);
        // completeActive.value = false;
      } else {
        showMessage();
      }

      // console.log('showComplete isFullscreen : ',isFullscreen.value);

      setTimeout(() => {
        // 완료창 떠있으면 닫기
        if (popups.value.complete.show) popups.value.complete.toggle(false);
        // 종료창 떠있으면 닫기
        if (localMessage.show) localMessage.show = false;
        // 전체화면이면 닫기
        exitFullscreen();
      },15000);
    };

    // 완료창 닫기 눌렀을때
    watch(() => popups.value.complete.show, () => {
      // console.log('[WATCH] complete show : ',popups.value.complete.show);
      // COMPLETE ALERT CLOSED
      if (!popups.value.complete.show) {
        exitFullscreen();
      }
    });

    const toggleFullScreen = () => {
      // console.log('[toggleFullScreen] isFullscreen : ',isFullscreen.value);
      if (videoInstance.value !== null) {
        if (!isFullscreen.value) {
          videoInstance.value.enterFullWindow();
        } else {
          videoInstance.value.exitFullWindow();
        }
      }
      activeContinue.value = false;
    };


    // console.log("renderImg", session.value.itn)

    const renderImg = () => {
      const getImg = getThumbImg(props.crse.thumb);
      return (getImg ? getImg : require('../../assets/lxp/images/@tmp/@tmp_myclass_player_thumbnail_pdf.png'));
    };

    const callNativeFunc = () => {
      const token = computed(() => localstorageService.getToken(TOKEN_KEY));
      callNative(props.crse, props.progress, uuid, token, session);
    };

    const toggleSeries = (enable) => {
      if (props.crse.cttSnLst != null && props.crse.cttSnLst.length > 0) {
        if (enable) {
          emit('update:isSeriesActive', true);
        } else {
          emit('update:isSeriesActive', !props.isSeriesActive);
        }
      }
    };

    const logDebounce = ref(0);

    watch(() => popups.value.resume.response.effect, () => {
      if (props.progress.lastPlaySec > 0) {
        if (popups.value.resume.response.type) {
          if (popups.value.resume.response.type === 'continue') {
            resumePlaySec.value = props.progress.lastPlaySec;
            log.logSec = props.progress.lastPlaySec;
            videoInstance.value.currentTime(props.progress.lastPlaySec);
          }
          popups.value.resume.toggle(false);
          popups.value.resume.initRespond();
          // console.log('[중단 알럿] videoInstance.value.paused() : ',videoInstance.value.paused())
          doPlay(videoInstance.value);
        }
      }
      resumeResponded.value = true;
      activeContinue.value = false;
    });

    watch(() => isFullscreen.value, () => {
      if (isMobile.value || isPortableiOS) {
        if (isFullscreen.value) {
          hideFooter();
          setTimeout(function() {
            window.scrollTo(0, 1);
          }, 500);
        }
        else showFooter();
      }
    });

    onMounted(() => {

      let youtubeId = props.crse.etnUrl.indexOf("watch")>-1 ? props.crse.etnUrl.split("=")[1] : "";
      youtubeId = props.crse.etnUrl.indexOf("youtu.be")>-1 ? props.crse.etnUrl.split("/").pop() : youtubeId;
      // console.log('poster', props.crse.etnUrl, youtubeId);
      const vjsOptions = (isMobile.value || isPortableiOS) ? {
        language: 'ko',
        width: window.innerWidth,
        height: 360,
        autoplay: false,
        crossOrigin: 'anonymous',
        controls: true,
        controlBar: {
          subsCapsButton: false,
          audioTrackButton: false,
          fullscreenToggle: !(isMobile.value || isPortableiOS)
        },
        techOrder:['youtube'],
        poster: `https://i.ytimg.com/vi/${youtubeId}/maxresdefault.jpg`,
        sources: [{
          src: `https://www.youtube.com/watch?v=${youtubeId}`,
          type: 'video/youtube'
        }],
        youtube: {
          autoplay:false,
          controls:true,
          ytControls: false
        }
      } : {

        autoplay: false,
        crossOrigin: 'anonymous',
        controls: true,
        controlBar: {
          subsCapsButton: false,
          audioTrackButton: false,
          fullscreenToggle: !(isMobile.value || isPortableiOS)
        },
        techOrder:['youtube'],
        poster: `https://i.ytimg.com/vi/${youtubeId}/maxresdefault.jpg`,
        sources: [{
          src: `https://www.youtube.com/watch?v=${youtubeId}`,
          type: 'video/youtube'
        }],
        youtube: {
          autoplay:false,
          controls:true,
          ytControls: false
        }
      };


      // console.log(vjsOptions.poster);

      if(session.value.itn){
        // console.log("내부망");
        vjsOptions.sources = [];
        vjsOptions.poster = `/files/images/youtube.jpg`;
        vjsOptions.controls = false;
        videojs(videoDom.value, vjsOptions, function () {

        });

      }else {

          // 외부망
          const initYoutubeJS = () => import ('videojs-youtube');
          // const youtubeJs = import('videojs-youtube');
          // youtubeJs();
          initYoutubeJS().then(()=> {
            // console.log("youtube player init!!")
            videojs(videoDom.value, vjsOptions, function () {
              this.tech_.off('dblclick');

              videoInstance.value = this;

              emit('update:modelValue', videoInstance.value);

              // 학습종료 닫기 콜백 렌더
              localMessage.callback = () => {
                exitFullscreen();
              };

              let vjs = videoInstance.value;

              videoInstance.value.el().appendChild(coldcallDom.value);

              if (isMobile.value || isPortableiOS) {
                videoInstance.value.el().appendChild(fullscreenBtn.value);
                videoInstance.value.on('exitFullWindow', () => {
                  isFullscreen.value = false;
                  if (!isMobile.value) showHeader();
                  emit('toggleFull', isFullscreen.value);
                });

                videoInstance.value.on('enterFullWindow', () => {
                  isFullscreen.value = true;
                  if (!isMobile.value) hideHeader();
                  emit('toggleFull', isFullscreen.value);
                });

                const controlsEls = videoInstance.value.el().getElementsByClassName('vjs-control-bar');
                if (controlsEls.length > 0) {
                  controlsEls[0].append(fullscreenBtn.value)
                }
              }

              // vjs.on('click', function() {
              //   if (vjs.paused()) {
              //     console.log('vjs.getChild(\'controlBar\') : ',vjs.getChild('controlBar'));
              //   }
              // });

              vjs.on('loadedmetadata', function () {
                metadata.duration = Math.floor(this.duration());
              });

              vjs.on('play', function () {
                enableNoSleep();

                if (activeContinue.value) {
                  continuePlay(vjs);
                }

                let ct = Math.floor(this.currentTime());
                if (ct - log.logSec >= 0 && ((ct - log.logSec) < 2)) {
                  if (resumeResponded.value) {
                    logDebounce.value = setTimeout(() => {
                      if (logDebounce.value) clearTimeout(logDebounce.value);

                      insertVodLearnProgress(props.crse, {
                        progSesnKey: uuid.value,
                        lastPlaySec: ct,
                        playSec: getPlaySec(ct),
                        playSpeed: 1,
                        eventCdDcd: eventCdDcds.Play,
                        eventNm: '학습시작',
                      }, () => {
                        log.logSec = ct;
                      });
                    }, 500);
                  }
                }
              });

              vjs.on('pause', function () {
                disableNoSleep();

                const ct = Math.floor(this.currentTime());
                if (!completePause.value && ct < this.duration()) {
                  if (ct - log.logSec >= 0 && ((ct - log.logSec) < 1)) {
                    if (resumeResponded.value) {
                      logDebounce.value = setTimeout(() => {
                        if (logDebounce.value) clearTimeout(logDebounce.value);

                        insertVodLearnProgress(props.crse, {
                          progSesnKey: uuid.value,
                          lastPlaySec: ct,
                          playSec: getPlaySec(ct),
                          playSpeed: this.playbackRate(),
                          eventCdDcd: eventCdDcds.Pause,
                          eventNm: '학습중지',
                        }, () => {
                          log.logSec = ct;
                        });
                      }, 500);
                    }
                  }
                }

                if (completePause.value) completePause.value = false;
              });

              vjs.on('timeupdate', function () {
                let dur = Math.floor(this.duration());
                let ct = Math.floor(this.currentTime());
                if ((dur - ct) <= 10) {
                  disableNoSleep();
                }

                if (this.currentTime() > -1) {
                  let ct = Math.floor(this.currentTime());
                  // 최대 도달 시간
                  if (log.completeYn === 'N' && (ct > log.max)) {
                    if (ct - log.max < 2) {
                      log.max = ct;
                    }
                  }
                  // log.logSec 찍은 초
                  if ((ct - log.logSec >= 30) && ((ct - log.logSec) <= 32)) {
                    log.logSec = ct;
                    // console.log('학습중 log.logSec : ',log.logSec);
                    insertVodLearnProgress(props.crse, {
                      progSesnKey: uuid.value,
                      lastPlaySec: ct,
                      playSec: Math.round(30 / this.playbackRate()),
                      playSpeed: this.playbackRate(),
                      eventCdDcd: eventCdDcds.Timeupdate,
                      eventNm: '학습중',
                    });
                  }
                }
              });

              vjs.on('ended', function () {
                toggleSeries(true);

                let ct = Math.floor(this.currentTime());
                insertVodLearnProgress(props.crse, {
                  progSesnKey: uuid.value,
                  lastPlaySec: ct,
                  playSec: getPlaySec(ct),
                  playSpeed: this.playbackRate(),
                  eventCdDcd: eventCdDcds.Ended,
                  eventNm: '학습종료',
                }, res => {
                  log.logSec = 0;
                  showComplete(res);
                });

                if (!vjs.paused()) doPause(vjs);
                else {
                  vjs.currentTime(0);
                  vjs.bigPlayButton.show();
                }
              });

              vjs.on('seeked', function () {
                log.logSec = Math.floor(this.currentTime());
                // if(seekTimer) {
                //   clearTimeout(seekTimer);Hist
                //   seekTimer = null;
                // }
                //
                // seekTimer = setTimeout(()=>{
                //   let ct = this.currentTime();
                //   log.logSec = ct;
                //   insertVodLearnProgress(props.crse, {
                //     progSesnKey: uuid.value,
                //     lastPlaySec: ct,
                //     playSec: getPlaySec(ct),
                //     playSpeed: this.playbackRate(),
                //     eventCdDcd: eventCdDcds.S,
                //     eventNm: '학습이동',
                //     coldcall: props.coldcall,
                //   });
                // }, 5000);
              });

              vjs.on('fullscreenchange', function () {
                // console.log('fullscreenchange');
                isFullscreen.value = vjs.isFullscreen() ? true : false;
              });
            });
          });
      }
    });



    // const orientation = computed(() => screen.orientation.type);

    // alert(1);

    // watch(() => orientation.value, () => {
    //   // console.log('orientation : '+orientation.value);
    //   alert('orientation : '+orientation.value);
    // });

    // window.addEventListener("orientationchange", function() {
    //   console.log('screen type : ',screen.orientation.type);

    //   // alert('orientation change');
    //   if (isFullscreen.value) {
    //     // alert('isFullscreen : '+isFullscreen.value);
    //     setTimeout(function() {
    //       alert('window.scrollTo');
    //       window.scrollTo(0, 1);
    //     }, 0);
    //     document.body.style.overflow = 'hidden';
    //   }
    // });

    onBeforeUnmount(() => {
      if (videoInstance.value) videoInstance.value.dispose();
      exitFullscreen();
      disableNoSleep();
      noSleep.value = null;
    });

    return {
      isMobile, isPortableiOS, isFullscreen, fullscreenBtn, noSleep,
      videoDom, coldcallDom, videoInstance, metadata, resumePlaySec, activeContinue,
      objectParam, completeActive, videoContainer, isDev, isInApp, callNativeFunc, renderImg,
      toggleFullScreen, localMessage, isAppleTablet, toggleSeries
    };
  }

};
</script>