<template>
  <!-- page-container -->
  <div v-if="isReady" class="page-container">
    <div class="page-top">
      <div class="page-component">
        <div class="top-nav">
          <router-link :to="{name: 'SSLMain'}" class="top-kb-nav-link">
            <span class="ic-bg"><i class="icon-type-back"></i></span>
            <span class="text">{{ mainName }} 홈 </span>
          </router-link>
        </div>

        <div v-if="isNotMine" class="top-nav">
          <SSLLearnerSubscribe :lrner="target"/>
        </div>

      </div>
    </div>
    <div class="page-body">
      <div class="myssl-container">
        <SSLLearnerTop
            :main-name="mainName"
            :lrner="target"
        />
        <div class="myssl-top">
          <SSLMyWrite v-if="isRecord && distCrseCd === 'book'" :lrner="target"/>
          <SSLMyActivity v-if="isRecord && distCrseCd === 'book'" :lrner="target"/>
        </div>
        <SSLLearnerBadge v-if="isRecord" />
        <SSLLearnerBoard v-else-if="isBoard"/>
      </div>
    </div>
    <!-- //page-footer -->
  </div>
  <!-- //page-container -->
</template>

<script>
import SSLLearnerTop from '@/components/ssl/learner/SSLLearnerTop';
import SSLLearnerBadge from '@/components/ssl/learner/SSLLearnerBadge';
import SSLLearnerSubscribe from '@/components/ssl/learner/SSLLearnerSubscribe';
import SSLLearnerBoard from '@/components/ssl/learner/SSLLearnerBoard';
import {sslLearnerSetup} from '@/assets/js/modules/ssl/setup/ssl-learn-setup';
import SSLMyWrite from "@/components/ssl/my/SSLMyWrite.vue";
import SSLMyActivity from "@/components/ssl/my/SSLMyActivity.vue";

export default {
  name: 'SSLLearner',
  components: {
    SSLMyActivity,
    SSLMyWrite,
    SSLLearnerBoard,
    SSLLearnerSubscribe,
    SSLLearnerBadge,
    SSLLearnerTop
  },
  setup: sslLearnerSetup
}
</script>
