import LxpLayout from '@/views/layout/lxp/LxpLayout';
import navigationUtils from '@/assets/js/navigationUtils';

const learnRoutes = [
    {
        path: '/learn',
        component: LxpLayout,
        children: [
            {
                path: ':distCrseSn/:view',
                name: 'LearnMain',
                component: () => import('@/views/pages/learn/LearnMain'),
            },
            {
                path: ':distCrseSn/:view/:lrnObjDtlDistSn',
                name: 'LearnBoard',
                component: () => import('@/views/pages/learn/LearnMain'),
            },
            {
                path: ':distCrseSn/:view/:lrnObjDtlDistSn/edit/:lrnPostSn',
                name: 'LearnBoardWrite',
                component: () => import('@/views/pages/learn/LearnBoardWrite'),
            },
            {
                path: ':distCrseSn/:view/:lrnObjDtlDistSn/view/:lrnPostSn',
                name: 'LearnBoardView',
                component: () => import('@/views/pages/learn/LearnBoardView'),
            },
            {
                path: ':mode',
                name: 'LearnBulletin',
                component: () => import('@/views/pages/learn/LearnBulletin'),
            },
            {
                // path variable 을 생성해야 된다. 임시 라우터 처리
                path:':mode/:lrnPostSn',
                name: 'LearnBulletinView',
                component: () => import('@/views/pages/learn/LearnBulletinView'),
            },

            {
                path: 'regular/:distCrseSn',
                name: 'LearnRegularMain',
                component: () => import('@/views/pages/learn/LearnRegularMain'),
            },

        ],
    }
];

const mobileLearnRoutes = [
    {
        path: '/learn',
        component: () => import('@/views/layout/lxp/mobile/LxpMobileLayout'),
        children: [
            {
                path: ':distCrseSn/:view',
                name: 'LearnMain',
                component: () => import('@/views/pages/learn/mobile/MobileLearnMain'),
            },
            {
                path: ':distCrseSn/:view/:lrnObjDtlDistSn',
                name: 'LearnBoard',
                component: () => import('@/views/pages/learn/mobile/MobileLearnMain'),
            },
            {
                path: ':distCrseSn/:view/:lrnObjDtlDistSn/edit/:lrnPostSn',
                name: 'LearnBoardWrite',
                component: () => import('@/views/pages/learn/LearnBoardWrite'),
            },
            {
                path: ':distCrseSn/:view/:lrnObjDtlDistSn/view/:lrnPostSn',
                name: 'LearnBoardView',
                component: () => import('@/views/pages/learn/LearnBoardView'),
            },

            {
                path: ':mode',
                name: 'LearnBulletin',
                component: () => import('@/views/pages/learn/LearnBulletin'),
            },
            {
                // path variable 을 생성해야 된다. 임시 라우터 처리
                path:':mode/:lrnPostSn',
                name: 'LearnBulletinView',
                component: () => import('@/views/pages/learn/mobile/MobileLearnBulletinView'),
            },

            {
                path: 'regular/:distCrseSn',
                name: 'LearnRegularMain',
                component: () => import('@/views/pages/learn/mobile/MobileLearnRegularMain'),
            },
        ],
    }
];

export const setLearnRoutes = (routes) => {
    if (navigationUtils.any()) {
        routes.push(...mobileLearnRoutes);
    } else {
        routes.push(...learnRoutes);
    }
}
