import {createStore} from 'vuex';
import common from '@/store/modules/common/common';
import auth from '@/store/modules/auth/auth';
import menu from '@/store/modules/menu/menu';
import code from '@/store/modules/code/code';
import train from '@/store/modules/train/train';
import hrdcloud from '@/store/modules/hrdcloud/hrdcloud';
import notification from '@/store/modules/notification/notification';
import history from '@/store/modules/history/history';
import apply from '@/store/modules/apply/apply';
import prep from '@/store/modules/prep/prep';
import help from '@/store/modules/help/help';
import my from '@/store/modules/my/my';
import learn from '@/store/modules/learn/learn';
import capability from '@/store/modules/capability/capability';
import board from '@/store/modules/board/board';
import coldcall from '@/store/modules/common/coldcall';
import evaluation from '@/store/modules/evaluation/evaluation';
import survey from '@/store/modules/survey/survey';
import course from '@/store/modules/course/course';
import ssl from '@/store/modules/ssl/ssl';
import contents from '@/store/modules/contents/contents';
import license from '@/store/modules/license/license';
import certification from '@/store/modules/certification/certification';
import talent from '@/store/modules/talent/talent';
import support from '@/store/modules/support/support';
import school from '@/store/modules/common/school';
import costproof from '@/store/modules/costproof/costproof';
import trnct from '@/store/modules/trnct/trnct';
import nowon from '@/store/modules/nowon/nowon';
import badge from '@/store/modules/badge/badge';
import quiz from  '@/store/modules/quiz/quiz';
import baner from '@/store/modules/baner/baner'
import live from '@/store/modules/live/live';
import dept from '@/store/modules/dept/dept';
import createPersistedState from 'vuex-persistedstate';
import mypage from '@/store/modules/mypage/mypage';
import fcpl from '@/store/modules/fcpl/fcpl';
import boardmst from '@/store/modules/board/board-mst';
import wise from '@/store/modules/wise/wise';
import wmportfolio from '@/store/modules/otherdept/wmportfolio';
import newz from '@/store/modules/newzonboarding/newz';
import consult from '@/store/modules/consult/consult';
import book from '@/store/modules/book/book';
import checklist from '@/store/modules/checklist/checklist';

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    common,
    coldcall,
    auth,
    menu,
    code,
    train,
    hrdcloud,
    notification,
    history,
    apply,
    prep,
    help,
    my,
    learn,
    capability,
    board,
    evaluation,
    survey,
    course,
    ssl,
    contents,
    certification,
    license,
    talent,
    support,
    school,
    costproof,
    trnct,
    nowon,
    badge,
    quiz,
    baner,
    live,
    dept,
    mypage,
    fcpl,
    boardmst,
    wise,
    wmportfolio,
    newz,
    consult,
    book,
    checklist,
  },
  plugins: [createPersistedState({
    paths: ["ssl.routeFrom"]
  })],
});
