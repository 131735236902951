import ApiService from '@/assets/js/api.service';

export const MUT_SET_LEARN_OBJECT = 'setLearnObject';
// ACTION
// JY
export const ACT_CHECK_LEARN = 'checkMyLearn'
export const ACT_GET_LEARN_LIST = 'getLearnList';
export const ACT_GET_LEARN_OBJECT_ADDED = 'getLearnObjectAdded';
export const ACT_GET_LEARN_OBJECT_MASTER_LIST = 'getLearnObjectMasterList';
export const ACT_GET_LEARN_OBJECT_SCHEDULE_LIST = 'getLearnObjectScheduleList';
export const ACT_GET_LEARN_OBJECT_RESULT = 'getLearnObjectResult';
export const ACT_GET_LEARN_OBJECT_RESULT_LIST = 'getLearnObjectResultList';
export const ACT_GET_LEARN_RESULT_SUMUP = 'getLearnResultSumup';
export const ACT_GET_LEARN_HISTORY_LIST = 'getLearnHistoryList';

export const ACT_GET_MLG_HISTORY_LIST = 'getMlgHistoryList';
export const ACT_GET_LEARN_FINISH_HISTORY_LIST = 'getLearnFinishHistoryList';
export const ACT_GET_LEARN_GLOBAL_LIST = 'getLearnGlobalList';
export const ACT_GET_LEARN_MY_CERTIFICATION_LIST = 'getLearnMyCertificationList';
export const ACT_GET_LEARN_MY_CERTIFICATION_TEMP_LIST = 'getLearnMyCertificationTempList';
export const ACT_GET_LEARN_MY_CERTIFICATION_COUNT_LIST = 'getLearnMyCertificationCountList';
export const ACT_INSERT_LEARN_RESULT = 'insertLearnResult'
export const ACT_INSERT_LEARN_PROGRESS_RESULTS = 'insertLearnProgressResults';
export const ACT_INSERT_LEARN_PROGRESS_LOGS = 'insertLearnProgressLogs';

export const ACT_INSERT_LEARN_PROGRESS_LOG_COLD_CALL = 'insertLearnProgressLogColdCall';
export const ACT_UPDATE_LEARN_PROGRESS_LOG_COLD_CALL = 'updateLearnProgressLogColdCall';

export const ACT_GET_LEARN_OBJECT_TODAY_LIST = 'getLearnObjectTodayList';

// export const ACT_GET_LEARN_REGULAR_OBJECT_RESULT_LIST = 'getLearnRegularObjectResultList';
// export const ACT_GET_LEARN_REGULAR_OBJECT_RESULT = 'getLearnRegularObjectResult';

export const ACT_GET_LEARN_ACCUM_OBJECT_RESULT_LIST = 'getLearnAccumObjectResultList';
export const ACT_GET_LEARN_ACCUM_OBJECT_RESULT = 'getLearnAccumObjectResult';

// HS
// Progress
export const ACT_INSERT_LEARN_PROGRESS = 'actInsertLearnProgress';
export const ACT_GET_LEARN_PROGRESS = 'actGetLearnProgress';
// Log
export const ACT_GET_LEARN_PROGRESS_LOG_LIST = 'actGetLearnProgressLogList';
// Comment
export const ACT_INSERT_LEARN_COMMENT = 'actInsertLearnComment';
export const ACT_UPDATE_LEARN_COMMENT = 'actUpdateLearnComment';
export const ACT_DELETE_LEARN_COMMENT = 'actDeleteLearnComment';
export const ACT_GET_LEARN_COMMENT = 'actGetLearnComment';
// ATTACHED FILE
export const ACT_GET_LEARN_ATCH_FILE = 'actGetLearnAttachFile';
export const ACT_CHECK_GROUP_LEADER = 'actCheckGroupLeader';

// --- 삭제 예정
// export const ACT_GET_LEARN_VOD_LIST = 'actGetLearnVodList';
export const ACT_GET_LEARN_REFERENCE_CERTIFICATE_LIST ='actGetLearnReferenceCertificateList'
export const ACT_GET_LEARN_TEXTBOOK_LIST = 'actGetLearnTextbookList';
export const ACT_GET_LEARN_SPECIAL_ACTIVITY_LIST = 'actGetLearnSpecialActivityList';
export const ACT_GET_LEARN_CONTENT = 'actGetLearnContent';
export const ACT_GET_LEARN_VOD = 'actGetLearnVod';
export const ACT_GET_LEARN_PDF = 'actGetLearnPdf';
export const ACT_GET_LEARN_EDUTOR = 'actGetLearnEdutor';
// export const ACT_GET_LEARN_REFERENCE_LIST = 'actGetLearnReferenceList';
// export const ACT_GET_LEARN_COURSE = 'actGetLearnCourse';
// export const ACT_GET_LEARN_TODAY_LIST = 'actGetLearnTodayList';
// export const ACT_GET_LEARN_STATUS_LIST = 'actGetLearnStatusList';
// export const ACT_GET_LEARN_INDEX_LIST = 'actGetLearnIndexList';

export const ACT_GET_JM_JOB_CERT_LIST = 'getJmJobCertList';
export const ACT_GET_VICE_PROMOTION_REQUIREMENT_LIST = 'getVicePromotionRequirementList';

export const ACT_GET_KNOWLEDGE_E_LEARN_OBJECT = 'actGetKnowledgeELearnObject';
export const ACT_GET_SUMGO_LEARNING_LIVE_LIST = 'getSumgoLearningLiveList';

// HotTip
export const ACT_INSERT_LEARN_HOT_TIP_ACT_LOG = 'insertLearnHotTipActLog';
export const ACT_GET_LEARN_HOT_TIP_USER_VISIT_COUNT = 'getLearnHotTipUserVisitCount';

const state = {
    // 객체 마스터 정보
    objects: []
};

const mutations = {
    [MUT_SET_LEARN_OBJECT](state, objects){
        state.objects = objects;
    }
};

const actions = {
    //    JY
    [ACT_CHECK_LEARN](context, distCrseSn){
        return new Promise(resolve => {
            ApiService.query(`/v1/app/crses/dists/${distCrseSn}/regs`, {}).then(response => resolve(response));
        });
    },
    [ACT_GET_LEARN_LIST](context, params){
        return new Promise(resolve => {
            ApiService.query('/v1/app/learns', params).then(response => resolve(response));
        });
    },
    [ACT_GET_LEARN_OBJECT_ADDED](context, lrnObjDtlDistSn){
        return new Promise(resolve => {
            ApiService.query(`/v1/app/learns/objects/${lrnObjDtlDistSn}/added`, {}).then(response => resolve(response));
        });
    },
    [ACT_GET_LEARN_OBJECT_MASTER_LIST](){
        return new Promise(resolve => {
            ApiService.query('/v1/app/learns/objects/masters', {}).then(response => resolve(response));
        });
    },
    [ACT_GET_LEARN_OBJECT_SCHEDULE_LIST](context, params){
        return new Promise(resolve => {
            ApiService.query('/v1/app/learns/objects/schedules', params).then(response => resolve(response));
        });
    },
    [ACT_GET_LEARN_OBJECT_TODAY_LIST](context, params){
        return new Promise(resolve => {
            ApiService.query('/v1/app/learns/objects/today', params).then(response => resolve(response));
        });
    },
    [ACT_GET_LEARN_OBJECT_RESULT](context, lrnObjDtlDistSn){
        return new Promise(resolve => {
            ApiService.query(`/v1/app/learns/objects/${lrnObjDtlDistSn}/results`, {}).then(response => resolve(response));
        });
    },
    [ACT_GET_LEARN_OBJECT_RESULT_LIST](context, distCrseSn){
        return new Promise(resolve => {
            ApiService.query(`/v1/app/learns/${distCrseSn}/objects/results`, {}).then(response => resolve(response));
        });
    },
    // [ACT_GET_LEARN_REGULAR_OBJECT_RESULT](context, lrnObjDtlDistSn){
    //     return new Promise(resolve => {
    //         ApiService.query(`/v1/app/learns/regulars/objects/${lrnObjDtlDistSn}/results`, {}).then(response => resolve(response));
    //     });
    // },
    [ACT_GET_LEARN_ACCUM_OBJECT_RESULT](context, {lrnObjDtlDistSn, params}){
        return new Promise(resolve => {
            ApiService.query(`/v1/app/learns/accum/objects/${lrnObjDtlDistSn}/results`, params).then(response => resolve(response));
        });
    },
    // [ACT_GET_LEARN_REGULAR_OBJECT_RESULT_LIST](context, distCrseSn){
    //     return new Promise(resolve => {
    //         ApiService.query(`/v1/app/learns/${distCrseSn}/regulars/objects/results`, {}).then(response => resolve(response));
    //     });
    // },
    [ACT_GET_LEARN_ACCUM_OBJECT_RESULT_LIST](context, {distCrseSn, params}){
        return new Promise(resolve => {
            ApiService.query(`/v1/app/learns/${distCrseSn}/accum/objects/results`, params).then(response => resolve(response));
        });
    },
    [ACT_GET_LEARN_RESULT_SUMUP](context, distCrseSn){
        return new Promise(resolve => {
            ApiService.query(`/v1/app/learns/${distCrseSn}/results`, {}).then(response => resolve(response));
        });
    },
    [ACT_INSERT_LEARN_RESULT](context, params){
        return new Promise(resolve => {
            ApiService.post('/v1/app/learns/objects/results', params).then(response => resolve(response));
        });
    },
    [ACT_INSERT_LEARN_PROGRESS_RESULTS](context, params){
        return new Promise(resolve => {
            ApiService.post('/v1/app/learns/progress/results', params).then(response => resolve(response));
        });
    },
    [ACT_INSERT_LEARN_PROGRESS_LOGS](context, params){
        return new Promise(resolve => {
            ApiService.post('/v1/app/learns/progress/logs', params).then(response => resolve(response));
        });
    },
    [ACT_GET_LEARN_HISTORY_LIST](context, params){
        return new Promise(resolve => {
            ApiService.query('/v1/app/learns/histories', params).then(response => resolve(response));
        });
    },
    [ACT_GET_MLG_HISTORY_LIST](context, params){
        return new Promise(resolve => {
            ApiService.query('/v1/app/learns/mlgs', params).then(response => resolve(response));
        });
    },
    [ACT_GET_LEARN_FINISH_HISTORY_LIST](context, params){
        return new Promise(resolve => {
            ApiService.query('/v1/app/learns/finish-histories', params).then(response => resolve(response));
        });
    },
    [ACT_GET_LEARN_GLOBAL_LIST](context, params){
        return new Promise(resolve => {
            ApiService.query('/v1/app/learns/globals', params).then(response => resolve(response));
        });
    },
    [ACT_GET_LEARN_MY_CERTIFICATION_LIST](context, params){
        return new Promise(resolve => {
            ApiService.query('/v1/app/learns/mycertifications', params).then(response => resolve(response));
        });
    },
    [ACT_GET_LEARN_MY_CERTIFICATION_TEMP_LIST](context, params){
        return new Promise(resolve => {
            ApiService.query('/v1/app/learns/mycertifications/all', params).then(response => resolve(response));
        });
    },
    [ACT_GET_LEARN_MY_CERTIFICATION_COUNT_LIST](context, params){
        return new Promise(resolve => {
            ApiService.query('/v1/app/learns/mycertification-cnt', params).then(response => resolve(response));
        });
    },
    //    HS
    [ACT_INSERT_LEARN_PROGRESS](context, params) {
        return new Promise(resolve => {
            ApiService.post('/v1/app/learn/progress', params).then(response => resolve(response))
        });
    },
    [ACT_GET_LEARN_PROGRESS](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/learn/progress', params).then(response => resolve(response))
        });
    },
    [ACT_GET_LEARN_PROGRESS_LOG_LIST](context, {objSn}) {
        return new Promise(resolve => {
            ApiService.query(`/v1/app/learn/progress/${objSn}/log`, {}).then(response => resolve(response))
        });
    },
    [ACT_INSERT_LEARN_COMMENT](context, {lrnObjDtlDistSn, params}) {
        return new Promise(resolve => {
            ApiService.post(`/v1/app/lrn/${lrnObjDtlDistSn}/cmnt`,params).then(response => resolve(response))
        });
    },
    [ACT_UPDATE_LEARN_COMMENT](context, {lrnObjCmntSn, params}) {
        return new Promise(resolve => {
            ApiService.put(`/v1/app/lrn/cmnt/${lrnObjCmntSn}`,params).then(response => resolve(response))
        });
    },
    [ACT_DELETE_LEARN_COMMENT](context, lrnObjCmntSn) {
        return new Promise(resolve => {
            ApiService.delete(`/v1/app/lrn/cmnt`,lrnObjCmntSn).then(response => resolve(response))
        });
    },
    [ACT_GET_LEARN_COMMENT](context, {lrnObjDtlDistSn, params}) {
        return new Promise(resolve => {
            ApiService.query(`/v1/app/lrn/${lrnObjDtlDistSn}/cmnt`,params).then(response => resolve(response))
        });
    },
    // 추후 삭제 예정
    [ACT_GET_LEARN_REFERENCE_CERTIFICATE_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa405as', params).then(response => resolve(response))
        });
    },
    [ACT_GET_LEARN_TEXTBOOK_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa405bs', params).then(response => resolve(response))
        });
    },
    [ACT_GET_LEARN_SPECIAL_ACTIVITY_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa405cs', params).then(response => resolve(response))
        });
    },
    // [ACT_GET_LEARN_REFERENCE_LIST](context, params) {
    //     return new Promise(resolve => {
    //         ApiService.query('/v1/app/front/fa405es', params).then(response => resolve(response))
    //     });
    // },
    // [ACT_GET_LEARN_COURSE](context, params) {
    //     return new Promise(resolve => {
    //         ApiService.query('/v1/app/front/fa402a', params).then(response => resolve(response))
    //     });
    // },
    // [ACT_GET_LEARN_TODAY_LIST](context, params) {
    //     return new Promise(resolve => {
    //         ApiService.query('/v1/app/lrn/today', params).then(response => resolve(response))
    //     });
    // },
    // [ACT_GET_LEARN_STATUS_LIST](context, params) {
    //     return new Promise(resolve => {
    //         ApiService.query('/v1/app/lrn/status', params).then(response => resolve(response))
    //     });
    // },
    // [ACT_GET_LEARN_INDEX_LIST](context, params) {
    //     return new Promise(resolve => {
    //         ApiService.query('/v1/app/front/fa402b', params).then(response => resolve(response))
    //     });
    // },

    [ACT_GET_LEARN_CONTENT](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa404a', params).then(response => resolve(response))
        });
    },

    [ACT_GET_LEARN_VOD](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa404a', params).then(response => resolve(response))
        });
    },
    [ACT_GET_LEARN_PDF](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa404b', params).then(response => resolve(response))
        });
    },
    [ACT_GET_LEARN_EDUTOR](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa404c', params).then(response => resolve(response))
        });
    },
    [ACT_GET_LEARN_ATCH_FILE](context, {lrnObjDtlDistSn, params}) {
        return new Promise(resolve => {
           ApiService.query(`/v1/app/lrn/file/${lrnObjDtlDistSn}`, params).then(response => resolve(response))
        });
    },
    [ACT_INSERT_LEARN_PROGRESS_LOG_COLD_CALL](context, params) {
        return new Promise(resolve => {
            ApiService.post('/v1/app/learns/progress/logs/cold-calls', params).then(response => resolve(response))
        });
    },
    [ACT_UPDATE_LEARN_PROGRESS_LOG_COLD_CALL](context, {progLogSn, params}) {
        return new Promise(resolve => {
            ApiService.put(`/v1/app/learns/progress/logs/${progLogSn}/cold-calls`,params).then(response => resolve(response))
        });
    },
    [ACT_GET_JM_JOB_CERT_LIST](){
        return new Promise(resolve => {
            ApiService.get(`/v1/app/learns/jm/job/certs`).then(response => resolve(response))
        });
    },
    [ACT_GET_VICE_PROMOTION_REQUIREMENT_LIST](){
        return new Promise(resolve => {
            ApiService.get(`/v1/app/learns/vice/promotion/requirement/list`).then(response => resolve(response))
        });
    },
    [ACT_CHECK_GROUP_LEADER](context, lrnerId){
        return new Promise(resolve => {
           ApiService.get(`/v1/app/learns/${lrnerId}`,'group').then(response => resolve(response))
        });
    },
    [ACT_GET_KNOWLEDGE_E_LEARN_OBJECT](context, {lrnObjDtlDistSn, fileName}){
        ApiService.download2('/v1/app/learns/socs/knowledge', lrnObjDtlDistSn, null, fileName)
    },
    [ACT_GET_SUMGO_LEARNING_LIVE_LIST]() {
        return new Promise(resolve => {
            ApiService.query('/v1/app/learns/sumgo', {}).then(response => resolve(response))
        });
    },
    [ACT_INSERT_LEARN_HOT_TIP_ACT_LOG](context, params){
        return new Promise(resolve => {
            ApiService.post(`/v1/app/learn/hottip/act/log`, params).then(response => resolve(response))
        });
    },
    [ACT_GET_LEARN_HOT_TIP_USER_VISIT_COUNT](){
        return new Promise(resolve => {
            ApiService.query('/v1/app/learn/hottip/uv', {}).then(response => resolve(response))
        });
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
