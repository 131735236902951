<template>
  <!-- page-container -->
  <div class="page-container">

    <div class="board-view-container mt-3">
      <div class="board-view-top view-component">
        <div class="top-column"><button class="kb-btn-type back" @click="goBack"><span class="ic-bg"><i class="icon-type-back"></i></span><span class="text">이전</span></button></div>
      </div>
    </div>

    <div class="page-body">
      <!-- board-list-container -->
      <div class="board-list-container">
        <!-- board-list-header -->
        <div class="board-list-header view-component">
          <h2 class="title">{{ distCrseNm }} 공지사항</h2>
          <p v-if="paging.totalCount > 0" class="subtext">전체 <span class="num">{{ paging.totalCount }}</span>건</p>
        </div>
        <!-- //board-list-header -->
        <!-- board-list-wrapper -->
        <div class="board-list-wrapper">
          <div v-if="!isLoading && items.length === 0" class="board-search-wrapper">
            <div class="search-empty">
              <img src="@/assets/lxp/images/ssl/main/empty_image.png" alt>
            </div>
          </div>

          <ul v-else class="board-list">
            <li v-for="(item, idx) in items" class="board-list-item" :key="idx">
              <article class="board">
                <router-link :to="{name: 'SSLNoticeView', params: {socLrnNtcSn: item.comNtcSn}}" class="board-link"></router-link>
                <div class="board-category">
                  <span class="text">{{ item.expsrLocNm }}</span>
                </div>
                <div class="board-title">
                  <span class="text">{{ item.ntcTitle }}</span>
                </div>
                <div class="board-date">
                  <span class="text">{{ timestampToDateFormat(item.regDt, 'yyyy.MM.dd') }} 작성</span>
                </div>
                <div class="board-hit">
                  <span class="text">{{ numberComma(item.inqCnt) }} 조회</span>
                </div>
              </article>
            </li>
          </ul>
        </div>
        <!-- //board-list-wrapper -->
        <SSLPagination
            :paging="paging"
            :page-func="pageFunc"
        />
      </div>
      <!-- //board-list-container -->
    </div>

  </div>

</template>

<script>

import {computed, ref, watch} from 'vue';
import SSLPagination from '@/components/ssl/notice/SSLPagination';
import {dateToDateFormat, getItems, getPaging, lengthCheck, numberComma, timestampToDateFormat} from '@/assets/js/util';
import {useStore} from 'vuex';
import {ACT_GET_SSL_NTC_LIST} from '@/store/modules/ssl/ssl-index';
import {getExpsrLocCd, sslRoutes} from '@/assets/js/modules/ssl/ssl-common';
import {goBack} from '@/assets/js/modules/common/common';
import {useRoute, useRouter} from 'vue-router';
import {expsrLocCds} from '@/assets/js/modules/help/help-common';

export default {
  name: 'SSLNotice',
  components: {
    SSLPagination
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const isLoading = ref(false);
    const items = ref([]);
    const paging = ref({pageNo: 1, pageSize: 10, totalCount: 0});

    const distCrseNm = computed(() => sslRoutes[route.params.distCrseCd] ? sslRoutes[route.params.distCrseCd].name : 'SSL' );
    const expsrLocCd = computed(() => getExpsrLocCd(route.params.distCrseCd));

    const pageFunc = (num) => {
      router.push({query: {pageNo: num}});
    }

    const getNoticeList = () => {
      isLoading.value = true;
      store.dispatch(`ssl/${ACT_GET_SSL_NTC_LIST}`, {
        nowDate: dateToDateFormat(new Date(), 'yyyy-MM-dd'),
        expsrLocCd: `${expsrLocCds.EXPSR_LOC_CD_SSL_COMMON},${expsrLocCd.value ? expsrLocCd.value : expsrLocCds.EXPSR_LOC_CD_SSL_MAIN}`,
        pageNo: paging.value.pageNo,
        pageSize: paging.value.pageSize
      }).then(res => {
        if(lengthCheck(res)){
          items.value = getItems(res);
          paging.value = getPaging(res);
        }
        isLoading.value = false;
      }).catch(() => {});
    }

    watch(() => route.query, () => {

      paging.value.pageNo = route.query.pageNo > 0 ? parseInt(route.query.pageNo) : 1;
      getNoticeList();
    });

    paging.value.pageNo = route.query.pageNo > 0 ? parseInt(route.query.pageNo) : 1
    getNoticeList();

    return {
      distCrseNm,
      isLoading,
      items,
      paging,
      pageFunc,
      timestampToDateFormat,
      numberComma,
      goBack

    }

  }
}
</script>
