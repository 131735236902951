<template>
  <!-- cloud-header -->
  <LxpMobileHeader title="퀴즈출제결과 상세보기" id="kb-header">
    <template v-slot:left>
        <div class="util util-back">
            <a href="javascript:" class="util-actions util-actions-back" @click="closeLayer">
                <i class="icon-history-back"></i>
            </a>
        </div>
    </template>
  </LxpMobileHeader>
  <!-- //cloud-header -->
  <!-- cloud-main -->
  <main class="kb-main" id="kb-prep-quiz">
      <!-- main-view -->
      <div class="main-content">
        <div>
          <div class="view-contents">
            <!-- content-section -->
            <article class="content-section section-margin">
              <header class="section-header header-divider">
                <h4 class="title">퀴즈 출제 결과정보</h4>
              </header>
              <div class="text-item-container">
                <!-- kb-form-field -->
                <div class="text-item">
                  <div class="item-row row-contents">
                      <div class="item-column">
                          <p class="item-subtitle mb-2">강의명</p>
                          <h5 class="item-title">{{rsltItem.objNm}}</h5>
                      </div>
                  </div>
                </div>
                <!-- kb-form-field -->
                <div class="text-item">
                    <div class="item-row row-contents">
                        <div class="item-column">
                            <p class="item-subtitle mb-2">퀴즈목록명</p>
                            <h5 class="item-title">{{rsltItem.lrnEvlNm}}</h5>
                        </div>
                    </div>
                </div>
                <!-- kb-form-field -->
                  <div class="text-item">
                      <div class="item-row row-contents">
                          <div class="item-column">
                              <p class="item-subtitle mb-2">제출인원</p>
                              <h5 class="item-title">{{rsltItem.evlCnt}}</h5>
                          </div>
                      </div>
                  </div>
                <!-- //kb-form-field -->
              </div>
            </article>
            <!-- content-section -->
            <article class="content-section section-margin">
              <header class="section-header">
                <h3 class="title">퀴즈제출자명단 <em class="title-count">{{rsltItem.evlCnt}}</em></h3>
              </header>
              <div class="kb-table kb-table-v2 kb-table-v2-idx">
                <table>
                  <colgroup>
                      <col style="width:100px">
                      <col style="width:140px">
                      <col style="width:140px">
                      <col v-for="(idx) in rsltColCnt" :key="idx" style="width:auto" />
                      <col style="width:240px">
                  </colgroup>
                  <thead>
                    <tr>
                      <th><span class="th-title">NO</span></th>
                      <th><span class="th-title">직원명</span></th>
                      <th><span class="th-title">직원번호</span></th>
                      <th v-for="(idx) in rsltColCnt" :key="idx" style="width:auto"><span class="th-title">{{idx}}번</span></th>
                      <th><span class="th-title">제출일시</span></th>
                    </tr>
                  </thead>
                  <tbody class="align-center">
                    <tr v-for="(item, idx) in rsltDtlBodies" :key="idx">
                      <td><span class="td-text">{{idx+1}}</span></td>
                      <td><span class="td-text">{{item.lrnerNm}}</span></td>
                      <td><span class="td-text">{{item.lrnerId}}</span></td>
                      <td v-for="(ansItem, ansIdx) in item.ansrItem" :key="ansIdx"><span class="td-text">{{ansItem.inptAns}} ({{ansItem.crctAnsYn}})</span></td>
                      <td><span class="td-text">{{item.regDt}}</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </article>
          </div>
        </div>
      </div>
  </main>
  <!-- //cloud-main -->
</template>

<script>
import {onMounted, ref} from 'vue';
import {useStore} from 'vuex';
import {ACT_GET_QUIZ_RSLT_DTL_LIST} from '@/store/modules/quiz/quiz';
import LxpMobileHeader from '@/views/layout/lxp/header/mobile/LxpMobileHeader.vue';
import {getItems, lengthCheck, timestampToDateFormat} from '@/assets/js/util';

export default {
  name: "MobileQuizMngResultDetail",
  components: {LxpMobileHeader},
  props:{
    isQuizMngRsltDtlShow: Boolean,
    rsltItem: Object,
  },
  emits:['update:isQuizMngRsltDtlShow'],
  setup(props, {emit}){

    const store = useStore();
    const rsltDtlBodies = ref([]);
    const rsltDtlAns = ref([]);
    const rsltColCnt = ref(0);

    const getRsltDtlList = () => {
      store.dispatch(`quiz/${ACT_GET_QUIZ_RSLT_DTL_LIST}`, {
        lrnTyEvlMstSn: props.rsltItem.lrnTyEvlMstSn,
        lrnEvlMstSn: props.rsltItem.lrnEvlMstSn,
        distObjSn: props.rsltItem.distObjSn,
      }).then(res => {
        if (lengthCheck(res)) {
          rsltDtlBodies.value = getItems(res);
          rsltDtlAns.value = res.ansItems;

          rsltDtlBodies.value.forEach(item => {
            item.ansrItem = [];
            rsltDtlAns.value.forEach(ansItem => {
              if(item.lrnerId === ansItem.lrnerId){
                item.ansrItem.push(ansItem);
              }
              ansItem.crctAnsYn = ansItem.crctAnsYn === 'Y' ? 'O' : 'X'
            })
            item.regDt = timestampToDateFormat(item.regDt, 'yyyy.MM.dd hh:mm:ss')
          })

          rsltColCnt.value = parseInt(Math.max.apply(null, rsltDtlBodies.value.map(x => x.rsltCnt)));

        } else {
          rsltDtlBodies.value = [];
        }
      }).catch(e => {
        console.error(e);
      });
    };

    const closeLayer = () => {
      emit('update:isQuizMngRsltDtlShow', false)
      document.getElementById('kb-header').style.display = '';
      document.getElementById('kb-prep-quiz').style.display = '';
    }

    onMounted(()=>{
      getRsltDtlList();
    });

    return{
      rsltDtlBodies,
      rsltDtlAns,
      rsltColCnt,
      closeLayer,
    }
  }
}
</script>