<template>
  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-prep-group">
    <!-- main-header -->
    <div class="main-header">
      <div class="header-top main-component">
        <div class="header-column">
          <router-link :to="{name: 'GroupLearning'}" class="page-nav">
            <i class="icon-kb-nav-arrow"></i>
            <span class="text">이전</span>
          </router-link>
        </div>
      </div>
      <!-- main-header > header-title -->
      <div class="header-title">
        <h2 class="title">{{ boardSn > 0 ? '그룹관리' :  '그룹 생성하기' }}</h2>
      </div>
    </div>
    <!-- //main-header -->
    <!-- main-content -->
    <div class="main-content min-component">

        <section class="content-section section-forms">
          <!-- kb-form-fields -->
          <div class="kb-form-fields">
            <!-- kb-form-row:그룹명 -->
            <div class="kb-form-row">
              <div class="kb-form-column kb-form-column-title">
                <label class="kb-form-label">
                  <span class="kb-form-label-text">그룹명</span>
                  <span class="kb-form-label-text text-red"> *</span>
                </label>
              </div>
              <div class="kb-form-column column-whole">
                <input v-model="params.distCrseNm" type="text" class="kb-form-control" placeholder="그룹명을 입력하세요." :disabled="params.stt === '00'"/>
              </div>
            </div>
            <!-- kb-form-row:학습분야 -->
            <div class="kb-form-row">
              <div class="kb-form-column kb-form-column-title">
                <label class="kb-form-label">
                  <span class="kb-form-label-text">학습분야</span>
                  <span class="kb-form-label-text text-red"> *</span>
                  <span v-if="isMobile" class="text text-muted tip">그룹확정 후 변경 불가</span>
                </label>
              </div>
              <div class="kb-form-column">
                <SortButtonSelect3
                    v-if="isMobile"
                    v-model="params.distCrseLrnFldSn"
                    v-model:toggle="relToggle"
                    :options="fldOptions"
                    sequence-key="distCrseLrnFldSn"
                    name-key="distCrseLrnFldNm"
                    title="학습분야 선택"
                    button-class="kb-form-dropdown-btn"
                    :is-row="true"
                    :is-all="false"
                    :is-disabled="params.stt === '00'"
                />
                <SortButtonSelect
                    v-else
                    v-model="params.distCrseLrnFldSn"
                    v-model:toggle="relToggle"
                    :options="fldOptions"
                    sequence-key="distCrseLrnFldSn"
                    name-key="distCrseLrnFldNm"
                    title="학습분야 선택"
                    :is-row="true"
                    :is-all="false"
                    :is-disabled="params.stt === '00'"
                />
              </div>
              <div class="kb-form-column justify-content-end">
                <span class="kb-form-text text-muted">그룹확정 후 변경 불가</span>
              </div>
            </div>
            <!-- kb-form-row:그룹인원 -->
            <div class="kb-form-row">
              <div class="kb-form-column kb-form-column-title">
                <label class="kb-form-label">
                  <span class="kb-form-label-text">그룹인원</span>
                  <span class="kb-form-label-text text-red"> *</span>
                </label>
              </div>
              <div class="kb-form-column">
                <div class="kb-form-dropdown" :class="{'is-active' : peoplCountToggle}" data-offset="bottom-left">
                  <button class="kb-form-dropdown-btn" @click.stop="clickPeoplCount" :disabled="params.stt === '00'">
                    <span class="text ">{{ params.peoplCnt > 0 ? `${params.peoplCnt}명 (리더 포함)` : '그룹인원 선택' }}</span>
                    <i class="icon-arrow"></i>
                  </button>
                  <div class="kb-form-dropdown-options">
                    <div class="dropdown-option">
                      <ul class="dropdown-option-list">
                        <li v-for="num in 27" :key="num" class="dropdown-option-item">
                          <a href="javascript:" class="dropdown-option-link" :class="{'is-active' : params.peoplCnt === (num + 3)}" @click.stop="selectPeoplCount(num + 3)">
                            <span class="dropdown-option-text">{{ num + 3 }}명</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="kb-form-column justify-content-end">
                <span class="kb-form-text text-muted">설정한 인원 모집 완료 시 그룹 일괄 확정</span>
              </div>
            </div>
            <!-- kb-form-row:그룹목표 -->
            <div class="kb-form-row">
              <div class="kb-form-column kb-form-column-title">
                <label class="kb-form-label">
                  <span class="kb-form-label-text">그룹목표</span>
                  <span class="kb-form-label-text text-red"> *</span>
                </label>
              </div>
              <div class="kb-form-column column-whole">
                <input v-model="params.boardSumup" type="text" class="kb-form-control" placeholder="그룹의 달성 목표를 입력하세요." />
              </div>
            </div>
            <!-- kb-form-row:자기소개 -->
            <div class="kb-form-row">
              <div class="kb-form-column kb-form-column-title">
                <label class="kb-form-label">
                  <span class="kb-form-label-text">자기소개</span>
                  <span class="kb-form-label-text text-red"> *</span>
                </label>
              </div>
              <div class="kb-form-column column-whole">
                <textarea v-model="params.selfIntro" name="" id="" cols="30" rows="2" class="kb-form-control" placeholder="자기소개 및 각오를 입력하세요." :disabled="params.stt === '00'"></textarea>
              </div>
            </div>
            <!-- kb-form-row:비밀번호 사용여부 -->
            <div class="kb-form-row">
              <div class="kb-form-column kb-form-column-title">
                <label class="kb-form-label">
                  <span class="kb-form-label-text">비밀번호 사용여부</span>
                </label>
              </div>
              <div class="kb-form-column column-whole">
                <template v-if="!pwdUseYn">
                  <input v-model="tempPwd" type="password" class="kb-form-control" maxlength="4" placeholder="비밀번호 사용 안함" disabled="disabled" @input="checkPwd">
                </template>
                <template v-else>
                  <input v-model="tempPwd" type="password" class="kb-form-control" maxlength="4" placeholder="비밀번호를 입력해주세요" @input="checkPwd" :disabled="params.stt === '00'">
                </template>
              </div>
              <div class="kb-form-column justify-content-end">
                <!-- kb-form-switch -->
                <div class="kb-form-switch">
                  <label class="kb-form-switch-label">
                    <input v-model="pwdUseYn" type="checkbox" class="kb-form-switch-input" @change="changePwdUseYn" :disabled="params.stt === '00'"/>
                    <span class="kb-form-switch-slider"></span>
                  </label>
                </div>
                <!-- //kb-form-switch -->
              </div>
            </div>
            <!-- //kb-form-row -->
          </div>
          <!-- //kb-form-fields -->
          <div class="section-bottom">
            <p class="notice-icontext">1인당 1개 분야 ・ 1개 그룹만 참여 가능합니다. </p>
          </div>
        </section>
        <div class="page-buttons">
          <button class="kb-btn kb-btn-primary kb-btn-lg" @click="clickSave"><span class="text">{{ boardSn > 0 ? '저장' : '그룹생성' }}</span></button>
        </div>
    </div>
    <!-- //main-content -->

  </main>
  <!-- end::kb-main -->
</template>
<script>
import SortButtonSelect from "@/components/common/SortButtonSelect";
import SortButtonSelect3 from "@/components/common/SortButtonSelect3";
import {groupLearningMngSetup} from "@/assets/js/modules/groupLearing/group-learning-mng-setup";

export default {
  name: "GroupLearningMng",
  components: {SortButtonSelect3, SortButtonSelect},
  setup: groupLearningMngSetup
}
</script>