<template>
  <div class="player-detail-series" ref="seriesEl">
    <ul class="hrdcloud-list">
      <template v-for="(item, idx) in series" :key="idx" >
      <li v-if="item.objSn!=thisObj" class="hrdcloud-item">
        <article class="hrdcloud-card">
          <router-link :to="{ name: 'HrdCloudContentBridge',params: {lrnObjDtlDistSn: item.lrnObjDtlDistSn} }">
            <div class="hrdcloud-image">
              <div class="image">
                <a href="javascript:" class="image-link">
                  <CommonImage :img-src="getThumbUrl(item.thumb)" />
                </a>
              </div>
            </div>
          </router-link>
          <div class="hrdcloud-content">
            <div class="content-category">
              <span class="text">{{ getCateNm(item.category3) }}</span>
            </div>
            <div class="content-title">
              <h5 class="title">
                <router-link :to="{ name: 'HrdCloudContentBridge',params: {lrnObjDtlDistSn: item.lrnObjDtlDistSn} }" class="title-link">
                  {{ item.objNm }}
                </router-link>
              </h5>
            </div>
            <div class="content-info-and-meta">
              <div class="content-info">
                <div class="info">
                  <i class="icon-views"></i>
                  <span class="text">{{ item.inqCnt }}조회</span>
                </div>
                <div class="info">
                  <i class="icon-like"></i>
                  <span class="text">{{ item.lkCnt }}</span>
                </div>
              </div>
              <div class="content-meta">
                <span class="text">{{ secondToFormat(item.cttSz, 'hh:mm:ss') }}</span>
              </div>
            </div>
          </div>
        </article>
      </li>
      </template>

    </ul>

  </div>
  <div class="divider"></div>
</template>

<script>
import {useStore} from 'vuex';
import {onMounted, ref} from 'vue';
import {ACT_GET_HRDCLOUD_SERIES_LIST} from '@/store/modules/prep/prep';
import {getCheckItems, secondToFormat} from '@/assets/js/util';
import {getCateNm} from '@/assets/js/modules/hrd/hrd-common';
import CommonImage from "../../common/CommonImage.vue";
import {getThumbUrl} from '@/assets/js/modules/course/course-common';

export default {
  name: 'HrdCloudMobileSeriesList',
  components: {CommonImage},
  props: {
    crse: Object
  },
  setup(props) {
    const store = useStore();
    const series = ref([]);
    const isSeriesActive = ref(false);
    const thisObj = ref([]);
    const seriesEl = ref(null);


    const getHrdCloudSeriesList = () => {
      store.dispatch(`prep/${ACT_GET_HRDCLOUD_SERIES_LIST}`,{
        cttSnLst: props.crse.cttSnLst
      }).then(res => {
        series.value = getCheckItems(res);
        thisObj.value = props.crse.objSn;
        setTimeout(() => {seriesEl.value.scrollIntoView({block:'start',behavior:'smooth'});}, 100);
      }).catch(e => {
        console.error(e);
      })
    };

    // watch(() => props.crse.cttSnLst, () => {
    //   console.log('watch series : ',props.crse.cttSnLst);
    //   getHrdCloudSeriesList();
    // });

    onMounted(() => {
      getHrdCloudSeriesList();
    });

    return {
      secondToFormat,
      series,
      isSeriesActive,
      getCateNm,
      getThumbUrl,
      thisObj,
      seriesEl,
    }
  }
};
</script>
