<template>
  <div ref="dom" class="layer-container" :class="{ 'is-active' : modelValue }">
    <div class="layer" id="layer-coldcall" style="padding:0px">
      <div class="layer-contents">
        <div class="layer-icon">
          <img v-if="type==='coldcall'||type==='easy'" src="../../assets/lxp/images/icon/icon_coldcall_2.svg" alt="" />
          <img v-else-if="type==='resume'||type==='easyAbort'" src="../../assets/lxp/images/icon/icon_coldcall_3.svg" alt="" />
        </div>
        <strong class="layer-title">{{ title }}</strong>
        <div class="layer-content">
          <slot name="content" />
        </div>
      </div>
      <div v-if="type==='easy'||type==='easyAbort'" class="layer-actions">
        <button class="kb-btn-layer" @click="func(false)">
          <strong class="text">확인</strong>
        </button>
      </div>
      <div v-else class="layer-actions">
        <button class="kb-btn-layer" @click="func.respond('reset')">
          <strong class="text text-gold">다시학습</strong>
        </button>
        <button class="kb-btn-layer" @click="func.respond('continue')">
          <strong class="text">계속학습</strong>
        </button>
      </div>
<!--      <input type="text" id="focusHolder" style="display:none">-->
    </div>
  </div>
</template>

<script>
import {onBeforeUnmount, onMounted, ref, watch} from 'vue';

export default {
  name: 'HrdCloudStopAlert',
  props: {
    modelValue: Boolean,
    title: String,
    type: String, // 콜드콜 중단 알림, 이어보기 알림
    func: Object,
    wrapperDom: Object
  },
  emits: ['update:wrapperDom'],
  setup(props, {emit}) {

    // 창이 열린 경우 keyboard enter, space 차단한다.
    // const enterKeydeny = (event) => {
    //   if(event.keyCode==13||event.keyCode==32){
    //     console.log("deny", event.keyCode);
    //     event.preventDefault();
    //   }
    // };
    //

    onMounted( () =>{
      console.log("focus holder");
      // document.getElementById("focusHolder").focus();
      if(props.type=="easyAbort" && props.modelValue)alert("학습이 중단되었습니다.");
    });
    //
    onBeforeUnmount(() => {
       // window.removeEventListener("keydown", enterKeydeny);
    });


    const dom = ref(null);



    watch(() => dom.value, () => {
      if (dom.value) {
        emit('update:wrapperDom', dom.value);
      }
    });

    return {
      dom
    }
  }
};
</script>