<template>
  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-support">
    <template v-if="isHeader">
      <!-- main-header -->
      <LxpMobileHeader title="학비 지원신청">
        <template v-slot:right>
          <div v-if="guideFile.length > 0" class="util util-download">
            <a href="javascript:" class="util-actions util-actions-download" @click="isFileAtchListShow = true">
              첨부파일
              <i class="icon-download"></i>
            </a>
          </div>
        </template>
        <template v-slot:left>
          <div class="util util-back">
            <a href="javascript:" class="util-actions util-actions-back" @click="goBack">
              <i class="icon-history-back"></i>
            </a>
          </div>
        </template>
      </LxpMobileHeader>
      <!-- //main-header -->
      <!-- main-content -->
      <div v-if="!isTuition202309ModalActive && !isTuitionSubModalActive" class="main-header main-header-visual">
        <div class="header-snb">
          <nav class="snb">
            <ul class="snb-list">
              <li class="snb-item">
                <button :class="{ 'is-active' : menu === 1 }" @click="menu = 1">학비 지원 신청</button>
              </li>
              <li class="snb-item">
                <button :class="{ 'is-active' : menu === 2 }" @click="menu = 2">부점장최고경영자과정 지원신청</button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </template>

    <template v-if="!isTuition202309ModalActive && !isTuitionSubModalActive">
      <div v-if="menu === 1" class="main-content main-component">
        <div class="support-view-container">
          <!-- page-buttons -->
          <div class="page-buttons">
            <a href="javascript:" class="kb-btn kb-btn-primary" @click="isApplyYn"><span class="text">지원신청</span></a>
          </div>
          <div class="view-contents">
            <!-- section: 학비 지원안내 -->
            <section class="content-section">
              <header class="section-header">
                <h4 class="title">학비 지원안내</h4>
              </header>
              <div class="section-content">
                <div class="content-item">
                  <div class="title">지원대상</div>
                  <div class="desc">
                    <p>지급일 현재 재직중인 직원(일반 ・ 계약)</p>
                    <p class="warn">※ 지급일 현재 정직자 및 파트타이머 제외</p>
                    <p class="warn">단, 국내외 석사학위 취득 목적의 자기계발휴직 Type I 선발자의 경우 지원</p>
                    <p class="warn">※ 학점은행제의 경우 학사 이상 학위 소지자는 지원대상에서 제외(단, 만 50세 이상 가능)</p>
                  </div>
                </div>
              </div>
            </section>

            <section class="content-section">
              <header class="section-header">
                <h4 class="title">신청기한</h4>
              </header>
              <div class="section-content">
                <div class="content-item">
                  <div class="desc">
                    <span><span style="font-weight: bold">학기 종료일(종강일)</span>로부터 <span class="text-red">6개월 이내</span></span>
                  </div>
                </div>
              </div>
            </section>


            <section class="content-section">
              <header class="section-header">
                <h4 class="title">지원 절차 및 시기</h4>
              </header>
              <div class="section-content">
                <div class="content-item">
                  <h5 class="b-title">Step1.</h5>
                  <p class="desc">학기 종료 후 필요서류 준비</p>
                </div>
                <div class="content-item">
                  <h5 class="b-title">Step2.</h5>
                  <p class="desc">스타런에서 신청</p>
                </div>
                <div class="content-item">
                  <h5 class="b-title">Step3.</h5>
                  <p class="desc">HR부(인재개발) 심사</p>
                </div>
                <div class="content-item">
                  <h5 class="b-title">Step4.</h5>
                  <p class="desc">급여계좌로 지급(전산 신청일 기준 <span class="text-red">익월 15일경</span>)</p>
                </div>
              </div>
            </section>

            <!-- section: 지원 내용 -->
            <section class="content-section">
              <header class="section-header">
                <h4 class="title">지원 내용</h4>
              </header>
              <div class="section-content">
                <div class="content-item">
                  <h5 class="b-title">정규대학 ・ 대학원</h5>
                  <ul class="desc-type-title baseline">
                    <li>
                      <h6>지원 과정</h6>
                      <p>- 학위가 인정되는 국내 소재의 대학 및 대학원</p>
                    </li>
                    <li>
                      <h6> </h6>
                      <p>- 2~3년제 ・ 사이버대학 포함</p>
                    </li>
                    <li>
                      <h6>지원 범위<sup class="text-red">주1)</sup></h6>
                      <p>등록금의 50%</p>
                    </li>
                    <li>
                      <h6>지원 조건</h6>
                      <p>평균 평점 B학점(2.8/4.5) 또는 80점(80/100)이상</p>
                    </li>
                    <li>
                      <h6>지원 한도</h6>
                      <p>전문대 - 4학기 이내</p>
                    </li>
                    <li>
                      <h6> </h6>
                      <p>대학교(학사) - 8학기 이내</p>
                    </li>
                    <li>
                      <h6> </h6>
                      <p>대학원(석사) - 5학기 이내</p>
                    </li>
                  </ul>
                  <div class="desc">
                    <p class="sub-text">각 학위별 지원한도 내에서는 학교가 상이해도 지원 가능.</p>
                    <p class="sub-text">계절학기도 지원 가능하나 1학기로 산정</p>
                  </div>
                </div>
                <div class="content-item">
                  <h5 class="b-title">학점은행제</h5>
                  <ul class="desc-type-title baseline">
                    <li>
                      <h6>지원 과정</h6>
                      <p>학점은행제 학점 취득이 인정되는 교육기관</p>
                    </li>
                    <li>
                      <h6> </h6>
                      <p>대학부설 사회(평생)교육원 학습 과목 ・ 시간제등록 학습과목 포함</p>
                    </li>
                    <li>
                      <h6>지원 범위<sup class="text-red" style="font-size:10px">주1)</sup></h6>
                      <p>등록금의 50%</p>
                    </li>
                    <li>
                      <h6>지원 조건</h6>
                      <p>평균 평점 B학점(2.8/4.5) 또는 80점(80/100)이상</p>
                    </li>
                    <li>
                      <h6>지원 한도</h6>
                      <p>140학점 이내<sup class="text-red" style="font-size:10px">주2)</sup></p>
                    </li>
                  </ul>
                  <div class="desc">
                    <p class="sub-text">각 학위별 지원한도 내에서는 학교가 상이해도 지원 가능.</p>
                    <p class="sub-text">계절학기도 지원 가능하나 1학기로 산정</p>
                  </div>
                </div>
                <div class="content-item">
                  <h5 class="b-title">한국방송통신 대학 ・ 대학원</h5>
                  <ul class="desc-type-title baseline">
                    <li>
                      <h6>지원 과정</h6>
                      <p>한국방송통신 대학 및 대학원</p>
                    </li>
                    <li>
                      <h6>지원범위<sup class="text-red" style="font-size:10px">주1)</sup></h6>
                      <p>등록금의 100%</p>
                    </li>
                    <li>
                      <h6>지원조건</h6>
                      <p>평균 평점 C학점(1.7/4.5) 이상</p>
                    </li>
                    <li>
                      <h6>지원한도</h6>
                      <p>학기 및 학점 제한없음</p>
                    </li>
                  </ul>
                  <div class="desc">
                    <p class="sub-text">각 학위별 지원한도 내에서는 학교가 상이해도 지원 가능.</p>
                    <p class="sub-text">계절학기도 지원 가능하나 1학기로 산정</p>
                  </div>
                </div>

                <div class="section-content">
                  <div class="content-item">
                    <div class="b-title">지원제외과정</div>
                    <div class="desc">
                      <p>- 학위 미인정 과정 및 박사과정</p>
                      <p>- <span class="text-decoration-underline">근무시간</span>중에 정규수업을 실시하는 과정</p>
                      <p>&nbsp;&nbsp;&nbsp;(인사규정 제10장 제54조 근무시간 준수)</p>
                      <p>- 학기 시작일이 입행일 이전인 경우</p>
                      <p>- 수강기간(학기)이 휴직/정직기간에 전부 또는 일부 포함된 경우</p>
                    </div>
                  </div>
                </div>


                <div class="content-item">
                  <ul class="desc-context">
                    <li>
                      <p class="mt-2">주1) <span class="font-body14B">지원범위, 입학금</span></p>
                      <p>- 재입학과 편입학의 경우 입학금은 지원 제외<br>&nbsp;&nbsp;&nbsp;(한국방송통신 대학ㆍ대학원은 지원)</p>
                      <p>- 학비는 실부담금에 대한 지원으로, 현금이나<br>&nbsp;&nbsp;&nbsp;현물로 반대급부(장학금 등)를 받은 경우 해당<br>&nbsp;&nbsp;&nbsp;금액은 차감 지원</p>
                      <p><span class="font-bodyB">단, 학업성취도의 결과인 교내 성적장학금은 차감하지 않고 지원</span>(한국방송통신 대학ㆍ대학원은 해당없음)</p>
                      <p>- 수업료, 입학금 등 필수 경비 외 선택적 경비<br>&nbsp;&nbsp;&nbsp;(학생회비, 원우회비, 동문회비 등)지원 불가</p>
                      <p class="mt-2">주2) 2007.1.1 이후 정규 대학ㆍ대학원 학비지원을 받은 직원이 학점은행제를 신청할 경우, 정규 대학ㆍ대학원 1학기당 학점은행제 지원한도를 18학점씩 차감.</p>
                      <p>반대의 경우에도 동일 기준 적용(학점은행제 18학점 당 정규 대학ㆍ대학원 1하기 적용)</p>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
            <!-- section: 지원절차 -->

            <section class="content-section">
              <header class="section-header">
                <h4 class="title">필요서류</h4>
              </header>
              <div class="section-content">
                <div class="content-item">
                  <div class="desc">
                    <p>1. 교육기관에서 발급한 등록금납입증명서<br>&nbsp;&nbsp;&nbsp;(입금영수증 인정 불가)</p>
                    <p>2. 해당 학기 성적증명서<br>&nbsp;&nbsp;&nbsp;s(진위확인코드 16자리 필수)</p>
                    <p>3. 장학금 (비)수혜증명서, 연말정산용 교육비납입증명서 中 택1</p>
                  </div>
                </div>
              </div>
            </section>

          </div>
        </div>
      </div>
      <!-- //main-content -->

      <!-- main-content -->
      <div v-else class="main-content min-component">
        <div class="support-view-container">
          <!-- page-buttons -->
          <div class="page-buttons">
            <a href="javascript:" class="kb-btn kb-btn-primary" @click="subTuitOpen"><span class="text">지원신청</span></a>
          </div>
          <div class="view-contents">
            <!-- section: 지원안내 -->
            <section class="content-section">
              <header class="section-header">
                <h4 class="title">부점장최고경영자과정 지원안내</h4>
                <div class="header-content-text">- 지급일 현재 재직 중인 부점장<br>
                  - L3이상 팀원/팀장 중 국은인상 수상자<br>
                  &nbsp;&nbsp;&nbsp;(업무추진실적최고 부문 및 기타업무추진우수 부문 수상자)<br>
                  ※지급일 현재 휴・정직자 및 후선보임자 제외
                </div>
              </header>

              <section class="content-section">
                <header class="section-header">
                  <h4 class="title">신청기한</h4>
                </header>
                <div class="section-content">
                  <div class="content-item">
                    <div class="desc">
                      <span><span style="font-weight: bold">등록금 납입일</span>로부터 <span class="text-red">3개월 이내</span></span>
                    </div>
                  </div>
                </div>
              </section>

              <section class="content-section">
                <header class="section-header">
                  <h4 class="title">지원 절차 및 시기</h4>
                </header>
                <div class="section-content">
                  <div class="content-item">
                    <h5 class="b-title">Step1.</h5>
                    <p class="desc">등록금 납입 후 필요서류 준비</p>
                  </div>
                  <div class="content-item">
                    <h5 class="b-title">Step2.</h5>
                    <p class="desc">스타런에서 신청</p>
                  </div>
                  <div class="content-item">
                    <h5 class="b-title">Step3.</h5>
                    <p class="desc">HR부(인재개발) 심사</p>
                  </div>
                  <div class="content-item">
                    <h5 class="b-title">Step4.</h5>
                    <p class="desc">급여계좌로 지급(전산 신청일 기준 <span class="text-red">익월 15일경</span>)</p>
                  </div>
                </div>
              </section>

              <div class="content-item">
                <h5 class="b-title">지원내용</h5>
                <ul class="desc-type-title baseline">
                  <li>
                    <h6>지원과정</h6>
                    <p>- 대학교 및 전문교육기관이 주관하는 최고경영자과정 등 지역 내 유수의 특별과정</p>
                  </li>
                  <li>
                    <h6></h6>
                    <p>- 스포츠관련 교육과정은 대학교 주관과정만 지원 가능</p>
                  </li>
                  <li>
                    <h6>지원금액</h6>
                    <p>- 5백만원 한도 내에서 교육비의 80%지원</p>
                  </li>
                  <li>
                    <h6></h6>
                    <p>- 연수비용만 지원 가능, 원우회비, 세미나비 등은 지원 불가</p>
                  </li>
                  <li>
                    <h6>지원횟수</h6>
                    <p>- 부점장 재직기간 중 개인별 1회</p>
                  </li>
                  <li>
                    <h6></h6>
                    <p>&nbsp;&nbsp;&nbsp;단, 전략회의 수상 부점장은 1회 추가 지원하며, 개인별 최대 2회까지 지원</p>
                  </li>
                  <li>
                    <h6></h6>
                    <p>&nbsp;&nbsp;&nbsp;2회 합산신청 가능(HR부(인재개발) 담당자에게 별도 요청 필요)</p>
                  </li>
                  <li>
                    <h6>지원제외<br>과정</h6>
                    <p>- 근무시간(인사규정 제10장 제54조 근무시간 준수) 중에 정규수업을 실시하는 과정</p>
                  </li>
                  <li>
                    <h6></h6>
                    <p>- 교육기간이 1년(12개월)을 초과하는 과정</p>
                  </li>
                  <li>
                    <h6></h6>
                    <p>- 자격증 취득 교육과정</p>
                  </li>
                </ul>
              </div>
            </section>


              <section class="content-section">
                <header class="section-header">
                  <h4 class="title">필요서류</h4>
                </header>
                <div class="section-content">
                  <div class="content-item">
                    <div class="desc">
                      <p>교육기관에서 발급한 등록금납입증명서(입금영수증 인정 불가)</p>
                    </div>
                  </div>
                </div>
              </section>

          </div>
        </div>
      </div>
      <!-- //main-content -->
    </template>

    <SupportTuition202309MobileModal v-model="isTuition202309ModalActive" />
<!--    <SupportTuitionMobileModal v-model="isTuitionModalActive" />-->

    <SupportTuitionSubMobileModal v-model="isTuitionSubModalActive" />
  </main>
  <!-- end::kb-main -->
  <SupportMobileFileAtch v-if="isFileAtchListShow" v-model:isFileAtchListShow="isFileAtchListShow" :file-key="guideFile"/>
</template>

<script>
import {computed, ref} from 'vue';
import {getItems} from "@/assets/js/util";
import {changeNotice, downloadGuideFiles, getDsjChk} from '@/assets/js/modules/support/support-common'; //, getRefundChk
import LxpMobileHeader from "@/views/layout/lxp/header/mobile/LxpMobileHeader";
import SupportMobileFileAtch from "@/views/pages/support/mobile/SupportMobileFileAtch";
import {checkRefundConsult} from "@/assets/js/modules/consult/consult-common";
import {
  MUT_CLOSE_SUPPORT,
  MUT_SHOW_SUPPORT,
  ACT_GET_LEARN_SUPPORT_SCHOOL_NOT_END_CNT
} from "@/store/modules/support/support";
import {useStore} from "vuex";
import {useRouter} from "vue-router/dist/vue-router";
import {useAlert} from "@/assets/js/modules/common/alert";
import SupportTuitionSubMobileModal from "@/components/support/mobile/SupportTuitionSubMobileModal";
import SupportTuition202309MobileModal from "@/components/support/mobile/SupportTuition202309MobileModal";
// import SupportTuitionMobileModal from "@/components/support/mobile/SupportTuitionMobileModal";

export default {
  name: 'SupportTuition',
  components: {
    SupportTuitionSubMobileModal,
    SupportTuition202309MobileModal,
    // SupportTuitionMobileModal,
    SupportMobileFileAtch, LxpMobileHeader},
  setup() {

    const store = useStore();
    const router = useRouter();
    const {showMessage, showConfirm} = useAlert();

    const guideFile = computed(() => downloadGuideFiles.find(x => x.page === 'school').fileKey);

    // const isTuitionModalActive = ref(false);
    const isTuition202309ModalActive = ref(false);
    const isTuitionSubModalActive = ref(false);
    const isFileAtchListShow = ref(false);

    const menu = ref(1);

    const isActive = computed(() => store.state.support.isSupport);
    const isHeader = computed(() => store.state.support.isHeader);

    const clickSupport = () => {
      store.commit(`support/${MUT_SHOW_SUPPORT}`);
    }

    const goBack = () => {
      if(!store.state.support.main.show) {
        store.commit(`support/${MUT_CLOSE_SUPPORT}`);
      } else {
        router.go(-1);
      }
    }


    const subTuitOpen = () => {
      // let chkVal = await getRefundChk();
      // if(getItems(chkVal) > 0){
      //   // showMessage('과정미수료 환입비용 미처리내역이 있습니다.<br>환입비용 처리 후 신청해주시기 바랍니다.');
      //   showMessage('미지급 또는 미환입 내역이 있습니다.<br>완료 후 신청해 주시기 바랍니다.');
      //   isTuitionSubModalActive.value = false;
      //   return false;
      // }else{
      //   isTuitionSubModalActive.value = true;
      // }

      checkRefundConsult(`#lrnerNm#님은 연수과정 미수료에 의한 <br>교육훈련비 #rtnAmt#원(#count#건) 환입 대상자 입니다.<br>환입 완료 전까지 <span style=color:#FF0000!important;>연수신청(자기주도학습지원 포함)이 제한</span>됩니다.<br><br> 연수비용 환입은 HR부(인재개발)<br>최슬비대리(☎7880)에게<br>문의 부탁드립니다.`, tuitionModalOpen );
    };

    const tuitionModalOpen=()=>{
      showConfirm({text: changeNotice, callback: () => {isTuitionSubModalActive.value = true;}});
    }

    // 미처리내역 확인 -- 미지급, 학습결과등록 미처리내역이 있을 경우 신청불가
    const isApplyYn  = async() => {

      //지원가능 대상자 체크
      let chkVal = await getDsjChk();
      if (getItems(chkVal) > 0){
        showMessage('학비 지원 신청 대상이 아닙니다.');
        isTuition202309ModalActive.value = false;
        return false;
      }else{
        // chkVal = await getRefundChk();
        // if(getItems(chkVal) > 0){
        //   // showMessage('과정미수료 환입비용 미처리내역이 있습니다.<br>환입비용 처리 후 신청해주시기 바랍니다.');
        //   showMessage('미지급 또는 미환입 내역이 있습니다.<br>완료 후 신청해 주시기 바랍니다.');
        //   isTuitionModalActive.value = false;
        //   return false;
        // }else{
        //   rstRegChk();
        // }

        checkRefundConsult(`#lrnerNm#님은 연수과정 미수료에 의한 <br>교육훈련비 #rtnAmt#원(#count#건) 환입 대상자 입니다.<br>환입 완료 전까지 <span style=color:#FF0000!important;>연수신청(자기주도학습지원 포함)이 제한</span>됩니다.<br><br> 연수비용 환입은 HR부(인재개발)<br>최슬비대리(☎7880)에게<br>문의 부탁드립니다.`, rstRegChk );
      }
    }

    const rstRegChk = ()=>{
      store.dispatch(`support/${ACT_GET_LEARN_SUPPORT_SCHOOL_NOT_END_CNT}`).then(res => {

        if(res && res.schlNotEndCnt && Number(res.schlNotEndCnt) > 0){
          showMessage('지원내역 또는 학습결과 미등록 내역이 있습니다.<br>해당 내역을 완료 하시고 신청해 주세요. ');
          isTuition202309ModalActive.value = false;
          return false;
        } else {
          showConfirm({text: changeNotice, callback: () => {isTuition202309ModalActive.value = true;}});
        }
      }).catch(e => {
        console.error(e);
      });
    }

    return {
      guideFile,
      isTuition202309ModalActive,
      isTuitionSubModalActive,
      menu, isFileAtchListShow, isActive,
      clickSupport, goBack, isHeader, isApplyYn, subTuitOpen
    }
  }
};
</script>