<template>
  <div v-if="confirm.show" class="kb-alert-container" :class="{'is-active': confirm.show}">
    <div v-if="confirm.show" class="kb-alert">
      <div class="kb-alert-contents">
        <strong class="kb-alert-title" v-html="confirm.title"></strong>
        <p class="kb-alert-text" v-html="confirm.text"></p>
      </div>
      <div class="kb-alert-actions">
        <button @click="firstButton" class="kb-btn-alert"><span class="text" v-html="confirm.firstBtnTitle"></span></button>
        <button @click="secondButton" class="kb-btn-alert"><span class="text" v-html="confirm.secondBtnTitle"></span></button>
        <button @click="cancelConfirm" class="kb-btn-alert"><span class="text">취소</span></button>
      </div>
    </div>
  </div>
</template>
<style scoped>
.lxp-mobile-layout .alert-container .alert-contents {justify-content: center;}
</style>
<script>
import {useStore} from 'vuex';
import {computed} from 'vue';
import {MUT_CLOSE_CUSTOM_CONFIRM} from '@/store/modules/common/common-index';

export default {
  name: 'LxpCustomConfirm',
  setup(){
    const store = useStore();
    const confirm = computed(() => store.state.common.customConfirm);
    // 확인콜백
    const firstButton = () => {
      if(confirm.value.firstCallback instanceof Function) confirm.value.firstCallback();
      store.commit(`common/${MUT_CLOSE_CUSTOM_CONFIRM}`);
    };

    const secondButton = () => {
      console.log('secondButton');
      if(confirm.value.secondCallback instanceof Function) confirm.value.secondCallback();
      store.commit(`common/${MUT_CLOSE_CUSTOM_CONFIRM}`);
    };
    // 취소콜백
    const cancelConfirm = () => {
      if(confirm.value.cancelCallback instanceof Function) confirm.value.cancelCallback();
      store.commit(`common/${MUT_CLOSE_CUSTOM_CONFIRM}`);
    }

    return {
      confirm,
      firstButton,
      secondButton,
      cancelConfirm
    }
  }
};
</script>