import {guid} from '@/assets/js/ui.init';
import navigationUtils from '@/assets/js/navigationUtils';

const isMobile = navigationUtils.any();
const uuid = guid(12);

const bridgeRoutes = [
    {
        path: '/bridge',
        component: () => import('@/views/layout/BlankLayout'),
        children: [
            {
                path: ':contents/sso',
                name: "BridgeSSO",
                component: () => import('@/views/auth/BridgeSSO.vue'),
            },
        ],
    },
    {
        path: '/bridge',
        component: () => import('@/views/layout/BlankLayout'),
        children: [
            {
                name: "TimetableSurvey",
                path: `:distCrseSn/${uuid}/survey`,
                component: () => isMobile ? import('@/views/pages/survey/mobile/MobileTimetableSurvey.vue') : import('@/views/pages/survey/TimetableSurvey.vue'),
            },
        ],
    },
];

export const setBridgeRoutes = (routes) => {
    routes.push(...bridgeRoutes);
}
