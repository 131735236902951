<template>
  <template v-if="isMobile">
    <div class="section-bottom mt-5">
      <div class="bartexts">
        <span class="text text-brown">방문자수 <strong class="text-black">Today</strong> {{ numberComma(uv.todayUvCnt) }}({{ numberComma(uv.todayCmptnCnt) }}건)</span>
        <span class="text text-brown"> <strong class="text-black">Total</strong> {{ numberComma(uv.totalUvCnt) }}({{ numberComma(uv.totalCmptnCnt) }}건)</span>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="header-column">
      <div class="text-group">
        <span class="text">방문자수 <strong class="text-black">Today</strong> {{ numberComma(uv.todayUvCnt) }}({{ numberComma(uv.todayCmptnCnt) }}건)</span>
        <span class="text divider"> <strong class="text-black">Total</strong> {{ numberComma(uv.totalUvCnt) }}({{ numberComma(uv.totalCmptnCnt) }}건)</span>
      </div>
    </div>
  </template>
</template>

<script>
import {computed, reactive} from 'vue';
import {getHrdUvList} from '@/assets/js/modules/hrd/hrd-common';
import {numberComma} from '@/assets/js/util';
import {useStore} from 'vuex';

export default {
  name: 'HrdCloudVisitor',
  setup() {
    const store = useStore();
    const isMobile = computed(() => store.state.auth.isMobile);

    const uv = reactive({
      totalUvCnt: 0,
      todayUvCnt: 0,
      totalCmptnCnt: 0,
      todayCmptnCnt: 0,
    });

    getHrdUvList(uv);

    return {
      isMobile, uv, numberComma
    }
  }
};
</script>