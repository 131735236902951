<template>
  <template v-if="isLoading && !isMobile">
    <article class="content-section">
      <LoadingDiv v-model="isLoading"/>
    </article>
  </template>
  <template v-else>
    <article class="content-section">
      <template v-if="isMobile">
        <header class="section-header">
          <h4 class="title">HRD 클라우드 학습현황</h4>
        </header>
      </template>
      <div :class="[isMobile ? 'text-item-container' : 'roundedbox-wrap']" data-content="between">
        <template v-if="!lrnSecItems.isLoading">
          <RoundedBoxToolTip :title="lrnSecItems.title" :show-all="lrnSecItems.showAll" :data="lrnSecItems.data" :unit="lrnSecItems.unit" :is-mobile="isMobile"/>
        </template>
        <template v-else>
          <LoadingDiv v-model="lrnSecItems.isLoading"/>
        </template>
        <template v-for="(item, idx) in lrnItems" :key="idx">
          <RoundedBox :title="item.title" :show-all="item.showAll" :data="item.data" :unit="item.unit" :func="item.func" :is-mobile="isMobile"/>
        </template>
      </div>
    </article>
    <article class="content-section">
      <template v-if="isMobile">
        <header class="section-header">
          <h4 class="title">HRD 클라우드 활동현황</h4>
        </header>
      </template>
      <div :class="[isMobile ? 'symbol-item-wrap' : 'symbol-card-wrap']" data-content="row">
        <template v-for="(item, idx) in actItems" :key="idx">
          <SymbolCard :title="item.title" :type="item.type" :data="item.data" :show-detail="item.showDetail" :func="item.func" :is-mobile="isMobile" />
        </template>
      </div>
    </article>
  </template>
</template>

<script>
import {computed, ref, toRefs, watch} from 'vue';
import RoundedBox from '@/components/history/module/RoundedBox';
import RoundedBoxToolTip from '@/components/history/module/RoundedBoxToolTip.vue';
import {useStore} from 'vuex';
import {
  ACT_GET_HRDCLOUD_HISTORY_STATISTICS,
  ACT_GET_HRDCLOUD_HISTORY_STATISTICS_LRNSEC
} from '@/store/modules/history/history';
import {dateToDatePart, getCheckItem, lengthCheck} from '@/assets/js/util';
import SymbolCard from '@/components/history/module/SymbolCard';
import LoadingDiv from '@/components/common/LoadingDiv.vue';

export default {
  name: 'Total',
  components: {LoadingDiv, SymbolCard, RoundedBox, RoundedBoxToolTip},
  props: {
    year: { type: [Number, String], default: dateToDatePart(new Date()).year},
    month: { type: [Number, String], default: dateToDatePart(new Date()).month},
    mlgModal: Boolean,
    focusFunc: Object
  },
  setup(props) {
    const store = useStore();
    const isMobile = computed(() => store.state.auth.isMobile);
    const isLoading = ref(true);
    const {year, month, focusFunc} = toRefs(props);

    const addModal = computed(() => store.state.hrdcloud.added.show);
    const subscriptionModal = computed(() => store.state.hrdcloud.subscribed.show);

    // const yearStr = computed(() => store.state.hrdcloud.share.year);

    const actItems = ref([
      { type: 'subscribe', title: '구독한 채널', showDetail: true, keys: ['totalSubscribe'], data: 0, func: focusFunc.value.openSubscriptionModal },
      { type: 'add', title: '담은 콘텐츠', showDetail: true, keys: ['totalKeep'], data: 0, func: focusFunc.value.openAddModal },
      { type: 'comment', title: '남긴 댓글', showDetail: false, keys: ['totalCmnt'],  data: 0 },
      { type: 'like', title: '남긴 좋아요', showDetail: false, keys: ['totalLike'], data: 0 },
      { type: 'share', title: '공유', showDetail: true, keys: ['totalShare'], data: 0 , func: focusFunc.value.openShareModal},
    ]);

    const lrnItems = ref([
      { title: '학습완료', showAll: true, keys: ['yearLrnCmptn', 'monthLrnCmptn'], data: {month: 0, year: 0}, func: focusFunc.value.fullHistory },
      { title: '학습일수', showAll: true, keys: ['yearLrnDayCnt','monthLrnDayCnt'], unit: 'day', data: {month: 0, year: 0}, func: focusFunc.value.openAttendanceModal },
      { title: '마일리지', showAll: true, keys: ['yearTotalMlg','monthTotalMlg'], unit: 'mileage', data: {month: 0, year: 0}, func: focusFunc.value.openMlgModal },
    ]);

    const lrnSecItems = ref(
        { title: '학습시간', isLoading: false, showAll: false, keys: ['yearLrnSec', 'monthLrnSec'], unit: 'time', data: {month: 0, year: 0} }
    );

    const debounce = ref(0);
    const debounce2 = ref(0);

    const getHistory = () => {
      debounce.value = setTimeout(() => {
        if (debounce.value) clearTimeout(debounce.value);

        isLoading.value = true;
        store.dispatch(`history/${ACT_GET_HRDCLOUD_HISTORY_STATISTICS}`, {year: year.value, month: month.value}).then(res => {
          if (lengthCheck(res)) {
            let data = getCheckItem(res);
            actItems.value = actItems.value.map(x => {
              x.keys.forEach(key => {
                if (data[key] !== undefined) {
                  x.data = data[key];
                }
              });
              return x;
            });

            lrnItems.value = lrnItems.value.map(x => {
              x.keys.forEach(key => {
                if (data[key] !== undefined) {
                  if (key.indexOf('year') > -1) x.data.year = data[key];
                  if (key.indexOf('month') > -1) x.data.month = data[key];
                }
              });
              return x;
            });
          }
          isLoading.value = false;
        }).catch(() => {
          isLoading.value = false;
        });
      },500);
    };

    const getHistorylrnSec = () => {
      debounce2.value = setTimeout(() => {
        if (debounce2.value) clearTimeout(debounce2.value);

        lrnSecItems.value.isLoading = true;
        store.dispatch(`history/${ACT_GET_HRDCLOUD_HISTORY_STATISTICS_LRNSEC}`, {year: year.value, month: month.value}).then(res => {
          if (lengthCheck(res)) {
            let data = getCheckItem(res);

            lrnSecItems.value.data.year = data['yearLrnSec'];
            lrnSecItems.value.data.month = data['monthLrnSec'];
          }
          lrnSecItems.value.isLoading = false;
        }).catch(() => {
          lrnSecItems.value.isLoading = false;
        });
      },500);
    };

    getHistory();
    getHistorylrnSec();

    watch(() => year.value, () => {
      if (year.value != null && year.value != '') {
        getHistory();
        getHistorylrnSec();
      }
    });

    watch(() => addModal.value, () => {
      if (!addModal.value) {
        getHistory();
        getHistorylrnSec();
      }
    });

    watch(() => subscriptionModal.value, () => {
      if (!subscriptionModal.value) {
        getHistory();
        getHistorylrnSec();
      }
    });

    return {
      isLoading, isMobile, actItems, lrnItems, lrnSecItems
    }
  }
};
</script>
