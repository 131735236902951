import {useStore} from "vuex";
import {computed, reactive, ref} from "vue";
import {useAlert} from "@/assets/js/modules/common/alert";
import {
  ACT_GET_LRNER_NOTI_MNG,
  ACT_INSERT_LRNER_NOTI_MNG,
  ACT_UPDATE_LRNER_NOTI_MNG
} from "@/store/modules/notification/notification";
import {getItem, lengthCheck, setParams} from "@/assets/js/util";

export const hrdSubscribeSettingsSetup = () => {
  const store = useStore();
  // eslint-disable-next-line no-unused-vars
  const isLoading = ref(true);
  const {showToast} = useAlert();
  const isMobile = computed(() => store.state.auth.isMobile);
  const lrnerNotiMng = reactive ({
    lrnerNotiMngSn: 0,
    hrdSubscbNotiYn: 'N',
    liivNotiYn: 'N'
  });

  const msg = {
    on: {
      popup: isMobile.value ? "팝업 알림 설정이 완료되었습니다.<br>HRD 클라우드 접속 시 팝업 알림이 발송됩니다." : "팝업 알림 설정이 완료되었습니다.\nHRD 클라우드 접속 시 팝업 알림이 발송됩니다.",
      liiv: isMobile.value ? "리브똑똑 알림 설정이 완료되었습니다.<br>오전 8시 30분에 리브똑똑 알림이 발송됩니다." : "리브똑똑 알림 설정이 완료되었습니다.\n오전 8시 30분에 리브똑똑 알림이 발송됩니다."
    },
    off: {
      popup: "팝업 알림 취소 설정이 완료되었습니다.",
      liiv: "리브똑똑 알림 취소 설정이 완료되었습니다."
    }
  };

  const getLrnerNotiMng = () => {
    store.dispatch(`notification/${ACT_GET_LRNER_NOTI_MNG}`).then(res => {
      isLoading.value = false;
      if (lengthCheck(res)) {
        setParams(lrnerNotiMng, getItem(res));
      }
    });
  };

  const saveSettings = msg => {
    if (lrnerNotiMng.lrnerNotiMngSn > 0) {
      store.dispatch(`notification/${ACT_UPDATE_LRNER_NOTI_MNG}`, {
        lrnerNotiMngSn : lrnerNotiMng.lrnerNotiMngSn,
        params: {
          hrdSubscbNotiYn: lrnerNotiMng.hrdSubscbNotiYn,
          liivNotiYn: lrnerNotiMng.liivNotiYn
        }
      }).then(() => {
        showToast(msg);
      });
    } else {
      store.dispatch(`notification/${ACT_INSERT_LRNER_NOTI_MNG}`, {
        hrdSubscbNotiYn: lrnerNotiMng.hrdSubscbNotiYn,
        liivNotiYn: lrnerNotiMng.liivNotiYn
      }).then(res => {
        lrnerNotiMng.lrnerNotiMngSn = res.lrnerNotiMngSn;
        showToast(msg);
      });
    }
  };

  const savePopup = data => {
    lrnerNotiMng.hrdSubscbNotiYn = data;
    saveSettings(lrnerNotiMng.hrdSubscbNotiYn === 'Y' ? msg.on.popup : msg.off.popup);
  };

  const saveLiiv = data => {
    lrnerNotiMng.liivNotiYn = data;
    saveSettings(lrnerNotiMng.liivNotiYn === 'Y' ? msg.on.liiv : msg.off.liiv);
  };

  const infoModal = ref(false);

  getLrnerNotiMng();

  return {
    lrnerNotiMng,
    savePopup,
    saveLiiv,
    infoModal
  }
};