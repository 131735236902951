import ApiService from '@/assets/js/api.service';

export const MUT_SET_BOARD_MST_OBJECT = 'setBoardMstObject';
export const MUT_INIT_BOARD_MST_OBJECT = 'initBoardMstObject';

// 게시판
export const ACT_GET_BOARD_MST = 'actGetBoardMap';
// 게시판 운영자
export const ACT_GET_BOARD_OPER_COUNT = 'actGetBoardOperCount';
export const ACT_GET_BOARD_OPER_LIST = 'actGetBoardOperList';
// 게시판 카테고리
export const ACT_GET_BOARD_CATE_LIST = 'actGetBoardCateList';
// 게시물
export const ACT_GET_POST_LIST = "actGetPostList";
export const ACT_GET_POST_DTL = "actGetPostDtl";
export const ACT_GET_POST_COUNT = "actGetPostCount";
export const ACT_INSERT_POST = "actInsertPost";
export const ACT_UPDATE_POST = "actUpdatePost";
export const ACT_UPDATE_POST_INQ_CNT = "actUpdatePostInqCnt";
export const ACT_UPLOAD_BOARD_THUMB_FILE = "actUploadBoardThumbFile";
// 게시물 첨부파일
export const ACT_GET_BOARD_ATCH_FILE_LIST = "actGetBoardAtchFileList";
export const ACT_INSERT_BOARD_ATCH_FILE = "actInsertBoardAtchFile";
export const ACT_DOWNLOAD_BOARD_ATCH_FILE = "actDownloadBoardAtchFile";
// 게시물 댓글
export const ACT_GET_BOARD_COMMENT_LIST = "actGetBoardCommentList";
export const ACT_INSERT_BOARD_COMMENT = "actInsertBoardComment";
export const ACT_DELETE_BOARD_COMMENT = "actDeleteBoardComment";
export const ACT_GET_BOARD_COMMENT_COUNT = "actGetBoardCommentCount";
// 게시물 평가
export const ACT_INSERT_BOARD_EVAL = "actInsertBoardEval";
// 활동 로그
export const ACT_INSERT_BOARD_ACT_LOG = "actInsertBoardActLog";
export const ACT_GET_BOARD_ACT_LOG_LIST = "getBoardActLogList";

// 부가 입력 정보
export const ACT_GET_BOARD_CSTM_INFO_LIST = "actGetBoardCstmInfoList";
export const ACT_SAVE_BOARD_CSTM_DATA_LIST = "actSaveBoardCstmDataList";

const state = {
    objects: []
};

const mutations = {
    [MUT_SET_BOARD_MST_OBJECT](state, object){
        if(state.objects){
            state.objects.push(object);
        }else{
            state.objects = [object];
        }
    },
    [MUT_INIT_BOARD_MST_OBJECT](state){
        state.objects = [];
    }
};

const actions = {
    [ACT_GET_BOARD_MST](context, boardId){
        return new Promise(resolve => {
            ApiService.get('/v1/app/common/board', boardId).then(response => resolve(response));
        });
    },
    [ACT_GET_BOARD_OPER_COUNT](context, params){
        return new Promise(resolve => {
            ApiService.query('/v1/app/common/board/oper/cnt', params).then(response => resolve(response));
        });
    },
    [ACT_GET_BOARD_OPER_LIST](context, boardMstSn){
        return new Promise(resolve => {
            ApiService.get('/v1/app/common/board/oper', boardMstSn).then(response => resolve(response));
        });
    },
    [ACT_GET_BOARD_CATE_LIST](context, boardMstSn){
        return new Promise(resolve => {
            ApiService.get('/v1/app/common/board/cate', boardMstSn).then(response => resolve(response));
        });
    },
    [ACT_GET_POST_LIST](context, params){
        return new Promise(resolve => {
            ApiService.query('/v1/app/common/board/posts', params).then(response => resolve(response));
        });
    },
    [ACT_GET_POST_DTL](context, params){
        return new Promise(resolve => {
            ApiService.query('/v1/app/common/board/post', params).then(response => resolve(response));
        });
    },
    [ACT_GET_POST_COUNT](context, params){
        return new Promise(resolve => {
            ApiService.query('/v1/app/common/board/post/cnt', params).then(response => resolve(response));
        });
    },
    [ACT_INSERT_POST](context, params){
        return new Promise(resolve => {
            ApiService.post('/v1/app/common/board/post/save', params).then(response => resolve(response));
        });
    },
    [ACT_UPDATE_POST](context, params){
        return new Promise(resolve => {
            ApiService.put('/v1/app/common/board/post/save', params).then(response => resolve(response));
        });
    },
    [ACT_UPDATE_POST_INQ_CNT](context, comPostSn){
        ApiService.update('/v1/app/common/board/post/inq', comPostSn);
    },
    [ACT_UPLOAD_BOARD_THUMB_FILE](context, {boardMstSn, file}){
        return ApiService.upload('/v1/app/common/board/thumb/upload/'+boardMstSn, file, {},false);
    },
    [ACT_GET_BOARD_ATCH_FILE_LIST](context, boardSecuKey){
        return new Promise(resolve => {
            ApiService.get('/v1/app/common/board/atch', boardSecuKey).then(response => resolve(response));
        });
    },
    [ACT_INSERT_BOARD_ATCH_FILE](context, {params, files}){
        return new Promise(resolve => {
            ApiService.upload('/v1/app/common/board/atch/save', files, params, true).then(response => resolve(response))
        });
    },
    [ACT_DOWNLOAD_BOARD_ATCH_FILE](context, {secuKey, fileName, params}){
        ApiService.download('/v1/app/file/atch', secuKey, params, fileName);
    },
    [ACT_GET_BOARD_COMMENT_LIST](context, boardSecuKey){
        return new Promise(resolve => {
            ApiService.get('/v1/app/common/board/cmnt', boardSecuKey).then(response => resolve(response));
        });
    },
    [ACT_INSERT_BOARD_COMMENT](context, params){
        return new Promise(resolve => {
            ApiService.post('/v1/app/common/board/cmnt', params).then(response => resolve(response));
        });
    },
    [ACT_DELETE_BOARD_COMMENT](context, comBoardCmntSn){
        return new Promise(resolve => {
            ApiService.delete('/v1/app/common/board/cmnt', comBoardCmntSn).then(response => resolve(response));
        });
    },
    [ACT_GET_BOARD_COMMENT_COUNT](context, params){
        return new Promise(resolve => {
            ApiService.query('/v1/app/common/board/cmnt/cnt', params).then(response => resolve(response));
        });
    },
    [ACT_INSERT_BOARD_EVAL](context, params){
        return new Promise(resolve => {
            ApiService.post('/v1/app/common/board/eval', params).then(response => resolve(response));
        });
    },
    [ACT_INSERT_BOARD_ACT_LOG](context, params){
        return new Promise(resolve => {
            ApiService.post('/v1/app/common/board/log', params).then(response => resolve(response));
        });
    },
    [ACT_GET_BOARD_CSTM_INFO_LIST](context, {boardMstSn, boardSecuKey}){
        return new Promise(resolve => {
            ApiService.get('/v1/app/common/board/cstm/info/' + boardMstSn, boardSecuKey).then(response => resolve(response));
        });
    },
    [ACT_SAVE_BOARD_CSTM_DATA_LIST](context, params){
        return new Promise(resolve => {
            ApiService.post('/v1/app/common/board/cstm/data/save', params).then(response => resolve(response));
        });
    },
    [ACT_GET_BOARD_ACT_LOG_LIST](context, params){
        return new Promise(resolve => {
            ApiService.query('/v1/app/common/board/log/share', params).then(response => resolve(response));
        });
    },

};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};