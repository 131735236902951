<template>
  <div class="content-section">
    <header class="section-header">
      <h3 class="title">자격증 신청이력</h3>
    </header>
    <div class="history-table">
      <div class="list-top">
        <div class="top-column">
          <p class="title text-muted">총 {{ lcnsPaging.totalCount }}건</p>
        </div>
        <div class="top-column">
          <router-link :to="{name: 'SupportLicenseApply', query: {'view': 'history'}}" class="text-link">
            <strong class="text">자격증 보유현황 바로가기</strong>
            <i class="icon-link"></i>
          </router-link>
        </div>
      </div>
      <template v-if="!isLcnsLoading && (licenses && licenses.length > 0)">
        <div class="kb-table kb-table-bordered">
          <table>
            <colgroup>
              <col style="width:100px" />
              <col style="width:153px" />
              <col />
              <col />
              <col />
              <col />
              <col style="width:120px" />
              <col/>
            </colgroup>
            <thead>
            <tr>
              <th><span class="th-title">관리번호</span></th>
              <th><span class="th-title">자격증명</span></th>
              <th><span class="th-title">주관처</span></th>
              <th><span class="th-title">신청일</span></th>
              <th><span class="th-title">지원 금액</span></th>
              <th><span class="th-title">신청 금액</span></th>
              <th><span class="th-title">진행상태</span></th>
              <th><span class="th-title"> </span></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(license, idx) in licenses" :key="idx">
              <td><span class="td-text">{{ license.costLcsnSn }}</span></td>
              <td><strong class="td-text">{{ license.lcsnNm }}</strong></td>
              <td><span class="td-text">{{ license.pblcnInstNm }}</span></td>
              <td><span class="td-text">{{ timestampToDateFormat(license.aplyDt, 'yyyy.MM.dd') }}</span></td>
              <CommonTdSprtAmt :stt="license.stt" :aply-stt-cd-dcd="license.aplySttCdDcd" :sprt-amt="license.sprtAmt" :rtn-yn="license.rtnYn" :add-amt-yn="license.addAmtYn" />
              <td><span class="td-text">{{ license.totAmt > 0 ? numberComma(license.totAmt) : '-' }}</span></td>
              <CommonTdStt :stt="license.stt" :aply-stt-cd-dcd="license.aplySttCdDcd"/>
              <td class="cell-btn"><button class="kb-btn kb-btn-outline" @click="detailFunc('license', license.costLcsnSn)"><strong class="text">상세</strong></button></td>
            </tr>
            </tbody>
          </table>
        </div>
        <more-button
            :paging="lcnsPaging"
            :more-func="moreFunc"
        />
      </template>
      <div v-else-if="!isLcnsLoading && (licenses && licenses.length === 0)" class="search-container">
        <div class="result-empty">
          <img src="../../assets/lxp/images/common/img_empty.png" alt="">
          <p class="text">신청이력이 없습니다.</p>
        </div>
      </div>
      <LoadingDiv v-if="isLcnsLoading" />
    </div>
  </div>
  <div class="content-section">
    <header class="section-header">
      <h3 class="title">자격증 갱신비용</h3>
    </header>
    <div class="history-table">
      <div class="list-top">
        <div class="top-column">
          <p class="title text-muted">총 {{ rnewPaging.totalCount }}건</p>
        </div>
      </div>
      <template v-if="!isRnewLoading && (renews && renews.length > 0)">
        <div class="kb-table kb-table-bordered">
          <table>
            <colgroup>
              <col style="width:100px;"/>
              <col style="width:153px" />
              <col />
              <col />
              <col />
              <col />
              <col style="width:120px" />
              <col/>
            </colgroup>
            <thead>
            <tr>
              <th><span class="th-title">관리번호</span></th>
              <th><span class="th-title">자격증명</span></th>
              <th><span class="th-title">주관처</span></th>
              <th><span class="th-title">신청일</span></th>
              <th><span class="th-title">지원 금액</span></th>
              <th><span class="th-title">신청 금액</span></th>
              <th><span class="th-title">진행상태</span></th>
              <th><span class="th-title">&nbsp;</span></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(renew, idx) in renews" :key="idx">
              <td><span class="td-text">{{ renew.costLcsnSn }}</span></td>
              <td><strong class="td-text">{{ renew.lcsnNm }}</strong></td>
              <td><span class="td-text">{{ renew.pblcnInstNm }}</span></td>
              <td><span class="td-text">{{ timestampToDateFormat(renew.regDt, 'yyyy.MM.dd') }}</span></td>
                <CommonTdSprtAmt :stt="renew.stt" :aply-stt-cd-dcd="renew.aplySttCdDcd" :sprt-amt="renew.sprtAmt" :rtn-yn="renew.rtnYn" :add-amt-yn="renew.addAmtYn" />
              <td><span class="td-text">{{ renew.totAmt > 0 ? numberComma(renew.totAmt) : '-' }}</span></td>
              <CommonTdStt :stt="renew.stt" :aply-stt-cd-dcd="renew.aplySttCdDcd" />
              <td class="cell-btn"><button class="kb-btn kb-btn-outline" @click="detailFunc('renew', renew.costLcsnSn)"><strong class="text">상세</strong></button></td>
            </tr>
            </tbody>
          </table>
        </div>
        <more-button
            :paging="rnewPaging"
            :more-func="renewMoreFunc"
        />
      </template>
      <div v-else-if="!isRnewLoading && (renews && renews.length === 0)" class="search-container">
        <div class="result-empty">
          <img src="../../assets/lxp/images/common/img_empty.png" alt="">
          <p class="text">신청이력이 없습니다.</p>
        </div>
      </div>
      <LoadingDiv v-if="isRnewLoading" />
    </div>
  </div>
</template>

<script>
import {numberComma, timestampToDateFormat} from '@/assets/js/util';
import MoreButton from '@/components/support/common/moreButton';
import LoadingDiv from '@/components/common/LoadingDiv';
import CommonTdStt from "@/components/support/common/CommonTdStt";
import CommonTdSprtAmt from "@/components/support/common/CommonTdSprtAmt";

export default {
  name: 'SupportStatusLicense',
  components: {CommonTdSprtAmt, CommonTdStt, LoadingDiv, MoreButton},
  props: {
    licenses: Array,
    renews: Array,
    lcnsPaging: Object,
    rnewPaging: Object,
    moreFunc: Function,
    isLcnsLoading: Boolean,
    isRnewLoading: Boolean,
    detailFunc: Function
  },
  setup(props) {
    const renewMoreFunc = (paging) => {
      props.moreFunc(paging, 'renew');
    };

  /*  const applyLicenses = computed(() => {
      return props.licenses.filter(x => x.acqsUpdtDiv = 'N')
    })

    const renewLicenses = computed(() => {
      return props.licenses.filter(x => x.acqsUpdtDiv = 'Y')
    })*/

    return {
      timestampToDateFormat, numberComma, renewMoreFunc,
    }
  }
};
</script>