<template>
  <img v-if="imgSrc" :src="imgSrc" @error="setDefaultImg" alt="">
  <img v-else :src="setEmptyImg()" alt="">
</template>

<script>

export default {
  name: 'CommonImage',
  props: {
    imgSrc: String
  },
  setup() {
    // const rand = ref(Math.floor(Math.random() * 6) + 1);

    const setEmptyImg = () => {
      return require('../../assets/lxp/images/@tmp/@tmp_course_thumbnail_06.jpg');
    };

    const setDefaultImg = (e) => {
      e.target.src = setEmptyImg();
    };

    return {
      setDefaultImg,
      setEmptyImg
    }
  }
};
</script>