import {useRoute, useRouter} from 'vue-router';
import {useStore} from 'vuex';
import {computed, onMounted, ref, watch} from 'vue';
import {
  currentYmd,
  getRouteLrnTrgtGrpMstSn,
  initAttendCalendar, isMyLrnTrgtGrpMstSn,
  isRightSSL, noneRewardCds,
  sslOpenDate,
  sslOpenYmd,
  sslRoutes,
} from '@/assets/js/modules/ssl/ssl-common';
import {
  ACT_GET_MY_LEARN_TARGET_LIST,
  ACT_GET_SOC_ACTS_EXCELLENT_LIST,
  ACT_GET_SOC_MY_REL,
  MUT_CLOSE_BADGE,
  MUT_INIT_MY_REL,
  MUT_SET_EXCELLENT_LRNERS,
  MUT_SET_MY_LEARN_TARGET_LIST,
  MUT_SET_MY_REL,
} from '@/store/modules/ssl/ssl-index';
import {
  dateToDateFormat,
  getCheckItems,
  getItem, getItems,
  goToTop,
  initProgressBar,
  lengthCheck,
} from '@/assets/js/util';
import {
  getGroupList,
  getLearnTargetList,
  setLearnTargetList,
} from '@/assets/js/modules/ssl/ssl-learn';
import {setProfileImgUrl} from '@/assets/js/modules/common/common';
import {Autoplay, Pagination} from 'swiper';
import {getListFunc} from "@/assets/js/ui.init";
import {ACT_GET_COM_BADGE_TY_LIST} from "@/store/modules/badge/badge";
import {ACT_GET_CODE_LIST, MUT_SET_CODE_LIST} from "@/store/modules/code/code";

export const sslLayoutSetup = () => {
  const router = useRouter();
  const route = useRoute();
  const store = useStore();

  const isMobile = computed(() => store.state.auth.isMobile);
  const calendar = computed(() => store.state.ssl.calendar);

  const lrnTrgtGrpMstSn = computed(() => getRouteLrnTrgtGrpMstSn(route.params.lrnTrgtGrpMstSn));
  const distCrseCd = computed(() => route.params.distCrseCd);

  const isHome = computed(() => route.name === 'SSLHome');
  const isMain = computed(() => route.name === 'SSLMain');
  const isFill = computed(() => isHome.value || route.name === 'SSLWrite');
  const isGroup = computed(() => distCrseCd.value === 'group');
  const hideFooter = computed(() => (route.name === 'SSLWrite'));
  const mobileHideFooter = computed(() => route.name === 'SSLWrite' || calendar.value.show);

  const eventStatusPopup = ref(false);

  const isReady = ref(false);
  const touchBodyCount = ref(0);

  const isNavWrt = ref(false);

  const isMyLrnTrgt = computed(() => {
    if (lrnTrgtGrpMstSn.value > 0) {
      return isMyLrnTrgtGrpMstSn(lrnTrgtGrpMstSn.value)
    }
    return false;
  });
  const isNoneReward = computed(() => noneRewardCds.includes(distCrseCd.value));

  const plusEffectNav = () => {
    touchBodyCount.value++;
  }

  const getSSLMemberList = () => {
    if(isRightSSL(distCrseCd.value, lrnTrgtGrpMstSn.value)){
      if (isGroup.value) {
        getGroupList();
      } else {
        getLearnTargetList(lrnTrgtGrpMstSn.value);
      }
    }else{
      setLearnTargetList([]);
    }
  }

  const getNecessaries = () => {
    store.commit(`ssl/${MUT_INIT_MY_REL}`);

    Promise
    .all([
      store.dispatch(`ssl/${ACT_GET_MY_LEARN_TARGET_LIST}`,{customCd: 'ssl'}),
      store.dispatch(`ssl/${ACT_GET_SOC_MY_REL}`, 'group'),
      // store.dispatch(`ssl/${ACT_GET_SOC_MY_REL}`, 'knowledge'),
      // store.dispatch(`ssl/${ACT_GET_SOC_MY_REL}`, 'hrd'),
    ])
    .then(([res1, res2, res3, res4]) => {
      let items = getCheckItems(res1);
      let noGroup = true;

      // 그룹러닝을 위한 해당 년도 그룹 조회
      if (lengthCheck(res2)) {
        noGroup = false;
        store.commit(`ssl/${MUT_SET_MY_REL}`, {division: 'group', items: getItem(res2)});
      }
      if (lengthCheck(res3)) store.commit(`ssl/${MUT_SET_MY_REL}`, {division: 'knowledge', items: getItem(res3)});
      if (lengthCheck(res4)) store.commit(`ssl/${MUT_SET_MY_REL}`, {division: 'hrd', items: getItem(res4)});

      if (noGroup) {
        // 그룹러닝 입과정보를 제거한다.
        items = items.filter(x => !sslRoutes['group'].lrnTrgtGrpMstSns.includes(x.lrnTrgtGrpMstSn));
      }

      store.commit(`ssl/${MUT_SET_MY_LEARN_TARGET_LIST}`,items);

      if(distCrseCd.value && lrnTrgtGrpMstSn.value > 0) getSSLMemberList();

      if (items.filter(x => sslRoutes['book'].lrnTrgtGrpMstSns.includes(x.lrnTrgtGrpMstSn)).length > 0) {
        eventStatusPopup.value = false;
      }

      isReady.value = true;
    }).catch(() => {
      isReady.value = true;
    });
  };

  const getExcellentLearnerList = () => {
    store.dispatch(`ssl/${ACT_GET_SOC_ACTS_EXCELLENT_LIST}`).then(res => {
      store.commit(`ssl/${MUT_SET_EXCELLENT_LRNERS}`, getCheckItems(res));
    });
  }

  const goTop = () => {
    goToTop('app', 100);
  }

  watch(() => lrnTrgtGrpMstSn.value, () => {
    // 차수로 입장...
    getSSLMemberList();
  });

  // pc
  const mainId = computed(() => {
    if(route.name === 'SSLHome' || route.name === 'SSLApply'){
      // return `ssl-home`;
      return `ssl-home`;
    }else if(route.name === 'SSLNotice' || route.name === 'SSLCrseNotice'){
      return `ssl-notice-list`;
    }
    else if(route.name === 'SSLWrite'){
      return `ssl-editor`;
    }
    else if(route.name === 'SSLView' || route.name === 'SSLNoticeView'){
      return `ssl-view`;
    }
    else if(route.name === 'SSLMy' || route.name === 'SSLMyView' || route.name === 'SSLLearner' || route.name === 'SSLLearnerBoard'){
      return `ssl-myssl`;
    }
    // 임시
    else if(route.path.includes('selfGa')){
      return `ssl-selfGa-learning`;
    }
    else if(route.path.includes('selfGi')){
      return `ssl-selfGi-learning`;
    }
    else if(route.path.includes('selfJa')){
      return `ssl-selfJa-learning`;
    }
    else if(route.path.includes('selfIct')){
      return `ssl-selfIct-learning`;
    }
    else if(route.path.includes('group')){
      return `ssl-group-learning`;
    }
    else if(route.path.includes('knowledge')){
      return `ssl-e-learning`;
    }
    else if(route.path.includes('hrd')){
      return `ssl-hrd-learning`;
    }
    else if(route.path.includes('book')){
      return `ssl-book-learning`;
    }
    else if(route.path.includes('trivia')){
      return `ssl-alssl-learning`;
    }
    else if(route.path.includes('college')){
      return `ssl-college-learning`;
    }
    else if(route.path.includes('live')){
      return `ssl-live-learning`;
    }
    return '';
  });

  const mainClass = computed(() => {
    if(route.name === 'SSLMain'){
      return `ssl-learning`;
    }
    return '';
  });

  const session = computed(() => store.state.auth.session);
  const fastLearner = ['3171749', '1652204', '3831580', '3826701', '1533872', '1653886', '3900156', '3331340', '3172423', 'S017352', 'T004493']

  const canEnter = (() => {
    if (store.state.ssl.sslAcl) return store.state.ssl.sslAcl;
    return process.env.NODE_ENV === 'production' && (
        dateToDateFormat(new Date(), 'yyyyMMddhhmm') >= sslOpenDate
        ||
        (fastLearner.includes(session.value.lrnerId) && currentYmd >= sslOpenYmd)
    );
  })();

  if (canEnter) {
    setProfileImgUrl();
    getNecessaries();
    initAttendCalendar();
    getExcellentLearnerList();

    if(!isMobile.value) {
      initProgressBar();
      watch(() => route.path, () => {
        initProgressBar();
      });
    }
  } else {
    router.push({name: 'Main'});
  }

  const notices = ref([]);

  const getCodes = () => {
    store.dispatch(`code/${ACT_GET_CODE_LIST}`).then(res => {
      if (lengthCheck(res)) {
        store.commit(`code/${MUT_SET_CODE_LIST}`, getItems(res));
      }
    });
  };

  onMounted(getCodes)

  return {
    calendar,
    isFill,
    isHome,
    isMain,
    isReady,
    touchBodyCount,
    hideFooter,
    plusEffectNav,
    goTop,

    // pc
    mainId,
    mainClass,

    // mobile
    // showLrner,
    mobileHideFooter,
    notices,
    isNavWrt,

    isMyLrnTrgt,
    isNoneReward,
    lrnTrgtGrpMstSn,
    eventStatusPopup,
  }
}

export const sslApplyLayoutSetup = () => {
  // const router = useRouter();
  const route = useRoute();
  const store = useStore();

  const isMobile = computed(() => store.state.auth.isMobile);
  const calendar = computed(() => store.state.ssl.calendar);

  const lrnTrgtGrpMstSn = computed(() => getRouteLrnTrgtGrpMstSn(route.params.lrnTrgtGrpMstSn));
  const distCrseCd = computed(() => route.params.distCrseCd);

  const isHome = computed(() => route.name === 'SSLHome');
  const isFill = computed(() => isHome.value || route.name === 'SSLWrite');
  const isGroup = computed(() => distCrseCd.value === 'group');
  const hideFooter = computed(() => (route.name === 'SSLWrite'));
  const mobileHideFooter = computed(() => route.name === 'SSLWrite' || calendar.value.show);

  const isReady = ref(false);
  const touchBodyCount = ref(0);

  const plusEffectNav = () => {
    touchBodyCount.value++;
  }

  const getSSLMemberList = () => {
    if(isRightSSL(distCrseCd.value, lrnTrgtGrpMstSn.value)){
      if (isGroup.value) {
        getGroupList();
      } else {
        getLearnTargetList(lrnTrgtGrpMstSn.value);
      }
    }else{
      setLearnTargetList([]);
    }
  }

  const getNecessaries = () => {
    store.commit(`ssl/${MUT_INIT_MY_REL}`);

    Promise
    .all([
      store.dispatch(`ssl/${ACT_GET_MY_LEARN_TARGET_LIST}`,{customCd: 'ssl'}),
      store.dispatch(`ssl/${ACT_GET_SOC_MY_REL}`, 'group'),
      // store.dispatch(`ssl/${ACT_GET_SOC_MY_REL}`, 'knowledge'),
      // store.dispatch(`ssl/${ACT_GET_SOC_MY_REL}`, 'hrd'),
    ])
    .then(([res1, res2]) => { //, res3, res4
      let items = getCheckItems(res1);
      let noGroup = true;

      // 그룹러닝을 위한 해당 년도 그룹 조회
      if (lengthCheck(res2)) {
        noGroup = false;
        store.commit(`ssl/${MUT_SET_MY_REL}`, {division: 'group', items: getItem(res2)});
      }
      // if (lengthCheck(res3)) store.commit(`ssl/${MUT_SET_MY_REL}`, {division: 'knowledge', items: getItem(res3)});
      // if (lengthCheck(res4)) store.commit(`ssl/${MUT_SET_MY_REL}`, {division: 'hrd', items: getItem(res4)});

      if (noGroup) {
        // 그룹러닝 입과정보를 제거한다.
        items = items.filter(x => !sslRoutes['group'].lrnTrgtGrpMstSns.includes(x.lrnTrgtGrpMstSn));
      }

      store.commit(`ssl/${MUT_SET_MY_LEARN_TARGET_LIST}`,items);

      if(distCrseCd.value && lrnTrgtGrpMstSn.value > 0) getSSLMemberList();

      isReady.value = true;
    }).catch(() => {
      isReady.value = true;
    });
  };


  const goTop = () => {
    goToTop('app', 100);
  }

  watch(() => lrnTrgtGrpMstSn.value, () => {
    // 차수로 입장...
    getSSLMemberList();
  });

  // pc
  const mainId = computed(() => {
    if(route.name === 'SSLHome' || route.name === 'SSLApply' || route.name === 'SSLBoardSearch'){
      // return `ssl-home`;
      return `ssl-home`;
    }else if(route.name === 'SSLNotice' || route.name === 'SSLCrseNotice'){
      return `ssl-notice-list`;
    }
    else if(route.name === 'SSLWrite'){
      return `ssl-editor`;
    }
    else if(route.name === 'SSLView' || route.name === 'SSLNoticeView'){
      return `ssl-view`;
    }
    else if(route.name === 'SSLMy' || route.name === 'SSLMyView' || route.name === 'SSLLearner' || route.name === 'SSLLearnerBoard'){
      return `ssl-myssl`;
    }
    // 임시
    else if(route.path.includes('self')){
      return `ssl-self-learning`;
    }
    else if(route.path.includes('group')){
      return `ssl-group-learning`;
    }
    else if(route.path.includes('knowledge')){
      return `ssl-e-learning`;
    }
    else if(route.path.includes('hrd')){
      return `ssl-hrd-learning`;
    }
    else if(route.path.includes('book')){
      return `ssl-book-learning`;
    }
    else if(route.path.includes('trivia')){
      return `ssl-alssl-learning`;
    }
    else if(route.path.includes('college')){
      return `ssl-college-learning`;
    }
    else if(route.path.includes('live')){
      return `ssl-live-learning`;
    }
    return '';
  });

  const mainClass = computed(() => {
    if(route.name === 'SSLMain'){
      return `ssl-learning`;
    }
    return '';
  });

  // mobile
  // const showLrner = computed(() => store.state.ssl.showLrner);

  // const sslAcl = computed(() => store.state.ssl.sslAcl);
  //
  // if (sslAcl.value) {
    setProfileImgUrl();
    getNecessaries();
    // initAttendCalendar();
    // getExcellentLearnerList();

    if(!isMobile.value) {
      initProgressBar();
      watch(() => route.path, () => {
        initProgressBar();
      });
    }

  // }
  // else {
  //   router.push({name: 'Main'});
  // }

  const notices = ref([]);

  return {
    calendar,
    isFill,
    isHome,
    isReady,
    touchBodyCount,
    hideFooter,
    plusEffectNav,
    goTop,

    // pc
    mainId,
    mainClass,

    // mobile
    // showLrner,
    mobileHideFooter,
    notices
  }
}

export const sslBadgeModalSetup = () => {
  const currentYear = new Date().getFullYear().toString();
  const sslHrdBadgesTmp = ref([]);
  getListFunc(`badge/${ACT_GET_COM_BADGE_TY_LIST}`, {year: currentYear, badgeKindCdDcd: "2065001"}, sslHrdBadgesTmp, null);

  const store = useStore();
  const show = computed(() => store.state.ssl.badgeShow);
  const myBadges = computed(() => {
    const filtered = sslHrdBadgesTmp.value.filter(x => store.state.ssl.badges.indexOf(x.badgeTyCdDcd) > -1);
    return filtered.map(x => (
        {
          ...x,
          badgeName: x.badgeTyNm instanceof Object ? x.badgeTyNm[currentYear] : x.badgeTyNm,
          badgeDesc: x.cdDesc instanceof Object ? x.cdDesc[currentYear] : x.cdDesc,
        }
    ));
  });

  const closeModal = () => {
    store.commit(`ssl/${MUT_CLOSE_BADGE}`);
  }

  return {
    show,
    myBadges,
    closeModal,
    modules: [Pagination, Autoplay]
  }
}
