<template>
  <div>
    <video ref="videoPlayer" :style="videoStyle"></video>
  </div>
</template>

<script>
import 'video.js/dist/video-js.min.css';
import videojs from 'video.js';
import {computed, onBeforeUnmount, onMounted, ref} from 'vue';

export default {
  name: 'HrdCloudBannerVideo',
  props: {
    videoSrc: String
  },
  setup(props) {
    const videoPlayer = ref(null);
    const player = ref(null),
          videoStyle = ref({});

    const options = computed(() => (
        {
          playsinline: true,
          loop: true,
          autoplay: true,
          muted: true,
          sources: [
            {
              src: props.videoSrc,
              type: "video/mp4"
            }
          ]
        }
    ));

    onMounted(() => {
      if(videoPlayer.value){
        player.value = videojs(videoPlayer.value, options.value, function() {
          this.on('loadedmetadata', function() {
            if (this.videoWidth <= 416) {
              videoStyle.value = {'width':'100%'};
            } else {
              videoStyle.value = {};
            }
          });
        });
      }
    })

    onBeforeUnmount(() => {
      if (player.value) {
        player.value.dispose()
      }
    });

    return {
      videoPlayer, videoStyle
    }

  }
};
</script>