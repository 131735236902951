<template>
  <article class="content-section" ref="fullHistory" :class="{'section-divider' : !isMobile}">
    <header class="section-header">
      <template v-if="isMobile">
        <div class="header-column">
          <h3 class="title">HRD 클라우드 전체 학습이력 </h3>
          <span class="subtitle">{{ getBgngEndDate() }}</span>
        </div>
      </template>
      <template v-else>
        <h3 class="title" :class="{ 'd-block' : isMobile }">HRD 클라우드 전체 학습이력 <span class="subtitle">{{ getBgngEndDate() }}</span></h3>
      </template>
    </header>
    <p class="description">진도율과 학습확인(Cold Call) 응답을 모두 충족해야 학습완료 및 마일리지가 인정됩니다.
      <br/>전산실습 Easy(실습모드)를 포함하여 1일 2마일리지까지 인정됩니다.
      <br/>※비고: <span style="color:#ff8000;line-height:unset !important;">학습완료(마일리지 부여일)</span> 또는 <span style="color:#ff8000;line-height:unset !important;">마일리지 미부여 사유</span>를 표시합니다.
<!--      <br/>일 마일리지 초과/당해년도 마일리지 기부여 등 사유로 마일리지가 미부여된 경우 비고란에 표시됩니다.-->
    </p>
  </article>
  <article class="content-section" :class="{'section-margin' : isMobile}">
<!-- 삭제요청: <header class="section-header">-->
<!--      <h3 class="title">{{ session.lrnerNm }}님의 {{ year }}년 콘텐츠별 학습이력</h3>-->
<!--    </header>-->
    <div v-if="!isLoading" class="kb-table kb-table-bordered" :class="[isMobile ? 'kb-table-my-02' : 'kb-table-padding']">
      <table>
        <colgroup>
          <col style="width:44px;" />
          <col style="width:200px;" />
          <col style="width:134px;" />
          <col style="width:74px;" />
          <col style="width:74px;" />
          <col style="width:53px;" />
<!--          <col style="width:60px;" />-->
<!--          <col style="width:76px;" />-->
<!--          <col style="width:76px;" />-->
          <col style="width:74px;" />
          <col style="width:53px;" />
          <col style="width:53px;" />
          <col style="width:76px;" />
          <col style="width:112px;" />
        </colgroup>
        <thead>
        <tr>
          <th rowspan="2" class="px-0"><span class="th-title">번호</span></th>
          <th rowspan="2"><span class="th-title">콘텐츠명</span></th>
<!--          <th rowspan="2"><span class="th-title">카테고리</span></th>-->
<!--          <th rowspan="2"><span class="th-title">구분</span></th>-->
<!--          <th rowspan="2"><span class="th-title">최초 <br/>학습일시</span></th>-->
          <th rowspan="2"><span class="th-title">최종 <br/>학습일시</span></th>
          <th rowspan="2"><span class="th-title">길이</span></th>
          <th colspan="4"><span class="th-title">학습완료여부</span></th>
          <th rowspan="2"><span class="th-title">마일<br/>리지</span></th>
          <th rowspan="2"><span class="th-title">학습상세</span></th>
          <th rowspan="2"><span class="th-title">비고</span></th>
        </tr>
        <tr>
          <th class="cell-border"><span class="th-title">누적 <br/>학습시간</span></th>
          <th><span class="th-title">누적 <br/>진도율</span></th>
          <th><span class="th-title">콜드콜</span></th>
          <th><span class="th-title">완료여부</span></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, idx) in items" :key="idx">
          <td class="px-0"><span class="td-text">{{idx+1+(paging.pageSize*(paging.pageNo-1))}}</span></td>
          <td>
            <a href="javascript:">
              <strong class="td-text" @click="goToDetail(item)">{{ servTyCdDcds.SERV_TY_CD_EASY === item.servTyCdDcd ? `[Easy] ${item.objNm}` : item.objNm }}</strong>
            </a>
          </td>
<!--          <td><span class="td-text">{{ item.cloudNmLst }}</span></td>-->
<!--          <td><span class="td-text">{{ item.servTyNm }}</span></td>-->
<!--          <td><span class="td-text">{{ timestampToDateFormat(item.firstLrnTime, 'yyyy.MM.dd hh:mm') }}</span></td>-->
          <td><span class="td-text">{{ timestampToDateFormat(item.lastLrnTime, 'yyyy.MM.dd hh:mm') }}</span></td>
          <td><span class="td-text">{{ getConvertTime(item.cttTyCdDcd, item.cttSz) }}</span></td>
          <td><span class="td-text">{{ getConvertTime(item.cttTyCdDcd, item.accumPlaySec) }}</span></td>
<!--            <br/>{{ item.accumPlaySec >= Math.floor(item.cttSz*0.95) ? '(O)':'(X)' }}-->

          <td>
            <template v-if="cttTyCdDcds.CTT_TY_CD_YOUTUBE === item.cttTyCdDcd || cttTyCdDcds.CTT_TY_CD_VOD === item.cttTyCdDcd || cttTyCdDcds.CTT_TY_CD_ZIP === item.cttTyCdDcd">
              <span class="td-text">{{ getProgressPercent(item.cttSz, item.accumPlaySec, item.cttTyCdDcd) }}%</span>
            </template>
            <template v-else>
              <span>-</span>
            </template>
          </td>
<!--            <br/>{{ item.progPrcnt > 95 ? '(O)' : '(X)' }}-->

          <td>
            <template v-if="cttTyCdDcds.CTT_TY_CD_VOD === item.cttTyCdDcd && item.cttSz > 60">
              <span class="td-text">{{ item.coldCallRspnsDt > 0 ? '응답' : '미응답'}} {{ `${item.coldCallRspnsDt ? 1 : 0} / ${item.coldCallRound}` }}</span>
            </template>
            <template v-else>
              <span class="td-text">-</span>
            </template>
<!--            <br/>{{ item.coldCallRspnsDt ? '(O)' : '(X)' }}-->
          </td>
          <td>
            <strong v-if="item.cmptnYn === 'Y'" class="td-text text-primary">완료</strong>
            <strong v-else class="td-text text-muted">미완료</strong>
          </td>
          <td><span class="td-text">{{ item.mlgScore }}</span></td>
          <td>
            <a v-if="lrnObjTyCds.PDF !== item.lrnObjTyCd" href="javascript:" class="td-link td-link-line" @click="openDtlFunc(item.lrnObjDtlDistSn)"><strong class="td-text text-gold">상세보기</strong></a>
            <strong v-if="lrnObjTyCds.PDF === item.lrnObjTyCd" class="td-text text-gold">-</strong>
          </td>
          <td>
            <span class="td-text" :style="item.mlgTyCd === '1001' || item.mlgTyCd === '1003' || item.mlgTyCd === '1004' || item.mlgTyCd === '1005' ? {color:'#ff8000'} : {}">
              {{ item.mlgGiveRsn || '-' }} {{ getMlgGiveDt(item) }}
            </span>
          </td>
        </tr>
        <tr v-if="!isLoading && items.length === 0">
          <td colspan="11" class="text-center"><span class="td-text">학습이력이 없습니다.</span></td>
        </tr>
        </tbody>
      </table>
      <CommonPaginationFront :paging="paging" :page-func="pagingFunc" :page-list-size="isMobile ? 5 : 10"/>
    </div>
    <LoadingDiv v-if="!isMobile" v-model="isLoading" />
  </article>
</template>
<script>
import {computed, onMounted, reactive, ref, toRefs, watch} from 'vue';
import {getListFunc} from '@/assets/js/ui.init';
import {getBgngEndDate, secondToFormat, timestampToDateFormat} from '@/assets/js/util';
import {ACT_GET_HRDCLOUD_HISTORY_ALL_LEARN_LIST} from '@/store/modules/history/history';
import CommonPaginationFront from '@/components/CommonPaginationFront';
import {useStore} from 'vuex';
import LoadingDiv from '@/components/common/LoadingDiv.vue';
import {lrnObjTyCds} from '@/assets/js/modules/learn/learn-common';
import {getMlgGiveDt, getProgressPercent} from '@/assets/js/modules/hrd/hrd-history-common';
import {checkHrdCloudObject, cttTyCdDcds, getConvertTime, servTyCdDcds} from '@/assets/js/modules/hrd/hrd-common';
import {useRouter} from 'vue-router';
import {useAlert} from '@/assets/js/modules/common/alert';

export default {
  name: 'FullHistory',
  components: {LoadingDiv, CommonPaginationFront},
  props: {
    modelValue: Number,
    year: [Number, String],
    targetRef: Object,
    isMobile: Boolean,
    openDtlFunc: Function
  },
  emits: ['update:modelValue', 'update:targetRef'],
  setup(props, {emit}) {
    const store = useStore();
    // eslint-disable-next-line no-unused-vars
    const router = useRouter();
    const {showMessage} = useAlert();
    const isLoading = ref(true);
    const session = computed(() => store.state.auth.session);
    const {year} = toRefs(props);
    const fullHistory = ref(null);
    const items = ref([]);
    const paging = reactive({
      pageNo: 1,
      pageSize: 10,
      totalCount: 0
    });

    const pagingFunc = (pageNo) => {
      paging.pageNo = pageNo;
      getList();
    };

    const getList = () => {
      if (!isLoading.value) isLoading.value = true;
      getListFunc(`history/${ACT_GET_HRDCLOUD_HISTORY_ALL_LEARN_LIST}`, {year: props.year, ...paging}, items, paging, ()=>{isLoading.value=false;},()=>{isLoading.value=false;});
    };

    const showDetail = (idx) => {
      emit('update:modelValue', items.value[idx].lrnObjDtlDistSn);
    };

    const goToDetail = async (item) => {
      const res = await checkHrdCloudObject(item.lrnObjDtlDistSn);
      if (res && res.items && res.items.length > 0 && res.items[0] && res.items[0].lrnObjDtlDistSn > 0) {
        if (props.isMobile) {
          if (servTyCdDcds.SERV_TY_CD_EASY === item.servTyCdDcd) {
            showMessage('실습에 최적화된 PC에서 학습하세요.');
            return;
          }
        }
        await router.push({name: 'HrdCloudContentBridge', params: {lrnObjDtlDistSn: res.items[0].lrnObjDtlDistSn}});
      } else {
        showMessage('더 이상 재생할 수 없는 콘텐츠입니다.');
      }
    };

    getList();

    watch(() => year.value, () => {
      getList();
    });

    onMounted(() => {
      emit('update:targetRef', fullHistory.value);
    });

    return {
      session, isLoading,
      items, paging, pagingFunc,
      showDetail, fullHistory, lrnObjTyCds, cttTyCdDcds, servTyCdDcds,
      getProgressPercent,getBgngEndDate,timestampToDateFormat,secondToFormat,
      getConvertTime, goToDetail, getMlgGiveDt,
    }
  }
};
</script>