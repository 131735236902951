<template>
  <div class="contents">
    <SSLMobileBoardList
        :is-loading="isLoading"
        :items="items"
        :paging="paging"
        :more-func="moreFunc"
    />
  </div>
</template>

<script>

import SSLMobileBoardList from "@/components/ssl/main/mobile/SSLMobileBoardList";
import {sslLearnerBoardSetup} from "@/assets/js/modules/ssl/ssl-learn";

export default {
  name: 'SSLMobileLearnerBoard',
  components: {
    SSLMobileBoardList
  },
  setup: sslLearnerBoardSetup
}
</script>
