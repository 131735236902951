<template>
  <LxpMobileHeader>
    <template v-slot:left>
      <div class="util util-back">
<!--        <router-link :to="{name: 'Main'}" class="util-actions util-actions-back">-->
        <a href="javascript:" @click="goBack">
          <i class="icon-history-back"></i>
        </a>
<!--        </router-link>-->
      </div>
    </template>
  </LxpMobileHeader>
  <main class="ssl-body" id="kb-lxp-ssl" @click="clickMain">
    <!-- page-progress -->
    <div class="page-progress">
      <div class="progress-bar"></div>
    </div>
    <!-- //page-progress -->
    <!-- page-container -->
    <div v-if="isReady" class="page-container">
      <div class="board-view-container">
        <div class="board-view">
          <div class="board-view-header view-component">
            <img v-if="socBoard.thumb" :src="socBoard.thumb" alt="">
            <div class="blur"></div>
            <div class="page-header-wrap">
              <div>
                <h2 class="title">{{ socBoard.title }}</h2>
                <p class="page-editor">{{ socBoard.lrnerNm }}<span> ・ </span>{{ socBoard.deptNm }}</p>
              </div>
              <div class="page-info">
                <span class="date">{{ dateToFormat(socBoard.pblcnDt) }}</span>&nbsp;&nbsp;|&nbsp;&nbsp;<span class="views">{{ socBoard.inqcnt }}</span>&nbsp;조회
              </div>
            </div>
          </div>

          <div class="board-view-body view-component" style="margin-top: 32px;">
            <div class="editor-area">
              <div class="editor-contents" v-html="currentPage.cn"></div>
            </div>
            <SSLViewRel
                :hash-tags="hashTags"
                :ref-url="socBoard.refUrl"
                :ref-post="socBoard.refPost"
                :modal-dist-crse-cd="$route.params.distCrseCd"
                page-type="main"
            />

            <div class="like-container">
              <div class="like">
                <img src="@/assets/lxp/images/ssl/view/ic_like.png" alt="">
                <button :class="{'is-active': isLike}" @click="toggleLike">
                  <span>좋아요</span>&nbsp;<span class="like-num">{{ socBoard.likeCnt  }}</span>
                </button>
              </div>
            </div>

          </div>
          <div class="divider"></div>
          <SSLMobileComment :reg-dt="socBoard.pblcnDt" :effected="effected" :is-write="false"/>
        </div>
      </div>


    </div>
    <!-- //page-container -->
  </main>
</template>
<script>
import {computed, onBeforeUnmount, onMounted, reactive, ref} from "vue";
import {ACT_GET_SOC_PAGE_LIST} from "@/store/modules/ssl/ssl-index";
import {getItems, lengthCheck, setParams, timestampToDateFormat} from "@/assets/js/util";
import {useRoute} from "vue-router";
import {useStore} from "vuex";
import {
  actSubscribe,
  getAllSubscribes,
  getDistCrseSns,
  getLrnTrgtGrpMstSn,
  goBack, noneRewardCds,
} from '@/assets/js/modules/ssl/ssl-common';
import SSLViewRel from "@/components/ssl/view/SSLViewRel";
import SSLMobileComment from "@/components/ssl/view/mobile/SSLMobileComment";
import LxpMobileHeader from "@/views/layout/lxp/header/mobile/LxpMobileHeader";
import {useAlert} from "@/assets/js/modules/common/alert";

export default {
  name: 'LxpMobileSSLView',
  components: {
    LxpMobileHeader,
    SSLMobileComment,
    SSLViewRel
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const isReady = ref(false);
    const effected = ref(0);

    const {showMessage} = useAlert();

    const session = computed(() => store.state.auth.session ? store.state.auth.session : {});
    const distCrseSn = computed(() => getDistCrseSns(route.params.distCrseCd));

    const socBoard = reactive({
      socLrnPostSn: 0,
      title: '',
      thumb: '',
      regDt: 0,
      inqcnt: 0,
      lrnerId: '',
      lrnerNm: '',
      deptNm: '',
      deptCd: '',
      lrnTrgtGrpMstSn: 0,
      lrnTrgtGrpNm: '',
      likeCnt: 0,
      pblcnDt: 0,
      refUrl: '',
      refPost: ''
    });

    const hashTags = ref([]);
    const canAction = computed(() => (session.value.lrnerId !== socBoard.lrnerId && getLrnTrgtGrpMstSn(distCrseSn.value) === socBoard.lrnTrgtGrpMstSn));
    const socPages = ref([]);
    const currentPageIdx = ref(0);

    const currentPage = computed(() => {
      if(socPages.value.length > 0 && socPages.value[currentPageIdx.value]){
        return socPages.value[currentPageIdx.value];
      }
      return {};
    });

    const isNoneReward = computed(() => noneRewardCds.includes(route.params.distCrseCd));

    const isSubscribe = computed(() => {
      return (store.state.ssl.subscribe.my.filter(x => x.lrnTrgtGrpMstSn === getLrnTrgtGrpMstSn(distCrseSn.value) && x.trgtLrnerId === socBoard.lrnerId).length > 0);
    });

    const isLike = ref(false);
    const isPut = ref(false);


    const socLrnPostSn = ref(route.params.socLrnPostSn || 0);

    const getSocsPageList = () => {
      store.dispatch(`ssl/${ACT_GET_SOC_PAGE_LIST}`, socLrnPostSn.value).then(res => {
        // SocBoard
        if (res.socBoard) {
          if(route.params.distCrseCd === 'hrd') res.socBoard.refUrl = `${process.env.VUE_APP_LXP_URL}prep/hrdcloud/${res.socBoard.lrnObjDtlDistSn}/detail`;

          setParams(socBoard, res.socBoard);
          // myLikeCnt: 0
          if(res.socBoard.hashTag){
            hashTags.value = res.socBoard.hashTag.split(',');
          }

          isLike.value = (res.socBoard.myLikeCnt > 0);
          isPut.value = (res.socBoard.myPutCnt > 0);
          // SocPage
          if (lengthCheck(res)) {
            socPages.value = getItems(res);
            isReady.value = true;
          }

        }
        else{
          // TODO:: 읽기 권한이 없음 My Page로 이동
          socLrnPostSn.value = "0";
        }
      }).catch(e => {
        console.error(e);
      })
    }

    const dateToFormat = (regDt) => {
      if(regDt){
        return timestampToDateFormat(regDt, 'yyyy.MM.dd hh:mm')
      }
      return '';
    }

    const toggleLike = () => {
      showMessage(`좋아요는 SSL 학습사이트에서만 가능합니다.`);
    }

    const clickMain = () => {
      effected.value++;
    }


    onMounted(() => {
      document.oncontextmenu = function () {return false};
      document.onselectstart = function () {return false};
      document.ondragstart = function () {return false};
    });

    onBeforeUnmount(() => {
      document.oncontextmenu = function () {return true};
      document.onselectstart = function () {return true};
      document.ondragstart = function () {return true};
    });

    getSocsPageList();
    getAllSubscribes();

    return {
      effected,
      isReady,
      isNoneReward,
      session,

      socBoard,
      socPages,
      currentPageIdx,
      currentPage,

      isSubscribe,
      isPut,
      isLike,

      canAction,
      hashTags,

      distCrseSn,

      toggleLike,
      actSubscribe,

      dateToFormat,
      goBack,
      clickMain,
    }


  }
}
</script>
