  <!-- cloud-header -->
<template>
  <LxpMobileHeader title="퀴즈 추가">
    <template v-slot:left>
        <div class="util util-history-back">
            <a href="javascript:" class="util-actions util-actions-history-back"  @click="closeLayer"><i class="icon-history-back"></i></a>
        </div>
    </template>
  </LxpMobileHeader>
  <!-- //cloud-header -->
  <!-- cloud-main -->
  <main class="kb-main" id="kb-prep-quiz" :class="{'is-active': isQuizListAddLayerShow}">
    <!-- main-view -->
    <div class="main-content">
      <div>
        <div class="view-contents">
          <!-- content-section -->
          <article class="content-section section-forms">
            <div class="kb-form-fields kb-form-fields-v2">
              <div class="kb-form-field field-first">
                <div class="kb-form-row">
                  <label class="kb-form-label"><span class="kb-form-label-text">퀴즈명</span></label>
                </div>
                <div class="kb-form-row">
                  <input type="text" v-model="quizTitle" class="kb-form-control" placeholder="퀴즈명을 입력하세요." />
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
    <div class="page-buttons">
        <button class="kb-btn kb-btn-primary" @click="insertQuiz(quizTitle)"><span class="text">추가</span></button>
    </div>
  </main>
</template>
  <!-- //cloud-main -->

<script>
import {ref} from 'vue';
import LxpMobileHeader from '@/views/layout/lxp/header/mobile/LxpMobileHeader.vue';

export default {
  name: "MobileQuizMngListAdd",
  components: {LxpMobileHeader},
  props:{
    isQuizListAddLayerShow: Boolean,
    insertQuiz: Function,
  },
  emits:['update:isQuizListAddLayerShow'],
  setup(props, {emit}){

    const quizTitle = ref('');
    const closeLayer = () => {
      emit('update:isQuizListAddLayerShow', false);
      document.getElementById('kb-header').style.display = '';
      document.getElementById('kb-prep-quiz').style.display = '';
    }

    return{
      quizTitle,
      closeLayer
    }
  }
}
</script>

<style scoped>

</style>