import LxpLayout from '@/views/layout/lxp/LxpLayout';
import LxpMobileLayout from '@/views/layout/lxp/mobile/LxpMobileLayout';
import navigationUtils from "@/assets/js/navigationUtils";

import SupportClass from "@/views/pages/support/SupportClass";
import SupportMobileClass from "@/views/pages/support/mobile/SupportMobileClass";

import SupportEval from "@/views/pages/support/SupportEval";
import SupportMobileEval from "@/views/pages/support/mobile/SupportMobileEval";

import SupportTuition from "@/views/pages/support/SupportTuition";
import SupportMobileTuition from "@/views/pages/support/mobile/SupportMobileTuition";

import SupportLicenseMain from "@/views/pages/support/SupportLicenseMain";
import SupportLicenseMobileMain from "@/views/pages/support/mobile/SupportLicenseMobileMain";

import SupportLicenseHistory from "@/views/pages/support/SupportLicenseHistory";
import SupportLicenseMobileHistory from "@/views/pages/support/mobile/SupportLicenseMobileHistory";

import SupportStatusMain from "@/views/pages/support/SupportStatusMain";
import SupportStatusMobileMain from "@/views/pages/support/mobile/SupportStatusMobileMain";

// import BlankLayout from '@/views/layout/lxp/BlankLayout';

const isMobile = navigationUtils.any();

const supportRoutes = [
  {
    path: '/support',
    component: isMobile ? LxpMobileLayout : LxpLayout,
    children: [
      {
        path: 'class', // 학원
        name: "SupportClassFeeApply",
        component: isMobile ? SupportMobileClass : SupportClass
      },
      {
        path: 'eval',
        name: 'SupportEvalFeeApply',
        component: isMobile ? SupportMobileEval : SupportEval
      },
      {
        path: 'tuition',
        name: 'SupportTuitionApply',
        component: isMobile ? SupportMobileTuition : SupportTuition
      },
      {
        path: 'license',
        name: 'SupportLicenseApply',
        component: isMobile ? SupportLicenseMobileMain : SupportLicenseMain
      },
      {
        path: 'history',
        name: 'SupportLicenseHistory',
        component: isMobile ? SupportLicenseMobileHistory : SupportLicenseHistory
      },
      {
        path: 'status',
        name: 'SupportStatus',
        component: isMobile ? SupportStatusMobileMain : SupportStatusMain
      }
    ],
  },
  // {
  //   path: '/admin',
  //   component: BlankLayout,
  //   children: [
  //     {
  //       path: 'support',
  //       name: 'AdminSupport',
  //       component: () => import('../../views/pages/support/admin/SupportList')
  //     }
  //   ]
  // }
];

export const setSupportRoutes = (routes) => {
  routes.push(...supportRoutes);
}