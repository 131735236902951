<template>
  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-take">
    <!-- main-content -->
    <div class="main-content main-component">
      <div class="kb-form-layer is-active">
        <div class="layer-container" @click="closeLayer">
          <div class="layer">
            <div class="kb-form-layer-options">
              <div class="layer-option">
                <ul class="layer-option-list">
                  <li v-for="(item, idx) in fileItems" :key="idx" class="layer-option-item">
                    <a href="javascript:" class="layer-option-link" :title="item.fileNm" @click.stop="downloadFile(item)">
                      <span class="layer-option-text">{{item.fileNm}}</span>
                      <span class="text-muted">{{ convertToStorageBytes(item.fileSz)}}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //main-content -->
  </main>
</template>

<script>
import {commonDownloadFile, convertToStorageBytes} from "@/assets/js/util";
import {downloadAtch} from "@/assets/js/modules/help/help-common";
export default {
  name: "TakeMobileFileAtch",
  props:{
    isFileAtchListShow: Boolean,
    fileItems: Array,
  },
  emits: ['update:isFileAtchListShow'],
  setup(props, {emit}){

    const downloadFile = (atch) => {
      commonDownloadFile(atch);
    }

    const closeLayer = () => {
      emit('update:isFileAtchListShow', false)
    }

    return{
      downloadAtch,
      downloadFile,
      closeLayer,
      convertToStorageBytes
    }
  }
}
</script>

<style scoped>

</style>