// import store from '@/store';
import {useStore} from 'vuex';
import {computed, reactive, ref} from 'vue';
import {
  ACT_GET_MY_SOC_BOARD_COUNT,
  ACT_GET_SOC_REL_STAT,
} from '@/store/modules/ssl/ssl-index';
import {
  enrollSSL, getMyLearnTargetList,
  getTargetDistCrseSn,
  halfNo, sslRoutes,
  sslStatusStandard,
  isMyLrnTrgtGrpMstSn
} from '@/assets/js/modules/ssl/ssl-common';
import {getItem, lengthCheck, setParams} from '@/assets/js/util';
import {Controller, EffectFade, Pagination} from 'swiper';
import {useRouter} from 'vue-router';
import {useAlert} from "@/assets/js/modules/common/alert";

const {showMessage, showLoading, hideLoading} = useAlert();

// // 셀프러닝
export const sslStatus1Setup = () => {
  const store = useStore();
  const selfSocBoardCnt = ref(0);
  const sslDivisionCd = 'self';
  const targetStandard = sslStatusStandard[sslDivisionCd];

  const getSelfStat = () => {
    store.dispatch(`ssl/${ACT_GET_MY_SOC_BOARD_COUNT}`,{
      sslDivisionCd
    }).then(res => {
      if(res && res.socBoardCount){
        selfSocBoardCnt.value = res.socBoardCount;
      }
    }).catch(() => {});
  }

  getSelfStat();

  return {
    targetStandard,
    selfSocBoardCnt,
    modules: [Pagination]
  }
}

// 셀프러닝 공통
export const sslStatusSetup = (props) => {
  const store = useStore();
  const router = useRouter();
  const sslDivisionCd = computed(() => props.sslDivisionCd);
  const selfSocBoardCnt = ref(0);
  const targetStandard = sslStatusStandard['self'];
  const isMyLrnTrgt = computed(() => isMyLrnTrgtGrpMstSn(sslRoutes[sslDivisionCd.value].lrnTrgtGrpMstSns[0]));
  // const targetLrnTrgtGrpMstSn = ref(0);


  // if(isMyLrnTrgtGrpMstSn(sslRoutes[sslDivisionCd.value].lrnTrgtGrpMstSns[0])){
  //   targetLrnTrgtGrpMstSn.value = sslRoutes[sslDivisionCd.value].group[0].lrnTrgtGrpMstSn;
  // }

  const param = reactive({
    sslDivCd : sslDivisionCd.value,
    sslDivNm : sslRoutes[sslDivisionCd.value].name,
    targetLrnTrgtGrpMstSn : sslRoutes[sslDivisionCd.value].group[0].lrnTrgtGrpMstSn
  })


  const getSelfStat = () => {
    store.dispatch(`ssl/${ACT_GET_MY_SOC_BOARD_COUNT}`,{
      sslDivisionCd:sslDivisionCd.value,
    }).then(res => {
      if(res && res.socBoardCount){
        selfSocBoardCnt.value = res.socBoardCount;
      }
    }).catch(e => {
      console.error(e);
    });
  }

  getSelfStat();

  const enroll = reactive({
    key: null,
    toggle: false,
  });
  const goMain = (key, lrnTrgtGrpMstSn)  => {
    router.push({name: 'SSLMain', params: {distCrseCd: key, lrnTrgtGrpMstSn}}).then(() => {});
  }

  const myGroup = computed(() => store.state.ssl.group);
  const goLearn=(key, lrnTrgtGrpMstSn)=>{

    if (lrnTrgtGrpMstSn > 0) {
      goMain(key, lrnTrgtGrpMstSn);
    } else {
      // 그룹은 있지만 SSL 그룹러닝에 입과되지 않는 경우 강제 입과를 한다.
      if (key === 'group' && myGroup.value.distCrseSn > 0) {
        showLoading();
        enrollSSL(lrnTrgtGrpMstSn, result => {
          if (result > 0) {
            getMyLearnTargetList(() => {
              hideLoading();
              goMain(key, lrnTrgtGrpMstSn);
            }).then(() => {});
          } else {
            hideLoading()
            showMessage('서버처리 중 일시적인 장애가 발생하였습니다.<br>잠시 후 다시 시도 해 주시기 바랍니다.');
          }
        });
      }else {
        goMain(key, lrnTrgtGrpMstSn);
        // enroll.key = key;
        // enroll.toggle = true;
      }
    }
  }

  return {
    param,
    targetStandard,
    selfSocBoardCnt,
    enroll,
    goLearn,
    modules: [Pagination],
    isMyLrnTrgt
  }
}

// 그룹러닝
export const sslStatus2Setup = () => {
  const store = useStore();
  const router = useRouter();
  const sslDivisionCd = 'group';
  const targetStandard = sslStatusStandard[sslDivisionCd];

  const visualSwiper = ref(null);
  const controlledSwiper = ref(null);
  const groupSocBoardCnt = ref(0);
  const targetLrnTrgtGrpMstSn = ref(0);


  if(isMyLrnTrgtGrpMstSn(sslRoutes[sslDivisionCd].lrnTrgtGrpMstSns[0])){
    targetLrnTrgtGrpMstSn.value = sslRoutes[sslDivisionCd].lrnTrgtGrpMstSns[0];
  }

  const params = reactive({
    firstCnt: 0,
    secondCnt: 0,
    thirdCnt: 0,
    targetLrnTrgtGrpMstSn : targetLrnTrgtGrpMstSn.value
  });

  const getGroupStatList = () => {
    store.dispatch(`ssl/${ACT_GET_MY_SOC_BOARD_COUNT}`,{
      sslDivisionCd
    }).then(res => {
      if(res && res.socBoardCount) groupSocBoardCnt.value = res.socBoardCount;
    }).catch(() => {});

    const distCrseSn = getTargetDistCrseSn(sslDivisionCd);
    if (distCrseSn > 0) {
      store.dispatch(`ssl/${ACT_GET_SOC_REL_STAT}`, {division: sslDivisionCd, distCrseSn}).then(res => {
        if (lengthCheck(res)) setParams(params, getItem(res))
      });
    }
  }

  const setControlledSwiper = (swiper) => {
    controlledSwiper.value = swiper;
  };

  const setVisualSwiper = (swiper) => {
    visualSwiper.value = swiper;
  };


  getGroupStatList();


  const enroll = reactive({
    key: null,
    toggle: false,
  });
  const goMain = (key, lrnTrgtGrpMstSn)  => {
    router.push({name: 'SSLMain', params: {distCrseCd: key, lrnTrgtGrpMstSn}}).then(() => {});
  }

  const myGroup = computed(() => store.state.ssl.group);

  const goLearn=(key, lrnTrgtGrpMstSn)=>{

    if (lrnTrgtGrpMstSn > 0) {
      goMain(key, lrnTrgtGrpMstSn);
    } else {
      // 그룹은 있지만 SSL 그룹러닝에 입과되지 않는 경우 강제 입과를 한다.
      if (key === 'group' && myGroup.value.distCrseSn > 0) {
        showLoading();
        enrollSSL(lrnTrgtGrpMstSn, result => {
          if (result > 0) {
            getMyLearnTargetList(() => {
              hideLoading();
              goMain(key, lrnTrgtGrpMstSn);
            }).then(() => {});
          } else {
            hideLoading()
            showMessage('서버처리 중 일시적인 장애가 발생하였습니다.<br>잠시 후 다시 시도 해 주시기 바랍니다.');
          }
        });
      }else {
        if(key === 'group'){
          router.push({name: 'GroupLearning'}).then(() => {});
        }else{

          goMain(key, lrnTrgtGrpMstSn);
        }
        //입과되지 않은 과정인경우 입과안내창 띄우기
        // enroll.key = key;
        // enroll.toggle = true;
      }
    }
  }

  return {
    targetStandard,
    groupSocBoardCnt,
    params,
    controlledSwiper,
    visualSwiper,
    setControlledSwiper,
    setVisualSwiper,
    enroll,
    goLearn,
    modules: [Pagination, Controller, EffectFade]
  }
}


// HRD 클라우드
export const sslStatus3Setup = () => {
  const store = useStore();
  const sslDivisionCd = 'hrd';
  const hrdSocBoardCnt = ref(0);
  const targetStandard = sslStatusStandard[sslDivisionCd];
  // const circleDeg = computed(() => getCircleDeg(hrdSocBoardCnt.value));

  const getHrdCloudStat = () => {
    store.dispatch(`ssl/${ACT_GET_MY_SOC_BOARD_COUNT}`,{
      sslDivisionCd
    }).then(res => {
      if(res && res.socBoardCount) {
        hrdSocBoardCnt.value = res.socBoardCount;
      }
    }).catch(() => {});
  }

  getHrdCloudStat();

  return {
    targetStandard,
    hrdSocBoardCnt,
    modules: [Pagination]
    // circleDeg
  }

}

// 지식-e
export const sslStatus4Setup = () => {
  const store = useStore();

  const sslDivisionCd = 'knowledge';
  const targetStandard = sslStatusStandard[sslDivisionCd];

  const visualSwiper = ref(null);
  const controlledSwiper = ref(null);
  const knowledgeSocBoardCnt = ref(0);

  const params = reactive({
    firstCnt: 0,
    secondCnt: 0,
    thirdCnt: 0,
    avgScore: 0
  });

  const averageScore = computed(() => {
    return params.avgScore > 0 ? params.avgScore: 0;
  });

  const getSangsiStatList = () => {
    store.dispatch(`ssl/${ACT_GET_MY_SOC_BOARD_COUNT}`,{
      sslDivisionCd
    }).then(res => {
      if(res && res.socBoardCount) knowledgeSocBoardCnt.value = res.socBoardCount;
    }).catch(() => {});

    const distCrseSn = getTargetDistCrseSn(sslDivisionCd);
    if (distCrseSn > 0) {
      store.dispatch(`ssl/${ACT_GET_SOC_REL_STAT}`, {division: sslDivisionCd, distCrseSn}).then(res => {
        if (lengthCheck(res)) setParams(params, getItem(res))
      });
    }
  }

  const setControlledSwiper = (swiper) => {
    controlledSwiper.value = swiper;
  };

  const setVisualSwiper = (swiper) => {
    visualSwiper.value = swiper;
  };

  getSangsiStatList();

  return {
    targetStandard,
    knowledgeSocBoardCnt,
    params,
    averageScore,
    controlledSwiper,
    visualSwiper,
    setControlledSwiper,
    setVisualSwiper,
    modules: [Pagination, Controller, EffectFade]
  }
}

// 북러닝
export const sslStatus5Setup = () => {
  const store = useStore();
  const visualSwiper = ref(null);
  const controlledSwiper = ref(null);

  const bookFirstSocBoardCnt = ref(0);
  const bookSecondSocBoardCnt = ref(0);

  const sslDivisionCd = 'book';
  const firstDivisionCd = sslDivisionCd + '-first';
  const secondDivisionCd = sslDivisionCd + '-second';

  const firstStandard = sslStatusStandard[firstDivisionCd];
  const secondStandard = sslStatusStandard[secondDivisionCd];

  const getTriangleHeight = (count, standard) => {
    if(count > 0 && standard > 0){
      const share = (count / standard);
      if(share > 1){
        return 0;
      }
      return 105 - (share * 105);
    }
    return 105;
  }

  const getBookFirstStat = () => {
    store.dispatch(`ssl/${ACT_GET_MY_SOC_BOARD_COUNT}`,{
      sslDivisionCd: firstDivisionCd
    }).then(res => {
      if(res && res.socBoardCount) bookFirstSocBoardCnt.value = res.socBoardCount;
    }).catch(() => {});
  }

  const getBookSecondStat = () => {
    store.dispatch(`ssl/${ACT_GET_MY_SOC_BOARD_COUNT}`,{
      sslDivisionCd: secondDivisionCd
    }).then(res => {
      if(res && res.socBoardCount) bookSecondSocBoardCnt.value = res.socBoardCount;
    }).catch(() => {});
  }

  const setControlledSwiper = (swiper) => {
    controlledSwiper.value = swiper;
  };

  const setVisualSwiper = (swiper) => {
    visualSwiper.value = swiper;
  };

  const activeIdx = ref(0);

  const activeBook = computed(() => {
    if (halfNo > 1) {
      return activeIdx.value === 0 ? '하반기' : '상반기';
    }
    return '';
  });

  const toggleActive = (e) => {
    activeIdx.value = e.activeIndex;
  }

  getBookFirstStat();
  if (halfNo > 1) {
    getBookSecondStat();
  }



  return {
    halfNo,
    getTriangleHeight,
    controlledSwiper,
    visualSwiper,
    firstStandard,
    secondStandard,
    bookFirstSocBoardCnt,
    bookSecondSocBoardCnt,
    setControlledSwiper,
    setVisualSwiper,
    activeBook,
    toggleActive,
    modules: [Pagination, Controller, EffectFade]
  }
}
