<template>
  <v-lazy-image v-if="imgSrc != null" class="kb-thumb-list" :src="imgSrc" @error="setDefaultImg" />
  <img v-else class="kb-thumb-list" :src="setEmptyImg()" alt="">
</template>

<script>
import VLazyImage from 'v-lazy-image';

export default {
  name: 'CommonLazyImage',
  components: {VLazyImage},
  props: {
    imgSrc: String
  },
  setup() {
    // const rand = ref(Math.floor(Math.random() * 6) + 1);
    const setEmptyImg = () => {
      return require('../../assets/lxp/images/@tmp/@tmp_hrdcloud_02_03.jpg');
    };

    const setDefaultImg = (e) => {
      e.src = setEmptyImg();
    };

    return {
      setDefaultImg,
      setEmptyImg
    }
  }
};
</script>