<template>
  <!-- main-content -->
  <div class="main-content min-component">
    <!-- section: 상품판매 필수 자격증 -->
    <article class="content-section section-01">
      <header class="section-header">
        <div class="header-column">
          <h4 class="title">상품판매 필수 자격증</h4>
        </div>
        <!-- [yyy:220823] -->
<!--        <div class="header-column">
          <a href="" class="download" download="엑셀.xslx"><span class="text">엑셀 다운로드</span><i class="icon-download"></i></a>
        </div>-->
        <!-- //[yyy:220823] -->
      </header>
      <div class="guide-table">
        <div class="kb-table kb-table-bordered-v2 kb-table-bordered-v2-min">
          <table>
            <colgroup>
              <col style="width:196px">
              <col style="width:92px">
              <col style="width:92px">
              <col style="width:152px">
              <col style="width:120px">
              <col style="width:120px">
              <col style="width:120px">
              <col style="width:auto">
            </colgroup>
            <thead>
            <tr>
              <th><span class="th-title">자격증명</span></th>
              <th><span class="th-title">세부사항</span></th>
              <th><span class="th-title">취득여부</span></th>
              <th><span class="th-title">주관처</span></th>
              <th><span class="th-title">지원금액</span></th>
              <th><span class="th-title">등재일</span></th>
              <th><span class="th-title">자격취득일</span></th>
              <th><span class="th-title">비고</span></th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <th rowspan="3"><strong class="th-title">펀드투자 권유자문인력</strong></th>
              <td><span class="td-text">증권</span></td>
              <td class="cell-center"><span class="td-text"><i :class="{'icon-o': isStockFundLcsn}">{{isStockFundLcsn ? '' : '-'}}</i></span></td>
              <td><span class="td-text">한국금융투자협회</span></td>
              <td><span class="td-text">{{stockFund.sprtAmt ? stockFund.sprtAmt : '-'}}</span></td>
              <td><span class="td-text">{{stockFund.lcsnRegYmd ? stockFund.lcsnRegYmd : '-'}}</span></td>
              <td><span class="td-text">{{stockFund.passYmd}}</span></td>
              <td><span class="td-text">{{stockFund.lcsnNm ? stockFund.lcsnNm+' 취득' : '-'}}</span></td>
            </tr>
            <tr>
              <td class="border-s"><span class="td-text">파생상품</span></td>
              <td class="cell-center"><span class="td-text"><i :class="{'icon-o': isDerivationFundLcsn}">{{isDerivationFundLcsn ? '' : '-'}}</i></span></td>
              <td><span class="td-text">한국금융투자협회</span></td>
              <td><span class="td-text">{{derivationFund.sprtAmt ? derivationFund.sprtAmt : '-'}}</span></td>
              <td><span class="td-text">{{derivationFund.lcsnRegYmd ? derivationFund.lcsnRegYmd : '-'}}</span></td>
              <td><span class="td-text">{{derivationFund.passYmd}}</span></td>
              <td><span class="td-text">{{derivationFund.lcsnNm ? derivationFund.lcsnNm+' 취득' : '-'}}</span></td>
            </tr>
            <tr>
              <td class="border-s"><span class="td-text">부동산</span></td>
              <td class="cell-center"><span class="td-text"><i :class="{'icon-o': isRealEstateFundLcsn}">{{isRealEstateFundLcsn ? '' : '-'}}</i></span></td>
              <td><span class="td-text">한국금융투자협회</span></td>
              <td><span class="td-text">{{realEstateFund.sprtAmt ? realEstateFund.sprtAmt : '-'}}</span></td>
              <td><span class="td-text">{{realEstateFund.lcsnRegYmd ? realEstateFund.lcsnRegYmd : '-'}}</span></td>
              <td><span class="td-text">{{realEstateFund.passYmd}}</span></td>
              <td><span class="td-text">{{realEstateFund.lcsnNm ? realEstateFund.lcsnNm+' 취득' : '-'}}</span></td>
            </tr>
            <tr>
              <th><strong class="th-title">파생상품투자 권유자문인력</strong></th>
              <td><span class="td-text">-</span></td>
              <td class="cell-center"><span class="td-text"><i :class="{'icon-o': isDerivationLcsn}">{{isDerivationLcsn ? '' : '-'}}</i></span></td>
              <td><span class="td-text">금융투자협회</span></td>
              <td><span class="td-text">{{derivation.sprtAmt ? derivation.sprtAmt : '-'}}</span></td>
              <td><span class="td-text">{{derivation.lcsnRegYmd ? derivation.lcsnRegYmd : '-'}}</span></td>
              <td><span class="td-text">{{derivation.passYmd}}</span></td>
              <td><span class="td-text">{{derivation.lcsnNm ? derivation.lcsnNm+' 취득' : '-'}}</span></td>
            </tr>
            <tr>
              <th rowspan="4"><strong class="th-title">보험대리점</strong></th>
              <td><span class="td-text">생명보험</span></td>
              <td class="cell-center"><span class="td-text"><i :class="{'icon-o': isLifeInsuranceLcsn}">{{isLifeInsuranceLcsn ? '' : '-'}}</i></span></td>
              <td><span class="td-text">보험연수원</span></td>
              <td><span class="td-text">{{lifeInsurance.sprtAmt ? lifeInsurance.sprtAmt : '-'}}</span></td>
              <td><span class="td-text">{{lifeInsurance.lcsnRegYmd ? lifeInsurance.lcsnRegYmd : '-'}}</span></td>
              <td><span class="td-text">{{lifeInsurance.passYmd}}</span></td>
              <td><span class="td-text">{{lifeInsurance.lcsnNm ? lifeInsurance.lcsnNm+' 취득' : '-'}}</span></td>
            </tr>
            <tr>
              <td class="border-s"><span class="td-text">제3보험</span></td>
              <td class="cell-center"><span class="td-text"><i :class="{'icon-o': isThirdInsuranceLcsn}">{{isThirdInsuranceLcsn ? '' : '-'}}</i></span></td>
              <td><span class="td-text">보험연수원</span></td>
              <td><span class="td-text">{{thirdInsurance.sprtAmt ? thirdInsurance.sprtAmt : '-'}}</span></td>
              <td><span class="td-text">{{thirdInsurance.lcsnRegYmd ? thirdInsurance.lcsnRegYmd : '-'}}</span></td>
              <td><span class="td-text">{{thirdInsurance.passYmd}}</span></td>
              <td><span class="td-text">{{thirdInsurance.lcsnNm ? thirdInsurance.lcsnNm+' 취득' : '-'}}</span></td>
            </tr>
            <tr>
              <td class="border-s"><span class="td-text">손해보험</span></td>
              <td class="cell-center"><span class="td-text"><i :class="{'icon-o': isGeneralInsuranceLcsn}">{{isGeneralInsuranceLcsn ? '' : '-'}}</i></span></td>
              <td><span class="td-text">보험연수원</span></td>
              <td><span class="td-text">{{generalInsurance.sprtAmt ? generalInsurance.sprtAmt : '-'}}</span></td>
              <td><span class="td-text">{{generalInsurance.lcsnRegYmd ? generalInsurance.lcsnRegYmd : '-'}}</span></td>
              <td><span class="td-text">{{generalInsurance.passYmd}}</span></td>
              <td><span class="td-text">{{generalInsurance.lcsnNm ? generalInsurance.lcsnNm+' 취득' : '-'}}</span></td>
            </tr>
            <tr>
              <td class="border-s"><span class="td-text">변액보험</span></td>
              <td class="cell-center"><span class="td-text"><i :class="{'icon-o': isVariableInsuranceLcsn}">{{isVariableInsuranceLcsn ? '' : '-'}}</i></span></td>
              <td><span class="td-text">생명보험협회</span></td>
              <td><span class="td-text">{{variableInsurance.sprtAmt ? variableInsurance.sprtAmt : '-'}}</span></td>
              <td><span class="td-text">{{variableInsurance.lcsnRegYmd ? variableInsurance.lcsnRegYmd : '-'}}</span></td>
              <td><span class="td-text">{{variableInsurance.passYmd}}</span></td>
              <td><span class="td-text">{{variableInsurance.lcsnNm ? variableInsurance.lcsnNm+' 취득' : '-'}}</span></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </article>
    <!-- section: 자격증 이력 -->
    <article class="content-section section-02 section-spacing">
      <header class="section-header">
        <h4 class="title">자격증 이력</h4>
      </header>
      <div class="guide-table">
        <div class="list-top">
          <div class="top-column pt-5">
            <p class="title text-muted">총 {{paging.totalCount}}건</p>
          </div>
          <div class="top-column">
            <SortButtonSelect
                v-model="filters.year"
                v-model:toggle="toggles.year"
                title="년도"
                :options="yearOptions"
                sequence-key="value"
                name-key="display"
                button-class="kb-btn kb-btn-outline"
                :is-all="true"
                :is-num="true"
                @update:toggle="closeToggles(toggles, 'year')"
                @selected="pagingFunc(1)"
            />
            <div class="kb-form-search">
              <div class="kb-form-search-field">
                <input v-model="filters.keyword" type="text" class="kb-form-search-input" @keyup.enter="getLcsnList" placeholder="검색">
                <button class="kb-form-search-btn" @click="getLcsnList"><i class="icon-search">검색</i></button>
              </div>
            </div>
<!--            </form>-->
          </div>
        </div>
        <div class="kb-table kb-table-bordered-v2">
          <table>
            <colgroup>
              <col style="width:auto">
              <col style="width:184px">
              <col style="width:144px">
              <col style="width:154px">
              <col style="width:100px">
              <col style="width:127px">
            </colgroup>
            <thead>
              <tr>
                <th><span class="th-title">자격증명</span></th>
                <th><span class="th-title">주관처</span></th>
                <th><span class="th-title">자격증코드</span></th>
                <th><span class="th-title">자격적용일</span></th>
                <th><span class="th-title">등급</span></th>
                <th><span class="th-title">지원 금액</span></th>
              </tr>
            </thead>
            <tbody>
              <template v-if="licenses.length > 0">
                <tr v-for="(item, index) in licenses" :key="index">
                  <th><strong class="th-title">{{item.lcsnNm}}</strong></th>
                  <td><span class="td-text">{{item.pblcnInstNm}}</span></td>
                  <td><span class="td-text">{{item.lcsnCd}}</span></td>
                  <td><span class="td-text">{{item.passYmd}}</span></td>
                  <td><span class="td-text">{{item.grd1Cd}}</span></td>
                  <td><span class="td-text">{{item.sprtAmt}}</span></td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="6"><span class="td-text text-muted">검색 결과가 없습니다.</span></td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>
      </div>
    </article>
  </div>
  <!-- //main-content -->
</template>

<script>
import {useStore} from "vuex";
import {reactive, ref, watch} from "vue";
import {closeToggles} from '@/assets/js/ui.init';
import {
  ACT_GET_SUPPORT_LICENSE_ALL_HISTORY_LIST,
  ACT_GET_SUPPORT_LICENSE_HISTORY_LIST
} from "@/store/modules/support/support";
import {
  getItems,
  getPaging,
  lengthCheck,
  numberComma,
  setParamsByQueryString,
  timestampToDateFormat,
  ymdStrToDateFormat
} from "@/assets/js/util";
import {
  derivationFundLcsn,
  derivationLcsn,
  generalInsuranceLcsn,
  lifeInsuranceLcsn,
  realEstateFundLcsn,
  stockFundLcsn,
  thirdInsuranceLcsn,
  variableInsuranceLcsn
} from "@/assets/js/modules/support/support-common";
import CommonPaginationFront from "@/components/CommonPaginationFront";
import {useRoute, useRouter} from "vue-router";
import SortButtonSelect from "@/components/common/SortButtonSelect";
export default {
  name: 'SupportLicenseHistory',
  components: {SortButtonSelect, CommonPaginationFront},
  setup(){
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const licenses = ref([]);
    const allLicenses = ref([]);

    const stockFund = ref([]);
    const derivationFund = ref([]);
    const realEstateFund = ref([]);
    const derivation = ref([]);
    const lifeInsurance = ref([]);
    const generalInsurance = ref([]);
    const thirdInsurance = ref([]);
    const variableInsurance = ref([]);
    const currentDate = new Date();

    const yearOptions = (() => {
      const options = [];
      for(let i = currentDate.getFullYear(); i >= 1978 ; i--){
        options.push({value: i, display: `${i}년`});
      }
      return options;
    })();

    const toggles = reactive({
      year: false,
    });

    const filters = reactive({
      year: '',
      keyword: '',
    });

    const paging = ref({pageNo: 1, pageSize: 50, totalCount: 0});

    const pagingFunc = (pageNo, pageSize) => {
      const query = {pageNo: pageNo};
      if (pageSize !== paging.value.pageSize) {
        query.pageSize = pageSize;
      }
      console.log('query : ',query)
      router.push({query: query});
    };

    const clickSearch = () => {
      pagingFunc(1,10);
    };

    const isStockFundLcsn = ref(false);
    const isDerivationFundLcsn = ref(false);
    const isRealEstateFundLcsn = ref(false);
    const isDerivationLcsn = ref(false);
    const isLifeInsuranceLcsn = ref(false);
    const isGeneralInsuranceLcsn = ref(false);
    const isThirdInsuranceLcsn = ref(false);
    const isVariableInsuranceLcsn = ref(false);

    const getAllLcsnList = () => {
      store.dispatch(`support/${ACT_GET_SUPPORT_LICENSE_ALL_HISTORY_LIST}`, {
      }).then(res => {
        if(lengthCheck(res)){
          allLicenses.value.push(...getItems(res));
          allLicenses.value.forEach(x => {
            if(stockFundLcsn.includes(x.lcsnCd)) {
              isStockFundLcsn.value = true;
              stockFund.value.sprtAmt = x.sprtAmt ? numberComma(x.sprtAmt) : '-';
              stockFund.value.lcsnRegYmd = x.lcsnRegYmd ? timestampToDateFormat(x.lcsnRegYmd, 'yyyy.MM.dd') : '-';
              if(x.passAnnonYmd){
                stockFund.value.passYmd = ymdStrToDateFormat(x.passAnnonYmd, 'yyyy.MM.dd');
              }else if(x.lcsnExamYmd){
                stockFund.value.passYmd = ymdStrToDateFormat(x.lcsnExamYmd, 'yyyy.MM.dd');
              }else if(x.lcsnRegYmd){
                stockFund.value.passYmd =  ymdStrToDateFormat(x.lcsnRegYmd, 'yyyy.MM.dd');
              }else{
                stockFund.value.passYmd =  x.regDt ? timestampToDateFormat(x.regDt,'yyyy.MM.dd') : '-';
              }
              stockFund.value.lcsnNm = x.lcsnNm ? x.lcsnNm : '-';
            }
            if(derivationFundLcsn.includes(x.lcsnCd)) {
              isDerivationFundLcsn.value = true;
              derivationFund.value.sprtAmt = x.sprtAmt ? numberComma(x.sprtAmt) : '-';
              derivationFund.value.lcsnRegYmd = x.lcsnRegYmd ? timestampToDateFormat(x.lcsnRegYmd, 'yyyy.MM.dd') : '-';
              if(x.passAnnonYmd){
                derivationFund.value.passYmd = ymdStrToDateFormat(x.passAnnonYmd, 'yyyy.MM.dd');
              }else if(x.lcsnExamYmd){
                derivationFund.value.passYmd = ymdStrToDateFormat(x.lcsnExamYmd, 'yyyy.MM.dd');
              }else if(x.lcsnRegYmd){
                derivationFund.value.passYmd =  ymdStrToDateFormat(x.lcsnRegYmd, 'yyyy.MM.dd');
              }else{
                derivationFund.value.passYmd =  x.regDt ? timestampToDateFormat(x.regDt,'yyyy.MM.dd') : '-';
              }
              derivationFund.value.lcsnNm = x.lcsnNm ? x.lcsnNm : '-';
            }
            if(realEstateFundLcsn.includes(x.lcsnCd)) {
              isRealEstateFundLcsn.value = true;
              realEstateFund.value.sprtAmt = x.sprtAmt ? numberComma(x.sprtAmt) : '-';
              realEstateFund.value.lcsnRegYmd = x.lcsnRegYmd ? timestampToDateFormat(x.lcsnRegYmd, 'yyyy.MM.dd') : '-';
              if(x.passAnnonYmd){
                realEstateFund.value.passYmd = ymdStrToDateFormat(x.passAnnonYmd, 'yyyy.MM.dd');
              }else if(x.lcsnExamYmd){
                realEstateFund.value.passYmd = ymdStrToDateFormat(x.lcsnExamYmd, 'yyyy.MM.dd');
              }else if(x.lcsnRegYmd){
                realEstateFund.value.passYmd =  ymdStrToDateFormat(x.lcsnRegYmd, 'yyyy.MM.dd');
              }else{
                realEstateFund.value.passYmd =  x.regDt ? timestampToDateFormat(x.regDt,'yyyy.MM.dd') : '-';
              }
              realEstateFund.value.lcsnNm = x.lcsnNm ? x.lcsnNm : '-';
            }
            if(derivationLcsn.includes(x.lcsnCd)) {
              isDerivationLcsn.value = true;
              derivation.value.sprtAmt = x.sprtAmt ? numberComma(x.sprtAmt) : '-';
              derivation.value.lcsnRegYmd = x.lcsnRegYmd ? timestampToDateFormat(x.lcsnRegYmd, 'yyyy.MM.dd') : '-';
              if(x.passAnnonYmd){
                derivation.value.passYmd = ymdStrToDateFormat(x.passAnnonYmd, 'yyyy.MM.dd');
              }else if(x.lcsnExamYmd){
                derivation.value.passYmd = ymdStrToDateFormat(x.lcsnExamYmd, 'yyyy.MM.dd');
              }else if(x.lcsnRegYmd){
                derivation.value.passYmd =  ymdStrToDateFormat(x.lcsnRegYmd, 'yyyy.MM.dd');
              }else{
                derivation.value.passYmd =  x.regDt ? timestampToDateFormat(x.regDt,'yyyy.MM.dd') : '-';
              }
              derivation.value.lcsnNm = x.lcsnNm ? x.lcsnNm : '-';
            }
            if(lifeInsuranceLcsn.includes(x.lcsnCd)) {
              isLifeInsuranceLcsn.value = true;
              lifeInsurance.value.sprtAmt = x.sprtAmt ? numberComma(x.sprtAmt) : '-';
              lifeInsurance.value.lcsnRegYmd = x.lcsnRegYmd ? timestampToDateFormat(x.lcsnRegYmd, 'yyyy.MM.dd') : '-';
              if(x.passAnnonYmd){
                lifeInsurance.value.passYmd = ymdStrToDateFormat(x.passAnnonYmd, 'yyyy.MM.dd');
              }else if(x.lcsnExamYmd){
                lifeInsurance.value.passYmd = ymdStrToDateFormat(x.lcsnExamYmd, 'yyyy.MM.dd');
              }else if(x.lcsnRegYmd){
                lifeInsurance.value.passYmd =  ymdStrToDateFormat(x.lcsnRegYmd, 'yyyy.MM.dd');
              }else{
                lifeInsurance.value.passYmd =  x.regDt ? timestampToDateFormat(x.regDt,'yyyy.MM.dd') : '-';
              }
              lifeInsurance.value.lcsnNm = x.lcsnNm ? x.lcsnNm : '-';
            }
            if(generalInsuranceLcsn.includes(x.lcsnCd)) {
              isGeneralInsuranceLcsn.value = true;
              generalInsurance.value.sprtAmt = x.sprtAmt ? numberComma(x.sprtAmt) : '-';
              generalInsurance.value.lcsnRegYmd = x.lcsnRegYmd ? timestampToDateFormat(x.lcsnRegYmd, 'yyyy.MM.dd') : '-';
              if(x.passAnnonYmd){
                generalInsurance.value.passYmd = ymdStrToDateFormat(x.passAnnonYmd, 'yyyy.MM.dd');
              }else if(x.lcsnExamYmd){
                generalInsurance.value.passYmd = ymdStrToDateFormat(x.lcsnExamYmd, 'yyyy.MM.dd');
              }else if(x.lcsnRegYmd){
                generalInsurance.value.passYmd =  ymdStrToDateFormat(x.lcsnRegYmd, 'yyyy.MM.dd');
              }else{
                generalInsurance.value.passYmd =  x.regDt ? timestampToDateFormat(x.regDt,'yyyy.MM.dd') : '-';
              }
              generalInsurance.value.lcsnNm = x.lcsnNm ? x.lcsnNm : '-';
            }
            if(thirdInsuranceLcsn.includes(x.lcsnCd)) {
              isThirdInsuranceLcsn.value = true;
              thirdInsurance.value.sprtAmt = x.sprtAmt ? numberComma(x.sprtAmt) : '-';
              thirdInsurance.value.lcsnRegYmd = x.lcsnRegYmd ? timestampToDateFormat(x.lcsnRegYmd, 'yyyy.MM.dd') : '-';
              if(x.passAnnonYmd){
                thirdInsurance.value.passYmd = ymdStrToDateFormat(x.passAnnonYmd, 'yyyy.MM.dd');
              }else if(x.lcsnExamYmd){
                thirdInsurance.value.passYmd = ymdStrToDateFormat(x.lcsnExamYmd, 'yyyy.MM.dd');
              }else if(x.lcsnRegYmd){
                thirdInsurance.value.passYmd =  ymdStrToDateFormat(x.lcsnRegYmd, 'yyyy.MM.dd');
              }else{
                thirdInsurance.value.passYmd =  x.regDt ? timestampToDateFormat(x.regDt,'yyyy.MM.dd') : '-';
              }
              thirdInsurance.value.lcsnNm = x.lcsnNm ? x.lcsnNm : '-';
            }
            if(variableInsuranceLcsn.includes(x.lcsnCd)) {
              isVariableInsuranceLcsn.value = true;
              variableInsurance.value.sprtAmt = x.sprtAmt ? numberComma(x.sprtAmt) : '-';
              variableInsurance.value.lcsnRegYmd = x.lcsnRegYmd ? timestampToDateFormat(x.lcsnRegYmd, 'yyyy.MM.dd') : '-';
              if(x.passAnnonYmd){
                variableInsurance.value.passYmd = ymdStrToDateFormat(x.passAnnonYmd, 'yyyy.MM.dd');
              }else if(x.lcsnExamYmd){
                variableInsurance.value.passYmd = ymdStrToDateFormat(x.lcsnExamYmd, 'yyyy.MM.dd');
              }else if(x.lcsnRegYmd){
                variableInsurance.value.passYmd =  ymdStrToDateFormat(x.lcsnRegYmd, 'yyyy.MM.dd');
              }else{
                variableInsurance.value.passYmd =  x.regDt ? timestampToDateFormat(x.regDt,'yyyy.MM.dd') : '-';
              }
              variableInsurance.value.lcsnNm = x.lcsnNm ? x.lcsnNm : '-';
            }
          });
        }
      }).catch(e => {
        console.error(e);
      });
    }

    const getLcsnList = () => {
      store.dispatch(`support/${ACT_GET_SUPPORT_LICENSE_HISTORY_LIST}`, {
        year: filters.year > 0 ? filters.year : '',
        lcsnNm: filters.keyword,
        acqsUpdtDiv: 'N',
        pageNo: paging.value.pageNo,
        pageSize: paging.value.pageSize
      }).then(res => {
        if(lengthCheck(res)){
          licenses.value = getItems(res);
          paging.value = getPaging(res);
          licenses.value.forEach(x => {
            if(x.passAnnonYmd){
              x.passYmd = ymdStrToDateFormat(x.passAnnonYmd, 'yyyy.MM.dd');
            }else if(x.lcsnExamYmd){
              x.passYmd = ymdStrToDateFormat(x.lcsnExamYmd, 'yyyy.MM.dd');
            }else if(x.lcsnRegYmd){
              x.passYmd = ymdStrToDateFormat(x.lcsnRegYmd, 'yyyy.MM.dd');
            }else{
              x.passYmd = x.regDt ? timestampToDateFormat(x.regDt,'yyyy.MM.dd') : '-';
            }
            x.sprtAmt = x.sprtAmt ? numberComma(x.sprtAmt) : '-';
          })
        }else{
          licenses.value = [];
          paging.value.totalCount = 0;
        }
      }).catch(e => {
        console.error(e);
      });
    }

    watch(() => route.query, () => {
      setParamsByQueryString(route, 'SupportLicenseApply', paging, filters, true);
      getAllLcsnList();
      getLcsnList();
    });

    getAllLcsnList();
    getLcsnList();

    return{
      licenses,filters,yearOptions,closeToggles,toggles,allLicenses,
      isStockFundLcsn,isDerivationFundLcsn,isRealEstateFundLcsn,isDerivationLcsn,isLifeInsuranceLcsn,isGeneralInsuranceLcsn,isThirdInsuranceLcsn,isVariableInsuranceLcsn,
      stockFund,derivationFund,realEstateFund,derivation,lifeInsurance,generalInsurance,thirdInsurance,variableInsurance,
      paging,pagingFunc,
      clickSearch,
      getLcsnList
    }
  }
};
</script>