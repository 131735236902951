<template>
  <file-down-link file-key="SSL_2023Paper" :add-link-class="trialClass.split(' ')" :add-text-class="textClass.split(' ')" :show-icon="showArrow" icon-class="icon-arrow" />
</template>
<script>

// import {openPopUp} from '@/assets/js/modules/ssl/ssl-common';
import FileDownLink from "@/components/common/FileDownLink";

export default {
  name: 'SSLTrial',
  components: {FileDownLink},
  props: {
    trialClass: String,
    textClass: String,
    showArrow: Boolean
  }
}
</script>
