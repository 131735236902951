<template>
  <div class="page-mode">
    <div class="mode-tabs">
      <a href="javascript:" :class="{ 'is-active' : mode !== 'dark' }" class="tab" @click="lightMode">집중모드 꺼짐</a>
      <a href="javascript:" :class="{ 'is-active' : mode === 'dark' }" class="tab" @click="darkMode">집중모드 켜짐</a>
    </div>
  </div>
</template>

<script>
import {computed, onBeforeUnmount, onMounted} from 'vue';
import {useStore} from 'vuex';
import {darkMode, lightMode, toggleConcentrationMode} from '@/assets/js/modules/hrd/hrd-common';

export default {
  name: 'HrdCloudMobileMode',
  setup() {
    const store = useStore();
    const mode = computed(() => store.state.hrdcloud.mode);

    onMounted(() => {
      darkMode();
    });

    onBeforeUnmount(() => {
      lightMode();
    });

    return {
      mode, darkMode, lightMode,
      toggleConcentrationMode
    }
  }
};
</script>