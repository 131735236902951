<template>
  <div class="board-view-source" style="margin-bottom: 5px">
    <div class="source-tags">
      <span v-for="(hashTag, idx) in hashTags" class="tag" :key="idx">{{ hasTagPrefix }}{{ hashTag }}</span>
    </div>
<!--    <template v-if="distCrseCd === 'self'">-->
<!--    </template>-->
    <template v-if="distCrseCd === 'group'">
      <div class="source-category">
        <p v-if="relInfos.length > 2" class="text">{{ relInfos[0] }} | {{ relInfos[1] }} | {{ relInfos[2] }}</p>
      </div>
    </template>

    <template v-else-if="distCrseCd === 'knowledge'">
      <div class="source-category">
        <div v-if="relInfos.length > 0" class="image-box kb-mouse-cursor">
          <div class="image">
            <img src="@/assets/lxp/images/ssl/myssl/@img_jisike.png" alt="">
          </div>
          <div class="content">
            <span class="text">{{ relInfos[0] }}</span>
          </div>
        </div>
      </div>
    </template>

    <template v-else-if="distCrseCd === 'hrd'">
      <div class="source-category">
        <div v-if="relInfos.length > 2" class="image-box kb-mouse-cursor">
          <div class="image">
            <CommonTypeImage
                :sequence="relInfos[3]"
                :img-src="relInfos[0]"
                :target="relInfos"
                @clickImage="goToHrd"
            />

            <img :src="relInfos[0]" alt="">
          </div>
          <div class="content">
            <span class="text">{{ relInfos[1] }}</span>
          </div>
        </div>
      </div>
    </template>

    <template v-else-if="distCrseCd === 'book'">
      <div v-if="relInfos.length > 0" class="source-category">
        <template v-if="relInfos[0] === '자유도서'">
          <div class="image-box image-book">
            <div class="image">
              <img src="@/assets/lxp/images/ssl/myssl/img_book_kb.jpg" alt="">
            </div>
            <div class="content">
              <p class="text text-muted">-</p>
              <p class="text">자유도서</p>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="image-box image-book">
            <div v-if="relInfos.length > 2" class="image">
              <img :src="relInfos[2]" alt="">
            </div>
            <div class="content">
              <p v-if="relInfos.length > 1" class="text text-muted">{{ relInfos[1] }}</p>
              <p class="text">{{ relInfos[0] }}</p>
            </div>
          </div>
        </template>
      </div>
    </template>

    <template v-else-if="distCrseCd === 'live'">
      <div class="source-category">
        <p v-if="relInfos.length > 2" class="text">{{ relInfos[0] }} | {{ relInfos[2] }}</p>
      </div>
    </template>

    <template v-else-if="relInfos.length > 4">
      <div class="source-category">
        <div class="image-box" :class="{'image-book w-auto': relInfos[0] === 'ssl'}" >
          <div class="image kb-mouse-cursor" title="콘텐츠 보기" @click.stop="$router.push({path:refUrl})">
            <CommonTypeImage :img-src="relInfos[1]" :theme="relInfos[0]" />
          </div>
          <div class="content">
            <p class="text">{{relInfos[2]}}</p>
            <p class="text text-muted">{{relInfos[3]}}</p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>


import {useRoute, useRouter} from 'vue-router';
import {computed} from 'vue';
import {useStore} from 'vuex';
import CommonTypeImage from '@/components/common/CommonTypeImage';

export default {
  name: 'SSLViewRel',
  components: {CommonTypeImage},
  props: {
    hashTags: Array,
    refPost: String,
    refUrl: String,
    modalDistCrseCd: String,
    pageType: String // lxp-Main, ssl
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const isPageMian = computed(() => props.pageType === 'main');
    const distCrseCd = computed(() => isPageMian.value ? props.modalDistCrseCd : route.params.distCrseCd);
    const hasTagPrefix = computed(() => {
      if(store.state.auth.isMobile){
        return '';
      }
      return '#';
    });

    // const currentRel = computed(() => sslRoutes[props.pageType === 'main' ? props.modalDistCrseCd : route.params.distCrseCd  ] ? sslRoutes[props.pageType === 'main' ? props.modalDistCrseCd : route.params.distCrseCd].rel.write : null);
    // group :: 0 => group 명, 1 => meeting 명, 3 => meeting Date
    // knowledge :: 0 => 학습자료 명명
    // hrd :: 0 => post image, 1 => hrd 이름, 3. hrd 분야
    // book :: 0 => book image, 1 => book 명
    const relInfos = computed(() => {
      if(props.refPost){
        return props.refPost.split('^');
      }
      return [];
    });

    // const openLoading = ref(false);

    // const clickOpenRel = () => {
    //   if(isPageMian.value && distCrseCd.value === 'hrd') {
    //     winOpen(props.refUrl);
    //   } else {
    //     openRel(openLoading.value, distCrseCd.value, relInfos.value, props.refUrl, currentRel.value);
    //   }
    // }

    const goToHrd = item => {
      if (item && item.length > 3) {
        router.push({name: 'HrdCloudContentBridge', params: {lrnObjDtlDistSn: item[3], mode: 'detail'}});
      }
    }

    return {
      hasTagPrefix,
      distCrseCd,
      relInfos,
      // clickOpenRel,
      goToHrd
    }
  }
}
</script>
