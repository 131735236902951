<template>
  <div>
    <slot name="first" />
    <div v-if="title" :class="formOutClass">
      <label class="kb-form-label">
        <strong class="kb-form-label-text">{{ title }}</strong>
      </label>
    </div>
    <div :class="formInClass">
      <template v-if="isNumber">
        <input v-if="max > 0"
               ref="inputEl"
               type="text"
               class="kb-form-control"
               :class="classLst"
               :value="modelValue"
               :maxlength="max"
               :placeholder="placeholder"
               :disabled="disabled"
               @input="updateValue"
               @focusin="handleFocus"
               @focusout="handleFocus"
        />
        <input v-else-if="isMoney"
               ref="inputEl"
               type="text"
               class="kb-form-control"
               :class="classLst"
               :value="numberComma(modelValue)"
               @input="updateValue"
               :placeholder="placeholder"
               :disabled="disabled"
               @focusin="handleFocus"
               @focusout="handleFocus"
        />
        <input v-else
               ref="inputEl"
               type="text"
               class="kb-form-control"
               :class="classLst"
               :value="numberComma(modelValue)"
               @input="updateValue"
               :placeholder="placeholder"
               :disabled="disabled"
               @focusin="handleFocus"
               @focusout="handleFocus"
        />
      </template>
      <input v-else
             ref="inputEl"
             type="text"
             class="kb-form-control"
             :class="classLst"
             :value="modelValue"
             @input="updateValue"
             :placeholder="placeholder"
             :disabled="disabled"
             @focusin="handleFocus"
             @focusout="handleFocus"
      />
      <div v-if="isFormSide" class="kb-form-side">
        <span class="kb-form-text">{{ formSideText }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from "vue";
import {emptyNumberOnClick, numberComma, removeComma, numberOnlyChk} from "@/assets/js/util";
import {useAlert} from "@/assets/js/modules/common/alert";

export default {
  name: "MobileRowInputText",
  props: {
    modelValue: [String, Number],
    title: String,
    classLst: String,
    isNumber: {  type: Boolean, default: false },
    isMoney: { type: Boolean, default: false },
    disabled: { type:Boolean,  default: false },
    max: Number,
    placeholder: String,
    isFormSide: {
      type: Boolean,
      default: false
    },
    formSideText: String,
    formOutClass: {
      type: String,
      default: 'kb-form-row'
    },
    formInClass: {
      type: String,
      default: 'kb-form-row'
    }
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const {showMessage} = useAlert();
    const inputEl = ref(null);
    const updateValue = (e) => {
      let val = e.target.value;
      if (props.isNumber) {
        if (props.isMoney) {
          val = removeComma(val);
        }

        if (!numberOnlyChk(val)) {
          showMessage('숫자입력만 가능합니다.');
          e.target.value = '';
          return;
        }
        val = Number(val);
      }
      emit('update:modelValue',val);
    };

    const handleFocus = (e) => {
      if (props.isMoney) {
        emptyNumberOnClick(e);
      }
    };

    return {
      inputEl, updateValue, handleFocus, numberComma
    }
  }
}
</script>
