<template>
  <!-- content-section -->
  <div v-if="quizItems.length === 0">
      <div class="view-contents">
          <article class="content-section section-forms">
              <header class="section-header header-divider">
                  <h3 class="title">퀴즈목록 <span class="count">{{ quizItems.length }}</span></h3>
              </header>
              <div class="contents-empty empty-min">
                  <p class="text">등록된 퀴즈문제지가 없습니다. <br>[문제지 추가]를 클릭하여 퀴즈문제지를 추가하세요.</p>
              </div>
          </article>
<!--          <div class="section-bottom">-->
<!--              <button class="kb-btn-content-item kb-btn-primary" @click="addQuiz">문제지 추가</button>-->
<!--          </div>-->
      </div>
  </div>
  <div  v-else-if="quizItems.length > 0 && !isQuizDetailShow" class="sub-content">
      <div class="view-contents">
          <!-- content-section -->
          <article class="content-section section-forms">
              <header class="section-header header-divider d-flex">
                  <h3 class="title">퀴즈목록 <span class="count">{{ quizItems.length }}</span></h3>
                  <div class="header-side">
                      <button class="kb-btn-text"><span class="text text-primary">전체선택</span></button>
                      <button class="kb-btn-text" @click="deleteQuiz"><span class="text text-red">삭제</span></button>
                  </div>
              </header>
              <!-- text-item-container -->
              <div class="text-item-container" data-content="check">
                  <!-- text-item -->
                  <div v-for="(item, idx) in quizItems" :key="idx" class="text-item">
<!--                      <label :for="`chk_${idx}`" class="kb-form-check-label"></label>-->
                      <div class="item-column">
                          <a href="javascript:" @click="quizDetail(item)">
                              <h4 class="item-title">{{ item.lrnEvlNm }}</h4>
                          </a>
                          <div class="item-meta">
                              <span class="text">문항({{ item.qstnCnt }})</span>
                              <span class="text">{{ item.rgtrNm }} 작성</span>
                              <span class="text">{{ item.regDt }} 등록</span>
                          </div>
                      </div>
                      <div class="item-column column-check">
                          <div class="kb-form-check">
                              <input type="checkbox" class="kb-form-check-input" :name="`chk_${idx}`" v-model="targetQuiz" :value="item.lrnTyEvlMstSn" >
                          </div>
                      </div>
                  </div>
                  <!-- //text-item -->
              </div>
              <!-- //text-item-container -->
          </article>
      </div>
  </div>

</template>

<script>
import {computed, onMounted, ref} from 'vue';
import {useStore} from 'vuex';
import {useAlert} from '@/assets/js/modules/common/alert';
import {ACT_DELETE_QUIZ, ACT_GET_QUIZ_LIST} from '@/store/modules/quiz/quiz';
import {getItems, getPaging, isSuccess, lengthCheck, timestampToDateFormat} from '@/assets/js/util';

export default {
  name: "MobileQuizMngList",
  components: {
    // CommonPagination,
  },
  props:{
    quizDetailFunc: Function
  },
  setup(props){

    const store = useStore();
    const targetQuiz = ref([]);
    const isQuizDetailShow = ref(false);
    const quizItems = ref([]);
    const {showMessage, showConfirm} = useAlert();
    const quizDtlSn = ref(0);
    const paging = ref({pageNo: 1, pageSize: 10});
    const quizItem = ref();
    const distCrseSn = computed(() => store.state.quiz.distCrseSn);

    onMounted(() => {
      getQuizList();
    })

    // 퀴즈목록
    const getQuizList = () => {
      store.dispatch(`quiz/${ACT_GET_QUIZ_LIST}`, {
        evlKindCdDcd: '2048098', //2048098 : ON, 2048099 : NOW
        lrnEvlMstSn: distCrseSn.value, // 차수로 생각하면됨
        // 필수
        pageNo: paging.value.pageNo,
        pageSize: paging.value.pageSize,
      }).then(res => {
        if (lengthCheck(res)) {
          quizItems.value = getItems(res);
          quizItems.value.forEach((x) => {x.regDt = timestampToDateFormat(x.regDt, 'yyyy.MM.dd')})
          paging.value = getPaging(res);
        } else {
          quizItems.value = [];
          paging.value.totalCount = 0;
        }
      }).catch(e => {
        console.error(e);
      });
    };

    const pagingFunc = (pageNo, pageSize) => {
      paging.value.pageNo = pageNo;
      paging.value.pageSize = pageSize;
      getQuizList();
    };

    // 퀴즈삭제
    const deleteQuiz = () => {
      if(targetQuiz.value.length === 0) {
        showMessage('퀴즈 문제지를 선택해주세요.');
        return false;
      }
      // 삭제할 퀴즈 여러개 선택시 순차적으로 삭제
      showConfirm({
        text: '선택한 퀴즈 문제지를 삭제 하시겠습니까?',
        callback: () => {
          targetQuiz.value.forEach((lrnTyEvlMstSn) => {
            doDelete(lrnTyEvlMstSn);
          });
          showMessage('퀴즈 문제지를 삭제했습니다.');
        }
      })

    }

    // 퀴즈삭제 콜백
    const doDelete = () => {
      store.dispatch(`quiz/${ACT_DELETE_QUIZ}`, {delQuizList: targetQuiz.value}).then(res => {
        if(isSuccess(res)){
          getQuizList();
        }
      }).catch(e => {
        console.error(e);
      });
    }

    const quizDetail = (item) => {
      quizDtlSn.value = item.lrnTyEvlMstSn;
      quizItem.value = item;
      props.quizDetailFunc(quizItem.value, quizDtlSn.value);
    }

    return{
      quizItems,
      targetQuiz,
      isQuizDetailShow,
      quizDtlSn,
      paging,
      quizItem,
      getQuizList,
      deleteQuiz,
      quizDetail,
      pagingFunc,
    }
  }
}
</script>

<style scoped>
.dimmed{
  opacity: 0.4;
}
</style>