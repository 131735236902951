<template>
  <div class="popup-container" :class="{'is-active': modelValue}">
    <!-- popup -->
    <div class="popup" id="popup-prep-n03">
      <!-- popup > popup-inner -->
      <div class="popup-inner">
        <div>
          <!-- popup-inner > popup-header -->
          <header class="popup-header">
            <h3 class="title">리더 변경하기</h3>
          </header>
          <!-- popup-inner > popup-content -->
          <div class="popup-content">
            <article class="popup-section">
              <div class="section-content">
                <div class="kb-table kb-table-striped member-tablelist">
                  <table>
                    <colgroup>
                      <col style="width:auto;" />
                      <col style="width:auto;" />
                      <col style="width:auto;" />
                      <col style="width:auto;" />
                      <col style="width:160px;" />
                    </colgroup>
                    <thead>
                    <tr>
                      <th><span class="th-title">부점/부서명</span></th>
                      <th><span class="th-title">직원명</span></th>
                      <th><span class="th-title">직원번호</span></th>
                      <th><span class="th-title">직급</span></th>
                      <th class="text-center">
                        <button class="kb-btn-check">
                          <span class="th-title text-primary">선택</span>
                        </button>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                      <template v-for="(item, idx) in members" :key="idx">
                        <tr v-if="item.grpRoleCdDcd !== '2010001'">
                          <td><strong class="td-text">{{ item.deptNm }}</strong></td>
                          <td><strong class="td-text">{{ item.lrnerNm }}</strong></td>
                          <td><strong class="td-text">{{ item.lrnerId }}</strong></td>
                          <td><strong class="td-text">{{ item.jbgdCd }} {{ item.jbpsNm }}</strong></td>
                          <td class="text-center">
                            <div class="kb-form-check">
                              <input v-model="checkedUser" type="radio" class="kb-form-check-input" name="chkMember" :value="item"/></div>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
              </article>
          </div>
          <!-- popup-inner > popup-buttons -->
          <div class="popup-buttons">
            <button class="kb-btn kb-btn-outline kb-btn-lg" @click="closeModal"><span class="text">취소</span></button>
            <button class="kb-btn kb-btn-primary kb-btn-lg" @click="clickChange"><span class="text">변경</span></button>
          </div>
        </div>
      </div>
      <!-- popup > popup-close -->
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
    <!-- //popup -->
  </div>
</template>

<script>

import {changeLeaderSetup} from "@/assets/js/modules/groupLearing/change-leader-setup";

export default {
  name: "ChangeLeaderModal",
  props: {
    modelValue: Boolean,
    distCrseSn: String,
    members: Array
  },
  emits: ['update:modelValue'],
  setup: changeLeaderSetup
}
</script>