import {computed, onMounted, ref, watch} from "vue";
import {downloadMyPageGuideFiles} from "@/assets/js/modules/mypage/mypage-common";
import {useStore} from "vuex";
import {getListFunc} from "@/assets/js/ui.init";
import {useRoute} from "vue-router";
import {
    ACT_GET_LEARN_GLOBAL_LIST,
    ACT_GET_LEARN_HISTORY_LIST,
    ACT_GET_MLG_HISTORY_LIST
} from "@/store/modules/learn/learn";
import {getItems, lengthCheck, timestampToDateFormat, ymdStrToDateFormat} from "@/assets/js/util";
import {ACT_GET_ALL_BADGE_LIST} from "@/store/modules/badge/badge";
import {ACT_GET_MY_PAGE_BANK_HISTORY_LIST} from "@/store/modules/my/my";
import navigationUtils from "@/assets/js/navigationUtils";
import {ACT_GET_LRN_SPRT_EVL_APRVD_LIST} from "@/store/modules/support/support";
import {ACT_GET_LRN_SPRT_LCSN_LIST} from "@/store/modules/license/license";

export const mypageSetup = () => {
    const view = ref('dashboard');
    // const view = ref('mileage');
    const route = useRoute();
    const isFileAtchListShow = ref(false);

    const effected = ref(0);
    const learns = ref([]);
    const mlgLearns = ref([]);
    const licenses = ref([]);
    const mlgLicenses = ref([]);
    const hrdCloudMlg = ref(0);
    const badges = ref([]);
    const globals = ref([]);
    const guideFile = computed(() => downloadMyPageGuideFiles.find(x => x.page === 'myPage').fileKey);

    const works = ref([]);
    // 직무
    // const certifications = ref([]);
    if(!route.query.view) route.query.view = 'dashboard';

    const changeView = (change) => {view.value = change;}

    const store = useStore();
    const banks = ref([]);
    const evaluations = ref([]);


    getListFunc(`learn/${ACT_GET_LEARN_HISTORY_LIST}`, {}, learns, null);
    getListFunc(`license/${ACT_GET_LRN_SPRT_LCSN_LIST}`, {regYn: 'Y'}, licenses, null,()=>{
        licenses.value.forEach(x => {
            if(x.passAnnonYmd){
                x.passYmd = ymdStrToDateFormat(x.passAnnonYmd, 'yyyy.MM.dd');
            }else if(x.lcsnExamYmd){
                x.passYmd = ymdStrToDateFormat(x.lcsnExamYmd, 'yyyy.MM.dd');
            }else if(x.lcsnRegYmd){
                x.passYmd = ymdStrToDateFormat(x.lcsnRegYmd, 'yyyy.MM.dd');
            }else{
                x.passYmd = x.regDt ? timestampToDateFormat(x.regDt,'yyyy.MM.dd') : '-';
            }
        });
    });
    const getMlgHistoryList = () => {
        store.dispatch(`learn/${ACT_GET_MLG_HISTORY_LIST}`, {}).then(res => {
            mlgLearns.value = res.learnMlgHist;
            mlgLicenses.value = res.lcsnMlgHist;
            hrdCloudMlg.value = res.hrdCloudHistoryMlg;
        }).catch(err => {
            console.error(err)
        })
    }
    getMlgHistoryList();

    getListFunc(`badge/${ACT_GET_ALL_BADGE_LIST}`, {}, badges, null);

    getListFunc(`learn/${ACT_GET_LEARN_GLOBAL_LIST}`, {}, globals, null);

    getListFunc(`support/${ACT_GET_LRN_SPRT_EVL_APRVD_LIST}`,{},evaluations, null);

    const lrnObjHistroyData = ref([]);

    const lrnObjPrint = (data) => {
        lrnObjHistroyData.value = data.value;
    }

    const historyPrintModal = ref(false);
    const lrnHistoryPrint = () => {
        // var prtOption = 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0';
        // var WinPrint = window.open('', '', prtOption);
        //
        // WinPrint.document.write("<html>");
        // WinPrint.document.write("<head>");
        // WinPrint.document.write("<style>");
        // WinPrint.document.write("@media print {");
        // WinPrint.document.write("kb-table {");
        // WinPrint.document.write("  border: 1px solid  #444444;");
        // WinPrint.document.write("  border-collapse: collapse;");
        // WinPrint.document.write("  width: 1000px;");
        // WinPrint.document.write("}");
        // WinPrint.document.write("th {");
        // WinPrint.document.write("  border: 1px solid #444444;");
        // WinPrint.document.write("  padding: 10px;");
        // WinPrint.document.write("  font-size: 12px;");
        // WinPrint.document.write("  text-align:center;");
        // WinPrint.document.write("}");
        // WinPrint.document.write("tr,td {");
        // WinPrint.document.write("  border: 1px solid #444444;");
        // WinPrint.document.write("  padding: 10px;");
        // WinPrint.document.write("  font-size: 12px;");
        // WinPrint.document.write("}");
        // WinPrint.document.write("}");
        // WinPrint.document.write("</style>");
        // WinPrint.document.write("</head>");
        // WinPrint.document.write("<body>");
        // WinPrint.document.write("<table>");
        // WinPrint.document.write("<tr>");
        // WinPrint.document.write("<th colspan=4>학습이력</th>");
        // WinPrint.document.write("</tr>");
        // WinPrint.document.write("<tr>");
        // WinPrint.document.write("<th>과정명</th>");
        // WinPrint.document.write("<th>기간</th>");
        // WinPrint.document.write("<th>점수</th>");
        // WinPrint.document.write("<th>수료여부</th>");
        // WinPrint.document.write("</tr>");
        //
        // lrnObjHistroyData.value.forEach((e => {
        //   WinPrint.document.write("<tr>");
        //   WinPrint.document.write("<td>" + e.crseNm + "</td>");
        //   WinPrint.document.write("<td>" + timestampToDateFormat(e.bgngDt, 'yyyy-MM-dd')+"-"+timestampToDateFormat(e.endDt, 'yyyy-MM-dd') + "</td>");
        //   if (e.getScore ===  null || e.getScore ===  '')
        //     WinPrint.document.write("<td>-</td>");
        //   else
        //     WinPrint.document.write("<td>"+e.getScore+"</td>");
        //   if (e.fnshYn === 'Y')
        //       WinPrint.document.write("<td>수료</td>");
        //     else
        //       WinPrint.document.write("<td>미수료</td>");
        //     WinPrint.document.write("</tr>");
        // }))
        //
        // // for (var i = 1; i < 100; i++) {
        // //   WinPrint.document.write("<tr>");
        // //   WinPrint.document.write("<td>KB HRD 클라우드 학습 마일리지(2021.12月 ~2022.11月) </td>");
        // //   WinPrint.document.write("<td>2022.01.01-2022.11.30</td>");
        // //   WinPrint.document.write("<td>100</td>");
        // //   WinPrint.document.write("<td>수료</td>");
        // //   WinPrint.document.write("</tr>");
        // // }
        // WinPrint.document.write("</table>");
        // WinPrint.document.write("</body>");
        // WinPrint.document.write("</html>");
        // WinPrint.document.close();
        // WinPrint.focus();
        // WinPrint.print();
        // WinPrint.close();
    }

    // 연수이력 - 은실종
    const getAbilityBankList = () => {
        store.dispatch(`my/${ACT_GET_MY_PAGE_BANK_HISTORY_LIST}`, {}).then(res => {
            if(lengthCheck(res)){
                banks.value = getItems(res);
            }else{
                banks.value = [];
            }
        }).catch(err => {
            console.error(err)
        })
    }
    getAbilityBankList();


    // getListFunc(`talent/${ACT_GET_LRN_CRSE_WORK_PHASE_LIST}`, {crseWorkTyCdDcd: crseWorkTyCdDcds.certification}, works, null);

    // getListFunc(`talent/${ACT_GET_LRN_CRSE_WORK_ACHV_LIST}`, {crseWorkTyCdDcd: crseWorkTyCdDcds.certification}, certifications, null);
    onMounted(() => {
        changeView(route.query.view);
    });

    watch(() => route.query, () =>{
        if(route.name === 'MyPageMain') {
            if(!route.query.view) route.query.view = 'dashboard';

            if(route.query.view) {
                changeView(route.query.view);
            }
        }
    });

    return {
        view,
        effected,
        learns,
        licenses,
        mlgLearns,
        mlgLicenses,
        hrdCloudMlg,
        badges,
        evaluations,
        guideFile,
        downloadMyPageGuideFiles,
        works,
        banks,
        globals,
        // certifications,
        isFileAtchListShow,
        changeView,
        lrnHistoryPrint,
        lrnObjPrint,
        lrnObjHistroyData,
        historyPrintModal,
        isMobile: navigationUtils.any()
    }
}