<template>
  <div class="popup-container p-0 d-block" id="kb-mobile-popup" :class="{ 'is-active' : modelValue }">
    <HrdCloudMobileHeader
        title="HRD 클라우드 콘텐츠 상세 학습이력"
        :show-back="true"
        :go-back-func="closeModal"
    />
    <main id="kb-hrdcloud" class="kb-main">
      <div class="main-content main-component">
        <LoadingDiv v-if="isLoading && !isMobile" />
        <template v-else>
          <article class="content-section">
            <header class="section-header"><h4 class="title">콘텐츠 학습 누적 정보</h4></header>
            <div v-if="summary" class="kb-table kb-table-v2 kb-table-detail-01">
              <table>
                <colgroup>
                  <col style="width:177px">
                  <col style="width:96px">
                  <col style="width:96px">
                  <col style="width:96px">
                  <col style="width:96px">
                  <col style="width:96px">
                  <col style="width:96px">
                </colgroup>
                <thead>
                <tr>
                  <th rowspan="2"><span class="th-title">콘텐츠명</span></th>
                  <th colspan="4"><span class="th-title">학습완료여부</span></th>
                  <th rowspan="2"><span class="th-title">마일리지</span></th>
                  <th rowspan="2"><span class="th-title">비고</span></th>
                </tr>
                <tr>
                  <th class="border-s"><span class="th-title">누적학습시간</span></th>
                  <th><span class="th-title">누적진도율</span></th>
                  <th><span class="th-title">콜드콜</span></th>
                  <th><span class="th-title">완료여부</span></th>
                </tr>
                </thead>
                <tbody class="align-center">
                <tr>
                  <td><strong class="td-text">{{ summary.objNm }}</strong></td>
                  <td><span class="td-text">{{ getConvertTime(summary.cttTyCdDcd, summary.accumPlaySec) }}</span></td>
                  <!--                    {{ summary.accumPlaySec >= summary.cttSz ? '(O)' : '(X)' }}-->
                  <td><span class="td-text">{{ getProgressPercent(summary.cttSz, summary.accumPlaySec, summary.cttTyCdDcd) }}%</span></td>
                  <!--                  {{ summary.progPrcnt === 100 ? '(O)' : '(X)' }}-->
                  <td>
                    <span v-if="summary.cttSz > 60" class="td-text">{{ summary.coldCallRspnsDt ? '응답' : '미응답'}} {{ `${summary.coldCallRspnsDt ? 1 : 0} / ${summary.coldCallRound}` }}</span>
                    <span v-else class="td-text">-</span>
                  </td>
                  <!--                  {{ summary.coldCallRspnsDt ? '(O)' : '(X)' }}-->
                  <td>
                    <span v-if="summary.cmptnYn === 'Y'" class="td-text text-primary">완료</span>
                    <span v-else class="td-text text-muted">미완료</span>
                  </td>
                  <td><span class="td-text">{{ summary.mlgScore }}</span></td>
                  <td>
                    <span class="td-text" :style="summary.mlgTyCd === '1001' || summary.mlgTyCd === '1003' || summary.mlgTyCd === '1004' || summary.mlgTyCd === '1005' ? {color:'#ff8000'} : {}">
                      {{ summary.mlgGiveRsn ? summary.mlgGiveRsn : '-' }} {{ getMlgGiveDt(summary) }}
                    </span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div v-else>
              <div class="search-container">
                <div class="result-empty">
                  <img src="../../../assets/lxp/images/common/img_empty.png" alt="">
                  <p class="text">시청 이력이 없습니다.</p>
                </div>
              </div>
            </div>
          </article>

          <article class="content-section section-margin">
            <header class="section-header">
              <h4 class="title">콘텐츠 상세 플레이로그</h4>
            </header>
            <div class="text-item-container">
              <div  v-for="(item, idx) in body" :key="idx" class="text-item">
<!--                <h5 class="item-title">{{ item.eventNm }}</h5>-->
                <div class="item-row">
                  <span class="text-title">날짜시간</span>
                  <span class="text-description">{{ timestampToDateFormat(item.regDt, 'yyyy.MM.dd hh:mm:ss') }}</span>
                </div>
                <div class="item-row">
                  <span class="text-title">로그</span>
                  <span class="text-description">{{ item.eventNm }}</span>
                </div>
                <div class="item-row">
                  <span class="text-title">학습시간</span>
                  <span class="text-description">{{ item.playTime }}</span>
                </div>
                <div class="item-row">
                  <span class="text-title">콜드콜횟수</span>
                  <span class="text-description">{{ item.coldCallRound }}</span>
                </div>
                <div class="item-row">
                  <span class="text-title">응답여부</span>
                  <span class="text-description">{{ item.coldCallRspns }}</span>
                </div>
                <div class="item-row">
                  <span class="text-title">응답시간</span>
                  <span class="text-description">{{ item.coldCallRspnsDt ? timestampToDateFormat(item.coldCallRspnsDt, 'yyyy.MM.dd hh:mm:ss') : '-' }}</span>
                </div>
              </div>
              <div v-if="body.length === 0">
                <div class="search-container">
                  <div class="result-empty">
                    <img src="../../../assets/lxp/images/common/img_empty.png" alt="">
                    <p v-if="!paramCheck(month)" class="text">최근 1개월간 이력이 없습니다.</p>
                    <p v-else class="text">시청 이력이 없습니다.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="section-bottom bottom-divider">
              <p class="text text-muted">최근 1개월간 해당 콘텐츠 시청 상세 이력입니다.</p>
            </div>
          </article>
        </template>
      </div>
    </main>
  </div>
</template>

<script>
import HrdCloudMobileHeader from '@/components/hrdcloud/mobile/HrdCloudMobileHeader';
import LoadingDiv from '@/components/common/LoadingDiv';
import {hrdHistoryDtlSetup} from '@/assets/js/modules/hrd/hrd-history-dtl-setup';

export default {
  name: 'HrdCloudHistoryMobileDtl',
  components: {LoadingDiv, HrdCloudMobileHeader},
  props: {
    modelValue: Boolean,
    lrnObjDtlDistSn: [Number, String],
    year: [Number, String],
    month: [Number, String]
  },
  emits: ['update:modelValue'],
  setup: hrdHistoryDtlSetup
};
</script>