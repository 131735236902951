import ApiService from "@/assets/js/api.service";

// ACTION
export const ACT_SET_WISE_TRUST = "actSetWiseTrust";
export const ACT_GET_WISE_SEARCH_LIST = "actGetWiseSearchList";
export const ACT_GET_WISE_SUB_EXAMPLE_LIST = "actGetWiseSubExampleList";
export const ACT_GET_WISE_INIT_INFO = "actGetWiseInitInfo";

const state = {};

const mutations = {};

const actions = {
    [ACT_SET_WISE_TRUST](context, params) {
        return new Promise((resolve) => {
            ApiService.post("/v1/app/wise/send", params).then((response) =>
                resolve(response)
            );
        });
    },
    [ACT_GET_WISE_SEARCH_LIST](context, params) {
        return new Promise((resolve) => {
            ApiService.query("/v1/app/wise/search", params).then((response) =>
                resolve(response)
            );
        });
    },
    [ACT_GET_WISE_SUB_EXAMPLE_LIST](context, params) {
        return new Promise((resolve) => {
            ApiService.query("/v1/app/wise/subExample", params).then((response) =>
                resolve(response)
            );
        });
    },
    [ACT_GET_WISE_INIT_INFO](context, params) {
        return new Promise((resolve) => {
            ApiService.query("/v1/app/wise/init", params).then((response) =>
                resolve(response)
            );
        });
    },
};


export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
