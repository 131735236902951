import store from '../../../../store';
import {
    ACT_INSERT_CRSE_ACT_LOG,
    ACT_INSERT_CRSE_REG_APLY,
} from '@/store/modules/course/course';
import {aws2ncloudUrl, isSuccess} from '@/assets/js/util';
import {MUT_SHOW_HRD_BADGE} from '@/store/modules/hrdcloud/hrdcloud';
import {getHrdBadgeList} from '@/assets/js/modules/hrd/hrd-badge';
import {checkRefundConsult} from "@/assets/js/modules/consult/consult-common";

export const insertCourseAct = (actTyCdDcd, payload, callback, catchCallback) => {
    store.dispatch(`course/${ACT_INSERT_CRSE_ACT_LOG}`, {actTyCdDcd, ...payload}).then(res => {
        if(isSuccess(res)) if(callback instanceof Function) callback(res);
        if (res.badges && res.badges.length > 0) {
            store.commit(`hrdcloud/${MUT_SHOW_HRD_BADGE}`,res.badges);
            getHrdBadgeList();
        }
    }).catch(e => {
        console.error(e);
        if(callback instanceof Function) catchCallback();
    });
};

// 수강 신청 공통화
export const applyCourse = (distCrseSn, params, successCallback, failCallback, errorCallback) => {
    // 환입비용 처리 여부
    checkRefundConsult(`#lrnerNm#님은 연수과정 미수료에 의한 <br>교육훈련비 #rtnAmt#원(#count#건) 환입 대상자 입니다.<br>환입 완료 전까지 <span style=color:#FF0000!important;>연수신청(자기주도학습지원 포함)이 제한</span>됩니다.<br><br> 연수비용 환입은 HR부(인재개발)<br>최슬비대리(☎7880)에게<br>문의 부탁드립니다.`, () => {

        store.dispatch(`course/${ACT_INSERT_CRSE_REG_APLY}`, {distCrseSn, params}).then(res => {
            if(isSuccess(res)){
                if(successCallback instanceof Function) successCallback(res);
            }else{
                if(failCallback instanceof Function) failCallback(res);
            }
        }).catch(e => {
            console.error(e);
            if(errorCallback instanceof Function) errorCallback();
        });

    });
}

const s3Url = process.env.VUE_APP_S3_URL;
const cloudfrontUrl = process.env.VUE_APP_CLOUDFRONT_URL;

export const getThumbUrl = (src) => {
    let thumbUrl = null;
    const session = store.state.auth.session;
    if(src){
        if(src.includes(s3Url)){
            thumbUrl = src.replace(s3Url, cloudfrontUrl);
        }else if(src.indexOf('contents/') === 0){
            thumbUrl = `${cloudfrontUrl}/${src}`;
        }else if(src.indexOf('/contents/') === 0){
            thumbUrl = `${cloudfrontUrl}${src}`;
        }
        if(session.itn){
            // console.log('session.itn):',session.itn, thumbUrl);
            return thumbUrl;
        } else {
            // console.log('!session.itn):',!session.itn, aws2ncloudUrl(thumbUrl));
            return aws2ncloudUrl(thumbUrl);
        }
    }
    return src;
};


export const crseMndCertCd = ['3311055','3311080','3313053','3318119','3310005','3310006','3310007','3310008','3310009','3310010','3310011','3310012','3310013','3310014','3310015','3311145','3311146','3313147','3311150','3312139','3313121','3312133','3313137','3312140','3314101'];
export const pnslCd = ['2016002','2016004','2016005'];