<template>
  <div class="map-container">
    <ul class="map-list" data-depth="1">
      <HrdCloudMapTreeNode
          v-for="(item, index) in items"
          :key="index"
          :current-idx="index"
          :item="item"
          @toggleNode="toggleNode"
          @searchCate="searchCate"
      />
    </ul>
  </div>
</template>

<script>

// level 1 때 사용
import HrdCloudMapTreeNode from '@/components/hrdcloud/HrdCloudMapTreeNode';

export default {
  name: 'HrdCloudMapTree',
  components: {HrdCloudMapTreeNode},
  props: {
    items: Array,
  },
  emits: ['toggleNode', 'searchCate'],
  setup(props, {emit}) {

    const toggleNode = (arr) => {
      emit('toggleNode', arr);
    };

    const searchCate = (arr) => {
      emit('searchCate', arr);
    };
    return {
      toggleNode,
      searchCate,
    };
  },
};
</script>
