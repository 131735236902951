import ApiService from '@/assets/js/api.service';

// ACTION
export const ACT_GET_SSO_KGEDU_URL = 'getSsoKgeduUrl';

export const ACT_GET_SSO_FCAMPUS_URL = 'getSsoFcampusUrl';
export const ACT_GET_SSO_CP_URL = 'getSsoCpEduUrl';
export const ACT_GET_SSO_PROGRAMMERS_URL = 'getSsoProgrammersUrl';


const state = {
};

const mutations = {
};

const actions = {

    [ACT_GET_SSO_KGEDU_URL](context, params) {
        return new Promise(resolve => {
            ApiService.get('/v1/app/mypage/sso-kgedu-url?lrnerId='+params).then(response => resolve(response))
        });
    },
    [ACT_GET_SSO_FCAMPUS_URL](context, params) {
        return new Promise(resolve => {
            ApiService.get('/v1/app/mypage/sso-fcampus-url?lrnerId='+params).then(response => resolve(response))
        });
    },
    [ACT_GET_SSO_CP_URL](context, params) {
        return new Promise(resolve => {
            ApiService.get('/v1/app/mypage/ssocp/'+params).then(response => resolve(response))
        });
    },
    [ACT_GET_SSO_PROGRAMMERS_URL](context, params) {
        return new Promise(resolve => {
            ApiService.get('/v1/app/mypage/sso-programmers-url/'+params).then(response => resolve(response))
        });
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};