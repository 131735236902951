<template>
  <HrdCloudMobileHeader v-if="!isFullscreen" :show-back="true">
    <template v-slot:right>
      <HrdCloudAtchFile v-if="hrd" :is-mobile="isMobile" :hrd="hrd"/>
    </template>
  </HrdCloudMobileHeader>
  <main class="kb-main" id="kb-hrdcloud">
    <div class="main-content main-component">
      <HrdCloudMobileMode />
      <div class="hrdcloud-view-container">
        <div class="player hrdcloud-player">
          <div class="player-container">
            <div class="player-inner">
              <template v-if="hrd && uuid">
                <LXPYoutubePlayer
                    v-if="hrd.lrnObjTyCd === lrnObjTyCds.Youtube"
                    v-model="videoInstance"
                    v-model:uuid="uuid"
                    v-model:is-series-active="isSeriesActive"
                    :coldcall="coldcall"
                    :crse="hrd"
                    :progress="user"
                    :enable-progress="true"
                    :popups="popups"
                    @toggleFull="toggleFull"
                />
              </template>
            </div>
            <HrdCloudMobileSeriesList v-if="isSeriesActive && hrd && hrd.cttSnLst != null && hrd.cttSnLst.length > 0" :crse="hrd" />
            <HrdMobilePlayerDetails v-if="hrd" :player="videoInstance" :hrd="hrd" :funcs="detailFuncs" />
          </div>
        </div>
        <HrdCloudMobileComment v-if="hrd" :effect-nav="effectNav" :objSn="hrd.objSn" />
      </div>
    </div>
  </main>
  <div v-if="hrd" class="popup-container p-0 d-block" id="kb-mobile-popup" :class="{ 'is-active' : showModal }">
    <HrdCloudMobileHistory v-model="historyModal" :title="hrd.objNm" :duration="hrd.cttSz" :items="histories" :lrnObjDtlDistSn="lrnObjDtlDistSn" :ctt-ty-cd-dcd="hrd.cttTyCdDcd" />
    <HrdCloudMobileShare v-if="shareModal" v-model="shareModal" :data="hrd" />
    <HrdCloudMobileOpinion v-model="opinionModal" :cttMstSn="hrd.objSn" :title="hrd.objNm"/>
  </div>
</template>
<script>
import HrdCloudMobileHeader from '@/components/hrdcloud/mobile/HrdCloudMobileHeader';
import HrdCloudAtchFile from '@/components/hrdcloud/HrdCloudAtchFile';
import HrdMobilePlayerDetails from '@/components/hrdcloud/mobile/HrdMobilePlayerDetails';
import HrdCloudMobileComment from '@/components/hrdcloud/mobile/HrdCloudMobileComment';
import HrdCloudMobileHistory from '@/components/hrdcloud/mobile/HrdCloudMobileHistory';
import {useStore} from 'vuex';
import {useRoute} from 'vue-router';
import {computed, onBeforeUnmount, onMounted, reactive, ref, watch} from 'vue';
// import {ACT_GET_HRDCLOUD_DETAIL} from '@/store/modules/prep/prep';
import {
  closeAdded,
  closeSubscribed,
  darkMode,
  detailCategoryDisplay,
  disableMyAddChanged,
  generateUUID,
  handleActions,
  lightMode,
  pageView,
  showMain,
} from '@/assets/js/modules/hrd/hrd-common';
import {getFunc} from '@/assets/js/ui.init';
import {ACT_GET_LEARN_PROGRESS} from '@/store/modules/learn/learn';
import {goBack} from '@/assets/js/modules/common/common';
import {initProgressBar, timestampToDateFormat} from '@/assets/js/util';
import HrdCloudMobileShare from '@/components/hrdcloud/mobile/HrdCloudMobileShare';
import HrdCloudMobileOpinion from '@/components/hrdcloud/mobile/HrdCloudMobileOpinion';
import {lrnObjTyCds} from '@/assets/js/modules/learn/learn-common';
import HrdCloudMobileMode from '@/components/hrdcloud/mobile/HrdCloudMobileMode.vue';
import HrdCloudMobileSeriesList from "@/components/hrdcloud/mobile/HrdCloudMobileSeriesList.vue";
import LXPYoutubePlayer from "@/components/hrdcloud/LXPYoutubePlayer";

export default {
  name: 'HrdMobileYoutube',
  components: {
    HrdCloudMobileSeriesList,
    LXPYoutubePlayer,
    HrdCloudMobileMode,
    HrdCloudMobileOpinion,
    HrdCloudMobileShare,
    HrdCloudMobileHistory,
    HrdCloudMobileComment, HrdMobilePlayerDetails, HrdCloudAtchFile, HrdCloudMobileHeader
  },
  props: {
    objDetail: Object,
    effectNav: {
      required: false,
      type: Number,
      default: 0
    },
  },
  setup(props) {
    const store = useStore(), route = useRoute();
    const isMobile = computed(() => store.state.auth.isMobile);
    const session = computed(() => store.state.auth.session);
    const hrd = computed(() => props.objDetail);
    const lrnObjDtlDistSn = computed(() => route.params.lrnObjDtlDistSn);
    const videoInstance = ref(null);
    const category = ref({});
    const isMenuActive = ref(false);
    const coldcall = ref(false);
    const user = reactive({progRsltSn: 0, maxPlaySec: 0, lastPlaySec: 0, cmptnYn: 'N', mdfcnDt: null, regDt: null});
    const showModal = ref(false), historyModal = ref(false), shareModal = ref(false), opinionModal = ref(false);
    const histories = ref([]);
    const mainShow = computed(() => store.state.hrdcloud.main.show);
    const isFullscreen = ref(false);
    const toggleFull = change => { isFullscreen.value = change; }
    const uuid = ref(null);
    const isSeriesActive = ref(false);

    // PC랑 맞추기 위해 같은 객체 사용
    const popups = reactive({
      miss: {
        show: false,
        toggle: (bool) => {
          popups.miss.show = bool;
        },
        response: {
          effect: 0,
          type: null
        },
        respond: (type) => {
          popups.miss.response.effect++;
          popups.miss.response.type = type;
          popups.miss.show = false;
        },
        initRespond : () => {
          popups.miss.response.type = null;
        }
      },
      resume: {
        show: false,
        toggle: (bool) => {
          popups.resume.show = bool;
        },
        response: {
          effect: 0,
          type: null
        },
        respond: (type) => {
          popups.resume.response.effect++;
          popups.resume.response.type = type;
          popups.resume.show = false;
        },
        initRespond : () => {
          popups.resume.response.type = null;
        }
      },
      complete: {
        show: false,
        feedback: false,
        toggle: (bool) => {
          popups.complete.show = bool;
          if(!bool) popups.complete.feedback = bool;
        }
      },
      getUUID: async () => {
        const id = await generateUUID();
        if (id && id.uuid) uuid.value = id.uuid;
      }
    });

    const objectParam = computed(() => {
      return {
        distCrseSn: hrd.value.distCrseSn,
        distChapSn: hrd.value.distChapSn,
        lrnObjDtlDistSn: hrd.value.lrnObjDtlDistSn,
        objSn: hrd.value.objSn,
        // division: 'vod'
      }
    });
    // 콘텐츠 정보
    const getHrdCloudDetail =async () => {

      pageView(hrd);
      await popups.getUUID();
      getUserProgress();
      //
      // store.dispatch(`prep/${ACT_GET_HRDCLOUD_DETAIL}`, {lrnObjDtlDistSn: lrnObjDtlDistSn.value}).then(async (res) => {
      //   if (res && res.items.length > 0) {
      //     hrd.value = res.items.map(x => {
      //       return {
      //         ...getTop1CategoryByLevel(x.cloudKeyLst),
      //         ...x,
      //         tag: makeTags(x.tag)
      //       };
      //     })[0];
      //     pageView(hrd);
      //   }
      //   await popups.getUUID();
      //   getUserProgress();
      // }).catch(e => {
      //   console.error(e);
      // });
    };
    // 사용자 진도 로그
    const getUserProgress = () => {
      getFunc(`learn/${ACT_GET_LEARN_PROGRESS}`, {
        distCrseSn: hrd.value.distCrseSn,
        lrnObjDtlDistSn: hrd.value.lrnObjDtlDistSn
      }, user);
    };

    const togglePlayerMenu = () => {
      isMenuActive.value = !isMenuActive.value;
    };

    const detailFuncs = {
      openHistory: (item) => {
        handleActions(item, videoInstance.value).actHistory(historyModal, histories,
            () => {
              showModal.value = true;
            });
      },
      openShare: (item) => {
        handleActions(item, videoInstance.value).actShare(shareModal);
      },
      openOpinion: (item) => {
        handleActions(item, videoInstance.value).actOpinion(opinionModal);
      }
    };

    getHrdCloudDetail();

    watch(() => lrnObjDtlDistSn.value, () => {
      initProgressBar();
      if (lrnObjDtlDistSn.value > 0) {
        getHrdCloudDetail();
      }
    });

    watch(() => historyModal.value, () => {
      showModal.value = !showModal.value;
    });
    watch(() => shareModal.value, () => {
      showModal.value = !showModal.value;
    });
    watch(() => opinionModal.value, () => {
      showModal.value = !showModal.value;
    });

    const leaderAdd = computed(() => store.state.hrdcloud.leaderAdd);

    // 담기 후 업데이트를 위해 한번 더 호출
    watch(() => leaderAdd.value.show, () => {
      if (hrd.value && !leaderAdd.value.show && leaderAdd.value.updateMyAdd) {
        const lrnObjDtlDistSn = leaderAdd.value.lrnObjDtlDistSn;
        if (hrd.value.lrnObjDtlDistSn === lrnObjDtlDistSn) {
          hrd.value.put = leaderAdd.value.isPut;
          disableMyAddChanged();
        }
      }
    });

    onMounted(() => {
      darkMode();
    });

    onBeforeUnmount(() => {
      lightMode();
      showMain();
      closeAdded();
      closeSubscribed();
      coldcall.value = false;
    });

    return {
      isMobile, session, uuid, hrd, category, coldcall, user, histories,
      videoInstance, lrnObjDtlDistSn,
      goBack,
      isMenuActive,
      togglePlayerMenu,
      timestampToDateFormat,
      handleActions, detailFuncs, detailCategoryDisplay,
      showModal, historyModal, shareModal, opinionModal, objectParam,
      lrnObjTyCds,
      mainShow,
      popups,
      isFullscreen, toggleFull,
      isSeriesActive,
    };
  }
};
</script>