<template>
  <transition name="custom-classes"
              enter-active-class="animate__animated animate__fadeIn" appear>
  <div class="layer-container" :class="{'is-active' : isPopupActive}">
    <div class="layer" id="layer-main">
      <swiper
          class="banner-container banner-wrapper"
          :modules="modules"
          effect="fade"
          :pagination="{
            el: '.banner-pagination',
            clickable: true
          }"
      >
        <swiper-slide v-for="(item, idx) in items" :key="idx" class="banner-item">
          <a href="javascript:" class="banner-link"  @click="moveLink(item.banerUrl)">
            <!--            <div class="banner-content">-->
            <!--              <span class="text">{{item.banerTitle}}</span>-->
            <!--            </div>-->
            <div v-html="item.banerCn"></div>
          </a>
        </swiper-slide>
        <div class="banner-pagination swiper-pagination"></div>
        <button class="banner-close" @click="clickTodayClose"><span class="text">오늘 하루 보지 않기</span></button>
        <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
      </swiper>
      <button class="layer-close-btn" @click="clickClose"><i class="icon-close"></i></button>
    </div>
  </div>
  </transition>
</template>

<script>
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import {Navigation} from 'swiper';
import {computed, ref} from "vue";
import {winOpen} from "@/assets/js/util";
import {useRouter} from 'vue-router';
import {getBanerExcUrl} from "@/assets/js/modules/baner/common-baner";
import {useAlert} from "@/assets/js/modules/common/alert";
import store from "@/store";
import {goLink} from "@/assets/js/modules/common/common";
export default {
  name: "BanerPopupModal",
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    items: Array
  },
  setup() {
    const isPopupActive = ref(true);
    const extUrl = ref([]);
    const session = computed(() => store.state.auth.session);
    const sessionItn = store.state.auth.session.itn;
    const clickClose = () => {
      isPopupActive.value = false;
    }
    const router = useRouter();

    let date = new Date();
    const {showMessage} = useAlert();
    const clickTodayClose = () => {
      // date = date.setHours(23,59,59);
      date = date.setHours(23,59,59);
      // 만료 시간 구하기(exp를 ms단위로 변경)
      let pathname = window.location.pathname;
      if (pathname.includes('hrdcloud')) {
        localStorage.setItem('kb-popup-hrdcloud', date);
      }
          // else if (pathname.includes('sslMain')) {
          //   localStorage.setItem('kb-popup-ssl', date);
      // }
      else {
        localStorage.setItem('kb-popup-main', date);
      }
      clickClose();
    }
    const getBanerUrl = (execInptId) => {
      getBanerExcUrl({inptId: execInptId, col:session.value.lrnerId },extUrl,() => {
        if (extUrl.value.length > 0){ // URL존재(모바일)
          if(sessionItn){
            winOpen(extUrl.value[0].url2,'width=300, height=300, title="QR",popup=yes' );
          }else {
            winOpen(extUrl.value[0].url); // 외부망 리다이렉트
          }
        }else{
          showMessage('설문대상이 아닙니다.');
        }

      });
    };

    const moveLink = (banerUrl) => {

      if(!banerUrl) {return false;}

      let host = window.location.host;
      //기본 url과 비교하여 타 domain인 경우 winopen , 동일 domain인 경우 route 처리

      console.log("moveLink popup", banerUrl, host);
      if(banerUrl.indexOf("/help/notice/")>0){
        let notiNum = banerUrl.split("/").pop();
        router.push({name: 'HelpNoticeView', params: {ntcSn: notiNum}});
        return;
      }else if(banerUrl.indexOf(host)<0){
        if (banerUrl.indexOf('survey')>-1){
          // 피플인사이트 서베이 처리(PC)
          // 외부망인경우 엑셀 조회해서 본인 URL 리다이렉트
          // 내부망인 경우 QR URL 띄우기
          if (banerUrl.split('|')[1]) { //ID유무 판별
             getBanerUrl(banerUrl.split('|')[1]);
          }
          return;
        }else {
          console.log("winopen", banerUrl);
          goLink(banerUrl)
          //winOpen(banerUrl);
          return;
        }
      } else{
        let finLink = banerUrl.replace(window.location.host, "");
        finLink = finLink.replace(window.location.protocol, "");
        //router.push(finLink);
        goLink(finLink);
      }
    }

    return {
      isPopupActive,
      clickClose,
      clickTodayClose,
      moveLink,
      modules: [Navigation]
    }
  }
}
</script>