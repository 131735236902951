<template>
  <div class="popup-container is-active">
    <div class="popup popup-attendance kb-scroll-attendance" id="popup-attendance" >
      <button v-if="!isMobile" class="kb-btn-popup-close" @click="closeCalendar">
        <i class="icon-close"></i>
      </button>
      <div class="calendar">
        <div class="calendar-top">
          <h2 class="title">{{ currentMonth }}월 출석</h2>
          <div class="actions">
            <button class="kb-btn-month kb-btn-month-prev" type="button" @click="changeMonth(false)">
              <i class="icon-prev"></i>
              <span class="text">{{ prevYearMonth }}</span>
            </button>
            <button class="kb-btn-month kb-btn-month-next" type="button" @click="changeMonth(true)">
              <span class="text">{{ nextYearMonth }}</span>
              <i class="icon-next"></i>
            </button>
          </div>
        </div>
        <div class="calendar-body">
          <table class="calendar-table">
            <thead>
            <tr>
              <th scope="col"><span class="day-header">일</span></th>
              <th scope="col"><span class="day-header">월</span></th>
              <th scope="col"><span class="day-header">화</span></th>
              <th scope="col"><span class="day-header">수</span></th>
              <th scope="col"><span class="day-header">목</span></th>
              <th scope="col"><span class="day-header">금</span></th>
              <th scope="col"><span class="day-header">토</span></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(week, idx) in currentWeeks" :key="idx">
              <td v-for="(day, i) in week" :key="`${idx}-${i}`">
                <div v-if="day.month === currentMonth"  class="day day-check">
                  <template v-if="isHolyDay(day)">
                    <template v-if="!isActive(day)">
                      <span class="att-text calendar-day-text calendar-day-large-text" :class="{ 'text-red' : isWeekend(day) }">{{ day.day }}</span>
                      <span class="att-text">{{ getHolyDay(day) }}</span>
                    </template>
                    <template v-else>
                      <img  class="img-att" src="@/assets/images/ssl/ic_att_active.png" alt="" />
                    </template>
                  </template>
                  <template v-else-if="(i === 0 || i === 6)&& day.isCouponDay < 0">
                    <span class="att-text calendar-day-text calendar-day-large-text" :class="{ 'text-red' : isWeekend(day) }">{{ day.day }}</span>
                  </template>
                  <template v-else-if="day.isCouponDay > 0">
                    <img v-if="day.isCouponDay === 1" class="img-att" src="@/assets/images/ssl/ic_coupon_deactive.svg" alt="" />
                    <img v-else-if="day.isCouponDay === 2" class="img-att" src="@/assets/images/ssl/ic_coupon_active.svg" alt="" />
                  </template>
                  <template v-else>
                    <img v-if="isActive(day)" class="img-att" src="@/assets/images/ssl/ic_att_active.png" alt="" />
                    <span v-else class="att-text calendar-day-text calendar-day-large-text" :class="{ 'text-red' : isWeekend(day) }">{{ day.day }}</span>
                    <span v-if="day.year === today.year && day.month === today.month && day.day === today.day" class="att-text">오늘</span>
                  </template>
                </div>
                <div v-else class="day blank"></div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="calendar-bottom">
          <p class="bottom-text mb-1">2주 단위 매영업일 출석체크 달성 시 커피쿠폰이 제공됩니다.</p>
          <p class="bottom-text mt-1 mb-3">학습노트 조회 + 좋아요 1건 완료시 출석이 인정됩니다.</p>
          <button v-if="isMobile" class="kb-btn-attendance-close" @click="closeCalendar" style="flex: 0 0 100% !important; margin-left: 0 !important;">
            <span class="text">닫기</span>
          </button>
          <button class="kb-btn-attendance mt-3" @click="closeCalendarAndExpire">
            <span class="text">오늘 하루 보지 않기</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>


import {dateToDatePart, getCalendar, isSuccess, timestampToDatePart} from '@/assets/js/util';
import {computed, reactive, ref} from 'vue';
import {
  ACT_INSERT_SOC_ACT,
  MUT_CLOSE_ATTENDANCE,
  MUT_SET_MY_ATTENDANCE,
  MUT_SHOW_BADGE,
} from '@/store/modules/ssl/ssl-index';
import {calendarKey, checkDayOn, checkTodayNotAttend} from '@/assets/js/modules/ssl/ssl-common';
import {useStore} from 'vuex';
import {holyDay} from '@/assets/js/holy-day';
import {getBadgeList} from '@/assets/js/modules/ssl/ssl-badge';
import {couponDays} from '@/assets/js/coupon-day';
import {actTyCdDcds} from '@/assets/js/modules/common/common';
import {useAlert} from '@/assets/js/modules/common/alert';
import {saveToken} from '@/assets/js/localstorage.service';

export default {
  name: 'SSLCalendar',
  setup() {
    const store = useStore();
    const {showMessage} = useAlert();
    const isMobile = computed(() => store.state.auth.isMobile);
    const session = computed(() => store.state.auth.session);

    const currentDate = new Date();
    const currentDatePart = dateToDatePart(currentDate);
    const currentYear = ref(currentDate.getFullYear());
    const currentMonth = ref(currentDate.getMonth() + 1);
    const myAttends = computed(() => store.state.ssl.calendar.myAttends);
    const isDayOn = computed(() => checkDayOn(currentDatePart));
    const isNotTodayAttend = computed(() => checkTodayNotAttend(currentDatePart, myAttends.value))

    const today = reactive({
      year: currentDate.getFullYear(),
      month: (currentDate.getMonth() + 1),
      day: currentDate.getDate(),
    });
    const todayYmd = `${today.year}${('0' + today.month).slice(-2)}${('0' + today.day).slice(-2)}`;

    const attendTimeout = ref(null);
    const isLoading = ref(false);

    const currentWeeks = computed(() => getCalendar(currentYear.value, currentMonth.value).map(week => week.map((day, idx) => ({...day, isCouponDay: isCouponDay(day.ymd, idx)}))));
    const nextYearMonth = computed(() => {
      if(currentMonth.value === 12){
        return `${currentYear.value + 1}년 1월`
      }else{
        return `${currentYear.value}년 ${currentMonth.value + 1}월`
      }
    });
    const prevYearMonth = computed(() => {
      if(currentMonth.value === 1){
        return `${currentYear.value - 1}년 12월`
      }else{
        return `${currentYear.value}년 ${currentMonth.value - 1}월`
      }
    });

    const isHolyDay = ({year, month, day}) => {
      return !!holyDay[`${year}${('0' + month).slice(-2)}${('0' + day).slice(-2)}`];
    }

    const getHolyDay = ({year, month, day}) => {
      return holyDay[`${year}${('0' + month).slice(-2)}${('0' + day).slice(-2)}`];
    }

    const isWeekend = (date) => {
      const week = ['일', '월', '화', '수', '목', '금', '토'];

      const dayOfWeek = week[new Date(date.ymdformat).getDay()];

      if(dayOfWeek === '토' || dayOfWeek === '일') return true;

      return holyDay[date.ymd];
      // return false;
    }

    const changeMonth = (isNext) => {
      if(isNext){
        if(currentMonth.value === 12){
          currentYear.value++;
          currentMonth.value = 1;
        }else{
          currentMonth.value++;
        }
      }else{
        if(currentMonth.value === 1){
          currentYear.value--;
          currentMonth.value = 12;
        }else{
          currentMonth.value--;
        }
      }
    }

    const attendSSL = () => {
      if(isLoading.value) return;
      isLoading.value = true;

      const date = new Date();
      const currentDatePart = dateToDatePart(date);
      if(currentDatePart.dow === '일' || currentDatePart.dow === '토' || holyDay[`${currentDatePart.year}${currentDatePart.mm}${currentDatePart.dd}`]) {
        showMessage('주말 또는 공휴일의 경우<br>출석을 할 수 없습니다.');
        // store.commit(`common/${MUT_SHOW_MESSAGE}`, '주말 또는 공휴일의 경우<br>출석을 할 수 없습니다.');
        store.commit(`ssl/${MUT_CLOSE_ATTENDANCE}`);
        return;
      }

      store.dispatch(`ssl/${ACT_INSERT_SOC_ACT}`, {
        socLrnPostSn: 0,
        actTyCdDcd: actTyCdDcds.ACT_TY_CD_ATTENDANCE,
      })
      .then(res => {
        if(isSuccess(res)){
          store.commit(`ssl/${MUT_SET_MY_ATTENDANCE}`, {actTyCdDcd: actTyCdDcds.ACT_TY_CD_ATTENDANCE, lrnerId: session.value.lrnerId, datePart: timestampToDatePart(new Date().getTime())});
          store.commit(`ssl/${MUT_CLOSE_ATTENDANCE}`);

          showMessage({
            text: '출석하셨습니다!',
            callback: () => {
              if(res.badges && res.badges.length > 0) {
                getBadgeList();
                store.commit(`ssl/${MUT_SHOW_BADGE}`, res.badges);
              }
            }
          })
        }else{
          showMessage('오류가 발생했습니다. 다시 시도해주세요.');
          isLoading.value = false;
        }
      })
      .catch(() => {
        showMessage('오류가 발생했습니다. 다시 시도해주세요.');
        isLoading.value = false;
      });
    }

    const debounceAttend = () => {
      if(attendTimeout.value){
        clearTimeout(attendTimeout.value);
        attendTimeout.value = null;
      }
      attendTimeout.value = setTimeout(() => {
        attendSSL();
      }, 500);
    }

    const {showConfirm} = useAlert();

    const confirmAttend = () => {
      showConfirm({
        text : `출석하시겠습니까?`,
        callback: debounceAttend,
      })
    }

    const isCouponDay = (ymd, idx) => {
      if(idx === 5 || idx === 4){
        // 이미 획득...
        const filtered = couponDays.filter(x => (x.indexOf(ymd) > -1));
        if(filtered.length > 0){
          const lastDate = filtered[0][(filtered[0].length - 1)];
          if(ymd === lastDate){
            // 1. 이미 지남...
            if(lastDate < todayYmd){
              if((myAttends.value.filter(x => (x.datePart.ymd >= filtered[0][0] && x.datePart.ymd <= lastDate) && filtered[0].includes(x.datePart.ymd)).length) >= filtered[0].length){
                return 2;
              }
            }
            // 2. 아직 안지남
            else if(lastDate > todayYmd){
              const index = filtered[0].indexOf(todayYmd);
              if(myAttends.value.filter(x => (x.datePart.ymd >= filtered[0][0] && x.datePart.ymd <= lastDate)).length >= index){
                return 1;
              }
            }
            // 3. 당일
            else if(lastDate === todayYmd){
              const todayAttend = myAttends.value.filter(x => x.datePart.ymd === todayYmd);
              const attendCount = myAttends.value.filter(x => (x.datePart.ymd >= filtered[0][0] && x.datePart.ymd <= lastDate)&& filtered[0].includes(x.datePart.ymd)).length;
              // 오늘 출석한 정보가 있을 경우
              if(todayAttend.length > 0){
                if(attendCount >= filtered[0].length){
                  return 2
                }
              }else{
                if(attendCount < (filtered[0].length )){
                  return 1;
                }
              }
            }
          }
        }
      }
      return 0;
    }

    const isActive = ({year, month, day}) => {
      return (myAttends.value.filter(x => x.datePart.year === year && x.datePart.month === month && x.datePart.day === day).length > 0);
    }

    const closeCalendar = () => {
      store.commit(`ssl/${MUT_CLOSE_ATTENDANCE}`);
    }

    const closeCalendarAndExpire = () => {
      saveToken(calendarKey, todayYmd);
      closeCalendar();
    }


    return {
      isMobile,
      isDayOn,
      isNotTodayAttend,

      currentYear,
      currentMonth,
      today,
      currentWeeks,
      nextYearMonth,
      prevYearMonth,
      isHolyDay,
      getHolyDay,
      changeMonth,
      confirmAttend,
      isActive,
      closeCalendar,
      closeCalendarAndExpire,
      isCouponDay,
      isWeekend
    }

  }
}
</script>
