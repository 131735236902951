<template>
  <router-view/>
</template>

<style>
@import "./assets/custom/developer.css";
@import "./assets/custom/temp.custom.css";
@import "./assets/custom/ckeditor.custom.css";
@import "./assets/custom/ssl.custom.css";
@import "./assets/lxp/css/_animate.css";
</style>

<script>
import navigationUtils from '@/assets/js/navigationUtils';

if(navigationUtils.any()){
  import("./assets/lxp/mobile/css/lxp.mobile.css");
}else{
  import("./assets/lxp/css/lxp.css");
}

export default {
  name: 'App',
}
</script>
