import store from '../../../../store';
import router from '../../../../router';
import {ssoKB} from '@/assets/js/modules/ssl/ssl-common';
import {MUT_HIDE_FOOTER, MUT_SHOW_FOOTER,} from '@/store/modules/common/common-index';
import {ACT_GET_PROFILE_IMG_URL, MUT_SET_PROFILE_IMG_URL,} from '@/store/modules/my/my';
import {MUT_HIDE_HEADER, MUT_SHOW_HEADER} from '@/store/modules/auth/auth';
import {stringCheck} from '@/assets/js/util';

export const actTyCdDcds = {
  ACT_TY_CD_LIKE: '2001001',
  ACT_TY_CD_CANCEL_LIKE: '2001002',
  ACT_TY_CD_SHARE: '2001003',
  ACT_TY_CD_CANCEL_SHARE: '2001004',
  ACT_TY_CD_ADD: '2001005',
  ACT_TY_CD_CANCEL_ADD: '2001006',
  ACT_TY_CD_SUBSCRIBE: '2001007',
  ACT_TY_CD_CANCEL_SUBSCRIBE: '2001008',
  ACT_TY_CD_READ: '2001009',
  ACT_TY_CD_WRITE: '2001010',
  ACT_TY_CD_REMOVE: '2001011',
  ACT_TY_CD_LEARN_HOME: '2001012',
  ACT_TY_CD_NOTE_HOME: '2001013',
  ACT_TY_CD_ATTENDANCE: '2001014',
  ACT_TY_CD_LEARN_END: '2001015',
  ACT_TY_CD_EXCELLENT: '2001016',
  ACT_TY_CD_HRDCLOUD: '2001017',
  ACT_TY_CD_INQUIRY: '2001018',
  ACT_TY_CD_DOWNLOAD: '2001037'
};

export const crseAplyDcds = {
  REG_CRSE_APLY_STT_APPLY: '2049001',
  REG_CRSE_APLY_STT_CANCEL: '2049002',
  REG_CRSE_APLY_STT_CHANGE: '2049003',
  REG_CRSE_APLY_STT_NOSW: '2049004',
  REG_CRSE_APLY_STT_NOEV: '2049005',

  TAKE_STT_COMPLETE: '2050001',
  TAKE_STT_REJECT: '2050002',
  TAKE_STT_PENDING: '2050003',
};

export const ntcCateCdDcds = {
  NTC_CATE_CD_ALL: '2022001',
  NTC_CATE_CD_LMS: '2022002',
  NTC_CATE_CD_PREP: '2022003',
  NTC_CATE_CD_LIFE: '2022004',
  NTC_CATE_CD_LOC: '2022005',
};

export const divCdDcds = {
  DIV_CD_NORMAL: '2013001',
  DIV_CD_EVENT: '2013002',
}

export const expsrLocCds = {
  HRDCLOUD: '100001',
};

export const webHiddenMenu = ['MY','알림','라이브편성표','관리자메뉴'];

export const setProfileImgUrl = () => {
  store.dispatch(`my/${ACT_GET_PROFILE_IMG_URL}`, '2161001').then(res => {
    if(res.status && res.status.src ) {
      store.commit(`my/${MUT_SET_PROFILE_IMG_URL}`, res.status.src)
    }
 });
}

export const setGDefaultImg = (e) => {
  e.target.src = require('../../../../assets/images/profile/sample.png');
}

// export const getProfileImg = (lrnerId) => {
//   if(!lrnerId) return;
//   if(lrnerId === 'S017330') return 'https://gs-entt-dev.onsang.co.kr/app/img/@tmp_talentboard_chat_01.cfb1d6e9.png';
//   if( ret) return ret
//   if( store.state.my.profileImgUrl) return store.state.my.profileImgUrl
//   return  `https://lxpcms.kbstar.com/contents/emp_img/current/${lrnerId}.jpg` // `https://hrd2.kbstar.com/images/profile/1/${lrnerId}.jpg`;  // 프로필 정보
// }

export const goBack = () => {
  router.go(-1);
}

export const goLink = (linkStr) =>{
  console.log("goto", linkStr);
  //domainUrl체크
  let link = linkStr.replace("https://lxp.kbstar.com/app", "");
  link = link.replace("https://devlxp.kbstar.com/app", "");
  if(link.indexOf("/app")==0){
    link = link.replace("/app/","/");
  }
  if(link.indexOf("https:")<0){
    if(link.indexOf("?")>0){
      let linkArr = link.split("?");
      link = linkArr[0];
      let queryJson = {};
      linkArr[1].split("&").map(x=>{queryJson[x.split("=")[0]] = x.split("=")[1]});
      console.log("link push querystring", link, queryJson);
      router.push({path:link, query:queryJson});
    }else{
      router.push({path:link});
    }


  }else{
    router.push({redirect: window.location.href=linkStr});
  }
}

export const getParentsLevel = (items, targetKey, key) => {
  if(targetKey){
    const target = items.find(x => x[key] === targetKey)
    if(target) return target;
  }
  return {}
}

export const isTempMenu = (menu) => {
  const splits = menu.menuUrl.split('/');
  let isTemp = false;
  for(let i = 0; i < splits.length; i++){
    if(splits[i] !== '' && splits[i] >= 0){
      isTemp = true;
      break;
    }
  }
  return isTemp;
}


export const customPageMove = (url) => {
  // menuSn 이 밀리는 이슈가 있어서 url로 체크하도록 수정
  if (url === '/junior/mentoring') {
    ssoKB('mentoring');
    return false;
  }
  else if (url === '/junior/ssm') {
    ssoKB('ssm');
    return false;
  } else if (url === '/junior/training') {
    ssoKB('juniortraining');
    return false;
  } else{
    ssoKB(url);
    return false;
  }
}

export const prdAsisMoveLinkMenus = [
   '/junior/mentoring'
  , '/junior/training'
]

export const devAsisMoveLinkMenus = [
  '/junior/mentoring'
  , '/junior/training'
]

export const hideFooter = () => {
  store.commit(`common/${MUT_HIDE_FOOTER}`);
};

export const showFooter = () => {
  store.commit(`common/${MUT_SHOW_FOOTER}`);
};

export const hideHeader = () => {
  store.commit(`auth/${MUT_HIDE_HEADER}`);
};

export const showHeader = () => {
  store.commit(`auth/${MUT_SHOW_HEADER}`);
};

export const moveSSL = () => {
  router.push({name: 'SSLHome'});
}

export const goBackFunc = (namespace) => {
  if (router.options.history.state.back) {
    router.go(-1);
  } else {
    router.push({name: stringCheck(namespace) ? namespace : "Main"}).then();
  }
};

export const sortByActiveAndNew = (arr) => {
  if (arr && arr.length > 0) {
    arr.sort((a, b) => {
      if (a.isActive && !b.isActive) {
        return -1;
      } else if (!a.isActive && b.isActive) {
        return 1;
      } else if (a.isNew && !b.isNew) {
        return -1;
      } else if (!a.isNew && b.isNew) {
        return 1;
      } else {
        return 0;
      }
    });
  }
};