<template>
  <main id="ssl-home" style="padding-bottom: 0;background-color:#fff">
    <SSLMobileSlotHeader
        :hideTitle="true">
      <template v-slot:title>
        <SSLMobileNav />
      </template>
      <template v-slot:left>
        <div class="util util-back">
          <a href="javascript:" class="util-actions util-actions-back" @click="goHome"><i class="icon-history-back"></i></a>
        </div>
        <div class="util util-search-input">
          <input ref="keywordEl" v-model.trim="keyW" type="text" placeholder="학습노트 검색" @keyup.prevent.stop.enter="getSocsBoardList" />
        </div>
      </template>
      <template v-slot:right>
        <div class="util util-search">
          <a href="javascript:"
             class="util-actions util-actions-search"
             @click="getSocsBoardList"
             @keyup.prevent.stop.enter="getSocsBoardList"
          ><i class="icon-search"></i></a>
        </div>
      </template>
    </SSLMobileSlotHeader>

    <div class="board-list-container">
      <!-- board-list-wrapper -->
      <!-- board-list-wrapper -->
      <div class="board-list-wrapper">

        <div v-if="!isLoading && items.length === 0" class="board-search-wrapper">
          <div class="search-empty">
            <img src="@/assets/lxp/images/ssl/main/empty_image.png" alt>
          </div>
        </div>

        <template v-else>

  <!--         board-list-type -->
          <ul v-for="(item, idx) in items" class="board-list-type mb-5 pb-3" :key="idx">
            <div class="board-list-top" style="padding-left:20px">
              <div class="top-column"><h3>{{item.distCrseNm}} ({{item.boardCnt}}건)</h3></div>
            </div>

            <li v-for="(socBoard, idx2) in item.socBoards" class="board-item" :key="idx2"> <!-- :class="{'type-text': !item.thumb}"  -->
              <SSLMobileBoardCard
                    :item="socBoard"
                    :is-group="getDistCrseCd(item.distCrseSn.toString()) == 'group'"
                    @moveBoard="moveBoard"
              />
            </li>
          </ul>


        </template>

      </div>
    </div>


  </main>
</template>

<script>
import {ACT_GET_SOC_BOARD_GROUP_LIST} from "@/store/modules/ssl/ssl-index"; //, BOARD_MODE_KEY
import {getItems, lengthCheck} from "@/assets/js/util"; //, initProgressBar
import {ref, onMounted} from "vue";
import {useStore} from "vuex";
// import SSLBoardCard from "@/components/ssl/home/SSLBoardCard.vue";
import {getDistCrseCd, goHome} from "@/assets/js/modules/ssl/ssl-common";
// import CommonPaginationFront from "@/components/CommonPaginationFront.vue";
import {useAlert} from "@/assets/js/modules/common/alert";
import SSLMobileBoardCard from "@/components/ssl/home/mobile/SSLMobileBoardCard";
import {useRoute} from "vue-router";
import SSLMobileSlotHeader from '@/components/ssl/main/mobile/SSLMobileSlotHeader';
import SSLMobileNav from '@/components/ssl/mobile/SSLMobileNav';

export default {
  name: 'SSLMobileBoardSearch',
  methods: {getDistCrseCd, goHome},
  components: {
    SSLMobileBoardCard,
    SSLMobileNav,
    SSLMobileSlotHeader
  },
  setup() {
    const store = useStore();
    const {showMessage} = useAlert();
    const isLoading = ref(true);
    const route = useRoute();

    const keyW = ref(route.params.keyword);
    const items = ref([]);

    const getSocsBoardList = () => {
      if(keyW.value == null || keyW.value == ''){
        showMessage('검색어를 입력해주세요');
        return;
      }

      store.dispatch(`ssl/${ACT_GET_SOC_BOARD_GROUP_LIST}`, {
        title: keyW.value,
        // pageNo: paging.pageNo,
        // pageSize: paging.pageSize
      }).then(res => {
        if(lengthCheck(res)) {
          items.value = getItems(res);
          // paging.totalCount= getPaging(res).totalCount;
          isLoading.value = false;
        }else{
          isLoading.value = false;
          items.value = [];
        }
      }).catch(e => {
        console.error(e);
        isLoading.value = false;
        items.value = [];
      });
    }

    const moveBoard = (socLrnPostSn) => {
      const target = items.value.find(item => item.socLrnPostSn === socLrnPostSn);
      if (target) target.myViewCnt++;

    }


    onMounted(() => {
      getSocsBoardList();
    });

    return {
      isLoading,
      items,
      moveBoard,
      getSocsBoardList,
      keyW,

    }
  }
}
</script>