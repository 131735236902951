<template>
  <LxpMobileHeader :title="evl.lrnEvlNm">
<!--    <template v-slot:left>-->
<!--      <div class="util util-back">-->
<!--        <a href="javascript:" class="util-actions util-actions-back" @click="conditionBack">-->
<!--          <i class="icon-history-back"></i>-->
<!--        </a>-->
<!--      </div>-->
<!--    </template>-->
  </LxpMobileHeader>
  <main class="kb-main etest-main" id="kb-etest">
    <template v-if="isInit">
    <!-- main-content -->
      <div class="main-content etest-contents" style="margin-bottom: 60px;">
        <div style="padding-top: 8px; padding-bottom: 24px">
          <section class="etest-section test-time">
            <ul>
              <li><span>현재</span>&nbsp;<span>{{ currentTimeText }}</span></li>
              <li><span>남음</span>&nbsp;<span>{{ remainTime }}</span></li>
            </ul>
          </section>
          <section class="etest-section main-tests">
            <div class="test-container">
              <div class="test-status">
                <span class="status-current">문제 {{ currentIndex + 1  }}</span>
                <span class="status-total">{{ questions.length }}</span>
              </div>
              <div class="test-questions">
<!--                <h3 class="questions-title">{{ targetQuestion.qstnNm }}</h3>-->
                <h3 v-html="targetQuestion.qstnNm"></h3>
              </div>


              <div  v-if="['2019003', '2019002', '2019001', '2019005'].includes(targetQuestion.qstnTyCdDcd)" class="test-answer answer-choice kb-form-check-group kb-form-check-block">
                <div v-for="(option, index) in targetQuestion.options" class="kb-form-check" :key="index" style="flex-wrap: nowrap; flex-direction: row; justify-content: flex-start;">
                  <div>
                    <input v-if="targetQuestion.qstnTyCdDcd === '2019003'"
                           v-model="answers[currentIndex].model"
                           type="checkbox" class="kb-form-check-input" :name="getSelectName(index)" :id="getSelectId(index)"
                           :value="option.optNo"
                           @change="saveAnswer"
                    >
                    <input v-else
                           v-model="answers[currentIndex].model"
                           type="radio" class="kb-form-check-input" :name="getSelectName(index)" :id="getSelectId(index)"
                           :value="option.optNo"
                           @change="saveAnswer"
                    >
                  </div>
                  <div>&nbsp;&nbsp;</div>
                  <div>
                    <label :for="getSelectId(index)" class="kb-form-check-label"><h3>{{ index + 1 }}. {{ option.optNm }}</h3></label>
                  </div>
                </div>
              </div>

              <div v-else class="test-answer answer-short">
                <input v-model="answers[currentIndex].model" type="text" placeholder="답을 직접 입력하세요." class="kb-form-control-answer" @change="saveAnswer">
              </div>

            </div>
          </section>
          <MobileEvlAside
              v-model="currentIndex"
              :answers="answers"
              @submitEvl="submitAnswers"
              @closeEvl="closeAnswers"
          />
        </div>
      </div>
    </template>
    <!-- //main-content -->
  </main>

  <footer class="eTest-footer">
    <div class="footer-column column-font">
      <button class="kb-btn kb-btn-primary kb-btn-short kb-btn-check" @click="checkQuestion">
        <span class="text">{{ targetAnswerChecked ? '해제' : '체크' }}</span>
      </button>
    </div>
    <div class="footer-column column-nav">
      <button class="kb-btn kb-btn-secondary kb-btn-short kb-btn-kb-nav-prev" @click="prevQuestion" style="margin-right: 8px;"><span class="text">이전</span></button>
      <button class="kb-btn kb-btn-secondary kb-btn-short kb-btn-kb-nav-next" @click="nextQuestion"><span class="text">다음</span></button>
    </div>
  </footer>

</template>

<script>
import LxpMobileHeader from '@/views/layout/lxp/header/mobile/LxpMobileHeader';
import MobileEvlAside from '@/components/evaluation/mobile/MobileEvlAside';
import {evlMainSetup} from '@/assets/js/modules/evaluation/evl-setup';

export default {
  name: 'MobileEvlMain',
  components: {MobileEvlAside, LxpMobileHeader},
  props: {
    evl: Object,
    isEnd: Boolean,
    isMock: Boolean,
    initDt: Number,
    canTake: Number,
    // currentTime: Object,
    currentTime: Number,
    currentTimeText: String,
    stopEvl: Function,
  },
  emits: ['endEvl', 'closeEvl'],
  setup: evlMainSetup
};
</script>

<style scoped>
  h3, b {font-weight: unset !important}
</style>
