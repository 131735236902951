<template>
  <div class="comment-container min-component" style="margin-bottom: 150px">
    <div class="comment-write" v-if="isWrite">
      <form @submit.prevent class="kb-form-comment">
        <input type="text" v-model.trim="cmnt" class="kb-form-comment-input" placeholder="댓글 달기 .." :disabled="isDisabled" @keyup.enter="clickInsert" />
        <button class="kb-btn kb-btn-secondary kb-btn-comment" :disabled="isDisabled" @click.stop="clickInsert">
          <span class="text">등록</span>
        </button>
      </form>
    </div>

<!--  <div class="board-view-comment" style="margin-bottom: 144px;">-->
<!--    <div v-if="isWrite" class="comment-write">-->
<!--&lt;!&ndash;      <form action="" class="kb-form-comment">&ndash;&gt;-->
<!--      <div class="kb-form-comment">-->
<!--        <input v-model.trim="cmnt" type="text" class="kb-form-comment-input" placeholder="댓글 달기" :disabled="isDisabled" @keyup.enter="clickInsert"/>-->
<!--        <button class="kb-btn-comment" :class="{'disabled': isDisabled}" :disabled="isDisabled" @click="clickInsert">-->
<!--          <span class="text">댓글</span>-->
<!--        </button>-->
<!--      </div>-->
<!--    </div>-->
    <div class="comment-wrapper">
      <ul class="comment-list">
        <li v-if="comments.length == 0" class="comment-item"><p class="comment-delete-text">등록된 댓글이 없습니다.</p></li>
        <template v-for="(comment, idx) in comments" :key="idx">
          <li v-if="comment.stt == '99'" class="comment-item"><p class="comment-delete-text" :style="{'margin-left': `${(comment.cmntLvl-1)*2}rem`}">- 삭제된 댓글입니다 -</p></li>
          <li v-else class="comment-item">
            <article class="comment" :style="{'margin-left': `${(comment.cmntLvl-1)*2}rem`}">
              <div class="comment-avatar">
                <div class="avatar">
                  <CommonTypeImage
                      :img-src="comment.imgUrl"
                      theme="profile"
                      :target="comment"
                      @clickImage="goLearner"
                  />
                </div>
              </div>
              <div class="comment-contents">
                  <p v-if="!comment.editMode" class="comment">{{ comment.cmnt }}</p>
                  <div class="comment-info">
                    <p class="text"><span v-if="comment.deptNm != ''">{{ comment.deptNm }} - </span>{{ comment.lrnerNm }}</p>
                    <p class="text">{{ timestampToDateFormat(comment.regDt, 'yyyy.MM.dd hh:mm') }}</p>
                  </div>
                  <div v-if="comment.editMode" class="comment-form">
                    <div>
                      <input v-model.trim="comment.cmnt" type="text" class="comment-field" @keyup.enter="clickUpdate(idx)"/>
                      <div class="kb-form-buttons">
                        <button class="kb-btn kb-btn-outline" @click="changeMode(idx)"><span class="text">취소</span></button>
                        <button class="kb-btn kb-btn-primary" @click="clickUpdate(idx)"><span class="text">수정</span></button>
                      </div>
                    </div>
                  </div>
              </div>

              <div class="comment-actions"> <!--  v-if="isDisabled" -->
                <div class="dropdown" :class="{ 'is-active' : comment.toggle }">
                  <button class="dropdown-btn kb-btn-actions" @click.stop="comment.toggle=!comment.toggle"><i class="icon-menu"></i></button>
                  <div class="dropdown-target">
                    <div class="dropdown-box">
                      <ul class="dropdown-option-list" @click.stop="comment.toggle=!comment.toggle">
                        <li class="dropdown-option-item" v-if="!comment.editMode && canCommentEdit(comment.regDt, 'college' === distCrseCd) && session.lrnerId === comment.lrnerId">
                          <a class="dropdown-option-link kb-mouse-cursor" @click="clickDelete(idx)"><span class="dropdown-option-text">삭제</span></a>
                        </li>
                        <li class="dropdown-option-item" v-if="isWrite">
                          <a class="dropdown-option-link kb-mouse-cursor" @click="addReply('add', idx)"><span class="dropdown-option-text">댓글 달기</span></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <!-- [YYY:220317] Add -->
  <!--            <div  class="comment-menu">-->
  <!--              &lt;!&ndash; [!!!DEV!!!] is-active 활성화 클래스 &ndash;&gt;-->
  <!--              <div v-if="!comment.editMode && session.lrnerId === comment.lrnerId && canCommentEdit(comment.regDt, 'college' === distCrseCd)" class="dropdown" :class="{'is-active': comment.toggle}">-->
  <!--                <button class="dropdown-toggle" @click.stop="toggleEdit(idx)"><i class="icon-menu"></i></button>-->
  <!--                &lt;!&ndash; [!!!DEV!!!] is-active 활성화 클래스 &ndash;&gt;-->
  <!--                <div class="dropdown-menu" :class="{'is-active': comment.toggle}" style="z-index: 1200">-->
  <!--                  <a href="javascript:" class="dropdown-item" @click="changeMode(idx)"><span class="text">수정</span></a>-->
  <!--                  <a href="javascript:" class="dropdown-item" @click="clickDelete(idx)"><span class="text">삭제</span></a>-->
  <!--                </div>-->
  <!--              </div>-->
  <!--            </div>-->
              <!-- //[YYY:220317] Add -->
            </article>
          </li>
          <div class="comment-write" v-if="comment.isReplying">
            <div class="kb-form-comment">
              <input type="text" v-model.trim="replyParam.cmnt" class="kb-form-comment-input" placeholder="댓글 달기 .." @keyup.enter="addReply('save', idx)" />
              <button class="kb-btn kb-btn-secondary kb-btn-comment" @click.stop="addReply('cancel', idx)">
                <span class="text">취소</span>
              </button>
              <button class="kb-btn kb-btn-secondary kb-btn-comment mx-1" @click.stop="addReply('save', idx)">
                <span class="text">등록</span>
              </button>
            </div>
          </div>
        </template>
      </ul>
    </div>
  </div>
</template>
<script>

import {sslCommentSetup} from '@/assets/js/modules/ssl/setup/ssl-comment-setup';
import CommonTypeImage from '@/components/common/CommonTypeImage';

export default {
  name: 'SSLMobileComment',
  components: {CommonTypeImage},
  props: {
    regDt: {
      type: Number,
      required: true
    },
    isWrite: {
      type: Boolean,
      default: true
    },
  },
  setup: sslCommentSetup
}
</script>
