<template>
  <div>
      <div class="view-contents">
          <!-- content-section -->
          <article class="content-section">
              <header v-if="evlQstnList.length > 0" class="section-header header-divider d-flex">
                  <h4 class="header-title">퀴즈목록 <span class="title-count">1</span></h4>
              </header>
              <div class="kb-form-fields kb-form-fields-v2">
                  <div class="kb-form-field field-first">
                      <div class="kb-form-row">
                          <label class="kb-form-label"><span class="kb-form-label-text">퀴즈명</span></label>
                      </div>
                      <div class="kb-form-row">
                          <input type="text" class="kb-form-control" v-model="evlMst.lrnEvlNm" />
                      </div>
                  </div>
              </div>
          </article>
          <!-- content-section -->
          <article v-if="evlQstnList.length > 0" class="content-section section-margin">
              <header class="section-header header-divider d-flex">
                  <h4 class="title">퀴즈문항 <span class="count">{{evlQstnList.length}}</span></h4>
<!--                  <div class="header-side">-->
<!--                      <button class="kb-btn-text"><span class="text text-primary">전체선택</span></button>-->
<!--                      <button class="kb-btn-text" @click="deleteQstn()"><span class="text text-red">삭제</span></button>-->
<!--                  </div>-->
              </header>
              <!-- text-item-container -->
              <div class="text-item-container" data-content="check">
                  <!-- text-item -->
                  <div class="text-item" v-for="(item, idx) in evlQstnList" :key="idx">
<!--                      <label class="kb-form-check-label" :for="`chk_${idx}`"></label>-->
                      <div class="item-column">
                          <h4 class="item-title" @click="qstnAddLayer(item)">{{item.qstnNm}}</h4>
                          <div class="item-meta">
                              <span class="text">보기({{item.optCnt}})</span>
                              <span class="text">{{item.rgtrNm}} 작성</span>
                              <span class="text">{{item.regDt}} 등록</span>
                          </div>
                      </div>
                      <div class="item-column column-check">
                          <div class="kb-form-check">
                              <button class="kb-btn-delete" @click="deleteQstn(item.lrnTyEvlMstSn, item.lrnTyEvlQstnSn)"><i class="icon-delete">삭제</i></button>
<!--                              <input type="checkbox" class="kb-form-check-input" :name="`chk_${idx}`" v-model="targetQuiz" :value="item.lrnTyEvlQstnSn" >-->
                          </div>
                      </div>
                  </div>
                  <!-- text-item -->
              </div>
              <!-- //text-item-container -->
          </article>
          <!-- content-section -->
          <article v-else class="content-section section-margin">
              <header class="section-header header-divider">
                  <h4 class="title">퀴즈문항 <span class="count">0</span></h4>
              </header>
              <div class="contents-empty empty-min">
                  <p class="text">등록된 퀴즈 문항이 없습니다. [문항 추가]를 클릭하여 <br>퀴즈 문항을 생성하세요.</p>
              </div>
<!--              <div class="section-bottom">-->
<!--                  <button class="kb-btn-content-item kb-btn-primary">문항 추가</button>-->
<!--              </div>-->
          </article>
      </div>
      <!-- popup-inner > popup-buttons -->
      <div style="display: none;" class="popup-buttons">
          <button class="kb-btn kb-btn-dark" @click="closeLayer"><span class="text">취소</span></button>
          <button class="kb-btn kb-btn-primary" @click="updateQuiz"><span class="text">저장</span></button>
      </div>
  </div>
</template>

<script>
import {computed, onMounted, ref} from 'vue';
import {useStore} from 'vuex';
import {useAlert} from '@/assets/js/modules/common/alert';
import {ACT_DELETE_QSTN, ACT_GET_QUIZ, ACT_UPDATE_QUIZ} from '@/store/modules/quiz/quiz';
import {getItems, isSuccess, lengthCheck, timestampToDateFormat} from '@/assets/js/util';

export default {
  name: "MobileQuizMngListDetail",
  props:{
    isQuizDetailShow: Boolean,
    quizDtlSn: Number,
    quizItem: Object,
    rsltAddLayer: Function,
    qstnAddLayer: Function,
  },
  emits:['update:isQuizDetailShow'],
  setup(props, {emit}){

    const store = useStore();
    const isQuizDtlQstnAddShow = ref(false);
    const isQstnAddShow = ref(false);
    const lrnTyEvlMstSn = ref(computed(() => props.quizDtlSn));
    const lrnTyEvlQstnSn = ref(0);
    const evlMst = ref({});
    const evlQstnList = ref([]);
    const qstnIdx = ref(0); // 현재 마지막 문제 번호
    const qstnNo = ref(0); // 문제번호
    const {showMessage, showConfirm} = useAlert();
    const targetQuiz = ref([]);

    onMounted(() => {
      getQstnList();
    })

    const getQstnList = () => {
      store.dispatch(`quiz/${ACT_GET_QUIZ}`, lrnTyEvlMstSn.value,
      ).then(res => {
        evlMst.value = res.quizItem;
        if (lengthCheck(res)) {
          let list = getItems(res);
          list.forEach(x => {return x.regDt = timestampToDateFormat(x.regDt, 'yyyy.MM.dd')})
          qstnIdx.value = parseInt(Math.max.apply(null, list.map(x => x.qstnNo)));
          evlQstnList.value = list.filter(x => x.stt==='00');
        } else {
          evlQstnList.value = [];
          qstnIdx.value = 0;
        }
      }).catch(e => {
        console.error(e);
      });
    };

    const addQstn = (qstn) => {

      if(qstn === 0 && props.quizItem.distCnt > 0){
        showMessage('출제된 퀴즈는 수정할 수 없습니다.');
        return false;
      }

      if(evlQstnList.value.length >= 10){
        showMessage('퀴즈 문항은 최대 10개까지 등록 가능합니다');
        return false;
      }

      lrnTyEvlQstnSn.value = qstn;
      if(qstn === 0 ) { // 새로운 문항추가할때
        qstnNo.value = qstnIdx.value + 1;
      } else { // 기존 문항 수정할때
        evlQstnList.value.filter(x => { if(x.lrnTyEvlQstnSn === qstn) qstnNo.value = x.qstnNo; });
      }
      isQstnAddShow.value = true;
      isQuizDtlQstnAddShow.value = false; // 삭제할것
    }

    const deleteQstn = (quizSn, qstnSn) => {

      if(props.quizItem.distCnt > 0){
        showMessage('출제된 퀴즈는 수정 할 수 없습니다.');
        return false;
      }

      showConfirm({
        text: '해당 퀴즈 문항을 삭제 하시겠습니까?',
        callback: () => {
          lrnTyEvlQstnSn.value = qstnSn;
          store.dispatch(`quiz/${ACT_DELETE_QSTN}`, {
            lrnTyEvlMstSn: quizSn,
            lrnTyEvlQstnSn: qstnSn,
          }).then(res => {
            if(isSuccess(res)){
              getQstnList();
              showMessage('퀴즈 문항을 삭제했습니다.');
            }
          }).catch((err) => {
            console.error(err);
          })
        }
      });
    }

    const updateQuiz = () => {
      if(props.quizItem.distCnt > 0){
        showMessage('출제된 퀴즈는 수정할수 없습니다.');
        return false;
      }
      store.dispatch(`quiz/${ACT_UPDATE_QUIZ}`, {
        lrnTyEvlMstSn: lrnTyEvlMstSn.value,
        params:{
          lrnEvlNm: evlMst.value.lrnEvlNm,
        }
      }).then(res => {
        if(isSuccess(res)){
          showMessage('퀴즈수정을 완료했습니다.');
        }
      }).catch((err) => {
        console.error(err);
      })
    }

    const closeLayer = () => {
      props.rsltAddLayer();
      emit('update:isQuizDetailShow', false);
    }

    return{
      evlMst,
      evlQstnList,
      lrnTyEvlMstSn,
      lrnTyEvlQstnSn,
      isQuizDtlQstnAddShow,
      isQstnAddShow,
      qstnIdx,
      qstnNo,
      targetQuiz,
      getQstnList,
      addQstn,
      deleteQstn,
      updateQuiz,
      closeLayer
    }
  }
}
</script>