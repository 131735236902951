<template>
  <template v-if="isMobile">
    <div class="text-item">
      <h5 class="item-title">{{ title }}</h5>
      <div class="item-row">
        <span class="text-title">당월{{title == '학습시간' ? ' (완료기준)':''}}</span>
        <span class="text-description text-gold">{{ item.month }}</span>
      </div>
      <div class="item-row">
        <span class="text-title">연간{{title == '학습시간' ? ' (완료기준)':''}}</span>
        <span class="text-description text-gold">{{ item.year }}</span>
      </div>
      <div class="item-row">
        <button v-if="showAll && func" class="kb-btn kb-btn-outline kb-btn-content-item" @click="func"><span class="text">상세보기</span></button>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="roundedbox">
      <div class="roundedbox-top">
        <div class="top-row">
          <h5 class="roundedbox-title">{{ title }}</h5>
          <a href="javascript:" v-if="showAll && func" class="roundedbox-link" @click="func">상세보기</a>
        </div>
      </div>
      <div class="roundedbox-bottom">
        <div class="roundedbox-subtitle">당월{{title == '학습시간' ? ' (완료기준)':''}}</div>
        <span class="roundedbox-text">{{ item.month }}</span>
      </div>
      <div class="roundedbox-bottom">
        <div class="roundedbox-subtitle">연간{{title == '학습시간' ? ' (완료기준)':''}}</div>
        <span class="roundedbox-text">{{ item.year }}</span>
      </div>
    </div>
  </template>
</template>

<script>
import {computed} from 'vue';
import {secondToKrFormat} from '@/assets/js/util';

export default {
  name: 'RoundedBox',
  props: {
    title: String,
    data: {type: [Object, Number], default: () => { return {month: 0, year: 0}; }},
    unit: {type: String, default: null},
    showAll: {type: Boolean, default: false},
    func: Function,
    isMobile: Boolean,
  },
  setup(props) {
    const item = computed(() => {
      let data = {month: 0, year: 0};
      if (props.data instanceof Object) {
        if (props.unit === 'time') {
          data.month = secondToKrFormat(props.data.month);
        } else if (props.unit === 'day') {
          data.month = `${props.data.month} 일`;
        } else if (props.unit === 'mileage') {
          data.month = `${props.data.month} 마일리지`;
        } else {
          data.month = `${props.data.month} 개`;
        }

        if (props.unit === 'time') {
          data.year = secondToKrFormat(props.data.year);
        } else if (props.unit === 'day') {
          data.year = `${props.data.year} 일`;
        } else if (props.unit === 'mileage') {
          data.year = `${props.data.year} 마일리지`;
        } else {
          data.year = `${props.data.year} 개`;
        }
      }
      return data;
    });

    return {
      item
    }
  }
};
</script>