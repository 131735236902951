import LxpLayout from "@/views/layout/lxp/LxpLayout";
import navigationUtils from "@/assets/js/navigationUtils";
import LxpMobileLayout from '@/views/layout/lxp/mobile/LxpMobileLayout';
const isMobile = navigationUtils.any();

const EventRoutes = [
    {
        path: '/event',
        component: isMobile ? LxpMobileLayout : LxpLayout,
        children: [
            {
                // path variable 을 생성해야 된다. 임시 라우터 처리
                path: 'ccc',
                name: "cccMain",
                component: () =>isMobile ? import('../../views/pages/event/mobile/MobileCccMain'):import('../../views/pages/event/CccMain')
            },
            {
                // path variable 을 생성해야 된다. 임시 라우터 처리
                path: 'cert',
                name: "lrnCertChall",
                component: () =>isMobile ? import('../../views/pages/event/mobile/MobileLrnCertChall'):import('../../views/pages/event/LrnCertChall')
            },
        ],
    }
];

export const setEventRoutes = (routes) => {
    routes.push(...EventRoutes);
}