<template>
  <div class="popup-container is-active">
    <div class="popup" id="popup-hrdcolud-h0205">
      <div class="popup-inner">
        <div>
          <header class="popup-header"><h3 class="title">SSL 학습노트 공유</h3></header>
          <div class="popup-content">
            <div class="member-search-container">
              <div class="kb-form-fields field-01">
                <div class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label"><span class="kb-form-label-text">부점/부서 </span></label>
                  </div>
                  <div class="kb-form-column">
                    <div class="kb-form-search-field">
                      <input v-model.trim="filters.dept" type="text" class="kb-form-search-input" placeholder="부점 및 부서명 검색" @keyup.enter="searchFunc">
                      <button class="kb-form-search-btn" @click="searchFunc"><i class="icon-search"></i></button>
                    </div>
                  </div>
                </div>
                <div class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title"><label class="kb-form-label"><span
                      class="kb-form-label-text">직원</span></label></div>
                  <div class="kb-form-column">
                    <div class="kb-form-search-field">
                      <input v-model.trim="filters.lrner" type="text" class="kb-form-search-input" placeholder="직원명 및 직원번호 검색" @keyup.enter="searchFunc">
                      <button class="kb-form-search-btn" @click="searchFunc"><i class="icon-search"></i></button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="kb-form-buttons">
                <button class="kb-btn kb-btn-primary kb-btn-lg" @click="searchFunc"><span class="text">검색</span></button>
              </div>

              <div class="search-results">
                <section class="popup-section">
                  <header class="section-header">
                    <h4 class="title">검색결과 <strong class="text text-muted">{{ paging.totalCount }}</strong>
                    </h4>
                  </header>
                  <div class="section-content">
                    <div ref="searchEl" class="kb-table kb-table-striped member-tablelist" style="height: 300px; overflow-y: auto;">
                      <table>
                        <colgroup>
                          <col style="width: auto;">
                          <col style="width: auto;">
                          <col style="width: auto;">
                          <col style="width: auto;">
                          <col style="width: 160px;">
                        </colgroup>
                        <thead>
                        <tr>
                          <th><span class="th-title">지점코드/지점명</span></th>
                          <th><span class="th-title">직원명</span></th>
                          <th><span class="th-title">직원번호</span></th>
                          <th><span class="th-title">직급</span></th>
                          <th class="text-center"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="searchedItems.length === 0">
                          <td colspan="5" style="text-align: center"><span
                              class="td-text text-muted">검색 결과가 없습니다.</span></td>
                        </tr>
                        <template v-else>
                          <tr v-for="(item, index) in searchedItems" :key="index">
                            <td><strong class="td-text">{{ item.deptCd }} / {{ item.deptNm }}</strong></td>
                            <td><strong class="td-text">{{ item.lrnerNm }}</strong></td>
                            <td><strong class="td-text">{{ item.lrnerId }}</strong></td>
                            <td><strong class="td-text">{{
                                item.jbgdCd && item.jbgdCd.length >= 2 ? item.jbgdCd.substr(0, 2) : '-'
                              }}</strong></td>
                            <td class="text-center">
                              <div class="kb-form-check">
                                <input type="checkbox" class="kb-form-check-input" @change="changeShareItems($event, item)" :checked="checkedShare(item)">
                              </div>
                            </td>
                          </tr>
                        </template>
                        </tbody>
                      </table>
                    </div>

                    <SSLPagination
                        :paging="paging"
                        :page-func="pageFunc"
                    />
                  </div>
                </section>
                <section class="popup-section">
                  <header class="section-header"><h4 class="title">공유대상 <strong class="text text-muted">{{ shareItems.length }}</strong></h4>
                  </header>
                  <div class="section-content">
                    <div class="kb-table kb-table-striped member-tablelist" style="height: 300px; overflow-y: auto;">
                      <table>
                        <colgroup>
                          <col style="width: auto;">
                          <col style="width: auto;">
                          <col style="width: auto;">
                          <col style="width: auto;">
                          <col style="width: 160px;">
                        </colgroup>
                        <thead>
                        <tr>
                          <th><span class="th-title">지점코드/지점명</span></th>
                          <th><span class="th-title">직원명</span></th>
                          <th><span class="th-title">직원번호</span></th>
                          <th><span class="th-title">직급</span></th>
                          <th class="text-center">
                            <button class="kb-btn-check" @click="clearShareItems"><span class="th-title text-primary">전체해제</span></button>
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="shareItems.length === 0">
                          <td colspan="5" style="text-align: center"><span
                              class="td-text text-muted">공유 대상이 없습니다.</span></td>
                        </tr>
                        <template v-else>
                          <tr v-for="(item, index) in shareItems" :key="index">
                            <td><strong class="td-text">{{ item.deptCd }} / {{ item.deptNm }}</strong></td>
                            <td><strong class="td-text">{{ item.lrnerNm }}</strong></td>
                            <td><strong class="td-text">{{ item.lrnerId }}</strong></td>
                            <td><strong class="td-text">{{ item.jbgdCd && item.jbgdCd.length >= 2 ? item.jbgdCd.substr(0, 2) : '-'}}</strong></td>
                            <td class="text-center">
                              <div class="kb-form-check">
                                <input type="checkbox" class="kb-form-check-input" checked @change="changeShareItems($event, item)">
                              </div>
                            </td>
                          </tr>
                        </template>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>
                <section class="popup-section">
                  <header class="section-header"><h4 class="title">공유 메시지</h4></header>
                  <div class="section-content">
                    <div class="segment-box">
                      <textarea v-model.trim="params.message" cols="30" rows="6" class="kb-form-control"
                                placeholder="전송할 메시지를 입력해주세요."></textarea>
                    </div>
                  </div>
                </section>
                <section class="popup-section">
                  <header class="section-header"><h4 class="title">공유방법</h4></header>
                  <div class="section-content">
                    <div class="kb-form-fields">
                      <div class="kb-form-row">
                        <div class="kb-form-column kb-form-column-title"><label class="kb-form-label"><strong
                            class="kb-form-label-text">구분</strong></label></div>
                        <div class="kb-form-column">
                          <div class="kb-form-group">
                            <div v-for="(notiPtm, index) in notiPtms" class="kb-form-check kb-form-check-inline" :key="index">
                              <input v-model="params.notiPfmCd" type="checkbox" class="kb-form-check-input" :id="`chk_ptm_${index}`" :value="notiPtm.dcd">
                              <label :for="`chk_ptm_${index}`" class="kb-form-check-label"><span class="text">{{ notiPtm.name }}</span></label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
          <div class="popup-buttons"><a href="javascript:" class="kb-btn kb-btn-primary kb-btn-lg" @click="shareSSL"><span class="text">공유하기</span></a>
          </div>
        </div>
      </div>
      <div class="popup-close" @click="closeModal">
        <button class="kb-btn-popup-close"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
  </div>
</template>
<script>

import SSLPagination from '@/components/ssl/notice/SSLPagination';
import {sslShareSetup} from '@/assets/js/modules/ssl/setup/ssl-view-setup';

export default {
  name: 'SSLShare',
  components: {SSLPagination},
  props: {
    toggle: Boolean,
    lrnTrgtGrpMstSn: Number,
    socLrnPostSn: Number,
    title: String
  },
  emits: ['update:toggle', 'acted'],
  setup: sslShareSetup,
};
</script>
