<template>
  <!-- [begin::popup-container] -->
  <div class="popup-container" :class="{'is-active': modelValue}">
    <!-- popup -->
    <div class="popup" id="popup-quiz">
      <!-- popup > popup-inner -->
      <div class="popup-inner">
        <div>
          <!-- popup-inner > popup-header -->
          <header class="popup-header">
            <h3 class="title">퀴즈관리</h3>
          </header>
          <!-- popup-inner > popup-tabs -->
          <nav class="popup-tabs">
            <a href="javascript:" class="tab" :class="{'is-active' : activeTab === 'quiz'}" @click="activeTab = 'quiz'">퀴즈목록</a>
            <a href="javascript:" class="tab" :class="{'is-active' : activeTab === 'result'}" @click="activeTab = 'result'">퀴즈출제결과</a>
          </nav>

          <!--  퀴즈목록   -->
          <QuizList v-if="activeTab === 'quiz'" />
          <QuizMngResultList v-if="activeTab === 'result'"/>

        </div>

      </div>
      <!-- popup > popup-close -->
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
    <!-- //popup -->
  </div>
  <!-- //[begin::popup-container] -->
</template>

<script>
import QuizList from "@/components/quiz/QuizList";
import QuizMngResultList from "@/components/quiz/QuizMngResultList";
import {quizMngModalSetup} from "@/assets/js/modules/quiz/quiz-mng-modal-setup";

export default {
  name: "QuizMngModal",
  components: {QuizMngResultList, QuizList},
  props: {
    modelValue: Boolean,
    quizAddFun: Function
  },
  emits: ['update:modelValue'],
  setup: quizMngModalSetup
}
</script>