<template>
  <div class="header-snb">
    <nav class="snb">
      <ul class="snb-list">
        <li v-for="(category,idx) in lvl1Cate" class="snb-item" :key="idx">
          <template v-if="category.hrdCloudCateSn === 5">
            <div v-if="checkVice()" class="dropdown" :class="{'is-active' : toggle && category.hrdCloudCateSn === lvl1Sn}">
              <a href="javascript:" class="snb-link"
                 :class="{'is-active': category.hrdCloudCateSn === lvl1Sn}"
                 @click.stop.prevent="handleCategory1(category)">
                <i :class="renderIcon(category.hrdCloudCateSn)"></i>
                <span class="snb-text">{{ category.hrdCloudCateNm }}</span>
              </a>
              <HrdCloudCategory v-if="lvl1Sn > 0" v-model:toggle="toggleLvl1" @update:toggle="$emit('update:toggle',toggleLvl1)"/>
            </div>
          </template>
          <template v-else>
            <div class="dropdown" :class="{'is-active' : toggle && category.hrdCloudCateSn === lvl1Sn}">
              <a href="javascript:" class="snb-link"
                 :class="{'is-active': category.hrdCloudCateSn === lvl1Sn}"
                 @click.stop.prevent="handleCategory1(category)">
                <i :class="renderIcon(category.hrdCloudCateSn)"></i>
                <span class="snb-text">{{ category.hrdCloudCateNm }}</span>
              </a>
              <HrdCloudCategory v-if="lvl1Sn > 0" v-model:toggle="toggleLvl1" @update:toggle="$emit('update:toggle',toggleLvl1)"/>
            </div>
          </template>
        </li>
      </ul>
    </nav>
    <slot name="hrdcloud-search"></slot>
  </div>

</template>

<script>
import {computed, onMounted, ref, watch} from 'vue';
import HrdCloudCategory from '@/components/hrdcloud/HrdCloudCategory';
import {useStore} from 'vuex';
import {getHrdCates} from '@/assets/js/modules/hrd/hrd-common';

export default {
  name: 'HrdCloudRoute',
  components: {HrdCloudCategory},
  props: {
    isLoading: Boolean,
    lvl1Sn: Number,
    toggle: Boolean,
    func: Function
  },
  emits: ['update:lvl1Sn', 'update:options', 'update:toggle'],
  setup(props, {emit}) {
    const store = useStore();
    const session = computed(() => store.state.auth.session);
    const hrdCloudCateSn = ref(0);
    const categories = computed(() => store.state.hrdcloud.hrdcloudCates);
    const toggleLvl1 = ref(props.toggle || false);

    const lvl1Cate = ref([{
      hrdCloudCateNm: '홈',
      hrdCloudCateSn: 0,
      lvl: 1
    }]);

    const getMainCategories = async () => {
      if (!categories.value) getHrdCates();

      if (categories.value.length > 0 && lvl1Cate.value.length === 1) {
        categories.value.filter(x => {
          if (x.lvl === 1) {
            lvl1Cate.value.push({
              hrdCloudCateNm: x.hrdCloudCateNm,
              hrdCloudCateSn: x.hrdCloudCateSn,
              lvl: x.lvl
            });
          }
        });
      }
    };

    const handleCategory1 = (menu) => {
      hrdCloudCateSn.value = menu.hrdCloudCateSn;
      if (props.lvl1Sn !== menu.hrdCloudCateSn) {
        props.func(menu.hrdCloudCateSn);
        emit('update:toggle',true);
      } else {
        emit('update:toggle',!props.toggle);
      }
    };

    const renderIcon = (sn) => {
      if (sn === 0) {
        return 'icon-hrd-home';
      } else if (sn === 1) {
        return 'icon-hrd-bag';
      } else if (sn === 2) {
        return 'icon-hrd-book';
      } else if (sn === 3) {
        return 'icon-hrd-cloud';
      } else if (sn === 4) {
        return 'icon-hrd-monitor';
      } else if (sn === 5) {
        return 'icon-hrd-tie';
      }
    };

    const checkVice = () => {
      if (session.value) {
        if(session.value.deptCd ==='190450' || session.value.deptCd ==='115090'){ //HR부(인재개발) HR부 컨텐츠 관리를 위하여 권한추가
          return true;
        }
        if (session.value.vice) {
          return true;
        } else if (session.value.jbpsCd) {
          if (session.value.jbpsCd.indexOf('A') === 0 || session.value.jbpsCd.indexOf('B') === 0) {
            return true;
          }
        }
      }
      return false;
    };

    watch(() => categories.value, () => {
      getMainCategories();
    });

    onMounted(() => {
      getMainCategories();
    });

    return {
      hrdCloudCateSn, toggleLvl1, lvl1Cate,
      categories,
      handleCategory1,
      renderIcon,
      session,
      checkVice
    }
  }
};
</script>