<template>
  <!-- [begin::popup-container] -->
  <div class="popup-container is-active">
    <!-- popup -->
    <div class="popup" id="popup-quiz">
      <!-- popup > popup-inner -->
      <div class="popup-inner">
        <div>
          <!-- popup-inner > popup-header -->
          <header class="popup-header">
            <h3 class="title">퀴즈 문항 추가</h3>
          </header>
          <!-- popup-inner > popup-content -->
          <div class="popup-content">
            <!-- popup-section:문제 -->
            <article class="popup-section">
              <header class="section-header">
                <h4 class="title">문제</h4>
              </header>
              <div class="section-content">
                <div class="segment-box">
                  <textarea v-model="qstnNm" name="" id="" class="kb-form-control" placeholder="문제를 입력하세요."></textarea>
                </div>
              </div>
            </article>
            <!-- popup-section:보기 설정 -->
            <article class="popup-section">
              <header class="section-header">
                <h4 class="title">보기 설정</h4>
              </header>
              <div class="section-content">
                <div class="kb-table kb-table-striped">
                  <table>
                    <colgroup>
                      <col style="width:88px" />
                      <col style="width:auto" />
                      <col style="width:120px" />
                      <col style="width:120px" />
                    </colgroup>
                    <thead>
                    <tr>
                      <th><span class="th-title">번호</span></th>
                      <th><span class="th-title">보기</span></th>
                      <th class="td-center"><span class="th-title">삭제</span></th>
                      <th class="td-center"><span class="th-title">정답</span></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, idx) in evlQstnOpt" :key="idx">
                      <td><span class="td-text">{{ idx+1 }}</span></td>
                      <td><span class="td-text"><input style="width: 850px;" type="text" v-model="item.optNm" :placeholder="`보기${idx+1}`" class="opt-input"/></span></td>
                      <td class="td-center">
                        <div class="td-cell">
                          <button class="kb-btn-delete" @click="deleteQstnOpt(idx)"><i class="icon-delete">삭제</i></button>
                        </div>
                      </td>
                      <td class="td-center">
                        <div class="td-cell">
                          <div class="kb-form-check">
                            <input v-if="lrnTyEvlQstnSn > 0" type="radio" class="kb-form-check-input" @click="ansCheck(idx)" name="optCheck" :value="idx+1" :checked="item.crctAnsYn === 'Y'" />
                            <input v-else type="radio" class="kb-form-check-input" @click="ansCheck(idx)" name="optCheck" :value="idx+1" :checked="idx===0" />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td><span class="td-text"></span></td>
                      <td colspan="3"><button class="td-btn" @click="addQstnOpt"><strong class="td-text text-primary">보기 추가</strong></button></td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </article>
          </div>
          <!-- popup-inner > popup-buttons -->
          <div class="popup-buttons">
            <button class="kb-btn kb-btn-outline kb-btn-lg" @click="closeLayer"><span class="text">취소</span></button>
            <button class="kb-btn kb-btn-primary kb-btn-lg" @click="addQstn"><span class="text">{{ qstnSn === 0 ? '추가' : '저장' }}</span></button>
          </div>
        </div>
      </div>
      <!-- popup > popup-close -->
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeLayer"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
    <!-- //popup -->
  </div>
  <!-- //[begin::popup-container] -->
</template>

<script>
import {quizMngQstnAddSetup} from "@/assets/js/modules/quiz/quiz-mng-qstn-add-setup";

export default {
  name: "QuizMngQstnAdd",
  props: {
    isQstnAddShow: Boolean,
    quizSn: Number,
    qstnSn: Number,
    qstnIdx: Number,
    distCnt: Number,
    getQstnList: Function
  },
  emits:['update:isQstnAddShow'],
  setup: quizMngQstnAddSetup
}
</script>