<template>
  <LxpMobileHeader title="활동 피드">
    <template v-slot:left>
      <div class="util util-back">
        <a href="javascript:" class="util-actions util-actions-back" @click="goBack">
          <i class="icon-history-back"></i>
        </a>
      </div>
    </template>
  </LxpMobileHeader>
  <main class="kb-main" id="kb-facility">
    <!-- main-header -->
    <div class="main-header main-header-visual">
      <!-- ㅡmain-header-nav -->
      <div class="header-snb">
        <nav class="snb">
          <ul class="snb-list">
            <li class="snb-item">
              <button :class="{'is-active': actDiv === 'all'}"
                      @click="getActFeedList('all', true)"
              >전체</button>
            </li>
            <li class="snb-item">
              <button :class="{'is-active': actDiv === 'cmntRec'}"
                      @click="getActFeedList('cmntRec', true)"
              >댓글</button>
            </li>
            <li class="snb-item">
              <button :class="{'is-active': actDiv === 'likeGet'}"
                      @click="getActFeedList('likeGet', true)"
              >좋아요</button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <!-- //main-header -->
    <!-- main-content -->
    <div class="main-content">
    </div>

    <div class="main-content main-component">
      <div class="view-contents">


        <section class="content-section">
          <header class="section-header">
            <div class="title-list">
              <h4 class="title is-active"></h4>
            </div>
            <div class="sub-desc">
              <p class="total">총 {{ paging.totalCount }}건</p>
            </div>
          </header>
          <div class="section-content">
            <template v-if="!isLoading && (items && items.length > 0)">
              <div v-for="(item,idx) in items" class="content-item history-list" :key="idx">
                <a href="javascript:" @click="goBoardView(item.distCrseSn, item.lrnTrgtGrpMstSn, item.socLrnPostSn);">
                  <div class="content-item-header">
                    <h5 class="item-title">[{{ item.distCrseNm }}] <span style="font-weight:normal">{{ item.title }}</span></h5>

                  </div>
                  <div class="meta-content">
                    <span class="td-text">{{ item.lrnerNm }}<span v-if="item.deptNm != ''">({{item.deptNm}})</span>님이 <strong :class="[item.actDivCd == 'cmntRec' ? 'text-gold':'text-red']">{{item.actDivNm}}</strong><span v-if="item.actDivCd=='cmntRec'">을</span><span v-else>를</span> 남겼습니다<strong v-if="item.actDivCd=='cmntRec'" class="text-gold"> : {{item.cmnt}}&nbsp;&nbsp;{{item.regYmd}} &nbsp;＞</strong> </span>
                  </div>
                  <div class="meta-content text-gold"></div>
<!--                  <div class="kb-btn-actions">-->
<!--                    <button class="kb-btn-content-item" @click="goBoardView(item.distCrseSn, item.lrnTrgtGrpMstSn, item.socLrnPostSn);">상세</button>-->
<!--                  </div>-->
                </a>
              </div>
              <div v-if="paging.hasMore" class="content-item">
                <div class="kb-btn-more-container">
                  <button class="kb-btn-content-item kb-btn-primary" @click.stop="moreFunc(paging)">더보기</button>
                </div>
              </div>
            </template>
            <div v-else-if="!isLoading && (items && items.length === 0)" class="search-container">
              <div class="result-empty">
                <img src="../../../../assets/lxp/images/common/img_empty.png" alt="">
                <p class="text">해당 데이터가 없습니다.</p>
              </div>
            </div>
            <LoadingDiv v-if="isLoading"/>
          </div>
        </section>

      </div>
    </div>
  </main>

</template>



<script>
import {onMounted, reactive, ref} from 'vue';
import {ACT_GET_ACT_FEED_LIST} from "@/store/modules/ssl/ssl-index";
import {getDistCrseCd} from "@/assets/js/modules/ssl/ssl-common";
import {useStore} from "vuex";
import {getItems, getPaging, lengthCheck, timestampToDateFormat} from "@/assets/js/util";
import {useRoute, useRouter} from "vue-router";
import LxpMobileHeader from "@/views/layout/lxp/header/mobile/LxpMobileHeader.vue";
import LoadingDiv from "@/components/common/LoadingDiv.vue";

export default {
  name: "SSLMobileActFeed",
  components: {LoadingDiv, LxpMobileHeader},
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const isLoading = ref(true);
    const actDiv = ref(route.params.div);
    const items = ref([]);

    const paging = reactive(
        {pageNo: 1, pageSize: 20, totalCount: 0, hasMore: false}
    );

    const getActFeedList=(actDivCd, isInit)=>{
        if(isInit) {
          items.value = [];
          paging.pageNo = 1;
        }
        actDiv.value = actDivCd;

        store.dispatch(`ssl/${ACT_GET_ACT_FEED_LIST}`, {actDivCd : actDivCd, params:paging}).then(res => {

          if (lengthCheck(res)) {
            items.value.push(...getItems(res));
            paging.totalCount = getPaging(res).totalCount;
            paging.hasMore = getPaging(res).hasMore;
            isLoading.value = false;

            if(isInit){
              window.scrollTo(0, 0);
            }
          }else{
            items.value = [];
            isLoading.value=false;
          }

        });
    }
    const goBack = () => {
      router.go(-1);
    }

    const goBoardView =(distCrseSn, lrnTrgtGrpMstSn, socLrnPostSn)=>{
      router.push({name: 'SSLView', params: {distCrseCd:getDistCrseCd(distCrseSn), lrnTrgtGrpMstSn:lrnTrgtGrpMstSn, socLrnPostSn: socLrnPostSn}}).then(() => {});
    };

    const moreFunc = (paging) => {
      paging.pageNo++;
      getActFeedList(actDiv.value, false);
    };

    onMounted(()=>{
      getActFeedList(actDiv.value, true);
    });

    return{
      actDiv,
      items,
      paging,
      isLoading,
      getActFeedList,
      goBoardView,
      goBack,
      timestampToDateFormat,
      moreFunc
    }
  }
}

</script>