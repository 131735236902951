import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {computed, reactive, ref} from "vue";
import {useAlert} from "@/assets/js/modules/common/alert";
import {ACT_GET_CRSE_DIST_FLD, ACT_INSERT_CRSE_DIST_GROUP} from "@/store/modules/course/course";
import {getItems, isSuccess, lengthCheck, setParams} from "@/assets/js/util";
import {ACT_GET_GROUP_LRN_BOARD_MNG, ACT_UPDATE_GROUP_LRN_BOARD_MNG} from "@/store/modules/board/board";
import navigationUtils from "@/assets/js/navigationUtils";

export const groupLearningMngSetup = () => {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const boardSn = computed(() => route.params.boardSn);
    const {showConfirm, showConfirmCustomBtn, showMessage} = useAlert();

    const relToggle = ref(false);
    const peoplCountToggle = ref(false);

    const pwdUseYn = ref(false);

    const params = reactive({
        distCrseSn: 0,
        boardSn: 0,
        distCrseLrnFldSn: 0,
        distCrseNm: '',
        peoplCnt: 0,
        boardSumup:  '',
        selfIntro:  '',
        pwdUseYn: 'N',
        pwd: '',
        newPwd: null,
        changePwd: 'N',
        stt: '01'
    });

    const fldOptions = ref([]);

    const getCrseDistFldList = () => {
        store.dispatch(`course/${ACT_GET_CRSE_DIST_FLD}`).then(res => {
            if(lengthCheck(res)) {
                fldOptions.value = getItems(res);
            }
        })
    }
    getCrseDistFldList();

    const item = ref({});

    const clickPeoplCount = () => {
        peoplCountToggle.value = !peoplCountToggle.value
    };

    const selectPeoplCount = (num) => {
        params.peoplCnt = num;
        peoplCountToggle.value = false;
    };

    const tempPwd = ref('');

    const isChanged = ref(false);

    const getGroupLrnBoardMng = () => {
        store.dispatch(`board/${ACT_GET_GROUP_LRN_BOARD_MNG}`, {boardSn: boardSn.value, lrnPostSn: 0}).then(res=>{
            if(lengthCheck(res)) {
                item.value = res.items.map(x => {
                    pwdUseYn.value = x.pwdUseYn === 'Y';
                    if (x.pwd) {
                        tempPwd.value = '0000';
                    }
                    return x;
                })[0];
                setParams(params, item.value);
            }
        }).catch(e => {
            console.error(e);
        })
    }

    // input 내용이 변경 됐는지 확인
    const checkPwd = () => {
        isChanged.value = true;
        params.newPwd = tempPwd.value;
    };

    const insertGroupLrnBoard = () => {
        store.dispatch(`course/${ACT_INSERT_CRSE_DIST_GROUP}`, params).then(res=>{
            if(isSuccess(res)) {
                showConfirmCustomBtn({
                    text: '그룹이 생성되었습니다.<br/>함께 할 멤버를 초대하세요.',
                    okBtnTitle: '확인',
                    callback: () => {
                        router.push({name: 'GroupLearningDetailView', params: {distCrseSn: item.value.distCrseSn > 0 ? item.value.distCrseSn : res.distCrseSn }})
                    }
                }, () => {}, () => {}, () => {
                    getGroupLrnBoardMng();
                });
            }
        }).catch(e => {
            console.error(e);
        })
    }

    const updateGroupLrnBoard = () => {
        const boardParams = {
            lrnTrgtGrpMstSn: item.value.lrnTrgtGrpDtlSn,
            lrnTrgtGrpDtlSn: item.value.lrnTrgtGrpDtlSn,
            distCrseSn: item.value.distCrseSn
        };

        store.dispatch(`board/${ACT_UPDATE_GROUP_LRN_BOARD_MNG}`, {
            boardSn: boardSn.value,
            params:  {...params, ...boardParams}
        }).then(res=>{
            if(isSuccess(res)) {
                showMessage({
                    text: '그룹 정보가 저장되었습니다.',
                    callback: () => {
                        if(params.stt === '00') {
                            router.push({name: 'GroupView', params: {distCrseSn: params.distCrseSn}});
                        } else {
                            router.push({name: 'GroupLearningDetailView', params: {distCrseSn: params.distCrseSn}});
                        }
                    }
                });
            }
        }).catch(e => {
            console.error(e);
        });
    }

    const clickSave = () => {
        if(!params.distCrseNm || params.distCrseLrnFldSn === 0 || params.peoplCnt === 0 || !params.boardSumup || !params.selfIntro) {
            showMessage('필수 입력값을 모두 입력해주세요.');
        } else {
            params.pwdUseYn = pwdUseYn.value ? 'Y' : 'N';
            if(pwdUseYn.value && tempPwd.value.length < 4) {
                showMessage('비민번호 4자리 입력해주세요.');
            } else {
                showConfirm({
                    title: '안내',
                    text: `그룹 정보를 ${boardSn.value > 0 ? '저장' : '생성'}하시겠습니까?`,
                    callback: () => {
                        boardSn.value > 0 ? updateGroupLrnBoard() : insertGroupLrnBoard();
                    }
                });
            }
        }
    };

    getGroupLrnBoardMng();

    const changePwdUseYn =() => {
        if(!pwdUseYn.value) {
            params.newPwd = null;
        } else {
            params.newPwd = tempPwd.value;
        }
    }

    return {
        relToggle,
        peoplCountToggle,

        boardSn,
        pwdUseYn,
        params,
        fldOptions,
        item,
        tempPwd,
        isChanged,

        checkPwd,
        selectPeoplCount,
        clickPeoplCount,
        clickSave,
        changePwdUseYn,

        isMobile: navigationUtils.any()
    }
}