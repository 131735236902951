<template>
  <td>
    <span v-if="stt === '00' && aplySttCdDcd === '2106003'" class="td-text">-</span> <!-- 지급준비중-->
    <span v-else-if="stt === '00' && aplySttCdDcd === '2106006'" class="td-text">{{ sprtAmt > 0 ? numberComma(sprtAmt) : 0 }}</span>
    <span v-else class="td-text">-</span>
    <span v-if="rtnYn=='Y' && addAmtYn=='Y'"><br>(환입 및 추가지원)</span>
    <span v-else-if="rtnYn=='Y' && addAmtYn!='Y'"><br>(환입)</span>
    <span v-else-if="rtnYn!='Y' && addAmtYn=='Y'"><br>(추가지원)</span>
  </td>
</template>

<script>
import {numberComma} from '@/assets/js/util';

export default {
  name: 'CommonTdSprtAmt',
  props: {
    stt: String,
    aplySttCdDcd: String,
    sprtAmt: Number,
    rtnYn: String,
    addAmtYn: String,
  },
  setup() {

    return {
      numberComma
    }
  }
};
</script>