<template>
  <article class="content-section section-my">
    <div class="my-info">
      <div class="avatar"><ProfileImg :target="session"/></div>
      <div class="info-content">
        <h4 class="name">{{ session.lrnerNm }}</h4>
        <p class="team">{{ session.deptNm }}</p>
      </div>
    </div>
    <div class="my-links">
      <router-link :to="{ name:'HistoryHrdCloud' }" class="link kb-btn kb-btn-outline kb-btn-content-item">
        <span class="text">My 클라우드</span><i class="icon-arrow"></i>
      </router-link>
      <a href="javascript:" class="link kb-btn kb-btn-outline kb-btn-content-item" @click="showSubscribed">
        <span class="text">My 구독채널</span><i class="icon-arrow"></i>
      </a>
      <a href="javascript:" class="link kb-btn kb-btn-outline kb-btn-content-item" @click="showAdded">
        <span class="text">My 담은 콘텐츠</span><i class="icon-arrow"></i>
      </a>
    </div>
  </article>
</template>

<script>
import ProfileImg from '@/components/common/ProfileImg';
import {closeSubscribed, showAdded, showSubscribed} from '@/assets/js/modules/hrd/hrd-common';

export default {
  name: 'HrdCloudMobileMy',
  components: {ProfileImg},
  props: {
    session: Object,
    toggleModal: Object
  },setup() {
    return {
      showAdded,
      showSubscribed,
      closeSubscribed
    }
  }
};
</script>