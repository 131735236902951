<template>
  <main class="kb-main">
    <lxp-main-header :show-back="true" :show-custom-title="true" title="상품포트폴리오 제출" />
    <div class="main-content min-component">
      <header class="page-buttons">
        <button class="kb-btn kb-btn-primary" @click="isHrdCloudModalActive=true"><strong class="text"> WM동영상 학습실적</strong></button>
        <template  v-if="adminYn=='Y'">
<!--        <button class="kb-btn kb-btn-primary" @click="isHrdCloudModalActive=true"><strong class="text"> WM동영상 학습실적</strong></button>-->
        <button class="kb-btn kb-btn-primary" @click="isSchduleModalActive=true"><strong class="text">스케줄 등록하기[관리자용]</strong></button>
          <input v-model.trim="searchId" type="text" class="kb-form-input m-3" placeholder="직원번호입력 후 Enter" @keyup.prevent.stop.enter="getPortfolioList" />
        </template>
<!--        <template v-else>-->
<!--          <button class="kb-btn kb-btn-primary" @click="openMsg"><strong class="text"> WM동영상 학습실적</strong></button>-->
<!--        </template>-->
      </header>
      <div class="content-section section-spacing">
        <p v-if="searchInfo!=null && searchId != ''" class="font-body16B color-red">조회대상 : {{searchInfo.lrnerNm}}({{searchInfo.lrnerId}}/{{searchInfo.jbgdNm}}/{{searchInfo.deptNm}})</p>
        <div class="kb-table kb-table-bordered-v2">
          <table>
            <thead>
              <tr>
                <th style="width:80px"><span class="th-title">년도</span></th>
                <th style="width:80px"><span class="th-title">회차</span></th>
                <th><span class="th-title">등록기간</span></th>
                <th><span class="th-title">최초등록일시</span></th>
                <th><span class="th-title">최종제출일시</span></th>
                <th><span class="th-title">포트폴리오</span></th>
              </tr>
            </thead>
            <tbody>
              <template v-if="portfolioList.length > 0">
                <tr v-for="(item, index) in portfolioList" :key="index">
                  <td><span class="td-text">{{item.year}}</span></td>
                  <td><span class="td-text">{{item.seq}}</span></td>
                  <td><span class="td-text">{{dateformat(item.startYmdh+'00')}} ~ {{dateformat(item.endYmdh+'23')}}</span></td>
                  <td><span class="td-text">{{item.regDt}}</span></td>
                  <td><span class="td-text" v-if="item.mdfrDt != null">{{item.mdfrDt}}</span></td>
                  <td>
                    <div class="d-inline-flex" v-if="studentYn=='Y'">
                      <template v-if="today>= item.startYmdh && today <= item.endYmdh && (searchId=='' || (searchId!='' && sessionId == searchId))">
                        <button class="kb-btn kb-btn-sm kb-btn-outline" @click="showPortfolioDtl(item.pfSeq, item.atdDivCd, item.schdlSn)" v-if="item.pfSeq > 0"><strong class="text">수정</strong></button>
                        <button class="kb-btn kb-btn-sm kb-btn-outline" @click="showPortfolioDtl(0, item.atdDivCd, item.schdlSn)" v-else><strong class="text">등록</strong></button>
                      </template>
                      <template v-else>
                        <template v-if="adminYn=='Y' && today <= item.endYmdh && (searchId=='' || (searchId!='' && sessionId == searchId))">
                          <button class="kb-btn kb-btn-sm kb-btn-outline" @click="showPortfolioDtl(item.pfSeq, item.atdDivCd, item.schdlSn)" v-if="item.pfSeq > 0"><strong class="text">TEST수정</strong></button>
                          <button class="kb-btn kb-btn-sm kb-btn-outline" @click="showPortfolioDtl(0, item.atdDivCd, item.schdlSn)" v-else><strong class="text">TEST등록</strong></button>
                        </template>
                      </template>
                      <button class="kb-btn kb-btn-sm kb-btn-outline" @click="showPortfolioResult(item.pfSeq)" v-if="item.scoreYn == 'Y'"><strong class="text">성적</strong></button>
                      <button class="kb-btn kb-btn-sm kb-btn-outline" @click="showPortfolioPrint(item.pfSeq)" v-if="item.pfSeq != 0"><strong class="text">출력</strong></button>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="6"><span class="td-text text-muted">등록 정보가 없습니다.</span></td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </main>
  <ScheduleManage v-if="isSchduleModalActive" v-model="isSchduleModalActive" />
  <PortfolioPage v-if="isPortfolioModalActive"
                 v-model="isPortfolioModalActive"
                 v-model:is-print="isPrint"
                 v-model:pf-seq="pfSeq"
                 :atdDivCd="atdDivCd"
                 :schdlSn="schdlSn"
                 :func="getPortfolioList"
  />
  <PortfolioResult v-if="isPortfolioResultActive"
                   v-model="isPortfolioResultActive"

                   v-model:pf-seq="pfSeq" />
  <CloudContentStudy v-if="isHrdCloudModalActive" v-model="isHrdCloudModalActive" :searchId="searchId" />
</template>

<script>
import {computed, onMounted, ref} from "vue";
import {useStore} from "vuex";
import {getItems, lengthCheck, dateToDateFormat} from "@/assets/js/util";
import LxpMainHeader from '@/components/common/LxpMainHeader';
import ScheduleManage from '@/components/otherdept/wm/ScheduleManage';
import PortfolioPage from '@/components/otherdept/wm/PortfolioPage';
import PortfolioResult from '@/components/otherdept/wm/PortfolioResult';
import CloudContentStudy from '@/components/otherdept/wm/CloudContentStudy';
import {
  ACT_GET_ADMIN_YN,
  ACT_GET_PORTFOLIO_LIST, ACT_GET_STUDENT_YN,
  // MUT_SET_ADMIN_YN
} from "@/store/modules/otherdept/wmportfolio";
import {useAlert} from "@/assets/js/modules/common/alert";
import {useRouter} from "vue-router/dist/vue-router";

export default {
  name: "PortfolioList",
  components: {LxpMainHeader, PortfolioPage, PortfolioResult, ScheduleManage, CloudContentStudy},
  setup() {
    const store = useStore();
    const router = useRouter();

    const { showMessage } = useAlert();
    const today = computed(() => {
      return dateToDateFormat(new Date(), 'yyyyMMddhhmm').substr(0,10);
    });

    const dateformat=(strDate)=>{

      return strDate.substr(0, 4) + '-'+strDate.substr(4, 2)+'-'+strDate.substr(6, 2);

    }

    const adminYn = ref('N');
    const studentYn = ref('N');
    const sessionId = computed(() => store.state.auth.session.lrnerId);

    const isSchduleModalActive = ref(false);
    const isPortfolioModalActive = ref(false);
    const isPortfolioResultActive = ref(false);
    const isHrdCloudModalActive = ref(false);

    const portfolioList = ref([]);
    const pfSeq = ref(null);
    const atdDivCd = ref('');
    const schdlSn = ref(null);
    const isPrint = ref(false);
    const searchId = ref('');
    const searchInfo = ref(null);

    const getPortfolioList = () => {
      store.dispatch(`wmportfolio/${ACT_GET_PORTFOLIO_LIST}`, {lrnerId:searchId.value}).then(res => {
        if (lengthCheck(res)) {
          portfolioList.value = getItems(res);
          searchInfo.value = res.lrner;
        }else{
          portfolioList.value = [];
          searchInfo.value = null;
        }
      }).catch(e => {
        console.error(e);
      });
    };

    const showPortfolioDtl = (data, cd, sn) => {
      pfSeq.value = data;
      atdDivCd.value = cd;
      schdlSn.value = sn;
      isPortfolioModalActive.value = true;
    }

    const showPortfolioResult = (data) => {
      pfSeq.value = data;
      isPortfolioResultActive.value = true;
    }

    const showPortfolioPrint = (data) => {
      pfSeq.value = data;
      isPrint.value = true;
      isPortfolioModalActive.value = true;
    }

    const getStudentyn = () => {
      store.dispatch(`wmportfolio/${ACT_GET_STUDENT_YN}`).then(res => {

          studentYn.value = res.studentYn;

          if (studentYn.value == 'N') {
              showMessage("참여대상이 아닙니다.", () => {
              router.push('/main');
            });
          }
      }).catch(e => {
        console.error(e);
      });
    }

    const getAdminyn = () => {
      store.dispatch(`wmportfolio/${ACT_GET_ADMIN_YN}`).then(res => {
        adminYn.value = res.adminYn;
      }).catch(e => {
        console.error(e);
      });

    }

    const openMsg = () => {
      showMessage("서비스 준비중입니다.");
    }

    const pageInit = async () => {
      await getStudentyn();
      await getAdminyn();
      await getPortfolioList();
    }

    onMounted(() => {

      pageInit();

    })

    return {
      portfolioList,
      isSchduleModalActive,
      isPortfolioModalActive,
      isPortfolioResultActive,
      isHrdCloudModalActive,
      pfSeq,
      isPrint,
      showPortfolioDtl,
      showPortfolioResult,
      showPortfolioPrint,
      today,
      atdDivCd,
      schdlSn,
      adminYn,
      studentYn,
      getPortfolioList,
      dateformat,
      openMsg,
      searchId,
      searchInfo,
      sessionId
    }
  }
}
</script>