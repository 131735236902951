import ApiService from '@/assets/js/api.service';

export const ACT_GET_BANER_LIST = 'getBanerList';
export const ACT_GET_BANER_EXT_URL = 'getBanerExtUrl';

const state = {
};

const mutations = {
};

const actions = {
    [ACT_GET_BANER_LIST](context, params){
        return new Promise(resolve => {
            ApiService.query('/v1/app/baners', params).then(response => resolve(response))
        });
    },
    [ACT_GET_BANER_EXT_URL](context, params){
        return new Promise(resolve => {
            ApiService.query('/v1/app/baners/exturl', params).then(response => resolve(response))
        });
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};