<template>
  <div class="chart-circle" :class="{'chart-circle' : !isMobile, 'graph-circle': isMobile}">
    <div class="circle-bg"></div>
    <div class="circle-active" :style="{'background': `conic-gradient(from ${circleDeg}deg, rgba(255,255,255,0), #fff)`}"></div>
  </div>
</template>
<script>
import {computed} from "vue";
import {getCircleDeg} from "@/assets/js/modules/ssl/ssl-common";
import {useStore} from "vuex";

export default {
  name: 'SSLCircleGraph',
  props: {
    targetCount: Number,
    targetMaxCount: Number
  },
  setup(props) {
    const store = useStore();
    const isMobile = computed(() => store.state.auth.isMobile);
    const circleDeg = computed(() => getCircleDeg(props.targetCount, props.targetMaxCount));
    return {
      isMobile,
      circleDeg
    }
  }
}
</script>
