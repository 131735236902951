<template>
  <div class="header-tab">
    <nav class="kb-nav-round-tabs">
      <ul class="kb-nav-list">
        <li v-for="(menu, idx) in menus" class="kb-nav-item" :key="idx">
          <a href="javascript:" class="kb-nav-link" :class="{ 'is-active' : menu.view === $route.query.view }" @click="changeView(menu.view)"><span class="kb-nav-text">{{ menu.name }}</span></a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import {computed} from 'vue';
import {useRouter} from 'vue-router';

export default {
  name: 'SupportLicenseRoute',
  props: {
    modelValue: String
  },
  emits: ['changeView'],
  setup(props, {emit}) {
    const router = useRouter();
    const menus = computed( ()=> [
        { name: '취득지원 안내', view: 'acquisition' },
        { name: '갱신지원 안내', view: 'renewal' },
        { name: '자격증 시험일정', view: 'schedule' },
        { name: '자격증 보유현황', view: 'history' }
    ]);

    const changeView = (view) => {
      router.push({query: {view: view}});
      emit('changeView', view);
    }

    return {
      menus, changeView
    }
  }
};
</script>