<template>
  <main class="kb-main">
<!--    <div class="main-header">-->
<!--      <div class="header-top main-component">-->
<!--        <div class="header-column"><a href="javascript:" class="page-nav" @click="reSetSslTotalSearch"><i class="icon-kb-nav-arrow"></i><span class="text">이전</span></a>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div class="main-content main-component mt-5">
      <div class="search-container">
        <div class="search-field">
          <div class="kb-form-search">
            <div class="kb-form-search-field">
              <input type="text" class="kb-form-search-input" v-model.trim="keyW" placeholder="학습노트 검색" @keyup.enter="getSocsBoardList">
              <button type="button" class="kb-form-search-btn" @click="getSocsBoardList" ><i class="icon-search">검색</i></button>
<!--              <button type="button" class="kb-form-search-delete" @click="keyword = ''"><i class="icon-delete">삭제</i></button>-->
            </div>
          </div>
        </div>
        <div class="search-result">
          <section class="content-section">

            <div v-if="!isLoading && items.length === 0" class="board-search-wrapper">
              <!-- board-list -->
              <div class="page-content">
                <!-- board-list-container -->
                <div class="board-list-container">
                  <div class="board-list-wrapper">
                    <div class="search-empty">
                      <img src="@/assets/lxp/images/ssl/main/empty_image.png" alt>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <template v-for="(item, idx) in items" :key="idx">
              <div class="board-list-container">
                <!-- board-list-top -->
                <div class="board-list-top" style="padding-left:40px">
                  <div class="top-column"><h3>{{item.distCrseNm}} ({{item.boardCnt}}건)</h3></div>
                </div>
                <div class="board-list-wrapper">
                  <ul class="board-card-type" style="padding-left: 27px;">
                    <template v-for="(socBoard, idx2) in item.socBoards" :key="idx2">
                      <li class="board-item" style="flex: 0 0 19%;margin-left:0"> <!-- :class="{'type-text': !item.thumb}"  -->
                        <SSLBoardCard
                            :item="socBoard"
                            :is-group="getDistCrseCd(socBoard.distCrseSn.toString()) == 'group'"
                            @moveBoard="moveBoard"
                        />
                      </li>
                    </template>
                  </ul>
                </div>
                <!-- //board-list-wrapper -->
<!--                <CommonPaginationFront v-if="paging" :paging="paging" :page-func="pagingFunc" /> &lt;!&ndash; :page-list-size="isMobile ? 5 : 10" &ndash;&gt;-->
              </div>
            </template>
          </section>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {ACT_GET_SOC_BOARD_GROUP_LIST} from "@/store/modules/ssl/ssl-index"; //, BOARD_MODE_KEY
import {getItems, lengthCheck} from "@/assets/js/util"; //, initProgressBar, getPaging
import { ref, onMounted} from "vue"; //, reactive
import {useStore} from "vuex";
import SSLBoardCard from "@/components/ssl/home/SSLBoardCard.vue";
import {getDistCrseCd, goHome} from "@/assets/js/modules/ssl/ssl-common";
// import CommonPaginationFront from "@/components/CommonPaginationFront.vue";
import {useAlert} from "@/assets/js/modules/common/alert";
import {useRoute} from "vue-router";

export default {
  name: 'SSLBoardSearch',
  methods: {getDistCrseCd, goHome},
  components: {SSLBoardCard}, //CommonPagination, FrontSSLBoardItem, , , HrdCloudList
  setup() {
    const store = useStore();
    const {showMessage} = useAlert();
    const isLoading = ref(true);
    const route = useRoute();
    // const isMobile = computed(() => store.state.auth.isMobile);

    const keyW = ref(route.params.keyword);
    const items = ref([]);
    // const paging = reactive({pageNo: 1, pageSize: 14, totalCount: 0}); // , hasMore: false

    const getSocsBoardList = () => {
      if(keyW.value == null || keyW.value == ''){
        showMessage('검색어를 입력해주세요');
        return;
      }
      store.dispatch(`ssl/${ACT_GET_SOC_BOARD_GROUP_LIST}`, {
        title: keyW.value,
        // pageNo: paging.pageNo,
        // pageSize: paging.pageSize
      }).then(res => {
        if(lengthCheck(res)) {
          items.value = getItems(res);
          // paging.totalCount= getPaging(res).totalCount;
          isLoading.value = false;
        }else{
          isLoading.value = false;
          items.value = [];
        }
      }).catch(e => {
        console.error(e);
        isLoading.value = false;
        items.value = [];
      });
    }

    const moveBoard = (socLrnPostSn) => {
      const target = items.value.find(item => item.socLrnPostSn === socLrnPostSn);
      if (target) target.myViewCnt++;

    }

    // const pagingFunc = (pageNo) =>{
    //   paging.pageNo = pageNo;
    //   getSocsBoardList();
    // }

    const reSetSslTotalSearch = ()=>{
      goHome();
    }

    onMounted(() => {
      getSocsBoardList();
    });

    return {
      isLoading,
      items,
      // paging,
      // pagingFunc,
      moveBoard,
      reSetSslTotalSearch,
      getSocsBoardList,
      keyW
    }
  }
}
</script>