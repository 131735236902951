import LxpLayout from "@/views/layout/lxp/LxpLayout";
import LxpMobileLayout from "@/views/layout/lxp/mobile/LxpMobileLayout";
import navigationUtils from "@/assets/js/navigationUtils";

const deptRoutes = [
    {
        path: '/dept',
        component: LxpLayout,
        children: [
            {
                path: 'duty-lrn-stt',
                name: "dutyLrnStatus",
                component: () => import('../../views/pages/dept/DutyLrnStatus'),
            },
        ]
    },
];

const mobiledeptRoutes = [
    {
        path: '/dept',
        component: LxpMobileLayout,
        children: [
            {
                path: 'duty-lrn-stt',
                name: "dutyLrnStatus",
                component: () => import('../../views/pages/dept/mobile/MobileDutyLrnStatus'),
            },
        ]
    },
];

export const setDeptRoutes = (routes) => {
    if (navigationUtils.any()) {
        routes.push(...mobiledeptRoutes);
    } else {
        routes.push(...deptRoutes);
    }

}