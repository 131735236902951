import ApiService from "@/assets/js/api.service";

// ACTION
export const ACT_GET_APPLY_DISTINCT_LIST = 'actGetApplyDistinctList';
export const ACT_GET_APPLY_LIST = 'actGetApplyList';
export const ACT_GET_APPLY_STATUS_LIST = 'actGetApplyStatusList';

const state = {
};

const mutations = {
};

const actions = {
    [ACT_GET_APPLY_DISTINCT_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa010as/distinct', params).then(response => resolve(response))
        });
    },
    [ACT_GET_APPLY_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa010as', params).then(response => resolve(response))
        });
    },

    [ACT_GET_APPLY_STATUS_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa012as', params).then(response => resolve(response))
        });
    },

};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
