<template>
  <div class="myssl-top">
    <div class="top-my">
      <div class="my-avatar">
        <div class="avatar">
          <SSLProfileImg :target="lrner"/>
        </div>
      </div>
      <div class="my-title">
        <h2 class="title">
          <img src="@/assets/lxp/images/ssl/myssl/ic_myssl.svg" alt/>
        </h2>
      </div>

      <div class="my-category" :class="categoryClass">
        <span class="category">{{ mainName }}</span>
      </div>
      <div class="my-name">
        <span class="name" style="margin-right: 5px">{{ lrner.lrnerNm }}</span>
        <span class="name text-muted">{{ lrner.deptNm }}</span>
      </div>
    </div>

    <SSLLearnerRoute/>
  </div>
</template>

<script>
import {getCategoryClass} from '@/assets/js/modules/ssl/ssl-common';
import {useRoute} from 'vue-router';
import {computed} from 'vue';
import SSLLearnerRoute from '@/components/ssl/learner/SSLLearnerRoute';
import SSLProfileImg from '@/components/ssl/common/SSLProfileImg';

export default {
  name: 'SSLLearnerTop',
  props: {
    mainName: String,
    lrner: Object
  },
  components: {
    SSLProfileImg,
    SSLLearnerRoute
  },
  setup() {
    const route = useRoute();
    const categoryClass= computed(() => getCategoryClass(route.params.distCrseCd));

    return {
      categoryClass,
    }


  }
}
</script>
