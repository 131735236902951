<template>
  <article class="popup-section section-form">
    <header class="section-header">
      <h4 class="title">결제정보</h4>
    </header>
    <div class="section-content">
      <div class="kb-form-fields kb-form-fields-v2">
        <div class="kb-form-row">
          <div class="kb-form-column kb-form-column-title">
            <label class="kb-form-label">
              <strong class="kb-form-label-text">결제방법</strong>
            </label>
          </div>
          <div class="kb-form-column">
            <div v-if="!disabled" class="kb-form-group">
              <div v-for="(item, idx) in stlmMthdCdDcdLst" class="kb-form-check kb-form-check-inline" :key="idx">
                <input
                    type="radio" class="kb-form-check-input" name="chk_01"
                    :id="`chk_01_${idx}`"
                    v-model="param.stlmMthdCdDcd"
                    :value="item.cd"
                />
                <label for="chk_01_01" class="kb-form-check-label"><span class="text">{{ item.cdNm }}</span></label>
              </div>
            </div>
            <div v-else class="kb-form-group">
              <div class="kb-form-check kb-form-check-inline">
                <input
                    type="radio" class="kb-form-check-input" name="chk_01" :id="`chk_01`"
                    v-model="param.stlmMthdCdDcd"
                    :value="param.stlmMthdCdDcd"
                    :disabled="disabled"
                />
                <label for="chk_01_01" class="kb-form-check-label"><span class="text">{{ param.stlmMthdNm }}</span></label>
              </div>
            </div>
          </div>
        </div>
        <template v-if="param.stlmMthdCdDcd === stlmMthdCdDcds.STLM_MTHD_CD_CARD">
<!--          <InputSelect-->
<!--              v-model:value1="param.cardKind"-->
<!--              v-model:toggle1="payToggles.cardKinds"-->
<!--              title="카드 종류"-->
<!--              :options1="cardKinds"-->
<!--              sequence-key1="nm"-->
<!--              name-key1="nm"-->
<!--              placeholder1="카드종류를 선택해주세요."-->
<!--              :disabled="disabled"-->
<!--              @update:toggle1="$emit('update:cardKindToggle',payToggles.cardKinds)"-->
<!--          />-->
          <RowInputText v-model="param.cardNo" title="카드번호" :is-text-number="true" placeholder="카드 번호를 입력하세요" :disabled="disabled">
            <template v-slot:last>
              <div class="kb-form-bottomtext">
                반드시 실카드번호를 입력해주세요. (ex. 알파원카드로 결제시 알파원으로 설정된 실카드번호, KB Pay로 결제시 가상카드번호가 아닌 실카드번호)
              </div>
            </template>
          </RowInputText>
          <RowInputText v-model="param.cardAprvNo" title="승인번호" :is-text-number="true" placeholder="승인 번호를 입력하세요" :disabled="disabled" />
          <RowDatepicker
              v-model="param.cardAprvDt"
              v-model:toggle="payToggles.cardAprvDt"
              title="승인 ・ 입금일"
              :disabled="disabled"
              @update:toggle="$emit('update:cardAprvDtToggle',payToggles.cardAprvDt)"
              :width="370"
          />
          <RowInputText v-model.trim="param.frcsNm" title="가맹점명" placeholder="가맹점명을 입력하세요" :disabled="disabled"/>
          <Upload
              v-if="isInit"
              v-model="receiptFiles"
              title="결제 영수증"
              placeholder="카드번호,승인번호, 승인일, 승인금액, 가맹점 정보가 기재된 결제영수증을 등록하세요."
              btn-title="결제 영수증 가져오기"
              :extensions="extensions"
              :max-qty="10"
              sequence-key="proofDataSn"
              row-classes="kb-form-receipt"
              :is-payment="true"
              subtext="*영수증은 본인의 KB카드에 한함"
              :disabled="disabled"
              @update:modelValue="$emit('update:files',receiptFiles)"
          />
        </template>
        <template v-else>
          <RowInputText v-model="param.dpstBnkNm" title="입금은행명" placeholder="입금은행명을 입력하세요" :disabled="disabled"/>
          <RowInputText v-model="param.dpstActno" :is-number="true" title="입금계좌번호" placeholder="입금계좌번호를 입력하세요" :disabled="disabled"/>
          <RowInputText v-model="param.dpstrNm" title="예금주" placeholder="예금주를 입력하세요" :disabled="disabled"/>
          <Upload
              v-if="isInit"
              v-model="receiptFiles"
              title="입금영수증"
              placeholder="입금영수증을 등록하세요."
              btn-title="입금 영수증 가져오기"
              :extensions="extensions"
              :max-qty="10"
              sequence-key="proofDataSn"
              row-classes="kb-form-receipt"
              :is-payment="true"
              subtext=""
              :disabled="disabled"
              @update:modelValue="$emit('update:files',receiptFiles)"
          />
        </template>
      </div>
    </div>
  </article>
</template>

<script>
import {computed, onMounted, reactive, ref, watch} from 'vue';
import {getDcdOptions} from '@/assets/js/ui.init';
import RowInputText from '@/components/support/common/RowInputText';
import {paymentDomain, stlmMthdCdDcds, extensions} from '@/assets/js/modules/support/support-common';
import Upload from '@/components/support/common/Upload';
import RowDatepicker from '@/components/support/common/RowDatepicker';
import {setParams} from '@/assets/js/util';

export default {
  name: 'Payment',
  components: {RowDatepicker, Upload, RowInputText},
  props: {
    modelValue: Object,
    cardKindsToggle: Boolean,
    cardAprvDtToggle: Boolean,
    files: Object,
    disabled: { type:Boolean,  default: false },
  },
  emits: ['update:modelValue', 'update:cardKindToggle', 'update:cardAprvDtToggle', 'update:files'],
  setup(props, {emit}) {
    const isInit = ref(false);
    const stlmMthdCdDcdLst = computed(() => getDcdOptions('2058'));
    const cardKinds = [ { nm:'KB국민 카드' }, { nm:'KB국민 체크카드'} ];
    const param = reactive({...paymentDomain});

    // const extensions = ref(['bmp','gif','jpe','jpeg','jpg','svg', 'png', 'tif']);

    const payToggles = reactive({
      cardKinds: false,
      cardAprvDt: false
    });

    const receiptFiles = ref({ files: [], removed: [], binaries: [] });

    watch(() => param, () => {
      emit('update:modelValue', {...param, cardKind: param.cardKind});
    },{deep:true});

    onMounted(() => {
      setParams(param, props.modelValue);
      receiptFiles.value = props.files;
      isInit.value = true;
    });

    return {
      stlmMthdCdDcdLst, payToggles, cardKinds, receiptFiles,
      stlmMthdCdDcds,
      param, isInit, extensions
    }
  }
};
</script>