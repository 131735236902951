<template>
  <div class="popup-container" :class="{'is-active': active, 'popup-no-padding-container': !isAnswer}">
    <EvlAnswer
        v-if="isAnswer"
        :evl="evl"
        @closeEvl="closeModal"
    />
    <div v-else class="popup eTest-modal-container" id="kb-eTest">
      <EvlMain v-if="isStart"
               :evl="evl"
               :is-end="isEnd"
               :is-mock="isMock"
               :init-dt="initDt"
               :can-take="canTake"
               :current-time="currentTime"
               :current-time-text="currentTimeText"
               :stopEvl="stopEvl"
               @closeEvl="closeModal"
               @endEvl="endEvaluation"
      />
      <EvlIntro v-else
                :evl="evl"
                :is-ready="isReady"
                :is-end="isEnd"
                :can-take="canTake"
                :current-time-text="currentTimeText"
                @closeEvl="closeModal"
                @readyEvl="readyEvaluation"
                @initEvl="initEvaluation"
                @initMockEvl="initMockEvaluation"
      />
      </div>
      <div v-if="scoreToggle" class="kb-alert-container is-active" id="alert-score">
        <div class="kb-alert">
          <div class="kb-alert-contents">
            <strong class="kb-alert-title">평가종료</strong>
<!--            <p class="kb-alert-score">{{ score }}점</p>-->
            <p class="kb-alert-text">종료시간 이전에 비정상 종료된 경우 F5키를 눌러 <br>다시 참여하실 수 있습니다.</p>
          </div>
          <div class="kb-alert-actions">
            <button class="kb-btn-alert" @click="closeModal"><span class="text">확인</span></button>
          </div>
        </div>
      </div>
  </div>
</template>

<script>


import EvlMain from '@/components/evaluation/EvlMain';
import EvlIntro from '@/components/evaluation/EvlIntro';
import EvlAnswer from '@/components/evaluation/EvlAnswer';
import {learnEvlSetup} from '@/assets/js/modules/learn/learn-evl-setup';

export default {
  name: 'LearnEvaluation',
  components: {EvlAnswer, EvlIntro, EvlMain},
  props: {
    active: Boolean,
    evl: Object,
    isAnswer: Boolean
  },
  emits: ['update:active', 'renewInit', 'renewEnd'],
  setup: learnEvlSetup
}
</script>
