import BlankLayout from "@/views/layout/BlankLayout";
import navigationUtils from "@/assets/js/navigationUtils";

import LinkCertification from "@/views/pages/link/LinkCertification";
import MobileLinkCertification from "@/views/pages/link/mobile/MobileLinkCertification";

const isMobile = navigationUtils.any();

const linkRoutes = [
    {
        path: '/link',
        component: BlankLayout,
        children: [
            {
                path: 'certification',
                name: 'MyPageCertification',
                component: isMobile ? MobileLinkCertification : LinkCertification
            }
        ]
    }
];

export const setLinkRoutes = (routes) => {
    routes.push(...linkRoutes);
}