<template>
  <LxpMobileHeader title="내가 공유한 노트">
    <template v-slot:left>
      <div class="util util-back">
        <a href="javascript:" class="util-actions util-actions-back" @click="goBack">
          <i class="icon-history-back"></i>
        </a>
      </div>
    </template>
  </LxpMobileHeader>
  <main class="kb-main" id="kb-facility">
    <!-- main-header -->
    <!-- //main-header -->
    <!-- main-content -->
    <div class="main-content">
    </div>

    <div class="main-content main-component">
      <div class="view-contents">


        <section class="content-section">
          <header class="section-header">
            <div class="title-list">
              <h4 class="title is-active"></h4>
            </div>
            <div class="sub-desc">
              <p class="total">총 {{ paging.totalCount }}건</p>
            </div>
          </header>
          <div class="section-content">
            <template v-if="!isLoading && (items && items.length > 0)">
              <div v-for="(item,idx) in items" class="content-item history-list" :key="idx">
                <a href="javascript:" @click="goBoardView(item.distCrseSn, item.lrnTrgtGrpMstSn, item.socLrnPostSn);">
                  <div class="content-item-header">
                    <h5 class="item-title">[{{ item.distCrseNm }}]</h5>
                  </div>
                  <div class="meta-content text-gold">{{ item.title }} ({{ item.lrnerNm }}<span v-if="item.deptNm!=''">/{{item.deptNm}}</span>)</div>
                  <div class="meta-content">
                    <span class="td-text">{{ item.shareLrnInfo}} 공유</span>
                    <span class="td-text" style="float: right"><strong>{{timestampToDateFormat(item.regDt, 'yyyy.MM.dd')}} &nbsp;＞</strong></span>
                  </div>
                  <div class="meta-content text-gold"></div>
                </a>
              </div>
              <p class="text-muted">※ 내가 공유한 노트수는 본인의 학습노트 공유건은 제외하고 합산합니다.</p>
              <div v-if="paging.hasMore" class="content-item">
                <div class="kb-btn-more-container">
                  <button class="kb-btn-content-item kb-btn-primary" @click.stop="moreFunc(paging)">더보기</button>
                </div>
              </div>
            </template>
            <div v-else-if="!isLoading && (items && items.length === 0)" class="search-container">
              <div class="result-empty">
                <img src="../../../../assets/lxp/images/common/img_empty.png" alt="">
                <p class="text">해당 데이터가 없습니다.</p>
              </div>
            </div>
            <LoadingDiv v-if="isLoading"/>
          </div>
        </section>

      </div>
    </div>
  </main>

</template>



<script>
import {onMounted, reactive, ref} from 'vue';
import { ACT_GET_SHARE_LIST} from "@/store/modules/ssl/ssl-index";
import {getDistCrseCd} from "@/assets/js/modules/ssl/ssl-common";
import {useStore} from "vuex";
import {getItems, getPaging, lengthCheck, timestampToDateFormat} from "@/assets/js/util";
import { useRouter} from "vue-router";
import LxpMobileHeader from "@/views/layout/lxp/header/mobile/LxpMobileHeader.vue";
import LoadingDiv from "@/components/common/LoadingDiv.vue";

export default {
  name: "SSLMobileActFeed",
  components: {LoadingDiv, LxpMobileHeader},
  setup() {
    const store = useStore();
    const router = useRouter();
    const isLoading = ref(true);
    const items = ref([]);

    const paging = reactive(
        {pageNo: 1, pageSize: 20, totalCount: 0, hasMore: false}
    );

    const getList=(isInit)=>{
      if(isInit) {
        items.value = [];
        paging.pageNo = 1;
      }

      store.dispatch(`ssl/${ACT_GET_SHARE_LIST}`, paging).then(res => {

        if (lengthCheck(res)) {
          items.value.push(...getItems(res));
          paging.totalCount = getPaging(res).totalCount;
          paging.hasMore = getPaging(res).hasMore;
          isLoading.value = false;

          if(isInit){
            window.scrollTo(0, 0);
          }
        }else{
          items.value = [];
          isLoading.value=false;
        }

      });
    }
    const goBack = () => {
      router.go(-1);
    }

    const goBoardView =(distCrseSn, lrnTrgtGrpMstSn, socLrnPostSn)=>{
      router.push({name: 'SSLView', params: {distCrseCd:getDistCrseCd(distCrseSn.toString()), lrnTrgtGrpMstSn:lrnTrgtGrpMstSn, socLrnPostSn: socLrnPostSn}}).then(() => {});
    };

    const moreFunc = (paging) => {
      paging.pageNo++;
      getList(false);
    };

    onMounted(()=>{
      getList(true);
    });

    return{
      items,
      paging,
      isLoading,
      goBoardView,
      goBack,
      timestampToDateFormat,
      moreFunc
    }
  }
}

</script>