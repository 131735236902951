<template>
  <div class="post-list" :class="[isSpecial() && 'special']" :data-read="refinedItem.myViewCnt > 0 ? 'active' : ''">
    <router-link :to="getPath(refinedItem)" class="post-link" @click="moveBoard">
      <section class="left">
        <div class="post-image-wrap">
          <template v-if="isSpecial()">
            <div class="post-line">
              <img src="@/assets/lxp/mobile/images/ssl/update/special_line.svg" alt="">
            </div>
            <div class="post-badge">
              <img src="@/assets/lxp/mobile/images/ssl/update/crown.gif" alt="">
            </div>
          </template>

          <div class="post-image">
            <v-lazy-image v-if="refinedItem.thumb" :src="refinedItem.thumb" />
            <div class="read">
              <i class="icon-read"></i>읽음
            </div>
          </div>
        </div>
      </section>
      <section class="right">
        <div class="post-list-desc top">
          <div class="post-title">
            <h3>{{ refinedItem.title }}</h3>
          </div>
          <div class="post-desc">
            <p class="text"></p>
          </div>
        </div>
        <div class="post-list-desc bottom">
          <div>
            <p class="post-date">
              {{ refinedItem.ymd }}
            </p>
            <p class="view-text">
              <i class="icon-view"></i>
              <span class="views">{{ refinedItem.inqcnt }}</span>
            </p>
            <p class="view-text">
              <i class="icon-like"></i>
              <span class="views">{{ refinedItem.likeCnt }}</span>
            </p>
          </div>
          <div class="post-profile">
            <div class="author">
              <span class="name">{{ refinedItem.lrnerNm }}</span><span class="office">{{ isGroup ?  refinedItem.refTyNm : refinedItem.deptNm }}</span>
            </div>
          </div>
        </div>
      </section>
    </router-link>
  </div>
</template>
<script>

import {useRoute} from "vue-router";
import {dateToDateFormat, timestampToDateFormat} from '@/assets/js/util';
import {computed} from "vue";
import VLazyImage from "v-lazy-image";
import {getTargetDt, getDistCrseCd} from '@/assets/js/modules/ssl/ssl-common';
import {useStore} from 'vuex';

export default {
  name: 'SSLMobileCard',
  methods: {getDistCrseCd},
  components:{
    VLazyImage
  },
  props: {
    item: {
      required: true,
      type: Object
    },
    isGroup: Boolean
  },
  emits: ['moveBoard'],
  setup(props, {emit}) {
    const store = useStore();
    const route = useRoute();
    const excellentTargets = computed(() => store.state.ssl.excellents.map(x => x.lrnerId+"-"+x.lrnTrgtGrpMstSn));
    const excellentDay = computed(() => store.state.ssl.excellents[0] ? store.state.ssl.excellents[0].regDt : null);

    const refinedItem = computed(() => {
      return {
        ...props.item,
        ymd: timestampToDateFormat(getTargetDt(props.item), 'yyyy.MM.dd')
      }
    });


    const getPath = (item) => {
      if(item.stt === '01'){
        return {name: 'SSLWrite', params: {socLrnPostSn: item.socLrnPostSn}};
      }else{
        if(route.name === 'SSLMyBoard'){
          if(timestampToDateFormat((item.pblcnDt ? item.pblcnDt : item.regDt), 'yyyy-MM-dd') === dateToDateFormat(new Date(), 'yyyy-MM-dd')){
            return {name: 'SSLWrite', params: {socLrnPostSn: item.socLrnPostSn}};
          }else{
            return {name: 'SSLView', params: {socLrnPostSn: item.socLrnPostSn}};
          }
        }else{
          if (route.name=== 'SSLHome' || route.name === 'SSLBoardSearch'){
            return {name: 'SSLView', params: {distCrseCd:getDistCrseCd(item.distCrseSn.toString()), lrnTrgtGrpMstSn:item.lrnTrgtGrpMstSn, socLrnPostSn: item.socLrnPostSn}};
          }else {
            return {name: 'SSLView', params: {socLrnPostSn: item.socLrnPostSn}};
          }
        }
      }
    }

    const isSpecial = () => {
      return excellentTargets.value.includes(props.item.lrnerId+"-"+props.item.lrnTrgtGrpMstSn) && ((props.item.pblcnDt ? props.item.pblcnDt : props.item.regDt) >= excellentDay.value);
      // return excellentTargets.value.includes(props.item.lrnerId) && timestampToDatePart((props.item.pblcnDt ? props.item.pblcnDt : props.item.regDt)).ymd > halfYmd;
    }

    const moveBoard = () => {
      emit('moveBoard', props.item.socLrnPostSn);
    }


    return {
      refinedItem,
      getPath,
      isSpecial,
      moveBoard
    }
  }
}
</script>
