<template>
  <div class="contents">
    <SSLMobileBoardList
        :is-loading="isLoading"
        :items="items"
        :paging="paging"
        :more-func="moreFunc"
    />
  </div>
</template>

<script>
import SSLMobileBoardList from '@/components/ssl/main/mobile/SSLMobileBoardList';
import {sslMyBoardSetup} from '@/assets/js/modules/ssl/setup/ssl-my-setup';

export default {
  name: 'SSLMobileMyBoard',
  components: {
    SSLMobileBoardList,
  },
  props: {
    lrnTrgtGrpMstSn: Number
  },
  setup: sslMyBoardSetup,
};
</script>
