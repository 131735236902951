<template>
  <img v-if="badgeSrc" :src="badgeSrc" alt="" class="icon" :class="{'disActive': !isActive}">



<!--    <template v-else>-->
<!--      <img v-if="isActive" src="@/assets/lxp/images/ssl/badges/ssl_badge_none.svg" alt="" class="icon">-->
<!--      <img v-else src="@/assets/lxp/images/ssl/badges/ssl_badge_none_deactive.svg" alt="" class="icon">-->
<!--    </template>-->
</template>
<script>

import {computed} from 'vue';

export default {
  name: 'SSLBadgeTwo',
  props: {
    badgeTyCdDcd: String,
    isActive: Boolean,
    hiddenYn: String
  },
  setup(props) {
    const badgeSrc = computed(() => {
      if (props.badgeTyCdDcd) {
        if (props.hiddenYn === 'Y' && !props.isActive) {
          return require(`@/assets/lxp/images/questionmark.svg`);
        } else {
          return require(`@/assets/images/ssl/badge/active/${props.badgeTyCdDcd}.svg`);
        }
      }
      return null;
    });
    return {
      badgeSrc
    }
  }
}
</script>
<style scoped>
.disActive {filter:grayscale(1)}
.icon {width:104px;height:104px}
</style>