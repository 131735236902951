<template>
  <main class="kb-main" id="kb-prep">
    <lxp-main-header v-if="!isMobile" :show-visitor-cnt="true" >
      <template v-slot:inner>
        <div class="header-column">
          <router-link :to="{name: 'SSLMain', params: {distCrseCd: 'knowledge', lrnTrgtGrpMstSn: 112121}}"  class="page-nav">
            <i class="icon-kb-nav-arrow"></i>
            <span class="text">지식e러닝 홈</span>
          </router-link>
        </div>
      </template>
    </lxp-main-header>
    <!-- main-content -->
    <div class="main-content" :class="{ 'min-component' : !isMobile }">
        <div class="mypage-my">
          <div class="my-avatar">
            <div class="image">
              <ProfileImg
                  :target="session"
                  sub-title="지식e러닝"
              />
            </div>
          </div>
          <div class="my-content">
            <h3 class="title"><i class="icon-title-my-test"></i></h3>
            <h4 class="subtitle">지식e러닝</h4>
            <p class="content"><span class="text">{{ session.lrnerNm }}</span> <span class="text text-muted">{{ session.deptNm }}</span></p>
          </div>
          <div class="my-links">
            <router-link :to="{name: 'SSLMy', params: {distCrseCd: 'knowledge', lrnTrgtGrpMstSn: 112121}}" class="link"><i class="icon-my-ssl"></i></router-link>
            <a href="javascript:" class="link is-active"><i class="icon-my-test"></i></a>
          </div>
        </div>
      <!-- content-section:학습 현황 -->

      <section class="content-section section-status">
          <header class="section-header">
            <h4 class="title">학습 현황</h4>
          </header>
          <div class="roundedbox-wrap roundedbox-low-xs">
            <!-- roundedbox -->
            <div class="roundedbox">
              <div class="roundedbox-top">
                <div class="top-row">
                  <h5 class="roundedbox-title">연간평가 참여횟수</h5>
                </div>
              </div>
              <div class="roundedbox-bottom">
                <span class="roundedbox-text">{{ participationCount }}/{{ evaluationTotalCount }}회</span>
              </div>
            </div>
            <!-- roundedbox -->
            <div class="roundedbox">
              <div class="roundedbox-top">
                <div class="top-row">
                  <h5 class="roundedbox-title">평가 총점</h5>
                </div>
              </div>
              <div class="roundedbox-bottom">
                <span class="roundedbox-text">{{ Math.round(evlSum) }}점</span>
              </div>
            </div>
            <!-- roundedbox -->
            <div class="roundedbox">
              <div class="roundedbox-top">
                <div class="top-row">
                  <h5 class="roundedbox-title">평가 평균점수</h5>
                </div>
              </div>
              <div class="roundedbox-bottom">
                <span class="roundedbox-text">{{(evlSum > 0 && avgCalCount > 0) ? Math.round(evlSum/avgCalCount) : 0}}점</span>
              </div>
            </div>
          </div>
        </section>
      <!-- content-section:학습자료 -->
        <section class="content-section section-divider">
          <header class="section-header">
            <h4 class="title">학습자료</h4>
            <router-link :to="{name: 'HrdCloud', query:{sortTyCdDcd: '2073001',category1:'1',category2:'14',category3:'114',category4:'348',category5:'1584' }}" class=" kb-btn kb-btn-primary kb-btn-md rounded-xs" ><span class="aplytext">AI강의 보러가기</span><i class="icon-arrow-next"></i></router-link>
          </header>
          <div class="textbook-list-container">
            <div class="textbook-list-nav swiper-nav js-swiper-kb-nav-textbook">
              <button class="kb-btn-nav kb-btn-swiper-prev kb-btn-data-swiper-prev"><i class="icon-arrow-prev"></i></button>
              <button class="kb-btn-nav kb-btn-swiper-next kb-btn-data-swiper-next"><i class="icon-arrow-next"></i></button>
            </div>
            <div class="textbook-list-swiper swiper">
              <swiper
                  :slides-per-view="4"
                  :navigation="{
                    nextEl: '.kb-btn-data-swiper-next',
                    prevEl: '.kb-btn-data-swiper-prev'
                  }"
                  class="textbook-list"
              >
                <swiper-slide v-for="(item, idx) in dItems" :key="idx" class="textbook-item">
                  <article class="textbook-card">
                    <div class="textbook-image">
                      <a href="javascript:" class="image-link" @click="openContent(item)"></a>
                      <div class="image">
                        <v-lazy-image v-if="item.thumb" :src="getThumbUrl(item.thumb)" @error="setDefaultImg($event, idx)" />
                        <template v-else>
                          <v-lazy-image v-if="idx % 4 === 0" :src="jisikeImages[0]" @error="setDefaultImg($event, idx)"  />
                          <v-lazy-image v-else-if="idx % 4 === 1" :src="jisikeImages[1]"  @error="setDefaultImg($event, idx)" />
                          <v-lazy-image v-else-if="idx % 4 === 2" :src="jisikeImages[2]"  @error="setDefaultImg($event, idx)" />
                          <v-lazy-image v-else-if="idx % 4 === 3" :src="jisikeImages[3]"  @error="setDefaultImg($event, idx)" />
                        </template>
                      </div>
                    </div>
                    <div class="textbook-content">
                      <div class="content-title">
                        <p class="subtitle">{{ timestampToDateFormat(item.objBgngDt, 'yyyy.MM.dd') }}</p>
                        <h4 class="title"><a href="javascript:" class="title-link" @click="openContent(item)">{{item.objNm}}</a></h4>
                      </div>
                    </div>
                  </article>
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </section>
      <!-- content-section:진단평가 -->
        <section v-if="evlTestEnabled" class="content-section section-divider">
          <header class="section-header">
            <h4 class="title">모의테스트</h4>
          </header>
          <div class="status-tablelist">
            <div class="kb-table kb-table-bordered assessment-tablelist">
              <table>
                <colgroup>
                  <col style="width:400px;">
                  <col style="width:auto;">
                  <col style="width:120px;">
                  <col style="width:160px;">
                </colgroup>
                <tbody>
                <template v-if="eItems.length > 0">
                  <template v-for="(item, idx) in eItems" :key="idx">
                    <tr v-if="item.lrnEvlMstSn === 4888">
                      <td><strong class="td-text">{{ item.lrnEvlNm }}</strong></td>
                      <td><strong class="td-text text-muted">{{timestampToDateFormat(item.evlBgngDt, 'full-kr')}} {{timestampToDateFormat(item.evlBgngDt,'hh:mm')}}-{{timestampToDateFormat(item.evlEndDt, 'full-kr')}} {{timestampToDateFormat(item.evlEndDt,'hh:mm')}}</strong></td>
                      <td>
                        <strong v-if="item.fnshYn === 'Y'" class="td-text text-primary">참여완료</strong>
<!--                        <strong v-else-if="item.comntYn === 'Y' && item.evlBgngDt <= currentTime.getTime() && item.evlEndDt >= currentTime.getTime()" class="td-text text-primary">참여중</strong>-->
                        <strong v-else-if="item.comntYn === 'Y' && item.evlBgngDt <= currentTime && item.evlEndDt >= currentTime" class="td-text text-primary">참여중</strong>
<!--                        <strong v-else-if="item.comntYn === 'N' && item.evlEndDt < currentTime.getTime()" class="td-text text-muted">미참여</strong>-->
                        <strong v-else-if="item.comntYn === 'N' && item.evlEndDt < currentTime" class="td-text text-muted">미참여</strong>
                      </td>
                      <td class="text-center">
<!--                        <button v-if="item.evlBgngDt <= currentTime.getTime() && item.evlEndDt >= currentTime.getTime()" class="kb-btn kb-btn-primary" @click="setTargetEvaluation(idx, 'E')" :disabled="btnDisabled"><span class="text">평가하기</span></button>-->
                        <button v-if="item.evlBgngDt <= currentTime && item.evlEndDt >= currentTime" class="kb-btn kb-btn-primary" @click="setTargetEvaluation(idx, 'E')" :disabled="btnDisabled"><span class="text">평가하기</span></button>
                      </td>
                    </tr>
                  </template>
                </template>
                <template v-else>
                    <tr>
                        <td colspan="4" style="text-align: center"><strong class="td-text text-muted">지식e러닝 입과자가 아닙니다.</strong></td>
                    </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
        </section>
        <section class="content-section section-divider" :class="{'mt-10' : !evlTestEnabled, 'mt-0' : evlTestEnabled}">
          <header class="section-header">
            <h4 class="title">진단평가<span class="text text-muted">{{evaluationTotalCount}}</span></h4>
          </header>
          <div class="status-tablelist">
            <div class="kb-table kb-table-bordered assessment-tablelist">
              <table>
                <colgroup>
                  <col style="width:400px;">
                  <col style="width:auto;">
                  <col style="width:120px;">
                  <col style="width:120px;">
                  <col style="width:160px;">
                </colgroup>
                <tbody>
                <template v-if="eItems.length > 0">
                  <template v-for="(item, idx) in eItems" :key="idx">
                    <tr v-if="item.lrnEvlMstSn !== 4888">
                      <td><strong class="td-text">{{ item.lrnEvlNm }}</strong></td>
                      <td><strong class="td-text text-muted">{{timestampToDateFormat(item.evlBgngDt, 'full-kr')}} {{timestampToDateFormat(item.evlBgngDt,'hh:mm')}}-{{timestampToDateFormat(item.evlEndDt,'hh:mm')}}</strong></td>
                      <td>
<!--                        <span v-if="item.evlRlsDt <= currentTime.getTime() && item.qstnSumScore >= 0 && item.comntYn === 'Y'" class="td-text">{{ `${item.qstnSumScore}점` }}</span>-->
                        <span v-if="item.evlRlsDt <= currentTime && item.qstnSumScore >= 0 && item.comntYn === 'Y' && item.fnshPrcsRsnCd !=='01'" class="td-text">{{ `${item.qstnSumScore}점` }}</span>
                        <span v-else class="td-text">-</span>
                      </td>
                      <td>
                        <strong v-if="item.fnshYn === 'Y'" class="td-text text-primary">참여완료</strong>
<!--                        <strong v-else-if="item.comntYn === 'Y' && item.evlBgngDt <= currentTime.getTime() && item.evlEndDt >= currentTime.getTime()" class="td-text text-primary">참여중</strong>-->
                        <strong v-else-if="item.comntYn === 'Y' && item.evlBgngDt <= currentTime && item.evlEndDt >= currentTime" class="td-text text-primary">참여중</strong>
<!--                        <strong v-else-if="item.comntYn === 'N' && item.evlEndDt < currentTime.getTime()" class="td-text text-muted">미참여</strong>-->
                        <strong v-else-if="item.comntYn === 'N' && item.evlEndDt < currentTime" class="td-text text-muted">미참여</strong>
                        <strong class="text" v-else>-</strong>
                      </td>
                      <td class="text-center">
                        <button v-if="item.fnshYn === 'Y'" class="kb-btn kb-btn-secondary" @click="setTargetEvaluation(idx, 'A')"><span class="text">답안지</span></button>
<!--                        <button v-else-if="item.evlBgngDt >= currentTime.getTime() || item.evlEndDt > currentTime.getTime()" class="kb-btn kb-btn-primary" @click="setTargetEvaluation(idx, 'E')" :disabled="btnDisabled"><span class="text">평가하기</span></button>-->
                        <button v-else-if="item.evlBgngDt >= currentTime || item.evlEndDt > currentTime" class="kb-btn kb-btn-primary" @click="setTargetEvaluation(idx, 'E')" :disabled="btnDisabled"><span class="text">평가하기</span></button>
                      </td>
                    </tr>
                  </template>
                </template>
                <template v-else>
                    <tr>
                        <td colspan="5" style="text-align: center"><strong class="td-text text-muted">지식e러닝 입과자가 아닙니다.</strong></td>
                    </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
        </section>
      <!-- content-section:학습일정 -->
        <section class="content-section section-divider mt-0">
          <header class="section-header">
            <h4 class="title">학습일정</h4>
            <div class="header-sub">
              <div class="datepicker-labels">
                <div class="label"><i class="label-mark mark-primary"></i><span class="label-text">학습자료 제공일</span></div>
                <div class="label"><i class="label-mark mark-red"></i><span class="label-text">평가일</span></div>
              </div>
            </div>
          </header>
          <KnowledgeELearningCalendar />
        </section>
    </div>
    <!-- //main-content -->
  </main>
  <LearnEvaluation
      v-if="windowActive"
      :evl="targetEvl"
      v-model:active="windowActive"
      :is-answer="windowOptions.answer"
      @renewInit="renewInitEvl"
      @renewEnd="renewEndEvl"
  />
</template>
<style scoped>
@import '../../../assets/custom/ssl.custom2.css';
</style>
<script>
import LxpMainHeader from '@/components/common/LxpMainHeader';
import {computed, onMounted, reactive, ref, watch} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {useStore} from 'vuex';
import {getItems, isSuccess, lengthCheck, timestampToDateFormat, winOpen} from '@/assets/js/util';
import {ACT_GET_KNOWLEDGE_LRN_OBJ_DTL_DIST_LIST} from '@/store/modules/course/course';
import {
  ACT_GET_EVL_SYNC_DT,
  ACT_GET_KNOWLEDGE_LRN_TY_EVL_DIST_LIST,
  ACT_INSERT_LRN_TY_EVL_DIST,
} from '@/store/modules/evaluation/evaluation';
import ProfileImg from '@/components/common/ProfileImg';
import KnowledgeELearningCalendar from '@/components/prep/KnowledgeELearningCalendar';
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import VLazyImage from 'v-lazy-image';
import LearnEvaluation from '@/components/learn/window/LearnEvaluation';
import {getThumbUrl} from '@/assets/js/modules/course/course-common';
import navigationUtils from '@/assets/js/navigationUtils';
import {useAlert} from '@/assets/js/modules/common/alert';
import {Navigation} from 'swiper';
import { debounce } from 'lodash-es';

export default {
  name: 'KnowledgeELearning',
  components: {
    LearnEvaluation,
    KnowledgeELearningCalendar,
    ProfileImg,
    VLazyImage,
    LxpMainHeader,
    Swiper,
    SwiperSlide
  },
  setup() {
    const activeMenu = ref('learningHome');

    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const dItems = ref([]);
    const eItems = ref([]);

    const evaluationTotalCount = ref(0);
    const participationCount = ref(0);
    const avgCalCount = ref(0);

    const session = computed(() => store.state.auth.session);
    // const currentTime = ref(new Date());
    const currentTime = ref(null);
    const evlSum = ref(0);

    const modelEvlValue = ref(false);

    const {showMessage, showLoading, hideLoading} = useAlert();

    const windowActive = ref(false);
    const windowOptions = reactive({
      answer: false
    });

    const toggles = reactive({
      nav: false
    });

    const target = reactive({
      toggle: false,
      index: -1
    });

    const targetEvl = computed(() => {
      if(eItems.value[target.index]){
        return eItems.value[target.index]
      }
      return {}
    });

    const learnSn = computed(() => parseInt(route.query.learn || 0));
    const btnDisabled = ref(false);
    const evlTestEnabled = ref(false);

    const setTargetEvaluation = debounce(async (index, type) => {
      btnDisabled.value = true;
      showLoading();
      target.index = index;

      await store.dispatch(`evaluation/${ACT_GET_EVL_SYNC_DT}`, {
      }).then(res => {
        if(isSuccess(res)){
          // console.log('setTargetEvaluation',timestampToDateFormat(res.srvSyncDt, 'yyyy.MM.dd hh:mm:ss'));
          currentTime.value = res.srvSyncDt;
        }
      });

      if(targetEvl.value.fnshYn === 'Y' && targetEvl.value.lrnEvlMstSn !== 4888){
        // if (new Date().getTime() < (targetEvl.value.evlRlsDt)) {
        if (currentTime.value < (targetEvl.value.evlRlsDt)) {
          showMessage('답안지는 평가전체종료 10분후에 공개됩니다.');
          btnDisabled.value = false;
          hideLoading();
          return false;
        }
      } else {
        let basTime = targetEvl.value.evlBgngDt;
        // if (new Date().getTime() < basTime) {
        if (currentTime.value < basTime) {
          showMessage('평가응시 가능시간이 아닙니다.');
          btnDisabled.value = false;
          hideLoading();
          return false;
        }
      }

      if(type === 'A') {
        windowOptions.answer = true;
      }
      else {
        // if(targetEvl.value.lrnTyEvlDistSn === 0 || (targetEvl.value.lrnEvlMstSn === 4888 && targetEvl.value.fnshYn === 'Y')){
        if(targetEvl.value.lrnTyEvlDistSn === 0){
          await store.dispatch(`evaluation/${ACT_INSERT_LRN_TY_EVL_DIST}`, {
            distCrseSn: targetEvl.value.distCrseSn,
            lrnEvlMstSn: targetEvl.value.lrnEvlMstSn,
            lrnRsltSn: targetEvl.value.lrnRsltSn
          }).then(res => {
            if(isSuccess(res)){
              targetEvl.value.lrnTyEvlDistSn = res.lrnTyEvlDistSn;
              if (targetEvl.value.lrnEvlMstSn === 4888) {
                modelEvlValue.value = true;
              }
              getEvaluateList();
            }
          });
        }
      }
      setTimeout(() => {
        target.toggle = true;
        windowActive.value = true;
        btnDisabled.value = false;
        hideLoading();
      }, 225);
    }, 500);

    const renewInitEvl = (evlBgngDt) => {
      eItems.value[target.index].evlExamBgngDt = evlBgngDt;
    }

    const renewEndEvl = () => {
      // eItems.value[target.index].evlExamEndDt = new Date().getTime();
      eItems.value[target.index].evlExamEndDt = currentTime.value;
      modelEvlValue.value = true;
      windowActive.value = false;
      getEvaluateList();
    }

    const jisikeImages = [
        require('../../../assets/lxp/images/ssl/myssl/@img_jisike.png'),
        require('../../../assets/lxp/images/ssl/myssl/@img_jisike_02.jpg'),
        require('../../../assets/lxp/images/ssl/myssl/@img_jisike_03.jpg'),
        require('../../../assets/lxp/images/ssl/myssl/@img_jisike_04.jpg')
    ];
    const setDefaultImg = (e, idx) => {
        const imageIndex = idx % 4;
        e.src = jisikeImages[imageIndex];
    };

    const getKnoewledgeLearningList = async () => {
      await store.dispatch(`course/${ACT_GET_KNOWLEDGE_LRN_OBJ_DTL_DIST_LIST}`, {}).then(res => {
        if (lengthCheck(res)) {
          dItems.value = getItems(res);
        } else {
          dItems.value = [];
        }
      }).catch(e => {
        console.error(e);
      });

      await store.dispatch(`evaluation/${ACT_GET_EVL_SYNC_DT}`, {
      }).then(res => {
        if(isSuccess(res)){
          // console.log('getKnoewledgeLearningList',timestampToDateFormat(res.srvSyncDt, 'yyyy.MM.dd hh:mm:ss'));
          currentTime.value = res.srvSyncDt;
        }
      });
    }

    const getEvaluateList = async () => {
      await store.dispatch(`evaluation/${ACT_GET_EVL_SYNC_DT}`, {
      }).then(res => {
        if(isSuccess(res)){
          // console.log('getEvaluateList',timestampToDateFormat(res.srvSyncDt, 'yyyy.MM.dd hh:mm:ss'));
          currentTime.value = res.srvSyncDt;
        }
      });
      
      await store.dispatch(`evaluation/${ACT_GET_KNOWLEDGE_LRN_TY_EVL_DIST_LIST}`).then(res => {
        evlSum.value = 0;
        participationCount.value = 0;
        avgCalCount.value = 0;
        if (lengthCheck(res)) {
          evaluationTotalCount.value = res.items.filter(x => x.lrnEvlMstSn!==4888).length;
          const target = getItems(res).
              map((x, i) => ({...x, objectIndex: i})).
              find(x => learnSn.value === x.lrnObjDtlDistSn);
          eItems.value = getItems(res);

          participationCount.value = eItems.value
              .filter(x => x.fnshYn === 'Y' && x.lrnEvlMstSn!==4888)
              .length;
          eItems.value.forEach(item=>{
            // if (item.evlRlsDt <= currentTime.value.getTime() && item.comntYn === 'Y' && item.lrnEvlMstSn!==4888) {
            if (item.evlRlsDt <= currentTime.value && item.comntYn === 'Y' && item.lrnEvlMstSn!==4888) {
              if(item.fnshPrcsRsnCd !== '01'){

                evlSum.value += item.qstnSumScore;
                avgCalCount.value++;

              }
            }
            if (item.evlBgngDt <= currentTime.value && item.evlEndDt >= currentTime.value && item.lrnEvlMstSn === 4888) {
              evlTestEnabled.value = true;
            }
          });

          if (learnSn.value > 0 && !modelEvlValue.value) {
            if(target) {
              // if (target.evlRlsDt <= currentTime.value.getTime() && target.comntYn === 'Y') {
              if (target.evlRlsDt <= currentTime.value && target.comntYn === 'Y') {
                setTargetEvaluation(target.objectIndex, 'A');
              } else {
                // if (target.evlBgngDt <= currentTime.value.getTime() && target.evlEndDt > currentTime.value.getTime()) {
                if (target.evlBgngDt <= currentTime.value && target.evlEndDt > currentTime.value) {
                  setTargetEvaluation(target.objectIndex, 'E');
                }
              }
            }
          }

        } else {
          eItems.value = [];
        }
      }).catch(e => {
        console.error(e);
      })
    }

    // const currentDate = ref(new Date());

    const openContent = (item) => {
      // if(item.objBgngDt >= currentDate.value.getTime() || item.objEndDt <= currentDate.value.getTime()) {
      if(item.objBgngDt >= currentTime.value || item.objEndDt <= currentTime.value) {
        showMessage('해당 학습자료 다운로드 가능기간이 아닙니다.<br/>화면 하단 학습일정을 확인하세요.');
      } else {
        // store.dispatch(`learn/${ACT_GET_KNOWLEDGE_E_LEARN_OBJECT}`, {lrnObjDtlDistSn: item.lrnObjDtlDistSn,fileName: item.fileNm});
        winOpen(`${process.env.VUE_APP_CLOUDFRONT_URL}/${item.etnUrl}`);
      }
    }

    onMounted(() => {
      if(route.query.type === undefined || route.query.type === 'learningHome'){
        activeMenu.value = 'learningHome'
        getKnoewledgeLearningList();
      }else if(route.query.type === 'evaluate'){
        activeMenu.value = 'evaluate'
        getEvaluateList();
      }
      getEvaluateList();
    });

    watch(() => activeMenu.value, () => {
      const query = {type: activeMenu.value}
      router.push({query: query})
    });

    watch(()=> route.query, () => {
      if(activeMenu.value === undefined || activeMenu.value === 'assetMng') {
        getKnoewledgeLearningList();
      }else{
        getEvaluateList();
      }
    });

    const goBack = () => {
      if (router.options.history.state.back) {
        router.go(-1);
      }
    };

    const goKnowledge = () =>{
      router.push({name: 'KnowledgeELearning'});
    };

    return {
      session,
      activeMenu,
      route,
      dItems,
      eItems,
      evaluationTotalCount,

      target,
      targetEvl,
      windowActive,
      windowOptions,
      currentTime,
      evlSum,
      participationCount,

      jisikeImages,
      setTargetEvaluation,
      renewInitEvl,
      renewEndEvl,

      setDefaultImg,
      timestampToDateFormat,
      openContent,
      getThumbUrl,
      isMobile: !!navigationUtils.any(),
      modules: [Navigation],
      goBack,
      goKnowledge,
      toggles,
      avgCalCount,
      btnDisabled,
      evlTestEnabled
    };
  },
};
</script>