<template>
  <div class="page-container">
    <div class="board-view-container">
      <div class="board-view">
        <div class="board-view-header view-component">
          <img v-if="socBoard.thumb" :src="socBoard.thumb" alt="">
          <div class="blur"></div>
          <div class="page-header-wrap">
            <div>
              <h2 class="title">{{ socBoard.title }}</h2>
              <p class="page-editor">{{ session.lrnerNm }}<span> ・ </span>{{ session.deptNm }}</p>
            </div>
            <div class="page-info">
              <span class="date">{{ todayDate }}</span>&nbsp;&nbsp;|&nbsp;&nbsp;<span class="views">{{ socBoard.inqcnt }}</span>&nbsp;조회
            </div>
          </div>
        </div>

        <div class="board-view-body view-component" style="margin-top: 32px;">
          <div class="editor-area">
            <div class="editor-contents" v-html="currentPage.cn"></div>
          </div>
          <SSLViewRel
              :hash-tags="hashTags"
              :ref-url="socBoard.refUrl"
              :ref-post="socBoard.refPost"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {computed, ref} from "vue";
import {dateToDateFormat} from "@/assets/js/util";
import {useStore} from "vuex";
import SSLViewRel from "@/components/ssl/view/SSLViewRel";

export default {
  name: 'SSLMobilePreview',
  components: {SSLViewRel},
  props: {
    socBoard: Object,
    currentPageIdx: Number,
    socPages: Array,
    hashTags: Array
  },
  setup(props) {
    const store = useStore();
    const session = computed(() => store.state.auth.session);

    const currentPage = computed(() => {
      if(props.socPages.length > 0 && props.socPages[props.currentPageIdx]){ return props.socPages[props.currentPageIdx]; }
      return {
        title: '',
        cn: ''
      };
    });

    const todayDate = ref(dateToDateFormat(new Date(), 'yyyy.MM.dd'));
    return {
      session,
      currentPage,
      todayDate
    }
  }
}
</script>
