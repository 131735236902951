<template>
  <div v-if="show" class="popup-container" :class="{ 'is-active' : show }">
    <div class="popup" id="popup-hrdcolud-h0211">
      <div class="popup-inner">
        <form action="">
          <header class="popup-header">
            <h3 class="title">HRD 콘텐츠 담기</h3>
          </header>
          <div class="popup-content">
            <div class="popup-section section-01">
              <div class="kb-form-fields field-01">
                <div class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <span class="kb-form-label-text">콘텐츠명</span>
                    </label>
                  </div>
                  <div class="kb-form-column">
                    <div class="kb-form-text">{{ objNm }}</div>
                  </div>
                </div>
                <div class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <span class="kb-form-label-text">구분</span>
                    </label>
                  </div>
                  <div class="kb-form-column">
                    <div class="kb-form-group">
                      <div class="kb-form-check kb-form-check-inline" v-if="showRoadmapOption">
                        <input v-model="roadmap" type="checkbox" class="kb-form-check-input" id="chk_01_03" :disabled="isRoadmapSaved" />
                        <label for="chk_01_03" class="kb-form-check-label"><span class="text">로드맵 공모</span></label>
                      </div>
                      <div class="kb-form-check kb-form-check-inline">
                        <input v-model="put" type="checkbox" class="kb-form-check-input" id="chk_01_01" :checked="put" />
                        <label for="chk_01_01" class="kb-form-check-label"><span class="text">MY 콘텐츠</span></label>
                      </div>
                      <div class="kb-form-check kb-form-check-inline" v-if="showGlPutOption">
                        <input v-model="glPut" type="checkbox" class="kb-form-check-input" id="chk_01_02" :checked="glPut" :disabled="isPdf" />
                        <label for="chk_01_02" class="kb-form-check-label"><span class="text" :class="{'text-muted': isPdf}">그룹러닝 콘텐츠</span></label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="kb-form-row" v-if="roadmap">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <span class="kb-form-label-text">로드맵 구분</span>
                    </label>
                  </div>
                  <div class="kb-form-column kb-form-dropdown-group">
                    <div class="kb-form-dropdown" :class="{'is-active':roadmapToggle.cate}">
                      <button class="kb-form-dropdown-btn" @click.stop.prevent="roadmapToggleChange('cate')">
                        <span class="text" v-if="roadmapParam.hrdCloudRoadmapCateSn > 0">{{roadmapParam.hrdCloudRoadmapCateNm}}</span>
                        <span class="text" v-else>분야 선택</span>
                        <i class="icon-arrow" />
                      </button>
                      <div class="kb-form-dropdown-options">
                        <div class="dropdown-option">
                          <ul class="dropdown-option-list">
                            <li v-for="(item, idx) in roadmapCates" :key="item.hrdCloudCateSn" class="dropdown-option-item">
                              <a class="dropdown-option-link" :class="{'is-active': roadmapParam.hrdCloudRoadmapCateSn == item.hrdCloudCateSn}" @click.stop.prevent="setRoadmapParam(idx,'cate')">
                                <span class="dropdown-option-text">{{item.hrdCloudCateNm}}</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="kb-form-dropdown" :class="{'is-active':roadmapToggle.level}">
                      <button class="kb-form-dropdown-btn" @click.stop.prevent="roadmapToggleChange('level')">
                        <span class="text" v-if="roadmapParam.hrdCloudRoadmapCateLevel > 0">{{roadmapParam.hrdCloudRoadmapCateLevelNm}}</span>
                        <span class="text" v-else>난이도 선택</span>
                        <i class="icon-arrow" />
                      </button>
                      <div class="kb-form-dropdown-options">
                        <div class="dropdown-option">
                          <ul class="dropdown-option-list">
                            <li v-for="(item, idx) in hrdCloudRoadmapCateLevels" :key="item.level" class="dropdown-option-item">
                              <a class="dropdown-option-link" :class="{'is-active': roadmapParam.hrdCloudRoadmapCateLevel == item.level}" @click.stop.prevent="setRoadmapParam(idx,'level')">
                                <span class="dropdown-option-text">{{item.label}}</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p class="text-muted pt-3">
                ※ 그룹러닝 콘텐츠 담기는 동영상, 전산실습(실습모드)만 가능합니다.(PDF 제외)
              </p>
            </div>
          </div>
          <div class="popup-buttons">
            <a href="javascript:" class="kb-btn kb-btn-outline kb-btn-lg" @click="closeHrdLeaderAdd"><span class="text">취소</span></a>
            <a href="javascript:" class="kb-btn kb-btn-primary kb-btn-lg" @click="handlePut"><span class="text">확인</span></a>
          </div>
        </form>
      </div>

      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeHrdLeaderAdd"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
  </div>
</template>

<script>
import {closeHrdLeaderAdd} from '@/assets/js/modules/hrd/hrd-common';
import {hrdLeaderAddSetup} from '@/assets/js/modules/hrd/hrd-leader-add-setup';

export default {
  name: 'HrdCloudLeaderAddModal',
  methods: {closeHrdLeaderAdd},
  setup: hrdLeaderAddSetup
};
</script>