<template>
  <div class="sbadge text-center">
    <template v-if="showTooltip">
      <Popper
        :content="badgeData.badgeDesc"
        class="badge-tooltip-dark"
        hover
      >
        <div>
          <div class="sbadge-icon">
            <span v-if="isNew" class="badge-new"></span>
            <SSLBadgeTwo
                :badge-ty-cd-dcd="badgeTyCdDcd"
                :is-active="isActive"
                :hidden-yn="hiddenYn"
            />
          </div>
          <div v-if="showTitle" class="sbadge-content">
            <strong class="title" style="white-space: normal; word-break:break-all" :title="badgeData.badgeName">{{ badgeData.badgeName }}</strong>
          </div>
        </div>
      </Popper>
    </template>
    <template v-else>
      <div class="sbadge-icon">
        <span v-if="isNew" class="badge-new"></span>
        <SSLBadgeTwo
            :badge-ty-cd-dcd="badgeTyCdDcd"
            :is-active="isActive"
            :hidden-yn="hiddenYn"
        />
      </div>
      <div v-if="showTitle" class="sbadge-content">
        <strong class="title" style="white-space: normal; word-break:break-all" :title="badgeData.badgeName">{{ badgeData.badgeName }}</strong>
      </div>
    </template>

  </div>
</template>
<script>
import Popper from 'vue3-popper';
import SSLBadgeTwo from '@/components/ssl/badge/SSLBadgeTwo';

export default {
  name: 'SSLBadgeTitle',
  components:{
    SSLBadgeTwo,
    Popper
  },
  props: {
    badgeTyCdDcd: String,
    isActive: Boolean,
    isNew: Boolean,
    showTitle: Boolean,
    showTooltip: Boolean,
    hiddenYn: String,
    badgeData: Object
  },
  setup() {
    return {
    }
  }
}
</script>
