import store from '../../store';
import router from '../../router';
import {
  getCheckItems,
  getItem,
  getItems,
  lengthCheck,
  setParams,
} from '@/assets/js/util';
import {
  editorConfig,
  editorCss,
  inAppEditorConfig,
  mobileEditorCss,
} from '@/assets/js/editor/editor-config';
import ClassicEditor from '@/assets/js/editor/custom-ckeditor';
import UploadAdapter from '@/assets/js/editor/upload-adapter';

const getDefaultValue = (value, defaultValue) => {
  return defaultValue !== undefined ? defaultValue : value;
};

export const getValueByQuery = (query, key, isNumber, defaultValue) => {
  if(query && query[key]) {
    try {
      return isNumber ? parseInt(query[key]) : query[key];
    } catch (e) {
      return isNumber ? getDefaultValue(0, defaultValue) : getDefaultValue('', defaultValue);
    }
  }
  return isNumber ? getDefaultValue(0, defaultValue) : getDefaultValue('', defaultValue);
};

export const setFilterByQuery = (filter, query, nums) => {
  Object.keys(filter).forEach(k => {
    if(nums){
      filter[k] = getValueByQuery(query, k, nums[k]);
    }else{
      filter[k] = getValueByQuery(query, k);
    }
  });
};

const getQuery = (query) => {
  if(query){
    const result = {};
    Object.keys(query).forEach(k => {
      if(query[k]) result[k] = query[k];
    });
    return result;
  }
  return {};
};

export const pushQuery = (query) => {
  router.push({query: getQuery(query)}).then();
};

/***
 * Store 에 저장된 Action 공통 호출, List 로 반환
 * @params type (String)
 * @params payload (Object)
 * @params items (Reactive API)
 * @params paging (Object)
 * @params callback (Function)
 * @return String
 ***/
export const getListFunc = (type, payload, items, paging, callback, catchCallback) => {
  store.dispatch(type, payload)
  .then(res => {
    if(items && items.value) items.value = getCheckItems(res);
    if(paging) paging.totalCount = res && res.paging ? res.paging.totalCount : 0;
    if(callback instanceof Function) callback(res);
  }).catch(e => {
    console.error(e);
    if(catchCallback instanceof Function) catchCallback();
  });
};

export const getFunc = (type, payload, params, callback) => {
  store.dispatch(type, payload)
  .then(res => {
    const isLen = lengthCheck(res);
    if(isLen) setParams(params, getItem(res));
    if(callback instanceof Function) callback(isLen);
  }).catch(e => {
    console.error(e);
  });
};

export const getListMoreFunc = (type, payload, items, paging, isLoading, isInit, callback, catchCallback) => {
  if (isInit) items.value = [];
  paging.hasMore = false;
  store.dispatch(type, payload)
  .then(res => {
    if(lengthCheck(res)){
      items.value.push(...getItems(res));
    }
    if(paging) {
      paging.totalCount = res && res.paging ? res.paging.totalCount : 0;
      paging.hasMore = res && res.paging ? res.paging.hasMore : false;
    }
    if(callback instanceof Function) callback(res);
    isLoading.value = false;
  }).catch(e => {
    console.error(e);
    if(catchCallback instanceof Function) catchCallback();
    isLoading.value = false;
  });
};

export const getDcdOptions = (dcd) => {
  return store.state.code.codes.filter(x => x.cd && x.cd.indexOf(dcd) === 0 && x.mstYn === 'N');
};

export const closeToggles = (toggles, key) => {
  Object.keys(toggles).forEach(k => {
    if(k !== key){
      toggles[k] = false
    }
  });
};

export const datePartToFormat = ({year, month, day}, delimiter) => {
  if(!delimiter) delimiter = '';
  return `${year}${delimiter}${('0' + month).slice(-2)}${delimiter}${('0' + day).slice(-2)}`;
}


export const releaseArr = (arr, result, getFunc) => {
  if(arr && arr.length > 0){
    arr.forEach(a => {
      result.push(getFunc(a));
      releaseArr(a.children, result, getFunc);
    });
  }
}

const setEditorInIframe = (pageEditor, editorIframe) => {
  editorIframe.contentDocument.body.innerHTML = '<div id="editor"></div>'
  editorIframe.contentDocument.head.innerHTML =
      editorCss.map(x => ('<style type="text/css">' + x + '</style>')).join('') + '<style type="text/css">' + mobileEditorCss + '</style>';
  pageEditor = editorIframe.contentDocument.getElementById('editor');
}

const setPageEditor = (pageEditor, tryCount, callback, isInApp, endpoint ) => {
  if(tryCount > 10) return;
  if(pageEditor){
    ClassicEditor.create( pageEditor, (isInApp ? inAppEditorConfig : editorConfig) )
    .then(editor => {
      editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
        return new UploadAdapter( loader, endpoint );
      };
      if(callback instanceof Function) callback(editor);
    })
    .catch( error => {
      console.error( error );
    });

  }else{
    tryCount++;
    setTimeout(() => {
      setPageEditor();
    }, 200);
  }
}

export const initEditor = (pageEditor, editorIframe, tryCount, callback, isInApp, endpoint) => {
  if(isInApp) setEditorInIframe(pageEditor, editorIframe);
  setPageEditor(pageEditor, tryCount, callback, isInApp, endpoint);
}


export const convertCloudFrontUrl = (itn, item, itnKey, etnKey) => {
  let src = itn ? item[itnKey] : item[etnKey];
  if(src) {
    if (src.indexOf('http') > -1) {
      return src;
    } else {
      if (src.indexOf('/') !== 0) src = `/${src}`;

      if (item.buketNm === 'pea.hrd.aws.prd.s3.contents') {
        src = 'https://lxpcms.kbstar.com' + src;
      } else if (item.buketNm === 'pea.hrd.aws.dev.s3.contents') {
        src = 'https://devlxpcms.kbstar.com' + src;
      }
    }
  }
  return src;
}

function _s4() {
  return ((1 + Math.random()) * 0x10000 | 0).toString(16).substring(1);
}

export function guid(len) {
  if(len > 0) {
    const share = len/4;
    const uuids = [];
    for(let i = 0; i < (share + 1); i++) {
      uuids.push(_s4());
    }
    return uuids.join('').substring(0, len);
  }
  return '';
}
