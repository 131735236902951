<template>
  <main class="kb-main" id="kb-prep">
    <div class="main-header">
      <div class="header-top main-component">
        <div class="header-column">
          <a href="javascript:" class="page-nav" @click="goBackHrd">
            <i class="icon-kb-nav-arrow"></i>
            <span class="text">이전</span>
          </a>
        </div>
        <div class="header-column">
          <button class="kb-btn-mode" @click="toggleConcentrationMode">
            <span class="text">{{ concentrationModeText }}</span>
            <i class="icon-mode"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="main-content main-component">
      <div class="board-view-container min-component">
        <div class="view-body">
          <div v-if="hrd && (hrd.title || hrd.desc)" class="chat-container">
            <ul class="chat-messages">
              <li class="incoming-message message">
                <div class="message-content">
                  <template v-if="hrd.title">
                    <div class="message-author">
                      <div class="author-avatar">
                        <img src="../../../assets/lxp/images/common/avatar_01.svg">
                      </div>
                      <span class="author-name">실습 시나리오</span>
                    </div>
                    <div class="message-bubble">
                      <p class="text" v-html="hrd.title" />
                    </div>
                  </template>
                  <template v-if="hrd.desc">
                    <!-- [!DEV] message-bubble is-active 영역 확장 활성화 클래스 -->
                    <div class="message-bubble is-active">
                      <!-- [!DEV] collapse is-active 상세보기 활성화 -->
                      <div class="message-collapse kb-collapse" :class="{'is-active':toggleScenario}">
                        <div class="message-collapse-toggle kb-collapse-toggle">
                          <a href="javascript:" class="kb-btn-toggle" @click="toggleScenario=!toggleScenario">
                            <span class="text">상세 시나리오</span>
                            <strong class="text">{{ toggleScenario ? '접기' : '열기' }} <i class="icon-arrow"></i></strong>
                          </a>
                        </div>
                        <div class="message-collapse-target kb-collapse-target kb-scenario-wrapper">
                          <div class="kb-collapse-contents" v-html="hrd.desc"></div>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </li>
            </ul>
          </div>
          <div class="position-relative" style="overflow-x: clip;">
            <div class="player">
              <HrdCloudRoadmapPlayerButton :crse="hrd" />
            </div>
          </div>
          <div class="segment-box mt-3 rounded-lg" style="height:1080px;">
            <iframe v-if="hrd"
                ref="iframeViewer"
                :src="hrd.etnUrl"
                :data-lrner-id="session.lrnerId"
                width="100%"
                height="100%"
                title=""
            />
          </div>
        </div>
        <div class="view-details">
          <HrdPlayerDetails v-if="hrd" :hrd="hrd" :funcs="detailFuncs" />
        </div>
        <div class="view-comment">
          <HrdCloudComment v-if="hrd" :effect-nav="effectNav" :objSn="hrd.objSn" />
        </div>
      </div>
    </div>

    <HrdCloudStopAlert v-if="scoreBoard.zeroScoreDisplay"
        v-model="scoreBoard.zeroScoreDisplay"
        title="전산실습Easy 학습 안내"
        type="easy"
        :func="scoreBoard.toggle">
      <template v-slot:content>
        <p class="text text-sm">가이드에서 제시된 실습이 아닌 <span style="color:red;">비정상 실습진행</span>으로<br>
          <span style="color:red;">학습완료 및 마일리지가 인정되지 않을 수 있습니다. 정상적인 방법으로 실습을 진행 해 주세요</span></p>
      </template>
    </HrdCloudStopAlert>


    <HrdCloudStopAlert v-if="scoreBoard.zeroScoreAbort"
        v-model="scoreBoard.zeroScoreAbort"
        title="전산실습Easy 학습 안내"
        type="easyAbort"
        :func="scoreBoard.reStart">
      <template v-slot:content>
        <p class="text"><span style="color:red;">비정상 실습진행</span>으로 더이상 학습하실 수 없습니다.<br>
          처음부터 다시 정상적인 방법으로 실습을 진행 해 주세요</p>
      </template>
    </HrdCloudStopAlert>
    <LxpMessage v-model="localMessage.show" :options="localMessage" />
    <HrdCloudHistory v-if="hrd && historyModal" v-model="historyModal" :title="hrd.objNm" :duration="hrd.cttSz" :items="histories" :func="showLatestHistory" :ctt-ty-cd-dcd="hrd.cttTyCdDcd" />
    <HrdCloudHistoryDtlModal v-if="latestHistoryModal" v-model="latestHistoryModal" v-model:lrn-obj-dtl-dist-sn="lrnObjDtlDistSn" :year="new Date().getFullYear()" :month="new Date().getMonth()+1" />
    <HrdCloudShareModal v-if="hrd && shareModal" v-model="shareModal" :data="hrd" />
    <HrdCloudOpinionModal v-if="hrd && opinionModal" v-model="opinionModal" :cttMstSn="hrd.objSn" :title="hrd.objNm" />

    <HrdCloudLrnComplete v-model="completeModal"  v-model:hrdObj="hrd"/>
  </main>
</template>
<style scoped>
.lxp-layout .kb-scenario-wrapper {
  overflow-y: unset !important;
}
</style>
<script>
import {goBack} from '@/assets/js/modules/common/common';
import {getItem, getVideoUrl, lengthCheck, setParams, stringCheck} from '@/assets/js/util';
import {computed, onBeforeUnmount, onMounted, reactive, ref} from 'vue';
import {useStore} from 'vuex';
import HrdCloudComment from '@/components/hrdcloud/HrdCloudComment';
import HrdCloudShareModal from '@/components/hrdcloud/HrdCloudShareModal';
import {
  darkMode,
  eventCdDcds,
  generateUUID,
  // getTop1CategoryByLevel,
  getUserProgress,
  handleActions,
  insertVodLearnProgress,
  lightMode,
  // makeTags,
  pageView,
  toggleConcentrationMode,
} from '@/assets/js/modules/hrd/hrd-common';
import {getToken} from '@/assets/js/localstorage.service';
import {TOKEN_KEY} from '@/store/modules/auth/auth';
import HrdCloudOpinionModal from '@/components/hrdcloud/HrdCloudOpinionModal';
import {useRoute, useRouter} from 'vue-router';
// import {ACT_GET_HRDCLOUD_DETAIL} from '@/store/modules/prep/prep';
import HrdCloudLrnComplete from '@/components/hrdcloud/HrdCloudLrnComplete.vue';
import HrdPlayerDetails from '@/components/hrdcloud/pages/HrdPlayerDetails.vue';
import HrdCloudHistory from '@/components/hrdcloud/HrdCloudHistory.vue';
import HrdCloudHistoryDtlModal from '@/components/history/HrdCloudHistoryDtlModal.vue';
import HrdCloudRoadmapPlayerButton from '@/components/hrdcloud/HrdCloudRoadmapPlayerButton';
import {useAlert} from '@/assets/js/modules/common/alert';
import HrdCloudStopAlert from "@/components/hrdcloud/HrdCloudStopAlert.vue";
import LxpMessage from "@/components/common/LxpMessage.vue";

export default {
  name: 'HrdScorm',
  components: {
    LxpMessage,
    HrdCloudStopAlert,
    HrdCloudHistoryDtlModal,
    HrdCloudHistory,
    HrdPlayerDetails, HrdCloudLrnComplete, HrdCloudOpinionModal, HrdCloudShareModal, HrdCloudComment, HrdCloudRoadmapPlayerButton,
  },
  props: {
    objDetail: Object,
    effectNav: {
      required: false,
      type: Number,
      default: 0
    },
  },
  setup(props) {
    const store = useStore(), route = useRoute(), {hideLoading, showLoading} = useAlert(),
          isMobile = computed(() => store.state.auth.isMobile),
          session = computed(() => store.state.auth.session),
          targetMode = computed(() => process.env.NODE_ENV === 'production' ? "PRD" : "DEV");
    const concentrationModeText = computed(() => store.state.hrdcloud.mode === 'dark' ? '집중모드 켜짐' : '집중모드 꺼짐');
    showLoading();
    const lrnObjDtlDistSn = computed(() => route.params.lrnObjDtlDistSn),
          mode = computed(() => route.params.mode || 'demo'),
          shareModal = ref(false), historyModal = ref(false), opinionModal = ref(false), latestHistoryModal = ref(false),
          iframeViewer = ref(null),
          coldcall = ref(false),
          user = reactive({ progRsltSn: 0, maxPlaySec: 0, lastPlaySec: 0, cmptnYn: 'N', mdfcnDt: null, regDt: null }),
          token = getToken(TOKEN_KEY),
          completeModal = ref({
            show: false,
            feedback: false
          });
    const toggleScenario = ref(false);
    const hrd = computed(() => props.objDetail);
    const histories = ref([]);
    const uuid = ref(null);
    // const completeActive = ref(true);

    const updatingLog = ref(false);

    const scoreBoard = reactive({
      zeroScoreCnt:0,
      totCumScore:0,
      totCurScore:0,
      zeroScoreDisplay:false,
      zeroScoreAbort:false,
      toggle: (bool) =>{
        scoreBoard.zeroScoreDisplay = bool;
      },
      reStart: () => {
        location.reload();
      }
    });

    const getUUID = async () => {
      let id = null;
      if (!uuid.value) {
        id = await generateUUID();
        if (id && id.uuid) uuid.value = id.uuid;
      } else {
        uuid.value = id.uuid;
      }
    };

    const getEasyUrl = (data) => {
      if (data) {
        const playSrc = getVideoUrl(session.value.itn, data);
        let queryString = `&kbtoken=${encodeURIComponent(token)}&lrnerId=${encodeURIComponent(session.value.lrnerId)}&progSesnKey=${encodeURIComponent(uuid.value)}&distCrseSn=${encodeURIComponent(data.distCrseSn)}&lrnObjDtlDistSn=${encodeURIComponent(data.lrnObjDtlDistSn)}&objSn=${encodeURIComponent(data.objSn)}&mode=${mode.value}&targetMode=${targetMode.value}`;
        if (stringCheck(playSrc)) {
          const src = `${playSrc}:${mode.value}${queryString}`;
          return src;
        }
      }
      return null;
    };

    const getHrdCloudDetail = async () => {

      pageView(hrd);
      await getUUID();
      hrd.value.etnUrl = getEasyUrl(hrd.value);
      const userProgressRes = await getUserProgress(hrd);
      if(lengthCheck(userProgressRes)) setParams(user, getItem(userProgressRes));
      hideLoading();

      // store.dispatch(`prep/${ACT_GET_HRDCLOUD_DETAIL}`, {lrnObjDtlDistSn: lrnObjDtlDistSn.value}).then(async (res) => {
      //   if (res && res.items.length > 0) {
      //     hrd.value = res.items.map(x => {
      //       return {
      //         ...getTop1CategoryByLevel(x.cloudKeyLst),
      //         ...x,
      //         tag: makeTags(x.tag)
      //       };
      //     })[0];
      //     pageView(hrd);
      //   }
      //
      //   await getUUID();
      //
      //   hrd.value.etnUrl = getEasyUrl(hrd.value);
      //
      //   const userProgressRes = await getUserProgress(hrd);
      //   if(lengthCheck(userProgressRes)) setParams(user, getItem(userProgressRes));
      //   hideLoading();
      // }).catch(e => {
      //   console.error(e);
      //   hideLoading();
      // });
    };


    const localMessage = reactive({
      show: false,
      center: true,
      title: '',
      text: '학습이 종료되었습니다. 실습과정에서 60%이상 득점하지 못한 경우 학습완료 되지 않습니다.',
      callback: null
    });

    const showMessage = () => {
      localMessage.show = true;
    };

    const receiveMessage = (event) => {

      //lxp, lxpcms 도메인 이외는 무시  cdn.ntruss.com ncloud CDN 추가
      if(event.origin.indexOf("lxp")<0 && event.origin.indexOf("cdn.ntruss.com")<0 && event.origin.indexOf("localhost:8081")<0)
      {
        console.log("not allow domain", event.origin);
        return;
      }

      //localhost 호출 filter
      if(event.data.source=="vue-devtools-backend")return;

      //중복로깅 방지 (callback 확인 후 허용)
      if(updatingLog.value){
        setTimeout(()=>{
          console.log('recall');
          receiveMessage(event)
        }, 1000);
        return;
      }

      try {
        if (event && event.data) {

          try{
            let msg = JSON.parse(event.data);
            console.log("message receive", msg);

            updatingLog.value = true;
            if (msg.complete) {
              if (mode.value === 'uebung') {
                insertVodLearnProgress(hrd.value, {
                  progSesnKey: uuid.value,
                  lastPlaySec: msg.lastPlaySec,
                  playSec: msg.playSec,
                  playSpeed: 1,
                  eventCdDcd: eventCdDcds.Ended,
                  eventNm: '학습종료',
                  currIndex: msg.currIndex,

                },(res) => {

                  updatingLog.value = false;
                  console.log("result", res);
                  // console.log('1 : ',res);
                  console.log("complete" , res.complete);
                  // console.log(completeActive.value);
                  if (res.complete) {
                    completeModal.value.show = true;
                    // completeActive.value = false;
                    if(res.feedbackEnable){
                      completeModal.value.feedback = true;
                    }
                  }else{
                    console.log("complete fail", res);
                    showMessage();
                  }
                });
              }
            }else{


              if (mode.value === 'uebung') {
                insertVodLearnProgress(hrd.value, msg, ()=>{
                  console.log('update fin');
                  updatingLog.value = false;
                });
              }


              //무작위 click 방지 매 3회 경고, 17회에 중단
              if(msg.curScore!=undefined){
                if(msg.curScore==0){
                  let alertLog = false;
                  scoreBoard.zeroScoreCnt++;
                  let limitCnt = Math.floor(msg.totalPageCnt * 0.3);
                  limitCnt = limitCnt<7 ? 7 : limitCnt;

                  console.log("zero", limitCnt, scoreBoard.zeroScoreCnt);

                  if(limitCnt<=scoreBoard.zeroScoreCnt ){
                    scoreBoard.zeroScoreAbort = true;
                    alertLog = true;
                  }
                  else if(scoreBoard.zeroScoreCnt%3==0){
                    scoreBoard.zeroScoreDisplay = true;
                    alertLog = true;
                  }

                  if (alertLog){

                    msg.eventCdDcd = "2064005";
                    msg.evnetNm = "중단알림 전송";
                    msg.playSec = 0;

                    insertVodLearnProgress(hrd.value, msg, ()=>{
                      console.log('중단알림 발송');
                      updatingLog.value = false;
                    });
                  }


                }
              }

            }
          }
          catch (e) {
            // console.log("message parsing fail", e.message, event.data);
          }

        }
        else{
          console.warn("not data", event);
        }
      } catch (e){
        // POST MESSAGE FAILED
        console.error('logerror : ',e);
      }
    };

    const router = useRouter();
    const fromQuery = computed(() => store.state.common.fromQuery);
    const goBackHrd = () => {
      router.push({name: 'HrdCloud', query: fromQuery.value || {}});
    }

    const detailFuncs = {
      openHistory: (item) => {
        handleActions(item).actHistory(historyModal, histories);
      },
      openShare: (item) => {
        handleActions(item).actShare(shareModal);
      },
      openOpinion: (item) => {
        handleActions(item).actOpinion(opinionModal);
      }
    };

    const showLatestHistory = () => {
      latestHistoryModal.value = true;
    };

    getHrdCloudDetail();

    onMounted(() => {
      darkMode();

      window.addEventListener('message', receiveMessage);
      console.log("add eventlistener!!");
    });

    onBeforeUnmount(() => {
      lightMode();
      window.removeEventListener('message',receiveMessage);
    });

    return {
      isMobile, coldcall, hrd, histories, lrnObjDtlDistSn, concentrationModeText,
      goBackHrd, goBack, getVideoUrl, handleActions, showLatestHistory,
      detailFuncs,
      session, iframeViewer,
      shareModal, historyModal, opinionModal, completeModal, latestHistoryModal,
      toggleScenario, toggleConcentrationMode, scoreBoard, localMessage
    }
  }
};
</script>