<template>

  <!-- begin:popup-container -->
  <div class="popup-container" :class="{'is-active' : modelValue}">
    <!-- popup -->
    <div class="popup" id="popup-subscription-settings">
      <!-- popup > popup-inner -->
      <div class="popup-inner">
        <div>
          <!-- popup-inner > popup-header -->
          <header class="popup-header">
            <h3 class="title">내가 공유한 노트</h3>
          </header>


          <div class="popup-content">

            <LoadingDiv v-if="isLoading"/>
            <section class="popup-section mt-5">

              <div class="kb-table kb-table-bordered">
                <table>
                  <colgroup>
<!--                    <col style="width:8%;">-->
                    <col style="width:10%;">
                    <col style="width:auto;">
                    <col style="width:18%;">
                    <col style="width:20%;">
                    <col style="width:13%;">
                  </colgroup>
                  <tr>
<!--                    <th><span class="th-title">공유횟수</span></th>-->
                    <th><span class="th-title">과정명</span></th>
                    <th><span class="th-title">노트명</span></th>
                    <th><span class="th-title">작성자</span></th>
                    <th><span class="th-title">공유직원</span></th>
                    <th><span class="th-title">공유일자</span></th>
                  </tr>
                  <tbody>
                  <tr v-for="(item, idx) in items" :key="idx">
<!--                    <td class="text-overf">{{paging.totalCount-item.sortNum+1}}</td>-->
                    <td class="text-overf">{{item.distCrseNm}}</td>
                    <td class="text-overf">{{item.title}}</td>
                    <td class="text-overf">{{item.lrnerNm}}<span v-if="item.deptNm != ''">({{item.deptNm}})</span></td>
                    <td class="text-overf">{{item.shareTrgtLrnNmLst}}</td>
                    <td class="text-right"><a href="javascript:" @click="goBoardView(item.distCrseSn, item.lrnTrgtGrpMstSn, item.socLrnPostSn);">{{timestampToDateFormat(item.regDt, 'yyyy.MM.dd')}}<i class="icon-arrow-next"></i></a></td>
                  </tr>
                  <tr v-if="items && items.length === 0 && !isLoading" class="search-container">
                    <td colspan="5" class="result-empty">
                      <img src="../../../assets/lxp/images/common/img_empty.png" alt="">
                      <p class="text">해당 데이터가 없습니다.</p>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <CommonPaginationFront :paging="paging" :page-func="pagingFunc" :divider="true" />
              </div>
              <p class="text text-muted" style="margin-top:-60px">※ 내가 공유한 노트수는 본인의 학습노트 공유건은 제외하고 합산합니다.</p>
            </section>

          </div>
          <!--          -->
        </div>
      </div>
      <!-- popup > popup-close -->
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="$emit('update:modelValue',false)"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
    <!-- //popup -->
  </div>
  <!-- end:popup-container -->
</template>

<script>
import {reactive, ref} from 'vue';
import {ACT_GET_SHARE_LIST} from "@/store/modules/ssl/ssl-index";
import {getDistCrseCd} from "@/assets/js/modules/ssl/ssl-common";
import {useStore} from "vuex";
import {getItems, getPaging, lengthCheck, timestampToDateFormat} from "@/assets/js/util";
import CommonPaginationFront from "@/components/CommonPaginationFront.vue";
import LoadingDiv from "@/components/common/LoadingDiv.vue";
import {useRouter} from "vue-router";

export default {
  name: "SSLShareModal",
  components: {LoadingDiv, CommonPaginationFront},
  props: {
    modelValue: Boolean,
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const store = useStore();
    const router = useRouter();
    const isLoading = ref(true);
    const actDiv = ref('all');
    const items = ref([]);

    const paging = reactive({
      pageNo: 1,
      pageSize: 10,
      totalCount: 0
    });

    const closeModal = () => {
      emit('update:modelValue', false);
    };

    const getList=()=>{

      // actDiv.value = actDivCd;
      // paging.pageNo = 1;
      // paging.pageSize = 3;

      store.dispatch(`ssl/${ACT_GET_SHARE_LIST}`, paging).then(res => {

        if (lengthCheck(res)) {
          items.value = getItems(res);
          paging.totalCount = getPaging(res).totalCount;
          isLoading.value=false;
        }else{
          items.value = [];
          isLoading.value=false;
        }

      });
    }

    const pagingFunc = (pageNo) => {
      if (pageNo > 0) paging.pageNo = pageNo;
      getList();
    };

    const goBoardView =(distCrseSn, lrnTrgtGrpMstSn, socLrnPostSn)=>{

      router.push({name: 'SSLView', params: {distCrseCd:getDistCrseCd(distCrseSn.toString()), lrnTrgtGrpMstSn:lrnTrgtGrpMstSn, socLrnPostSn: socLrnPostSn}}).then(() => {});
    };

    return{
      closeModal,
      actDiv,
      items,
      paging,
      pagingFunc,
      isLoading,
      getList,
      goBoardView,
      timestampToDateFormat
    }
  }
}

</script>

<style>
.text-overf {overflow: hidden;text-overflow: ellipsis;white-space: nowrap}
</style>