import {useStore} from 'vuex';
import {useRouter} from 'vue-router';
import {useAlert} from '@/assets/js/modules/common/alert';
import {computed, ref} from 'vue';
import {
    enrollSSL,
    getMyLearnTargetList,
    sslRoutes,
} from '@/assets/js/modules/ssl/ssl-common';
import {getItems} from "@/assets/js/util";
import {ACT_GET_SSL_APPLY_CNT, MUT_SET_ENROLL_CNT} from '@/store/modules/ssl/ssl-index';

export const sslApplyCompSetup = (props, {emit}) => {
    const store = useStore();
    const router = useRouter();
    const {showMessage, showConfirm, showLoading, hideLoading} = useAlert();

    const isMobile = computed(() => store.state.auth.isMobile);
    const distCrseNm = computed(() => sslRoutes[props.distCrseCd] ? sslRoutes[props.distCrseCd].name : '' );
    const options = sslRoutes[props.distCrseCd] ? sslRoutes[props.distCrseCd].group : [];
    const lrnTrgtGrpMstSn = ref(options.length > 0 ? options[0].lrnTrgtGrpMstSn : 0);

    const closeModal = () => {
        emit('update:toggle', false);
    }

    const enrollLearn = () => {
        const confirmText = `${distCrseNm.value}${options.length > 1 ? `(${options.find(x => x.lrnTrgtGrpMstSn === lrnTrgtGrpMstSn.value).lrnTrgtGrpNm})` : ''} 과정을 신청하시겠습니까?`;

        showConfirm({
            text : confirmText,
            callback: enrollInit
        });
    }

    const getApplyCnt = () => {
        store.dispatch(`ssl/${ACT_GET_SSL_APPLY_CNT}`, {
            lrnerId: '',
        }).then(res => {
            store.commit(`ssl/${MUT_SET_ENROLL_CNT}`,getItems(res)[0]);
        });
    };

    const enrollInit = () => {
        if (props.distCrseCd === 'group') {
            router.push({name: 'GroupLearning'});
        } else {
            showLoading();
            enrollSSL(lrnTrgtGrpMstSn.value, result => {
                if (result > 0) {
                    if (result === 3) {
                        getMyLearnTargetList(() => {
                            hideLoading();
                            showMessage('다른 분야에 입과된 이력이 존재합니다.');
                            closeModal();
                        }).then(() => {});
                    } else {
                        getMyLearnTargetList(() => {
                            hideLoading();
                            showMessage({
                                title: '입과완료',
                                text: '입과 완료되었습니다.'
                            });
                            getApplyCnt();
                            closeModal();
                        }).then(() => {});
                    }
                } else {
                    hideLoading();
                    showMessage('서버처리 중 일시적인 장애가 발생하였습니다.<br>잠시 후 다시 시도 해 주시기 바랍니다.');
                }
            });
        }
    }

    // const experienceLearn = () => {
    //     router.push({name: 'SSLMain', params: {distCrseCd: props.distCrseCd, lrnTrgtGrpMstSn: lrnTrgtGrpMstSn.value}}).then(() => {});
    // }


    return {
        isMobile,
        distCrseNm,
        lrnTrgtGrpMstSn,
        options,

        closeModal,
        enrollLearn
        ,getApplyCnt
        // experienceLearn
    }

}
