<template>
  <template v-if="isMobile">
    <div v-if="!isLoading" class="kb-player-menu" :class="{'is-active': isActive}" :style="{top: crse.cttSnLst != null && crse.cttSnLst.length > 0 ? '25px' : 0}">
      <button class="kb-controller-actions" :class="{'kb-btn-series-close' : isActive}" @click="mobileBtnActive">
        로드맵<i :class="isActive ? 'icon-series-close' : 'icon-map-white'"></i>
      </button>
    </div>
  </template>
  <template v-else>
    <div v-if="!isLoading" class="player-menu" :class="{'is-active': isActive}" :style="{top: crse.cttSnLst != null && crse.cttSnLst.length > 0 ? '60px' : 0}">
      <button class="kb-btn-menu" title="학습 로드맵" @click="isActive = !isActive"><i class="icon-map"></i></button>
      <HrdCloudRoadmapPlayList :crse="crse" :roadmap-sns="roadmapSns" :close-func="() => {isActive = !isActive}" />
    </div>
  </template>
</template>

<script>
import {onMounted, ref, computed, watch} from 'vue';
import {useStore} from "vuex";
import HrdCloudRoadmapPlayList from '@/components/hrdcloud/HrdCloudRoadmapPlayList';
import {getListFunc} from "@/assets/js/ui.init";
import {ACT_GET_HRDCLOUD_LEARNING_ROADMAP_SNS} from "@/store/modules/hrdcloud/hrdcloud";


export default {
  name: 'HrdCloudRoadmapPlayerButton',
  components: {HrdCloudRoadmapPlayList},
  props: {
    crse: Object,
    mobileViewFunc: Function,
  },
  setup(props) {
    const store = useStore()

    const isLoading = ref(true)
    const isActive = ref(false)
    const roadmapSns = ref([])
    const isMobile = computed(() => store.state.auth.isMobile)

    const mobileBtnActive = () => {
      isActive.value = !isActive.value;
      if(isActive.value) {
        props.mobileViewFunc(roadmapSns.value);
      }else{
        props.mobileViewFunc([]);
      }
    }

    const getLearningRoadmapSns = () => {
      isLoading.value = true;
      getListFunc(`hrdcloud/${ACT_GET_HRDCLOUD_LEARNING_ROADMAP_SNS}`, props.crse.lrnObjDtlDistSn, roadmapSns, null, () => {isLoading.value = (roadmapSns.value == null || roadmapSns.value.length == 0);}, () => {roadmapSns.value = [];});
    }

    watch(() => props.crse.lrnObjDtlDistSn, getLearningRoadmapSns)

    onMounted(getLearningRoadmapSns)

    return {
      isLoading,
      isActive,
      roadmapSns,
      isMobile,
      mobileBtnActive,
    }
  }
}
</script>