<template>
  <div class="kb-form-fields">
    <div class="kb-form-field field-border-0">
      <div class="kb-form-row">
        <label class="kb-form-label"><span class="kb-form-label-text">제목</span></label>
      </div>
      <div class="kb-form-row">
        <span class="kb-form-text">{{ item.cttTitle }}</span>
      </div>
    </div>
    <div class="kb-form-field">
      <div class="kb-form-row">
        <label class="kb-form-label"><span class="kb-form-label-text">분야</span></label>
      </div>
      <div class="kb-form-row">
        <span class="kb-form-text">{{ item.cloudNmLst }}</span>
      </div>
    </div>
    <div class="kb-form-field">
      <div class="kb-form-row">
        <label class="kb-form-label"><span class="kb-form-label-text">공개범위</span></label>
      </div>
      <div class="kb-form-row">
        <span class="kb-form-text">{{ expsrServ }}</span>
      </div>
    </div>
    <div class="kb-form-field">
      <div class="kb-form-row">
        <label class="kb-form-label"><span class="kb-form-label-text">동영상 업로드</span></label>
      </div>
      <div class="kb-form-row">
        <div class="file-text">
          <p class="text">{{ item.fileNm }}</p>
          <div class="close">
            <span v-if="item.cttFileSz > 0" class="close-text">{{ convertToStorageBytes(item.cttFileSz) }}</span>
          </div>
        </div>
      </div>
      <div class="kb-form-row row-buttons">
        <button class="kb-btn kb-btn-outline kb-btn-content-item" @click="showPreview"><span class="text">미리보기</span></button>
      </div>
    </div>
    <div v-if="item.thumb" class="kb-form-field">
      <div class="kb-form-row">
        <label class="kb-form-label"><span class="kb-form-label-text">썸네일 업로드</span></label>
      </div>
      <div class="kb-form-row">
        <div class="file-text">
          <article class="hrdcloud-card">
            <div class="hrdcloud-image">
              <div class="image">
                <a href="javascript:" class="image-link">
                  <img class="kb-thumb-list" :src="item.thumb" alt="썸네일 이미지" />
                </a>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
    <div v-if="stringCheck(item.orgFileNm)" class="kb-form-field">
      <div class="kb-form-row">
        <label class="kb-form-label"><span class="kb-form-label-text">첨부파일</span></label>
      </div>
      <div class="kb-form-row">
        <div class="file-text">
          <p class="text">{{ item.orgFileNm }}</p>
          <div class="close">
            <span v-if="item.fileSz > 0" class="close-text">{{ convertToStorageBytes(item.fileSz) }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="stringCheck(item.cttCn)" class="kb-form-field">
      <div class="kb-form-row">
        <label class="kb-form-label"><span class="kb-form-label-text">설명</span></label>
      </div>
      <div class="kb-form-row">
        <div class="editor-content m-h-300 ms-0">
          <div ref="pageEditor"></div>
        </div>
      </div>
    </div>
    <div v-if="stringCheck(item.tags)" class="kb-form-field">
      <div class="kb-form-row">
        <label class="kb-form-label"><span class="kb-form-label-text">태그</span></label>
      </div>
      <div class="kb-form-row">
        <div class="file-text">
          <div class="tags-wrap">
            <div class="tag-list">
              <div v-for="(hashTag, idx) in item.tags" class="tag-item is-active" :key="idx">
                <span class="tag-item-text">{{ hashTag }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="kb-form-fields-bottom divider" style="height: unset !important;">
    <strong class="text">유의사항</strong>
    <p class="text text-icon">1. 제작한 영상을 <span style="color:#ff8000;line-height:unset !important;">MP4파일(최대 2GB)로 외부망에서 업로드</span> 해주세요.</p>
    <p class="text">2. 첨부파일(붙임자료)가 있는 경우 <span style="color:#ff8000;line-height:unset !important;">암호화 해제 후 업로드</span> 해 주세요.</p>
    <p class="text"> ㅇ 암호화된 파일은 외부망에서 열람 불가</p>
    <p class="text"> ㅇ PDF/PPT/EXCEL 파일 등록 가능(최대 10MB)</p>
    <p class="text">3. <span style="color:#ff8000;line-height:unset !important;">
                콘텐츠에 개인정보 및 중요정보가 포함되지 않도록 유의</span>하여 주시고, 개인정보 유출 등으로 인한 책임은 등록자에게 있음을 확인해주시기 바랍니다.
    </p>
  </div>
</template>

<script>
import {convertToStorageBytes, getImg, setParams, stringCheck} from '@/assets/js/util';
import {getKbTubeList} from '@/assets/js/modules/hrd/hrd-history-common';
import {computed, onMounted, reactive, ref, toRefs} from 'vue';
import {useStore} from 'vuex';
import {useAlert} from '@/assets/js/modules/common/alert';
import ClassicEditor from "@/assets/js/editor/custom-ckeditor";
import {kbtubeEditorConfig} from "@/assets/js/editor/editor-config";

export default {
  name: 'KbTubeMobilePending',
  props: {
    modelValue: Object,
    cttMstSn: Number,
    previewModal: Object
  },
  setup(props) {
    const store = useStore();
    const session = computed(() => store.state.auth.session);
    const {showLoading, hideLoading} = useAlert();
    const {showMessage} = useAlert();
    const {cttMstSn} = toRefs(props);

    const expsrServ = computed(() => {
      // if (item.expsrServ === '2053001,2053002,2053003,2053004')
      return '전직원';
    })

    const item = reactive({
      cttTitle: '',
      cloudNmLst: '',
      expsrServ: '',
      fileNm: '',
      etnUrl: '',
      itnUrl: '',
      orgFileNm: '',
      cttFileSz: 0,
      fileSz: 0,
      buketNm: '',
      thumb: '',
      tag: '',
      cttCn: '',
      tags: []
    });

    const pageEditor = ref(null);
    let $_instance = null;

    const showPreview = () => {
      if (session.value.itn && item.itnUrl) {
        props.previewModal.update(item);
        props.previewModal.toggle();
      } else if (!session.value.itn && item.etnUrl) {
        props.previewModal.update(item);
        props.previewModal.toggle();
      } else {
        showMessage('현재 망에서 재생할 수 있는 영상이 없습니다.');
      }
    };

    let tryCount = 0;
    const setPageEditor = () => {
      if(tryCount > 10) return;
      if(pageEditor.value){
        ClassicEditor.create( pageEditor.value, kbtubeEditorConfig ).then(editor => {
          editor.isReadOnly = true;
          $_instance = editor;
          if(item.cttCn) $_instance.setData(item.cttCn);
        }).catch(error => {
          console.error(error);
        })
      }else{
        tryCount++;
        setPageEditor();
      }
    }

    onMounted(() => {
      showLoading();
      getKbTubeList({cttMstSn: cttMstSn.value}, null, {}, async (res) => {
        await setParams(item, res.items[0]);
        if (item.cloudNmLst && item.cloudNmLst.length > 0) {
          item.cloudNmLst = item.cloudNmLst.replaceAll(/,/g, ' > ');
        }
        item.thumb = stringCheck(item.thumb) ? getImg(item.thumb) : null;
        item.tags = stringCheck(item.tag) ? item.tag.split(',') : [];
        await setPageEditor();
        hideLoading();
      },() => {hideLoading()});
    });

    return {
      item, expsrServ, pageEditor, showPreview,
      convertToStorageBytes, stringCheck
    }
  }
};
</script>