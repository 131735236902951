import {getCurrentInstance} from 'vue';
import router from '../../router';
import {useAlert} from '@/assets/js/modules/common/alert';
import {TOKEN_KEY} from '@/store/modules/auth/auth';
import localstorageService, {getToken} from '@/assets/js/localstorage.service';
import {useStore} from 'vuex';
import mobileUtils, {isInApp} from '@/assets/js/navigationUtils';
import {popupBlock} from '@/assets/js/modules/ssl/ssl-message';
import {holyDay} from "@/assets/js/holy-day";
import {executeApps} from "@/assets/js/nativeAppUtils";

const {showMessage, showConfirmCustomBtn} = useAlert();

/***
 * GearResponse 에서 전달 받은 Data 성공, 실패여부 검증
 * @params GearResponse
 * @return Boolean
***/
export const lengthCheck = res => {
  return !!(res && res.items && res.items.length > 0);
};

export const stringCheck = (items) => {
  return !!(items && items.length > 0);
}

export const collectionCheck = (items, key) => {
  return !!(items && items.length > 0 && items[0][key] > 0);
}

export const lengthCheckFailAlert = (res) => {
  if(res && res.result && res.result.number != 200){
    showMessage(`처리 실패하였습니다.<br>관리자에게 문의하시기 바랍니다.`);
    return false;
  }else{
    return lengthCheck(res);
  }
}

/***
 * GearResponse 에서 전달 받은 Data => Item List Get
 * @params GearResponse
 * @return Array
 ***/
export const getItems = res => {
  return res.items;
};

/***
 * GearResponse 에서 전달 받은 Data => 단건 Item Get
 * @params GearResponse
 * @return Object
 ***/
export const getItem = res => {
  return res.items[0];
};

export const getCheckItem = res => {
  if(lengthCheck(res)){
    return res.items[0];
  }
  return {};
}

export const getCheckItems = res => {
  if(lengthCheck(res)){
    return res.items;
  }
  return [];
}

/***
 * GearResponse 에서 전달 받은 Data => Result Code 체크
 * @params GearResponse
 * @return Boolean
 ***/
export const isSuccess = res => {
  return !!(res && res.result && res.result.number === 200);
};

export const isSuccessFailAlert = res => {
  if(res && res.result && res.result.number != 200){
    showMessage(`처리 실패하였습니다.<br>관리자에게 문의하시기 바랍니다.`);
    return false;
  }else{
    return isSuccess(res);
  }
};

export const isUploadSuccess = (res) => {
  return !!(res && res.data && res.data.result && res.data.result.number === 200);
}

export const getResult = res => {
  if(res && res.result){
    return res.result;
  }
  return {
    number: 500,
    message: '오류가 발생했습니다. 잠시 후 다시 시도해주세요.'
  };
};

export const getPaging = (res, pageSize) => {
  if(res && res.paging){
    return res.paging;
  }
  return {
    pageNo: 1,
    pageSize: pageSize || 10,
    totalCount: 0,
    hasMore: false,
  }
};

export const ymdStrToFormat = (ymdStr, format) => {
  if (ymdStr !== null && ymdStr.length >= 8) {
    return ymdStr.substr(0, 4) + format + ymdStr.substr(4, 2) + format + ymdStr.substr(6, 2);
  }
  return "-";
}

export const hhmmStrToFormat = (hhmm, division) => {
  return hhmm.substr(0, 2) + division + hhmm.substr(2, 2);
}

export const ymdStrToDate = (ymd) => {
  return new Date(Number(ymd.substr(0, 4)), Number(ymd.substr(4, 2))-1, Number(ymd.substr(6, 2)));
}

/***
 * Timestamp 의 String Date Format
 * @params Timestamp (Number)
 * @return String
 ***/
export const timestampToDateFormat = (timestamp, format) => {
  // rendering 단계에서 NaN으로 떨어져서 null 체크 추가
  if (timestamp) {
    let d;
    if (typeof timestamp === 'string') {
      let year='', month='', day='';
      if (timestamp.indexOf('.') > -1) {
        let splitTime = timestamp.split('.');
        year = +splitTime[0];
        month = +splitTime[1];
        day = +splitTime[2].substring(0,1);
      } else {
        year = +timestamp.substring(0,4);
        month = +timestamp.substring(4,6);
        day = +timestamp.substring(6,8);
      }
      d = new Date(year, month-1,day);
    } else {
      d = new Date(timestamp);
    }
    return dateToDateFormat(d, format);
  } else {
    return null;
  }
}

export const ymdStrToDateFormat = (ymd, format) => {
  return dateToDateFormat(ymdStrToDate(ymd), format);
}

export const hyphenSeparatedToYmdStr = (dateStr) => {
  let year='', month='', day='';
  if (dateStr && typeof dateStr === 'string' && dateStr.indexOf('-') > -1) {
    let splitDate = dateStr.split('-');
    year = splitDate[0];
    month = splitDate[1];
    day = splitDate[2];
    return year+month+day;
  }
  return null;
}

export const minuteToFormat = (minute) => {
  let result = '';
  let hh = Math.floor(minute / 60);
  let mm = Math.floor(minute % 60);

  if (hh > 0) {
    result = hh+'시간 ';
  }

  if (mm > 0) {
    result += mm+'분';
  } else {
    result += '00분';
  }

  return result;
}

const week = ['일', '월', '화', '수', '목', '금', '토'];

export const getWeekOfDay = (idx) => {
  return week[idx];
}

export const dateToDateFormat = (d, format) => {
  const yyyy = d.getFullYear(),
        mm = ('0' + (d.getMonth() + 1)).slice(-2),	// Months are zero based. Add leading 0.
        dd = ('0' + d.getDate()).slice(-2),			// Add leading 0.
        w = (week[d.getDay()]),
        hh = ('0' + d.getHours()).slice(-2),
        min = ('0' + d.getMinutes()).slice(-2),		// Add leading 0.
        ss = ('0' + d.getSeconds()).slice(-2);

  let h = hh, ampm = 'AM', AMPM = '오전';
  let time;
  if (hh > 12) {
    h = hh - 12;
    ampm = 'PM';
    AMPM = '오후';
  } else if (hh === 12) {
    h = 12;
    ampm = 'PM';
    AMPM = '오후';
  } else if (hh === 0) {
    h = 12;
  }
  if (format === 'yyyy-MM-dd') {
    time = yyyy + '-' + mm + '-' + dd;
  }else if(format === 'yyyy.MM.dd'){
    time = yyyy + '.' + mm + '.' + dd;
  }else if(format === 'yyyyMMdd'){
    time = yyyy + '' + mm + '' + dd;
  }else if(format === 'yyyy-MM-dd(w)'){
    time = `${yyyy}-${mm}-${dd} (${w})`;
  }else if(format === 'yyyyMMddhhmm'){
    time = `${yyyy}${mm}${dd}${hh}${min}`;
  }else if(format === 'yyyy.MM.dd(w)'){
    time = `${yyyy}.${mm}.${dd} (${w})`;
  }else if(format === 'yyyy.MM.dd.w'){
    time = `${yyyy}.${mm}.${dd}.${w}`;
  }else if(format === 'yyyy.MM.dd hh:mm'){
    time = yyyy + '.' + mm + '.' + dd + ' ' + hh + ':' + min;
  }else if(format === 'yyyy.MM.dd hh:mm:ss'){
    time = yyyy + '.' + mm + '.' + dd + ' ' + hh + ':' + min + ':' + ss;
  }else if(format === 'yyyy-MM-dd, hh:mm'){
    time = yyyy + '-' + mm + '-' + dd + ', ' + hh + ':' + min;
  }else if(format === 'yyyy-MM-dd hh:mm:ss'){
    time = yyyy + '-' + mm + '-' + dd + ' ' + hh + ':' + min + ':' + ss;
  }else if(format === 'yyyy-MM-dd(w), hh:mm'){
    time = `${yyyy}-${mm}-${dd}(${w}), ${hh}:${min}`;
  }else if(format === 'yyyy.MM.dd.w hh:mm'){
    time = `${yyyy}.${mm}.${dd}.${w} ${hh}:${min}`;
  }else if(format === 'yyyy.MM.dd AMPM hh:mm'){
    time = `${yyyy}.${mm}.${dd} ${AMPM} ${hh}:${min}`;
  }else if(format === 'yyyy.MM'){
    time = yyyy + '.' + mm;
  }else if(format === 'yyyyMM'){
    time = yyyy + mm;
  }else if(format === 'yyyy'){
    time = yyyy;
  }else if(format === 'dd'){
    time = dd;
  }else if(format === 'hh:mm'){
    time = hh + ':' + min;
  }else if(format === 'hh:mm:ss'){
    time = hh + ':' + min + ':' + ss;
  } else if(format === 'hhmm'){
    time = hh + '' + min;
  }else if(format === 'hh:mm(A)'){
    time = h + ':' + min + '(' + ampm + ')';
  }else if(format === 'full-kr'){
    time = yyyy + '년 ' + mm + '월 ' + dd + '일 ' + w + '요일';
  }else {
    time = yyyy + '-' + mm + '-' + dd + ', ' + h + ':' + min + ' ' + ampm;
  }
  return time;
}

export const getTodayTimestamp = (date) => {
  return dateToDateFormat(date, 'yyyy-MM-dd hh:mm:ss');
};

export const getTodayDate = (format) => {
  let date = new Date(),
      year = date.getFullYear(),
      month = '' + (date.getMonth()+1),
      day = '' + date.getDate(),
      hh = ('0' + date.getHours()).slice(-2),
      min = ('0' + date.getMinutes()).slice(-2),		// Add leading 0.
      ss = ('0' + date.getSeconds()).slice(-2);

  if(month.length < 2){
    month = '0' + month;
  }

  if(day.length < 2){
    day = '0' + day;
  }

  let today = '';

  if(format === 'yyyy'){
    today = year.toString();
  } else if(format === 'yyyyMMdd'){
    today = year.toString() + '' + month + '' + day;
  }else if(format === 'yyyy.mm.dd'){
    today = year.toString() + '.' + month + '.' + day;
  } else if(format === 'yyyy-mm-dd'){
    today = year.toString() + '-' + month + '-' + day;
  } else if (format === 'yyyyMMddhhmm') {
    today = year.toString() + '' + month + '' + day + '' + hh + '' + min;
  } else if (format === 'yyyyMMddhhmmss') {
    today = year.toString() + '' + month + '' + day + '' + hh + '' + min + '' + ss;
  } else if (format === '년월일') {
    today = year.toString() + '년' + month + '월' + day + '일';
  }else{
    today = year.toString() + month + day;
  }


  return today;
}

export const timestampToDatePart = (timestamp) => {
  return dateToDatePart(new Date(timestamp));
}

export const dateToDatePart = (d) => {
  const year = d.getFullYear(),
      month = (d.getMonth() + 1),
      day = d.getDate(),
      mm = ('0' + (d.getMonth() + 1)).slice(-2),	// Months are zero based. Add leading 0.
      dd = ('0' + d.getDate()).slice(-2),			// Add leading 0.
      dow = (week[d.getDay()]),
      w = d.getDay(),
      hh = d.getHours(),
      hour = ('0' + hh).slice(-2),		// Add leading 0.
      min = ('0' + d.getMinutes()).slice(-2);		// Add leading 0.
  let h = hh, ampm = 'AM';
  if (hh > 12) {
    h = hh - 12;
    ampm = 'PM';
  } else if (hh === 12) {
    h = 12;
    ampm = 'PM';
  } else if (hh === 0) {
    h = 12;
  }
  return { year, month, day, mm, dd, w, dow, hh, h, hour, min, ampm, ymd: `${year}${mm}${dd}` };
}

export const monthToYear = (month) => {
  if(month < 12){
    return month + '개월';
  }else{
    let years = Math.floor(month / 12);
    let months = month - (years * 12);
    if(months > 0){
      return years + '년 ' + months + '개월';
    }else{
      return years + '년';
    }
  }
}

// export const queryToValue = (query, isNum, defaultVal) => {
//   if(query){
//     return isNum ? parseInt(query) : query;
//   }else{
//     if(defaultVal !== null && defaultVal !== undefined){
//       return defaultVal;
//     }else{
//       return isNum ? 1 : '';
//     }
//   }
// }

// export const itemsToOptions = (items, {nameKey, valueKey}) => {
//   return items.map(item => ({
//     name: item[nameKey],
//     value: item[valueKey]
//   }));
// }

export const phoneNumberMask = ( phoneNumber ) => {
  if (!phoneNumber) return phoneNumber
  phoneNumber = phoneNumber.replace(/[^0-9]/g, '')
  let res = ''
  if (phoneNumber.length < 3) {
    res = phoneNumber
  } else {
    if (phoneNumber.substr(0, 2) === '02') {
      if (phoneNumber.length <= 5) {//02-123-5678
        res = phoneNumber.substr(0, 2) + '-' + phoneNumber.substr(2, 3)
      } else if (phoneNumber.length > 5 && phoneNumber.length <= 9) {//02-123-5678
        res = phoneNumber.substr(0, 2) + '-' + phoneNumber.substr(2, 3) + '-' + phoneNumber.substr(5)
      } else if (phoneNumber.length > 9) {//02-1234-5678
        res = phoneNumber.substr(0, 2) + '-' + phoneNumber.substr(2, 4) + '-' + phoneNumber.substr(6)
      }
    } else {
      if (phoneNumber.length < 8) {
        res = phoneNumber
      } else if (phoneNumber.length === 8) {
        res = phoneNumber.substr(0, 4) + '-' + phoneNumber.substr(4)
      } else if (phoneNumber.length === 9) {
        res = phoneNumber.substr(0, 3) + '-' + phoneNumber.substr(3, 3) + '-' + phoneNumber.substr(6)
      } else if (phoneNumber.length === 10) {
        res = phoneNumber.substr(0, 3) + '-' + phoneNumber.substr(3, 3) + '-' + phoneNumber.substr(6)
      } else if (phoneNumber.length > 10) { //010-1234-5678
        res = phoneNumber.substr(0, 3) + '-' + phoneNumber.substr(3, 4) + '-' + phoneNumber.substr(7)
      }
    }
  }
  return res;
}

const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
export const convertToStorageBytes = (x) => {
  let n = parseInt(x, 10) || 0, l = 0;
  while (n > 1024) {
    n = n / 1024;
    l++;
  }
  return (n.toFixed(n >= 10 ? 0 : 1) + ' ' + units[l]);
}

export const paginate = (array, page_number, page_size) => {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array.slice((page_number - 1) * page_size, page_number * page_size);
}

export const validate = (store, target, validateObj) => {
  let result = true;
  let msg = null;
  for(let i = 0; i < validateObj.length; i++ ){
    if(validateObj[i].type === 'number'){
      if(target[validateObj[i].key] === null || target[validateObj[i].key] === undefined) {
        msg = validateObj[i].msg;
        result = false;
        break;
      }
    }else{
      if(!target[validateObj[i].key]){
        msg = validateObj[i].msg;
        result = false;
        break;
      }
    }
  }

  if(!result){
    alert(msg);
    // store.commit(`common/${MUT_SHOW_TOAST}`, {
    //   title: msg
    // });
  }

  return result;
}

export const paramCheck = (param) => {
  if (param === null) {
    return true;
  }
  if (typeof param === 'string') {
    if (param.length === 0) {
      return true;
    }
  }
  if (typeof param === 'number') {
    if (param === 0) {
      return true;
    }
  }

  return false;
};

export const nullEmpty = (param) => {
  Object.keys(param).forEach(key => {
    if (typeof param[key] === 'string') {
      if (param[key].length === 0) {
        param[key] = null;
      }
    } else if (typeof param[key] === 'number') {
      if (param[key] === 0) {
        param[key] = null;
      }
    }
  });
}

// 페이징 정보 등록
export const setPaging = (target, res) => {
  const paging = getPaging(res);
  Object.keys(paging).forEach(key => {
    target[key] = paging[key];
  });
}

export const setParams = (params, item) => {
  Object.keys(params).forEach(key => {
    if(item[key] !== null && item[key] !== undefined){
      params[key] = item[key];
    }
  });
}

export const initParams = (params) => {
  if (params != null) {
    Object.keys(params).forEach(key => {
      if(params[key] instanceof Number){
        params[key] = 0;
      }else if(params[key] instanceof Array){
        params[key] = [];
      }else if(params[key] instanceof Boolean){
        params[key] = false;
      }else{
        params[key] = null;
      }
    });
  }
};

/**
 * URL로 직접 pageNo나 pageSize 입력시 반영되지 않는 오류 방지
 * 사용법 const paging = ref(initPaging(route));
 * @param route
 * @param max
 * @returns {{pageNo: number, pageSize: number, totalCount: number}}
 */
export const initPaging  = (route, max = 10) => {
  const paging = {pageNo: 1, pageSize: (max > 0 ? max : 10), totalCount: 0};
  if(route.query && route.query.pageNo > 0){
    paging.pageNo = route.query.pageNo;
  }
  if(route.query && route.query.pageSize > 0){
    paging.pageSize = route.query.pageSize;
  }

  return paging;
};

export const initPagingExceptTotalCount = (route, max) => {
  const paging = {pageNo: 1, pageSize: max};
  if(route.query && route.query.pageNo > 0){
    paging.pageNo = route.query.pageNo
  }
  if(route.query && route.query.pageSize > 0){
    paging.pageSize = route.query.pageSize
  }
  return paging;
}

// export const getRegisterTimeFormat = (timestamp, now) => {
//   if(!now) now = new Date().getTime();
//   const diff = now - timestamp;
//   const sec = diff /1000;
//   const min = sec / 60;
//   const hour = min / 60;
//   const day = hour / 24;
//
//   if(day > 30){
//     return timestampToDateFormat(now, 'yyyy.MM.dd');
//   }else if(hour > 24){
//     const day = hour / 24;
//     return `${day.toFixed(0)}일전`;
//   }else if(hour < 1){
//     return `${min.toFixed(0)}분전`;
//   }else{
//     return `${hour.toFixed(0)}시간전`;
//   }
// }

export const goToTop = (targetId, _duration, _adjust) => {
  const targetEle = document.getElementById(targetId);
  if (!targetEle){
    console.warn("not found ele ", targetEle);
    return;
  }

  // - Get current &amp; target positions
  const scrollEle = document.documentElement || window.scrollingElement,
      currentY = scrollEle.scrollTop,
      targetY = targetEle.offsetTop - (_adjust || 0);
  animateScrollTo(currentY, targetY, _duration);

  // - Animate and scroll to target position
  function animateScrollTo(_startY, _endY, _duration) {
    _duration = _duration ? _duration : 600;
    const unitY = (targetY - currentY) / _duration;
    const startTime = new Date().getTime();
    const endTime = new Date().getTime() + _duration;
    const scrollTo = function() {
      let now = new Date().getTime();
      let passed = now - startTime;
      if (now <= endTime) {
        scrollEle.scrollTop = currentY + (unitY * passed);
        requestAnimationFrame(scrollTo);
      }
    };
    requestAnimationFrame(scrollTo);
  }
}

export const numberComma = (val) => {
  if (val) {
    const num = val.toString().replace(/^0/g,"").replace(/,/g, "")
    const parts = num.toString().split('.')
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')  //(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
    return parts.join('.')
  }
  return val;
};

export const removeComma = (val) => {
  if (val && val.toString().indexOf(',') > -1) {
    return val.replace(/,/g, '');
  }
  return val;
};

export const getCalendar = (year, month) => {
  const getCalendarData = (year, month) => {
    const currentMonth = parseInt(month);
    const prevMonth = currentMonth === 1 ? 12 : currentMonth - 1;
    const nextMonth = currentMonth === 12 ? 1 : currentMonth + 1;
    const prevYear = prevMonth === 12 ? year - 1 : year;
    const nextYear = nextMonth === 1 ? year + 1 : year;

    const calendar = [[],[],[],[],[],[]];

    const setDate = new Date(year, month - 1, 1);
    //이번 달의 처음 요일을 구합니다.
    const firstDayName = setDate.getDay();
    const lastDay = new Date(targetDay.getFullYear(),targetDay.getMonth() + 1,0).getDate();
    const prevLastDay = new Date(targetDay.getFullYear(),targetDay.getMonth(),0).getDate();
    let startDayCount = 1;
    let lastDayCount = 1;

    //1~6주차를 위해 6번 반복합니다.
    for (let i = 0; i < 6; i++) {
      //일요일~토요일을 위해 7번 반복합니다.
      for (let j = 0; j < 7; j++) {
        // 이번달 이전...
        if (i === 0 && j < firstDayName) {
          calendar[i].push({
            'year': prevYear,
            'month': prevMonth,
            'day': (prevLastDay - (firstDayName - 1) + j),
            'ymd': `${prevYear}${('0' + prevMonth).slice(-2)}${('0' + (prevLastDay - (firstDayName - 1) + j)).slice(-2)}`,
            'ymdformat': `${prevYear}-${('0' + prevMonth).slice(-2)}-${('0' + (prevLastDay - (firstDayName - 1) + j)).slice(-2)}`,
          });
        }
        else if (i === 0 && j >= firstDayName) {
          calendar[i].push({
            'year': year,
            'month': currentMonth,
            'day': startDayCount,
            'ymd': `${year}${('0' + currentMonth).slice(-2)}${('0' + startDayCount).slice(-2)}`,
            'ymdformat': `${year}-${('0' + currentMonth).slice(-2)}-${('0' + startDayCount).slice(-2)}`,
          })
          setFixDayCount(startDayCount++)
        }
        else if (i > 0 && startDayCount <= lastDay) {
          //일요일일 때, 토요일일 때, 나머지 요일 일 때
          calendar[i].push({
            'year': year,
            'month': currentMonth,
            'day': startDayCount,
            'ymd': `${year}${('0' + currentMonth).slice(-2)}${('0' + startDayCount).slice(-2)}`,
            'ymdformat': `${year}-${('0' + currentMonth).slice(-2)}-${('0' + startDayCount).slice(-2)}`,
          });
          setFixDayCount(startDayCount++)
        }
        // startDayCount > lastDay: 이번 달의 마지막 날 이후일 때
        // 다음 달임...
        else if (startDayCount > lastDay) {
          // calendar[i].push(lastDayCount++)
          calendar[i].push({
            'year': nextYear,
            'month': nextMonth,
            'day': lastDayCount,
            'ymd': `${nextYear}${('0' + nextMonth).slice(-2)}${('0' + lastDayCount).slice(-2)}`,
            'ymdformat': `${nextYear}-${('0' + nextMonth).slice(-2)}-${('0' + lastDayCount).slice(-2)}`,
          });
          lastDayCount++
        }
      }
    }
    return calendar;
    //캘린더 div 태그에 내용 붙임
  };

  const setFixDayCount = number => {
    //캘린더 하루마다 아이디를 만들어주기 위해 사용
    let fixNum = "";
    if (number < 10) {
      fixNum = "0" + number;
    } else {
      fixNum = number;
    }
    return fixNum;
  };

  const targetDay = (year > 0 && month > 0) ? new Date(year, (month - 1), 1) : new Date();
  if (targetDay.getMonth() + 1 < 10) {
    return getCalendarData(targetDay.getFullYear(), "0" + (targetDay.getMonth() + 1));
  } else {
    return getCalendarData(targetDay.getFullYear(), "" + (targetDay.getMonth() + 1));
  }
}

export const getWeek = (year, month, day) => {
  const targetDate = new Date(year,month - 1,day);
  const targetYear = targetDate.getFullYear();
  const targetMonth = targetDate.getMonth();
  const targetDay = targetDate.getDate();
  const targetDayOfWeek = targetDate.getDay();
  const targetWeek = [];
  for(let i = 0; i < 7; i++) {
    const resultDay = new Date(targetYear, targetMonth, targetDay + (i - targetDayOfWeek));
    const resultDate = {
      'year': resultDay.getFullYear(),
      'month': (resultDay.getMonth() + 1),
      'day': resultDay.getDate(),
      'dayofweek': (week[resultDay.getDay()])
    }
    resultDate['ymd'] = `${resultDate.year}${('0' + resultDate.month).slice(-2)}${('0' + resultDate.day).slice(-2)}`;
    targetWeek.push(resultDate);
  }
  return targetWeek;
}

export const getLastCntYears = (cnt) => {
  const date = new Date();
  const years = [];
  const year = date.getFullYear();

  for (let i = year; i > year - cnt; i--) {
    years.push(i);
  }
  return years;
}

export const getTargetDate = (year, month, day) => {
  return new Date(year,month - 1,day);
}

export const validateObject = (validateKeys, target, idx) => {
  const keys = Object.keys(validateKeys);
  const msgs = [];
  keys.forEach(key  => {
    // 지정된 키만 컨트롤
    if(!target[key]){
      if(validateKeys[key] instanceof Function){
        msgs.push(validateKeys[key](idx));
      }else{
        msgs.push(validateKeys[key]);
      }
    }
  });
  return msgs;
}

let internalInstance = null;
let progressTimeout = null;

export const initProgressBar = () => {
  if(!internalInstance) internalInstance = getCurrentInstance();
  if(internalInstance){
    if(progressTimeout){
      clearTimeout(progressTimeout);
      progressTimeout = null;
    }
    internalInstance.appContext.config.globalProperties.$Progress.start();
    progressTimeout = setTimeout(() => {
      internalInstance.appContext.config.globalProperties.$Progress.finish();
    }, 500);
  }
  //  [App.vue specific] When App.vue is first loaded start the progress bar
}


export const startProgressBar = () => {
  if(!internalInstance) internalInstance = getCurrentInstance();
  if(internalInstance) {
    if (progressTimeout) {
      clearTimeout(progressTimeout);
      progressTimeout = null;
    }
    internalInstance.appContext.config.globalProperties.$Progress.start();
  }
};

export const finishProgressBar = () => {
  if(!internalInstance) internalInstance = getCurrentInstance();
  if(internalInstance) {
    progressTimeout = setTimeout(() => {
      internalInstance.appContext.config.globalProperties.$Progress.finish();
    }, 500);
  }
};

export const resetStyles = () => {
  const styles = document.querySelectorAll('style')
  styles.forEach(s => {
    s.remove();
  });
}

// 임시: 자기주도학습이력 요약정보 모두 더하기
export const summarizeAll = (arr, key) => {
  if (arr.length > 0) {
    let newArr = [...new Map(arr.map(x => [x[key], x])).values()];
    let keysToAdd = [...new Set(arr.map(x => x[key])).values()];
    let obj = {};

    keysToAdd.forEach(akey => {
      arr.map(x => {
        if (x[key] === akey) {
          if (x.val) {
            obj[akey] = (obj[akey] ? obj[akey] : 0) + x.val;
          } else if (x.cnt) {
            obj[akey] = (obj[akey] ? obj[akey] : 0) + x.cnt;
          }
        }
      });

      newArr.forEach(item => {
        if (item[key] === akey) {
          if (item.val) {
            item.val = obj[akey];
          }else if (item.cnt) {
            item.cnt = obj[akey];
          }
        }
      });
    });
    return newArr;
  }
  return [];
}

// 구분자가 시간일때 XX시간 XX분 표시
export const checkFormat = (val, valTp) => {
  if (valTp === '분') return minuteToFormat(val);
  else if (valTp === '원') return numberComma(val)+valTp;
  else return ''+val+valTp;
}

/**
  Router에 Query String 이 존재하면 Parameter에 넣어주는 기능.

  @사용처: Query String을 검색조건에 담는다.
  @파라미터:
    initialize - target 초기화 여부
    router - 라우터
    param - query string 값이 저장될 변수
*/
export const setParamsByQuery = (route, target, initialize) => {
  if (initialize) initParams(target);
  if(route && route.query) {
    Object.keys(target).forEach(key => {
      if (route.query[key] !== null && route.query[key] !== undefined) {
        target[key] = route.query[key];
      }
    });
  }
}

/**
 * QueryString validation : null & undefined & empty string URL에서 제외시킨다.
 * */
export const removeEmptyParams = (route) => {
  let qParams = {};
  Object.keys(route.query).forEach(key => {
    if (route.query[key] !== null && route.query[key] !== undefined) {
      if (route.query[key] !== '') {
        qParams[key] = route.query[key];
      }
    }
  });
  router.push({'query': qParams});
}

/**
 *  target에 route.query 값 넣어줌 이때 없는 값은 날려줌
 **/
export const setParamsAndRemoveEmptyOfQueryString = (route, target, initialize) => {
  if (initialize) initParams(target);

  if(route && route.query) {
    Object.keys(target).forEach(key => {
      if (route.query[key] !== null && route.query[key] !== undefined) {
        if (route.query[key] !== '') {
          target[key] = route.query[key];
        }
      }
    });

    Object.keys(route.query).forEach(key => {
      if (route.query[key] !== null && route.query[key] !== undefined) {
        if (route.query[key] !== '') {
          target[key] = route.query[key];
        }
      }
    });
  }
}

/**
*   URL에 QueryString 이 존재하면 Parameter에 넣어주고
 *  validation 해서 비어있거나 null, undefined 이면 제외 시켜줌
*/
export const setParamsByQueryString = (route, name, paging, target, initialize) => {
  if (route.name === name) {
    setParamsByQuery(route, target, initialize);
    removeEmptyParams(route);
    paging.value = {
      ...paging.value,
      ...initPagingExceptTotalCount(route, paging.value.pageSize)
    };
  }
};

export const getText = (htmlStr) => {
  if (!htmlStr) return null;
  return htmlStr.replace(/(<([^>]+)>)/ig,"").replace('&nbsp;', '');
};

export const aws2ncloudUrl = (url) =>{
  // return url;
  // let ncloud = url.replace(/devlxpcms.kbstar.com/g,'vgugjckibnob10116049.cdn.ntruss.com');
  // ncloud = ncloud.replace(/lxpcms.kbstar.com/g,'fyybpfluxebt13568016.cdn.ntruss.com');
  let ncloud = url.replace(/devlxpcms.kbstar.com/g,'devlxpcms-ncloud.cdn.ntruss.com');
  ncloud = ncloud.replace(/lxpcms.kbstar.com/g,'lxpcms-ncloud.cdn.ntruss.com');

  return ncloud;


}

export const getVideoUrl = (itn, src) => {
  let convertUrl = (videoSrc) => {
    let source = null;
    let domain = null;
    if (videoSrc.indexOf('http') > -1 || videoSrc.indexOf('https') > -1) {
      source = videoSrc;
    } else if (videoSrc.indexOf('contents/') > -1) {

      /***
       * pea.hrd.aws.prd.s3.contents :: KB 운영 - lxpcms
       * pea.hrd.aws.dev.s3.contents :: KB 개발 - devlxpcms
       * pea-hrd-ncp-dev-s3-contents :: 사용안함
       * pea.hrd.aws.dev.s3.convert  :: 트랜코딩 진행중
       * kb.dev.asset                :: GS
       ***/
      if (src.buketNm === 'pea.hrd.aws.prd.s3.contents') {
        domain = 'https://lxpcms.kbstar.com';
      } else if (src.buketNm === 'pea.hrd.aws.dev.s3.contents') {
        domain = 'https://devlxpcms.kbstar.com';
      } else {
        // 추후 정책 확인 해야된다.
        // domain = process.env.VUE_APP_CLOUDFRONT_URL;
        domain = 'https://lxpcms.kbstar.com';
      }

      if (videoSrc.indexOf('/') === 0) {
        source = domain+`${videoSrc}`;
      } else {
        source = domain+`/${videoSrc}`;
      }
    }
    return source;
  }
  if (!src) {
    return null;
  } else {

    // console.log("chk vod url", itn, aws2ncloudUrl(convertUrl(src.itnUrl)));
    if (itn) {
      if (src.itnUrl) {
        return convertUrl(src.itnUrl);
      }
    } else {
      if (src.etnUrl) {
        let awsUrl = convertUrl(src.etnUrl);
        // return awsUrl;
        return aws2ncloudUrl(awsUrl);
      }
    }
  }
};

export const getVideoVttUrl = (itn, src) => {
  let convertUrl = (videoSrc) => {
    let source = null;
    let domain = null;
    if (videoSrc.indexOf('http') > -1 || videoSrc.indexOf('https') > -1) {
      source = videoSrc;
    } else if (videoSrc.indexOf('contents/') > -1) {

      /***
       * pea.hrd.aws.prd.s3.contents :: KB 운영 - lxpcms
       * pea.hrd.aws.dev.s3.contents :: KB 개발 - devlxpcms
       * pea-hrd-ncp-dev-s3-contents :: 사용안함
       * pea.hrd.aws.dev.s3.convert  :: 트랜코딩 진행중
       * kb.dev.asset                :: GS
       ***/
      if (src.buketNm === 'pea.hrd.aws.prd.s3.contents') {
        domain = 'https://lxpcms.kbstar.com';
      } else if (src.buketNm === 'pea.hrd.aws.dev.s3.contents') {
        domain = 'https://devlxpcms.kbstar.com';
      } else {
        // 추후 정책 확인 해야된다.
        // domain = process.env.VUE_APP_CLOUDFRONT_URL;
        domain = 'https://lxpcms.kbstar.com';
      }

      if (videoSrc.indexOf('/') === 0) {
        source = domain+`${videoSrc}`;
      } else {
        source = domain+`/${videoSrc}`;
      }
    }
    return source;
  }
  if (!src) {
    return null;
  } else {
    if (itn) {
      if (src.substFileNm) {
        return convertUrl(src.substFileNm);
      }
    } else {
      if (src.substFileNm) {
        let awsUrl = convertUrl(src.substFileNm);
        // return awsUrl;
        return aws2ncloudUrl(awsUrl);

      }
    }
  }
};

export const getUrl = (src) => {
  let source = null;
  let domain = null;
  if (src.indexOf('http') > -1 || src.indexOf('https') > -1) {
    source = src;
  } else if (src.indexOf('contents/') > -1) {

    /***
     * pea.hrd.aws.prd.s3.contents :: KB 운영 - lxpcms
     * pea.hrd.aws.dev.s3.contents :: KB 개발 - devlxpcms
     * pea-hrd-ncp-dev-s3-contents :: 사용안함
     * pea.hrd.aws.dev.s3.convert  :: 트랜코딩 진행중
     * kb.dev.asset                :: GS
     ***/
    if (src.buketNm === 'pea.hrd.aws.prd.s3.contents') {
      domain = 'https://lxpcms.kbstar.com';
    } else if (src.buketNm === 'pea.hrd.aws.dev.s3.contents') {
      domain = 'https://devlxpcms.kbstar.com';
    } else {
      // 추후 정책 확인 해야된다.
      // domain = process.env.VUE_APP_CLOUDFRONT_URL;
      domain = 'https://lxpcms.kbstar.com';
    }

    if (src.indexOf('/') === 0) {
      source = domain+`${src}`;
    } else {
      source = domain+`/${src}`;
    }
  }
  return source;
}

export const checkCurrentRegion = () => {
  const store = useStore();
  const sessionItn = store.state.auth.session.itn;
  if (sessionItn) {
    return true;
  } else {
    return false;
  }
};

export const convertUrl = (bucket, url) => {
  let source = null;
  let domain = null;
  if (url != null && url !== '' && url.length > 0) {
    /***
     * pea.hrd.aws.prd.s3.contents :: KB 운영 - lxpcms
     * pea.hrd.aws.dev.s3.contents :: KB 개발 - devlxpcms
     * pea-hrd-ncp-dev-s3-contents :: 사용안함
     * pea.hrd.aws.dev.s3.convert  :: 트랜코딩 진행중
     * kb.dev.asset                :: GS
     ***/
    if (bucket === 'pea.hrd.aws.prd.s3.contents') {
      domain = 'https://lxpcms.kbstar.com';
    } else if (bucket === 'pea.hrd.aws.dev.s3.contents') {
      domain = 'https://devlxpcms.kbstar.com';
    } else {
      domain = 'https://devlxpcms.kbstar.com';
    }

    if (url.indexOf('/') === 0) {
      source = domain+`${url}`;
    } else {
      source = domain+`/${url}`;
    }
  }
  return source;
};

export const getUrlByRegion = (data) => {
  let internal = checkCurrentRegion();
  let url = null;
  if (internal) url = data.itnUrl;
  else url = data.etnUrl;
  return convertUrl(data.bucketNm, url);
};

/*
  공통코드명 가져오기
  @파라미터
    store - 스토어
    cd - 공통코드명 가져올 코드
*/
export const getCdNm = (store, cd) => {
  let cdNm = null;
  store.state.code.codes.filter(code => {
    if (code.cd === cd) cdNm = code.cdNm
  });
  return cdNm;
};

export const getType = (src) => {
  if (src && src.length > 0) {
    if (src.indexOf('.mp3') > -1) {
      return 'audio/mp3';
    } else if (src.indexOf('.m3u8') > -1) {
      return 'application/x-mpegURL';
    }
  }
  return 'video/mp4';
};

export const getSimpleType = (thumb) => {
  if(thumb){
    if(thumb.indexOf('.mp4') > -1){
      return 'video';
    }else if(thumb.indexOf('.jpg') > -1 || thumb.indexOf('.png') > -1){
      return 'image';
    }
  }
  return null;
}

export const getRandom = (min, max) => {
  // Between min & max
  if (min >= 0 && max >= 0) {
    return Math.floor(Math.random() * (max - min +1)) + min;
  }
  return Math.floor(Math.random());
};


export const clearTargetInterval = (targetInterval) => {
  if(targetInterval){
    clearInterval(targetInterval);
    targetInterval = null;
  }
};

export const clearTargetTimeout = (targetTimeout) => {
  if(targetTimeout){
    clearTimeout(targetTimeout);
    targetTimeout = 0;
  }
};

export const secondToFormat = (currentTime, format) => {
  const sec = parseInt(currentTime, 10); // don't forget the second param
  const hour   = Math.floor(sec / 3600);
  const minute = Math.floor((sec - (hour * 3600)) / 60);
  const seconds = sec - (hour * 3600) - (minute * 60);

  let time = '';
  if(format === 'hh:mm:ss'){
    time = `0${hour}`.slice(-2) + ':' + `0${minute}`.slice(-2) + ':' + `0${seconds}`.slice(-2);
  } else if (format === 'mm:ss') {
    time = `0${minute}`.slice(-2) + ':' + `0${seconds}`.slice(-2);
  } else if (format === 'hh:mm') {
    time = `0${hour}`.slice(-2) + ':' + `0${minute}`.slice(-2);
  } else if(format === 'h.m') {
    if (minute > 0) {
      time = `0${minute}`.slice(-2) + '.' + `${seconds}`.slice(-1);
    }
    if (hour > 0) {
      time = `0${hour}`.slice(-2) + '.' + `${minute}`.slice(-2);
    }
  } else {
    if (seconds > 0) {
      time = `0${seconds}`.slice(-2);
    }
    if (minute > 0) {
      time = `0${minute}`.slice(-2) + ':' + `0${seconds}`.slice(-2);
    }
    if (hour > 0) {
      time = `0${hour}`.slice(-2) + ':' + `0${minute}`.slice(-2) + ':' + `0${seconds}`.slice(-2);
    }
  }

  return time;
}

export const secondToKrFormat = (time, isSecond = true) => {
  const sec = parseInt(time, 10); // don't forget the second param
  const day = Math.floor(sec / 3600 / 24);
  const hour   = Math.floor(sec / 3600) % 24;
  const minute = Math.floor(sec / 60) % 60;
  const seconds = Math.floor(sec) % 60;

  if(day > 0){
    time = `${day}일 ` + `${hour}`.slice(-2)+`시간 ` + `${minute}`.slice(-2) + `분 ` + (isSecond ? `${seconds}`.slice(-2) + `초` : '');
  } else if(hour > 0){
    time = `${hour}`.slice(-2) + '시간 ' + `${minute}`.slice(-2) + `분 ` + (isSecond ? `${seconds}`.slice(-2) + `초` : '');
  } else if(minute > 0){
    time = `${minute}`.slice(-2) + `분 ` +  (isSecond ? `${seconds}`.slice(-2) + `초` : '');
  } else{
    time = `${seconds}`.slice(-2) + `초`;
  }

  return time;
};

export const getDDay = (targetTs, currentTs) => {
  if(!currentTs) currentTs = new Date().getTime();
  const diff = targetTs - currentTs;
  return Math.floor(diff / (1000 * 60 * 60 * 24));
}

export const validatePassword = (pwd, checkPwd) => {
  const regPwd = /^(?=.*[a-zA-Z])(?=.*[!@#$%^)(&*+=-])(?=.*[0-9])/

  let result = false;
  let msg = '';

  if(!pwd){
    msg = '변경 할 비밀번호를 입력해주세요.';
  } else if(!checkPwd) {
    msg = '재입력 비밀번호를 입력해주세요.';
  } else if(pwd !== checkPwd) {
    msg = '입력하신 비밀번호와 재입력 한 비밀번호가 다릅니다. 다시 입력하여 주십시오.';
  } else if(pwd.length < 8) {
    msg = '비밀번호는 8자리 이상이어야 합니다.';
  } else if(!regPwd.test(pwd)) {
    msg = '비밀번호 중 영문자, 숫자, 특수문자가 최소 1자리 이상 포함되어야 합니다';
  } else if(pwd.indexOf('kbstar') > -1 || pwd.indexOf('kbfng') > -1
            || pwd.indexOf('kbfg') > -1 || pwd.indexOf('kbcard') > -1
            || isSameContinuedValue(pwd)
  ) {
    msg = '비밀번호에 금지단어가 포함되어 있습니다, 다시 입력하여 주십시오.';
  } else {
    result = true;
  }
  showMessage({text: msg});

  return result;
};

// 연속 된 문자 체크
const isSameContinuedValue = (text) => {
  let temp = '';
  let intCnt = 0;

  for (let i = 0; i < text.length; i++) {
    temp = text.charAt(i);
    if (temp === text.charAt(i+1) && temp === text.charAt(i+2) && temp === text.charAt(i+3) ) {
      intCnt = intCnt + 1;
    }
  }
  return intCnt > 0;
};

export const emptyNumberOnClick = (e) => {
  if (e.type === 'focusin') {
    if (e.target.value == 0) {
      e.target.value = '';
    }
  } else if (e.type === 'focusout') {
    if (!e.target.value) {
      e.target.value = 0;
    }
  }
};

export const floatNumChk = (val) =>{
  const reg = /^-?\d*(\.\d*)?$/;
  if(reg.test(val)){
    return true;
  }else{
    return false;
  }

}

export const numberOnlyChk = (val) =>{

  for (let n = 0; n < val.length; n++) {
    if (val.charAt(n) < "0" || val.charAt(n) > "9") return false;
  }

  return true;

}

export const numbersOnly = (e) => {
  if (e.target.value) {
    e.target.value = e.target.value.trim().replace(/[^.0-9]/, '');
  }
};

export const removeParamCommas = (param, exclude) => {
  Object.keys(param).forEach(key => {
    if (exclude && exclude.length > 0 && !exclude.includes(key)) {
      param[key] = removeComma(param[key]);
    } else {
      param[key] = removeComma(param[key]);
    }
  });
};

export const getNowOnUrl = () => {
  if( process.env.NODE_ENV === 'production') {
    return 'https://lxp.kbstar.com/onclass/'
  } else if(process.env.NODE_ENV === 'development') {
    return 'https://devlxp.kbstar.com/onclass/'
  } else
    return 'http://localhost:8082/'
}

export const compareArrays = (a, b) => {
  if (a instanceof Array) {
    a.sort((a, b) => {
      if (a.lrnerId > b.lrnerId) return -1;
      if (a.lrnerId < b.lrnerId) return 1;
      return 0;
    });
  }
  if (b instanceof Array) {
    b.sort((a, b) => {
      if (a.lrnerId > b.lrnerId) return -1;
      if (a.lrnerId < b.lrnerId) return 1;
      return 0;
    });
  }
  return a.length === b.length && a.every((element, index) => element === b[index]);
};

export const getBgngEndDate = (year, type) => {
  year = Number(year);
  let start = '01.01'
  let end = '12.31';
  if (type === 'now') {
    //  { year, month, day, mm, dd, w, dow, hh, min, h, ampm, ymd: `${year}${mm}${dd}` };
    let date = dateToDatePart(new Date);
    year = date.year;
  }
  if (year > 0) {
    return `${year}.${start} ~ ${year}.${end}`;
  } else {
    return `${start} ~ ${end}`;
  }
};
export const getHeader = () => {
  return {
    headers: [
      {
        key: TOKEN_KEY,
        value: localstorageService.getToken(TOKEN_KEY)
      }
    ]
  }
};

export const getTextByte = (s) => {
  let cnt = 0;
  let i = 0;

  // 한글 3byte, 영문&숫자 1byte
  for(i; i<s.length; i++){
    cnt += (s.charCodeAt(i) > 128) ? 3 : 1;
  }

  return cnt;
};

export const setYmdObject = (ymd) => {
  if (ymd && ymd.indexOf('-') > -1) {
    const split = ymd.split('-');
    if (split.length === 3) {
      const dateSplit = split.map(x => parseInt(x));
      return {
        year: dateSplit[0],
        month: dateSplit[1],
        day: dateSplit[2],
        ymd: ymd
      };
    }
  }
  return null;
};

export const addDays = (date, day) => {
  const result = new Date(date);
  result.setDate(result.getDate() + day);
  return result;
};

export const winOpen = (url, options, name) => {


  let hostname = location.hostname;
  let protocol = location.protocol;
  let mobUrl = `${protocol}//${hostname}${url}`;
  console.log("winOpen check", isInApp, url, mobUrl);
  if(isInApp){
    if (!url.includes('http')) {
      location.href = 'browser::' + mobUrl;
    } else {
      location.href = 'browser::' + url;
    }
  }else{
    if (name === null || name === undefined){
      name= '_blank'
    }
    const popUpObj = options ? window.open(url, name, options) : window.open(url, name);
    if (popUpObj === null || popUpObj === undefined){
      // alert('차단된 팝업창을 허용해 주십시오.');
      alert(popupBlock);
    }else{
      popUpObj.focus();
    }
  }
}

export const nowWinOpen = (url, options) => {
  if(isInApp){
    location.href = 'browser::'+url;
  }else{
    const popUpObj = options ? window.open(url, '_blank', options) : window.open(url);
    if (popUpObj === null || popUpObj === undefined){
      // alert('차단된 팝업창을 허용해 주십시오.');
      alert(popupBlock);
    }else{
      popUpObj.focus();
    }
  }
}

export const replaceDateToYmd = (date, format= '-') => {
  if(date) {
    return date.replaceAll(format, '');
  }
}

// 날짜 일수 구하기
export const dateDiff = (d1, d2) => {
  const date1 = new Date(d1);
  const date2 = new Date(d2);

  let diffDate = Math.abs(date1.getTime() - date2.getTime());
  return Math.ceil(diffDate / (1000 * 60 * 60 * 24))+1; // 밀리세컨 * 초 * 분 * 시 = 일
}

const prvdrCds = ['10812']; // KB WITH(KG에듀원) - 추후 추가 가능

export const winLearnOpen = (item) => {
  if(prvdrCds.includes(item.prvdrCd)){
    if(mobileUtils.iOS() || mobileUtils.Android()){
      goCpCourse(item);
    }else{
      confirmWinopen(item);
    }
  }else{
    confirmWinopen(item);
  }
}

const confirmWinopen = (item) => {
  if(item.etnLrnUrl){
    // if(confirm('해당 과정은 외부 과정이므로 스타런이 아닌 외부 학습 홈페이지를 통해 학습이 가능합니다.\n\n외부 학습 홈페이지로 이동하시겠습니까?')){
    //   winOpen(item.etnLrnUrl);
    // }

    showConfirmCustomBtn({
      text: "해당 과정은 외부 과정이므로 스타런이 아닌 외부 학습 홈페이지를 통해 학습이 가능합니다.<br/><br/>외부 학습 홈페이지로 이동하시겠습니까?",
      okBtnTitle: "바로가기",
      callback: ()=>{
        winOpen(item.etnLrnUrl);
      }
    });



  }else{
    showMessage('별도의 학습페이지가 없습니다.');
  }
}

const goCpCourse = (item) => {
  if(navigator.userAgent.match(/Android/)) {
    if(confirm("해당 과정은 KB W.I.T.H 앱을 통하여 학습하셔야 합니다.\n\n앱 설치가 되어있지 않은경우 아래 확인 버튼을 터치하여 앱 설치 바랍니다.\n\n 앱 설치화면으로 이동하시겠습니까?"))
      location.href="browser::http://appdown.passone.net/kbwith/";
  } else {
    if (confirm("그룹통합 W.I.T.H으로 연결하시겠습니까?\n\n미리 KB WITH 앱이 설치되어야 합니다.")) {
      let scheme = '';
      if ('10812' === item.prvdrCd) {
        scheme = 'kbwith';
      }
      let phone_gb = "";
      const userAgent = navigator.userAgent;
      if (userAgent.match(/Android/)) phone_gb = "1";
      else if (userAgent.match(/iPhone|iPad|iPod/)) phone_gb = "2";

      const url = scheme + "://";
      const androidStoreUrl = "browser::http://appdown.passone.net/kbwith/";
      const iosStoreUrl = "browser::http://appdown.passone.net/kbwith/";

      //Android
      if (phone_gb == "1") {
        if (userAgent.match(/Chrome/)) {
          const chkApps = window.Android.executeApps("kbwith", "net.passone.hrd_kbgroup");

          if (chkApps === "true") {
            location.href = "intent://kbapp#Intent;scheme=kbwith;package=net.passone.hrd_kbgroup;end";
          } else {
            if (confirm("KB WITH를 이용하려면 해당 앱을 설치하여야 합니다.\n\n앱 설치화면으로 이동하시겠습니까?"))
              location.href = androidStoreUrl;
          }

        } else {
          setTimeout(function () {
            if (confirm("KB WITH를 이용하려면 해당 앱을 설치하여야 합니다.\n\n앱 설치화면으로 이동하시겠습니까?"))
              location.href = androidStoreUrl;

          }, 1500);

          const iframe = document.createElement('iframe');
          iframe.style.visivility = 'hidden';
          iframe.src = url;
          document.body.appendChild(iframe);
          document.body.removeChild(iframe); //back호출 시 캐싱될 수 있으므로 제거.
        }
        //IOS
      } else if (phone_gb === "2") {
        setTimeout(function () {
          if (confirm("KB WITH전용앱을 호출 하였습니다. 앱이 실행되지 않는다면 '확인'을 눌러 KB WITH앱 설치를 진행하시기 바랍니다."))
            location.href = iosStoreUrl;
        }, 1500);
        location.href = "browser::" + url;
      }
    }
  }

};

export const getImg = (src) => {
  return`${process.env.VUE_APP_CLOUDFRONT_URL}/${src}`;
};

export const getPreviousBusinessDay = (date, days, format) => {
  let matchCnt = 1;
  let targetDate = new Date(date);
  while(matchCnt <= days){
    targetDate.setTime(targetDate.getTime() - (24*60*60*1000));
    if(targetDate.getDay() == 0 || targetDate.getDay() == 6){
      // weekend
    }else if(Object.keys(holyDay).includes(dateToDateFormat(targetDate, 'yyyyMMdd'))){
      // holiday
    }else{
      matchCnt++;
    }
  }
  return dateToDateFormat(targetDate, format);
};

export const getNextBusinessDay = (date, days, format) => {
  let matchCnt = 1;
  let targetDate = new Date(date);
  while(matchCnt <= days){
    targetDate.setTime(targetDate.getTime() + (24*60*60*1000));
    if(targetDate.getDay() == 0 || targetDate.getDay() == 6){
      // weekend
    }else if(Object.keys(holyDay).includes(dateToDateFormat(targetDate, 'yyyyMMdd'))){
      // holiday
    }else{
      matchCnt++;
    }
  }
  return dateToDateFormat(targetDate, format);
};

//일반연락처 체크
export const isTelFormat = (tel) => {
	if(tel == ""){
		return true;
	}
	var phoneRule = /^(070|02|0[3-9]{1}[0-9]{1})[0-9]{3,4}[0-9]{4}$/;
	return phoneRule.test(tel);
}

export const isHpTelFormat = (hp) => {
  if(hp == ""){
		return true;
	}
	var phoneRule = /^(01[016789]{1})[0-9]{3,4}[0-9]{4}$/;
	return phoneRule.test(hp);
}

// 앱 호출
export const excuteApps_webview = (JobJson) => {
  executeApps('WEBVIEW', JSON.parse(JobJson));
  // if(isInAppAnd){
  //   window.Android.WEBVIEW(JobJson);
  //   console.log('android excute apps', JobJson);
  // }else if(isInAppIOS){
  //   window.location="iOSNativeCall://WEBVIEW?"+JobJson;
  //   console.log('ios excute apps', JobJson);
  // }else{
  //   console.log('apps device error', JobJson);
  // }
};


export const commonDownloadFile = (atchJson) =>{
  const authToken = getToken(TOKEN_KEY);
  let link = `/lmd/atchFile/${atchJson.secuKey}?t=${authToken}`;
  // console.log("commondown", link);
  winOpen(link, '_blank');
}