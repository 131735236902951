<template>
  <!-- popup-inner > popup-content -->
  <div class="popup-content">
    <!-- popup-section:퀴즈목록 -->
    <article v-if="paging.totalCount === 0" class="popup-section">
      <div class="segment-box">
        <strong class="text text-muted">현재 퀴즈 출제 이력이 없습니다. </strong>
      </div>
    </article>
    <article v-else class="popup-section">
      <header class="section-header">
        <h4 class="title">퀴즈출제결과 <span class="subtitle">{{paging.totalCount}}</span></h4>
      </header>
      <div class="section-content">
        <div class="kb-table kb-table-striped">
          <table>
            <colgroup>
              <col style="width:64px">
              <col style="width:280px">
              <col style="width:280px">
              <col style="width:120px">
              <col style="width:240px">
              <col style="width:130px">
            </colgroup>
            <thead>
            <tr>
              <th><span class="th-title">NO</span></th>
              <th><span class="th-title">모임주제</span></th>
              <th><span class="th-title">퀴즈문제지명</span></th>
              <th><span class="th-title">제출인원</span></th>
              <th><span class="th-title">출제일시</span></th>
              <th><span class="th-title">&nbsp;</span></th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="(item, idx) in items" :key="idx">
                <td><span class="td-text">{{ idx+1 }}</span></td>
                <td><span class="td-text">{{item.distCrseNm}} / {{item.objNm}}</span></td>
                <td><span class="td-text">{{item.lrnEvlNm}}</span></td>
                <td><span class="td-text">{{item.evlCnt}}</span></td>
                <td><span class="td-text">{{timestampToDateFormat(item.regDt, 'yyyy.MM.dd hh:mm')}}</span></td>
                <td class="td-right">
                  <a href="javascript:" @click="rsltDetail(item.lrnTyEvlMstSn)" class="td-link"><strong class="td-text text-primary">명단보기</strong></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>
    </article>
  </div>
  <QuizMngResultView
      v-if="isQuizMngRsltDtlShow"
      v-model:isQuizMngRsltDtlShow="isQuizMngRsltDtlShow"
      :rsltItem="rsltItem"
  />
</template>

<script>
import {computed, ref} from "vue";
import {useStore} from "vuex";
import {ACT_GET_QUIZ_RSLT_LIST} from "@/store/modules/quiz/quiz";
import {getItems, getPaging, lengthCheck, timestampToDateFormat} from "@/assets/js/util";
import QuizMngResultView from "@/components/quiz/QuizMngResultView";
import CommonPaginationFront from "@/components/CommonPaginationFront";

export default {
  name: "QuizMngResultList",
  components: {CommonPaginationFront, QuizMngResultView},
  setup() {
    const store = useStore();
    const distCrseSn = computed(() => store.state.quiz.distCrseSn);

    const items = ref([]);
    const paging = ref({pageNo: 1, pageSize: 3, totalCount: 0, hasMore: false});

    const isQuizMngRsltDtlShow = ref(false);
    const rsltItem = ref({});

    const getQuizRslt = () => {
      store.dispatch(`quiz/${ACT_GET_QUIZ_RSLT_LIST}`, {
        lrnEvlMstSn: distCrseSn.value, // 차수번호 79214
        // distObjSn: userInfo.value.lrnObjDtlDistSn, // 객체번호 // 209510
        // 필수
        pageNo: paging.value.pageNo,
        pageSize: paging.value.pageSize
      }).then(res => {
        if (lengthCheck(res)) {
          items.value = getItems(res);
          items.value.map(x => ({
            ...x,
            regDt: timestampToDateFormat(x.regDt, 'yyyy-MM-dd hh:mm:ss')
          }));
          paging.value = getPaging(res);
        } else {
          items.value = [];
        }
      }).catch(e => {
        console.error(e);
      });
    };

    getQuizRslt();

    const rsltDetail = (quizSn) => {
      rsltItem.value = items.value.filter(x => x.lrnTyEvlMstSn === quizSn)[0];
      isQuizMngRsltDtlShow.value = true;
    }

    const pagingFunc = (pageNo) => {
      if (pageNo > 0) paging.value.pageNo = pageNo;
      getQuizRslt();
    };


    return {
      items,
      paging,
      isQuizMngRsltDtlShow,
      rsltItem,
      rsltDetail,
      timestampToDateFormat,
      pagingFunc
    }
  }
}
</script>