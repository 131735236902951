<template>
  <div class="util util-more">
    <div class="kb-form-layer"  :class="{'is-active': modelValue }" >
      <button class="kb-form-layer-btn" @click="$emit('update:modelValue',!modelValue);"><i class="icon-more"></i></button>
      <div class="layer-container">
        <div class="kb-top-layer" @click.stop.prevent="$emit('update:modelValue',false)" />
        <div class="layer">
          <div class="kb-form-layer-options">
            <div class="layer-option">
              <ul class="layer-option-list">
                <li class="layer-option-item">
                  <a href="javascript:" class="layer-option-link" @click.stop="handleHome">
                    <strong class="layer-option-text">홈</strong>
                  </a>
                </li>
                <li v-if="false" class="layer-option-item">
                  <a href="javascript:" class="layer-option-link" @click.stop="handleMap">
                    <strong class="layer-option-text">클라우드 맵</strong>
                  </a>
                </li>
                <li class="layer-option-item">
                  <router-link :to="{name: 'HistoryHrdCloud'}" class="layer-option-link">
                  <strong class="layer-option-text">My 클라우드</strong></router-link>
                </li>
                <li class="layer-option-item">
                  <a class="layer-option-link" @click.stop="handleKbTube">
                    <strong class="layer-option-text">Hot Tube 등재</strong>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.layer-container { flex-direction:column; }
.kb-top-layer { width:100%;flex:1 1 auto;position:relative;overflow:hidden; }
.layer {height:auto;}
</style>
<script>
import {useRouter} from 'vue-router';

export default {
  name: 'HrdCloudMobileSubMenu',
  props: {
    modelValue: Boolean,
    showHrdCloudMap: Boolean,
    showKbTubeModal: Boolean,
    tabMenu: Number,
  },
  emits: ['update:modelValue', 'update:showHrdCloudMap', 'update:tabMenu', 'update:showKbTubeModal'],
  setup(props, {emit}) {
    const router = useRouter();
    const handleHome = () => {
      emit('update:modelValue',!props.modelValue);
      router.push({name:'HrdCloud'});
    };

    const handleMap = () => {
      emit('update:modelValue',!props.modelValue);
      emit('update:showHrdCloudMap',!props.showHrdCloudMap);
    };

    const handleKbTube = () => {
      emit('update:modelValue', !props.modelValue);
      emit('update:showKbTubeModal', !props.showKbTubeModal);
    }

    return {
      handleMap, handleHome, handleKbTube
    };
  }
}
</script>
