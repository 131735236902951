<template>
  <div class="popup-container" :class="{ 'is-active' : modelValue }">
    <div class="popup" id="popup-wmpd-result">
      <div class="popup-inner">
        <div class="popup-header">
          <h3 class="title">포트폴리오 성적</h3>
        </div>
        <div class="popup-section">
          <div class="kb-table kb-table-bordered-v2">
            <table>
              <colgroup>
                <col style="width:10%"/>
                <col style="width:10%"/>
                <col style="width:auto"/>
                <col style="width:10%"/>
                <col style="width:10%"/>
                <col style="width:10%"/>
              </colgroup>
              <thead>
                <tr>
                  <th><span class="th-title">포트폴리오</span><br/><span class="th-title">유형</span></th>
                  <th><span class="th-title">자산군</span></th>
                  <th><span class="th-title">상품명</span></th>
                  <th><span class="th-title">수익률(%)</span></th>
                  <th><span class="th-title">변동성</span><br/><span class="th-title">(표준편차)</span></th>
                  <th><span class="th-title">점수</span></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowspan="3">퇴직연금 추천상품</td>
                  <td>주식형</td>
                  <td>{{portfolio.pfaSItemName}}</td>
                  <td>{{portfolio.pfaSRetrt}}</td>
                  <td>{{portfolio.pfaSVariab}}</td>
                  <td rowspan="3">{{portfolio.pfaScore}}</td>
                </tr>
                <tr>
                  <td>채권형</td>
                  <td>{{portfolio.pfaBItemName}}</td>
                  <td>{{portfolio.pfaBRetrt}}</td>
                  <td>{{portfolio.pfaBVariab}}</td>
                </tr>
                <tr>
                  <td>기타</td>
                  <td>{{portfolio.pfaEItemName}}</td>
                  <td>{{portfolio.pfaERetrt}}</td>
                  <td>{{portfolio.pfaEVariab}}</td>
                </tr>
                <tr>
                  <td rowspan="3">온국민투자 추천상품</td>
                  <td>주식형</td>
                  <td>{{portfolio.pfbSItemName}}</td>
                  <td>{{portfolio.pfbSRetrt}}</td>
                  <td>{{portfolio.pfbSVariab}}</td>
                  <td rowspan="3">{{portfolio.pfbScore}}</td>
                </tr>
                <tr>
                  <td>채권형</td>
                  <td>{{portfolio.pfbBItemName}}</td>
                  <td>{{portfolio.pfbBRetrt}}</td>
                  <td>{{portfolio.pfbBVariab}}</td>
                </tr>
                <tr>
                  <td>기타</td>
                  <td>{{portfolio.pfbEItemName}}</td>
                  <td>{{portfolio.pfbERetrt}}</td>
                  <td>{{portfolio.pfbEVariab}}</td>
                </tr>
<!--                <tr>-->
<!--                  <td rowspan="3">안정추구형</td>-->
<!--                  <td>주식형</td>-->
<!--                  <td>{{portfolio.pfcSItemName}}</td>-->
<!--                  <td>{{portfolio.pfcSRetrt}}</td>-->
<!--                  <td>{{portfolio.pfcSVariab}}</td>-->
<!--                  <td rowspan="3">{{portfolio.pfcScore}}</td>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                  <td>채권형</td>-->
<!--                  <td>{{portfolio.pfcBItemName}}</td>-->
<!--                  <td>{{portfolio.pfcBRetrt}}</td>-->
<!--                  <td>{{portfolio.pfcBVariab}}</td>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                  <td>기타</td>-->
<!--                  <td>{{portfolio.pfcEItemName}}</td>-->
<!--                  <td>{{portfolio.pfcERetrt}}</td>-->
<!--                  <td>{{portfolio.pfcEVariab}}</td>-->
<!--                </tr>-->
                <tr>
                  <th colspan="3"><span class="th-title">합계</span></th>
                  <th colspan="3"><span class="th-title">{{portfolio.totScore}}</span></th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="popup-buttons mb-6">
        <button class="kb-btn kb-btn-lg kb-btn-secondary" @click="closeModal"><strong class="text">닫기</strong></button>
      </div>
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
  </div>
</template>

<script>
import {reactive} from "vue";
import {getItem, initParams, lengthCheck, setParams} from "@/assets/js/util";
import {ACT_GET_PORTFOLIO} from "@/store/modules/otherdept/wmportfolio";
import {useStore} from "vuex";

export default {
  name: 'ProtfolioResult',
  props: {
    modelValue: Boolean,
    pfSeq: String,
  },
  emits: ['update:modelValue', 'update:pfSeq'],
  setup(props, {emit}) {
    const store = useStore();
    const portfolio = reactive({
      // 유형 A
      pfaScore: 0,
      pfaSKrcd: '',
      pfaSItemName: '',
      pfaSRetrt: 0,
      pfaSVariab: '',
      pfaBItemName: '',
      pfaBRetrt: 0,
      pfaBVariab: '',
      pfaEItemName: '',
      pfaERetrt: 0,
      pfaEVariab: '',
      // 유형 B
      pfbScore: 0,
      pfbSKrcd: '',
      pfbSItemName: '',
      pfbSRetrt: 0,
      pfbSVariab: '',
      pfbBItemName: '',
      pfbBRetrt: 0,
      pfbBVariab: '',
      pfbEItemName: '',
      pfbERetrt: 0,
      pfbEVariab: '',
      // 유형 C
      pfcScore: 0,
      pfcSKrcd: '',
      pfcSItemName: '',
      pfcSRetrt: 0,
      pfcSVariab: '',
      pfcBItemName: '',
      pfcBRetrt: 0,
      pfcBVariab: '',
      pfcEItemName: '',
      pfcERetrt: 0,
      pfcEVariab: '',
      // 기타 정보
      totScore: 0,
    })

    const getPortFolio = () => {
      store.dispatch(`wmportfolio/${ACT_GET_PORTFOLIO}`, props.pfSeq).then(res => {
        if (lengthCheck(res)) {
          setParams(portfolio,  getItem(res));
        }
      }).catch(e => {
        console.error(e);
      });
    }
    getPortFolio();


    const closeModal = () => {
      initParams(portfolio);
      emit('update:pfSeq', null);
      emit('update:modelValue', false);
    }

    return {
      portfolio,
      closeModal,
    }
  }
}
</script>