<template>
  <article class="home-section section-sbadge">
    <header class="section-header">
      <h3 class="title">배지<small class="text-muted">{{myBadges.length}}/{{activeBadges.length}}</small></h3>
    </header>
    <div class="section-body">
      <swiper
          class="sbadge-container"
          :modules="modules"
          slides-per-view="auto"
          :speed="750"
          :pagination="{
            el: '.sbadge-pagination',
            clickable: true
          }"
      >
        <swiper-slide v-for="(badgeItems, idx) in activeBadgesItems" class="sbadge-page mobile-sbadge-wrapper" :key="idx" >
<!--          <div class="badge hrd-badge animate__animated animate__heartBeat" style="height:121px !important; background: none;">-->
          <SSLBadgeTitle v-for="(badge, i) in badgeItems"
              :key="`${idx}-${i}`"
              :badge-ty-cd-dcd="badge.badgeTyCdDcd"
              :is-active="badge.isActive"
              :is-new="badge.isNew"
              :hidden-yn="badge.hiddenYn"
              :badge-data="badge"
              :show-title="true"
               @click="showBadgeModal(badge)"
          />
<!--          </div>-->
        </swiper-slide>
        <div class="sbadge-pagination swiper-pagination"></div>
      </swiper>
    </div>

    <SSLBadgeViewModal
        v-model="badgeModal"
        :selected-badge="selectedBadge"
    />

    <div class="section-bottom mt-4" style="padding-left: 20px; padding-right: 20px;">
      <router-link :to="{ name: 'BadgeBox' }" custom v-slot="{ navigate }">
        <button class="kb-btn-content-item"
                @click="navigate" @keypress.enter="navigate" role="link">배지 보관함</button>
      </router-link>
    </div>

  </article>
</template>
<script>
import {Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import SSLBadgeTitle from "@/components/ssl/badge/SSLBadgeTitle";
import {computed, ref} from "vue";
import {useStore} from "vuex";
import {getBadgeList} from "@/assets/js/modules/ssl/ssl-badge";
import SSLBadgeViewModal from "@/components/ssl/badge/mobile/SSLBadgeViewModal";
import {getListFunc} from "@/assets/js/ui.init";
import {ACT_GET_COM_BADGE_TY_LIST} from "@/store/modules/badge/badge";


export default {
  name: 'SSLMobileHomeBadge',
  components: {
    SSLBadgeViewModal,
    Swiper,
    SwiperSlide,
    SSLBadgeTitle
  },
  setup() {
    const store = useStore();
    const myBadges = computed(() => store.state.ssl.myBadges);
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear().toString();
    const currentTimestamp = currentDate.getTime();
    // const newRange = 1000 * 60 * 60 * 24 * 7;
    const sslBadges = ref([]);

    getListFunc(`badge/${ACT_GET_COM_BADGE_TY_LIST}`, {year: currentYear, badgeKindCdDcd: "2065001"}, sslBadges, null);

    const activeBadges = computed(() => {
      // return sslBadges.value.filter(x => !x.showYears || x.showYears.includes(currentYear)).map(x => {
      return sslBadges.value.filter(x => x.year === currentYear).map(x => {
        const targetBadge = {
          badgeTySn: x.badgeTySn,
          badgeTyCdDcd: x.badgeTyCdDcd,
          isActive: false,
          isNew: false,
          hiddenYn: x.hiddenYn,
          badgeName: x.badgeTyNm,
          badgeDesc: x.cdDesc
        };
        const found = myBadges.value.filter(y => x.badgeTyCdDcd === y.badgeTyCdDcd);

        if(found.length>0){
          targetBadge.isActive = true;
          targetBadge.isNew = found.getDt + (1000 * 60 * 60 * 24 * 7) >= currentTimestamp;
        }else{
          if (targetBadge.hiddenYn === 'Y') {
            targetBadge.badgeName = x.hiddenBadgeTyNm;
            targetBadge.badgeDesc = x.hiddenBadgeCn;
          }
        }

        return targetBadge;
      }).sort((a,b) => {
        if (a.badgeTySn < b.badgeTySn) { return -1; }
        else {return 1;}
      }).sort((a) => {
        if (a.isActive) { return -1; }
        else {return 1;}
      }).sort((a) => {
        if (a.isNew) { return -1; }
        else {return 1;}
      });
    });

    const activeBadgesItems = computed(() => {
      const result = [];
      for(let i=0; i <= activeBadges.value.length/6; i++){
        result.push([]);
        for(let j=0; j < 6; j++){
          if(activeBadges.value[i*6 + j]){
            result[i][j] = activeBadges.value[i*6 + j];
          }
        }
      }
      return result;
    });

    const badgeModal = ref(false);
    const selectedBadge = ref(null);

    const showBadgeModal = (badge) => {
      selectedBadge.value = badge;
      badgeModal.value = true;
    }


    getBadgeList();
    return {
      myBadges,
      selectedBadge,
      activeBadges,
      activeBadgesItems,
      badgeModal,
      showBadgeModal,

      sslBadges,
      modules: [Pagination]
    }

  }
}
</script>
