import {useStore} from "vuex";
import {computed, reactive, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {
    addDays,
    getItem, getItems, getPaging,
    getTodayDate,
    initPaging, isSuccess,
    lengthCheck, secondToKrFormat,
    setParams,
    timestampToDateFormat
} from "@/assets/js/util";
import {useAlert} from "@/assets/js/modules/common/alert";
import {
    ACT_GET_GROUP_LRN_ATTN_STATUS_LIST,
    ACT_GET_GROUP_LRN_BOARD_HRDCLOUD,
    ACT_GET_GROUP_LRN_BOARD_MNG, ACT_UPDATE_GROUP_LEARN_BOARD_CN
} from "@/store/modules/board/board";
import {goBack} from "@/assets/js/modules/common/common";
import {getThumbUrl} from "@/assets/js/modules/course/course-common";

export const groupLearningJournalSetup = () => {
    const store = useStore();
    const session = computed(() => store.state.auth.session);

    const route = useRoute();
    const router = useRouter();

    const attnItems = ref([]);

    const paging = ref(initPaging(route, 1000));


    const {showConfirm, showMessage} = useAlert();

    const item = reactive({
        distCrseSn: 0,
        distCrseNm: '',
        objNm: '',
        lrnObjTyCd: '',
        distCrseLrnFldNm: '',
        grpRoleCdDcd: '',
        bgngYmd: '',
        objBgngDt	: '',
        objEndDt: '',
        cn: '',
        avgLrnTime: 0,
        bgngLrn: '',
        endLrn: '',
        mtgAcptYn: 'N'
    });

    const cttItems = ref([]);
    const cttPaging = ref(initPaging(route));

    const writeYmd = reactive({
        startYmd: '',
        endYmd: ''
    });

    const today = getTodayDate('yyyyMMdd');



    const getBoard = () => {
        store.dispatch(`board/${ACT_GET_GROUP_LRN_BOARD_MNG}`, {
            boardSn: route.params.boardSn,
            lrnPostSn: route.params.lrnPostSn
        }).then(res => {
            if(lengthCheck(res)) {
                const boardItem = getItem(res);

                // 모임일지 등록기간 계산(모임일지 작성 가능 상태(모임종료일 다음날을 시작으로 +14일) / 이후 [등록] 버튼 비노출)
                writeYmd.startYmd = timestampToDateFormat(addDays(boardItem.objBgngDt, 1), 'yyyy.MM.dd');
                writeYmd.endYmd = timestampToDateFormat(addDays((writeYmd.startYmd), 14), 'yyyyMMdd');

                setParams(item, boardItem);

                item.objBgngDt = timestampToDateFormat(item.objBgngDt, 'hh:mm');
                item.objEndDt = timestampToDateFormat(item.objEndDt, 'hh:mm');

                // params.cn = item.cn;
            }
        }).catch(e => {
            console.error(e);
        });
    }

    const cmptnCnt = ref(0);

    const isWrite = computed(() => {
        return item.grpRoleCdDcd === '2010001' && today > (writeYmd.startYmd).replaceAll('.', '') && writeYmd.endYmd >= today;
    });

    // 모임 현황(멤버별)
    const getGroupLrnAttnStatusList = () => {
        store.dispatch(`board/${ACT_GET_GROUP_LRN_ATTN_STATUS_LIST}`,{
            boardSn: route.params.boardSn,
            lrnPostSn: route.params.lrnPostSn,
            pageNo: paging.value.pageNo,
            pageSize: paging.value.pageSize
        }).then(res => {
            if(lengthCheck(res)){
                attnItems.value = getItems(res).map(x => ({
                    ...x,
                    regDt: timestampToDateFormat(x.regDt,'hh:mm:ss'),
                    mdfcnDt: timestampToDateFormat(x.mdfcnDt,'hh:mm:ss'),
                }));

                //cmptnYn
                cmptnCnt.value = attnItems.value.filter(x => x.rsltYn === 'Y').length;
                paging.value = getPaging(res)

            } else {
                attnItems.value = [];
                paging.value.totalCount = 0;
            }
        })
    }

    const coldCallRound = computed(() => attnItems.value.map(x => (x.coldCallRound || 0)).reduce(add,0));
    const coldCallRspnsCnt = computed(() => attnItems.value.map(x => (x.coldCallRspnsCnt || 0)).reduce(add,0));

    const add = (accumulator, a) => {
        return accumulator + a;
    }

    const getGroupLrnBoardHrdcloud = () => {
        store.dispatch(`board/${ACT_GET_GROUP_LRN_BOARD_HRDCLOUD}`, {
            lrnPostSn: route.params.lrnPostSn,
            pageNo: cttPaging.value.pageNo,
            pageSize: cttPaging.value.pageSize
        }).then(res => {
            if(lengthCheck(res)) {
                cttItems.value = getItems(res);
                cttPaging.value = getPaging(res);
            } else {
                cttItems.value = [];
                cttPaging.value.totalCount = 0;
            }
        })
    }

    getGroupLrnBoardHrdcloud();

    const clickSave = () => {
        showConfirm({
            text: '모임일지를 등록하시겠습니까?',
            callback: () => {
                updateGroupLearnBoardCn();
            }
        })
    }

    const updateGroupLearnBoardCn = () => {
        store.dispatch(`board/${ACT_UPDATE_GROUP_LEARN_BOARD_CN}`, {
            lrnPostSn: route.params.lrnPostSn,
            params: {cn: item.cn}
        }).then(res => {
            if(isSuccess(res)) {
                showMessage({
                    text: '등록 완료됐습니다.',
                    callback: () => {
                        router.push({name: 'GroupView', params: {distCrseSn: item.distCrseSn}})
                    }
                })
            }
        }).catch(e => {
            console.error(e);
        })
    };

    const pagingFunc = (pageNo, pageSize) => {
        const query = {type: route.query.type, pageNo: pageNo};
        if (pageSize !== paging.value.pageSize) {
            query.pageSize = pageSize;
        }
        router.push({query: query});
    };

    watch(() => route.query,() => {
        if (route.name === 'GroupLearningJournal') {
            if (route.query.pageNo) {
                paging.value.pageNo = route.query.pageNo;
            } else {
                paging.value.pageNo = 1;
            }
            if (route.query.pageSize) {
                paging.value.pageSize = route.query.pageSize;
            }
        }
        getGroupLrnAttnStatusList();
    });

    getBoard();
    getGroupLrnAttnStatusList();

    const clickBack = () => {
        router.push({name: 'GroupView', params: {distCrseSn: item.distCrseSn}})

    }

    return{
        item,
        attnItems,
        cttItems,
        cmptnCnt,

        paging,
        isWrite,
        session,
        coldCallRound,
        coldCallRspnsCnt,
        secondToKrFormat,
        clickSave,
        pagingFunc,
        goBack,
        clickBack,
        getThumbUrl,
        timestampToDateFormat
    }
}