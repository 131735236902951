import ApiService from '@/assets/js/api.service';

export const ACT_SEND_LOGIN_CERT_NO = 'actSendLoginCertNo';
export const ACT_SEND_MLOGIN_PUSH_SEND = 'actSendMLoginPushSend';
export const ACT_SEND_MLOGIN_CHECK_VERIFICATION = 'actSendMloginCheckVerification';

const state = {
};

const mutations = {
};

const actions = {
  [ACT_SEND_LOGIN_CERT_NO](context, params) {
    return new Promise(resolve => {
      ApiService.post('/v1/app/certs', params).then(response => resolve(response));
    });
  },
  [ACT_SEND_MLOGIN_PUSH_SEND](context, params) {
    return new Promise(resolve => {
      ApiService.post('/v1/app/certs/mlogin/pushsend', params).then(response => resolve(response));
    });
  },
  [ACT_SEND_MLOGIN_CHECK_VERIFICATION](context, params){
    return new Promise(resolve => {
      ApiService.post(`/v1/app/auth/mlogin/checkverification`, params).then(response => resolve(response))
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};