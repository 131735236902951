<template>
  <div class="popup-container p-0 d-block" id="kb-mobile-popup" :class="{ 'is-active' : show }">
    <HrdCloudMobileHeader title="공유 목록" :show-back="true" :go-back-func="closeShareList" />
    <main class="kb-main" id="kb-support">
      <div class="main-content main-component">
        <div class="view-contents">

        <article class="content-section">
<!--          <header class="section-header">-->
<!--            <h4 class="title">지원내용</h4>-->
<!--          </header>-->
          <LoadingDiv v-if="isLoading"/>
          <template v-else>
            <template v-if="items && items.length > 0">
              <div class="section-content">
                <div class="content-item" v-for="(item,idx) in items"  :key="idx">
                  <h5 class="b-title">{{ item.no }}.</h5>
                  <ul class="desc-type-title">
                    <li>
                      <h6>콘텐츠</h6>
                      <p>{{ item.objNm  }}</p>
                    </li>
                    <li>
                      <h6>공유대상</h6>
                      <p>{{ item.shareTrgtLrnerNmLst  }}</p>
                    </li>
                    <li>
                      <h6>공유일자</h6>
                      <p>{{ timestampToDateFormat(item.regDt, 'yyyy.MM.dd') }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </template>
            <template v-else-if="items && items.length === 0">
              <div class="section-content">
                <div class="content-item" >
                    <div class="result-empty">
                      <img src="../../../../assets/lxp/images/common/img_empty.png" alt="">
                      <p class="text">구독중인 채널이 없습니다.</p>
                    </div>
                </div>
              </div>
            </template>
          </template>
          <CommonPaginationFront :paging="paging" :page-func="pagingFunc" />
        </article>
        </div>
      </div>
    </main>
  </div>
</template>



<script>
import CommonPaginationFront from "@/components/CommonPaginationFront.vue";
import HrdCloudMobileHeader from "@/components/hrdcloud/mobile/HrdCloudMobileHeader.vue";
import LoadingDiv from "@/components/common/LoadingDiv.vue";
import {computed, reactive, ref, watch} from "vue";
import {closeShareList} from '@/assets/js/modules/hrd/hrd-common';
import {getListFunc} from "@/assets/js/ui.init";
import {ACT_GET_HRDCLOUD_HISTORY_SHARE_LIST} from "@/store/modules/history/history";
import {useStore} from "vuex";
import {timestampToDateFormat} from "@/assets/js/util"; //dateToDatePart

export default {
  name: 'HrdCloudHistoryMobileShareModal',
  components: {CommonPaginationFront, LoadingDiv, HrdCloudMobileHeader},
  setup() {

    const items = ref([]);
    const paging = reactive({
      pageNo: 1,
      pageSize: 5,
      totalCount: 0
    });

    // const router = useRouter();
    const store = useStore();
    const isLoading = ref(true);
    const show = computed(() => store.state.hrdcloud.share.show);
    // eslint-disable-next-line no-unused-vars
    const year = computed(() => store.state.hrdcloud.share.year
    // {
    //   if (store.state.hrdcloud.share.year && store.state.hrdcloud.share.year.length > 0) {
    //     return store.state.hrdcloud.share.year
    //   } else {
    //     return dateToDatePart(new Date()).year;
    //   }
    // }
    );

    const getList = () => {
      getListFunc(`history/${ACT_GET_HRDCLOUD_HISTORY_SHARE_LIST}`, {
        year:year.value,
        pageNo: paging.pageNo,
        pageSize: paging.pageSize,
        totalCount: paging.totalCount,
      }, items, paging, () => {isLoading.value=false;}, ()=>{isLoading.value=false;});
    };

    const pagingFunc = (pageNo) => {
      if (pageNo > 0) paging.pageNo = pageNo;
      getList();
    };

    watch(() => show.value, () => {
      if (!show.value) {
        paging.pageNo = 1;
      } else {
        getList();
      }
    });

    return{
      items, paging, show, isLoading, pagingFunc, timestampToDateFormat, closeShareList
    }

  }
};
</script>