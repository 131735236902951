import {computed, ref, watch} from "vue";
import {useStore} from "vuex";
import {useAlert} from "@/assets/js/modules/common/alert";
import {ACT_DELETE_QUIZ, ACT_GET_QUIZ_LIST, ACT_INSERT_QUIZ} from "@/store/modules/quiz/quiz";
import {getItems, getPaging, isSuccess, lengthCheck, timestampToDateFormat} from "@/assets/js/util";

export const quizListSetup = (props) => {
    const store = useStore();

    const quizItems = ref([]);
    const paging = ref({pageNo: 1, pageSize: 10, totalCount: 0});
    const targetQuiz = ref([]);

    const quizAddModal = ref(false);
    const isQuizViewShow = ref(false);
    const isQuizListShow = ref(true);

    const quizItem = ref();

    const {showMessage, showConfirm} = useAlert();

    const distCrseSn = computed(() => store.state.quiz.distCrseSn);

    // 퀴즈목록
    const getQuizList = () => {
        store.dispatch(`quiz/${ACT_GET_QUIZ_LIST}`, {
            evlKindCdDcd: '2048098', //2048098 : ON, 2048099 : NOW
            lrnEvlMstSn: distCrseSn.value, // 차수로 생각하면됨
            // 필수
            pageNo: paging.value.pageNo,
            pageSize: paging.value.pageSize,
        }).then(res => {
            if (lengthCheck(res)) {
                quizItems.value = getItems(res).map(x =>({
                    ...x,
                    regDt: timestampToDateFormat(x.regDt,  'yyyy.MM.dd')
                }));
                paging.value = getPaging(res);
            } else {
                quizItems.value = [];
                paging.value.totalCount = 0;
            }
        }).catch(e => {
            console.error(e);
        });
    };

    const quizDtlSn = ref(0);

    const quizDetail = (item) => {
        console.log('item')
        quizDtlSn.value = item.lrnTyEvlMstSn;
        quizItem.value = item;
        isQuizViewShow.value = true;
        isQuizListShow.value = false;
    }

    const addQuiz = () => {
        quizAddModal.value = true;
    }

    const insertQuiz = (quizTitle) => {
        if(quizTitle === ''){
            showMessage('퀴즈문제지명을 입력해주세요.');
            return false;
        }

        showConfirm('퀴즈문제지를 추가하시겠습니까?', () => {
            store.dispatch(`quiz/${ACT_INSERT_QUIZ}`, {
                lrnEvlNm: quizTitle,
                evlKindCdDcd: '2048098',
                lrnEvlMstSn: distCrseSn.value, // 차수번호
            }).then(res => {
                if(isSuccess(res)){
                    quizAddModal.value = false;
                    getQuizList();
                }
            }).catch(e => {
                console.error(e);
            });
        });
    }

    // 퀴즈삭제
    const deleteQuiz = () => {
        if(targetQuiz.value.length === 0) {
            showMessage('퀴즈 문제지를 선택해주세요.');
            return false;
        }
        // 삭제할 퀴즈 여러개 선택시 순차적으로 삭제
        showConfirm({
            text: '선택한 퀴즈 문제지를 삭제 하시겠습니까?',
            callback: () => {
                doDelete();
            }
        })

    }

    // 퀴즈삭제 콜백
    const doDelete = () => {
        store.dispatch(`quiz/${ACT_DELETE_QUIZ}`, {delQuizList: targetQuiz.value}).then(res => {
            if(isSuccess(res)){
                showMessage({
                    text: '퀴즈 문제지를 삭제했습니다.',
                    callback: () => {
                        getQuizList();
                    }
                });
            }
        }).catch(e => {
            console.error(e);
        });
    }

    const checkAllQuiz = () => {
        quizItems.value.filter(x => {
           targetQuiz.value.push(x.lrnTyEvlMstSn);
        });

        if(quizItems.value.length === targetQuiz.value.length) {
            const tempSet = new Set(targetQuiz.value);
            targetQuiz.value = [...tempSet];
        } else {
            targetQuiz.value = [];
        }
    }


    getQuizList();

    watch(() => props.isAddQuiz, ()=>{
        addQuiz();
    });

    return {
        quizItems,
        quizItem,
        quizDtlSn,
        paging,
        quizAddModal,
        isQuizViewShow,
        isQuizListShow,
        targetQuiz,
        getQuizList,
        insertQuiz,
        addQuiz,
        quizDetail,
        deleteQuiz,
        checkAllQuiz
    }
}