<template>
  <div class="kb-form-row">
    <slot name="first" />
    <div v-if="title" class="kb-form-column kb-form-column-title">
      <label class="kb-form-label">
        <strong class="kb-form-label-text" v-html="title"></strong>
      </label>
    </div>
    <div class="kb-form-column" :class="classLst">
      <template v-if="isNumber">
        <input v-if="max > 0"
               type="text"
               class="kb-form-control"
               :value="modelValue"
               :maxlength="max"
               :placeholder="placeholder"
               :disabled="disabled"
               @input="updateValue"
               @focusin="handleFocus"
               @focusout="handleFocus"
        />
        <input v-else-if="isMoney"
               ref="inputEl"
               type="text"
               class="kb-form-control"
               :class="classLst"
               :value="numberComma(modelValue)"
               @input="updateValue"
               :placeholder="placeholder"
               :disabled="disabled"
               @focusin="handleFocus"
               @focusout="handleFocus"
        />
        <input v-else-if="isFloat"
               type="text"
               class="kb-form-control"
               :value="modelValue"
               @input="floatFormatNum"
               :placeholder="placeholder"
               :disabled="disabled"
               @focusin="handleFocus"
               @focusout="handleFocus"
        />
        <input v-else
               type="text"
               class="kb-form-control"
               :value="modelValue"
               @input="updateValue"
               :placeholder="placeholder"
               :disabled="disabled"
               @focusin="handleFocus"
               @focusout="handleFocus"
        />
      </template>
      <template v-else-if="isTextNumber">
        <input type="text"
               class="kb-form-control"
               :value="modelValue"
               @input="updateValue"
               :placeholder="placeholder"
               :disabled="disabled"
        />
      </template>
      <input v-else
             type="text"
             class="kb-form-control"
             :value="modelValue"
             @input="updateValue"
             :placeholder="placeholder"
             :disabled="disabled"
      />
      <slot name="last" />
    </div>
    <div v-if="isKrw" class="kb-form-column column-side">
      <span class="kb-form-text">원</span>
    </div>
  </div>
</template>

<script>
import {
  emptyNumberOnClick,
  numberComma,
  removeComma,
  numberOnlyChk,
  floatNumChk
} from '@/assets/js/util';
import {useAlert} from "@/assets/js/modules/common/alert";

export default {
  name: 'RowInputText',
  props: {
    modelValue: [String, Number],
    title: String,
    classLst: String,
    isNumber: { type: Boolean, default: false },
    isFloat: {type: Boolean, default: false},
    isMoney: { type: Boolean, default: false },
    isTextNumber: { type: Boolean, default: false },
    disabled: { type:Boolean, default: false },
    max: Number,
    placeholder: String,
    isKrw: { type: Boolean, default: false }
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const {showMessage} = useAlert();

    const floatFormatNum = (e) => {
      let val = e.target.value;
      if (props.isNumber) {

        if (!floatNumChk(val)) {
          showMessage('숫자입력만 가능합니다.');
          e.target.value = '';
          return;
        }
        val = Number(val);
      }
      emit('update:modelValue',val);
    }

    const updateValue = (e) => {
      let val = e.target.value;
      if (props.isNumber) {
        if (props.isMoney) {
          val = removeComma(val);
        }

        if (!numberOnlyChk(val)) {
          showMessage('숫자입력만 가능합니다.');
          e.target.value = '';
          return;
        }
        val = Number(val);
      }
      if(props.isTextNumber){
        if(/[^0-9-]/g.test(val)) {
          val = val.replace(val, "");
        }

      }
      emit('update:modelValue',val);
    };
    const handleFocus = (e) => {
      if (props.isMoney) {
        emptyNumberOnClick(e);
      }
    };
    return {
      handleFocus, updateValue, numberComma, floatFormatNum
    }
  }
};
</script>