<template>
  <div class="day-wrapper" :style="getDayStyle">
    <ul class="day-list">
      <li v-if="targetCount > 1" class="day-item is-disabled">
        <div class="day-circle is-disabled">
          <span class="day">{{ targetCount - 1 }}</span>
        </div>
      </li>
      <li v-if="targetCount > 0" class="day-item">
        <div class="day-circle is-active">
          <span class="day">{{ targetCount }}</span>
        </div>
      </li>
      <li v-if="targetCount < targetMaxCount " class="day-item">
        <div class="day-circle">
          <span class="day">{{ targetCount + 1 }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import {computed} from "vue";
import {useStore} from "vuex";

export default {
  name: 'SSLDayGraph',
  props: {
    targetCount: Number,
    targetMaxCount: Number

  },
  setup(props) {
    const store = useStore();
    const isMobile = computed(() => store.state.auth.isMobile);

    const getDayStyle = computed(() => {
      if(props.targetCount === 0){
        return {
          transform: `translateY(240px)`
        }
      }else if(props.targetCount === 1){

        return {
          transform: (isMobile.value ? 'translateY(26px)' : 'translateY(90px)')
        }

      }
      return {
        transform: (isMobile.value ? 'translateY(-86px)': 'translateY(-65px)')
      }
    })


    return {
      getDayStyle
    }

  }
}
</script>
