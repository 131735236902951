<template>
  <div :class="[ isMobile ? 'hrdcloud-list-container' : 'popup-content' ]">
    <div class="hrdcloud-list-wrapper">
      <div v-if="!isMobile" class="hrdcloud-actions">
        <button type="button" class="kb-btn kb-btn-secondary kb-btn-sm rounded-lg" @click="handleNewUpload">
          <i class="icon-upload"></i>
          <span class="text">Hot Tube 등재</span>
        </button>
      </div>
      <ul class="hrdcloud-list" data-grid="3">
        <li v-for="(item, idx) in items" class="hrdcloud-item" :key="idx" @click="handleItemClick(idx)">
          <article class="hrdcloud-card">
            <div class="hrdcloud-image">
              <div class="image">
                <a href="javascript:" class="image-link">
                  <img v-if="item.thumb" class="kb-thumb-list" :src="getImg(item.thumb)" alt="임시이미지" />
                  <img v-else class="kb-thumb-list" src="../../../assets/lxp/images/@tmp/@tmp_hrdcloud_03.jpg" alt="임시이미지" />
                </a>
              </div>
              <div v-if="stringCheck(item.category2)" class="badges">
                <div class="badge-dark">
                  <span class="text">{{ item.category2 }}</span>
                </div>
              </div>
            </div>
            <div class="hrdcloud-content">
              <div class="content-category">
                <span class="text">{{ item.category3 }}</span>
              </div>
              <div class="content-title">
                <h5 class="title"><a href="javascript:" class="title-link">{{ item.cttTitle }}</a></h5>
              </div>
              <div :class="[ isMobile ? 'content-badge' : 'content-status' ]">
                <div class="badge" :class="getStatus(item.stt).classNm">
                  <span class="badge-text">{{ getStatus(item.stt).name }}</span>
                </div>
              </div>
            </div>
          </article>
        </li>
      </ul>
      <div v-if="items && items.length === 0 && !isLoading" class="search-container">
        <div class="result-empty">
          <img src="../../../assets/lxp/images/common/img_empty.png" alt="">
          <p class="text">등재완료된 Hot Tube 콘텐츠가 없습니다.</p>
<!--          <p class="text">나만의 다양한 노하우를 동영상으로 제작하여 공유해주세요.</p>-->
        </div>
      </div>
      <LoadingDiv v-if="isLoading" v-model="isLoading" />
      <CommonPaginationFront v-if="!isMobile" :paging="paging" :page-func="pagingFunc"/>
    </div>
    <CommonPaginationFront v-if="isMobile" :paging="paging" :page-func="pagingFunc"/>
  </div>
</template>

<script>
import CommonPaginationFront from '@/components/CommonPaginationFront';
import {reactive, ref, toRefs, watch} from 'vue';
import {getTop1CategoryByLevel} from '@/assets/js/modules/hrd/hrd-common';
import LoadingDiv from '@/components/common/LoadingDiv';
import {getKbTubeList} from '@/assets/js/modules/hrd/hrd-history-common';
import {useRouter} from 'vue-router';
import {useAlert} from '@/assets/js/modules/common/alert';
import {stringCheck} from '@/assets/js/util';

export default {
  name: 'KbTubeList',
  methods: {stringCheck},
  components: {LoadingDiv, CommonPaginationFront},
  props: {
    uploadFunc: Object,
    pendingFunc: Object,
    isMobile: Boolean
  },
  setup(props) {
    const router = useRouter();
    const {showMessage} = useAlert();
    const isLoading = ref(true);
    const {uploadFunc, pendingFunc} = toRefs(props);
    const items = ref([]);
    const paging = reactive({
      pageNo: 1,
      pageSize: 6,
      totalCount: 0
    });

    const getList = () => {
      getKbTubeList({}, items, paging, () => {
        isLoading.value = false;
        items.value.map(x => {
          let cates = getTop1CategoryByLevel(x.cloudNmLst);
          if (cates) {
            x.category1 = cates.category1;
            x.category2 = cates.category2;
            x.category3 = cates.category3;
            x.category4 = cates.category4;
            x.category5 = cates.category5;
          }
        });
      }, () => {isLoading.value=false;});
    };

    const getStatus = (stt) => {
      let info = {
        name: '',
        classNm: '',
      }
      if (stt === '01' || stt === '53') {
        info.name = '작성중';
        info.classNm = 'badge-silver';
      }
      else if (stt === '81') {
        info.name = '심사대기중';
        info.classNm = 'badge-silver';
      }
      else if (stt === '78') {
        info.name = '반려';
        info.classNm = 'badge-red';
      }
      else if (stt === '00') {
        info.name = '승인완료';
        info.classNm = 'badge-primary';
      }
      return info;
    };

    const pagingFunc = (pageNo) => {
      paging.pageNo = pageNo;
      getList();
    };

    const handleNewUpload = () => {
      uploadFunc.value.update(0);
      uploadFunc.value.toggle();
    };

    const handleItemClick = (idx) => {
      let item = items.value[idx];
      let stt = item.stt;
      if (stt == '01' || stt == '53' || stt == '78') {
        uploadFunc.value.update(item.cttMstSn);
        uploadFunc.value.toggle();
      } else if (stt == '81') {
        pendingFunc.value.update(item.cttMstSn);
        pendingFunc.value.toggle();
      } else {
        // TODO :: 상세페이지로 이동
        if (stt == '00') {
          router.push({ name: 'HrdCloudContentBridge',params: {lrnObjDtlDistSn: item.lrnObjDtlDistSn} });
        } else {
          showMessage('문제가 발생했습니다. 관리자에게 문의하세요.');
        }
      }
    };

    const getImg = (src) => {
      return`${process.env.VUE_APP_CLOUDFRONT_URL}/${src}`;
    };

    getList();

    watch(() => uploadFunc.value.isActive, () => {
      if (uploadFunc.value.isActive === false) {
        getList();
      }
    });

    return {
      isLoading, items, paging,
      pagingFunc, getStatus, handleNewUpload, handleItemClick, getImg
    }
  }
};
</script>