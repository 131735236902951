<template>
  <LxpMobileHeader title="모임등록">
    <template v-slot:left>
      <div class="util util-back">
        <router-link :to="{name: 'GroupView', params: {distCrseSn: distCrseSn}}" class="util-actions util-actions-back">
          <i class="icon-history-back"></i>
        </router-link>
      </div>
    </template>
    <template v-if="!params.bgngLrn" v-slot:right>
      <div class="util util-apply">
        <a href="javascript:" class="util-actions util-actions-yellow" @click="clickSave">
          {{ $route.params.lrnPostSn > 0 ? '수정' : '모임등록'}}
        </a>
      </div>
      <div v-if="$route.params.lrnPostSn > 0" class="util util-apply">
        <a href="javascript:" class="util-actions util-actions-silver" @click="clickDelete">
         삭제
        </a>
      </div>
    </template>
  </LxpMobileHeader>
  <main class="kb-main" id="kb-prep-group">
    <!-- main-content -->
    <div class="main-content">
      <div>
        <section class="content-section section-forms">
          <!-- kb-form-fields -->
          <div class="kb-form-fields kb-form-fields-v2">
            <!-- kb-form-field:모임주제 -->
            <div class="kb-form-field">
              <div class="kb-form-row">
                <label class="kb-form-label">
                  <span class="kb-form-label-text">모임주제</span>
                  <span class="kb-form-label-text text-red"> *</span>
                </label>
              </div>
              <div class="kb-form-row column-whole">
                <input v-model="params.objNm" type="text" class="kb-form-control" placeholder="모임주제를 입력하세요.">
              </div>
            </div>
            <!-- kb-form-field:모임구분 -->
            <div class="kb-form-field">
              <div class="kb-form-row">
                <label class="kb-form-label">
                  <span class="kb-form-label-text">모임구분</span>
                  <span class="kb-form-label-text text-red"> *</span>
                </label>
              </div>
              <div class="kb-form-row">
                <div class="kb-form-dropdown" data-offset="bottom-left">
                  <button class="kb-form-dropdown-btn"><span class="text">온라인</span><i class="icon-arrow"></i></button>
                  <div class="kb-form-dropdown-options">
                    <div class="dropdown-option">
                      <ul class="dropdown-option-list">
                        <li class="dropdown-option-item"><a href="javascript:" class="dropdown-option-link"><span class="dropdown-option-text">온라인</span></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- kb-form-field:모임일자 -->
            <div class="kb-form-field">
              <div class="kb-form-row">
                <label class="kb-form-label justify-content-between">
                  <span class="kb-form-label-text">모임일자</span>
                  <span class="kb-form-label-text text-red"> *</span>
                  <span class="text text-muted tip">모임가능일자 월/화/목/금(수요일, 주말 및 공휴일 제외)</span>
                </label>
              </div>
              <div class="kb-form-row">
                <GroupClassCalendar
                    v-model="classDate"
                    v-model:toggle="toggles.date"
                />
              </div>
            </div>
            <!-- kb-form-field:모임시작시간 -->
            <div class="kb-form-field">
              <div class="kb-form-row">
                <label class="kb-form-label justify-content-between">
                  <span class="kb-form-label-text">모임시작시간</span>
                  <span class="kb-form-label-text text-red"> *</span>
                  <span class="text text-muted tip">모임가능시간 18:00-22:00(22시 자동 종료)</span>
                </label>
              </div>
              <div class="kb-form-row d-flex justify-content-between">
                <SortButtonSelect
                    v-model="params.bgngHour"
                    v-model:toggle="hourToggle"
                    :options="classStartHour"
                    sequence-key="bgngHour"
                    name-key="bgngHourNm"
                    :is-row="false"
                    :is-all="false"
                />
                <SortButtonSelect
                    v-model="params.bgngMin"
                    v-model:toggle="minToggle"
                    :options="classStartMin"
                    sequence-key="bgngMin"
                    name-key="bgngMinNm"
                    :is-row="false"
                    :is-all="false"
                />
              </div>
            </div>
            <!-- kb-form-field:학습시간 -->
            <div class="kb-form-field">
              <div class="kb-form-row">
                <label class="kb-form-label">
                  <span class="kb-form-label-text">학습시간</span>
                  <span class="kb-form-label-text text-red"> *</span>
                </label>
              </div>
              <div class="kb-form-row">
                <SortButtonSelect
                    v-model="params.lrnDt"
                    v-model:toggle="lrnDtToggle"
                    :options="lrnDt"
                    sequence-key="lrnDt"
                    name-key="lrnDtNm"
                    :is-row="false"
                    :is-all="false"
                />
              </div>
            </div>
            <!-- kb-form-field:리브똑똑/Wisenet내용 -->
            <div class="kb-form-field">
              <div class="kb-form-row">
                <label class="kb-form-label">
                  <span class="kb-form-label-text">리브똑똑 ・ Wisenet내용</span>
                  <span class="kb-form-label-text text-red"> *</span>
                  <span class="text text-muted tip">{{ notiMsgLengthCnt }} / 300</span>
                </label>
              </div>
              <div class="kb-form-row">
                <textarea v-model="params.notiMsg" name="" id="" cols="30" rows="4" placeholder="그룹 멤버에게 발송할 모임 안내를 작성하세요." class="kb-form-control"></textarea>
              </div>
<!--              <div class="kb-form-row">-->
<!--                <div class="textarea-bottom mt-0">-->
<!--                  <div class="bottom-column">-->
<!--                    <div class="kb-form-check align-items-center">-->
<!--                      <input type="checkbox" class="kb-form-check-input" id="chk">-->
<!--                      <label for="chk" class="kb-form-check-label"><strong class="text">기본문구적용</strong></label>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
            </div>
            <!-- kb-form-field:발신자 연락처 -->
            <div class="kb-form-field">
              <div class="kb-form-row">
                <label class="kb-form-label">
                  <span class="kb-form-label-text">발신자 연락처</span>
                </label>
              </div>
              <div class="kb-form-row">
                <input v-model="mblTelNo" type="text" class="kb-form-control" placeholder="연락처" >
              </div>
            </div>
            <!-- //kb-form-field -->
          </div>
          <!-- //kb-form-fields -->
        </section>
      </div>
    </div>

  </main>
</template>

<script>
import LxpMobileHeader from "@/views/layout/lxp/header/mobile/LxpMobileHeader.vue";
import {groupClassEditSetup} from "@/assets/js/modules/groupLearing/group-class-edit-setup";
import GroupClassCalendar from "@/components/prep/group/GroupClassCalendar.vue";
import SortButtonSelect from "@/components/common/SortButtonSelect.vue";

export default {
  name: "MobileGroupClassEdit",
  components: {SortButtonSelect, GroupClassCalendar, LxpMobileHeader},
  setup: groupClassEditSetup
}
</script>