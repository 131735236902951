import {useAlert} from "@/assets/js/modules/common/alert";
import {computed, onMounted, reactive, ref} from "vue";
import {closeToggles} from "@/assets/js/ui.init";
import {
    initParams,
    isSuccess,
    // nullEmpty,
    paramCheck,
    replaceDateToYmd,
    setParams,
    ymdStrToDateFormat, ymdStrToFormat, numberComma
} from "@/assets/js/util";
import {
    applyBtnEnable,
    cancelSupport,
    checkUploadBinaries,
    getFileUploadFailResults,
    proofDivCdDcds,
    saveSupport,
    setCostProof,
    showComplete,
    uploadCostproof,
    extensions
} from "@/assets/js/modules/support/support-common";
import {
    ACT_DELETE_CANCEL_LRN_SPRT_LCSN,
    ACT_INSERT_LEARN_SUPPORT_LICENSE_RENEW,
    ACT_UPDATE_LEARN_SUPPORT_LICENSE_RENEW, MUT_CLOSE_SUPPORT, MUT_SHOW_MAIN
} from "@/store/modules/support/support";
import {useStore} from "vuex";

export const supportLicenseRenewSetup = (props, {emit}) => {
    const {showMessage, showLoading, hideLoading} = useAlert();
    const isLoading = ref(false);
    const isReadOnly = computed(() => props.data && props.data.stt !== '00');
    const store = useStore();
    const isMobile = computed(() => store.state.auth.isMobile);
    const userAgreement = ref(false);

    const costLcsnSn = computed(() => props.data.costLcsnSn);

    // const extensions = ref(['bmp','gif','jpe','jpeg','jpg','svg', 'png', 'tif']);

    const param = reactive({
        costLcsnSn: costLcsnSn.value,
        passAnnonYmd: '',
        lcsnRegYmd: '',
        updtAplyYmd: '',
        updtVldYmd: '',
        certNo: '',
        updtCost: 0,
        sprtAmt:  0,
        rtnYn:'N',
        rtnAmt:0,
        addAmtYn:'N',
        addAmt:0,
        regYn: '',
        lcsnSn: 0,
        pblcnInstNm: '',
        lcsnCd: '',
        lcsnNm: '',
        grd1Cd: '',
        grd2Cd: '',
        totAmt: 0
    });

    const toggles = reactive({
        passAnnonYmd: false,
        updtAplyYmd: false,
    });

    // PROOF_DIV_CD_LCSN_UDT_COST_PROOF
    const certFiles = ref({ files: [], removed: [], binaries: [] });
    const costProofFiles = ref({ files: [], removed: [], binaries: [] });

    const closeModal = () => {
        initLocalParams();
        closeToggles(toggles);
        if (props.reList instanceof Function) props.reList();
        if(isMobile.value) {
            if(props.data == null){
                store.commit(`support/${MUT_CLOSE_SUPPORT}`);
            }else {
                store.commit(`support/${MUT_SHOW_MAIN}`);
            }
        }else{
            emit('update:modelValue',false);
        }
    };

    const validate = () => {
        if (paramCheck(param.updtAplyYmd)) {
            showMessage('주관처 갱신 신청일자를 입력해주세요.');
            return false;
        }

        if (paramCheck(param.updtVldYmd) || param.updtVldYmd == undefined) {
            showMessage('갱신된 유효기간을 입력해주세요.');
            return false;
        }

        if ((paramCheck(param.certNo) || param.certNo == '0') && (param.lcsnCd == 'WR67' || param.lcsnCd == 'WR71')) {
            showMessage('인증번호를 입력해주세요.');
            return false;
        }

        if(certFiles.value.files.length == 0){
            showMessage('갱신증명서를 등록해주세요');
            return false;
        }

        if(param.lcsnCd != 'WR67' && param.lcsnCd != 'WR71' && costProofFiles.value.files.length == 0){
            showMessage('납부증빙서류를 등록해주세요');
            return false;

        }
        return true;
    };

    const usrAgrmntRef = ref(null);

    const initLocalParams = () => {
        initParams(param);
        initParams(certFiles.value);
        initParams(costProofFiles.value);
        userAgreement.value = false;
        if(props.data == null) {
            usrAgrmntRef.value.allUnChk();
        }
        isLoading.value = false;
    };

    const apply = async () => {
        // nullEmpty(param); --- ???
        showLoading();
        let certRes = null;
        let costProofRes = null;

        param.totAmt = param.updtCost;

        param.lcsnRegYmd = replaceDateToYmd(param.lcsnRegYmd);
        param.updtAplyYmd = replaceDateToYmd(param.updtAplyYmd);
        param.updtVldYmd = replaceDateToYmd(param.updtVldYmd);

        const applyRes = props.mode === 'renewal' ?
        await saveSupport(isLoading, `support/${ACT_INSERT_LEARN_SUPPORT_LICENSE_RENEW}`, {...param, removedFiles: certFiles.value.removed.concat(certFiles.value.removed)}, userAgreement, validate) :
        await saveSupport(isLoading, `support/${ACT_UPDATE_LEARN_SUPPORT_LICENSE_RENEW}`, {costLcsnSn: param.costLcsnSn, params: {...param, removedFiles: certFiles.value.removed.concat(certFiles.value.removed).concat(costProofFiles.value.removed)}}, userAgreement, validate);

        if (applyRes) {
            param.costLcsnSn = props.mode === 'renewal' ? applyRes.costLcsnSn : param.costLcsnSn;
        }

        if (param.costLcsnSn > 0) {
            if (checkUploadBinaries(certFiles)) {
                certRes = await uploadCostproof({
                    params: {costLcsnSn: param.costLcsnSn, proofDivCdDcd: proofDivCdDcds.PROOF_DIV_CD_LCSN_UDT_COPY},
                    files: certFiles.value.files
                });
            }
            if (checkUploadBinaries(costProofFiles)) {
                costProofRes = await uploadCostproof({
                    params: {costLcsnSn: param.costLcsnSn, proofDivCdDcd: proofDivCdDcds.PROOF_DIV_CD_LCSN_UDT_COST_PROOF},
                    files: costProofFiles.value.files
                });
            }
        }
        let failStr = getFileUploadFailResults(certRes, costProofRes);

        if (isSuccess(applyRes)) {
            showComplete(isLoading, applyRes, failStr,()=>{
                closeModal();
            });
        } else if(applyRes && applyRes.result && applyRes.result.number === 302) {
            showMessage(applyRes.result.message);
        }
        hideLoading();
    };


    const cancel = () => {
        cancelSupport(ACT_DELETE_CANCEL_LRN_SPRT_LCSN, param.costLcsnSn,'자격증 갱신 지원',  () => {
            closeModal();
        });
    }

    onMounted(() => {
        setParams(param, props.data);
        setCostProof(props.data.proofs, certFiles, proofDivCdDcds.PROOF_DIV_CD_LCSN_UDT_COPY);
        setCostProof(props.data.proofs, costProofFiles, proofDivCdDcds.PROOF_DIV_CD_LCSN_UDT_COST_PROOF);

        param.passAnnonYmd = ymdStrToFormat(param.passAnnonYmd,'-');
        param.updtAplyYmd = ymdStrToFormat(param.updtAplyYmd,'-');
        param.updtVldYmd = ymdStrToFormat(param.updtVldYmd,'-');

        if (props.data != null){
            userAgreement.value = true;
        }
    });

    return {
        isReadOnly, param, toggles, certFiles, costProofFiles,
        apply, closeModal, closeToggles,
        userAgreement, applyBtnEnable, ymdStrToDateFormat, cancel, numberComma, extensions, ymdStrToFormat, usrAgrmntRef
    }
}