<template>
  <div v-if="rsltBodies.length === 0" class="view-contents">
    <article class="content-section section-margin">
      <header class="section-header header-divider d-flex">
        <h3 class="title">퀴즈출제결과 <span class="count">{{rsltBodies.length}}</span></h3>
      </header>
    </article>
    <div class="contents-empty empty-min">
      <p class="text">현재 퀴즈 출제 이력이 없습니다. </p>
    </div>
  </div>
  <div v-else class="view-contents">
    <!-- content-section -->
    <article class="content-section section-margin">
      <header class="section-header header-divider d-flex">
        <h4 class="title">퀴즈출제결과 <span class="count">{{rsltBodies.length}}</span></h4>
      </header>
      <!-- text-item-container -->
      <div class="text-item-container">
        <!-- text-item -->
        <div class="text-item" v-for="(item, idx) in rsltBodies" :key="idx">
            <div class="item-row row-contents">
                <div class="item-column">
                    <h5 class="item-subtitle">{{item.distCrseNm}}</h5>
                    <h4 class="item-title mb-1">{{item.lrnEvlNm}}</h4>
                    <div class="item-meta">
                        <span class="text">제출인원 {{item.evlCnt}}</span>
                        <span class="text">{{item.regDt}}</span>
                    </div>
                </div>
            </div>
            <div class="item-row row-buttons">
                <button class="kb-btn kb-btn-outline" @click="rsltDetail(item.lrnTyEvlMstSn)"><span class="text">명단보기</span></button>
            </div>
        </div>
        <!-- //text-item -->
      </div>
      <!-- //text-item-container -->
    </article>
  </div>

  <!-- popup-inner > popup-content -->
  <div class="popup-content" style="display:none;">
    <article v-if="quizResultCnt === 0" class="popup-section">
      <div class="section-content">
        <div class="segment-box">
          <p class="text text-muted">현재 퀴즈 출제 이력이 없습니다. </p>
        </div>
      </div>
    </article>

    <!-- popup-section:퀴즈출제결과 -->
    <article v-else class="popup-section">
      <header class="section-header">
        <h4 class="title">퀴즈출제결과 <span class="subtitle">{{rsltBodies.length}}</span></h4>
      </header>
      <div class="section-content">
        <div class="kb-table kb-table-striped">
          <table>
            <colgroup>
              <col style="width:64px">
              <col style="width:auto">
              <col style="width:200px">
              <col style="width:90px">
              <col style="width:170px">
              <col style="width:95px">
            </colgroup>
            <thead>
            <tr>
              <th><span class="th-title">NO</span></th>
              <th><span class="th-title">차수코드/ 강의명</span></th>
              <th><span class="th-title">퀴즈목록명</span></th>
              <th><span class="th-title">제출인원</span></th>
              <th><span class="th-title">출제일시</span></th>
              <th><span class="th-title">&nbsp;</span></th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="(item, idx) in rsltBodies" :key="idx">
                <td><span class="td-text">{{ idx+1 }}</span></td>
                <td><span class="td-text">{{item.distCrseNm}} / {{item.objNm}}</span></td>
                <td><span class="td-text">{{item.lrnEvlNm}}</span></td>
                <td><span class="td-text">{{item.evlCnt}}</span></td>
                <td><span class="td-text">{{item.regDt}}</span></td>
                <td class="td-right">
                  <a href="javascript:" @click="rsltDetail(item.lrnTyEvlMstSn)" class="td-link"><strong class="td-text text-primary">명단보기</strong></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </article>
  </div>
</template>
<script>
import {computed, onMounted, ref} from 'vue';
import {useStore} from 'vuex';
import {ACT_GET_QUIZ_RSLT_LIST} from '@/store/modules/quiz/quiz';
import {getItems, getPaging, lengthCheck, timestampToDateFormat} from '@/assets/js/util';

export default {
  name: "MobileQuizMngQstnList",
  props:{
    rsltAddLayer: Function,
  },
  setup(props){

    const store = useStore();
    const quizResultCnt = ref(1);
    const rsltBodies = ref([]);
    const rsltItem = ref({});
    const distCrseSn = computed(() => store.state.quiz.distCrseSn);
    const paging = ref({pageNo: 1, pageSize: 3, totalCount: 0, hasMore: false});

    onMounted(() => {
      getQuizRslt();
    })

    const getQuizRslt = () => {
      store.dispatch(`quiz/${ACT_GET_QUIZ_RSLT_LIST}`, {
        lrnEvlMstSn: distCrseSn.value,
        pageNo: paging.value.pageNo,
        pageSize: paging.value.pageSize,
      }).then(res => {
        if (lengthCheck(res)) {
          rsltBodies.value = getItems(res);
          rsltBodies.value.map(x => ({
            ...x,
            regDt: timestampToDateFormat(x.regDt, 'yyyy-MM-dd hh:mm:ss')
          }));
          paging.value = getPaging(res);
        } else {
          rsltBodies.value = [];
        }
      }).catch(e => {
        console.error(e);
      });
    };

    const rsltDetail = (quizSn) => {
      rsltItem.value = rsltBodies.value.filter(x => x.lrnTyEvlMstSn === quizSn)[0];
      props.rsltAddLayer(rsltItem.value);
    }

    return{
      quizResultCnt,
      rsltBodies,
      rsltItem,
      rsltDetail,
    }
  }
}
</script>