import {useStore} from 'vuex';
import {useRouter} from 'vue-router';
import {useAlert} from '@/assets/js/modules/common/alert';
import {computed, ref} from 'vue';
import {
  enableExperiencePeriod,
  enrollSSL,
  getMyLearnTargetList,
  sslRoutes,
} from '@/assets/js/modules/ssl/ssl-common';
import {dateToDateFormat} from '@/assets/js/util';

export const sslEnrollSetup = (props, {emit}) => {
  const store = useStore();
  const router = useRouter();
  const {showMessage, showLoading, hideLoading} = useAlert(); //, showConfirm

  const nowDate = dateToDateFormat(new Date(), 'yyyyMMddhhmm');
  const isMobile = computed(() => store.state.auth.isMobile);
  const distCrseNm = computed(() => sslRoutes[props.distCrseCd] ? sslRoutes[props.distCrseCd].name : '' );
  const options = sslRoutes[props.distCrseCd] ? sslRoutes[props.distCrseCd].group : [];
  const lrnTrgtGrpMstSn = ref(options.length > 0 ? options[0].lrnTrgtGrpMstSn : 0);

  // console.log('props.distCrseCd : ' + props.distCrseCd);

  const canExperience = computed(() => {
    if (enableExperiencePeriod[props.distCrseCd]) {
      return enableExperiencePeriod[props.distCrseCd] <= nowDate;
    }
    return false;
  });

  const closeModal = () => {
    emit('update:toggle', false);
  }

  // const enrollLearn = () => {
  //   const confirmText = `${distCrseNm.value}${options.length > 1 ? `(${options.find(x => x.lrnTrgtGrpMstSn === lrnTrgtGrpMstSn.value).lrnTrgtGrpNm})` : ''} 과정을 신청하시겠습니까?`;
  //
  //   showConfirm({
  //     text : confirmText,
  //     callback: enrollInit
  //   });
  // }

  const enrollInit = () => {

    if (props.distCrseCd === 'group') {
      router.push({name: 'GroupLearning'});
    } else {
      showLoading();
      enrollSSL(lrnTrgtGrpMstSn.value, result => {
        if (result > 0) {
          if (result === 3) {
            getMyLearnTargetList(() => {
              hideLoading();
              showMessage('다른 분야에 입과된 이력이 존재합니다.');
              closeModal();
            }).then(() => {});
          } else {
            getMyLearnTargetList(() => {
              hideLoading();
              showMessage({
                title: '입과완료',
                text: '입과 완료되었습니다.'
              });
              experienceLearn();
            }).then(() => {});
          }
        } else {
          hideLoading();
          showMessage('서버처리 중 일시적인 장애가 발생하였습니다.<br>잠시 후 다시 시도 해 주시기 바랍니다.');
        }
      });
    }
  }

  const experienceLearn = () => {
    if(props.socLrnPostSn > 0){
      router.push({
        name: 'SSLView',
        params: {
        distCrseCd: props.distCrseCd,
            lrnTrgtGrpMstSn: lrnTrgtGrpMstSn.value,
            socLrnPostSn: props.socLrnPostSn
      }
      }).then(() => {
      });
    }else {
      router.push({
        name: 'SSLMain',
        params: {distCrseCd: props.distCrseCd, lrnTrgtGrpMstSn: lrnTrgtGrpMstSn.value}
      }).then(() => {
      });
    }
  }

  const bookText = nowDate <= enableExperiencePeriod['book'] ? '도서 신청 완료 후<br>3.31(금) 16시 오픈 예정' : '북러닝 과정의 신청기간이 종료되었습니다.<br>다음 신청기간을 이용해주시기 바랍니다.<br>(하반기 신청기간: 8월 中 별도 안내 예정)';

  return {
    isMobile,
    distCrseNm,
    lrnTrgtGrpMstSn,
    options,

    closeModal,
    // enrollLearn,
    enrollInit,
    experienceLearn,

    canExperience,
    bookText
  }

}
