import {useAlert} from "@/assets/js/modules/common/alert";
import {computed, onMounted, reactive, ref, watch} from "vue";
import {closeToggles, getDcdOptions} from "@/assets/js/ui.init";
import {
    applyBtnEnable,
    cancelSupport,
    checkUploadBinaries,
    getFileUploadFailResults,
    grdTots,
    proofDivCdDcds,
    saveSupport,
    schlDivCdDcds,
    schlInDivCdDcds,
    setCostProof,
    showComplete,
    uploadCostproof,
    extensions
} from "@/assets/js/modules/support/support-common";
import {
    emptyNumberOnClick,
    initParams,
    isSuccess,
    nullEmpty,
    numberComma,
    numbersOnly,
    paramCheck, replaceDateToYmd,
    setParams, ymdStrToFormat
    ,getItem, lengthCheck, removeComma
} from "@/assets/js/util";
import {
    ACT_DELETE_CANCEL_LRN_SPRT_SCHL,
    ACT_INSERT_LRN_SPRT_SCHL,
    ACT_UPDATE_LEARN_SUPPORT_SCHOOL,
    MUT_HIDE_HEADER,
    ACT_GET_BEF_TERM_BURS_AMT
} from "@/store/modules/support/support";
import {useStore} from "vuex";

export const supportTuitionSetup = (props, {emit}) => {
    const store = useStore();

    const {showMessage, showLoading, hideLoading} = useAlert();
    const isLoading = ref(false);

    const isReadOnly = computed(() => props.data && props.data.aplySttCdDcd !== '2106001');

    const showPrice = ref(true);        // 납부금액 - Expand Collapse
    const showReducedPrice = ref(true); // 감면금액 - Expand Collapse
    const userAgreement = ref(false);   // 동의

    const schlDivCdDcdOpt = computed(() => getDcdOptions('2061'));
    const schlInDivCdDcdOpt = computed(() => getDcdOptions('2062'));
    const termDivCdDcdOpt = computed(() => getDcdOptions('2063').filter(x=>x.alterCd == ""));

    const majorModal = ref(false);
    const collegeModal = ref(false);

    const isAcademicCreditBank = ref(false);

    // const extensions = ref(['bmp','gif','jpe','jpeg','jpg','svg', 'png', 'tif']);

    const param = reactive({
        costTuitFeeSn: 0,
        schlDivCdDcd: '', //  학교구분
        schlInDivCdDcd: '', // 재학구분
        termDivCdDcd: '', // 학기구분
        tuitFeePayDd: '', // 등록금 납부 일
        grdAvg: 0.0, // 평점평균
        grdTot: 0.0, // 평점 총점
        rtnYn: 'N',
        rtnAmt: 0,
        addAmtYn: 'N',
        addAmt: 0,
        // 고지금액 합계는 납부금액 더해서 보여주는 값
        // 감면금액 합계
        sprtExptAmt: 0, // 지원대상 금액
        befTermBursAmt: 0,// 직전학기학비지원후장학금수혜금액
        bursAmtBnfitRegno: 0, // 직전학기 장학금수혜 관리번호
        sprtAmt: 0,// 지원금액
        // 등록금 납입증명서
        inetProofNo: '',// 인터넷증명서발급번호
        // 장학금 수혜 여부
        bursAmtBnfitYn: 'N',
        dpstYmd: '', // 입금 일자
        dpstBankNm: '', // 입금 은행명
        dpstActno: '', // 입금계좌번호
        bursAmtAmt: 0, // 장학금 금액
        regCrseCrdit: 0, // 수강학점
        degAcqsSchedPerid: '', // 학위취득예정시기
        majorNm2: '', // 학과
    });

    const befSchlDivCdDcd = ref(''); // 직전학기 학교구분

    const major = ref({
        majorCd: '', // 전공
        majorNm: ''
    });

    const school = ref({
        scholCd: '',
        scholNm: ''
    });

    const fees = reactive({
        // 납부금액:지원
        sprtTuitFee: 0, // 수업료/등록금
        sprtSchlOperCost: 0, // 학교운영지원비(육성/기성회비)
        sprtTotStdntFee: 0, // 총학생회비(자치회비)
        sprtPraceCost: 0, // 실험실습비등
        // 납부금액: 지원제외
        nsprtStdntFee: 0, // 학생회비
        nsprtBookCost: 0, // 교재대
        nsprtGradPrepAmt: 0, // 졸업준비(앨범)금
    });

    const reduce = reactive({
        // 감면
        redGradeBursAmt: 0, // 성적
        redNtnBursAmt: 0, // 국가지원
        redEtcNm: '', // 감면기타명
        redEtcBursAmt: 0, // 기타
        // nsprtEtcAmt: 0, // 기타
        etcBursAmtNm: '', //기타장학금명
        etcBursAmt: 0, //기타장학금
    });

    const appBefTermBursAmt = ref(0); // 적용 직전학기 장학급수혜금액
    // PROOF_DIV_CD_SCHL_SCHOLAR_CERT
    const totalPayment = ref(0), totalReduce = ref(0);

    const transcriptFiles = ref({ files: [], removed: [], binaries: [] }),
        tuitPayCertFiles = ref({ files: [], removed: [], binaries: [] }),
        schlScholarCertFiles = ref({ files: [], removed: [], binaries: [] });

    const toggles = reactive({
        schlDivCdDcds: false,
        schlInDivCdDcds: false,
        termDivCdDcds: false,
        grdTots: false,
        tuitFeePayDd: false,
        dpstYmd: false
    });

    // const tuitFeePayDdToggle = ref(false);

    const toggleGrdTot = () => {
        toggles.grdTots=!toggles.grdTots
        closeToggles(toggles,'grdTots');
    };

    const selectGrdTot = (idx) => {
        param.grdTot = grdTots[idx].val;
        closeToggles(toggles);
    };


    const initLocalParams = () => {
        initParams(param);
        initParams(major.value);
        initParams(school.value);
        initParams(fees);
        initParams(reduce);
        initParams(transcriptFiles.value);
        initParams(tuitPayCertFiles.value);
        initParams(schlScholarCertFiles.value);
        userAgreement.value=false;
        closeToggles(toggles);
        closeModal();
        isLoading.value = false;
    };

    const validate = () => {
        if (paramCheck(param.schlDivCdDcd)) {
            showMessage('학교 구분을 선택해주세요');
            return false;
        }
        if (paramCheck(param.schlInDivCdDcd)) {
            showMessage('재학 구분을 선택해주세요');
            return false;
        }
        if (paramCheck(param.termDivCdDcd)) {
            showMessage('학기 구분을 선택해주세요');
            return false;
        }
        if (paramCheck(param.tuitFeePayDd)) {
            showMessage('등록금 납부일을 선택해주세요');
            return false;
        }
        if (paramCheck(param.grdAvg)) {
            if(param.schlInDivCdDcd !== '2062001') {
                showMessage('평점 평균을 입력해주세요');
                return false;
            }
        }
        if (paramCheck(param.grdTot)) {
            if(param.schlInDivCdDcd !== '2062001') {
                showMessage('평점 총점을 입력해주세요');
                return false;
            }
        }
        if (paramCheck(major.value.majorNm)) {
            showMessage('전공을 선택해주세요');
            return false;
        }
        if (paramCheck(school.value.scholNm)) {
            showMessage('학교를 선택해주세요');
            return false;
        }

        if(reduce.etcBursAmt !== '' && Number(reduce.etcBursAmt) > 0){
            if (paramCheck(reduce.etcBursAmtNm)) {
                showMessage('기타 장학금명을 입력해주세요');
                return false;
            }
        }
        if(reduce.etcBursAmtNm !== ''){
            if (paramCheck(reduce.etcBursAmt)) {
                showMessage('기타 장학금을 입력해주세요');
                return false;
            }
        }
        return true;
    };

    const apply = async () => {
        if (reduce.etcBursAmt !== '' && reduce.etcBursAmt !== null) {
            reduce.etcBursAmt = Number(removeComma(reduce.etcBursAmt)); // 기타 입력장학금 숫자형변환
        }
        let payload = { ...param, majorCd: major.value.majorCd, majorNm: major.value.majorNm, schlCd: school.value.scholCd, schlNm: school.value.scholNm, ...fees, ...reduce,
            removedFiles: transcriptFiles.value.removed.concat(transcriptFiles.value.removed).concat(tuitPayCertFiles.value.removed).concat(schlScholarCertFiles.value.removed),
        };
        nullEmpty(payload);
        showLoading();
        let applyRes=null, transRes=null, tuitRes=null, schlScholarCertRes=null;

        param.dpstYmd = replaceDateToYmd(param.dpstYmd);

        if (param.costTuitFeeSn > 0) {
            applyRes = await saveSupport(isLoading, `support/${ACT_UPDATE_LEARN_SUPPORT_SCHOOL}`, {costTuitFeeSn: param.costTuitFeeSn, params: {...payload}}, userAgreement, validate); }
        else {
            applyRes = await saveSupport(isLoading, `support/${ACT_INSERT_LRN_SPRT_SCHL}`, payload, userAgreement, validate);
            if (applyRes) {
                param.costTuitFeeSn = applyRes.costTuitFeeSn;
            }
        }

        if (param.costTuitFeeSn > 0) {
            if (checkUploadBinaries(transcriptFiles)) {
                transRes = await uploadCostproof({
                    params: {costTuitFeeSn: param.costTuitFeeSn, proofDivCdDcd: proofDivCdDcds.PROOF_DIV_CD_SCHL_LAST_SEM_TRANS},
                    files: transcriptFiles.value.files
                });
            }
            if (checkUploadBinaries(tuitPayCertFiles)) {
                tuitRes = await uploadCostproof({
                    params: {costTuitFeeSn: param.costTuitFeeSn, proofDivCdDcd: proofDivCdDcds.PROOF_DIV_CD_SCHL_TUIT_CERT},
                    files: tuitPayCertFiles.value.files
                });
            }
            if (checkUploadBinaries(schlScholarCertFiles)) {
                schlScholarCertRes = await uploadCostproof({
                    params: {costTuitFeeSn: param.costTuitFeeSn, proofDivCdDcd: proofDivCdDcds.PROOF_DIV_CD_SCHL_SCHOLAR_CERT},
                    files: schlScholarCertFiles.value.files
                });
            }
        }

        // let failStr = getFileUploadFailResults({title: '성적증명서', res: transRes}, {title: '등록금 납입증명서', res: tuitRes});

        let failed = getFileUploadFailResults(transRes, tuitRes, schlScholarCertRes);

        // 비용신청테이블 인서트
        if (isSuccess(applyRes)) {
            // 신청완료
            showComplete(isLoading, applyRes, failed,()=>{
                initLocalParams();
            });
        }

        hideLoading();
    };


    const searchSchool = (type) => {
        if(type === 'college') {
            collegeModal.value = true;
        }
        if(type === 'major') {
            majorModal.value = true;
        }

        store.commit(`support/${MUT_HIDE_HEADER}`);
    }

    const closeModal = () => {
        if (props.reList instanceof Function)
            props.reList();
        emit('update:modelValue',false);
    };


    const cancel = () => {
        cancelSupport(ACT_DELETE_CANCEL_LRN_SPRT_SCHL, param.costTuitFeeSn,'학비 지원', () => {
            closeModal();
        });
    }


    const getBefTermBursAmt = () => {
        store.dispatch(`support/${ACT_GET_BEF_TERM_BURS_AMT}`).then(res => {
            if(lengthCheck(res)) {
                const items = getItem(res);
                param.befTermBursAmt = items.befTermBursAmt;
                param.bursAmtBnfitRegno = items.bursAmtBnfitRegno;
                befSchlDivCdDcd.value = items.schlDivCdDcd; // 직전학기 학기구분

                // 지원금액 적용
                if ( befSchlDivCdDcd.value == '2061001'){ // 방송통신대
                    appBefTermBursAmt.value =  items.befTermBursAmt;
                }else{
                    appBefTermBursAmt.value =  items.befTermBursAmt/2;
                }
                param.sprtAmt = -1*appBefTermBursAmt.value;

                // console.log(param.bursAmtBnfitRegno+'/'+param.befSchlDivCdDcd+'/'+param.befTermBursAmt+'/'+param.sprtAmt);
            }
        }).catch(e => {
            console.error(e);
        });
    }

    const calSupportFee = () => {

        param.sprtExptAmt = totalPayment.value - totalReduce.value;

        if (param.schlDivCdDcd === schlDivCdDcds.SCHL_DIV_CD_KNOU) { // 방통대
            param.sprtAmt = param.sprtExptAmt;
        } else if (param.schlDivCdDcd === schlDivCdDcds.SCHL_DIV_CD_PHD || param.schlDivCdDcd === schlDivCdDcds.SCHL_DIV_CD_COMM || param.schlDivCdDcd === schlDivCdDcds.SCHL_DIV_CD_MD || param.schlDivCdDcd === schlDivCdDcds.SCHL_DIV_CD_CREDIT ) {
            param.sprtAmt = param.sprtExptAmt * 0.5;
        }

        param.sprtAmt = param.sprtAmt - appBefTermBursAmt.value;
    }


    watch(() => fees, () => {
        totalPayment.value = fees.sprtTuitFee + fees.sprtSchlOperCost + fees.sprtTotStdntFee + fees.sprtPraceCost + fees.nsprtStdntFee + fees.nsprtBookCost + fees.nsprtGradPrepAmt;
        if (totalPayment.value !== 0 && totalPayment.value !== '') {
            if (paramCheck(param.schlDivCdDcd)) {
                showMessage('학교 구분을 선택해주세요');
                return false;
            }
        }
        calSupportFee();
    },{deep:true});

    watch(() => reduce, () => {
        totalReduce.value = reduce.redGradeBursAmt + reduce.redNtnBursAmt + reduce.etcBursAmt;

        if (totalReduce.value !== 0 && totalReduce.value !== '') {
            if (paramCheck(param.schlDivCdDcd)) {
                showMessage('학교 구분을 선택해주세요');
                return false;
            }
        }
        calSupportFee();
    },{deep:true});

    // watch(() => totalReduce.value, () => {
    //     param.sprtAmt = totalReduce.value - appBefTermBursAmt.value;
    //     // param.befTermBursAmt = totalReduce.value;
    // });

    watch(() => [param.schlDivCdDcd, totalPayment.value, totalReduce.value], () => {
        // 학점은행제인 경우 수강학점과 학위취득예정시기 입력
        if(param.schlDivCdDcd === '2061005') {
            isAcademicCreditBank.value = true;
        } else {
            isAcademicCreditBank.value = false;
            param.regCrseCrdit = 0;
            param.degAcqsSchedPerid = '';
        }
        calSupportFee ();
        // param.sprtExptAmt = (fees.sprtTuitFee + fees.sprtSchlOperCost + fees.sprtTotStdntFee + fees.sprtPraceCost) - totalReduce.value;
        //
        // //if (param.sprtExptAmt > 0) {
        //     if (param.schlDivCdDcd === schlDivCdDcds.SCHL_DIV_CD_KNOU) { // 방통대
        //         param.sprtAmt = param.sprtExptAmt;
        //     } else if (param.schlDivCdDcd === schlDivCdDcds.SCHL_DIV_CD_PHD || param.schlDivCdDcd === schlDivCdDcds.SCHL_DIV_CD_COMM || param.schlDivCdDcd === schlDivCdDcds.SCHL_DIV_CD_MD || param.schlDivCdDcd === schlDivCdDcds.SCHL_DIV_CD_CREDIT ) {
        //         param.sprtAmt = param.sprtExptAmt * 0.5;
        //     }
        //
        //     param.sprtAmt = param.sprtAmt - appBefTermBursAmt.value;
        // //}
    });

    onMounted(() => {
        if (props.data != null) { // 수정mode
            setParams(param, props.data);
            setParams(fees, props.data);
            setParams(reduce, props.data);
            setParams(major.value, props.data);
            setCostProof(props.data.proofs, transcriptFiles, proofDivCdDcds.PROOF_DIV_CD_SCHL_LAST_SEM_TRANS);
            setCostProof(props.data.proofs, tuitPayCertFiles, proofDivCdDcds.PROOF_DIV_CD_SCHL_TUIT_CERT);
            setCostProof(props.data.proofs, schlScholarCertFiles, proofDivCdDcds.PROOF_DIV_CD_SCHL_SCHOLAR_CERT);

            userAgreement.value = true;

            school.value.scholCd = props.data.schlCd;
            school.value.scholNm = props.data.schlNm;

            if (props.data.befTermBursAmt !== 0 && props.data.befTermBursAmt !== '') {
                befSchlDivCdDcd.value = props.data.befSchlDivCdDcd;
                if (befSchlDivCdDcd.value == '2061001') { // 방송통신대
                    appBefTermBursAmt.value = props.data.befTermBursAmt;
                } else {
                    appBefTermBursAmt.value = props.data.befTermBursAmt / 2;
                }
                param.sprtAmt = -1 * appBefTermBursAmt.value;
            }
            param.dpstYmd = ymdStrToFormat(param.dpstYmd, '-');

        }else {
            getBefTermBursAmt(); // 직전학기 장학금 수혜금액정보 가져오기
        }
        // console.log('직전학기 금액 : '+param.befTermBursAmt+' / 직전학기 금액 관리번호 : '+param.bursAmtBnfitRegno);
    });

    return {
        schlInDivCdDcds, grdTots,
        schlDivCdDcdOpt, schlInDivCdDcdOpt, termDivCdDcdOpt,
        isReadOnly, param, major, school, fees, reduce, totalPayment, totalReduce, toggles,
        transcriptFiles, tuitPayCertFiles, majorModal, collegeModal, isAcademicCreditBank,
        showPrice, showReducedPrice, userAgreement, schlScholarCertFiles,
        apply, toggleGrdTot, selectGrdTot,
        numbersOnly, emptyNumberOnClick, numberComma,
        closeToggles, closeModal, applyBtnEnable, cancel,
        searchSchool, calSupportFee, getBefTermBursAmt, extensions
    }
}