<template>
  <div v-if="paging.hasMore" class="page-buttons">
    <button class="kb-btn kb-btn-secondary" @click.stop="moreFunc(paging)"><span class="text">신청이력 더보기</span></button>
  </div>
</template>

<script>
export default {
  name: 'moreButton',
  props: {
    paging: Object,
    moreFunc: Function
  }
};
</script>