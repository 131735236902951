<template>
  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-tuition-support">
    <!-- main-header -->
    <lxp-main-header :show-breadcrumb="true"
                     :show-title="true"
                     :file-down-key="guideFile">
      <template v-slot:action>
        <div class="header-tab">
          <nav class="kb-nav-round-tabs">
            <ul class="kb-nav-list">
              <li class="kb-nav-item">
                <a href="#" class="kb-nav-link" :class="{ 'is-active' : menu===1 }" @click="menu=1"><span class="kb-nav-text">학비 지원 신청</span></a>
              </li>
              <li class="kb-nav-item">
                <a href="#" class="kb-nav-link" :class="{ 'is-active' : menu===2 }" @click="menu=2"><span class="kb-nav-text">부점장최고경영자과정 지원신청</span></a>
              </li>
            </ul>
          </nav>
        </div>
      </template>
    </lxp-main-header>
    <!-- //main-header -->
    <!-- main-content -->

    <div v-if="menu===1" class="main-content min-component">
      <!-- page-buttons -->
      <div class="page-buttons">
        <a href="javascript:" class="kb-btn kb-btn-primary" @click="isApplyYn"><span class="text">지원신청</span></a> <!--  isTuitionModalActive=true -->
      </div>
      <!-- section: 학비 지원안내 -->
      <article class="content-section">
        <header class="section-header">
          <h4 class="title">지원대상</h4>
        </header>
        <div class="guide-table">
          <div class="kb-table kb-table-striped">
            <table>
              <tbody>
              <tr>
                <td><span class="td-text">지급일 현재 재직중인 직원 (일반/계약)<br>※ 지급일 현재 정직자 및 파트타이머 제외<br>&nbsp;&nbsp;&nbsp;&nbsp;단, 국내외 석사학위 취득 목적의 자기계발휴직 Type I 선발자의 경우 지원<br>※ 학점은행제의 경우 학사 이상 학위 소지자는 지원대상에서 제외(단, 만 50세 이상 가능)</span></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </article>

      <article class="content-section section-divider">
        <header class="section-header">
          <h4 class="title"> 신청기한</h4>
        </header>
        <div class="guide-table">
          <div class="kb-table kb-table-striped">
            <table>
              <tbody>
              <tr>
                <td><span class="td-text"><span class="fw-bolder">학기 종료일(종강일)</span>로부터 <span class="fw-bolder text-red">6개월 이내</span></span></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </article>

      <article class="content-section section-divider">
        <header class="section-header">
          <h4 class="title"> 지원절차 및 시기</h4>
        </header>
        <div class="guide-table">
          <div class="kb-table kb-table-striped">
            <table>
              <tbody>
              <tr>
                <td class="d-flex justify-content-evenly">
                  <div style="float:left;border:1px solid rgba(252, 234, 194, 1); background-color: rgba(252, 234, 192, 1); border-radius:50%;width:150px;height:150px">
                    <div class="font-body16B mt-lg-4 text-center" style="color:rgba(148, 119, 31, 1)">Step.1</div>
                    <div class="mt-lg-4 text-center">학기 종료 후<br>필요서류 준비</div>
                  </div>
                  <div style="float:left;width:100px;height:150px;padding-left: 35px;padding-top: 65px">
                    <i class="icon-arrow-next" style="background-size: 100px 40px;"></i>
                  </div>
                  <div style="float:left;border:1px solid rgba(252, 234, 194, 1); background-color: rgba(252, 234, 192, 1); border-radius:50%;width:150px;height:150px">
                    <div class="font-body16B mt-lg-4 text-center" style="color:rgba(148, 119, 31, 1)">Step.2</div>
                    <div class="mt-lg-4 text-center">스타런에서<br>신청</div>
                  </div>
                  <div style="float:left;width:100px;height:150px;padding-left: 35px;padding-top: 65px">
                    <i class="icon-arrow-next" style="background-size: 100px 40px;"></i>
                  </div>
                  <div style="float:left;border:1px solid rgba(252, 234, 194, 1); background-color: rgba(252, 234, 192, 1); border-radius:50%;width:150px;height:150px">
                    <div class="font-body16B mt-lg-4 text-center" style="color:rgba(148, 119, 31, 1)">Step.3</div>
                    <div class="mt-lg-4 text-center">HR부(인재개발)<br>심사</div>
                  </div>
                  <div style="float:left;width:100px;height:150px;padding-left: 35px;padding-top: 65px">
                    <i class="icon-arrow-next" style="background-size: 100px 40px;"></i>
                  </div>
                  <div style="float:left;border:1px solid rgba(252, 234, 194, 1); background-color: rgba(252, 234, 192, 1); border-radius:50%;width:150px;height:150px">
                    <div class="font-body16B mt-lg-4 text-center" style="color:rgba(148, 119, 31, 1)">Step.4</div>
                    <div class="mt-lg-4 text-center">급여계좌로 지급<br><span class="font-body12">(전산 신청일 기준<br><span class="fw-bolder">익월 15일</span>)</span></div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </article>
      <!-- section: 지원 내용 -->
      <article class="content-section section-divider">
        <header class="section-header">
          <h4 class="title">지원내용</h4>
        </header>
        <div class="guide-table">
          <div class="kb-table kb-table-bordered-v2">
            <table>
              <colgroup>
                <col style="width:100px;" />
                <col style="width:260px;" />
                <col style="width:310px;" />
                <col style="width:240px;" />
              </colgroup>
              <thead>
              <tr>
                <th><span class="th-title">구분</span></th>
                <th><span class="th-title">정규 대학・대학원</span></th>
                <th><span class="th-title">학점은행제</span></th>
                <th><span class="th-title">한국방송통신 대학・대학원</span></th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td class="text-center"><span class="td-text">지원 과정</span></td>
                <td style="text-align: left"><span class="td-text">- 학위가 인정되는 국내 소재의 대학 및 대학원<br>- 2~3년제, 사이버대학 포함</span></td>
                <td style="text-align: left"><span class="td-text">- 학점은행제 학점 취득이 인정되는 교육기관<br>- 대학부설 사회(평생)교육원, 시간제등록 학습과목 포함</span></td>
                <td style="text-align: left"><span class="td-text">- 한국방송통신 대학 및 대학원</span></td>
              </tr>
              <tr>
                <td class="text-center"><span class="td-text">지원 범위<sup class="text-red">주1)</sup></span></td>
                <td class="text-center" colspan="2"><div class="td-text" style="height:50px; wdth:250px;margin-left:0; border:2px solid #fff; border-radius: 30px;line-height:50px">등록금의 50%</div></td>
                <td class="text-center"><span class="td-text">등록금의 100%</span></td>
              </tr>
              <tr>
                <td class="text-center"><span class="td-text">지원 조건</span></td>
                <td colspan="2" class="text-center">평균 평점 B학점(2.8/4.5) 또는 80점(80/100)이상</td>
                <td class="text-center"><span class="td-text">평균 평점 C학점(1.7/4.5)이상</span></td>
              </tr>
              <tr>
                <td class="text-center" rowspan="2"><span class="td-text">지원한도</span></td>
                <td>
                  <p class="td-text">전문대 : 4학기 이내</p>
                  <p class="td-text">대학교(학사) : 8학기 이내</p>
                  <p class="td-text">대학원(석사) : 5학기 이내</p>
                </td>
                <td class="text-center"><span class="td-text">140학점 이내<sup class="text-red">주2)</sup></span></td>
                <td class="text-center"><span class="td-text">학기 및 학점 제한없음</span></td>
              </tr>
              <tr>
                <td colspan="3" style="text-align: left"><span class="td-text">- 각 학위별 지원한도 내에서는 학교가 상이해도 지원 가능<br>
                  - 계절학기도 지원 가능하나 1학기로 산정</span></td>
              </tr>
              <tr>
                <td class="text-center"><span class="td-text">지원<br>제외과정</span></td>
                <td colspan="3"  style="text-align: left">
                  <p class="td-text">- 학위 미인정 과정 및 박사과정</p>
                  <p class="td-text">- 근무시간<sup>*</sup>중에 정규수업을 실시하는 과정(<sup>*</sup>인사규정 제10장 제54조 근무시간 준수)</p>
                  <p class="td-text">- 학기 시작일이 입행일 이전인 경우</p>
                  <p class="td-text">- 수강기간(학기)이 휴직/정직기간에 전부 또는 일부 포함된 경우</p>
                </td>
              </tr>

              </tbody>
            </table>
              <div class ="font-body14 mt-2">주1) 지원범위 : <span class="font-body16B">수업료, 입학금</span></div>
              <div class ="font-body14 mt-1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 재입학과 편입학의 경우 입학금은 지원 제외(한국방송통신 대학・대학원은 지원)</div>
              <div class ="font-body14 mt-1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 학비는 실부담금에 대한 지원으로, 현금이나 현물로 반대급부(장학금 등)를 받은 경우 해당금액은 차감 지원</div>
              <div class ="font-body14 mt-1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="font-body14B">단, 학업성취도의 결과인 교내 성적장학금은 차감하지 않고 지원</span>(한국방송통신 대학・대학원은 해당없음)</div>
              <div class ="font-body14 mt-1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 수업료, 입학금 등 필수 경비 외 선택적 경비(학생회비, 원우회비, 동문회비 등) 지원 불가</div>
              <div class ="font-body14 mt-2">주2) 2007.1.1 이후 정규 대학・대학원 학비지원을 받은 직원이 학점은행제를 신청할 경우, 정규 대학・대학원 1학기 당 학점은행제 지원한도를 18학점씩 차감. </div>
              <div class ="font-body14 mt-2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;반대의 경우에도 동일 기준 적용(학점은행제 18학점 당 정규 대학・대학원 1학기 적용)</div>
          </div>
        </div>
      </article>
      <!-- section: 지원절차 -->

      <article class="content-section section-divider">
        <header class="section-header">
          <h4 class="title"> 필요서류</h4>
        </header>
        <div class="guide-table">
          <div class="kb-table kb-table-striped">
            <table>
              <tbody>
              <tr>
                <td>1. 교육기관에서 발급한 등록금납입증명서(입금영수증 인정 불가)</td>
              </tr>
              <tr>
                <td>2. 해당 학기 성적증명서(진위확인코드 16자리 필수)</td>
              </tr>
              <tr>
                <td>3. 장학금 (비)수혜증명서, 연말정산용 교육비납입증명서 中 택1</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </article>
    </div>
    <!-- //main-content -->

    <!-- main-content -->
    <div v-if="menu===2" class="main-content min-component">
      <!-- page-buttons -->
      <div class="page-buttons">
        <a href="javascript:" class="kb-btn kb-btn-primary" @click="subTuitOpen"><span class="text">지원신청</span></a>
      </div>
      <!-- section: 지원안내 -->
      <article class="content-section">
        <header class="section-header">
          <h4 class="title">지원대상</h4>
        </header>
        <div class="guide-table">
          <div class="kb-table kb-table-striped">
            <table>
              <tbody>
              <tr>
                <td><p class="td-text">- 지급일 현재 재직 중인 부점장</p>
                  <p class="td-text">- L3이상 팀원/팀장 중 국은인상 수상자</p>
                  <p class="td-text">&nbsp;&nbsp;&nbsp;(업무추진실적최고 부문 및 기타업무추진우수 부문 수상자)</p>
                  <p class="td-text">※지급일 현재 휴・정직자 및 후선보임자 제외</p>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </article>

      <article class="content-section section-divider">
        <header class="section-header">
          <h4 class="title"> 신청기한</h4>
        </header>
        <div class="guide-table">
          <div class="kb-table kb-table-striped">
            <table>
              <tbody>
              <tr>
                <td><span class="td-text"><span class="fw-bolder">등록금 납입일</span>로부터 <span class="fw-bolder text-red">3개월 이내</span></span></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </article>

      <article class="content-section section-divider">
        <header class="section-header">
          <h4 class="title"> 지원절차 및 시기</h4>
        </header>
        <div class="guide-table">
          <div class="kb-table kb-table-striped">
            <table>
              <tbody>
              <tr>
                <td class="d-flex justify-content-evenly">
                  <div style="float:left;border:1px solid rgba(252, 234, 194, 1); background-color: rgba(252, 234, 192, 1); border-radius:50%;width:150px;height:150px">
                    <div class="font-body16B mt-lg-4 text-center" style="color:rgba(148, 119, 31, 1)">Step.1</div>
                    <div class="mt-lg-4 text-center">등록금 납입 후<br>필요서류 준비</div>
                  </div>
                  <div style="float:left;width:100px;height:150px;padding-left: 35px;padding-top: 65px">
                    <i class="icon-arrow-next" style="background-size: 100px 40px;"></i>
                  </div>
                  <div style="float:left;border:1px solid rgba(252, 234, 194, 1); background-color: rgba(252, 234, 192, 1); border-radius:50%;width:150px;height:150px">
                    <div class="font-body16B mt-lg-4 text-center" style="color:rgba(148, 119, 31, 1)">Step.2</div>
                    <div class="mt-lg-4 text-center">스타런에서<br>신청</div>
                  </div>
                  <div style="float:left;width:100px;height:150px;padding-left: 35px;padding-top: 65px">
                    <i class="icon-arrow-next" style="background-size: 100px 40px;"></i>
                  </div>
                  <div style="float:left;border:1px solid rgba(252, 234, 194, 1); background-color: rgba(252, 234, 192, 1); border-radius:50%;width:150px;height:150px">
                    <div class="font-body16B mt-lg-4 text-center" style="color:rgba(148, 119, 31, 1)">Step.3</div>
                    <div class="mt-lg-4 text-center">HR부(인재개발)<br>심사</div>
                  </div>
                  <div style="float:left;width:100px;height:150px;padding-left: 35px;padding-top: 65px">
                    <i class="icon-arrow-next" style="background-size: 100px 40px;"></i>
                  </div>
                  <div style="float:left;border:1px solid rgba(252, 234, 194, 1); background-color: rgba(252, 234, 192, 1); border-radius:50%;width:150px;height:150px">
                    <div class="font-body16B mt-lg-4 text-center" style="color:rgba(148, 119, 31, 1)">Step.4</div>
                    <div class="mt-lg-4 text-center">급여계좌로 지급<br><span class="font-body12">(전산 신청일 기준<br><span class="fw-bolder">익월 15일</span>)</span></div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </article>

      <article class="content-section section-divider">
        <header class="section-header">
          <div class="header-column">
            <h4 class="title">지원내용</h4>
<!--            <p class="subtext">부점장 최고경영자과정 과세 · 비과세 적용 구분(관련법령-소득세법 시행령 제 11조 학자금의 범위)</p>-->
          </div>
        </header>
        <div class="guide-table">
          <div class="kb-table kb-table-striped">
            <table>
              <colgroup>
                <col style="width:200px"/>
                <col style="width:auto;" />
              </colgroup>
              <thead>
              <tr>
                <th class="text-center"><span class="th-title">구분</span></th>
                <th><span class="th-title">내용</span></th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td class="text-center"><span class="td-text">지원과정</span></td>
                <td><p class="td-text">- 대학교 및 전문교육기관이 주관하는 최고경영자과정 등 지역 내 유수의 특별과정</p>
                  <p class="td-text">- 스포츠관련 교육과정은 대학교 주관과정만 지원 가능</p>
                </td>
              </tr>
              <tr>
                <td class="text-center"><span class="td-text">지원금액</span></td>
                <td><p class="td-text">- <span class="font-bodyB">5백만원</span> 한도 내에서 교육비의 <span class="font-bodyB">80%지원</span></p>
                  <p class="td-text">&nbsp;&nbsp;&nbsp;연수비용만 지원 가능, 원우회비, 세미나비 등은 지원 불가</p>
                </td>
              </tr>
              <tr>
                <td class="text-center"><span class="td-text">지원횟수</span></td>
                <td><p class="td-text">- 부점장 재직기간 중 <span class="font-bodyB">개인별 1회</span></p>
                  <p class="td-text">&nbsp;&nbsp;&nbsp;단, 전략회의 수상 부점장은 1회 추가 지원하며, 개인별 최대 2회까지 지원</p>
                  <p class="td-text">&nbsp;&nbsp;&nbsp;2회 합산신청 가능(HR부(인재개발) 담당자에게 별도 요청 필요)</p>
                </td>
              </tr>
              <tr>
                <td class="text-center"><span class="td-text">지원제외과정</span></td>
                <td><p class="td-text">- 근무시간<sup>*</sup> 중에 정규수업을 실시하는 과정(<sup>*</sup>인사규정 제10장 제54조 근무시간 준수)</p>
                  <p class="td-text">- 교육기간이 1년(12개월)을 초과하는 과정</p>
                  <p class="td-text">- 자격증 취득 교육과정</p>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </article>



      <article class="content-section section-divider">
        <header class="section-header">
          <h4 class="title"> 필요서류</h4>
        </header>
        <div class="guide-table">
          <div class="kb-table kb-table-striped">
            <table>
              <tbody>
              <tr>
                <td>
                  <div>교육기관에서 발급한 등록금납입증명서(입금영수증 인정 불가)</div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </article>

      <article class="content-section section-divider">
        <header class="section-header">
          <h4 class="title">유의사항</h4>
          <span class="subtext">※관련법령 : 소득세법 시행령 제 11조 학자금의 범위</span>
        </header>
        <div class="guide-table">
          <div class="kb-table kb-table-striped">
            <table>
              <colgroup>
                <col style="width:170px"/>
                <col style="width:170px"/>
                <col style="width:170px"/>
                <col style="width:170px"/>
                <col style="width:auto;" />
              </colgroup>
              <thead>
              <tr>
                <th><span class="th-title">대학교(원)<sup class="text-red">주1</sup></span></th>
                <th><span class="th-title">영업관련</span></th>
                <th><span class="th-title">6개월 이상</span></th>
                <th><span class="th-title">비고</span></th>
                <th><span class="th-title">과세구분</span></th>
              </tr>
              </thead>
              <tbody>
                <tr>
                  <td><span class="td-text"><i class="icon-o"></i></span></td>
                  <td><span class="td-text"><i class="icon-o"></i></span></td>
                  <td><span class="td-text"><i class="icon-o"></i></span></td>
                  <td><span class="td-text">확약서 제출<sup class="text-red">주2)</sup></span></td>
                  <td><p class="td-text font-body14B">비과세</p>
                    <p class="td-text">[연말정산 시교육비세액공제대상에해당할경우</p>
                    <p class="td-text">본인 부담금(교육비의 20%)에 대해 세액공제가능]</p></td>
                </tr>
                <tr>
                  <td><span class="td-text"><i class="icon-o"></i></span></td>
                  <td><span class="td-text"><i class="icon-o"></i></span></td>
                  <td><span class="td-text"><i class="icon-x"></i></span></td>
                  <td><span class="td-text">-</span></td>
                  <td><p class="td-text font-body14B">비과세</p>
                    <p class="td-text">[연말정산 시교육비세액공제대상에해당할경우</p>
                    <p class="td-text">본인 부담금(교육비의 20%)에 대해 세액공제가능]</p></td>
                </tr>
                <tr>
                  <td><span class="td-text"><i class="icon-o"></i></span></td>
                  <td><span class="td-text"><i class="icon-x"></i></span></td>
                  <td><span class="td-text">-</span></td>
                  <td><span class="td-text">-</span></td>
                  <td><span class="td-text font-body14B">과세</span></td>
                </tr>
                <tr>
                  <td><span class="td-text"><i class="icon-x"></i></span></td>
                  <td><span class="td-text">-</span></td>
                  <td><span class="td-text">-</span></td>
                  <td><span class="td-text">-</span></td>
                  <td><span class="td-text font-body14B">과세</span></td>
                </tr>
              </tbody>
            </table>
            <div class ="font-body14 mt-2">주1) 고등교육법에 따른 학교 [일반적인 대학교∙대학원이 해당되며 협회∙단체(신문사, 상공회의소) 등은 비교육기관으로 분류]</div>
            <div class ="font-body14 mt-2">주2) 당해교육기간을 초과하여 근무하지 아니하는 때에는 지급받은 금액을 반납하는 조건</div>
            <div class ="font-body14B mt-2 text-red">※ 연말정산 시 세액공제 가능여부는 해당 교육기관을 통해 확인 필요</div>
          </div>
        </div>
      </article>

    </div>
    <!-- //main-content -->

    <SupportTuition202309Modal v-model="isTuition202309ModalActive" />
<!--    <SupportTuitionModal v-model="isTuitionModalActive" />-->
    <SupportTuitionSubModal v-model="isTuitionSubModalActive" />
  </main>
  <!-- end::kb-main -->
</template>

<script>
import LxpMainHeader from '@/components/common/LxpMainHeader';
import {computed, ref} from 'vue';
// import SupportTuitionModal from '@/components/support/SupportTuitionModal';
import SupportTuition202309Modal from '@/components/support/SupportTuition202309Modal';
import SupportTuitionSubModal from '@/components/support/SupportTuitionSubModal';
import {changeNotice, downloadGuideFiles, getDsjChk} from '@/assets/js/modules/support/support-common'; //, getRefundChk
import {ACT_GET_LEARN_SUPPORT_SCHOOL_NOT_END_CNT} from "@/store/modules/support/support";
import {useStore} from "vuex";
import {useAlert} from "@/assets/js/modules/common/alert";
import {getItems} from "@/assets/js/util";
import {checkRefundConsult} from "@/assets/js/modules/consult/consult-common";

export default {
  name: 'SupportTuition',
  components: {SupportTuitionSubModal, SupportTuition202309Modal, LxpMainHeader}, //, SupportTuitionModal
  setup() {
    const store = useStore();
    const {showMessage, showConfirm} = useAlert();

    const guideFile = computed(() => downloadGuideFiles.find(x => x.page === 'school').fileKey);

    // const isTuitionModalActive = ref(false);
    const isTuition202309ModalActive = ref(false);
    const isTuitionSubModalActive = ref(false);

    const menu = ref(1);

    const subTuitOpen = async () => {
      //지원가능 대상자 체크
      // let chkVal = await getRefundChk();
      // if(getItems(chkVal) > 0){
      //   // showMessage('과정미수료 환입비용 미처리내역이 있습니다.<br>환입비용 처리 후 신청해주시기 바랍니다.');
      //   showMessage('미지급 또는 미환입 내역이 있습니다.<br>완료 후 신청해 주시기 바랍니다.');
      //   isTuitionSubModalActive.value = false;
      //   return false;
      // }else{
      //   isTuitionSubModalActive.value = true;
      // }

      checkRefundConsult(`#lrnerNm#님은 연수과정 미수료에 의한 <br>교육훈련비 #rtnAmt#원(#count#건) 환입 대상자 입니다.<br>환입 완료 전까지 <span style=color:#FF0000!important;>연수신청(자기주도학습지원 포함)이 제한</span>됩니다.<br><br> 연수비용 환입은 HR부(인재개발)<br>최슬비대리(☎7880)에게<br>문의 부탁드립니다.`, tuitionSubModalOpen );
    };

    const tuitionSubModalOpen=()=>{
      showConfirm({text: changeNotice, callback: () => {isTuitionSubModalActive.value = true;}})
    }

    // 미처리내역 확인 -- 미지급, 학습결과등록 미처리내역이 있을 경우 신청불가
    const isApplyYn  = async() => {
      //지원가능 대상자 체크
      let chkVal = await getDsjChk();
      if (getItems(chkVal) > 0){
        showMessage('학비 지원 신청 대상이 아닙니다.');
        isTuition202309ModalActive.value = false;
        return false;
      }else{
        // chkVal = await getRefundChk();
        // if(getItems(chkVal) > 0){
        //   // showMessage('과정미수료 환입비용 미처리내역이 있습니다.<br>환입비용 처리 후 신청해주시기 바랍니다.');
        //   showMessage('미지급 또는 미환입 내역이 있습니다.<br>완료 후 신청해 주시기 바랍니다.');
        //   isTuitionModalActive.value = false;
        //   return false;
        // }else{
        //   rstRegChk();
        // }

        checkRefundConsult(`#lrnerNm#님은 연수과정 미수료에 의한 <br>교육훈련비 #rtnAmt#원(#count#건) 환입 대상자 입니다.<br>환입 완료 전까지 <span style=color:#FF0000!important;>연수신청(자기주도학습지원 포함)이 제한</span>됩니다.<br><br> 연수비용 환입은 HR부(인재개발)<br>최슬비대리(☎7880)에게<br>문의 부탁드립니다.`, rstRegChk );
      }
    }

    const rstRegChk = ()=>{
      store.dispatch(`support/${ACT_GET_LEARN_SUPPORT_SCHOOL_NOT_END_CNT}`).then(res => {

        if(res && res.schlNotEndCnt && Number(res.schlNotEndCnt) > 0){
          showMessage('미지급 내역 또는 학습결과 미등록 내역이 있습니다.<br>해당 내역이 완료되면 신청해 주세요.');
          isTuition202309ModalActive.value = false;
          return false;
        } else {
          showConfirm({text: changeNotice, callback: () => {isTuition202309ModalActive.value = true;}});
        }
      }).catch(e => {
        console.error(e);
      });

    }

    return {
      guideFile,
      isTuition202309ModalActive,
      isTuitionSubModalActive,
      menu, isApplyYn, subTuitOpen
    }
  }
};
</script>