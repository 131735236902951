import LxpLayout from '@/views/layout/lxp/LxpLayout';
import LxpMobileLayout from "@/views/layout/lxp/mobile/LxpMobileLayout";
import navigationUtils from "@/assets/js/navigationUtils";

import TakeMain from "@/views/pages/take/TakeMain";
import TakeMobileMain from "@/views/pages/take/mobile/TakeMobileMain";

import TakeNotice from "@/views/pages/take/TakeNotice";
import TakeMobileNotice from "@/views/pages/take/mobile/TakeMobileNotice";

import TakeNoticeView from "@/views/pages/take/TakeNoticeView";
import TakeNoticeMobileView from "@/views/pages/take/mobile/TakeNoticeMobileView";

import TakeLib from "@/views/pages/take/TakeLib";
import TakeMobileLib from "@/views/pages/take/mobile/TakeMobileLib";

import TakeLibView from "@/views/pages/take/TakeLibView";
import TakeLibMobileView from "@/views/pages/take/mobile/TakeLibMobileView";

const isMobile = navigationUtils.any();

const takeRoutes = [
    {
        path: '/take',
        component: isMobile ? LxpMobileLayout : LxpLayout,
        children: [
            {
                path: 'home',
                name: 'Take',
                component: isMobile ? TakeMobileMain : TakeMain
            },
            {
                path: 'notice',
                name: 'TakeNotice',
                component: isMobile ? TakeMobileNotice : TakeNotice
            },
            {
                path: 'notice/:comNtcSn',
                name: 'TakeNoticeView',
                component: isMobile ? TakeNoticeMobileView : TakeNoticeView
            },
            {
                path: 'lib',
                name: 'TakeLib',
                component: isMobile ? TakeMobileLib : TakeLib
            },
            {
                path: 'lib/:comPostSn',
                name: 'TakeLibView',
                component: isMobile ? TakeLibMobileView : TakeLibView
            },
        ]
    }
];

export const setTakeRoutes = (routes) => {
    routes.push(...takeRoutes);
}