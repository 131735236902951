<template>
  <div class="contents">
    <div class="member-container" style="margin-top: 8px">
      <div v-if="subscribes.length === 0" class="board-search-wrapper">
        <div class="search-empty">
          <img src="@/assets/lxp/images/ssl/main/empty_image.png" alt>
        </div>
      </div>
      <ul v-else class="member-list">
        <li v-for="(subscribe, idx) in subscribes"  class="member-item" :key="idx">
          <div class="member">
            <router-link :to="{name:'SSLLearner', params: {lrnerId: subscribe.lrnerId}}" class="member-link"></router-link>
            <div class="member-avatar">
              <SSLProfileImg :target="subscribe"/>
            </div>
            <div class="member-content">
              <strong class="name">{{ subscribe.trgtLrnerNm }}</strong>
              <p class="team">{{ subscribe.trgtDeptNm }}</p>
            </div>
            <div v-if="!isSubscribed" class="member-follow" :class="{'is-active': subscribe.subscribeSts > 0}">
              <button class="kb-btn-follow" :class="{'kb-btn-follow-friends': (subscribe.subscribeSts === 1), 'kb-btn-follow-mate': (subscribe.subscribeSts === 2)}" @click="unSubscribe(subscribe)">
                <span class="text">{{ subscribe.subscribeSts === 2 ? '메이트' : '프렌즈' }}</span>
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>


</template>
<script>

import SSLProfileImg from '@/components/ssl/common/SSLProfileImg';
import {sslMySubscribeSetup} from '@/assets/js/modules/ssl/setup/ssl-my-setup';

export default {
  name: 'SSLMobileMySubscribe',
  components: {SSLProfileImg},
  props: {
    lrnTrgtGrpMstSn: Number
  },
  setup: sslMySubscribeSetup
}
</script>
