<template>
  <HrdCloudMobileHeader
      :title="type.title"
      v-model:keyword="search.keyword"
      :show-back="true"
      :show-search="true"
  >
  </HrdCloudMobileHeader>
  <main class="kb-main" id="kb-hrdcloud">
    <div class="main-content main-component">
      <div v-if="type && type.name && type.name.length > 0 && type.name !== 'recommend'" class="list-top">
<!--        <SortButtonSelect3-->
<!--            v-model="search.sortTyCdDcd"-->
<!--            v-model:toggle="toggles.sortTyCdDcd"-->
<!--            :options="sortTyCdDcd"-->
<!--            sequence-key="cd"-->
<!--            name-key="cdNm"-->
<!--            :is-num="false"-->
<!--            @selected="pagingFunc"-->
<!--        />-->
      </div>
      <HrdCloudList
          :is-loading="isLoading"
          :result="items"
          :session="session"
          :show-put="true"
          :paging="paging"
          :paging-func="pagingFunc"
      />
    </div>
  </main>
</template>

<script>
import {useStore} from 'vuex';
import {computed, onMounted, reactive, ref, watch} from 'vue';
import {useRoute} from 'vue-router';
import {getVideoUrl, timestampToDateFormat} from '@/assets/js/util';
import {
  closeToggles,
  getDcdOptions,
  getListFunc,
  getValueByQuery,
  pushQuery,
  setFilterByQuery,
} from '@/assets/js/ui.init';
import {mainCustomList, sortTyCdDcds} from '@/assets/js/modules/hrd/hrd-common';
import HrdCloudList from '@/components/hrdcloud/HrdCloudList';
import HrdCloudMobileHeader from '@/components/hrdcloud/mobile/HrdCloudMobileHeader';

export default {
  name: 'HrdCloudMobileViewAll',
  components: {HrdCloudMobileHeader, HrdCloudList},
  setup(){
    const store = useStore(),
          route = useRoute(),
          isLoading = ref(false),
          session = computed(() => store.state.auth.session),
          sortTyCdDcd = computed(() => getDcdOptions('2073'));

    const type = computed(() => {
      if (route.params && route.params.view) {
        let obj = mainCustomList.filter(x => x.name === route.params.view)[0]
        return {
          ...obj
        }
      }
      return {
        title: ''
      };
    });

    const items = ref([]);
    const toggles = reactive({
      sortTyCdDcd: false,
      lvl1: false
    });
    const paging = reactive({
      pageNo: 1,
      pageSize: 12,
      totalCount: 0
    });

    const search = reactive({
      keyword:'',
      sortTyCdDcd: sortTyCdDcds.SORT_TY_CD_LATEST
    });

    const videoTimeout = ref(null);
    const isActiveIdx = ref(-1);

    const handleVideoActive = (idx) => {
      if (idx === -2) {
        isActiveIdx.value = idx;
      }
      if (videoTimeout.value) {
        clearTimeout(videoTimeout.value);
        videoTimeout.value = null;
      }
      videoTimeout.value = setTimeout(() => {
        isActiveIdx.value = idx;
      },1000);
    };

    const getHrdCloudList = () => {
      if (isLoading.value) return;
      isLoading.value = true;
      if (type.value.url) {
        getListFunc(type.value.url, {...search, ...paging, ...type.value.params}, items, paging, () => { isLoading.value = false; }, () => { isLoading.value = false; });
      }
    };

    const pagingFunc = (pageNo) => {
      paging.pageNo = pageNo;
      if(pageNo){
        pushQuery({...search, pageNo});
      }else{
        pushQuery({...search});
      }
    };

    const handleFilterQuery = () => {
      if (route.name && route.name.indexOf('HrdCloud') > -1) {
        setFilterByQuery(search, route.query);
        if (search.sortTyCdDcd == null || search.sortTyCdDcd === '') search.sortTyCdDcd = sortTyCdDcds.SORT_TY_CD_LATEST;
        paging.pageNo = getValueByQuery(route.query, 'pageNo', true, 1);
        getHrdCloudList();
      }
    };

    watch(() => route.query, () => {
      handleFilterQuery();
    });

    onMounted(() => {
      handleFilterQuery();
    });

    return {
      type, session, isLoading, items, paging, search,
      isActiveIdx, handleVideoActive,
      pagingFunc,
      timestampToDateFormat,
      getVideoUrl,
      sortTyCdDcd,
      toggles,
      closeToggles,
    }

  }
};
</script>