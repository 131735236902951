<template>
  <div class="myssl-top">
    <div v-if="isReady && enableWrite"  class="top-writing" :class="writeClass">
      <template v-if="distCrseCd.indexOf('self') >= 0">
        <div class="writing-bg mt-3">
          <img v-if="distCrseCd === 'selfGa'" src="@/assets/lxp/images/ssl/myssl/bg_writing_self_0.jpg" alt="" />
          <img v-if="distCrseCd === 'selfGi'" src="@/assets/lxp/images/ssl/myssl/bg_writing_self_1.jpg" alt="" />
          <img v-if="distCrseCd === 'selfJa'" src="@/assets/lxp/images/ssl/myssl/bg_writing_self_2.jpg" alt="" />
          <img v-if="distCrseCd === 'selfIct'" src="@/assets/lxp/images/ssl/myssl/bg_writing_self_v2_2.jpg" alt="" />
<!--          <img v-if="groupIdx === 0" src="@/assets/lxp/images/ssl/myssl/bg_writing_self_0.jpg" alt="" />-->
<!--          <img v-if="groupIdx === 1" src="@/assets/lxp/images/ssl/myssl/bg_writing_self_1.jpg" alt="" />-->
<!--          <img v-if="groupIdx === 2" src="@/assets/lxp/images/ssl/myssl/bg_writing_self_2.jpg" alt="" />-->
<!--          <img v-if="groupIdx === 3" src="@/assets/lxp/images/ssl/myssl/bg_writing_self_v2_2.jpg" alt="" />-->
        </div>
        <router-link v-if="showWriteButton" :to="{name: 'SSLWrite', params: {socLrnPostSn: 0}}" class="kb-btn-writing">
          <span class="text">글쓰기</span>
          <i class="icon-writing"></i>
        </router-link>
      </template>

      <template v-if="distCrseCd === 'group'">
        <swiper
            :loop="false"
            slides-per-view="auto"
            :centered-slides="false"
        >
          <swiper-slide v-for="(item, idx) in items" :key="idx" class="top-writing writing-default">
            <div>
              <div class="writing-bg">
                <img v-if="idx % 4 === 0" src="@/assets/lxp/images/ssl/myssl/bg_studygroup_01.jpg" alt="">
                <img v-else-if="idx % 4 === 1" src="@/assets/lxp/images/ssl/myssl/bg_studygroup_02.jpg" alt="">
                <img v-else-if="idx % 4 === 2" src="@/assets/lxp/images/ssl/myssl/bg_studygroup_03.jpg" alt="">
                <img v-else-if="idx % 4 === 3" src="@/assets/lxp/images/ssl/myssl/bg_studygroup_04.jpg" alt="">

              </div>
              <div class="writing-content">
                <p class="content-text text-skip ssl-group-attend-text" :title="item.relNm">{{ item.relNm }}</p>
                <p class="content-text ssl-group-attend-time-text">{{ getTimeText(item.relBgngDt, item.relEndDt) }}</p>
                <router-link v-if="showWriteButton" :to="{name: 'SSLWrite', params: {socLrnPostSn: 0}, query: {relSn: item.relSn}}" class="kb-btn-writing" :class="!isWritten(item.relSn) ? 'kb-force-primary' : ''">
                  <span class="text">글쓰기</span>
                  <i class="icon-writing"></i>
                </router-link>
              </div>
            </div>
          </swiper-slide>
        </swiper>
<!--        <div class="kb-btn-section">-->
<!--          <router-link :to="{name: 'GroupLearning'}" class="kb-btn-link" >그룹러닝 모임 Home <i class="icon-link"></i></router-link>-->
<!--        </div>-->
      </template>

      <template v-if="distCrseCd === 'knowledge'">
        <swiper
            class="jisike-container"
            :class="{'ssl-one-items': items.length === 1}"
            :loop="false"
            slides-per-view="auto"
            :centered-slides="false"
            :space-between="items.length === 1 ? 0 : 72"
            :slides-offset-before="items.length === 1 ? 0 : 72"
            :slides-offset-after="items.length === 1 ? 0 :  72"
        >
          <swiper-slide v-for="(item, idx) in items"  class="jisike-item" :key="idx">
            <div class="jisike">
              <div class="jisike-box" @click="openContent(item)">
                <div class="box-image kb-mouse-cursor">
                  <template v-if="item.relThumb && item.relThumb.length > 0">
                    <img :src="item.relThumb" alt="" />
                  </template>
                  <template v-else>
                    <img v-if="idx % 4 === 0" src="@/assets/lxp/images/ssl/myssl/@img_jisike.png" alt="" />
                    <img v-else-if="idx % 4 === 1" src="@/assets/lxp/images/ssl/myssl/@img_jisike_02.jpg" alt="" />
                    <img v-else-if="idx % 4 === 2" src="@/assets/lxp/images/ssl/myssl/@img_jisike_03.jpg" alt="" />
                    <img v-else-if="idx % 4 === 3" src="@/assets/lxp/images/ssl/myssl/@img_jisike_04.jpg" alt="">
                  </template>
                </div>
                <div class="box-content kb-mouse-cursor">
                  <span class="content-year">{{ currentYear }}</span>
                  <span class="content-text text-skip" style="width: 120px;" :title="item.relNm">{{ item.relNm }}</span>
                </div>
              </div>
              <div class="jisike-date"></div>
            </div>
            <router-link v-if="showWriteButton" :to="{name: 'SSLWrite', params: {socLrnPostSn: 0}, query: {relSn: item.relSn}}" class="kb-btn-writing">
              <span class="text">글쓰기</span>
              <i class="icon-writing"></i>
            </router-link>
          </swiper-slide>
        </swiper>

<!--        <div class="kb-btn-section">-->
<!--          <router-link :to="{name: 'KnowledgeELearning'}" class="kb-btn-link">지식e-러닝 평가 Home <i class="icon-link"></i></router-link>-->
<!--        </div>-->
      </template>


      <template v-if="distCrseCd === 'hrd'">
        <swiper
            class="cloud-container"
            :class="{'ssl-one-items': items.length === 1}"
            :loop="false"
            slides-per-view="auto"
            :slides-offset-after="items.length === 1 ? 0 : 16"
            :slides-offset-before="items.length === 1 ? 0 : 16"
            :centered-slides="false"
            :space-between="items.length === 1 ? 0 : 16"
        >
          <swiper-slide v-for="(item, idx) in items" class="cloud-item" :key="idx">
            <div class="cloud">
              <div class="cloud-image kb-mouse-cursor" @click="goToHrd(item, $event)">
                <CommonTypeImage
                    :sequence="item.relSn"
                    :img-src="getThumbImg(item.relThumb)"
                />
              </div>
              <div class="cloud-content">
                <span class="content-title">{{ item.relCateNm }}</span>
                <span class="content-text text-skip" :title="item.relNm">{{ item.relNm }}</span>
              </div>
            </div>
            <router-link v-if="showWriteButton" :to="{name: 'SSLWrite', params: {socLrnPostSn: 0}, query: {relSn: item.relSn}}" class="kb-btn-writing">
              <span class="text">글쓰기</span>
              <i class="icon-writing"></i>
            </router-link>
          </swiper-slide>

        </swiper>
        <div class="kb-btn-section">
          <router-link :to="{name: 'HrdCloud'}" class="kb-btn-link">HRD클라우드 <i class="icon-link"></i></router-link>
        </div>
      </template>

      <template v-if="distCrseCd === 'book'">
        <swiper
            class="book-container"
            :class="{'ssl-one-items': items.length === 1}"
            :loop="false"
            slides-per-view="auto"
            :centered-slides="false"
            :space-between="items.length === 1 ? 0 : 72"
            :slides-offset-before="items.length === 1 ? 0 : 72"
            :slides-offset-after="items.length === 1 ? 0 : 72"
        >
          <swiper-slide v-for="(item, idx) in items"  class="book-item" :key="idx">

            <span v-if="!item.isFree">p.
              <input v-if="lrner == null" name="inputPage"  v-model="item.inputPage" :placeholder="item.currentPage" onfocus="placeholder = ''" @blur="updatePercentage(idx)" @keyup.enter="updatePercentage(idx)" style="width: 40px; text-align: right; color: #84888b;" />
              <text v-else>{{item.currentPage}}</text>
              / {{item.totalPage}}
            </span>

            <div v-if="!item.isFree" class="book-mark" :style="{ left: item.percentage * 86 / 100 + '%'}">
              <img src="@/assets/lxp/images/ssl/myssl/bookmark.png"/>
            </div>
            <div v-if="!item.isFree" class="progress mb-4" style="width: 140px; margin-left: 0px; position: absolute; z-index: 2;">
              <div class="progress-bar" style="background-color: rgb(101 162 255)" role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100" :style="{ width: item.percentage + '%'}"></div>
            </div>

            <div v-if="item.isFree" class="book" style="margin-top: 30px;" >
              <a href="javascript:" class="book-link"></a>
              <div class="book-cover">
                <img src="@/assets/lxp/mobile/images/ssl/myssl/img_book_kb.jpg" alt="" />
              </div>
              <div class="book-content">
                <span class="content-author">-</span>
                <span class="content-title">자유도서</span>
              </div>
            </div>

            <div v-else class="book" style=" margin-top: 6px;">

              <a href="javascript:" class="book-link"></a>
              <div class="book-cover">
                <img :src="item.thumb" alt="" />
                <div v-if="lrner == null" class="record-book">
                  <i @click="openTimerModal(idx)" class="icon-progress"></i>
                  <i @click="openMemoModal(idx)" style="border-left:2px solid #454138;" class="icon-symbol"></i>
                </div>
              </div>
              <div class="book-content">
                <span class="content-author">{{ item.bookDesc }}</span>
                <span class="content-title text-skip">{{ item.bookNm }}</span>
              </div>


            </div>
            <router-link v-if="showWriteButton && lrner == null" :to="{name: 'SSLWrite', params: {socLrnPostSn: 0}, query: {relSn: item.bookIsbn}}" class="kb-btn-writing">
              <span class="text">글쓰기</span>
              <i class="icon-writing"></i>
            </router-link>
          </swiper-slide>
        </swiper>
      </template>

      <template v-if="distCrseCd === 'trivia'">
        <div class="writing-bg">
          <img src="@/assets/lxp/mobile/images/ssl/myssl/bg_writing_alssl.jpg" alt="">
        </div>
        <router-link v-if="showWriteButton" :to="{name: 'SSLWrite', params: {socLrnPostSn: 0}}" class="kb-btn-writing">
          <span class="text">글쓰기</span>
          <i class="icon-writing"></i>
        </router-link>
      </template>

      <template v-if="distCrseCd === 'college'">
        <div class="writing-bg">
          <img src="@/assets/lxp/mobile/images/ssl/myssl/bg_writing_colleage.jpg" alt="">
        </div>
        <router-link v-if="showWriteButton" :to="{name: 'SSLWrite', params: {socLrnPostSn: 0}}" class="kb-btn-writing">
          <span class="text">글쓰기</span>
          <i class="icon-writing"></i>
        </router-link>
      </template>

      <template v-if="distCrseCd === 'live'">
        <swiper
            :loop="false"
            slides-per-view="auto"
            :centered-slides="false"
        >
          <swiper-slide v-for="(item, idx) in items" :key="idx" class="top-writing writing-default">
            <div>
              <div class="writing-bg">
                <CommonTypeImage
                    :sequence="item.relSn"
                    theme="live"
                />
              </div>
              <div class="writing-content">
                <p class="content-text text-skip" :title="item.relNm">{{ item.relNm }}</p>
                <p class="content-text">{{ getTimeText(item.relBgngDt, item.relEndDt) }}</p>
                <router-link v-if="showWriteButton" :to="{name: 'SSLWrite', params: {socLrnPostSn: 0}, query: {relSn: item.relSn}}" class="kb-btn-writing" :class="!isWritten(item.relSn) ? 'kb-force-primary' : ''">
                  <span class="text">글쓰기</span>
                  <i class="icon-writing"></i>
                </router-link>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </template>
    </div>
  </div>
</template>
<script>
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';

import {sslMyWriteSetup} from '@/assets/js/modules/ssl/setup/ssl-my-setup';
import CommonTypeImage from '@/components/common/CommonTypeImage';

export default {
  name: 'SSLMobileMyWrite',
  components: {
    CommonTypeImage,
    Swiper,
    SwiperSlide
  },
  props: {
    focusFunc: Object,
    lrner: Object
  },
  setup: sslMyWriteSetup
}
</script>
