import {useRouter} from 'vue-router';
import {useStore} from 'vuex';
import {useAlert} from '@/assets/js/modules/common/alert';
import {computed, onMounted, reactive, ref, watch} from 'vue';
import {dateToDatePart, isSuccess} from '@/assets/js/util';
import {getListFunc} from '@/assets/js/ui.init';
import {ACT_GET_CRSE_ACT_SUBSCRIBED_LIST} from '@/store/modules/course/course';
import {deleteCrseActLrnerSumup,} from '@/assets/js/modules/hrd/hrd-history-common';
import {actTyCdDcds} from '@/assets/js/modules/common/common';
import {closeSubscribed} from '@/assets/js/modules/hrd/hrd-common';

export const hrdSubscriptionSetup = () => {
  const router = useRouter();
  const store = useStore();
  const {showMessage, showConfirm} = useAlert();
  const isLoading = ref(true);
  const show = computed(() => store.state.hrdcloud.subscribed.show);
  // eslint-disable-next-line no-unused-vars
  const year = computed(() => {
    if (store.state.hrdcloud.subscribed.year && store.state.hrdcloud.subscribed.year.length > 0) {
      return store.state.hrdcloud.subscribed.year
    } else {
      return dateToDatePart(new Date()).year;
    }
  });

  // dateToDatePart(new Date()).year
  const items = ref([]);
  const paging = reactive({
    pageNo: 1,
    pageSize: 10,
    totalCount: 0
  });
  const hrdCloudCateSnList = ref([]);
  const checkAll = computed(() => {
    let cnt = 0;
    items.value.map(item => {
      hrdCloudCateSnList.value.filter(x => {
        if (item.hrdCloudCateSn === x) cnt++;
      });
    });
    if (cnt == items.value.length) return true;
    return false;
  });

  const clickCheckAll = () => {
    if (hrdCloudCateSnList.value.length > 0) {
      items.value.map(x => {
        if (hrdCloudCateSnList.value.filter(y => x.hrdCloudCateSn === y.hrdCloudCateSn).length === 0) hrdCloudCateSnList.value.push(x);
      });
    } else {
      hrdCloudCateSnList.value = items.value.map(x => x.hrdCloudCateSn);
    }
  };

  const clickUnCheckAll = () => {
    hrdCloudCateSnList.value = [];
  };

  const getList = () => {
    getListFunc(`course/${ACT_GET_CRSE_ACT_SUBSCRIBED_LIST}`, {...paging}, items, paging, () => {isLoading.value=false;}, ()=>{isLoading.value=false;});
  };

  const pagingFunc = (pageNo) => {
    if (pageNo > 0) paging.pageNo = pageNo;
    getList();
  };

  const handleUnsubscribe = async () => {
    const itemSize = hrdCloudCateSnList.value && hrdCloudCateSnList.value.length || 0;
    if (itemSize === 0) {
      showMessage('구독해지 할 채널을 선택해 주세요.');
      return;
    }

    showConfirm(`선택한 ${itemSize}개의 채널의 구독을 해지하시겠습니까?`, async () => {
      let res = await deleteCrseActLrnerSumup({
        actTyCdDcd: actTyCdDcds.ACT_TY_CD_CANCEL_SUBSCRIBE,
        hrdCloudCateSns: hrdCloudCateSnList.value
      });
      if (isSuccess(res)) {
        pagingFunc(paging.pageNo);
      }
    });
  };

  const goSubsPage = (item) => {
    let url = { name: 'HrdCloud', query: {} };
    let cateSnArr = item.cloudKeyLst.split('>');

    for (let i in cateSnArr) {
      let cateNm = 'category'+(Number(i)+1);
      url.query[cateNm] = cateSnArr[i];
    }

    closeSubscribed();
    router.push(url);
  };

  const handleClick = data => {
    hrdCloudCateSnList.value = data;
  };

  watch(() => show.value, () => {
    if (!show.value) {
      paging.pageNo = 1;
      clickUnCheckAll();
    } else {
      getList();
    }
  });

  onMounted(() => {
    getList();
  });

  return {
    isLoading, items, paging, pagingFunc, hrdCloudCateSnList,
    checkAll, clickCheckAll, clickUnCheckAll, handleUnsubscribe,
    show, closeSubscribed, goSubsPage, handleClick
  }
};