<template>
  <div class="kb-form-layer" :class="{'is-active':modelValue}">
    <div class="layer-container">
      <div class="kb-top-layer" @click.stop.prevent="$emit('update:modelValue',false)" />
      <div class="layer">
        <div class="kb-form-layer-options">
          <div class="layer-option">
            <h3>
              <button class="back" @click="goBack"><i class="icon-depth-back"></i></button>
              <span class="depth-title-wrap">
                <span v-for="(nm,idx) in cateNms" class="depth-title" :key="idx">
                  {{ nm }}
                </span>
              </span>
            </h3>
            <ul class="layer-option-list">
              <template v-if="searchParam.category4 > 0 && category5.length > 0">
                <li v-for="(category, idx) in category5" class="layer-option-item" :key="idx">
                  <a href="javascript:" class="layer-option-link" @click="selectOptions(category.hrdCloudCateSn, 5)">
                    <strong class="layer-option-text">{{category.hrdCloudCateNm}}</strong>
                  </a>
                </li>
              </template>
              <template v-else-if="searchParam.category3 > 0 && category4.length > 0">
                <li v-for="(category, idx) in category4" class="layer-option-item" :key="idx">
                  <a href="javascript:" class="layer-option-link" @click="selectOptions(category.hrdCloudCateSn, 4)">
                    <strong class="layer-option-text">{{category.hrdCloudCateNm}}</strong>
                  </a>
                </li>
              </template>
              <template v-else-if="searchParam.category2 > 0 && category3.length > 0">
                <li v-for="(category, idx) in category3" class="layer-option-item" :key="idx">
                  <a href="javascript:" class="layer-option-link" @click="selectOptions(category.hrdCloudCateSn, 3)">
                    <strong class="layer-option-text">{{category.hrdCloudCateNm}}</strong>
                  </a>
                </li>
              </template>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.layer-container { flex-direction:column; }
.kb-top-layer { width:100%;flex:1 1 auto;position:relative;overflow:hidden; }
.layer {height:auto;}
</style>
<script>
import {computed, onMounted, reactive, watch} from 'vue';
import {useStore} from 'vuex';
import {useRoute} from 'vue-router';
import {goBack} from '@/assets/js/modules/common/common';
import {setParamsByQuery} from '@/assets/js/util';
import {pushQuery} from '@/assets/js/ui.init';

export default {
  name: 'HrdCloudMobileCategory',
  props: {
    modelValue: Boolean
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const store = useStore(), route = useRoute();

    const search = reactive({
      keyword: '',
      sortTypCdDcd: '',
      pageNo: 0,
    });

    const searchParam = reactive({
      category1: 0,
      category2: 0,
      category3: 0,
      category4: 0,
      category5: 0
    });

    // search requestParam에서 가져와서 넣어줘야함
    const category = computed(() => store.state.hrdcloud.hrdcloudCates),
          // category2 = computed(() => category.value.filter(x => x.lvl === 2 && x.upHrdCloudCateSn === searchParam.category1 && x.stt === '00')),
          category3 = computed(() => category.value.filter(x => x.lvl === 3 && x.upHrdCloudCateSn == searchParam.category2 && x.stt === '00')),
          category4 = computed(() => category.value.filter(x => x.lvl === 4 && x.upHrdCloudCateSn == searchParam.category3 && x.stt === '00')),
          category5 = computed(() => category.value.filter(x => x.lvl === 5 && x.upHrdCloudCateSn == searchParam.category4 && x.stt === '00'));

    const getTitle = (sn) => {
      return category.value.filter(x => x.hrdCloudCateSn === sn)[0].hrdCloudCateNm;
    };

    const cateNms = computed(() => {
      let nms = [];
      Object.keys(searchParam).forEach((key,idx) => {
        if (idx > 1 && searchParam[key] > 0) {
          nms.push(getTitle(searchParam[key]));
        }
      });
      return nms;
    });

    const selectOptions = (hrdCloudCateSn, lvl) => {
      if (lvl === 3) {
        searchParam.category4 = 0;
        searchParam.category5 = 0;

        searchParam.category3=hrdCloudCateSn;
        pushQuery({...search, ...searchParam});

        if (category4.value.length === 0) emit('update:modelValue',false);
      } else if (lvl === 4) {
        searchParam.category5 = 0;

        searchParam.category4=hrdCloudCateSn;
        pushQuery({...search, ...searchParam});

        if (category5.value.length === 0) emit('update:modelValue',false);
      } else if (lvl === 5) {

        searchParam.category5=hrdCloudCateSn;
        pushQuery({...search, ...searchParam});

        emit('update:modelValue',false);
      }
    };

    const setCateByQuery = () => {
      if (route.name && route.name.indexOf('HrdCloud') > -1 && route.query) {
        setParamsByQuery(route, search, true);
        setParamsByQuery(route, searchParam, true);

        if (route.query.category2) {
          searchParam.category2 = Number(route.query.category2);
        }
        if (route.query.category3) {
          searchParam.category3 = Number(route.query.category3);
        }
        if (route.query.category4) {
          searchParam.category4 = Number(route.query.category4);
        }
        if (route.query.category5) {
          searchParam.category5 = Number(route.query.category5);
        }
      }
    };

    watch(() => route.query, () => {
      setCateByQuery();
    },{deep:true});

    onMounted(() => {
      setCateByQuery();
    });

    return {
      searchParam,
      category3, category4, category5,
      cateNms,
      selectOptions,
      goBack
    }
  }
};
</script>