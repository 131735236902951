<template>
  <div class="page-container">
    <div class="page-body">
      <div class="board-view-container">
        <div class="board-view-top view-component">
          <div class="top-column">
            <button class="kb-btn-type back" @click="goBack">
              <span class="ic-bg"><i class="icon-type-back"></i></span>
              <span class="text">이전</span>
            </button>
          </div>

          <div v-if="atchs.length > 0" class="top-column">
            <div class="dropdown" :class="{'is-active': atchToggle}">
              <button class="kb-btn-type inner save dropdown-toggle" @click.stop="toggleAtch(true)">
                <i class="icon-type-file"></i>
                <span class="text">첨부파일</span>
              </button>

              <div class="dropdown-menu dropdown-file">
                <div  v-for="(atch, idx) in atchs" class="dropdown-inner" :key="idx" @click.stop="downloadAtch(idx)">
                  <a href="javascript:" class="file-item" style="cursor:pointer !important;">
                    <span class="file-name">{{ atch.fileNm }}</span>
                    <span class="file-size">{{ convertToStorageBytes(atch.fileSz) }} </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isReady" class="board-view-body">
          <div class="board-view-header view-component" style="position:unset!important;">
            <h2 class="title">{{ notice.ntcTitle }}</h2>
            <div class="page-info">
              <span class="date">{{ timestampToDateFormat(notice.regDt, 'yyyy.MM.dd') }}</span>
            </div>
          </div>
          <div class="board-view-body view-component" style="position:unset!important;">
            <div class="editor-area">
              <div v-html="notice.ntcCn"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {reactive, ref} from 'vue';
import {
  collectionCheck,
  convertToStorageBytes,
  getItem,
  lengthCheck,
  setParams,
  timestampToDateFormat,
} from '@/assets/js/util';
import {useStore} from 'vuex';
import {useRoute} from 'vue-router';
import {goBack, insertSocAct} from '@/assets/js/modules/ssl/ssl-common';
import {actTyCdDcds} from '@/assets/js/modules/common/common';
import {ACT_UPDATE_NOTICE_CNT} from '@/store/modules/help/help';
import {ACT_GET_SSL_NTC} from '@/store/modules/ssl/ssl-index';
import {ACT_DOWNLOAD_COM_ATCH} from '@/store/modules/board/board';

export default {
  name: 'SSLNoticeView',
  components: {
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const isReady = ref(false);


    const notice = reactive({
      comNtcSn: route.params.socLrnNtcSn,
      ntcTitle: '',
      ntcCn: '',
      inqCnt: 0,
      regDt: 0
    });

    const atchToggle = ref(false);
    const atchs = ref([]);

    const readNotice = () => {
      store.dispatch(`help/${ACT_UPDATE_NOTICE_CNT}`, {comNtcSn: notice.comNtcSn}).then(() => {}).catch(() => {});
    }

    const getNotice = () => {
      store.dispatch(`ssl/${ACT_GET_SSL_NTC}`, notice.comNtcSn).then(res => {
        if(lengthCheck(res)){
          const item = getItem(res);
          setParams(notice, item);
          if(collectionCheck(item.comFileAtchs, 'comFileAtchSn')){
            atchs.value = item.comFileAtchs;
          }

          insertSocAct(actTyCdDcds.ACT_TY_CD_READ, notice.comNtcSn, 'socLrnNtcSn');
          readNotice();

          isReady.value = true;
        }else{
          notice.comNtcSn = 0;
        }
      }).catch(() => {
        notice.comNtcSn = 0;
      });
    }

    const toggleAtch = () => {
      atchToggle.value = !atchToggle.value;
    }

    const closeAtch = () => {
      atchToggle.value = false;
    }

    const downloadAtch = (idx) => {
      store.dispatch(`board/${ACT_DOWNLOAD_COM_ATCH}`, {secuKey: atchs.value[idx].secuKey,fileName: atchs.value[idx].fileNm});
    }

    if(notice.comNtcSn > 0){
      getNotice();
    }

    return {
      isReady,

      atchToggle,
      notice,
      atchs,
      goBack,
      toggleAtch,
      closeAtch,
      timestampToDateFormat,
      downloadAtch,
      convertToStorageBytes

    }

  }
}
</script>
