<template>
  <article class="home-section section-myactivity" :class="{'is-open': isOpen}">
    <header class="section-header">
      <h3 class="title">내 활동</h3>
    </header>
    <div class="section-body">
      <div class="activity">
        <div class="activity-icon">
          <i class="icon-activity-note"></i>
        </div>
        <div class="title">
          <strong>학습노트 작성</strong>
        </div>
        <span class="text">{{socStat.boardCnt}}</span>
      </div>
      <div class="activity" :class="{'is-new': !todayAttended}" @click="showAttendance">
        <div class="activity-icon">
          <i class="icon-activity-att"></i>
        </div>
        <div class="title">
          <strong>총 출석일</strong>
        </div>
        <span class="text">{{ attendanceCnt }}</span>
      </div>
      <div class="activity" :class="{'is-new': (socStat.cmntTodayReceiveCnt > 0)}" @click="showActFeed('cmntRec')">
        <div class="activity-icon">
          <i class="icon-activity-cmt-in"></i>
        </div>
        <div class="title">
          <strong>받은 댓글</strong>
        </div>
        <span class="text">{{ socStat.cmntReceiveCnt }}</span>
      </div>
      <div class="activity" :class="{'is-new': (socStat.likeTodayGetCnt > 0)}" @click="showActFeed('likeGet')">
        <div class="activity-icon">
          <i class="icon-activity-like-in"></i>
        </div>
        <div class="title">
          <strong>받은 좋아요</strong>
        </div>
        <span class="text">{{ socStat.likeGetCnt }}</span>
      </div>

      <div class="activity">
        <div class="activity-icon">
          <i class="icon-activity-badge"></i>
        </div>
        <div class="title">
          <strong>취득배지수</strong>
        </div>
        <span class="text">{{ myBadges.length }}</span>
      </div>
      <div class="activity">
        <div class="activity-icon">
          <i class="icon-activity-rm"></i>
        </div>
        <div class="title">
          <strong>러닝메이트</strong>
        </div>
        <span class="text">{{ mateCount }}</span>
      </div>
      <div class="activity">
        <div class="activity-icon">
          <i class="icon-activity-note-in"></i>
        </div>
        <div class="title">
          <strong>내 학습노트 조회수</strong>
        </div>
        <span class="text">{{ socStat.boardInqCnt }}</span>
      </div>

      <div class="activity">
        <div class="activity-icon">
          <i class="icon-activity-share"></i>
        </div>
        <div class="title">
          <strong>내 학습노트 공유</strong>
        </div>
        <span class="text">{{ socStat.sharedCnt }}</span>
      </div>
      <div class="activity">
        <div class="activity-icon">
          <i class="icon-activity-cmt"></i>
        </div>
        <div class="title">
          <strong>남긴 댓글</strong>
        </div>
        <span class="text">{{ socStat.cmntWriteCnt }}</span>
      </div>
      <div class="activity">
        <div class="activity-icon">
          <i class="icon-activity-like"></i>
        </div>
        <div class="title">
          <strong>남긴 좋아요</strong>
        </div>
        <span class="text">{{ socStat.likeGiveCnt }}</span>
      </div>

      <div class="activity" @click="showShareList">
        <div class="activity-icon">
          <i class="icon-activity-share-b"></i>
        </div>
        <div class="title">
          <strong>내가 공유한 노트수</strong>
        </div>
        <span class="text">{{ socStat.shareGivCnt }}</span>
      </div>
    </div>

    <button class="kb-btn-action" @click="toggleOpen">
      <span class="text is-close">펼치기<i class="icon-down"></i></span>
      <span class="text is-open">접기<i class="icon-up"></i></span>
    </button>
  </article>

</template>
<script>
import {sslHomeActivitySetup} from '@/assets/js/modules/ssl/ssl-home';

export default {
  name: 'SSLMobileActivity',
  setup: sslHomeActivitySetup

}
</script>
