<template>
    <!-- begin::kb-main -->
    <main class="kb-main" id="kb-reinstatement-board">
      <!-- main-header -->
      <lxp-main-header :show-title="true" :show-back="true">
        <template v-slot:inner>
          <div class="header-column">
            <div class="kb-form-dropdown download-dropdown" :class="{'is-active': showAttachments}" data-offset="bottom-right" @click="showAttachments = !showAttachments">
              <button v-if="files.length > 0" class="kb-form-dropdown-btn kb-btn kb-btn-secondary kb-btn-sm rounded-lg"><i class="icon-download32"></i><span class="text">첨부파일</span></button>
              <div class="kb-form-dropdown-options">
                <div class="dropdown-option">
                  <ul class="dropdown-option-list">
                    <li v-for="(item, idx) in files" :key="idx" class="dropdown-option-item">
                      <a href="javascript:" class="dropdown-option-link" :title="item.fileNm" @click.stop="downloadComAtch(item)">
                        <span class="dropdown-option-text">{{item.fileNm}}</span><span class="download-mb">{{ convertToStorageBytes(item.fileSz )}}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </template>
      </lxp-main-header>
      <!-- //main-header -->

      <!-- main-content -->
      <div class="main-content main-component">
        <div class="board-view-container">
          <div class="view-header">
            <h3 class="title">{{ item.postTitle }}</h3>
            <p class="subtitle">{{getDateFormat('yyyy.MM.dd hh:mm', item.regDt) }} 등록</p>
          </div>
          <div class="view-body min-component">
            <div class="view-content" v-html="item.postCn">
            </div>
          </div>
        </div>
      </div>
      <!-- //main-content -->

    </main>
    <!-- end::kb-main -->
</template>

<script>

import {computed, ref} from "vue";
import {useStore} from "vuex";
import {useRoute} from "vue-router/dist/vue-router";
import {ACT_GET_HELP_FILE_ATCH_LIST, ACT_GET_HELP_LIB} from "@/store/modules/help/help";
import {convertToStorageBytes, getItem, getItems, getText, lengthCheck, timestampToDateFormat} from "@/assets/js/util";
import LxpMainHeader from "@/components/common/LxpMainHeader";
import {downloadComAtch} from "@/assets/js/modules/help/help-common";

export default {
  name: "TakeLibView",
  components: {LxpMainHeader},
  setup() {
    const store = useStore();
    const route = useRoute();

    const showAttachments = ref(false);

    const comPostSn = computed(() => route.params.comPostSn);

    const item = ref({});
    const files = ref([]);

    const getTakeLib = () => {
      if (route.params.comPostSn > 0) {
        store.dispatch(`help/${ACT_GET_HELP_LIB}`, comPostSn.value
        ).then(res => {
          if (lengthCheck(res)) {
            console.log(res)
            item.value = getItem(res);
            getTakeNoticeFileAtchList();
          }
        }).catch(e => {
          console.error(e);
        })
      }
    };

    const getTakeNoticeFileAtchList = () => {
      store.dispatch(`help/${ACT_GET_HELP_FILE_ATCH_LIST}`, {
        comPostSn: comPostSn.value
      }).then(res => {
        if (lengthCheck(res)) {

          files.value = getItems(res);
        } else {
          files.value = [];
        }
      }).catch(e => {
        console.error(e);
      })
    };

    getTakeLib();

    const getDateFormat = (format, value) => {
      if(value){
        return timestampToDateFormat(value, format ? format : 'yyyy.MM.dd');
      }
      return '-';
    };

    return {
      item,
      files,
      showAttachments,
      getDateFormat,
      getText,
      convertToStorageBytes,
      downloadComAtch
    }

  }

}
</script>
<style type="text/css">
.view-content p{
  font: unset;
}

</style>