<template>
  <div class="popup-container" :class="{ 'is-active' : modelValue }">
    <div class="popup" id="popup-hrdcloud-map">
      <div class="popup-inner">
        <section class="cloud-map-container">
          <header class="section-header">
            <h3 class="title">클라우드 Map</h3>
            <div class="header-side">
              <button class="kb-btn kb-btn-outline" @click="expandAll"><span class="text">전체 펼치기</span><i class="icon-plus"></i></button>
              <button class="kb-btn kb-btn-outline" @click="collapseAll"><span class="text">전체 접기</span><i class="icon-minus"></i></button>
            </div>
          </header>
            <!-- map-list -->
          <HrdCloudMapTree :items="treeItems" @toggleNode="toggleNode" @searchCate="searchCate"/>
        </section>
        <div class="popup-close">
          <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close"></i></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from 'vue';
import {useRouter} from 'vue-router';
import HrdCloudMapTree from '@/components/hrdcloud/HrdCloudMapTree';
import {ACT_GET_HRDCLOUD_CATE_TREE_LIST} from '@/store/modules/hrdcloud/hrdcloud';
import {useStore} from 'vuex';
import {getItems, lengthCheck} from '@/assets/js/util';

export default {
  name: 'HrdCloudMap2',
  components: {HrdCloudMapTree},
  props: {
    modelValue: Boolean
  },
  setup(props, {emit}) {
    const router = useRouter();
    // const showTree = ref(false);
    const treeItems = ref([]);
    // const isAllExpanded = ref(false);

    const closeModal = () => {
      emit('update:modelValue',false);
    };

    // const expandAll = () => {
    //   isAllExpanded.value = true;
    //   showTree.value = false;
    //
    //   treeItems.value = treeItems.value.map(x => {
    //     x.expand = true;
    //     const recursive = (child) => {
    //       for (let a in child) {
    //         child[a].expand = true;
    //         if (child[a].children != null) {
    //           recursive(child[a].children);
    //         }
    //       }
    //     };
    //     recursive(x.children);
    //     return x;
    //   });
    //
    //   setTimeout(() => {
    //     showTree.value = true;
    //   },0);
    // };

    // const collapseAll = () => {
    //   isAllExpanded.value = false;
    //   showTree.value = false;
    //
    //   treeItems.value = treeItems.value.map(x => {
    //     x.expand = false;
    //     const recursive = (child) => {
    //       for (let a in child) {
    //         child[a].expand = false;
    //         if (child[a].children != null) {
    //           recursive(child[a].children);
    //         }
    //       }
    //     };
    //     recursive(x.children);
    //     return x;
    //   });
    //
    //   setTimeout(() => {
    //     showTree.value = true;
    //   },0);
    // };

    const searchCategory = (e, data) => {
      let url = { name: 'HrdCloud', query: {} };
      let cateSnArr = data.hrdCloudCateSnLst.split(',');

      for (let i in cateSnArr) {
        let cateNm = 'category'+(Number(i)+1);
        url.query[cateNm] = cateSnArr[i];
      }

      router.push(url);
      closeModal();
    };

    const toggleNode = (arr) => {
        let target = treeItems.value;
        for (let i = 0; i < arr.length; i++) {
            if (i === (arr.length - 1)) {
                target[arr[i]].toggle = !target[arr[i]].toggle;
            } else {
                target = target[arr[i]].children;
            }
        }
    }

    const searchCate = (arr) => {
      const query = {};
      arr.forEach((x,i) => {
        query[`category${i+1}`] = x;
      });
      router.push({query});
      closeModal();
    }

    const expandAll = () => {
        treeItems.value.forEach(x => {
            controlNode(x, true);
        });
    }

    const collapseAll = () => {
        treeItems.value.forEach(x => {
            if (x && x.children && x.children.length > 0) {
                x.children.forEach(y => {
                    controlNode(y, false);
                });
            }
        });
    }

    const controlNode = (target, toggle) => {
        target.toggle = toggle;

        if (target && target.children && target.children.length > 0) {
            target.children.forEach(x => {
                controlNode(x, toggle);
            });
        }
    }

    const store = useStore();
      store.dispatch(`hrdcloud/${ACT_GET_HRDCLOUD_CATE_TREE_LIST}`).then(res => {
          if (lengthCheck(res)) {
              const items = getItems(res);
              treeItems.value = items.filter(x => x.hrdCloudCateNm === '직무 클라우드' || x.hrdCloudCateNm === '지식 클라우드').map(x => ({...x, toggle: true}));
          }
      });

    return {

      treeItems,
      closeModal,
      searchCategory,
      toggleNode,
      searchCate,
      expandAll,
      collapseAll
    }
  }
};
</script>
