import ApiService from "@/assets/js/api.service";

export const ACT_GET_DUTY_LRN_STATUS_LIST = 'actGetDutyLrnStatusList';

const state = {
};

const mutations = {
};

const actions = {
    [ACT_GET_DUTY_LRN_STATUS_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/dept/duty-lrn-stt', params).then(response => resolve(response))
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};