<template>
  <div class="popup-container is-active">
    <!-- popup -->
    <div class="popup" id="popup-quiz">
      <!-- popup > popup-inner -->
      <div class="popup-inner">
        <div>
          <!-- popup-inner > popup-header -->
          <header class="popup-header">
            <h3 class="title">퀴즈 출제결과 상세보기</h3>
          </header>
          <!-- popup-inner > popup-content -->
          <div class="popup-content">
            <!-- popup-section:퀴즈 출제 결과정보 -->
            <article class="popup-section">
              <header class="section-header">
                <h4 class="title">퀴즈 출제 결과정보</h4>
              </header>
              <div class="section-content">
                <div class="kb-table kb-table-striped">
                  <table>
                    <colgroup>
                      <col style="width:180px">
                      <col style="width:auto">
                    </colgroup>
                    <tbody>
                    <tr>
                      <th><span class="th-title">그룹코드 ・ 모임명</span></th>
                      <td><span class="td-text">({{rsltItem.distCrseSn}})/{{rsltItem.objNm}}</span></td>
                    </tr>
                    <tr>
                      <th><span class="th-title">퀴즈문제지명</span></th>
                      <td><span class="td-text">{{rsltItem.lrnEvlNm}}</span></td>
                    </tr>
                    <tr>
                      <th><span class="th-title">제출인원</span></th>
                      <td><span class="td-text">{{rsltItem.evlCnt}}</span></td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </article>
            <!-- popup-section:퀴즈제출자 명단 -->
            <article class="popup-section">
              <header class="section-header">
                <h4 class="title">퀴즈제출자 명단 <span class="subtitle">{{rsltDtlBodies.length}}</span></h4>
              </header>
              <div class="section-content">
                <div class="kb-table kb-table-striped">
                  <table>
                    <colgroup>
                      <col style="width:100px">
                      <col style="width:140px">
                      <col style="width:140px">
                      <col v-for="(idx) in rsltColCnt" :key="idx" style="width:auto" />
                      <col style="width:240px">
                    </colgroup>
                    <thead>
                    <tr>
                      <th><span class="th-title">NO</span></th>
                      <th><span class="th-title">직원명</span></th>
                      <th><span class="th-title">직원번호</span></th>
                      <th v-for="(idx) in rsltColCnt" :key="idx"><span class="th-title">{{idx}}번</span></th>
                      <th><span class="th-title">제출일시</span></th>
                    </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, idx) in rsltDtlBodies" :key="idx">
                        <td><span class="td-text">{{idx+1}}</span></td>
                        <td><span class="td-text">{{item.lrnerNm}}</span></td>
                        <td><span class="td-text">{{item.lrnerId}}</span></td>
                        <td v-for="(ansItem, ansIdx) in item.ansrItem" :key="ansIdx"><span class="td-text">{{ansItem.inptAns}} ({{ansItem.crctAnsYn}})</span></td>
                        <td><span class="td-text">{{item.regDt}}</span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
      <!-- popup > popup-close -->
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeLayer"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
    <!-- //popup -->
  </div>
</template>

<script>
import {ref} from 'vue';
import {getItems, lengthCheck, timestampToDateFormat} from "@/assets/js/util";
import {ACT_GET_QUIZ_RSLT_DTL_LIST} from "@/store/modules/quiz/quiz";
import {useStore} from "vuex";

export default {
  name: "QuizMngResultView",
  props:{
    isQuizMngRsltDtlShow: Boolean,
    rsltItem: Object,
  },
  emits:['update:isQuizMngRsltDtlShow'],
  setup(props, {emit}){

    const store = useStore();
    const rsltDtlBodies = ref([])
    const rsltDtlAns = ref([]);
    const rsltColCnt = ref(0);

    const getRsltDtlList = () => {
      store.dispatch(`quiz/${ACT_GET_QUIZ_RSLT_DTL_LIST}`, {
        lrnTyEvlMstSn: props.rsltItem.lrnTyEvlMstSn,
        lrnEvlMstSn: props.rsltItem.lrnEvlMstSn,
        distObjSn: props.rsltItem.distObjSn,
      }).then(res => {
        if (lengthCheck(res)) {
          rsltDtlBodies.value = getItems(res);
          rsltDtlAns.value = res.ansItems;
          rsltDtlBodies.value.forEach(item => {
            item.ansrItem = [];
            rsltDtlAns.value.forEach(ansItem => {
              if(item.lrnerId === ansItem.lrnerId) {
                item.ansrItem.push(ansItem);
                ansItem.crctAnsYn = ansItem.crctAnsYn === 'Y' ? 'O' : 'X'
              }
            })
            item.regDt = timestampToDateFormat(item.regDt, 'yyyy.MM.dd hh:mm:ss')
          })

          rsltColCnt.value = parseInt(Math.max.apply(null, rsltDtlBodies.value.map(x => x.rsltCnt)));

        } else {
          rsltDtlBodies.value = [];
        }
      }).catch(e => {
        console.error(e);
      });
    };

    getRsltDtlList();

    const closeLayer = () => {
      emit('update:isQuizMngRsltDtlShow', false)
    }

    return{
      rsltDtlBodies,
      rsltDtlAns,
      rsltColCnt,
      closeLayer,
    }
  }
}
</script>