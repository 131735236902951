<template>
  <div class="badge-container" :class="{'is-active': show}">
    <div class="popup popup-get-badge" @click="closeModal">
      <!-- get-badge-container -->
      <swiper
          class="get-badge-container"
          :modules="modules"
          :speed="750"
          :pagination="{
              el: '.badge-pagination',
          }"
      >
        <swiper-slide v-for="(badge, idx) in myBadges" class="item" :key="idx">
          <div class="badge hrd-badge animate__animated animate__heartBeat" style="height:121px !important; background: none;">
            <HrdCloudBadge
                :is-active="true"
                :badge-ty-cd-dcd="badge.badgeTyCdDcd"
            />
          </div>
          <p class="text">축하해요! <br/>{{ badge.badgeTyNm }}<br/>배지를 획득했어요</p>
          <p class="text" style="font-size:12px">터치하여 닫기</p>
        </swiper-slide>
        <div class="badge-pagination swiper-pagination"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import {hrdBadgeSetup} from '@/assets/js/modules/hrd/hrd-badge-setup';
import HrdCloudBadge from '@/components/history/hrdcloud/HrdCloudBadge.vue';
// import HrdCloudBadgeTwo from '@/components/history/hrdcloud/HrdCloudBadgeTwo.vue';

export default {
  name: 'HrdMobileBadgeModal',
  components: {
    // HrdCloudBadgeTwo,
    // eslint-disable-next-line vue/no-unused-components
    HrdCloudBadge,
    Swiper,
    SwiperSlide
  },
  setup: hrdBadgeSetup
};
</script>