<template>
  <div>
    <lxp-main-header :show-back="true" />
    <div class="main-content main-component">
      <div class="search-container">
        <div class="search-field">
          <div class="kb-form-search">
            <div class="kb-form-search-field">
              <input type="text" class="kb-form-search-input" v-model.trim="localKeyword" placeholder="HRD 클라우드 검색" @keyup.enter="handleSearch">
              <button type="button" class="kb-form-search-btn" @click="handleSearch" ><i class="icon-search">검색</i></button>
              <button type="button" class="kb-form-search-delete" @click="localKeyword = ''"><i class="icon-delete">삭제</i></button>
            </div>
          </div>
        </div>
        <div class="search-result">
          <section class="content-section">
            <div class="hrdcloud-list-container">
              <HrdCloudList :is-loading="isLoading"
                            :session="session"
                            :result="hrds"
                            :show-put="true"
                            :paging="paging"
                            :paging-func="pagingFunc">
                <template v-slot:top>
                  <div class="list-top">
                    <div class="top-column">
                      <h3 class="title text-muted">콘텐츠 검색결과 {{ numberComma(paging.totalCount) }}건</h3>
                    </div>
                  </div>
                </template>
              </HrdCloudList>
            </div>
          </section>
          <section class="content-section section-divider">
            <h3 class="title section-title">카테고리 검색결과 {{ categoriesPaging && categoriesPaging.totalCount ? categoriesPaging.totalCount : 0 }}건</h3>
            <ul class="category-list" v-if="categories && categories.length > 0">
              <li class="category-item" v-for="(cate, idx) in categories" :key="idx">
                <a href="javascript:" class="category-link" @click="searchCate(idx)">{{ replaceSymbol(cate.hrdCloudCateNmLst) }}</a>
              </li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LxpMainHeader from '@/components/common/LxpMainHeader';
import {computed, ref} from 'vue';
import HrdCloudList from '@/components/hrdcloud/HrdCloudList';
import {useRoute} from 'vue-router';
import {pushQuery} from '@/assets/js/ui.init';
import {numberComma} from '@/assets/js/util';

export default {
  name: 'HrdCloudSearchResult',
  components: {HrdCloudList, LxpMainHeader},
  props: {
    keyword: [Number, String],
    session: Object,
    hrds: Array,
    categories: Array,
    paging: Object,
    pagingFunc: Function,
    categoriesPaging: Object,
    isLoading: Boolean
  },
  setup(props) {
    const route = useRoute();
    const keyword = computed(() => route.query.keyword);
    const localKeyword = ref(keyword.value);

    const handleSearch = () => {
      pushQuery({keyword: localKeyword.value});
    };

    const replaceSymbol = (str) => {
      return (str && str.length && str.indexOf(',') > -1 ? str.replaceAll(',', '>') : '');
    };

    const searchCate = (idx) => {
      let query = {};
      let cates = props.categories[idx].hrdCloudCateSnLst.split(',');
      for (let idx in cates) {
        query[`category${Number(idx)+1}`] = cates[idx];
      }
      pushQuery(query);
    };

    return {
      localKeyword,
      handleSearch, numberComma, replaceSymbol, searchCate
    }
  }
};
</script>