<template>
  <HrdCloudMobileHeader :show-back="true" :go-back-func="closeModal" />
  <main class="kb-main" id="kb-hrdcloud">
    <div class="header-snb">
      <nav class="snb">
        <ul class="snb-list">
          <li class="snb-item">
            <button :class="{ 'is-active' : mlgSaveCdDcd === mlgSaveCdDcds.MLG_SAVE_CD_HRDCLOUD  }" @click="handleServ(mlgSaveCdDcds.MLG_SAVE_CD_HRDCLOUD)">동영상 학습</button>
          </li>
          <li class="snb-item">
            <button :class="{ 'is-active' : mlgSaveCdDcd === mlgSaveCdDcds.MLG_SAVE_CD_CLOUDEASY  }" @click="handleServ(mlgSaveCdDcds.MLG_SAVE_CD_CLOUDEASY)">전산실습 Easy</button>
          </li>
          <li class="snb-item">
            <button :class="{ 'is-active' : mlgSaveCdDcd === mlgSaveCdDcds.MLG_SAVE_CD_KBTUBE }" @click="handleServ(mlgSaveCdDcds.MLG_SAVE_CD_KBTUBE)">Hot Tube 등재</button>
          </li>
        </ul>
      </nav>
    </div>
    <div class="main-content main-component">
      <article class="content-section">
        <header class="section-header">
            <div v-if="mlgSaveCdDcd === mlgSaveCdDcds.MLG_SAVE_CD_HRDCLOUD">
              <h4  class="title">동영상 학습</h4>
              <p class="description">{{ getBgngEndDate() }}<br>학습완료 후 마일리지가 정상 부여된 학습건을 조회 가능합니다.</p>
            </div>
            <div  v-else-if="mlgSaveCdDcd === mlgSaveCdDcds.MLG_SAVE_CD_CLOUDEASY ">
              <h4 class="title">전산실습 Easy</h4>
              <p class="description">{{ getBgngEndDate() }}<br>학습완료 후 마일리지가 정상 부여된 학습건을 조회 가능합니다.</p>
            </div>
            <div v-else-if="mlgSaveCdDcd === mlgSaveCdDcds.MLG_SAVE_CD_KBTUBE ">
              <h4  class="title">Hot Tube 등재</h4>
              <p class="description">{{ getBgngEndDate() }}<br>등재완료 후 마일리지가 부여된 건을 조회 가능합니다.</p>
            </div>
        </header>
        <div class="kb-table kb-table-striped kb-table-mileage kb-table-responsive">
          <table>
            <colgroup>
              <col style="width:156px">
              <col>
              <col>
              <col style="width:124px">
            </colgroup>
            <thead>
            <tr>
              <th><span class="th-title">마일리지 부여일시</span></th>
              <th><span class="th-title">카테고리</span></th>
              <th><span class="th-title">콘텐츠명</span></th>
              <th><span class="th-title">획득마일리지</span></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, idx) in items" :key="idx">
              <td><span class="td-text">{{ timestampToDateFormat(item.regDt, 'yyyy.MM.dd hh:mm') }}</span></td>
              <td><span class="td-text">{{ item.cloudNmLst }}</span></td>
              <td><span class="td-text">{{ item.objNm }}</span></td>
              <td><span class="td-text">{{ item.mlgValue }}</span></td>
            </tr>
            </tbody>
          </table>
          <div v-if="!items || (items && items.length === 0)" class="search-container">
            <div class="result-empty">
              <img src="../../../assets/lxp/images/common/img_empty.png" alt="">
              <p class="text">부여된 마일리지가 없습니다.</p>
            </div>
          </div>
        </div>
        <CommonPaginationFront :paging="paging" :page-func="pagingFunc" />
      </article>
    </div>
  </main>
</template>

<script>
import HrdCloudMobileHeader from '@/components/hrdcloud/mobile/HrdCloudMobileHeader';
import CommonPaginationFront from '@/components/CommonPaginationFront';
import {getBgngEndDate, timestampToDateFormat} from '@/assets/js/util';
import {mlgSaveCdDcds} from '@/assets/js/modules/hrd/hrd-common';
import {reactive, ref} from 'vue';
import {getMileageList} from '@/assets/js/modules/hrd/hrd-history-common';

export default {
  name: 'HrdCloudHistoryMobileMileage',
  components: {CommonPaginationFront, HrdCloudMobileHeader},
  props: {
    modelValue: Boolean,
    year: [String, Number]
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const closeModal = () => {
      emit('update:modelValue');
    };

    const mlgSaveCdDcd = ref(mlgSaveCdDcds.MLG_SAVE_CD_HRDCLOUD);

    const items = ref([]);
    const paging = reactive({
      pageNo: 1,
      pageSize: 10,
      totalCount: 0
    });

    const getMlgList = () => {
      getMileageList({ year: props.year, mlgSaveCdDcd: mlgSaveCdDcd.value, mlgTyCd: (mlgSaveCdDcd.value === mlgSaveCdDcds.MLG_SAVE_CD_KBTUBE ? '4001' : '1001') }, items, paging);
    };

    const pagingFunc = (pageNo) => {
      paging.pageNo = pageNo;
      getMlgList();
    };

    const handleServ = (servTy) => {
      paging.pageNo = 1;
      mlgSaveCdDcd.value = servTy;
      getMlgList();
    };

    getMlgList();

    return {
      items, paging, pagingFunc,
      closeModal, getBgngEndDate, mlgSaveCdDcd, mlgSaveCdDcds,
      timestampToDateFormat, handleServ
    }
  }
};
</script>