<template>
  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-prep-group">
    <!-- main-header -->
    <div class="main-header">
      <div class="header-top main-component">
        <div class="header-column">
          <router-link :to="{name: 'GroupLearning'}" class="page-nav">
            <i class="icon-kb-nav-arrow"></i>
            <span class="text">이전</span>
          </router-link>
        </div>
      </div>
      <!-- main-header > header-title -->
      <div class="header-title">
        <h2 class="title">모임등록</h2>
      </div>
    </div>
    <!-- //main-header -->
    <!-- main-content -->
    <div class="main-content min-component">
      <div>
        <section class="content-section section-forms">
          <!-- kb-form-fields -->
          <div class="kb-form-fields">
            <!-- kb-form-row:모임주제 -->
            <div class="kb-form-row">
              <div class="kb-form-column kb-form-column-title">
                <label class="kb-form-label">
                  <span class="kb-form-label-text">모임주제</span>
                  <span class="kb-form-label-text text-red"> *</span>
                </label>
              </div>
              <div class="kb-form-column column-whole">
                <input v-model="params.objNm" type="text" class="kb-form-control" placeholder="모임주제를 입력하세요." />
              </div>
            </div>
            <!-- kb-form-row:모임구분 -->
            <div class="kb-form-row">
              <div class="kb-form-column kb-form-column-title">
                <label class="kb-form-label">
                  <span class="kb-form-label-text">모임구분</span>
                  <span class="kb-form-label-text text-red"> *</span>
                </label>
              </div>
              <div class="kb-form-column">
                <div class="kb-form-dropdown" data-offset="bottom-left">
                  <button class="kb-form-dropdown-btn"><span class="text">온라인</span><i class="icon-arrow"></i></button>
                  <div class="kb-form-dropdown-options">
                    <div class="dropdown-option">
                      <ul class="dropdown-option-list">
                        <li class="dropdown-option-item"><a href="javascript:" class="dropdown-option-link"><span class="dropdown-option-text">온라인</span></a></li>
                        <li class="dropdown-option-item"><a href="javascript:" class="dropdown-option-link"><span class="dropdown-option-text">오프라인</span></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="kb-form-row">
              <div class="kb-form-column kb-form-column-title">
                <label class="kb-form-label">
                  <span class="kb-form-label-text">모임일자</span>
                  <span class="kb-form-label-text text-red"> *</span>
                </label>
              </div>
              <div class="kb-form-column">
                <GroupClassCalendar
                    v-model="classDate"
                    v-model:toggle="toggles.date"
                />
              </div>
              <div class="kb-form-column justify-content-end">
                <strong class="text text-muted tip">모임가능일자 월/화/목/금(수요일, 주말 및 공휴일 제외)</strong>
              </div>
            </div>
            <!-- kb-form-row:모임시작시간 -->
            <div class="kb-form-row">
              <div class="kb-form-column kb-form-column-title">
                <label class="kb-form-label">
                  <span class="kb-form-label-text">모임시작시간</span>
                  <span class="kb-form-label-text text-red"> *</span>
                </label>
              </div>
              <div class="kb-form-column">
                <SortButtonSelect
                    v-model="params.bgngHour"
                    v-model:toggle="hourToggle"
                    :options="classStartHour"
                    sequence-key="bgngHour"
                    name-key="bgngHourNm"
                    :is-row="false"
                    :is-all="false"
                />
              </div>
              <div class="kb-form-column">
                <SortButtonSelect
                    v-model="params.bgngMin"
                    v-model:toggle="minToggle"
                    :options="classStartMin"
                    sequence-key="bgngMin"
                    name-key="bgngMinNm"
                    :is-row="false"
                    :is-all="false"
                />
              </div>
              <div class="kb-form-column justify-content-end">
                <strong class="text text-muted tip">모임가능시간 18:00-22:00(22시 자동 종료)</strong>
              </div>
            </div>
            <!-- kb-form-row:학습시간 -->
            <div class="kb-form-row">
              <div class="kb-form-column kb-form-column-title">
                <label class="kb-form-label">
                  <span class="kb-form-label-text">학습시간</span>
                  <span class="kb-form-label-text text-red"> *</span>
                </label>
              </div>
              <div class="kb-form-column">
                <SortButtonSelect
                    v-model="params.lrnDt"
                    v-model:toggle="lrnDtToggle"
                    :options="lrnDt"
                    sequence-key="lrnDt"
                    name-key="lrnDtNm"
                    :is-row="false"
                    :is-all="false"
                />
              </div>
            </div>
            <!-- kb-form-row:리브똑똑/Wisenet내용 -->
            <div class="kb-form-row">
              <div class="kb-form-column kb-form-column-title">
                <label class="kb-form-label">
                  <span class="kb-form-label-text">리브똑똑/<br/>Wisenet내용</span>
                  <span class="kb-form-label-text text-red"> *</span>
                </label>
              </div>
              <div class="kb-form-column column-whole">
                <textarea v-model="params.notiMsg" name="" id="" cols="30" rows="4" maxlength="300" placeholder="그룹 멤버에게 발송할 모임 안내를 작성하세요." class="kb-form-control"></textarea>
                <div class="textarea-bottom divider">
                  <div class="bottom-column">
                    <span class="counter">{{ notiMsgLengthCnt }} / 300</span>
                  </div>
<!--                  <div class="bottom-column">-->
<!--                    <div class="kb-form-check">-->
<!--                      <label class="kb-form-check-label"><strong class="text">기본문구적용</strong></label>-->
<!--                      <input v-model="useNotiTextYn" type="checkbox" class="kb-form-check-input" />-->
<!--                    </div>-->
<!--                  </div>-->
                </div>
              </div>
            </div>
            <!-- kb-form-row:발신자 연락처 -->
            <div class="kb-form-row">
              <div class="kb-form-column kb-form-column-title">
                <label class="kb-form-label">
                  <span class="kb-form-label-text">발신자 연락처</span>
                </label>
              </div>
              <div class="kb-form-column column-whole">
                <input v-model="mblTelNo" type="text" class="kb-form-control" placeholder="연락처" />
              </div>
            </div>
            <!-- //kb-form-row -->
          </div>
          <!-- //kb-form-fields -->
        </section>
        <div class="page-buttons">
          <button class="kb-btn kb-btn-primary kb-btn-lg" @click="clickSave"><span class="text">{{ $route.params.lrnPostSn > 0 ? '수정' : '모임등록'}}</span></button>
          <button v-if="$route.params.lrnPostSn > 0" class="kb-btn kb-btn-light-gray kb-btn-lg" @click="clickDelete"><span class="text">삭제</span></button>
        </div>
      </div>
    </div>
    <!-- //main-content -->
  </main>
  <!-- end::kb-main -->
</template>

<script>
import GroupClassCalendar from "@/components/prep/group/GroupClassCalendar";
import SortButtonSelect from "@/components/common/SortButtonSelect";
import {groupClassEditSetup} from "@/assets/js/modules/groupLearing/group-class-edit-setup";

export default {
  name: "GroupClassEdit",
  components: {SortButtonSelect, GroupClassCalendar},
  setup: groupClassEditSetup
}
</script>