<template>
  <!-- [begin::layer-container] -->
  <div class="popup-container" :class="{'is-active' : isClose}">
    <div class="popup" id="popup-notice-alert">
      <div class="banner-container">
        <div class="banner-wrapper">
          <div class="banner-item">
            <a href="javascript:" class="banner-link">
              <img src="../../../assets/lxp/mobile/images/@tmp/@tmp_alt.png" alt="" />
            </a>
          </div>
        </div>
        <button class="banner-close" type="button" @click="clickTodayClose"><span class="text">오늘 하루 보지않기</span></button>
      </div>
      <div class="popup-close">
        <button @click="closeModal"><i class="icon-popup-close"></i></button>
      </div>
    </div>
  </div>
  <!-- [end::layer-container] -->
</template>

<script>
import {ref, watch} from "vue";
import {useRoute} from "vue-router";
import navigationUtils from "@/assets/js/navigationUtils";

export default {
  name: "MobileDailyPopup",
  props: {
    modelValue: Boolean,
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {

    const isClose = ref(true);

    const closeModal = () => {
      isClose.value = false;
      emit('update:modelValue', false);
    }

    let date = new Date();
    const clickTodayClose = () => {
      // date = date.setHours(23,59,59);
      date = date.setHours(23,59,59);
      // 만료 시간 구하기(exp를 ms단위로 변경)
      localStorage.setItem('kb-popup-daliy', date);
      closeModal();
    }

    const route = useRoute();

    watch(() => route.path, () => {
      isClose.value = true;
    });

    return {
      isMobile: navigationUtils.any(),
      isClose,
      closeModal,
      clickTodayClose
    }

  }
}
</script>