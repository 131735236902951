<template>
  <div v-if="notices.length > 0" class="intro-notice">
    <router-link :to="{name: 'SSLNoticeView', params: {socLrnNtcSn: currentNotice.comNtcSn}}" class="notice-item">
      <span class="date">{{timestampToDateFormat(currentNotice.regDt, 'yyyy.MM.dd')}}</span>
      <span class="title text-truncate">{{  currentNotice.ntcTitle  }}</span>
    </router-link>
    <p class="notice-list-link">
      <router-link :to="{name: 'SSLCrseNotice'}">공지목록</router-link>
    </p>
  </div>
</template>
<script>

import {timestampToDateFormat} from '@/assets/js/util';
import {computed, ref, watch} from 'vue';

export default {
  name: 'SSLMobileMainNotice',
  components: {},
  props: {
    notices: Array
  },
  setup(props) {

    const currentIdx = ref(0);
    const currentNotice = computed(() => {
      if(props.notices[currentIdx.value]){
        return props.notices[currentIdx.value];
      }
      return null;
    });

    const slideButton = (isNext) => {
      if(isNext){
        const nextIdx = currentIdx.value + 1;
        if(nextIdx > (props.notices.length - 1)){
          currentIdx.value = 0;
        }else{
          currentIdx.value = nextIdx;
        }
      }else{
        const prevIdx = currentIdx.value -1;
        if(prevIdx < 0){
          currentIdx.value = (props.notices.length - 1);
        }else{
          currentIdx.value = prevIdx;
        }
      }
    }

    watch(() => props.notices, () => {
      currentIdx.value = 0;
    });

    return {
      currentNotice,
      slideButton,
      timestampToDateFormat
    }
  }
}
</script>
