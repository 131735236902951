<template>
  <div class="top-tabs">
    <router-link
        v-for="(item, idx) in items"
        :to="item.to" :key="idx"
        class="tab" :class="{'is-active': idx === activeIdx}"
    >
      <span class="tab-text">{{ item.name }}</span>
    </router-link>
  </div>

</template>

<script>

import {computed, ref} from "vue";
import {useRoute} from "vue-router";

export default {
  name: 'SSLLearnerRoute',
  setup(){
    const route = useRoute();

    const activeIdx = computed(() => {
      if(route.name === 'SSLLearner'){
        return 0
      }else if(route.name === 'SSLLearnerBoard'){
        return 1;
      }
      return 0;
    });


    const items = ref([
      {to: {name: 'SSLLearner'}, name: '학습현황'},
      {to: {name: 'SSLLearnerBoard'}, name: '등록완료'},
    ]);

    return {
      items,
      activeIdx
    }
  }
}
</script>
