<template>
  <div class="kb-form-period-date dropdown kb-form-dropdown" :class="[toggle && 'is-active']" data-id="dropdown-calendar-picker">
    <button class="kb-form-dropdown-btn" @click.stop="toggleOptions">
      <strong class="text">{{ renderText }}</strong><i class="icon-arrow"></i>
    </button>
    <div class="dropdown-target" style="width: 400px">
      <div class="dropdown-box"  @click.stop="() => {}">
        <!-- calendar-picker-container -->
        <div class="calendar-picker-container">
          <!-- calendar-picker-container > calendar-picker -->
          <div class="picker-body" style="">
            <div class="datepicker-header">
              <button class="kb-btn-datepicker-nav kb-btn-datepicker-prev" @click.stop="changeMonth(false)"><i class="icon-prev"></i></button>
              <div class="title">{{ viewDate.year }}년 {{ viewDate.month }}월</div>
              <button class="kb-btn-datepicker-nav kb-btn-datepicker-next" @click.stop="changeMonth(true)"><i class="icon-next"></i></button>
            </div>
            <div class="datepicker-table">
              <table>
                <thead>
                <tr>
                  <th>일</th>
                  <th>월</th>
                  <th>화</th>
                  <th>수</th>
                  <th>목</th>
                  <th>금</th>
                  <th>토</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(week, idx) in currentWeeks" :key="idx">
                  <template v-for="(day, i) in week" :key="`${idx}-${i}`">
                    <td v-if="day.year === viewDate.year && day.month === viewDate.month">
                      <template v-if="isHolyDay(day)">
                        <button class="kb-btn-day" disabled>
                          <span class="text">{{ day.day }}</span>
                        </button>
                      </template>
                      <template v-else-if="i === 0 || i === 3 || i === 6">
                        <button class="kb-btn-day" disabled>
                          <span class="text">{{ day.day }}</span>
                        </button>
                      </template>
                      <template v-else>
                        <button class="kb-btn-day" :class="[isSelected(day) && 'is-active']" @click.stop="selectDate(day)">
                          <span class="text">{{ day.day }}</span>
                        </button>
                      </template>
                    </td>
                    <td v-else>&nbsp;</td>
                  </template>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- //calendar-picker-container -->
      </div>
    </div>
  </div>
</template>

<script>
import {computed, reactive} from 'vue';
import {getCalendar} from '@/assets/js/util';
import {useAlert} from '@/assets/js/modules/common/alert';
import {holyDay} from "@/assets/js/holy-day";
import {isSSLPeriod} from '@/assets/js/modules/ssl/ssl-write';

export default {
  name: 'GroupClassCalendar',
  props: {
    toggle: Boolean,
    modelValue: Object,
  },
  emits: ['update:modelValue', 'update:toggle'],
  setup(props, {emit}) {

    const {showMessage} = useAlert();
    const currentDate = new Date();

    const today = reactive({
      year: currentDate.getFullYear(),
      month: (currentDate.getMonth() + 1),
      day: currentDate.getDate(),
      ymd: ''
    });

    const getYmd = ({year, month, day}) => {
      return `${year}${('0' + month).slice(-2)}-${('0' + day).slice(-2)}`
    }

    today.ymd = getYmd(today);

    const viewDate = reactive({
      year: 0,
      month: 0,
    });

    const selectDate = (selected, isView) => {
      if(isView){
        viewDate.year = selected.year;
        viewDate.month = selected.month;
        // viewDate.day = selected.day;
      }else{
        if(getYmd(selected) < today.ymd){
          showMessage('모임일자는 오늘일자 이후로 선택해주세요.');
        }else{
          emit('update:modelValue', selected);
          emit('update:toggle', false);
        }


        // emit 처리 필요
      }
    }

    if(props.modelValue && props.modelValue.year > 0 && props.modelValue.month > 0){
      selectDate(props.modelValue, true);
    }else{
      selectDate(today, true);
    }

    const currentWeeks = computed(() => {
      if(viewDate.year > 0 && viewDate.month > 0){
        return getCalendar(viewDate.year, viewDate.month);
      }
      return [];
    });

    const renderText = computed(() => {
      if(props.modelValue){
        return `${props.modelValue.year}-${('0' + props.modelValue.month).slice(-2)}-${('0' + props.modelValue.day).slice(-2)}`;
      }
      return '-';
    });

    const isHolyDay = ({year, month, day}) => {
      if(!isSSLPeriod(new Date(year,(month-1),day),'group')) return true;
      return !!holyDay[`${year}${('0' + month).slice(-2)}${('0' + day).slice(-2)}`];
    }

    const getHolyDay = ({year, month, day}) => {
      return holyDay[`${year}${('0' + month).slice(-2)}${('0' + day).slice(-2)}`];
    }


    const changeMonth = (isNext) => {
      if (isNext) {
        if (viewDate.month === 12) {
          viewDate.year++;
          viewDate.month = 1;
        } else {
          viewDate.month++;
        }
      } else {
        if (viewDate.month === 1) {
          viewDate.year--;
          viewDate.month = 12;
        } else {
          viewDate.month--;
        }
      }
    };


    const isSelected = (day) => {
      if(props.modelValue){
        return props.modelValue.year === day.year && props.modelValue.month === day.month && props.modelValue.day === day.day;
      }
      return false;
    }

    const toggleOptions = () => {
      emit('update:toggle', !props.toggle);
    }

    return {
      today,
      viewDate,
      currentWeeks,
      renderText,
      isHolyDay,
      getHolyDay,
      isSelected,
      toggleOptions,
      selectDate,
      changeMonth
    }
  }
};
</script>

