<template>
  <main class="kb-main" id="kb-hrdcloud" @click="closeToggles(toggles)">

    <lxp-main-header :show-back="true" :show-custom-title="true" :title="type.title"/>

    <div class="main-content main-component">
      <div class="hrdcloud-list-container">

        <div class="list-top">
          <div class="top-column">
            <!-- 추천 영상의 데이터가 적어서 오더링을 넣으면 비슷해보이는 이슈가 있음. -->
<!--            <div v-if="type && type.name && type.name.length > 0 && type.name !== 'recommend'" class="list-sort">-->
<!--              <div class="sort">-->
<!--                <SortButtonSelect-->
<!--                  v-model="search.sortTyCdDcd"-->
<!--                  v-model:toggle="toggles.sortTyCdDcd"-->
<!--                  :options="sortTyCdDcd"-->
<!--                  sequence-key="cd"-->
<!--                  name-key="cdNm"-->
<!--                  :width="138"-->
<!--                  @selected="pagingFunc()"-->
<!--                />-->
<!--              </div>-->
<!--            </div>-->
          </div>
          <div class="top-column">
            <div class="kb-form-search">
              <div class="kb-form-search-field">
                <input type="text" class="kb-form-search-input" v-model="search.keyword" @keyup.enter="pagingFunc(0)" placeholder="HRD 클라우드 검색" />
                <button class="kb-form-search-btn" @click="pagingFunc(0)"><i class="icon-search">검색</i></button>
              </div>
            </div>
          </div>
        </div>

        <div class="hrdcloud-list-wrapper">
          <template v-if="items && items.length > 0">
            <ul class="hrdcloud-list" data-grid="4">
              <li v-for="(item, idx) in items" class="hrdcloud-item" :key="idx">
                <HrdCloudContent
                    :item="item"
                    :idx="idx"
                    :session="session"
                    :show-put="true"
                />
              </li>
            </ul>
            <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>
          </template>
          <div v-else-if="(items.length === 0) && !isLoading" class="search-container">
            <div class="result-empty">
              <img src="../../../assets/lxp/images/common/img_empty.png" alt="">
              <p class="text">준비중입니다.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
+
  </main>
</template>

<script>
import {useStore} from 'vuex';
import {computed, onMounted, reactive, ref, watch} from 'vue';
import {useRoute} from 'vue-router';
import {getVideoUrl, timestampToDateFormat} from '@/assets/js/util';
import LxpMainHeader from '@/components/common/LxpMainHeader';
import CommonPaginationFront from '@/components/CommonPaginationFront';
import {
  ACT_GET_HRDCLOUD_LATEST_LIST,
  ACT_GET_HRDCLOUD_RECENT_LIST,
  ACT_GET_HRDCLOUD_RECENT_POPULAR_LIST,
  ACT_GET_HRDCLOUD_RECOMMEND_LIST,
} from '@/store/modules/prep/prep';
import {
  closeToggles,
  getDcdOptions,
  getListFunc,
  getValueByQuery,
  pushQuery,
  setFilterByQuery,
} from '@/assets/js/ui.init';

import {useAlert} from '@/assets/js/modules/common/alert';
import {disableMyAddChanged, mainCustomList, sortTyCdDcds} from '@/assets/js/modules/hrd/hrd-common';
import HrdCloudContent from '@/components/hrdcloud/HrdCloudContent';

export default {
  name: 'HrdCloud',
  components: {HrdCloudContent,  LxpMainHeader, CommonPaginationFront},
  setup(){
    const store = useStore();
    const route = useRoute();
    const {showLoading, hideLoading} = useAlert();
    const isLoading = ref(false);
    const session = computed(() => store.state.auth.session);
    const sortTyCdDcd = computed(() => getDcdOptions('2073'));

    const type = computed(() => {
      if (route.params && route.params.view) {
        let obj = mainCustomList.filter(x => x.name === route.params.view)[0]
        return {
          ...obj
        }
      }
      return {
        title: ''
      };
    });

    const toggles = reactive({
      sortTyCdDcd: false,
      lvl1: false
    });
    const items = ref([]);
    const paging = reactive({
      pageNo: 1,
      pageSize: 12,
      totalCount: 0
    });

    const search = reactive({
      keyword:'',
      sortTyCdDcd: '',
      category1: 0,
      category2: 0,
      category3: 0,
      category4: 0,
      category5: 0
    });

    const videoTimeout = ref(null);
    const isActiveIdx = ref(-1);

    const handleVideoActive = (idx) => {
      if (idx === -2) {
        isActiveIdx.value = idx;
      }
      if (videoTimeout.value) {
        clearTimeout(videoTimeout.value);
        videoTimeout.value = null;
      }
      videoTimeout.value = setTimeout(() => {
        isActiveIdx.value = idx;
      },1000);
    };

    const getHrdCloudList = () => {
      if (isLoading.value) return;
      isLoading.value = true;

      let url = null;
      if (type.value.name === 'recommend') url = `prep/${ACT_GET_HRDCLOUD_RECOMMEND_LIST}`;
      else if (type.value.name === 'latest') url = `prep/${ACT_GET_HRDCLOUD_LATEST_LIST}`;
      else if (type.value.name === 'recent') url = `prep/${ACT_GET_HRDCLOUD_RECENT_LIST}`;
      else if (type.value.name === 'latestPopularDuty' || type.value.name === 'latestPopularKnowledge') url = `prep/${ACT_GET_HRDCLOUD_RECENT_POPULAR_LIST}`;

      if (url) {

        getListFunc(url, {...search, ...paging, ...type.value.params}, items, paging, () => {
          isLoading.value = false;
          hideLoading();
        }, () => {
          isLoading.value = false;
          hideLoading();
        });
      }
    };

    const pagingFunc = (pageNo) => {
      paging.pageNo = pageNo > 0 ? pageNo : 0;
      if(pageNo){
        pushQuery({...search, pageNo});
      }else{
        pushQuery({...search});
      }
    };

    const handleFilterQuery = () => {
      if (route.name === 'HrdCloudList') {
        showLoading();
        setFilterByQuery(search, route.query);
        if (search.sortTyCdDcd == null || search.sortTyCdDcd === '') search.sortTyCdDcd = sortTyCdDcds.SORT_TY_CD_LATEST;
        paging.pageNo = getValueByQuery(route.query, 'pageNo', true, 1);
        getHrdCloudList();
      }
    };

    const leaderAdd = computed(() => store.state.hrdcloud.leaderAdd);

    watch(() => leaderAdd.value.show, () => {
      if (!leaderAdd.value.show && leaderAdd.value.updateMyAdd) {
        const lrnObjDtlDistSn = leaderAdd.value.lrnObjDtlDistSn;
        const item = items.value && items.value.length > 0 ? items.value.filter(x => x.lrnObjDtlDistSn === lrnObjDtlDistSn)[0] : null;
        if (item) {
          item.put = leaderAdd.value.isPut;
          disableMyAddChanged();
        }
      }
    });

    watch(() => route.query, () => {
      handleFilterQuery();
    });

    // onBeforeMount(() => {
    //   setParamsByQueryString(route, 'HrdCloud', paging, search, true);
    //   // 초기화 되니까 여기서 한번 더 init?
    //   search.sortTyCdDcd = '2073001';
    // });

    onMounted(() => {
      handleFilterQuery();
    });

    return {
      type,
      session,
      isLoading,
      items,
      paging,
      search,
      isActiveIdx,
      handleVideoActive,
      pagingFunc,
      timestampToDateFormat,
      getVideoUrl,
      sortTyCdDcd,
      toggles,
      closeToggles,
    }

  }
};
</script>