<template>
  <div class="main-content min-component">
    <div class="page-buttons">
      <a class="kb-btn kb-btn-primary" @click="openAppPage"><span class="text">등록/지원신청</span></a>
    </div>
    <!-- section: 지원대상 -->
    <article class="content-section">
      <header class="section-header">
        <div class="header-column">
          <h4 class="title">지원대상</h4>
        </div>
      </header>
      <div class="guide-table">
        <div class="kb-table kb-table-striped">
          <table>
            <tbody>
              <tr>
                <td><span class="td-text">지급일 현재 재직중인 직원 (일반/계약)<br>※ 지급일 현재 정직자 및 파트타이머 제외</span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </article>
    <!-- section: 신청기한 -->
    <article class="content-section section-divider">
      <header class="section-header">
        <div class="header-column">
          <h4 class="title">신청기한</h4>
        </div>
      </header>
      <div class="guide-table">
        <div class="kb-table kb-table-striped">
          <table>
            <tbody>
            <tr>
              <td>
                <span class="td-text"><span class="fw-bolder">자격증 취득일</span>로부터 <span class="text-red fw-bolder">2년 이내</span></span><br>
                <span class="td-text">※ 입행 전 취득한 자격증은 신청기한과 관계없이 지원 불가</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </article>
    <!-- section: 지원절차 및 시기 -->
    <article class="content-section section-divider">
      <header class="section-header">
        <h4 class="title">지원절차 및 시기</h4>
      </header>
      <div class="guide-table">
        <div class="kb-table kb-table-striped">
          <table>
            <tbody>
            <tr>
              <td class="d-flex justify-content-evenly">
                <div style="float:left;border:1px solid rgba(252, 234, 194, 1); background-color: rgba(252, 234, 192, 1); border-radius:50%;width:150px;height:150px">
                  <div class="font-body16B mt-lg-4 text-center" style="color:rgba(148, 119, 31, 1)">Step.1<sup class="text-red">주1)</sup></div>
                  <div class="mt-lg-4 text-center">자격증 취득 후<br>필요서류 준비</div>
                </div>
                <div style="float:left;width:100px;height:150px;padding-left: 35px;padding-top: 65px">
                  <i class="icon-arrow-next" style="background-size: 100px 40px;"></i>
                </div>
                <div style="float:left;border:1px solid rgba(252, 234, 194, 1); background-color: rgba(252, 234, 192, 1); border-radius:50%;width:150px;height:150px">
                  <div class="font-body16B mt-lg-4 text-center" style="color:rgba(148, 119, 31, 1)">Step.2</div>
                  <div class="mt-lg-4 text-center">스타런에서<br>신청</div>
                </div>
                <div style="float:left;width:100px;height:150px;padding-left: 35px;padding-top: 65px">
                  <i class="icon-arrow-next" style="background-size: 100px 40px;"></i>
                </div>
                <div style="float:left;border:1px solid rgba(252, 234, 194, 1); background-color: rgba(252, 234, 192, 1); border-radius:50%;width:150px;height:150px">
                  <div class="font-body16B mt-lg-4 text-center" style="color:rgba(148, 119, 31, 1)">Step.3</div>
                  <div class="mt-lg-4 text-center">HR부(인재개발)<br>심사 및 등록<sup class="text-red">주2)</sup></div>
                </div>
                <div style="float:left;width:100px;height:150px;padding-left: 35px;padding-top: 65px">
                  <i class="icon-arrow-next" style="background-size: 100px 40px;"></i>
                </div>
                <div style="float:left;border:1px solid rgba(252, 234, 194, 1); background-color: rgba(252, 234, 192, 1); border-radius:50%;width:150px;height:150px">
                  <div class="font-body16B mt-lg-4 text-center" style="color:rgba(148, 119, 31, 1)">Step.4</div>
                  <div class="mt-lg-4 text-center">급여계좌로 지급<br><span class="font-body12">(전산 신청일 기준<br><span class="fw-bolder">익월 15일</span>)</span></div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="kb-table-bottom">
          <dl class="bottom-dl">
            <dd class="dl-dd subtext">주1) <span class="fw-bolder">비용지원 대상이 아닌</span><span class="text-red"> 단순히 자격증을 등록하는 경우에도 취득 지원절차와 동일한 방법으로 신청</span><br>&emsp;&emsp;예시 : 취득일로부터 2년이 경과한 자격증 등록, 입행 전 취득한 자격증 등록</dd>
            <dd class="dl-dd subtext">주2) 담당자 승인 즉시 취득신고한 자격증이 연수시스템 내에 등록되며(비용 지급 전), 익영업일 인사시스템(HR) 내에 등록됨</dd>
         </dl>
        </div>
      </div>
    </article>
    <!-- section: 필요서류 -->
    <article class="content-section section-divider">
      <header class="section-header">
        <h4 class="title">필요서류</h4>
      </header>
      <div class="guide-table">
        <div class="kb-table kb-table-striped">
          <table>
            <colgroup>
              <col style="width:212px" />
              <col />
            </colgroup>
            <tbody>
            <tr>
              <th class="text-center"><span class="th-title">구분</span></th>
              <th class="text-center"><span class="th-title">세부내용</span></th>
            </tr>
            <tr>
              <td class="text-center"><strong class="td-text">기본 증빙서류</strong></td>
              <td><ul><li class="circle"><span class="td-text">자격증(합격증) : 주관처에서 발급하는 자격증/합격증(사본, 인터넷 발급분 포함)</span></li></ul></td>
            </tr>
            <tr>
              <td class="text-center"><strong class="td-text">추가 증빙서류<br>(응시료, 교재비 등)</strong></td>
              <td>
                <ul>
                  <li class="circle"><span class="td-text fw-bolder">본인 명의의 KB신용·체크카드·직원복지카드로 결제한 매출전표만 인정</span><br><span class="td-text">※ 선불카드 및 <span class="text-red">타사 카드결제에 의한 증빙은 불가</span></span></li><br>
                  <li class="circle"><span class="td-text">학원비 - 학원 수강증 <span class="fw-bolder">※ 환급 받는 과정, 사은품이 제공 되는 과정은 제외</span><br>교재비 – <span class="fw-bolder">해당 자격증과 직접 연관된 도서명 확인 필요</span> (ex. 주문내역서 등)<br>※ 등급외 자격증의 경우 학원비 지원이 되지 않으므로 동영상 강의에 포함된 교재비 지원 요청 시 교재비에 대한 금액 증빙을 별도로 제출</span></li>
                </ul>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </article>
    <!-- section: 유의사항 -->
    <article class="content-section section-divider">
      <header class="section-header">
        <h4 class="title">유의사항</h4>
      </header>
      <div class="guide-table">
        <div class="kb-table kb-table-striped">
          <table>
            <tbody>
            <tr>
              <td>
                <span class="td-text">- 자격증 취득 지원금은 투명하고 정당한 절차에 의한 방법으로 지원받으시기 바랍니다.</span><br>
                <span class="td-text">- 지급된 지원금은 기소득 처리 대상으로 HR부(인재개발)에서 일괄 과표 처리합니다.</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </article>
    <!-- section: 등급 외 자격증(펀드,파생,보험,영업점컴플라이언스 오피서) -->
    <article class="content-section section-divider">
      <header class="section-header">
        <h4 class="title">등급 외 자격증(펀드,파생,보험,영업점컴플라이언스 오피서)</h4>
      </header>
      <div class="guide-table">
        <div class="kb-table kb-table-striped">
          <table>
            <colgroup>
              <col style="width:212px" />
              <col />
            </colgroup>
            <tbody>
            <tr>
              <th><strong class="th-title">지원내용</strong></th>
              <td><span class="td-text fw-bolder">항목별 지원한도 이내에서 실비 지원</span>&nbsp;&nbsp;&nbsp;※항목별 지원한도는 상단 안내문 참조</td>
            </tr>
            <tr>
              <th><strong class="th-title">지원항목</strong></th>
              <td><span class="td-text">투자자보호교육비, 응시료, 교재비 </span><span class="text-red">(학원비/동영상 강의비는 지원하지 않음)</span></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </article>
    <!-- section: 등급 자격증 -->
    <article class="content-section section-divider">
      <header class="section-header">
        <h4 class="title">등급 자격증</h4>
      </header>
      <div class="guide-table">
        <div class="kb-table kb-table-striped">
          <table>
            <colgroup>
              <col style="width:212px" />
              <col />
            </colgroup>
            <tbody>
            <tr>
              <th><strong class="th-title">지원내용</strong></th>
              <td><span class="td-text fw-bolder">등급별 지원한도 이내에서 <span class="text-red">소요된 비용의 80% 금액 지원</span></span><br><span class="td-text">최소 지원금액: 등급별 지원한도의 50%, 최대 지원금액: 지원한도 금액</span></td>
            </tr>
            <tr>
              <th><strong class="th-title">지원항목</strong></th>
              <td><span class="td-text">학원비, 응시료, 교재비(연회비, 연체료 등은 제외)</span></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </article>
    <!-- section: 등급 자격증 현황표 -->
    <article class="content-section">
      <header class="section-header">
          <h4 class="title">등급 자격증 현황표</h4><span class="subtitle">총 81개</span>
      </header>
      <div class="guide-table">
        <div class="kb-table kb-table-bordered-v2">
          <table>
            <colgroup>
              <col style="width:12%" />
              <col style="width:12%" />
              <col style="width:12%" />
              <col />
            </colgroup>
            <thead>
            <tr>
              <th><span class="th-title">등급</span></th>
              <th><span class="th-title">마일리지</span></th>
              <th><span class="th-title">지원한도<br>(천원)</span></th>
              <th><span class="th-title">자격증명</span></th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td><span class="td-text">A</span></td>
              <td><span class="td-text">150</span></td>
              <td><span class="td-text">3,000</span></td>
              <td class="text-start"><span class="td-text">공인회계사 ・ 국제공인재무분석사(CFA) ・ 기술사 ・ 변호사</span></td>
            </tr>
            <tr>
              <td rowspan="2"><span class="td-text">B</span></td>
              <td rowspan="2"><span class="td-text">100</span></td>
              <td><span class="td-text">2,000</span></td>
              <td class="text-start"><span class="td-text">감정평가사 ・ 건축사 ・ 공인국제제재전문가(CGSS) ・ 공인노무사 ・ 공인대체투자분석사(CAIA) ・ 관세사 ・ 국제공인내부감사사(CIA) ・ 국제공인재무설계사(CFP) ・ 美공인회계사(AICPA) ・ 美변호사(LL.M) ・ 법무사 ・ 보험계리사 ・ 세무사 ・ 자금세탁방지전문가(CAMS), 변리사</span></td>
            </tr>
            <tr>
              <td><span class="td-text">1,500</span></td>
              <td class="text-start"><span class="td-text">신용위험분석사(CRA) ・ 프라이빗뱅커(CPB)</span></td>
            </tr>
            <tr>
              <td rowspan="2"><span class="td-text">C</span></td>
              <td rowspan="2"><span class="td-text">50</span></td>
              <td><span class="td-text">1,500</span></td>
              <td class="text-start"><span class="td-text">FDP(Financial Data Professional) ・ 국제거래용은행보증서실무전문가(CSDG) ・ 국제공인무역금융컴플라이언스(CTFC) ・ 국제공인신용장전문가(CDCS) ・ 美공인관리회계사(CMA) ・ 美재무위험관리사(FRM/G.A.R.P) ・ 정보시스템감리사 ・ 정보시스템감사사(CISA)</span></td>
            </tr>
            <tr>
              <td><span class="td-text">700</span></td>
              <td class="text-start"><span class="td-text">국제금융역 ・ 금융투자분석사 ・ 기술신용평가사 1급 ・ 여신심사역 ・ 자산관리사(FP,한국금융연수원) ・ 재경관리사 ・ 재무위험관리사(FRM,금융투자협회) ・ 정보보안기사 ・ 투자자산운용사 ・ 한국재무설계사(AFPK), ISMS-P인증심사원</span></td>
            </tr>
            <tr>
              <td rowspan="2"><span class="td-text">D</span></td>
              <td rowspan="2"><span class="td-text">40</span></td>
              <td><span class="td-text">1,000</span></td>
              <td class="text-start"><span class="td-text">경영지도사 ・ 손해사정사 ・ 외환관리사 ・ 정보시스템보안전문가(CISSP)</span></td>
            </tr>
            <tr>
              <td><span class="td-text">500</span></td>
              <td class="text-start"><span class="td-text">AWS Solution Architect-Professional ・ SQL전문가(SQLP) ・ 공인기능점수전문가(CFPS) ・ 공인중개사 ・ 국제투자분석사(K-CIIA) ・ 금융채권관리사 ・ 기술신용평가사2급 ・ 데이터분석전문가(ADP) ・ 데이터아키텍처전문가(DAP) ・ 빅데이터분석기사 ・ 신용분석사 ・ 애자일전문가(ACP) ・ 정보처리기사 ・ 프로젝트관리전문가(PMP,P.M.I) ・ 행정사</span></td>
            </tr>
            <tr>
              <td><span class="td-text">E</span></td>
              <td><span class="td-text">30</span></td>
              <td><span class="td-text">300</span></td>
              <td class="text-start"><span class="td-text">AWS Solution Architect-Associate ・ CCNA ・ LPIC-1 ・ OCPJP ・ SQL개발자(SQLD) ・ Tableau Desktop Specialist ・ 개인정보관리사(CPPG) ・ 국제무역사1급 ・ 기술신용평가사3급 ・ 기술지도사 ・ 네트워크관리사2급 ・ 데이터분석준전문가(ADsP) ・ 데이터아키텍처준전문가(DAsP) ・ 마이데이터관리사 ・ 리눅스마스터1급 ・ 신용관리사 ・ 신용상담사 ・ 외환전문역1종 ・ 외환전문역2종 ・ 전자상거래관리사1급 ・ 증권투자권유자문인력 ・ 텔레마케팅관리사 ・ 한국자금세탁방지전문가(KCAMS), 사회조사분석사 2급</span></td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="kb-table-bottom">
          <dl class="bottom-dl">
            <dd class="dl-dd subtext">※ <span class="fw-bolder text-red">신청일 당시</span> 등급기준에 따라 마일리지 및 비용 지급</dd>
          </dl>
        </div>
      </div>
    </article>
    <!-- section: 등급 자격증 지원 예시 (E 등급 자격증 기준) -->
    <article class="content-section">
      <header class="section-header">
        <h4 class="title">등급 자격증 지원 예시 (E등급 자격증 기준)</h4>
      </header>
      <div class="guide-table">
        <div class="kb-table kb-table-bordered-v2">
          <table>
            <colgroup>
              <col style="width:12%" />
              <col style="width:12%" />
              <col style="width:12%" />
              <col style="width:12%" />
              <col style="width:12%" />
              <col style="width:12%" />
              <col />
            </colgroup>
            <thead>
            <tr>
              <th><span class="th-title">유형</span></th>
              <th><span class="th-title">학원비</span></th>
              <th><span class="th-title">응시료</span></th>
              <th><span class="th-title">교재비</span></th>
              <th><span class="th-title">소요금액</span></th>
              <th><span class="th-title">지원금액</span></th>
              <th><span class="th-title">비고</span></th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td><span class="td-text">A</span></td>
              <td><span class="td-text">0</span></td>
              <td><span class="td-text">50,000</span></td>
              <td><span class="td-text">23,000</span></td>
              <td><span class="td-text">73,000</span></td>
              <td><span class="td-text">150,000</span></td>
              <td><span class="td-text">최소지원금액 (지원한도의 50%)</span></td>
            </tr>
            <tr>
              <td><span class="td-text">B</span></td>
              <td><span class="td-text">100,000</span></td>
              <td><span class="td-text">50,000</span></td>
              <td><span class="td-text">23,000</span></td>
              <td><span class="td-text">173,000</span></td>
              <td><span class="td-text">150,000</span></td>
              <td><span class="td-text">최소지원금액 (지원한도의 50%)</span></td>
            </tr>
            <tr>
              <td><span class="td-text">C</span></td>
              <td><span class="td-text">200,000</span></td>
              <td><span class="td-text">50,000</span></td>
              <td><span class="td-text">45,000</span></td>
              <td><span class="td-text">295,000</span></td>
              <td><span class="td-text">236,000</span></td>
              <td><span class="td-text">소요비용의 80%</span></td>
            </tr>
            <tr>
              <td><span class="td-text">D</span></td>
              <td><span class="td-text">300,000</span></td>
              <td><span class="td-text">50,000</span></td>
              <td><span class="td-text">60,000</span></td>
              <td><span class="td-text">410,000</span></td>
              <td><span class="td-text">300,000</span></td>
              <td><span class="td-text">최대지원금액 (지원한도의 100%)</span></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </article>
    <!-- section: 기타 -->
    <article class="content-section section-divider">
      <header class="section-header">
        <h4 class="title">기타</h4>
      </header>
      <ol class="lh-lg">
        <li>
          <span>1. 아래 자격증은 동일 자격증으로 간주하여 복수 취득 시에도 자격증 취득지원금 및 마일리지 1회만 지원</span>
          <div class="mx-3">
            <span>- FP 계열</span>
            <ul class="mx-2">
              <li class="circle">프라이빗뱅커 (CPB, 한국금융연수원 ) = 국제공인재무설계사 (CFP, 한국 FPSB)</li>
              <li class="circle">자산관리사 (FP, 한국금융연수원 ) = 한국재무설계사 (AFPK, 한국 FPSB)</li>
            </ul>
            <span>- FRM 계열 : 美재무위험관리사 (FRM, G.A.R.P) = 재무위험관리사 (FRM, 금융투자협회)</span>
          </div>
        </li>
        <li>2. <span class="text-red">FP/IT 분야 학원비를 지원 받은 후 자격증을 취득한 경우 자격증 취득 지원 시 응시료만 80% 지원</span></li>
        <li>
          <span class="kb-table">3. 은행에서 지원한 유관과정에 참가한 후 자격증을 취득한 직원은 자격증 취득 비용 지원 제외<sup class="text-red">주)</sup></span>
          <div class="mx-3">
            <ul><li class="circle">단 , 아래의 경우 응시료의 80% 지원</li></ul>
            <div class="kb-table kb-table-bordered-v2">
              <table>
                <thead>
                <tr>
                  <th><span class="th-title">구분</span></th>
                  <th><span class="th-title">CDCS</span></th>
                  <th><span class="th-title">CSDG</span></th>
                  <th><span class="th-title">CTFC</span></th>
                  <th><span class="th-title">CFP</span></th>
                  <th><span class="th-title">CPB</span></th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td><span class="td-text">적용기준일</span></td>
                  <td><span class="td-text">2011년 이후</span></td>
                  <td><span class="td-text">2017년 이후</span></td>
                  <td colspan="2"><span class="td-text">2018년 이후</span></td>
                  <td><span class="td-text">2020년 7월 이후</span></td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="kb-table-bottom">
              <dl class="bottom-dl">
                <dd class="dl-dd subtext"><span class="text-red">주) FP, 신용분석사 관련 연수과정에 참가한 경우는 지원 가능</span><br>&emsp;(예비심사역 과정 수료 또는 참여 중인 연수생은 신용분석사 자격증 취득비용 지원불가)</dd>
              </dl>
            </div>
          </div>
        </li>
      </ol>
      <br>
      <dl class="lh-lg">
        <dt>▶ 학원비 기수혜 및 유관과정 수강여부 확인방법</dt>
        <dd class="mx-3">‘스타런 > 자기주도학습지원 자격증지원 신청 > 지원신청’ 화면에서 본인이 지원 신청하는 자격증 선택 시 기수혜 여부 확인 가능</dd>
      </dl>
    </article>
    <!-- page-buttons -->
  </div>

  <SupportLicenseFeeModal
      v-model="isCertFeeModalActive"
      :jncmpYmd="jncmpYmd"
  />
</template>

<script>
import {ref} from 'vue';
import {useStore} from "vuex";
import SupportLicenseFeeModal from "@/components/support/SupportLicenseFeeModal";
import {useAlert} from "@/assets/js/modules/common/alert";
import {getItems, ymdStrToFormat} from "@/assets/js/util";
import {getDsjChk} from "@/assets/js/modules/support/support-common"; // , getRefundChk
import {checkRefundConsult} from "@/assets/js/modules/consult/consult-common";
import {ACT_GET_JNCMP_YMD} from "@/store/modules/support/support";

export default {
  name: 'SupportLicenseAcquisitionGuide',
  components: {SupportLicenseFeeModal},
  setup() {
    const {showMessage} = useAlert();

    const isCertFeeModalActive = ref(false);
    const store = useStore();

    const openAppPage = async() => {
      //지원가능 대상자 체크
      let chkVal = await getDsjChk();
      if (getItems(chkVal) > 0){
        showMessage('자격증 지원 신청 대상이 아닙니다. ');
        isCertFeeModalActive.value = false;
        return false;
      }else{
        // chkVal = await getRefundChk();
        // if(getItems(chkVal) > 0){
        //   // showMessage('과정미수료 환입비용 미처리내역이 있습니다.<br>환입비용 처리 후 신청해주시기 바랍니다.');
        //   showMessage('미지급 또는 미환입 내역이 있습니다.<br>완료 후 신청해 주시기 바랍니다.');
        //   isCertFeeModalActive.value = false;
        //   return false;
        // }else{
        //   isCertFeeModalActive.value=true;
        // }
        checkRefundConsult(`#lrnerNm#님은 연수과정 미수료에 의한 <br>교육훈련비 #rtnAmt#원(#count#건) 환입 대상자 입니다.<br>환입 완료 전까지 <span style=color:#FF0000!important;>연수신청(자기주도학습지원 포함)이 제한</span>됩니다.<br><br> 연수비용 환입은 HR부(인재개발)<br>최슬비대리(☎7880)에게<br>문의 부탁드립니다.`, licModalOpen );
      }
    };


    const licModalOpen=()=>{
      isCertFeeModalActive.value=true;
    };


  const jncmpYmd = ref('');
  const getJncmpYmd=() => {
    store.dispatch(`support/${ACT_GET_JNCMP_YMD}`).then(res=>{
      jncmpYmd.value = ymdStrToFormat(res.jncmpYmd, "-");
    });
  };
  getJncmpYmd();

    return {
      isCertFeeModalActive,
      openAppPage,jncmpYmd
    };
  }
};
</script>