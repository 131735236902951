<template>
  <div class="ssl lxp-mobile-layout ssl-mobile-layout">
    <template v-if="isReady">
      <router-view @click="plusEffectNav"/>
      <div v-if="!mobileHideFooter" class="floating-btn-area">
        <SSLWriteButton :effect-nav="touchBodyCount" :effect-func="plusEffectNav" />
        <div class="floating-btn bg-white-btn">
          <button class="floating-btn-link" @click="goTop">
            <i class="icon-scrolltop"></i>
          </button>
        </div>
      </div>
      <SSLMobileGnb v-if="!mobileHideFooter" :effect-nav="touchBodyCount"/>
      <SSLCalendar v-if="calendar.show"/>
      <SSLPopup/>
    </template>
    <SSLEventStatusPopup v-if="eventStatusPopup" v-model="eventStatusPopup"/>
  </div>
</template>
<script>
import SSLMobileGnb from '@/views/layout/ssl/header/mobile/SSLMobileGnb';
import SSLCalendar from '@/components/ssl/common/SSLCalendar';
import SSLPopup from '@/components/ssl/common/SSLPopup';
import SSLWriteButton from "@/components/ssl/SSLWriteButton.vue";
import {sslLayoutSetup} from '@/assets/js/modules/ssl/setup/ssl-layout-setup';
import SSLEventStatusPopup from "@/components/ssl/SSLEventStatusPopup.vue";

export default {
  name: 'SSLMobileLayout',
  components: {
    SSLWriteButton,
    SSLPopup,
    SSLCalendar,
    SSLMobileGnb,
    SSLEventStatusPopup,
  },
  setup: sslLayoutSetup
}
</script>
