<template>
  <swiper
      :data-card="sslDivisionCd"
      class="learning"
      :loop="false"
      slides-per-view="auto"
      :centered-slides="false"
      :space-between="10"
      :pagination="{
        el: '.learning-pagination',
        clickable: true,
      }"
      :modules="modules"
  >

    <swiper-slide class="learning-item" :class="(selfSocBoardCnt >= targetStandard.finish)?'is-active':((isMyLrnTrgt)?'is-active_ing':'')">
      <a href="javascript:" @click="goLearn(sslDivisionCd, param.targetLrnTrgtGrpMstSn)">
        <header class="item-header">
          <h3 class="title">{{param.sslDivNm}}</h3>
        </header>
        <div class="learning-contents">
          <div class="content">
            <div class="content-left">
              <p class="content-title">학습노트 작성</p>
              <p class="content-subtext">연간 최대 {{ targetStandard.limit }}회</p>
            </div>
            <div class="content-text">{{ selfSocBoardCnt }}/{{ targetStandard.finish }}회</div>
          </div>
        </div>
        <div class="learning-visual">
          <div class="visual-item">
            <SSLStarGraph
                v-if="selfSocBoardCnt >= targetStandard.finish"
            />
            <SSLCircleGraph
                v-else
                :target-max-count="targetStandard.finish"
                :target-count="selfSocBoardCnt"
            />
          </div>
        </div>
      </a>
    </swiper-slide>
  </swiper>

  <SSLEnroll
      v-if="enroll.toggle"
      v-model:toggle="enroll.toggle"
      :dist-crse-cd="enroll.key"
  />
</template>
<script>
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import SSLCircleGraph from '@/components/ssl/graph/SSLCircleGraph';
import SSLStarGraph from '@/components/ssl/graph/SSLStarGraph';
import {sslStatusSetup} from '@/assets/js/modules/ssl/setup/ssl-status-setup';
import SSLEnroll from "@/components/ssl/common/SSLEnroll.vue";

export default {
  name: 'SSLMobileSelfStatus',
  components: {
    SSLEnroll,
    SSLStarGraph,
    SSLCircleGraph,
    Swiper,
    SwiperSlide
  },
  props: {
    sslDivisionCd:String,
  },
  setup: sslStatusSetup
}
</script>
