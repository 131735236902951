<template>
  <div class="popup-container" :class="{ 'is-active' : modelValue }">
    <div class="popup" id="popup-tuition-support-p062">

      <div class="popup-inner">
        <div class="popup-header">
          <h3 class="title">자격증 갱신비용 신청</h3>
        </div>
        <div class="popup-content">
          <div class="survey-container">
            <article class="popup-section section-form">
              <header class="section-header header-divider-v2">
                <h4 class="title">신청정보  {{param.costLcsnSn}}</h4>
              </header>
              <div class="section-content">
                <div class="kb-form-fields kb-form-fields-v2">
                  <Applicant />
                  <RowInputText v-model="param.lcsnNm" title="자격증명" :disabled="true"/>
                  <RowInputText v-model="param.pblcnInstNm" title="발급처" :disabled="true"/>
                  <RowInputText v-model="param.passAnnonYmd"  title="합격자 발표일" :disabled="true"/>
                  <RowDatepicker
                      title="주관처 갱신 신청일자"
                      v-model="param.updtAplyYmd"
                      v-model:toggle="toggles.updtAplyYmd"
                      subtext="카드영수증의 결제일자 또는 계좌이체 영수증의 이체일자"
                      :show-subtext="true"
                      @update:toggle="closeToggles(toggles, 'updtAplyYmd')"
                  />
                  <RowDatepicker
                      title="갱신된 유효기간"
                      v-model="param.updtVldYmd"
                      v-model:toggle="toggles.updtVldYmd"
                      :show-subtext="true"
                      subtext="주관처에서 자격갱신심사 후 발급된 자격증명사 상의 유효 기일"
                      :show-side="true"
                      @update:toggle="closeToggles(toggles, 'updtVldYmd')"
                  />
                  <RowInputText v-model="param.updtCost" title="갱신비용" placeholder="갱신비용을 입력해주세요" :is-number="true" :is-money="true"></RowInputText>
                  <RowInputText v-model="param.certNo" title="인증번호" placeholder="인증번호를 입력해주세요" class-lst="column-whole" />
                  <Upload
                      v-model="certFiles"
                      title="갱신증명서 사본"
                      placeholder="갱신증명서를 등록해주세요"
                      btn-title="갱신증명서 등록하기"
                      sequence-key="proofDataSn"
                      :extensions="extensions"
                      :max-qty="10"
                      :is-payment="true"

                  >
                    <template v-slot:file-last>
                      <div class="kb-form-bottomtext">*갱신자격증, 청구서, 수료증 등 포함. 유효기간 등 일자 표시</div>
                    </template>
                  </Upload>
                  <Upload  v-if="param.lcsnCd != 'WR67' && param.lcsnCd != 'WR71'"
                      v-model="costProofFiles"
                      title="납부증빙서류"
                      placeholder="납부증빙서류를 등록해주세요"
                      btn-title="납부증빙서류 등록하기"
                      sequence-key="proofDataSn"
                      :extensions="extensions"
                      :max-qty="10"
                      :is-payment="true"
                  >
                    <template v-slot:file-last>
                      <div class="kb-form-bottomtext">*갱신자격증, 청구서, 수료증 등 포함. 유효기간 등 일자 표시</div>
                    </template>
                  </Upload>
                </div>
              </div>
            </article>

            <article class="popup-section section-agree" v-if="param.rtnYn=='Y' || param.addAmtYn=='Y'">
              <header class="section-header header-divider-v2">
                <h4 class="title">환입 및 추가지원금액</h4>
              </header>
              <div class="section-content">
                <div class="kb-form-fields kb-form-fields-v2">
                  <RowInputText v-model="param.rtnAmt" title="환입금액"  :is-number="true" :is-money="true" :disabled="true" v-if="param.rtnYn=='Y'" />
                  <RowInputText v-model="param.addAmt" title="추가지원금액"  :is-number="true" :is-money="true" :disabled="true" v-if="param.addAmtYn=='Y'" />
                </div>
              </div>
            </article>

            <article v-if="param.costLcsnSn == 0 ||param.costLcsnSn==null" class="popup-section section-agree">
              <UserAgreement
                  ref="usrAgrmntRef"
                  v-model:user-agreement="userAgreement"
                  @applyBtnEnable="applyBtnEnable"
              />
            </article>
<!--            <article class="popup-section section-agree">-->
<!--              <header class="section-header">-->
<!--                <h4 class="title">유의사항</h4>-->
<!--              </header>-->
<!--              <div class="section-content agree-content">-->
<!--                <div class="segment-box">-->
<!--                  <p class="text">갱신비용 지급일 - 신청일 기준 익월 15일 경 급여계좌 입금</p>-->
<!--                  <p class="text">&nbsp;</p>-->
<!--                  <p class="text">연회비, 연체료, 계속학점취득비는 지원대상 아님</p>-->
<!--                  <p class="text">부정신청시 환입조치는 물론 향후 자기주도학습비 지원 제한, 인사상 불이익이 따를 수 있으므로 유의하시기 바랍니다.</p>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="agree-check">-->
<!--                <div class="kb-form-check">-->
<!--                  <input type="checkbox" v-model="userAgreement" class="kb-form-check-input" id="chk_agree_01" @click="userAgreement=!userAgreement"/>-->
<!--                  <label class="kb-form-check-label" for="chk_agree_01"><span class="text">동의완료</span></label>-->
<!--                </div>-->
<!--              </div>-->
<!--            </article>-->
          </div>
        </div>

        <SupportPopupButton
            :stt="data && data.stt"
            :aply-stt-cd-dcd="data && data.aplySttCdDcd"
            :apply-btn-enable="applyBtnEnable"
            :user-agreement="userAgreement"
            :apply="apply"
            :cancel="cancel"
            :close-modal="closeModal"
            type="renewal"
        />
      </div>
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
  </div>
  <LxpConfirm />
</template>

<script>
import LxpConfirm from '@/components/common/LxpConfirm';
import Applicant from '@/components/support/common/Applicant';
import RowInputText from '@/components/support/common/RowInputText';
import RowDatepicker from '@/components/support/common/RowDatepicker';
import Upload from '@/components/support/common/Upload';
import {supportLicenseRenewSetup} from "@/assets/js/modules/support/support-license-renew-setup";
import SupportPopupButton from "@/components/support/common/SupportPopupButton";
import UserAgreement from "@/components/support/common/UserAgreement";

export default {
  name: 'SupportLicenseRenewModal',
  components: {SupportPopupButton, Upload, RowDatepicker, RowInputText, Applicant, LxpConfirm, UserAgreement},
  props: {
    modelValue: Boolean,
    data: Object,
    reList : Function,
    mode: String
  },
  setup: supportLicenseRenewSetup
};
</script>