<template>
  <div v-if="!reload" @click="plusNavigation">
    <HrdMobileVod
        v-if="item.cttTyCdDcd === cttTyCdDcds.CTT_TY_CD_VOD"
        :effect-nav="clickCount"
        v-model:objDetail="item"
    />
    <HrdMobileYoutube
        v-else-if="item.cttTyCdDcd === cttTyCdDcds.CTT_TY_CD_YOUTUBE"
        :effect-nav="clickCount"
        v-model:objDetail="item"
    />

    <HrdMobilePdf
        v-else-if="!isLoading && item.cttTyCdDcd === cttTyCdDcds.CTT_TY_CD_PDF"
        :effect-nav="clickCount"
        v-model:objDetail="item"
    />
    <HrdScorm
        v-else-if="item.cttTyCdDcd === cttTyCdDcds.CTT_TY_CD_ZIP"
        :mode="mode"
        :effect-nav="clickCount"
        v-model:objDetail="item"
    />
  </div>
</template>

<script>
import {useRoute} from 'vue-router';
import {computed, ref, watch} from 'vue';
import {ACT_GET_HRDCLOUD_DETAIL} from '@/store/modules/prep/prep';
import {
  cttTyCdDcds,
  getHrdCloudLatestLrnObjDtlDistSn,
  getTop1CategoryByLevel,
  makeTags,
  showAlertAndGoBack,
} from '@/assets/js/modules/hrd/hrd-common';
import {useStore} from 'vuex';
import HrdScorm from '@/components/hrdcloud/pages/HrdScorm';
import HrdMobileVod from '@/components/hrdcloud/mobile/page/HrdMobileVod';
import HrdMobileYoutube from '@/components/hrdcloud/mobile/page/HrdMobileYoutube';
import HrdMobilePdf from '@/components/hrdcloud/mobile/HrdMobilePdf';
import {lengthCheck, stringCheck} from '@/assets/js/util';

export default {
  name: 'HrdCloudMobileContentBridge',
  components: {HrdMobilePdf, HrdMobileVod, HrdScorm, HrdMobileYoutube},
  setup() {
    const store = useStore(), route = useRoute(), isLoading = ref(true);
    const lrnObjDtlDistSn = computed(() => route.params.lrnObjDtlDistSn);
    const item = ref({});
    const reload = ref(false);

    const getItem = async () => {
      try {
        await getHrdCloudLatestLrnObjDtlDistSn(lrnObjDtlDistSn.value);

        const res = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_DETAIL}`, {lrnObjDtlDistSn: lrnObjDtlDistSn.value});
        if (lengthCheck(res)) {
          item.value = res.items.map(x => {
            return {
              ...x,
              ...getTop1CategoryByLevel(x.cloudKeyLst),
              tag: makeTags(x.tag)
            };
          })[0];
          if (!stringCheck(item.value.etnUrl)) {
            showAlertAndGoBack(null, "HrdCloud");
          }
          isLoading.value = false;
        } else {
          isLoading.value = false;
          showAlertAndGoBack(null, "HrdCloud");
        }
      } catch {
        isLoading.value = false;
        showAlertAndGoBack(null, "HrdCloud");
      }
    };

    getItem();

    const clickCount = ref(0);
    const plusNavigation = () => {
      clickCount.value++;
    };

    watch(() => lrnObjDtlDistSn.value, () => {
      reload.value = !reload.value;
      if(lrnObjDtlDistSn.value > 0) getItem();
    });

    watch(() => reload.value, () => {
      const tm = setTimeout(() => {
        reload.value = false;
        clearTimeout(tm);
      }, 500);
    });

    return {
      isLoading,
      cttTyCdDcds,
      item,
      plusNavigation, clickCount,
      reload
    }
  }
};
</script>
