<template>
  <HrdCloudMobileHeader title="클라우드 맵" :show-back="true" :go-back-func="goBackFunc">
    <template v-slot:right>
      <div class="util util-menu">
        <a v-if="isAllExpanded" href="javascript:" class="util-actions util-actions-download text-primary" @click="collapseAll">전체 접기</a>
        <a v-else href="javascript:" class="util-actions util-actions-download text-primary"  @click="expandAll">전체 펼치기</a>
      </div>
    </template>
  </HrdCloudMobileHeader>
  <main class="kb-main" id="kb-hrdcloud">
    <section class="cloud-map-container">
      <LoadingDiv v-if="isLoading" />
      <HrdCloudMapTree :items="treeItems" @toggleNode="toggleNode" @searchCate="searchCate"/>
    </section>
  </main>
</template>
<style scoped>
.lxp-mobile-layout #kb-hrdcloud { padding-bottom: 0px !important;}
</style>
<script>
import {useRouter} from 'vue-router';
import {ref} from 'vue';
import {useStore} from 'vuex';
import {ACT_GET_HRDCLOUD_CATE_TREE_LIST} from '@/store/modules/hrdcloud/hrdcloud';
import {getItems, lengthCheck} from '@/assets/js/util';
import HrdCloudMobileHeader from '@/components/hrdcloud/mobile/HrdCloudMobileHeader';
import HrdCloudMapTree from '@/components/hrdcloud/HrdCloudMapTree';
import LoadingDiv from '@/components/common/LoadingDiv.vue';

export default {
  name: 'HrdCloudMobileCloudMap',
  components: {LoadingDiv, HrdCloudMapTree, HrdCloudMobileHeader},
  props:{modelValue:Boolean},
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const store = useStore();
    const router = useRouter();
    const isLoading = ref(true);
    const treeItems = ref([]);
    const isAllExpanded = ref(false);

    const searchCategory = (e, data) => {
      let url = { name: 'HrdCloud', query: {} };
      let cateSnArr = data.hrdCloudCateSnLst.split(',');

      for (let i in cateSnArr) {
        let cateNm = 'category'+(Number(i)+1);
        url.query[cateNm] = cateSnArr[i];
      }

      router.push(url);
    };

    const searchCate = (arr) => {
      const query = {};
      arr.forEach((x,i) => {
        query[`category${i+1}`] = x;
      });
      router.push({query});
      goBackFunc();
    }

    const toggleNode = (arr) => {
      let target = treeItems.value;
      for (let i = 0; i < arr.length; i++) {
        if (i === (arr.length - 1)) {
          target[arr[i]].toggle = !target[arr[i]].toggle;
        } else {
          target = target[arr[i]].children;
        }
      }
    }

    const expandAll = () => {
      isAllExpanded.value = true;
      treeItems.value.forEach(x => {
        controlNode(x, true);
      });
    }

    const collapseAll = () => {
      isAllExpanded.value = false;
      treeItems.value.forEach(x => {
        if (x && x.children && x.children.length > 0) {
          x.children.forEach(y => {
            controlNode(y, false);
          });
        }
      });
    }

    const controlNode = (target, toggle) => {
      target.toggle = toggle;

      if (target && target.children && target.children.length > 0) {
        target.children.forEach(x => {
          controlNode(x, toggle);
        });
      }
    }

    const goBackFunc = () => {
      emit('update:modelValue',false);
    };

    try {
      store.dispatch(`hrdcloud/${ACT_GET_HRDCLOUD_CATE_TREE_LIST}`).then(res => {
        if (lengthCheck(res)) {
          const items = getItems(res);
          treeItems.value = items.filter(x => x.hrdCloudCateNm === '직무 클라우드' || x.hrdCloudCateNm === '지식 클라우드').map(x => ({...x, toggle: true}));
        }
        isLoading.value = false;
      });
    } catch (e) {
      isLoading.value = false;
    }


    return {
      isLoading,
      treeItems, isAllExpanded,
      searchCategory,
      toggleNode,
      expandAll,
      collapseAll,
      goBackFunc,
      searchCate
    }
  }
};
</script>