<template>
  <div class="board-view-top view-component">
    <div class="board-view-author">
      <button class="kb-btn-following" :class="{'is-follow': isSubscribe}" @click="toggleSubscribe">
        <i class="following"></i>
        <span class="text">{{ isSubscribe ? '구독중' : '구독' }}</span>
      </button>
    </div>

  </div>
</template>
<script>
import {sslLearnerSubscribeSetup} from '@/assets/js/modules/ssl/setup/ssl-learn-setup';

export default {
  name: 'SSLMobileLearnerSubscribe',
  props: {
    lrner: Object
  },
  setup: sslLearnerSubscribeSetup
}
</script>
