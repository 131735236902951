<template>
  <article class="home-section section-sbadge">
    <header class="section-header">
      <h3 class="title">배지 <small class="text-muted">{{myBadges.length}}/{{activeBadges.length}}</small></h3>
      <div v-if="!isMobile" class="header-sub">
        <router-link :to="{name:'BadgeBox'}" class="more-link text-dark">배지 보관함</router-link>
      </div>
    </header>
    <div class="section-body">
      <swiper
          class="sbadge-container"
          :modules="modules"
          :slides-per-view="10"
          :speed="750"
          :navigation="{
            nextEl: '.kb-btn-badge-next',
            prevEl: '.kb-btn-badge-prev'
          }"
      >
        <swiper-slide v-for="(badge, idx) in activeBadges" :key="idx" >
          <SSLBadgeTitle
              :badge-ty-cd-dcd="badge.badgeTyCdDcd"
              :is-active="badge.isActive"
              :is-new="badge.isNew"
              :show-title="true"
              :hidden-yn="badge.hiddenYn"
              :badge-data="badge"
          />
        </swiper-slide>
      </swiper>

      <button class="kb-btn-sbadge-prev kb-btn-badge-prev">
        <i class="icon-arrow-prev">prev</i>
      </button>
      <button class="kb-btn-sbadge-next kb-btn-badge-next">
        <i class="icon-arrow-next">next</i>
      </button>
    </div>

  </article>
</template>
<script>
import {Navigation} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import SSLBadgeTitle from '@/components/ssl/badge/SSLBadgeTitle';
import {computed, ref} from 'vue';
import {useStore} from 'vuex';
import {getBadgeList} from '@/assets/js/modules/ssl/ssl-badge';
import {getListFunc} from "@/assets/js/ui.init";
import {ACT_GET_COM_BADGE_TY_LIST} from "@/store/modules/badge/badge";
// import {getBadgeList} from "@/assets/js/modules/ssl/ssl-badge";
// import {useRoute} from "vue-router/dist/vue-router";

export default {
  name: 'SSLHomeBadge',
  components: {
    Swiper,
    SwiperSlide,
    SSLBadgeTitle
  },
  setup() {
    const store = useStore();
    // const route = useRoute();
    const myBadges = computed(() => store.state.ssl.myBadges);
    const isMobile = computed(() => store.state.auth.isMobile);
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear().toString();
    const currentTimestamp = currentDate.getTime();

    const sslBadges = ref([]);

    getListFunc(`badge/${ACT_GET_COM_BADGE_TY_LIST}`, {year: currentYear, badgeKindCdDcd: "2065001"}, sslBadges, null);

    getBadgeList();

    const activeBadges = computed(() => {
      return sslBadges.value.filter(i => i.year === currentYear).map(x => {
        const targetBadge = {
          badgeTySn: x.badgeTySn,
          badgeTyCdDcd: x.badgeTyCdDcd,
          isActive: false,
          isNew: false,
          hiddenYn: x.hiddenYn,
          badgeName: x.badgeTyNm,
          badgeDesc: x.cdDesc
        };
        const found = myBadges.value.filter(y => x.badgeTyCdDcd === y.badgeTyCdDcd);

        if(found.length>0){
          targetBadge.isActive = true;
          targetBadge.isNew = found.getDt + (1000 * 60 * 60 * 24 * 7) >= currentTimestamp;
        }else{
          if (targetBadge.hiddenYn === 'Y') {
            targetBadge.badgeName = x.hiddenBadgeTyNm;
            targetBadge.badgeDesc = x.hiddenBadgeCn;
          }
        }

        return targetBadge;
      }).sort((a,b) => {
        if (a.badgeTySn < b.badgeTySn) { return -1; }
        else {return 1;}
      }).sort((a) => {
        if (a.isActive) { return -1; }
        else {return 1;}
      }).sort((a) => {
            if (a.isNew) { return -1; }
            else {return 1;}
      });
    });


    // console.log(activeBadges);


    return {
      sslBadges,
      myBadges,
      activeBadges,
      modules: [Navigation],
      isMobile
    }

  }
}
</script>
