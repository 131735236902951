<template>
    <!-- member-list-wrap -->
      <div class="list-top">
        <div class="top-column">
          <p class="title text-muted">총 {{ paging.totalCount }}건</p>
        </div>
      </div>
      <ul class="meeting-list divider-list">
        <!-- member-item -->
        <li v-for="(item, idx) in items" :key="idx" class="meeting-item divider-item">
          <div class="meeting-card">
            <div class="meeting-content">
              <div class="content-title">
                <h4 class="title">{{ item.objNm }}</h4>
              </div>
              <div class="content-meta">
                <div class="meta"><span class="meta-text">{{ timestampToDateFormat(item.regDt, 'yyyy.MM.dd') }}  {{timestampToDateFormat(item.regDt, 'hh:mm')}}-{{timestampToDateFormat(item.mdfcnDt, 'hh:mm')}}</span></div>
                <div class="meta"><span class="meta-text text-muted">참석 {{ item.grpMoimAttndCnt }} 정원 {{ item.grpTrgtDtlCnt }}</span></div>
              </div>
            </div>
            <div class="meeting-status">
              <strong class="text text-primary" :class="{'text-red' : item.mtgAcptYn === 'N'}">{{ item.mtgAcptYn === 'Y' ? '인정' : '미인정'}}</strong>
            </div>
            <div class="meeting-actions">
              <router-link :to="{name: 'GroupLearningJournal', params: {boardSn: item.boardSn, lrnPostSn: item.lrnPostSn}}" class="kb-btn kb-btn-primary">
                <span class="text">모임상세</span>
              </router-link>
            </div>
          </div>
        </li>
      </ul>
      <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>

      <div v-if="items.length === 0" class="contents-empty">
        <p class="text">완료된 그룹 모임이 없습니다.</p>
      </div>
    <!-- //member-list-wrap -->


</template>

<script>
import CommonPaginationFront from "@/components/CommonPaginationFront";
import {groupMeetingStatusSetup} from "@/assets/js/modules/groupLearing/group-meeting-status-setup";

export default {
  name: "MeetingStatus",
  components: {CommonPaginationFront},
  setup: groupMeetingStatusSetup
}
</script>