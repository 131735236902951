<template>
  <div class="popup-container p-0 d-block" id="kb-mobile-popup" :class="{ 'is-active' : show }">
    <HrdCloudMobileHeader :title="`담은 콘텐츠${ page === 'grouplearning' ? '(그룹러닝)' : '' }`" :show-back="true" :go-back-func="goBackFunc">
      <template v-slot:right>
        <div v-if="!isEditMode" class="util util-btn">
          <a href="javascript:" class="util-actions util-actions-btn text-primary" @click="isEditMode=!isEditMode">편집하기</a>
        </div>
        <div v-else class="util util-btn">
          <a v-if="!checkAll" href="javascript:" class="util-actions util-actions-btn text-primary" @click="clickCheckAll">전체선택</a>
          <a v-else href="javascript:" class="util-actions util-actions-btn text-primary" @click="clickUnCheckAll">전체해제</a>
          <a href="javascript:" class="util-actions util-actions-btn text-red" @click="removeAddedCtt">삭제</a>
        </div>
      </template>
    </HrdCloudMobileHeader>
    <main class="kb-main" id="kb-hrdcloud">
      <div class="main-content main-component">
        <article class="content-section">
          <div class="hrdcloud-list-container">
            <HrdCloudRow
                :data-grid="3"
                :session="session"
                :items="items"
                :paging="paging"
                :paging-func="pagingFunc"
                :is-editable="isEditMode"
                :edit-func="selectKeepToBeDeleted"
            />
          </div>
          <LoadingDiv v-if="isLoading" />
        </article>
        <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>
      </div>
    </main>
  </div>
</template>
<script>
import HrdCloudMobileHeader from '@/components/hrdcloud/mobile/HrdCloudMobileHeader';
import HrdCloudRow from '@/components/hrdcloud/HrdCloudRow';
import {hrdAddSetup} from '@/assets/js/modules/hrd/hrd-add-setup';
import LoadingDiv from '@/components/common/LoadingDiv.vue';
import CommonPaginationFront from "@/components/CommonPaginationFront.vue";

export default {
  name: 'HrdCloudHistoryMobileAdd',
  components: {LoadingDiv, HrdCloudRow, HrdCloudMobileHeader,CommonPaginationFront},
  setup: hrdAddSetup
};
</script>
