import {
  computed,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  toRefs,
  watch,
} from 'vue';
import {useStore} from 'vuex';
import {
  clearTargetTimeout,
  getRandom,
  initParams,
  isSuccess,
} from '@/assets/js/util';
import {
  ACT_GET_COLDCALL_TIME,
  ACT_INSERT_COLDCALL,
  ACT_UPDATE_COLDCALL,
} from '@/store/modules/common/coldcall';
import {eventCdDcds} from '@/assets/js/modules/hrd/hrd-common';
import {ACT_INSERT_HRDCLOUD_LVL_FEEDBACK} from "@/store/modules/hrdcloud/hrdcloud";
import {useAlert} from "@/assets/js/modules/common/alert";

export const hrdColdCallSetup = (props) => {
  const store = useStore();
  const {showMessage} = useAlert();

  const isMobile = computed(() => store.state.auth.isMobile);
  const layerCont = ref(null),
      coldCallTimeout = ref(null),
      {popups} = toRefs(props);
      // {completeActive} = toRefs(props);

  const randPos = reactive({
    left: 0,
    top: 0
  });

  const videoMeta = reactive({
    currTime: 0,
    maxPlaySec: 0,
    actPlaySec: 0,
    lastPlaySec: 0
  });

  const param = reactive({
    progLogSn: 0,
    progRflYn: 'Y', // 반영 여부 현재는 Y로 고정 추후 인정 불가 사유 생길시 개발필요
    coldCallRound: 0, // 매회 상승 maximum : 2
    coldCallTime: 0, // 콜드콜 노출 시간
    responded: false // 응답여부 닫으면 초기화되어야함
  });

  const toggle = reactive( {
    coldCall: false,
    miss: false,
    continue: false,
    coldCallSuccess: false,
  });

  const userFeedback = ref(null);
  const feedback_que = ref(true);

  const levelString = computed(()=>{
    let lvlStr ={
      "1": "초급",
      "3": "중급",
      "5": "고급"
    };

    return props.objectParam.cttDifi ? lvlStr[props.objectParam.cttDifi] : "미분류";
  });

  const submitFeedback_mob = async () =>{

    if(userFeedback.value){
      await store.dispatch(`hrdcloud/${ACT_INSERT_HRDCLOUD_LVL_FEEDBACK}`, {
        cttMstSn: props.objectParam.objSn,
        lrnObjDtlDistSn: props.objectParam.lrnObjDtlDistSn,
        params: {
          lvl: userFeedback.value,
          cmnt: "학습자 난이도 평가",

        },
      }).then(res => {
        if(isSuccess(res)){
          feedback_que.value = false;
        }else{
          feedback_que.value = true;
          showMessage("평가 제출 중 오류입니다. 다시 시도 해 주세요!!");
          return;
        }
      })
    }else{
      showMessage('난이도를 선택 해 주세요!!');
      return;
    }

  };

  // 콜드콜 노출 위치 설정
  const setPosition = (pCont, lCont) => {
    if (pCont && lCont) {
      let pOff = pCont.getBoundingClientRect(), cOff =  lCont.getBoundingClientRect();

      // 모바일 전체화면일 경우 HTML Element의 길이가 아닌 화면의 길이 넣어줘서 coldcall이 화면을 이탈하는 현상을 방지
      const qHeight = window.innerHeight < pOff.height ? window.innerHeight : pOff.height;

      let x1 = 0; // 플레이어 레이어 left 시작 위치
      let x2 = pOff.width - cOff.width; // 콜드콜 레이어 width만큼 플레이어 밖으로 안나가는 최대 위치
      let y1 = 0; // 플레이어 레이어 top 시작 위치
      let y2 = qHeight - cOff.height; // 콜드콜 레이어 heigth만큼 플레이어 밖으로 안나가는 최대 위치
      randPos.left = getRandom(x1, x2);
      randPos.top = getRandom(y1,y2);
    }
  };

  const handleRespond = () => {

    if(toggle.coldCallSuccess){
      toggle.coldCall = false;
      return;
    }
    clearTimeout(coldCallTimeout.value);
    updateColdCall('er');
    param.responded = true;
    toggle.coldCallSuccess = true;
    setTimeout(() => {
      toggle.coldCall = false;
    },3000)
  };
  // 학습 중단, 처음부터 다시 학습
  const resetColdCall = (pause) => {
    if (props.meta.duration > 60) {
      updateColdCall('ea');
      props.player.currentTime(0);
      popups.value.miss.toggle(false);
      toggle.continue = false;
      // emit('update:completeActive', true);
      clearTargetTimeout(coldCallTimeout.value);
      param.progLogSn = 0;
      param.coldCallRound = 0;
      param.responded = false;
      initParams(videoMeta);
      getColdCallTime(0);
      if (pause) props.player.pause();
      else props.player.play();
    }
  };
  // 학습 중단, 계속 학습
  const continueWatching = () => {
    updateColdCall('e');
    param.progLogSn = 0;
    param.coldCallTime = 0;

    popups.value.miss.toggle(false);
  };

  const getColdCallTime = async (currentTime) => {
    if (!isFinite(props.meta.duration)) {
      props.meta.duration = 0;
    }

    store.dispatch(`coldcall/${ACT_GET_COLDCALL_TIME}`,{
      objSn: props.objectParam.objSn,
      duration: props.meta.duration,
      coldCallRound: param.coldCallRound,
      lastPlaySec: currentTime
    }).then(res => {
      if (isSuccess(res)) {
        if (res.time > 0) {
          param.coldCallTime = res.time;
        }
      }
    });
  };

  const getContinueColdCallTime = async (currentTime) => {

    let durationValue;

    if (!isFinite(props.meta.duration)) {
      durationValue = 0;
    }else {
      durationValue = props.meta.duration - currentTime;
    }
    store.dispatch(`coldcall/${ACT_GET_COLDCALL_TIME}`,{
      objSn: props.objectParam.objSn,
      duration: durationValue,
      coldCallRound: param.coldCallRound,
      lastPlaySec: 0
    }).then(res => {
      if (isSuccess(res)) {
        if (res.time > 0) {
          param.coldCallTime = res.time;
        }
      }
    });
  };

  const showColdCall = (currentTime, playbackRate) => {
    if (param.responded) return;

    insertColdCall(eventCdDcds.Coldcall, currentTime, playbackRate);
    setPosition(props.playerCont, layerCont.value);
    toggle.coldCall = true;

    coldCallTimeout.value = setTimeout(() => {
      toggle.coldCall = false;
      param.coldCallRound++;
      updateColdCall('e');
      if (param.coldCallRound === 1) {
        getColdCallTime(videoMeta.actPlaySec);
      } else {
        props.player.pause();
        insertColdCall(eventCdDcds.ColdcallStop, currentTime, playbackRate, () => {
          popups.value.miss.toggle(true);
        });
      }
    }, 15000);
  };

  const insertColdCall = (eventCdDcd, currentTime, playbackRate, cb) => {
    store.dispatch(`coldcall/${ACT_INSERT_COLDCALL}`,{
      progSesnKey: props.uuid,
      lastPlaySec: currentTime,
      duration: props.meta.duration,
      playSpeed: playbackRate,
      cmptnYn: props.progress.cmptnYn,
      progRflYn: 'Y',
      coldCallRound: (eventCdDcd === eventCdDcds.ColdcallStop ? 0 : param.coldCallRound + 1),
      eventCdDcd,
      ...props.objectParam,
    }).then(res => {
      if (isSuccess(res)) {
        param.progLogSn = res.progLogSn;
        if (cb instanceof Function) cb(res);
      }
    })
  };

  // r 응답, e 종료, a 중단
  const updateColdCall = (coldCallDivision) => {

    console.log("update coldcall")
    if (param.progLogSn > 0) {
      store.dispatch(`coldcall/${ACT_UPDATE_COLDCALL}`,{
        progLogSn: param.progLogSn,
        params: {
          progSesnKey: props.uuid,
          progRsltSn: props.progress.progRsltSn,
          ...props.objectParam,
          playSpeed: props.player.playbackRate(),
          responded: param.responded,
          coldCallDivision
        }
      });
    }
  };

  const closeColdCallSuccess = () => {
    toggle.coldCallSuccess = false;
  };

  const successColor = computed(() => toggle.coldCallSuccess ? 'rgba(224,211,188,0.8)' : '');

  getColdCallTime(props.lastPlaySec);

  watch(() => popups.value.miss.show, () => {
    if (popups.value.miss.show) props.player.pause();
  });

  watch(() => popups.value.miss.response.effect, () => {
    if (popups.value.miss.response.type) {
      if (popups.value.miss.response.type === 'reset') resetColdCall(true);
      else if (popups.value.miss.response.type === 'continue') continueWatching();
      popups.value.miss.initRespond();
    }
  });

  // 계속학습을 눌렀을 경우 콜드콜 나오는 시간 조정
  watch(() => popups.value.resume.response.continueEffect, () => {
    getContinueColdCallTime(props.progress.lastPlaySec);
  });

  onMounted(() => {
    videoMeta.actPlaySec = props.lastPlaySec;
    props.player.on('timeupdate', function() {
      let currentTime = Math.floor(this.currentTime());

      if (currentTime - videoMeta.lastPlaySec === 0) {
        return;
      }
      // seek 이벤트 걸러내기
      if (currentTime - videoMeta.lastPlaySec > 0 && currentTime - videoMeta.lastPlaySec < 2) {
        videoMeta.actPlaySec += (currentTime - videoMeta.lastPlaySec);
        videoMeta.lastPlaySec = currentTime;
        // if (props.meta.duration > 60) {
          let miss = isMobile.value ? toggle.miss : popups.value.miss.show;
          if (!toggle.coldCall && !miss && !toggle.continue && !param.responded && param.coldCallRound < 2 && param.coldCallTime > 0) {
            if (toggle.coldCallSuccess) toggle.coldCallSuccess = false;
            // if (videoMeta.lastPlaySec == param.coldCallTime) {
            if (videoMeta.actPlaySec >= param.coldCallTime && (videoMeta.actPlaySec - param.coldCallTime) <= 5) {
              const playbackRate = this.playbackRate();
              showColdCall(currentTime, playbackRate);
            }
          }

          // 1분마다 콜드콜 체크
          if ((videoMeta.actPlaySec % 60) === 0) {
            // (콜드콜 시간을 가져왔으나 사용자가 seek 하여 현재 시간이 더 앞서 갔을때 || 콜드콜을 못가져왔으면 가져오기) 새로운 콜드콜 시간 생성
            if (!param.responded && !toggle.coldCall && (param.coldCallTime < videoMeta.actPlaySec || param.coldCallTime === 0)) getColdCallTime(currentTime);
            if (!window.navigator.onLine) return alert('인터넷 연결이 끊어졌습니다.');
          }
        // }
      }
    });

    props.player.on('seeked', function() {
      videoMeta.lastPlaySec = Math.floor(props.player.currentTime());
    });

    props.player.on('ended', function() {
      param.progLogSn = 0;
      resetColdCall(true);
    });
  });

  window.addEventListener("resize", () => {
    setPosition(props.playerCont, layerCont.value);
  }, true);

  onUnmounted(() => {
    clearTargetTimeout(coldCallTimeout.value);
  });

  return {
    layerCont, randPos, toggle,
    handleRespond, resetColdCall, continueWatching,
    setPosition, closeColdCallSuccess, successColor,
    levelString, submitFeedback_mob, userFeedback, feedback_que
  }
}