<template>
  <div class="member-list-wrap">
<!--    <ul class="member-list" :style="memberStyle">-->
    <ul class="member-list">
      <!-- member-item -->
      <li v-for="(item, idx) in items" :key="idx" class="member-item">
        <div class="member-card">
          <div class="member-avatar">
            <div class="image">
              <ProfileImg :target="item"/>
            </div>
            <div v-if="!isMobile" class="badge" :class="item.grpRoleCdDcd === '2010001' ? 'badge-primary' : 'badge-gold'">
              <span class="badge-text">{{item.grpRoleCdDcd === '2010001' ? '리더' : '멤버' }}</span>
            </div>
          </div>
          <div class="member-content">
            <div class="content-meta">
              <div class="meta"><span class="meta-text">{{ item.lrnerNm }}</span></div>
              <div class="meta"><span class="meta-text text-muted">{{ item.deptNm }}・{{ item.jbgdNm }}{{ item.jbpsNm }}</span></div>
            </div>
            <div v-if="isSelfIntro && (item.lrnerId === session.lrnerId)" class="content-edit">
              <div class="segment-box edit-box">
                <textarea v-model="params.selfIntro" class="kb-form-control" maxlength="30"></textarea>
              </div>
              <div class="edit-actions">
                <button class="kb-btn-outline btn" @click="clickSelfIntroCancel"><span class="text">취소</span></button>
                <button class="kb-btn-outline btn" @click="clickSelfIntroSave(item.lrnTrgtGrpDtlSn)"><span class="text">등록</span></button>
              </div>
            </div>
            <div v-else class="content-text"><p class="text">{{ item.selfIntro }}</p></div>
          </div>
          <div v-if="$route.name === 'GroupView'" class="member-info">
            <div class="info"><span class="info-title">출석</span><span class="info-text">{{ item.attndRate }}회</span></div>
            <div class="info"><span class="info-title">학습시간</span><span class="info-text">{{ item.sumActPlaySec ? item.sumActPlaySecMi : '00' }}분</span></div>
          </div>
          <template v-if="!isSelfIntro">
            <div v-if="item.lrnerId === session.lrnerId && item.stt !== '00' && $route.name !== 'GroupView'" class="member-actions">
              <button class="kb-btn kb-btn-outline" @click="clickSelfIntroEdit(item)"><span class="text">수정</span></button>
            </div>
          </template>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import ProfileImg from "@/components/common/ProfileImg";
import {groupMembersSetup} from "@/assets/js/modules/groupLearing/group-members-setup";

export default {
  name: "GroupMembers",
  components: {ProfileImg},
  props: {
    items: Array,
    modelValue: Boolean,
  },
  emits: ['update:modelValue'],
  setup: groupMembersSetup
}
</script>