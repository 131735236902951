<template>
  <div class="popup-container" :class="{'is-active': modelValue}">
    <div class="popup" id="popup-hrdcloud-mileage">
      <div class="popup-inner">
          <header class="popup-header">
            <h3 class="title">HRD 클라우드 마일리지 </h3>
          </header>
          <div class="popup-content">
            <div class="popup-tabs">
              <a href="javascript:" class="tab" :class="{ 'is-active' : mlgSaveCdDcd === mlgSaveCdDcds.MLG_SAVE_CD_HRDCLOUD }" @click="handleServ(mlgSaveCdDcds.MLG_SAVE_CD_HRDCLOUD)">동영상 학습</a>
              <a href="javascript:" class="tab" :class="{ 'is-active' : mlgSaveCdDcd === mlgSaveCdDcds.MLG_SAVE_CD_CLOUDEASY }" @click="handleServ(mlgSaveCdDcds.MLG_SAVE_CD_CLOUDEASY)">전산실습 Easy</a>
              <a v-if="year <= 2023" href="javascript:" class="tab" :class="{ 'is-active' : mlgSaveCdDcd === mlgSaveCdDcds.MLG_SAVE_CD_KBTUBE }" @click="handleServ(mlgSaveCdDcds.MLG_SAVE_CD_KBTUBE)">KB Tube 등재</a>
            </div>
            <div class="mileage-container">
              <div v-if="!isLoading" class="kb-table kb-table-striped">
                <div class="kb-table-top">
                  <span class="text">{{ getBgngEndDate(year) }}</span>
                </div>
                <table>
                  <colgroup>
                    <col style="width:240px" />
                    <col />
                    <col />
                    <col style="width:200px" />
                  </colgroup>
                  <thead>
                  <tr>
                    <th><span class="th-title">마일리지 부여일시</span></th>
                    <th><span class="th-title">카테고리</span></th>
                    <th><span class="th-title">콘텐츠명</span></th>
                    <th><span class="th-title">획득마일리지</span></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, idx) in items" :key="idx">
                    <td><span class="td-text">{{ timestampToDateFormat(item.regDt, 'yyyy.MM.dd hh:mm') }}</span></td>
                    <td><span class="td-text">{{ item.cloudNmLst }}</span></td>
                    <td><span class="td-text">{{ item.objNm }}</span></td>
                    <td><span class="td-text">{{ item.mlgValue }}</span></td>
                  </tr>
                  </tbody>
                </table>
                <div v-if="!items || (items && items.length === 0)" class="search-container">
                  <div class="result-empty">
                    <img src="../../../assets/lxp/images/common/img_empty.png" alt="">
                    <p class="text">부여된 마일리지가 없습니다.</p>
                  </div>
                </div>
                <div class="kb-table-bottom bottom-notice">
                  <strong class="text text-muted texticon"><i class="icon-notice"></i> 학습완료 후 마일리지가 정상 부여된 학습건을 조회 가능합니다.</strong>
                </div>
              </div>
              <CommonPaginationFront :paging="paging" :page-func="pagingFunc" />
            </div>
          </div>
      </div>
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
  </div>
</template>

<script>
import {getBgngEndDate, timestampToDateFormat} from '@/assets/js/util';
import {reactive, ref} from 'vue';
import {mlgSaveCdDcds} from '@/assets/js/modules/hrd/hrd-common';
import CommonPaginationFront from '@/components/CommonPaginationFront';
import {getMileageList} from '@/assets/js/modules/hrd/hrd-history-common';
import {useAlert} from '@/assets/js/modules/common/alert';

export default {
  name: 'Mileage',
  components: {CommonPaginationFront},
  props: {
    modelValue: Boolean,
    year: [String, Number]
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const {showLoading, hideLoading} = useAlert();
    const isLoading = ref(false);
    const closeModal = () => {
      emit('update:modelValue');
    };

    const mlgSaveCdDcd = ref(mlgSaveCdDcds.MLG_SAVE_CD_HRDCLOUD);

    const items = ref([]);
    const paging = reactive({
      pageNo: 1,
      pageSize: 10,
      totalCount: 0
    });

    const callback = () => {
      hideLoading();
      isLoading.value = false;
    }

    const getMlgList = () => {
      if (isLoading.value) return;
      isLoading.value = true;
      showLoading();
      getMileageList({
            year: props.year,
            mlgSaveCdDcd: mlgSaveCdDcd.value,
            mlgTyCd: (mlgSaveCdDcd.value === mlgSaveCdDcds.MLG_SAVE_CD_KBTUBE ? '4001' : '1001')
          }, items, paging,
          callback, callback);
    };

    const pagingFunc = (pageNo) => {
      paging.pageNo = pageNo;
      getMlgList();
    };

    const handleServ = (servTy) => {
      paging.pageNo = 1;
      mlgSaveCdDcd.value = servTy;
      getMlgList();
    };

    getMlgList();

    return {
      items, paging, pagingFunc, isLoading,
      closeModal, getBgngEndDate, mlgSaveCdDcd, mlgSaveCdDcds,
      timestampToDateFormat, handleServ
    }
  }
};
</script>