<template>
  <div class="kb-form-layer" :class="{'is-active': toggle}" >
    <template v-if="custom">
      <button class="kb-form-dropdown-btn" :class="buttonClass" @click.stop="toggleOptions" :disabled="isDisabled">
        <span class="text">{{ renderText }}</span>
        <i v-if="options.length > 0" class="icon-arrow"></i>
      </button>
    </template>
    <template v-else>
      <button class="kb-form-layer-btn" :class="buttonClass" @click.stop="toggleOptions" :disabled="isDisabled">
        <strong class="text">{{ renderText }}</strong>
        <i v-if="options.length > 0" class="icon-arrow-bottom-black"></i>
      </button>
    </template>
    <div v-if="options.length > 0"  class="layer-container" :style="custom ? {'flex-direction':'column'} : {}">
      <div v-if="custom" class="kb-top-layer" style="width:100%;flex:1 1 auto;position:relative;overflow:hidden;" @click.stop.prevent="$emit('update:toggle',false)" />
      <div class="layer" :style="custom ? {'height':'auto'} : {}">
        <div class="kb-form-layer-options">
          <div class="layer-option">
            <h4>{{ renderText }}</h4>
            <ul class="layer-option-list">
              <li v-if="isAll" class="layer-option-item">
                <a href="javascript:" class="layer-option-link" :class="{'is-active': !modelValue}" @click.stop="selectOption()"><span class="layer-option-text">전체</span></a>
              </li>
              <li v-for="(option, index) in options" class="layer-option-item" :key="index">
                <a href="javascript:" class="layer-option-link" :class="{'is-active': modelValue === option[sequenceKey]}" @click.stop="selectOption(index)">
                  <span class="layer-option-text">{{ option[nameKey] }}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {computed} from 'vue';

export default {
  name: 'SortButtonSelect3',
  props: {
    modelValue: [Number, String],
    toggle: Boolean,
    isAll: Boolean,
    isNum: Boolean,
    options: Array,
    sequenceKey: String,
    nameKey: String,
    title: String,
    width: Number,
    isRow: Boolean,
    buttonClass: String,
    emptyText: String,
    custom: {type:Boolean,default:false},
    isDisabled:  {type:Boolean,default:false},
  },
  emits: ['update:modelValue', 'update:toggle', 'selected'],
  setup(props, {emit}){

    const renderText = computed(() => {
      // Option이 없을 경우 노출
      if(props.options.length === 0 && props.emptyText){
        return props.emptyText;
      }

      const target = props.options.find(x => {
        if (x[props.sequenceKey] === props.modelValue) return x;
      });
      if(target){
        return target[props.nameKey];
      }
      return props.title;
    });

    const widthStyle = computed(() => {
      if(props.width > 0){
        return {width: `${props.width}px`};
      }
      return {};
    });

    const toggleOptions = () => {
      emit('update:toggle', !props.toggle);
    }

    const selectOption = (index) => {
      // console.log('props : ',props);
      // console.log('selected : ',props.options[index]);
      // console.log('selected : ',props.options[index][props.sequenceKey]);
      if(index === undefined){
        emit('update:modelValue', props.isNum ? 0 : '');
      }else{
        emit('update:modelValue', props.options[index][props.sequenceKey]);
      }
      emit('update:toggle', false);
      emit('selected');
    }

    return {
      renderText,
      widthStyle,
      toggleOptions,
      selectOption,

    }
  }
}
</script>
