<template>
  <div v-if="msg.show" class="kb-alert-container" :class="{'is-active': msg.show}">
    <div class="kb-alert">
      <div class="kb-alert-contents">
        <template v-if="isMobile">
          <strong class="kb-alert-title" :class="{'text-center': msg.center}" v-html="msg.title ? msg.title : '&nbsp;'"></strong>
        </template>
        <template v-else>
          <strong v-if="msg.title" class="kb-alert-title" :class="{'text-center': msg.center}" v-html="msg.title"></strong>
        </template>
        <p v-if="msg.text" class="kb-alert-text"  :class="{'text-center': msg.center}" v-html="msg.text"></p>
      </div>
      <div class="kb-alert-actions">
        <button @click="okMessage" class="kb-btn-alert"><span class="text">확인</span></button>
      </div>
    </div>
  </div>
</template>
<script>
import {useStore} from 'vuex';
import {computed} from 'vue';
import {MUT_CLOSE_MESSAGE} from '@/store/modules/common/common-index';
import navigationUtils from '@/assets/js/navigationUtils';

export default {
  name: 'LxpMessage',
  props: {
    modelValue: {
      type: Boolean,
      required: false
    },
    options: {
      type: Object,
      required: false
    }
  },
  emits: ['update:modelValue'],
  setup(props, {emit}){
    const store = useStore();
    const msg = computed(() => {
      if (props.modelValue) return props.options;
      return store.state.common.msg;
    });

    const okMessage = () => {
      if(msg.value.callback instanceof Function) msg.value.callback();
      closeMessage();
    }

    const closeMessage = () => {
      if (props.modelValue) {
        emit('update:modelValue', false);
      } else {
        store.commit(`common/${MUT_CLOSE_MESSAGE}`);
      }
    };

    return {
      isMobile: navigationUtils.any(),
      msg,
      okMessage,
      closeMessage
    }
  }
}
</script>
<style scoped>
 div{ color:#151515}
</style>