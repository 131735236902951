<template>
  <LxpMobileHeader title="리더 변경">
  </LxpMobileHeader>
  <main class="kb-main" id="kb-prep-group">
    <!-- main-content -->
    <div class="main-content ">
      <div>
        <div class="member-search-container">
          <!-- search-result -->
          <div class="search-result">
            <section class="content-section section-leaders">
              <div class="section-content">
                <ul class="search-result-list">
                  <template v-for="(item, idx) in members" :key="idx">
                    <!-- search-result-item -->
                    <li v-if="item.grpRoleCdDcd !== '2010001'" class="search-result-item">
                      <label :for="`result${idx}`" class="search-result-target">
                        <span class="text">{{ item.deptNm }}</span>
                        <span class="text">{{ item.lrnerNm }}</span>
                        <span class="text">{{ item.lrnerId }}</span>
                        <span class="text">{{ item.jbgdCd }} {{ item.jbpsNm }}</span>
                      </label>
                      <div class="kb-form-check">
                        <input v-model="checkedUser" type="radio" :id="`result${idx}`" class="kb-form-check-input" name="chkMember" :value="item" />
                      </div>
                    </li>
                  </template>
                </ul>
                <div class="kb-form-buttons">
                  <button class="kb-btn kb-btn-primary" :disabled="!checkedUser.lrnerId" @click="clickChange"><span class="text">변경하기</span></button>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import LxpMobileHeader from "@/views/layout/lxp/header/mobile/LxpMobileHeader";
import {changeLeaderSetup} from "@/assets/js/modules/groupLearing/change-leader-setup";

export default {
  name: "MobileChangeLeaderModal",
  components: {LxpMobileHeader},
  props: {
    modelValue: Boolean,
    distCrseSn: String,
    members: Array
  },
  emits: ['update:modelValue'],
  setup: changeLeaderSetup
}
</script>
