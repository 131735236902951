<template>
  <HrdCloudMobileCloudMap v-if="showHrdCloudMap" v-model="showHrdCloudMap" />
  <HrdCloudMobileCategory v-if="showHrdCloudCate" v-model="showHrdCloudCate" />
  <HrdCloudHistoryMobileKbTube v-if="showKbTubeModal" v-model="showKbTubeModal" :init-upload-modal-active="true" />

  <main class="kb-main" id="kb-hrdcloud" v-if="!showKbTubeModal">
    <HrdCloudMobileHeader
        :title="renderTitle"
        :keyword="search.keyword"
        :show-search="true"
    >
      <template v-slot:right>
        <HrdCloudMobileSubMenu v-model="showSubMenu" v-model:showHrdCloudMap="showHrdCloudMap" v-model:showKbTubeModal="showKbTubeModal" v-model:tabMenu="tabMenu"/>
      </template>
    </HrdCloudMobileHeader>
    <template v-if="isSearching">
      <HrdCloudMobileSearchResult
          :keyword="search.keyword"
          :session="session"
          :hrds="hrds"
          :categories="categories"
          :categories-paging="categoriesPaging"
          :paging="paging"
          :paging-func="pagingFunc"
      />
    </template>
    <template v-else>
      <template v-if="tabMenu === 0">
        <HrdCloudMobileRoute
            v-model="tabMenu"
            :showRoute="showRoute"
            :func="handleMenuClick"
        />
        <HrdCloudMobileMainList
            :mainList="mainList"
        >
          <template v-slot:top>
            <HrdCloudBanner />
            <HrdCloudNotice />
            <HrdCloudMobileMy :session="session" :toggleModal="toggleModal" />
          </template>
        </HrdCloudMobileMainList>
      </template>
      <template v-else>
        <HrdCloudMobileCateSnb v-model="search" v-model:showHrdCloudCate="showHrdCloudCate" />

        <HrdCloudList
            :is-loading="isLoading"
            :session="session"
            :result="hrds"
            :show-put="true"
            :paging="paging"
            :paging-func="pagingFunc"
        >
          <template v-slot:top>
            <div class="list-top">
              <div class="top-column">
                <SortButtonSelect3
                    v-model="search.sortTyCdDcd"
                    v-model:toggle="toggles.sortTyCdDcd"
                    sequence-key="cd"
                    name-key="cdNm"
                    :options="sortTyCdDcd"
                    :is-num="false"
                    @selected="pagingFunc"
                />
              </div>
              <div class="top-column">
                <div class="list-breadcrumb">
                  <HrdCloudBreadcrumb v-if="search.category1" v-model:search="search" />
                </div>
              </div>
            </div>
          </template>
        </HrdCloudList>
      </template>
    </template>
    <MobileBanerPopupModal
        v-if="popups.length > 0 && !expirationPopup && !lochref.includes('?')"
        :items="popups"
    />
    <HrdCloudMobileSubscribeNotice v-if="subscribeNotiPopupModal" v-model="subscribeNotiPopupModal"/>
    <HrdCloudEventStatusPopup v-if="eventStatusPopup" v-model="eventStatusPopup"/>
  </main>
</template>

<script>
import HrdCloudMobileHeader from '@/components/hrdcloud/mobile/HrdCloudMobileHeader';
import {computed, onBeforeUnmount, onMounted, reactive, ref, watch} from 'vue';
import {useStore} from 'vuex';
import {
  getCateNm,
  getHrdCloudList,
  handleActions,
  mainCustomList,
  setMainItems,
  sortTyCdDcds,
} from '@/assets/js/modules/hrd/hrd-common';
import {
  ACT_GET_HRDCLOUD_LATEST_LIST,
  ACT_GET_HRDCLOUD_RECENT_LIST,
  ACT_GET_HRDCLOUD_RECENT_POPULAR_LIST,
  ACT_GET_HRDCLOUD_RECOMMEND_LIST,
} from '@/store/modules/prep/prep';
import {getDcdOptions, getValueByQuery, pushQuery, setFilterByQuery} from '@/assets/js/ui.init';
import {useRoute} from 'vue-router';
import {dateToDateFormat, getCheckItems} from '@/assets/js/util';
import {ACT_GET_HRDCLOUD_CATE_SEARCH_LIST} from '@/store/modules/hrdcloud/hrdcloud';
import HrdCloudMobileMainList from '@/components/hrdcloud/mobile/HrdCloudMobileMainList';
import HrdCloudBanner from '@/components/hrdcloud/HrdCloudBanner';
import HrdCloudMobileMy from '@/components/hrdcloud/mobile/HrdCloudMobileMy';
import HrdCloudMobileCloudMap from '@/views/pages/prep/mobile/HrdCloudMobileCloudMap';
import HrdCloudMobileSubMenu from '@/components/hrdcloud/mobile/HrdCloudMobileSubMenu';
import HrdCloudMobileRoute from '@/components/hrdcloud/mobile/HrdCloudMobileRoute';
import HrdCloudList from '@/components/hrdcloud/HrdCloudList';
import HrdCloudMobileCategory from '@/components/hrdcloud/mobile/HrdCloudMobileCategory';
import SortButtonSelect3 from '@/components/common/SortButtonSelect3';
import HrdCloudBreadcrumb from '@/components/hrdcloud/HrdCloudBreadcrumb';
import HrdCloudMobileCateSnb from '@/components/hrdcloud/mobile/HrdCloudMobileCateSnb';
import HrdCloudMobileSearchResult from '@/components/hrdcloud/mobile/HrdCloudMobileSearchResult';
import {banerDivCdDcds, banerExpsrLocCdDcds, getBanerList} from '@/assets/js/modules/baner/common-baner';
import MobileBanerPopupModal from '@/components/main/mobile/MobileBanerPopupModal';
import HrdCloudHistoryMobileKbTube from '@/components/history/mobile/HrdCloudHistoryMobileKbTube';
import HrdCloudMobileSubscribeNotice from "@/components/hrdcloud/mobile/HrdCloudMobileSubscribeNotice.vue";
import HrdCloudNotice from "@/components/hrdcloud/HrdCloudNotice";
import HrdCloudEventStatusPopup from "@/components/hrdcloud/HrdCloudEventStatusPopup";

export default {
  name: 'HrdCloudMobileMain',
  components: {
    HrdCloudMobileSubscribeNotice,
    HrdCloudMobileSearchResult,
    HrdCloudMobileCateSnb,
    HrdCloudBreadcrumb,
    SortButtonSelect3,
    // eslint-disable-next-line vue/no-unused-components
    HrdCloudMobileCategory,
    HrdCloudList,
    HrdCloudMobileRoute,
    HrdCloudMobileSubMenu,
    // eslint-disable-next-line vue/no-unused-components
    HrdCloudMobileCloudMap,
    HrdCloudMobileMy,
    HrdCloudBanner,
    HrdCloudMobileMainList,
    HrdCloudMobileHeader,
    MobileBanerPopupModal,
    HrdCloudHistoryMobileKbTube,
    HrdCloudNotice,
    HrdCloudEventStatusPopup,
  },
  setup() {
    const store = useStore(), route = useRoute();
    const session = computed(() => store.state.auth.session);
    const sortTyCdDcd = computed(() => getDcdOptions('2073'));
    const isSearching = computed(() => (route.query && route.query.keyword && route.query.keyword.length > 0));
    const isLoading = ref(true);
    const showRoute = ref(true),
        showSubMenu = ref(false),
        showHrdCloudMap = ref(false),
        showHrdCloudCate = ref(false),
        showKbTubeModal = ref(false),
        subscriptionModal = computed(() => store.state.hrdcloud.subscribed.show),
        addModal = computed(() => store.state.hrdcloud.added.show);
    const lochref = ref(location.href);

    const modalActive = computed(() => {
      if (subscriptionModal.value || addModal.value) return true;
      else return false;
    });

    const view = reactive({
      name: 'main',
      title: 'HRD 클라우드'
    });

    const mainList = ref(mainCustomList),
        hrds = ref([]),
        categories = ref([]),
        tabMenu = ref(0),
        paging = reactive({
          pageNo: 1,
          pageSize: 12,
          totalCount: 0
        }),
        categoriesPaging = reactive({
          pageNo: 1,
          pageSize: 9999,
          totalCount: 0
        }),
        search = reactive({
          keyword: '',
          sortTyCdDcd: '',
          category1: 0,
          category2: 0,
          category3: 0,
          category4: 0,
          category5: 0
        }),
        toggles = reactive({
          sortTyCdDcd: false
        });

    const getList = async (params) => {
      let contentsList = await getHrdCloudList({...search, ...paging, ...params});
      hrds.value = getCheckItems(contentsList);
      paging.totalCount = contentsList && contentsList.paging ? contentsList.paging.totalCount : 0;
      let categoryList = await store.dispatch(`hrdcloud/${ACT_GET_HRDCLOUD_CATE_SEARCH_LIST}`, {keyword: search.keyword, ...paging});
      categories.value = getCheckItems(categoryList);
      categoriesPaging.totalCount = categoryList && categoryList.paging ? categoryList.paging.totalCount : 0;
      isLoading.value = false;
    };

    // 지금 나에게 필요한 콘텐츠, 따끈따끈 신규 콘텐츠, 나의 최근 학습 콘텐츠, 최근 직무 분야 인기 콘텐츠, 최근 직무외 분야 인기 콘텐츠
    const getMainList = async () => {
      let recommend = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_RECOMMEND_LIST}`, {pageNo: 1, pageSize: 4});
      setMainItems(mainList, recommend, 0);
      let latest = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_LATEST_LIST}`, {pageNo: 1, pageSize: 4});
      setMainItems(mainList, latest, 1);
      let latestPopularDuty = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_RECENT_POPULAR_LIST}`, {category1: mainList.value[2].params.category1, pageNo: 1, pageSize: 4});
      setMainItems(mainList, latestPopularDuty, 2);
      let latestPopularKnowledge = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_RECENT_POPULAR_LIST}`, {category1: mainList.value[3].params.category1, pageNo: 1, pageSize: 4});
      setMainItems(mainList, latestPopularKnowledge, 3);
      let recent = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_RECENT_LIST}`, {pageNo: 1, pageSize: 4});
      setMainItems(mainList, recent, 4);
    };

    const toggleModal = {
      openSubscribe: () => {
        subscriptionModal.value = true;
      }
    };

    const debounce = ref(0);
    const timeout = ref(1500);

    let nowDate = new Date();
    const expirationPopup = computed(() => parseInt(localStorage.getItem('kb-popup-hrdcloud')) > nowDate);

    const handleFilterQuery = () => {
      if (route.name === 'HrdCloud' || route.name === 'HrdCloudRoute') {
        if (route.query) {
          if (route.query.keyword != null && route.query.keyword.length > 0) {
            isLoading.value = true;
            setFilterByQuery(search, {keyword: route.query.keyword});
            paging.pageNo = getValueByQuery(route.query, 'pageNo', true, 1);
            debounce.value = setTimeout(() => {
              if (debounce.value) clearTimeout(debounce.value);
              getList();
            },timeout.value);
          } else {
            isLoading.value = true;
            if (!route.query.sortTyCdDcd) {
              route.query.sortTyCdDcd = sortTyCdDcds.SORT_TY_CD_LATEST;
            }
            setFilterByQuery(search, route.query);
            if (route.query.category1 == 1) {
              tabMenu.value = 1;
            } else if (route.query.category1 == 2) {
              tabMenu.value = 2;
            } else if (route.query.category1 == 3) {
              tabMenu.value = 3;
            } else if (route.query.category1 == 4) {
              tabMenu.value = 4;
            } else if (route.query.category1 == 5) {
              tabMenu.value = 5;
            } else {
              tabMenu.value = 0;
            }
            if (tabMenu.value === 0) {
              debounce.value = setTimeout(() => {
                if (debounce.value) clearTimeout(debounce.value);
                getMainList();
              },timeout.value);
            } else {
              paging.pageNo = getValueByQuery(route.query, 'pageNo', true, 1);
              debounce.value = setTimeout(() => {
                if (debounce.value) clearTimeout(debounce.value);
                getList();
              },timeout.value);
            }
          }
        }
      }
    };

    const pagingFunc = (pageNo) => {
      paging.pageNo = pageNo;
      if(pageNo){
        pushQuery({...search, pageNo});
      }else{
        pushQuery({...search});
      }
    };

    const handleMenuClick = (tabMenu) => {
      hrds.value = [];
      // initParams(search);
      search.sortTyCdDcd = sortTyCdDcds.SORT_TY_CD_LATEST;
      if (tabMenu === 1) {
        search.category1 = 1;
      } else if (tabMenu === 2 ) {
        search.category1 = 2;
      } else if (tabMenu === 3 ) {
        search.category1 = 3;
      } else if (tabMenu === 4 ) {
        search.category1 = 4;
      } else if (tabMenu === 5) {
        search.category1 = 5;
      }
      pagingFunc(1);
    };

    const searchFunc = async () => {
      await getList();
    };

    const renderTitle = computed(() => {
      if (tabMenu.value > 0) return getCateNm(search.category1);
      else return view.title;
    });

    const prev = ref(0);

    // const scrollFunction = () => {
    //   showSubMenu.value=false;
    //   showHrdCloudCate.value=false;
    //   const curr = window.pageYOffset;
    //   if (prev.value >= curr) showRoute.value=true
    //   else showRoute.value=false;
    //   prev.value = curr;
    // };

    watch(() => route.query, () => {
      handleFilterQuery();
    });

    const subscribeNotiPopupModal = ref(true);
    const eventStatusPopup = ref(true);

    const checkEventFlag = ()=>{
      console.log("checkEventFlag", dateToDateFormat(new Date(), 'yyyyMMdd'), dateToDateFormat(new Date(), 'yyyyMMdd') <= '20231130')
      return dateToDateFormat(new Date(), 'yyyyMMdd') <= '20231130';
    }

    eventStatusPopup.value = checkEventFlag();

    watch(() => tabMenu.value, () => {
      subscribeNotiPopupModal.value = tabMenu.value === 0;
      eventStatusPopup.value = tabMenu.value === 0 ? checkEventFlag() : false;
    });

    onMounted(() => {
      prev.value = window.pageYOffset;
      // document.addEventListener('scroll',scrollFunction);
      handleFilterQuery();
    });

    onBeforeUnmount(() => {
      // document.removeEventListener('scroll', scrollFunction);
    });
    // localStorage.removeItem('kb-popup-main');

    const popups = ref([]);
    // 팝업
    getBanerList({banerDivCdDcd: banerDivCdDcds.popup, banerExpsrLocCdDcd: banerExpsrLocCdDcds.hrdCloud, pageNo: 1, pageSize: 10}, popups);

    return {
      isSearching, isLoading, view, session, search, mainList, tabMenu, toggles, hrds, paging, toggleModal, categories, lochref,
      modalActive, subscriptionModal, addModal,
      showSubMenu, showHrdCloudMap, showRoute, showHrdCloudCate, categoriesPaging,
      pagingFunc, handleActions, searchFunc, handleMenuClick,
      sortTyCdDcd, getCateNm, renderTitle,
      popups, expirationPopup,
      showKbTubeModal,
      subscribeNotiPopupModal,
      eventStatusPopup
    }
  }
};
</script>
