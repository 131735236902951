import ApiService from "@/assets/js/api.service";
// import {ACT_GET_LRN_SPRT_LCSN_LIST} from "@/store/modules/license/license";

// ACTION
export const ACT_GET_FCPL_LIST = "actGetFcplList";

const state = {};

const mutations = {};

const actions = {
  [ACT_GET_FCPL_LIST](context, params) {
    return new Promise((resolve) => {
      ApiService.query("/v1/app/fcpl", params).then((response) =>
        resolve(response)
      );
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
