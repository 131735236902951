<template>
  <div class="popup-container" :class="{ 'is-active' : modelValue }" @click="closeToggles(toggles)">
    <div class="popup" id="popup-tuition-support-p052">
      <div class="popup-inner">
        <div class="popup-header"><h3 class="title">자격증 등록/지원 신청</h3></div>
        <div class="popup-content">
          <div class="survey-container">
            <article class="popup-section section-form">
              <header class="section-header header-divider-v2">
                <h4 class="title">신청정보</h4>
              </header>
              <div class="section-content">
                <div class="kb-form-fields kb-form-fields-v2">
                  <Applicant />
                  <InputSearch v-if="!isReadOnly" title="자격증명" v-model="license" v-model:inputNm="license.lcsnNm" placeholder="자격증명 검색" :func="searchModalFunc" />
                  <RowInputText v-else title="자격증명" v-model="license.lcsnNm" :disabled="isReadOnly"/>
                  <RowInputText v-model="license.pblcnInstNm" title="발급처" placeholder="발급처를 입력하세요." :disabled="isReadOnly"/>
                  <RowDatepicker
                      title="자격증 응시일"
                      v-model="param.lcsnExamYmd"
                      v-model:toggle="toggles.lcsnExamYmd"
                      :disabled="isReadOnly"
                      @update:toggle="closeToggles(toggles, 'lcsnExamYmd')"
                  />
                  <RowDatepicker
                      title="합격자 발표일"
                      v-model="param.passAnnonYmd"
                      v-model:toggle="toggles.passAnnonYmd"
                      :disabled="isReadOnly"
                      @update:toggle="closeToggles(toggles, 'passAnnonYmd')"
                  />
                  <RowDatepicker
                      title="자격증 등록일"
                      v-model="param.lcsnRegYmd"
                      v-model:toggle="toggles.lcsnRegYmd"
                      :disabled="isReadOnly"
                      @update:toggle="closeToggles(toggles, 'lcsnRegYmd')"
                  />
                  <Upload v-model="licenseFiles"
                          title="자격증 사본"
                          placeholder="자격증 사본을 등록하세요."
                          btn-title="자격증 사본 등록하기"
                          sequence-key="proofDataSn"
                          :extensions="extensions"
                          :max-qty="10"
                          :disabled="isReadOnly"
                  />

                  <template v-if="formModes.isInvProtEdu">
                    <div class="kb-form-row">
                      <InputText
                          title="투자자보호교육비"
                          v-model="param.invProtEduCost"
                          placeholder="교육비를 입력해주세요"
                          :class-lst="invProtEduDivs && invProtEduDivs.length > 0 ? 'kb-form-divider' : ''"
                          :is-number="true"
                          :is-money="true"
                          :disabled="isReadOnly"
                      />
                      <div v-if="invProtEduDivs && invProtEduDivs.length > 0" class="kb-form-column kb-form-dropdown-group">
                        <div class="kb-form-dropdown" :class="{'is-active': toggles.invProtEduDivs}">
                          <button class="kb-form-dropdown-btn" @click.stop="toggles.invProtEduDivs=!toggles.invProtEduDivs">
                            <span class="text">{{ invProtEduDivNm }}</span><i class="icon-arrow"></i>
                          </button>
                          <div v-if="!isReadOnly" class="kb-form-dropdown-options">
                            <div class="dropdown-option">
                              <ul class="dropdown-option-list">
                                <li v-for="(item, idx) in invProtEduDivs" class="dropdown-option-item" :key="idx">
                                  <a href="javascript:" class="dropdown-option-link" @click.stop="selectInvProtEduDivs(idx)">
                                    <span class="dropdown-option-text">{{ item.nm }}</span></a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="kb-form-column column-last">
                        <span class="subtext">금액 수정 가능</span>
                      </div>
                    </div>
                    <Upload v-model="certFiles"
                            title="투자자보호교육수료증"
                            placeholder="투자자보호교육수료증을 등록하세요."
                            btn-title="투자자보호교육수료증 등록하기"
                            sequence-key="proofDataSn"
                            :extensions="extensions"
                            :max-qty="10"
                            :is-payment="true"
                            :disabled="isReadOnly"
                    />
                  </template>

                  <!-- kb-form-row:학원비 TODO:등급자격증만 -->
                  <template v-if="formModes.isTrnAid && license.grd1Cd != null">
                    <RowInputText v-model="param.acdmFee" :isNumber="true" :is-money="true" title="학원비" placeholder="학원비를 입력하세요." :disabled="isReadOnly" />
                    <Upload v-model="acadRegCert"
                            title="학원비 영수증"
                            placeholder="학원비 영수증을 등록하세요."
                            btn-title="수강증 및 학원비 영수증 등록"
                            sequence-key="proofDataSn"
                            :extensions="extensions"
                            :max-qty="10"
                            :disabled="isReadOnly"
                    />
                  </template>

                  <template v-if="formModes.isExamFeeSprt">
                    <RowInputText v-model="param.examFee" :isNumber="true" :is-money="true" title="응시료" placeholder="응시료 입력하세요." :disabled="isReadOnly" />
                    <Upload v-model="examFeeFiles"
                            title="응시료 영수증"
                            placeholder="영수증을 등록하세요."
                            btn-title="영수증 등록"
                            :extensions="extensions"
                            :max-qty="10"
                            :disabled="isReadOnly"
                            sequence-key="proofDataSn"
                    />
                  </template>

                  <template v-if="formModes.isBookCostSprt">
                    <RowInputText v-model="param.bookCost" :isNumber="true" :is-money="true" title="교재비" placeholder="교재비를 입력하세요." :disabled="isReadOnly" />
                    <Upload v-model="receiptFiles"
                            title="교재비 영수증"
                            placeholder="영수증을 등록하세요."
                            btn-title="도서주문 내용 및 영수증 등록"
                            :extensions="extensions"
                            :max-qty="10"
                            sequence-key="proofDataSn"
                            :disabled="isReadOnly"
                    />
                  </template>

                  <template v-if="formModes.isInvProtEdu || formModes.isExamFeeSprt || formModes.isBookCostSprt || formModes.isTrnAid">
                    <div class="kb-form-row kb-form-total">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <strong class="kb-form-label-text">합계금액(실소요비)</strong>
                        </label>
                      </div>
                      <div class="kb-form-column">
                        <span class="kb-form-text">{{ numberComma(param.totAmt) }}</span>
                      </div>
                    </div>
                    <div class="kb-form-row kb-form-total" v-if="license.grd1Cd != null">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <strong class="kb-form-label-text">지원예정금액</strong>
                        </label>
                      </div>
                      <div class="kb-form-column">
                        <span class="kb-form-text text-red font-body16B">{{ numberComma(expAmt) }}</span>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </article>

            <article class="popup-section section-agree" v-if="param.rtnYn=='Y' || param.addAmtYn=='Y'">
              <header class="section-header header-divider-v2">
                <h4 class="title">환입 및 추가지원금액</h4>
              </header>
              <div class="section-content">
                <div class="kb-form-fields kb-form-fields-v2">
                  <RowInputText v-model="param.rtnAmt" title="환입금액"  :is-number="true" :is-money="true" :disabled="true" v-if="param.rtnYn=='Y'" />
                  <RowInputText v-model="param.addAmt" title="추가지원금액"  :is-number="true" :is-money="true" :disabled="true" v-if="param.addAmtYn=='Y'" />
                </div>
              </div>
            </article>

            <article v-if="data == null" class="popup-section section-agree">
              <UserAgreement
                  ref="usrAgrmntRef"
                  v-model:user-agreement="userAgreement"
                  @applyBtnEnable="applyBtnEnable"
              />
            </article>
<!--            <article v-if="data == null" class="popup-section section-agree">-->
<!--              <header class="section-header header-divider-v2">-->
<!--                <h4 class="title">증빙서류 제출시 유의사항</h4>-->
<!--              </header>-->
<!--              <div class="section-content agree-content">-->
<!--                <div class="segment-box">-->
<!--                  <p class="text">◉학원비</p>-->
<!--                  <p class="text">학원비 결제관련 본인 KB신용/체크카드(선불카드 제외)매출전표 + 학원수강사실 확인가능 서류</p>-->
<!--                  <p class="text">&nbsp;</p>-->
<!--                  <p class="text">◉응시료</p>-->
<!--                  <p class="text">응시료 결제관련 본인 KB신용/체크카드(선불카드 제외)매출전표</p>-->
<!--                  <p class="text">&nbsp;</p>-->
<!--                  <p class="text">◉교재비</p>-->
<!--                  <p class="text">교재비 결제관련 본인 KB신용/체크카드(선불카드 제외)매출전표 + 자격증 연관 도서명 확인가능 서류 (주문내역서 등)</p>-->
<!--                  <p class="text">&nbsp;</p>-->
<!--                  <p class="text">◉등급자격증의 경우, 금액증빙서류 미제출 시 등급별 지원한도의 50% 지원 (지원 신청금액 입력 불필요)</p>-->
<!--                  <p class="text">&nbsp;</p>-->
<!--                  <p class="text">◉자격증 취득비용은 본인 KB신용/체크카드(선불카드 제외) 결제 매출전표에 한하여 실비지원</p>-->
<!--                  <p class="text">&nbsp;</p>-->
<!--                  <p class="text">*기타 결제방법에 의한 증빙은 불인정. 단, 사학재단 등과 같이 신용카드 가맹점 가입이 되어있지 않은 경우에 한하여 무통장 입금증 및 관인이 날인된 영수증 대체 가능</p>-->
<!--                  <p class="text">&nbsp;</p>-->
<!--                  <p class="text">※자격증 취득∙갱신비용 지원은 직원들의 자기개발을 효과적으로 지원하기 위하여 실시하는 제도로서, 지원신청과 관련하여 허위 정보 등록 또는 부당지원으로 판명될 경우,’지원신청 오류 사유서 작성, 기 지원 연수비용 환입, 향후 지원 대상 제한, 각종 연수참가 제한 등‘ 단계별 조치사항을 실시하고 있습니다.</p>-->
<!--                  <p class="text">&nbsp;</p>-->
<!--                  <p class="text">※시스템 등재(연수/인사시스템)및 지원 비용지급은 신청일 다음달 15일에 일괄처리됩니다.</p>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="agree-check">-->
<!--                <div class="kb-form-check">-->
<!--                  <input type="checkbox" v-model="userAgreement" class="kb-form-check-input" id="chk_agree_01" @click="userAgreement=!userAgreement"/>-->
<!--                  <label class="kb-form-check-label" for="chk_agree_01"><span class="text">동의완료</span></label>-->
<!--                </div>-->
<!--              </div>-->
<!--            </article>-->
          </div>
        </div>
        <SupportPopupButton
            :stt="data && data.stt"
            :aply-stt-cd-dcd="data && data.aplySttCdDcd"
            :apply-btn-enable="applyBtnEnable"
            :user-agreement="userAgreement"
            :apply="apply"
            :cancel="cancel"
            :close-modal="closeModal"
        />
      </div>
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
  </div>

  <SupportSearchLicenseModal
      v-if="showSearchModal"
      v-model="showSearchModal"
      v-model:selected-value="license"
      @update:modelValue="changeLicense"
  />
  <LxpConfirm />
</template>

<script>
import LxpConfirm from '@/components/common/LxpConfirm';
import SupportSearchLicenseModal from '@/components/support/SupportSearchLicenseModal';
import Applicant from '@/components/support/common/Applicant';
import InputSearch from '@/components/support/common/InputSearch';
import RowDatepicker from '@/components/support/common/RowDatepicker';
import RowInputText from '@/components/support/common/RowInputText';
import Upload from '@/components/support/common/Upload';
import InputText from '@/components/support/common/InputText';
import {supportLicenseSetup} from '@/assets/js/modules/support/support-license-setup';
import SupportPopupButton from '@/components/support/common/SupportPopupButton';
import UserAgreement from "@/components/support/common/UserAgreement";

export default {
  name: 'SupportLicenseFeeModal',
  components: {
    UserAgreement,
    SupportPopupButton,
    InputText,
    Upload, RowInputText, RowDatepicker, InputSearch, Applicant, SupportSearchLicenseModal, LxpConfirm},
  props: {
    modelValue: Boolean,
    data: { type: Object, default: null },
    reList : Function,
    jncmpYmd:String
  },
  setup: supportLicenseSetup
};
</script>
