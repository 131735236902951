<template>
    <li class="map-item" :class="{'is-active': item.toggle}">
        <div class="item-box">
            <span class="title text-skip" :title="item.hrdCloudCateNm" @click.prevent.stop="searchCurrentCate">{{ item.hrdCloudCateNm }}</span>
            <div v-if="item.children && item.children.length > 0" class="box-column" @click.prevent.stop="toggleCurrentNode">
                <i class="icon-mark"></i>
            </div>
        </div>

        <ul v-if="item.children && item.children.length > 0" class="map-list nested">
            <HrdCloudMapTreeNode
                v-for="(item, index) in item.children"
                :key="index"
                :current-idx="index"
                :item="item"
                @toggleNode="toggleNode"
                @searchCate="searchCate"
            />
        </ul>
    </li>
</template>

<script>

export default {
  name: 'HrdCloudMapTreeNode',
  components: {},
  props: {
    currentIdx: Number,
    item: Object
  },
  emits: ['toggleNode', 'searchCate'],
  setup(props, {emit}) {

    const toggleCurrentNode = () => {
        emit('toggleNode', [props.currentIdx]);
    }

    const toggleNode = (arr) => {
        emit('toggleNode', [props.currentIdx, ...arr]);
    }

    const searchCurrentCate = () => {
      emit('searchCate', [props.item.hrdCloudCateSn]);
    }

    const searchCate = (arr) => {
      emit('searchCate', [props.item.hrdCloudCateSn, ...arr]);
    }

    return {
      toggleCurrentNode,
      toggleNode,
      searchCate,
      searchCurrentCate,
    }
  }
};
</script>
