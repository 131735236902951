<template>
  <article class="popup-section-group">
    <div class="history-table">
      <div class="kb-table-striped">
        <table>
          <colgroup>
            <col>
            <col>
            <col>
            <col>
          </colgroup>
          <thead>
          <tr>
            <th><span class="th-title">회차</span></th>
            <th><span class="th-title">시작일시</span></th>
            <th><span class="th-title">종료일시</span></th>
            <th><span class="th-title">참여인원</span></th>
            <th><span class="th-title">참가자명단</span></th>
            <th><span class="th-title">성적게시여부</span></th>
            <th><span class="th-title">상세보기</span></th>
          </tr>
          </thead>
          <tbody>
          <template v-if="scheduleList.length > 0">
            <tr v-for="(item, index) in scheduleList" :key="index">
              <th><strong class="th-title">{{item.seq}}</strong></th>
              <td><span class="td-text">{{ymdStrToFormat(item.startYmdh.substr(0, 8), '.')}} {{item.startYmdh.substr(-2)}}시</span></td>
              <td><span class="td-text">{{ymdStrToFormat(item.endYmdh.substr(0, 8), '.')}} {{item.endYmdh.substr(-2)}}시</span></td>
              <td><span class="td-text">{{item.atdCnt}}</span></td>
              <td><span class="td-text"></span></td>
              <td><span class="td-text">{{item.scoreYn}}</span></td>
              <td><div class="d-inline-flex">
                <button class="kb-btn kb-btn-sm kb-btn-outline" @click="openScheduleDtl(item.schdlSn)"><strong class="text">상세</strong></button>
              </div></td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="6"><span class="td-text text-muted">검색 결과가 없습니다.</span></td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
    </div>
  </article>
</template>

<script>

import {reactive, ref} from 'vue';
import {useStore} from "vuex";
import {ACT_GET_SCHEDULE_LIST} from "@/store/modules/otherdept/wmportfolio";
import {getItems, lengthCheck} from "@/assets/js/util";
import {getTodayDate, ymdStrToFormat} from '@/assets/js/util';

export default {
  name: "ScheduleList",
  props:{
    modelValue: Boolean,
    func: Function,
  },
  emits: ['update:modelValue', 'setSchdlSn'],
  setup(props, {emit}) {
    const store = useStore();

    const params = reactive({
      year: getTodayDate('yyyy'),
      seq: 0,
      startYmd: getTodayDate('yyyy-mm-dd'),
      startH: '00',
      endYmd: getTodayDate('yyyy-mm-dd'),
      endH: '23',
      scoreYn: 'N'
    });

    const scheduleList = ref([]);

    const getscheduleList = () => {
      store.dispatch(`wmportfolio/${ACT_GET_SCHEDULE_LIST}`, {}).then(res => {
        if (lengthCheck(res)) {
          scheduleList.value = getItems(res);
        }else{
          scheduleList.value = [];
        }
      }).catch(e => {
        console.error(e);
      });
    };
    getscheduleList();

    const openScheduleDtl = (sn) =>{
      console.log('sn : '+sn);
      emit('setSchdlSn',sn);
      props.func('reg');
    }
    return {
      params, scheduleList, ymdStrToFormat, openScheduleDtl
    };
  }
}
</script>