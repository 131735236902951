<template>
  <v-lazy-image v-if="imgSrc" :src="imgSrc" @error="setDefaultImg" @click="clickImage"/>
  <img v-else :src="setEmptyImg()" alt="" @click="clickImage">
</template>

<script>
import VLazyImage from 'v-lazy-image';

export default {
  name: 'CommonTypeImage',
  components: {VLazyImage},
  props: {
    sequence: {
      require: false,
      default: 0
    },
    imgSrc: String,
    theme: String,
    target: Object
  },
  emits: ['clickImage'],
  setup(props, {emit}) {
    // const rand = ref(Math.floor(Math.random() * 6) + 1);
    const setEmptyImg = () => {
      if (props.theme === 'profile') {
        return require(`@/assets/images/profile/sample.png`);
      }else if (props.theme === 'live') {
        return require(`@/assets/lxp/images/ssl/myssl/bg_learninglive_0${(props.sequence%4 + 1)}.jpg`);
      }else if (props.theme === 'ssl') {
        return require(`@/assets/lxp/images/ssl/common/thumb_deem.png`);
      }
      // return require(`@/assets/lxp/images/@tmp/@tmp_course_thumbnail_06.jpg`);
      return require(`@/assets/lxp/images/@tmp/@tmp_hrdcloud_02_03.jpg`);
    };

    const setDefaultImg = (e) => {
      e.src = setEmptyImg();
    };

    const clickImage = () => {
      emit('clickImage', props.target);
    }

    return {
      setDefaultImg,
      setEmptyImg,
      clickImage
    }
  }
};
</script>
