import ApiService from '@/assets/js/api.service';

export const ACT_GET_LCSN_MST_LIST = 'getLcsnMstList';
export const ACT_GET_LRN_SPRT_LCSN_LIST = 'getLrnSprtLcsnList';
export const ACT_GET_LRN_LCSN_LIST = 'getLrnLcsnList';
export const ACT_GET_LCSN_GOAL_LIST = 'getLcsnGoalList';

const state = {};

const mutations = {};

const actions = {
  [ACT_GET_LCSN_MST_LIST](context, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/lcsns', params).then(response => resolve(response));
    });
  },
  [ACT_GET_LRN_SPRT_LCSN_LIST](context, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/lrns/lcsns', params).then(response => resolve(response));
    });
  },
  [ACT_GET_LRN_LCSN_LIST](context, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/lcsn/lcsns', params).then(response => resolve(response));
    });
  },
  [ACT_GET_LCSN_GOAL_LIST](context, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/lcsns/goals', params).then(response => resolve(response));
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
