
<template>
  <main class="kb-main" id="kb-support">
    <!-- main-content -->
    <div class="main-content main-component">
      <div class="support-view-container">
        <div class="view-contents">
          <!-- content-section -->
          <section class="content-section">
            <header class="section-header">
              <h4 class="title">신청정보 {{param.costLcsnSn}}</h4>
            </header>
            <div class="section-content">
              <MobileApplicant :col-type="true"/>
              <div class="content-item">
                <div class="kb-form-fields">
                  <!-- kb-form-item:자격증명 -->
                  <div class="kb-form-item">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <span class="kb-form-label-text">자격증명</span>
                      </label>
                    </div>
                    <div class="kb-form-column column-whole">
                      <input v-model="param.lcsnNm" type="text" class="kb-form-control"/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="content-item">
                <div class="kb-form-fields">
                  <!-- kb-form-item:발급처 -->
                  <div class="kb-form-item">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <span class="kb-form-label-text">발급처</span>
                      </label>
                    </div>
                    <div class="kb-form-column column-whole">
                      <input v-model="param.pblcnInstNm" type="text" class="kb-form-control" placeholder="발급처를 입력하세요">
                    </div>
                  </div>
                </div>
              </div>
              <div class="content-item">
                <div class="kb-form-fields">
                  <!-- kb-form-item:합격자 발표일 -->
                  <div class="kb-form-item">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <span class="kb-form-label-text">합격자 발표일</span>
                      </label>
                    </div>
                    <div class="kb-form-text">
                      {{param.passAnnonYmd}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="content-item">
                <div class="kb-form-fields">
                  <RowDatepicker
                      title="주관처 갱신 신청일자"
                      v-model="param.updtAplyYmd"
                      v-model:toggle="toggles.updtAplyYmd"
                      subtext="카드영수증의 결제일자 또는 계좌이체 영수증의 이체일자"
                      :show-subtext="true"
                      custom-class="license"
                      @update:toggle="closeToggles(toggles, 'updtAplyYmd')"
                  />
                </div>
              </div>
              <div class="content-item">
                <div class="kb-form-fields">
                  <RowDatepicker
                      title="갱신된 유효기간"
                      v-model="param.updtVldYmd"
                      v-model:toggle="toggles.updtVldYmd"
                      :show-subtext="true"
                      subtext="주관처에서 자격갱신심사 후 발급된 자격증명사 상의 유효 기일"
                      custom-class="license"
                      @update:toggle="closeToggles(toggles, 'updtVldYmd')"
                  />
                </div>
              </div>
              <div class="content-item">
                <div class="kb-form-fields">
                  <!-- kb-form-item:갱신비용 -->
                  <div class="kb-form-item">
                    <MobileRowInputText v-model="param.updtCost" title="갱신비용" placeholder="갱신비용을 입력해주세요" :is-number="true" :is-money="true"></MobileRowInputText>
                  </div>
                </div>
              </div>
              <div class="content-item">
                <div class="kb-form-fields">
                  <!-- kb-form-item:인증번호 -->
                  <div class="kb-form-item">
                    <MobileRowInputText v-model="param.certNo" title="인증번호" placeholder="인증번호를 입력해주세요" />
                  </div>
                </div>
              </div>
              <div class="content-item">
                <MobileUpload
                    v-model="certFiles"
                    title="갱신증명서 사본"
                    placeholder="갱신증명서를 등록해주세요"
                    btn-title="갱신증명서 등록하기"
                    sequence-key="proofDataSn"
                    subtext="*갱신자격증, 청구서, 수료증 등 포함. 유효기간 등 일자 표시"
                    :extensions="extensions"
                    :max-qty="10"
                    :is-payment="true"

                />
              </div>
              <div class="content-item">
                <MobileUpload
                    v-model="costProofFiles"
                    title="납부증빙서류"
                    placeholder="납부증빙서류를 등록해주세요"
                    btn-title="납부증빙서류 등록하기"
                    sequence-key="proofDataSn"
                    subtext="*갱신자격증, 청구서, 수료증 등 포함. 유효기간 등 일자 표시"
                    :extensions="extensions"
                    :max-qty="10"
                    :is-payment="true"

                />
              </div>
            </div>
          </section>

          <section class="content-section" v-if="param.rtnYn=='Y' || param.addAmtYn=='Y'">
            <header class="section-header header-divider">
              <h4 class="title">환입 및 추가지원금액</h4>
            </header>
            <div class="section-content">
              <div class="kb-form-fields kb-form-fields-v2" v-if="param.rtnYn=='Y'">
                <div class="kb-form-row">
                  <label class="kb-form-label">
                    <span class="kb-form-label-text">환입금액</span>
                  </label>
                </div>
                <div class="kb-form-row">
                  <input type="text" class="kb-form-control"  :value="numberComma(param.rtnAmt)" readonly>
                  <div class="kb-form-side">
                    <span class="kb-form-text">원</span>
                  </div>
                </div>
              </div>
              <div class="kb-form-fields kb-form-fields-v2" v-if="param.addAmtYn=='Y'">
                <div class="kb-form-row">
                  <label class="kb-form-label">
                    <span class="kb-form-label-text">추가지원금액</span>
                  </label>
                </div>
                <div class="kb-form-row">
                  <input type="text" class="kb-form-control" :value="numberComma(param.addAmt)" readonly>
                  <div class="kb-form-side">
                    <span class="kb-form-text">원</span>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <!-- content-section -->
          <section class="content-section" v-if="param.costLcsnSn == '0' ||param.costLcsnSn==null">
            <UserAgreementMobile
                ref="usrAgrmntRef"
                v-model:user-agreement="userAgreement"
                @applyBtnEnable="applyBtnEnable"
            />
          </section>
<!--          <section class="content-section">-->
<!--            <header class="section-header">-->
<!--              <h4 class="title">유의사항</h4>-->
<!--            </header>-->
<!--            <div class="section-content">-->
<!--              <div class="content-item">-->
<!--                <div class="desc">-->
<!--                  <p>갱신비용 지급일 - 신청일 기준 익월 15일 경 급여계좌 입금-->
<!--                    <br><br>-->
<!--                    연회비, 연체료, 계속학점취득비는 지원대상 아님-->
<!--                    부정신청시 환입조치는 물론 향후 자기주도학습비 지원 제한, 인사상 불이익이 따를 수 있으므로 유의하시기 바랍니다.-->
<!--                  </p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="content-item">-->
<!--              <label for="chk_agree_03" class="item-agree">-->
<!--                상기내용을 숙지하였으며 동의합니다.-->
<!--                <div class="kb-form-check">-->
<!--                  <input v-model="userAgreement" type="checkbox" class="kb-form-check-input" id="chk_agree_03" @click="userAgreement=!userAgreement" :value="true"/>-->
<!--                </div>-->
<!--              </label>-->
<!--            </div>-->
<!--          </section>-->
        </div>
        <MobileSupportButton
            :stt="data && data.stt"
            :apply-btn-enable="applyBtnEnable"
            :user-agreement="userAgreement"
            :apply="apply"
            :cancel="cancel"
            :close-modal="closeModal"
            type="renewal"
        />
      </div>
    </div>
    <!-- //main-content -->
  </main>
</template>

<script>
import RowDatepicker from '@/components/support/common/RowDatepicker';
import MobileApplicant from "@/components/support/mobile/MobileApplicant";
import MobileUpload from "@/components/support/common/MobileUpload";
import {supportLicenseRenewSetup} from "@/assets/js/modules/support/support-license-renew-setup";
import MobileRowInputText from "@/components/support/common/mobile/MobileRowInputText";
import MobileSupportButton from "@/components/support/common/mobile/MobileSupportButton";
import UserAgreementMobile from "@/components/support/common/mobile/UserAgreementMobile";

export default {
  name: 'SupportLicenseRenewMobileModal',
  components: {MobileSupportButton,MobileRowInputText, MobileUpload, MobileApplicant, RowDatepicker, UserAgreementMobile},
  props: {
    modelValue: Boolean,
    data: Object,
    reList : Function,
    mode: String
  },
  setup: supportLicenseRenewSetup
};
</script>