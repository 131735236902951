import store from '@/store';
import router from '@/router';

import {
    MUT_REMOVE_SESSION,
    MUT_SET_EXPTIME,
    TOKEN_KEY,
} from '@/store/modules/auth/auth';
import {destroyToken} from '@/assets/js/localstorage.service';
import {MUT_SET_MENUS} from '@/store/modules/menu/menu';
import {
    MUT_INIT_MY_BADGE,
    MUT_REMOVE_STATICS,
} from '@/store/modules/ssl/ssl-index';
import {isInApp} from '@/assets/js/navigationUtils';
import {nativeLoginInfoClear} from '@/assets/js/nativeAppUtils';
import {MUT_SET_RETURN_QUERY} from '@/store/modules/common/common-index';
import {MUT_INIT_MY_HRD_BADGE} from '@/store/modules/hrdcloud/hrdcloud';

export const isLocal = location.href.indexOf('localhost') > -1
    || location.href.indexOf('127.0.0.1') > -1
    || location.href.indexOf('gs-entt-') > -1
    || location.href.indexOf('lxp.kbstar.com') > -1
    || location.href.indexOf('192.168.10.6') > -1



const getQueryStringObject = () => {
        var a = window.location.search.substr(1).split('&');
        if (a == "") return {};
        var b = {};
        for (var i = 0; i < a.length; ++i) {
            var p = a[i].split('=', 2);
            if (p.length == 1)
                b[p[0]] = "";
            else
                b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
        }
        return b;
    }

const removeSession = () => {
    destroyToken(TOKEN_KEY);
    store.commit(`auth/${MUT_REMOVE_SESSION}`);
    store.commit(`auth/${MUT_SET_EXPTIME}`, 0);
    store.commit(`menu/${MUT_SET_MENUS}`, []);
    // SSL 처리
    store.commit(`ssl/${MUT_REMOVE_STATICS}`);
    store.commit(`ssl/${MUT_INIT_MY_BADGE}`);
    store.commit(`hrdcloud/${MUT_INIT_MY_HRD_BADGE}`);
    if(isInApp){nativeLoginInfoClear();}
}

const savePath = pathName => {
    return !pathName.includes('login') && !pathName.includes('signin') && !pathName.includes('sessionExpired');
}

export const goToSignin = (next) => {
    removeSession();
    const url = {name: 'Signin'};
    const pathName = window.location.pathname.replace('/app', '');
    const queryStr = window.location.search ? JSON.stringify(getQueryStringObject(window.location.search)) : '';

    if (window.location.pathname.indexOf('app') > -1 && pathName.length > 0) {
        const isLogout = store.state.auth.isLogout
        if(savePath(pathName)){
            if(!isLogout){
                url.query = {'redirect': pathName};
                if (queryStr) url.query['q'] = window.encodeURIComponent(queryStr);
            }
        }
    }

    if(next !== undefined){
        next(url);
    } else {
        router.push(url).then(() => {});
    }
}


export const goToExpired = next => {
    removeSession();
    // const url = {name: 'Signin'};
    const pathName = window.location.pathname.replace('/app', '');
    const queryStr = window.location.search ? JSON.stringify(getQueryStringObject(window.location.search)) : '';
    //
    if (window.location.pathname.indexOf('app') > -1 && pathName.length > 0) {
        if(savePath(pathName)){
            store.commit(`common/${MUT_SET_RETURN_QUERY}`, {'redirect': pathName , 'q' : window.encodeURIComponent(queryStr)});
        }
    }

    if(next !== undefined){
        next({name: 'SessionExpired'});
    } else {
        router.push({name: 'SessionExpired'}).then(() => {});
    }
}
