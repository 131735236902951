<template>
  <div class="sbadge text-center">
    <template v-if="showTooltip">
      <Popper
        :content="badgeData.badgeDesc"
        class="badge-tooltip-dark"
        hover
      >
        <div>
          <div class="sbadge-icon">
            <span v-if="badgeData.isNew" class="badge-new"></span>
            <HrdCloudBadge
                :badge-ty-cd-dcd="badgeData.badgeTyCdDcd"
                :is-active="badgeData.isActive"
                :hidden-yn="badgeData.hiddenYn"
            />
          </div>
          <div v-if="showTitle" class="sbadge-content">
            <strong class="title text-skip" :title="badgeData.badgeName">{{ badgeData.badgeName }}</strong>
          </div>
        </div>
      </Popper>
    </template>
    <template v-else>
      <div class="sbadge-icon">
        <span v-if="badgeData.isNew" class="badge-new"></span>
        <HrdCloudBadge
            :badge-ty-cd-dcd="badgeData.badgeTyCdDcd"
            :is-active="badgeData.isActive"
            :hidden-yn="badgeData.hiddenYn"
        />
      </div>
      <div v-if="showTitle" class="sbadge-content">
        <strong class="title text-skip" :title="badgeData.badgeName">{{ badgeData.badgeName }}</strong>
      </div>
    </template>
  </div>
</template>
<script>
import Popper from 'vue3-popper';
import HrdCloudBadge from '@/components/history/hrdcloud/HrdCloudBadge.vue';

export default {
  name: 'MobileBadgeItem',
  components:{
    HrdCloudBadge,
    Popper
  },
  props: {
    showTitle: Boolean,
    showTooltip: Boolean,
    badgeData: Object
  },
  setup() {
    return {

    }
  }
}
</script>
