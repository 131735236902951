<template>
  <div v-if="show" class="popup-container" :class="{ 'is-active' : show }">
    <div class="popup" id="popup-hrdcloud-g04">
      <div class="popup-inner">
        <header class="popup-header">
          <h3 class="title">담은 콘텐츠{{ page === 'grouplearning' ? '(그룹러닝)' : '' }}</h3>
        </header>
        <LoadingDiv v-if="isLoading" />
        <template v-else-if="!isLoading">
          <div v-if="items && items.length > 0" class="popup-content">
            <HrdCloudRow
                :data-grid="3"
                :session="session"
                :items="items"
                :paging="paging"
                :paging-func="pagingFunc"
                :is-editable="isEditMode"
                :edit-func="selectKeepToBeDeleted"
            >
              <template v-slot:action>
                <div class="hrdcloud-actions-sub">
                  <template v-if="!isEditMode">
                    <button type="button" class="kb-btn kb-btn-secondary kb-btn-sm rounded-lg" @click="isEditMode=!isEditMode">
                      <i class="icon-edit"></i>
                      <span class="text">편집하기</span>
                    </button>
                  </template>
                  <template v-else>
                    <button type="button" class="kb-btn kb-btn-outline rounded-lg" @click="isEditMode=false">
                      <span class="text">취소</span>
                    </button>
                    <button type="button" class="kb-btn kb-btn-outline rounded-lg" :disabled="!hasUnKeepList" @click="removeAddedCtt">
                      <span class="text">삭제</span>
                    </button>
                    <button v-if="!checkAll" type="button" class="kb-btn kb-btn-outline rounded-lg" @click="clickCheckAll"><span class="text">전체선택</span></button>
                    <button v-else type="button" class="kb-btn kb-btn-outline rounded-lg" @click="clickUnCheckAll"><span class="text">전체해제</span></button>
                  </template>
                </div>
              </template>
            </HrdCloudRow>
          </div>
          <div v-else class="search-container">
            <div class="result-empty">
              <img src="../../../assets/lxp/images/common/img_empty.png" alt="">
              <p class="text">담은 콘텐츠가 없습니다.</p>
            </div>
          </div>

        </template>

      </div>
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
  </div>
  <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>
</template>

<script>
import HrdCloudRow from '@/components/hrdcloud/HrdCloudRow';
import {hrdAddSetup} from '@/assets/js/modules/hrd/hrd-add-setup';
import LoadingDiv from '@/components/common/LoadingDiv.vue';
import CommonPaginationFront from "@/components/CommonPaginationFront";

export default {
  name: 'Add',
  components: {LoadingDiv, HrdCloudRow,CommonPaginationFront},
  setup: hrdAddSetup
};
</script>