import {useStore} from 'vuex';
import {useAlert} from '@/assets/js/modules/common/alert';
import {computed, reactive, ref, watch} from 'vue';
import {
  dateToDatePart,
  getItems,
  isSuccess,
  lengthCheck,
} from '@/assets/js/util';
import {
  ACT_GET_HRDCLOUD_HISTORY_ADD_LIST,
} from '@/store/modules/history/history';
import {actTyCdDcds} from '@/assets/js/modules/common/common';
import {
  deleteCrseActLrnerSumup,
} from '@/assets/js/modules/hrd/hrd-history-common';
import {
  closeAdded,
  darkMode,
  getGroupLeader,
  lightMode,
} from '@/assets/js/modules/hrd/hrd-common';

export const hrdAddSetup = () => {
  const store = useStore(),
        {showConfirm} = useAlert();
  const isLoading = ref(true);
  const session = computed(() => store.state.auth.session);
  const isMobile = computed(() => store.state.auth.isMobile);
  const bgMode = computed(() => store.state.hrdcloud.mode);
  const show = computed(() => {
    return store.state.hrdcloud.added.show;
  });
  const page = computed(() => store.state.hrdcloud.added.type);

  // eslint-disable-next-line no-unused-vars
  const year = computed(() => {
    if (store.state.hrdcloud.added.year && store.state.hrdcloud.added.year.length > 0) {
      return store.state.hrdcloud.added.year
    } else {
      return dateToDatePart(new Date()).year;
    }
  });
  const isEditMode = ref(false);
  const unKeepList = ref([]);
  const items = ref(null);
  const paging = reactive({ pageNo: 1, pageSize: 12, totalCount: 0 });

  const hasUnKeepList = computed(() => unKeepList.value.length > 0);

  const checkAll = computed(() => {
    let cnt = 0;
    items.value.map(item => {
      unKeepList.value.filter(x => {
        if (item.lrnObjDtlDistSn === x.lrnObjDtlDistSn) cnt++;
      });
    });

    if (cnt == items.value.length) return true;
    return false;
  });

  // const pagingFunc = (pageNo) => {
  //   if (pageNo > 0) paging.pageNo = pageNo;
  //
  // };



  const pagingFunc = (pageNo) => {
    if (pageNo) {
      paging.pageNo = pageNo;
      getKeepList();
    }else{
      paging.pageNo = 1;
    }
  };

  const getKeepList = async () => {
    let payload = {...paging};
    if (page.value === 'grouplearning') {
      const trgtRes = await getGroupLeader(session.value.lrnerId);
      payload.distCrseSn = trgtRes && trgtRes.trgt && trgtRes.trgt.distCrseSn ? trgtRes.trgt.distCrseSn : 0;
    }

    store.dispatch(`history/${ACT_GET_HRDCLOUD_HISTORY_ADD_LIST}`, payload).then(res => {
      if (lengthCheck(res)) {
        items.value = getItems(res);
        console.log(res)
        paging.totalCount= res.paging.totalCount;
      } else {
        items.value = [];
      }
      isLoading.value = false;
    }).catch(() => {
      const {showMessage} = useAlert();
      showMessage("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
      isLoading.value = false;
    });
  };

  const selectKeepToBeDeleted = (idx) => {
    let item = items.value[idx];
    if (isEditMode.value) {
      if (item && item.isSelected) {
        item.isSelected = false;
        unKeepList.value = unKeepList.value.filter(x => x.lrnObjDtlDistSn != item.lrnObjDtlDistSn);
      } else {
        item.isSelected = true;
        unKeepList.value.push(item);
      }
    }
  };

  const removeAddedCtt = () => {
    showConfirm('삭제하시겠습니까?',async () => {
      let res = await deleteCrseActLrnerSumup({
        actTyCdDcd: actTyCdDcds.ACT_TY_CD_CANCEL_ADD,
        objSns: unKeepList.value.map(x => x.objSn)
      });
      if (isSuccess(res)) {
        pagingFunc(paging.pageNo);
        unKeepList.value = [];
        isEditMode.value = false;
      }
    });
  };

  const clickCheckAll = () => {
    if (unKeepList.value.length > 0) {
      items.value.map(x => {
        if (unKeepList.value.filter(y => x.lrnObjDtlDistSn === y.lrnObjDtlDistSn).length === 0) {
          x.isSelected = true;
          unKeepList.value.push(x);
        }
      });
    } else {
      unKeepList.value = items.value.map(x => {
        x.isSelected = true;
        return x;
      });
    }
  };

  const goBackFunc = () => {
    if (isEditMode.value) isEditMode.value = false;
    else closeAdded();
  }

  const clickUnCheckAll = () => {
    unKeepList.value = unKeepList.value.map(x => x.isSelected = false);
    unKeepList.value = [];
  };

  const closeModal = () => {
    closeAdded();
  };

  watch(() => isEditMode.value, () => {
    if (isEditMode.value === false) {
      clickUnCheckAll();
    }
  });

  const bgModeBeforeShow = ref('');

  watch(() => show.value, () => {
    if (!show.value) {
      if (isMobile.value) {
        if (bgModeBeforeShow.value === 'dark') darkMode();
        else lightMode();
      }
      // paging.pageNo = 1;
      isEditMode.value = false;
      unKeepList.value = [];
      items.value = [];
      isLoading.value = true;
    } else {
      if (isMobile.value) {
        bgModeBeforeShow.value = bgMode.value;
        lightMode();
      }
      getKeepList();
    }
  });

  return {
    session, isLoading, show,
    items, paging, isEditMode, hasUnKeepList, checkAll, closeModal,
    pagingFunc, selectKeepToBeDeleted, removeAddedCtt, clickCheckAll, clickUnCheckAll,
    goBackFunc, page
  };
}