<template>
  <!-- begin::kb-main -->
  <LxpMobileHeader title="휴직자 복직지원" />
  <main class="kb-main" id="kb-reinstatement-support">
    <!-- main-content -->
    <div class="main-content min-component">
      <div class="reinstatement-header">
        <article class="header-wrap">
          <div class="avatar">
            <ProfileImg :target="session"/>
          </div>
          <div class="title">
            <h3 class="name">{{ session.lrnerNm }}</h3>
            <p>{{ session.jbgdNm }} {{ session.jbpsNm }}</p>
          </div>
        </article>
        <article>
          <ol class="reinstatement-desc">
            <li>
              <h6>휴직종류</h6>
              <p>{{ item.restRsnNm ? item.restRsnNm : '-'}}</p>
            </li>
            <li>
              <h6>휴직일</h6>
              <p>{{ item.strDt ? ymdStrToFormat(item.strDt, '.') : '' }} - {{ (item.endDt && timestampToDateFormat(item.endDt,'yyyy') === '9999') ? ymdStrToFormat(item.endDt, '.') : ''}}</p>
            </li>
          </ol>
        </article>
      </div>

      <div class="view-component">
        <div class="reinstatement-container">
          <div class="contents">
            <div class="reinstatement-content">
              <div class="content-header">
                <h6 class="title">공지사항</h6>
              </div>
              <div class="mini-notice-container">
                <ul class="mini-notice">
                  <template v-if="noticeTotalCnt > 0">
                    <li v-for="(item, idx) in noticeItems" :key="idx" class="notice-item">
                      <router-link :to="{ name: 'TakeNoticeView', params: {comNtcSn: item.comNtcSn}}" class="link"><h5 class="title">{{ item.ntcTitle }}</h5></router-link>
                      <div class="meta">
                        <span class="text">{{ timestampToDateFormat(item.regDt, 'yyyy.MM.dd')}}</span>
                        <span class="text">{{ item.inqCnt }} 조회</span>
                      </div>
                    </li>
                  </template>
                  <li v-else>
                    <p class="board-title">
                      <a class="link">등록 된 공지사항이 없습니다.</a>
                    </p>
                  </li>
                </ul>
                <router-link :to="{name: 'TakeNotice'}" custom v-slot="{ navigate }">
                  <button class="kb-btn-mini-notice" @click="navigate" @keypress.enter="navigate" role="link">
                    전체보기
                  </button>
                </router-link>
              </div>
            </div>
          </div>
          <div class="contents">
            <div class="reinstatement-content">
              <div class="content-header">
                <h6 class="title">자료실</h6>
              </div>
              <div class="mini-notice-container">
                <ul class="mini-notice">
                  <template v-if="libTotalCnt > 0">
                    <li v-for="(item, idx) in libItems" :key="idx" class="notice-item">
                      <router-link :to="{name: 'TakeLibView', params: {comPostSn: item.comPostSn}}" class="link"><h5 class="title">{{ item.postTitle }}</h5></router-link>
                      <div class="meta">
                        <span class="text">{{ timestampToDateFormat(item.regDt, 'yyyy.MM.dd') }}</span>
                        <span class="text">{{ item.inqCnt }} 조회</span>
                      </div>
                    </li>
                  </template>
                </ul>
                <router-link :to="{name: 'TakeLib'}" custom v-slot="{ navigate }">
                  <button class="kb-btn-mini-notice" @click="navigate" @keypress.enter="navigate" role="link">
                    전체보기
                  </button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end:intro-container -->
    </div>
    <!-- //main-content -->
  </main>
  <!-- end::kb-main -->
</template>

<script>
import {computed, ref} from "vue";
import {useStore} from "vuex";
import ProfileImg from "@/components/common/ProfileImg";
import ApiService from "@/assets/js/api.service";
import {getItem, getItems, lengthCheck, timestampToDateFormat, ymdStrToFormat} from "@/assets/js/util";
import {ACT_GET_HELP_LIB_LIST, ACT_GET_HELP_NOTICE_LIST} from "@/store/modules/help/help";
import {expsrLocCds, libTyCdDcds} from "@/assets/js/modules/help/help-common";
import LxpMobileHeader from "@/views/layout/lxp/header/mobile/LxpMobileHeader";

export default {
  name: "TakeMobileMain",
  components: {LxpMobileHeader, ProfileImg},
  setup(){
    const store = useStore();
    const session = computed(() => store.state.auth.session);

    const item = ref({});

    const noticeItems = ref([]);
    const noticeTotalCnt = ref(0);

    const libItems = ref([]);
    const libTotalCnt = ref(0);

    const getTakeUser = () => {
      ApiService.get('/v1/app/auth/take').then(res => {
        if(lengthCheck(res)) {
          item.value = getItem(res);
        }
      });
    }

    const getTakeNoticeList = () => {
      store.dispatch(`help/${ACT_GET_HELP_NOTICE_LIST}`, {
        expsrLocCd: expsrLocCds.EXPSR_LOC_CD_TAKE,
        pageSize: 2
      }).then(res => {
        noticeTotalCnt.value = res.paging.totalCount;
        if (lengthCheck(res)) {
          noticeItems.value = getItems(res);
        } else {
          noticeItems.value = [];
        }
      }).catch(e => {
        console.error(e);
      })
    }

    const getTakeLibList = () => {
      store.dispatch(`help/${ACT_GET_HELP_LIB_LIST}`, {
        libTyCdDcd: libTyCdDcds.LIB_TY_CD_TAKE_LIB,
        pageSize: 2
      }).then(res => {
        libTotalCnt.value = res.paging.totalCount;
        if (lengthCheck(res)) {
          libItems.value = getItems(res);
        } else {
          libItems.value = [];
        }
      }).catch(e => {
        console.error(e);
      })
    }

    getTakeUser();
    getTakeNoticeList();
    getTakeLibList();

    return {
      session,
      item,
      noticeItems,
      noticeTotalCnt,
      libItems,
      libTotalCnt,
      timestampToDateFormat,
      ymdStrToFormat
    }
  }
}
</script>