import ApiService from '@/assets/js/api.service';

export const ACT_GET_COM_BADGE_GET_LIST = 'actGetComBadgeGetList';
export const ACT_GET_ALL_BADGE_LIST = 'actGetAllBadgeList';
export const ACT_GET_COM_BADGE_TY_LIST = 'actGetComBadgeTyList';
export const ACT_GET_CHK_HIDDEN_BADGE = 'actGetChkHiddenBadge';

const state = {
};

const mutations = {
};

const actions = {
  [ACT_GET_COM_BADGE_GET_LIST](context, params) {
    return new Promise(resolve => {
      ApiService.query('/v1/app/badge', params).then(response => resolve(response))
    });
  },
  [ACT_GET_ALL_BADGE_LIST](context, params) {
    return new Promise(resolve => {
      ApiService.query('/v1/app/badge/all', params).then(response => resolve(response))
    });
  },
  [ACT_GET_COM_BADGE_TY_LIST](context, params) {
    return new Promise(resolve => {
      ApiService.query('/v1/app/badge/all/ty', params).then(response => resolve(response))
    });
  },
  [ACT_GET_CHK_HIDDEN_BADGE](context, params) {
    return new Promise(resolve => {
      ApiService.query('/v1/app/badge/hidden', params).then(response => resolve(response))
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
