<template>
  <div class="popup-container ssl-badge" :class="{'is-active': show}" data-popup="popup-attend-badge">
    <div class="popup" style="border-radius: 24px;">
      <div class="kb-alert-box">
        <swiper
            class="kb-alert-content-container js-swiper-badge"
            :modules="modules"
            :autoplay="{
                delay: 3000,
                disableOnInteraction: false,
                stopOnLast: true,
            }"
            :pagination="{
              el: '.swiper-pagination',
              clickable: true,
            }"
        >
          <swiper-slide v-for="(badge, idx) in myBadges" class="kb-alert-content" :key="idx">
            <div class="badge animate__animated animate__flip" style="background-color: #ffffff">
              <SSLBadgeTwo
                  :is-active="true"
                  :badge-ty-cd-dcd="badge.badgeTyCdDcd"
                  :hidden-yn="badge.hiddenYn"
              />
            </div>
            <p class="text">축하해요! <br/>"{{ badge.badgeName }} 배지"를 획득했어요</p>
          </swiper-slide>

          <div class="swiper-pagination"></div>
        </swiper>
        <button class="kb-btn-alert-close" @click="closeModal">
          <i class="icon-alert-close"></i>
        </button>
      </div>
      <div class="popup-deem"></div>
    </div>
  </div>
</template>
<script>

import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import SSLBadgeTwo from '@/components/ssl/badge/SSLBadgeTwo';
import {sslBadgeModalSetup} from '@/assets/js/modules/ssl/setup/ssl-layout-setup';

export default {
  name: 'SSLBadgeModal',
  components: {
    SSLBadgeTwo,
    Swiper,
    SwiperSlide
  },
  setup: sslBadgeModalSetup
}
</script>
