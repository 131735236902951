<template>
  <div class="contents">
    <div class="section-wrapper">
      <article class="myssl-section section-sbadge">
        <header class="section-header">
          <h3 class="title">배지
            <small class="text-muted">{{ learnerBadges.length }}/{{activeBadges.length}}</small>
            <button class="kb-btn-right"><i class="icon-right"></i></button>
          </h3>
        </header>
        <div class="section-body">
          <div class="sbadge-container">
            <div class="sbadge-wrapper my-sbadge-wrapper" style="grid-template-columns: repeat(auto-fill, 108px) !important;max-height: unset !important;">
              <!-- badge -->
              <SSLBadgeTitle v-for="(badge, idx) in activeBadges"
                             :key="idx"
                             :badge-ty-cd-dcd="badge.badgeTyCdDcd"
                             :is-active="badge.isActive"
                             :is-new="badge.isNew"
                             :hidden-yn="badge.hiddenYn"
                             :badge-data="badge"
                             :show-title="true"
                             :show-tooltip="false"
               />
            </div>
          </div>
        </div>
      </article>
    </div>
  </div>
</template>

<script>

import SSLBadgeTitle from '@/components/ssl/badge/SSLBadgeTitle';
import {sslLearnerBadgeSetup} from '@/assets/js/modules/ssl/setup/ssl-learn-setup';

export default {
  name: 'SSLMobileLearnerBadge',
  components: {
    SSLBadgeTitle
  },
  setup: sslLearnerBadgeSetup
}
</script>
