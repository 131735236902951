<template>
  <div class="page-container">
    <div class="page-body">
      <div class="page-component">
        <div class="home-section-row">
          <SSLEvent/>
          <SSLHomeProfile/>
        </div>

        <!-- 공지사항 -->
        <div class="home-section-row">
          <SSLHomeNotice/>
        </div>

        <!-- 내 활동 -->
        <div class="home-section-row">
          <SSLActivity/>
        </div>

        <!-- 학습현황 -->
        <SSLHomeStatus/>

        <!-- 내 배지 -->
        <div class="home-section-row">
          <SSLHomeBadge/>
        </div>

        <!-- begin::학습노트-->
          <section class="note section_note">
            <div class="section-title-area">
              <div class="titles fl">
                <h2 class="title">SSL 학습노트</h2>
                <span class="bar"></span>
                <ul class="title-buttons">
                  <li v-for="(item, idx) in sslList"
                      :key="idx"
                      :class="{ 'is-active' : sslCardFilter === item.name }"
                      @click="sslCardFilter = item.name">
                    <a href="javascript:">{{ item.title }}</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="board-list-wrapper" style="padding:0 20px">
              <!-- board-list -->
              <template v-if="sslLoading">
                <LoadingDiv v-model="sslLoading" />
              </template>
              <template v-else>
                <!-- board-list -->
                <template v-if="sslitems.length > 0">
                  <SSLNoteCard :slides-per-view="4"
                               :swiper-class="`excellent-board-card-type`"
                               :sslItems="sslitems"/>
                </template>
                <template v-else>
                  <div class="search-container">
                    <div class="result-empty" style="text-align: center; margin-top: 30px; margin-bottom: 30px;"><img
                        src="../../assets/lxp/images/common/img_empty.png" alt="">
                      <p class="text">구독직원의 SSL 학습노트가 없습니다.</p>
                    </div>
                  </div>
                </template>
              </template>
              <!-- //board-list -->
            </div>
          </section>

        <SSLLink/>
      </div>
    </div>
  </div>
</template>

<script>

import {getAllSubscribes, getDistCrseCd, sslRoutes} from "@/assets/js/modules/ssl/ssl-common"; // getProfileImg,
import SSLHomeProfile from "@/components/ssl/home/SSLHomeProfile";
import SSLHomeStatus from "@/components/ssl/home/SSLHomeStatus";
import SSLHomeBadge from "@/components/ssl/home/SSLHomeBadge";
import SSLLink from "@/components/ssl/home/SSLLink";
import SSLActivity from "@/components/ssl/home/SSLActivity";
import SSLEvent from "@/components/ssl/home/SSLEvent";
import SSLHomeNotice from "@/components/ssl/home/SSLHomeNotice";
import SSLNoteCard from "@/components/main/SSLNoteCard.vue";
import LoadingDiv from "@/components/common/LoadingDiv.vue";
import {ref, watch} from "vue";
import {getItems, lengthCheck, stringCheck, timestampToDateFormat} from "@/assets/js/util";
import {
  ACT_GET_SOC_CNT,
  ACT_GET_SOC_EXCELLENT_BOARD_LIST,
  ACT_GET_SOC_SUBSCRIBE_BOARD_LIST
} from "@/store/modules/ssl/ssl-index";
import {useStore} from 'vuex';

export default {
  name: 'SSLHome',
  components: {
    LoadingDiv,
    SSLNoteCard, SSLHomeNotice, SSLEvent, SSLLink, SSLHomeBadge, SSLHomeStatus, SSLActivity, SSLHomeProfile},
  setup() {
    getAllSubscribes();

    const store = useStore();
    const sslCardFilter = ref('excellent');
    const sslLoading = ref(false);
    const sslList = ref([
      { name: 'excellent', title: '인기', isLoading: true, items:[] },
      { name: 'subscribe', title: '구독', isLoading: true, items:[] },
    ]);
    const sslitems = ref([]);
    const excellentItems = ref([]);
    const subscribeItems = ref([]);

    // 쓸이 종료된 경우 구독 학습노트 숨김 처리
    store.dispatch(`ssl/${ACT_GET_SOC_CNT}`).then(res => {
      if(res && res.cnt === 0) {
        sslList.value[1].title = '';
      }
    });

    const getsslCardList = async (sslCardFilter) => {
      sslLoading.value = true;
      if (sslCardFilter.value === 'excellent') {
        if (!stringCheck(excellentItems.value)) {
          await store.dispatch(`ssl/${ACT_GET_SOC_EXCELLENT_BOARD_LIST}`).then(res => {
            if(lengthCheck(res)) {
              console.log("res.value");
              console.log(getItems(res));
              excellentItems.value = getItems(res).map(x => ({
                ...x,
                date: timestampToDateFormat(x.pblcnDt, 'yyyy.MM'),
                day: timestampToDateFormat(x.pblcnDt, 'dd'),
                distCrseCd: getDistCrseCd(x.distCrseSn)
              }));
              console.log("sslitems.value");
              console.log(sslitems.value);
            }
          });
        }
        sslitems.value = excellentItems.value;
      } else if (sslCardFilter.value === 'subscribe') {
        if (!stringCheck(subscribeItems.value)) {
          await store.dispatch(`ssl/${ACT_GET_SOC_SUBSCRIBE_BOARD_LIST}`).then(res => {
            if(lengthCheck(res)) {
              console.log("res.value");
              console.log(getItems(res));
              subscribeItems.value = getItems(res).map(x => ({
                ...x,
                date: timestampToDateFormat(x.pblcnDt, 'yyyy.MM'),
                day: timestampToDateFormat(x.pblcnDt, 'dd'),
                distCrseCd: getDistCrseCd(x.distCrseSn)
              }));
              console.log("subscribe.value");
              console.log(sslitems.value);
            }
          });
        }
        sslitems.value = subscribeItems.value;
      }
      sslLoading.value = false;
    };

    watch(() => sslCardFilter.value, () => {
      getsslCardList(sslCardFilter);
    });

    getsslCardList(sslCardFilter);


    return {
      // getProfileImg,
      sslRoutes,
      sslCardFilter,
      sslList,
      sslitems,
      sslLoading,

    }
  }
}
</script>
