import LxpLayout from "@/views/layout/lxp/LxpLayout";
import navigationUtils from "@/assets/js/navigationUtils";
import LxpMobileLayout from "@/views/layout/lxp/mobile/LxpMobileLayout.vue";

const isMobile = navigationUtils.any();

const notificationRoutes = [
  {
    path: '/notification',
    component: isMobile ? LxpMobileLayout : LxpLayout,
    children: [
      {
        // path variable 을 생성해야 된다. 임시 라우터 처리
        path: 'train',
        name: "TrainNotification",
        component: () => isMobile ? import('../../views/pages/notification/mobile/TrainMobileNotification') : import('../../views/pages/notification/TrainNotification'),
      },
      {
        // path variable 을 생성해야 된다. 임시 라우터 처리
        path: 'train/:trainNotiSn',
        name: "TrainNotiView",
        component: () => import('../../views/pages/notification/TrainNotiView'),
      },

    ],
  }
];

export const setNotificationRoutes = (routes) => {
  routes.push(...notificationRoutes);
}