<template>
  <div class="header-column column-dropdown">
    <div class="dropdown is-active">
      <div class="dropdown-target" style="transform: translateY(290px);left: -290px;right:50px">
        <div class="dropdown-box">
          <div class="depthmenu">
            <ul class="dropdown-option-list">

              <li v-for="(mainRoute, idx) in mainRoutes" class="kb-nav-item" :key="idx">
<!--                <router-link :to="getWritePath(mainRoute)" class="dropdown-option-link" :class="{'is-active': distCrseCd === mainRoute.key}" @click="toggles.main = false">-->
                <div class="dropdown-option-link kb-mouse-cursor" :class="{'is-active': distCrseCd === mainRoute.key}" @click="getWritePath(mainRoute)"> <!-- ;toggles.main = false -->
                  <span class="dropdown-option-text">{{ mainRoute.name }}</span>
                </div>
<!--                </router-link>-->
              </li>
            </ul>
            <ul v-if="subRoutes.length > 0" class="dropdown-option-list">
              <li v-for="(subRoute, idx) in subRoutes" class="dropdown-option-item" :key="idx">
                <router-link :to="getSubPath(subRoute)" class="dropdown-option-link" :class="{'is-active': currentSubRoute.lrnTrgtGrpMstSn === subRoute.lrnTrgtGrpMstSn}" @click="toggles.main = false">
                  <span class="dropdown-option-text">{{ subRoute.lrnTrgtGrpNm }}</span>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
<!--    <div v-if="subRoutes.length > 0" class="dropdown">-->
<!--      <a href="javascript:" class="dropdown-btn" @click.stop="toggleVisible('main')"><strong class="text">{{ currentSubRoute.lrnTrgtGrpNm }}</strong></a>-->
<!--    </div>-->
  </div>

  <SSLEnroll
      v-if="enroll.toggle"
      v-model:toggle="enroll.toggle"
      :dist-crse-cd="enroll.key"
  />
</template>


<script>

import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {computed, reactive} from "vue";
import {getRouteLrnTrgtGrpMstSn, sslNavRoutes, sslRoutes} from "@/assets/js/modules/ssl/ssl-common";
import SSLEnroll from "@/components/ssl/common/SSLEnroll.vue";
import {MUT_SET_ROUTE_FROM} from "@/store/modules/ssl/ssl-index";

export default {
  name: 'SSLNavWrt',
  components: {SSLEnroll},
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const distCrseCd = computed(() => route.params.distCrseCd);
    const lrnTrgtGrpMstSn = computed(() => getRouteLrnTrgtGrpMstSn(route.params.lrnTrgtGrpMstSn));
    const myLrnTrgtGrpMstSns = computed(() => store.state.ssl.myLearnTargets.map(x => x.lrnTrgtGrpMstSn));

    const enroll = reactive({
      key: null,
      toggle: false,
    });

    const toggles = reactive({
      main: false,
      sub: false
    });

    // main route
    const mainRoutes = computed(() => {
      return Object.values(sslNavRoutes(myLrnTrgtGrpMstSns));
      // if (getTodayDate('yyyyMMdd') < '20240701'){
      //   return Object.values(sslRoutes).filter(x => x.key != 'book').filter(x=>(x.lrnTrgtGrpMstSns.filter(y=>myLrnTrgtGrpMstSns.value.includes(y)) != '' || x.key == 'trivia'));
      // }else{
      //   return Object.values(sslRoutes).filter(x=>(x.lrnTrgtGrpMstSns.filter(y=>myLrnTrgtGrpMstSns.value.includes(y)) != '' || x.key == 'trivia'));
      // }

    });

    const currentMainRoute = computed(() => {
      if(route.name === 'SSLHome' || route.name === 'SSLNoticeView'){
        return {name: 'SSL 홈', key: 'home'};
      }
      else if(distCrseCd.value && sslRoutes[distCrseCd.value]){
        return sslRoutes[distCrseCd.value];
      }
      else if (route.name === 'SSLNotice') {
        return {name: 'SSL 홈', key: 'home'};
      }
      return {};
    });

    const currentSubRoute = computed(() => {
      if (lrnTrgtGrpMstSn.value > 0 && currentMainRoute.value && currentMainRoute.value.group && currentMainRoute.value.group.length > 0) {
        const found = currentMainRoute.value.group.find(x => lrnTrgtGrpMstSn.value === x.lrnTrgtGrpMstSn);
        if (found) return found;
      }
      return {};
    });

    // sub route
    const subRoutes = computed(() => {
      if (route.name === 'SSLMain' && currentMainRoute.value && currentMainRoute.value.group && currentMainRoute.value.group.length > 1) {
        return currentMainRoute.value.group.map(x => ({...x, key: currentMainRoute.value.key}));
      }
      return [];
    });

    const getWritePath = targetRoute => {
      let targetLrnTrgtGrpMstSn = targetRoute.lrnTrgtGrpMstSns[0];
      const targetLrnTrgtGrpMstSns = targetRoute.lrnTrgtGrpMstSns.filter(x => myLrnTrgtGrpMstSns.value.includes(x));
      if (targetLrnTrgtGrpMstSns.length > 0) targetLrnTrgtGrpMstSn = targetLrnTrgtGrpMstSns[0];

      // SSL 글쓰기 진입 페이지 정보 저장
      store.commit(`ssl/${MUT_SET_ROUTE_FROM}`, route);

      // if (isMyLrnTrgtGrpMstSn(targetLrnTrgtGrpMstSn)){ //신청한 과정인경우 -- 2024년 신청된 메뉴만 가져오는거라 검사 불필요
        if(targetRoute.key == 'group' || targetRoute.key == 'book'){
          router.push({name: 'SSLMy', params: {distCrseCd: targetRoute.key, lrnTrgtGrpMstSn: targetLrnTrgtGrpMstSn}}).then(() => {});
        }else {
          router.push({name: 'SSLWrite', params: {distCrseCd: targetRoute.key, lrnTrgtGrpMstSn: targetLrnTrgtGrpMstSn, socLrnPostSn: 0}}).then(() => {});
        }
      // }else{// 미신청인 경우 신청권유
      //   enroll.key = targetRoute.key;
      //   enroll.toggle = true;
      // }
    };

    const getSubPath = targetRoute => {
      return {name: 'SSLWrite', params: {distCrseCd:targetRoute.key, lrnTrgtGrpMstSn: targetRoute.lrnTrgtGrpMstSn, socLrnPostSn: 0}}
    }

    return {
      distCrseCd,
      lrnTrgtGrpMstSn,
      myLrnTrgtGrpMstSns,
      mainRoutes,
      subRoutes,
      getWritePath,
      toggles,
      getSubPath,
      currentSubRoute,
      enroll
    }
  }
}
</script>