<template>
  <div class="main-header">
    <div class="header-snb">
      <nav class="snb">
        <ul class="snb-list">
          <li v-for="(category, idx) in category2" class="snb-item"
              :class="{ 'is-active' : category.hrdCloudCateSn == modelValue.category2 }" :key="idx">
            <button @click="handleClick(category)">{{category.hrdCloudCateNm}}
              <i v-if="hasChild(category.hrdCloudCateSn)" class="icon-arrow-bottom-gray"></i>
            </button>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import {computed, onMounted, reactive, toRefs} from 'vue';
import {useStore} from 'vuex';
import {useRoute} from 'vue-router';
import {pushQuery} from '@/assets/js/ui.init';
import {setParamsByQuery} from '@/assets/js/util';

export default {
  name: 'HrdCloudMobileCateSnb',
  props: {
    modelValue: Object,
    showHrdCloudCate: Boolean,
  },
  emits: ['update:modelValue','update:showHrdCloudCate'],
  // eslint-disable-next-line no-unused-vars
  setup(props, {emit}) {
    const store = useStore(), route = useRoute();
    const {modelValue} = toRefs(props);

    const params = reactive({
      keyword: '',
      sortTyCdDcd: '',
      category1: 0,
      category2: 0,
      pageNo: 0,
    });

    const categories = computed(() => store.state.hrdcloud.hrdcloudCates),
          category2 = computed(() => categories.value.filter(x => x.lvl === 2 && x.upHrdCloudCateSn === Number(modelValue.value.category1) && x.stt === '00'));

    const hasChild = (cate2Sn) => {
      return categories.value.find(x => x.lvl === 3 && x.upHrdCloudCateSn === Number(cate2Sn) && x.stt === '00')
    }

    const handleClick = (cate) => {
      params.category2=cate.hrdCloudCateSn;
      pushQuery({...params});
      if (hasChild(cate.hrdCloudCateSn)) emit('update:showHrdCloudCate', !props.showHrdCloudCate);
    };

    // const setParamsByQuery = () => {
    //   if (route.name && route.name.indexOf('HrdCloud') > -1 && route.query) {
    //     if (route.query.category1) {
    //       searchParam.category1 = Number(route.query.category1);
    //     }
    //   }
    // };

    onMounted(() => {
      setParamsByQuery(route, params, true);
    });

    return {
      category2,
      handleClick,
      hasChild
    }
  }
};
</script>