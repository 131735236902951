<template>
  <section class="content-section" :class="{'section-divider' : !isMobile}">
    <header class="section-header">
      <h3 class="title">Hot Tube</h3>
      <div v-if="!isMobile" class="header-sub">
        <a href="javascript:" class="more-link more-link-line" @click="func">Hot Tube 등재</a>
      </div>
    </header>
    <p class="description">
      영업과 관련된 노하우, 우수사례, TIP이 담긴 Hot Tube 영상을 자유롭게 등재해주세요.<br /><span style="color:#ff8000;line-height:unset !important;">※MP4파일(최대 2GB)로 외부망에서 업로드</span>
    </p>
    <template v-if="!isLoading && (items && items.length > 0)">
      <div class="hrdcloud-list-wrapper" style="margin-top: var(--kb-spacer-6);">
        <ul class="hrdcloud-list" data-grid="3">
          <li v-for="(item, idx) in items" class="hrdcloud-item" :key="idx">
            <router-link :to="{ name: 'HrdCloudContentBridge',params: {lrnObjDtlDistSn: item.lrnObjDtlDistSn} }">
              <article class="hrdcloud-card">
                <div class="hrdcloud-image">
                  <div class="image">
                    <a href="javascript:" class="image-link">
                      <img v-if="item.thumb" :src="getImg(item.thumb)" alt="임시이미지" />
                      <img v-else src="../../../assets/lxp/images/@tmp/@tmp_hrdcloud_03.jpg" alt="임시이미지" />
                    </a>
                  </div>
                  <div class="badges">
                    <div class="badge-dark">
                      <span class="text">{{ item.category2 }}</span>
                    </div>
                  </div>
                </div>
                <div class="hrdcloud-content">
                  <div class="content-category">
                    <span class="text">{{ item.category3 }}</span>
                  </div>
                  <div class="content-title">
                    <h5 class="title"><a href="javascript:" class="title-link">{{ item.objNm }}</a></h5>
                  </div>
                  <template v-if="isMobile">
                    <div class="content-info-and-meta">
                      <div class="content-info">
                        <div class="info">
                          <i class="icon-views"></i>
                          <span class="text">{{ item.inqCnt }}</span>
                        </div>
                        <div class="info">
                          <i class="icon-like"></i>
                          <span class="text">{{ item.lkCnt }}</span>
                        </div>
                      </div>
                      <div class="content-meta">
                        <span class="text">{{ timestampToDateFormat(item.regDt, 'yyyy.MM.dd') }}</span>
                        <span class="text text-red">{{ secondToFormat(item.cttSz, 'hh:mm:ss') }}</span>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="content-info">
                      <div class="info">
                        <i class="icon-views"></i>
                        <span class="text">{{ item.inqCnt }}</span>
                      </div>
                      <div class="info">
                        <i class="icon-like"></i>
                        <span class="text">{{ item.lkCnt }}</span>
                      </div>
                    </div>
                    <div class="content-meta">
                      <span class="text">{{ timestampToDateFormat(item.regDt, 'yyyy.MM.dd') }}</span>
                      <span class="text text-red">{{ secondToFormat(item.cttSz, 'hh:mm:ss') }}</span>
                    </div>
                  </template>
                </div>
              </article>
            </router-link>
          </li>
        </ul>
      </div>
    </template>
    <template v-else-if="!isLoading && (items && items.length === 0)">
      <div class="search-container">
        <div class="result-empty">
          <img src="../../../assets/lxp/images/common/img_empty.png" alt="">
          <p class="text">등재완료된 Hot Tube 콘텐츠가 없습니다.</p>
<!--          <p class="text">나만의 다양한 노하우를 동영상으로</p>-->
<!--          <p class="text">제작하여 공유해주세요.</p>-->
        </div>
      </div>
    </template>
    <div v-if="isMobile" class="section-bottom" style="margin-top:50px">
      <button class="kb-btn kb-btn-outline kb-btn-content-item" @click="func"><span class="text">Hot Tube 등재</span></button>
    </div>
  </section>
  <LoadingDiv v-if="!isMobile" v-model="isLoading" />
</template>

<script>
import {computed, reactive, ref, toRefs, watch} from 'vue';
import {getHrdCloudList, getTop1CategoryByLevel, servTyCdDcds} from '@/assets/js/modules/hrd/hrd-common';
import LoadingDiv from '@/components/common/LoadingDiv';
import {useStore} from 'vuex';
import {dateToDatePart, getCheckItems, secondToFormat, timestampToDateFormat} from '@/assets/js/util';

export default {
  name: 'KBTube',
  components: {LoadingDiv},
  props: {
    year: { type: [Number, String], default: dateToDatePart(new Date()).year},
    func: Function,
    isMobile: Boolean
  },
  setup(props) {
    const store = useStore();
    const session = computed(() => store.state.auth.session);
    const isLoading = ref(false);
    const {year} = toRefs(props);
    const items = ref([]);
    const paging = reactive({
      pageNo: 1,
      pageSize: 3,
      totalCount: 0
    });

    const getList = async () => {
      isLoading.value = true;

      let res = await getHrdCloudList({
        ...paging,
        crsePrfx1: props.year,
        rgtrId: session.value.lrnerId,
        stt: '00',
        servTyCdDcd: servTyCdDcds.SERV_TY_CD_KBTUBE
      });
      items.value = getCheckItems(res);
      items.value.map(x => {
        let cates = getTop1CategoryByLevel(x.cloudNmLst);
          if (cates) {
            x.category1 = cates.category1;
            x.category2 = cates.category2;
            x.category3 = cates.category3;
            x.category4 = cates.category4;
            x.category5 = cates.category5;
          }
      });
      isLoading.value = false;
    };

    const getImg = (src) => {
      return`${process.env.VUE_APP_CLOUDFRONT_URL}/${src}`;
    };

    getList();

    watch(() => year.value, () => {
      getList();
    });

    return {
      isLoading, items,
      getImg,
      timestampToDateFormat, secondToFormat
    }
  }
};
</script>