<template>
  <div class="myssl-container">
    <SSLMyTop :show-write="true"/>
    <!--  my activity      -->
    <SSLMyActivity v-if="enableWrite" :focus-func="historyEvents"/>
    <!--    my badge    -->
    <SSLMyBadge/>
  </div>
  <BookHistory v-if="bookHistoryModal" v-model="bookHistoryModal" :selectValue="selectValue"/>
</template>

<script>
import SSLMyTop from '@/components/ssl/my/SSLMyTop';
import SSLMyActivity from '@/components/ssl/my/SSLMyActivity';
import SSLMyBadge from '@/components/ssl/my/SSLMyBadge';
import {computed} from 'vue';
import {getRouteLrnTrgtGrpMstSn, isMyLrnTrgtGrpMstSn} from '@/assets/js/modules/ssl/ssl-common';
import {useRoute} from 'vue-router';
import {useStore} from 'vuex';
import BookHistory from "@/components/history/book/BookHistory.vue";
import {ref} from 'vue';

export default {
  name: 'SSLMyStatus',
  components: {
    BookHistory,
    SSLMyBadge,
    SSLMyActivity,
    SSLMyTop,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const distCrseCd = computed(() => route.params.distCrseCd);
    const lrnTrgtGrpMstSn = computed(() => getRouteLrnTrgtGrpMstSn(route.params.lrnTrgtGrpMstSn));

    const enableWrite = computed(() => {
      // group 러닝일 경우 리더일 경우만 글을 쓸수 있다.
      if (distCrseCd.value === 'group') {
        return store.state.ssl.group.leaderLrnerId === store.state.auth.session.lrnerId;
      } else if (distCrseCd.value === 'college') {
        // 입과된 사람들만 글 쓰기 가능
        return isMyLrnTrgtGrpMstSn(lrnTrgtGrpMstSn.value);
      }
      return true;
    });

    const bookHistoryModal = ref(false);

    const selectValue = ref('2001038');

    const historyEvents = {
      openTimeHistoryModal: () => {
        selectValue.value = '2001038';
        bookHistoryModal.value = true;
      },
      openMemoHistoryModal: () => {
        selectValue.value = '2001039';
        bookHistoryModal.value = true;
      },
    };

    return {
      enableWrite, bookHistoryModal, historyEvents, selectValue
    }
  }
}
</script>
