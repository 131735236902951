<template>
  <div class="toast-container" :class="{'is-visible': toast.show}">
    <div v-if="toast.show" class="toast" v-html="toast.text"></div>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {computed} from "vue";

export default {
  name: "LxpMobileToast",
  setup() {
    const store = useStore();
    const toast = computed(() => store.state.common.toast);

    return {
      toast
    }

  }
}
</script>