<template>
  <div class="page-content">
    <!-- board-list-container -->
    <div class="board-list-container">
      <!-- board-list-top -->
      <div class="board-list-top">

        <div class="top-column">
          <div class="tabs">
            <ul class="tab-list">
              <li v-for="(viewMode, idx) in viewModes" class="tab-item" :class="{'is-active': idx === viewModeIdx}" :key="idx">
                <router-link :to="{ query: {view: viewMode.mode} }" class="tab-link">
                  <span class="tab-text">{{ viewMode.tag }}</span>
                </router-link>
              </li>
            </ul>
          </div>
        </div>

        <div class="top-column">
          <div class="kb-form-field field-search">
            <input v-model.trim="keyword" type="text" class="kb-form-control" name="" placeholder="검색" @keyup.enter="searchBoard"/>
            <button class="kb-btn-search" @click="searchBoard">
              <i class="icon-search"></i>
            </button>
            <button class="kb-btn-search-delete" @click="deleteKeyword">
              <i class="icon-delete"></i>
            </button>
          </div>
        </div>

        <div class="top-column">
          <div class="list-type">
            <!-- is-active 클래스로 활성화. -->
            <button class="kb-btn-type" :class="{'is-active': boardMode === 'card'}" @click="changeMode(0)">
              <i class="icon-type-thumbnail"></i>
            </button>
            <button class="kb-btn-type" :class="{'is-active': boardMode === 'list'}" @click="changeMode(1)">
              <i class="icon-type-list"></i>
            </button>
          </div>
        </div>
      </div>
      <!-- //board-list-top -->
      <!-- board-list-wrapper -->
      <div class="board-list-wrapper">
        <div v-if="!isLoading && items.length === 0" class="board-search-wrapper">
          <div class="search-empty">
            <img src="@/assets/lxp/images/ssl/main/empty_image.png" alt>
          </div>
        </div>
        <!-- board-list -->
        <ul v-else :class="boardClass">
          <li v-for="(item, idx) in items" class="board-item"  :key="idx"> <!-- :class="{'type-text': !item.thumb}" -->
            <SSLCard v-if="boardMode === 'card'"
                     :item="item"
                     :is-group="isGroup"
                     @moveBoard="moveBoard"
            />
            <SSLItem v-else-if="boardMode === 'list'"
                     :item="item"
                     :is-group="isGroup"
                     @moveBoard="moveBoard"
            />
          </li>
        </ul>
        <!-- //board-list -->
        <!-- board-list-actions -->
        <div v-if="paging.hasMore" class="board-list-actions">
          <button class="kb-btn-more" @click="moreFunc">
            <span class="text">더보기</span>
          </button>
        </div>
        <!-- //board-list-actions -->
      </div>
      <!-- //board-list-wrapper -->
    </div>
    <!-- //board-list-container -->
  </div>
</template>

<script>

import {computed, ref, toRef, watch} from "vue";
import SSLCard from "@/components/ssl/SSLCard";
import SSLItem from "@/components/ssl/SSLItem";
import {useRoute} from "vue-router";
import {getToken, saveToken} from "@/assets/js/localstorage.service";
import {BOARD_MODE_KEY} from "@/store/modules/ssl/ssl-index";
import {noneRewardCds} from '@/assets/js/modules/ssl/ssl-common';


export default {
  name: 'SSLBoard',
  components: {
    SSLItem,
    SSLCard
  },
  props: {
    isLoading: {
      required: true,
      type: Boolean
    },
    viewModes: {
      required: true,
      type: Array
    },
    viewModeIdx: {
      required: true,
      type: Number
    },
    lrnTrgtGrpMstSn: {
      required: true,
      type: Number
    },
    items: {
      required: true,
      type: Array
    },
    paging: {
      required: true,
      type: Object,
      default: () => ({
        pageNo: 1,
        pageSize: 10,
        totalCount: 0,
        hasMore: false
      })
    },
    moreFunc: {
      required: true,
      type: Function
    },
    searchFunc: {
      required: true,
      type: Function
    },
    initKeyword: String
  },
  emits: ['moveBoard'],
  setup(props, {emit}) {
    const route = useRoute();

    const isGroup = computed(() => route.params.distCrseCd === 'group');
    const boardModes = ['card', 'list'];
    const savedMode = getToken(BOARD_MODE_KEY);
    const boardMode = ref(savedMode ? savedMode : 'card');

    const boardClass = computed(() => {
      if(noneRewardCds.includes(route.params.distCrseCd)){
        return `board-${boardMode.value}-type x5`
      }
      return `board-${boardMode.value}-type`
    });

    const changeMode = (idx) => {
      boardMode.value = boardModes[idx];
      saveToken(BOARD_MODE_KEY, boardModes[idx]);
    }

    const keyword = ref('');
    const initKeyword = toRef(props, 'initKeyword')
    if(props.initKeyword){
      keyword.value = initKeyword.value;
    }

    const searchBoard = () => {
      props.searchFunc(keyword.value);
    }

    const deleteKeyword = () => {
      keyword.value = '';
      props.searchFunc(keyword.value);
    }

    const moveBoard = (item) => {
      emit('moveBoard', item);
    }

    watch(() => props.lrnTrgtGrpMstSn, () => {
      keyword.value = '';
    });

    watch(() => props.viewModeIdx, () => {
      keyword.value = '';
    });

    return {
      isGroup,
      boardMode,
      boardClass,
      keyword,
      changeMode,
      searchBoard,
      deleteKeyword,
      moveBoard
    }
  }
}
</script>
