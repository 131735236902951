<template>
  <LxpMobileHeader :title="`${isSearch ? '' : '휴직자 복직지원 자료실'}`">
    <template v-slot:left>
      <template v-if="isSearch">
        <div class="util util-back">
          <a href="javascript:" class="util-actions util-actions-back" @click="clickBack">
            <i class="icon-history-back"></i>
          </a>
        </div>
        <div class="util util-search-input">
          <input type="text" placeholder="자료실 검색" v-model="search.postTitle" @keyup.enter="toggleSearch">
        </div>
      </template>
    </template>
    <template v-slot:right>
      <div class="util util-search">
        <a href="javascript:" class="util-actions util-actions-search" @click="toggleSearch">
          <i class="icon-search"></i>
        </a>
      </div>
    </template>
  </LxpMobileHeader>
  <main class="kb-main" id="kb-reinstatement-support">
    <!-- main-content -->
    <div class="main-content main-component">
      <div class="notice-list-container">
        <div class="list-top status">
          <div class="top-column">
            <p class="text text-muted">총 {{ paging.totalCount }}건</p>
          </div>
        </div>

        <div class="board-list-container">
          <ul class="board-list">
            <li v-for="(item, idx) in items" class="board-list-item" :key="idx">
              <router-link :to="{name: 'TakeLibView', params: {comPostSn: item.comPostSn}}" class="board-link"></router-link>
              <div class="list-content">
                <div class="list-content-title">
                  <h5 class="title">
                    <span class="text text-truncate">{{item.postTitle}}</span>
                    <i class="icon-new ms-1"></i>
                  </h5>
                </div>
                <div class="list-meta-content">
                  <span class="text">{{ timestampToDateFormat( item.regDt, 'yyyy-MM-dd')}} 작성</span>
                  <span class="text">{{item.inqCnt}} 조회</span>
                </div>
              </div>
              <div v-if="item.postFileAtchYn === 'Y'" class="board-column column-file"><a href="javascript:" class="file-link"><i class="icon-file"></i></a></div>
            </li>
          </ul>
          <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>
        </div>
        <div v-if="items.length === 0" class="search-result">
          <div class="result-empty">
            <img src="../../../../assets/lxp/images/common/img_empty.png">
            <p class="text">검색결과가 없어요</p>
          </div>
        </div>

      </div>
    </div>
    <!-- //main-content -->
  </main>
  <!-- end::kb-main -->
</template>

<script>
import {onBeforeMount, reactive, ref, watch} from "vue";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {
  getItems,
  getPaging,
  initPaging,
  initProgressBar,
  lengthCheck,
  setParamsByQueryString,
  timestampToDateFormat
} from "@/assets/js/util";
import {ACT_GET_HELP_LIB_LIST} from "@/store/modules/help/help";
import {libTyCdDcds} from "@/assets/js/modules/help/help-common";
import CommonPaginationFront from "@/components/CommonPaginationFront";
import LxpMobileHeader from "@/views/layout/lxp/header/mobile/LxpMobileHeader";
import {pushQuery} from "@/assets/js/ui.init";

export default {
  name: "TakeMobileLib",
  components: {LxpMobileHeader, CommonPaginationFront},
  setup() {

    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const paging = ref(initPaging(route));
    const isSearch = ref(false);

    const items = ref([]);

    const search = reactive({
      postTitle: ''
    });


    const resetParams = () => {
      search.postTitle = '';
    };

    const getTakeLibList = () => {
      store.dispatch(`help/${ACT_GET_HELP_LIB_LIST}`, {
        postTitle: search.postTitle,
        libTyCdDcd: libTyCdDcds.LIB_TY_CD_TAKE_LIB,
        pageNo: paging.value.pageNo,
        pageSize: paging.value.pageSize
      }).then(res => {
        if (lengthCheck(res)) {
          items.value = getItems(res);
          paging.value = getPaging(res);
        } else {
          items.value = [];
          paging.value.totalCount = 0;
        }
      }).catch(e => {
        console.error(e);
      })
    }

    const pagingFunc = (pageNo, pageSize) => {
      const query = {pageNo: pageNo};
      if (pageSize !== paging.value.pageSize) {
        query.pageSize = pageSize;
      }
      if(search.postTitle) query.postTitle = search.postTitle;
      router.push({query: query});
    };

    const clickSearch = () => {
      pagingFunc(1, 10);
    };

    getTakeLibList();

    const toggleSearch = () => {
      if (isSearch.value) {
        pagingFunc();
      } else {
        resetParams();
        isSearch.value = true;
      }
    };

    const clickBack = () => {
      isSearch.value = false;
      resetParams();
      if(Object.keys(route.query).length === 0) getTakeLibList();
      else pushQuery({...search});
    };

    watch(() => route.query, () => {
      setParamsByQueryString(route, 'TakeMobileLib', paging, search, true);
      getTakeLibList();
      initProgressBar();
    });

    onBeforeMount(() => {
      setParamsByQueryString(route, 'TakeMobileLib', paging, search, true);
    });

    return {
      items,
      search,
      paging,
      pagingFunc,
      timestampToDateFormat,
      clickSearch,
      toggleSearch,
      clickBack,
      isSearch
    }
  }
}
</script>