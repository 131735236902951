<template>
  <td>
    <span v-if="stt === '81'" class="td-text">신청완료 {{subText ? subText : ''}}</span>
    <template v-else-if="stt === '00'">
      <span v-if="aplySttCdDcd === '2106003'" class="td-text text-muted">승인완료 {{subText ? subText : ''}}</span>
      <template v-else-if="aplySttCdDcd === '2106006'">
        <span v-if="supportType === 'academy' || supportType === 'school'" class="td-text text-muted">승인완료 {{subText ? subText : ''}}</span>
        <span v-else class="td-text text-muted">지급완료</span>
      </template>
    </template>
    <span v-else-if="stt === '78'" class="td-text text-red">반려</span>
    <template v-else-if="stt === '23'">
      <span v-if="aplySttCdDcd === '2106005'" class="td-text">미신청</span>
      <span v-else-if="aplySttCdDcd === '2106012'" class="td-text">지급예정</span>
    </template>
    <span v-else class="td-text">-</span>
  </td>
</template>

<script>
export default {
  name: 'CommonTdStt',
  props: {
    stt: String,
    aplySttCdDcd: String,
    subText: String,
    supportType: String
  }
};
</script>