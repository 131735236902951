<template>
  <div class="kb-form-dropdown" :class="{'is-active': toggle}" >
    <button class="kb-form-dropdown-btn" :class="buttonClass" @click.stop="toggleOptions" :disabled="isDisabled">
      <span v-if="isRow" class="text">{{ renderText }}</span>
      <strong v-else class="text">{{ renderText }}</strong>

      <i v-if="options.length > 0" class="icon-arrow"></i>
    </button>
    <div v-if="options.length > 0" class="kb-form-dropdown-options" :style="optionStyle ? optionStyle : {}">
      <div class="dropdown-option" :style="widthStyle">
        <ul class="dropdown-option-list">
          <li v-if="isAll" class="dropdown-option-item">
            <a href="javascript:" class="dropdown-option-link" :class="{'is-active': !modelValue}" @click.stop="selectOption()">
              <span class="dropdown-option-text">전체</span>
            </a>
          </li>
          <li v-for="(option, index) in options" class="dropdown-option-item" :key="index">
            <a href="javascript:" class="dropdown-option-link" :class="{'is-active': modelValue === option[sequenceKey]}" @click.stop="selectOption(index)">
              <span class="dropdown-option-text">{{ option[nameKey] }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>

import {computed} from 'vue';

export default {
  name: 'SortButtonSelect',
  props: {
    modelValue: [Number, String],
    toggle: Boolean,
    isAll: Boolean,
    isNum: Boolean,
    options: Array,
    sequenceKey: String,
    nameKey: String,
    title: String,
    width: Number,
    isRow: Boolean,
    buttonClass: String,
    emptyText: String,
    optionStyle: Object,
    isDisabled:  {type:Boolean,default:false},
  },
  emits: ['update:modelValue', 'update:toggle', 'selected'],
  setup(props, {emit}){

    const renderText = computed(() => {
      // Option이 없을 경우 노출
      if(props.options.length === 0 && props.emptyText){
        return props.emptyText;
      }

      const target = props.options.find(x => {
        if (x[props.sequenceKey] === props.modelValue) return x;
      });
      if(target){
        return target[props.nameKey]
      }
      return props.title;
    });

    const widthStyle = computed(() => {
      if(props.width > 0){
        return {width: `${props.width}px`};
      }
      return {};
    });

    const toggleOptions = () => {
      emit('update:toggle', !props.toggle);
    }

    const selectOption = (index) => {
      if(index === undefined){
        emit('update:modelValue', props.isNum ? 0 : '');
      }else{
        emit('update:modelValue', props.options[index][props.sequenceKey]);
      }
      emit('update:toggle', false);
      emit('selected');
    }

    return {
      renderText,
      widthStyle,
      toggleOptions,
      selectOption,

    }
  }
}
</script>
