<template>
  <div v-if="notifications.length > 0" class="kb-toast-popup" :class="{'is-active':show}">
    <div class="toast-popup-box">
      <div class="toast-popup-body">
        <h3 class="toast-popup-title" v-html="notifications[idx].notiTitle"></h3>
        <div class="toast-popup-content mt-2">
          <p class="toast-popup-text">{{ notifications[idx].cloudNmLst }}</p>
        </div>
        <button class="toast-popup-close" @click="closeModal">
          <i class="icon-toast-popup-close"></i>
        </button>
      </div>
      <div class="toast-popup-actions">
        <button class="toast-popup-action-item toast-popup-action-base" @click="goNext">
          <span v-if="paging.hasMore" class="toast-popup-action-text text-brown">다음</span>
          <span class="toast-popup-action-text text-brown">{{ paging.pageNo }}/{{ paging.totalCount }}</span>
        </button>
        <button class="toast-popup-action-item toast-popup-action-primary" @click="handleGo">
          <span class="toast-popup-action-text">보러가기</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {hrdSubscribePopupSetup} from "@/assets/js/modules/hrd/hrd-subscribe-popup-setup";

export default {
  name: "HrdCloudSubscribePopup",
  props: {
    modelValue: Boolean
  },
  emits: ['update:modelValue'],
  setup: hrdSubscribePopupSetup
}
</script>