import {useStore} from 'vuex';
import {useRoute} from 'vue-router';
import {computed, onMounted, reactive, ref, watch} from 'vue';
import {ACT_GET_CTT_CMNT_LST} from '@/store/modules/contents/contents';
import {
  getPaging,
  initProgressBar,
  lengthCheck,
  timestampToDateFormat,
} from '@/assets/js/util';
import {
  confirmDeleteComment,
  confirmInsertComment,
  confirmUpdateComment,
} from '@/assets/js/modules/hrd/hrd-common';

export const hrdCommentSetup = (props) => {
  const store = useStore();
  const route = useRoute();
  const session = computed(() => store.state.auth.session);
  const lrnObjDtlDistSn = computed(() => route.params.lrnObjDtlDistSn);
  const isLoading = ref(false);
  const isDisabled = ref(false);
  const comments = ref([]);
  const paging = reactive({pageNo: 1, pageSize: 10, totalCount: 0, hasMore: false});

  const cmnt = ref('');

  const getCommentList = () => {
    store.dispatch(`contents/${ACT_GET_CTT_CMNT_LST}`,{
      lrnObjDtlDistSn: lrnObjDtlDistSn.value,
      params :{...paging}
    }).then(res => {
      if (lengthCheck(res)) {
        comments.value = res.items.map(x => {
          x.toggle = false;
          return x;
        });
      }
      const resPaging = getPaging(res);
      paging.hasMore = resPaging.hasMore;
      isLoading.value = false;
    }).catch(e => {
      console.error(e);
      isLoading.value = false;
    })
  };

  const pushComment = (cttCmntSn) => {
    comments.value.unshift({
      cttCmntSn,
      cmnt : cmnt.value,
      regDt: new Date().getTime(),
      lrnerId: session.value.lrnerId,
      lrnerNm: session.value.lrnerNm,
      deptNm: session.value.deptNm
    });
    cmnt.value = '';
  };

  const getLrner = (comment) => {
    // return (comment.deptNm || '')+' - '+(comment.jbgdNm || '')+' '+(comment.jbpsNm || '')+' '+comment.lrnerId
    return (comment.deptNm || '')+' - '+(comment.lrnerNm || '');
  };

  const changeMode = (idx) => {
    comments.value[idx].toggle = false;
    if(!comments.value[idx].editMode){
      comments.value[idx].originCmnt = comments.value[idx].cmnt;
    }else{
      // 취소 햇을 때
      comments.value[idx].cmnt = comments.value[idx].originCmnt;
    }
    comments.value[idx].editMode = !comments.value[idx].editMode;
  }

  const handleNewComment = () => {
    confirmInsertComment(isLoading.value, props.objSn, {cmnt: cmnt.value}, pushComment);
  };

  const handleModifyComment = (idx) => {
    confirmUpdateComment(isLoading.value, comments.value[idx]);
  };

  const handleDeleteComment = (idx) => {
    confirmDeleteComment(isLoading.value, comments.value, idx);
  };

  const showMore = () => {
    if (isLoading.value) return;
    isLoading.value = true;
    paging.pageSize = paging.pageSize + 5;
    initProgressBar();
    getCommentList();
  };

  watch(() => props.effectNav, () => {
    comments.value.forEach(x => {
      if (x.toggle) {
        x.toggle = false;
      }
    });
  });

  onMounted(() => {
    if (lrnObjDtlDistSn.value > 0) getCommentList();
  });

  return {
    session,
    isDisabled,
    cmnt,
    comments,
    paging,
    getLrner,
    changeMode,
    handleNewComment, handleModifyComment, handleDeleteComment,
    timestampToDateFormat,
    showMore
  }
}