<template>
  <div v-if="session" class="kb-form-row">
    <div class="kb-form-column kb-form-column-title">
      <label class="kb-form-label">
        <strong class="kb-form-label-text">신청직원</strong>
      </label>
    </div>
    <div class="kb-form-column column-auto">
      <input type="text" class="kb-form-control" :value="`${ session.deptNm } ・ ${ session.jbgdCd } ${ session.jbpsNm } ・ ${ session.lrnerNm } | ${ session.lrnerId }`" readonly />
    </div>
<!--    <div class="kb-form-column column-last">-->
<!--      <button class="kb-btn-link"><span class="text">과거 신청내역 조회</span></button>-->
<!--    </div>-->
  </div>
</template>

<script>
import {useStore} from 'vuex';
import {computed} from 'vue';

export default {
  name: 'Applicant',
  props: {
    aplcnt: {
      type: Object,
      required: false
    }
  },
  setup(props) {
    const store = useStore();
    const session = computed(() => {
      if (props.aplcnt) {
        return props.aplcnt;
      } else {
        return store.state.auth.session;
      }
    });

    return {
      session
    };
  }
};
</script>