<template>
  <div class="content-section">
    <header class="section-header">
      <h3 class="title">학원비 신청이력</h3>
    </header>
    <div class="history-table">
      <div class="list-top">
        <div class="top-column">
          <p class="title text-muted">총 {{ paging.totalCount }}건</p>
        </div>
        <div class="top-column">
          <span class="text text-gold">신청 현황만 표시됩니다. </span>
        </div>
      </div>
      <template v-if="!isLoading && (academies && academies.length > 0)">
        <div class="kb-table kb-table-bordered">
          <table>
            <colgroup>
              <col style="width:70px;">
              <col style="width:150px;">
              <col>
              <col style="width:250px;">
              <col>
              <col>
              <col>
            </colgroup>
            <thead>
            <tr>
              <th><span class="th-title">관리번호</span></th>
              <th><span class="th-title">지원 구분/분야</span></th>
              <th><span class="th-title">과정명</span></th>
              <th><span class="th-title">학습기간</span></th>
              <th><span class="th-title">진행상태</span></th>
              <th><span class="th-title">지원 금액</span></th>
              <th><span class="th-title"> </span></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(acad,idx) in academies" :key="idx">
              <td><span class="td-text">{{ acad.costAcdmFeeSn }}</span></td>
              <td><span class="td-text">{{ acad.lrnCostDivNm }} / {{ acad.lrnCostFldNm }}</span></td>
              <td>
                <div class="td-cell">
                  <strong class="content-title">{{ acad.lrnSprtNm }}</strong>
                </div>
              </td>
              <td><span class="td-text">{{ acad.lrnBgngDd }}~{{ acad.lrnEndDd }}</span></td>
              <CommonTdStt
                  :stt="acad.stt"
                  :aply-stt-cd-dcd="acad.aplySttCdDcd"
                  :sub-text="(acad.proofDivCdAcadCert > 0 || timestampToDateFormat(acad.aplyDt,'yyyyMMdd') < '20200821')? '' : '(결과등록필요)'"
                  support-type="academy"
              />
              <td><span class="td-text">{{ acad.sprtAmt > 0 ? numberComma(acad.sprtAmt) : '-' }}</span>
                <span v-if="acad.rtnYn=='Y' && acad.addAmtYn=='Y'"><br>(환입 및 추가지원)</span>
                <span v-else-if="acad.rtnYn=='Y' && acad.addAmtYn!='Y'"><br>(환입)</span>
                <span v-else-if="acad.rtnYn!='Y' && acad.addAmtYn=='Y'"><br>(추가지원)</span>
              </td>
              <td class="cell-btn">
                <template v-if="timestampToDateFormat(acad.aplyDt,'yyyyMM') >= '202309'">
                  <button class="kb-btn kb-btn-outline" @click="detailFunc('academy_202309', acad.costAcdmFeeSn)"><strong class="text">상세</strong></button>
                </template>
                <template v-else>
                  <button class="kb-btn kb-btn-outline" @click="detailFunc('academy', acad.costAcdmFeeSn)"><strong class="text">상세</strong></button>
                </template>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <more-button
            :paging="paging"
            :more-func="moreFunc"
        />
      </template>
      <div v-else-if="!isLoading && (academies && academies.length === 0)" class="search-container">
        <div class="result-empty">
          <img src="../../assets/lxp/images/common/img_empty.png" alt="">
          <p class="text">신청이력이 없습니다.</p>
        </div>
      </div>
      <LoadingDiv v-if="isLoading"/>
    </div>
  </div>
</template>

<script>
import {numberComma, timestampToDateFormat} from '@/assets/js/util';
import LoadingDiv from '@/components/common/LoadingDiv';
import MoreButton from '@/components/support/common/moreButton';
import CommonTdStt from "@/components/support/common/CommonTdStt";

export default {
  name: 'SupportStatusAcademies',
  components: {CommonTdStt, MoreButton, LoadingDiv},
  props: {
    academies: Array,
    paging: Object,
    moreFunc: Function,
    isLoading: Boolean,
    detailFunc: Function
  },
  setup() {
    return {
      timestampToDateFormat, numberComma,
    }
  }
};
</script>
