import ApiService from '@/assets/js/api.service';

export const ACT_INSERT_LRN_COST_PROOF = 'actInsertLearnCostProof';
// download
export const ACT_DOWNLOAD_COST_PROOF = '';

const state = {};

const mutations = {};

const actions = {
  [ACT_INSERT_LRN_COST_PROOF](context, {params, files}) {
    return new Promise(resolve => {
      ApiService.upload('/v1/app/costproof', files, params, true).then(response => resolve(response))});
  },
  [ACT_DOWNLOAD_COST_PROOF](context, {proofDataSn,fileName}){
    ApiService.download(`/v1/app/costproof`, proofDataSn, null, fileName);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
