import {onMounted, reactive, ref, watch} from "vue";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {getItems, getPaging, initPaging, lengthCheck} from "@/assets/js/util";
import {ACT_GET_CRSE_DIST_FLD} from "@/store/modules/course/course";
import {ACT_GET_GROUP_LRN_LIST} from "@/store/modules/prep/prep";
import navigationUtils from "@/assets/js/navigationUtils";

export const groupApplicationSetup = () => {

    const activeMenu = ref('assetMng');

    const isActive = ref(false);
    const activeLrnFld = ref('ALL');

    const store = useStore();

    const route = useRoute();
    const router = useRouter();
    const items = ref([]);
    const paging = ref(initPaging(route, 10));
    const search = reactive({
        crseInfo: '',
        distCrseLrnFldSn: 0
    });

    const isSearch = ref(false);

    const isReady = ref(false);

    const pagingFunc = (pageNo, pageSize) => {
        const query = {pageNo: pageNo};
        if (pageSize !== paging.value.pageSize) {
            query.pageSize = pageSize;
        }
        if(search.crseInfo) query.crseInfo = search.crseInfo;
        if(search.distCrseLrnFldSn) query.distCrseLrnFldSn = search.distCrseLrnFldSn;

        router.push({query: query});
    };

    const clickSearch = () => {
        isSearch.value = false
        pagingFunc(1, 10);
    }

    const fldToggle = ref(false);
    const crseDistFlds = ref([]);

    const getCrseDistFldList = () => {
        store.dispatch(`course/${ACT_GET_CRSE_DIST_FLD}`).then(res => {
            if(lengthCheck(res)) {
                crseDistFlds.value = getItems(res);
            }
        })
    }

    getCrseDistFldList();

    const getGroupList = () => {
        store.dispatch(`prep/${ACT_GET_GROUP_LRN_LIST}`, {
            crseInfo: search.crseInfo,
            distCrseLrnFldSn: search.distCrseLrnFldSn,
            pageNo: paging.value.pageNo,
            pageSize: paging.value.pageSize
        }).then(res => {
            if (lengthCheck(res)) {
                items.value = getItems(res);
                paging.value = getPaging(res);
            } else {
                items.value = [];
                paging.value.totalCount = 0;
            }

            isReady.value = true;
        }).catch(e => {
            console.error(e);
        })
    }

    onMounted(() => {
        getGroupList();
    })

    watch(()=> activeMenu.value, () => {
        const query = {type: activeMenu.value}
        router.push({query: query})
    });

    watch(()=> route.query, () => {
        if (route.query.pageNo) {
            paging.value.pageNo = route.query.pageNo;
        } else {
            paging.value.pageNo = 1;
        }
        if (route.query.pageSize) {
            paging.value.pageSize = route.query.pageSize;
        }
        getGroupList();
    });

    const selectLrnFld = (lrnFld, distCrseLrnFldSn) => {
        activeLrnFld.value = lrnFld;
        search.distCrseLrnFldSn = distCrseLrnFldSn;

        pagingFunc();
    }


    return{
        activeMenu,
        items,
        paging,
        search,
        route,
        isActive,
        activeLrnFld,
        isReady,
        fldToggle,
        crseDistFlds,
        isSearch,

        pagingFunc,
        clickSearch,
        selectLrnFld,
        isMobile: navigationUtils.any()
    }
}