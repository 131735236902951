<template>
  <div v-if="notifications && notifications.length > 0" class="kb-alertL-container" :class="{'is-active': notifications.length > 0}">
    <div class="kb-alertL">
      <div class="kb-alertL-contents">
        <h3 class="kb-alertL-title">구독중인 채널에 신규 콘텐츠가 <br>업데이트 되었습니다.</h3>
        <p class="kb-alertL-text">({{ timestampToDateFormat(notifications[idx].regDt, 'yyyy-MM-dd') }})</p>
        <p class="kb-alertL-text text-muted">{{ notifications[idx].cloudNmLst }}</p>
      </div>
      <div class="kb-alertL-actions">
        <button class="kb-btn-alertL" @click="goNext"><span class="text">{{ `${paging.hasMore ? '다음' : ''} ${paging.pageNo} / ${paging.totalCount}` }}</span></button>
        <a href="javascript:" class="kb-btn-alertL" @click="handleGo"><span class="text text-gold">보러가기</span></a>
      </div>
      <button class="kb-alertL-close" @click="closeModal"><i class="icon-alertL-close"></i></button>
    </div>
  </div>
</template>

<script>

import {hrdSubscribePopupSetup} from "@/assets/js/modules/hrd/hrd-subscribe-popup-setup";

export default {
  name: "HrdCloudMobileSubscribeNotice",
  setup: hrdSubscribePopupSetup
}
</script>