<template>
  <div class="hrdcloud-list-wrapper" :class="{ 'hrdcloud-list-edit' : isEditable }">
    <slot name="action" />
    <ul class="hrdcloud-list" :data-grid="dataGrid">
      <li v-for="(item,idx) in items" :key="idx" class="hrdcloud-item">
        <HrdCloudEasyContent
            v-if="item.cttTyCdDcd === cttTyCdDcds.CTT_TY_CD_ZIP"
            :item="item"
            :idx="idx"
            :session="session"
            :is-editable="isEditable"
            :is-selected="item.isSelected"
            :show-put="showPut"
            :show-index="showIndex"
            @click.stop="editFunc && editFunc(idx)"
        />
        <HrdCloudContent
            v-else
            :item="item"
            :idx="idx"
            :session="session"
            :is-editable="isEditable"
            :is-selected="item.isSelected"
            :show-put="showPut"
            :show-index="showIndex"
            @click.stop="editFunc && editFunc(idx)"
        />
      </li>
    </ul>
    <CommonPaginationFront v-if="paging" :paging="paging" :page-func="pagingFunc" :page-list-size="isMobile ? 5 : 10"/>
  </div>
</template>

<script>
import HrdCloudContent from '@/components/hrdcloud/HrdCloudContent';
import CommonPaginationFront from '@/components/CommonPaginationFront';
import HrdCloudEasyContent from '@/components/hrdcloud/HrdCloudEasyContent.vue';
import {cttTyCdDcds, disableMyAddChanged} from '@/assets/js/modules/hrd/hrd-common';
import navigationUtils from '@/assets/js/navigationUtils';
import {computed, toRefs, watch} from 'vue';
import {useStore} from 'vuex';

export default {
  name: 'HrdCloudRow',
  components: {HrdCloudEasyContent, CommonPaginationFront, HrdCloudContent},
  props: {
    dataGrid: {
      type: [String, Number],
      default: '4'
    },
    items: Array,
    session: Object,
    showPut: Boolean,
    showIndex: Boolean,
    paging: Object,
    pagingFunc: Function,
    pageType: {
      type: String,
      default: 'hrdcloud'
    },
    isEditable: Boolean,
    editFunc: Function
  },
  setup(props) {
    const store = useStore();
    const leaderAdd = computed(() => store.state.hrdcloud.leaderAdd);
    const {items} = toRefs(props);

    watch(() => leaderAdd.value.show, () => {
      if (!leaderAdd.value.show && leaderAdd.value.updateMyAdd) {
        const lrnObjDtlDistSn = leaderAdd.value.lrnObjDtlDistSn;
        const item = items.value && items.value.length > 0 ? items.value.filter(x => x.lrnObjDtlDistSn === lrnObjDtlDistSn)[0] : null;
        if (item) {
          item.put = leaderAdd.value.isPut;
          disableMyAddChanged();
        }

      }
    });

    return {
      cttTyCdDcds,
      isMobile: navigationUtils.any(),
    }
  }
};
</script>