import LxpLayout from '@/views/layout/lxp/LxpLayout';

import PortfolioList from '@/views/pages/otherdept/wm/PortfolioList';
// import navigationUtils from "@/assets/js/navigationUtils";


// const isMobile = navigationUtils.any();

const wmPortfolioRoutes = [
    {
        path: '/wmPortfolio',
        component: LxpLayout,
        children: [
            {
                path: 'list', // 학원
                name: "PortfolioList",
                component: PortfolioList
            },
        ],
    },
];

export const setWMPortfolioRoutes = (routes) => {
    routes.push(...wmPortfolioRoutes);
}