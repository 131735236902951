import {useStore} from "vuex";
import {computed, ref} from "vue";
import {useAlert} from "@/assets/js/modules/common/alert";
import {ACT_GET_QSTN, ACT_INSERT_QUIZ_QSTN} from "@/store/modules/quiz/quiz";
import {getItem, isSuccess, lengthCheck} from "@/assets/js/util";

export const quizMngQstnAddSetup = (props,{emit}) => {
    const store = useStore();
    const qstnNm = ref('');

    const {showMessage, showConfirm} = useAlert();

    const evlQstnOpt = ref([
        {optNo: 1, optNm: '', ansYn: 'Y'},
        {optNo: 2, optNm: '', ansYn: 'N'}
    ]);

    const distCrseSn = computed(() => store.state.quiz.distCrseSn); //차수번호

    const lrnTyEvlMstSn = computed(() => props.quizSn); // 퀴즈번호
    const lrnTyEvlQstnSn = computed(() => props.qstnSn); // 문항번호
    const qstnNo = computed(() => props.qstnIdx); // 문항번호


    const getQstn = () => {
        store.dispatch(`quiz/${ACT_GET_QSTN}`, {
            lrnTyEvlMstSn: lrnTyEvlMstSn.value,
            lrnTyEvlQstnSn: lrnTyEvlQstnSn.value,
        }).then(res => {
            if (lengthCheck(res)) {
                let evlQstn = getItem(res);
                qstnNo.value = evlQstn.qstnNo
                qstnNm.value = evlQstn.qstnNm;
                evlQstnOpt.value = evlQstn.quizOptList;
            } else {
                evlQstnOpt.value = [];
            }
        }).catch(e => {
            console.error(e);
        });
    };

    if(lrnTyEvlQstnSn.value > 0){ // 추가,수정구분
        getQstn();
    }

    // 문항추가
    const addQstn = () => {
        if(props.distCnt > 0){
            showMessage('출제된 퀴즈는 수정할 수 없습니다.');
            return false;
        }

        if(qstnNm.value === ''){
            showMessage('문제 입력하세요.');
            return false;
        }

        let isCrctAnsCheck = false;
        let isOptNmEmpty = false;
        evlQstnOpt.value.forEach(x => {
            if(x.optNm === ''){
                isOptNmEmpty = true;
            }
            if(x.ansYn === 'Y'){
                isCrctAnsCheck = true;
            }
        })

        if(isOptNmEmpty){
            showMessage("보기를 입력해주세요.");
            return false;
        }

        if(!isCrctAnsCheck){
            showMessage('정답을 체크해주세요.');
            return false;
        }

        showConfirm({
            text: props.qstnSn === 0 ? '추가 하시겠습니까?' : '저장 하시겠습니까?',
            callback: () => {
                doInsertQstn();
            }
        });
    }

    const evlOpt = ref([]);

    const doInsertQstn = () => {
        let optIdx = 1;
        evlOpt.value = evlQstnOpt.value.map(x => ({...x, idx: optIdx++}));
        store.dispatch(`quiz/${ACT_INSERT_QUIZ_QSTN}`, {
            qstnNm: qstnNm.value,
            lrnTyEvlQstnSn: lrnTyEvlQstnSn.value, // 문항시퀀스
            lrnTyEvlMstSn: lrnTyEvlMstSn.value, // 퀴즈번호
            lrnEvlMstSn: distCrseSn.value, // 차수번호
            optYn: 'Y',
            optCnt: evlQstnOpt.value.length,
            crctAns: evlOpt.value.filter(x => x.ansYn === 'Y').map(y => y.idx).join(','),
            qstnNo : qstnNo.value, // 인덱스로 착각하면안됨
            quizOptList: evlQstnOpt.value,
        }).then(res => {
            if (isSuccess(res)) {
                showMessage({
                    text : `문항이 ${props.qstnSn === 0 ? '추가' : '저장'} 되었습니다.`,
                    callback: () => {
                        props.getQstnList();
                        closeLayer();
                    }
                });

            }
        }).catch(e => {
            console.error(e);
        });
    };

    // 보기추가
    const addQstnOpt = () => {
        if(evlQstnOpt.value.length >= 5) return false;
        evlQstnOpt.value.push({optNo: evlQstnOpt.value.length+1, optNm: '', ansYn: 'N'})
    }

    // 보기삭제
    const deleteQstnOpt = (idx) => {
        if(evlQstnOpt.value.length < 3) return false;
        evlQstnOpt.value.splice(idx, 1);
        evlQstnOpt.value.filter((item, index) => {  item.optNo = index+1 });
    }

    // 정답체크
    const ansCheck = (idx) => {
        evlQstnOpt.value = evlQstnOpt.value.map(x => ({...x, ansYn: 'N'}));
        evlQstnOpt.value[idx].ansYn = 'Y';

        let optIdx = 1;
        evlOpt.value = evlQstnOpt.value.map(x => ({...x, idx: optIdx++}));
    }

    const closeLayer = () => {
        emit('update:isQstnAddShow', false);
    }

    return {
        qstnNm,
        lrnTyEvlQstnSn,
        evlQstnOpt,
        addQstn,
        addQstnOpt,
        deleteQstnOpt,
        ansCheck,
        closeLayer
    }
}