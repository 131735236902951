<template>
  <article class="roadmap-card" v-if="!isLoading">
    <header class="card-header">
      <h3 class="title">{{item.hrdCloudCateNm}}</h3>
    </header>
    <div v-if="!item.isCompleted" class="roadmap-recmd">
      <div v-if="item.upskillRcmdYn == 'Y'" class="badge badge-red"><span class="badge-text">UpSkill추천</span></div>
      <div v-if="item.reskillRcmdYn == 'Y'" class="badge badge-primary"><span class="badge-text">ReSkill추천</span></div>
    </div>
    <div class="card-visual">
      <div v-if="!isMobile" class="card-shadow"><div class="play-btn" @click.stop="moveToDtl($router, item.hrdCloudCateSn, item.hrdCloudCateLevel)"><i class="icon-play" /></div></div>
      <SSLStarGraph v-if="item.isCompleted" />
      <img v-else class="card-img" :src="item.thumbUrl" alt="" />
    </div>
    <div class="card-contents">
      <div class="d-flex justify-content-between align-items-center w-100">
        <div class="content-info">
          <div class="content w-100">
            <span class="content-title text-skip">{{item.roadmapTitle}}</span>
          </div>
          <div class="d-flex align-items-center">
            <span class="content-text" :class="{'text-yellow':item.isCompleted}">{{item.cttCmptnCount}}/{{item.cttCount}}개</span>
            <span v-if="item.isCompleted" class="content-badge">완료</span>
            <span v-else-if="item.cttCmptnCount > 0" class="content-badge progress-badge">진행중</span>
          </div>
        </div>
        <div v-if="isMobile" class="content-btn">
          <button class="kb-btn kb-btn-dark kb-btn-radius-circle w-100 h-100" @click="moveToDtl($router, item.hrdCloudCateSn, item.hrdCloudCateLevel)">
            <img src="../../assets/lxp/images/common/ic_video_play.svg" alt="시작"/>
          </button>
        </div>
      </div>
    </div>
    <div v-show="roadmap.length > 1" class="card-btn-area">
      <div v-for="(data,idx) in roadmap" :key="idx" class="card-pagination" :class="{'card-pagination-active': data.hrdCloudCateLevel == item.hrdCloudCateLevel}" @click="activeIdx = idx"></div>
    </div>
  </article>
</template>

<script>
import {onMounted, ref, computed, watch} from "vue";
import {useStore} from "vuex";
import SSLStarGraph from '@/components/ssl/graph/SSLStarGraph';
import {moveToDtl} from "@/assets/js/modules/hrd/hrd-cloud-roadmap-setup";
import {getThumbImg} from "@/assets/js/modules/baner/common-baner";

export default {
  name: 'HrdCloudRoadmapCard',
  components: {SSLStarGraph},
  props: {
    data: Object,
  },
  setup(props) {
    const store = useStore()
    const roadmap = computed(() => props.data)
    const isLoading = ref(true)
    const isMobile = computed(() => store.state.auth.isMobile)
    const item = ref(null)
    const activeIdx = ref(-1)

    const getActiveRoadmap = () => {
      isLoading.value = true;
      roadmap.value.map(x => {
        if(x.cttCmptnCount > 0 && x.cttCmptnCount >= x.cttCount){
          x.isCompleted = true;
        }else{
          x.isCompleted = false;
        }
      })
      // 최근 진행한 로드맵
      activeIdx.value = roadmap.value.findIndex(x => x.roadmapLastProgDt === Math.max(...roadmap.value.filter(y => y.isCompleted === false && y.cttCmptnCount > 0).map(z => z.roadmapLastProgDt)));
      // UpSkill추천 로드맵
      if(activeIdx.value < 0){
        activeIdx.value = roadmap.value.findIndex(x => x.upskillRcmdYn == 'Y' && x.isCompleted === false);
      }
      // ReSkill추천 로드맵
      if(activeIdx.value < 0){
        activeIdx.value = roadmap.value.findIndex(x => x.reskillRcmdYn == 'Y' && x.isCompleted === false);
      }
      // 완료한 로드맵의 최종 난이도
      if(activeIdx.value < 0){
        const lastCmptnIdx = roadmap.value.findLastIndex(x => x.isCompleted === true);
        activeIdx.value = lastCmptnIdx >= 0 && lastCmptnIdx + 1 < roadmap.value.length ? lastCmptnIdx + 1 : 0;
      }
    }
    watch(() => activeIdx.value, () => {
      if(activeIdx.value >= 0 && activeIdx.value < roadmap.value.length){
        item.value = roadmap.value[activeIdx.value];
        isLoading.value = false;
      }
      item.value.thumbUrl = getThumbImg(item.value.thumbUrl);
    })

    onMounted(getActiveRoadmap)

    return {
      roadmap,
      item,
      activeIdx,
      isLoading,
      isMobile,
      moveToDtl,
    }
  }
}
</script>

<style>
/* 웹 */
.lxp-layout #kb-hrdcloud .roadmap-card {position:relative; width: 300px; flex:0 0 300px;border-radius:24px;overflow:hidden;}
.lxp-layout #kb-hrdcloud .roadmap-card .card-visual {overflow:hidden;z-index:2;position:relative;height:500px;border-radius:24px;}
.lxp-layout #kb-hrdcloud .roadmap-card .card-visual .chart-star {position:relative;width:100%;height:500px;border-radius:24px;background-color: var(--kb-yellow);}
.lxp-layout #kb-hrdcloud .roadmap-card .card-contents {z-index:var(--kb-zindex-dropdown);position:absolute;left:0;bottom:0;width:100%;height:165px;padding:48px 32px;background-color:var(--kb-background-brown);border-radius:22px;}
.lxp-layout #kb-hrdcloud .roadmap-card .content-title {font-family: var(--kb-font-KBFGTextB); color: #84888b; line-height: 19px; margin-bottom: 1rem;}
.lxp-layout #kb-hrdcloud .roadmap-card .content-text {font: bold 24px/30px var(--kb-font-KBFGTextB);}
.lxp-layout #kb-hrdcloud .roadmap-card .content-badge {font: bold 0.8rem/1rem var(--kb-font-KBFGTextB); margin-left: 1rem; padding: 0.5rem; border-radius: 1rem; color: var(--kb-yellow); background-color: var(--kb-black);}
.lxp-layout #kb-hrdcloud .roadmap-card .card-btn-area {position: absolute; top: 40px; right: 30px; z-index: var(--kb-zindex-dropdown);}
.lxp-layout #kb-hrdcloud .roadmap-card .card-btn-area .card-pagination {width: 10px; height: 10px; border-radius:5px;}
.lxp-layout #kb-hrdcloud .roadmap-card .content-info {width: 100%;}
/* 모바일 */
.lxp-mobile-layout #kb-hrdcloud .roadmap-card {position:relative; width:95%; margin:auto; border-radius:24px; overflow:hidden;}
.lxp-mobile-layout #kb-hrdcloud .roadmap-card .card-visual {overflow:hidden;z-index:2;position:relative;height:415px;border-radius:24px}
.lxp-mobile-layout #kb-hrdcloud .roadmap-card .chart-star {position:relative;width:100%;height:415px;border-radius:24px;background-color: var(--kb-yellow);}
.lxp-mobile-layout #kb-hrdcloud .roadmap-card .card-contents {z-index:var(--kb-zindex-dropdown);position:absolute;left:0;bottom:0;width:100%;height:100px;padding:25px 32px;background-color:var(--kb-background-brown);border-radius:22px;}
.lxp-mobile-layout #kb-hrdcloud .roadmap-card .content-title {font-weight: bold; line-height: 20px;}
.lxp-mobile-layout #kb-hrdcloud .roadmap-card .content-text {line-height: 20px; font-weight: 300;}
.lxp-mobile-layout #kb-hrdcloud .roadmap-card .content-badge {font: normal 0.8rem/1rem var(--kb-font-KBFGTextB); margin-left: 0.5rem; padding: 5px 10px; border-radius: 1rem; color: var(--kb-yellow); background-color: var(--kb-black);}
.lxp-mobile-layout #kb-hrdcloud .roadmap-card .card-btn-area {position: absolute; top: 42px; right: 35px; z-index: var(--kb-zindex-dropdown);}
.lxp-mobile-layout #kb-hrdcloud .roadmap-card .card-btn-area .card-pagination {width: 16px; height: 16px; border-radius:8px;}
.lxp-mobile-layout #kb-hrdcloud .roadmap-card .content-info {width: calc(100% - 48px);}
/* 공통 */
#kb-hrdcloud .roadmap-card .card-header {z-index:var(--kb-zindex-dropdown);position:absolute;left:0;top:0;padding:48px 32px 0;}
#kb-hrdcloud .roadmap-card .card-header .title {font-size:20px;font-family:var(--kb-font-KBFGDisplayB);white-space:nowrap;color: var(--kb-white);margin-right: 5px;}
#kb-hrdcloud .roadmap-card .card-visual .card-shadow {position: absolute; width: 100%; height: 100%; background-color: var(--kb-silver); opacity: 0.5; display: none; justify-content: center; z-index: var(--kb-zindex-dropdown);}
#kb-hrdcloud .roadmap-card .card-visual .card-img {width: 100%; z-index: 2;}
#kb-hrdcloud .roadmap-card .card-visual .card-shadow .play-btn {cursor: pointer; background-color: rgba(43,51,63,0.7); width: 90px; height: 50px; margin-top:50%; border: 0.06rem solid white; border-radius: 0.5rem; display: flex; align-items: center; justify-content: center;}
#kb-hrdcloud .roadmap-card .card-visual .card-shadow .icon-play {background-image: url(../../assets/lxp/images/common/ic_video_play.svg); width: 24px; height: 24px;}
#kb-hrdcloud .roadmap-card .card-visual .chart-star .star-bg {width:220px;height:220px;border-radius:110px;}
#kb-hrdcloud .roadmap-card .card-visual .chart-star .star-active {position:absolute;left:45px;top:70px;width:220px;height:220px;background:url(../../assets/lxp/images/ssl/home/graph_star_active.png) no-repeat 0 0;background-size:220px}
#kb-hrdcloud .roadmap-card .card-visual:hover {}
#kb-hrdcloud .roadmap-card .card-visual:hover .card-shadow {display: flex;}
#kb-hrdcloud .roadmap-card .roadmap-recmd {position: absolute; top: 75px; left: 30px; z-index: var(--kb-zindex-dropdown);}
#kb-hrdcloud .roadmap-card .content {display: flex; flex-direction: column;}
#kb-hrdcloud .roadmap-card .content-badge.progress-badge {color: var(--kb-black); background-color: var(--kb-yellow);}
#kb-hrdcloud .roadmap-card .content-btn img {object-fit: none;}
#kb-hrdcloud .roadmap-card .card-btn-area .card-pagination {cursor: pointer; margin: 8px 0; background-color: rgba(132,136,139,0.5); transition-property:height, background-color; transition-duration:0.4s; z-index: var(--kb-zindex-dropdown);}
#kb-hrdcloud .roadmap-card .card-btn-area .card-pagination.card-pagination-active {height: 50px; background-color: var(--kb-white);}
#kb-hrdcloud .roadmap-card .content-btn {width: 48px; height: 48px;}
</style>
