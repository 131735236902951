<template>
  <div class="home-section-row">
    <div v-if="currentNotice != null" class="section-notice">
      <section class="home-section">
        <div class="notice-list-wrapper">
          <ul class="notice-list">
            <li class="notice-item">
              <router-link :to="{name: 'SSLNoticeView', params: {socLrnNtcSn: currentNotice.comNtcSn}}" class="notice-link">
                <span class="notice-date">{{ timestampToDateFormat(currentNotice.regDt, 'yyyy.MM.dd') }}</span>
                <span class="notice-text">{{ currentNotice.ntcTitle }}</span>
              </router-link>
            </li>
          </ul>
        </div>
        <div class="notice-more">
          <router-link :to="{name: 'SSLNotice'}" class="notice-more-link">공지 전체보기</router-link>
        </div>
      </section>
      <div class="notice-actions">
        <button class="kb-btn-notice kb-btn-prev" @click="slideButton(false)"><i class="icon-prev"></i></button>
        <button class="kb-btn-notice kb-btn-next" @click="slideButton(true)"><i class="icon-next"></i></button>
      </div>
    </div>
  </div>
</template>
<script>

import {useStore} from 'vuex';
import {computed, ref} from 'vue';
import {ACT_GET_SSL_NTC_LIST} from '@/store/modules/ssl/ssl-index';
import {dateToDateFormat, getItems, lengthCheck, timestampToDateFormat} from '@/assets/js/util';
import {expsrLocCds} from '@/assets/js/modules/help/help-common';

export default {
  name: 'SSLHomeNotice',
  components: {},
  setup() {
    const store = useStore();
    const items = ref([]);
    const currentIdx = ref(0);

    const currentNotice = computed(() => {
      if(items.value[currentIdx.value]){
        return items.value[currentIdx.value];
      }
      return null;
    });

    const getHomeNoticeList = () => {
      store.dispatch(`ssl/${ACT_GET_SSL_NTC_LIST}`, {
        nowDate: dateToDateFormat(new Date(), 'yyyy-MM-dd'),
        expsrLocCd: `${expsrLocCds.EXPSR_LOC_CD_SSL_COMMON},${expsrLocCds.EXPSR_LOC_CD_SSL_MAIN}`,
        pageNo: 1,
        pageSize: 5
      }).then(res => {
        if(lengthCheck(res)){
          items.value = getItems(res);
        }
      }).catch(() => {});
    }

    const slideButton = (isNext) => {
      if(isNext){
        const nextIdx = currentIdx.value + 1;
        if(nextIdx > (items.value.length - 1)){
          currentIdx.value = 0;
        }else{
          currentIdx.value = nextIdx;
        }
      }else{
        const prevIdx = currentIdx.value -1;
        if(prevIdx < 0){
          currentIdx.value = (items.value.length - 1);
        }else{
          currentIdx.value = prevIdx;
        }
      }
    }

    getHomeNoticeList();

    return {
      currentNotice,
      timestampToDateFormat,
      slideButton

      // modules: [Navigation],
    }

  }
}
</script>
