<template>
  <div class="kb-form-row" :class="{ 'kb-form-receipt':isPayment }">
    <label class="kb-form-label">
      <span class="kb-form-label-text">{{ title }}</span>
    </label>
  </div>
  <div class="kb-form-row row-filelist">
    <template v-if="modelValue && modelValue.files && modelValue.files.length > 0">
      <div v-for="(file, idx) in modelValue.files" :key="idx" class="file-text">
        <p :title="file.name" class="file-name kb-form-text kb-mouse-cursor" @click="downloadFile(file[sequenceKey], file.name)">{{ file.name }}</p>
        <div v-if="!disabled" class="close">
          <span class="close-text">{{ convertToStorageBytes(file.size) }}</span>
          <i class="icon-delete" @click.stop="handleRemove(idx)" :disabled="disabled"></i>
        </div>
      </div>
    </template>
  </div>
  <div v-if="!disabled" class="kb-form-row row-buttons">
    <button class="kb-btn-content-item" @click.stop="clickUploadBtn">{{ btnTitle }}</button>
  </div>
  <div class="kb-form-column column-last">
    <div class="file-form">
      <input v-if="maxQty > 1" v-show="false"
             ref="uploadFiles" type="file" class="file-kb-form-input"
             :accept="mimeType"
             multiple @change="addFile"
             :disabled="disabled"
      >
      <input v-else v-show="false"
             ref="uploadFiles" type="file" class="file-kb-form-input"
             :accept="mimeType"
             :disabled="disabled"
             @change="addFile"
      >
    </div>
  </div>

</template>

<script>
import {computed, ref, toRefs} from 'vue';
import {convertToStorageBytes, getUrlByRegion} from '@/assets/js/util';
import {useAlert} from '@/assets/js/modules/common/alert';
import {checkExtension, fileLengthCheck, getTotalSize} from '@/assets/js/file-util';
import {downloadCostProof} from '@/assets/js/modules/support/support-common';

export default {
  name: 'MobileUpload',
  props: {
    modelValue: {
      type: Object,
      default: () => ({
        files: [],
        removed: []
      })
    },
    title: String,
    placeholder: String,
    btnTitle: String,
    maxQty: {
      type: Number,
      required: false,
      default: 0
    },
    maxSize: {
      type: Number,
      required: false,
      default: 0
    },
    sequenceKey: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: false,
      default: 'image'
    },
    extensions: {
      type: Array,
      required: false,
      default: () => ([])
    },
    isPayment: {
      type: Boolean,
      required: false,
      default: false
    },
    subtext: String,
    disabled: {type:Boolean, default:false}
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const {showMessage} = useAlert();
    const {modelValue, extensions, maxQty, maxSize, sequenceKey} = toRefs(props);
    const mimeType = computed(() => {
      // if (props.type === 'image')
      return 'image/*';
    });

    const uploadFiles = ref(null);
    const totalFileSize = computed(() => { return getTotalSize(modelValue.value.files); });
    const fileLimitTxt = computed(() => {
      const texts = [];
      if(maxSize.value > 0){
        texts.push(`파일크기 최대 ${maxSize.value}MB`)
      }
      if(maxQty.value > 0){
        texts.push(`${maxQty.value}개의 파일을 업로드 할 수 있습니다.`)
      }
      return texts.join(', ');
    });

    const addFile = () => {
      if (fileLengthCheck(uploadFiles)) {
        if (extensions.value.length > 0) {
          if (checkExtension(uploadFiles.value.files, extensions.value)) {
            // showMessage(`확장자가 ${extensions.value.join(', ')} 인 파일만 등록 가능합니다.`);
            showMessage(`이미지파일만 등록 가능합니다.<br>(확장자 : ${extensions.value.join(', ')})`);
            return;
          }
        }

        if(maxQty.value > 0){
          if ((modelValue.value.files.length + uploadFiles.value.files.length) > maxQty.value) {
            showMessage(`첨부파일은 최대 ${maxQty.value}개까지 등록 가능합니다.`);
            uploadFiles.value.value = null;
            return;
          }
        }

        if(maxSize.value > 0){
          // 파일 사이즈 체크
          if (totalFileSize.value + getTotalSize(uploadFiles.value.files) > 1024 * 1024 * maxSize.value) {
            showMessage(`파일 사이즈는 최대 ${maxSize.value}MB 입니다.`);
            uploadFiles.value.value = null;
            return;
          }
        }

        const files = [];
        for(let i=0; i < uploadFiles.value.files.length; i++){
          files.push(uploadFiles.value.files[i]);
        }
        const params = {
          files: [...modelValue.value.files, ...files],
          removed: modelValue.value.removed
        };
        params['binaries'] = params.files.filter(x => !x[sequenceKey.value]);

        emit('update:modelValue', params);
        uploadFiles.value.value = null;
      }
    };

    const handleRemove = (idx) => {
      if (modelValue.value.files.length > 0) {
        const removed = [];

        // insert 되어있는것중
        if(modelValue.value.files[idx][sequenceKey.value]) removed.push({[sequenceKey.value]: modelValue.value.files[idx][sequenceKey.value]});

        const target = [...modelValue.value.files];
        target.splice(idx,1);

        emit('update:modelValue', {
          files: target,
          removed: [...modelValue.value.removed, ...removed],
          binaries: target.filter(x => !x[sequenceKey.value])
        });
      }
    };

    const clickUploadBtn = () => {
      if (uploadFiles.value) {
        uploadFiles.value.click();
      }
    };

    const downloadFile = (key, fileNm) => {
      if (props.sequenceKey === 'proofDataSn') {
        if (key > 0) {
          return downloadCostProof(key, fileNm);
        }
      }
    };

    return {
      mimeType,
      uploadFiles, fileLimitTxt,
      convertToStorageBytes,
      addFile, handleRemove, clickUploadBtn, getUrlByRegion, downloadFile
    }
  }
};
</script>