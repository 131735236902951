const commonUpload = (e, a, n, s) => new Promise((t, o) => {
  const r = new FormData;
  r.append('uploadFile', n), r.append('params',
      new Blob([JSON.stringify(s)], {type: 'application/json'}));
  const l = new XMLHttpRequest;
  l.timeout = 6e5, l.responseType = 'json', l.open('POST', `${e}`,
      !0), l.setRequestHeader('Access-Control-Allow-Origin', '*'), a.forEach(
      e => {
        l.setRequestHeader(e.key, e.value);
      }), l.addEventListener('load', () => {
    const e = l.response;
    return t(e);
  }), l.addEventListener('error', () => o(l.response)), l.send(r);
}), defaultParams = {
  manageKey: null,
  uploadId: null,
  uploadKey: null,
  moveKey: null,
  buketNm: null,
  targetTags: [],
}, defaultOptions = {headers: [], chunkSize: 8388608};
export const splitUpload = async (e, a, n, s, t) => {
  if (s = Object.assign(defaultParams, s), a = Object.assign(defaultOptions,
      a), !defaultParams.manageKey) return {
    code: '500',
    message: 'Missing Manage Key',
  };
  s.targetTags = [];
  const o = n.name, r = t instanceof Function, l = n.size / a.chunkSize > 0 ?
      Math.floor(n.size / a.chunkSize) + 1 :
      Math.floor(n.size / a.chunkSize);
  let i = 1;
  try {
    for (let c = 0; c < n.size; c += a.chunkSize) {
      const d = {
            firstPart: 0 === c,
            lastPart: c + a.chunkSize >= n.size,
            partNo: i,
            fileName: o, ...s,
          }, u = n.slice(c, c + a.chunkSize),
          p = await commonUpload(e, a.headers, u, d);
      if (!p || 200 !== p.code || !p.data) return {
        code: 500,
        msg: 'Upload Fail',
      };
      {
        const e = p.data;
        Object.keys(s).forEach(a => {
          null !== e[a] && void 0 !== e[a] && (s[a] = e[a]);
        });
      }
      if (r) {
        let e = Math.round(100 * (i / l * 100 + Number.EPSILON)) / 100;
        e > 100 && (e = 100), t(e);
      }
      i++;
    }
    return {
      code: 200,
      message: 'Success',
      data: {fileName: o, uploadKey: s.moveKey, buketNm: s.buketNm},
    };
  } catch (e) {
    return console.error(e), {code: 500, msg: 'Upload Fail'};
  }
};