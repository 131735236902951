<template>
  <header class="kb-header" id="kb-header">
    <div class="header-component">
      <!-- header-util -->
      <div class="header-column header-util left">
        <div class="util util-back">
          <a href="javascript:" class="util-actions util-actions-back" @click="goBack">
            <i class="icon-history-back"></i>
          </a>
        </div>
        <div class="util util-search-input">
          <input v-model="keyword" type="text" :placeholder="placeholder" @keypress.enter="pagingFunc(1)">
        </div>
      </div>
      <!-- //header-util -->
      <!-- header-util -->
      <div class="header-column header-util right" @click="pagingFunc(1)" >
        <div class="util util-search">
          <a href="javascript:" class="util-actions util-actions-search">
            <i class="icon-search"></i>
          </a>
        </div>
      </div>
      <!-- //header-util -->
    </div>
  </header>
  <main class="kb-main" id="kb-support">
    <!-- main-content -->
    <div class="main-content main-component">
      <div class="search-result">
        <div class="view-contents">

          <!-- content-section -->
          <section v-for="(item, idx) in items" :key="idx" class="content-section">
            <div class="content-item bd-clear pd-clear">
              <div class="title"></div>
              <div class="desc">
                <p><b>{{ item[nm] }}</b></p>
              </div>
              <div class="kb-btn-actions">
                <button class="kb-btn-support" @click="handleSelect(idx)">{{ selectButtonText }}</button>
              </div>
            </div>

          </section>
        </div>
      </div>
    </div>
    <!-- //main-content -->
    <CommonPaginationFront :paging="paging" :page-func="pagingFunc" :page-list-size="5"/>
  </main>
</template>

<script>
import {onMounted, reactive, ref, toRef} from "vue";
import {getCollegeList, getMajorList} from "@/assets/js/modules/support/support-common";
import {initParams, numberComma} from "@/assets/js/util";
import {useStore} from "vuex";
import CommonPaginationFront from "@/components/CommonPaginationFront";
import {MUT_SHOW_HEADER} from "@/store/modules/support/support";


export default {
  name: "MobileSchoolSearch",
  components: {CommonPaginationFront},
  props: {
    modelValue: Boolean,
    selectedValue: Object,
    listType: {
      type: String,
      required: true,
      default: 'college'
    },
    selectButtonText: String,
    placeholder: String
  },
  emits: ['update:modelValue', 'update:selectedValue'],
  setup(props, {emit}) {

    const store = useStore();

    const type = toRef(props, 'listType');
    const items = ref([]);
    const keyword = ref('');
    const paging = reactive({
      pageNo: 1,
      pageSize: 10,
      totalCount: 0
    });
    const title = ref(''), cd = ref(''), nm = ref('');


    const getList = () => {
      if (type.value === 'college') {
        title.value = '학교 검색';
        cd.value='scholCd';
        nm.value='scholNm';
        getCollegeList({keyword: keyword.value, ...paging}, items, paging);
      } else if (type.value === 'major') {
        title.value = '전공 검색';
        cd.value='majorCd';
        nm.value='majorNm';
        getMajorList({keyword: keyword.value, ...paging}, items, paging);
      }
    };

    const pagingFunc = (pageNo) => {
      paging.pageNo = pageNo;
      // items.value = [];
      getList();
    }

    const closeModal = () => { emit('update:modelValue', false); }

    const handleSelect = (idx) => {
      emit('update:selectedValue',items.value[idx]);
      closeModal();
    };

    const goBack = () => {
      initParams(keyword);
      emit('update:modelValue', false);
      store.commit(`support/${MUT_SHOW_HEADER}`);
    }


    onMounted(() => {
      getList();
    });


    return {
      keyword, cd, nm, title,
      items,
      paging,
      pagingFunc,
      handleSelect,
      numberComma,
      goBack
    }
  }
}
</script>