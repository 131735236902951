import ApiService from "@/assets/js/api.service";

export const MUT_TOGGLE_TRAIN_DETAIL = 'toggleTrainDetail';

// ACTION
export const ACT_GET_TRAIN_SHEDULE_LIST = 'actGetTrainSheduleList';
export const ACT_GET_TRAIN_PROCEEDING_LIST = 'actGetProceedingList';
export const ACT_GET_TRAIN_REGULAR_LIST = 'actGetRegularList';

const state = {
    train: {
        show: false,
        item: {},
        callback: null
    },
};

const mutations = {
    [MUT_TOGGLE_TRAIN_DETAIL](state, {show, item, callback}) {
        state.train.show = !!show;
        state.train.show = item;
        state.train.callback = callback;
    },
};

const actions = {

    [ACT_GET_TRAIN_SHEDULE_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa001as', params).then(response => resolve(response))
        });
    },
    [ACT_GET_TRAIN_PROCEEDING_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa001bs', params).then(response => resolve(response))
        });
    },
    [ACT_GET_TRAIN_REGULAR_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa004a', params).then(response => resolve(response))
        });
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
