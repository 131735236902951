import store from '../../../../store';
import {
  ACT_GET_HRDCLOUD_HISTORY_DETAIL,
  ACT_GET_HRDCLOUD_HISTORY_JOB_CONTENTS_LEARN_LIST,
  ACT_GET_HRDCLOUD_HISTORY_LEARN_ROUTINE_LIST_BY_TIME,
  ACT_GET_HRDCLOUD_HISTORY_LEARN_ROUTINE_LIST_BY_WEEKDAYS,
  ACT_GET_HRDCLOUD_HISTORY_MILEAGE_LIST,
  ACT_GET_HRDCLOUD_HISTORY_PROGRESS_LOG_LIST,
} from '@/store/modules/history/history';
import {
  ACT_GET_ATCH_FILE_MANAGE_NO,
  ACT_GET_CONTENTS_MANAGE_NO,
  ACT_GET_CTT_MST_LIST,
  ACT_GET_CTT_TRFM_STT,
  ACT_INSERT_CTT_MST,
  ACT_UPDATE_CTT_MST,
  ACT_UPLOAD_CMS_ATCH_FILE,
  ACT_UPLOAD_CTT_MST,
} from '@/store/modules/contents/contents';
import {getListFunc} from '@/assets/js/ui.init';
import {ACT_DELETE_CRSE_ACT} from '@/store/modules/course/course';
import {timestampToDateFormat} from '@/assets/js/util';
import {cttTyCdDcds} from '@/assets/js/modules/hrd/hrd-common';

export const HRD_ATT_TOKEN = 'kb-hrd-attendance';

export const routineTypes = {
  TIME: 'hour',
  WEEKDAYS: 'week'
};

export const getLrnerId = () => {
  return store.state.auth.session.lrnerId;
};

export const getListByTime = (payload) => {
  return store.dispatch(`history/${ACT_GET_HRDCLOUD_HISTORY_LEARN_ROUTINE_LIST_BY_TIME}`, payload);
};

export const getListByWeekdays = (payload) => {
  return store.dispatch(`history/${ACT_GET_HRDCLOUD_HISTORY_LEARN_ROUTINE_LIST_BY_WEEKDAYS}`, payload);
};

// 0시~24시
export const getTime = () => {
  let cate = [];
  for (let i=0; i<24; i++) {
    cate.push(`${i}시`);
  }
  return cate;
};

export const getWeekdays = (lang) => {
  let cate = [];
  if (lang === 'ko') {
    cate = ['월', '화', '수', '목', '금', '토', '일'];
  }
  return cate;
};

export const getProgressPercent = (contentSize, accumulatedSecond, cttTyCdDcd) => {
  if (contentSize > 0 && accumulatedSecond > 0) {
    const calc = (accumulatedSecond / contentSize) * 100;
    let percent = cttTyCdDcds.CTT_TY_CD_ZIP === cttTyCdDcd ?  Math.ceil(calc) : Math.floor(calc);
    return (percent >= 95 || percent > 100 ? 100 : percent);
  }
  return 0;
};

export const getMlgGiveDt = (item) => {
  if (item) {
    if (item.mlgTyCd && item.mlgRegDt) {
      return `(${timestampToDateFormat(item.mlgRegDt, 'yyyy.MM.dd')})`;
    }
  }
};

export const getSummary = (payload) => {
  return store.dispatch(`history/${ACT_GET_HRDCLOUD_HISTORY_DETAIL}`, payload);
};

export const getProgLogLst = (payload) => {
  return store.dispatch(`history/${ACT_GET_HRDCLOUD_HISTORY_PROGRESS_LOG_LIST}`, payload);
};

export const getCttMngNo = () => {
  return store.dispatch(`contents/${ACT_GET_CONTENTS_MANAGE_NO}`);
};

export const getFileMngNo = () => {
  return store.dispatch(`contents/${ACT_GET_ATCH_FILE_MANAGE_NO}`);
};

export const insertCttMst = (payload) => {
  return store.dispatch(`contents/${ACT_INSERT_CTT_MST}`, payload);
};

export const updateCttMst = (payload) => {
  return store.dispatch(`contents/${ACT_UPDATE_CTT_MST}`, payload);
};

export const uploadContents = (payload) => {
  return store.dispatch(`contents/${ACT_UPLOAD_CTT_MST}`, payload);
};

export const uploadAtch = (payload) => {
  return store.dispatch(`contents/${ACT_UPLOAD_CMS_ATCH_FILE}`, payload);
};

export const getKbTubeList = (payload, items, paging, cb, ccb) => {
  getListFunc(`contents/${ACT_GET_CTT_MST_LIST}`, {servTyCdDcd: '2014004', ...payload, ...paging}, items, paging, cb, ccb);
};

export const deleteCrseActLrnerSumup = (payload) => {
  return store.dispatch(`course/${ACT_DELETE_CRSE_ACT}`, payload);
};

export const getJobCttLrnLst = (payload) => {
  return store.dispatch(`history/${ACT_GET_HRDCLOUD_HISTORY_JOB_CONTENTS_LEARN_LIST}`, payload);
};

export const getMileageList = (payload, items, paging, cb, ccb) => {
  getListFunc(`history/${ACT_GET_HRDCLOUD_HISTORY_MILEAGE_LIST}`, { ...paging, ...payload}, items, paging, cb, ccb);
};

export const getCttTrfmStt = (cttMstSn) => {
  return store.dispatch(`contents/${ACT_GET_CTT_TRFM_STT}`, cttMstSn);
};