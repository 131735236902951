<template>
  <template v-if="modelValue">
    <HrdCloudMobileHeader
        :show-back="true"
        :go-back-func="closeModal"
    >
      <template v-slot:right>
        <div class="util util-btn">
          <a href="javascript:" class="util-actions util-actions-btn text-primary" @click="insertQaAndFiles">저장</a>
        </div>
      </template>
    </HrdCloudMobileHeader>
    <main class="kb-main" id="kb-hrdcloud">
      <div class="main-content main-component">
        <article class="content-section">
          <div class="kb-form-fields">
            <div class="kb-form-field field-border-0">
              <div class="kb-form-row">
                <label class="kb-form-label"><span class="kb-form-label-text">제목</span></label>
              </div>
              <div class="kb-form-row">
                <input v-model="param.inqTitle" type="text" class="kb-form-control" name="" placeholder="제목을 입력하세요" :disabled="true">
              </div>
            </div>
            <div class="kb-form-field">
              <div class="kb-form-row">
                <label class="kb-form-label"><span class="kb-form-label-text">구분</span></label>
              </div>
              <div class="kb-form-row">
                <div class="kb-form-dropdown" data-offset="bottom-left">
                  <button class="kb-form-dropdown-btn"><span class="text">콘텐츠 개선의견</span><i class="icon-arrow"></i></button>
                </div>
              </div>
            </div>
            <div class="kb-form-field">
              <div class="kb-form-row">
                <label class="kb-form-label"><span class="kb-form-label-text">파일첨부</span></label>
              </div>
              <div class="kb-form-row">
                <div v-for="(file, idx) in file.files" class="file-text" :key="idx">
                  <p class="text">{{file.name}}</p>
                  <div class="close">
                    <span class="close-text">{{ convertToStorageBytes(file.size) }} </span>
                    <button @click.stop="removeUpload(file, 'comFileAtchSn', idx)"><i class="icon-del"></i></button>
                  </div>
                </div>
              </div>
              <div class="kb-form-row row-buttons">
                <label class="kb-btn kb-btn-outline kb-btn-content-item kb-form-file-label" for="inp_file2"><span class="text">파일 첨부</span>
                  <InputUpload
                      v-model="file"
                      :max-qty="1"
                      sequence-key="comFileAtchSn"
                  />
                </label>
              </div>
            </div>
            <div class="kb-form-field">
              <div class="kb-form-row">
                <label class="kb-form-label"><span class="kb-form-label-text">개선의견</span><span class="kb-form-byte-text">{{ cnSz }} / 500</span></label>
              </div>
              <div class="kb-form-row">
                <textarea v-model="param.inqCn" name="" class="kb-form-control" placeholder="내용을 입력해 주세요."></textarea>
              </div>
            </div>
          </div>
        </article>
      </div>
    </main>
  </template>
</template>

<script>
import HrdCloudMobileHeader from '@/components/hrdcloud/mobile/HrdCloudMobileHeader';
import {
  cttTableNm,
  insertFileAtch,
  insertQa,
  lightMode,
  removeUpload,
  toggleConcentrationMode,
} from '@/assets/js/modules/hrd/hrd-common';
import {convertToStorageBytes, initParams, isSuccess, isUploadSuccess} from '@/assets/js/util';
import {useAlert} from '@/assets/js/modules/common/alert';
import {computed, reactive, ref, toRefs, watch} from 'vue';
import InputUpload from '@/components/common/InputUpload';
import store from '@/store';
import {MUT_SHOW_HRD_BADGE} from '@/store/modules/hrdcloud/hrdcloud';
import {getHrdBadgeList} from '@/assets/js/modules/hrd/hrd-badge';

export default {
  name: 'HrdCloudMobileOpinion',
  components: {InputUpload, HrdCloudMobileHeader},
  props: {
    modelValue: Boolean,
    title: String,
    cttMstSn: Number,
  },
  setup(props, {emit}) {
    const {showMessage} = useAlert();
    const {cttMstSn, modelValue : show} = toRefs(props);
    const inqTyCdDcds = [{ cd: '2091010', cdNm: '콘텐츠 개선의견' }];

    const param = reactive({
      tblNm: cttTableNm,
      tblSn: (cttMstSn.value ? cttMstSn.value : 0),
      comInqSn: 0,
      inqTitle: props.title,
      inqTyCdDcd: '2091010',
      inqTyNm: '콘텐츠 개선의견',
      inqCn: '',
      readYn: '',
      ansYn: '',
      ansCn: ''
    });

    const file = ref({ files: [], removed: [], binaries: [] });

    const cnSz = computed(() => {
      return (param.inqCn && param.inqCn.length ? param.inqCn.length : 0 );
    });

    const closeModal = () => {
      emit('update:modelValue', false);
    };

    const validate = () => {
      if (!param.inqTitle) {
        showMessage('제목을 입력해주세요.');
        return false;
      }
      if (!param.inqCn) {
        showMessage('내용을 입력해주세요.');
        return false;
      }
      if (param.inqCn.length > 500) {
        showMessage('내용을 500자 이하로 작성해주세요.');
        return false;
      }
      return true;
    };

    const init = () => {
      initParams(param);
      initParams(file.value);
      param.tblNm = cttTableNm;
      param.tblSn = (cttMstSn.value ? cttMstSn.value : 0);
      param.inqTyCdDcd = '2091010';
      param.inqTyNm = '콘텐츠 개선의견';
    };

    const insertQaAndFiles = async () => {
      if (validate()) {
        let qa = await insertQa(param);
        if (isSuccess(qa)) {
          if (file.value.files.length > 0) {
            let fileRes = await insertFileAtch({comInqSn: qa.comInqSn}, file.value.files);
            if (isUploadSuccess(fileRes)) {
              showMessage("콘텐츠 개선의견이 접수되었습니다", () => {
                init();
                closeModal();

                if (qa.badges && qa.badges.length > 0) {
                  store.commit(`hrdcloud/${MUT_SHOW_HRD_BADGE}`,qa.badges);
                  getHrdBadgeList();
                }
              });
            } else {
              showMessage("등록에 실패했습니다. 잠시 후 다시 시도해주세요.");
            }
          } else {
            showMessage("콘텐츠 개선의견이 접수되었습니다", () => {
              init();
              closeModal();

              if (qa.badges && qa.badges.length > 0) {
                store.commit(`hrdcloud/${MUT_SHOW_HRD_BADGE}`,qa.badges);
                getHrdBadgeList();
              }
            });
          }
        } else {
          showMessage("등록에 실패했습니다. 잠시 후 다시 시도해주세요.");
        }
      }
    };

    watch(() => show.value,() => {
      if (show.value) {
        lightMode();
      } else {
        toggleConcentrationMode();
      }
    });

    watch(() => props.title,() => {
      param.inqTitle = props.title;
    });

    return {
      inqTyCdDcds, param, file, cnSz,
      closeModal, insertQaAndFiles, convertToStorageBytes,
      removeUpload
    }
  }
};
</script>