<template>
  <main class="kb-main" id="kb-mypage">
    <div class="main-content min-component">
      <div class="mypage-header"></div>
      <div class="mypage-body">
        <MyPageCertification
            :works="works"
            :learns="learns"
        />
      </div>
    </div>
  </main>
</template>

<script>
import MyPageCertification from "@/components/mypage/MyPageCertification";
import {mypageSetup} from "@/assets/js/modules/mypage/mypage-setup";

export default {
  name: "LinkCertification",
  components: {MyPageCertification},
  setup: mypageSetup
}
</script>