<template>
  <article class="hrdcloud-card" :class="[isEditable && isSelected ? 'is-active' : '' ]">
    <div class="hrdcloud-image">
      <div class="image" @click="lrnStart('uebung')">
        <div class="image-link">
          <CommonLazyImage :img-src="getThumbImg(item.thumb)"/>
        </div>
      </div>
      <div class="badges">
        <div v-if="categoryNms && categoryNms.category2" class="badge-dark">
          <span class="text">{{ categoryNms.category2 }}</span>
        </div>
        <div v-if="item.hrdCloudRoadmapSns && item.hrdCloudRoadmapSns.length > 0" class="badge-green">
          <span class="text">로드맵</span>
        </div>
        <div v-if="(item.cmptnYn === 'Y' || item.lastYearCmptnYn === 'Y')" class="badge-finished ">
          <span class="text">완료</span>
          <i class="icon-check"></i>
        </div>
      </div>
      <div class="hrdcloud-hover-action">
        <div class="hrdcloud-actions">
          <button class="kb-btn kb-btn-outline rounded-lg" @click="lrnStart('demo')"><span class="text">데모시작</span></button>
          <button class="kb-btn kb-btn-outline rounded-lg" @click="lrnStart('uebung')"><span class="text">실습시작</span></button>
        </div>
      </div>
    </div>
    <div class="hrdcloud-content">
      <div class="content-category">
        <span v-if="categoryNms && categoryNms.category3" class="text" @click="searchCategory">
          <a href="javascript:">{{ categoryNms.category3 }}</a>
        </span>
      </div>
      <div class="content-title">
        <h5 class="title" :class="{ 'new' : item.new > 0 }">
          <div :title="item.objNm" class="title-link">
            <span v-if="showIndex" class="me-1">{{idx+1}}.</span><span>{{ item.objNm }}</span>
          </div>
        </h5>
      </div>
      <template v-if="isMobile">
        <div class="content-info-and-meta">
          <div class="content-info">
            <div class="info">
              <i class="icon-views"></i>
              <span class="text">{{ item.inqCnt || 0 }}</span>
            </div>
            <div class="info">
              <i class="icon-like"></i>
              <span class="text">{{ item.lkCnt || 0 }}</span>
            </div>
          </div>
          <div class="content-meta">
            <span class="text">{{ timestampToDateFormat(item.regDt, 'yyyy.MM.dd') }}</span>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="content-info">
          <div class="info">
            <i class="icon-views"></i>
            <span class="text">{{ item.inqCnt || 0 }}</span>
          </div>
          <div class="info">
            <i class="icon-like"></i>
            <span class="text">{{ item.lkCnt || 0 }}</span>
          </div>
        </div>
        <div class="content-meta">
          <span class="text">{{ timestampToDateFormat(item.regDt, 'yyyy.MM.dd') }}</span>
        </div>
      </template>
      <div v-if="showPut" class="content-interest">
        <button class="kb-btn-interest" data-content="담기" @click.prevent.stop="handleActions(item).actMultiPut(session.lrnerId)">
          <i v-if="isMobile" :class="[ item.put ? 'icon-interest-active' : 'icon-interest' ]"></i>
          <i v-else class="icon-interest" :class="{ 'is-active' : item.put }"></i>
        </button>
      </div>
    </div>
  </article>
</template>
<script>
import {getVideoUrl, secondToFormat, timestampToDateFormat} from '@/assets/js/util';
import CommonLazyImage from '@/components/common/CommonLazyImage';
import {getTop1CategoryByLevel, handleActions} from '@/assets/js/modules/hrd/hrd-common';
import {computed, toRef} from 'vue';
import {pushQuery} from '@/assets/js/ui.init';
import {useRoute, useRouter} from 'vue-router';
import {getThumbImg} from '@/assets/js/modules/baner/common-baner';
import {useStore} from 'vuex';
import {useAlert} from '@/assets/js/modules/common/alert';

export default {
  name: 'HrdCloudEasyContent',
  components: {CommonLazyImage},
  props: {
    item: Object,
    idx: Number,
    session: Object,
    showPut: {type:Boolean, default:false},
    showIndex: {type: Boolean, default: false},
    isEditable: Boolean,
    isSelected: Boolean,
    editFunc: Function
  },
  setup(props) {
    const route = useRoute(), router = useRouter(), store = useStore(), {showMessage} = useAlert();
    const isMobile = computed(() => store.state.auth.isMobile);
    const hrd =  toRef(props, "item");
    const categoryNms = computed(() => getTop1CategoryByLevel(hrd.value.cloudNmLst));
    const categorSns = computed(() => getTop1CategoryByLevel(hrd.value.cloudKeyLst));

    const searchCategory = () => {
      let query = {};

      for (let i=1; i < 4; i++) {
        let key = 'category'+(Number(i));
        query[key] = categorSns.value[key];
      }

      pushQuery(query);

      if (route.name !== 'HrdCloud') {
        router.push({name: 'HrdCloud', query: query});
      }
    };

    const lrnStart = (mode) => {
      if (isMobile.value) {
        showMessage('실습에 최적화된 PC에서 학습하세요.');
      } else {
        router.push({name: 'HrdCloudContentBridge', params: {lrnObjDtlDistSn: props.item.lrnObjDtlDistSn, mode:mode}})
      }
    };

    return {
      isMobile, categoryNms, categorSns, handleActions,
      timestampToDateFormat, secondToFormat, getVideoUrl, searchCategory, getThumbImg,
      lrnStart,
    }
  }
};
</script>