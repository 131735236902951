/*
  2037001	: SMS
  2037002	: Wise-Net(사내쪽지)
  2037003	: APPS PUSH
  2037005	: 리브똑똑
  2037006	: Kakao
  2037007	: 포털내부메일
  2037008	: 웹메일
  2037009	: 연수 알리미
*/
export const notiPfmCds = {
  'sms' : '2037001',
  'wiseNet': '2037002',
  'liiv' : '2037005',
  'kakao' : '2037006',
  'wiseEml' : '2037007',
  'webMail' : '2037008',
  'kbMobile' : '2037009',
}