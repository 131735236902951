<template>
  <header v-if="!calendar.show" class="kb-header" id="kb-header">
    <div class="header-component">
      <div v-if="!hideTitle" class="header-column header-title">
        <slot name="title"></slot>
      </div>

      <div class="header-column header-util left">
        <slot name="left"></slot>
      </div>

      <div class="header-column header-util right">
        <slot name="right"></slot>
      </div>
    </div>
  </header>

</template>
<script>

import {computed} from 'vue';
import {useStore} from 'vuex';

export default {
  name: 'SSLMobileSlotHeader',
  components: {},
  props: {
    hideTitle: Boolean,
  },
  setup() {
    const store = useStore();
    const calendar = computed(() => store.state.ssl.calendar);

    return {
      calendar,
    }
  }
}
</script>
