import store from '@/store';
import {
    ACT_GET_LEARN_TARGET_LIST,
    ACT_GET_SOC_BOARD_LIST, ACT_GET_SOC_REL_GROUP_LIST, MUT_SET_GROUP_LIST,
    MUT_SET_LEARN_TARGET_LIST,
} from '@/store/modules/ssl/ssl-index';
import {getItems, getPaging, lengthCheck} from '@/assets/js/util';
import {useStore} from 'vuex';
import {useRoute} from 'vue-router';
import {
    getRouteLrnTrgtGrpMstSn,
    isMyLrnTrgtGrpMstSn,
} from '@/assets/js/modules/ssl/ssl-common';
import {computed, reactive, ref} from 'vue';

export const getLearnTargetList = lrnTrgtGrpMstSn => {
    store.dispatch(`ssl/${ACT_GET_LEARN_TARGET_LIST}`, {lrnTrgtGrpMstSn, customCd: 'ssl'}).then(res => {
        if(lengthCheck(res)){
            setLearnTargetList(getItems(res));
        }else{
            setLearnTargetList([]);
        }
    }).catch(e => {
        console.error(e);
        setLearnTargetList([]);
    });
};

export const setLearnTargetList = (items) => {
    store.commit(`ssl/${MUT_SET_LEARN_TARGET_LIST}`, items);
}

export const getGroupList = () => {
    store.dispatch(`ssl/${ACT_GET_SOC_REL_GROUP_LIST}`).then(res => {
        if(lengthCheck(res)){
            setGroupList(getItems(res));
        }else{
            setGroupList([]);
        }
    }).catch(e => {
        console.error(e);
        setGroupList([]);
    });
}

export const setGroupList = (items) => {
    store.commit(`ssl/${MUT_SET_GROUP_LIST}`, items.map(x => ({...x, lrnerId: x.leaderLrnerId})));
}

export const sslLearnerBoardSetup = () => {
    const store = useStore();
    const route = useRoute();

    const isLoading = ref(true);
    const items = ref([]);
    const paging = reactive({pageNo: 1, pageSize: 12, totalCount: 0, hasMore: false});
    const lrnerId = computed(() => route.params.lrnerId);

    const lrnTrgtGrpMstSn = computed(() => getRouteLrnTrgtGrpMstSn(route.params.lrnTrgtGrpMstSn));
    const isMyLrnTrgt = computed(() => isMyLrnTrgtGrpMstSn(lrnTrgtGrpMstSn.value));

    const getLrnerSocBoardList = (isInit) => {
        if(isInit) items.value = [];
        store.dispatch(`ssl/${ACT_GET_SOC_BOARD_LIST}`, {
            lrnTrgtGrpMstSn: lrnTrgtGrpMstSn.value,
            lrnerId: lrnerId.value,
            pageNo: paging.pageNo,
            pageSize: paging.pageSize
        }).then(res => {
            if(lengthCheck(res)) items.value.push(...getItems(res));
            const resPaging = getPaging(res);
            paging.totalCount = resPaging.totalCount;
            paging.hasMore = resPaging.hasMore;

            isLoading.value = false;
        }).catch(() => {
            isLoading.value = false;
        });
    }

    const moreFunc = () => {
        if(isLoading.value) return;
        isLoading.value = true;
        paging.pageNo++;
        getLrnerSocBoardList();
    }

    getLrnerSocBoardList();

    return {
        isLoading,
        items,
        paging,
        isMyLrnTrgt,
        moreFunc
    }


}
