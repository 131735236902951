<template>
  <div class="detail-row"><HrdCloudBreadcrumb v-if="hrd" :search="hrd" /></div>
  <div class="detail-row">
    <div class="detail-contents">
      <h3 class="title">{{ hrd.objNm }} <i v-if="hrd.isNew" class="icon-new"></i></h3>
      <div class="metadata-container">
        <div class="metadata"><i class="icon-view"></i><span class="text">{{ hrd.inqCnt || 0 }}</span></div>
        <div v-if="servTyCdDcds.SERV_TY_CD_KBTUBE === hrd.servTyCdDcd" class="metadata w-ctt"><span class="text">등록 {{ hrd.cttMstRgtrDeptNm }} {{ hrd.cttMstRgtrNm }} {{ timestampToDateFormat(hrd.regDt, 'yyyy.MM.dd') }}</span></div>
        <div v-else class="metadata w-ctt"><span class="text">등록 {{ timestampToDateFormat(hrd.regDt, 'yyyy.MM.dd') }}</span></div>
        <div v-if="hrd.category1 && hrd.category1 === '1' && hrd.cttDifi" class="metadata w-ctt"><span class="text">{{ lvlStr[hrd.cttDifi] }}</span></div>
        <div class="metadata"><span class="text tag">{{ hrd.tag }}</span></div>
      </div>
    </div>
    <HrdCloudActions
        :hrd="hrd"
        :funcs="funcs"
    />
  </div>
  <div class="detail-row detail-tips">
    <p v-if="cttTyCdDcds.CTT_TY_CD_PDF !== hrd.cttTyCdDcd" class="text ck-content kb-hrd-text" v-html="hrd.mlgDesc" />
    <br/>
    <p class="text kb-hrd-text ck-content" v-html="hrd.cttCn" />
  </div>
  <HrdCloudAtchFile v-if="hrd" :hrd="hrd"/>
</template>
<style>

</style>
<script>
import HrdCloudBreadcrumb from '@/components/hrdcloud/HrdCloudBreadcrumb';
import {timestampToDateFormat} from '@/assets/js/util';
import HrdCloudAtchFile from '@/components/hrdcloud/HrdCloudAtchFile';
import {cttTyCdDcds, handleActions, servTyCdDcds} from '@/assets/js/modules/hrd/hrd-common';
import HrdCloudActions from '@/components/hrdcloud/HrdCloudActions';

export default {
  name: 'HrdPlayerDetails',
  components: {HrdCloudActions, HrdCloudAtchFile, HrdCloudBreadcrumb},
  props: {
    hrd: { type: Object, required: true },
    funcs: Object,
    showHistoryBtn: { type: Boolean, default: true },
  },
  setup() {
    const lvlStr ={
      "1": "초급",
      "3": "중급",
      "5": "고급"
    };

    return {
      timestampToDateFormat,
      handleActions,
      cttTyCdDcds,
      servTyCdDcds,
      lvlStr,
    }
  }
};
</script>