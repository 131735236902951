<template>
  <section class="vow-container">
    <header class="vow-header"><h3 class="title">서약 안내</h3></header>
    <div class="vow-contents">
      <dl class="content-dl">
        <dt class="title">서약 1</dt>
        <dd class="description">하나, 본인은 평가중에 대리평가 등 다른 직원의 도움을 받는 부정행위를 절대 하지 않겠습니다.</dd>
        <dt class="title">서약 2</dt>
        <dd class="description">하나, 본 평가는 1회 응시만 가능하며, 답안 제출시 재평가가 불가능한 점을 잘 알고 있습니다.</dd>
        <dt class="title">서약 3</dt>
        <dd class="description">하나, WIFI, LTE, 5G 응시자의 네트워크 환경에 따라 시간이 지연될 수 있음을 잘 알고 있습니다.</dd>
        <dt class="title">서약 4</dt>
        <dd class="description">하나, 수료기준 점수 미달시 별도로 정한 과정(평가)을 제외하고, 연수 비용이 환입됨을 잘 알고 있습니다.</dd>
      </dl>
    </div>
    <header class="vow-header"><h3 class="title">서약 정보</h3></header>
    <div class="vow-contents">
      <dl class="content-dl">
        <dt class="title">서약정보</dt>
        <dd class="description">{{ session.deptNm }} ・ {{ session.lrnerNm }}</dd>
        <dt class="title">직원번호</dt>
        <dd class="description">{{ session.lrnerId }}</dd>
      </dl>
      <div class="vow-check-cont">
        <p>{{ currentTimeText }} 서약</p>
        <div class="vow-check">
          <label for="result1" class="item-agree">
            상기내용을 숙지하였으며 동의합니다.
          </label>
          <input v-model="isChecked" type="checkbox" class="kb-form-check-input" name="chkMember" id="result1">
        </div>
      </div>
    </div>

    <div class="vow-button">
      <a href="javascript:" class="kb-btn kb-btn-primary" @click="clickPledge"><span class="text">서약하기</span></a>
    </div>
  </section>
</template>

<script>
import {evlPledgeSetup} from '@/assets/js/modules/evaluation/evl-setup';

export default {
  name: 'MobileEvlPledge',
  props: {
    modelValue: Boolean,
    currentTimeText: String
  },
  emits: ['update:modelValue', 'pledge'],
  setup: evlPledgeSetup
};
</script>
