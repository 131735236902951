<template>
<!--  is-visible-->
  <article class="home-section section-my">
    <div class="my-info" :style="isMobile?'justify-content: left;padding-left:20px':''">
      <div style="margin-top:10px">
        <div style="text-align: right"></div> <!-- <a href="javascript:" @click="popAlarmInfo"><i class="icon-alarm"></i></a> -->
        <div v-if="isMobile" class="info-avatar">
          <SSLProfileImg :target="session"/>
        </div>
      </div>
      <div class="info-box">
        <div v-if="!isMobile" class="info-avatar">
          <SSLProfileImg :target="session"/>
        </div>
        <div class="info-content" :class="isMobile?'mt-3':''">
          <span class="name">{{ session.lrnerNm }}</span>
          <span class="team">{{ session.deptNm }}</span>

        </div>

        <div v-if="isMobile" class="status-box mt-3">
          <div  class="status-item">
            <span class="title" style="font-size: 14px">나를 구독한 사람</span>
            <span class="text" style="font-size: 14px">{{ targetSubscribes }}</span>
          </div>
        </div>
        <div v-if="isMobile" class="status-box mt-1">
          <div class="status-item">
            <span class="title" style="font-size: 14px">내가 구독한 사람</span>
            <span class="text" style="font-size: 14px">{{ mySubscribes }}</span>
          </div>
        </div>
      </div>
      <div class="info-box" v-if="!isMobile">
        <div class="myssl-top writing-default mt-lg-4 ">
          <a href="javascript:" class="kb-btn kb-btn-writing" @click="navWrtToggle"><span class="text">글쓰기</span><i class="icon-writing"></i></a>
        </div>
      </div>
      <div  v-if="!isMobile" class="status-box mt-lg-4">
        <div class="status-item">
          <span class="title" >나를 구독한 사람</span>
          <span class="text">{{ targetSubscribes }}</span>
        </div>
        <div class="status-item">
          <span class="title">내가 구독한 사람</span>
          <span class="text">{{ mySubscribes }}</span>
        </div>
      </div>
    </div>
  </article>

  <SSLNavWrt v-if="isNavWrt && !isMobile" />
  <SSLMobileNavWrt v-if="isNavWrt && isMobile" />
</template>
<script>

import {useStore} from "vuex";
import {computed, ref} from "vue";
import SSLProfileImg from "@/components/ssl/common/SSLProfileImg";
import SSLNavWrt from "@/components/ssl/SSLNavWrt";
import SSLMobileNavWrt from "@/components/ssl/mobile/SSLMobileNavWrt"

export default {
  name: 'SSLHomeProfile',
  components: {SSLNavWrt, SSLProfileImg, SSLMobileNavWrt},
  props: {
  },
  setup() {
    const store = useStore();

    const isMobile = computed(() => store.state.auth.isMobile);
    const session = computed(() => store.state.auth.session);
    // 내가 구독한 사용자
    const mySubscribes = computed(() => Array.from(new Set(store.state.ssl.subscribe.my.map(x => x.trgtLrnerId))).length);
    // 나를 구독한 사용자
    const targetSubscribes = computed(() => Array.from(new Set(store.state.ssl.subscribe.target.map(x => x.lrnerId))).length);

    const isNavWrt = ref(false);

    const navWrtToggle=()=>{
      if(isNavWrt.value){
        isNavWrt.value = false;
      }else{
        isNavWrt.value = true;
      }
    }

    return {
      isMobile,
      session,
      mySubscribes,
      targetSubscribes,
      navWrtToggle,
      isNavWrt
    }

  }
}
</script>
