<template>
  <template v-if="isMobile">
    <SSLMobileBadgeModal/>
    <SSLMobileToast/>
  </template>
  <template v-else>
    <SSLBadgeModal/>
    <SSLToast/>
  </template>
  <LxpConfirm/>
  <LxpMessage/>
  <LxpLoading
      v-if="loading"
      :loading="loading"
  />
</template>
<script>


import {computed} from 'vue';
import {useStore} from 'vuex';
import SSLToast from '@/components/ssl/common/SSLToast';
import SSLMobileToast from '@/components/ssl/common/mobile/SSLMobileToast';
import SSLMobileBadgeModal from '@/components/ssl/common/mobile/SSLMobileBadgeModal';
import SSLBadgeModal from '@/components/ssl/common/SSLBadgeModal';
import LxpLoading from '@/components/common/LxpLoading';
import LxpConfirm from '@/components/common/LxpConfirm';
import LxpMessage from '@/components/common/LxpMessage';

export default {
  name: 'SSLPopup',
  components: {
    LxpMessage,
    LxpConfirm,
    LxpLoading,
    SSLBadgeModal,
    SSLMobileBadgeModal, SSLMobileToast, SSLToast},
  setup() {
    const store = useStore();
    const isMobile = computed(() => store.state.auth.isMobile);
    const loading = computed(() => store.state.common.loading);
    return {
      isMobile,
      loading
    }
  }
}
</script>
