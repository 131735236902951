import LxpLayout from "@/views/layout/lxp/LxpLayout";
import navigationUtils from "@/assets/js/navigationUtils";

const fcplRoutes = [
  {
    path: "/fcpl",
    component: LxpLayout,
    children: [
      {
        // path variable 을 생성해야 된다. 임시 라우터 처리
        path: "main",
        name: "FcplMain",
        component: () => import("../../views/pages/fcpl/FcplMain.vue"),
      },
    ],
  },
];

export const setFcplRoutes = (routes) => {
  if (navigationUtils.any()) {
    routes.push(...fcplRoutes);
  } else {
    routes.push(...fcplRoutes);
  }
};
