import LxpLayout from "@/views/layout/lxp/LxpLayout";
import navigationUtils from "@/assets/js/navigationUtils";
import {useAlert} from "@/assets/js/modules/common/alert";
const isMobile = navigationUtils.any();
const {showMessage} = useAlert();

const sumgoRoutes = [
  {
    path: '/sumgo',
    component: LxpLayout,
    children: [
      {
        // path variable 을 생성해야 된다. 임시 라우터 처리
        path: '',
        name: "SumgoMain",
        // component: () => isMobile ? import('../../views/pages/sumgo/mobile/mobileSumgoMain') : import('../../views/pages/sumgo/SumgoMain')
        component: () => isMobile ? showMessage('해당 페이지는 PC로 접속 가능합니다.') : import('../../views/pages/sumgo/SumgoMain')
      }
    ],
  }
];

export const setSumgoRoutes = (routes) => {
  routes.push(...sumgoRoutes);
}