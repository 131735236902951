import {
  computed,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  toRef,
  toRefs,
  watch,
} from 'vue';
import {useStore} from 'vuex';
import {useAlert} from '@/assets/js/modules/common/alert';
import {
  cttTyCdDcds,
  getCateList,
  getCateNm,
  getTop1CategoryByLevel,
  servTyCdDcds,
} from '@/assets/js/modules/hrd/hrd-common';
import {
  convertToStorageBytes,
  getCheckItems,
  getHeader,
  getImg,
  initParams,
  isSuccessFailAlert,
  nullEmpty,
  setParams,
  stringCheck,
} from '@/assets/js/util';
import {
  getCttMngNo,
  getCttTrfmStt,
  getFileMngNo,
  getKbTubeList,
  insertCttMst,
  updateCttMst,
  uploadAtch,
  uploadContents,
} from '@/assets/js/modules/hrd/hrd-history-common';
import {splitUpload} from '@/assets/js/modules/common/split_upload.min';
import {
  ACT_DELETE_CTT_MST,
  ACT_UPLOAD_CMS_THUMBNAIL,
} from '@/store/modules/contents/contents';
import {closeToggles} from '@/assets/js/ui.init';
import ClassicEditor from '@/assets/js/editor/custom-ckeditor';
import {kbtubeEditorConfig} from "@/assets/js/editor/editor-config";

export const checkCttStt = (stt) => {
  const unDeletableStt = ['00','81'];
  return stringCheck(stt) && !(unDeletableStt.includes(stt));
};

export const kbtubeUploadSetup = (props, {emit}) => {
  const isMobile = computed(() => store.state.auth.isMobile);
  const isLoading = ref(false);
  const store = useStore();
  const session = computed(() => store.state.auth.session);
  const {showMessage, showLoading, hideLoading, showConfirm} = useAlert();
  const {modelValue, cttMstSn} = toRefs(props);
  // PC용 Preview Modal, Mobile은 vue에 있음
  const pcPreviewModal = ref(false);
  const items = ref([])
  const cates = ref([]);
  const uploadEvent = toRef(props, 'uploadEvent');

  const cttMstParam = reactive({
    cttMngNo: null,
    cttTitle: '',
    thumb: '',
    fileNm: '',
    etnUrl: '',
    itnUrl: '',
    cttSz: 0,
    cttFileSz: 0,
    stt: '',
    buketNm: '',
    category2: 0, category3: 0, category4: 0, category5: 0,
    categoryNm2: '', categoryNm3: '', categoryNm4: '', categoryNm5: '',
    atrzOpn: '',
    mlgDesc:'',
    trfmSttCdDcd:'',
    tag: '',
    cttCn: '',
    atchFileDomain: null // 빈 객체 있으면 INSERT 해서 null로 준다
  });

  const cttMstStatic = {
    category1: 4,
    cttTyCdDcd: cttTyCdDcds.CTT_TY_CD_VOD,
    servTyCdDcd: servTyCdDcds.SERV_TY_CD_KBTUBE,
    // bgngYmd: getTodayDate('yyyy-mm-dd'),
    // endYmd: dateToDateFormat(new Date(now.setFullYear(now.getFullYear() + 2)), 'yyyy-MM-dd')
  };

  const atchFileParam = reactive({
    atchFileSn: 0,
    fileMngNo: null,
    fileNm: '',
    orgFileNm: '',
    fileSz: 0,
    buketNm: '',
    etnUrl: '',
    itnUrl: '',
    fileTyCdDcd: '', // 미적용
    fileTyNm: '', // 미적용
  });

  const catesLvl2 = computed(() => cates.value.filter(x => x.upHrdCloudCateSn === cttMstStatic.category1 && x.lvl === 2)),
        catesLvl3 = computed(() => cates.value.filter(x => x.upHrdCloudCateSn == cttMstParam.category2 && x.lvl == 3)),
        catesLvl4 = computed(() => cates.value.filter(x => x.upHrdCloudCateSn == cttMstParam.category3 && x.lvl == 4)),
        catesLvl5 = computed(() => cates.value.filter(x => x.upHrdCloudCateSn == cttMstParam.category4 && x.lvl == 5));

  const cttFile = ref({file: []}), atchFile = ref({file: []}), thumbFile = ref({file: []});

  const cttFileInfo = computed(() => {
    if (cttFile.value.file && cttFile.value.file[0]) {
      return cttFile.value.file[0];
    } else if (stringCheck(cttMstParam.fileNm)) {
      return {
        name: cttMstParam.fileNm,
        size: cttMstParam.cttFileSz
      }
    } else {
      return null;
    }
  });

  const atchFileInfo = computed(() => {
    if (atchFile.value.file && atchFile.value.file[0]) {
      return atchFile.value.file[0];
    } else if (stringCheck(atchFileParam.orgFileNm)) {
      return {
        name: atchFileParam.orgFileNm,
        size: atchFileParam.fileSz
      }
    } else {
      return null;
    }
  });

  const toggles = reactive({
    category2: false,
    category3: false,
    category4: false,
    category5: false,
  });

  const thumbSrc = ref(null);
  const hashTag = ref('');
  const hashTags = ref([]);
  const isTranscoding = ref(false);
  const transcodingInterval = ref(null);

  const pageEditor = ref(null);
  let $_instance = null;

  const getCategories = () => {
    getCateList().then(res => {
      cates.value = getCheckItems(res);
    });
  };

  getCategories();

  const setPlaceholder = (idx) => {
    if (idx > 1) return '하위 카테고리 선택';
    else return '카테고리 선택';
  };

  const splitKeys = () => {
    return {
      manageKey: cttMstParam.cttMngNo,
      userSn: session.value.lrnerId
    }
  };

  const closeModal = () => {
    initParams(cttMstParam);
    modelValue.value.toggle();
  };

  const TRFM_ST_CD_BEFORE = '2025001';

  const uploadCttFile = async () => {
    // 파일이 있을때만 업로드 없을수도 있음
    if (cttFile.value.file && cttFile.value.file[0]) {
      // 없을때, 즉 완전 신규일때만 최초 생성
      if (cttMstParam.cttMngNo === null) {
        let cttMngRes = await getCttMngNo();
        cttMstParam.cttMngNo = cttMngRes.cttMngNo;
      }

      if (cttMstParam.cttMngNo != null) {
        if (cttFile.value.file[0].size > 1024 * 1024 * 100) {
          let blobRes = await splitUpload('/v1/app/contents/split', getHeader(), cttFile.value.file[0], splitKeys(), percent => {
            console.log('blob upload : ',percent);
          });
          if (blobRes && blobRes.code === 200) {
            cttMstParam.cttFileSz = cttFile.value.file[0].size;
            cttMstParam.fileNm = blobRes.data.fileName;
            cttMstParam.etnUrl = blobRes.data.uploadKey;
            cttMstParam.itnUrl = blobRes.data.uploadKey;
            cttMstParam.buketNm = blobRes.data.buketNm;
          }
        } else {
          let upRes = await uploadContents({
            params: {cttMngNo: cttMstParam.cttMngNo, contentsType: 'vod', cttTyCdDcd: cttTyCdDcds.CTT_TY_CD_VOD},
            files: cttFile.value.file
          });
          if (upRes && upRes.data && upRes.data.cttMstDomain) {
            cttMstParam.cttFileSz = upRes.data.cttMstDomain.cttFileSz;
            cttMstParam.fileNm = upRes.data.cttMstDomain.fileNm;
            cttMstParam.etnUrl = upRes.data.cttMstDomain.etnUrl;
            cttMstParam.itnUrl = upRes.data.cttMstDomain.itnUrl;
            cttMstParam.buketNm = upRes.data.cttMstDomain.buketNm;
          }
        }
        cttMstParam.stt = '';
        cttMstParam.trfmSttCdDcd = TRFM_ST_CD_BEFORE;
      }
    }
  };

  const uploadAtchFile = async () => {
    if (atchFile.value.file && atchFile.value.file[0]) {
      if (atchFileParam.fileMngNo === null) {
        let fileMngRes = await getFileMngNo();
        atchFileParam.fileMngNo = fileMngRes.fileMngNo;
      }

      if (atchFileParam.fileMngNo != null) {
        let atchRes = await uploadAtch({
          params: { atchFileSn: atchFileParam.atchFileSn, fileMngNo: atchFileParam.fileMngNo },
          files: atchFile.value.file
        });
        if (atchRes && atchRes.data && atchRes.data.atchFileDomain) {
          cttMstParam.atchFileDomain = atchRes.data.atchFileDomain;
        }
      }
    }
  };

  const uploadThumbFile = async () => {
    if (thumbFile.value != null && thumbFile.value.file != null && thumbFile.value.file.length > 0) {
      // 없을때, 즉 완전 신규일때만 최초 생성
      if (cttMstParam.cttMngNo === null) {
        let cttMngRes = await getCttMngNo();
        cttMstParam.cttMngNo = cttMngRes.cttMngNo;
      }

      if (cttMstParam.cttMngNo != null) {
        const thumbRes = await store.dispatch(`contents/${ACT_UPLOAD_CMS_THUMBNAIL}`,{cttMngNo: cttMstParam.cttMngNo, fileList: [thumbFile.value.file[0]]});
        if(thumbRes.url) {
          // 썸네일이 등록되면 file 을 삭제
          cttMstParam.thumb = thumbRes.url;
          thumbFile.value = {file:[]};
        }
      }
    }
  };

  // type - 저장: 0, 제출: 1
  const submitKbTube = async (type) => {
    if (isMobile.value) emit('update:uploadEvent',null);
    // 제출일때만 VALIDATION 체크
    if (type > 0) {
      if (!stringCheck(cttMstParam.cttTitle) || cttMstParam.cttTitle === '임시저장 콘텐츠') {
        showMessage("제목을 입력해주세요.");
        return;
      } else if (cttMstParam.category2 == 0) {
        showMessage("분야를 선택해주세요.");
        return;
      } else if (!stringCheck(hashTags.value)) {
        showMessage("해시태그를 입력해주세요.");
        return;
      } else if (!stringCheck(cttFile.value.file)) {
        if(!stringCheck(cttMstParam.fileNm)) {
          showMessage("파일을 업로드해주세요.");
          return;
        }
      }
    }

    isLoading.value = true;
    showLoading();

    await uploadCttFile();
    await uploadAtchFile();
    await uploadThumbFile();

    cttMstParam.cttTitle = (type === 0 && !cttMstParam.cttTitle) ? '임시저장 콘텐츠' : cttMstParam.cttTitle;
    // HOT Tube만
    let hotTubeCataSn = process.env.NODE_ENV == 'production' ? 1586 : 1426;
    if(cttMstParam.category2 == hotTubeCataSn){
      cttMstParam.mlgDesc = `<p><span style="color:hsl(180,75%,60%);">영업과 관련된 노하우 &amp; 우수사례 &amp; TIP을 한번에! ★</span><br><span style="color:hsl(180,75%,60%);">Hot Tube는 전 직원이 자유롭게 영업노하우를 등재하는 공간입니다.</span></p><p>&nbsp;</p>`;
    }else{
      cttMstParam.mlgDesc = '';
    }
    cttMstParam.mlgDesc += '<p><span style="color:rgb(255,0,0);">진도율과 학습확인(Cold Call) 응답을 모두 충족해야 학습완료 및 마일리지가 인정됩니다.&nbsp;</span></p><p><span style="color:rgb(255,0,0);">※ 전산실습 Easy(실습모드)를 포함하여 1일 2마일리지까지 인정&nbsp;</span></p>';
    cttMstParam.stt = type > 0 ? '81' : '01'; // 저장일때는 01 제출일때는 81
    cttMstParam.tag = stringCheck(hashTags.value) ? hashTags.value.join(',') : null;
    if($_instance) cttMstParam.cttCn = $_instance.getData();
    nullEmpty(cttMstParam); // 빈값 NULL 넣어주기

    let transactionResult;
    const payload = {
      ...cttMstParam,
      ...cttMstStatic
    }
    if (cttMstSn.value > 0) {
      transactionResult = await updateCttMst({cttMstSn: cttMstSn.value, params: payload});
    } else {
      transactionResult = await insertCttMst(payload);
    }

    isLoading.value = false;
    hideLoading();

    if (isSuccessFailAlert(transactionResult)) {
      showMessage(type > 0 ? `제출되었습니다.<br/>My 클라우드 > Hot Tube에서 등록 영상 확인이 가능합니다.` : '저장되었습니다.');
      closeModal();
    }
  };

  // for PC ONLY
  const getToggle = (idx) => {
    if (idx === 0) return toggles.category2;
    if (idx === 1) return toggles.category3;
    if (idx === 2) return toggles.category4;
    if (idx === 3) return toggles.category5;
  };

  // for PC ONLY
  const openToggle = (idx) => {
    if (idx === 0) {
      toggles.category2 = !toggles.category2;
      closeToggles(toggles, 'category2');
    } else if (idx === 1) {
      toggles.category3 = !toggles.category3;
      closeToggles(toggles, 'category3');
    } else if (idx === 2) {
      toggles.category4 = !toggles.category4;
      closeToggles(toggles, 'category4');
    } else if (idx === 3) {
      toggles.category5 = !toggles.category5;
      closeToggles(toggles, 'category5');
    }
  };

  const selectCate = (lvl, idx) => {
    if (lvl === 2) {
      if (isMobile.value) {
        cttMstParam.categoryNm2 = getCateNm(cttMstParam.category2);
      } else {
        cttMstParam.category2 = catesLvl2.value[idx].hrdCloudCateSn;
        cttMstParam.categoryNm2 = catesLvl2.value[idx].hrdCloudCateNm;
      }

      cttMstParam.category3 = 0;
      cttMstParam.categoryNm3 = '';
      cttMstParam.category4 = 0;
      cttMstParam.categoryNm4 = '';
      cttMstParam.category5= 0;
      cttMstParam.categoryNm5= '';
    }
    if (lvl === 3) {
      if (isMobile.value) {
        cttMstParam.categoryNm3 = getCateNm(cttMstParam.category3);
      } else {
        cttMstParam.category3 = catesLvl3.value[idx].hrdCloudCateSn;
        cttMstParam.categoryNm3 = catesLvl3.value[idx].hrdCloudCateNm;
      }

      cttMstParam.category4 = 0;
      cttMstParam.categoryNm4 = '';
      cttMstParam.category5= 0;
      cttMstParam.categoryNm5= '';
    }
    if (lvl === 4) {
      if (isMobile.value) {
        cttMstParam.categoryNm4 = getCateNm(cttMstParam.category4);
      } else {
        cttMstParam.category4 = catesLvl4.value[idx].hrdCloudCateSn;
        cttMstParam.categoryNm4 = catesLvl4.value[idx].hrdCloudCateNm;
      }
      cttMstParam.category5= 0;
      cttMstParam.categoryNm5= '';
    }
    if (lvl === 5) {
      if (isMobile.value) {
        cttMstParam.categoryNm5 = getCateNm(cttMstParam.category5);
      } else {
        cttMstParam.category5 = catesLvl5.value[idx].hrdCloudCateSn;
        cttMstParam.categoryNm5 = catesLvl5.value[idx].hrdCloudCateNm;
      }

    }
    closeToggles(toggles);
  };

  const checkCateList = (idx) => {
    if (idx === 1 && catesLvl2.value && catesLvl2.value.length > 0) return true;
    else if (idx === 2 && catesLvl3.value && catesLvl3.value.length > 0) return true;
    else if (idx === 3 && catesLvl4.value && catesLvl4.value.length > 0) return true;
    else if (idx === 4 && catesLvl5.value && catesLvl5.value.length > 0) return true;
  };

  const getParamCate = (idx) => {
    if (idx === 1) return cttMstParam.categoryNm2;
    else if (idx === 2) return cttMstParam.categoryNm3;
    else if (idx === 3) return cttMstParam.categoryNm4;
    else if (idx === 4) return cttMstParam.categoryNm5;
    else return '';
  };

  const removeFile = (type) => {
    if (type === 'ctt') {
      cttFile.value.file = null;
      cttFile.value.removed = null;
      cttMstParam.fileNm = '';
      cttMstParam.etnUrl = '';
      cttMstParam.itnUrl = '';
      cttMstParam.cttSz = 0;
      cttMstParam.cttFileSz = 0;
      cttMstParam.stt = '';
      cttMstParam.stt = '';
      cttMstParam.trfmSttCdDcd = '';
    } else if (type === 'thumb') {
      thumbFile.value.file = null;
      thumbFile.value.removed = null;
      cttMstParam.thumb = '';
      thumbSrc.value=null;
    } else if (type === 'atch'){
      atchFile.value.file = null;
      atchFile.value.binaries = null;
      atchFile.value.removed = null;
      atchFileParam.orgFileNm = '';
      atchFileParam.fileSz = 0;
      atchFileParam.etnUrl = '';
      atchFileParam.itnUrl = '';
      cttMstParam.atchFileDomain.etnUrl = null;
      cttMstParam.atchFileDomain.itnUrl = null;
      cttMstParam.atchFileDomain.orgFileNm = null;
      cttMstParam.atchFileDomain.fileNm = null;
      cttMstParam.atchFileDomain.fileSz = 0;
      cttMstParam.atchFileDomain.fileTyCdDcd = null;
      cttMstParam.atchFileDomain.fileTyNm = null;
    }
  };

  const addHashTag = () => {
    if(hashTags.value && hashTags.value.length > 9){
      showMessage('해시태그는 최대 10개까지 작성 가능합니다.');
      return;
    }

    if (hashTag.value !== '') {
      hashTags.value.push(hashTag.value);
      hashTag.value = '';
    }
  };

  const deleteHashTag = (idx) => {
    hashTags.value.splice(idx, 1);
  };

  const imgReader = new FileReader();
  imgReader.onload = (event) => {
    thumbSrc.value = event.target.result;
  };

  // 임시저장 AND 변환전 상태일때
  // '01' && '2025001'
  const checkTranscodingStatus = async () => {
    let startDt = new Date().getTime();

    const enableBtn = () => {
      if (isMobile.value) modelValue.value.toggleSubmitBtn(false);
      else isTranscoding.value = false;
      clearInterval(transcodingInterval.value);
    };

    const intervalFunc = async () => {
      let endDt = new Date().getTime();
      // 2시간시 경과 에러
      if ((endDt - startDt) >= 1000 * 60 * 60 * 2) {
        enableBtn();
      }
      const trfmRes = await getCttTrfmStt(cttMstSn.value);
      // 임시저장 및 변환전
      if (trfmRes.result > 0) {
        enableBtn();
      } else {
        if (isMobile.value) modelValue.value.toggleSubmitBtn(true);
        else isTranscoding.value = true;
      }
    }

    if (stringCheck(cttMstParam.etnUrl)) {
      if (cttMstParam.stt === '01') {
        await intervalFunc();
        transcodingInterval.value = setInterval(async () => {
          await intervalFunc();
        },5000);
      }
    } else {
      enableBtn();
    }
  };

  const handleDeleteKbTube = () => {
    showConfirm({
      text: '정말 삭제하시겠습니까?<br><p style="color:red;font-weight:bold;">삭제 후 되돌릴 수 없습니다</p>',
      callback: deleteKbTube
    });
  };

  const deleteKbTube = () => {
    showLoading();
    store.dispatch(`contents/${ACT_DELETE_CTT_MST}`, cttMstSn.value).then(res => {
      if (isSuccessFailAlert(res)) {
        showMessage('삭제되었습니다.', closeModal);
      }
    }).catch(e => {
      console.error(e);
    }).finally(hideLoading);
  };

  let tryCount = 0;
  const setPageEditor = () => {
    if(tryCount > 10) return;
    if(pageEditor.value){
      ClassicEditor.create( pageEditor.value, {...kbtubeEditorConfig, placeholder:'내용을 입력하세요'} ).then(editor => {
        $_instance = editor;
        if(cttMstParam.cttCn) $_instance.setData(cttMstParam.cttCn);
      }).catch(error => {
        console.error(error);
      })
    }else{
      tryCount++;
      setPageEditor();
    }
  }

  watch(() => thumbFile.value, () => {
    if (imgReader !== null && thumbFile.value.file && thumbFile.value.file.length > 0) {
      imgReader.readAsDataURL(thumbFile.value.file[0]);
    }
  });

  // FOR MOBILE ONLY
  // 모바일의 경우 모달창의 위치가 달라서 저장 및 제출 처리 WATCH로 처리
  watch(() => uploadEvent.value, async () => {
    if (isMobile.value) {
      if (uploadEvent.value === 'save') await submitKbTube(0);
      else if (uploadEvent.value === 'submit') await submitKbTube(1);
    }
  });

  onMounted(() => {
    if (cttMstSn.value > 0) {
      showLoading();
      // GETCTTMST :: cttMstSn로 1개만 조회
      getKbTubeList({cttMstSn: cttMstSn.value}, items, {},async () => {
        items.value.map(x => {
          let cateSns = getTop1CategoryByLevel(x.cloudKeyLst), cateNms = getTop1CategoryByLevel(x.cloudNmLst);
          for (let i=1; i<6; i++) {
            x[`category${i}`] = Number(cateSns[`category${i}`]);
            x[`categoryNm${i}`] = cateNms[`category${i}`];
          }
          if (x.cttSz > 0) cttFileInfo.value;
          thumbSrc.value = stringCheck(x.thumb) ? getImg(x.thumb) : null;
          hashTags.value = stringCheck(x.tag) ? x.tag.split(',') : [];
        });

        let item = items.value[0];
        setParams(cttMstParam, item);
        if (item && item.atchFileDomain) setParams(atchFileParam, item.atchFileDomain);
        if (isMobile.value) {
          props.previewModal.update(item);
          props.modelValue.stt = cttMstParam.stt;
        }
        await setPageEditor();
        await checkTranscodingStatus();
        hideLoading();
      }, () => {hideLoading();});
    }else{
      setPageEditor();
    }
  });

  onBeforeUnmount(() => {
    if (isMobile.value) emit('update:uploadEvent',null);
    initParams(cttMstParam);
    if (transcodingInterval.value > 0) {
      clearInterval(transcodingInterval.value);
    }
    if($_instance){
      $_instance.destroy();
      $_instance = null;
    }
  });

  return {
    isLoading, cttMstParam, toggles, pcPreviewModal,
    cttFile, atchFile, catesLvl2, catesLvl3, catesLvl4, catesLvl5,
    cttFileInfo, atchFileInfo, thumbFile, pageEditor,
    submitKbTube, removeFile, getParamCate, selectCate, getToggle, openToggle, checkCateList,
    convertToStorageBytes, closeToggles, closeModal, setPlaceholder,
    hashTag, hashTags, addHashTag, deleteHashTag, thumbSrc,
    isTranscoding,  checkCttStt, handleDeleteKbTube
  }
};