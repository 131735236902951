<template>
  <div class="layer-container" :class="{'is-active': show}">
    <div class="layer" id="layer-completed">
      <div>
        <swiper
            :modules="modules"
            :autoplay="{
                delay: 3000,
                disableOnInteraction: false,
                stopOnLast: true,
            }"
            :pagination="{
              el: '.swiper-pagination',
              clickable: true,
            }"
        >
          <swiper-slide v-for="(badge, idx) in myBadges" class="layer-contents" :key="idx" style="justify-content: center; padding-top: 0px !important">
            <div class="layer-icon icon animate__animated animate__flip" style="padding-top:37px;align-items: center;justify-content: center;text-align: center;">
              <HrdCloudBadge
                  :is-active="true"
                  :badge-ty-cd-dcd="badge.badgeTyCdDcd"
              />
            </div>
            <strong class="layer-title" style="align-items: center;justify-content: center;text-align: center;">축하해요! <br/>{{ badge.badgeTyNm }} 배지를 획득했어요</strong>
          </swiper-slide>

          <div class="swiper-pagination"></div>
        </swiper>
        <button class="layer-close-btn" @click="closeModal" style="z-index:2 !important;cursor:pointer;">
          <i class="icon-close"></i>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import HrdCloudBadge from '@/components/history/hrdcloud/HrdCloudBadge.vue';
import {hrdBadgeSetup} from '@/assets/js/modules/hrd/hrd-badge-setup';

export default {
  name: 'HrdBadgeModal',
  components: {
    HrdCloudBadge,
    Swiper,
    SwiperSlide
  },
  setup: hrdBadgeSetup
}
</script>
