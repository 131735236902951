<template>
  <header class="eTest-header">
    <div class="header-column header-title">
      <h2 class="title">e-Test</h2>
      <p class="subtitle">{{ title }}</p>
    </div>
    <div class="header-column header-util">
      <slot name="count"></slot>
      <div class="util-my">
        <span class="text">{{ session.lrnerNm }}</span>
        <div class="my-avatar">
          <ProfileImg :target="session"/>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import {computed} from 'vue';
import ProfileImg from '@/components/common/ProfileImg';
import {useStore} from 'vuex';

export default {
  name: 'EvlHeader',
  components: {
    ProfileImg,
  },
  props: {
    title: String
  },
  setup() {
    const store = useStore();
    const session = computed(() => store.state.auth.session);
    return {
      session
    }

  }
}
</script>
