<template>
  <div class="popup-container" :class="{ 'is-active' : modelValue }">
    <div class="popup" id="popup-hrdcolud-h0301">
      <div class="popup-inner">
        <header class="popup-header">
          <h3 class="title">콘텐츠 개선의견</h3>
        </header>
        <div class="popup-content">
          <section class="popup-section">
            <header class="section-header">
              <h4 class="title">콘텐츠 정보</h4>
            </header>
            <div class="section-content">
              <div class="kb-form-fields">
                <RowInputText v-model="param.inqTitle" class-lst="column-whole" title="제목" placeholder="제목을 입력하세요" :disabled="true" />
                <InputSelect
                    v-model:value1="param.inqTyCdDcd"
                    v-model:toggle1="toggles.inqTyCdDcds"
                    title="구분"
                    :options1="inqTyCdDcds"
                    :useSubText="false"
                    sequence-key1="cd"
                    nameKey1="cdNm"
                    @update:toggle1="closeToggles(toggles, 'inqTyCdDcds')"
                />
                <Upload
                    v-model="file"
                    title="첨부파일"
                    placeholder="첨부파일을 등록하세요"
                    btn-title="첨부파일 등록하기"
                    :max-qty="10"
                    sequence-key="comFileAtchSn"
                />
              </div>
            </div>
          </section>
          <section class="popup-section">
            <header class="section-header">
              <h4 class="title">콘텐츠 개선의견</h4>
            </header>
            <div class="section-content">
              <div class="segment-box">
                <textarea v-model="param.inqCn" name="" id="" cols="30" rows="6" class="kb-form-control" placeholder="내용을 입력해 주세요."></textarea>
                <div class="textarea-bottom divider">
                  <div class="bottom-column">
                    <span class="counter">{{ cnSz }} / 500</span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="popup-buttons">
          <a href="javascript:" class="kb-btn kb-btn-primary kb-btn-lg" @click="insertQaAndFiles"><span class="text">저장</span></a>
        </div>
      </div>
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click.stop="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, reactive, ref, toRefs} from 'vue';
import RowInputText from '@/components/support/common/RowInputText';
import Upload from '@/components/support/common/Upload';
import InputSelect from '@/components/support/common/InputSelect';
import {closeToggles} from '@/assets/js/ui.init';
import {initParams, isSuccess, isUploadSuccess} from '@/assets/js/util';
import {cttTableNm, insertFileAtch, insertQa} from '@/assets/js/modules/hrd/hrd-common';
import {useAlert} from '@/assets/js/modules/common/alert';
import store from '@/store';
import {MUT_SHOW_HRD_BADGE} from '@/store/modules/hrdcloud/hrdcloud';
import {getHrdBadgeList} from '@/assets/js/modules/hrd/hrd-badge';

export default {
  name: 'HrdCloudOpinionModal',
  components: {InputSelect, Upload, RowInputText},
  props: {
    modelValue: Boolean,
    title: String,
    cttMstSn: Number,
  },
  setup(props, {emit}) {
    const {showMessage} = useAlert();
    const {cttMstSn} = toRefs(props);

    const inqTyCdDcds = [{ cd: '2091010', cdNm: '콘텐츠 개선의견' }];

    const param = reactive({
      tblNm: cttTableNm,
      tblSn: (cttMstSn.value ? cttMstSn.value : 0),
      comInqSn: 0,
      inqTitle: props.title,
      inqTyCdDcd: '2091010',
      inqTyNm: '콘텐츠 개선의견',
      inqCn: '',
      readYn: '',
      ansYn: '',
      ansCn: ''
    });

    const file = ref({ files: [], removed: [], binaries: [] });

    const toggles = reactive({
      inqTyCdDcds: false
    });

    const cnSz = computed(() => {
      return (param.inqCn && param.inqCn.length ? param.inqCn.length : 0 );
    });

    const closeModal = () => {
      emit('update:modelValue', false);
    };

    const validate = () => {
      if (!param.inqTitle) {
        showMessage('제목을 입력해주세요.');
        return false;
      }
      if (!param.inqCn) {
        showMessage('내용을 입력해주세요.');
        return false;
      }
      if (param.inqCn.length > 500) {
        showMessage('내용을 500자 이하로 작성해주세요.');
        return false;
      }
      return true;
    };

    const init = () => {
      initParams(param);
      initParams(file.value);
      param.tblNm = cttTableNm;
      param.tblSn = (cttMstSn.value ? cttMstSn.value : 0);
      param.inqTyCdDcd = '2091010';
      param.inqTyNm = '콘텐츠 개선의견';
    };

    const insertQaAndFiles = async () => {
      if (validate()) {
        let qa = await insertQa(param);
        if (isSuccess(qa)) {
          if (file.value.files.length > 0) {
            let fileRes = await insertFileAtch({comInqSn: qa.comInqSn}, file.value.files);
            if (isUploadSuccess(fileRes)) {
              showMessage("콘텐츠 개선의견이 접수되었습니다", () => {
                init();
                closeModal();

                if (qa.badges && qa.badges.length > 0) {
                  store.commit(`hrdcloud/${MUT_SHOW_HRD_BADGE}`,qa.badges);
                  getHrdBadgeList();
                }
              });
            } else {
              showMessage("등록에 실패했습니다. 잠시 후 다시 시도해주세요.");
            }
          } else {
            showMessage("콘텐츠 개선의견이 접수되었습니다", () => {
              init();
              closeModal();
            });

            if (qa.badges && qa.badges.length > 0) {
              store.commit(`hrdcloud/${MUT_SHOW_HRD_BADGE}`,qa.badges);
              getHrdBadgeList();
            }
          }
        } else {
          showMessage("등록에 실패했습니다. 잠시 후 다시 시도해주세요.");
        }
      }
    };

    return {
      inqTyCdDcds, param, file, toggles, cnSz,
      closeModal, closeToggles, insertQaAndFiles
    }
  }
};
</script>