import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {ref, watch} from "vue";
import {getItems, getPaging, initPaging, lengthCheck, timestampToDateFormat} from "@/assets/js/util";
import {ACT_GET_GROUP_LRN_BOARD_STATUS_LIST} from "@/store/modules/board/board";

export const groupMeetingStatusSetup = () => {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    // 그룹모임
    const items = ref([]);
    const paging = ref(initPaging(route));

    const isReady = ref(false);

    // 그룹모임
    const getGroupLrnBoardStatusList = () => {
        store.dispatch(`board/${ACT_GET_GROUP_LRN_BOARD_STATUS_LIST}`, {
            distCrseSn: route.params.distCrseSn,
            pageNo: paging.value.pageNo,
            pageSize: paging.value.pageSize
        }).then(res => {
            if(lengthCheck(res)) {
                items.value = getItems(res);
                paging.value = getPaging(res);
                isReady.value = true;
            }else {
                items.value = [];
                paging.value.totalCount = 0;
            }
        }).catch(e => {
            console.error(e);
        });
    }


    // 그룹모임 페이징
    watch(() => route.query, () => {
        if (route.name === 'MeetingStatus') {
            if (route.query.pageNo) {
                paging.value.pageNo = route.query.pageNo;
            } else {
                paging.value.pageNo = 1;
            }
            if (route.query.pageSize) {
                paging.value.pageSize = route.query.pageSize;
            }
            getGroupLrnBoardStatusList();
        }
    });

    // 그룹모임 페이징
    const pagingFunc = (pageNo, pageSize) => {
        const query = {pageNo: pageNo};
        if (pageSize !== paging.value.pageSize) {
            query.pageSize = pageSize;
        }
        router.push({query: query});
    };

    getGroupLrnBoardStatusList();

    return {
        isReady,
        items,
        paging,

        pagingFunc,
        timestampToDateFormat,
    }
}