<template>
  <nav class="kb-breadcrumb">
    <ol class="kb-breadcrumb-list">
      <template v-if="isMobile">
        <template v-for="(bread, idx) in breadcrumbs" :key="idx">
          <template v-if="idx > 1">
            <li class="kb-breadcrumb-item"
                :class="{'is-active': getCurrent(idx)}"
                :aria-current="getAriaCurrent(idx)">
              <a href="javascript:" class="kb-breadcrumb-link" @click.prevent.stop="handleBreadcrumb(idx)">
                <span class="kb-breadcrumb-text">{{ getCateNm(bread) }}</span>
              </a>
            </li>
          </template>
        </template>
      </template>
      <template v-else>
        <li v-for="(bread, idx) in breadcrumbs"
            class="kb-breadcrumb-item"
            :class="{'is-active': getCurrent(idx)}"
            :aria-current="getAriaCurrent(idx)"
            :key="idx"
        >
          <a href="javascript:" class="kb-breadcrumb-link" @click.prevent.stop="handleBreadcrumb(idx)">
            <span class="kb-breadcrumb-text">{{ getCateNm(bread) }}</span>
          </a>
        </li>
      </template>
    </ol>
  </nav>
</template>

<script>
import {computed, toRefs, watch} from 'vue';
import {getCateNm} from '@/assets/js/modules/hrd/hrd-common';
import {useRoute, useRouter} from 'vue-router';
import {useStore} from 'vuex';
import {pushQuery} from '@/assets/js/ui.init';

export default {
  name: 'HrdCloudBreadcrumb',
  props: {
    search: Object,
    startIdx: Number
  },
  setup(props) {
    const store = useStore(), route = useRoute(), router = useRouter();

    const isMobile = computed(() => store.state.auth.isMobile);

    const {search} = toRefs(props);

    const breadcrumbs = computed(() => {
      let crumbs = [];
      if (search.value.category1) crumbs.push(search.value.category1);
      if (search.value.category2) crumbs.push(search.value.category2);
      if (search.value.category3) crumbs.push(search.value.category3);
      if (search.value.category4) crumbs.push(search.value.category4);
      if (search.value.category5) crumbs.push(search.value.category5);
      return crumbs;
    });

    const handleBreadcrumb = (destination) => {
      let query = {};
      for (let idx in breadcrumbs.value) {
        if (idx <= destination) {
          let key = 'category'+(Number(idx)+1);
          query[key] = breadcrumbs.value[idx];
          if (idx === destination) break;
        }
      }
      pushQuery(query);

      if (route.name !== 'HrdCloud') {
        router.push({name: 'HrdCloud', query: query});
      }
    };

    const getCurrent = (idx) => {
      if (idx+1 === breadcrumbs.value.length) return true;
      else return false;
    };

    const getAriaCurrent = (idx) => {
      if (idx+1 === breadcrumbs.value.length) return 'page';
      else return '';
    }

    watch(() => breadcrumbs.value, () => {
      // console.log('breadcrumbs : ',breadcrumbs.value);
    });

    return {
      isMobile, breadcrumbs, handleBreadcrumb, getCateNm, getCurrent,
      getAriaCurrent
    }
  }
};
</script>