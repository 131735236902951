<template>
  <template v-if="windowActive">
    <MobileLearnEvaluation
        :evl="targetEvl"
        :is-answer="windowOptions.answer"
        :toggleEnd="toggleEnd"
        @renewInit="renewInitEvl"
        @renewEnd="renewEndEvl"
    />
  </template>
  <template v-else>
  <LxpMobileHeader :headerClass="false">
    <template v-slot:title>
      <SSLMobileNav child-dist-crse-cd="knowledge" />
    </template>
    <template v-slot:left>
      <div class="util util-history-back">
        <a href="javascript:" class="util-actions util-actions-history-back" @click.stop.prevent="goBack"><i class="icon-history-back"></i></a>
      </div>
    </template>
    <template v-slot:right>
      <div class="util util-more">
        <div class="dropdown" :class="{'is-active': toggles.nav}" data-offset="header-left">
          <button class="dropdown-btn" @click="toggles.nav = !toggles.nav"><i class="icon-more"></i></button>
          <div class="dropdown-target" style="width: 144px;left: -104px;">
            <div class="dropdown-box">
              <ul class="dropdown-option-list">
                <li class="dropdown-option-item">
                  <SSLTrial
                      trial-class="dropdown-option-link"
                      text-class="dropdown-option-text"
                  />
                </li>
                <li class="dropdown-option-item">
                  <SSLManual
                      manual-class="dropdown-option-link"
                      text-class="dropdown-option-text"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </template>
  </LxpMobileHeader>
  <main class="kb-main" id="kb-prep">
    <!-- main-content -->
    <div class="main-content" :class="{ 'min-component' : !isMobile }">
        <article class="content-section section-padding" style="padding-top:unset !important;">
          <SSLMobileMyTop child-dist-crse-cd="knowledge"/>
        </article>
      <!-- content-section:학습 현황 -->
        <section class="content-section-wrap">
          <div class="section-divider">
            <div class="divider"></div>
          </div>
          <header class="section-header">
            <h4 class="title">학습 현황</h4>
          </header>
          <div class="section-contents side-pd">
            <ul class="contents-list">
              <li class="contents-item">
                <h5 class="text title">연간평가 참여횟수</h5>
                <span class="text text-gold">{{ participationCount }}/{{ evaluationTotalCount }}회</span>
              </li>
              <li class="contents-item">
                <h5 class="text title">평가 총점</h5>
                <span class="text text-gold">{{ Math.round(evlSum) }}점</span>
              </li>
              <li class="contents-item">
                <h5 class="text title">평가 평균점수</h5>
                <span class="text text-gold">{{(evlSum > 0 && avgCalCount > 0) ? Math.round(evlSum/avgCalCount) : 0}}점</span>
              </li>
            </ul>
          </div>
        </section>
      <!-- content-section:학습자료 -->
        <section class="content-section-wrap">
          <div class="section-divider">
            <div class="divider"></div>
          </div>
          <header class="section-header">
            <h4 class="title">학습자료</h4>
            <router-link :to="{name: 'HrdCloud', query:{sortTyCdDcd: '2073001',category1:'1',category2:'14',category3:'114',category4:'348',category5:'1584' }}" class=" kb-btn kb-btn-secondary kb-btn-xs rounded-xs"><span class="aplytext">AI강의 보러가기</span><i class="icon-arrow-next"></i></router-link>
          </header>
          <div class="textbook-list-container">
            <div class="textbook-list-swiper swiper">
              <ul class="textbook-list swiper-wrapper">
                <!-- textbook-item -->
                <swiper
                    :slides-per-view="dItems.length > 1 ? '2' : 'auto'"
                    :navigation="{
                nextEl: '.kb-btn-data-swiper-next',
                prevEl: '.kb-btn-data-swiper-prev'
              }"
                >
                  <swiper-slide v-for="(item, idx) in dItems" :key="idx" >
                    <li class="textbook-item swiper-slide">
                      <article class="textbook-card">
                        <div class="textbook-image">
                          <a href="javascript:" class="image-link" @click="openContent(item)"></a>
                          <div class="image">
                            <template v-if="item.thumb">
                                <v-lazy-image :src="getThumbUrl(item.thumb)" @error="setDefaultImg($event, idx)" />
                            </template>
                            <template v-else>
                                <v-lazy-image v-if="idx % 4 === 0" :src="jisikeImages[0]" @error="setDefaultImg($event, idx)" />
                                <v-lazy-image v-else-if="idx % 4 === 1" :src="jisikeImages[1]"  @error="setDefaultImg($event, idx)" />
                                <v-lazy-image v-else-if="idx % 4 === 2" :src="jisikeImages[2]"  @error="setDefaultImg($event, idx)" />
                                <v-lazy-image v-else-if="idx % 4 === 3" :src="jisikeImages[3]"  @error="setDefaultImg($event, idx)" />
                            </template>
                          </div>
                        </div>
                        <div class="textbook-content">
                          <div class="content-title">
                            <p class="subtitle">{{ timestampToDateFormat(item.objBgngDt, 'yyyy.MM.dd') }}</p>
                            <h4 class="title"><a href="javascript:" class="title-link" @click="openContent(item)">{{item.objNm}}</a></h4>
                          </div>
                        </div>
                      </article>
                    </li>
                  </swiper-slide>
                </swiper>
              </ul>
            </div>
          </div>
        </section>
      <!-- content-section:진단평가 -->
        <section v-if="evlTestEnabled" class="content-section-wrap">
          <div class="section-divider">
            <div class="divider"></div>
          </div>
          <header class="section-header">
            <h4 class="title">모의테스트</h4>
          </header>
          <div class="section-contents side-pd">
            <ul class="eLearning-status">
              <template v-if="eItems.length > 0">
                <template v-for="(item, idx) in eItems" :key="idx">
                <li v-if="item.lrnEvlMstSn === 4888" class="eLearning-status-item">
                  <h5 class="title">{{ item.lrnEvlNm }}</h5>
                  <div class="meta">
                    <span class="text" v-if="item.fnshYn === 'Y'">참여완료</span>
<!--                    <span class="text" v-else-if="item.comntYn === 'Y' && item.evlBgngDt <= currentTime.getTime() && item.evlEndDt >= currentTime.getTime()"></span>-->
                    <span class="text" v-else-if="item.comntYn === 'Y' && item.evlBgngDt <= currentTime && item.evlEndDt >= currentTime"></span>
<!--                    <span class="text" v-else-if="item.comntYn === 'N' && item.evlEndDt < currentTime.getTime()">미참여</span>-->
                    <span class="text" v-else-if="item.comntYn === 'N' && item.evlEndDt < currentTime">미참여</span>
                    <span class="text">{{timestampToDateFormat(item.evlBgngDt, 'yyyy-MM-dd(w), hh:mm')}}~{{timestampToDateFormat(item.evlEndDt, 'yyyy-MM-dd(w), hh:mm')}}</span>
                  </div>
                  <div class="eLearning-actions">
<!--                    <button v-if="item.evlBgngDt <= currentTime.getTime() && item.evlEndDt >= currentTime.getTime()" class="kb-btn kb-btn-primary eLearning-action-button" :disabled="btnDisabled" @click="setTargetEvaluation(idx, 'E')">-->
                    <button v-if="item.evlBgngDt <= currentTime && item.evlEndDt >= currentTime" class="kb-btn kb-btn-primary eLearning-action-button" :disabled="btnDisabled" @click="setTargetEvaluation(idx, 'E')">
                      <span class="text">평가하기</span>
                    </button>
                  </div>
                </li>
                </template>
              </template>
              <template v-else>
                <li class="eLearning-status-item">
                  <div class="meta justify-content-center">
                    <span class="text"><strong class="text text-muted">지식e러닝 입과자가 아닙니다.</strong></span>
                  </div>
                </li>
              </template>
            </ul>
          </div>
        </section>
        <section class="content-section-wrap">
          <div class="section-divider">
            <div class="divider"></div>
          </div>
          <header class="section-header">
            <h4 class="title">진단평가</h4>
          </header>
          <div class="section-contents side-pd">
            <ul class="eLearning-status">
              <li class="eLearning-status-item">
                <p class="sub-text text-silver">총 {{evaluationTotalCount}}회</p>
              </li>
              <template v-if="eItems.length > 0">
              <template v-for="(item, idx) in eItems" :key="idx">
              <li v-if="item.lrnEvlMstSn !== 4888" class="eLearning-status-item">
                <h5 class="title">{{ item.lrnEvlNm }}</h5>
                <div class="meta">
                  <span class="text" v-if="item.fnshYn === 'Y'">참여완료</span>
<!--                  <span class="text" v-else-if="item.comntYn === 'Y' && item.evlBgngDt <= currentTime.getTime() && item.evlEndDt >= currentTime.getTime()">참여중</span>-->
                  <span class="text" v-else-if="item.comntYn === 'Y' && item.evlBgngDt <= currentTime && item.evlEndDt >= currentTime">참여중</span>
<!--                  <span class="text" v-else-if="item.comntYn === 'N' && item.evlEndDt < currentTime.getTime()">미참여</span>-->
                  <span class="text" v-else-if="item.comntYn === 'N' && item.evlEndDt < currentTime">미참여</span>
                  <span class="text" v-else>-</span>
<!--                  <span v-if="item.evlRlsDt <= currentTime.getTime() && item.qstnSumScore >= 0 && item.comntYn === 'Y'" class="text">{{ `${item.qstnSumScore}점` }}</span>-->
                  <span v-if="item.evlRlsDt <= currentTime && item.qstnSumScore >= 0 && item.comntYn === 'Y'" class="text">{{ `${item.qstnSumScore}점` }}</span>
                  <span v-else class="text">-</span>
                  <span class="text">{{timestampToDateFormat(item.evlBgngDt, 'yyyy-MM-dd(w), hh:mm')}}~{{timestampToDateFormat(item.evlEndDt,'hh:mm')}}</span>
                </div>
                <div class="eLearning-actions">
<!--                  <button v-if="item.evlBgngDt <= currentTime.getTime() && item.evlEndDt >= currentTime.getTime() && item.fnshYn === 'N'" class="kb-btn kb-btn-primary eLearning-action-button" :disabled="btnDisabled" @click="setTargetEvaluation(idx, 'E')">-->
                  <button v-if="item.fnshYn === 'Y'" class="kb-btn eLearning-action-button" @click="setTargetEvaluation(idx, 'A')">
                    <span class="text">답안지</span>
                  </button>
                  <button v-else-if="item.evlBgngDt >= currentTime || item.evlEndDt > currentTime" class="kb-btn kb-btn-primary eLearning-action-button" :disabled="btnDisabled" @click="setTargetEvaluation(idx, 'E')">
                      <span class="text">평가하기</span>
                  </button>
                </div>
              </li>
              </template>
              </template>
              <template v-else>
                <li class="eLearning-status-item">
                  <div class="meta justify-content-center">
                    <span class="text"><strong class="text text-muted">지식e러닝 입과자가 아닙니다.</strong></span>
                  </div>
                </li>
              </template>
            </ul>
          </div>
        </section>
      <!-- content-section:학습일정 -->
        <section class="content-section-wrap">
          <div class="section-divider"><div class="divider"></div></div>
          <header class="section-header"><h4 class="title">학습일정</h4></header>
          <KnowledgeELearningCalendar />
        </section>
    </div>
    <!-- //main-content -->
  </main>
  </template>
</template>
<style scoped>
@import '../../../../assets/custom/ssl.custom2.css';
</style>
<script>
import {computed, onMounted, reactive, ref, watch} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {useStore} from 'vuex';
import {getItems, isSuccess, lengthCheck, timestampToDateFormat, winOpen} from '@/assets/js/util';
import {ACT_GET_KNOWLEDGE_LRN_OBJ_DTL_DIST_LIST} from '@/store/modules/course/course';
import {
  ACT_GET_EVL_SYNC_DT,
  ACT_GET_KNOWLEDGE_LRN_TY_EVL_DIST_LIST,
  ACT_INSERT_LRN_TY_EVL_DIST,
} from '@/store/modules/evaluation/evaluation';
import KnowledgeELearningCalendar from '@/components/prep/KnowledgeELearningCalendar';
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import VLazyImage from 'v-lazy-image';
import {getThumbUrl} from '@/assets/js/modules/course/course-common';
import LxpMobileHeader from '@/views/layout/lxp/header/mobile/LxpMobileHeader';
import navigationUtils from '@/assets/js/navigationUtils';
import {useAlert} from '@/assets/js/modules/common/alert';
import SSLMobileMyTop from '@/components/ssl/my/mobile/SSLMobileMyTop.vue';
import SSLMobileNav from '@/components/ssl/mobile/SSLMobileNav.vue';
import SSLTrial from '@/components/ssl/common/SSLTrial.vue';
import SSLManual from '@/components/ssl/common/SSLManual.vue';
import {Navigation} from 'swiper';
import MobileLearnEvaluation from '@/components/learn/mobile/window/MobileLearnEvaluation.vue';
import { debounce } from 'lodash-es';

export default {
  name: 'KnowledgeELearning',
  components: {
    SSLManual,
    SSLTrial,
    SSLMobileNav,
    SSLMobileMyTop,
    LxpMobileHeader,
    KnowledgeELearningCalendar,
    VLazyImage,
    Swiper,
    SwiperSlide,
    MobileLearnEvaluation
  },
  setup() {
    const activeMenu = ref('learningHome');

    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const dItems = ref([]);
    const eItems = ref([]);

    const evaluationTotalCount = ref(0);
    const participationCount = ref(0);
    const avgCalCount = ref(0);

    const session = computed(() => store.state.auth.session);
    // const currentTime = ref(new Date());
    const currentTime = ref(null);
    const evlSum = ref(0);

    const modelEvlValue = ref(false);

    const {showMessage, showLoading, hideLoading} = useAlert();

    const windowActive = ref(false);
    const windowOptions = reactive({
      answer: false
    });

    const toggles = reactive({
      nav: false
    });

    const target = reactive({
      toggle: false,
      index: -1
    });

    const targetEvl = computed(() => {
      if(eItems.value[target.index]){
        return eItems.value[target.index]
      }
      return {}
    });

    store.state.common.footer.hide = false;
    const learnSn = computed(() => parseInt(route.query.learn || 0));
    const btnDisabled = ref(false);
    const evlTestEnabled = ref(false);

    const setTargetEvaluation = debounce(async (index, type) => {
      showLoading();
      btnDisabled.value = true;
      target.index = index;

      await store.dispatch(`evaluation/${ACT_GET_EVL_SYNC_DT}`, {
      }).then(res => {
        if(isSuccess(res)){
          // console.log('setTargetEvaluation',timestampToDateFormat(res.srvSyncDt, 'yyyy.MM.dd hh:mm:ss'));
          currentTime.value = res.srvSyncDt;
        }
      });

      if(targetEvl.value.fnshYn === 'Y' && targetEvl.value.lrnEvlMstSn !== 4888){
        // if (new Date().getTime() < (targetEvl.value.evlRlsDt)) {
        if (currentTime.value < (targetEvl.value.evlRlsDt)) {
          showMessage('답안지는 평가전체종료 10분후에 공개됩니다.');
          btnDisabled.value = false;
          hideLoading();
          return false;
        }
      } else {
        let basTime = targetEvl.value.evlBgngDt;
        // if (currentDate.value.getTime() < basTime) {
        if (currentTime.value < basTime) {
          showMessage('평가응시 가능시간이 아닙니다.');
          btnDisabled.value = false;
          hideLoading();
          return false;
        }
      }

      if(type === 'A') {
        windowOptions.answer = true;
      }
      else {
        // if(targetEvl.value.lrnTyEvlDistSn === 0 || (targetEvl.value.lrnEvlMstSn === 4888 && targetEvl.value.fnshYn === 'Y')){
        if(targetEvl.value.lrnTyEvlDistSn === 0){
          await store.dispatch(`evaluation/${ACT_INSERT_LRN_TY_EVL_DIST}`, {
            distCrseSn: targetEvl.value.distCrseSn,
            lrnEvlMstSn: targetEvl.value.lrnEvlMstSn,
            lrnRsltSn: targetEvl.value.lrnRsltSn
          }).then(res => {
            if(isSuccess(res)){
              targetEvl.value.lrnTyEvlDistSn = res.lrnTyEvlDistSn;
              if (targetEvl.value.lrnEvlMstSn === 4888) {
                modelEvlValue.value = true;
              }
              getEvaluateList();
            }
          });
        }
      }
      setTimeout(() => {
        target.toggle = true;
        windowActive.value = true;
        btnDisabled.value = false;
        store.state.common.footer.hide = true;
        hideLoading();
      }, 158);
    }, 500);

    const renewInitEvl = (evlBgngDt) => {
      eItems.value[target.index].evlExamBgngDt = evlBgngDt;
    }

    const renewEndEvl = () => {
      // eItems.value[target.index].evlExamEndDt = new Date().getTime();
      eItems.value[target.index].evlExamEndDt = currentTime.value;
      modelEvlValue.value = true;
      windowActive.value = false;
      getEvaluateList();
    }

    const jisikeImages = [
        require('../../../../assets/lxp/images/ssl/myssl/@img_jisike.png'),
        require('../../../../assets/lxp/images/ssl/myssl/@img_jisike_02.jpg'),
        require('../../../../assets/lxp/images/ssl/myssl/@img_jisike_03.jpg'),
        require('../../../../assets/lxp/images/ssl/myssl/@img_jisike_04.jpg')
    ];
    const setDefaultImg = (e, idx) => {
        const imageIndex = idx % 4;
        e.src = jisikeImages[imageIndex];
    };

    const getKnoewledgeLearningList = async () => {
      await store.dispatch(`course/${ACT_GET_KNOWLEDGE_LRN_OBJ_DTL_DIST_LIST}`, {}).then(res => {
        if (lengthCheck(res)) {
          dItems.value = getItems(res);
        } else {
          dItems.value = [];
        }
      }).catch(e => {
        console.error(e);
      });

      await store.dispatch(`evaluation/${ACT_GET_EVL_SYNC_DT}`, {
      }).then(res => {
        if(isSuccess(res)){
          // console.log('getKnoewledgeLearningList',timestampToDateFormat(res.srvSyncDt, 'yyyy.MM.dd hh:mm:ss'));
          currentTime.value = res.srvSyncDt;
        }
      });
    }

    const getEvaluateList = async () => {
      await store.dispatch(`evaluation/${ACT_GET_EVL_SYNC_DT}`, {
      }).then(res => {
        if(isSuccess(res)){
          // console.log('getEvaluateList',timestampToDateFormat(res.srvSyncDt, 'yyyy.MM.dd hh:mm:ss'));
          currentTime.value = res.srvSyncDt;
        }
      });
      
      await store.dispatch(`evaluation/${ACT_GET_KNOWLEDGE_LRN_TY_EVL_DIST_LIST}`).then(res => {
        evlSum.value = 0;
        participationCount.value = 0;
        avgCalCount.value = 0;
        if (lengthCheck(res)) {
          evaluationTotalCount.value = res.items.filter(x => x.lrnEvlMstSn!==4888).length;
          const target = getItems(res).
              map((x, i) => ({...x, objectIndex: i})).
              find(x => learnSn.value === x.lrnObjDtlDistSn);
          eItems.value = getItems(res);

          participationCount.value = eItems.value
              .filter(x => x.fnshYn === 'Y' && x.lrnEvlMstSn!==4888)
              .length;
          eItems.value.forEach(item=>{
            // if (item.evlRlsDt <= currentTime.value.getTime() && item.comntYn === 'Y' && item.lrnEvlMstSn!==4888) {
            if (item.evlRlsDt <= currentTime.value&& item.comntYn === 'Y' && item.lrnEvlMstSn!==4888) {
              evlSum.value += item.qstnSumScore;
              avgCalCount.value++;
            }
            if (item.evlBgngDt <= currentTime.value && item.evlEndDt >= currentTime.value && item.lrnEvlMstSn === 4888) {
              evlTestEnabled.value = true;
            }
          });

          if (learnSn.value > 0 && !modelEvlValue.value) {
            if(target) {
              // if (target.evlRlsDt <= currentTime.value.getTime() && target.comntYn === 'Y') {
              if (target.evlRlsDt <= currentTime.value && target.comntYn === 'Y') {
                setTargetEvaluation(target.objectIndex, 'A');
              } else {
                // if (target.evlBgngDt <= currentTime.value.getTime() && target.evlEndDt > currentTime.value.getTime()) {
                if (target.evlBgngDt <= currentTime.value && target.evlEndDt > currentTime.value) {
                  setTargetEvaluation(target.objectIndex, 'E');
                }
              }
            }
          }

        } else {
          eItems.value = [];
        }
      }).catch(e => {
        console.error(e);
      })
    }

    // const currentDate = ref(new Date());

    const openContent = (item) => {
      // if(item.objBgngDt >= currentDate.value.getTime() || item.objEndDt <= currentDate.value.getTime()) {
      if(item.objBgngDt >= currentTime.value || item.objEndDt <= currentTime.value) {
        showMessage('해당 학습자료 다운로드 가능기간이 아닙니다.<br/>화면 하단 학습일정을 확인하세요.');
      } else {
        // store.dispatch(`learn/${ACT_GET_KNOWLEDGE_E_LEARN_OBJECT}`, {lrnObjDtlDistSn: item.lrnObjDtlDistSn,fileName: item.fileNm});
        winOpen(`${process.env.VUE_APP_CLOUDFRONT_URL}/${item.etnUrl}`);
      }
    }

    onMounted(() => {
      if(route.query.type === undefined || route.query.type === 'learningHome'){
        activeMenu.value = 'learningHome'
        getKnoewledgeLearningList();
      }else if(route.query.type === 'evaluate'){
        activeMenu.value = 'evaluate'
        getEvaluateList();
      }
      getEvaluateList();
    });

    watch(() => activeMenu.value, () => {
      const query = {type: activeMenu.value}
      router.push({query: query})
    });

    watch(()=> route.query, () => {
      if(activeMenu.value === undefined || activeMenu.value === 'assetMng') {
        getKnoewledgeLearningList();
      }else{
        getEvaluateList();
      }
    });

    const goBack = () => {
        router.go(-1);
    };

    const goKnowledge = () =>{
      router.push({name: 'KnowledgeELearning'});
    };

    const toggleEnd = () => {
      target.toggle = false;
      windowActive.value = false;
    }

    return {
      session,
      activeMenu,
      route,
      dItems,
      eItems,
      evaluationTotalCount,

      target,
      targetEvl,
      windowActive,
      windowOptions,
      currentTime,
      evlSum,
      participationCount,

      jisikeImages,
      setTargetEvaluation,
      renewInitEvl,
      renewEndEvl,

      setDefaultImg,
      timestampToDateFormat,
      openContent,
      getThumbUrl,
      isMobile: !!navigationUtils.any(),
      modules: [Navigation],
      goBack,
      goKnowledge,
      toggles,
      toggleEnd,
      avgCalCount,
      btnDisabled,
      evlTestEnabled
    };
  },
};
</script>