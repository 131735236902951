<template>
<!--  is-visible-->
  <div class="board-alert" :class="{'is-visible': toast.show}">
    <p class="kb-alert-text">
      <span v-if="toast.show" class="text" v-html="toast.text"></span>
    </p>
  </div>
</template>
<script>

import {useStore} from "vuex";
import {computed} from "vue";

export default {
  name: 'SSLToast',
  props: {
  },
  setup() {
    const store = useStore();
    const toast = computed(() => store.state.common.toast);

    return {
      toast
    }

  }
}
</script>
