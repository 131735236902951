<template>
  <div class="popup-container p-0 d-block is-active" id="kb-mobile-popup">
    <SSLMobileSlotHeader
        :hide-title="true"
    >
      <template v-slot:left>
        <div class="util util-history-back" @click="closeModal">
          <a href="javascript:" class="util-actions util-actions-history-back">
            <i class="icon-history-back"></i>
          </a>
        </div>
        <div class="util util-search-input">
          <input v-model.trim="filters.info" type="text" :placeholder="`공유 대상 검색`" @keyup.enter="searchFunc">
        </div>
      </template>

      <template v-slot:right>
        <div class="util util-search" @click="searchFunc">
          <a href="javascript:" class="util-actions util-actions-search">
            <i class="icon-search"></i>
          </a>
        </div>
      </template>
    </SSLMobileSlotHeader>

    <main class="kb-main" id="kb-hrdcloud">
      <div class="main-content main-component">
        <article class="content-section">
          <div class="kb-form-fields">
            <div class="kb-form-field field-border-0">
              <div class="kb-form-row"><label class="kb-form-label"><span class="kb-form-label-text">검색결과</span></label></div>
              <div class="kb-form-row">
                <div v-if="searchedItems.length > 0" class="kb-form-check-group">
                  <div v-for="(item, index) in searchedItems" :key="index" class="kb-form-check">
                    <input type="checkbox" :id="`chk_search_${index}`" class="kb-form-check-input" @change="changeShareItems($event, item)" :checked="checkedShare(item)">
                    <label :for="`chk_search_${index}`" class="kb-form-check-label text-skip" style="max-width: 280px;">
                      {{ item.lrnerId }} ・ {{ item.deptNm }} ・ {{ item.jbgdCd && item.jbgdCd.length >= 2 ? item.jbgdCd.substr(0, 2) : '-' }} ・ {{ item.lrnerNm }}
                    </label>
                  </div>
                  <CommonPaginationFront :paging="paging" :page-func="pageFunc" :pageListSize="5"/>
                </div>
                <div v-else>
                  <span class="text text-muted">검색결과가 없습니다.</span>
                </div>
              </div>
            </div>

            <div class="kb-form-field">
              <div class="kb-form-row"><label class="kb-form-label"><span class="kb-form-label-text">공유대상</span></label></div>
              <div class="kb-form-row">
                <div v-if="searchedItems.length > 0" class="kb-form-check-group">
                  <div v-for="(item, index) in shareItems" :key="index"  class="kb-form-check">
                    <input type="checkbox" class="kb-form-check-input" :id="`chk_share_${index}`" checked @change="changeShareItems($event, item)">
                    <label :for="`chk_share_${index}`" class="kb-form-check-label text-skip" style="max-width: 280px;">
                      {{ item.lrnerId }} ・ {{ item.deptNm }} ・ {{ item.jbgdCd && item.jbgdCd.length >= 2 ? item.jbgdCd.substr(0, 2) : '-' }} ・ {{ item.lrnerNm }}
                    </label>
                  </div>
                </div>
                <div v-else>
                  <span class="text text-muted">공유대상을 선택해주세요.</span>
                </div>
              </div>
            </div>
            <div class="kb-form-field">
              <div class="kb-form-row">
                <label class="kb-form-label"><span class="kb-form-label-text">공유 메시지</span></label>
              </div>
              <div class="kb-form-row">
                <textarea v-model.trim="params.message" class="kb-form-control" placeholder="전송할 메시지를 입력해주세요."></textarea>
              </div>
            </div>
            <div class="kb-form-field">
              <div class="kb-form-row"><label class="kb-form-label"><span class="kb-form-label-text">공유 방법</span></label></div>
              <div class="kb-form-row">
                <div class="kb-form-check-group">
                  <div v-for="(notiPtm, index) in notiPtms" :key="index" class="kb-form-check">
                    <input v-model="params.notiPfmCd" type="checkbox" class="kb-form-check-input" :id="`chk_ptm_${index}`" :value="notiPtm.dcd">
                    <label :for="`chk_ptm_${index}`" class="kb-form-check-label">
                      <span class="text">{{ notiPtm.name }}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>

        <div class="page-buttons mt-0" @click="shareSSL">
          <button class="kb-btn kb-btn-primary">공유하기</button>
        </div>
      </div>
    </main><!--v-if-->
  </div>
</template>
<script>

import {sslShareSetup} from '@/assets/js/modules/ssl/setup/ssl-view-setup';
import SSLMobileSlotHeader from '@/components/ssl/main/mobile/SSLMobileSlotHeader';
import CommonPaginationFront from '@/components/CommonPaginationFront';

export default {
  name: 'SSLMobileShare',
  components: {CommonPaginationFront, SSLMobileSlotHeader},
  props: {
    toggle: Boolean,
    lrnTrgtGrpMstSn: Number,
    socLrnPostSn: Number,
    title: String,
  },
  emits: ['update:toggle', 'acted'],
  setup: sslShareSetup,
};
</script>
