<template>
  <div class="popup-container" :class="{'is-active' : isPopupActive}">
    <div class="popup" id="popup-main">
      <swiper
          class="banner-container banner-wrapper"
          :modules="modules"
          effect="fade"
          :pagination="{
            el: '.banner-pagination',
            clickable: true
          }"
      >
        <swiper-slide v-for="(item, idx) in items" :key="idx" class="banner-item">
          <a href="javascript:" class="banner-link" @click="moveLink(item.banerUrl)">
            <!--            <div class="banner-content">-->
            <!--              <span class="text">{{item.banerTitle}}</span>-->
            <!--            </div>-->
            <div v-html="item.banerCn"></div>
          </a>
        </swiper-slide>
        <div class="banner-pagination swiper-pagination"></div>
        <button class="banner-close" @click="clickTodayClose"><span class="text">오늘 하루 보지 않기</span></button>
        <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
        <div class="banner-pagination swiper-pagination"></div>
        <button class="banner-close" @click="clickTodayClose"><span class="text">오늘 하루 보지 않기</span></button>
        <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
      </swiper>
      <div class="popup-close">
        <button class="icon-popup-close" @click="clickClose"><i class="icon-close"></i></button>
      </div>
    </div>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import {Navigation} from 'swiper';
import {computed, ref} from "vue";
import {ssoKB} from "@/assets/js/modules/ssl/ssl-common";
import {winOpen} from "@/assets/js/util";
import {useRouter} from 'vue-router';
import store from '@/store';

import {
    getBanerExcUrl,
} from "@/assets/js/modules/baner/common-baner";
import {useAlert} from "@/assets/js/modules/common/alert";
import {goLink} from "@/assets/js/modules/common/common";


export default {
  name: "MobileBanerPopupModal",
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    items: Array
  },
  setup() {
    const isPopupActive = ref(true);
    const session = computed(() => store.state.auth.session);
    const clickClose = () => {
      isPopupActive.value = false;
    }
    const extUrl = ref([]);
    const {showMessage} = useAlert();
    const router = useRouter();

    let date = new Date();
    const clickTodayClose = () => {
      // date = date.setHours(23,59,59);
      date = date.setHours(23,59,59);
      // 만료 시간 구하기(exp를 ms단위로 변경)
      let pathname = window.location.pathname;
      if (pathname.includes('hrdcloud')) {
        localStorage.setItem('kb-popup-hrdcloud', date);
      }
      // else if (pathname.includes('sslMain')) {
      //   localStorage.setItem('kb-popup-ssl', date);
      // }
      else {
        localStorage.setItem('kb-popup-main', date);
      }
      clickClose();
    }
    const getBanerUrl = (execInptId) => {
      getBanerExcUrl({inptId: execInptId, col:session.value.lrnerId },extUrl,() => {
        console.log(extUrl.value.length);
        if (extUrl.value.length > 0){ // URL존재(모바일)
            winOpen(extUrl.value[0].url); // 외부망 리다이렉트
        }else{
          showMessage("설문대상이 아닙니다.");
        }

      });
    };


    const moveLink = (banerUrl) => {
      if(!banerUrl) {
        return false;
      }

      // else{
      //   if(banerUrl === 'junior') {
      //     ssoKB(banerUrl);
      //   } else {
      //     winOpen(banerUrl);
      //   }
      // }

      let host = window.location.host;
      //기본 url과 비교하여 타 domain인 경우 winopen , 동일 domain인 경우 route 처리
      console.log('host',host);

      if(banerUrl ==='junior'){
        ssoKB(banerUrl);
      }else if(banerUrl.indexOf("/help/notice/")>0){
        let notiNum = banerUrl.split("/").pop();
        router.push({name: 'HelpNoticeView', params: {ntcSn: notiNum}});
        return;
      }else if(banerUrl.indexOf("prep/hrdcloud/")>0){
        let lrnObjDtlDistSn = banerUrl.replace("/detail", "").split("/").pop();
        router.push({name: 'HrdCloudContentBridge', params: {lrnObjDtlDistSn: lrnObjDtlDistSn}});
        return;
      }else if(banerUrl.indexOf(host)<0){
        if (banerUrl.indexOf('survey')>-1){
          // 피플인사이트 서베이 처리(모바일)
          // 외부망(모바일)인경우 엑셀 조회해서 본인 URL 리다이렉트
          if (banerUrl.split('|')[1]) { //ID유무 판별
            getBanerUrl(banerUrl.split('|')[1]);
          }
          return;
        }else{
          goLink(banerUrl);
          //winOpen(banerUrl);
          return;
        }
      } else{
        let finLink = banerUrl.replace(window.location.host, "");
        finLink = finLink.replace(window.location.protocol, "");
        // router.push(finLink);
        goLink(finLink);
      }
    }

    return {
      isPopupActive,
      moveLink,
      clickClose,
      clickTodayClose,
      modules: [Navigation]
    }
  }
}
</script>