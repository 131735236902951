import LxpLayout from '@/views/layout/lxp/LxpLayout';
import navigationUtils from "@/assets/js/navigationUtils";
import LxpMobileLayout from "@/views/layout/lxp/mobile/LxpMobileLayout";

const isMobile = navigationUtils.any();

const errorRoutes = [
    {
        path: '/notFound',
        component: isMobile ? LxpMobileLayout : LxpLayout,
        children: [
            {
                path: "",
                name: 'PageNotFound',
                component: () => import('@/views/pages/error/PageNotFound')
            }
        ]
    },
    {
        path: '/sessionExpired',
        component: isMobile ? LxpMobileLayout : LxpLayout,
        children: [
            {
                path: "",
                name: 'SessionExpired',
                component: () => import('@/views/pages/error/SessionExpired')
            }
        ]
    },
];

export const setErrorRoutes = (routes) => {
    routes.push(...errorRoutes);
}