<template>
  <div class="popup-container" :class="{ 'is-active' : modelValue }" @click="closeToggles(toggles)">
    <div class="popup" id="popup-tuition-support-p012">
      <div class="popup-inner">
        <div class="popup-header"><h3 class="title">학원비 지원신청</h3></div>
        <div class="popup-content">
          <div class="survey-container">

            <article class="popup-section section-form">
              <header class="section-header header-divider-v2"><h4 class="title">신청정보</h4></header>
              <div class="section-content">
                <div class="kb-form-fields kb-form-fields-v2">
                  <Applicant />
                  <InputSelect
                      v-if="lrnCostDivs && lrnCostFlds"
                      v-model:value1="param.lrnCostDivSn"
                      v-model:value2="param.lrnCostFldSn"
                      v-model:value3="param.lcsnCd"
                      v-model:toggle1="toggles.lrnCostDivs"
                      v-model:toggle2="toggles.lrnCostFlds"
                      title="학습구분 및 분야"
                      :options1="lrnCostDivs"
                      :options2="lrnCostFlds"
                      sequence-key1="lrnCostDivSn"
                      sequence-key2="lrnCostFldSn"
                      nameKey1="lrnCostDivNm"
                      nameKey2="lrnCostFldNm"
                      placeholder1="학습구분을 선택해주세요"
                      placeholder2="학습분야를 선택해주세요"
                      :disabled="isReadOnly"
                      @update:toggle1="closeToggles(toggles, 'lrnCostDivs')"
                      @update:toggle2="closeToggles(toggles, 'lrnCostFlds')"
                  />
                  <InputSelect
                      v-model:value1="param.lrnMthdCdDcd"
                      v-model:toggle1="toggles.lrnMthdCdDcd"
                      title="학습방법"
                      :options1="lrnMthdCdDcds"
                      placeholder1="학습방법을 선택해주세요"
                      sequence-key1="cd"
                      nameKey1="cdNm"
                      :disabled="isReadOnly"
                      @update:toggle1="closeToggles(toggles, 'lrnMthdCdDcd')"
                  />
                  <RowInputText v-model="param.acadNm" title="학원명" placeholder="학원명을 입력하세요" :disabled="isReadOnly"/>
                  <RowInputText v-model="param.acadHmpg" title="학원 홈페이지" placeholder="학원 홈페이지 주소를 입력하세요" :disabled="isReadOnly"/>
                  <RowInputText
                      v-model="param.lrnSprtNm"
                      title="과정명"
                      placeholder="과정명을 입력하세요"
                      :disabled="isReadOnly"
                  />
<!--                  <RowInputText v-model="param.acadCt"  :is-text-number="true" :max="15" title="학원 연락처" placeholder="학원 연락처를 입력하세요" :disabled="isReadOnly"/>-->
<!--                  <RowInputText v-model="param.acadPic" title="학원 담당자" placeholder="학원 담당자를 입력하세요" :disabled="isReadOnly"/>-->
<!--                  <RowInputText v-model="param.acadAddr" title="학원 주소" placeholder="학원 주소를 입력하세요" :disabled="isReadOnly"/>-->
<!--                  <RowInputText v-model="param.acadBzmnNo" :is-text-number="true" :max="10" title="학원 사업자번호" placeholder="사업자번호를 입력하세요" :disabled="isReadOnly"/>-->

                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <strong class="kb-form-label-text">수강 기간</strong>
                      </label>
                    </div>
                    <div class="kb-form-column kb-form-dropdown-group">
                      <Datepicker
                          v-model="param.lrnBgngDd"
                          v-model:toggle="toggles.lrnBgngDd"
                          :disabled="isReadOnly"
                      />
                      <Datepicker
                          v-model="param.lrnEndDd"
                          v-model:toggle="toggles.lrnEndDd"
                          :disabled="isReadOnly"
                      />
                    </div>
                    <div class="kb-form-column column-last">
                      <span class="subtext">{{ dateRange }}</span>
                    </div>
                  </div>

                  <Upload
                    v-model="lrnCertFiles"
                    title="수강증"
                    placeholder="수강자명,학원명,수강과정명,수강기간,수강료가 기입된 수강증을 등록하세요."
                    btn-title="수강증 등록하기"
                    :extensions="extensions"
                    :max-qty="10"
                    sequence-key="proofDataSn"
                    :disabled="isReadOnly"
                  />
                  <div class="kb-form-row">
                    <InputText v-model="param.schlFee" title="수강료" placeholder="수강료를 입력하세요" :is-number="true" :is-money="true" :disabled="isReadOnly"/>
                  </div>
                  <div class="kb-form-row">
                    <InputText v-model="param.sprtExptAmt" title="지원금액" :is-number="true" :is-money="true" :disabled="true" />
                    <div class="kb-form-column column-last">
                      <span class="subtext">지원대상금액은 수강료의 80% / <br/>교재비 제외</span>
                    </div>
                  </div>
                </div>
              </div>
            </article>

            <Payment
                v-if="isInit"
                v-model="paymentInfo"
                v-model:card-kinds-toggle="toggles.cardKinds"
                v-model:card-aprv-dt-toggle="toggles.cardAprvDt"
                v-model:files="receiptFiles"
                :disabled="isReadOnly"
                @update:modelValue="updatePaymentInfo(paymentInfo)"
            />

            <article class="popup-section section-agree">
              <header class="section-header header-divider-v2">
                <h4 class="title">결과 등록</h4>
              </header>
              <div class="section-content">
                <div class="kb-form-fields kb-form-fields-v2">
                  <Upload
                      v-model="acadCertFiles"
                      title="수료증 등록"
                      placeholder="수강한 교육과정의 수료증, 출석표, 진도율표 中 택1하여 등록해 주세요."
                      btn-title="수료증 등록하기"
                      :extensions="extensions"
                      :max-qty="10"
                      sequence-key="proofDataSn"
                  />
                </div>
              </div>
            </article>

            <article class="popup-section section-agree" v-if="param.rtnYn=='Y' || param.addAmtYn=='Y'">
              <header class="section-header header-divider-v2">
                <h4 class="title">환입 및 추가지원금액</h4>
              </header>
              <div class="section-content">
                <div class="kb-form-fields kb-form-fields-v2">
                  <RowInputText v-model="param.rtnAmt" title="환입금액"  :is-number="true" :is-money="true" :disabled="true" v-if="param.rtnYn=='Y'" />
                  <RowInputText v-model="param.addAmt" title="추가지원금액"  :is-number="true" :is-money="true" :disabled="true" v-if="param.addAmtYn=='Y'" />
                </div>
              </div>
            </article>

            <article class="popup-section section-agree">
              <header class="section-header header-divider-v2">
                <h4 class="title">지원정보</h4>
              </header>
              <div class="section-content">
                <div class="kb-form-fields kb-form-fields-v2">
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <strong class="kb-form-label-text">지원 한도액</strong>
                      </label>
                    </div>
                    <div class="kb-form-column column-whole">
                      <input type="text" class="kb-form-control" :value="`${numberComma(supportAcademyYearlyLimit)}`" readonly />
                    </div>
                  </div>
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <strong class="kb-form-label-text">지원신청 누계액</strong>
                      </label>
                    </div>
                    <div class="kb-form-column column-whole">
                      <input type="text" class="kb-form-control" :value="`${numberComma(totalSprtExptAmt)}`" readonly />
                    </div>
                  </div>
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <strong class="kb-form-label-text">지원 누계액</strong>
                      </label>
                    </div>
                    <div class="kb-form-column column-whole">
                      <input type="text" class="kb-form-control" :value="`${numberComma(myTotalSprtAmt)}`" readonly />
                    </div>
                  </div>
                </div>
              </div>
            </article>

            <article v-if="data == null" class="popup-section section-agree">
              <UserAgreement
                  ref="usrAgrmntRef"
                  v-model:user-agreement="userAgreement"
                  @applyBtnEnable="applyBtnEnable"
                />
            </article>
          </div>
        </div>
        <SupportPopupButton
            :stt="data && data.stt"
            :aply-stt-cd-dcd="data && data.aplySttCdDcd"
            :apply-btn-enable="applyBtnEnable"
            :user-agreement="userAgreement"
            :apply="apply"
            :cancel="cancel"
            :close-modal="closeModal"
            type="academy"
        />
      </div>

      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
  </div>
  <LxpConfirm />
</template>

<script>
import LxpConfirm from '@/components/common/LxpConfirm';
import Payment from '@/components/support/common/Payment';
import Applicant from '@/components/support/common/Applicant';
import RowInputText from '@/components/support/common/RowInputText';
import InputSelect from '@/components/support/common/InputSelect';
import InputText from '@/components/support/common/InputText';
import Upload from '@/components/support/common/Upload';
import Datepicker from '@/components/support/common/Datepicker';
import SupportPopupButton from "@/components/support/common/SupportPopupButton";
import {supportClassFeeCommon} from "@/assets/js/modules/support/support-class-fee-common";
import UserAgreement from "@/components/support/common/UserAgreement";

export default {
  name: 'SupportClassFeeModal',
  components: {
    UserAgreement,
    SupportPopupButton,
    Datepicker,
    Upload, InputText, InputSelect, RowInputText,  Applicant, Payment, LxpConfirm},
  props: {
    modelValue: Boolean,
    data: {
      type: Object,
      required: false,
      default: null
    },
    reList : Function
  },
  setup: supportClassFeeCommon
};
</script>
