import ClassicEditorBase from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import AutoformatPlugin from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import UnderlinePlugin from '@ckeditor/ckeditor5-basic-styles/src/underline';
import BlockQuotePlugin from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import FontPlugin from '@ckeditor/ckeditor5-font/src/font';
import AlignmentPlugin from '@ckeditor/ckeditor5-alignment/src/alignment';
import EasyImagePlugin from '@ckeditor/ckeditor5-easy-image/src/easyimage';
import HeadingPlugin from '@ckeditor/ckeditor5-heading/src/heading';
import ImagePlugin from '@ckeditor/ckeditor5-image/src/image';
import ImageCaptionPlugin from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStylePlugin from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageToolbarPlugin from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageUploadPlugin from '@ckeditor/ckeditor5-image/src/imageupload';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ListPlugin from '@ckeditor/ckeditor5-list/src/list';
// import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import MediaEmbedPlugin from "@ckeditor/ckeditor5-media-embed/src/mediaembed";
import ImageResizePlugin from "@ckeditor/ckeditor5-image/src/imageresize";
import CloudservicePlugin from "@ckeditor/ckeditor5-cloud-services/src/cloudservices";
import SourceEditingPlugin from '@ckeditor/ckeditor5-source-editing/src/sourceediting';
import TablePlugin from '@ckeditor/ckeditor5-table/src/table';
import TableToolbarPlugin from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import TablePropertiesPlugin from "@ckeditor/ckeditor5-table/src/tableproperties";
import TableCellPropertiesPlugin from "@ckeditor/ckeditor5-table/src/tablecellproperties";

export default class ClassicEditor extends ClassicEditorBase {}

ClassicEditor.builtinPlugins = [
    EssentialsPlugin,
    AutoformatPlugin,
    BoldPlugin,
    ItalicPlugin,
    UnderlinePlugin,
    BlockQuotePlugin,
    FontPlugin,
    AlignmentPlugin,
    EasyImagePlugin,
    HeadingPlugin,
    ImagePlugin,
    ImageCaptionPlugin,
    ImageStylePlugin,
    ImageToolbarPlugin,
    ImageUploadPlugin,
    LinkPlugin,
    ListPlugin,
    // ParagraphPlugin,
    MediaEmbedPlugin,
    ImageResizePlugin,
    CloudservicePlugin,
    SourceEditingPlugin,
    TablePlugin,
    TableToolbarPlugin,
    TablePropertiesPlugin,
    TableCellPropertiesPlugin
];

ClassicEditor.defaultConfig = {
    toolbar: {
        items: [
            'bold',
            'italic',
            'uploadImage',
            'blockQuote',
            'undo',
            'redo'
        ]
    },
    image: {
        toolbar: [
            'toggleImageCaption',
        ]
    },
    language: 'ko'
};
