<template>
  <main class="kb-main" id="kb-prep-group">
    <lxp-main-header :show-back="true">
      <template v-slot:inner>
        <div v-if="isLeader" class="header-column">
          <router-link :to="{name: 'GroupLearningMng', params: {boardSn: item.boardSn}}" type="button" class="kb-btn kb-btn-secondary kb-btn-sm rounded-lg">
            <i class="icon-edit"></i>
            <span class="text">그룹관리</span>
          </router-link>
        </div>
      </template>
    </lxp-main-header>

    <div class="main-content min-component">
      <!-- learningGroup-header -->
      <div class="learningGroup-header">
        <!-- learningGroup-info -->
        <div class="learningGroup-info">
          <div class="info-symbol"><div class="image"><img :src="bgImgUrl" alt=""></div></div>
          <div class="info-category"><span class="text">그룹러닝 | {{ item.distCrseLrnFldNm }}</span></div>
          <div class="info-title"><h3 class="title">{{ item.crseNm }}</h3></div>
          <div class="info-description"><p class="text">{{ item.crseSumup }}</p></div>
        </div>
        <div v-if="item.stt === '01' && isGroup.length === 0" class="learningGroup-buttons">
          <button class="kb-btn kb-btn-primary" @click="clickApply"><span class="text">참여하기</span></button>
        </div>
      </div>
      <!-- //learningGroup-header -->
      <!-- learningGroup-body -->
      <div class="learningGroup-body">
        <!-- content-section:멤버 -->
        <section class="content-section section-member">
          <header class="section-header">
            <h4 class="title">
              <h4 class="title-text">참여 {{ item.lrnTrgtCnt }}  정원 {{ item.peoplCnt }}</h4>

              <div class="kb-form-layer" :class="{'is-active' : isAction}">
                <button class="dropdown-btn kb-btn-actions" @click="clickAction"><i class="icon-more"></i></button>
                <div class="layer-container">
                  <div class="layer">
                    <div class="kb-form-layer-options">
                      <div class="layer-option">
                        <ul class="layer-option-list">
                          <li v-if="item.stt === '01'" class="layer-option-item" @click="clickInvite">
                            <a href="javascript:" class="layer-option-link"><span class="layer-option-text">초대하기</span></a>
                          </li>
                          <li v-if="item.stt === '01'" class="layer-option-item" @click="clickSecession">
                            <a href="javascript:" class="layer-option-link"><span class="layer-option-text">탈퇴하기</span></a>
                          </li>
                          <li v-if="item.stt === '01' && isLeader" class="layer-option-item" @click="clickChangeLeader">
                            <a href="javascript:" class="layer-option-link"><span class="layer-option-text">리더변경</span></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </h4>
            <div v-if="isGroup.length > 0 && isGroup[0].lrnerId === session.lrnerId" class="button-actions">
              <button v-if="item.stt === '01'" class="kb-btn-actions kb-btn-inviting" @click="clickInvite">
                <i class="icon-inviting"></i><span class="text">초대하기</span>
              </button>
              <button v-if="item.stt === '01'" class="kb-btn-actions kb-btn-withdraw" @click="clickSecession">
                <i class="icon-withdraw"></i><span class="text">탈퇴하기</span>
              </button>
              <button v-if="item.stt === '01' && isLeader" class="kb-btn-actions kb-btn-leader" @click="clickChangeLeader">
                <i class="icon-leader"></i><span class="text">리더변경</span>
              </button>
              <button v-if="item.stt === '01' && isLeader" class="kb-btn-actions kb-btn-leader" @click="clickDeleteGroup">
                <i class="icon-leader"></i><span class="text">그룹삭제</span>
              </button>
            </div>
          </header>
          <!-- member-list-wrap updateSelfIntro-->
          <GroupMembers
              :items="items"
              @update:modelValue="updateSelfIntro"

          />
          <!-- //member-list-wrap -->
        </section>
      </div>
      <!-- //learningGroup-body -->
    </div>

    <ApplyGroupLrnModal
        v-if="applyGroupModal"
        v-model="applyGroupModal"
        @apply-success="applySuccess"
        :dist-crse-sn="$route.params.distCrseSn"
    />
    <GroupMemberInviteModal
        v-if="inviteModal"
        v-model="inviteModal"
        :group-info="item"
    />
    <ChangeLeaderModal
        v-if="changeLeaderModal"
        v-model="changeLeaderModal"
        :dist-crse-sn="$route.params.distCrseSn"
        :members="items"
    />

  </main>
</template>

<script>
import LxpMainHeader from "@/components/common/LxpMainHeader";
import GroupMembers from "@/components/prep/group/GroupMembers";
import ApplyGroupLrnModal from "@/components/prep/group/ApplyGroupLrnModal";
import GroupMemberInviteModal from "@/components/prep/group/GroupMemberInviteModal";
import ChangeLeaderModal from "@/components/prep/group/ChangeLeaderModal";
import {groupLearningDetailViewSetup} from "@/assets/js/modules/groupLearing/group-learning-detail-view-setup";

export default {
  name: "GroupLearningDetailView",
  components: {
        ChangeLeaderModal, GroupMemberInviteModal, ApplyGroupLrnModal, GroupMembers, LxpMainHeader},
  setup: groupLearningDetailViewSetup
}
</script>