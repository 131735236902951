<template>
  <div  class="coldcall-container kb-size-100" style="background-color:unset;"
        :style="{ top:`${randPos.top}px !important`, left:`${randPos.left}px !important`}"
        :class="{ 'is-active' : toggle.coldCall }"
        @click.stop="handleRespond"
  >
    <div ref="layerCont" class="coldcall" :style="{background:successColor}">
      <a class="coldcall-contents">
        <img src="../../../assets/lxp/mobile/images/hrdcloud/coldcall_image.png">
        <div v-if="toggle.coldCallSuccess">
          <h5>클릭 성공!</h5>
          <p>학습을 계속 진행해주세요.</p>
        </div>
        <div v-else>
          <h5>잠깐!</h5>
          <p>학습중이라면 클릭해주세요.</p>
        </div>
      </a>
    </div>
  </div>

  <div v-if="popups.complete.feedback" class="coldcall-container is-center" :class="{ 'is-active' : popups.complete.feedback }" style="rgb(238 238 238 / 90%);color: #000000;">
    <div class="coldcall" id="coldcall-4">
      <div class="coldcall-contents">

        <div v-if="feedback_que">

            <img src="../../../assets/lxp/images/icon/level_feedback_que.svg" alt="" style="max-width: 86px; height:50% !important; position: absolute; top:0px;left:10px;margin-top:3px !important;">
            <div class="contents" style="padding-top:1em;">
              <h5 class="title">학습완료! 축하합니다.</h5>
              <h5 style="padding-top: 15px; padding-bottom: 15px !important; padding-left:2em;">
                본 콘텐츠의 난이도는 <span v-html="levelString" style="font-size: 18px;"></span> 입니다.<br/>
                여러분이 직접 난이도를 선택한다면?
              </h5>

              <div class="kb-form-column flex-fill" style="margin-top:1em;">
                <div>
                  <div class="kb-form-check kb-form-check-inline">
                    <input type="radio"  class="kb-form-check-input" id="chk_group01_01" name="chk_group01" value="1" v-model="userFeedback" />
                    <label for="chk_group01_01" class="kb-form-check-label"><span class="text" style="font-size:1.2em;">초급</span></label>
                  </div>
                  <div class="kb-form-check kb-form-check-inline">
                    <input type="radio"  class="kb-form-check-input" id="chk_group01_02" name="chk_group01" value="3" v-model="userFeedback"  />
                    <label for="chk_group01_02" class="kb-form-check-label"><span class="text" style="font-size:1.2em;">중급</span></label>
                  </div>
                  <div class="kb-form-check kb-form-check-inline">
                    <input type="radio"  class="kb-form-check-input" id="chk_group01_03" name="chk_group01" value="5" v-model="userFeedback" />
                    <label for="chk_group01_03" class="kb-form-check-label"><span class="text" style="font-size:1.2em;">고급</span></label>
                  </div>
                  <div class="kb-form-check kb-form-check-inline popup-buttons">
                    <button class="kb-btn kb-btn-primary kb-btn-sm" style="height: 36px; margin-top: -0.5em;" @click="submitFeedback_mob"><span class="text">확인</span></button>
                  </div>

                </div>
              </div>
            </div>
        </div>

        <div v-else>

          <img src="../../../assets/lxp/images/icon/level_feedback_complete.svg" alt="" style="height:55% !important; margin-top: 0.5em !important;">
          <div class="contents" style="padding-top:1em;">
            <h5 class="title">제출완료! 감사합니다.</h5>
        </div>
          <button class="kb-btn-close" @click.stop="popups.complete.toggle(false)"><i class="icon-close"></i></button>
      </div>
      </div>


    </div>
  </div>


  <div v-if="!popups.complete.feedback&&popups.complete.show" class="coldcall-container is-center" :class="{ 'is-active' : popups.complete.show }" style="rgb(238 238 238 / 90%);color: #ffbc00;">
    <div class="coldcall" id="coldcall-4">
      <div class="coldcall-contents">
        <img src="../../../assets/lxp/mobile/images/hrdcloud/coldcall_image4.png" alt="">
        <div class="contents">
          <h5 class="title">학습완료! 축하합니다.</h5>
        </div>
      </div>
      <button class="kb-btn-close" @click.stop="popups.complete.toggle(false)"><i class="icon-close"></i></button>
    </div>
  </div>

  <div class="coldcall-container is-center" :class="{ 'is-active' : popups.miss.show }">
    <div class="coldcall" id="coldcall-2">
      <div class="coldcall-contents">
        <img src="../../../assets/lxp/mobile/images/hrdcloud/coldcall_image.png">
        <div class="contents">
          <h5 class="title">학습확인(Cold Call) 미응답 안내</h5>
          <p class="text text-sm"><span style="color:red;">학습확인(Cold Call) 미응답</span>으로<br>
            <span style="color:red;">학습완료 및 마일리지가 인정되지 않습니다.</span></p>
        </div>
      </div>
      <div class="coldcall-actions">
        <button class="kb-btn-action" @click.stop="popups.miss.respond('reset')"><span class="text text-gold">다시학습</span></button>
        <button class="kb-btn-action" @click.stop="popups.miss.respond('continue')"><span class="text">계속학습</span></button>
      </div>
    </div>
  </div>
  <div class="coldcall-container is-center" :class="{ 'is-active' : popups.resume.show }">
    <div class="coldcall" id="coldcall-2">
      <div class="coldcall-contents">
        <img src="../../../assets/lxp/mobile/images/hrdcloud/coldcall_image3.png">
        <div class="contents">
          <h5 class="title">이어보시겠습니까?</h5>
          <p class="text">이어보기 위치에 따라 <span class="text-red">학습확인(Cold Call) 미호출</span>로 인해<br>
            <span class="text-red">학습완료가 미인정</span>될 수 있습니다.</p>
          <p class="text-sm text-muted">※ 정상적인 학습완료를 위해서는 '다시학습' 을 권장드립니다.</p>
        </div>
      </div>
      <div class="coldcall-actions">
        <button class="kb-btn-action" @click.stop="popups.resume.respond('reset')"><span class="text text-gold">다시학습</span></button>
        <button class="kb-btn-action" @click.stop="popups.resume.respond('continue')"><span class="text">계속학습</span></button>
      </div>
    </div>
  </div>
</template>
<script>
import {hrdColdCallSetup} from '@/assets/js/modules/hrd/hrd-coldcall-setup';

export default {
  name: 'HrdCloudMobileColdCall',
  props: {
    playerCont: Object,
    player: Object,
    uuid: String,
    progress: Object,
    objectParam: Object,
    meta: Object,
    lastPlaySec: {
      type:Number,
      default: 0
    },
    popups: Object,
    completeActive: Boolean
  },
  emits:['update:completeActive'],
  setup: hrdColdCallSetup,
};
</script>

<style scoped>
.lxp-mobile-layout .kb-form-check-inline {margin-right:1em;}
.kb-form-check-label span .text {font-size: 2em;}


</style>