<template>
  <main class="kb-main" :id="[view==='history' ? 'kb-tuition-support-p08' : 'kb-tuition-support-p06']">
    <!-- main-header -->
    <lxp-main-header
      :show-breadcrumb="true"
      :show-title="true"
      :file-down-key="guideFile"
      :title="`자격증 등록/지원 신청`">
<!--      <template v-if="modeIdx === 0 || modeIdx === -1" v-slot:download>
        <Download :items="guideDown" />
      </template>
      <template v-else-if="modeIdx===1" v-slot:download>
        <Download :items="guideDown" />
      </template>
      <template v-else-if="modeIdx===2" v-slot:download>
        <Download :items="guideDown" />
      </template>-->
      <template v-slot:action>
        <SupportLicenseRoute
            :view="view"
            @changeView="changeView"/>

      </template>
    </lxp-main-header>
    <!-- main-content -->
    <SupportLicenseAcquisitionGuide v-if="!$route.query.view || $route.query.view==='acquisition'"/>
    <SupportLcnsRenewal v-else-if="$route.query.view==='renewal'"/>
    <SupportLicenseExamSchedule v-else-if="$route.query.view==='schedule'"/>
    <SupportLicenseHistory v-else-if="$route.query.view==='history'"/>
    <!-- //main-content -->
  </main>
  <!-- end::kb-main -->
</template>

<script>
import LxpMainHeader from '@/components/common/LxpMainHeader';
import SupportLcnsRenewal from '@/components/support/SupportLicenseRenewal';
import SupportLicenseExamSchedule from '@/components/support/SupportLicenseExamSchedule';
import {computed, ref, watch} from 'vue';
import SupportLicenseAcquisitionGuide from '@/components/support/SupportLicenseAcquisitionGuide';
import SupportLicenseRoute from '@/components/support/SupportLicenseRoute';
import {downloadGuideFiles} from "@/assets/js/modules/support/support-common";
import SupportLicenseHistory from "@/views/pages/support/SupportLicenseHistory";
import {useRoute} from 'vue-router';

export default {
  name: 'SupportLicenseMain',
  components: {
    SupportLicenseHistory,
    SupportLicenseRoute,
    // Download,
    SupportLicenseExamSchedule, SupportLcnsRenewal, SupportLicenseAcquisitionGuide, LxpMainHeader},
  setup() {
    const route = useRoute();
    const view = ref('acquisition');
    if(!route.query.view) route.query.view = 'acquisition';
    const changeView = (change) => {view.value = change;}
    const guideFile = computed(() => downloadGuideFiles.find(x => x.page === 'license').fileKey);
    /*const guideDown = computed(() => {
      if (modeIdx.value === 1) {
        return [
          { title: '자격증 갱신비용 지원 안내' }
        ];
      } else if (modeIdx.value === 2) {
        return [
          { title: '보험대리점 자격시험 일정' }
        ];
      } else {
        return [
          { title: '자격증 취득비용 지원 안내' },
          { title: '보험대리점 등록절차 안내' }
        ];
      }
    });*/

    watch(() => route.query, () =>{
      if(route.name === 'SupportLicenseApply') {
        if(route.query.view === 'history') {
          view.value = 'history';
        }
      }
    });

    return {
      view, guideFile, changeView
      // guideDown
    };
  }
};
</script>