<template>
  <SSLBoardList
      :is-loading="isLoading"
      :items="items"
      :paging="paging"
      :more-func="moreFunc"
  />
</template>

<script>

import SSLBoardList from "@/components/ssl/SSLBoardList";
import {sslLearnerBoardSetup} from "@/assets/js/modules/ssl/ssl-learn";

export default {
  name: 'SSLLearnerBoard',
  components: {
    SSLBoardList
  },
  setup: sslLearnerBoardSetup
}
</script>
