<template>
  <!-- cloud-header -->
  <LxpMobileHeader title="보기설정" id="kb-header">
    <template v-slot:left>
        <button class="util-icon-button" @click="closeLayer"><i class="icon-close"></i></button>
    </template>
    <template v-slot:right>
        <button class="util-text-button" @click="addQstnOpt"><span class="util-actions util-actions-yellow">보기추가</span></button>
    </template>
  </LxpMobileHeader>
  <!-- //cloud-header -->
  <!-- cloud-main -->
  <main v-if="isOptMngShow" class="kb-main" id="kb-prep-quiz">
      <!-- main-view -->
      <div class="main-content">
        <div>
          <div class="view-contents">
            <!-- content-section -->
            <article class="content-section section-forms">
              <header class="section-header header-divider d-flex">
                <h3 class="header-title">보기설정</h3>
                <div class="header-side">
                  <button class="kb-btn-text"><span class="text text-primary">전체선택</span></button>
                  <button class="kb-btn-text" @click="deleteQstnOpt(checkOptIdx)"><span class="text text-red">삭제</span></button>
                </div>
              </header>
              <div class="kb-form-check-group">
                <div class="kb-form-check" v-for="(item, idx) in evlQstnOpt" :key="idx">
                  <input type="radio" class="kb-form-check-input" @click="checkOptIdx = idx" name="optCheck" :value="idx+1" :checked="idx===0" />
                  <input style="width: 250px;" type="text" v-model="item.optNm" :placeholder="`보기${idx+1}`" class="kb-form-check-label"/>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
      <div class="page-buttons">
          <button class="kb-btn kb-btn-primary" @click="optAnsrLayer"><span class="text">다음</span></button>
      </div>
  </main>
</template>

<script>
import {ref} from 'vue';
import LxpMobileHeader from '@/views/layout/lxp/header/mobile/LxpMobileHeader.vue';

export default {
  name: "MobileOptionMng",
  components: {LxpMobileHeader},
  props:{
    isOptMngShow: Boolean,
    evlQstnOpt: Array,
    lrnTyEvlQstnSn: Number,
    addQstnOpt: Function,
    deleteQstnOpt: Function,
    optAnsrLayer: Function,
  },
  emits:['update:isOptMngShow'],
  setup(props, {emit}){

    const checkOptIdx = ref(0);

    const closeLayer = () => {
      emit('update:isOptMngShow', false)
    }

    return {
      checkOptIdx,
      closeLayer,
    }
  }
}
</script>