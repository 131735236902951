import LxpLayout from '@/views/layout/lxp/LxpLayout';
import LxpMobileLayout from '@/views/layout/lxp/mobile/LxpMobileLayout';
import navigationUtils from "@/assets/js/navigationUtils";
const isMobile = navigationUtils.any();

const juniorRoutes = [
    {
        path: '/junior',
        component: isMobile ? LxpMobileLayout : LxpLayout,
        children: [
            // 온보딩
            {
                path: 'training',
                name: "Training",
                component: () => import('../../views/pages/junior/Training')
            },
            {
                path: 'onboarding',
                name: "Onboarding",
                component: () => isMobile ? import('../../views/pages/junior/mobile/MobileOnboardingNew') : import('../../views/pages/junior/OnboardingNew')
            },
            {
                path: 'notice/:boardType',
                name: "JuniorNotice",
                component: () => isMobile ? import('../../views/pages/junior/mobile/JuniorNoticeMobile') : import('../../views/pages/junior/JuniorNotice')
            },
            {
                path: 'notice/:comNtcSn',
                name: "JuniorNoticeView",
                component: () => isMobile ? import('../../views/pages/junior/mobile/JuniorNoticeMobileView') : import('../../views/pages/junior/JuniorNoticeView')
            },

            // 멘토링
            {
                path: 'mentoring',
                name: "MentoringMain",
                component: () => import('../../views/pages/prep/MentoringMain')
            },
            {
                path: 'mentoring/:view',
                name: "MentoringSub",
                component: () => import('../../views/pages/prep/MentoringMain')
            },
            {
                path: 'mentoring/:distCrseSn/:view/:targetSn',
                name: "MentoringWrite",
                component: () => import('../../views/pages/prep/MentoringWrite')
            },
            {
                path: 'mentoring/:view/:targetSn/view',
                name: "MentoringView",
                component: () => import('../../views/pages/prep/MentoringView')
            },
            {
                path: 'ssm',
                name: "SSMMain",
                component: () => import('../../views/pages/prep/SSMMain')
            },
            {
                path: 'ssm/:view',
                name: "SSMSub",
                component: () => import('../../views/pages/prep/SSMMain')
            },
            {
                path: 'ssm/:distCrseSn/:view/:targetSn/:distCrseGrpSeqSn?',
                name: "SSMWrite",
                component: () => import('../../views/pages/prep/MentoringWrite')
            },
            {
                path: 'ssm/:view/:targetSn/view',
                name: "SSMView",
                component: () => import('../../views/pages/prep/MentoringView')
            },
            {
                path: 'newz/newzitemdtl/:distCrseSn/:newzClssCd',
                name: "NewzItemDtlScoreView",              
                component: () => import('../../views/pages/junior/mobile/NewzItemDtlScoreView') 
            }
        ],
    }
];

export const setJuniorRoutes = (routes) => {
    routes.push(...juniorRoutes);
}
