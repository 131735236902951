<template>
  <article class="home-section section-card kb-mouse-cursor" data-card="group"  @click="goLearn('group', params.targetLrnTrgtGrpMstSn)">
    <header class="card-header">
      <h3 class="title">그룹러닝</h3>
    </header>
    <div class="card-contents">

      <swiper
          class="content-container"
          :modules="modules"
          effect="fade"
          :controller="{ control: controlledSwiper }"
          @swiper="setVisualSwiper"
      >
        <swiper-slide class="content">
          <span class="content-title">모임횟수</span>
          <!-- span class="content-text" :class="{'is-active': params.secondCnt >= targetStandard.finish}">{{ params.secondCnt }}/{{ targetStandard.finish }}회</span -->
          <span class="content-text" :class="(params.secondCnt >= targetStandard.finish)?'is-active':((params.secondCnt > 0)?'is-active_ing':'')">{{ params.secondCnt }}/{{ targetStandard.finish }}회</span>
          <span class="content-subtext">연간 최대 {{ targetStandard.limit }}회</span>
        </swiper-slide>

<!--        <swiper-slide class="content">-->
<!--          <span class="content-title">나의 출석률</span>-->
<!--          <span class="content-text">{{ params.firstCnt > 0 ? (params.secondCnt/params.firstCnt * 100).toFixed(1) : '0.0' }}%</span>-->
<!--          <span class="content-subtext">연간</span>-->
<!--        </swiper-slide>-->

<!--        <swiper-slide class="content">-->
<!--          <span class="content-title">학습노트 작성</span>-->
<!--          <span class="content-text" :class="{'is-active': groupSocBoardCnt >= targetStandard.finish}">{{ groupSocBoardCnt }}/{{ targetStandard.finish }}회</span>-->
<!--          <span class="content-subtext">연간 최대 {{ targetStandard.limit }}회</span>-->
<!--        </swiper-slide>-->
      </swiper>
    </div>
    <div class="card-visual">

      <swiper
          class="visual-container"
          effect="fade"
          :modules="modules"
          :speed="750"
          direction="vertical"
          :pagination="{
            el: '.visual-pagination',
            clickable: true,
          }"
          :controller="{ control: visualSwiper }"
          @swiper="setControlledSwiper"
      >

        <swiper-slide class="visual-item">
          <div class="visual-item swiper-slide">
            <!-- [YYY:220321] 수정 -->
            <SSLDayGraph
                :target-count="params.secondCnt"
                :target-max-count="targetStandard.limit"
            />
          </div>
        </swiper-slide>

<!--        <swiper-slide class="visual-item">-->
<!--          <SSLBarGraph-->
<!--              :target-count="params.secondCnt"-->
<!--              :target-standard-count="params.firstCnt"-->
<!--          />-->
<!--        </swiper-slide>-->

<!--        <swiper-slide class="visual-item" :class="{'is-active': (groupSocBoardCnt >= targetStandard.finish)}">-->
<!--          <SSLStarGraph-->
<!--            v-if="groupSocBoardCnt >= targetStandard.finish"-->
<!--          />-->
<!--          <SSLCircleGraph-->
<!--              v-else-->
<!--              :target-count="groupSocBoardCnt"-->
<!--          />-->
<!--        </swiper-slide>-->


        <div class="visual-pagination swiper-pagination"></div>
      </swiper>
    </div>
  </article>
  <SSLEnroll
      v-if="enroll.toggle"
      v-model:toggle="enroll.toggle"
      :dist-crse-cd="enroll.key"
  />
</template>
<script>
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import SSLDayGraph from '@/components/ssl/graph/SSLDayGraph';
// import SSLCircleGraph from '@/components/ssl/graph/SSLCircleGraph';
// import SSLBarGraph from '@/components/ssl/graph/SSLBarGraph';
// import SSLStarGraph from '@/components/ssl/graph/SSLStarGraph';
import {sslStatus2Setup} from '@/assets/js/modules/ssl/setup/ssl-status-setup';
import SSLEnroll from "@/components/ssl/common/SSLEnroll.vue";

export default {
  name: 'SSLStatus2',
  components: {
    SSLEnroll,
    // SSLStarGraph,
    // SSLBarGraph,
    // SSLCircleGraph,
    SSLDayGraph,
    Swiper,
    SwiperSlide
  },
  setup: sslStatus2Setup

}
</script>
