<template>
  <article class="content-section pb-0">
    <header class="section-header">
      <div class="info-container" :data-infoview="infoModal" data-info="ON으로 설정 시 구독중인 채널에 신규 콘텐츠가 업데이트되면 알림 수신이 가능합니다.">
        <button class="title">구독 알림설정 <i class="icon-info ms-1" @click="infoModal=!infoModal"></i></button>
      </div>
    </header>
    <div class="kb-form-fields">
      <SwitchCheckbox
          :is-checked="lrnerNotiMng.hrdSubscbNotiYn === 'Y'"
          title="팝업알림"
          @saved="savePopup"
      />
      <SwitchCheckbox
          :is-checked="lrnerNotiMng.liivNotiYn === 'Y'"
          title="리브똑똑알림"
          @saved="saveLiiv"
      />
    </div>
  </article>
</template>

<script>
import {hrdSubscribeSettingsSetup} from "@/assets/js/modules/hrd/hrd-subscribe-settings-setup";
import SwitchCheckbox from "@/components/history/module/SwitchCheckbox.vue";

export default {
  name: "HrdMobileSubscribeSettings",
  components: {SwitchCheckbox},
  setup: hrdSubscribeSettingsSetup
}
</script>