<template>
<!--  <li class="badge-item">-->
    <div class="kb-badge">
      <div class="badge-icon">
        <div v-if="badgeData.isNew" class="badge">
          <span class="badge-text">NEW</span>
        </div>
        <HrdCloudBadge
            :badge-ty-cd-dcd="badgeData.badgeTyCdDcd"
            :is-active="badgeData.isActive"
        />
      </div>
      <template v-if="badgeData">
        <div class="badge-content">
          <strong class="title" :class="{ 'kb-badge-title' : isMobile }">{{ badgeData.badgeName }}</strong>
        </div>
<!--        <div class="badge-popover">-->
<!--          <span class="popover-title">{{ badgeData.badgeName }}</span>-->
<!--          <span class="popover-text">{{ badgeData.badgeDesc }}</span>-->
<!--        </div>-->
      </template>
    </div>
<!--  </li>-->
</template>

<script>
import HrdCloudBadge from '@/components/history/hrdcloud/HrdCloudBadge';
import navigationUtils from '@/assets/js/navigationUtils';

export default {
  name: 'BadgeItem',
  components: {HrdCloudBadge},
  props: {
    badgeData: Object
  },
  setup() {
    const isMobile = navigationUtils.any();
    return {
      isMobile,
    }
  }
};
</script>