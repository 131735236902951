import {computed, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {ACT_GET_GROUP_LRN_TRGT_DTL_LIST} from "@/store/modules/prep/prep";
import {getItems, lengthCheck} from "@/assets/js/util";
import {MUT_SET_QUIZ_DIST_CRSE_SN} from "@/store/modules/quiz/quiz";
import {showAdded} from "@/assets/js/modules/hrd/hrd-common";
import navigationUtils from "@/assets/js/navigationUtils";

export const groupViewSetup = () =>  {
    const activeMenu = ref('groupInfo');

    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const session = computed(() => store.state.auth.session);
    const boardSn = computed(() => route.params.boardSn);

    const items = ref([]);
    const groupItems = ref([]);
    const grpCpItems = ref([]);

    const lrnFldNm = ref('');
    const crseNm = ref('');
    const lrnGoals = ref('');

    const isLeader = ref(false);

    const quizModal = ref(false);
    const quizAddModal = ref(false);
    const contestsModal = ref(false);


    // 최상단 과정정보
    const getCrseDisp = () => {
        store.dispatch(`prep/${ACT_GET_GROUP_LRN_TRGT_DTL_LIST}`, {
            lrnerId: session.value.lrnerId,
        }).then(res => {
            if (lengthCheck(res)) {
                items.value = getItems(res);

                lrnFldNm.value = items.value[0].distCrseLrnFldNm;
                isLeader.value = items.value[0].leaderId === session.value.lrnerId;
                crseNm.value = items.value[0].crseNm;
                lrnGoals.value = items.value[0].lrnGoals;

                if(items.value[0].stt !== '00') {
                    router.push({name: 'GroupLearning'})
                }
            } else {
                router.push({name: 'GroupLearning'})
                items.value = [];
            }
        }).catch(e => {
            console.error(e);
        })
    }


    const clickStatusTab = (tab) => {
        activeMenu.value = tab;
    }

    const clickQuiz = () => {
        quizModal.value = true;
        store.commit(`quiz/${MUT_SET_QUIZ_DIST_CRSE_SN}`,route.params.distCrseSn);
    };

    const quizAdd = (isAdd) => {
        quizAddModal.value = isAdd;
    }

    const isContents = ref(false);

    const clickContentsMng = () => {
        isContents.value = true;
        showAdded(null, 'grouplearning');
    };

    getCrseDisp();

    onMounted(() => {
        if(route.query.type === undefined || route.query.type === 'groupInfo') {
            activeMenu.value = 'groupInfo';
        } else if(route.query.type === 'groupClass') {
            activeMenu.value = 'groupClass'
        }
    })

    watch(() => activeMenu.value, () => {
        const query = {type: activeMenu.value}
        router.push({query: query})
    });

    const goBack = () => {
        if (router.options.history.state.back) {
            router.go(-1);
        } else {
            router.push({name: 'Main'}).then();
        }
    };

    return {
        boardSn,
        activeMenu,
        items,
        groupItems,
        grpCpItems,
        lrnFldNm,
        crseNm,
        lrnGoals,
        isLeader,
        isContents,
        quizModal,
        quizAddModal,
        contestsModal,
        clickQuiz,
        quizAdd,
        clickContentsMng,

        clickStatusTab,
        goBack,
        isMobile: navigationUtils.any()
    };
}