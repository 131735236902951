import ApiService from '@/assets/js/api.service';

export const ACT_GET_COLLEGE_LIST = 'getCollegeList';
export const ACT_GET_MAJOR_LIST = 'getMajorList';

const state = {};

const mutations = {};

const actions = {
  [ACT_GET_COLLEGE_LIST](context, params) {
    return new Promise(resolve => {
      ApiService.query('/v1/app/common/college',params).then(response => resolve(response))
    });
  },
  [ACT_GET_MAJOR_LIST](context, params) {
    return new Promise(resolve => {
      ApiService.query('/v1/app/common/major',params).then(response => resolve(response))
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
