<template>
  <main class="ssl-body ssl-notice-view" @click="closeAtch">
    <header class="ssl-header back">
      <div class="page-component">
        <!-- header-back -->
        <div class="header-column header-back">
          <a href="javascript:" class="back-link"  @click="goBack">
            <span class="back"></span>
          </a>
        </div>
        <!-- //header-back -->
        <!-- header-right -->
        <div v-if="atchs.length > 0" class="header-column header-right" @click.stop="toggleAtch">
          <span class="save">첨부파일<i class="icon-save"></i></span>
        </div>
        <!-- //header-right -->
      </div>
    </header>

    <div class="page-container">
      <div class="board-view-container">
        <div class="board-view">
          <div class="board-view-header view-component">
            <div class="page-header-wrap">
              <div>
                <h2 class="title">{{ notice.ntcTitle }}</h2>
              </div>
              <div class="page-info">
                <span>{{ timestampToDateFormat(notice.regDt, 'yyyy.MM.dd') }}</span>
              </div>
            </div>
          </div>
          <div class="board-view-body view-component">
            <div class="editor-area">
              <div class="editor-contents" v-html="notice.ntcCn"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" id="modal-file" :class="{'is-active': atchModal}" style="z-index:1200 !important;">
      <div class="modal-dimmed"></div>
      <div class="modal-contents">
        <div class="file-list">
          <a v-for="(atch, idx) in atchs" href="javascript:" class="file-item" :key="idx" @click.stop="downloadAtch(idx)">
            <span class="file-name">{{ atch.fileNm }}</span>
            <span class="file-size">{{ convertToStorageBytes(atch.fileSz) }} </span>
          </a>
        </div>
      </div>
    </div>

    <!-- //page-container -->
  </main>
  <!-- page-container -->
</template>

<script>

import {reactive, ref} from 'vue';
import {
  collectionCheck,
  convertToStorageBytes,
  getItem,
  lengthCheck,
  setParams,
  timestampToDateFormat,
  commonDownloadFile
} from '@/assets/js/util';
import {useStore} from 'vuex';
import {ACT_GET_SSL_NTC} from '@/store/modules/ssl/ssl-index';
import {useRoute} from 'vue-router';
import {goBack, insertSocAct} from '@/assets/js/modules/ssl/ssl-common';
import {actTyCdDcds} from '@/assets/js/modules/common/common';
import {ACT_UPDATE_NOTICE_CNT} from '@/store/modules/help/help';

export default {
  name: 'SSLMobileNoticeView',
  components: {
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const isReady = ref(false);
    const atchModal = ref(false);


    const notice = reactive({
      comNtcSn: route.params.socLrnNtcSn,
      ntcTitle: '',
      ntcCn: '',
      inqCnt: 0,
      regDt: 0
    });
    const atchs = ref([]);

    const readNotice = () => {
      store.dispatch(`help/${ACT_UPDATE_NOTICE_CNT}`, {comNtcSn: notice.comNtcSn}).then(() => {}).catch(() => {});
    }

    const getNotice = () => {
      store.dispatch(`ssl/${ACT_GET_SSL_NTC}`, notice.comNtcSn).then(res => {
        if(lengthCheck(res)){
          const item = getItem(res);
          setParams(notice, item);
          if(collectionCheck(item.comFileAtchs, 'comFileAtchSn')){
            atchs.value = item.comFileAtchs;
          }

          insertSocAct(actTyCdDcds.ACT_TY_CD_READ, notice.comNtcSn, 'socLrnNtcSn');
          readNotice();

          isReady.value = true;
        }else{
          notice.comNtcSn = 0;
        }
      }).catch(() => {
        notice.comNtcSn = 0;
      });
    }

    const downloadAtch = (idx) => {
      let atch = atchs[idx]
      commonDownloadFile(atch);
      // winOpen(`${process.env.VUE_APP_CLOUDFRONT_URL}/${atchs.value[idx].upldLoc}`)
    }

    const toggleAtch = () => {
      atchModal.value = !atchModal.value;
    }

    const closeAtch = () => {
      atchModal.value = false;
    }

    if(notice.comNtcSn > 0){
      getNotice();
    }

    return {
      isReady,
      atchModal,
      notice,
      atchs,
      goBack,
      toggleAtch,
      closeAtch,
      timestampToDateFormat,
      convertToStorageBytes,
      downloadAtch

    }

  }
}
</script>
