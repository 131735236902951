import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ApiService from '@/assets/js/api.service';
import VueProgressBar from '@aacassandra/vue3-progressbar';
import Swiper, {Navigation, Pagination} from 'swiper';
import navigationUtils from '@/assets/js/navigationUtils';
// video js css 처리
import "video.js/dist/video-js.min.css"
// toast ui css 처리
import "@toast-ui/chart/dist/toastui-chart.min.css";
import print from 'vue3-print-nb'

//bootstrap & veltrix theme icon
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';
import '@/design/app.scss';


Swiper.use([Navigation, Pagination]);

const app = createApp(App);
ApiService.init(app);

const options = {
    color: "#FFBC00",
    failedColor: "#874b4b",
    thickness: "4px",
    transition: {
        speed: "0.5s",
        opacity: "0.5s",
        termination: 300,
    },
    autoRevert: true,
    location: "top",
    inverse: false,
};

app
    .use(VueProgressBar, options)
    .use(store)
    .use(router)
    .use(print)
    .mount('#app')


const windowSize = [window.outerWidth, window.outerHeight];
const getScale = (isEvent) => {
    let tabletWidth = windowSize[0];
    if(isEvent){
        if(window.innerHeight > window.innerWidth){
            tabletWidth = windowSize[1] > windowSize[0] ? windowSize[1] : windowSize[0];
        }else{
            tabletWidth = windowSize[0] > windowSize[1] ? windowSize[1] : windowSize[0];
        }
    }

    let scale = 1
    if(tabletWidth < 1640){
        scale = tabletWidth/1640;
    }
    if(scale > 0.5){
        scale = scale - 0.1
    }
    return scale;
}

// 여기서 meta tag 처리
const meta = document.createElement('meta');
meta.name = 'viewport';
meta.id = 'kb-meta'
if(navigationUtils.isTablet()){
    const viewScale = getScale();
    meta.content = `width=1640, initial-scale=${viewScale}, minimum-scale=${viewScale}, maximum-scale=${viewScale}, user-scalable=yes, viewport-fit=cover`;
    // 여기서 테블릿 orientation change event
    window.addEventListener("orientationchange", function() {
        const targetMeta = document.getElementById('kb-meta');
        targetMeta.remove();
        const meta = document.createElement('meta');
        const changeScale = getScale(true);
        meta.name = 'viewport';
        meta.id = 'kb-meta'
        meta.content = `width=1640, initial-scale=${changeScale}, minimum-scale=${changeScale}, maximum-scale=${changeScale}, user-scalable=yes, viewport-fit=cover`;
        document.getElementsByTagName('head')[0].appendChild(meta);
    });
}else{
    meta.content = 'width=device-width,initial-scale=1.0';
}
document.getElementsByTagName('head')[0].appendChild(meta);



