import {useStore} from "vuex";
import {useAlert} from "@/assets/js/modules/common/alert";
import {computed, onMounted, onUnmounted, reactive, ref, watch} from "vue";
import {
    ACT_DELETE_PROFILE_IMG,
    ACT_GET_ALL_PROFILE_IMGS, ACT_GET_USER_PROFILE,
    ACT_SAVE_USER_PROFILE,
    ACT_UPLOAD_PROFILE_IMG, MUT_SHOW_MY_PROFILE_MAIN, MUT_TOGGLE_MY_PORTRAIT_MODAL
} from "@/store/modules/my/my";
import {getItem, getItems, isSuccess, lengthCheck, setParams} from "@/assets/js/util";
import {setGDefaultImg, setProfileImgUrl} from "@/assets/js/modules/common/common";
import {checkExtension} from "@/assets/js/file-util";
import {ACT_GET_SSL_APPLY_CNT} from "@/store/modules/ssl/ssl-index";

export const myPortraitSetup = (props) => {
    const store = useStore()
    const { showMessage, showConfirm } = useAlert()
    const session = computed(() => store.state.auth.session);
    const isMobile = computed(() => store.state.auth.isMobile);
    const defaultImgUrl = computed(() => {
        getSSLApplyStt();
        return 'https://lxpcms.kbstar.com/contents/emp_img/current/' + session.value.lrnerId + '.jpg';
    })
    const isActiveModal = computed(() => store.state.my.showMyPortraitModal);
    const userProfileTyCdDcds = computed(() => store.state.code.codes.filter(code => code.cd.substring(4) !== '000' && code.cd.indexOf('2161') === 0));
    const mainProfileTyCdDcd = '2161001';
    const activeProfileTy = reactive({
        cd: '',
        cdNm: ''
    });
    const profileParam = reactive({
        empImgSn: 0,
        lrnerNickNm: null,
    });
    const items = ref(new Map());
    const empImgList = ref([]);
    const isLoading = ref(false);

    const closeModal = () => {
        if(isMobile.value){
            store.commit(`my/${MUT_SHOW_MY_PROFILE_MAIN}`);
        }
        activeProfileTy.cd = (props.userProfileTyCdDcd) ? props.userProfileTyCdDcd : mainProfileTyCdDcd;
        store.commit(`my/${MUT_TOGGLE_MY_PORTRAIT_MODAL}`);
    }

    const setCurrentProfileImg = () => {
        // 변동사항 없음
        if(
            items.value.get(activeProfileTy.cd)
            && items.value.get(activeProfileTy.cd)['empImgSn'] == profileParam.empImgSn
            && items.value.get(activeProfileTy.cd)['lrnerNickNm'] == profileParam.lrnerNickNm
        ){
            closeModal();
        }else{
            showConfirm({title:'프로필 변경', text:'저장하시겠습니까?', callback: saveUserProfile});
        }
    }

    const saveUserProfile = () => {
        isLoading.value = true;
        store.dispatch(`my/${ACT_SAVE_USER_PROFILE}`, {
            ...profileParam,
            userProfileTyCdDcd: activeProfileTy.cd,
        }).then((res) => {
            if(isSuccess(res)){
                showMessage('저장되었습니다.');
                getProfile(activeProfileTy.cd);
                // 메인 프로필인 경우
                if(activeProfileTy.cd == mainProfileTyCdDcd){
                    // 프로필 이미지 url 커밋
                    setProfileImgUrl();
                }
            }else{
                showMessage("변경에 실패 하였습니다.");
            }
        }).finally(() => {
            isLoading.value = false;
        })
    }

    const newProfileImgFile = (e) => {
        if( checkExtension(e.target.files, ["jpg", "jpeg", "png", "svg"]) ){
            showMessage('[ jpg, jpeg, png, svg ] 확장자만 업로드 할 수 있습니다.');
            return;
        }
        uploadProfileImage(e.target.files);
    }

    const uploadProfileImage = (files) => {
        isLoading.value = true;
        store.dispatch(`my/${ACT_UPLOAD_PROFILE_IMG}`, files).then(res => {
            if( res.status && res.status.code == 200) {
                getAllProfileImages();
            }
        }).finally(() => {
            isLoading.value = false;
        })
    }

    const deleteProfileImage = (empImgSn) => {
        showConfirm('선택한 이미지를 삭제하시겠습니까?', () => {
            store.dispatch(`my/${ACT_DELETE_PROFILE_IMG}`, empImgSn).then(res => {
                if( res.status && res.status.code == 200) {
                    if (empImgSn == items.value.get(activeProfileTy.cd)['empImgSn']) {
                        getProfile(activeProfileTy.cd);
                    }
                    getAllProfileImages()
                }
            })
        })
    }

    const initProfileParam = () => {
        profileParam.empImgSn = 0;
        profileParam.lrnerNickNm = null;
    }

    const getAllProfileImages = () => {
        isLoading.value = true;
        store.dispatch(`my/${ACT_GET_ALL_PROFILE_IMGS}`).then((res) => {
            if(lengthCheck(res)){
                empImgList.value = getItems(res);
            }else{
                empImgList.value = [];
            }
        }).finally(() => {
            isLoading.value = false;
        })
    }

    // SSL 입과 여부 확인
    const sslApplyStt = reactive({
        bookAppCnt: 0, // SSL 북러닝
    });
    const getSSLApplyStt = () => {
        store.dispatch(`ssl/${ACT_GET_SSL_APPLY_CNT}`, {lrnerId: session.value.lrnerId, period: true}).then((res) => {
            if(lengthCheck(res)){
                setParams(sslApplyStt, getItem(res));
            }
        })
    }

    const isValidProfile = (cd) => {
        switch (cd) {
            case '2161002': // 북러닝
                return sslApplyStt.bookAppCnt > 0;
            default:
                return true;
        }
    }

    const getProfile = (profileTyCdDcd) => {
        store.dispatch(`my/${ACT_GET_USER_PROFILE}`, profileTyCdDcd).then((res) => {
            if(lengthCheck(res)){
                items.value.set(profileTyCdDcd, getItem(res));
                setParams(profileParam, items.value.get(profileTyCdDcd));
            }else{
                initProfileParam();
            }
        })
    }

    watch(() => [activeProfileTy.cd, userProfileTyCdDcds.value], () => {
        if(userProfileTyCdDcds.value && userProfileTyCdDcds.value.length > 0){
            setParams(activeProfileTy, userProfileTyCdDcds.value.find(x => x.cd === activeProfileTy.cd));
        }
        if(items.value.get(activeProfileTy.cd)){
            setParams(profileParam, items.value.get(activeProfileTy.cd));
        }else{
            getProfile(activeProfileTy.cd);
        }
    })

    onMounted(() => {
        activeProfileTy.cd = (props.userProfileTyCdDcd) ? props.userProfileTyCdDcd : mainProfileTyCdDcd;
        getAllProfileImages();
    })

    onUnmounted(() => {
        store.commit(`my/${MUT_TOGGLE_MY_PORTRAIT_MODAL}`, false);
    })

    return {
        session,
        isActiveModal,
        empImgList,
        defaultImgUrl,
        userProfileTyCdDcds,
        mainProfileTyCdDcd,
        activeProfileTy,
        profileParam,
        isLoading,
        setGDefaultImg,
        closeModal,
        setCurrentProfileImg,
        newProfileImgFile,
        deleteProfileImage,
        isValidProfile,
    }
}