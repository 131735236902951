<template>
  <!-- popup-inner > popup-content -->
  <div class="popup-content">
    <!-- popup-section:퀴즈문제지명 -->
    <article class="popup-section">
      <div class="section-content">
        <div class="kb-form-fields">
          <div class="kb-form-row">
            <div class="kb-form-column kb-form-column-title">
              <label class="kb-form-label">
                <span class="kb-form-label-text">퀴즈문제지명</span>
              </label>
            </div>
            <div class="kb-form-column">
              <span class="kb-form-text"><input type="text" v-model="evlMst.lrnEvlNm" /></span>
            </div>
          </div>
        </div>
      </div>
    </article>
    <!-- popup-section:퀴즈문항 -->
    <article class="popup-section">
      <header v-if="evlQstnItems.length > 0" class="section-header">
        <h4 class="title">퀴즈문항 <span class="subtitle">{{ evlQstnItems.length }}</span></h4>
      </header>
      <div v-if="evlQstnItems.length === 0" class="section-content">
        <div class="kb-form-fields">
          <div class="kb-form-row">
            <div class="kb-form-column kb-form-column-title">
              <label class="kb-form-label">
                <span class="kb-form-label-text">퀴즈문항</span>
              </label>
            </div>
            <div class="kb-form-column">
              <span class="kb-form-text">-</span>
            </div>
            <div class="kb-form-column column-last">
              <a href="javascript:" class="kb-btn-link" @click="addQstn(0)"><span class="text">문항추가</span></a>
            </div>
          </div>
        </div>
        <div class="kb-form-fields-bottom"><span class="text">등록된 퀴즈 문항이 없습니다. [문항 등록]을 클릭하여 퀴즈 문항을 생성하세요.</span></div>
      </div>
      <div v-else class="section-content">
        <div class="kb-table kb-table-striped">
          <table>
            <colgroup>
              <col style="width:88px">
              <col style="width:auto">
              <col style="width:160px">
              <col style="width:160px">
              <col style="width:160px">
              <col style="width:138px">
            </colgroup>
            <thead>
            <tr>
              <th><span class="th-title">NO</span></th>
              <th><span class="th-title">퀴즈문항명</span></th>
              <th><span class="th-title">보기수</span></th>
              <th><span class="th-title">작성자</span></th>
              <th><span class="th-title">등록일자</span></th>
              <th class="cell-center"><span class="th-title">삭제</span></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, idx) in evlQstnItems" :key="idx">
              <td><span class="td-text">{{ idx+1 }}</span></td>
              <td><strong class="td-text kb-mouse-cursor" @click="addQstn(item.lrnTyEvlQstnSn)">{{ item.qstnNm }}</strong></td>
              <td><strong class="td-text">{{ item.optCnt }}</strong></td>
              <td><strong class="td-text">{{ item.rgtrNm }}</strong></td>
              <td><strong class="td-text">{{ item.regDt }}</strong></td>
              <td class="td-center">
                <div class="td-cell">
                  <button class="kb-btn-delete" @click="deleteQstn(item.lrnTyEvlMstSn, item.lrnTyEvlQstnSn)"><i class="icon-delete">삭제</i></button>
                </div>
              </td>
            </tr>
            <tr>
              <td><strong class="td-text">{{ evlQstnItems.length + 1 }}</strong></td>
              <td colspan="5">
                <button class="td-btn" @click="addQstn(0)">
                  <strong class="td-text text-primary">문항 추가</strong>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </article>
  </div>

  <!-- popup-inner > popup-buttons -->
  <div class="popup-buttons">
    <button class="kb-btn kb-btn-outline kb-btn-lg" @click="clickCancel"><span class="text">취소</span></button>
    <button class="kb-btn kb-btn-primary kb-btn-lg" @click="saveQuiz"><span class="text">저장</span></button>
  </div>

  <QuizMngQstnAdd
      v-if="isQstnAddShow"
      v-model:is-qstn-add-show="isQstnAddShow"
      v-model:qstnSn="lrnTyEvlQstnSn"
      v-model:quizSn="lrnTyEvlMstSn"
      v-model:qstnIdx="qstnNo"
      :distCnt="quizItem.distCnt"
      :getQstnList="getQstnList"
  />
</template>

<script>
import QuizMngQstnAdd from "@/components/quiz/QuizMngQstnAdd";
import {quizMngViewSetup} from "@/assets/js/modules/quiz/quiz-mng-view-setup";

export default {
  name: "QuizMngView",
  components: {QuizMngQstnAdd},
  props: {
    isQuizViewShow: Boolean,
    isQuizListShow: Boolean,
    quizDtlSn: Number,
    quizItem: Object,
    getQuizList: Function,
  },
  emits:['update:isQuizViewShow','update:isQuizListShow'],
  setup: quizMngViewSetup
}
</script>