import {useStore} from 'vuex';
import {useRoute, useRouter} from 'vue-router';
import {computed, onBeforeUnmount, onMounted, reactive, ref, watch} from 'vue';
import {
    actSubscribe, enrollSSLAlert,
    getAllSubscribes,
    getRouteLrnTrgtGrpMstSn,
    getTrgtLrnerParams,
    insertSocAct, insertSocActByParams, isMyLrnTrgtGrpMstSn,
    noneRewardCds, sslRoutes,
} from '@/assets/js/modules/ssl/ssl-common';
import {
    ACT_GET_MY_SOC_BOARD_LIST, ACT_GET_SOC_BOARD_LIST,
    ACT_GET_SOC_PAGE_LIST, ACT_SHARE_SOC_BOARD,
    ACT_UPDATE_SOC_BOARD_INQ_CNT,
    MUT_ADD_SUBSCRIBE_MY,
    MUT_REMOVE_SUBSCRIBE_MY, MUT_SET_POSTSN_LIST,
} from '@/store/modules/ssl/ssl-index';
import {
    getItems,
    lengthCheck,
    setParams,
    timestampToDateFormat,
    numberComma, getPaging, dateToDatePart, isSuccess, dateToDateFormat,
} from '@/assets/js/util';
import {actTyCdDcds} from '@/assets/js/modules/common/common';
import {useAlert} from '@/assets/js/modules/common/alert';
import {notiPfmCds} from '@/assets/js/modules/noti/noti-common';
import {ACT_GET_USERS_LIST} from '@/store/modules/user/user';

export const sslViewSetup = (props, {emit}) => {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const {showConfirm, showMessage} = useAlert();

    const session = computed(() => store.state.auth.session ? store.state.auth.session : {});
    const lrnTrgtGrpMstSn = computed(() => getRouteLrnTrgtGrpMstSn(route.params.lrnTrgtGrpMstSn));
    const isMyLrnTrgt = computed(() => isMyLrnTrgtGrpMstSn(lrnTrgtGrpMstSn.value));
    const socLrnPostSn = ref(route.params.socLrnPostSn || 0);

    const isReady = ref(false);
    const commentComp = ref(null);
    const postSns = computed(()=> store.state.ssl.socBoardTotList.postSns);

    const distCrseCd = computed(() => route.params.distCrseCd);
    const distCrseNm = computed(() => sslRoutes[route.params.distCrseCd] ? sslRoutes[route.params.distCrseCd].name : '' );

    const getIdx=(list, val)=>{
         let i = 0;
         for(const sn of list.value){
             if(parseInt(sn) == parseInt(val)){
                 console.log(parseInt(sn)+' = ' + parseInt(val)+ '==>' + i);
                 return i;
             }
             i ++;
         }
        return 0;
    }

    const goPage = (div) =>{
        //현재글의 idx

        const crtIdx = ref(getIdx(postSns, route.params.socLrnPostSn)); //  computed(()=> postSns.value.findIndex(e => e === route.params.socLrnPostSn));
        const paging = computed(()=> store.state.ssl.socBoardTotList.socPaging);
        const opt = computed(() => store.state.ssl.socBoardTotList.opt);
        const no=ref(0);

        // console.log('현재 글 IDX : ' + (crtIdx.value));
        // console.log('총건수 : ' + paging.value.totalCount);
        // console.log('route.params.socLrnPostSn : ' + route.params.socLrnPostSn);
        // console.log('postSns : ' + postSns.value.toString());
        // console.log('postSns length : ' + postSns.value.length);
        // console.log('-------------------------------------------------');


        if(div == 'prv'){
            if (crtIdx.value <= 0){
                showMessage('이전 글이 없습니다.');
            }else{
                no.value = postSns.value[crtIdx.value-1];
                router.push({name: 'SSLView', params: {socLrnPostSn: no.value}}).then();
            }
        }else{
            if (crtIdx.value+1 >= paging.value.totalCount){ //&& crtIdx.value+1 ==  postSns.value.length
                showMessage('다음 글이 없습니다.');
            }else if((crtIdx.value+1 >= postSns.value.length) && (crtIdx.value+1 < paging.value.totalCount)){

                paging.value.pageNo = paging.value.pageNo+1;

                if(opt.value.div == 'my'){
                    store.dispatch(`ssl/${ACT_GET_MY_SOC_BOARD_LIST}`, {
                        lrnTrgtGrpMstSn: route.params.lrnTrgtGrpMstSn,
                        stt: '00',
                        pageNo: paging.value.pageNo,
                        pageSize: paging.value.pageSize,
                    }).then(res => {
                        if (lengthCheck(res)) {
                            for(let i=0; i < getItems(res).length; i++){
                                postSns.value.push(getItems(res)[i].socLrnPostSn);
                            }
                        }
                        paging.value.hasMore = getPaging(res).hasMore;

                        store.commit(`ssl/${MUT_SET_POSTSN_LIST}`, {paging : paging.value, items:postSns.value, opt:opt.value}); // 이전,다음페이지 이동을 위해 추가함.

                        // console.log('1. 추가조회 후 list length : ' + postSns.value.toString());
                        no.value = postSns.value[crtIdx.value+1];
                        router.push({name: 'SSLView', params: {socLrnPostSn: no.value}}).then();

                    }).catch(e => {
                        console.error(e);
                    });

                }else{

                    store.dispatch(`ssl/${ACT_GET_SOC_BOARD_LIST}`, {
                        lrnTrgtGrpMstSn: route.params.lrnTrgtGrpMstSn,
                        distCrseCd: route.params.distCrseCd,
                        relationYn: (route.params.distCrseCd === 'book' ? 'Y' : 'N'),
                        title: opt.value.title,
                        sortCd: opt.value.sortCd,
                        pageNo: paging.value.pageNo,
                        pageSize: paging.value.pageSize,
                    }).then(res => {
                        if (lengthCheck(res)) {

                            for(let i=0; i < getItems(res).length; i++){
                                postSns.value.push(getItems(res)[i].socLrnPostSn);
                            }
                        }
                        paging.value.hasMore = getPaging(res).hasMore;

                        store.commit(`ssl/${MUT_SET_POSTSN_LIST}`, {paging : paging.value, items:postSns.value, opt:opt.value}); // 이전,다음페이지 이동을 위해 추가함.

                        // console.log('2. 추가조회 후 list : ' + postSns.value.toString());
                        postSns.value = postSns.value.toString().split(',');
                        // console.log('3. 추가조회 후 list length : ' + postSns.value.length);
                        // console.log('4. 현재 글 IDX : ' + crtIdx.value);
                        // console.log('5. 다음 글 IDX : ' + (parseInt(crtIdx.value)+1));
                        no.value = postSns.value[parseInt(crtIdx.value)+1];
                        router.push({name: 'SSLView', params: {socLrnPostSn: no.value}}).then();
                    }).catch(e => {
                        console.error(e);
                    });
                }

            }else{
                no.value = postSns.value[crtIdx.value+1];
                router.push({name: 'SSLView', params: {socLrnPostSn: no.value}}).then();
            }
        }

        window.scrollTo(0, 0);
    }


    const socBoard = reactive({
        socLrnPostSn: 0,
        title: '',
        thumb: '',
        regDt: 0,
        pblcnDt: 0,
        inqcnt: 0,
        lrnerId: '',
        lrnerNm: '',
        deptNm: '',
        deptCd: '',
        lrnTrgtGrpMstSn: 0,
        lrnTrgtGrpNm: '',
        likeCnt: 0,
        refTyNm: null,
        refUrl: '',
        refPost: '',
        sharedCnt: 0,
    });

    const currentYmd = dateToDatePart(new Date());
    const canEdit = computed(() => {
        // 직무 college인 경우 상시 수정 가능
        if(route.params.distCrseCd === 'college'){
            return session.value.lrnerId === socBoard.lrnerId;
        }
        return session.value.lrnerId === socBoard.lrnerId && currentYmd.ymd === dateToDatePart(new Date(socBoard.pblcnDt)).ymd;
    });

    const shareToggle = ref(false);

    const hashTags = ref([]);

    const isGroup = computed(() => (route.params.distCrseCd === 'group'));
    const isNoneReward = computed(() => noneRewardCds.includes(route.params.distCrseCd));
    const canSubscribe = computed(() => (session.value.lrnerId !== socBoard.lrnerId && !isNoneReward.value && isMyLrnTrgt.value));
    const canPut = computed(() => (session.value.lrnerId !== socBoard.lrnerId && (isNoneReward.value || isMyLrnTrgt.value)));
    const canShare = computed(() => (isNoneReward.value || isMyLrnTrgt.value));


    const socPages = ref([]);
    const currentPageIdx = ref(0);
    const currentPage = computed(() => {
        if(socPages.value.length > 0 && socPages.value[currentPageIdx.value]){
            return socPages.value[currentPageIdx.value];
        }
        return {};
    });

    const isSubscribe = computed(() => {
        return (store.state.ssl.subscribe.my.filter(x => x.lrnTrgtGrpMstSn === lrnTrgtGrpMstSn.value && x.trgtLrnerId === socBoard.lrnerId).length > 0);
    });
    const isLike = ref(false);
    const isShare = ref(false);
    const isPut = ref(false);

    const readSocBoard = (lrnerId) => {
        if(session.value.lrnerId !== lrnerId){
            store.dispatch(`ssl/${ACT_UPDATE_SOC_BOARD_INQ_CNT}`, socLrnPostSn.value).then(() => {}).catch(() => {});
        }
    }

    const getSocsPageList = () => {
        if (socLrnPostSn.value > 0) {
            store.dispatch(`ssl/${ACT_GET_SOC_PAGE_LIST}`, socLrnPostSn.value).then(res => {
                // SocBoard
                if (res.socBoard) {
                    setParams(socBoard, res.socBoard);
                    // myLikeCnt: 0
                    if (res.socBoard.hashTag) {
                        hashTags.value = res.socBoard.hashTag.split(',');
                    } else {
                        hashTags.value = '';
                    }

                    isLike.value = (res.socBoard.myLikeCnt > 0);
                    isPut.value = (res.socBoard.myPutCnt > 0);
                    isShare.value = (res.socBoard.mySharedCnt > 0);
                    // SocPage
                    if (lengthCheck(res)) {
                        socPages.value = getItems(res);
                        isReady.value = true;
                    } else {
                        socPages.value = [];
                    }
                    insertSocAct(actTyCdDcds.ACT_TY_CD_READ, socBoard.socLrnPostSn, null, () => {
                        readSocBoard(socBoard.lrnerId);
                    });
                } else {
                    socLrnPostSn.value = "0";
                    isLike.value = false;
                    isPut.value = false;
                    isShare.value = false;
                    socPages.value = [];
                    isReady.value = false;

                }
            }).catch(e => {
                console.error(e);
            })
        }
    }

    const dateToFormat = (regDt) => {
        if(regDt){
            return timestampToDateFormat(regDt, 'yyyy.MM.dd hh:mm')
        }
        return '';
    }

    const actLike = () => {
        isLike.value = !isLike.value;
        socBoard.likeCnt++;
        insertSocAct(actTyCdDcds.ACT_TY_CD_LIKE, socBoard.socLrnPostSn);

        emit('acted', {socLrnPostSn: socBoard.socLrnPostSn, act: 'like'});
    }

    const toggleSubscribe = () => {
        // 구독된 상태일 경우
        if(isSubscribe.value){
            showConfirm({
                text : `구독을 취소 하시겠습니까?`,
                callback: () => {
                    actSubscribe(actTyCdDcds.ACT_TY_CD_CANCEL_SUBSCRIBE, {trgtLrnerId: socBoard.lrnerId, lrnTrgtGrpMstSn: socBoard.lrnTrgtGrpMstSn, lrnTrgtGrpNm:socBoard.lrnTrgtGrpNm});
                    store.commit(`ssl/${MUT_REMOVE_SUBSCRIBE_MY}`, {actTyCdDcd: actTyCdDcds.ACT_TY_CD_SUBSCRIBE, trgtLrnerId: socBoard.lrnerId, lrnTrgtGrpMstSn: socBoard.lrnTrgtGrpMstSn});
                }
            });
        }else{
            actSubscribe(actTyCdDcds.ACT_TY_CD_SUBSCRIBE, {trgtLrnerId: socBoard.lrnerId, lrnTrgtGrpMstSn: socBoard.lrnTrgtGrpMstSn, lrnTrgtGrpNm:socBoard.lrnTrgtGrpNm});
            store.commit(`ssl/${MUT_ADD_SUBSCRIBE_MY}`, {
                lrnerId: session.value.lrnerId,
                actTyCdDcd: actTyCdDcds.ACT_TY_CD_SUBSCRIBE,
                socLrnPostSn: 0,
                ...getTrgtLrnerParams(socBoard)
            });
        }
    }

    const putSocBoard = () => {
        if(isPut.value){
            // 구독된 상태일 경우
            showConfirm({
                text : '해당 노트의 담기를 취소하시겠습니까?',
                callback: () => {
                    insertSocAct(actTyCdDcds.ACT_TY_CD_CANCEL_ADD, socBoard.socLrnPostSn);
                    isPut.value = false;
                }
            });

        }else{
            insertSocAct(actTyCdDcds.ACT_TY_CD_ADD, socBoard.socLrnPostSn);
            isPut.value = true;
        }
    }

    const toggleLike = () => {
        // 이미 좋아요 상태이면 취소가 불가능
        if(isLike.value){
            showMessage('좋아요는 취소할 수 없습니다.');
            // store.commit(`common/${MUT_SHOW_MESSAGE}`, '좋아요는 취소 할수 없습니다.');
            return;
        }

        if (session.value.lrnerId === socBoard.lrnerId) {
            showMessage('자신이 작성한 학습노트는 좋아요를 할 수 없습니다.');
            return;
        }

        if (!isNoneReward.value && !isMyLrnTrgt.value) {
            enrollSSLAlert(distCrseCd, distCrseNm, lrnTrgtGrpMstSn);
            // showMessage('입과되지 않은 학습자는 좋아요를 할 수 없습니다.');
            return;
        }

        // if(!canAction.value){
        //     showMessage('좋아요를 할 수 없습니다.');
        //     return;
        // }

        // 직무 college만 허용
        if(socBoard.lrnTrgtGrpMstSn !== 112124) {
            let currentDate = new Date();
            currentDate.setDate(currentDate.getDate() - 7);
            if(timestampToDateFormat((socBoard.pblcnDt), 'yyyyMMdd') < dateToDateFormat(currentDate, 'yyyyMMdd')){
                showMessage('작성 후 7일이 지난 글은<br>좋아요를 할 수 없습니다.');
                return;
            }
        }

        showConfirm({
            text : `좋아요를 하시겠습니까?<br>(좋아요는 취소할 수 없습니다.)`,
            callback: actLike
        });
    }

    const goList = () => {
        if (router.options.history.state.back) {
            router.go(-1);
        } else {
            router.push({name: 'SSLMain'}).then();
        }
    }

    const goEdit = () => {
        router.push({name: 'SSLWrite'}).then();
    }

    const closeAllToggle = () => {
        if (commentComp.value && commentComp.value.closeCommentToggles) {
            commentComp.value.closeCommentToggles();
        }
    }

    const acted = params => {
        if (params.act === 'share') {
            if (socBoard.lrnerId !== session.value.lrnerId) {
                if (!isShare.value) {
                    socBoard.sharedCnt++
                    emit('acted',params);
                    isShare.value = true;
                }
            }
        } else {
            emit('acted',params);
        }
    }

    const goLearner = target => {
        if (target && target.lrnerId) {
            router.push({name: 'SSLLearnerBoard', params: {lrnerId: target.lrnerId}}).then(() => {});
        }
    }

    watch(() => route.query, () => {
        socLrnPostSn.value = route.params.socLrnPostSn;


        getSocsPageList();
        getAllSubscribes();

    });

    onMounted(() => {
        document.oncontextmenu = function () {return false};
        document.onselectstart = function () {return false};
        document.ondragstart = function () {return false};
    });

    onBeforeUnmount(() => {
        document.oncontextmenu = function () {return true};
        document.onselectstart = function () {return true};
        document.ondragstart = function () {return true};
    });

    getSocsPageList();
    getAllSubscribes();

    return {
        isReady,
        isGroup,
        session,
        lrnTrgtGrpMstSn,

        socBoard,
        socPages,
        currentPageIdx,
        currentPage,

        isSubscribe,
        isPut,
        isLike,
        shareToggle,
        canEdit,
        goLearner,

        toggleLike,
        putSocBoard,
        actSubscribe,
        toggleSubscribe,
        goEdit,
        dateToFormat,
        canSubscribe,
        canPut,
        canShare,
        hashTags,
        acted,
        numberComma,

        // PC
        goList,

        // Mobile
        commentComp,
        closeAllToggle,
        goPage,
        postSns,
    }

}

export const sslShareSetup = (props, {emit}) => {
    const store = useStore();
    const {showMessage, showConfirm, showLoading, hideLoading} = useAlert();

    const session = computed(() => store.state.auth.session);

    let isLoding = false;
    const searchEl = ref(null);
    const filters = reactive({
        lrner: '',
        dept: '',
        info: ''
    });

    const searchedItems = ref([]);
    const paging = reactive({
        pageNo: 1,
        pageSize: 10,
        totalCount: 0,
    });

    const shareItems = ref([]);

    const notiPtms = [
        {
            name: '리브똑똑',
            dcd: notiPfmCds.liiv,
        }, {
            name: '메신저 쪽지',
            dcd: notiPfmCds.wiseNet,
        },
        {
            name: '내부메일',
            dcd: notiPfmCds.wiseEml,
        },
    ];

    const params = reactive({
        message: '',
        notiPfmCd: [notiPfmCds.liiv , notiPfmCds.wiseNet]
    });


    const closeModal = () => {
        emit('update:toggle', false);
    };

    const checkedShare = item => {
        return shareItems.value.some(x => x.lrnerId === item.lrnerId);
    }

    const clearShareItems = () => {
        return shareItems.value = [];
    }

    const changeShareItems = (e, item) => {
        if (e.target.checked) {
            if (item.lrnerId === session.value.lrnerId) {
                e.target.checked = false;
                showMessage('자신에게는 공유할수 없습니다.');
            } else {
                if (shareItems.value.length > 14) {
                    e.target.checked = false;
                    showMessage('공유할 수 있는 사용자는 15명까지 입니다.');
                }else {
                    shareItems.value.push(item);
                }
            }
        } else {
            shareItems.value = shareItems.value.filter(x => x.lrnerId !== item.lrnerId);
        }
    }

    const getUserList = () => {
        if (isLoding) return;
        isLoding = true;
        showLoading();

        store.dispatch(`auth/${ACT_GET_USERS_LIST}`, {
            lrnerNm: filters.lrner,
            deptNm: filters.dept,
            userInfo: filters.info,
            pageNo: paging.pageNo,
            pageSize: paging.pageSize,
        }).then(res => {
            if (lengthCheck(res)) {
                searchedItems.value = getItems(res);
            } else {
                searchedItems.value = [];
            }
            paging.totalCount = getPaging(res).totalCount;
            if (searchEl.value) searchEl.value.scrollTo(0, 0);

            isLoding = false;
            hideLoading();
        }).catch(() => {
            searchedItems.value = [];
            isLoding = false;
            hideLoading();
        });
    }

    const getSSLNotiCn = notiPfmCdDcd => {
        return `- 제목: ${props.title} ${notiPfmCdDcd === notiPfmCds.wiseNet ? '<br/>' : '\\n'}- 내용: ${params.message}`;
    }

    const shareSSL = () => {
        if (shareItems.value.length === 0) {
            showMessage('공유할 대상을 선택해주세요.');
        } else if (params.notiPfmCd.length === 0) {
            showMessage('공유방법을 1개이상 선택해주세요.');
        } else if (!params.message) {
            showMessage('공유 메시지를 입력해주세요.');
        }else {
            showConfirm('학습노트를 공유 하시겠습니까?', () => {
                showLoading();

                const notiParams = {
                    notiMsgDomains: params.notiPfmCd.map(x => ({notiPfmCdDcd: x, notiCn: getSSLNotiCn(x)})),
                    notiTrgtDomains: shareItems.value.map(x => ({trprId: x.lrnerId, trprSn: x.userSn, trprNm: x.lrnerNm, trprTelno: x.mblTelno}))
                }


                store.dispatch(`ssl/${ACT_SHARE_SOC_BOARD}`, {socLrnPostSn: props.socLrnPostSn, lrnTrgtGrpMstSn: props.lrnTrgtGrpMstSn, params: notiParams}).then(res => {
                    hideLoading();
                    if (isSuccess(res)) {
                        showMessage('공유 완료 되었습니다.');
                        insertSocActByParams({
                            actTyCdDcd: actTyCdDcds.ACT_TY_CD_SHARE,
                            socLrnPostSn: props.socLrnPostSn,
                            shareTrgtLrnerNoLst: shareItems.value.map(x => x.lrnerId).join(','),
                            shareTrgtLrnNmLst: shareItems.value.map(x => x.lrnerNm).join(','),
                        }, () => {
                            emit('acted', {socLrnPostSn: props.socLrnPostSn, act: 'share'});
                        });
                        closeModal();
                    } else {
                        showMessage("잘못된 정보입니다.");
                    }
                }).catch(() => {
                    hideLoading();
                    showMessage("네트워크 확인 중 오류가 발생했습니다.");
                });
            });

        }
    }

    const searchFunc = () => {
        if (filters.lrner || filters.dept || filters.info) {
            paging.pageNo = 1;
            getUserList();
        }
        else {
            showMessage('검색어를 입력해주세요.');
        }
    }

    const pageFunc = pageNo => {
        paging.pageNo = pageNo;
        getUserList();
    }

    return {
        searchEl,
        filters,
        searchedItems,
        paging,
        shareItems,
        notiPtms,
        params,
        checkedShare,
        clearShareItems,
        changeShareItems,
        closeModal,
        shareSSL,
        searchFunc,
        pageFunc,
    };
}
