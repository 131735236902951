<template>
  <div class="content-item-header">
    <h5 class="item-title">{{ license.lcsnNm }}</h5>
    <h5 class="item-state">{{ getAplySttNm(license) }}</h5>
  </div>
  <div class="meta-content">
    {{ license.grd1Cd ? license.grd1Cd+'등급 | ': '' }} {{ license.pblcnInstNm }}
    | {{ timestampToDateFormat(license.regDt, 'yyyy.MM.dd') }} 신청
    | {{ license.costLcsnSn }}
    | 지원금 {{ getSprtAmt(license) }}
    | 신청금 {{ license.totAmt > 0 ? numberComma(license.totAmt) : '-' }}
    <span v-if="license.rtnYn=='Y' && license.addAmtYn=='Y'">(환입 및 추가지원)</span>
    <span v-else-if="license.rtnYn=='Y' && license.addAmtYn!='Y'">(환입)</span>
    <span v-else-if="license.rtnYn!='Y' && license.addAmtYn=='Y'">(추가지원)</span>
  </div>
</template>

<script>
import {numberComma, timestampToDateFormat} from "@/assets/js/util";

export default {
  name: "CommonLicenseState",
  props: {
    license: Object
  },
  setup() {

    const getSprtAmt = (x) => {
      let state = '-';

      if(x.stt === '00'){
        if(x.aplySttCdDcd === '2106003') state = '지급준비중';
        else if(x.aplySttCdDcd === '2106006') state = x.sprtAmt > 0 ? numberComma(x.sprtAmt) : 0;
      }

      return state;
    }

    const getAplySttNm = (x) => {
      let state = '-';

      if(x.stt === '81') {
        state = '신청완료';
      } else if(x.stt === '23') {
        if(x.aplySttCdDcd === '2106005') state = '미신청';
        else if(x.aplySttCdDcd === '2106012') state = '지급예정';
      }else if(x.stt === '00') {
        if(x.aplySttCdDcd === '2106003') state = '승인완료';
        else if(x.aplySttCdDcd === '2106006') state = '지급완료';
      } else if(x.stt === '78') {
        state ='반려';
      }

      return state;
    }

    return {
      timestampToDateFormat,
      numberComma,
      getSprtAmt,
      getAplySttNm
    }
  }
}
</script>