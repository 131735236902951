<template>
  <article v-if="activeBadges.length > 0" class="content-section" :class="[ isMobile ? 'section-sbadge' : 'section-divider' ]">
    <header class="section-header">
      <h4 class="title">내 배지 <small class="text-muted ms-2 fw-bold">{{activeBadges.length}}/{{sslHrdBadgesTmp.length}}</small></h4>
      <div v-if="!isMobile" class="header-sub">
        <router-link :to="{name:'BadgeBox'}" class="more-link text-dark">배지 보관함</router-link>
      </div>
    </header>
    <div v-if="!isMobile" class="badge-list-container">
      <swiper
          :modules="modules"
          class="badgeList"
          tag="ul"
          slides-per-view="auto"
          :centeredSlides="false"
          :navigation="{
            nextEl: '.kb-btn-badge-next',
            prevEl: '.kb-btn-badge-prev'
          }"
      >
        <swiper-slide
            v-for="(badge, idx) in activeBadges"
            :key="idx"
            class="badge-item"
            :style="{ 'z-index': (activeBadges && activeBadges.length > 0 ? activeBadges.length - idx : 1) }"
        >
          <BadgeItem
              :badge-ty-cd-dcd="badge.badgeTyCdDcd"
              :is-active="badge.isActive"
              :is-new="badge.isNew"
              :badge-data="badge"
          />
        </swiper-slide>
      </swiper>
      <button class="kb-btn-sbadge-prev kb-btn-badge-prev">
        <i class="icon-arrow-prev">prev</i>
      </button>
      <button class="kb-btn-sbadge-next kb-btn-badge-next">
        <i class="icon-arrow-next">next</i>
      </button>
    </div>

    <div v-else class="section-body">
      <swiper
          class="sbadge-container"
          :modules="modules"
          slides-per-view="auto"
          :centeredSlides="false"
          :pagination="{
            el: '.sbadge-pagination',
            clickable: true
          }"
      >
        <swiper-slide v-for="(badgeItems, idx) in activeBadgesItems" class="sbadge-page mobile-sbadge-wrapper" :key="idx" >
          <MobileBadgeItem
              v-for="(badge, i) in badgeItems"
              :key="`${idx}-${i}`"
              :badge-data="badge"
              :show-title="true"
              @click="showBadgeModal(badge)"
          />
        </swiper-slide>
        <div class="sbadge-pagination swiper-pagination"></div>
      </swiper>
    </div>

    <div v-if="isMobile" class="section-bottom mt-4">
      <router-link :to="{ name: 'BadgeBox' }" custom v-slot="{ navigate }">
        <button class="kb-btn-content-item"
                @click="navigate" @keypress.enter="navigate" role="link">배지 보관함</button>
      </router-link>
    </div>

    <SSLBadgeViewModal
        v-model="badgeModal"
        :selected-badge="selectedBadge"
        type="hrdcloud"
    />

  </article>
</template>

<script>
import {computed, ref,watch} from 'vue';
import BadgeItem from '@/components/history/module/BadgeItem';
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import {Navigation, Pagination} from 'swiper';
import {getHrdBadgeList} from '@/assets/js/modules/hrd/hrd-badge';
import {useStore} from 'vuex';
import MobileBadgeItem from '@/components/history/hrdcloud/mobile/MobileBadgeItem.vue';
import SSLBadgeViewModal from '@/components/ssl/badge/mobile/SSLBadgeViewModal.vue';
import {getListFunc} from "@/assets/js/ui.init";
import {ACT_GET_COM_BADGE_TY_LIST} from "@/store/modules/badge/badge";
import {useRoute} from "vue-router/dist/vue-router";

export default {
  name: 'BadgeList',
  components: {SSLBadgeViewModal, MobileBadgeItem, Swiper, SwiperSlide, BadgeItem },
  props: {
    year: { type: [Number, String], default: null},
    isMobile: Boolean,
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const myBadges = computed(() => store.state.hrdcloud.myBadges);
    const currentDate = new Date();
    const currentYear = ref(props.year ? props.year : currentDate.getFullYear().toString());
    const currentTimestamp = currentDate.getTime();
    const sslHrdBadgesTmp = ref([]);

    getListFunc(`badge/${ACT_GET_COM_BADGE_TY_LIST}`, { year: currentYear.value, badgeKindCdDcd: "2065002"}, sslHrdBadgesTmp, null);



    getHrdBadgeList();

    const activeBadges = computed(() => {
      return myBadges.value.filter(i => i.year === currentYear.value.toString()).map(x => {
        const targetBadge = {
          badgeTySn: x.badgeTySn,
          badgeTyCdDcd: x.badgeTyCdDcd,
          isActive: false,
          isNew: false,
          hiddenYn: x.hiddenYn,
          badgeName: x.badgeTyNm,
          badgeDesc: x.cdDesc
        };

        const found = sslHrdBadgesTmp.value.filter(y => x.badgeTyCdDcd === y.badgeTyCdDcd );
        if(found){
          targetBadge.isActive = true;
          targetBadge.isNew = x.getDt + (1000 * 60 * 60 * 24 * 7) >= currentTimestamp;
        }

        if (found.badgeTyNm ) {
          targetBadge.badgeName = found.badgeTyNm;
        }

        if (found.cdDesc)  {
          targetBadge.badgeDesc = found.cdDesc;
        }

        return targetBadge;
      }).sort((a,b) => {
        if (a.badgeTySn < b.badgeTySn) { return -1; }
        else {return 1;}
      }).sort((a) => {
        if (a.isActive) { return -1; }
        else {return 1;}
      }).sort((a) => {
        if (a.isNew) { return -1; }
        else {return 1;}
      });
    });

    const activeBadgesItems = computed(() => {
      const result = [];
      for(let i=0; i <= activeBadges.value.length/6; i++){
        result.push([]);
        for(let j=0; j < 6; j++){
          if(activeBadges.value[i*6 + j]){
            result[i][j] = activeBadges.value[i*6 + j];
          }
        }
      }
      return result;
    });

    const modules = computed(() => props.isMobile ? [Pagination] : [Navigation]);

    const badgeModal = ref(false);
    const selectedBadge = ref(null);

    const showBadgeModal = (badge) => {
      selectedBadge.value = badge;
      badgeModal.value = true;
    };

    watch(()=> route.query.year,()=>{
      currentYear.value = route.query.year;
      getListFunc(`badge/${ACT_GET_COM_BADGE_TY_LIST}`, { year: route.query.year || currentYear, badgeKindCdDcd: "2065002"}, sslHrdBadgesTmp, null);
    })

    return {
      myBadges,
      activeBadges,
      activeBadgesItems,
      modules,
      showBadgeModal,
      badgeModal,
      selectedBadge,
      sslHrdBadgesTmp,
    }
  }
};
</script>