import ApiService from '@/assets/js/api.service';

export const ACT_GET_QUIZ_LIST = 'actGetQuizList';
export const ACT_INSERT_QUIZ = 'actInsertQuiz';
export const ACT_UPDATE_QUIZ = 'actUpdateQuiz';
export const ACT_DELETE_QUIZ = 'actDeleteQuiz';

export const ACT_GET_QUIZ = 'getQuiz';
export const ACT_GET_QUIZ_QSTNS = 'getQuizQstns';

export const ACT_GET_QSTN = 'getQstn';
export const ACT_DELETE_QSTN = 'deleteQstn';
export const ACT_INSERT_QUIZ_QSTN = 'insertQuizQstn';
export const ACT_INSERT_QUIZ_DIST = 'insertQuizDist';
export const ACT_UPDATE_QUIZ_DIST = 'updateQuizDist';
export const ACT_INSERT_QUIZ_RSLT = 'insertQuizRslt';
export const ACT_GET_QUIZ_RSLT_LIST = 'getQuizRsltList';
export const ACT_GET_QUIZ_RSLT_DTL_LIST = 'getQuizRsltDtlList';
export const ACT_GET_QUIZ_REQUEST = 'getQuizRequest';


export const MUT_SET_QUIZ_DIST_CRSE_SN = 'setQuizDistCrseSn';

const state = {
    distCrseSn: 0
};

const mutations = {
    [MUT_SET_QUIZ_DIST_CRSE_SN](state, distCrseSn){
        state.distCrseSn = distCrseSn;
    }
};

const actions = {
    [ACT_GET_QUIZ_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/quiz/quizs', params).then(response => resolve(response))
        });
    },
    [ACT_INSERT_QUIZ](context, params) {
        return new Promise(resolve => {
            ApiService.post('/v1/app/quiz/quizs', params).then(response => resolve(response))
        });
    },
    [ACT_UPDATE_QUIZ](context, {lrnTyEvlMstSn, params}) {
        return new Promise(resolve => {
            ApiService.put(`/v1/app/quiz/quizs/${lrnTyEvlMstSn}`, params).then(response => resolve(response))
        });
    },
    [ACT_DELETE_QUIZ](context, params) {
        return new Promise(resolve => {
            ApiService.post(`/v1/app/delete/quiz`,params).then(response => resolve(response))
        });
    },
    [ACT_GET_QUIZ](context, lrnTyEvlMstSn) {
        return new Promise(resolve => {
            ApiService.get('/v1/app/quiz/quizs', lrnTyEvlMstSn).then(response => resolve(response))
        });
    },
    [ACT_GET_QUIZ_QSTNS](context, params) {
        return new Promise(resolve => {
            ApiService.query(`/v1/app/quiz/dist/quizs`, params).then(response => resolve(response))
        });
    },
    [ACT_GET_QSTN](context, {lrnTyEvlMstSn, lrnTyEvlQstnSn}) {
        return new Promise(resolve => {
            ApiService.get(`/v1/app/quiz/quizs/qstn/${lrnTyEvlMstSn}`, lrnTyEvlQstnSn).then(response => resolve(response))
        });
    },
    [ACT_DELETE_QSTN](context, {lrnTyEvlMstSn, lrnTyEvlQstnSn}) {
        return new Promise(resolve => {
            ApiService.delete(`/v1/app/quiz/quizs/qstn/${lrnTyEvlMstSn}`, lrnTyEvlQstnSn).then(response => resolve(response))
        });
    },
    [ACT_INSERT_QUIZ_QSTN](context, params) {
        return new Promise(resolve => {
            ApiService.post('/v1/app/quiz/quizs/qstn', params).then(response => resolve(response))
        });
    },
    [ACT_INSERT_QUIZ_DIST](context, params) {
        return new Promise(resolve => {
            ApiService.post('/v1/app/quiz/quizs/dist', params).then(response => resolve(response))
        });
    },
    [ACT_UPDATE_QUIZ_DIST](context, {lrnTyEvlDistSn, params}) {
        return new Promise(resolve => {
            ApiService.put(`/v1/app/quiz/quizs/dist/${lrnTyEvlDistSn}`, params).then(response => resolve(response))
        });
    },
    [ACT_INSERT_QUIZ_RSLT](context, params) {
        return new Promise(resolve => {
            ApiService.post('/v1/app/quiz/quizs/rslt', params).then(response => resolve(response))
        });
    },
    [ACT_GET_QUIZ_RSLT_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/quiz/quizs/rslts', params).then(response => resolve(response))
        });
    },
    [ACT_GET_QUIZ_RSLT_DTL_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/quiz/quizs/rslts/dtls', params).then(response => resolve(response))
        });
    },
    [ACT_GET_QUIZ_REQUEST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/quiz/quizs/request', params).then(response => resolve(response))
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
