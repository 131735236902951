<template>
  <!-- cloud-header -->
  <LxpMobileHeader v-if="!isOptMngShow" id="kb-header" title="퀴즈문항추가">
    <template v-slot:left>
        <div class="util util-back">
            <a href="javascript:" class="util-actions util-actions-back" @click="closeLayer()">
                <i class="icon-history-back"></i>
            </a>
        </div>
    </template>
  </LxpMobileHeader>
  <!-- //cloud-header -->
  <!-- cloud-main -->
  <main v-if="!isOptMngShow && !isOptAnsrMngShow" class="kb-main" id="kb-prep-quiz">
      <!-- main-view -->
      <div class="main-content">
        <div>
          <div class="view-contents">
            <!-- content-section -->
            <article class="content-section section-forms">
              <div class="kb-form-fields kb-form-fields-v2">
                <div class="kb-form-field">
                  <div class="kb-form-row">
                    <label class="kb-form-label"><span class="kb-form-label-text">문항</span></label>
                  </div>
                  <div class="kb-form-row">
                    <textarea v-model="qstnNm" class="kb-form-control" placeholder="문항을 입력하세요"></textarea>
                  </div>
                </div>
                <div class="kb-form-field">
                    <div class="kb-form-row">
                        <label class="kb-form-label"><span class="kb-form-label-text">보기</span></label>
                        <span v-if="evlQstnOpt.length > 0" class="kb-form-side-text">정답</span>
                    </div>
                    <div class="kb-form-row row-checkgroup">
                        <div class="kb-form-check-group group-icon">
                            <div v-for="(item, idx) in evlQstnOpt" :key="idx" class="kb-form-check">
                                <input type="checkbox" class="kb-form-check-input" name="chk_01" id="chk_01_1" :checked="item.ansYn === 'Y'" readonly onclick="return false;"/>
                                <label for="chk_01_1" class="kb-form-check-label">{{ item.optNm }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="kb-form-row">
                        <button @click="optionLayer" class="kb-btn-content-item"><span class="text">보기 설정</span></button>
                    </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
      <div class="page-buttons">
          <button class="kb-btn kb-btn-primary" @click="addQstn"><span class="text">저장</span></button>
      </div>
  </main>
  <!-- //cloud-main -->

  <!-- 보기설정 -->
  <MobileOptionMng v-if="isOptMngShow && !isOptAnsrMngShow" v-model:isOptMngShow="isOptMngShow"
                   v-model:evlQstnOpt="evlQstnOpt" v-model:lrnTyEvlQstnSn="lrnTyEvlQstnSn"
                   :addQstnOpt="addQstnOpt" :deleteQstnOpt="deleteQstnOpt" :optAnsrLayer="optAnsrLayer"
  />

  <!-- 정답설정 -->
  <MobileOptionAnswerMng v-if="isOptAnsrMngShow" v-model:isOptAnsrMngShow="isOptAnsrMngShow" v-model:evlQstnOpt="evlQstnOpt"
                         :ansCheck="ansCheck"
  />

</template>

<script>
import {computed, onMounted, ref, watch} from 'vue';
import LxpMobileHeader from '@/views/layout/lxp/header/mobile/LxpMobileHeader';
import MobileOptionMng from '@/components/quiz/mobile/MobileOptionMng.vue';
import MobileOptionAnswerMng from '@/components/quiz/mobile/MobileOptionAnswerMng.vue';
import {useStore} from 'vuex';
import {useAlert} from '@/assets/js/modules/common/alert';
import {ACT_GET_QSTN, ACT_INSERT_QUIZ_QSTN} from '@/store/modules/quiz/quiz';
import {getItem, isSuccess, lengthCheck} from '@/assets/js/util';

export default {
  name: "MobileQuizMngQstnAdd",
  components: {
    LxpMobileHeader,
    MobileOptionAnswerMng,
    MobileOptionMng
  },
  props:{
    isQstnAddShow: Boolean,
    quizSn: Number,
    qstnSn: Number,
    qstnIdx: Number,
    distCnt: Number,
    getQstnList: Function
  },
  emits:['update:isQstnAddShow'],
  setup(props, {emit}){

    console.log('qstnAdd;;', props.quizSn,',',props.qstnSn,',',props.qstnIdx)

    const store = useStore();
    const {showMessage} = useAlert();
    const qstnNm = ref('');
    const evlQstnOpt = ref([
    ]);

    const lrnTyEvlMstSn = ref(computed(() => props.quizSn)); // 퀴즈번호
    const lrnTyEvlQstnSn = ref(computed(() => props.qstnSn)); // 문항번호
    const qstnNo = ref(props.qstnIdx); // 문항번호
    const distCrseSn = computed(() => store.state.quiz.distCrseSn);

    onMounted(() => {
      if(lrnTyEvlQstnSn.value > 0){ // 추가,수정구분
        getQstn();
      }
    })

    const isOptMngShow = ref(false);
    const isOptAnsrMngShow = ref(false);

    // 보기설정 화면으로 이동
    const optionLayer = () => {
      if(props.distCnt > 0){
        showMessage('출제된 퀴즈는 수정할수 없습니다.');
        return false;
      }
      isOptMngShow.value = true;
    }

    // 정답설정 화면으로 이동
    const optAnsrLayer = () => {
      isOptAnsrMngShow.value = true;
    }

    const getQstn = () => {
      store.dispatch(`quiz/${ACT_GET_QSTN}`, {
        lrnTyEvlMstSn: lrnTyEvlMstSn.value,
        lrnTyEvlQstnSn: lrnTyEvlQstnSn.value,
      }).then(res => {
        if (lengthCheck(res)) {
          let evlQstn = getItem(res);
          qstnNo.value = evlQstn.qstnNo
          qstnNm.value = evlQstn.qstnNm;
          evlQstnOpt.value = evlQstn.quizOptList;
        } else {
          evlQstnOpt.value = [];
        }
      }).catch(e => {
        console.error(e);
      });
    };

    // 문항추가
    const addQstn = () => {
      if(props.distCnt > 0){
        showMessage('출제된 퀴즈는 수정할수 없습니다.');
        return false;
      }

      if(qstnNm.value === ''){
        showMessage('문제 입력하세요.');
        return false;
      }

      let isCrctAnsCheck = false;
      let isOptNmEmpty = false;
      evlQstnOpt.value.forEach(x => {
        if(x.optNm === ''){
          isOptNmEmpty = true;
        }
        if(x.ansYn === 'Y'){
          isCrctAnsCheck = true;
        }
      })

      if(evlQstnOpt.value.length === 0 || isOptNmEmpty){
        showMessage("보기를 입력해주세요.");
        return false;
      }

      if(!isCrctAnsCheck){
        showMessage('정답을 체크해주세요.');
        return false;
      }

      doInsertQstn();
    }

    const evlOpt = ref([]);
    const doInsertQstn = () => {
      let optIdx = 1;
      evlOpt.value = evlQstnOpt.value.map(x => ({...x, idx: optIdx++}));
      store.dispatch(`quiz/${ACT_INSERT_QUIZ_QSTN}`, {
        qstnNm: qstnNm.value,
        lrnTyEvlQstnSn: lrnTyEvlQstnSn.value, // 문항시퀀스
        lrnTyEvlMstSn: lrnTyEvlMstSn.value, // 퀴즈번호
        lrnEvlMstSn: distCrseSn.value, // 차수번호
        optYn: 'Y',
        optCnt: evlQstnOpt.value.length,
        crctAns: evlOpt.value.filter(x => x.ansYn === 'Y').map(y => y.idx).join(','),
        qstnNo : qstnNo.value, // 인덱스로 착각하면안됨
        quizOptList: evlQstnOpt.value,
      }).then(res => {
        if (isSuccess(res)) {
          showMessage('문항이 추가됐습니다.');
          props.getQstnList();
          closeLayer();
        }
      }).catch(e => {
        console.error(e);
      });
    };

    // 보기추가
    const addQstnOpt = () => {
      if(evlQstnOpt.value.length >= 5) return false;
      evlQstnOpt.value.push({optNo: evlQstnOpt.value.length+1, optNm: '', ansYn: evlQstnOpt.value.length === 0 ? 'Y' : 'N'})
    }

    // 보기삭제
    const deleteQstnOpt = (idx) => {
      if(evlQstnOpt.value.length < 3) return false;
      evlQstnOpt.value.splice(idx, 1);
      evlQstnOpt.value.filter((item, index) => {  item.optNo = index+1 });
    }

    // 정답체크
    const ansCheck = (idx) => {
      evlQstnOpt.value = evlQstnOpt.value.map(x => ({...x, ansYn: 'N'}));
      evlQstnOpt.value[idx].ansYn = 'Y';

      let optIdx = 1;
      evlOpt.value = evlQstnOpt.value.map(x => ({...x, idx: optIdx++}));
    }

    const closeLayer = () => {
      emit('update:isQstnAddShow', false);
      document.getElementById('kb-header').style.display = '';
      document.getElementById('kb-prep-quiz').style.display = '';
    }


    watch(()=> isOptAnsrMngShow.value, ()=>{
      if(!isOptAnsrMngShow.value) {
        isOptMngShow.value = false;
      }
    });

    return {
      qstnNm,
      lrnTyEvlQstnSn,
      evlQstnOpt,
      isOptMngShow,
      isOptAnsrMngShow,
      addQstn,
      addQstnOpt,
      deleteQstnOpt,
      ansCheck,
      optionLayer,
      optAnsrLayer,
      closeLayer,
    }
  }
}
</script>

<style scoped>
.opt-input{
  width: 100%;
}
</style>