<template>
  <input
      ref="uploadFile"
      type="file" id="inpFile" name="inpFile"
      :class="[ isMobile ? 'kb-form-file-input' : 'file-kb-form-input' ]"
      :accept="mimeType"
      @change="addFile"
  />
</template>

<script>
import {computed, ref, toRef} from 'vue';
import {
  allowedCmsAtchFile,
  allowedKbtubeAtchFile,
  allowedThumbExts,
  allowedThumbFile,
  atchFileExts,
  checkExtension,
  fileLengthCheck,
  kbtubeAtchFileExts,
} from '@/assets/js/file-util';
import {useAlert} from '@/assets/js/modules/common/alert';
import {useStore} from 'vuex';

export default {
  name: 'FileUpload',
  props: {
    modelValue: {
      type: Object,
      default: () => ({
        files: [],
        removed: []
      })
    },
    fileType: {
      type: String,
      default: 'vod'
    },
    sizeLimit: Number
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const store = useStore();
    const isMobile = computed(() => store.state.auth.isMobile);

    const fileType = toRef(props, 'fileType');
    const sizeLimit = toRef(props, 'sizeLimit');
    const {showMessage} = useAlert();

    const uploadFile = ref(null);

    const mimeType = computed(() => {
      let mime = ['video/mp4'];
      if (fileType.value === 'atch') mime = allowedCmsAtchFile;
      else if (fileType.value === 'kbtube') mime = allowedKbtubeAtchFile;
      else if (fileType.value === 'img') mime = allowedThumbFile;
      return mime;
    });

    const allowedExt = computed(() => {
      if (fileType.value === 'atch') return atchFileExts;
      else if (fileType.value === 'kbtube') return kbtubeAtchFileExts;
      else if (fileType.value === 'img') return allowedThumbExts;
      else return ['mp4','mov'];
    });

    const addFile = () => {
      // 업로드한 파일 유무 확인
      if (fileLengthCheck(uploadFile)) {
        // 업로드된 파일 확장자 확인 후 일치하지 않을 경우 알럿 노출
        if (checkExtension(uploadFile.value.files, allowedExt.value)) {
          let msg = '지원하지 않는 확장자입니다.';
          if (fileType.value === 'kbtube') {
            msg = '부연설명자료(PPT, PDF, WORD)로 최대 1개(10MB)까지만 등록하실 수 있습니다.';
          } else if (fileType.value === 'vod') {
            msg = `확장자가 mp4 인 파일만 등록 가능합니다.`;
          }
          showMessage(msg);
          uploadFile.value.value = null;
          return;
        }

        // 업로드된 파일은 최대 1개까지만 등록가능
        // if (uploadFile.value.files.length > 1) {
        //   showMessage(`최대 1개까지만 등록 가능합니다.`);
        //   uploadFile.value.value = null;
        //   return;
        // }

        // 용량 체크
        if (sizeLimit.value > 0 && uploadFile.value.files[0].size > sizeLimit.value * 1024 * 1024) {
          showMessage(`파일첨부는 최대 10Mb까지만 등록 가능합니다.`);
          uploadFile.value.value = null;
          return;
        }

        const files = [];
        for(let i=0; i < uploadFile.value.files.length; i++){
          files.push(uploadFile.value.files[i]);
        }

        const params = {
          file: [...files]
        };

        emit('update:modelValue', params);
        uploadFile.value.value = null;
      }
    };

    return {
      uploadFile, mimeType,
      addFile, isMobile
    }
  }
};
</script>
