import ApiService from '@/assets/js/api.service';

export const ACT_GET_ITEM_LIST = 'actGetItemList';
export const ACT_GET_PORTFOLIO_LIST = 'actGetPortfolioList';
export const ACT_GET_PORTFOLIO = 'actGetPortfolio';
export const ACT_GET_SCHEDULE_LIST = 'actGetScheduleList';
export const ACT_GET_NEW_SEQ = 'actGetNewSeq';
export const ACT_GET_SCHEDULE = 'actGetSchedule';
export const ACT_GET_ADMIN_YN = 'actGetAdminYn';
export const ACT_GET_STUDENT_YN = 'actGetStudentYn'
export const ACT_GET_LAST_PORTFOLIO = 'actGetLastPortfolio';
export const ACT_GET_HRD_CLOUD_STUDY_LIST = 'actGetHrdCloudStudyList';

export const ACT_INSERT_SCHEDULE = 'actInsertSchedule';
export const ACT_INSERT_PORTFOLIO = 'actInsertPortfolio';

export const ACT_UPDATE_SCHEDULE = 'actUpdateSchedule';
export const ACT_UPDATE_PORTFOLIO = 'actUpdatePortfolio';

export const MUT_SET_ADMIN_YN = 'setAdminYn';
export const MUT_SET_STUDENT_YN = 'setStudentYn';



const state = {
    adminYn: 'N',
    studentYn: 'N',
};


const mutations = {

    [MUT_SET_ADMIN_YN](state, Yn){
        state.adminYn = Yn;
    },

    [MUT_SET_STUDENT_YN](state, Yn){
        state.studentYn = Yn;
    }

};


const actions = {
    [ACT_GET_ITEM_LIST](context, params){
        return new Promise(resolve => {
            ApiService.query('/v1/app/wmportfolio/itemlist', params).then(response => resolve(response));
        });
    },
    [ACT_GET_PORTFOLIO_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/wmportfolio/list', params).then(response => resolve(response))
        });
    },
    [ACT_GET_PORTFOLIO](context, pfSeq) {
        return new Promise(resolve => {
            ApiService.get('/v1/app/wmportfolio/portfolio', pfSeq).then(response => resolve(response))
        });
    },

    [ACT_GET_SCHEDULE_LIST](){
        return new Promise(resolve => {
            ApiService.get('/v1/app/wmportfolio/schedulelist').then(response => resolve(response))
        });
    },
    [ACT_GET_NEW_SEQ](context, atdDivCd) {
        return new Promise(resolve => {
            ApiService.get('/v1/app/wmportfolio/newSeq', atdDivCd).then(response => resolve(response))
        });
    },
    [ACT_GET_SCHEDULE](context, schdlSn) {
        return new Promise(resolve => {
            ApiService.get('/v1/app/wmportfolio/schedule', schdlSn).then(response => resolve(response))
        });
    },
    [ACT_GET_LAST_PORTFOLIO](){
        return new Promise(resolve => {
            ApiService.get('/v1/app/wmportfolio/lastportfolio').then(response => resolve(response))
        });
    },
    [ACT_GET_ADMIN_YN]() {
        return new Promise(resolve => {
            ApiService.get('/v1/app/wmportfolio/adminYn').then(response => resolve(response))
        });
    },
    [ACT_GET_STUDENT_YN]() {
        return new Promise(resolve => {
            ApiService.get('/v1/app/wmportfolio/studentYn').then(response => resolve(response))
        });
    },
    [ACT_INSERT_SCHEDULE](context, params){
        return new Promise(resolve => {
            ApiService.post(`/v1/app/wmportfolio/schedule`, params).then(response => resolve(response));
        });
    },
    [ACT_UPDATE_SCHEDULE](context, params){
        return new Promise(resolve => {
            ApiService.put(`/v1/app/wmportfolio/schedule`, params).then(response => resolve(response));
        });
    },
    [ACT_INSERT_PORTFOLIO](context, params){
        return new Promise(resolve => {
            ApiService.post(`/v1/app/wmportfolio/portfolio`, params).then(response => resolve(response));
        });
    },
    [ACT_UPDATE_PORTFOLIO](context, {pfSeq, params}){
        return new Promise(resolve => {
            ApiService.update(`/v1/app/wmportfolio/portfolio`, pfSeq, params).then(response => resolve(response));
        });
    },

    [ACT_GET_HRD_CLOUD_STUDY_LIST](context, params){
        return new Promise(resolve => {
            ApiService.query(`/v1/app/wmportfolio/hrdcloudlist`, params).then(response => resolve(response));
        });
    },

};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};