<template>
  <div class="popup-container" :class="{'is-active': isVisible}" data-popup="popup-upload">
    <!-- begin::검색창 -->
    <div class="popup">
      <form onsubmit="return false;">
        <div class="upload-container">
          <div class="upload-placeholder">
            <div class="placeholder-image">
               <img v-if="hasThumbnail" :src="thumb" alt="" />
            </div>
            <!-- [dev: data-visible="hover"] -->
<!--            <div class="placeholder-actions" :data-visible="hasThumbnail ? 'hover' : ''">-->
            <div class="placeholder-actions" data-visible="hover">
              <label for="formFile" class="kb-form-file-label kb-btn kb-btn-images">
                <span class="text">이미지 {{ hasThumbnail ? '변경' : '추가' }}하기</span>
                <input ref="uploadFile" type="file" id="formFile" class="kb-form-file-input" @change="setThumbnail">
              </label>
              <!-- 추천 이미지는 현재 제외 또는 추후 파일을 지정할 것 -->
              <button class="kb-btn kb-btn-images" @click="setRecommend">
                <span class="text">추천 이미지</span>
              </button>
            </div>
          </div>
          <div class="upload-actions">
            <button class="kb-btn kb-btn-outline-light" type="button" @click="closeFunc">
              <span class="text">취소</span>
            </button>
            <button class="kb-btn kb-btn-primary" type="button" :disabled="!hasThumbnail" @click="applyThumbnail">
              <span class="text">적용</span>
            </button>
          </div>
        </div>
      </form>
      <div class="popup-deem"></div>
    </div>
  </div>
</template>

<script>

import {computed, ref, watch} from "vue";
import {getRandomThumb} from "@/assets/js/modules/ssl/ssl-write";

export default {
  name: 'SSLUploadModal',
  props: {
    isVisible: Boolean,
    closeFunc: Function,
    applyFunc: Function,
    initEffect: Number
  },
  setup(props) {
    // const store = useStore();
    // const isLoading = ref(false);
    const thumb = ref('');
    const uploadFile = ref(null);

    const hasThumbnail = computed(() => !!thumb.value)

    const imgReader = new FileReader();
    imgReader.onload = (event) => {
      thumb.value = event.target.result;
    };

    const setThumbnail = () => {
      if(uploadFile.value.files && uploadFile.value.files.length > 0){
        const file = uploadFile.value.files[0];
        // 썸 네일 처리
        imgReader.readAsDataURL(file);
      }
    }

    const applyThumbnail = () => {
      if(hasThumbnail.value){
        if(uploadFile.value && uploadFile.value.files && uploadFile.value.files.length > 0){
          props.applyFunc(thumb.value, uploadFile.value.files[0]);
        }else{
          props.applyFunc(thumb.value, null);
        }
        props.closeFunc();
      }
    }

    const setRecommend = () => {
      // if(isLoading.value) return;
      // isLoading.value = true;
      uploadFile.value.value = null;
      thumb.value = getRandomThumb();
      // store.dispatch(`ssl/${ACT_GET_UNSPLASH_IMAGE_LIST}`).then(res => {
      //   if(res && res.urls && res.urls.small) {
      //     thumb.value = res.urls.small;
      //     // input 파일 제거
      //     uploadFile.value.value = null;
      //   }
      //   isLoading.value = false;
      // }).catch(() => {
      //   isLoading.value = false;
      // });
    }

    watch(() => props.initEffect, () => {
      if(uploadFile.value){
        uploadFile.value.value = null;
      }
      thumb.value = '';
    });


    const thumb_select = ref("../../assets/images/ssl/select_thumb.svg");

    return {
      thumb,
      hasThumbnail,
      uploadFile,
      setThumbnail,
      applyThumbnail,
      setRecommend,
      thumb_select
    }
  }
}
</script>
