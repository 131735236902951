<template>
  <div class="popup-container" :class="{ 'is-active' : modelValue }">
    <div class="popup" id="popup-hrdcloud-g03">
      <div class="popup-inner">
        <header class="popup-header">
          <h3 class="title">HRD 클라우드 콘텐츠 상세 학습이력</h3>
        </header>
        <LoadingDiv v-if="isLoading" />
        <div v-else class="popup-content">
          <article v-if="summary" class="popup-section">
            <header class="section-header">
              <h4 class="title">콘텐츠 학습 누적 정보</h4>
            </header>
            <div class="kb-table kb-table-bordered kb-table-bordered-all">
              <table>
                <colgroup>
                  <col />
                  <col style="width:152px" />
                  <col style="width:152px" />
                  <col style="width:152px" />
                  <col style="width:152px" />
                  <col style="width:152px" />
                  <col style="width:152px" />
                </colgroup>
                <thead>
                <tr>
                  <th rowspan="2"><span class="th-title">콘텐츠명</span></th>
                  <th colspan="4"><span class="th-title">학습완료여부</span></th>
                  <th rowspan="2"><span class="th-title">마일리지</span></th>
                  <th rowspan="2"><span class="th-title">비고</span></th>
                </tr>
                <tr>
                  <th class="border-s"><span class="th-title">누적학습시간</span></th>
                  <th><span class="th-title">누적진도율</span></th>
                  <th><span class="th-title">{{ detTitle.dtlColdcall }}</span></th>
                  <th><span class="th-title">완료여부</span></th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td><strong class="td-text">{{ summary.objNm }}</strong></td>
                  <td><span class="td-text">{{ getConvertTime(
                            summary.cttTyCdDcd, summary.cttTyCdDcd === cttTyCdDcds.CTT_TY_CD_ZIP ? summary.actPlaySec : summary.accumPlaySec) }}</span></td>
<!--                    {{ summary.accumPlaySec >= summary.cttSz ? '(O)' : '(X)' }}-->
                  <td><span class="td-text">{{ getProgressPercent(summary.cttSz, summary.accumPlaySec, summary.cttTyCdDcd) }} %</span></td>
<!--                  {{ summary.progPrcnt === 100 ? '(O)' : '(X)' }}-->
                  <td>
                    <span class="td-text">{{dtlColdcallValue}}</span>
                  </td>
<!--                  {{ summary.coldCallRspnsDt ? '(O)' : '(X)' }}-->
                  <td>
                    <span v-if="summary.cmptnYn === 'Y'" class="td-text text-primary">완료</span>
                    <span v-else class="td-text text-muted">미완료</span>
                  </td>
                  <td><span class="td-text">{{ summary.mlgScore }}</span></td>
                  <td>
                    <span class="td-text" :style="summary.mlgTyCd === '1001' || summary.mlgTyCd === '1003' || summary.mlgTyCd === '1004' || summary.mlgTyCd === '1005' ? {color:'#ff8000'} : {}">
                      {{ summary.mlgGiveRsn ? summary.mlgGiveRsn : '-' }} {{ getMlgGiveDt(summary) }}
                    </span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </article>
          <article class="popup-section">
            <header class="section-header">
              <h4 class="title">콘텐츠 상세 플레이로그</h4>
            </header>
            <div class="kb-table kb-table-bordered">
              <table>
                <colgroup>
                  <col />
                  <col style="width:152px" />
                  <col style="width:152px" />
                  <col style="width:152px" />
                  <col style="width:152px" />
                  <col style="width:300px" />
                </colgroup>
                <thead>
                <tr>
                  <th><span class="th-title">날짜시간</span></th>
                  <th><span class="th-title">로그</span></th>
                  <th><span class="th-title">학습시간</span></th>
                  <th><span class="th-title">{{ detTitle.logColdcall }}</span></th>
                  <th><span class="th-title">{{ detTitle.logRspdYn }}</span></th>
                  <th><span class="th-title">{{ detTitle.logRspdDt }}</span></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, idx) in body" :key="idx">
                  <td><span class="td-text">{{ timestampToDateFormat(item.regDt, 'yyyy.MM.dd hh:mm:ss') }}</span></td>
                  <td><span class="td-text">{{ item.eventNm }}</span></td>
                  <td><span class="td-text">{{ item.playTime }}</span></td>
                  <td><span class="td-text">{{ item.coldCallRound }}</span></td>
                  <td><span class="td-text">{{ item.coldCallRspns }}</span></td>
                  <td><span class="td-text">{{ item.coldCallRspnsDt ? timestampToDateFormat(item.coldCallRspnsDt, 'yyyy.MM.dd hh:mm:ss') : '-' }}</span></td>
                </tr>
                <tr v-if="body.length === 0">
                  <td colspan="6">
                    <div class="search-container">
                      <div class="result-empty">
                        <img src="../../assets/lxp/images/common/img_empty.png" alt="">
                        <p v-if="!paramCheck(month)" class="text">최근 1개월간 이력이 없습니다.</p>
                        <p v-else class="text">시청 이력이 없습니다.</p>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="kb-table-bottom kb-table-bottom-divider bottom-notice">
            <!-- <strong class="text texticon text-muted"><i class="icon-notice"></i> 최근 1개월간 해당 콘텐츠 시청 상세 이력입니다.</strong> -->
            </div>
          </article>
        </div>
      </div>
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingDiv from '@/components/common/LoadingDiv';
import {hrdHistoryDtlSetup} from '@/assets/js/modules/hrd/hrd-history-dtl-setup';
import {cttTyCdDcds} from "../../assets/js/modules/hrd/hrd-common";

export default {
  name: 'HrdCloudHistoryDtlModal',
  computed: {
    cttTyCdDcds() {
      return cttTyCdDcds
    }
  },
  components: {LoadingDiv},
  props: {
    modelValue: Boolean,
    lrnObjDtlDistSn: [Number, String],
    year: [Number, String],
    month: [Number, String]
  },
  emits: ['update:modelValue', 'update:lrnObjDtlDistSn'],
  setup: hrdHistoryDtlSetup
};
</script>