import {ref} from "vue";
import {useStore} from "vuex";
import {useAlert} from "@/assets/js/modules/common/alert";
import {ACT_UPDATE_GROUP_LEADER} from "@/store/modules/board/board";
import {isSuccess} from "@/assets/js/util";

export const changeLeaderSetup = (props, {emit}) => {

    const store = useStore();

    const { showMessage, showConfirm } = useAlert();

    const checkedUser = ref([]);

    const params = ref([]);

    const clickChange = () => {
        if(checkedUser.value.length < 1) {
            showMessage('리더로 변경할 직원을 선택하세요.');
        } else {
            showConfirm({
                text: '그룹의 리더를 변경 하시겠습니까?',
                callback: () => {
                    changeLeader().then(res => {
                        if(isSuccess(res)) {
                            closeModal();
                        }
                    })
                }
            });
        }
    }

    const changeLeader = async () => {
        try {
            const leader = props.members.filter(x => x.grpRoleCdDcd === '2010001')[0];

            params.value.push({
                lrnerId: checkedUser.value.lrnerId,
                lrnTrgtGrpDtlSn: checkedUser.value.lrnTrgtGrpDtlSn,
                grpRoleCdDcd: checkedUser.value.grpRoleCdDcd
            });

            params.value.push({
                lrnerId: leader.lrnerId,
                lrnTrgtGrpDtlSn: leader.lrnTrgtGrpDtlSn,
                grpRoleCdDcd: leader.grpRoleCdDcd
            });

            return await store.dispatch(`board/${ACT_UPDATE_GROUP_LEADER}`, params.value);

        } catch (e) {
            console.error(e);
        }
    }

    const closeModal = () => {
        emit('update:modelValue', false);
    }

    return {
        checkedUser,
        clickChange,
        closeModal
    }
}