<template>
  <div :class="{'lxp-layout': !isMobile, 'lxp-mobile-layout': isMobile}">
    <router-view/>
    <LxpPopup v-if="loaded"/>
  </div>
</template>
<script>
import LxpPopup from "@/components/common/LxpPopup";
import {useStore} from "vuex";
import {computed, onMounted, ref} from "vue";
// import { setProfileImgUrl } from "@/assets/js/modules/common/common";

export default {
  components: {LxpPopup},
  setup() {
    const store = useStore();
    const isMobile = computed(() => store.state.auth.isMobile);
    const loaded = ref(false);
    // setProfileImgUrl();

    onMounted(() => {
      loaded.value = true;
    });

    return {
      loaded,
      isMobile
    }
  }

}
</script>