import ApiService from '@/assets/js/api.service';

// ACTION
export const ACT_GET_NOTIFICATION_LIST = 'getNotificationList';

export const ACT_GET_NOTI_TRSM_BAR_LIST = 'getNotiTrsmBarList';
export const ACT_GET_NOTI_TRSM_LIST = 'getNotiTrsmList';
export const ACT_GET_NOTI_TRSM = 'getNotiTrsm';

export const ACT_DELETE_NOTI_TRSM = 'deleteNotiTrsm';

export const ACT_GET_LRNER_NOTI_MNG = 'actGetLrnerNotiMng';
export const ACT_INSERT_LRNER_NOTI_MNG = 'actInsertLrnerNotiMng';
export const ACT_UPDATE_LRNER_NOTI_MNG = 'actUpdateLrnerNotiMng';

export const ACT_GET_NOTI_HRD_SUBSCRIBE_LST = 'actGetNotiHrdSubscribeLst';

export const ACT_GET_NOTI_TRSM_APPPUSH_LIST = 'getNotiTrsmApppushList';

const state = {
};

const mutations = {
};

const actions = {

    [ACT_GET_NOTIFICATION_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa034as', params).then(response => resolve(response))
        });
    },
    [ACT_GET_NOTI_TRSM_BAR_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/notis/trsms/bar', params).then(response => resolve(response))
        });
    },
    [ACT_GET_NOTI_TRSM_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/notis/trsms', params).then(response => resolve(response))
        });
    },
    [ACT_GET_NOTI_TRSM](context, notiTrsmSn) {
        return new Promise(resolve => {
            ApiService.get('/v1/app/notis/trsms', notiTrsmSn).then(response => resolve(response))
        });
    },
    [ACT_DELETE_NOTI_TRSM](context, notiTrsmSn) {
        return new Promise(resolve => {
            ApiService.delete('/v1/app/notis/trsms', notiTrsmSn).then(response => resolve(response))
        });
    },
    [ACT_GET_LRNER_NOTI_MNG](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/noti/config', params).then(response => resolve(response))
        });
    },
    [ACT_INSERT_LRNER_NOTI_MNG](context, params) {
        return new Promise(resolve => {
            ApiService.post('/v1/app/noti/config', params).then(response => resolve(response))
        });
    },
    [ACT_UPDATE_LRNER_NOTI_MNG](context, {lrnerNotiMngSn, params}) {
        return new Promise(resolve => {
            ApiService.put(`/v1/app/noti/config/${lrnerNotiMngSn}`, params).then(response => resolve(response))
        });
    },
    [ACT_GET_NOTI_HRD_SUBSCRIBE_LST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/noti/hrd/subscribe', params).then(response => resolve(response))
        });
    },
    [ACT_GET_NOTI_TRSM_APPPUSH_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/notis/trsms/apppush', params).then(response => resolve(response))
        });
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
