<template>
  <article v-if="currentNotice != null" class="home-section section-notice">
    <div class="notice-list">
      <router-link :to="{name: 'SSLNoticeView', params: {socLrnNtcSn: currentNotice.comNtcSn}}" class="notice-link">
        <span class="notice-date">{{ timestampToDateFormat(currentNotice.regDt, 'yyyy.MM.dd') }}</span>
<!--        <span class="notice-text text-truncate">{{ currentNotice.ntcTitle  }}</span>-->
        <span class="text-truncate text-skip" style="width: 15em;" :title="currentNotice.ntcTitle">{{ currentNotice.ntcTitle }}</span>
      </router-link>
    </div>
    <div class="notice-more">
      <router-link :to="{name: 'SSLNotice'}" class="notice-more-link">공지목록</router-link>
    </div>
  </article>
</template>
<script>

import {useStore} from 'vuex';
import {computed, onBeforeUnmount, ref} from 'vue';
import {ACT_GET_SSL_NTC_LIST} from '@/store/modules/ssl/ssl-index';
import {dateToDateFormat, getItems, lengthCheck, timestampToDateFormat} from '@/assets/js/util';
import {expsrLocCds} from '@/assets/js/modules/help/help-common';

export default {
  name: 'SSLMobileHomeNotice',
  components: {},
  setup() {
    const store = useStore();
    const items = ref([]);
    const currentIdx = ref(0);

    const noticeInterval = ref(null);

    const currentNotice = computed(() => {
      if(items.value[currentIdx.value]){
        return items.value[currentIdx.value];
      }
      return null;
    });

    const slideButton = (isNext) => {
      if(isNext){
        const nextIdx = currentIdx.value + 1;
        if(nextIdx > (items.value.length - 1)){
          currentIdx.value = 0;
        }else{
          currentIdx.value = nextIdx;
        }
      }else{
        const prevIdx = currentIdx.value -1;
        if(prevIdx < 0){
          currentIdx.value = (items.value.length - 1);
        }else{
          currentIdx.value = prevIdx;
        }
      }
    }

    const getHomeNoticeList = () => {
      store.dispatch(`ssl/${ACT_GET_SSL_NTC_LIST}`, {
        nowDate: dateToDateFormat(new Date(), 'yyyy-MM-dd'),
        expsrLocCd: `${expsrLocCds.EXPSR_LOC_CD_SSL_COMMON},${expsrLocCds.EXPSR_LOC_CD_SSL_MAIN}`,
        pageNo: 1,
        pageSize: 5
      }).then(res => {
        if(lengthCheck(res)){
          items.value = getItems(res);
          if(items.value.length > 1){
            noticeInterval.value = setInterval(() => {
              slideButton(true);
            }, 3000);
          }
        }
      }).catch(() => {});
    }

    onBeforeUnmount(() => {
      if(noticeInterval.value){
        clearInterval(noticeInterval.value);
        noticeInterval.value = null;
      }
    })

    getHomeNoticeList();

    return {
      currentNotice,
      timestampToDateFormat,
      slideButton
    }

  }
}
</script>
