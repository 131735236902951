import ApiService from '@/assets/js/api.service';

export const MUT_SHOW_COLDCALL = 'showColdCall';
export const MUT_CLOSE_COLDCALL = 'closeColdCall';

export const ACT_GET_COLDCALL_TIME = 'checkColdCall'
export const ACT_GET_COLDCALL_LIST = 'getColdCall';
export const ACT_INSERT_COLDCALL = 'actInsertColdcall';

export const ACT_UPDATE_COLDCALL = 'actUpdateColdcall';

const state = {
  coldcall: {
    show: false,
    callback: null,
    closeCallback: null
  }
};

const mutations = {
  [MUT_SHOW_COLDCALL](state, {callback, closeCallback}) {
    state.confirm.callback = callback;
    state.confirm.closeCallback = closeCallback;
    state.confirm.show = true;
  },
  [MUT_CLOSE_COLDCALL](state) {
    state.confirm.show = false;
  }
};

const actions = {
  [ACT_GET_COLDCALL_TIME](context, params) {
    return new Promise(resolve => {
      ApiService.query('/v1/app/coldcall',params).then(response => resolve(response));
    });
  },
  [ACT_INSERT_COLDCALL](context, params) {
    return new Promise(resolve => {
      ApiService.post('/v1/app/coldcall', params).then(response => resolve(response));
    });
  },
  [ACT_UPDATE_COLDCALL](context, {progLogSn, params}) {
    return new Promise(resolve => {
      ApiService.put(`/v1/app/coldcall/${progLogSn}`, params).then(response => resolve(response));
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
