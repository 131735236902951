<template>
  <img :src="profileImgUrl" @error="setDefaultImg" alt="" />
</template>

<script>
// import {getProfileImg} from '@/assets/js/modules/common/common'; getProfileImg(target.lrnerId)
import {computed, ref} from 'vue';
import {useStore} from 'vuex';
import {ACT_GET_PROFILE_IMG_URL_BY_ID} from '@/store/modules/my/my';

export default {
  name: 'ProfileImg',
  props: {
    target: Object
  },
  setup(props) {
    const store = useStore();
    const session = computed(() => store.state.auth.session);
    const setDefaultImg = (e) => {
      e.target.src = require('../../assets/images/profile/sample.png');
    }

    let profileImgUrl = ref('')

    if (session.value.lrnerId !== props.target.lrnerId) {
      store.dispatch(`my/${ACT_GET_PROFILE_IMG_URL_BY_ID}`, {userProfileTyCdDcd: '2161001', lrnerId:props.target.lrnerId}).then( res => {
        if(res.status && res.status.src ) {
          profileImgUrl.value = res.status.src
        }
      });
    } else {
      profileImgUrl = computed(() => store.state.my.profileImgUrl)
    }
    
    return {
      // getProfileImg,
      profileImgUrl,
      setDefaultImg
    }

  }
};
</script>