import LxpLayout from "@/views/layout/lxp/LxpLayout";

const hrdcloudRoutes = [
  {
    path: '/hrdcloud',
    component: LxpLayout,
    children: [
      {
        // path variable 을 생성해야 된다. 임시 라우터 처리
        path: 'assistant-manager',
        name: "SubHrdCloud",
        component: () => import('../../views/pages/hrdcloud/HrdCloudSub'),
      }
    ],
  }
];

export const setHrdcloudRoutes = (routes) => {
  routes.push(...hrdcloudRoutes);
}