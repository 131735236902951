import LxpLayout from '@/views/layout/lxp/LxpLayout';
import navigationUtils from '@/assets/js/navigationUtils';

const myRoutes = [
  {
    path: '/my',
    component: LxpLayout,
    children: [
      {
        // path variable 을 생성해야 된다. 임시 라우터 처리
        path: 'mypage',
        name: 'MyPageMain',
        component: () => import('../../views/pages/my/MyPageMain'),
      },

      {
        path: 'talent',
        name: 'MyTalentBoard',
        component: () => import('../../views/pages/my/MyTalentBoard'),
      },
      {
        path: 'vice-talent',
        name: 'MyViceTalentBoard',
        component: () => import('../../views/pages/my/MyViceTalentBoard'),
      },

      //역량개발평가
      // {
      //   path: 'ability',
      //   name: 'MyAbility',
      //   component: () => import('../../views/pages/my/MyAbility'),
      // },

      // 등급/배지
      {
        path: 'badge',
        name: 'MyBadge',
        component: () => import('../../views/pages/my/MyBadge'),
      },
      // 구독
      {
        path: 'subscribe',
        name: 'MySubscribe',
        component: () => import('../../views/pages/my/MySubscribe'),
      },
      // 담기
      {
        path: 'keep',
        name: 'MyKeep',
        component: () => import('../../views/pages/my/MyKeep'),
      },
      {
        path: 'keep/edit/:type',
        name: 'MyKeepEdit',
        component: () => import('../../views/pages/my/MyKeepEdit'),
      },
      {
        path: 'profile',
        name: 'MyProfile',
        component: () => import('../../views/pages/my/MyProfile'),
      },
      {
        path: 'badgeBox',
        name: 'BadgeBox',
        component: () => import('../../views/pages/my/BadgeBox')
      },
      //역량개발평가
      {
        path: 'ability',
        name: 'MyAbility',
        component: () => import('@/views/pages/my/MyCapability'),
      },
      {
        path: 'ability/:view',
        name: 'MyAbilityView',
        component: () => import('@/views/pages/my/MyCapability'),
      },
    ],
  }
];

const mobileMyRoutes = [
  {
    path: '/my',
    component: () => import('../../views/layout/lxp/mobile/LxpMobileLayout'),
    children: [
      {
        // path variable 을 생성해야 된다. 임시 라우터 처리
        path: 'mypage',
        name: 'MyPageMain',
        component: () => import('../../views/pages/my/MyPageMain'),
      },
      {
        // path variable 을 생성해야 된다. 임시 라우터 처리
        path: 'mypagecert/:courseKey',
        name: 'MyPageCert',
        component: () => import('../../views/pages/my/mobile/MobileMyPageCertification'),
      },
      {
        path: 'talent',
        name: 'MyTalentBoard',
        component: () => import('../../views/pages/my/mobile/MobileMyTalentBoard'),
      },
      {
        path: 'talent/goal',
        name: 'MyTalentBoardGoal',
        component: () => import('../../views/pages/my/mobile/MobileMyTalentBoardGoal'),
      },
      {
        path: 'talent/coaching',
        name: 'MyTalentBoardCoaching',
        component: () => import('../../views/pages/my/mobile/MobileMyTalentBoardCoaching'),
      },
      {
        path: 'vice-talent',
        name: 'MyViceTalentBoard',
        component: () => import('../../views/pages/my/mobile/MobileMyViceTalentBoard'),
      },
      {
        path: ':frlngGradeSn',
        name: 'MobileMyPageGlobal',
        component: () => import('../../views/pages/my/mobile/MobileMyPageGlobal'),
      },


      //역량개발평가
      // {
      //   path: 'ability',
      //   name: 'MyAbility',
      //   component: () => import('../../views/pages/my/MyAbility'),
      // },

      // 등급/배지
      {
        path: 'badge',
        name: 'MyBadge',
        component: () => import('../../views/pages/my/MyBadge'),
      },
      // 구독
      {
        path: 'subscribe',
        name: 'MySubscribe',
        component: () => import('../../views/pages/my/MySubscribe'),
      },
      // 담기
      {
        path: 'keep',
        name: 'MyKeep',
        component: () => import('../../views/pages/my/MyKeep'),
      },
      {
        path: 'keep/edit/:type',
        name: 'MyKeepEdit',
        component: () => import('../../views/pages/my/MyKeepEdit'),
      },
      {
        path: 'profile',
        name: 'MyProfile',
        component: () => import('../../views/pages/my/mobile/MobileMyProfile'),
      },
      {
        path: 'badgeBox',
        name: 'BadgeBox',
        component: () => import('../../views/pages/my/BadgeBox')
      },
      {
        path: 'ability',
        name: 'MyAbility',
        component: () => import('@/views/pages/my/mobile/MobileMyCapability'),
      },
      {
        path: 'ability/:view',
        name: 'MyAbilityView',
        component: () => import('@/views/pages/my/mobile/MobileMyCapability'),
      },

      {
        // path variable 을 생성해야 된다. 임시 라우터 처리
        path: 'abilitySubj/:courseKey',
        name: 'AbilitySubjPopView',
        component: () => import('@/components/capability/mobile/MobileCapabilityReqSubjPopup'),

      },

    ],
  }
];


export const setMyRoutes = (routes) => {
  if (navigationUtils.any()) {
    routes.push(...mobileMyRoutes);
  } else {
    routes.push(...myRoutes);
  }
}
