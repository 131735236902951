<template>
  <div id="eTest-entry">
    <div class="eTest-wrapper">
      <!-- eTest-header -->
      <EvlHeader
          :title="evl.lrnEvlNm"
      >
        <template v-slot:count>
        </template>
      </EvlHeader>
      <!-- eTest-main -->
      <div class="eTest-main">
        <div class="main-progress"></div>
        <div class="main-body main-tests">
          <section class="entry-container">
            <header class="entry-header"><h3 class="title">{{evlText}}</h3></header>
            <div class="entry-contents">
              <dl class="content-dl">
                <dt class="title">평가명</dt>
                <dd class="description">{{ evl.lrnEvlNm }}</dd>
                <dt class="title">문제유형</dt>
                <dd class="description">{{ evl.evlTyNm }}</dd>
                <dt class="title">평가일시</dt>
                <dd class="description">{{ timestampToDateFormat(evl.evlBgngDt, 'yyyy.MM.dd hh:mm') }} ~ {{ timestampToDateFormat(evl.evlEndDt, 'yyyy.MM.dd hh:mm') }}</dd>
                <dt v-if="!isEnd" class="title">현재시간</dt>
                <dd v-if="!isEnd" class="description">{{ currentTimeText }}</dd>

                <template v-if="canTake === 1">
                  <dt class="title">대상구분</dt>
                  <dd class="description">현재 예정된 평가가 종료되었습니다.</dd>
                </template>
              </dl>
            </div>
            <div class="entry-buttons">
<!--              <a href="javascript:" class="kb-btn kb-btn-primary" @click="initMockEvl"><span class="text">모의테스트</span></a>-->
            </div>
            <div class="entry-contact">
              <dl class="contact-dl">
                <dt class="title">연수시스템</dt>
                <dd class="description">02-2073-5482, 5386, 7744, 5485</dd>
<!--                <dt class="title">지식e러닝</dt>-->
<!--                <dd class="description">02-2073-5364, 5373</dd>-->
                <dt class="title">통신사이버</dt>
                <dd class="description">02-2073-7880</dd>
              </dl>
            </div>
          </section>
        </div>
      </div>
      <!-- //eTest-main -->
      <!-- eTest-footer -->
      <footer class="eTest-footer">
        <div class="footer-column column-nav">
          <button v-if="isReady" class="kb-btn kb-btn-primary kb-btn-prepare" @click="initEvaluation"><i class="icon-prepare"></i><span class="text">평가시작</span></button>
          <button v-else class="kb-btn kb-btn-primary kb-btn-prepare" @click="clickReady"><i class="icon-prepare"></i><span class="text">평가준비</span></button>

          <button class="kb-btn kb-btn-secondary kb-btn-close" @click="closeModal"><i class="icon-close"></i><span class="text">종료</span></button>
        </div>
      </footer>
    </div>

    <EvlPledge
        :current-time-text="currentTimeText"
        v-model="pledgeToggle"
        @pledge="pledgeEvl"
    />
  </div>
</template>

<script>
import EvlHeader from '@/components/evaluation/EvlHeader';
import {isSuccess, timestampToDateFormat} from '@/assets/js/util';
import {computed, ref} from 'vue';
import {useAlert} from '@/assets/js/modules/common/alert';
import EvlPledge from '@/components/evaluation/EvlPledge';
import {ACT_INSERT_LRN_TY_EVL_RSLT} from '@/store/modules/evaluation/evaluation';
import {useStore} from 'vuex';

export default {
  name: "EvlIntro",
  components:{EvlPledge, EvlHeader},
  props: {
    evl: Object,
    isReady: Boolean,
    isEnd: Boolean,
    canTake: Number,
    currentTimeText: String
  },
  emits: ['closeEvl', 'readyEvl', 'initEvl', 'initMockEvl'],
  setup(props, {emit}){
    const store = useStore();
    const {showMessage} = useAlert();

    const pledgeToggle = ref(false);

    const evlText = computed(() => {
      if(props.canTake === 0){
        return '진행중인 평가';
      }else if(props.canTake === 1){
        return '종료된 평가';
      }
      return '평가'
    })

    const clickReady = () => {
      if(props.canTake === 0){
        pledgeToggle.value = true;
      }else{
        showMessage({
          title: '평가준비',
          text: '본 평가를 응시하실 수 없습니다.<br>응시 대상구분을 확인하시고 다시 로그인 하십시오.'
        });
      }
    }

    const pledgeEvl = () => {
      store.dispatch(`evaluation/${ACT_INSERT_LRN_TY_EVL_RSLT}`, props.evl.lrnTyEvlDistSn).then(res => {
        if(isSuccess(res)){
          emit('readyEvl');
        }else{
          showMessage('오류가 발생했습니다. 다시 시도해주세요.');
        }
      }).catch(e => {
        console.error(e);
        showMessage('오류가 발생했습니다. 다시 시도해주세요.');
      })
    }

    const initEvaluation = () => {
      if(props.canTake === 0){
        showMessage({text: '평가 응시창으로 전환됩니다.<br>평가 도중 강제로 창을 닫거나 다른 창으로 이동하면<br>평가 결과에 불이익을 받을 수 있습니다.', callback: () => emit('initEvl')});
      }else{
        showMessage({
          title: '평가시작',
          text: '평가에 참여완료하였습니다.'
        });
      }
    }

    const initMockEvl = () => {
      emit('initMockEvl');
    }


    const closeModal = () => {
      emit('closeEvl');
    }


    return {
      pledgeToggle,
      evlText,
      clickReady,
      pledgeEvl,
      initEvaluation,
      initMockEvl,
      closeModal,
      timestampToDateFormat
    }
  }
}
</script>
