<template>
  <header class="ssl-header back">
    <div class="page-component">
      <!-- header-back -->
      <div class="header-column header-back">
        <a href="javascript:" class="back-link" @click="backFunc">
          <span class="back"></span>
        </a>
      </div>

      <!-- //header-back -->
      <!-- header-title -->
      <!-- 백 버튼 헤더 가운데에 들어가는 타이틀 -->
      <div v-if="!previewVisible" class="header-column header-title">
        <h3>글쓰기 </h3>
      </div>

      <!-- //header-title -->
      <!-- header-right -->
      <div v-if="!previewVisible" class="header-column header-right">
        <!-- <span class="following">구독중<i class="icon-following"></i></span> -->
        <!-- 발행이 불가능할 경우 button에 disabled 속성 추가 -->
        <button class="publish-btn" @click="goFunc">{{ step === 1 ? '등록' : '다음' }}</button>
        <!-- <button class="publish-btn">다음</button> -->
      </div>
      <!-- //header-right -->
    </div>
  </header>
</template>

<script>

export default {
  name: 'SSLMobileWriteHeader',
  props: {
    step: Number,
    goFunc: Function,
    backFunc: Function,
    validationStep: Boolean,
    previewVisible: Boolean,

  },
}
</script>
