<template>
  <div class="popup-container" :class="{ 'is-active' : modelValue }"> <!--  @click="closeToggles(toggles)" -->
   <div class="popup"> <!--  id="popup-tuition-support-p012"-->
      <div class="popup-inner">
        <div class="popup-header"><h3 class="title">자산포트폴리오 관리자</h3></div>

        <div class="popup-content">
          <div class="popup-buttons m-3 justify-content-xl-end">
            <button class="kb-btn kb-btn-sm kb-btn-primary" @click="setSchdlSn(0);setPageDiv('reg')" v-if="isSchduleListActive">회차추가</button>
            <button class="kb-btn kb-btn-sm kb-btn-primary" @click="setPageDiv('list')" v-if="isSchduleRegActive">목록</button>
          </div>

          <ScheduleList v-if="isSchduleListActive" v-model="isSchduleListActive" @setSchdlSn="setSchdlSn" :func="setPageDiv"/>
          <ScheduleInsert v-if="isSchduleRegActive" v-model="isSchduleRegActive"  :schdlSn=schdlSn  :func="setPageDiv"/>

        </div>

      </div>
      <div class="popup-close">
       <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from 'vue';
import ScheduleList from '@/components/otherdept/wm/ScheduleList';
import ScheduleInsert from '@/components/otherdept/wm/ScheduleInsert';
export default {
  name: "ScheduleManage",
  props:{
    modelValue: Boolean,
  },
  components:{ScheduleList, ScheduleInsert},
  emits: ['update:modelValue'],
  setup(props, {emit}) {
      const schdlSn = ref(0);
      const pageDiv = ref('list');
      const isSchduleListActive = ref(true);
      const isSchduleRegActive = ref(false);

      // 회차추가/상세보기 상태변경
      const setPageDiv=(str)=>{
          pageDiv.value = str;
          if(str == 'reg'){
            isSchduleListActive.value = false;
            isSchduleRegActive.value = true;

          }else{
            isSchduleListActive.value = true;
            isSchduleRegActive.value = false;
          }
      }
      // 상세페이지 스케쥴id 셋팅
      const setSchdlSn = (sn) => {
        schdlSn.value = sn;
      }
      const closeModal = () => {
        emit('update:modelValue', false);
      };

      return{
        closeModal, pageDiv, isSchduleListActive, isSchduleRegActive, setPageDiv, schdlSn, setSchdlSn
      };
  }
}
</script>