<template>
  <main class="kb-main" id="kb-hrdcloud-my">
    <lxp-main-header :show-back="true" :show-custom-title="true" title="My 클라우드">
      <template v-slot:action>
        <!-- 초기 기획은 과거 이력을 몇개까지 보여줄거냐였습니다.
             그래서, 과거이력을 몇개 보여줄지 설정할 수 있게 콤포넌트를 만들었지만,
             2022년에는 2022,2023년 2개만 보여달라하여 2개로 제한. 2023년에는 2023년만 보여달라하여 1개로 제한해서
             2023년에는 1개만 뿌려지도록 예외처리해놓고
             2024년 부터는 최대 2개 년도가 뿌려지도록 해놓음
             그래서 2025년에 2023년꺼를 보고싶으면 수정해야함.
             모바일 별도, 같이 수정해줘야함 -->
        <template v-if="new Date().getFullYear() == 2023">
          <HistoryYearSelector :show-select-all="false" :yearCnt="1" v-model="yearStr"/>
        </template>
        <template v-else>
          <HistoryYearSelector :show-select-all="false" :yearCnt="2" v-model="yearStr"/>
        </template>
      </template>
    </lxp-main-header>
    <div class="main-content min-component">
      <div class="my-hrdcloud-container">
        <Total :year="yearStr" v-model:mlg-modal="mlgModal" :focus-func="historyEvents"/>
        <BadgeList :year="yearStr" />
<!--        <MyLearnStatus :year="yearStr" />-->
<!--        <LearnRoutine :year="yearStr"/>-->
        <FullHistory :year="yearStr" v-model="lrnObjDtlDistSn" v-model:target-ref="fullHistory" :openDtlFunc="historyEvents.openHistoryDtlModal"/>
        <KBTube :year="yearStr" :func="historyEvents.openKbTubeModal" />
      </div>
    </div>
    <HrdCloudHistoryDtlModal v-if="historyDtlModal" v-model="historyDtlModal" v-model:lrn-obj-dtl-dist-sn="lrnObjDtlDistSn" :year="yearStr" />
    <Attendance v-if="attendanceModal" v-model="attendanceModal" />
    <Mileage v-if="mlgModal" v-model="mlgModal" :year="yearStr"/>
    <KbTubeModal v-if="kbTubeModal" v-model="kbTubeModal" />
  </main>
</template>
<script>
import LxpMainHeader from '@/components/common/LxpMainHeader';
import HistoryYearSelector from '@/components/history/HistoryYearSelector';
import {ref} from 'vue';
import Total from '@/components/history/hrdcloud/Total';
import KBTube from '@/components/history/hrdcloud/KBTube';
import BadgeList from '@/components/history/hrdcloud/BadgeList';
// import MyLearnStatus from '@/components/history/hrdcloud/MyLearnStatus';
// import LearnRoutine from '@/components/history/hrdcloud/LearnRoutine';
import FullHistory from '@/components/history/hrdcloud/FullHistory';
import HrdCloudHistoryDtlModal from '@/components/history/HrdCloudHistoryDtlModal';
import Attendance from '@/components/history/hrdcloud/Attendance';
import Mileage from '@/components/history/hrdcloud/Mileage';
import KbTubeModal from '@/components/history/hrdcloud/KbTubeModal';
import localstorageService from '@/assets/js/localstorage.service';
import {HRD_ATT_TOKEN} from '@/assets/js/modules/hrd/hrd-history-common';
import {showAdded, showShareList, showSubscribed} from '@/assets/js/modules/hrd/hrd-common';

export default {
  name: 'HistoryHrdCloud',
  components: {
    KbTubeModal,
    Mileage,
    Attendance,
    HrdCloudHistoryDtlModal,
    // eslint-disable-next-line vue/no-unused-components
    FullHistory,
    // LearnRoutine,
    // MyLearnStatus,
    BadgeList, KBTube, Total, HistoryYearSelector, LxpMainHeader},
  setup() {
    const yearStr = ref(new Date().getFullYear());

    const lrnObjDtlDistSn = ref(0);

    const historyDtlModal = ref(false), attendanceModal = ref(false), mlgModal = ref(false), kbTubeModal = ref(false);

    const checkTime = () => {
      const local = localstorageService.getToken(HRD_ATT_TOKEN);
      const now = new Date(), then = new Date(Number(local)) ;
      const diff = Math.round((now.getTime() - then.getTime()) / (60*60*1000));
      if (diff > 24) {
        attendanceModal.value = true;
        localstorageService.saveToken(HRD_ATT_TOKEN, new Date().getTime());
      } else {
        attendanceModal.value = false;
      }
    };

    checkTime();

    const fullHistory = ref(null);

    const historyEvents = {
      fullHistory : () => {
        fullHistory.value.scrollIntoView({behavior: 'smooth'});
      },
      openAttendanceModal: () => {
        attendanceModal.value = true;
      },
      openMlgModal: () => {
        mlgModal.value = true;
      },
      openAddModal: () => {
        showAdded(yearStr.value);
      },
      openHistoryDtlModal: (key) => {
        lrnObjDtlDistSn.value = key;
        historyDtlModal.value = true;
      },
      openKbTubeModal: () => {
        kbTubeModal.value = true;
      },
      openSubscriptionModal: () => {
        showSubscribed(yearStr.value);
      },
      openShareModal: () =>{
        showShareList(yearStr.value);
      }
    };

    return {
      yearStr, lrnObjDtlDistSn,
      historyDtlModal, attendanceModal, mlgModal, kbTubeModal,
      historyEvents, fullHistory
    };
  },
};
</script>