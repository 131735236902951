<template>
  <template v-if="modelValue">
    <HrdCloudMobileHeader
        title="본문 요약"
        :show-back="true"
        :go-back-func="closeModal"
    >

    </HrdCloudMobileHeader>
    <main class="kb-main" id="kb-hrdcloud">
      <div class="main-content main-component">
        <article class="content-section">
          <div class="section-header text txt_multi">
            <p>Chat GPT가 자동으로 요약한 내용입니다. 자동 요약 기술의 특성상 콘텐츠의 중요한 내용이 제외되거나 잘못 요약될 수 있으니 참고용으로만 활용해주시기 바랍니다.</p>
          </div>

          <div class="text-item-container">
            <div  class="text-item" >
              <p class="item-subtitle">콘텐츠명</p>
              <h5 class="item-title">{{title}}</h5><br>
              <p class="item-subtitle">요약내용</p><br>
              <div v-if="item.cttSummScript !== null" class="item-row" style="word-break: keep-all;">
                <span >{{item.cttSummScript}}</span>
              </div>
              <div v-else class="item-row">
                <span >요약 작업이 미처리 되었습니다. 관리자에게 문의해주세요(☎5473)</span>
              </div>
            </div>
          </div>

        </article>
      </div>
    </main>
  </template>
</template>

<script>
import HrdCloudMobileHeader from '@/components/hrdcloud/mobile/HrdCloudMobileHeader';
import {lightMode, toggleConcentrationMode} from '@/assets/js/modules/hrd/hrd-common';
import { toRefs, watch} from 'vue';

export default {
  name: 'HrdCloudMobileSttModal',
  components: { HrdCloudMobileHeader},
  props: {
    modelValue: Boolean,
    title: String,
    item: Object,
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const {modelValue : show} = toRefs(props);



    const closeModal = () => {
      emit('update:modelValue', false);
    };

    watch(() => show.value,() => {
      if (show.value) {
        lightMode();
      } else {
        toggleConcentrationMode();
      }
    });

    return {
      closeModal,
    }
  }
};
</script>