<template>
  <video class="vjs-matrix video-js vjs-default-skin vjs-big-play-centered"
         ref="videoDom"
         oncontextmenu="return false;"

         @playing="onPlayerPlaying($event)"
         @pause="onPlayerPause($event)"

         @mouseover="playVideo()"
         @mouseleave="pauseVideo()"
         @focusout="pauseVideo()"
  ></video>
</template>

<script>
import videojs from 'video.js';
import {onBeforeUnmount, onMounted, ref} from 'vue';

export default {
  name: 'ThumbnailVideo',
  props: {
    src: {
      type: String,
      default: '',
      // "https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8"
    },
  },
  setup(props) {
    // videojs.addLanguage('ko',require('@/assets/video/ko.json'));
    const videoDom = ref(null);
    const video_instance = ref(null);

    const isPlaying = ref(false);

    const getType = (src) => {
      if (src && src.length > 0) {
        if (src.indexOf('.mp3') > -1) {
          return 'audio/mp3';
        } else if (src.indexOf('.m3u8') > -1) {
          return 'application/x-mpegURL';
        }
      }
      return 'video/mp4';
    };

    const videojsOptions = ref({
      playsinline: true,
      preload: 'metadata',
      fluid: true,
      muted: true,
      autoplay: false,
      crossOrigin: 'anonymous',
      controls: false,
      height: 376,
      width: 224,
      sources: [
        {
          src: props.src,
          type: getType(props.src),
        }],
    });

    const playVideo = () => {
      if (video_instance.value) {
        if (video_instance.value.paused() && !isPlaying.value) {
          let play = video_instance.value.play();
          // console.log('play  : ',play);
          play.then(() => {
            // console.log('play res : ',res);
          }).catch(() => {
            // console.log('play e : ',e);
            // DOMException: The play() request was interrupted by a new load request.
          });
        }
      }
    };

    const pauseVideo = () => {
      if (video_instance.value) {
        if (!video_instance.value.paused() && isPlaying.value) {
          video_instance.value.pause();
        }
      }
    };

    const onPlayerPlaying = () => {
      isPlaying.value = true;
    };

    const onPlayerPause = () => {
      isPlaying.value = false;
    };

    onMounted(() => {
      // console.log('videojsOptions : ',videojsOptions.value);
      video_instance.value = videojs(videoDom.value, videojsOptions.value, () => {
        // playPromise.value = video_instance.value.play();
      });
    });

    onBeforeUnmount(() => {
      // console.log('video disposed onbeforeunmount');
      if (video_instance.value) video_instance.value.dispose();
    });

    return {
      videoDom,
      video_instance,
      playVideo,
      pauseVideo,
      onPlayerPlaying,
      onPlayerPause,
    };
  },
};
</script>