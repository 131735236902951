import {useStore} from 'vuex';
import {useAlert} from '@/assets/js/modules/common/alert';
import {computed, onBeforeMount, onUnmounted, ref} from 'vue';
import {
  clearTargetInterval,
  getResult,
  isSuccess,
  lengthCheck,
  timestampToDateFormat,
} from '@/assets/js/util';
import {
  ACT_GET_LRN_TY_EVL_QSTN_RSLT_LIST,
  ACT_INIT_LRN_TY_EVL_DIST,
  ACT_GET_EVL_SYNC_DT
} from '@/store/modules/evaluation/evaluation';
import {useRoute, useRouter} from 'vue-router';

export const learnEvlSetup = (props, {emit}) => {
  const store = useStore();
  const route = useRoute();
  const router = useRouter();

  const {showMessage} = useAlert();
  // 평가 준비 여부!
  const isMobile = computed(() => store.state.auth.isMobile);
  const isMock = ref(false);
  const isReady = ref(false);
  const isStart = ref(false);
  const isEnd = ref(false);
  const score = ref(0);
  const scoreToggle = ref(false);

  // const currentTime = ref(new Date());
  const currentTime = ref(null);
  const currentTimeText = computed(() => timestampToDateFormat(currentTime.value, 'yyyy.MM.dd hh:mm'));
  const countInterval = ref(null);

  const checkCanTake = () => {
    if(props.evl && props.evl.lrnTyEvlDistSn > 0){
      // 시험을 수행하지 않는 경우
      if(props.evl.evlExamEndDt !== null){
        return 1;
      }else{
        if(props.evl.evlExamBgngDt !== null){
          // if(props.evl.evlExamBgngDt + (1000 * 60 * props.evl.evlReqHr) >= currentTime.value.getTime()){
          if(props.evl.evlExamBgngDt + (1000 * 60 * props.evl.evlReqHr) >= currentTime.value){
            return 0;
          }
          // 시험 종료
          else{
            return 1;
          }
        }
        // 시험 시작하지 않음
        else{
          // 시험기간만 확인
          // if(currentTime.value.getTime() >= props.evl.evlBgngDt && currentTime.value.getTime() < props.evl.evlEndDt){
          if(currentTime.value >= props.evl.evlBgngDt && currentTime.value < props.evl.evlEndDt){
            return 0;
          }
          // 시험 종료
          // else if(currentTime.value.getTime() > props.evl.evlEndDt){
          else if(currentTime.value > props.evl.evlEndDt){
            return 1;
          }
        }
      }
    }
    return 2;
  }
  const canTake = computed(checkCanTake);

  const initDt = computed(() => {
    if(isMock.value) return new Date().getTime();
    return props.evl.evlExamBgngDt;
  });

  const getEvaluationResultList = () => {
    store.dispatch(`evaluation/${ACT_GET_LRN_TY_EVL_QSTN_RSLT_LIST}`, props.evl.lrnTyEvlDistSn).then(res => {
      isReady.value = lengthCheck(res);
    }).catch(e => {
      console.error(e);
    });
  }

  const readyEvaluation = () => {
    isReady.value = true;
  }

  const initEvaluation = () => {
    store.dispatch(`evaluation/${ACT_INIT_LRN_TY_EVL_DIST}`, props.evl.lrnTyEvlDistSn).then(res => {
      if(isSuccess(res) && res.initDt){
        // 시험 시작 시간 갱신
        emit('renewInit', res.initDt);
        isStart.value = true;
      }else{
        showMessage(getResult(res).message, () => {
          closeModal();
        });
      }

    }).catch(e => {
      console.error(e);
      showMessage('오류가 발생했습니다. 다시 시도해주세요.');
    })
    // isStart.value = true;
  }

  const initMockEvaluation = () => {
    isMock.value = true;
    isStart.value = true;
  }

  const endEvaluation = () => {
    if(isMock.value){
      isStart.value = false;
      isMock.value = false;
      showMessage('모의 시험이 종료되었습니다. 고생하셨습니다.');
    }else{
      isEnd.value = true;
      isStart.value = false;
      clearTargetInterval(countInterval.value);
      if(props.evl.evlScoreRlsYn === 'Y'){
        // store.dispatch(`evaluation/${ACT_GET_LRN_TY_EVL_RSLT_SCORE}`,props.evl.lrnTyEvlDistSn).then(res => {
        //   if(res && res.score){
        //     score.value = res.score;
        //     scoreToggle.value = true;
        //   }
        // })
        scoreToggle.value = true;
      }else{
        showMessage('시험이 종료되었습니다. 고생하셨습니다.');
      }
      // emit('renewEnd');
    }
  }

  const clickBack = () => {
    const currentQuery = {...route.query};
    delete currentQuery.modal;
    if (props.toggleEnd && props.toggleEnd instanceof Function) {
      props.toggleEnd();
    }
    router.push({query: currentQuery});
  }

  const closeModal = () => {
    if (isMobile.value) {
      emit('renewEnd');
      clickBack();
    } else {
      scoreToggle.value = false
      emit('renewEnd');
    }
  }

  const stopEvl = () => {
    isStart.value = false;
    closeModal();
  }

  // 시험전의 경우
  if(!props.isAnswer){
    countInterval.value = setInterval(() => {
      // currentTime.value = new Date();
      currentTime.value += 1000;
    }, 1000);
    getEvaluationResultList();
  }else {
    getEvaluationResultList();
  }

  const getInitDt =  async () => {
    await store.dispatch(`evaluation/${ACT_GET_EVL_SYNC_DT}`, {
    }).then(res => {
      if(isSuccess(res)){
        // console.log('LearnEvaluation On',timestampToDateFormat(res.srvSyncDt, 'yyyy.MM.dd hh:mm:ss'));
        currentTime.value = res.srvSyncDt;
      }
    });
  }

  onBeforeMount(() => {
    getInitDt();
  })

  onUnmounted(() => {
    clearTargetInterval(countInterval.value);
  });

  return {
    isMock,
    isReady,
    isStart,
    isEnd,
    canTake,
    currentTime,
    currentTimeText,
    scoreToggle,
    score,
    initDt,
    initMockEvaluation,
    readyEvaluation,
    initEvaluation,
    endEvaluation,
    closeModal,
    stopEvl,
  }
}
