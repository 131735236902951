<template>
  <div class="popup-container" :class="{'is-active': show}">
    <div class="popup popup-get-badge " style="height: 350px !important; ">
      <button class="kb-btn-popup-close" @click="closeModal">
        <i class="icon-close"></i>
      </button>
      <!-- get-badge-container -->
      <swiper
          class="get-badge-container"
          :modules="modules"
          :speed="750"
          :pagination="{
              el: '.badge-pagination',
          }"
      >
        <swiper-slide v-for="(badge, idx) in myBadges" class="item" :key="idx">
          <div class="badge animate__animated animate__heartBeat" style="margin-top: 64px;background-color: #ffffff">
            <SSLBadge
                :is-active="true"
                :badge-ty-cd-dcd="badge.badgeTyCdDcd"
            />
          </div>
          <p class="text text-black" style="margin-top: 64px;">축하해요! <br/>"{{ badge.badgeName }} 배지"를 획득했어요</p>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>
<script>

import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import SSLBadge from '@/components/ssl/badge/SSLBadge';
import {sslBadgeModalSetup} from '@/assets/js/modules/ssl/setup/ssl-layout-setup';

export default {
  name: 'SSLMobileBadgeModal',
  components: {
    SSLBadge,
    Swiper,
    SwiperSlide
  },
  setup: sslBadgeModalSetup
}
</script>
