<template>
  <div :class="[isMobile ? 'kb-form-field' : 'kb-form-row']">
    <div class="kb-form-column kb-form-column-title">
      <label class="kb-form-label">
        <span class="kb-form-label-text">{{ title }}</span>
      </label>
    </div>
    <div class="kb-form-column" :class="{ 'column-whole' : isMobile }">
      <p class="kb-form-text">{{ isChecked ? 'ON' : 'OFF' }}</p>
    </div>
    <template v-if="isMobile">
      <div class="kb-form-switch-controller">
        <label class="kb-form-switch-label">
          <input v-model="model" type="checkbox" class="kb-form-switch-input" :checked="isChecked" @change.stop="handleSwitch">
          <span class="kb-form-switch-slider"></span>
        </label>
      </div>
    </template>
    <template v-else>
      <div class="kb-form-column column-last">
        <div class="kb-form-switch kb-form-switch-incolumn">
          <label class="kb-form-switch-label">
            <input v-model="model" type="checkbox" class="kb-form-switch-input" :checked="isChecked" @change.stop="handleSwitch">
            <span class="kb-form-switch-slider"></span>
          </label>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {ref} from 'vue';
import navigationUtils from "@/assets/js/navigationUtils";

export default {
  name: "SwitchCheckbox",
  props: {
    isChecked: {
      type: Boolean,
      default: false
    },
    title: String
  },
  emits: ['saved'],
  setup(props, {emit}) {
    const model = ref(props.modelValue);
    const switchDebounce = ref(0);

    const handleSwitch = () => {
      if(switchDebounce.value){
        clearTimeout(switchDebounce.value);
        switchDebounce.value = 0;
      }

      switchDebounce.value = setTimeout(() => {
        emit('saved', (model.value ? 'Y' : 'N'));
      },500);
    };

    return {
      model,
      handleSwitch,
      isMobile: navigationUtils.any()
    }
  }
}
</script>