import LxpLayout from "@/views/layout/lxp/LxpLayout";
import LxpMobileLayout from "@/views/layout/lxp/mobile/LxpMobileLayout";
import navigationUtils from "@/assets/js/navigationUtils";
import LxpMobileSSLView from "@/views/pages/mobile/LxpMobileSSLView";


const isMobile = navigationUtils.any();

const lxpRoutes = [
    {
        path: '/main',
        component: isMobile ? LxpMobileLayout: LxpLayout,
        children: [
            {
                path: "",
                name: "Main",
                component: () => isMobile ? import('../../views/pages/mobile/MobileMain') : import('../../views/pages/Main'),
            },
            {
                path: "/mockup",
                name: "MobileMockup",
                component: () => import('../../views/pages/mobile/MobileMockup'),
            },
            {
                path: "ssl/:distCrseCd/view/:socLrnPostSn",
                name: "LxpMobileSSLView",
                component: LxpMobileSSLView
            },
        ],
    }
];

export const setLxpRoutes = (routes) => {
    routes.push(...lxpRoutes);
}