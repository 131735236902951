import LxpLayout from "@/views/layout/lxp/LxpLayout";
import navigationUtils from "@/assets/js/navigationUtils";
import LxpMobileLayout from '@/views/layout/lxp/mobile/LxpMobileLayout';
const isMobile = navigationUtils.any();

const bpccRoutes = [
    {
        path: '/bpcc',
        component: isMobile ? LxpMobileLayout : LxpLayout,
        children: [
            {
                // path variable 을 생성해야 된다. 임시 라우터 처리
                path: 'main',
                name: "main",
                component: () =>isMobile ? import('../../views/pages/bpcc/mobile/BpccMain'): import('../../views/pages/bpcc/BpccMain')
            }
        ],
    }
];

export const setBpccRoutes = (routes) => {
    routes.push(...bpccRoutes);
}