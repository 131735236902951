<template>
<!--  <LxpMobileHeader v-if="isMobile" :use-route-name="true"/>-->
  <main v-if="isReady" class="kb-main" id="kb-prep-group">
    <lxp-main-header v-if="!isMobile" :show-breadcrumb="true" :show-title="true" :header-class="'main-header-visual'">
     <template v-slot:action>
       <div class="header-bg" :style="{'background-image': `url(${bgImgUrl})`}"></div>
     </template>
      <template v-slot:inner>
        <div class="header-column">
          <div class="download">
            <file-down-link :file-key="guideFileKey" add-text-style="color: #FFFFFFFF !important;" icon-class="icon-arrow20-right-w" />
          </div>
        </div>
      </template>
    </lxp-main-header>
    <div v-else class="main-header main-header-visual">
      <div class="header-bg" :style="{'background-image': `url(${bgImgUrl})`}"></div>
    </div>
    <!-- main-content -->
    <div class="main-content min-component">
      <section class="content-section section-chart section-divider">
        <!-- learningGroup-container -->
        <div class="learningGroup-container">
          <template v-if="paging.totalCount > 0">
            <header class="section-header">
              <h4 class="title">내 그룹</h4>
            </header>
            <GroupCard :items="items" />
          </template>
          <div v-else class="contents-empty">
            <p class="text">현재 참여중인 그룹이 없습니다.</p>
            <router-link :to="{name: 'GroupApplication'}" class="kb-btn kb-btn-primary"><span class="text">신청하기</span></router-link>
          </div>
        </div>
        <!-- //learningGroup-container -->
      </section>
    </div>
  </main>
</template>

<script>
import LxpMainHeader from "@/components/common/LxpMainHeader";
import GroupCard from "@/components/prep/group/GroupCard";
import FileDownLink from "@/components/common/FileDownLink";
import {groupLearningSetup} from "@/assets/js/modules/groupLearing/group-learning-setup";

export default {
  name: 'GroupLearning',
  components: {GroupCard, LxpMainHeader, FileDownLink,},
  setup: groupLearningSetup
};
</script>
<style>
.lxp-layout .kb-main .main-header-visual .header-top {z-index:999 !important;}
</style>
