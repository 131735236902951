<template>
  <div id="eTest-test">
  <div class="eTest-wrapper">
    <!-- eTest-header -->
      <EvlHeader
          :title="evl.lrnEvlNm"
      >
        <template v-slot:count>
          <div class="util-status">
            <span class="text">{{ currentTimeText }} {{ canTake === 0 ? '평가중' : '' }}</span>
            <span class="text">{{ remainTime }}  남음</span>
          </div>
        </template>
      </EvlHeader>

      <!-- eTest-main -->
      <div v-if="isInit" class="eTest-main" :class="`kb-font-size${fontSize}`">
        <div class="main-progress"></div>
        <div class="main-body main-tests">
          <div class="test-container">
            <div class="test-status">
              <span class="status-current">문제 {{ currentIndex + 1 }}</span>
              <span class="status-total">{{ questions.length }}</span>
            </div>
              <div class="test-questions">
<!--              <h3 class="questions-title">{{targetQuestion.qstnNm}}</h3>-->
              <h3 v-html="targetQuestion.qstnNm"></h3>
            </div>

            <div v-if="['2019003', '2019002', '2019001', '2019005'].includes(targetQuestion.qstnTyCdDcd)" class="test-answer answer-choice kb-form-check-group kb-form-check-block">
              <div v-for="(option, index) in targetQuestion.options" class="kb-form-check" :key="index" style="flex-wrap: nowrap;">
                <div>
                  <label :for="getSelectId(index)" class="kb-form-check-label" :class="{'kb-eTest-checked-label': answers[currentIndex].model.includes(option.optNo) }">{{ index + 1 }}. {{ option.optNm }}</label>
                </div>
                <div>&nbsp;&nbsp;</div>
                <div>
                  <input v-if="targetQuestion.qstnTyCdDcd === '2019003'"
                         v-model="answers[currentIndex].model"
                         type="checkbox" class="kb-form-check-input" :name="getSelectName(index)" :id="getSelectId(index)"
                         :value="option.optNo"
                         @change="saveAnswer"
                  >
                  <input v-else
                         v-model="answers[currentIndex].model"
                         type="radio" class="kb-form-check-input" :name="getSelectName(index)" :id="getSelectId(index)"
                         :value="option.optNo"
                         @change="saveAnswer"
                  >
                </div>
              </div>
            </div>
            <div v-else class="test-answer answer-short">
              <input v-model="answers[currentIndex].model" type="text" placeholder="답을 직접 입력하세요." class="kb-form-control-answer" @change="saveAnswer">
            </div>

          </div>
        </div>

<!--        <div class="toast-popup ">-->
        <div class="toast-popup" :class="{'is-active': showRemain}">
          <div class="popup-wrapper">
            <p class="text"><i class="text-icon">📣</i> 평가종료까지 <em>{{ remainTime }}</em> 남았습니다!</p>
          </div>
        </div>


      </div>
      <!-- //eTest-main -->
      <!-- eTest-aside -->
      <EvlAside
          v-model="currentIndex"
          :answers="answers"
          @submitEvl="submitAnswers"
          @closeEvl="closeAnswers"
      />
      <!-- //eTest-aside -->
      <!-- eTest-footer -->
      <footer class="eTest-footer">
        <div class="footer-column column-font">
          <span class="title">글자크기</span>
          <button class="kb-btn-font kb-btn-font-minus" @click="resizeFont(-1)"><i class="icon-minus"></i></button>
          <button class="kb-btn-font kb-btn-font-plus" @click="resizeFont(1)"><i class="icon-plus"></i></button>
        </div>
        <div class="footer-column column-nav">
          <button class="kb-btn kb-btn-check" :class="{'kb-btn-secondary' : targetAnswerChecked, 'kb-btn-primary': !targetAnswerChecked}" @click="checkQuestion">
            <i v-if="!targetAnswerChecked" class="icon-check"></i>
            <span class="text">{{ targetAnswerChecked ? '해제' : '체크' }}</span></button>
          <button class="kb-btn kb-btn-secondary kb-btn-nav kb-btn-kb-nav-prev" @click="prevQuestion"><i class="icon-prev"></i><span class="text">이전</span></button>
          <button class="kb-btn kb-btn-secondary kb-btn-nav kb-btn-kb-nav-next" @click="nextQuestion"><i class="icon-next"></i><span class="text">다음</span></button>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import EvlAside from '@/components/evaluation/EvlAside';
import EvlHeader from '@/components/evaluation/EvlHeader';
import {evlMainSetup} from '@/assets/js/modules/evaluation/evl-setup';

export default {
  name: "EvlMain",
  components:{EvlHeader, EvlAside},
  props: {
    evl: Object,
    isEnd: Boolean,
    isMock: Boolean,
    initDt: Number,
    canTake: Number,
    // currentTime: Object,
    currentTime: Number,
    currentTimeText: String,
    stopEvl: Function,
  },
  emits: ['endEvl'],
  setup: evlMainSetup
}
</script>

<style scoped>
  h3, b {font-weight: unset !important}
</style>