<template>
  <Transition name="custom-classes"
              :enter-active-class="enter_action_ClassName"
              :leave-active-class="leave_action_ClassName" appear>
  <div v-if="isLoading"  :class="{'lxp-mobile' : isMobile }" class="kb-toast-popup is-active">
    <button class="toast-popup-close" @click="closeModal">
          <i class="icon-toast-popup-close"></i>
    </button>

    <div class="toast-popup-box"  v-on="isMobile ? { click : handleGo }: {}" >
      <div class="toast-popup-body">
        <h3 class="toast-popup-title" v-html="eventTitle"></h3>

        <div class="toast-popup-content mt-2">
          <p class="toast-popup-text" :class="{'mt-2': isMobile, 'mt-3': !isMobile}" v-html="eventDescrHtml"></p>
        </div>

      </div>
      <div class="toast-popup-actions-event">
        <button v-if="!isMobile" class="toast-popup-action-item toast-popup-action-primary" @click="handleGo">
          <span class="toast-popup-action-text">{{moreBtnTitle}}</span>
        </button>

        <div v-if="!isMobile" class="eventBadgePosition">
          <HrdCloudBadge
              :is-active="eventBadge.isActive"
              :badge-ty-cd-dcd="eventBadge.code"
          />
        </div>
      </div>
    </div>
  </div>
  </Transition>
</template>

<script>
// import {hrdSubscribePopupSetup} from "@/assets/js/modules/hrd/hrd-subscribe-popup-setup";

import {useStore} from "vuex";
import {computed, ref} from "vue";
import {clearTargetTimeout,  getCheckItem, lengthCheck} from "@/assets/js/util";
import {ACT_GET_SSL_EVENTSTATUS} from '@/store/modules/history/history';
import HrdCloudBadge from '@/components/history/hrdcloud/HrdCloudBadge';
import {useRouter} from "vue-router/dist/vue-router";

export default {
  name: "SSLEventStatusPopup",
  components: {HrdCloudBadge},
  props: {
    modelValue: Boolean
  },
  emits: ['update:modelValue'],
  setup(
      // props, {emit}
  ) {
    const store = useStore();
    const router = useRouter();
    const now = new Date();

    let eventStartMon = 7; //test 6월 ,
    let eventYear = 2024; //test 6월 ,

    // let noti_202407 = 199;

    // const firstDate = new Date(now.getFullYear(), now.getMonth(), 1);
    // const lastDate = new Date(now.getFullYear(), now.getMonth()+1, 0);

    const enter_action_ClassName = computed(()=> {return isMobile.value ? "animate__animated animate__fadeInDown" : "animate__animated animate__fadeInRight"});
    const leave_action_ClassName = computed(()=> {return isMobile.value ? "animate__animated animate__fadeOutUp" : "animate__animated animate__fadeOutRight"});


    const eventTitle = ref(`7월 북러닝 이벤트 달성 현황<br/>`);
    // const show = ref(false);
    const isMobile = computed(() => store.state.auth.isMobile);
    const goDebounce = ref(0);
    const debounce = ref(0);
    const isLoading = ref(false);

    const closeModal = () => {
      isLoading.value = false;
      // emit('update:modelValue', false)
    };

    const eventBadge = ref({code:"0", isActive:false});
    const moreBtnTitle = ref("");

    const eventDescrHtml = ref("");

    const handleGo = () => {
      goDebounce.value = setTimeout(() => {
         clearTargetTimeout(goDebounce);
          // if(eventBadge.value.isActive){
          //   router.push('/my/badgeBox');
          // }else{
          //   router.push('/help/notice/'+noti_202407);

            router.push('/ssl/book/115618/main');
          // }

          closeModal();
        // });
      },500);

    };


    const getStatus = () => {
      debounce.value = setTimeout(() => {
        if (debounce.value) clearTimeout(debounce.value);

        // let year = dateToDatePart(new Date()).year;
        // let month = dateToDatePart(new Date()).month;

        store.dispatch(`history/${ACT_GET_SSL_EVENTSTATUS}`, {
          lrnTrgtGrpMstSn : 115618
        }).then(res => {
          if (lengthCheck(res)) {
            let data = getCheckItem(res);
            eventBadge.value.code = data.badgeCdDcd;
            eventBadge.value.isActive = data.badgeFlag;

            if (eventBadge.value.isActive) {
              return ;
            }
            // eventBadge.value.isActive = true;

            // console.log(eventBadge.value);

            // console.log(month)


            // if(month==7){
              eventDescrHtml.value = !eventBadge.value.isActive ? `
                  - 독서노트 1회 작성 <span class="text-red">${data.boardCnt}/1<br></span>
                  - 7월 출석체크 20일 이상 <span class="text-red">${data.attendCnt}/20</span>`
                  : "<span style='font-size:1.2em;line-height: 130%'>달성완료! 축하드립니다.</span>";


              //23.11월
            // }else if(month==11){
            //   //9월 이벤트 공지사항 board_sn
            //   noti_202307 = 159
            //   eventDescrHtml.value = !eventBadge.value.isActive ? `
            //         직무분야 학습완료 + 난이도 평가 <span class="text-red">${data.jikmuMlg}/20</span>`
            //       : "<span style='font-size:1.2em;line-height: 130%'>달성완료! 축하합니다.</span>";
            //
            //   //23.10월
            // }else if(month==10){
            //   //9월 이벤트 공지사항 board_sn
            //   noti_202307 = 153
            //   eventDescrHtml.value = !eventBadge.value.isActive ? `
            //         지식 클라우드 <span class="text-red">${data.jisikMlg}/30</span>`
            //       : "<span style='font-size:1.2em;line-height: 130%'>달성완료!<br/>11월 학습이벤트를 기다려주세요.</span>";
            //
            // //23.09월
            // }else if(month==9){
            //   //9월 이벤트 공지사항 board_sn
            //   noti_202307 = 145
            //   eventDescrHtml.value = !eventBadge.value.isActive ? `
            //         종합정리 <span class="text-red">${data.jikmuMlg}/7</span>
            //       | 문제풀이 <span class="text-red">${data.jisikMlg}/7</span>`
            //       : "<span style='font-size:1.2em;line-height: 130%'>달성완료!<br/>10월 학습이벤트를 기다려주세요.</span>";
            //
            //   //23.08월
            // }else if(month==8){
            //   eventDescrHtml.value = !eventBadge.value.isActive ? `
            //       Hot Tube <span class="text-red">${data.tubeMlg}/31</span>`
            //       : "<span style='font-size:1.2em;line-height: 130%'>달성완료!<br/>9월 학습이벤트를 기다려주세요.</span>";
            //
            //
            //
            // }else{
            //   eventDescrHtml.value = !eventBadge.value.isActive ? `
            //       직무 <span class="text-red">${data.jikmuMlg}/10</span>
            //       | 지식 <span class="text-red">${data.jisikMlg}/10</span>
            //       | Hot Tube <span class="text-red">${data.tubeMlg}/10</span>`
            //       : "<span style='font-size:1.2em;line-height: 130%'>달성완료!<br/>8월 학습이벤트를 기다려주세요.</span>";
            //
            // }


            // moreBtnTitle.value = eventBadge.value.isActive ? "뱃지보관함" : "더 알아보기";
            moreBtnTitle.value = "북러닝 바로가기";

            isLoading.value = true;
          }else{
            isLoading.value = false;
          }
        }).catch(() => {
          isLoading.value = false;
        });
      },500);
    };




    if(now.getFullYear()==eventYear&&now.getMonth()>=eventStartMon-1){
      // show.value = true;
      setTimeout(()=>{getStatus();}, 200);

    }

    // getStatus();

    if(isMobile.value){
      setTimeout(()=>{closeModal();}, 11000);
    }

    return {
      isMobile,
      closeModal,
      eventTitle,
      isLoading,
      handleGo,
      eventBadge,
      eventDescrHtml,
      moreBtnTitle,
      enter_action_ClassName,
      leave_action_ClassName
  }
  }
}
</script>
<style scoped>
  .icon {
    width: 104px;
  }
  .eventBadgePosition {
    position: absolute;
    bottom:10px;right:10px;
  }

  .lxp-mobile.kb-toast-popup {visibility:hidden; position:fixed; width:100%; top:20px; display:flex; justify-content: center; z-index:9999; transform:translateY(40px);opacity:0; transition-property:transform,visibility, opacity;transition-duration:.4s;}
  .lxp-mobile.kb-toast-popup.is-active {visibility:visible;transform:translateY(0);opacity:1;}

  .lxp-mobile .toast-popup-box {align-items:center; width:96%;border-radius:24px; background-color:#f7f4f0;box-shadow: 0px 0.5em 0.5em rgba(0, 0, 0, 0.2);}
  .lxp-mobile .toast-popup-body {position:relative;padding:16px 30px 12px 24px}
  .lxp-mobile .toast-popup-close {position:absolute;top:24px;right:24px; z-index:20000; display: block;}
  .lxp-mobile .icon-toast-popup-close {width:24px;height:24px;background-image:url(../../assets/lxp/images/common/ic_toast_popup_close.svg);z-index:20000; display: block;}
  .lxp-mobile .toast-popup-title {font:normal 20px/28px var(--kb-font-KBFGDisplay);}
  .lxp-mobile .toast-popup-text {font:normal 16px/20px var(--kb-font-KBFGText);color:#979797}
  .lxp-mobile .toast-popup-actions {display:flex;justify-content:flex-start; padding:0 16px 16px 16px}
  .lxp-mobile .toast-popup-action-item {min-width:76px;height:43px;display:flex;align-items:center;justify-content:center;padding:0 12px;border-radius:25rem; }
  .lxp-mobile .toast-popup-action-item ~ .toast-popup-action-item{margin-left:8px}
  .lxp-mobile .toast-popup-action-text {font:normal 14px/16px var(--kb-font-KBFGDisplayB);color:var(--kb-black);display:block; }
  .lxp-mobile .toast-popup-action-text ~ .toast-popup-action-text {margin-left:8px;}
  .lxp-mobile .toast-popup-action-primary {background-color:var(--kb-primary)}
  .lxp-mobile .toast-popup-action-text {}

</style>