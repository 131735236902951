<template>
  <section class="content-section">
    <header class="section-header">
      <div class="title-list">
        <h4 class="title is-active">학원비 신청이력</h4>
      </div>
      <div class="sub-desc">
        <p class="total">총 {{ paging.totalCount }}건</p>
        <p class="cc">신청현황만 표시됩니다.</p>
      </div>
    </header>
    <div class="section-content">
      <template v-if="!isLoading && (academies && academies.length > 0)">
        <div v-for="(acad,idx) in academies" class="content-item history-list" :key="idx">
          <div class="content-item-header">
            <h5 class="item-title">{{ acad.lrnSprtNm }}</h5>
            <h5 v-if="acad.stt === '81'" class="item-state">승인요청중</h5>
            <h5 v-else-if="acad.stt === '00'" class="item-state">지급완료</h5>
            <h5 v-else-if="acad.stt === '78'" class="item-state">반려</h5>
          </div>
          <div class="meta-content">
            {{ acad.lrnCostDivNm }} | {{ acad.lrnCostFldNm }} | 학습기간 {{ acad.lrnBgngDd }}~{{ acad.lrnEndDd }} | {{ acad.costAcdmFeeSn }} | 지원금 {{ acad.sprtAmt > 0 ? numberComma(acad.sprtAmt) : '-' }}
            <span v-if="acad.rtnYn=='Y' && acad.addAmtYn=='Y'">(환입 및 추가지원)</span>
            <span v-else-if="acad.rtnYn=='Y' && acad.addAmtYn!='Y'">(환입)</span>
            <span v-else-if="acad.rtnYn!='Y' && acad.addAmtYn=='Y'">(추가지원)</span>
          </div>
          <div class="kb-btn-actions">
            <template v-if="timestampToDateFormat(acad.aplyDt,'yyyyMM') >= '202309'">
            <button class="kb-btn-content-item" @click="detailFunc('academy_202309',acad.costAcdmFeeSn)">상세</button>
            </template>
            <template v-else>
              <button class="kb-btn-content-item" @click="detailFunc('academy',acad.costAcdmFeeSn)">상세</button>
            </template>
          </div>
        </div>
        <div v-if="paging.hasMore" class="content-item">=
          <div class="kb-btn-more-container">
            <button class="kb-btn kb-btn-more" @click.stop="moreFunc(paging)">신청이력 더보기</button>
          </div>
        </div>
      </template>
      <div v-else-if="!isLoading && (academies && academies.length === 0)" class="search-container">
        <div class="result-empty">
          <img src="../../../assets/lxp/images/common/img_empty.png" alt="">
          <p class="text">신청이력이 없습니다.</p>
        </div>
      </div>
      <LoadingDiv v-if="isLoading"/>
    </div>
  </section>
</template>

<script>
import {numberComma, timestampToDateFormat} from '@/assets/js/util';
import LoadingDiv from "@/components/common/LoadingDiv";

export default {
  name: 'SupportStatusMobileAcademies',
  components: {LoadingDiv},
  props: {
    academies: Array,
    paging: Object,
    moreFunc: Function,
    isLoading: Boolean,
    detailFunc: Function,
  },
  setup() {
    return {
      timestampToDateFormat, numberComma,
    }
  }
};
</script>