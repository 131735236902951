import ApiService from '@/assets/js/api.service';

export const ACT_INSERT_BOOK_ACT_LOG = 'insertBookActLog';

const state = {};

const mutations = {};

const actions = {
  [ACT_INSERT_BOOK_ACT_LOG](context, params){
    return new Promise(resolve => {
      ApiService.post(`/v1/app/books/acts/logs`, params).then(response => resolve(response));
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
