import ApiService from "@/assets/js/api.service";

export const ACT_GET_EMP_CONSULT_REFUND_MAP = 'getEmpConsultRefundMap';
export const ACT_INSERT_EMP_CONSULT_MNG = 'insertEmpConsultMng';

const state = {};

const mutations = {};

const actions = {
    [ACT_GET_EMP_CONSULT_REFUND_MAP]() {
        return new Promise(resolve => {
            ApiService.query('/v1/app/consult/refund').then(response => resolve(response));
        });
    },
    [ACT_INSERT_EMP_CONSULT_MNG](context, params) {
        return new Promise(resolve => {
            ApiService.post('/v1/app/consult/refund', params).then(response => resolve(response));
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
}