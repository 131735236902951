<template>
  <section class="content-section">
    <header class="section-header">
      <div class="title-list">
        <h4 class="title" :class="{'is-active': isMode === 'apply'}" @click="changeMode('apply')">자격증 신청이력</h4>
        <h4 class="title" :class="{'is-active': isMode === 'renewalHistory'}" @click="changeMode('renewalHistory')">자격증 갱신비용</h4>
      </div>
      <button class="kb-btn-go" @click="goLcsnsHistory">자격증 보유현황 바로가기</button>
      <div class="sub-desc">
        <p class="total">총 {{ isMode === 'apply'? lcnsPaging.totalCount : rnewPaging.totalCount }}건</p>
      </div>
    </header>
    <template v-if="isMode==='apply'">
      <template v-if="!isLcnsLoading && (licenses && licenses.length > 0)">
        <div class="section-content">
          <div v-for="(license, idx) in licenses" class="content-item history-list" :key="idx">
            <CommonLicenseState :license="license"/>
            <div class="kb-btn-actions">
              <button class="kb-btn-content-item" @click="detailFunc('license',license.costLcsnSn)">상세</button>
            </div>
          </div>
          <div v-if="lcnsPaging.hasMore" class="content-item">
            <div class="kb-btn-more-container">
              <button class="kb-btn kb-btn-more" @click.stop="moreFunc(lcnsPaging)">신청이력 더보기</button>
            </div>
          </div>
        </div>
      </template>
      <div v-else-if="!isLcnsLoading && (licenses && licenses.length === 0)" class="search-container">
        <div class="result-empty">
          <img src="../../../assets/lxp/images/common/img_empty.png" alt="">
          <p class="text">신청이력이 없습니다.</p>
        </div>
      </div>
      <LoadingDiv v-if="isLcnsLoading"/>
    </template>
    <template v-if="isMode==='renewalHistory'">
      <template v-if="!isRnewLoading && (renews && renews.length > 0)">
        <div class="section-content">
          <div v-for="(license, idx) in renews" class="content-item history-list" :key="idx">
            <CommonLicenseState :license="license"/>
            <div class="kb-btn-actions">
              <button class="kb-btn-content-item" @click="detailFunc('renewalHistory',license.costLcsnSn)">상세</button>
            </div>
          </div>
          <div v-if="rnewPaging.hasMore" class="content-item">
            <div class="kb-btn-more-container">
              <button class="kb-btn kb-btn-more" @click.stop="moreFunc(rnewPaging)">신청이력 더보기</button>
            </div>
          </div>
        </div>
      </template>
      <div v-else-if="!isRnewLoading && (renews && renews.length === 0)" class="search-container">
        <div class="result-empty">
          <img src="../../../assets/lxp/images/common/img_empty.png" alt="">
          <p class="text">신청이력이 없습니다.</p>
        </div>
      </div>
      <LoadingDiv v-if="isRnewLoading"/>
    </template>
  </section>
</template>

<script>
import {numberComma, timestampToDateFormat} from '@/assets/js/util';
import LoadingDiv from '@/components/common/LoadingDiv';
import {ref} from "vue";
import {useRouter} from "vue-router/dist/vue-router";
import CommonLicenseState from "@/components/support/common/mobile/CommonLicenseState";

export default {
  name: 'SupportStatusMobileLicense',
  components: {CommonLicenseState, LoadingDiv},
  props: {
    licenses: Array,
    renews: Array,
    lcnsPaging: Object,
    rnewPaging: Object,
    moreFunc: Function,
    isLcnsLoading: Boolean,
    isRnewLoading: Boolean,
    detailFunc: Function,
    modeFunc: Function
  },
  setup(props) {
    const router = useRouter();

    const isMode = ref('apply');

    const changeMode = (mode) => {
      isMode.value = mode;
      console.log(isMode.value)
      props.modeFunc(isMode.value);
    }
    const renewMoreFunc = (paging) => {
      props.moreFunc(paging, 'renew');
    };

    const goLcsnsHistory = () => {
      router.push({name: 'SupportLicenseApply', query: {'view': 'history'}});
    }

    return {
      timestampToDateFormat, numberComma, renewMoreFunc,
      isMode,changeMode,goLcsnsHistory
    }
  }
};
</script>