import {useStore} from "vuex";
import {useAlert} from "@/assets/js/modules/common/alert";
import {computed, ref} from "vue";
import {ACT_DELETE_QSTN, ACT_GET_QUIZ, ACT_UPDATE_QUIZ} from "@/store/modules/quiz/quiz";
import {getItems, isSuccess, lengthCheck, timestampToDateFormat} from "@/assets/js/util";

export const quizMngViewSetup = (props, {emit}) => {

    const store = useStore();
    const {showMessage, showConfirm} = useAlert();

    const lrnTyEvlMstSn = computed(() => props.quizDtlSn);
    const lrnTyEvlQstnSn = ref(0);

    const evlMst = ref({});
    const evlQstnItems = ref([]);

    const qstnIdx = ref(0); // 현재 마지막 문제 번호
    const qstnNo = ref(0); // 문제번호

    const isQstnAddShow = ref(false);

    const getQstnList = () => {
        store.dispatch(`quiz/${ACT_GET_QUIZ}`, lrnTyEvlMstSn.value,
        ).then(res => {
            evlMst.value = res.quizItem;
            if (lengthCheck(res)) {
                let items = getItems(res).map(x =>({
                    ...x,
                    regDt: timestampToDateFormat(x.regDt,  'yyyy.MM.dd')
                }));
                qstnIdx.value = parseInt(Math.max.apply(null, items.map(x => x.qstnNo)));
                evlQstnItems.value = items.filter(x => x.stt === '00')
            } else {
                evlQstnItems.value = [];
                qstnIdx.value = 0;
            }
        }).catch(e => {
            console.error(e);
        });
    };

    getQstnList();

    const addQstn = (qstn) => {
        if(qstn === 0 && props.quizItem.distCnt > 0){
            showMessage('출제된 퀴즈는 수정할 수 없습니다.');
            return false;
        }

        if(evlQstnItems.value.length >= 10){
            showMessage('퀴즈 문항은 최대 10개까지 등록 가능합니다');
            return false;
        }

        lrnTyEvlQstnSn.value = qstn;
        if(qstn === 0 ) { // 새로운 문항추가할때
            qstnNo.value = qstnIdx.value + 1;
        } else { // 기존 문항 수정할때
            evlQstnItems.value.filter(x => { if(x.lrnTyEvlQstnSn === qstn) qstnNo.value = x.qstnNo; });
        }

        isQstnAddShow.value = true;
    }

    const deleteQstn = (quizSn, qstnSn) => {
        if(props.quizItem.distCnt > 0){
            showMessage('출제된 퀴즈는 수정 할 수 없습니다.');
            return false;
        }

        showConfirm({
            text: '해당 퀴즈 문항을 삭제 하시겠습니까?',
            callback: () => {
                lrnTyEvlQstnSn.value = qstnSn;
                store.dispatch(`quiz/${ACT_DELETE_QSTN}`, {
                    lrnTyEvlMstSn: quizSn,
                    lrnTyEvlQstnSn: qstnSn,
                }).then(res => {
                    if(isSuccess(res)){
                        getQstnList();
                        showMessage('퀴즈 문항을 삭제했습니다.');
                    }
                }).catch((err) => {
                    console.error(err);
                })
            }
        })
    }

    const saveQuiz = () => {
        if(props.quizItem.distCnt > 0){
            showMessage('출제된 퀴즈는 수정 할 수 없습니다.');
            return false;
        }

        showConfirm({
            text: '저장 하시겠습니까?',
            callback: () => {
                updateQstn();
            }
        })

    }


    const updateQstn = () => {
        store.dispatch(`quiz/${ACT_UPDATE_QUIZ}`, {
            lrnTyEvlMstSn: lrnTyEvlMstSn.value,
            params:{
                lrnEvlNm: evlMst.value.lrnEvlNm,
            }
        }).then(res => {
            if(isSuccess(res)){
                showMessage({
                    text: '퀴즈수정을 완료했습니다.',
                    callback: () => {
                        clickCancel();
                    }
                });
            }
        }).catch((err) => {
            console.error(err);
        });
    }

    const clickCancel = () => {
        props.getQuizList();
        emit('update:isQuizViewShow', false);
        emit('update:isQuizListShow', true);
    }


    return {
        evlQstnItems,
        evlMst,
        isQstnAddShow,
        lrnTyEvlMstSn,
        lrnTyEvlQstnSn,
        qstnIdx,
        qstnNo,
        getQstnList,
        saveQuiz,
        deleteQstn,
        addQstn,
        clickCancel
    }
}