// 관리자 기능 관련 메뉴
const mngRoutes = [
    {
        path: '/mng',
        component: () => import('@/views/layout/lxp/mobile/LxpMobileLayout'),
        children: [
            {
                path: 'checklist',
                name: "Checklist",
                component: () => import('@/views/pages/checklist/Checklist'),
            },
            {
                path: 'checklist/:distCrseSn',
                name: "ChecklistMain",
                component: () => import('@/views/pages/checklist/ChecklistMain'),
            },
            // {
            //     path: 'checklist/:distCrseSn',
            //     name: "ChecklistDtl",
            //     component: () => import('@/views/pages/checklist/ChecklistDtl'),
            // },
        ],
    }
]

export const setMngRoutes = (routes) => {
    routes.push(...mngRoutes);
}