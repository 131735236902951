<template>
  <main v-if="!bookHistoryModal && !memoModal && !timerModal && !showMyPortraitModal" class="main-content">
    <div class="ssl-body pb-0" id="ssl-myssl">
      <SSLMobileSlotHeader>
        <template v-slot:title>
          <SSLMobileNav />
        </template>
        <template v-slot:left>
          <div class="util util-history-back">
            <router-link :to="{name: 'SSLMain'}" class="util-actions util-actions-history-back">
              <i class="icon-history-back"></i>
            </router-link>
          </div>
        </template>
        <template v-slot:right>
          <div class="util util-more">
            <div class="dropdown" :class="{'is-active': toggles.nav}" data-offset="header-left">
              <button class="dropdown-btn" @click="toggles.nav = !toggles.nav"><i class="icon-more"></i></button>
              <div class="dropdown-target" style="width: 144px;left: -104px;">
                <div class="dropdown-box">
                  <ul class="dropdown-option-list">
                    <li class="dropdown-option-item">
                      <SSLTrial
                          trial-class="dropdown-option-link"
                          text-class="dropdown-option-text"
                      />
                    </li>
                    <li class="dropdown-option-item">
                      <SSLManual
                          manual-class="dropdown-option-link"
                          text-class="dropdown-option-text"
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </template>
      </SSLMobileSlotHeader>


      <div class="page-container">
        <div v-if="isReady" class="myssl-container">
          <SSLMobileMyTop/>

          <div class="myssl-top my-tabs">
            <SSLMyRoute/>
          </div>
          <template v-if="viewRoute === 'status'">
            <SSLMobileMyWrite :focus-func="memoTimerEvents"/>
            <!-- section-myactivity -->
            <SSLMobileMyActivity :focus-func="historyEvents"/>
            <!-- section-sbadge -->
            <SSLMobileMyBadge/>
          </template>

          <SSLMobileMyBoard
              v-else-if="['complete', 'temporary', 'save'].includes(viewRoute)"
              :lrn-trgt-grp-mst-sn="lrnTrgtGrpMstSn"
          />
          <SSLMobileMySubscribe
              v-else-if="['subscribe', 'subscribed'].includes(viewRoute)"
              :lrn-trgt-grp-mst-sn="lrnTrgtGrpMstSn"
          />
        </div>
      </div>
    </div>
  </main>
  <BookHistory v-if="bookHistoryModal" v-model="bookHistoryModal" :selectValue="selectValue"/>
  <SSLMyMemoModal v-if="memoModal" v-model="memoModal" :data="modalItem"/>
  <SSLMyTimerModal v-if="timerModal" v-model="timerModal" :data="modalItem"/>
  <MobileMyPortraitModal userProfileTyCdDcd="2161002" />
</template>

<script>
import SSLMobileMyTop from '@/components/ssl/my/mobile/SSLMobileMyTop';
import SSLMyRoute from '@/components/ssl/my/SSLMyRoute';
import SSLMobileMyWrite from '@/components/ssl/my/mobile/SSLMobileMyWrite';
import SSLMobileMyActivity from '@/components/ssl/my/mobile/SSLMobileMyActivity';
import SSLMobileMyBadge from '@/components/ssl/my/mobile/SSLMobileMyBadge';
import {sslMySetup} from '@/assets/js/modules/ssl/setup/ssl-my-setup';
import SSLMobileMyBoard from '@/views/ssl/mobile/SSLMobileMyBoard';
import SSLMobileMySubscribe from '@/components/ssl/my/mobile/SSLMobileMySubscribe';
import SSLMobileSlotHeader from '@/components/ssl/main/mobile/SSLMobileSlotHeader';
import SSLTrial from '@/components/ssl/common/SSLTrial';
import SSLManual from '@/components/ssl/common/SSLManual';
import SSLMobileNav from '@/components/ssl/mobile/SSLMobileNav';
import MobileMyPortraitModal from '@/components/mypage/mobile/MobileMyPortraitModal.vue';
import BookHistory from "@/components/history/book/BookHistory.vue";
import SSLMyMemoModal from "@/components/ssl/my/SSLMyMemoModal.vue";
import SSLMyTimerModal from "@/components/ssl/my/SSLMyTimerModal.vue";

export default {
  name: 'SSLMobileMy',
  components: {
    SSLMyTimerModal, SSLMyMemoModal,
    BookHistory,
    SSLMobileNav,
    SSLManual,
    SSLTrial,
    SSLMobileSlotHeader,
    SSLMobileMySubscribe,
    SSLMobileMyBoard,
    SSLMobileMyBadge,
    SSLMobileMyActivity,
    SSLMobileMyWrite,
    SSLMyRoute,
    SSLMobileMyTop,
    MobileMyPortraitModal
  },
  setup: sslMySetup
}
</script>
