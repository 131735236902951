<template>
  <template v-if="$route.name === 'GroupApplication'">
    <button v-if="item.pwdUseYn === 'Y'" class="kb-btn kb-btn-primary" @click="clickGroupDetail(item.boardSn)" :disabled="item.lrnTrgtCnt === item.peoplCnt">
      <span class="text">{{ item.lrnTrgtCnt === item.peoplCnt ? '참여마감' : '상세보기' }}</span>
    </button>
    <button v-else class="kb-btn kb-btn-primary" @click="goGroupLrn(item.distCrseSn)" :disabled="item.lrnTrgtCnt === item.peoplCnt">
      <span class="text">{{ item.lrnTrgtCnt === item.peoplCnt ? '참여마감' : '상세보기' }}</span>
    </button>
  </template>
  <template v-else-if="$route.name === 'GroupView'">
    <router-link :to="{name: 'GroupStudyPlan', params: {boardSn: item.boardSn}}" class="kb-btn kb-btn-primary">
      <span class="text">학습계획</span>
    </router-link>
  </template>
  <template v-else>
    <router-link :to="{name: 'GroupLearningDetailView', params: {distCrseSn: item.distCrseSn}}" class="kb-btn kb-btn-primary">
      <span class="text">상세보기</span>
    </router-link>
  </template>
</template>

<script>

export default {
  name: "GroupDetailButton",
  props: {
    item: Object,
    clickGroupDetail: Function,
    goGroupLrn: Function
  }
}
</script>