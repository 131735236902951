import ApiService from '@/assets/js/api.service';

// ACTION
export const ACT_GET_HRDCLOUD_HISTORY_STATISTICS = 'actGetHrdCloudHistoryStatistics';
export const ACT_GET_BOOK_HISTORY_STATISTICS = 'actGetBookHistoryStatistics';
export const ACT_GET_HRDCLOUD_HISTORY_STATISTICS_ACTITEMS = 'actGetHrdCloudHistoryStatisticsActitems';
export const ACT_GET_HRDCLOUD_HISTORY_STATISTICS_LRNSEC = 'actGetHrdCloudHistoryStatisticsLrnsec';
export const ACT_GET_HRDCLOUD_HISTORY_STATISTICS_LRNCMPTN = 'actGetHrdCloudHistoryStatisticsLrncmptn';
export const ACT_GET_HRDCLOUD_HISTORY_STATISTICS_DAYCNT = 'actGetHrdCloudHistoryStatisticsDaycnt';
export const ACT_GET_HRDCLOUD_HISTORY_STATISTICS_TOTALMLG = 'actGetHrdCloudHistoryStatisticsTotalmlg';
export const ACT_GET_HRDCLOUD_HISTORY_JOB_CONTENTS_LEARN_LIST = 'actGetHrdcloudHistoryJobContentsLearnList';
export const ACT_GET_HRDCLOUD_HISTORY_LEARN_ROUTINE_LIST_BY_TIME = 'actGetHrdcloudHistoryLearnRoutineListByTime';
export const ACT_GET_HRDCLOUD_HISTORY_LEARN_ROUTINE_LIST_BY_WEEKDAYS = 'actGetHrdcloudHistoryLearnRoutineListByWeekdays';
export const ACT_GET_HRDCLOUD_HISTORY_ALL_LEARN_LIST = 'actGetHrdcloudHistoryAllLearnList';
export const ACT_GET_HRDCLOUD_HISTORY_ATTENDANCE_LIST = 'actGetHrdcloudHistoryAttendanceList';
export const ACT_GET_HRDCLOUD_HISTORY_MILEAGE_LIST = 'actGetHrdcloudHistoryMileageList';
export const ACT_GET_BOOK_HISTORY_LIST = 'actGetBookHistoryList';
export const ACT_GET_HRDCLOUD_HISTORY_MILEAGE_EVENTSTATUS = 'actGetHrdcloudHistoryMileageEventStatus';
export const ACT_GET_SSL_EVENTSTATUS = 'actGetSSLEventStatus';

export const ACT_GET_HRDCLOUD_HISTORY_DETAIL = 'actGetHrdcloudHistoryDetail';
export const ACT_GET_HRDCLOUD_HISTORY_PROGRESS_LOG_LIST = 'actGetHrdcloudHistoryProgressLogList';
export const ACT_GET_HRDCLOUD_HISTORY_ADD_LIST = 'actGetHrdcloudHistoryKeepList';

export const ACT_GET_YEAR_SUMMARY_LIST = 'actGetYearSummaryList';
export const ACT_GET_YEAR_AUTH_LIST = 'actGetYearAuthList';
export const ACT_GET_YEAR_CERTIFICATION_LIST = 'actGetCertificationList';
export const ACT_GET_FA005AS_LIST = 'actGetFa005aList';
// 자기주도학습 이력 > 지식e러닝
export const ACT_GET_KNOWLEDGE_SUMMARY_LIST = 'actGetKnowledgeSummaryList';
export const ACT_GET_KNOWLEDGE_LIST = 'actGetKnowledgeList';
// 자기주도학습 이력 > 그룹러닝
export const ACT_GET_STUDY_GROUP_SUMMARY_LIST = 'actGetStudyGroupSummaryList';
export const ACT_GET_STUDY_GROUP_LIST = 'actGetStudyGroupList';
// 자기주도학습 이력 > 클라우드 Easy
export const ACT_GET_CLOUD_EASY_SUMMARY_LIST = 'actGetCloudEasySummaryList';
export const ACT_GET_CLOUD_EASY_LIST = 'actGetCloudEasyList';
// 자격증 이력
export const ACT_GET_CERTIFICATE_SUMMARY_LIST = 'actGetCertificateSummaryList';
export const ACT_GET_CERTIFICATE_LIST = 'actGetCertificateList';

export const ACT_GET_HRDCLOUD_HISTORY_SHARE_LIST = 'actGetHrdcloudHistoryShareList';

const state = {
};

const mutations = {
};

const actions = {
    [ACT_GET_HRDCLOUD_HISTORY_STATISTICS](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/hrdcloud/stat', params).then(response => resolve(response))
        });
    },
    [ACT_GET_HRDCLOUD_HISTORY_STATISTICS_ACTITEMS](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/hrdcloud/stat/actitems', params).then(response => resolve(response))
        });
    },
    [ACT_GET_HRDCLOUD_HISTORY_STATISTICS_LRNSEC](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/hrdcloud/stat/lrnsec', params).then(response => resolve(response))
        });
    },
    [ACT_GET_HRDCLOUD_HISTORY_STATISTICS_LRNCMPTN](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/hrdcloud/stat/lrncmptn', params).then(response => resolve(response))
        });
    },
    [ACT_GET_HRDCLOUD_HISTORY_STATISTICS_DAYCNT](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/hrdcloud/stat/daycnt', params).then(response => resolve(response))
        });
    },
    [ACT_GET_HRDCLOUD_HISTORY_STATISTICS_TOTALMLG](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/hrdcloud/stat/totalmlg', params).then(response => resolve(response))
        });
    },
    [ACT_GET_HRDCLOUD_HISTORY_MILEAGE_EVENTSTATUS](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/hrdcloud/eventmileage', params).then(response => resolve(response))
        });
    },
    [ACT_GET_SSL_EVENTSTATUS](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/book/event', params).then(response => resolve(response))
        });
    },
    [ACT_GET_HRDCLOUD_HISTORY_JOB_CONTENTS_LEARN_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/hrdcloud/job', params).then(response => resolve(response))
        });
    },
    [ACT_GET_HRDCLOUD_HISTORY_LEARN_ROUTINE_LIST_BY_TIME](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/hrdcloud/routine/time', params).then(response => resolve(response))
        });
    },
    [ACT_GET_HRDCLOUD_HISTORY_LEARN_ROUTINE_LIST_BY_WEEKDAYS](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/hrdcloud/routine/weekdays', params).then(response => resolve(response))
        });
    },
    [ACT_GET_HRDCLOUD_HISTORY_ALL_LEARN_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/hrdcloud/history', params).then(response => resolve(response))
        });
    },
    [ACT_GET_HRDCLOUD_HISTORY_DETAIL](context, params) {
        return new Promise(resolve => {
            ApiService.query(`/v1/app/hrdcloud/history/detail`, params).then(response => resolve(response))
        });
    },
    [ACT_GET_HRDCLOUD_HISTORY_PROGRESS_LOG_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/hrdcloud/history/progress', params).then(response => resolve(response))
        });
    },
    [ACT_GET_HRDCLOUD_HISTORY_ATTENDANCE_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/hrdcloud/attendance', params).then(response => resolve(response))
        });
    },
    [ACT_GET_HRDCLOUD_HISTORY_MILEAGE_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/hrdcloud/mileage', params).then(response => resolve(response))
        });
    },
    [ACT_GET_HRDCLOUD_HISTORY_ADD_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/hrdcloud/add', params).then(response => resolve(response))
        });
    },

    // fa로 시작하는것들 삭제 예정
    [ACT_GET_YEAR_SUMMARY_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa003as', params).then(response => resolve(response))
        });
    },
    [ACT_GET_FA005AS_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa005as', params).then(response => resolve(response))
        });
    },
    [ACT_GET_YEAR_AUTH_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa003bs', params).then(response => resolve(response))
        });
    },
    [ACT_GET_YEAR_CERTIFICATION_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa009bs', params).then(response => resolve(response))
        });
    },

    // 자기주도학습 이력 > 지식e러닝
    [ACT_GET_KNOWLEDGE_SUMMARY_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa007a', params).then(response => resolve(response))
        });
    },
    [ACT_GET_KNOWLEDGE_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa007al', params).then(response => resolve(response))
        });
    },
    // 자기주도학습 이력 > 그룹러닝
    [ACT_GET_STUDY_GROUP_SUMMARY_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa007b', params).then(response => resolve(response))
        });
    },
    [ACT_GET_STUDY_GROUP_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa007bl', params).then(response => resolve(response))
        });
    },
    // 자기주도학습 이력 > 클라우드 Easy
    [ACT_GET_CLOUD_EASY_SUMMARY_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa007c', params).then(response => resolve(response))
        });
    },
    [ACT_GET_CLOUD_EASY_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa007cl', params).then(response => resolve(response))
        });
    },
    // 자격증 이력
    [ACT_GET_CERTIFICATE_SUMMARY_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa008a', params).then(response => resolve(response))
        });
    },
    [ACT_GET_CERTIFICATE_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa008al', params).then(response => resolve(response))
        });
    },
    [ACT_GET_HRDCLOUD_HISTORY_SHARE_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/hrdcloud/share', params).then(response => resolve(response))
        });
    },
    [ACT_GET_BOOK_HISTORY_STATISTICS](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/book/stat', params).then(response => resolve(response))
        });
    },
    [ACT_GET_BOOK_HISTORY_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/book/history', params).then(response => resolve(response))
        });
    },
};

export default {

    namespaced: true,
    state,
    mutations,
    actions
};
