<template>
  <LxpMobileHeader v-if="isMobile" title="자기소개">
    <template v-slot:left>
      <div class="util util-back">
        <a href="javascript:" class="util-actions util-actions-back" @click="clickBack">
          <i class="icon-history-back"></i>
        </a>
      </div>
    </template>
  </LxpMobileHeader>
  <main v-if="isMobile" class="kb-main" id="kb-prep-group">
    <!-- main-content -->
    <div class="main-content">
      <div class="join-group-section">
          <textarea v-model="selfIntro" name="" id="" cols="30" rows="10" class="join-group" :placeholder="'자기 소개 및 각오를 입력해주세요.                                       예시) 열심히 학습해서 가계여신 마스터가 되겠습니다.'"></textarea>
        <p class="join-group-sub">작성 후 완료 버튼을 선택하면 해당 그룹에 참여됩니다.</p>
      </div>
      <div class="page-buttons">
        <button class="kb-btn kb-btn-primary" :disabled="selfIntro.length === 0" @click="clickConfirm">작성완료</button>
      </div>
    </div>
  </main>
  <div v-else class="popup-container" :class="{'is-active': modelValue}">
    <!-- popup -->
    <div class="popup" id="popup-prep-l02">
      <!-- popup > popup-inner -->
      <div class="popup-inner">
        <div>
          <!-- popup-inner > popup-header -->
          <header class="popup-header">
            <h3 class="title">자기소개</h3>
          </header>
          <!-- popup-inner > popup-content -->
          <div class="popup-content">
            <article class="popup-section">
              <div class="segment-box">
                <p class="textarea-title">자기소개 및 각오를 입력하세요.</p>
                <textarea v-model="selfIntro" name="" id="" cols="30" rows="6" class="kb-form-control" placeholder="예시) 열심히 학습해서 가계여신 마스터가 되겠습니다."></textarea>
              </div>
              <div class="section-bottom bottom-notice">
                <p class="text-icon"><span class="text">자기소개 및 각오 입력 후 확인 버튼을 누르시면 해당 그룹에 참여됩니다.</span></p>
              </div>
            </article>
          </div>
          <!-- popup-inner > popup-buttons -->
          <div class="popup-buttons">
            <button class="kb-btn kb-btn-outline kb-btn-lg" @click="closeModal"><span class="text">취소</span></button>
            <button class="kb-btn kb-btn-primary kb-btn-lg" @click="clickConfirm"><span class="text">확인</span></button>
          </div>
        </div>
      </div>
      <!-- popup > popup-close -->
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
    <!-- //popup -->
  </div>
  <!-- [end:popup-container] -->
</template>

<script>
import {ref} from "vue";
import {useStore} from "vuex";
import {ACT_INSERT_GROUP_APPLY} from "@/store/modules/course/course";
import {isSuccess} from "@/assets/js/util";
import navigationUtils from "@/assets/js/navigationUtils";
import LxpMobileHeader from "@/views/layout/lxp/header/mobile/LxpMobileHeader";
import {useAlert} from "@/assets/js/modules/common/alert";

export default {
  name: "ApplyGroupLrnModal",
  components: {LxpMobileHeader},
  props: {
    modelValue: Boolean,
    distCrseSn: String,
  },
  emits: ['update:modelValue', 'applySuccess'],
  setup(props, {emit}) {

    const store = useStore();
    // const router = useRouter();

    const selfIntro = ref('');

    const {showConfirm, showMessage} = useAlert();

    const clickConfirm = () => {
      if(!selfIntro.value) {
        showMessage('자기소개 및 각오를 입력하세요.');
      } else {
        showConfirm({
          text: '참여 하시겠습니까?',
          callback: () =>  {
            store.dispatch(`course/${ACT_INSERT_GROUP_APPLY}`, {
              distCrseSn: props.distCrseSn,
              params: {
                selfIntro: selfIntro.value
              }
            }).then(res=>{
              if(isSuccess(res)){
                emit('applySuccess', true);
                closeModal();
              }
            }).catch(e => {
              console.error(e);
            });
          }
        });
      }
    }

    const closeModal = () => {
      emit('update:modelValue', false);
    }

    const clickBack = () => {
      emit('update:modelValue', false);
      selfIntro.value = '';
    }

    return {
      selfIntro,
      clickConfirm,
      closeModal,
      clickBack,
      isMobile: navigationUtils.any()
    }

  }
}
</script>