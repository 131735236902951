import {useStore} from 'vuex';
import {computed, ref} from 'vue';
import {MUT_CLOSE_HRD_BADGE} from '@/store/modules/hrdcloud/hrdcloud';
import {Autoplay, Pagination} from 'swiper';
import {getListFunc} from "@/assets/js/ui.init";
import {ACT_GET_COM_BADGE_TY_LIST} from "@/store/modules/badge/badge";

export const hrdBadgeSetup = () => {
  const store = useStore();
  const show = computed(() => store.state.hrdcloud.badgeShow);
  // const hrdBadgescol = new Date().getFullYear() - 2022;
  const currentYear = new Date().getFullYear().toString();

  const sslHrdBadgesTmp = ref([]);


  const myBadges = computed(() => sslHrdBadgesTmp.value.filter(x => store.state.hrdcloud.badges.indexOf(x.badgeTyCdDcd) > -1));
  // const myBadges = computed(() => hrdBadges.filter(x => x.badgeTyCdDcd.indexOf('1025') > -1));

  const closeModal = () => {
    store.commit(`hrdcloud/${MUT_CLOSE_HRD_BADGE}`);
  }


     getListFunc(`badge/${ACT_GET_COM_BADGE_TY_LIST}`, {
      year: currentYear
    }, sslHrdBadgesTmp, null);

  return {
    show,
    myBadges,
    closeModal,
    modules: [Pagination, Autoplay]
  }
}