import ApiService from '@/assets/js/api.service';

export const ACT_GET_CNTST_SVY = 'getCntstSvy';

export const ACT_GET_SVY_RSLT_LIST = 'getSvyRsltList';

export const ACT_GET_SVY_DIST_PART_LIST = 'getSvyDistPartList';
export const ACT_INSERT_SVY_RSLTS = 'insertSvyRslts';

export const ACT_GET_SVY_TIME_TABLE_LIST = 'getSvyTimeTableList';
export const ACT_INSERT_SVY_TIME_TABLE_RESULT_LIST = 'insertSvyTimeTableResultList';


const state = {};

const mutations = {};

const actions = {

  [ACT_GET_SVY_DIST_PART_LIST](context, distSvySn){
    return new Promise(resolve => {
      ApiService.query(`/v1/app/svies/${distSvySn}/parts`, {}).then(response => resolve(response));
    });
  },
  [ACT_GET_SVY_RSLT_LIST](context, distSvySn){
    return new Promise(resolve => {
      ApiService.query(`/v1/app/svies/${distSvySn}/rslts`, {}).then(response => resolve(response));
    });
  },
  [ACT_INSERT_SVY_RSLTS](context, {distSvySn, params}){
    return new Promise(resolve => {
      ApiService.post(`/v1/app/svies/${distSvySn}/rslts`, params).then(response => resolve(response));
    });
  },

  // 공모 과정 설문
  [ACT_GET_CNTST_SVY](context, distCrseSn){
    return new Promise(resolve => {
      ApiService.get(`/v1/app/svies/cntsts`, distCrseSn).then(response => resolve(response));
    });
  },
  [ACT_GET_SVY_TIME_TABLE_LIST](context, distCrseSn){
    return new Promise(resolve => {
      ApiService.query(`/v1/app/svies/${distCrseSn}/timetables`, {}).then(response => resolve(response));
    });
  },
  [ACT_INSERT_SVY_TIME_TABLE_RESULT_LIST](context, params){
    // params => list []
    return new Promise(resolve => {
      ApiService.post(`/v1/app/svies/timetables/rslts`, params).then(response => resolve(response));
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
