<template>
  <template v-if="isMobile">
    <div class="symbol-item">
      <div class="item-row row-content">
        <div class="item-icon">
          <i v-if="type==='subscribe'" class="icon-hrdcloud-channel"></i>
          <i v-else-if="type==='add'" class="icon-hrdcloud-content"></i>
          <i v-else-if="type==='like'" class="icon-hrdcloud-thumbsup"></i>
          <i v-else-if="type==='comment'" class="icon-hrdcloud-comment"></i>
          <i v-else-if="type==='share'" class="icon-hrdcloud-share"></i>
        </div>
        <div class="item-body">
          <div class="content-title"> {{ title }} </div>
          <div class="content-num text-gold">{{ numberComma(data) }} 개</div>
        </div>
      </div>
      <div class="item-row row-buttons">
        <button v-if="showDetail && func" class="kb-btn kb-btn-outline kb-btn-content-item" @click="func"><span class="text">상세보기</span></button>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="symbol-card" style="width: 227px;">
      <div class="card-icon">
        <i v-if="type==='subscribe'" class="icon-pin"></i>
        <i v-else-if="type==='add'" class="icon-contents"></i>
        <i v-else-if="type==='like'" class="icon-like"></i>
        <i v-else-if="type==='comment'" class="icon-comment"></i>
        <i v-else-if="type==='share'" class="icon-share"></i>
      </div>
      <div class="card-content">
        <div class="content-text">{{ numberComma(data) }} 개</div>
        <div class="content-title"> {{ title }} <a href="javascript:" v-if="showDetail && func" class="content-link" @click="func">상세보기</a>
        </div>
      </div>
    </div>
  </template>

</template>

<script>
import {numberComma} from '@/assets/js/util';

export default {
  name: 'SymbolCard',
  props: {
    title: String,
    type: String,
    data: {type: [Object, Number], default: 0},
    showDetail: Boolean,
    func: Function,
    isMobile: Boolean,
  },
  setup() {
    return {
      numberComma
    }
  }
};
</script>