<template>
  <template v-if="!colType">
    <div v-if="session" class="kb-form-field field-first">
      <div class="kb-form-row">
        <label class="kb-form-label">
          <span class="kb-form-label-text">신청직원</span>
        </label>
      </div>
      <div class="kb-form-row">
        <span class="kb-form-text">{{ session.deptNm }} ・ {{ session.jbgdCd }} {{ session.jbpsNm }} ・ {{ session.lrnerNm }} | {{ session.lrnerId }}</span>
      </div>
    </div>
  </template>
  <template v-else>
    <div v-if="session" class="content-item">
      <div class="kb-form-fields">
        <div class="kb-form-item">
          <div class="kb-form-column kb-form-column-title">
            <label class="kb-form-label">
              <span class="kb-form-label-text">신청직원</span>
            </label>
          </div>
          <div class="kb-form-text">
            {{ session.deptNm }} ・ {{ session.jbgdCd }} {{ session.jbpsNm }} ・ {{ session.lrnerNm }} | {{ session.lrnerId }}
          </div>
        </div>
      </div>
    </div>

  </template>

</template>

<script>
import {useStore} from 'vuex';
import {computed} from 'vue';

export default {
  name: 'MobileApplicant',
  props: {
    aplcnt: {
      type: Object,
      required: false
    },
    colType: {
      type: Boolean,
      required: false
    }
  },
  setup(props) {
    const store = useStore();
    const session = computed(() => {
      if (props.aplcnt) {
        return props.aplcnt;
      } else {
        return store.state.auth.session;
      }
    });

    return {
      session
    };
  }
};
</script>