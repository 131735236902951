import LxpLayout from "@/views/layout/lxp/LxpLayout";
import navigationUtils from "@/assets/js/navigationUtils";
import LxpMobileLayout from "@/views/layout/lxp/mobile/LxpMobileLayout";

const isMobile = navigationUtils.any();

const boardRoutes = [
    {
        path: '/board/:boardId',
        component: isMobile ? LxpMobileLayout : LxpLayout,
        children: [
            {
                path: '',
                name: 'BoardMst',
                component: () => isMobile ? import('../../views/pages/board/mobile/BoardMst') : import('../../views/pages/board/BoardMst')
            },
            {
                path: ':postGrpNo',
                name: 'BoardMstGrp',
                component: () => isMobile ? import('../../views/pages/board/mobile/BoardMst') : import('../../views/pages/board/BoardMst')
            },
            {
                path: 'view/:boardSecuKey',
                name: 'BoardView',
                component: () => isMobile ? import('../../views/pages/board/mobile/BoardView') : import('../../views/pages/board/BoardView')
            },
            {
                path: 'edit/:boardSecuKey',
                name: 'BoardEdit',
                component: () => isMobile ? import('../../views/pages/board/mobile/BoardEdit') : import('../../views/pages/board/BoardEdit')
            },
        ],
    }
];

export const setBoardRoutes = (routes) =>{
    routes.push(...boardRoutes);
}