<template>
  <div class="kb-form-row">
    <div class="kb-form-column kb-form-column-title" v-if="title != null && title != ''">
      <label class="kb-form-label">
        <strong class="kb-form-label-text">{{ title }}</strong>
      </label>
    </div>
    <div class="kb-form-column column-whole kb-form-search">
      <input type="text" v-model="modelNm" class="kb-form-control" :placeholder="placeholder" disabled />
      <button class="kb-form-search-btn" @click="func"><i class="icon-search"></i></button>
    </div>
  </div>
</template>

<script>
import {toRef} from 'vue';

export default {
  name: 'InputSearch',
  props: {
    title: String,
    modelValue: Object,
    inputNm: String,
    placeholder: String,
    func: Function
  },
  setup(props) {
    const modelNm = toRef(props, "inputNm");
    return {
      modelNm
    }
  }
};
</script>