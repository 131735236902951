<template>
  <div class="kb-form-row">
    <div class="kb-form-column kb-form-column-title">
      <label class="kb-form-label">
        <strong class="kb-form-label-text">{{ title }}</strong>
      </label>
    </div>
    <template v-if="showText">
      <div class="kb-form-column column-whole">
        <span class="kb-form-text">{{ data }}</span>
      </div>
    </template>
    <slot name="action"></slot>
  </div>
</template>

<script>
export default {
  name: 'RowItem',
  props: {
    title: String,
    showText: {
      type: Boolean,
      default: true
    },
    data: String
  }
};
</script>