import store from '../../../../store';
import {
    MUT_HIDE_LOADING,
    MUT_SHOW_CONFIRM,
    MUT_SHOW_CUSTOM_CONFIRM,
    MUT_SHOW_LOADING,
    MUT_SHOW_MESSAGE,
    MUT_SHOW_TOAST,
} from '@/store/modules/common/common-index';

const showMessage = (text, callback) => {
    if(text instanceof Object) {
        store.commit(`common/${MUT_SHOW_MESSAGE}`, text);
    }else{
        store.commit(`common/${MUT_SHOW_MESSAGE}`, {text, callback});
    }
}

const showConfirm = (text, callback, cancelCallback, closeCallback) => {
    if(text instanceof Object){
        store.commit(`common/${MUT_SHOW_CONFIRM}`, text);
    }else{
        store.commit(`common/${MUT_SHOW_CONFIRM}`, {text, callback, cancelCallback, closeCallback});
    }
}

const showConfirmCustomBtn = (text, okBtnTitle, callback, cancelCallback, closeCallback) => {
    if(text instanceof Object){
        store.commit(`common/${MUT_SHOW_CONFIRM}`, text);
    }else{
        store.commit(`common/${MUT_SHOW_CONFIRM}`, {text, okBtnTitle, callback, cancelCallback, closeCallback});
    }
}

const showCustomConfirm = (text, firstBtnTitle, secondBtnTitle, firstCallback, secondCallback, cancelCallback) => {
    store.commit(`common/${MUT_SHOW_CUSTOM_CONFIRM}`, {text, firstBtnTitle, secondBtnTitle, firstCallback, secondCallback, cancelCallback});
}

const showToast = (text) => {
    store.commit(`common/${MUT_SHOW_TOAST}`, text);
}

const showLoading = () => {
    store.commit(`common/${MUT_SHOW_LOADING}`);
}

const hideLoading = () => {
    store.commit(`common/${MUT_HIDE_LOADING}`);
}

export const useAlert = () => {
    return {showMessage, showConfirm, showToast, showLoading, hideLoading, showConfirmCustomBtn, showCustomConfirm}
}
