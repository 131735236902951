<template>
  <header class="kb-header" id="kb-header">
    <div class="header-component">
      <template v-if="!searchMode">
        <div v-if="title" class="header-column header-title">{{ title }}</div>
      </template>

      <div class="header-column header-util left">
        <template v-if="showBack">
          <div v-if="showBack" class="util util-back">
            <a href="javascript:" class="util-actions util-actions-back" @click="handleGoBack"><i class="icon-history-back"></i></a>
          </div>
        </template>
        <template v-else>
          <div v-if="showSearch && searchMode" class="util util-back">
            <a href="javascript:" class="util-actions util-actions-back" @click="searchMode=false"><i class="icon-history-back"></i></a>
          </div>
        </template>
        <div v-if="searchMode" class="util util-search-input">
          <input ref="keywordEl" v-model.trim="localKeyword" type="text" :placeholder="placeholder" @keyup.prevent.stop.enter="handleSearch" />
        </div>
        <slot name="left" />
      </div>

      <div class="header-column header-util right">
        <slot name="right" />

        <div v-if="showSearch" class="util util-search">
          <a href="javascript:"
             class="util-actions util-actions-search"
             @click="handleSearch"
             @keyup.prevent.stop.enter="handleSearch"
          ><i class="icon-search"></i></a>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import {computed, nextTick, ref, watch} from 'vue';
import {useRoute} from 'vue-router';
import {goBack} from '@/assets/js/modules/common/common';
import {pushQuery} from '@/assets/js/ui.init';

export default {
  name: 'MobileGroupHeader',
  props: {
    title: {type: String, default: '그룹러닝'},
    placeholder: {type: String, default: '그룹러닝 검색'},
    keyword: [Number, String],
    goBackFunc: Function,
    showBack: Boolean,
    showMap: Boolean,
    showSearch: Boolean,
    showSubMenu: Boolean,
    searchByQuery: {type:Boolean, default:true},
    activeSearch: {type:Boolean, default:false},
  },
  emits: ['update:keyword'],
  setup(props, {emit}) {
    const route = useRoute();
    const searchMode = ref(props.activeSearch),
          keywordEl = ref(null),
          keyword = computed(() => route.query.keyword || ''),
          localKeyword = ref(keyword.value);

    const handleSearch = async () => {
      if (keywordEl.value) {
        if (props.searchByQuery) pushQuery({keyword: localKeyword.value});
        else emit('update:keyword', localKeyword.value);
      } else {
        searchMode.value = true;
        await nextTick();
        keywordEl.value.focus();
      }
    };

    const handleGoBack = () => {
      if (props.goBackFunc instanceof Function) {
        props.goBackFunc();
      } else {
        goBack();
      }
    };

    watch(() => searchMode.value, async () => {
      if (searchMode.value) {
        await nextTick();
        keywordEl.value.focus();
      }
    });

    return {
      searchMode, localKeyword, keywordEl,
      handleSearch, goBack, handleGoBack
    }
  }
};
</script>