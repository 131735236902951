import {useStore} from 'vuex';
import {computed, reactive, ref} from 'vue';
import {useAlert} from '@/assets/js/modules/common/alert';
import {getListFunc} from '@/assets/js/ui.init';
import {
  ACT_GET_CDP_LIST,
  ACT_GET_JOB_LIST,
  ACT_GET_LRN_CRSE_WORK_ABILITY_GROUP_LIST,
  ACT_GET_LRN_CRSE_WORK_ACHV_LIST,
  ACT_GET_LRN_CRSE_WORK_GOAL_LIST,
  ACT_GET_LRN_CRSE_WORK_PHASE_LIST,
  ACT_GET_LRN_SCORE_LIST,
} from '@/store/modules/talent/talent';
import {
  crseWorkTyCdDcds,
  getBookGroupSum,
  getCalTotalScore,
  getCrseWorkNm,
  getGroupSum,
  learningTalents,
} from '@/assets/js/modules/mypage/talent-common';
import {
  ACT_GET_LEARN_FINISH_HISTORY_LIST,
  ACT_GET_LEARN_HISTORY_LIST,
} from '@/store/modules/learn/learn';
import {ACT_GET_LRN_SPRT_LCSN_LIST} from '@/store/modules/license/license';
import {
  getDDay,
  timestampToDateFormat,
  winOpen,
  ymdStrToDate,
} from '@/assets/js/util';
import {useRouter} from 'vue-router';
import {ACT_GET_CRSE_APPLY_COUNT} from '@/store/modules/course/course';
import {ACT_GET_SOC_BOARD_WRITE_STAT_LIST} from '@/store/modules/ssl/ssl-index';
import {
  noneRewardCds,
  sslCodes,
  sslRoutes,
} from '@/assets/js/modules/ssl/ssl-common';
import {getToken} from '@/assets/js/localstorage.service';

const {showMessage} = useAlert();

export const talentMainSetup = () => {
  const store = useStore();
  const session = computed(() => store.state.auth.session);

  const {showLoading, hideLoading} = useAlert();

  // Talent 항목들
  const works = ref([]);
  // 현재 내 설정
  const goals = ref([]);

  // 총점
  const abilities = ref([]);
  // 전 직원
  const allAbilities = ref([]);

  // Ace Academy 정보 (과정 + 자격증), 해당 항목은 난이도만 보면된다.
  const aces = ref([]);

  const learns = ref([]);
  const licenses = ref([]);
  const licenseCodes = computed(() => licenses.value.map(x => x.lcsnCd));

  const readies = reactive({
    ability: false,
    ace: false,
    all: false,
    license: false,
    goal: false,
  });

  const toggles = reactive({
    download: false
  })

  const router = useRouter();
  const goGoal = () => {
    router.push({name: 'MyTalentBoardGoal'});
  }

  const getTalentWorks = () => {
    getListFunc(`talent/${ACT_GET_LRN_CRSE_WORK_GOAL_LIST}`, null, goals, null, () => {
      readies.goal = true;
      hideLoading();
    });
  }

  showLoading();

  // 현재 내 역량 점수 확인...
  const allReadies = computed(() => {
    return readies.ability && readies.ace && readies.all && readies.license && readies.goal;
  });

  // 현재 내 목표가 설정되었는지 확인한다.
  getListFunc(`talent/${ACT_GET_LRN_CRSE_WORK_PHASE_LIST}`, {crseWorkTyCdDcd: crseWorkTyCdDcds.ability}, works, null, () => {getTalentWorks();});
  getListFunc(`learn/${ACT_GET_LEARN_FINISH_HISTORY_LIST}`, {}, learns, null);
  getListFunc(`license/${ACT_GET_LRN_SPRT_LCSN_LIST}`, {regYn: 'Y'}, licenses, null, () => {readies.license = true;});
  getListFunc(`talent/${ACT_GET_LRN_CRSE_WORK_ABILITY_GROUP_LIST}`, {crseWorkTyCdDcd: crseWorkTyCdDcds.ability, allYn: 'N'}, abilities, null, () => {
    readies.ability = true
  });
  getListFunc(`talent/${ACT_GET_LRN_CRSE_WORK_ACHV_LIST}`, {crseWorkTyCdDcd: crseWorkTyCdDcds.ace}, aces, null, () => {readies.ace = true});
  getListFunc(`talent/${ACT_GET_LRN_CRSE_WORK_ABILITY_GROUP_LIST}`, {allYn: 'Y', jobYn: 'Y'}, allAbilities, null, () => {readies.all = true});

  return {
    session,
    works,
    goals,
    abilities,
    aces,
    allAbilities,
    learns,
    licenseCodes,
    readies,
    allReadies,
    toggles,
    guideFileKey: 'TalentBoardGuide',
    goGoal,
    getTalentWorks,
  }
}

export const talentGoalSetup = (props, {emit}) => {
  const store = useStore();
  const router = useRouter();
  const session = computed(() => store.state.auth.session);

  const jobs = ref([]);
  const cdps = ref([]);
  const crseWorkDtlSns = computed(() => props.works.map(x => x.crseWorkDtlSn));

  // const licenseCodes = computed(() => props.licenses.map(x => x.lcsnCd));
  const tooltip = ref(false);

  // 현재직무, 추천직무
  getListFunc(`talent/${ACT_GET_JOB_LIST}`, {}, jobs, null);
  getListFunc(`talent/${ACT_GET_CDP_LIST}`, {}, cdps, null);

  const nowGoal = computed(() => {
    const now = props.goals.find(x => crseWorkDtlSns.value.includes(x.crseWorkDtlSn) && x.goalsDivCd === '00');
    return now ? now : {
      crseWorkDtlSn: 0
    };
  });

  const hopeGoal = computed(() => {
    const hope = props.goals.find(x => crseWorkDtlSns.value.includes(x.crseWorkDtlSn) && x.goalsDivCd === '01');
    return hope ? hope : {
      crseWorkDtlSn: 0
    };
  });

  const coachings = computed(() => {
    return props.goals.filter(x => x.goalsDivCd === '02');
  });

  const talentLevel = computed(() => {
    if(hopeGoal.value.crseWorkDtlNm){
      let targetAbility = props.abilities.find(x => x.crseWorkDtlNm === hopeGoal.value.crseWorkDtlNm);
      if (!targetAbility) targetAbility = {crseWorkDtlNm: hopeGoal.value.crseWorkDtlNm, totalScore: 0}
      const calScore = getCalTotalScore(targetAbility, props.aces, props.licenseCodes);
      const level = parseInt(calScore / 10);
      return level > 4 ? 4 : level;
      // }
    }
    return 0;
  });

  const bestAbility = computed(() => {
    const scores = props.works.map(x => {
      // 확인
      let targetAbility = props.abilities.find(y => y.crseWorkDtlNm === x.crseWorkDtlNm);
      if (!targetAbility) targetAbility = {crseWorkDtlNm: x.crseWorkDtlNm, totalScore: 0}

      return {
        ...x,
        calScore: getCalTotalScore(targetAbility, props.aces, props.licenseCodes)
      }
    });

    if(scores.length > 0){
      const max = Math.max(...scores.map(x => x.calScore));
      return scores.find(x => x.calScore > 0 && x.calScore === max) || {crseWorkDtlSn: 0};
    }

    return {crseWorkDtlSn: 0};
  });

  const hopeLicenses = computed(() => {
    if(hopeGoal.value.crseWorkDtlNm && hopeGoal.value.lcsnLst){
      return hopeGoal.value.lcsnLst.split(',');
    }
    return [];
  });

  const currentDate = new Date();

  const restDay = computed(() => {
    if(hopeGoal.value.crseWorkDtlNm && hopeGoal.value.goalsEndYmd){
      return getDDay(ymdStrToDate(hopeGoal.value.goalsEndYmd.replace('-', '').replace('-', '')).getTime(), currentDate.getTime());
    }
    return -1;
  });

  const goalTalent = computed(() => {
    const key = hopeGoal.value.crseWorkDtlNm;
    const target = learningTalents.find(x => x.key === key);
    return target || {};
  });


  const isLicenseGet = (code) => {
    return props.licenseCodes.includes(code);
  }

  const getLicenseNm = (code) => {
    if(hopeGoal.value.licenses){
      const target = hopeGoal.value.licenses.find(x => x.lcsnCd === code);
      return target ? target.lcsnNm : code;
    }
    return code;
  }

  const completeSetting = () => {
    emit('completed');
  }

  const coachingModal = ref(false);
  const goalModal = ref(false);

  const goGoal = () => {
    router.push({name: 'MyTalentBoardGoal'});
  }

  const goCoaching = () => {
    router.push({name: 'MyTalentBoardCoaching'});
  }


  return {
    session,
    nowGoal,
    hopeGoal,
    coachings,
    goalModal,
    coachingModal,
    talentLevel,
    restDay,
    goalTalent,
    hopeLicenses,
    bestAbility,
    tooltip,
    jobs,
    cdps,
    getCrseWorkNm,
    getLicenseNm,
    isLicenseGet,
    completeSetting,
    timestampToDateFormat,
    goGoal,
    goCoaching,
    goRecommendSystem
  }
}

export const goRecommendSystem = () => {
  if('1' === getToken('kbInside')){
    winOpen('http://career.hrkbstar.com');
  }else{
    showMessage('외부망에서 지원하지 않습니다. <br>내부망기기에서 접속해주세요.');
  }
}

export const talentJourneySetup = (props) => {
  const tooltip = ref(false);

  const journey = reactive({
    toggle: !!props.isOpened,
    index: 0
  });

  if (props.initIndex >= 0) {
    journey.index = props.initIndex;
  }

  // const courseCodes = computed(() => {
  //   return props.learns.filter(x => x.befCrseCd).map(x => x.befCrseCd);
  // });

  const courseFinishCodes = computed(() => {
    // return props.learns.filter(x => x.befCrseCd && x.fnshYn === 'Y').map(x => x.befCrseCd);
    return props.learns.filter(x => x.crseMstSn && x.fnshYn === 'Y').map(x => x.crseMstSn + '');
  });

  const gridElements = computed(() => {
    if(learningTalents[journey.index]){
      return learningTalents[journey.index].grid;
    }
    return [];
  });

  const isDt = () => {
    if(learningTalents[journey.index]){
      return learningTalents[journey.index].name.indexOf('DT') === 0;
    }
    return false
  }

  const currentTalent = computed(() => {
    return learningTalents[journey.index];
  });

  const prevTalent = computed(() => {
    if(journey.index === 0){
      return learningTalents[(learningTalents.length - 1)]
    }
    return learningTalents[(journey.index - 1)];
  });

  const nextTalent = computed(() => {
    if(journey.index === (learningTalents.length - 1)){
      return learningTalents[0]
    }
    return learningTalents[(journey.index + 1)];
  });

  const goPrev = () => {
    if(journey.index === 0){
      journey.index = (learningTalents.length - 1);
    }else{
      journey.index = (journey.index - 1);
    }
  }

  const goNext = () => {
    if(journey.index === (learningTalents.length - 1)){
      journey.index = 0;
    }else{
      journey.index = (journey.index + 1);
    }
  }

  const isFinish = (el) => {
    // if(el.ace){
      const courseCodes = el.code.split(' ');
      // const filtered = Array.from(new Set(courseCodes.value.filter(x => aceCodes.includes(x))));
      // return courseCodes.value.filter(x => aceCodes.includes(x)).length > 0;
      return courseFinishCodes.value.filter(x => courseCodes.includes(x)).length > 0;
    // }
    // else{
    //   return courseFinishCodes.value.includes(el.code);
    // }
  }

  const isGet = (el) => {
    if(el.code){
      let count = 0;
      // 카운트 만큼 루프를 돌면서 라이센스가 있는지 체크
      const codes = el.code.split(' ');
      if (codes.length > 0) {
        for(let i = 0; i < codes.length; i++){
          const subCodes = codes[i].split('-');
          // if (subCodes.length === 1) {
          //   if (props.licenseCodes.includes(codes[i])) {
          //     result = true;
          //     break;
          //   }
          // } else {
          // console.log(subCodes.length);
          for(let j = 0; j < subCodes.length; j++){
            if (props.licenseCodes.includes(subCodes[j])) {
              count++;
              break;
            }
          }
        }
        return (codes.length === count);
      }
    }
    return false;
  }

  const getDefaultSpanValue = (spanValue) => {
    if(spanValue > 0){
      return spanValue;
    }
    return 1;
  }

  const store = useStore();
  const router = useRouter();
  const isLoading = ref(false);

  const goToApply = (el) => {
    if (props.showCode) return;
    if(isLoading.value) return;

    if(el.code){
      const codes = el.code.split(' ');
      if (codes.length > 0) {
        isLoading.value = true;
        store.dispatch(`course/${ACT_GET_CRSE_APPLY_COUNT}`, {crseMstSn: codes[0]}).then(res => {
          isLoading.value = false;
          if(res.count > 0){
            router.push({name: 'ApplyTrain', query: {crseMstSn: codes[0]}});
          }else{
            showMessage({
              title: el.name,
              text: '현재 입과할 수 있는 연수가 없습니다.',
              center: true
            });
          }

        }).catch(() => {
          isLoading.value = false;
        });
      }
    }
  }

  const getCodeHTML = (code, isLicense) => {
    const outputs = [];

    if (code) {
      const codes = code.split(' ');
      codes.forEach((sub, index) => {
        const subCodes = sub.split('-');
        outputs.push(
            subCodes.map((subCode, idx) => {
              let text = subCode;
              if (isLicense ? props.licenseCodes.includes(subCode) : courseFinishCodes.value.includes(subCode)) text = text + '*';

              if (!isLicense && index === 0 && idx === 0) {
                return `<span style="color: red">${text}</span>`
              } else {
                return `<span>${text}</span>`
              }
            }).join('<span>-</span>')
        );
      });

    }
    return `<div style="word-break: break-all;">(${outputs.join('&nbsp;&nbsp;')})</div>`;
  }

  const setHopeAbility = () => {
    if (props.goals) {
      const hope = props.goals.find(x => x.goalsDivCd === '01');
      if(hope){
        const idx = learningTalents.map(x => x.key).indexOf(hope.crseWorkDtlNm);
        if(idx > -1) journey.index = idx;
      }
    }
  };

  setHopeAbility();

  return {
    tooltip,
    journey,
    gridElements,
    currentTalent,
    prevTalent,
    nextTalent,

    isDt,
    isFinish,
    isGet,
    goPrev,
    goNext,
    goToApply,
    getDefaultSpanValue,
    getCodeHTML
  }
}

export const talentAbilitySetup = (props) => {
  const tooltip = ref(false);

  const abilityScores = computed(() => {
    const scores = learningTalents.map(x => {
      const score = {
        name: x.name,
        level: 0,
        score: 0,
        totalScore: 0,
        allTotalScore: 0,
      }

      let target = props.abilities.find(y => y.crseWorkDtlNm === x.key);
      if (!target) target = {crseWorkDtlNm: x.key, totalScore: 0}

      const calScore = getCalTotalScore(target, props.aces, props.licenseCodes);
      const level = parseInt(calScore / 10);
      score.level = level > 4 ? 4 : level;
      // score.score = calScore % 10;
      score.score = calScore / 4.5; // 게이지 차트, 점수에 비례하여 차게 바꿈
      score.totalScore = calScore;

      const allTarget = props.allAbilities.find(y => y.crseWorkDtlNm === x.key);
      if(allTarget){
        score.allTotalScore = allTarget.totalScore;
      }
      return score;
    });
    const maxScore = Math.max(...scores.map(x => x.totalScore));
    return scores.map(x => ({...x, isBest: (x.totalScore > 9 && maxScore === x.totalScore)}));
  });

  const getDescOrderItems = (items) => {
    return items.sort((a,b) => {if(a.totalScore > b.totalScore){ return -1} return 0;})

  }

  const getLevelText = (level) => {
    if(level === 1){
      return '초급<br>(Beginner)'
    }else if(level === 2){
      return '중급<br>(Intermediate)'
    }else if(level === 3){
      return '고급<br>(Advanced)'
    }else if(level === 4){
      return '전문가<br>(Expert)'
    }
    return '-';
  }

  const getHeight = (score) => {
    if(score > 50) score = 50;
    return 196 * score / 40;
  }

  return {
    tooltip,
    abilityScores,
    getDescOrderItems,
    getLevelText,
    getHeight

  }
}

export const talentSSLSetup = () => {
  const store = useStore();
  const isMobile = computed(() => store.state.auth.isMobile);
  const tooltip = ref(false);

  const isTemp2024Ready = ref(true);

  const sslOptions2 = {
    chart: { width: 300, height: 300 },
    series: {
      clockwise: false,
      dataLabels: {
        visible: false,
      },
      first: {
        radiusRange: {
          inner: '30%',
          outer: '40%'
        },
      },
      second: {
        radiusRange: {
          inner: '45%',
          outer: '55%'
        }
      },
      third: {
        radiusRange: {
          inner: '60%',
          outer: '70%'
        }
      }
    },
    legend: {
      visible: false
    },
    tooltip: {
      template: (model) => {
        if(model.data[0].label){
          return `<div style="background: #FFFFFF;width: 140px;padding: 0 5px;text-align: center;"><div class="toastui-chart-tooltip-series-wrapper" style="font-weight: normal; color:black; font-size: 12px; "><div class="toastui-chart-tooltip-series"><span class="toastui-chart-series-name"><i class="toastui-chart-icon" style="background: ${model.data[0].color}"></i><span class="toastui-chart-name" style="color:black;">${model.data[0].category}</span></span><span class="toastui-chart-series-value">${model.data[0].value}</span></div></div></div>`;
        }
        return '';
      }
    },
    exportMenu: {
      visible: false
    },
    theme: {
      series: {
        first: {
          colors: ['#84888B', '#e8e8e8'],
        },
        second: {
          colors: ['#545045', '#e8e8e8'],
        },
        third: {
          colors: ['#FFBC00', '#e8e8e8'],
        }
      },
    },
    usageStatistics: false
  }

  const sslOptions = isMobile.value ? {
        chart: {
          width: 300,
          height: 300,
        },
        series: {
          showArea: true,
        },
        legend: {
          visible: false,
        },
        theme: {
          series: {
            areaOpacity: 0.8,
            colors: ['#EEEEEE', '#FFCC00'],
            lineWidth: 1,
            dot: {
              radius: 5,
            },
          },
          tooltip: {
            background: '#FFFFFF',
            borderColor: '#FFBC00',
            borderWidth: 1,
            borderRadius: 20,
            header: {
              fontSize: 13,
              color: 'black',
            },
            body: {
              color:'block'
            }
          },
        },
        exportMenu: {
          visible: false,
        },
      } :
      {
        chart: {
          width: 415,
          height: 400,
        },
        series: {
          showArea: true,
        },
        legend: {
          visible: false,
        },
        theme: {
          series: {
            areaOpacity: 0.8,
            colors: ['#EEEEEE', '#FFCC00'],
            lineWidth: 1,
            dot: {
              radius: 5,
            },
          },
          tooltip: {
            background: '#FFFFFF',
            borderColor: '#FFBC00',
            borderWidth: 1,
            borderRadius: 20,
            header: {
              fontSize: 13,
              color: '#111111',
            },
            body: {
              color:'block'
            }
          },
        },
        exportMenu: {
          visible: false,
        },
        usageStatistics: false
      };


  // const isReady = ref(false);
  const sslReadies = reactive({
    ssl: false,
    self: false,
    group: false
  });

  const write = reactive({
    total: [],
    mine: [],
    job: []
  });

  const grape = reactive({
    total: [],
    mine: [],
    job: [],
  });

  const etcGrape = reactive({
    hrd: {
      total: 0,
      mine: 0,
      job: 0,
    },
    selfGa: {
      total: 0,
      mine: 0,
      job: 0,
    },
    selfgi: {
      total: 0,
      mine: 0,
      job: 0,
    },
    selIct: {
      total: 0,
      mine: 0,
      job: 0,
    },
    selfJa: {
      total: 0,
      mine: 0,
      job: 0,
    },
    group: {
      total: 0,
      mine: 0,
      job: 0,
    }
  });

  const hrdMlgs = ref([]);
  const knowledges = ref([]);
  const groups = ref([]);

  const onMlg = computed(() => {
    const target = hrdMlgs.value.find(x => x.knwlFlag === '1');
    return target || {sumMlgValue: 0}
  });

  const offMlg = computed(() => {
    const target = hrdMlgs.value.find(x => x.knwlFlag === '2');
    return target || {sumMlgValue: 0}
  });

  const sslTotalScore = computed(() => {
    let result = 0;
    if(write.mine.length > 0){
      result += (grape.mine.reduce((accumulator, a) => (accumulator + a)) * 5);
    }
    if (groups.value.length > 0) {
      result += (groups.value[0].learnCnt * 5);
    }

    if (knowledges.value.length > 0) {
      result += (knowledges.value[0].sumGetValue);
    }
    // if (sgGroupMCnt.value > 0) {
    //   result += (sgGroupMCnt.value * 5);
    // }
    // if (jisikECnt.value > 0) {
    //   result += (jisikECnt.value * 10);
    // }
    return result;
  });


  const totalScore = computed(() => {
    let result = 0;
    if(write.mine.length > 0){
      result += (grape.mine.reduce((accumulator, a) => (accumulator + a)) * 5);
    }
    result = (result + onMlg.value.sumMlgValue + offMlg.value.sumMlgValue);
    if (groups.value.length > 0) {
      result += (groups.value[0].learnCnt * 5);
    }

    if (knowledges.value.length > 0) {
      result += (knowledges.value[0].sumGetValue);
    }

    return result;
  });

  const etcSum = (position) => {
    let sum = 0;
    Object.keys(etcGrape).forEach(key => {
      sum += etcGrape[key][position];
    });
    return sum;
  }

  const grapeStatus = computed(() => {

    const total = parseInt(((grape.total.length > 0 ? grape.total.reduce((a, b) => a + b, 0) : 0) * 5) + etcSum('total'))
    const job = parseInt(((grape.job.length > 0 ? grape.job.reduce((a, b) => a + b, 0) : 0) * 5) + etcSum('job'));
    const mine = (grape.mine.length > 0 ? grape.mine.reduce((a, b) => a + b, 0) : 0) * 5 + etcSum('mine');

    return [
      {
        name: 'first',
        data: [
          {
            name: 'first',
            data: total,
          },
          {
            name: '',
            data: (1170 - total),
          },
        ],
      },
      {
        name: 'second',
        data: [
          {
            name: 'second',
            data: job,
          },
          {
            name: '',
            data: (1170 - job),
          },
        ],
      },
      {
        name: 'third',
        data: [
          {
            name: 'third',
            data: mine,
          },
          {
            name: '',
            data: (1170 - mine),
          },
        ],
      },
    ];
  })


  const getScore = (value, key) => {
    const count = key === 'book' ? getBookGroupSum(write.mine, value.group.map(x => x.lrnTrgtGrpMstSn)) : getGroupSum(write.mine, value.group.map(x => x.lrnTrgtGrpMstSn));


    if(count > 0){
      return `${count}건 <br/>(${count * 5}점)`
    }
    return '-';
  }

  const getSSLWriteStatList = () => {

    Object.keys(grape).forEach(key => {
      write[key] = [];
      grape[key] = [];
    });

    store.dispatch(`ssl/${ACT_GET_SOC_BOARD_WRITE_STAT_LIST}`, {totalYn: 'Y', mineYn: 'Y', jobYn: 'Y', year: filters.year}).then(res => {
      if(res){

        Object.keys(grape).forEach(key => {
          write[key] = res[key] ? res[key] : [];
          setGrape(write[key], grape[key]);
        });
      }
      sslReadies.ssl = true;
    });
  }

  const setGrape = (target, arr) => {
    if(target){
      Object.keys(targetSSLCodes.value).forEach(k => {
        if(k !== 'trivia' && k !== 'book' ) {
          // if( k === 'book'){
          //   // arr.push(getGroupSum(target, sslRoutes[k].refGroup.map(x => x.lrnTrgtGrpMstSn)).toFixed(1));
          //   arr.push(getBookGroupSum(target, sslRoutes[k].refGroup.map(x => x.lrnTrgtGrpMstSn)));
          // }else{
            arr.push(getGroupSum(target, sslRoutes[k].group.map(x => x.lrnTrgtGrpMstSn)));
          // }
        }
      });
    }
  }

  const currentDate =  new Date();
  const filters = reactive({
    year: currentDate.getFullYear(),
  });

  const toggles = reactive({
    year: false,
  });

  const yearOptions = (() => {
    const options = [];
    for(let i = currentDate.getFullYear(); i >= 2023 ; i--){
      options.push({value: i, display: `${i}년`});
    }
    return options;
  })();


  const targetSSLCodes = computed(() => {
    const target = sslCodes[filters.year + ""]

    return target ? target : [];
  });

  const keys = ['total', 'mine'];

  const getLearnScoreList = (contents, target) => {
    // init
    keys.forEach(k => {
      etcGrape[contents][k] = 0;
    });

    store.dispatch(`talent/${ACT_GET_LRN_SCORE_LIST}`, {contents, params: {totalYn: 'Y', mineYn: 'Y', jobYn: 'N', year: filters.year}}).then(res => {
      if(res){
        target.value = res.mine;
        keys.forEach(k => {
          if(res[k] && res[k].length > 0) {
            if (contents === 'hrd' && k === 'mine') {
              etcGrape[contents][k] = res[k].map(x => x.sumMlgValue).reduce((a, b) => a + b, 0)
            } else {
              const item = res[k][0];
              if (contents === 'group' || contents.includes('self')) {
                if (item && item.targetCnt > 0 && item.learnCnt > 0) etcGrape[contents][k] = (item.learnCnt * 5) / item.targetCnt;
              // } else if (contents === 'knowledge') {
              //   if (item && item.sumGetValue > 0 && item.targetCnt) etcGrape[contents][k] = item.sumGetValue / item.targetCnt;
              }else {
                if (item && item.targetCnt > 0 && item.sumMlgValue > 0)  etcGrape[contents][k] = item.sumMlgValue / item.targetCnt;
              }
            }
          }
        });
      }
      sslReadies[contents] = true;
    });
  }

  // const sgGroupMCnt = ref(0);
  // const jisikECnt = ref(0);

  // const getGljisikScore = () => {
  //
  //   const sgGroupMCntFilter = SSLGroupSum.filter(x => x.lrnerId === store.state.auth.session.lrnerId)[0];
  //   const jiskEFilter = SSLKnowledgeSum.filter(x => x.lrnerId === store.state.auth.session.lrnerId)[0];
  //
  //   if(sgGroupMCntFilter) sgGroupMCnt.value = sgGroupMCntFilter.count;
  //   if(jiskEFilter) jisikECnt.value = jiskEFilter.count;
  //
  //
  //   // store.dispatch(`ssl/${ACT_GET_GROUP_LRN_E_MNG}`, {
  //   //   // resNo: '3172437',
  //   //   resNo: store.state.auth.session.lrnerId,
  //   //   year: new Date().getFullYear(),
  //   // }).then(res => {
  //   //   if (res.status.message === 'OK') {
  //   //     sgGroupMCnt.value = res.sgGroupMCnt;
  //   //     jisikECnt.value = res.jisikECnt;
  //   //   }
  //   // }).catch(e => {
  //   //   console.error(e);
  //   // });
  // };

  // getGljisikScore();

  const selectedYear = () => {
    sslReadies.ssl = false;
    sslReadies.hrd = false;
    sslReadies.knowledge = false;
    sslReadies.group = false;

    getSSLWriteStatList();
    getLearnScoreList('hrd', hrdMlgs);
    getLearnScoreList('self', hrdMlgs);
    // getLearnScoreList('knowledge', knowledges);
    getLearnScoreList('group', groups);
  }

  // setTimeout(() => {

  //2024 자기주도학습지수 임시 제한
  if(isTemp2024Ready.value){
    getSSLWriteStatList();
    getLearnScoreList('hrd', hrdMlgs);
    // getLearnScoreList('knowledge', knowledges);
    getLearnScoreList('group', groups);
  }

    // getListFunc(`talent/${ACT_GET_LRN_SCORE_LIST}`, {contents:'hrd', params: {totalYn: 'Y', mineYn: 'Y', jobYn: 'Y'}}, hrdMlgs, null);
    // getListFunc(`talent/${ACT_GET_LRN_SCORE_LIST}`, {contents:'knowledge', params: {totalYn: 'Y', mineYn: 'Y', jobYn: 'Y'}}, knowledges, null);
    // getListFunc(`talent/${ACT_GET_LRN_SCORE_LIST}`, {contents:'group', params: {totalYn: 'Y', mineYn: 'Y', jobYn: 'Y'}}, groups, null);
  // }, 500)

  return {
    tooltip,
    sslOptions2,
    sslOptions,
    sslReadies,
    grape,
    totalScore,
    sslTotalScore,
    grapeStatus,
    filters,
    toggles,
    yearOptions,
    onMlg,
    offMlg,
    knowledges,
    groups,
    getScore,
    targetSSLCodes,
    noneRewardCds,
    // getGljisikScore,
    selectedYear,
    // jisikECnt,
    // sgGroupMCnt,
    isTemp2024Ready,
  }
}

export const talentJourneyModalSetup = (props, {emit}) => {
  const readies = reactive({
    learn: false,
    license: false,
  });

  const learns = ref([]);
  const licenses = ref([]);
  const licenseCodes = computed(() => licenses.value.map(x => x.lcsnCd));

  getListFunc(`learn/${ACT_GET_LEARN_HISTORY_LIST}`, {}, learns, null, () => {
    readies.learn = true;
  });
  getListFunc(`license/${ACT_GET_LRN_SPRT_LCSN_LIST}`, {regYn: 'Y'}, licenses, null, () => {
    readies.license = true;
  });

  const closeModal = () => {
    emit('update:toggle', false);
  };

  return {
    learns,
    licenseCodes,
    readies,
    closeModal
  }
}
