import LxpLayout from "@/views/layout/lxp/LxpLayout";
import navigationUtils from "@/assets/js/navigationUtils";
import LxpMobileLayout from '@/views/layout/lxp/mobile/LxpMobileLayout';
const isMobile = navigationUtils.any();

const remindRoutes = [
    {
        path: '/remind',
        component: isMobile ? LxpMobileLayout : LxpLayout,
        children: [
            {
                // path variable 을 생성해야 된다. 임시 라우터 처리
                path: ':distCrseSn',
                name: "remindMain",
                component: () =>isMobile ? import('../../views/pages/remind/mobile/MobileRemindMain'): import('../../views/pages/remind/RemindMain')
            }
        ],
    }
];

export const setRemindRoutes = (routes) => {
    routes.push(...remindRoutes);
}