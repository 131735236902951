import store from '../../../../store';
import {ACT_IS_AUTH_ADMIN, MUT_SET_LOGOUT, MUT_SET_INSIDE, ACT_LOGOUT_AUTH} from '@/store/modules/auth/auth';
import {getItem, isSuccess} from '../../util';
import {destroyToken, saveToken} from '../../localstorage.service.js';

export const isAdmin = () => {
    store.dispatch(`auth/${ACT_IS_AUTH_ADMIN}`).then(res => {
        if(isSuccess(res)) {
            store.state.auth.session.isAdmin = true
            if(getItem(res)){
                let item = getItem(res);
                if(item.mngrTyCdDcd === '1') { // 저작도구 페이지 이동 위해 총괄관리자 체크
                    store.state.auth.session.isGeneralAdmin = true;
                }
            }
        }
    }).catch(e => { console.error(e)})
}

export const setLogout = async (value) => {
    console.log("setting logout", value);
    if(value) {
        await store.dispatch(`auth/${ACT_LOGOUT_AUTH}`).then(res => {
            console.log("logout api result", res.result);
            store.commit(`auth/${MUT_SET_LOGOUT}`, value);
            destroyToken('kbInside')
        });
    }else{
        store.commit(`auth/${MUT_SET_LOGOUT}`, value);
        destroyToken('kbInside')
    }

}

export const setInside = (value) => {
    saveToken('kbInside', value)
    store.commit(`auth/${MUT_SET_INSIDE}`, value)
}

