import LxpLayout from '@/views/layout/lxp/LxpLayout';
import navigationUtils from '@/assets/js/navigationUtils';
import LxpMobileLayout from '@/views/layout/lxp/mobile/LxpMobileLayout';
import HistoryMobileHrdCloud
  from '@/views/pages/history/mobile/HistoryMobileHrdCloud';
import HistoryHrdCloud from '@/views/pages/history/HistoryHrdCloud';

const isMobile = navigationUtils.any();

const historyRoutes = [
  {
    path: '/history',
    component: isMobile ? LxpMobileLayout : LxpLayout,
    children: [
      {
        path: 'dashboard',
        name: "HistoryDashboard",
        component: () => import('../../views/pages/history/HistoryDashboard'),
      },

      {
        // path variable 을 생성해야 된다. 임시 라우터 처리
        path: 'regular', // 정규연수
        name: "HistoryRegular",
        component: () => import('../../views/pages/history/HistoryRegular'),
      },

      {
        // path variable 을 생성해야 된다. 임시 라우터 처리
        path: 'e-test',
        name: "HistoryEtest",
        component: () => import('../../views/pages/history/HistoryEtest'),
      },

      {
        path: 'hrdcloud',
        name: "HistoryHrdCloud",
        component: isMobile ? HistoryMobileHrdCloud : HistoryHrdCloud
      },
      /**
       * 자기주도학습
       *  : 지식e러닝
       *  : 그룹러닝
       *  : 클라우드이지
       * */
      {
        // path variable 을 생성해야 된다. 임시 라우터 처리
        path: 'self-study', // 자기주도학습 > 지식-e러닝
        name: "HistoryPrep",
        component: () => import('../../views/pages/history/HistoryPrep'),
      },


      {
        // path variable 을 생성해야 된다. 임시 라우터 처리
        path: 'knowledge-e', // 자기주도학습 > 지식-e러닝
        name: "HistoryKnowledgeE",
        component: () => import('../../views/pages/history/HistoryPrep'),
      },
      {
        // path variable 을 생성해야 된다. 임시 라우터 처리
        path: 'group-learning', // 자기주도학습 > 그룹러닝
        name: "HistoryGroupLearning",
        component: () => import('../../views/pages/history/HistoryPrep'),
      },
      {
        // path variable 을 생성해야 된다. 임시 라우터 처리
        path: 'cloud-easy', // 자기주도학습-클라우드이지
        name: "HistoryCloudEasy",
        component: () => import('../../views/pages/history/HistoryPrep'),
      },

      {
        // path variable 을 생성해야 된다. 임시 라우터 처리
        path: 'certificate', // 자격증
        name: "HistoryCertificate",
        component: () => import('../../views/pages/history/HistoryCertificate'),
      },

      {
        // path variable 을 생성해야 된다. 임시 라우터 처리
        path: 'job-certification', // 직무인증
        name: "HistoryJobCertification",
        component: () => import('../../views/pages/history/HistoryJobCertification'),
      },

    ],
  }
];

export const setHistoryRoutes = (routes) => {
  routes.push(...historyRoutes);
}
