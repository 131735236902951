<template>
  <!-- begin::kb-main -->
  <main class="kb-main kb-prep-mygroup" id="kb-prep-group">
    <!-- begin::kb-header -->
    <lxp-main-header v-if="!isMobile">
      <template v-slot:inner>
        <div class="header-column">
          <router-link :to="{name: 'SSLMain', params: {distCrseCd: 'group', lrnTrgtGrpMstSn: 115617}}"  class="page-nav">
            <i class="icon-kb-nav-arrow"></i>
            <span class="text">그룹러닝 홈</span>
          </router-link>
        </div>
        <div v-if="activeMenu === 'groupInfo' && isLeader" class="buttons">
          <router-link :to="{name: 'GroupLearningMng', params: {boardSn: items[0].boardSn}}" type="button" class="kb-btn kb-btn-secondary kb-btn-sm rounded-lg">
            <i class="icon-edit"></i>
            <span class="text">그룹관리</span>
          </router-link>
        </div>
        <div v-if="activeMenu === 'groupClass' && isLeader" class="buttons">
          <button class="kb-btn kb-btn-secondary kb-btn-sm rounded-lg" @click="clickContentsMng">
            <i class="icon-pencil"></i>
            <span class="text">콘텐츠관리</span>
          </button>
          <button class="kb-btn kb-btn-secondary kb-btn-sm rounded-lg" @click="clickQuiz">
            <i class="icon-edit"></i>
            <span class="text">퀴즈관리</span>
          </button>
        </div>
      </template>
    </lxp-main-header>
    <!-- main-content -->
    <div class="main-content min-component">
      <!-- learningGroup-header -->
      <MyGroupLearningProfile
          :crse-nm="crseNm"
          :dist-crse-lrn-fld-nm="lrnFldNm"
          dist-crse-cd="group"
          :lrn-goals="lrnGoals"
          :items="items"
      />
      <!-- //learningGroup-header -->

      <!-- learningGroup-body -->
      <div class="learningGroup-body">
        <!-- content-tabs -->
        <div class="content-tabs">
          <nav class="kb-nav-base-tabs">
            <ul class="kb-nav-list">
              <li class="kb-nav-item">
                <a href="javascript:" class="kb-nav-link" :class="{'is-active' : activeMenu === 'groupInfo'}" @click="clickStatusTab('groupInfo')"><span class="kb-nav-text text-lg">그룹현황</span></a>
              </li>
              <li class="kb-nav-item">
                <a href="javascript:" class="kb-nav-link" :class="{'is-active' : activeMenu === 'groupClass'}" @click="clickStatusTab('groupClass')"><span class="kb-nav-text text-lg">그룹모임</span></a>
              </li>
            </ul>
          </nav>
        </div>
        <!-- //content-tabs -->

        <template v-if="activeMenu === 'groupInfo'">
          <!-- content-section:my 그룹 현황 -->
          <GroupStatus :items="items" :is-leader="isLeader" />
        </template>

        <template v-else-if="activeMenu === 'groupClass'">
          <!-- content-section:모임 현황 -->
          <section class="content-section section-status">
            <!-- member-list-wrap -->
            <div class="meeting-list-container">
              <MeetingStatus />
            </div>
            <!-- //member-list-wrap -->
          </section>
        </template>
      </div>
      <!-- //learningGroup-body -->
    </div>
    <!-- //main-content -->
  </main>
  <!-- end::kb-main -->

  <QuizMngModal
      v-if="quizModal"
      v-model="quizModal"
      :quiz-add-fun="quizAdd"
  />

</template>

<script>
import LxpMainHeader from '@/components/common/LxpMainHeader';
import MyGroupLearningProfile from '@/components/prep/group/MyGroupLearningProfile';
import GroupStatus from '@/components/prep/group/GroupStatus';
import MeetingStatus from '@/components/prep/group/MeetingStatus';
import QuizMngModal from '@/components/quiz/QuizMngModal';
import {groupViewSetup} from "@/assets/js/modules/groupLearing/group-view-setup";

export default {
  name: 'GroupView',
  components: {
    QuizMngModal, MeetingStatus, GroupStatus, MyGroupLearningProfile, LxpMainHeader},
  setup : groupViewSetup
};
</script>