<template>
  <LxpMobileHeader>
    <template v-slot:left>
      <div class="util util-back">
        <a href="javascript:" class="util-actions util-actions-back" @click="goBack">
          <i class="icon-history-back"></i>
        </a>
      </div>
    </template>
    <template v-slot:right>
      <div v-if="files.length > 0" class="util util-download">
        <a href="javascript:" class="util-actions util-actions-download" @click="isFileAtchListShow = true">
          첨부파일
          <i class="icon-download"></i>
        </a>
      </div>
    </template>
  </LxpMobileHeader>
    <!-- begin::kb-main -->
    <main class="kb-main" id="kb-reinstatement-support">
      <!-- main-content -->
      <div class="main-content main-component">
        <div class="board-view-container">
          <div class="view-header">
            <div class="title-wrap">
              <h3 class="title">{{ item.postTitle }}</h3>
              <p class="subtitle">
                <span class="text">공지</span>
                <span class="text">{{ getDateFormat('yyyy.MM.dd hh:mm', item.regDt) }} 등록</span>
                <span class="text">{{ item.inqCnt }} 조회</span>
              </p>
            </div>
          </div>
          <div class="view-body">
            <div class="view-content">
              {{ getText(item.postCn) }}
            </div>
          </div>
        </div>
      </div>
      <!-- //main-content -->
    </main>
    <!-- end::kb-main -->
  <TakeMobileFileAtch v-if="isFileAtchListShow" v-model:isFileAtchListShow="isFileAtchListShow" :fileItems="files"/>
</template>

<script>

import {computed, ref} from "vue";
import {useStore} from "vuex";
import {useRoute} from "vue-router/dist/vue-router";
import {ACT_GET_HELP_FILE_ATCH_LIST, ACT_GET_HELP_LIB} from "@/store/modules/help/help";
import {convertToStorageBytes, getItem, getItems, getText, lengthCheck, timestampToDateFormat} from "@/assets/js/util";
import {downloadComAtch} from "@/assets/js/modules/help/help-common";
import LxpMobileHeader from "@/views/layout/lxp/header/mobile/LxpMobileHeader";
import router from "@/router";
import TakeMobileFileAtch from "@/views/pages/take/mobile/TakeMobileFileAtch";

export default {
  name: "TakeLibView",
  components: {TakeMobileFileAtch, LxpMobileHeader},
  setup() {
    const store = useStore();
    const route = useRoute();

    const isFileAtchListShow = ref(false);

    const comPostSn = computed(() => route.params.comPostSn);

    const goBack = () => {
      router.go(-1);
    }

    const item = ref({});
    const files = ref([]);

    const getTakeLib = () => {
      if (route.params.comPostSn > 0) {
        store.dispatch(`help/${ACT_GET_HELP_LIB}`, comPostSn.value
        ).then(res => {
          if (lengthCheck(res)) {
            console.log(res)
            item.value = getItem(res);
            getTakeNoticeFileAtchList();
          }
        }).catch(e => {
          console.error(e);
        })
      }
    };

    const getTakeNoticeFileAtchList = () => {
      store.dispatch(`help/${ACT_GET_HELP_FILE_ATCH_LIST}`, {
        comPostSn: comPostSn.value
      }).then(res => {
        if (lengthCheck(res)) {
          files.value = getItems(res);
        } else {
          files.value = [];
        }
      }).catch(e => {
        console.error(e);
      })
    };

    getTakeLib();

    const getDateFormat = (format, value) => {
      if(value){
        return timestampToDateFormat(value, format ? format : 'yyyy.MM.dd');
      }
      return '-';
    };

    return {
      item,
      files,
      getDateFormat,
      getText,
      convertToStorageBytes,
      downloadComAtch,
      goBack,
      isFileAtchListShow
    }

  }

}
</script>