<template>
  <div v-if="!isMobile" class="popup-container" :class="{ 'is-active' : modelValue }">
    <div class="popup" id="popup-hrdcolud-h0301">
      <div class="popup-inner">
        <header class="popup-header">
          <h3 class="title">{{ title }}</h3>
        </header>
        <div class="popup-content">
          <section class="popup-section">
            <header class="section-header" style="text-align: right;">
              <h4 class="title">페이지 :
                <input v-model="inputPage" @blur="updatePage()" @keyup.enter="updatePage()" placeholder="입력" style="width: 45px;" />
              </h4>
            </header>
            <div class="section-content">
              <div class="segment-box">
                <textarea v-model="param.memo" name="" id="" cols="30" rows="6" class="kb-form-control" placeholder="내용을 입력해 주세요."></textarea>
                <div class="textarea-bottom divider">
                  <div class="bottom-column">
                    <span class="counter">{{ cnSz }} / 1000</span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="popup-buttons">
          <a href="javascript:" class="kb-btn kb-btn-primary kb-btn-lg" @click="insertBookActLog()"><span class="text">저장</span></a>
        </div>
      </div>
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click.stop="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
  </div>
  <template v-else>
    <HrdCloudMobileHeader
        :show-back="true"
        :go-back-func="closeModal"
        :title="title"
    >
      <template v-slot:right>
        <div class="util util-btn">
          <a href="javascript:" class="util-actions util-actions-btn text-primary" @click="insertBookActLog()">저장</a>
        </div>
      </template>
    </HrdCloudMobileHeader>
    <main class="kb-main" id="kb-hrdcloud">
      <div class="main-content main-component">
        <div style="text-align: center; margin-top: 15px; font-weight: bold;">페이지 :
          <input v-model="inputPage" @blur="updatePage()" @keyup.enter="updatePage()" placeholder="입력" style="width: 45px; font-weight: bold;" />
        </div>
        <article class="content-section">
          <div class="kb-form-fields">
            <div class="kb-form-field field-border-0">
              <div class="kb-form-row">
                <label class="kb-form-label"><span class="kb-form-label-text">메모장</span><span class="kb-form-byte-text">{{ cnSz }} / 1000</span>
                </label>
              </div>
              <div class="kb-form-row">
                <textarea v-model="param.memo" name="" class="kb-form-control" placeholder="내용을 입력해 주세요."></textarea>
              </div>
            </div>
          </div>
        </article>
      </div>
    </main>
  </template>
</template>

<script>
import {computed, reactive, ref} from 'vue';
import {closeToggles} from '@/assets/js/ui.init';
import {useAlert} from '@/assets/js/modules/common/alert';
import store from '@/store';
import {ACT_INSERT_BOOK_ACT_LOG} from "@/store/modules/book/book";
import HrdCloudMobileHeader from "@/components/hrdcloud/mobile/HrdCloudMobileHeader";
import navigationUtils from "@/assets/js/navigationUtils";
import {MUT_SHOW_BADGE} from "@/store/modules/ssl/ssl-index";
import {getBadgeList} from "@/assets/js/modules/ssl/ssl-badge";


export default {
  name: 'SSLMyMemoModal',
  components: {HrdCloudMobileHeader},
  props: {
    modelValue: Boolean,
    data: Object
  },
  setup(props, {emit}) {
    const {showMessage} = useAlert();
    const inputPage = ref('');
    const isMobile = navigationUtils.any();

    const title = ref('');

    if (props.data.bookNm == null) {
      title.value = '메모장 (자유도서)';
    } else {
      title.value = '메모장 (' + props.data.bookNm + ')';
    }

    const updatePage = () => {
      // 숫자 아닐때
      if (isNaN(inputPage.value)) {
        showMessage('페이지에는 숫자만 입력 가능합니다.');
        inputPage.value = '';
        return ;
      } else {
        if (inputPage.value > props.data.totalPage) {
          inputPage.value = props.data.totalPage;
        } else if (inputPage.value <= 0) {
          inputPage.value = 0;
        }

        if (inputPage.value === props.data.currentPage) {
          return ;
        }
        inputPage.value = Number(inputPage.value);
        param.page = inputPage.value;
      }
    }

    const param = reactive({
      // bookActLogSn: '',
      socBookSn: props.data.socBookSn,
      lrnTrgtGrpMstSn: props.data.lrnTrgtGrpMstSn,
      lrnTrgtGrpNm: props.data.lrnTrgtGrpNm,
      actTyCdDcd: '2001039',
      actTyNm: '북러닝 메모',
      page: '',
      memo: '',
      prgrsSec: 0,
      bgngDt: '',
      endDt: '',
      deptCd: props.data.deptCd,
      deptNm: props.data.deptNm,
      lrnerNm: props.data.lrnerNm
    });

    const cnSz = computed(() => {
      return (param.memo && param.memo.length ? param.memo.length : 0 );
    });

    const closeModal = () => {
      emit('update:modelValue', false);
    };

    const validate = () => {
      if (!param.memo) {
        showMessage('내용을 입력해주세요.');
        return false;
      }
      if (param.memo.length > 1000) {
        showMessage('내용을 1000자 이하로 작성해주세요.');
        return false;
      }
      return true;
    };

    const insertBookActLog = () => {
      if (validate()) {
        store.dispatch(`book/${ACT_INSERT_BOOK_ACT_LOG}`, param).then(res => {
          if (res && res.bookActLogSn > 0) {
            showMessage('학습 메모 이력이 저장되었습니다.');
            closeModal();
            if(res.badges && res.badges.length > 0){
              store.commit(`ssl/${MUT_SHOW_BADGE}`, res.badges);
              getBadgeList();
            }
          } else {
            showMessage('저장을 실패했습니다.<br> 관리자에게 문의바랍니다.');
          }
        })
      }
    }

    return {
      param, cnSz,
      closeModal, closeToggles, insertBookActLog,
      inputPage,updatePage, isMobile, title
    }
  }
};
</script>