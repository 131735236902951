<template>
  <div class="popup-container" :class="{ 'is-active' : modelValue }">
    <!-- popup -->
    <div class="popup" id="popup-hrdcloud-kbtube">
      <!-- popup > popup-inner -->
      <div class="popup-inner">
        <video class="vjs-matrix video-js vjs-default-skin vjs-big-play-centered"
               ref="videoDom"
               oncontextmenu="return false;"
               playsinline
        ></video>
      </div>
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="$emit('update:modelValue',false)"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, onBeforeUnmount, onMounted, ref} from 'vue';
import {getType, getVideoUrl} from '@/assets/js/util';
import {useStore} from 'vuex';
import videojs from 'video.js';

export default {
  name: 'Preview',
  props: {
    modelValue: Boolean,
    item: Object,
  },
  emits: ['update:modelValue'],
  setup(props) {
    const store = useStore();
    const session = computed(() => store.state.auth.session);
    const videoDom = ref(null), video_instance = ref(null);

    onMounted(() => {
      if (session.value && videoDom.value) {
        const vjsOptions = {
          fluid: false,
          preload: 'metadata',
          autoplay: false,
          crossOrigin: 'anonymous',
          language: 'ko',
          width: 1200,
          height: 675,
          controls: true,
          controlBar: {
            subsCapsButton: false,
            audioTrackButton: false,
          },
          sources: [{
            src: getVideoUrl(session.value.itn, props.item),
            type: getType(getVideoUrl(session.value.itn, props.item))
          }]
        };
        videojs(videoDom.value, vjsOptions,function() {

        });
      }
    });

    onBeforeUnmount(() => {
      // console.log('video disposed onbeforeunmount');
      if (video_instance.value) video_instance.value.dispose();
    });

    return {
      videoDom
    }
  }
};
</script>