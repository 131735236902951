import store from '@/store';
import {
    ACT_GET_FILE_DOWN_HIST_SN,
    ACT_GET_FILE_DOWN_MNG,
} from "@/store/modules/common/common-index";
import {lengthCheck, getItem, winOpen, getItems} from "@/assets/js/util";
import {useAlert} from "@/assets/js/modules/common/alert";
import navigationUtils from "@/assets/js/navigationUtils";

const {showMessage} = useAlert();
let downloading = false;


// fileDownId 정보는 필수. comFileAtchSn 정보가 없는 경우 fileDownId에 매핑된 첫번째 파일을 다운로드 한다.
export const downloadFileDownMng = (fileDownId, comFileAtchSn) => {
    if(!downloading){
        downloading = true;
        store.dispatch(`common/${ACT_GET_FILE_DOWN_HIST_SN}`, {fileDownId, comFileAtchSn}).then((res) => {
            if(lengthCheck(res)){
                if(res.url){
                    // cloud front signed url
                    winOpen(res.url);
                }else{
                    let url = `${process.env.VUE_APP_LXP_URL}`.replace('/app/','/v1/app/filedown/atch/');
                    let isMobile = navigationUtils.any() ? '1' : '0';
                    winOpen( url + getItem(res) + '?isMob=' + isMobile);
                }
            }else{
                showMessage('유효하지 않은 파일입니다. 담당자에게 문의하세요.')
            }
        }).finally(() => {
            setTimeout(() => {downloading = false;}, 500);
        })
    }
}

export const getFileDownMng = (fileDownId, rtnData, callback) => {
    store.dispatch(`common/${ACT_GET_FILE_DOWN_MNG}`, fileDownId).then((res) => {
        if(lengthCheck(res)){
            rtnData.value = getItems(res);
        }else{
            console.log(res);
        }
    }).finally(() => {
        if(callback instanceof Function) callback();
    })
}
