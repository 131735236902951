<template>
  <article class="home-section section-banner">
    <swiper
        class="banner-container"
        :modules="modules"
        :speed="750"
         effect="fade"
        :pagination="{
          el: '.banner-pagination',
          clickable: true
        }"
        :autoplay="{
            delay: 15000,
            disableOnInteraction: false,
            stopOnLast: true,
        }"
        @slideChange="slideChange"
        @swiper="setControlledSwiper"
        :style="slideStyle"
    >
      <swiper-slide v-for="(item, idx) in items" :key="idx" class="banner-item">
        <a href="javascript:" @click="goTo(item)">
          <div class="banner-background">
            <template v-if="item.type === 'video'" >
              <SSLVideo :video-src="getThumbImg(item)"/>
            </template>
            <img v-else :src="getThumbImg(item)" alt="" class="img-fluid" />
          </div>
          <div class="banner-contents">
            <span v-if="item.type === 'video'" class="banner-title">{{item.ntcTitle}}</span>
          </div>
        </a>
      </swiper-slide>
      <div class="banner-pagination swiper-pagination"></div>


      <div v-if="!isMobile" class="banner-buttons">
        <button class="kb-btn-banner-prev" :class="{'swiper-button-disabled': isBeginning}" @click="moveSlide(false)">
          <i class="icon-arrow-prev"></i>
        </button>
        <button class="kb-btn-banner-next" :class="{'swiper-button-disabled': isEnd}" @click="moveSlide(true)">
          <i class="icon-arrow-next"></i>
        </button>
      </div>
    </swiper>
  </article>
</template>
<script>
import {computed, ref} from 'vue';

import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import {Autoplay, Pagination} from 'swiper';
import {useStore} from 'vuex';
import SSLVideo from '@/components/ssl/common/SSLVideo';
import {banerDivCdDcds, banerExpsrLocCdDcds, getBanerList} from '@/assets/js/modules/baner/common-baner';
// import {useRouter} from 'vue-router';
import {goLink} from "@/assets/js/modules/common/common";

export default {
  name: 'SSLEvent',
  components: {
    SSLVideo,
    Swiper,
    SwiperSlide
  },
  setup() {
    // const router = useRouter();
    const store = useStore();
    const isMobile = computed(() => store.state.auth.isMobile);
    const items = ref([]);

    const isBeginning = ref(true);
    const isEnd = ref(false);

    const getType = (thumb) => {
      if(thumb){
        if(thumb.indexOf('.mp4') > -1){
          return 'video';
        }else if(thumb.indexOf('.jpg') > -1 || thumb.indexOf('.png') > -1){
          return 'image';
        }
      }
      return null;
    }

    const getThumbImg = (item) => {
      if(isMobile.value){
        return item.mobiThumb;
      }
      return item.thumb;
    }

    const getEventList = () => {
      getBanerList({
        banerDivCdDcd: banerDivCdDcds.baner,
        banerExpsrLocCdDcd: banerExpsrLocCdDcds.sslMain,
        pageNo: 1, pageSize: 10
      }, items,() => {
        items.value.map(x => ({...x, type: getType(x.thumb)}));
        if(isMobile.value){
          tryGetGripSize();
        }
      });
    }

    const controlledSwiper = ref(null);
    const setControlledSwiper = (swiper) => {
      controlledSwiper.value = swiper;
    };

    const slideHeight = ref(0);
    const slideStyle = computed(() => {
      if(isMobile.value){
        return {'height': `${slideHeight.value}px`}
      }
      return {}
    });

    const tryCount = ref(0);

    const tryGetGripSize = () => {
      if(tryCount.value > 10) {
        slideHeight.value = 0;
        return;
      }
      if(controlledSwiper.value && controlledSwiper.value.slidesSizesGrid && controlledSwiper.value.slidesSizesGrid.length > 0){
        slideHeight.value = controlledSwiper.value.slidesSizesGrid[0];
      }else{
        tryCount.value++;
        setTimeout(() => {
          tryGetGripSize();
        }, 300);
      }
    };

    const moveSlide = (isNext) => {
      if(controlledSwiper.value){
        if(isNext){
          controlledSwiper.value.slideNext();
        }else{
          controlledSwiper.value.slidePrev();
        }
      }
    }

    const slideChange = (swiper) => {
      isBeginning.value = swiper.isBeginning;
      isEnd.value = swiper.isEnd;
    }

    const goTo = (item) => {

      if (item.banerUrl && item.banerUrl.length > 0) {
        goLink(item.banerUrl);
      }
    };

    getEventList();

    return {
      isMobile,
      items,
      isBeginning,
      isEnd,
      slideStyle,
      getThumbImg,
      setControlledSwiper,
      moveSlide,
      slideChange, goTo,
      modules: [Pagination, Autoplay],

    }

  }
}
</script>
