<template>
  <main class="kb-main" id="kb-mypage-support">
    <lxp-main-header :show-breadcrumb="true" :show-title="true">
      <template v-slot:action>
        <SupportStatusRoute
            :view="view"
            @changeView="changeView"
        />
        <HistoryYearSelector
            :use-query-string="false"
            :show-select-all="true"
            :yearCnt="2"
            v-model="yearStr"
            @update:modelValue="changeYear"
        />
      </template>
    </lxp-main-header>

    <div class="main-content min-component">
      <SupportStatusAcademies
          v-if="!$route.query.view || $route.query.view === 'academy'"
          :academies="academies"
          :paging="acadPaging"
          :moreFunc="moreFunc"
          :isLoading="isLoading"
          :detailFunc="showDetail"
      />
      <SupportStatusEvaluation
          v-if="$route.query.view === 'eval'"
          :evals="evals"
          :paging="evalPaging"
          :more-func="moreFunc"
          :isLoading="isLoading"
          :detailFunc="showDetail"
      />
      <SupportStatusSchool
          v-if="$route.query.view === 'school'"
          :schools="schools"
          :officers="officers"
          :schlPaging="schlPaging"
          :ofcrPaging="ofcrPaging"
          :moreFunc="moreFunc"
          :isSchlLoading="isLoading"
          :isOfcrLoading="isSecondLoading"
          :detailFunc="showDetail"
      />
      <SupportStatusLicense
          v-if="$route.query.view === 'license'"
          :licenses="licenses"
          :renews="renews"
          :lcnsPaging="lcnsPaging"
          :rnewPaging="rnewPaging"
          :moreFunc="moreFunc"
          :isLcnsLoading="isLoading"
          :isRnewLoading="isSecondLoading"
          :detailFunc="showDetail"
      />
    </div>
  </main>
  <SupportClassFeeModal
      v-if="acadModal && academy"
      v-model="acadModal"
      :data="academy"
      :reList="reList"
  />
  <SupportClassFee202309Modal
      v-if="acad202309Modal && academy"
      v-model="acad202309Modal"
      :data="academy"
      :reList="reList"
  />
  <SupportEvalFeeModal
      v-if="evalModal && evaluation"
      v-model="evalModal"
      :data="evaluation"
      :reList="reList"
  />
  <SupportTuition202309Modal
      v-if="school202309Modal && school"
      v-model="school202309Modal"
      :data="school"
      :reList="reList"
  />
  <SupportTuitionModal
      v-if="schoolModal && school"
      v-model="schoolModal"
      :data="school"
      :reList="reList"
  />
  <SupportTuitionSubModal
      v-if="officerModal && officer"
      v-model="officerModal"
      :data="officer"
      :reList="reList"
  />
  <SupportLicenseFeeModal
      v-if="licenseModal && license"
      v-model="licenseModal"
      :data="license"
      :reList="reList"
      :jncmpYmd="jncmpYmd"
  />
  <SupportLicenseRenewModal
      v-if="renewModal && renew"
      v-model="renewModal"
      :data="renew"
      :reList="reList"
  />
</template>

<script>
import {onMounted, reactive, ref, watch} from 'vue';
import HistoryYearSelector from '@/components/history/HistoryYearSelector';
import LxpMainHeader from '@/components/common/LxpMainHeader';
import SupportStatusRoute from '@/components/support/SupportStatusRoute';
import SupportStatusAcademies from '@/components/support/SupportStatusAcademies';
import {
  ACT_GET_LEARN_SUPPORT_ACADEMY_LIST,
  ACT_GET_LEARN_SUPPORT_EVALUATION_LIST,
  ACT_GET_LEARN_SUPPORT_LICENSE_LIST,
  ACT_GET_LEARN_SUPPORT_OFFICER_LIST,
  ACT_GET_LEARN_SUPPORT_SCHOOL_LIST,
  ACT_GET_LRN_SPRT_LCSN_RENEW_HISTORY_LIST,
} from '@/store/modules/support/support';
import {getListMoreFunc} from '@/assets/js/ui.init';
import {ymdStrToFormat} from "@/assets/js/util";
import SupportStatusEvaluation from '@/components/support/SupportStatusEvaluation';
import SupportStatusSchool from '@/components/support/SupportStatusSchool';
import SupportStatusLicense from '@/components/support/SupportStatusLicense';
import SupportClassFeeModal from '@/components/support/SupportClassFeeModal';
import SupportClassFee202309Modal from '@/components/support/SupportClassFee202309Modal';
import {
  getAcademy,
  getEvaluation,
  getLicenseApplication,
  getOfficer,
  getSchool
} from '@/assets/js/modules/support/support-common';
import SupportEvalFeeModal from '@/components/support/SupportEvalFeeModal';
import SupportTuitionModal from '@/components/support/SupportTuitionModal';
import SupportTuition202309Modal from '@/components/support/SupportTuition202309Modal';
import SupportTuitionSubModal from '@/components/support/SupportTuitionSubModal';
import SupportLicenseFeeModal from '@/components/support/SupportLicenseFeeModal';
import SupportLicenseRenewModal from '@/components/support/SupportLicenseRenewModal';
import {useRoute} from "vue-router";
import {useStore} from "vuex";
import {ACT_GET_JNCMP_YMD} from "../../../store/modules/support/support";

export default {
  name: 'SupportStatusMain',
  components: {
    SupportLicenseRenewModal,
    SupportLicenseFeeModal,
    SupportTuitionSubModal,
    SupportTuitionModal,
    SupportTuition202309Modal,
    SupportEvalFeeModal,
    SupportClassFeeModal,
    SupportClassFee202309Modal,
    SupportStatusLicense,
    SupportStatusSchool,
    SupportStatusEvaluation, SupportStatusAcademies, SupportStatusRoute, LxpMainHeader, HistoryYearSelector},
  setup() {
    const isLoading = ref(false), isSecondLoading = ref(false);
    const yearStr = ref(''), view = ref('academy');
    const route = useRoute();
    const store = useStore();

    if(!route.query.view) route.query.view = 'academy';

    const acadModal = ref(false),
          acad202309Modal = ref(false),
          evalModal = ref(false),
          schoolModal = ref(false),
          school202309Modal = ref(false),
          officerModal = ref(false),
          licenseModal = ref(false),
          renewModal = ref(false);

    const academies = ref([]), // 학원비
          evals = ref([]),   // 평가
          schools = ref([]),   // 학비
          officers = ref([]),// 부점장
          licenses = ref([]),  // 자격증
          renews = ref([]),  // 자격증갱신
          etcs = ref([]);    // 기타비용

    const academy = ref(null),
          evaluation = ref(null),
          school = ref(null),
          officer = ref(null),
          license = ref(null),
          renew = ref(null);

    const acadPaging = reactive({ pageNo: 1, pageSize: 10, totalCount: 0, hasMore: false }),
          evalPaging = reactive({ pageNo: 1, pageSize: 10, totalCount: 0, hasMore: false }),
          schlPaging = reactive({ pageNo: 1, pageSize: 10, totalCount: 0, hasMore: false }),
          ofcrPaging = reactive({ pageNo: 1, pageSize: 10, totalCount: 0, hasMore: false }),
          lcnsPaging = reactive({ pageNo: 1, pageSize: 10, totalCount: 0, hasMore: false }),
          rnewPaging = reactive({ pageNo: 1, pageSize: 10, totalCount: 0, hasMore: false });

    const getAcads = (isInit) => {getListMoreFunc(`support/${ACT_GET_LEARN_SUPPORT_ACADEMY_LIST}`, {rgtrYear : yearStr.value, ...acadPaging}, academies, acadPaging, isLoading, isInit)};
    const getEvals = (isInit) => {getListMoreFunc(`support/${ACT_GET_LEARN_SUPPORT_EVALUATION_LIST}`, {rgtrYear : yearStr.value, ...evalPaging}, evals, evalPaging, isLoading, isInit)};
    const getSchls = (isInit) => {getListMoreFunc(`support/${ACT_GET_LEARN_SUPPORT_SCHOOL_LIST}`, {rgtrYear : yearStr.value, ...schlPaging}, schools, schlPaging, isLoading, isInit)};
    const getOfcrs = (isInit) => {getListMoreFunc(`support/${ACT_GET_LEARN_SUPPORT_OFFICER_LIST}`, {rgtrYear : yearStr.value, ...ofcrPaging}, officers, ofcrPaging, isSecondLoading, isInit)};
    const getLcnss = (isInit) => {getListMoreFunc(`support/${ACT_GET_LEARN_SUPPORT_LICENSE_LIST}`, {rgtrYear : yearStr.value, ...lcnsPaging}, licenses, lcnsPaging, isLoading, isInit)};
    const getRenew = (isInit) => {getListMoreFunc(`support/${ACT_GET_LRN_SPRT_LCSN_RENEW_HISTORY_LIST}`, {rgtrYear : yearStr.value, regYn:'N',...rnewPaging}, renews, rnewPaging, isSecondLoading, isInit)};
    const getSupportList = (isInit) => {
      if (view.value === 'academy') {
        if(isInit) {
          acadPaging.pageNo=1;
          acadPaging.pageSize=10;
        }
        getAcads(isInit);
      } else if (view.value === 'eval') {
        if(isInit) {
          evalPaging.pageNo=1;
          evalPaging.pageSize=10;
        }
        getEvals(isInit);
      } else if (view.value === 'school') {
        if(isInit) {
          schlPaging.pageNo=1;
          schlPaging.pageSize=10;
        }
        getSchls(isInit);
        getOfcrs(isInit);
      } else if (view.value === 'license') {
        if(isInit) {
          lcnsPaging.pageNo=1;
          lcnsPaging.pageSize=10;
        }
        getLcnss(isInit);
        getRenew(isInit);
      }
    };

    const moreFunc = (paging) => {
      paging.pageNo++;
      if (view.value) {
        if (view.value === 'officer') getOfcrs();
        else if (view.value === 'renew') getRenew();
        else if (view.value === 'academy') getAcads();
        else if (view.value === 'eval') getEvals();
        else if (view.value === 'school') getSchls();
      } else {
        getLcnss();
      }
    };

    const changeView = (change) => {
      if (view.value === 'academy' || view.value === 'eval') {
        isLoading.value = true;
      } else if (view.value === 'school' || view.value === 'license') {
        isLoading.value = true;
        isSecondLoading.value = true;
      }
      view.value = change;
      getSupportList(true);
    };

    const changeYear = () => {
      if (view.value === 'academy' || view.value === 'eval') {
        isLoading.value = true;
      } else if (view.value === 'school' || view.value === 'license') {
        isLoading.value = true;
        isSecondLoading.value = true;
      }
      getSupportList(true);
    };

    const showDetail = (type, key) => {
      if (key != null) {
        if (type === 'academy_202309') {
          getAcademy(key, academy, acad202309Modal);
        } else if (type === 'academy') {
          getAcademy(key, academy, acadModal);
        } else if (type === 'evaluation') {
          getEvaluation(key, evaluation, evalModal);
        } else if (type === 'school') {
          getSchool(key, school, schoolModal, school202309Modal);
        } else if (type === 'officer') {
          getOfficer(key, officer, officerModal);
        } else if (type === 'license') {
          getLicenseApplication(key, license, licenseModal);
        } else if (type === 'renew') {
          getLicenseApplication(key, renew, renewModal);
        }
      }
    };

    const reList = () => {
      getSupportList(true);
    }
    const jncmpYmd = ref('');
    const getJncmpYmd=() => {
      store.dispatch(`support/${ACT_GET_JNCMP_YMD}`).then(res=>{
          jncmpYmd.value = ymdStrToFormat(res.jncmpYmd, "-");
      });
    };
    getJncmpYmd();

    onMounted(() => {
      isLoading.value = true;
      if (view.value === 'school' || view.value === 'license') isSecondLoading.value = true;
      if (route.query.view) {
        changeView(route.query.view);
      }
    });

    watch(() => route.query, () =>{
      if(route.name === 'SupportStatus') {
        if(route.query.view === 'academy') {
          changeView('academy');
        }else if(route.query.view === 'eval') {
          changeView('eval');
        }else if(route.query.view === 'school') {
          changeView('school');
        }else if(route.query.view === 'license') {
          changeView('license');
        }
      }
    });

    return {
      isLoading, isSecondLoading, view, yearStr,
      academies, evals, schools, officers, licenses, renews, etcs,
      acadPaging, evalPaging, schlPaging, ofcrPaging, lcnsPaging, rnewPaging,
      acadModal, acad202309Modal, evalModal, schoolModal, school202309Modal, officerModal, licenseModal, renewModal,
      academy, evaluation, school, officer, license, renew,
      changeView, moreFunc, changeYear, showDetail, reList, jncmpYmd
    }
  }
};
</script>