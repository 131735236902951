<template>
  <div class="chart-star">
    <div class="star-bg"></div>
    <div class="star-active"></div>
  </div>
</template>
<script>

export default {
  name: 'SSLStarGraph',
  setup() {}
}
</script>
