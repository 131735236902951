<template>
  <!-- begin::kb-main -->
  <LxpMobileHeader title="비용신청 현황조회">
    <template v-slot:left>
      <div class="util util-back">
        <a href="javascript:" class="util-actions util-actions-back" @click="goBack">
          <i class="icon-history-back"></i>
        </a>
      </div>
    </template>
    <template v-slot:right>
      <!--        <div v-if="guideFile.length > 0" class="util util-download">
                <a href="javascript:" class="util-actions util-actions-download" @click="isFileAtchListShow = true">
                  첨부파일
                  <i class="icon-download"></i>
                </a>
              </div>-->
    </template>
  </LxpMobileHeader>
  <main v-if="isMainActive" class="kb-main" id="kb-mypage">
    <!-- main-header -->
    <div class="main-header main-header-visual">
      <div class="header-snb">
        <nav class="snb">
          <ul class="snb-list">
            <li class="snb-item date">
              <SortButtonSelect3
                  v-model="yearStr"
                  v-model:toggle="toggles.year"
                  title="년도"
                  :options="yearOptions"
                  sequence-key="value"
                  name-key="display"
                  :is-num="true"
                  @update:toggle="closeToggles(toggles, 'year')"
                  @selected="pagingFunc(1)"
              />
            </li>
            <li v-for="(viewRoute, index) in viewRoutes" class="snb-item" :key="index">
              <button :class="{ 'is-active' : viewRoute.view === view }" @click="changeView(viewRoute.view)">{{viewRoute.name}}</button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <!-- main-content -->
    <div class="main-content main-component">
      <div class="view-contents">
        <SupportStatusMobileAcademies
            v-if="view === 'academy'"
            :academies="academies"
            :paging="acadPaging"
            :moreFunc="moreFunc"
            :isLoading="isLoading"
            :detailFunc="showDetail"
        />
        <SupportStatusMobileEvaluation
            v-else-if="view === 'eval'"
            :evals="evals"
            :paging="evalPaging"
            :more-func="moreFunc"
            :isLoading="isLoading"
            :detailFunc="showDetail"
        />
        <SupportStatusMobileSchool
            v-else-if="view === 'school'"
            :schools="schools"
            :officers="officers"
            :schlPaging="schlPaging"
            :ofcrPaging="ofcrPaging"
            :moreFunc="moreFunc"
            :isSchlLoading="isLoading"
            :isOfcrLoading="isSecondLoading"
            :detailFunc="showDetail"
            :mode-func="modeFunc"
        />
        <SupportStatusMobileLicense
            v-else-if="view === 'license'"
            :licenses="licenses"
            :renews="renews"
            :lcnsPaging="lcnsPaging"
            :rnewPaging="rnewPaging"
            :moreFunc="moreFunc"
            :isLcnsLoading="isLoading"
            :isRnewLoading="isSecondLoading"
            :detailFunc="showDetail"
            :mode-func="modeFunc"
        />
      </div>
    </div>
    <!-- //main-content -->
  </main>

  <SupportClassFeeMobileModal
      v-if="view === 'academy' && !isMainActive"
      :data="res"
  />
  <SupportClassFee202309MobileModal
      v-if="view === 'academy_202309' && !isMainActive"
      :data="res"
  />
  <SupportEvalFeeMobileModal
      v-if="view === 'eval' && !isMainActive"
      :data="res"
  />
  <SupportTuitionMobileModal
      v-if="(view === 'school' && mode === 'schl') && !isMainActive"
      v-model="isSchool"
      :data="res"
      :reList="schoolReList"
  />
  <SupportTuition202309MobileModal
      v-if="(view === 'school_202309' && mode === 'schl') && !isMainActive"
      v-model="isSchool"
      :data="res"
      :reList="schoolReList"
  />
  <SupportTuitionSubMobileModal
      v-if="(view === 'school' && mode === 'officer') && !isMainActive"
      v-model="isOfcSchool"
      :data="res"
  />
  <SupportStatusMobileLicenseModal
      v-if="(view === 'license' && mode === 'license') && !isMainActive"
      :data="res"
      mode="license"
  />
  <SupportLicenseRenewMobileModal
      v-if="(view === 'license' && mode === 'renewalHistory') && !isMainActive"
      :data="res"
      mode="renewalHistory"
  />

  <!-- end::kb-main -->
  <SupportMobileFileAtch v-if="isFileAtchListShow" v-model:isFileAtchListShow="isFileAtchListShow" :file-key="guideFile"/>

</template>

<script>
import {computed, onMounted, reactive, ref, watch} from 'vue';
import {closeToggles, getListMoreFunc} from '@/assets/js/ui.init';
import LxpMobileHeader from "@/views/layout/lxp/header/mobile/LxpMobileHeader";
import {downloadGuideFiles, getFuncRef, supportMenu} from '@/assets/js/modules/support/support-common';
import SupportMobileFileAtch from "@/views/pages/support/mobile/SupportMobileFileAtch";
import SupportStatusMobileAcademies from "@/components/support/mobile/SupportStatusMobileAcademies";
import SupportStatusMobileEvaluation from "@/components/support/mobile/SupportStatusMobileEvaluation";
import SupportStatusMobileSchool from "@/components/support/mobile/SupportStatusMobileSchool";
import SupportStatusMobileLicense from "@/components/support/mobile/SupportStatusMobileLicense";
import {
  ACT_GET_LEARN_SUPPORT_ACADEMY,
  ACT_GET_LEARN_SUPPORT_ACADEMY_LIST,
  ACT_GET_LEARN_SUPPORT_EVALUATION,
  ACT_GET_LEARN_SUPPORT_EVALUATION_LIST,
  ACT_GET_LEARN_SUPPORT_LICENSE,
  ACT_GET_LEARN_SUPPORT_LICENSE_LIST,
  ACT_GET_LEARN_SUPPORT_OFFICER,
  ACT_GET_LEARN_SUPPORT_OFFICER_LIST,
  ACT_GET_LEARN_SUPPORT_SCHOOL,
  ACT_GET_LEARN_SUPPORT_SCHOOL_LIST,
  ACT_GET_LRN_SPRT_LCSN_RENEW_HISTORY_LIST,
  MUT_CLOSE_MAIN,
  MUT_SHOW_MAIN,
} from "@/store/modules/support/support";
import {
  getCheckItem,
  goToTop,
  lengthCheck,
  setYmdObject,
  timestampToDateFormat,
  ymdStrToDateFormat
} from "@/assets/js/util";
import SortButtonSelect3 from "@/components/common/SortButtonSelect3";
import {useStore} from "vuex";
import SupportClassFeeMobileModal from "@/components/support/mobile/SupportClassFeeMobileModal";
import SupportClassFee202309MobileModal from "@/components/support/mobile/SupportClassFee202309MobileModal";
import {useRouter} from "vue-router";
import SupportEvalFeeMobileModal from "@/components/support/mobile/SupportEvalFeeMobileModal";
import SupportStatusMobileLicenseModal from "@/components/support/mobile/SupportStatusMobileLicenseModal";
import SupportTuitionMobileModal from "@/components/support/mobile/SupportTuitionMobileModal";
import SupportTuition202309MobileModal from "@/components/support/mobile/SupportTuition202309MobileModal";
import SupportTuitionSubMobileModal from "@/components/support/mobile/SupportTuitionSubMobileModal";
import SupportLicenseRenewMobileModal from "@/components/support/mobile/SupportLicenseRenewMobileModal";

export default {
  name: 'SupportStatusMobileMain',
  components: {
    SupportLicenseRenewMobileModal,
    SupportTuitionSubMobileModal,
    SupportTuitionMobileModal,
    SupportTuition202309MobileModal,
    SupportStatusMobileLicenseModal,
    SupportEvalFeeMobileModal,
    SupportClassFeeMobileModal,
    SupportClassFee202309MobileModal,
    SortButtonSelect3,
    SupportStatusMobileLicense,
    SupportStatusMobileSchool,
    SupportStatusMobileEvaluation,
    SupportStatusMobileAcademies,
    SupportMobileFileAtch,
    LxpMobileHeader,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const guideFile = computed(() => downloadGuideFiles.find(x => x.page === 'license').fileKey);
    const isFileAtchListShow = ref(false);
    const viewRoutes = computed(() => {
      const menu = supportMenu;
      return menu;
    });
    const res = ref([]);
    const isMainActive = computed(() => store.state.support.main.show);

    const isLoading = ref(false), isSecondLoading = ref(false);
    const currentDate = new Date();
    const yearStr = ref(''),
        view = ref('academy');

    const isSchool = ref(false);
    const isOfcSchool = ref(false);


    const toggles = reactive({
      year: false,
    });

    const mode = ref('license');

    const modeFunc = (type) => {
      mode.value = type;
    }

    const yearOptions = (() => {
      const options = [];
      options.push({value: '', display: `전체`});
      for(let i = currentDate.getFullYear(); i >= 1978 ; i--){
        options.push({value: i, display: `${i}년`});
      }
      return options;
    })();

    const pagingFunc = (pageNo) => {
      acadPaging.pageNo = pageNo;
      evalPaging.pageNo = pageNo;
      schlPaging.pageNo = pageNo;
      ofcrPaging.pageNo = pageNo;
      lcnsPaging.pageNo = pageNo;
      rnewPaging.pageNo = pageNo;

      getSupportList(true);
    }

    const acadModal = ref(false),
        acad202309Modal = ref(false),
        evalModal = ref(false),
        schoolModal = ref(false),
        officerModal = ref(false),
        licenseModal = ref(false),
        renewModal = ref(false);

    const academies = ref([]), // 학원비
        evals = ref([]),   // 평가
        schools = ref([]),   // 학비
        officers = ref([]),// 부점장
        licenses = ref([]),  // 자격증
        renews = ref([]),  // 자격증갱신
        etcs = ref([]);    // 기타비용

    const academy = ref(null),
        evaluation = ref(null),
        school = ref(null),
        officer = ref(null),
        license = ref(null),
        renew = ref(null);

    const acadPaging = reactive({ pageNo: 1, pageSize: 5, totalCount: 0, hasMore: false }),
        evalPaging = reactive({ pageNo: 1, pageSize: 10, totalCount: 0, hasMore: false }),
        schlPaging = reactive({ pageNo: 1, pageSize: 10, totalCount: 0, hasMore: false }),
        ofcrPaging = reactive({ pageNo: 1, pageSize: 10, totalCount: 0, hasMore: false }),
        lcnsPaging = reactive({ pageNo: 1, pageSize: 10, totalCount: 0, hasMore: false }),
        rnewPaging = reactive({ pageNo: 1, pageSize: 10, totalCount: 0, hasMore: false });

    const schoolReList = () => {
      view.value = 'school';
      store.commit(`support/${MUT_SHOW_MAIN}`);
    }

    const getAcads = (isInit) => {getListMoreFunc(`support/${ACT_GET_LEARN_SUPPORT_ACADEMY_LIST}`, {rgtrYear : yearStr.value, ...acadPaging}, academies, acadPaging, isLoading, isInit)};
    const getEvals = (isInit) => {getListMoreFunc(`support/${ACT_GET_LEARN_SUPPORT_EVALUATION_LIST}`, {rgtrYear : yearStr.value, ...evalPaging}, evals, evalPaging, isLoading, isInit, () => {})};
    const getSchls = (isInit) => {getListMoreFunc(`support/${ACT_GET_LEARN_SUPPORT_SCHOOL_LIST}`, {rgtrYear : yearStr.value, ...schlPaging}, schools, schlPaging, isLoading, isInit)};
    const getOfcrs = (isInit) => {getListMoreFunc(`support/${ACT_GET_LEARN_SUPPORT_OFFICER_LIST}`, {rgtrYear : yearStr.value, ...ofcrPaging}, officers, ofcrPaging, isSecondLoading, isInit)};
    const getLcnss = (isInit) => {getListMoreFunc(`support/${ACT_GET_LEARN_SUPPORT_LICENSE_LIST}`, {rgtrYear : yearStr.value, ...lcnsPaging}, licenses, lcnsPaging, isLoading, isInit,() => {
      licenses.value.forEach(x => {
        if(x.passAnnonYmd){
          x.passYmd = ymdStrToDateFormat(x.passAnnonYmd, 'yyyy.MM.dd');
        }else if(x.lcsnExamYmd){
          x.passYmd = ymdStrToDateFormat(x.lcsnExamYmd, 'yyyy.MM.dd');
        }else if(x.lcsnRegYmd){
          x.passYmd = ymdStrToDateFormat(x.lcsnRegYmd, 'yyyy.MM.dd');
        }else{
          x.passYmd = x.regDt ? timestampToDateFormat(x.regDt,'yyyy.MM.dd') : '-';
        }
      })
    })};

    const getAcademyDetail = (costAcdmFeeSn) => {
      getFuncRef(`support/${ACT_GET_LEARN_SUPPORT_ACADEMY}`,{costAcdmFeeSn: costAcdmFeeSn}, null, response => {
        if (lengthCheck(response)) {
          res.value = response.items.map(x => {
            x.cardAprvDt = timestampToDateFormat(x.cardAprvDt, 'yyyy-MM-dd');
            x.lrnBgngDd = setYmdObject(x.lrnBgngDd);
            x.lrnEndDd = setYmdObject(x.lrnEndDd);
            return x;
          })[0];
          store.commit(`support/${MUT_CLOSE_MAIN}`);
        }
      });
    };

    const getEvaluationDetail = (costEvlSn) => {
      getFuncRef(`support/${ACT_GET_LEARN_SUPPORT_EVALUATION}`,{costEvlSn: costEvlSn}, null, response => {
        if (lengthCheck(response)) {
          res.value = response.items.map(x => {
            x.cardAprvDt = timestampToDateFormat(x.cardAprvDt, 'yyyy-MM-dd');
            return x;
          })[0];
        }
        store.commit(`support/${MUT_CLOSE_MAIN}`);
      });
    };

    const getSchoolDetail = (costTuitFeeSn) => {
      getFuncRef(`support/${ACT_GET_LEARN_SUPPORT_SCHOOL}`,{costTuitFeeSn: costTuitFeeSn}, null, response => {
        isSchool.value = true;
        res.value = getCheckItem(response);
        store.commit(`support/${MUT_CLOSE_MAIN}`);
        modeFunc('schl');
      });
    };

    const getOfficerDetail = (costOffcrSn) => {
      getFuncRef(`support/${ACT_GET_LEARN_SUPPORT_OFFICER}`,{costOffcrSn: costOffcrSn}, null, response => {
        isOfcSchool.value = true;
        res.value = getCheckItem(response);
        store.commit(`support/${MUT_CLOSE_MAIN}`);
        modeFunc('officer');
      });
    };

    const getLicenseApplicationDetail = (costLcsnSn) => {
      getFuncRef(`support/${ACT_GET_LEARN_SUPPORT_LICENSE}`,{costLcsnSn: costLcsnSn}, null, response => {
        res.value = getCheckItem(response);
        store.commit(`support/${MUT_CLOSE_MAIN}`);
      });
    };

    const getRenew = (isInit) => {getListMoreFunc(`support/${ACT_GET_LRN_SPRT_LCSN_RENEW_HISTORY_LIST}`, {rgtrYear : yearStr.value, ...rnewPaging}, renews, rnewPaging, isSecondLoading, isInit)};
    // const getRenew = (isInit) => {getListMoreFunc(`support/${ACT_GET_LEARN_SUPPORT_LICENSE_RENEWAL_LIST}`, {rgtrYear : yearStr.value, ...rnewPaging}, renews, rnewPaging, isSecondLoading, isInit)};

    const getSupportList = (isInit) => {

      if (view.value === 'academy' || view.value === 'academy_202309') {
        if(isInit) {
          acadPaging.pageNo=1;
          acadPaging.pageSize=50;
        }
        getAcads(isInit);
      } else if (view.value === 'eval') {
        if(isInit) {
          evalPaging.pageNo=1;
          evalPaging.pageSize=50;
        }
        getEvals(isInit);
      } else if (view.value === 'school') {
        if(isInit) {
          schlPaging.pageNo=1;
          schlPaging.pageSize=50;
        }
        getSchls(isInit);
        getOfcrs(isInit);
      } else if (view.value === 'license') {
        if(isInit) {
          lcnsPaging.pageNo=1;
          lcnsPaging.pageSize=50;
        }
        getLcnss(isInit);
        getRenew(isInit);
      }
    };

    const moreFunc = (paging, division) => {
      paging.pageNo++;
      if (division) {
        if (division === 'officer') getOfcrs();
        else if (division === 'renewalHistory') getRenew();
      } else {
        getSupportList();
      }
    };

    getSupportList();

    const changeView = (change) => {
      if (view.value === 'academy' || view.value === 'academy_202309' || view.value === 'eval') {
          isLoading.value = true;
      } else if (view.value === 'school' || view.value === 'license') {
          isLoading.value = true;
          isSecondLoading.value = true;
      }
      view.value = change;
      getSupportList(true);
    };

    const changeYear = () => {
      if (view.value === 'academy' || view.value === 'academy_202309' || view.value === 'eval') {
        isLoading.value = true;
      } else if (view.value === 'school' || view.value === 'license') {
        isLoading.value = true;
        isSecondLoading.value = true;
      }
      getSupportList(true);
    };

    const showDetail = (type, key) => {
      if (key != null) {
        if (type === 'academy' || type === 'academy_202309') {
          view.value = type;
          getAcademyDetail(key);
        } else if (type === 'eval') {
          getEvaluationDetail(key);
        } else if (type === 'school' || type === 'school_202309') {
          view.value = type;
          getSchoolDetail(key);
        } else if (type === 'officer') {
          getOfficerDetail(key);
        } else if (type === 'license' || type === 'renewalHistory') {
          getLicenseApplicationDetail(key);
        }
      }
    };

    const reList = () => {
      getSupportList(true);
    }

    const goBack = () => {
      if(! store.state.support.main.show) {
        store.commit(`support/${MUT_SHOW_MAIN}`);
      } else {
        router.go(-1);
      }
    }

    watch(() => isMainActive.value, () => {
      goToTop('app', 100);
      getSupportList(true);
    })

    onMounted(() => {
      store.commit(`support/${MUT_SHOW_MAIN}`);
      isLoading.value = true;
      if (view.value === 'school' || view.value === 'license') isSecondLoading.value = true;
    });

    return {
      isLoading, isSecondLoading, view, yearStr, viewRoutes,
      guideFile,
      isFileAtchListShow,
      academies, evals, schools, officers, licenses, renews, etcs,goBack,
      acadPaging, evalPaging, schlPaging, ofcrPaging, lcnsPaging, rnewPaging,
      acadModal, acad202309Modal, evalModal, schoolModal, officerModal, licenseModal, renewModal,
      academy, evaluation, school, officer, license, renew,toggles,pagingFunc,yearOptions,closeToggles,
      changeView, moreFunc, changeYear, showDetail, reList, isMainActive,res,
      modeFunc, mode, isSchool, schoolReList, isOfcSchool

    }
  }
};
</script>