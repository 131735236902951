<template>
  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-support">
    <!-- main-content -->
    <div class="main-content main-component">
      <div class="kb-form-layer is-active">
        <div class="layer-container" @click="closeLayer">
          <div class="layer">
            <div class="kb-form-layer-options">
              <div class="layer-option">
                <ul class="layer-option-list">
                  <li class="layer-option-item">
                    <file-down-link :file-key="fileKey" :show-file-nm="true" :show-icon="false" :add-link-class="['layer-option-item']" :add-text-class="['layer-option-text']" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //main-content -->
  </main>
</template>

<script>
import FileDownLink from '@/components/common/FileDownLink';

export default {
  name: "SupportMobileFileAtch",
  props:{
    isFileAtchListShow: Boolean,
    fileKey: String,
  },
  components: {FileDownLink},
  emits: ['update:isFileAtchListShow'],
  setup(props, {emit}){

    const closeLayer = () => {
      emit('update:isFileAtchListShow', false)
    }

    return{
      closeLayer,
    }
  }
}
</script>

<style scoped>

</style>