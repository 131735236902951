<template>
  <nav class="footer-menu" id="footer-menu" :class="{'is-active' : modelValue }">
    <ul class="menu-list">
      <li v-if="isDev" class="menu-item" id="fnb-menu-0">
        <router-link to="/mockup" class="menu-link" id="fnb-menu-link-0">모바일앱 Mockup</router-link>
      </li>
      <template v-for="(menu, idx) in menus" :key="idx">
        <li v-if="!exclusiveNames.includes(menu.menuNm)" class="menu-item" :class="{ 'is-active' : toggleIdx === idx }">
<!--          <template v-if="menu.menuNm !== '알림'">-->
            <a href="javascript:" class="menu-link" @click="toggle(idx)">{{ menu.menuNm }}<i class="icon-menu-arrow"></i></a>
            <ul v-if="menu.children.length > 0" class="sub-list">
              <template v-for="(child, i) in menu.children" :key="i">
                <li v-if="child.menuNm === 'Talent Coaching Board'" :class="{'sub-item' : session.vice}">
                  <router-link v-if="session.vice" :to="child.menuUrl" class="sub-link" @click="toggleMenu">{{ child.menuNm }}</router-link>
                </li>
<!--                <li v-else-if="child.menuUrl === '/ssl/home' || child.menuUrl === '/prep/group'" class="sub-item">-->
<!--&lt;!&ndash;                <li v-else-if="child.menuNm === 'SSL' || child.menuNm === '그룹러닝'" class="sub-item">&ndash;&gt;-->
<!--                  <a href="javascript:;" class="sub-link" @click="showMessage('학습기간이 아닙니다.');toggleMenu">{{ child.menuNm }}</a>-->
<!--                </li>-->
                <li v-else class="sub-item">
                  <template v-if="child.menuUrl">
                    <a v-if="isTempMenu(child)" href="javascript:" class="sub-link" @click="toggleMenu">{{ child.menuNm }}</a>
                    <template v-else>
                      <a v-if="(isDev? devAsisMoveLinkMenus : prdAsisMoveLinkMenus).includes(child.menuUrl)" class="sub-link" @click="goMenu(child.menuUrl)">{{ child.menuNm }}</a>
                      <router-link v-else :to="child.menuUrl" class="sub-link" @click="toggleMenu">{{ child.menuNm }}</router-link>
                    </template>
                  </template>
                  <template v-else>
                    <a href="javascript:;" class="sub-link" @click="toggleMenu">{{ child.menuNm }}</a>
                  </template>
                </li>
              </template>
            </ul>
<!--          </template>-->
        </li>
      </template>
      <li class="menu-item">
        <a href="javascript:" class="menu-link" id="fnb-menu-link-11"  @click="clickLogout">로그아웃</a>
      </li>
    </ul>
  </nav>
</template>

<script>
import {setLogout} from '@/assets/js/modules/auth/auth-common';
import {goToSignin} from '@/assets/js/modules/common/auth';
import {
  customPageMove,
  devAsisMoveLinkMenus,
  isTempMenu,
  prdAsisMoveLinkMenus
} from '@/assets/js/modules/common/common';
import {computed, ref} from 'vue';
import {useStore} from 'vuex';
import {useAlert} from "@/assets/js/modules/common/alert";

export default {
  name: 'LxpMobileFooterMenu',
  props: {
    modelValue: Boolean,
    toggleMenu: Function
  },
  setup() {
    const store = useStore();
    const {showMessage} = useAlert();
    const menus = computed(() => store.state.menu.menus);
    const toggleIdx = ref(-1);

    const session = computed(() => store.state.auth.session);

    const isDev = (process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development' )
    const exclusiveNames = ['알림'];

    const toggle = (idx) => {
      if (toggleIdx.value !== idx) toggleIdx.value = idx
      else toggleIdx.value = -1;
    };


    const clickLogout = async () => {
      await setLogout(true);
      goToSignin();
    };

    const goMenu = (url) => {
      console.log('url : ' + url);
      customPageMove(url);
    }

    return {
      menus, toggleIdx,
      exclusiveNames,
      toggle, clickLogout,
      isTempMenu, goMenu, prdAsisMoveLinkMenus, devAsisMoveLinkMenus,
      session, isDev, showMessage
    }
  }
};
</script>
