import axios from 'axios';
import VueAxios from 'vue-axios';
import sessionStorageService from '@/assets/js/localstorage.service';
import {TOKEN_KEY} from '@/store/modules/auth/auth';
import {useAlert} from '@/assets/js/modules/common/alert';
import {goToExpired} from '@/assets/js/modules/common/auth';
import {isInApp} from '@/assets/js/navigationUtils';

const {showMessage} = useAlert();

const expiredSession = (e) => {
  if (e && e.response && e.response.status) {
    if (e.response.status === 403) {
      // showMessage('세션이 만료되었습니다.');
      // goToSignin();
      goToExpired();
    } else if (e.response.status === 500){
      showMessage('서버처리 중 일시적인 장애가 발생하였습니다.<br>잠시 후 다시 시도 해 주시기 바랍니다.');
    }
  }
}

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  vueInstance: null,
  init(app) {
    app.use(VueAxios, axios);
    this.vueInstance = app;
    this.setInterceptor();
  },

  setInterceptor() {

    this.vueInstance.axios.interceptors.request.use(function (request) {
      const headers = request.headers;
      if(sessionStorageService.getToken(TOKEN_KEY)) headers[TOKEN_KEY] = sessionStorageService.getToken(TOKEN_KEY);
      request.headers = headers;
      return request;
    }, function (error) {
      return Promise.reject(error);
    });

    this.vueInstance.axios.interceptors.response.use(function (response) {
      //if( response && response.data && response.data.status &&
      //    response.data.data && response.data.data.resultType = "CommonDataResultCodes" &&
      //  && response.data.data.result.code == "UNAUTHORIZED" ) {
      // } else { goToSignin();  alert('세션이 만료되었습니다.'); } else
      // 403 없이 200 으로 세션 말료 일 경우 사용

      if(response && response.data && response.data.status){
        response.data.data.status = response.data.status;
        return response.data.data;
      }else if(response.data){
        return response.data;
      }else{
        return response;
      }
    }, function (error) {
      return Promise.reject(error);
    });
  },

  /**
   * Set the default HTTP request headers
   */
  query(resource, params, isIgnored) {
    return this.vueInstance.axios.get(resource, {params: params}).catch(e => {
      if (!isIgnored) expiredSession(e);
    });
    // .catch(error => {
    //   throw new Error(`[KT] ApiService ${error}`);
    // });
  },
  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    return this.vueInstance.axios.get(`${resource}/${slug}`).catch(e => {
      expiredSession(e);
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return this.vueInstance.axios.post(`${resource}`, params).catch(e => {
      expiredSession(e);
    });
  },
  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return this.vueInstance.axios.put(`${resource}/${slug}`, params).catch(e => {
      expiredSession(e);
    });
  },
  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return this.vueInstance.axios.put(`${resource}`, params).catch(e => {
      expiredSession(e);
    });
  },
  delete(resource, slug) {
    return this.vueInstance.axios.delete(`${resource}/${slug}`).catch(e => {
      expiredSession(e);
    });
  },
  option(resource) {
    return this.vueInstance.axios.options(`${resource}`).catch(e => {
      console.error(e);
    });
  },
  upload(resource, fileList, params, isMulti){
    return new Promise((resolve, reject) => {
      const data = new FormData();
      if(isMulti){
        if(fileList && fileList.length > 0){
          for(let i = 0; i < fileList.length; i++){
            data.append("uploadFiles", fileList[i]);
          }
        }
      }else{
        if(fileList && fileList.length > 0){
          data.append( 'uploadFile', fileList[0]);
        }
      }
      data.append('params', new Blob([JSON.stringify(params)], {type:"application/json"}));
      const xhr = new XMLHttpRequest();
      // timeout 을 길게
      xhr.timeout = 10 * 60 * 1000;
      // xhr.open("POST", `${this.apisHost}${resource}`, true);
      xhr.open("POST", `${resource}`, true);
      xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
      xhr.setRequestHeader(TOKEN_KEY, sessionStorageService.getToken(TOKEN_KEY));
      xhr.responseType = 'json';

      xhr.addEventListener( 'load', () => {
        const response = xhr.response;
        if (!response || response.error) {
          return reject(response && response.error ? response.error : 'Upload Failed');
        }else{
          return resolve(response);
        }
      });

      xhr.send(data);
    });
  },
  download(resource, slug, params, fileName, callback){
    const xhr = new XMLHttpRequest();
    let url = '';

    if (slug) {  url = `${resource}/${slug}`; }
    else { url = `${resource}`; }

    if(params !== null && params !== undefined){
      const keys = Object.keys(params);
      if(keys.length > 0){
        url += ('?'+ keys.map(key => `${key}=${params[key]}`).join('&'));
      }
    }
    xhr.open("GET", url, true);
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    xhr.setRequestHeader(TOKEN_KEY, sessionStorageService.getToken(TOKEN_KEY));
    xhr.responseType = "blob";
    xhr.onload = () => {
      const link = document.createElement('a');
      link.href = isInApp ? 'browser::'+window.URL.createObjectURL(xhr.response) : window.URL.createObjectURL(xhr.response);

      let fileDenyFlag = xhr.getResponseHeader("lxpfiledown");
      let contDisp = xhr.getResponseHeader("Content-Disposition");
      let filename = contDisp && contDisp.split("filename=")[1];
      if(filename){
        filename = filename.trim().replace(/^"(.*)"$/, "$1");
        if(filename=="f.txt"){
          filename = fileName;
        }
      }else{
        filename = fileName;
      }

      //파일 오류시 TEXT파일로 인식
      if(fileDenyFlag =="DENY_NETWORK"){
        filename +=".DENY.TXT";
      }

      link.download = filename;
      link.click();
      if (callback instanceof Function) callback();
    };

    xhr.onerror = () => {
      if (callback instanceof Function) {
        callback();
      }else{
        showMessage('파일 다운로드 처리 중 일시적인 장애가 발생하였습니다.<br>잠시 후 다시 시도 해 주시기 바랍니다. <br/><br/>오류가 반복되는 경우 관리자에게 문의하시기 바랍니다.');
      }
    }

    xhr.send();
  },
  download2(resource, slug, params, fileName, callback){
    const xhr = new XMLHttpRequest();
    let url = '';

    if (slug) {  url = `${resource}/${slug}`; }
    else { url = `${resource}`; }

    if(params !== null && params !== undefined){
      const keys = Object.keys(params);
      if(keys.length > 0){
        url += ('?'+ keys.map(key => `${key}=${params[key]}`).join('&'));
      }
    }
    xhr.open("GET", url, true);
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    xhr.setRequestHeader(TOKEN_KEY, sessionStorageService.getToken(TOKEN_KEY));
    xhr.setRequestHeader('Content-Security-Policy',"default-src 'self'");
    xhr.responseType = "blob";
    xhr.onload = () => {
      const link = document.createElement('a');
      link.href = isInApp ? 'browser::'+window.URL.createObjectURL(xhr.response) : window.URL.createObjectURL(xhr.response);
      link.download = fileName;
      link.click();
      if (callback instanceof Function) callback();
    };

    xhr.onerror = () => {
      if (callback instanceof Function) callback();
    }

    xhr.send();
  }

};

export default ApiService;
