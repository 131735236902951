import LxpLayout from "@/views/layout/lxp/LxpLayout";
import navigationUtils from "@/assets/js/navigationUtils";
import LxpMobileLayout from "@/views/layout/lxp/mobile/LxpMobileLayout";

const isMobile = navigationUtils.any();

const helpRoutes = [
    {
        path: '/help',
        component: isMobile ? LxpMobileLayout : LxpLayout,
        children: [
            {
                // path variable 을 생성해야 된다. 임시 라우터 처리
                path: 'notice',
                name: "HelpNotice",
                component: () => isMobile ? import('../../views/pages/help/mobile/HelpMobileNotice') : import('../../views/pages/help/HelpNotice'),
            },
            {
                // path variable 을 생성해야 된다. 임시 라우터 처리
                path: 'pubnotice/:expsrLoc',
                name: "pubNotice",
                component: () => isMobile ? import('../../views/pages/help/mobile/HelpPublicMobileNotice') : import('../../views/pages/help/HelpPublicNotice'),
            },
            {
                // path variable 을 생성해야 된다. 임시 라우터 처리
                path: 'notice/:ntcSn',
                name: "HelpNoticeView",
                component: () => isMobile ? import('../../views/pages/help/mobile/HelpMobileNoticeView') : import('../../views/pages/help/HelpNoticeView'),
            },
            {
                // path variable 을 생성해야 된다. 임시 라우터 처리
                path: 'lib',
                name: "HelpLib",
                component: () => isMobile ? import('../../views/pages/help/mobile/HelpMobileLib') : import('../../views/pages/help/HelpLib'),
            },
            {
                // path variable 을 생성해야 된다. 임시 라우터 처리
                path: 'lib/:comPostSn',
                name: "HelpLibView",
                component: () => isMobile ? import('../../views/pages/help/mobile/HelpMobileLibView') : import('../../views/pages/help/HelpLibView'),
            },

            // FAQ 게시판
            {
                path: 'faq',
                name: "HelpFaq",
                component: () => isMobile ? import('../../views/pages/help/mobile/HelpMobileFaq') : import('../../views/pages/help/HelpFaq'),
            },

            // Q&A 게시판
            {
                path: 'qna',
                name: "HelpQna",
                component: () => isMobile ? import('../../views/pages/help/mobile/HelpMobileQna') : import('../../views/pages/help/HelpQna'),
            },
            {
                // path variable 을 생성해야 된다. 임시 라우터 처리
                path: 'qna/:qnaSn',
                name: "HelpQnaView",
                component: () => isMobile ? import('../../views/pages/help/mobile/HelpMobileQnaView') : import('../../views/pages/help/HelpQnaView'),
            },
            {
                // path variable 을 생성해야 된다. 임시 라우터 처리
                path: 'qna/write',
                name: "HelpQnaWrite",
                component: () => isMobile ? import('../../views/pages/help/mobile/HelpMobileQnaWrite') : import('../../views/pages/help/HelpQnaWrite'),
            },

            // HR부(인재개발)소개
            {
                path: 'hrd-intro',
                name: "HelpHrdIntro",
                component: () => isMobile ? import('../../views/pages/help/mobile/HelpMobileHrdIntro') : import('../../views/pages/help/HelpHrdIntro'),
            },

            // 업무별연락처
            {
                path: 'contact',
                name: "HelpContact",
                component: () => isMobile ? import('../../views/pages/help/mobile/HelpMobileContact') : import('../../views/pages/help/HelpContact'),
            },

            // OSOA 다운로드
            {
                path: 'osoa',
                name: 'HelpOsoa',
                component: () => import('../../views/pages/help/HelpOsoa'),
            },
        ],
    }
];

export const setHelpRoutes = (routes) => {
    routes.push(...helpRoutes);
}