<template>
  <nav v-if="paging.totalCount > 0" class="ssl-pagination">
    <ul class="kb-pagination">
      <li v-if="!((startPageList - 1) === 0)" class="page-item page-prev">
        <a class="page-link" href="javascript:" @click="pageFunc((startPageList - 1), paging.pageSize)"><i class="icon-prev"></i></a>
      </li>
      <li :key="idx" v-for="(num, idx) in numberRange" class="page-item" :class="{'is-active': (paging.pageNo === num)}">
        <a class="page-link" href="javascript:" @click="pageFunc(num, paging.pageSize)">{{ num }}</a>
      </li>
      <li v-if="!(endPageList >= totalPage)" class="page-item page-next">
        <a class="page-link" href="javascript:" @click="pageFunc((endPageList + 1), paging.pageSize)"><i class="icon-next"></i></a>
      </li>
    </ul>
  </nav>
</template>
<script>

import {computed, ref, toRef} from "vue";

export default {
  name: 'SSLPagination',
  props: {
    paging: {
      type: Object,
      default: () => ({
        pageNo: 1,
        pageSize: 10,
        totalCount: 0
      })
    },
    pageListSize: {
      type: Number,
      default: 10,
    },
    pageFunc: Function
  },
  setup(props) {
    const pageSize = ref(toRef(props, 'paging').value.pageSize);

    const totalPage = computed(() => Math.ceil(props.paging.totalCount / props.paging.pageSize));
    const totalPageList = computed(() => Math.ceil(totalPage.value / props.pageListSize));

    const pageList = computed(() => {
      let pageList = Math.ceil(props.paging.pageNo / props.pageListSize);
      if (pageList < 1) pageList = 1;
      if (pageList > totalPageList.value) pageList = totalPageList.value;
      return pageList;
    });

    const startPageList = computed(() => {
      let startPageList = (pageList.value - 1) * props.pageListSize + 1;
      if (startPageList < 1) startPageList = 1;
      return startPageList;
    });

    const endPageList = computed(() => {
      let endPageList = startPageList.value + props.pageListSize - 1;
      if (endPageList > totalPage.value) endPageList = totalPage.value;
      if (endPageList < 1) endPageList = 1;
      return endPageList;
    });

    const numberRange = computed(() => {
      // const range = [];
      // const range = [...Array(endPageList.value - startPageList.value + 1).keys()].map(x => x + startPageList.value);
      // for (let i = startPageList.value; i <= endPageList.value; i++) {
      //   range.push(i);
      // }
      return [...Array(endPageList.value - startPageList.value + 1).keys()].map(x => x + startPageList.value);
    })


    return {
      pageSize,
      totalPage,
      startPageList,
      endPageList,
      numberRange
    }
  }
}
</script>
