<template>
  <div class="kb-form-row">
    <label class="kb-form-label">
      <span class="kb-form-label-text">{{ title }}</span>
    </label>
  </div>
  <div class="kb-form-row" :class="classLst">
    <template v-if="isNumber">
      <input v-if="max > 0"
             type="text"
             class="kb-form-control"
             :value="modelValue"
             :maxlength="max"
             :placeholder="placeholder"
             :disabled="disabled"
             @input="updateValue"
             @focusin="handleFocus"
             @focusout="handleFocus"
      />
      <input v-else-if="isMoney"
             ref="inputEl"
             type="text"
             class="kb-form-control"
             :class="classLst"
             :value="numberComma(modelValue)"
             @input="updateValue"
             :placeholder="placeholder"
             :disabled="disabled"
             @focusin="handleFocus"
             @focusout="handleFocus"
      />
      <input v-else
             type="text"
             class="kb-form-control"
             :value="modelValue"
             @input="updateValue"
             :placeholder="placeholder"
             :disabled="disabled"
             @focusin="handleFocus"
             @focusout="handleFocus"
      />
    </template>
    <template v-else-if="isTextNumber">
      <input type="text"
             class="kb-form-control"
             :value="modelValue"
             @input="updateValue"
             :placeholder="placeholder"
             :disabled="disabled"
      />
    </template>
    <input v-else
           type="text"
           class="kb-form-control"
           :value="modelValue"
           @input="updateValue"
           :placeholder="placeholder"
           :disabled="disabled"
    />
  </div>
</template>

<script>
import {emptyNumberOnClick, numberComma, removeComma} from '@/assets/js/util';

export default {
  name: 'LabelCustomInputText',
  props: {
    modelValue: [String, Number],
    title: String,
    classLst: String,
    isNumber: { type: Boolean, default: false },
    isMoney: { type: Boolean, default: false },
    isTextNumber: { type: Boolean, default: false },
    disabled: { type:Boolean, default: false },
    max: Number,
    placeholder: String
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const updateValue = (e) => {
      let val = e.target.value;
      if (props.isNumber) {
        if (props.isMoney) {
          val = removeComma(val);
        }
        val = Number(val);
      }
      if(props.isTextNumber){
        if(/[^0-9-]/g.test(val)) {
          val = val.replace(val, "");
        }

      }
      emit('update:modelValue',val);
    };
    const handleFocus = (e) => {
      if (props.isMoney) {
        emptyNumberOnClick(e);
      }
    };
    return {
      handleFocus, updateValue, numberComma
    }
  }
};
</script>