<template>
  <div class="popup-container" :class="{ 'is-active' : modelValue }" @click.stop="closeToggles(toggles)">
    <div class="popup" id="popup-tuition-support-p032">
      <div class="popup-inner">
        <div class="popup-header">
          <h3 class="title">학비 지원신청</h3>
        </div>
        <div class="popup-content">
          <div class="survey-container">
            <article class="popup-section section-form">
              <header class="section-header header-divider-v2">
                <h4 class="title">신청정보</h4>
              </header>
              <div class="section-content">
                <div class="kb-form-fields kb-form-fields-v2">
                  <Applicant />
                  <InputSelect
                      v-model:value1="param.schlDivCdDcd"
                      v-model:toggle1="toggles.schlDivCdDcds"
                      :options1="schlDivCdDcdOpt"
                      :disabled="isReadOnly"
                      title="학교 구분"
                      sequence-key1="cd"
                      name-key1="cdNm"
                      placeholder1="학교구분을 선택해주세요"
                      :useSubText="isUseSubText"
                      @update:toggle1="closeToggles(toggles, 'schlDivCdDcds')"
                  />
                  <InputSelect
                      v-model:value1="param.schlInDivCdDcd"
                      v-model:toggle1="toggles.schlInDivCdDcds"
                      :options1="schlInDivCdDcdOpt"
                      :disabled="isReadOnly"
                      title="재학 구분"
                      sequence-key1="cd"
                      name-key1="cdNm"
                      placeholder1="재학구분을 선택해주세요"
                      :useSubText="isUseSubText"
                      @update:toggle1="closeToggles(toggles, 'schlInDivCdDcds')"
                  />
                  <div v-if="isAcademicCreditBank" class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <strong class="kb-form-label-text">수강학점</strong>
                      </label>
                    </div>
                    <div class="kb-form-column">
                      <input
                          type="text"
                          class="kb-form-control"
                          v-model="param.regCrseCrdit"
                          :disabled="isReadOnly"
                          maxlength="10"
                          @input="numbersOnly"
                          @focusin="emptyNumberOnClick"
                          @focusout="emptyNumberOnClick"
                      />
                      <span class="kb-form-subtext">수강가능학점 : {{sprtCredit}}점</span>
                    </div>

                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <strong class="kb-form-label-text">학위취득예정시기</strong>
                      </label>
                    </div>
                    <div class="kb-form-column">
                      <input
                          type="text"
                          class="kb-form-control"
                          maxlength="20"
                          v-model="param.degAcqsSchedPerid"
                          :disabled="isReadOnly"
                      />
                    </div>
                  </div>
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <strong class="kb-form-label-text">학교명</strong>
                      </label>
                    </div>
                    <div class="kb-form-column column-whole kb-form-search">
                      <input type="text" v-model="school.scholNm" class="kb-form-control" placeholder="학교명 검색" disabled />
                      <button v-if="!isReadOnly" class="kb-form-search-btn" @click="collegeModal = true" :disabled="isReadOnly"><i class="icon-search"></i></button>
                    </div>
                  </div>
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <strong class="kb-form-label-text">전공</strong>
                      </label>
                    </div>
                    <div class="kb-form-column column-whole kb-form-search">
                      <input type="text" v-model="major.majorNm" class="kb-form-control" placeholder="전공 검색" disabled />
                      <button v-if="!isReadOnly" class="kb-form-search-btn" @click="majorModal = true" :disabled="isReadOnly"><i class="icon-search"></i></button>
                    </div>
                  </div>
                  <RowInputText v-model="param.majorNm2" title="학과" class-lst="column-whole" placeholder="학과명을 정확하게 입력해주세요." :disabled="isReadOnly"/>
                  <div class="kb-form-row" v-if="!isAcademicCreditBank">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <strong class="kb-form-label-text">신청학기</strong>
                      </label>
                    </div>
                    <div class="kb-form-column kb-form-dropdown-group">
                      <select v-model="param.sprtYear"  class="kb-form-control kb-form-select" style="width:150px; float:left">
                        <option v-for="n in appYear" :key="n" :value="n">{{n}}년</option>
                      </select>
                      <InputSelect
                          v-model:value1="param.termDivCdDcd"
                          v-model:toggle1="toggles.termDivCdDcds"
                          :options1="termDivCdDcdOpt"
                          sequence-key1="cd"
                          name-key1="cdNm"
                          placeholder1="학기를 선택해주세요"
                          :disabled="isReadOnly"
                          :useSubText="isUseSubText"
                          @update:toggle1="closeToggles(toggles, 'termDivCdDcds')"
                      />
                    </div>
                  </div>
                  <RowDatepicker
                      title="등록금 납부일"
                      v-model="param.tuitFeePayDd"
                      v-model:toggle="toggles.tuitFeePayDd"
                      :disabled="isReadOnly"
                  />
                </div>
              </div>
            </article>


            <article class="popup-section section-form">
              <header class="section-header header-divider-v2">
                <h4 class="title">성적등록</h4>
              </header>
              <div class="section-content">
                <div class="kb-form-fields kb-form-fields-v2">

                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <strong class="kb-form-label-text">신청학기 평점평균</strong>
                      </label>
                    </div>
                    <div class="kb-form-column kb-form-divider">
                      <input
                          type="text" class="kb-form-control"
                          v-model="param.grdAvg"
                          :disabled="isReadOnly"
                          @input="numbersOnly"
                          @focusin="emptyNumberOnClick"
                          @focusout="emptyNumberOnClick"
                      />
                    </div>
                    <div class="kb-form-column kb-form-dropdown-group ps-0">
                      <div class="kb-form-dropdown" :class="{'is-active': toggles.grdTots}">
                        <button class="kb-form-dropdown-btn" @click.stop="toggleGrdTot" :disabled="isReadOnly">
                          <span class="text">{{ param.grdTot }}점</span><i class="icon-arrow"></i>
                        </button>
                        <div class="kb-form-dropdown-options">
                          <div class="dropdown-option">
                            <ul class="dropdown-option-list">
                              <li v-for="(item, idx) in grdTots2" class="dropdown-option-item" :key="idx">
                                <a href="javascript:" class="dropdown-option-link" @click.stop="selectGrdTot2(idx)"><span class="dropdown-option-text">{{ item.nm }}</span></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Upload
                      v-model="transcriptFiles"
                      title="신청학기 성적증명서"
                      placeholder="신청학기 성적증명서를 등록하세요"
                      btn-title="성적증명서 등록하기"
                      sequence-key="proofDataSn"
                      :extensions="extensions"
                      :max-qty="10"
                      :disabled="isReadOnly"
                  >

                    <template v-slot:last>
                      <p class="kb-form-bottomtext">
                        &nbsp; <span class="text-nowrap text-right">* 1장만 등록가능함, 증명서의 가장자리가 모두 보이도록 촬영</span>
                      </p>
                    </template>

                  </Upload>

                  <RowInputText v-model="param.inetProofNo" title="인터넷 증명서 발급번호" class-lst="column-whole" placeholder="진위확인코드(16자리) 등 증명서 진위여부 확인이 가능한 일련번호를 입력하세요." :disabled="isReadOnly">
                    <template v-slot:last>
                      <div class="kb-form-bottomtext">
                        <span class="text-nowrap">* 인터넷 증명서 발급번호(숫자,영문,- 만 입력가능)</span>
                      </div>
                    </template>
                  </RowInputText>

                </div>
              </div>
            </article>
            <article class="popup-section section-form">
              <header class="section-header">
                <h4 class="title">등록금 납부 내역 등록</h4>
              </header>
              <div class="section-content">
                <div class="kb-table kb-table-bordered-v2">
                  <table>
                    <thead>
                    <tr>
                      <th><strong class="th-title">고지금액</strong></th>
                      <th><strong class="th-title">장학금액</strong></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>
                        <div class="kb-form-fields kb-form-fields-v2">
                          <template v-if="param.schlInDivCdDcd != schlInDivCdDcds.SCHL_IN_DIV_CD_OLD">
                            <RowInputText v-model="fees.sprtEntrFee" title="입학금" class-lst="column-auto" placeholder="입학금을 입력하세요." :is-number="true" :is-money="true" :is-krw="true" :disabled="isReadOnly"/>
                          </template>
                          <RowInputText v-model="fees.sprtTuitFee" title="수업료" class-lst="column-auto" placeholder="수업료를 입력하세요." :is-number="true" :is-money="true" :is-krw="true" :disabled="isReadOnly"/>
                        </div>
                      </td>

                      <td class="align-top">
                        <div class="kb-form-fields kb-form-fields-v2">
                          <RowInputText v-model="reduce.redGradeBursAmt" title="교내 성적장학금" class-lst="column-auto" placeholder="교내 성적장학금을 입력하세요." :is-number="true" :is-money="true" :is-krw="true" :disabled="isReadOnly" />
                          <RowInputText v-model="reduce.redNtnBursAmt" title="국가 장학금" class-lst="column-auto" placeholder="국가 장학금을 입력하세요." :is-number="true" :is-money="true" :is-krw="true" :disabled="isReadOnly" />
                          <RowInputText v-model="reduce.etcBursAmt" class-lst="column-auto" placeholder="금액을 입력하세요." :is-number="true" :is-money="true" :is-krw="true" :disabled="isReadOnly">
                            <template v-slot:first>
                              <div class="kb-form-column kb-form-column-title">
                                <input type="text" class="kb-form-control me-6" v-model="reduce.etcBursAmtNm" placeholder="장학금명"/>
                              </div>
                            </template>
                          </RowInputText>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div class="kb-form-fields kb-form-fields-v2 mt-4">
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label"><strong class="kb-form-label-text">지원대상 금액</strong></label>
                    </div>
                    <div class="kb-form-column column-auto">
                      <input type="text" class="kb-form-control" placeholder="" :value="`${numberComma(param.sprtExptAmt)}`" readonly>
                    </div>
                    <div class="kb-form-column column-side">
                      <span class="kb-form-text">원</span>
                    </div>
                  </div>

                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <strong class="kb-form-label-text">지원금액</strong>
                      </label>
                    </div>
                    <div class="kb-form-column column-auto">
                      <input type="text" class="kb-form-control" placeholder="" :value="`${numberComma(param.sprtAmt)}`" readonly>
                    </div>
                    <div class="kb-form-column column-side">
                      <span class="kb-form-text">원</span>
                    </div>
                  </div>
                  <Upload
                      v-model="tuitPayCertFiles"
                      title="등록금 납입증명서"
                      placeholder="교육기관에서 발급한 '등록금납입증명서'를 등록하세요."
                      btn-title="등록금 납입증명서 등록"
                      sequence-key="proofDataSn"
                      :extensions="extensions"
                      :max-qty="10"
                      :is-payment="true"
                      :key="`tuition`"
                      :disabled="isReadOnly"
                  />
                </div>
              </div>
            </article>

            <article class="popup-section section-form">
              <header class="section-header header-divider-v2">
                <h4 class="title">결과 등록</h4>
              </header>
              <div class="section-content">
                <div class="kb-form-fields kb-form-fields-v2">
                  <Upload
                      v-model="schlScholarCertFiles"
                      title="장학금 (비)수혜 증명서"
                      placeholder="교육기관에서 발급한 장학금 (비)수혜증명서를 등록해주세요."
                      btn-title="증명서 등록하기"
                      sequence-key="proofDataSn"
                      :extensions="extensions"
                      :max-qty="10"
                      :is-payment="true"
                  />
                </div>
              </div>
            </article>

            <article class="popup-section section-agree" v-if="param.rtnYn=='Y' || param.addAmtYn=='Y'">
              <header class="section-header header-divider-v2">
                <h4 class="title">환입 및 추가지원금액</h4>
              </header>
              <div class="section-content">
                <div class="kb-form-fields kb-form-fields-v2">
                  <RowInputText v-model="param.rtnAmt" title="환입금액"  :is-number="true" :is-money="true" :disabled="true" v-if="param.rtnYn=='Y'" />
                  <RowInputText v-model="param.addAmt" title="추가지원금액"  :is-number="true" :is-money="true" :disabled="true" v-if="param.addAmtYn=='Y'" />
                </div>
              </div>
            </article>

            <article v-if="data == null" class="popup-section section-agree">
              <UserAgreement
                  ref="usrAgrmntRef"
                  v-model:user-agreement="userAgreement"
                  @applyBtnEnable="applyBtnEnable"
              />
            </article>
          </div>
        </div>
        <SupportPopupButton
            :stt="data && data.stt"
            :aply-stt-cd-dcd="data && data.aplySttCdDcd"
            :apply-btn-enable="applyBtnEnable"
            :user-agreement="userAgreement"
            :apply="apply"
            :cancel="cancel"
            :close-modal="closeModal"
            type="school"
        />
      </div>
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
  </div>
  <ListModal v-if="collegeModal"
             v-model="collegeModal"
             v-model:selected="school"
             list-type="college"
  />
  <ListModal v-if="majorModal"
             v-model="majorModal"
             v-model:selected="major"
             list-type="major"
  />
</template>

<script>
import Applicant from '@/components/support/common/Applicant';
import InputSelect from '@/components/support/common/InputSelect';
import ListModal from '@/components/support/common/ListModal';
import Upload from '@/components/support/common/Upload';
import RowInputText from '@/components/support/common/RowInputText';
import {supportTuitionVupSetup} from "@/assets/js/modules/support/support-tuition-vup-setup";
import SupportPopupButton from "@/components/support/common/SupportPopupButton";
import UserAgreement from "@/components/support/common/UserAgreement";
import RowDatepicker from '@/components/support/common/RowDatepicker';


export default {
  name: 'SupportTuition202309Modal',
  components: {SupportPopupButton, RowInputText , Upload, ListModal, InputSelect, Applicant, UserAgreement, RowDatepicker}, //, InputText
  props: {
    modelValue: Boolean,
    data: {type:Object, default:null},
    reList : Function
  },
  setup: supportTuitionVupSetup
};
</script>