import {computed, ref} from 'vue';
import {eventCdDcds, getConvertTime} from '@/assets/js/modules/hrd/hrd-common';
import {
  getCheckItems,
  paramCheck,
  secondToFormat,
  secondToKrFormat,
  timestampToDateFormat,
} from '@/assets/js/util';
import {
  getMlgGiveDt,
  getProgLogLst,
  getProgressPercent,
  getSummary,
} from '@/assets/js/modules/hrd/hrd-history-common';
import {useStore} from 'vuex';

export const hrdHistoryDtlSetup = (props, {emit}) => {
  const store = useStore();
  const isMobile = computed(() => store.state.auth.isMobile);
  const isLoading = ref(true);
  const closeModal = () => {
    emit('update:modelValue', false);
  };

  // eslint-disable-next-line no-unused-vars
  const summary = ref({}), items = ref([]), body = ref([]);

  const detTitle = ref({
    dtlColdcall: "콜드콜",  //취득점수(%)
    logColdcall: "콜드콜횟수", //취득점수(평균)
    logRspdYn: "응답여부", //
    logRspdDt: "응답시간",
  })

  const dtlColdcallValue = ref("-");

  // eslint-disable-next-line no-unused-vars
  const setLrnEnd = (item, info) => {
    if (info.startDt > 0) {
      let time = item.regDt - info.startDt;

      item.eventCdDcd = eventCdDcds.Ended;
      item.eventNm = '학습종료';

      if (info.idleTime > 0) {
        time -= info.idleTime;
        info.idleTime = 0;
      }
      item.playTime = time > 0 ? secondToKrFormat(time / 1000) : '-';
    }
    info.startDt = 0;
    info.idleTime = 0;
  };

  const setLrnEndForEasy = (item, info) => {
    if (info.startDt > 0) {
      let time = item.regDt - info.startDt;

      item.eventCdDcd = eventCdDcds.Ended;
      item.eventNm = '학습종료';

      //점수계산

      if (info.idleTime > 0) {
        time -= info.idleTime;
        info.idleTime = 0;
      }
      //학습시간
      console.log("startdt", timestampToDateFormat(info.startDt), time);

      if(timestampToDateFormat(info.startDt)>="2024-02-05"){
        item.playTime = info.getPlaySec > 0 ? secondToKrFormat(info.getPlaySec / 1000) : '-';
      }else{
        item.playTime = time > 0 ? secondToKrFormat(time / 1000) : '-';
      }

    }
    info.startDt = 0;
    info.idleTime = 0;
  };

  const getData = async () => {
    let payload = {
      lrnObjDtlDistSn: props.lrnObjDtlDistSn
    };

    if (!paramCheck(props.year)) {
      payload.year = props.year;
    }
    let head = await getSummary({...payload});

    if (!paramCheck(props.month)) {
      payload.month = props.month;
    }

    let logs = await getProgLogLst({...payload});

    summary.value = getCheckItems(head)[0];


    items.value = getCheckItems(logs);
    if (items.value && items.value.length > 0) {
      let info = {
        startDt: 0,
        pauseDt: 0,
        idleTime: 0,

        getScorePer:0,
        getPlaySec:0,
      };

      // eslint-disable-next-line no-unused-vars
      const logEventCds = [eventCdDcds.Play, eventCdDcds.Timeupdate, eventCdDcds.Pause, eventCdDcds.Ended];

      //EASY 인 경우 상세이력 생성
      // ColdcallStop : 비정상학습 학습중단
      if (summary.value.cttTyCdDcd === "2034009") {

        detTitle.value.dtlColdcall = "취득점수(%)";  //
        detTitle.value.logColdcall = "취득점수(평균)";
        detTitle.value.logRspdYn = "관련Step";
        detTitle.value.logRspdDt = "비고";


        if(summary.value.lastPlaySec>0){
          dtlColdcallValue.value = Math.floor(summary.value.maxPlaySec / summary.value.lastPlaySec * 100);
        }

        items.value.map((x, idx) => {
          x.playTime = '-';

          let sesnKey = x.progSesnKey,
              // eslint-disable-next-line no-unused-vars
              prevItem = (items.value[idx - 1] != null ? items.value[idx - 1] : null),
              nextItem = (items.value[idx + 1] != null ? items.value[idx + 1] : null);

          if (eventCdDcds.ColdcallStop === x.eventCdDcd) {

            x.playTime = "-";
            x.coldCallRound = "-";
            x.coldCallRspns = `${x.actPlaySec} Step`;// 중단 발생 page
            body.value.push(x);

          } else {
            x.coldCallRound = '-';
            x.coldCallRspns = '-';
            if (idx + 1 < items.value.length) {
              // 다음 로그도 같은 세션키
              if (nextItem && nextItem.progSesnKey === sesnKey) {
                // 일시정지했을때 시작-일시정지일때 시간 축척
                if (x.eventCdDcd === eventCdDcds.Play) {

                  info.startDt = x.regDt;
                  info.idleTime = 0;
                  info.getScorePer = 0;
                  info.getPlaySec = 0;
                  body.value.push(x);

                } else if (x.eventCdDcd === eventCdDcds.Timeupdate) {
                    // 누적점수
                    info.getScorePer += x.maxPlaySec;
                    // 누적playtime
                    info.getPlaySec += x.lastPlaySec;
                }
              }
                  // 세션키가 달라졌으므로 세션의 마지막 로그.
              // 즉, 학습종료 시점
              else {
                if (x.eventCdDcd !== eventCdDcds.Play) setLrnEndForEasy(x, info);
                body.value.push(x);
                info.pauseDt = 0;
              }
            } else {
              if (x.eventCdDcd !== eventCdDcds.Play) setLrnEndForEasy(x, info);
              body.value.push(x);
              info.pauseDt = 0;
            }
          }
        });

        // VOD,PDF 상세이력표시
      } else {

        //                    <span v-if="summary.cttSz > 60" class="td-text">{{ summary.coldCallRspnsDt ? '응답' : '미응답'}} {{ `${summary.coldCallRspnsDt ? 1 : 0} / ${summary.coldCallRound}` }}</span>
        if(summary.value.cttSz>60 && summary.value.coldCallRound>0){
          dtlColdcallValue.value = summary.value.coldCallRspnsDt ? "응답 " : "미응답 ";
          dtlColdcallValue.value += `${summary.value.coldCallRspnsDt ? "1" : "0"} / ${summary.value.coldCallRound}`;
        }

        items.value.map((x, idx) => {
          x.playTime = '-';

          let sesnKey = x.progSesnKey,
              // eslint-disable-next-line no-unused-vars
              prevItem = (items.value[idx - 1] != null ? items.value[idx - 1] : null),
              nextItem = (items.value[idx + 1] != null ? items.value[idx + 1] : null);

          if (eventCdDcds.Coldcall === x.eventCdDcd || eventCdDcds.ColdcallStop === x.eventCdDcd) {
            if (eventCdDcds.Coldcall === x.eventCdDcd) {
              if (x.coldCallRspnsDt > 0) {
                x.regDt = x.coldCallRspnsDt;
                x.playTime = secondToKrFormat(x.lastPlaySec, 'hh:mm:ss'); // 콜드콜 발생 시분초
                x.coldCallRspns = (x.coldCallRspnsDt ? '응답' : '미응답');
                body.value.push(x);
              } else if (x.coldCallEndDt > 0) {
                x.regDt = x.coldCallEndDt;
                x.playTime = secondToKrFormat(x.lastPlaySec, 'hh:mm:ss'); // 콜드콜 발생 시분초
                x.coldCallRspns = (x.coldCallRspnsDt ? '응답' : '미응답');
                body.value.push(x);
              }
            }
          } else {
            x.coldCallRound = '-';
            x.coldCallRspns = '-';
            if (idx + 1 < items.value.length) {
              // 다음 로그도 같은 세션키
              if (nextItem && nextItem.progSesnKey === sesnKey) {
                // 일시정지했을때 시작-일시정지일때 시간 축척
                if (x.eventCdDcd === eventCdDcds.Play) {
                  // 이전 로그중 중지가 있었으므로
                  if (info.pauseDt > 0) {
                    info.idleTime += (x.regDt - info.pauseDt);
                    info.pauseDt = 0; // idleTime 을 계산했으면 다음 중지를 위해 pauseDt 0으로 초기화
                  }

                  info.startDt = x.regDt;
                  body.value.push(x);

                  // PAUSE를 종료로 보고 계산한번 한다
                } else if (x.eventCdDcd === eventCdDcds.Pause) {
                  info.pauseDt = x.regDt;
                  // 학습중지,학습중 동시 발생했는데 학습중지가 먼저 나갔을때 startDt 초기화하면 시작 끝위치만 있고 시작위치가 없게됨
                  // 따라서, 이전 시작위치 사용
                  body.value.push(x);
                } else if (x.eventCdDcd === eventCdDcds.Timeupdate) {
                  // 중지 후 학습시작이 없으면 30초 더해주기
                  if (info.pauseDt > 0) {
                    info.idleTime += (x.regDt - info.pauseDt);
                    info.pauseDt = 0; // idleTime 을 계산했으면 다음 중지를 위해 pauseDt 0으로 초기화
                  }
                }
              }
                  // 세션키가 달라졌으므로 세션의 마지막 로그.
              // 즉, 학습종료 시점
              else {
                if (x.eventCdDcd !== eventCdDcds.Play) setLrnEnd(x, info);
                body.value.push(x);
                info.pauseDt = 0;
              }
            } else {
              if (x.eventCdDcd !== eventCdDcds.Play) setLrnEnd(x, info);
              body.value.push(x);
              info.pauseDt = 0;
            }
          }
        });
      }


      body.value.sort((a, b) => {
        if (a.regDt >= b.regDt) {
          return -1;
        } else {
          return 1;
        }
      });
    }
    isLoading.value = false;
  };

  getData();

  return {
    isLoading, closeModal, isMobile,
    summary, items, body,
    paramCheck, secondToFormat, timestampToDateFormat, getProgressPercent, getConvertTime, getMlgGiveDt,
    detTitle, dtlColdcallValue
  }
}