<template>
  <!-- begin::kb-main -->
  <LxpMobileHeader>
    <template v-slot:left>
      <div class="util util-back">
        <a href="javascript:" class="util-actions util-actions-back" @click="goBack">
          <i class="icon-history-back"></i>
        </a>
      </div>
    </template>
    <template v-slot:right>
      <div v-if="fileItems.length > 0" class="util util-download">
        <a href="javascript:" class="util-actions util-actions-download" @click="isFileAtchListShow = true">
          첨부파일
          <i class="icon-download"></i>
        </a>
      </div>
    </template>
  </LxpMobileHeader>

  <main class="kb-main" id="kb-reinstatement-support">
    <!-- main-content -->
    <div class="main-content main-component">
      <div class="board-view-container">
        <div class="view-header">
          <div class="title-wrap">
            <h3 class="title">{{ item.ntcTitle }}</h3>
            <p class="subtitle">
              <span class="text">공지</span>
              <span class="text">{{ getDateFormat('yyyy.MM.dd hh:mm', item.regDt) }} 등록</span>
              <span class="text">{{ item.inqCnt }} 조회</span>
            </p>
          </div>
        </div>
        <div class="view-body">
          <div class="view-content" v-html="item.ntcCn" />
        </div>
      </div>
    </div>
    <!-- //main-content -->
  </main>
  <!-- end::kb-main -->
  <TakeMobileFileAtch v-if="isFileAtchListShow" v-model:isFileAtchListShow="isFileAtchListShow" :fileItems="fileItems"/>
</template>

<script>
import {computed, onMounted, ref} from "vue";
import {useStore} from "vuex";
import {ACT_GET_HELP_FILE_ATCH_LIST, ACT_GET_HELP_NOTICE, ACT_UPDATE_NOTICE_CNT} from "@/store/modules/help/help";
import {convertToStorageBytes, getItem, getItems, lengthCheck, timestampToDateFormat} from "@/assets/js/util";
import {useRoute} from "vue-router";
import {downloadComAtch} from "@/assets/js/modules/help/help-common";
import LxpMobileHeader from "@/views/layout/lxp/header/mobile/LxpMobileHeader";
import router from "@/router";
import TakeMobileFileAtch from "@/views/pages/take/mobile/TakeMobileFileAtch";

export default {
  name: "TakeNoticeMobileView",
  components: {TakeMobileFileAtch, LxpMobileHeader},
  setup() {
    const noticeTitle = ref('연수시스템 점검 안내');
    const item = ref({});
    const fileItems = ref([]);

    const store = useStore();
    const route = useRoute();

    const isFileAtchListShow = ref(false);

    const comNtcSn = computed(() => route.params.comNtcSn);

    const goBack = () => {
      router.go(-1);
    }

    const getTakeNotice = () => {
      store.dispatch(`help/${ACT_GET_HELP_NOTICE}`, route.params.comNtcSn).then(res => {
        if (lengthCheck(res)) {
          item.value = getItem(res);
          getTakeNoticeFileAtchList();
        } else {
          item.value = [];
        }
      }).catch(e => {
        console.error(e);
      })
    };

    getTakeNotice();

    const getTakeNoticeFileAtchList = () => {
      store.dispatch(`help/${ACT_GET_HELP_FILE_ATCH_LIST}`, {
        comNtcSn: comNtcSn.value
      }).then(res => {
        if (lengthCheck(res)) {
          fileItems.value = getItems(res);
        } else {
          fileItems.value = [];
        }
      }).catch(e => {
        console.error(e);
      })
    };

    const getDateFormat = (format, value) => {
      if(value){
        return timestampToDateFormat(value, format ? format : 'yyyy.MM.dd');
      }
      return '-';
    };

    const pageViewCnt = async () => {
      if (comNtcSn.value > 0) {
        await store.dispatch(`help/${ACT_UPDATE_NOTICE_CNT}`, {comNtcSn: comNtcSn.value});
      }
    };

    onMounted(() => {
      pageViewCnt();
    });

    return {
      noticeTitle,
      item,
      fileItems,
      isFileAtchListShow,
      getDateFormat,
      convertToStorageBytes,
      downloadComAtch,
      goBack
    }
  }
}
</script>