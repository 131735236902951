import ApiService from '@/assets/js/api.service';

export const ACT_GET_CRSE_APPLY_LIST = 'getCrseApplyList';
export const ACT_GET_CRSE_APPLY_COUNT = 'getCrseApplyCount';
export const ACT_GET_CRSE_DIST_APPLY = 'getCrseDistApply';
export const ACT_GET_CRSE_DIST_LIST = 'getCrseDistList';
export const ACT_GET_CRSE_REL_LIST = 'getCrseRelList';
export const ACT_INSERT_CRSE_ACT_LOG = 'insertCrseActLog';
export const ACT_GET_CRSE_ACT_SUBSCRIBED_LIST = 'actGetCrseActSubscribedList';
export const ACT_DELETE_CRSE_ACT = 'actDeleteCrseAct';
export const ACT_GET_CRSE_ACT_ADD = 'actGetCrseActAdd';

export const ACT_GET_CRSE_FNSH_DIST_LIST = 'getCrseFnshDistList';
export const ACT_GET_LRN_OBJ_DTL_DIST_LIST_BY_DIST_CRSE_SN = 'getLrnObjDtlDistListByDistCrseSn';
export const ACT_GET_CHAP_DIST_LIST = 'getChapDistList';

export const ACT_INSERT_CRSE_REG_APLY = 'insertCrseRegAply';
export const ACT_GET_CRSE_REG_APLY_LIST = 'getCrseRegAplyList';
export const ACT_UPDATE_CRSE_REG_APLY = 'updateCrseRegAply';

export const ACT_GET_CRSE_DRAW_LIST = 'getCrseDrawList';
export const ACT_GET_CRSE_BPCC_LIST = 'getCrseBpccList';

export const ACT_GET_CRSE_DISP_CATE_LIST = 'getCrseDispCateList';
export const ACT_GET_KNOWLEDGE_LRN_OBJ_DTL_DIST_LIST = 'getKnowledgeLrnObjDtlDistList';

export const ACT_GET_CRSE_DIST_REGULAR_LIST = 'getCrseDistRegularList';
export const ACT_INSERT_CRSE_DIST_REGULAR = 'insertCrseDistRegular';

export const ACT_GET_CRSE_DIST_MENTORING = 'getCrseDistMentoring';

export const ACT_GET_CRSE_DIST_GRP_SEQ_LIST = 'getCrseDistGrpSeqList';

export const ACT_INSERT_CRSE_DIST_GROUP = 'insertCrseDistGroup';
export const ACT_INSERT_GROUP_APPLY = 'insertGroupApply';
export const ACT_DELETE_GROUP = 'deleteGroup';

export const ACT_INVITE_GROUP_MEMBER = 'inviteGroupMember';

export const ACT_GET_CRSE_DIST_OBJ_APPLY = 'getCrseDistObjApply';
export const ACT_GET_CRSE_DIST_OBJ_LRNER = 'getCrseDistObjLrner';
export const ACT_GET_CRSE_DIST_COND = 'getCrseDistCond';
export const ACT_GET_CRSE_DIST_DIGITAL_LIST = 'getCrseDistDigitalList';
export const ACT_GET_CRSE_DIST_GLOBAL_LIST = 'getCrseDistGlobalList';
export const ACT_GET_CRSE_DIST_STAR_LINK_ENROLLED_LIST = 'getCrseDistStarLinkEnrolledList';
export const ACT_GET_DIGITAL_APPLY_LIST = 'getDigitalApplyList';
export const ACT_GET_GLOBAL_APPLY_LIST = 'getGlobalApplyList';
export const ACT_GET_STAR_LINK_APPLY_LIST = 'getSrarLinkApplyList';
export const ACT_GET_STAR_LINK_FILE_LIST = 'getStarLinkFileList';
export const ACT_GET_CRSE_REG_APLY_HIST_LIST = 'getCrseRegAplyHistList';

export const ACT_GET_CRSE_DIST_FLD = 'getCrseDistFld';
export const ACT_GET_DIGITAL_DTL = 'getDigitalDtl';
export const ACT_GET_STAR_LINK_DTL = 'getStarLinkDtl';
export const ACT_GET_REG_APPLY_ANNUAL = 'getCrseRegAplyAnnual';
export const ACT_GET_GRP_CRSE_DIST_LIST = 'getGrpCrseDistList';
export const ACT_GET_DIGITAL_NOW_LIST = 'getDigitalNowList'

export const ACT_GET_LRN_NEWZ_MEM_LIST = 'actGetLrnNewZMemList';



const state = {};


const mutations = {};

const actions = {
  [ACT_GET_CRSE_DIST_APPLY](context, distCrseSn) {
    return new Promise(resolve => {
      ApiService.get('/v1/app/crses/dists', distCrseSn).then(response => resolve(response))
    });
  },
  [ACT_GET_CRSE_APPLY_LIST](context, params) {
    return new Promise(resolve => {
      ApiService.post('/v1/app/crses/aplies', params).then(response => resolve(response))
    });
  },
  [ACT_GET_CRSE_APPLY_COUNT](context, params) {
    return new Promise(resolve => {
      ApiService.post('/v1/app/crses/aplies/count', params).then(response => resolve(response))
    });
  },
  [ACT_GET_CRSE_DIST_LIST](context, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/crses/dists', params).then(response => resolve(response));
    });
  },
  [ACT_GET_CRSE_REL_LIST](context, distCrseSn) {
    return new Promise(resolve => {
      ApiService.query(`/v1/app/crses/dists/${distCrseSn}/rels`, {}).then(response => resolve(response))
    });
  },
  [ACT_INSERT_CRSE_ACT_LOG](context, params){
    return new Promise(resolve => {
      ApiService.post('/v1/app/crses/acts', params).then(response => resolve(response));
    });
  },
  [ACT_GET_CRSE_FNSH_DIST_LIST](context, distCrseSn){
    return new Promise(resolve => {
      ApiService.query(`/v1/app/crses/dists/${distCrseSn}/fnshs`, {}).then(response => resolve(response));
    });
  },
  [ACT_GET_LRN_OBJ_DTL_DIST_LIST_BY_DIST_CRSE_SN](context, distCrseSn){
    return new Promise(resolve => {
      ApiService.query(`/v1/app/crses/dists/${distCrseSn}/objs`, {}).then(response => resolve(response));
    });
  },
  [ACT_GET_CHAP_DIST_LIST](context, distCrseSn){
    return new Promise(resolve => {
      ApiService.query(`/v1/app/crses/dists/${distCrseSn}/chaps`, {}).then(response => resolve(response));
    });
  },
  [ACT_GET_CRSE_REG_APLY_LIST](context, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/crses/dists/regs', params).then(response => resolve(response));
    });
  },
  [ACT_GET_CRSE_DRAW_LIST](context, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/crses/dists/draws', params).then(response => resolve(response));
    });
  },
  [ACT_GET_CRSE_BPCC_LIST](context, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/crses/dists/bpcc', params).then(response => resolve(response));
    });
  },
  [ACT_INSERT_CRSE_REG_APLY](context, {distCrseSn, params}){
    return new Promise(resolve => {
      ApiService.post(`/v1/app/crses/dists/${distCrseSn}/aplie`, params).then(response => resolve(response));
    });
  },
  [ACT_UPDATE_CRSE_REG_APLY](context, {regCrseAplySn, params}){
    return new Promise(resolve => {
      ApiService.update('/v1/app/crses/dists/aplies', regCrseAplySn, params).then(response => resolve(response));
    });
  },
  [ACT_GET_CRSE_DISP_CATE_LIST](){
    return new Promise(resolve => {
      ApiService.query('/v1/app/crses/disps/cates', {}).then(response => resolve(response));
    });
  },
  [ACT_GET_CRSE_DIST_REGULAR_LIST](context, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/crses/dists/regulars', params).then(response => resolve(response));
    });
  },
  [ACT_INSERT_CRSE_DIST_REGULAR](context, distCrseSn){
    return new Promise(resolve => {
      ApiService.post(`/v1/app/crses/dists/${distCrseSn}/regulars`, {}).then(response => resolve(response));
    });
  },
  [ACT_GET_KNOWLEDGE_LRN_OBJ_DTL_DIST_LIST](){
    return new Promise(resolve => {
      ApiService.query('/v1/app/crses/dists/objs/dtls', {}).then(response => resolve(response));
    });
  },
  [ACT_GET_CRSE_DIST_MENTORING](context, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/crses/dists/mentorings', params).then(response => resolve(response));
    });
  },
  [ACT_GET_CRSE_DIST_GRP_SEQ_LIST](context, {distCrseSn, params}){
    return new Promise(resolve => {
      ApiService.query(`/v1/app/crses/dists/${distCrseSn}/seqs`, params).then(response => resolve(response));
    });
  },
  [ACT_INSERT_CRSE_DIST_GROUP](context, params){
    return new Promise(resolve => {
      ApiService.post(`/v1/app/crses/dists/group`, params).then(response => resolve(response));
    });
  },
  [ACT_INSERT_GROUP_APPLY](context, {distCrseSn, params}){
    return new Promise(resolve => {
      ApiService.post(`/v1/app/crses/dists/group/${distCrseSn}/apply`, params).then(response => resolve(response));
    });
  },
  [ACT_DELETE_GROUP](context, distCrseSn){
    return new Promise(resolve => {
      ApiService.delete(`/v1/app/crses/dists/group`, distCrseSn).then(response => resolve(response));
    });
  },
  [ACT_GET_CRSE_ACT_SUBSCRIBED_LIST](context, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/crses/act/subscribed', params).then(response => resolve(response));
    });
  },
  [ACT_DELETE_CRSE_ACT](context, params){
    return new Promise(resolve => {
      ApiService.post(`/v1/app/crses/act/delete`, params).then(response => resolve(response));
    });
  },
  [ACT_INVITE_GROUP_MEMBER](context, params) {
    return new Promise(resolve => {
      ApiService.post(`/v1/app/crses/group/invite`, params).then(response => resolve(response));
    });
  },
  [ACT_GET_CRSE_ACT_ADD](context, params) {
    return new Promise(resolve => {
      ApiService.query('/v1/app/crses/act/add', params).then(response => resolve(response));
    });
  },
  [ACT_GET_CRSE_DIST_OBJ_APPLY](context, distCrseSn) {
    return new Promise(resolve => {
      ApiService.get('/v1/app/crses/dists/objs', distCrseSn).then(response => resolve(response))
    });
  },
  [ACT_GET_CRSE_DIST_OBJ_LRNER]() {
    return new Promise(resolve => {
      ApiService.query('/v1/app/crses/dists/objs/lrners', {}).then(response => resolve(response))
    });
  },
  [ACT_GET_CRSE_DIST_COND](context, params) {
    return new Promise(resolve => {
      ApiService.query('/v1/app/crses/dists/conds', params).then(response => resolve(response))
    });
  },
  [ACT_GET_CRSE_DIST_DIGITAL_LIST](context, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/crses/dists/digitals', params).then(response => resolve(response));
    });
  },
  [ACT_GET_CRSE_DIST_GLOBAL_LIST](context, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/crses/dists/globals', params).then(response => resolve(response));
    });
  },
  [ACT_GET_CRSE_DIST_STAR_LINK_ENROLLED_LIST](context, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/crses/dists/starLinks/enrolled', params).then(response => resolve(response));
    });
  },
  [ACT_GET_DIGITAL_NOW_LIST](context, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/trgt/digitals/now', params).then(response => resolve(response));
    });
  },
  [ACT_GET_DIGITAL_APPLY_LIST](context, params) {
    return new Promise(resolve => {
      ApiService.post('/v1/app/crses/digitals/aplies', params).then(response => resolve(response))
    });
  },
  [ACT_GET_GLOBAL_APPLY_LIST](context, params) {
    return new Promise(resolve => {
      ApiService.post('/v1/app/crses/global/aplies', params).then(response => resolve(response))
    });
  },
  [ACT_GET_STAR_LINK_APPLY_LIST](context, params) {
    return new Promise(resolve => {
      ApiService.post('/v1/app/crses/starLinks/aplies', params).then(response => resolve(response))
    });
  },
  [ACT_GET_STAR_LINK_FILE_LIST](context, params) {
    return new Promise(resolve => {
      ApiService.query('/v1/app/crses/starLinks/files', params).then(response => resolve(response))
    });
  },
  [ACT_GET_CRSE_REG_APLY_HIST_LIST](context, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/crses/dists/regs/hists', params).then(response => resolve(response));
    });
  },
  [ACT_GET_CRSE_DIST_FLD](){
    return new Promise(resolve => {
      ApiService.query('/v1/app/crses/dists/flds').then(response => resolve(response));
    });
  },
  [ACT_GET_DIGITAL_DTL](context, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/crses/dists/digitals/dtls', params).then(response => resolve(response));
    });
  },
  [ACT_GET_STAR_LINK_DTL](context, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/crses/dists/starLinks/dtls', params).then(response => resolve(response));
    });
  },
  [ACT_GET_REG_APPLY_ANNUAL](context, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/crses/dists/annuals', params).then(response => resolve(response));
    });
  },
  [ACT_GET_GRP_CRSE_DIST_LIST](context, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/crses/dists/grps', params).then(response => resolve(response));
    });
  },
  [ACT_GET_LRN_NEWZ_MEM_LIST](context, params) {
    return new Promise(resolve => {
      ApiService.query('/v1/app/crses/dists/lrnnewzmem', params).then(response => resolve(response))
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
