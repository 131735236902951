<template>
  <main v-if="(modelValue && !collegeModal && !majorModal)" class="kb-main" id="kb-support">
    <!-- main-content -->
    <div class="main-content main-component">
      <div class="support-view-container">
        <div class="view-contents">
          <!-- content-section -->
          <section class="content-section">
            <header class="section-header">
              <h4 class="title">신청정보</h4>
            </header>
            <div class="section-content">
              <MobileApplicant :col-type="true"/>
              <!-- kb-form-fields -->
              <div class="kb-form-fields kb-form-fields-v2 kb-form-fields-divider">
                <!-- kb-form-field:학교구분 -->
                <div class="kb-form-field">
                  <MobileInputSelect
                      v-model:value1="param.schlDivCdDcd"
                      v-model:toggle1="toggles.schlDivCdDcds"
                      :options1="schlDivCdDcdOpt"
                      :disabled="isReadOnly"
                      title="학교구분"
                      :width="358"
                      sequence-key1="cd"
                      name-key1="cdNm"
                      placeholder1="학교구분을 선택해주세요"
                      @update:toggle1="closeToggles(toggles, 'schlDivCdDcds')"
                  />
                </div>
                <div class="kb-form-field">
                  <MobileInputSelect
                      v-model:value1="param.schlInDivCdDcd"
                      v-model:value2="param.termDivCdDcd"
                      v-model:toggle1="toggles.schlInDivCdDcds"
                      v-model:toggle2="toggles.termDivCdDcds"
                      title="학기(학년)재학 구분"
                      :options1="schlInDivCdDcdOpt"
                      :options2="termDivCdDcdOpt"
                      sequence-key1="cd"
                      sequence-key2="cd"
                      name-key1="cdNm"
                      name-key2="cdNm"
                      placeholder1="재학구분"
                      placeholder2="학기"
                      :disabled="isReadOnly"
                      :width="171"
                      @update:toggle1="closeToggles(toggles, 'schlInDivCdDcds')"
                      @update:toggle2="closeToggles(toggles, 'termDivCdDcds')"
                  />
                </div>
                <div v-if="isAcademicCreditBank" class="kb-form-field">
                  <div class="kb-form-row">
                    <label class="kb-form-label">
                      <span class="kb-form-label-text">수강학점</span>
                    </label>
                  </div>
                  <div class="kb-form-row">
                    <input
                        type="text"
                        class="kb-form-control"
                        v-model="param.regCrseCrdit"
                        :disabled="isReadOnly"
                        maxlength="10"
                        @input="numbersOnly"
                        @focusin="emptyNumberOnClick"
                        @focusout="emptyNumberOnClick"
                    />
                  </div>
                  <div class="kb-form-row">
                    <label class="kb-form-label">
                      <span class="kb-form-label-text">학위취득예정시기</span>
                    </label>
                  </div>
                  <div class="kb-form-row">
                    <input
                        type="text"
                        class="kb-form-control"
                        maxlength="20"
                        v-model="param.degAcqsSchedPerid"
                        :disabled="isReadOnly"
                    />
                  </div>
                </div>
                <!-- kb-form-field:학교명 -->
                <div class="kb-form-field">
                  <div class="kb-form-row">
                    <label class="kb-form-label">
                      <span class="kb-form-label-text">학교명</span>
                    </label>
                  </div>
                  <div class="kb-form-row">
                    <div class="kb-form-search" @click="searchSchool('college')">
                      <input type="text" v-model="school.scholNm" class="kb-form-control" placeholder="학교명 검색" disabled />
                      <button class="kb-form-search-btn"><i class="icon-search"></i></button>
                    </div>
                  </div>
                </div>
                <!-- kb-form-field:전공 -->
                <div class="kb-form-field">
                  <div class="kb-form-row">
                    <label class="kb-form-label">
                      <span class="kb-form-label-text">전공</span>
                    </label>
                  </div>
                  <div class="kb-form-search" @click="searchSchool('major')">
                    <input type="text" v-model="major.majorNm" class="kb-form-control" placeholder="전공 검색" disabled />
                    <button class="kb-form-search-btn"><i class="icon-search"></i></button>
                  </div>
                </div>
                <div class="kb-form-field">
                  <MobileRowInputText
                      v-model="param.majorNm2"
                      title="학과"
                      placeholder="학과를 입력해주세요"
                      :disabled="isReadOnly"
                  />
                </div>
                <!-- kb-form-field:등록금 납부일 -->
                <div class="kb-form-field">
                  <RowCustomDatepicker
                      title="등록금 납부일"
                      v-model="param.tuitFeePayDd"
                      v-model:toggle="toggles.tuitFeePayDd"
                      :disabled="isReadOnly"
                      @update:toggle="closeToggles(toggles, 'tuitFeePayDd')"
                  />
                </div>
                <!-- kb-form-field:직전학기 평점평균 -->
                <div class="kb-form-field">
                  <div class="kb-form-row">
                    <label class="kb-form-label">
                      <span class="kb-form-label-text">직전학기 평점평균</span>
                    </label>
                  </div>
                  <div class="kb-form-row">
                    <input
                        type="text" class="kb-form-control"
                        v-model="param.grdAvg"
                        :disabled="isReadOnly"
                        @input="numbersOnly"
                        @focusin="emptyNumberOnClick"
                        @focusout="emptyNumberOnClick"
                    />
                    <div class="kb-form-dropdown left-margin" data-offset="bottom-left">
                      <div class="kb-form-dropdown" :class="{'is-active': toggles.grdTots}">
                        <button class="kb-form-dropdown-btn" @click.stop="toggleGrdTot" :disabled="isReadOnly">
                          <span class="text">{{ param.grdTot }}점</span><i class="icon-arrow"></i>
                        </button>
                        <div class="kb-form-dropdown-options" style="width: 166px;">
                          <div class="dropdown-option">
                            <ul class="dropdown-option-list">
                              <li v-for="(item, idx) in grdTots" class="dropdown-option-item" :key="idx">
                                <a href="javascript:" class="dropdown-option-link" @click.stop="selectGrdTot(idx)"><span class="dropdown-option-text">{{ item.nm }}</span></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- //kb-form-field -->

                <template v-if="param.schlInDivCdDcd === schlInDivCdDcds.SCHL_IN_DIV_CD_OLD">
                  <div class="kb-form-filed">
                      <MobileUpload
                          v-model="transcriptFiles"
                          title="직전학기 성적증명서"
                          placeholder="직전학기 성적증명서를 등록하세요"
                          btn-title="성적증명서 등록하기"
                          sequence-key="proofDataSn"
                          subtext="*1장만 등록가능함, 증명서의 가장자리가 모두 보이도록 촬영"
                          :extensions="extensions"
                          :max-qty="10"
                          :is-payment="true"
                          :disabled="isReadOnly"
                      />
                  </div>
                  <div class="kb-form-filed">
                    <MobileRowInputText
                        v-model="param.inetProofNo"
                        title="인터넷 증명서 발급번호"
                        placeholder="진위여부 확인이 가능한 일련번호를 입력하세요. "
                        :disabled="isReadOnly"
                    />
                    <div class="kb-form-row">
                      <span class="kb-form-subtext">* 인터넷 증명서 발급번호(숫자,영문,- 만 입력가능)</span>
                    </div>
                  </div>
                </template>
              </div>
              <!-- //kb-form-fields -->
            </div>
          </section>

          <section class="content-section">
            <header class="section-header">
              <h4 class="title">납부금 고지서 내역</h4>
            </header>
            <div class="section-content">
              <!-- accordion-container-v2 -->
              <div class="accordion-container-v2">
                <!-- accordion:납부 금액 -->
                <div class="accordion"  :class="{ 'is-active' : showPrice }">
                  <!-- accordion-toggle-action -->
                  <a href="javascript:" class="accordion-toggle-action" @click="showPrice=!showPrice">
                    <div class="accordion-row">
                      <div class="accordion-column">
                        <div class="accordion-title">납부금액</div>
                      </div>
                      <div class="accordion-icon"><i class="icon-arrow"></i></div>
                    </div>
                  </a>
                  <!-- accordion-toggle -->
                  <div class="accordion-target">
                    <div class="accordion-sub">
                      <!-- sub-section:지원 대상 금액 -->
                      <article class="sub-section">
                        <header class="section-header header-divider">
                          <h4 class="title">지원 대상 금액</h4>
                        </header>
                        <div class="kb-form-fields kb-form-fields-v2">
                          <!-- kb-form-field:수업료 ・ 등록금 -->
                          <div class="kb-form-field field-first">
                            <MobileRowInputText
                                v-model="fees.sprtTuitFee"
                                title="수업료 ・ 등록금"
                                :is-number="true"
                                :is-money="true"
                                :is-kb-form-side="true"
                                kb-form-side-text="원"
                                :disabled="isReadOnly"
                            />
                          </div>
                          <!-- kb-form-field:학교운영지원비 (육성/기성회비) -->
                          <div class="kb-form-field">
                            <MobileRowInputText
                                v-model="fees.sprtSchlOperCost"
                                title="학교운영지원비 (육성/기성회비)"
                                :is-number="true"
                                :is-money="true"
                                :is-kb-form-side="true"
                                kb-form-side-text="원"
                                :disabled="isReadOnly"
                            />
                          </div>
                          <!-- kb-form-field:총학생회비 (자치회비) -->
                          <div class="kb-form-field">
                            <MobileRowInputText
                                v-model="fees.sprtTotStdntFee"
                                title="총학생회비 (자치회비)"
                                :is-number="true"
                                :is-money="true"
                                :is-kb-form-side="true"
                                kb-form-side-text="원"
                                :disabled="isReadOnly"
                            />
                          </div>
                          <!-- kb-form-field:실험실습비 등 -->
                          <div class="kb-form-field">
                            <MobileRowInputText
                                v-model="fees.sprtPraceCost"
                                title="실험실습비 등"
                                :is-number="true"
                                :is-money="true"
                                :is-kb-form-side="true"
                                kb-form-side-text="원"
                                :disabled="isReadOnly"
                            />
                          </div>
                          <!-- //kb-form-field -->
                        </div>
                      </article>
                      <!-- sub-section:지원제외 금액 -->
                      <article class="sub-section">
                        <div class="kb-form-fields kb-form-fields-v2">
                          <!-- kb-form-field:고지금액 합계 -->
                          <div class="kb-form-row">
                            <label class="kb-form-label">
                              <span class="kb-form-label-text">고지금액 합계</span>
                            </label>
                          </div>
                          <div class="kb-form-row">
                            <input type="text" class="kb-form-control kb-form-control-fill" placeholder="" disabled="" :value="numberComma(totalPayment)">
                            <div class="kb-form-side"><span class="kb-form-text">원</span></div>
                          </div>
                          <!-- //kb-form-field -->
                        </div>
                      </article>
                      <!-- //sub-section -->
                    </div>
                  </div>
                  <!-- //accordion-toggle -->
                </div>
                <!-- accordion:감면 금액 -->
                <div class="accordion" :class="{ 'is-active' : showReducedPrice }">
                  <!-- accordion-toggle-action -->
                  <a href="javascript:" class="accordion-toggle-action" @click="showReducedPrice=!showReducedPrice">
                    <div class="accordion-row">
                      <div class="accordion-column">
                        <div class="accordion-title">장학금</div>
                      </div>
                      <div class="accordion-icon"><i class="icon-arrow"></i></div>
                    </div>
                  </a>
                  <!-- accordion-toggle -->
                  <div class="accordion-target">
                    <div class="accordion-sub">
                      <!-- sub-section:장학금-->
                      <article class="sub-section">
                        <header class="section-header header-divider">
                          <h4 class="title">장학금</h4>
                        </header>
                        <div class="kb-form-fields kb-form-fields-v2">
                          <!-- kb-form-field:성적 -->
                          <div class="kb-form-field field-first">
                            <MobileRowInputText
                                v-model="reduce.redGradeBursAmt"
                                title="성적"
                                :is-number="true"
                                :is-money="true"
                                :is-kb-form-side="true"
                                kb-form-side-text="원"
                                :disabled="isReadOnly"
                            />
                          </div>
                          <!-- kb-form-field:국가지원-->
                          <div class="kb-form-field">
                            <MobileRowInputText
                                v-model="reduce.redNtnBursAmt"
                                title="국가지원"
                                :is-number="true"
                                :is-money="true"
                                :is-kb-form-side="true"
                                kb-form-side-text="원"
                                :disabled="isReadOnly"
                            />
                          </div>
                          <!-- kb-form-field:감면금액 합계 -->

                          <!-- kb-form-field:기타 -->
                          <div class="kb-form-field">
                            <MobileRowInputText v-model="reduce.etcBursAmt" class-lst="column-auto" placeholder="금액을 입력하세요." :is-number="true" :is-money="true" :is-kb-form-side="true" kb-form-side-text="원" :disabled="isReadOnly">
                              <template v-slot:first>
                                <div class="kb-form-column kb-form-column-title">
                                  <input type="text" class="kb-form-control me-3" v-model="reduce.etcBursAmtNm" placeholder="기타 장학금명을 입력하세요"/>
                                </div>
                              </template>
                            </MobileRowInputText>
                            <!-- MobileRowInputText
                                v-model="reduce.nsprtEtcAmt"
                                title="기타"
                                :is-number="true"
                                :is-money="true"
                                :is-kb-form-side="true"
                                kb-form-side-text="원"
                                :disabled="isReadOnly"
                            / -->
                          </div>
                          <div class="kb-form-field">
                            <div class="kb-form-row">
                              <label class="kb-form-label">
                                <span class="kb-form-label-text">감면금액 합계</span>
                              </label>
                            </div>
                            <div class="kb-form-row">
                              <input type="text" class="kb-form-control kb-form-control-fill" placeholder="" :value="numberComma(totalReduce)" disabled>
                              <div class="kb-form-side"><span class="kb-form-text">원</span></div>
                            </div>
                          </div>
                          <!-- //kb-form-field -->
                        </div>
                      </article>
                      <!-- //sub-section -->
                    </div>
                  </div>
                  <!-- //accordion-toggle -->
                </div>
                <!-- //accordion -->
              </div>
              <!-- kb-form-fields -->
              <div class="kb-form-fields kb-form-fields-v2 mt-3">
                <!-- kb-form-field:지원대상 금액 -->
                <div class="kb-form-field">
                  <div class="kb-form-row">
                    <label class="kb-form-label">
                      <span class="kb-form-label-text">지원대상 금액</span>
                    </label>
                  </div>
                  <div class="kb-form-row">
                    <input type="text" class="kb-form-control" placeholder="지원대상 금액을 입력하세요" :value="numberComma(param.sprtExptAmt)" readonly>
                    <div class="kb-form-side">
                      <span class="kb-form-text">원</span>
                    </div>
                  </div>
                </div>
                <!-- kb-form-field:직전학기 학비지원 후 장학금 수혜 금액 -->
                <div class="kb-form-field">
                  <div class="kb-form-row">
                    <label class="kb-form-label">
                      <span class="kb-form-label-text">직전학기 학비지원 후 장학금 수혜 금액</span>
                    </label>
                  </div>
                  <div class="kb-form-row">
                    <input type="text" class="kb-form-control" placeholder="장학금 수혜 금액을 입력하세요" :value="numberComma(param.befTermBursAmt)" readonly>
                    <div class="kb-form-side"><span class="kb-form-text">원</span></div>
                  </div>
                </div>
                <!-- kb-form-field:지원금액 -->
                <div class="kb-form-field">
                  <div class="kb-form-row">
                    <label class="kb-form-label">
                      <span class="kb-form-label-text">지원금액</span>
                    </label>
                  </div>
                  <div class="kb-form-row">
                    <input type="text" class="kb-form-control" placeholder="지원금액을 입력하세요" :value="numberComma(param.sprtAmt)" readonly>
                    <div class="kb-form-side"><span class="kb-form-text">원</span></div>
                  </div>
                </div>
                <!-- kb-form-field:등록금 납입증명서 -->
                <div class="kb-form-field">
                  <MobileUpload
                      v-model="tuitPayCertFiles"
                      title="등록금 납입증명서"
                      placeholder="등록금 납입증명서를 등록하세요"
                      btn-title="등록금 납입증명서 등록하기"
                      :extensions="extensions"
                      :max-qty="10"
                      sequence-key="proofDataSn"
                      :disabled="isReadOnly"
                  />
                </div>
                <!-- //kb-form-field -->
              </div>
            </div>
          </section>


          <!-- content-section:결과등록 -->
          <section class="content-section">
            <header class="section-header">
              <h4 class="title">결과등록</h4>
            </header>
            <div class="section-content">
              <div class="kb-form-fields kb-form-fields-v2 kb-form-fields-divider">
                <!-- kb-form-field:결과등록 -->
                <div class="kb-form-field field-first">
                  <div class="kb-form-row">
                    <div class="kb-form-check-group">
                      <div class="kb-form-check">
                        <input v-model="param.bursAmtBnfitYn"  type="radio" class="kb-form-check-input" name="chk" id="chk_01" value="Y">
                        <label for="chk_01" class="kb-form-check-label">
                          <span class="label-text">학비지원 후 장학금 수혜</span>
                        </label>
                      </div>
                      <div class="kb-form-check">
                        <input  v-model="param.bursAmtBnfitYn" type="radio" class="kb-form-check-input" name="chk" id="chk_02" value="N" checked="">
                        <label for="chk_02" class="kb-form-check-label">
                          <span class="label-text">학비지원 후 장학금 비수혜</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- kb-form-field:장학금 비수혜증명서 -->
                <div class="kb-form-field">
                  <MobileUpload
                      v-model="schlScholarCertFiles"
                      :title="param.bursAmtBnfitYn === 'Y' ? '장학금 수혜 증명서' : '장학금 비수혜 증명서'"
                      :placeholder="`${param.bursAmtBnfitYn === 'Y' ? '장학금 수혜' : '장학금 비수혜'} 증명서를 등록해주세요.`"
                      btn-title="증명서 등록하기"
                      sequence-key="proofDataSn"
                      :extensions="extensions"
                      :max-qty="10"
                      :is-payment="true"
                      :key="`tuition`"
                  />
                  <template v-if="param.bursAmtBnfitYn === 'Y'">
                    <RowCustomDatepicker
                        title="입금일"
                        v-model="param.dpstYmd"
                        v-model:toggle="toggles.dpstYmd"
                        @update:toggle="closeToggles(toggles, 'dpstYmd')"
                    />
                    <MobileRowInputText
                        v-model="reduce.dpstBankNm"
                        title="입금은행명"
                        placeholder="입금은행명을 입력해주세요."
                    />
                    <MobileRowInputText
                        v-model="reduce.dpstActno"
                        title="입금계좌번호"
                        placeholder="입금계좌번호를 입력해주세요."
                    />
                    <MobileRowInputText
                        v-model="reduce.bursAmtAmt"
                        title="장학금 금액"
                        placeholder="장학금 금액을 입력하세요."
                        :is-number="true"
                        :is-money="true"
                        :is-kb-form-side="true"
                        kb-form-side-text="원"
                    />
                  </template>
                </div>
                <!-- //kb-form-field -->
              </div>
            </div>
          </section>

          <section class="content-section" v-if="param.rtnYn=='Y' || param.addAmtYn=='Y'">
            <header class="section-header header-divider">
              <h4 class="title">환입 및 추가지원금액</h4>
            </header>
            <div class="section-content">
              <div class="kb-form-fields kb-form-fields-v2" v-if="param.rtnYn=='Y'">
                <div class="kb-form-row">
                  <label class="kb-form-label">
                    <span class="kb-form-label-text">환입금액</span>
                  </label>
                </div>
                <div class="kb-form-row">
                  <input type="text" class="kb-form-control"  :value="numberComma(param.rtnAmt)" readonly>
                  <div class="kb-form-side">
                    <span class="kb-form-text">원</span>
                  </div>
                </div>
              </div>
              <div class="kb-form-fields kb-form-fields-v2" v-if="param.addAmtYn=='Y'">
                <div class="kb-form-row">
                  <label class="kb-form-label">
                    <span class="kb-form-label-text">추가지원금액</span>
                  </label>
                </div>
                <div class="kb-form-row">
                  <input type="text" class="kb-form-control" :value="numberComma(param.addAmt)" readonly>
                  <div class="kb-form-side">
                    <span class="kb-form-text">원</span>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="content-section" v-if="data == null">
            <UserAgreementMobile
                v-model:user-agreement="userAgreement"
                @applyBtnEnable="applyBtnEnable"
            />
<!--            <header class="section-header">-->
<!--              <h4 class="title">증빙서류 제출시 유의사항</h4>-->
<!--            </header>-->
<!--            <div class="section-content">-->
<!--              <div class="content-item">-->
<!--                <div class="desc">-->
<!--                  <p class="text">- 학비지원은 직원들의 자기 개발을 효과적으로 지원하기 위하여 실시하는 제도로서 학비 지원 신청과 관련하여 아래와 같은 부적절한 사항 발생 시에는 ‘지원신청 오류 사유서 작성, 해당학기 부당지원금 환입 및 향후 학비 지원 제한, 각종 연수 참가 제한‘ 등 단계별 조치 사항을 실시하고 있습니다.</p>-->
<!--                  <p class="text">&nbsp;</p>-->
<!--                  <p class="text">&lt;부적절한 사항&gt;</p>-->
<!--                  <p class="text">◉직원 본인 명의로 학비를 지원받았으나 가족 등 제 3자가 수강하는 경우</p>-->
<!--                  <p class="text">◉수강등록만 하고 수업에 정상적으로 참여하지 않는 등 연수비용이 낭비 되는 경우</p>-->
<!--                  <p class="text">◉신청서류가 완비되지 않은 상태에서 신청하는 경우</p>-->
<!--                  <p class="text">※학비 신청시 필수 서류 : 등록금 납입증명서, 성적증명서</p>-->
<!--                  <p class="text">◉기타 자기주도학습 지원제도의 취지와 부적합하게 지원 신청된 경우 등</p>-->
<!--                  <p class="text">◉관련 증빙서류 (성적증명서, 등록금납입증명서 등)위∙변조행위</p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="content-item">-->
<!--              <label for="chk_agree_03" class="item-agree">-->
<!--                상기내용을 숙지하였으며 동의합니다.-->
<!--                <div class="kb-form-check"><input v-model="userAgreement" type="checkbox" class="kb-form-check-input" id="chk_agree_03"></div>-->
<!--              </label>-->
<!--            </div>-->
          </section>
        </div>
        <MobileSupportButton
            :stt="data && data.stt"
            :aply-stt-cd-dcd="data && data.aplySttCdDcd"
            :apply-btn-enable="applyBtnEnable"
            :user-agreement="userAgreement"
            :apply="apply"
            :cancel="cancel"
            :close-modal="closeModal"
            type="school"
        />
      </div>
    </div>
    <!-- //main-content -->
  </main>

  <MobileLicenseSearch
      v-if="collegeModal"
      v-model="collegeModal"
      v-model:selectedValue="school"
      list-type="college"
      select-button-text="학교선택"
      placeholder="학교검색"
  />
  <MobileLicenseSearch
      v-if="majorModal"
      v-model="majorModal"
      v-model:selectedValue="major"
      list-type="major"
      select-button-text="전공선택"
      placeholder="전공검색"
  />
</template>

<script>
import {supportTuitionSetup} from "@/assets/js/modules/support/support-tuition-setup";
import MobileLicenseSearch from "@/components/support/mobile/MobileSchoolSearch";
import MobileUpload from "@/components/support/common/MobileUpload";
import MobileRowInputText from "@/components/support/common/mobile/MobileRowInputText";
import MobileInputSelect from "@/components/support/common/mobile/MobileInputSelect";
import RowCustomDatepicker from "@/components/support/common/RowCustomDatepicker";
import MobileApplicant from "@/components/support/mobile/MobileApplicant";
import MobileSupportButton from "@/components/support/common/mobile/MobileSupportButton";
import UserAgreementMobile from "@/components/support/common/mobile/UserAgreementMobile";

export default {
  name: "SupportTuitionMobileModal",
  components: {
    MobileSupportButton, MobileApplicant,
    RowCustomDatepicker, MobileInputSelect, MobileRowInputText, MobileUpload, MobileLicenseSearch, UserAgreementMobile},
  props: {
    modelValue: Boolean,
    data: {type:Object, default:null},
    reList : Function
  },
  setup: supportTuitionSetup
}
</script>