<template>
  <SSLMobileView
      v-if="currentName === 'SSLView'"
      @acted="acted"
  />

  <main v-else class="kb-main" id="kb-prep" >
    <div class="main-content">
      <div class="ssl-body pb-0" :id="mainId">
        <SSLMobileSlotHeader
            :hide-title="toggles.member || toggles.search"
        >
          <template v-slot:title>
            <SSLMobileNav />
          </template>
          <template v-slot:left>
            <template v-if="toggles.member">
              <div class="util util-history-back" @click="closeMainToggle('member')">
                <a href="javascript:" class="util-actions util-actions-history-back">
                  <i class="icon-history-back"></i>
                </a>
              </div>
              <div class="util util-search-input">
                <input v-model.trim="memberKeyword" type="text" :placeholder="`참가${distCrseCd === 'group' ? '그룹' : '인원'} 검색`" @keyup.enter="searchMember">
              </div>
            </template>
            <template v-else-if="toggles.search">
              <div class="util util-search-input">
                <div class="util util-history-back" @click="closeMainToggle('search')">
                  <a href="javascript:" class="util-actions util-actions-history-back">
                    <i class="icon-history-back"></i>
                  </a>
                </div>
                <input v-model="keyword" type="text" :placeholder="`${mainName} 검색`" @keyup.enter="searchFunc">
              </div>
            </template>
            <div v-else class="util util-history-back">
              <router-link :to="{name: 'SSLHome'}" class="util-actions util-actions-history-back">
                <i class="icon-history-back"></i>
              </router-link>
            </div>
          </template>

          <template v-slot:right>
            <div v-if="toggles.member" class="util util-search" @click="searchMember">
              <a href="javascript:" class="util-actions util-actions-search">
                <i class="icon-search"></i>
              </a>
            </div>
            <div v-else-if="toggles.search" class="util util-search" @click="searchFunc">
              <a href="javascript:" class="util-actions util-actions-search">
                <i class="icon-search"></i>
              </a>
            </div>

            <template v-else>
              <div v-if="!isNoneReward" class="util util-member">
                <a href="javascript:" class="util-actions util-actions-member" @click="toggles.member = true">
                  <i class="icon-member"></i>
                </a>
              </div>

              <div class="util util-more">
                <div class="dropdown" :class="{'is-active': toggles.nav}" data-offset="header-left">
                  <button class="dropdown-btn" @click="toggles.nav = !toggles.nav"><i class="icon-more"></i></button>
                  <div class="dropdown-target" style="width: 144px;left: -104px;">
                    <div class="dropdown-box">
                      <ul class="dropdown-option-list">
                        <li class="dropdown-option-item">
                          <SSLTrial
                              trial-class="dropdown-option-link"
                              text-class="dropdown-option-text"
                          />
                        </li>
                        <li class="dropdown-option-item">
                          <SSLManual
                              manual-class="dropdown-option-link"
                              text-class="dropdown-option-text"
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </template>
        </SSLMobileSlotHeader>

        <SSLMobileSubNav/>

        <template v-if="!toggles.member">
          <SSLMobileMainNotice :notices="notices"/>
          <SSLMobileMainMy
              :main-name="mainName"
              :goMySSL="goMySSL"
          />
        </template>

        <div class="page-container">
          <SSLMobileMember
              v-if="toggles.member"
              :lrn-trgt-grp-mst-sn="lrnTrgtGrpMstSn"
          />
          <SSLMobileBoardList
              v-else
              :is-loading="isLoading"
              :lrn-trgt-grp-mst-sn="lrnTrgtGrpMstSn"
              :items="items"
              :paging="paging"
              :more-func="moreFunc"
              :show-filter="true"
              :view-modes="viewModes"
              :view-mode-idx="viewModeIdx"
              :open-search="() => {toggles.search = true;}"
              @moveBoard="moveBoard"
          />
        </div>
      </div>
    </div>
  </main>

</template>
<script>
import SSLMobileBoardList from '@/components/ssl/main/mobile/SSLMobileBoardList';
import SSLMobileMainNotice from '@/components/ssl/main/mobile/SSLMobileMainNotice';
import SSLMobileMainMy from '@/components/ssl/main/mobile/SSLMobileMainMy';
import SSLMobileMember from '@/components/ssl/main/mobile/SSLMobileMember';
import SSLMobileView from '@/views/ssl/mobile/SSLMobileView';
import {sslMainSetup} from '@/assets/js/modules/ssl/setup/ssl-main-setup';
import SSLMobileSlotHeader from '@/components/ssl/main/mobile/SSLMobileSlotHeader';
import SSLTrial from '@/components/ssl/common/SSLTrial';
import SSLManual from '@/components/ssl/common/SSLManual';
import SSLMobileNav from '@/components/ssl/mobile/SSLMobileNav';
import SSLMobileSubNav from '@/components/ssl/mobile/SSLMobileSubNav';

export default {
  name: 'SSLMobileMain',
  components: {
    SSLMobileSubNav,
    SSLMobileNav,
    SSLManual,
    SSLTrial,
    SSLMobileSlotHeader,
    SSLMobileView,
    SSLMobileMember,
    SSLMobileMainMy,
    SSLMobileMainNotice,
    SSLMobileBoardList,
  },
  setup: sslMainSetup
}
</script>
