<template>

  <div class="content-section-card card-major">
<!--    <div class="card-column card-goal">-->
    <article class="card-column card-goal">

      <span class="card-header">
        <label class="header-title">
          <h4 class="subtext">[오픈 이벤트] “함께, 다 함께” 신청해요!</h4>
          <h4 class="subtitle count">신청인원<br v-if="!isMobile"><span v-if="isMobile"> : </span>{{numberComma(applyCnt)}}명</h4>
        </label>
        <span class="subtext-dtl">
          <template v-if="isMobile">
            <table>
                <colgroup>
                  <col style="width:60px;" />
                  <col style="width:25px;" />
                  <col style="width:auto;" />
                </colgroup>
              <tr>
                <td style="width:95px;vertical-align: top" colspan="2">■ 달성 요건:</td>
                <td style="width:auto">미션1 + 미션2 모두 완료 →<br></td>
              </tr>
              <tr>
                <td colspan="3" style="padding-bottom: 7px">신청인원에 따라 달성직원 대상 인센티브 지급</td>
              </tr>
              <tr>
                <td style="width:60px;vertical-align: top">- 미션1:</td>
                <td colspan="2">신청기간(3.11~3.24) 내 SSL 1개 과정 이상 신청</td>
              </tr>
              <tr>
                <td style="width:50px;vertical-align: top">- 미션2:</td>
                <td colspan="2">1주차 학습기간(3.25~3.31) 내 학습노트 1개 이상 작성</td>
              </tr>
              <tr>
                <td colspan="3">※ 자세한 내용은 상단 시행문 참조</td>
              </tr>
            </table>
          </template>
          <template v-else>
            ■ 달성 요건 : 미션1 + 미션2 모두 완료 → 신청인원에 따라 달성직원 대상 인센티브 지급<br>
            - 미션1 : 신청기간(3.11~3.24) 내 SSL 1개 과정 이상 신청<br>
            - 미션2 : 1주차 학습기간(3.25~3.31) 내 학습노트 1개 이상 작성<br>
            ※ 자세한 내용은 상단 시행문 참조
          </template>
        </span>
      </span>
      <!-- running-level-fields -->
      <div class="running-level-fields">
        <!-- running-level-container -->
        <div class="running-level-container" :data-level="hope">
          <div class="level-bar-txt" :style="{'width': `${wd}%`}"><i class="icon-running"></i><!-- span style="float:right" v-if="applyCnt>10">{{numberComma(applyCnt)}}<span v-if="applyCnt>=130">명</span><span v-if="applyCnt>=200">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span --></div>
<!--/*          <div class="level-bar-txt" style="width: 90%"><i class="icon-running"></i>&lt;!&ndash; span style="float:right" v-if="applyCnt>10">{{numberComma(applyCnt)}}<span v-if="applyCnt>=130">명</span><span v-if="applyCnt>=200">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span &ndash;&gt;</div>*/-->
<!--          <div class="level-bar-txt" :style="{'width': `${wd}%`}"><i class="icon-running"></i>&lt;!&ndash; span style="float:right" v-if="applyCnt>10">{{numberComma(applyCnt)}}<span v-if="applyCnt>=130">명</span><span v-if="applyCnt>=200">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span &ndash;&gt;</div>-->

          <ul class="level-steps_ndot">
            <li class="level-step_ndot_bf">
  <!--            <div class="step-dot"></div>-->
              <div class="step-content">
                <span class="title"></span>
                <span class="title-sub"></span>
              </div>
            </li>
            <li class="level-step">
  <!--            <div class="step-dot"></div>-->
              <div class="step-content" style="text-align: center"><span class="title step-text" style="color:#000">3,500명</span>
                <span class="title-sub coffee" v-if="isMobile">커피<br>쿠폰×1</span>
                <span class="title-sub coffee" v-else>커피쿠폰×1</span>
              </div>
            </li>
            <li class="level-step" style="text-align: right">
              <div class="step-dot"></div>
              <div class="step-content" style="text-align: center"><span class="title step-text" :style="applyCnt>=4000?'color:rgba(195, 24, 75, 1)':'color:#000'">4,000명</span>
                <span class="title-sub coffee" v-if="isMobile">커피<br>쿠폰×2</span>
                <span class="title-sub coffee" v-else>커피쿠폰×2</span>
                 <!--v-if="talentLevel === 3" -->
              </div>
            </li>
            <li class="level-step_ndot">
              <div class="step-dot"></div>
              <div class="step-content" style="text-align: center">
                <span class="title"></span>
                <span class="title-sub coffee dessert">커피2잔<br>+디저트쿠폰</span>
                 <!-- v-if="talentLevel === 4" -->
              </div>
            </li>
          </ul>
        </div>
      </div>
    </article>
  </div>

</template>

<script>
// import '@C:/workspace/pea-p-entt-app/src/assets/lxp/css/page.tarentboard.css'
import {computed, ref} from "vue";
import {numberComma} from "@/assets/js/util";
import {useStore} from "vuex";


export default {
  name: 'ApplyGoal',
  methods:{numberComma},
  props: {
    applyCnt: Number
  },
  setup(props) {
    const store = useStore();
    const isMobile = computed(() => store.state.auth.isMobile);
    const talentLevel = ref(3);
    const wd = computed(()=> {
      if (props.applyCnt > 4000){
        return 66+(33*(props.applyCnt-4000)/1000);
      }if(props.applyCnt == 4000){
        return 66;
      }else if(props.applyCnt > 3500 && props.applyCnt < 4000){
        return 33+(33*(props.applyCnt-3500)/500);
      }else if(props.applyCnt == 3500){
        return 33;
      }else{
        if(props.applyCnt < 90){
          return 2;
        }else {
          return props.applyCnt * 33 / 3500;
        }
      }
    });

    const hope = computed(()=> {
      if(props.applyCnt >= 4000){
        return 2;
      }else if(props.applyCnt >= 3500){
        return 1;
      }else{
        return 0;
      }
    });

    return {
      talentLevel,
      hope,
      wd,
      props,
      isMobile
    }
  }
}
</script>