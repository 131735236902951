<template>
  <!-- board-alert -->
  <div class="board-alert" :class="{'is-active' : isActive}" id="alert-notice">
    <div class="kb-alert-box">
      <div class="kb-alert-icon"><i class="icon-notice"></i></div>
      <div v-for="(item, idx) in items" :key="idx" class="kb-alert-content">
        <h3 class="content-title">
          <router-link :to="{name: 'TrainNotiView', params: {trainNotiSn: item.notiTrsmSn}}" class="title-link">{{ item.notiTitle }}</router-link>
        </h3>
        <div class="content-meta"><span class="text">{{ item.notiTyNm }}</span><span class="text">{{ item.notiTrsmCmptnDt }}</span></div>
      </div>
      <div class="kb-alert-nav" @click="clickNext"><button class="kb-btn kb-btn-primary kb-btn-nav"><span class="text">다음 {{ paging.pageNo }}/ {{ paging.totalCount }}</span></button></div>
      <div class="kb-alert-close" @click="clickCose"><a href="javascript:" class="close-link">닫기</a></div>
    </div>
  </div>
  <!-- //board-alert -->
</template>

<script>
import {ref} from 'vue';
import {useStore} from 'vuex';
import {ACT_GET_NOTI_TRSM_BAR_LIST} from '@/store/modules/notification/notification';
import {getItems, getPaging, lengthCheck, timestampToDateFormat} from '@/assets/js/util';

export default {
  name: "NotiAlertBar",
  setup() {
    const store = useStore();
    // const route = useRoute();
    const isActive = ref(false);

    const items = ref([]);
    const paging = ref({pageNo: 1, pageSize: 1, totalCount: 0});

    const clickNext = () => {
      if(paging.value.totalCount > paging.value.pageNo) {
        paging.value.pageNo++;
      } else {
        paging.value.pageNo = 1;
      }

      getNotiTrsm();
    }

    const clickCose = () => {
      isActive.value = false;
    }

    const getNotiTrsm = () => {
      store.dispatch(`notification/${ACT_GET_NOTI_TRSM_BAR_LIST}`,{
        pageNo: paging.value.pageNo,
        pageSize: paging.value.pageSize
      }).then(res => {
        if(lengthCheck(res)) {
          items.value = getItems(res).map(x => ({
            ...x,
            notiTrsmCmptnDt: timestampToDateFormat(x.notiTrsmCmptnDt, 'yyyy-MM-dd')
          }));
          paging.value = getPaging(res);
          isActive.value = true;

          setTimeout(() => {
            isActive.value = false;
          }, 5000);

        } else {
          items.value = [];
          isActive.value = false;
        }

      }).catch(e => {
        console.error(e);
        items.value = [];
        isActive.value = false;
      })
    }

    // watch(() => route.query, ()=> {
    //   paging.value.pageNo = 1;
    //   getNotiTrsm();
    // });

    // watch(() => isActive.value, ()=>{
    //   console.log(isActive.value)
    //   if(isActive.value === false) {
    //     timeout.value = setInterval(()=>{
    //       console.log('setInterval')
    //       getNotiTrsm();
    //     }, 1000*10);
    //   } else {
    //     clearInterval(timeout.value);
    //     timeout.value = null;
    //   }
    // });


    getNotiTrsm();

    return {
      clickNext,
      clickCose,
      items,
      paging,
      isActive
    }
  }
}
</script>