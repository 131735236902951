<template>
  <div v-if="!isMobile" class="popup-container" :class="{ 'is-active' : modelValue }">
    <div class="popup" id="popup-hrdcolud-h0301" style="flex: 0 0 600px;">
      <div class="popup-inner">
        <header class="popup-header">
          <h3 class="title" style="color: #545045;">{{ title }}</h3>
        </header>
        <div class="popup-content">
          <section class="popup-section">
            <img v-if="data.thumb == null" src="@/assets/lxp/images/ssl/myssl/img_book_kb.jpg" style="width: 150px; height: 200px;" alt="" />
            <img v-else :src="data.thumb" style="width: 150px; height: 200px;" alt="" />
            <header class="section-header">
              <h4 class="title" style="margin: 10px; color: #545045;">페이지 :
                <input v-model="param.page" @blur="updatePage()" @keyup.enter="updatePage()" placeholder="입력" style="width: 45px;" /> / {{data.totalPage}}
              </h4>
            </header>
            <div style="font-size: 60px; margin: 20px; color: #545045;">
              {{ stopwatch }}
            </div>

            <button v-show="!isplay" @click="playFunc()">
              <img src="@/assets/lxp/images/ssl/myssl/play.png" style="width: 80px; height: 80px;" alt=""/>
            </button>
            <button v-show="isplay" @click="pauseFunc()">
              <img src="@/assets/lxp/images/ssl/myssl/pause.png" style="width: 80px; height: 80px;" alt=""/>
            </button>
          </section>
        </div>
        <div class="popup-buttons" style="margin-top: 20px;">
          <a href="javascript:" class="kb-btn kb-btn-primary kb-btn-lg" @click="insertBookActLog()"><span class="text">저장</span></a>
        </div>
      </div>
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click.stop="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
  </div>
  <div v-else>
    <HrdCloudMobileHeader
        :show-back="true"
        :go-back-func="closeModal"
        :title="title"
    >
      <template v-slot:right>
        <div class="util util-btn">
          <a href="javascript:" class="util-actions util-actions-btn text-primary" @click="insertBookActLog()">저장</a>
        </div>
      </template>
    </HrdCloudMobileHeader>
<!--    <div style="font-weight: bold; font-size: 40px; text-align: center; margin-top: 0px;"> 학습 타이머</div>-->
    <div>
      <div style="font-weight: bold; font-size: 15px; text-align: center; margin-top: 30px; color: #545045;">페이지 : <input v-model="param.page" @blur="updatePage()" @keyup.enter="updatePage()" placeholder="입력" style="width: 45px; font-weight: bold;" /></div>
      <div style="font-weight: bold; font-size: 70px; text-align: center; margin-top: 10px; color: #545045;"> {{ stopwatch }}</div>

      <div style="text-align: center; margin: 20px;">
        <button v-show="!isplay" @click="playFunc()" style="text-align: center;">
          <img src="@/assets/lxp/images/ssl/myssl/play.png" style="width: 150px; height: 150px; text-align: center;" alt=""/>
        </button>
        <button v-show="isplay" @click="pauseFunc()">
          <img src="@/assets/lxp/images/ssl/myssl/pause.png" style="width: 150px; height: 150px; text-align: center;" alt=""/>
        </button>
      </div>
      <div style="text-align: center;">
        <img style="width: 200px; height: 300px; margin-bottom: 10px;" :src="data.thumb" alt="" />
        <div style="font-weight: bold; font-size: 15px; margin-bottom: 10px;">{{ data.bookNm }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, reactive, ref} from 'vue';
// import RowInputText from '@/components/support/common/RowInputText';
// import Upload from '@/components/support/common/Upload';
// import InputSelect from '@/components/support/common/InputSelect';
import {closeToggles} from '@/assets/js/ui.init';
// import {cttTableNm, } from '@/assets/js/modules/hrd/hrd-common';
import {useAlert} from '@/assets/js/modules/common/alert';
// import {MUT_SHOW_HRD_BADGE} from '@/store/modules/hrdcloud/hrdcloud';
// import {getHrdBadgeList} from '@/assets/js/modules/hrd/hrd-badge';
import {ACT_INSERT_BOOK_ACT_LOG} from "@/store/modules/book/book";
import {useStore} from "vuex";
import navigationUtils from "@/assets/js/navigationUtils";
import HrdCloudMobileHeader from "@/components/hrdcloud/mobile/HrdCloudMobileHeader.vue";
import {MUT_SHOW_BADGE} from "@/store/modules/ssl/ssl-index";
import {getBadgeList} from "@/assets/js/modules/ssl/ssl-badge";

export default {
  name: 'SSLMyTimerModal',
  components: {HrdCloudMobileHeader},
  props: {
    modelValue: Boolean,
    data: Object
  },
  setup(props, {emit}) {
    const {showMessage} = useAlert();

    const stopwatch = ref('00:00:00');
    // const time = ref(0);
    const isplay = ref(false);
    const isMobile = navigationUtils.any();
    const title = ref('');

    if (props.data.bookNm == null) {
      title.value = '타이머 (자유도서)';
    } else {
      title.value = '타이머 (' + props.data.bookNm + ')';
    }

    const updatePage = () => {

      if (param.page == '') {
        showMessage('페이지를 입력 바랍니다.');
        param.page = '';
        return ;
      }
      // 숫자 아닐때
      if (isNaN(param.page)) {
        showMessage('페이지에는 숫자만 입력 가능합니다.');
        param.page = '';
        return ;
      }

      if (param.page > props.data.totalPage) {
        param.page = props.data.totalPage;
      } else if (param.page <= 0) {
        param.page = 0;
      }

      if (param.page === props.data.currentPage) {
        return ;
      }
      param.page = Number(param.page);
    }

    const param = reactive({
      // bookActLogSn: '',
      socBookSn: props.data.socBookSn,
      lrnTrgtGrpMstSn: props.data.lrnTrgtGrpMstSn,
      lrnTrgtGrpNm: props.data.lrnTrgtGrpNm,
      actTyCdDcd: '2001038',
      actTyNm: '북러닝 타이머',
      page: '',
      memo: '',
      prgrsSec: 0,
      bgngDt: '',
      endDt: '',
      deptCd: props.data.deptCd,
      deptNm: props.data.deptNm,
      lrnerNm: props.data.lrnerNm
    });

    const cnSz = computed(() => {
      return (param.inqCn && param.inqCn.length ? param.inqCn.length : 0 );
    });

    const closeModal = () => {
      emit('update:modelValue', false);
    };

    const startPlay = ref(null);

    const store = useStore();

    const insertBookActLog = () => {
      if (!param.prgrsSec > 0) {
        showMessage('타이머 작동 후 저장이 가능합니다.');
        return ;
      }
      if (param.page == '') {
        showMessage('현재 페이지를 입력 바랍니다.');
        return ;
      }
      pauseFunc();
      store.dispatch(`book/${ACT_INSERT_BOOK_ACT_LOG}`, param).then(res => {
        if (res && res.bookActLogSn > 0) {
          showMessage('학습 타이머 이력이 저장되었습니다.');
          closeModal();
          if(res.badges && res.badges.length > 0){
            store.commit(`ssl/${MUT_SHOW_BADGE}`, res.badges);
            getBadgeList();
          }
        } else {
          showMessage('저장을 실패했습니다.<br> 관리자에게 문의바랍니다.');
          closeModal();
        }
      })
    }

    const playFunc = () => {
      isplay.value = true;
      if (param.bgngDt === '') {
        param.bgngDt = new Date();
      }

      startPlay.value = setInterval(() => {
        param.prgrsSec += 1;
        getTimeFormatString();
      }, 1000);
    }

    const pauseFunc = () => {
      isplay.value = false;
      param.endDt = new Date();
      clearInterval(startPlay.value);
    }

    const getTimeFormatString = () => {
      let hour = parseInt(String(param.prgrsSec / (60 * 60)));
      let min = parseInt(String((param.prgrsSec - (hour * 60 * 60)) / 60));
      let sec = param.prgrsSec % 60;

      stopwatch.value = String(hour).padStart(2, '0') + ":" + String(min).padStart(2, '0') + ":" + String(sec).padStart(2, '0');
    }


    return {
      param, cnSz,
      closeModal, closeToggles,
      stopwatch,playFunc,pauseFunc,isplay,updatePage,
      insertBookActLog, isMobile, title
    }
  }
};
</script>