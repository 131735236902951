<template>
  <div class="board-view-top view-component" style="justify-content: flex-end">
    <div v-if="isMyLrnTrgt" class="ssl-action">
      <div class="ssl-action-column">
        <button type="button" class="btn kb-btn kb-btn-secondary kb-btn-sm rounded-lg" :class="{'is-active': isSubscribe}" @click="toggleSubscribe">
          <i class="icon-board-follow"></i>
          <span class="text">{{ isSubscribe ? '구독중' : '구독' }}</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import {sslLearnerSubscribeSetup} from '@/assets/js/modules/ssl/setup/ssl-learn-setup';

export default {
  name: 'SSLLearnerSubscribe',
  props: {
    lrner: Object
  },
  setup: sslLearnerSubscribeSetup
}
</script>
