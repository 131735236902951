<template>
  <div class="kb-form-fields">
    <div class="kb-form-field field-border-0">
      <div class="kb-form-row">
        <label class="kb-form-label"><span class="kb-form-label-text">제목</span><span class="color-red">*</span></label>
      </div>
      <div class="kb-form-row">
        <input type="text" v-model.trim="cttMstParam.cttTitle" class="kb-form-control" placeholder="제목을 입력하세요."/>
      </div>
    </div>
    <div class="kb-form-field">
      <div class="kb-form-row">
        <label class="kb-form-label"><span class="kb-form-label-text">분야</span><span class="color-red">*</span></label>
      </div>
      <div class="kb-form-row">
        <div class="kb-form-column d-flex justify-content-between">
          <div class="kb-form-dropdown" data-offset="bottom-left">
            <!-- KB튜브 카테고리 최대 3depth 이므로 SortButtonSelect3으로 개별 처리한다 -->
            <SortButtonSelect3
                v-model="cttMstParam.category2"
                v-model:toggle="toggles.category2"
                :options="catesLvl2"
                sequence-key="hrdCloudCateSn"
                name-key="hrdCloudCateNm"
                :is-num="true"
                :custom="true"
                :title="setPlaceholder(0)"
                @update:toggle="closeToggles(toggles, 'category2')"
                @selected="selectCate(2)"
            />

          </div>
          <div v-if="catesLvl3.length > 0" class="kb-form-dropdown" data-offset="bottom-left">
            <SortButtonSelect3
                v-if="cttMstParam.category2 > 0"
                v-model="cttMstParam.category3"
                v-model:toggle="toggles.category3"
                :options="catesLvl3"
                sequence-key="hrdCloudCateSn"
                name-key="hrdCloudCateNm"
                :is-num="true"
                :custom="true"
                :title="setPlaceholder(1)"
                @update:toggle="closeToggles(toggles, 'category3')"
                @selected="selectCate(3)"
            />
            <template v-else>
              <div class="kb-form-dropdown" data-offset="bottom-left">
                <button class="kb-form-dropdown-btn"><span class="text">{{ setPlaceholder(1) }}</span></button>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="kb-form-field">
      <div class="kb-form-row">
        <label class="kb-form-label"><span class="kb-form-label-text">공개범위</span></label>
      </div>
      <div class="kb-form-row">
        <div class="kb-form-dropdown" data-offset="bottom-left">
          <button class="kb-form-dropdown-btn"><span class="text">전직원</span>
<!--            <i class="icon-arrow"></i>-->
          </button>
        </div>
      </div>
    </div>
    <div class="kb-form-field">
      <div class="kb-form-row">
        <label class="kb-form-label"><span class="kb-form-label-text">동영상 업로드</span><span class="color-red">*</span></label>
      </div>
      <div class="kb-form-row">
        <div class="file-text">
          <p class="text">{{ cttFileInfo && cttFileInfo.name ? cttFileInfo.name : '동영상을 업로드해주세요.' }}</p>
          <div v-if="cttFileInfo" class="close">
            <span class="close-text">{{ convertToStorageBytes(cttFileInfo.size) }}</span>
            <i class="icon-del" @click="removeFile('ctt')"></i>
          </div>
        </div>
      </div>
      <div class="kb-form-row row-buttons">
        <button v-if="cttFileInfo" class="kb-btn kb-btn-outline kb-btn-content-item" @click.stop.prevent="previewModal.toggle"><span class="text">미리보기</span></button>
        <label v-else class="kb-btn kb-btn-outline kb-btn-content-item kb-form-file-label" for=""><span class="text">파일찾기</span>
          <FileUpload v-model="cttFile" file-type="vod" />
        </label>
      </div>
    </div>
    <div class="kb-form-field">
      <div class="kb-form-row">
        <label class="kb-form-label"><span class="kb-form-label-text">썸네일 이미지</span></label>
      </div>
      <div class="kb-form-row">
        <div class="file-text">
          <article v-if="thumbSrc" class="hrdcloud-card">
            <div class="hrdcloud-image">
              <div class="image">
                <a href="javascript:" class="image-link">
                  <img class="kb-thumb-list" :src="thumbSrc" alt="썸네일 이미지" />
                </a>
              </div>
            </div>
          </article>
          <p v-else class="text">썸네일을 업로드해주세요.(권장사이즈:1200x675)</p>
        </div>

      </div>
      <div class="kb-form-row row-buttons">
        <label v-if="thumbSrc" class="kb-btn kb-btn-outline kb-btn-content-item kb-form-file-label" for="" @click="removeFile('thumb')"><span class="text">삭제</span></label>
        <label class="kb-btn kb-btn-outline kb-btn-content-item kb-form-file-label" for=""><span class="text">파일찾기</span>
          <FileUpload v-model="thumbFile" file-type="img" />
        </label>
      </div>
    </div>
    <div class="kb-form-field">
      <div class="kb-form-row">
        <label class="kb-form-label"><span class="kb-form-label-text">파일첨부</span></label>
      </div>
      <div class="kb-form-row">
        <div class="file-text">
          <p class="text">{{ atchFileInfo && atchFileInfo.name ? atchFileInfo.name : '파일을 업로드해주세요.' }}</p>
          <div v-if="atchFileInfo" class="close">
            <span class="close-text text-nowrap">{{ convertToStorageBytes(atchFileInfo.size) }} </span>
            <i class="icon-del" @click="removeFile('atch')"></i>
          </div>
        </div>
      </div>
      <div class="kb-form-row row-buttons">
        <label class="kb-btn kb-btn-outline kb-btn-content-item kb-form-file-label" for="inp_file2"><span class="text">파일찾기</span>
          <FileUpload v-model="atchFile" file-type="kbtube" :size-limit="10" />
        </label>
      </div>
    </div>
    <div class="kb-form-field">
      <div class="kb-form-row">
        <label class="kb-form-label"><span class="kb-form-label-text">설명</span></label>
      </div>
      <div class="kb-form-row">
        <div class="editor-content m-h-300 ms-0">
          <div ref="pageEditor"></div>
        </div>
      </div>
    </div>
    <div class="kb-form-field">
      <div class="kb-form-row">
        <label class="kb-form-label"><span class="kb-form-label-text">해시태그</span><span class="color-red">*</span></label>
      </div>
      <div class="kb-form-row">
        <div class="file-text">
          <div class="tags-wrap">
            <div class="tag-list">
              <div v-for="(hashTag, idx) in hashTags" class="tag-item is-active" :key="idx">
                <span class="tag-item-text">{{ hashTag }}</span>
                <button class="kb-btn-tag" @click="deleteHashTag(idx)">
                  <i class="icon-tag-delete"></i>
                </button>
              </div>
              <div class="tag-item tag-input">
                <input v-model.trim="hashTag" type="text" class="tag-item-input" placeholder="해시태그 입력 후 Enter" style="width:135px !important;" @keyup.enter="addHashTag">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="cttMstParam.stt == '78' && cttMstParam.atrzOpn" class="kb-form-field">
      <div class="kb-form-row">
        <label class="kb-form-label"><span class="kb-form-label-text">사유</span></label>
      </div>
      <div class="kb-form-row">
        <span class="kb-form-text">{{ cttMstParam.atrzOpn }}</span>
      </div>
    </div>
  </div>
  <div class="kb-form-fields-bottom ">
    <strong class="text">유의사항</strong>
    <p class="text text-icon">1. 제작한 영상을 <span style="color:#ff8000;line-height:unset !important;">MP4파일(최대 2GB)로 외부망에서 업로드</span> 해주세요.</p>
    <p class="text">2. 첨부파일(붙임자료)가 있는 경우 <span style="color:#ff8000;line-height:unset !important;">암호화 해제 후 업로드</span> 해 주세요.</p>
    <p class="text"> ㅇ 암호화된 파일은 외부망에서 열람 불가</p>
    <p class="text"> ㅇ PDF/PPT/EXCEL 파일 등록 가능(최대 10MB)</p>
    <p class="text">3. <span style="color:#ff8000;line-height:unset !important;">
                콘텐츠에 개인정보 및 중요정보가 포함되지 않도록 유의</span>하여 주시고, 개인정보 유출 등으로 인한 책임은 등록자에게 있음을 확인해주시기 바랍니다.
    </p>
  </div>
</template>

<script>
import SortButtonSelect3 from '@/components/common/SortButtonSelect3';
import FileUpload from '@/components/history/module/FileUpload';
import {kbtubeUploadSetup} from '@/assets/js/modules/kbtube/kbtube-upload-setup';

export default {
  name: 'KbTubeMobileUpload',
  components: {FileUpload, SortButtonSelect3},
  props: {
    modelValue: {
      type: Object,
      default: () => {
        return {
          isActive: false,
          toggle: () => {}
        }
      }
    },
    cttMstSn: {
      type: Number,
      default: 0
    },
    uploadEvent: {
      type: String,
      default: null
    },
    previewModal: {
      type: Object,
      default: () => ( {} )
    }
  },
  emits: ['update:modelValue','update:uploadEvent'],
  setup: kbtubeUploadSetup
};
</script>