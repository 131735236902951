<template>
  <footer class="kb-footer" id="kb-footer">
    <!-- 공지사항 숨김처리 footer-divider -->
    <div v-if="items && items.length > 0" class="footer-row">

<!--      <div class="footer-notice footer-component">-->
<!--          <swiper-->
<!--              class="notice-list"-->
<!--              :slides-per-view="1"-->
<!--              :loop="true"-->
<!--              :navigation="{-->
<!--                nextEl: '.kb-btn-notice-kb-nav-prev',-->
<!--                prevEl: '.kb-btn-notice-kb-nav-next'-->
<!--              }"-->
<!--          >-->
<!--            <swiper-slide v-for="(item, idx) in items" :key="idx" class="notice-item">-->
<!--              <router-link :to="{name: 'HelpNoticeView', params: {ntcSn: item.comNtcSn}}" class="link notice-link"></router-link>-->
<!--              <div class="notice-date">{{ item.regDt }}</div>-->
<!--              <div class="notice-title">-->
<!--                <div class="title">{{item.ntcTitle}}</div>-->
<!--              </div>-->
<!--              <router-link :to="{name: 'HelpNoticeView', params: {ntcSn: item.comNtcSn}}" class="notice-more">자세히 보기</router-link>-->
<!--            </swiper-slide>-->
<!--          </swiper>-->
<!--        <div>-->
<!--          <router-link to="/help/notice" class="more-link">공지 전체보기</router-link>-->
<!--        </div>        -->
<!--      </div>-->
    </div>
    <div>
      <nav class="footer-fnb">
        <router-link to="/main" class="logo" v-slot="{ navigate }">
          <button @click="closeMenu(navigate)"><i class="kb-logo-long"></i></button>
        </router-link>
        <ol class="fnb-list">
          <li class="fnb-item"><a href="javascript:" class="fnb-link fnb-profile"><ProfileImg :target="session"/></a></li>
          <li class="fnb-item"><router-link :to="{name:'TrainNotification'}" class="fnb-link fnb-alarm"><i class="icon-alarm-nocircle"></i></router-link></li>
          <li class="fnb-item"><a href="javascript:" class="fnb-link fnb-menu" id="open-menu-button"><i class="icon-menu icon-menu-hamburger" @click="toggleMenu"></i></a></li>
        </ol>
      </nav>
      <LxpMobileFooterMenu v-model="isMenuOpen" :toggle-menu="toggleMenu"/>
    </div>
  </footer>
</template>

<script>
import {computed, ref} from 'vue';
import {useStore} from 'vuex';
// import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import LxpMobileFooterMenu from '@/views/layout/lxp/footer/mobile/LxpMobileFooterMenu';
import ProfileImg from '@/components/common/ProfileImg';

export default {
  name: "LxpMobileFooter",
  components: {LxpMobileFooterMenu, ProfileImg},
  props: {
    items: Array
  },
  setup() {
    const store = useStore();
    const session = computed(() => store.state.auth.session);
    const isMenuOpen = ref(false);

    const toggleMenu = () => {
      isMenuOpen.value = !isMenuOpen.value;
    };

    const closeMenu = (navigate) => {
      if (isMenuOpen.value) {
        isMenuOpen.value = false;
        navigate();
      }
    }

    return {
      session,
      isMenuOpen,
      toggleMenu,
      closeMenu
    }
  }
}
</script>

<style scoped>
  .lxp-mobile-layout #kb-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    border-top: 1px solid var(--kb-light-silver);
    z-index: var(--kb-zindex-footer-nav);
  }
</style>