<template>
  <div v-if="show" class="popup-container" :class="{ 'is-active' : show }">
    <div class="popup" id="popup-subscription-settings">
      <div class="popup-inner">
        <header class="popup-header">
          <h3 class="title">공유 목록</h3>
        </header>
        <div class="popup-content">
          <LoadingDiv v-if="isLoading"/>
          <section v-else class="popup-section">
<!--            <header class="section-header"><h4 class="title">구독채널 {{ items ? items.length : 0 }}</h4></header>-->
            <div class="kb-table kb-table-bordered">
              <table>
                <colgroup>
                  <col style="width:57px;">
                  <col style="width:auto;">
                  <col style="width:350px;">
                </colgroup>
                <thead>
                <tr>
                  <th><span class="th-title">공유일시</span></th>
                  <th><span class="th-title">콘텐츠</span></th>
                  <th><span class="th-title">공유대상</span></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, idx) in items" :key="idx">
                  <td><span class="td-text">{{ timestampToDateFormat(item.regDt, 'yyyy.MM.dd') }}</span></td>
                  <td>
                    <strong class="td-text text-gold">{{ item.objNm }}</strong>
                  </td>
                  <td><span class="td-text">{{ item.shareTrgtLrnerNmLst }}</span></td>
                </tr>
                <tr v-if="items && items.length === 0" class="search-container">
                  <td colspan="3" class="result-empty">
                    <img src="../../assets/lxp/images/common/img_empty.png" alt="">
                    <p class="text">공유내역이 없습니다.</p>
                  </td>
                </tr>
                </tbody>
              </table>
              <CommonPaginationFront :paging="paging" :page-func="pagingFunc" :divider="true" />
            </div>
          </section>
        </div>
        <div v-if="!isLoading && (items && items.length > 0)" class="popup-buttons">
          <button class="kb-btn kb-btn-outline kb-btn-lg" @click="closeShareList"><span class="text">닫기</span></button>
        </div>
      </div>
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeShareList"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
  </div>
</template>

<script>
import CommonPaginationFront from "@/components/CommonPaginationFront.vue";
import LoadingDiv from "@/components/common/LoadingDiv.vue";
import {computed, reactive, ref, watch} from "vue";
import {closeShareList} from '@/assets/js/modules/hrd/hrd-common';
import {getListFunc} from "@/assets/js/ui.init";
import {ACT_GET_HRDCLOUD_HISTORY_SHARE_LIST} from "@/store/modules/history/history";
import {useStore} from "vuex";
import {timestampToDateFormat} from "@/assets/js/util"; // dateToDatePart

export default {
  name: 'HrdCloudHistoryShareModal',
  components: {CommonPaginationFront, LoadingDiv},
  setup() {

    const items = ref([]);
    const paging = reactive({
      pageNo: 1,
      pageSize: 5,
      totalCount: 0
    });

    // const router = useRouter();
    const store = useStore();
    const isLoading = ref(true);
    const show = computed(() => store.state.hrdcloud.share.show);
    // eslint-disable-next-line no-unused-vars
    const year = computed(() => store.state.hrdcloud.share.year
    // {
    //   if (store.state.hrdcloud.share.year && store.state.hrdcloud.share.year.length > 0) {
      //   return store.state.hrdcloud.share.year
      // } else {
      //   return dateToDatePart(new Date()).year;
      // }
    // }
    );

    const getList = () => {

      // console.log('store.year:'+store.state.hrdcloud.share.year);
      // console.log('year:'+year.value);
      getListFunc(`history/${ACT_GET_HRDCLOUD_HISTORY_SHARE_LIST}`, {
        year: year.value,
        pageNo: paging.pageNo,
        pageSize: paging.pageSize,
        totalCount: paging.totalCount,
      }, items, paging, () => {isLoading.value=false;}, ()=>{isLoading.value=false;});
    };

    const pagingFunc = (pageNo) => {
      if (pageNo > 0) paging.pageNo = pageNo;
      getList();
    };

    watch(() => show.value, () => {
      if (!show.value) {
        paging.pageNo = 1;
      } else {
        getList();
      }
    });

    return{
      items, paging, show, isLoading, pagingFunc, timestampToDateFormat, closeShareList
    }

  }
};
</script>