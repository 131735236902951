<template>
  <div class="kb-form-row">
    <label class="kb-form-label">
      <span class="kb-form-label-text">{{ title }}</span>
    </label>
  </div>
  <div class="kb-form-row">
    <div class="kb-form-dropdown">
      <!-- kb-form-dropdown -->
      <div v-if="options1 && options1.length > 0" class="kb-form-dropdown" :class="{'is-active': toggle1}">
        <button class="kb-form-dropdown-btn" @click.stop="toggleOptions(1)" :disabled="disabled">
          <span class="text">{{ selectedValue1 }}</span><i class="icon-arrow"></i>
        </button>
        <div class="kb-form-dropdown-options" :style="width > 0 ? {width: `${width}${widthPx ? 'px' : '%'}`} : {}">
          <div class="dropdown-option">
            <ul class="dropdown-option-list">
              <li v-for="(option, index) in options1" class="dropdown-option-item" :key="index">
                <a href="javascript:" class="dropdown-option-link" :class="{'is-active': value1 === option[sequenceKey1]}" @click.stop="selectOption(1, index)"><span class="dropdown-option-text">{{ option[nameKey1] }}</span></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed} from 'vue';

export default {
  name: 'InputCustomSelect',
  props: {
    value1: [String, Number],
    title: String,
    options1: Array,
    toggle1: Boolean,
    sequenceKey1: {type:String, required:true},
    nameKey1: String,
    isRelated: {type: Boolean, default: true},
    placeholder1: String,
    disabled: Boolean,
    width: Number,
    widthPx: {type: Boolean, default: true},
  },
  emits: ['update:value1', 'update:toggle1', 'selected'],
  setup(props, {emit}) {
    const selectedValue1 = computed(() => {
      if (props.options1) {
        const target = props.options1.find(x => {
          if (x[props.sequenceKey1] == props.value1) return x;
        });
        if (target) {
          return target[props.nameKey1];
        }
      }
      return (props.placeholder1 ? props.placeholder1 : '');
    });


    // 선택 행위 함수
    const selectOption = (order, index) => {
      if (order === 1) {
        if (props.value1 !== props.options1[index][props.sequenceKey1]) {
          // firstSubtext.value = props.options1[index][props.nameKey1];
          emit('update:value1', props.options1[index][props.sequenceKey1]);
        }
        emit('update:toggle1', false);
        // emit('selected');
      }
    };

    const toggleOptions = (order) => {
      if (order === 1) emit('update:toggle1', !props.toggle1);
    };

    const checkDisabled = computed(() => {
      if (!props.disabled) {
        if (props.isRelated) {
          if (props.value1 > 0) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else {
        return true;
      }
    });

    return {
      toggleOptions,
      selectedValue1,
      selectOption,
      checkDisabled
    }
  }
};
</script>