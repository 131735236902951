<template>
  <div class="intro-column intro-my">
    <div class="my-info">
      <div class="avatar"><ProfileImg :target="session"/></div>
      <h4 class="name">{{ session.lrnerNm }}</h4>
      <p class="team">{{ session.deptNm }}</p>
    </div>
    <div class="my-service">
      <router-link :to="{ name:'HistoryHrdCloud' }" class="link">
        <span class="text">My 클라우드</span><i class="icon-arrow"></i>
      </router-link>
      <a href="javascript:" class="link" @click="showSubscribed">
        <span class="text">My 구독채널</span><i class="icon-arrow"></i>
      </a>
      <a href="javascript:" class="link" @click="showAdded">
        <span class="text">My 담은 콘텐츠</span><i class="icon-arrow"></i>
      </a>
    </div>
  </div>
</template>

<script>
import ProfileImg from '@/components/common/ProfileImg';
import {showAdded, showSubscribed} from '@/assets/js/modules/hrd/hrd-common';

export default {
  name: 'HrdCloudMy',
  components: {ProfileImg},
  props: {
    session: Object,
  },setup() {

    return {
      showAdded,
      showSubscribed
    }
  }
};
</script>