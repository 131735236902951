<template>
  <div class="popup-container" :class="{ 'is-active' : modelValue }">
    <div class="popup" id="popup-hrdcloud-kbtube">
      <div class="popup-inner">
        <header class="popup-header">
          <h3 class="title">Hot Tube</h3>
        </header>
        <KbTubeList :upload-func="uploadModal" :pending-func="pendingModal"/>
        <KbTubeUpload v-if="uploadModal.isActive" v-model="uploadModal" :ctt-mst-sn="cttMstSn" />
        <KbTubePending v-if="pendingModal.isActive" v-model="pendingModal" :ctt-mst-sn="cttMstSn" />
      </div>
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="$emit('update:modelValue',false)"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
  </div>
</template>

<script>
import {reactive, ref} from 'vue';
import KbTubeList from '@/components/history/hrdcloud/KbTubeList';
import KbTubeUpload from '@/components/history/hrdcloud/KbTubeUpload';
import KbTubePending from '@/components/history/hrdcloud/KbTubePending';

export default {
  name: 'KbTubeModal',
  components: {KbTubePending, KbTubeUpload, KbTubeList},
  props: {
    modelValue: Boolean,
  },
  emits: ['update:modelValue'],
  setup(){
    const cttMstSn = ref(0);

    const setCttMstSn = (sn) => {
      cttMstSn.value = sn;
    };

    const uploadModal = reactive({
      isActive: false,
      toggle: () => {
        uploadModal.isActive = !uploadModal.isActive;
      },
      update: setCttMstSn
    });

    const pendingModal = reactive({
      isActive: false,
      toggle: () => {
        pendingModal.isActive = !pendingModal.isActive;
      },
      update: setCttMstSn
    });

    return {
      cttMstSn,
      uploadModal, pendingModal
    }
  }
};
</script>