<template>
  <div v-if="show" class="popup-container" :class="{ 'is-active' : show }">
    <div class="popup" id="popup-subscription-settings">
      <div class="popup-inner">
        <header class="popup-header">
          <h3 class="title">구독채널 상세</h3>
        </header>
        <div class="popup-content">
          <HrdSubscribeSettings />
          <LoadingDiv v-if="isLoading"/>
          <section v-else class="popup-section">
            <header class="section-header"><h4 class="title">구독채널 {{ items ? items.length : 0 }}</h4></header>
            <div class="kb-table kb-table-bordered">
              <table>
                <colgroup>
                  <col style="width:auto;">
                  <col style="width:200px;">
                  <col style="width:140px;">
                </colgroup>
                <thead>
                <tr>
                  <th><span class="th-title">카테고리 경로</span></th>
                  <th><span class="th-title">구독자수</span></th>
                  <th class="cell-center"><button class="kb-btn-check"><span class="th-title text-primary">전체선택</span></button></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, idx) in items" :key="idx">
                  <td>
                    <a href="javascript:" class="td-link td-link-line" @click.stop="goSubsPage(item)">
                      <strong class="td-text text-gold">{{ item.cloudNmLst }}</strong>
                    </a>
                  </td>
                  <td><span class="td-text">{{ item.subscribedCnt }}</span></td>
                  <td class="text-center">
                    <div class="kb-form-check">
                      <input type="checkbox" class="kb-form-check-input" name="chk_01" id="chk_01_01"
                             v-model="hrdCloudCateSnList"
                             :value="item.hrdCloudCateSn"
                      />
                    </div>
                  </td>
                </tr>
                <tr v-if="items && items.length === 0" class="search-container">
                  <td colspan="3" class="result-empty">
                    <img src="../../assets/lxp/images/common/img_empty.png" alt="">
                    <p class="text">구독중인 채널이 없습니다.</p>
                  </td>
                </tr>
                </tbody>
              </table>
              <CommonPaginationFront :paging="paging" :page-func="pagingFunc" :divider="true" />
            </div>
          </section>
        </div>
        <div v-if="!isLoading && (items && items.length > 0)" class="popup-buttons">
          <button class="kb-btn kb-btn-outline kb-btn-lg" @click="closeSubscribed"><span class="text">취소</span></button>
          <button class="kb-btn kb-btn-primary kb-btn-lg" @click="handleUnsubscribe"><span class="text">구독해지</span></button>
        </div>
      </div>
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeSubscribed"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingDiv from '@/components/common/LoadingDiv';
import {hrdSubscriptionSetup} from '@/assets/js/modules/hrd/hrd-subscription-setup';
import HrdSubscribeSettings from "@/components/history/HrdSubscribeSettings.vue";
import CommonPaginationFront from "@/components/CommonPaginationFront.vue";

export default {
  name: 'HrdCloudHistorySubscribedModal',
  components: {CommonPaginationFront, HrdSubscribeSettings, LoadingDiv},
  setup: hrdSubscriptionSetup
};
</script>