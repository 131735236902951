import ApiService from '@/assets/js/api.service';

export const ACT_GET_CAPABILITY_LIST = 'getCapabilityList';
export const ACT_GET_CAPABILITY_REQUIREMENT = 'getCapabilityRequirement';

const actions = {
    [ACT_GET_CAPABILITY_LIST](){
        return new Promise(resolve => {
            ApiService.query('/v1/app/capabilities', {}).then(response => resolve(response));
        });
    },
    [ACT_GET_CAPABILITY_REQUIREMENT](){
        return new Promise(resolve => {
            ApiService.query('/v1/app/capabilities/req', {}).then(response => resolve(response));
        });
    },
};

export default {
    namespaced: true,
    actions
};
