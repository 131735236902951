import ApiService from '@/assets/js/api.service';
import navigationUtils from '@/assets/js/navigationUtils';

export const TOKEN_KEY = 'kb-auth-token'
export const TOKEN_ADMKEY = 'kb-admin-token';
// MUTATION
export const MUT_SET_SESSION = 'setSession';
export const MUT_SET_SESSION_ITN = 'setSessionItn';
export const MUT_REMOVE_SESSION = 'remoSession';
export const MUT_SET_EXPTIME = 'setExpiredTime';
export const MUT_SET_LOGOUT = 'setLogout';
export const MUT_SET_INSIDE = 'setInside';

// ACTION
export const ACT_KB_SSO_AUTH = 'ssoKBAuth';
export const ACT_KB_EHRD_SSO_AUTH = 'ssoKBEhrdAuth';
export const ACT_SSO_AUTH = 'ssoAuth';
export const ACT_SSO_AUTH_WITH = 'ssoAuthWith';
export const ACT_SSO_AUTH_CP = 'ssoAuthCp';
export const ACT_BRIDGE_SSO_AUTH = 'bridgeSsoAuth';
export const ACT_LOGIN_AUTH = 'loginAuth';
export const ACT_SIGNIN_AUTH = 'signinAuth';
export const ACT_MLOGIN_SIGNIN_AUTH = 'mloginSigninAuth';
export const ACT_MLOGIN_SIGNIN_ADMIN_AUTH = 'mloginAdminSignAuth';
export const ACT_LOGOUT_AUTH = 'logOutAuth';
export const ACT_GET_AUTH = 'getAuth';
export const ACT_RENEW_AUTH = 'renewAuth';

export const ACT_GET_AUTH_BY_BRDT = 'getAuthByBrdt';
export const ACT_CHECK_AUTH_CERT = 'checkAuthCert';

export const ACT_SSO_AUTH_ADMIN = 'ssoAuthAdmin';
export const ACT_IS_AUTH_ADMIN = 'authIsAdmin';
export const ACT_GET_ADMIN_AUTH = "getAdminAuth"

export const ACT_UPDATE_AUTH_PWD = 'actUpdateAuthPwd';

export const ACT_GET_USERS_LIST = 'getUsersList';

export const ACT_GET_AUTH_DEPT = 'getAuthDept';

export const ACT_GET_AUTH_INFO = 'getAuthInfo';

export const ACT_SSL_SSO_AUTH = 'sslSsoAuth';

export const ACT_GET_SHORT_URL = 'getShortUrl';

export const ACT_GET_STORE_LOGIN_INFO = 'storeLoginInfo';

export const ACT_GET_AUTH_PROFILE = 'getAuthProfile';

export const ACT_INSERT_COM_USERS_SHIP_INFO = 'insertComUsersShipInfo';
export const ACT_UPDATE_COM_USERS_SHIP_INFO = 'updateComUsersShipInfo';
export const ACT_UPDATE_SHORT_URL = 'updateShortUrl';

export const MUT_HIDE_HEADER = 'mutHideHeader';
export const MUT_SHOW_HEADER = 'mutShowHeader';


// interface session {
//     lrnerId: string; 직번
//     lrnerNm: string;
//     jbgdCd: string; 직급 (L0 ~ L7 또는 etc)
//     itn: boolean; 내부망 여부
//     vice: boolean; 부점장 여부
// }
const state = {
    isMobile: !!navigationUtils.any(),
    isTablet: !!navigationUtils.isTablet(),
    session: {
        lrnerId: null,
        vice: false,
        trnMngr: false,
        isAdmin: false,
        takeTmOffYn: 'N',
        isGeneralAdmin: false,
        ficbjjyb: false,
    },
    isLogout: false,
    isInside: null,
    sessionExpiredTime: 0,
    isHeaderShow: 0
};

const mutations = {
    [MUT_SET_SESSION](state, session){
        state.session = session;
    },
    [MUT_SET_SESSION_ITN](state){
        state.session.itn = true;
    },
    [MUT_REMOVE_SESSION](state){
        state.session = {
            lrnerId: null
        };
    },
    [MUT_SET_EXPTIME](state, tm) {
        if (tm === 0) {
            state.sessionExpiredTime = 0;
        } else {
            state.sessionExpiredTime = new Date().getTime() + (1000 * 60 * 10);

        }
    },

    [MUT_SET_LOGOUT](state, value) {
        state.isLogout = value;
    },

    [MUT_SET_INSIDE](state, value) {
        state.isInside = value;
    },

    [MUT_HIDE_HEADER](state) {
        state.isHeaderShow = 1
    },
    [MUT_SHOW_HEADER](state) {
        state.isHeaderShow = 0
    },
};

const actions = {
    [ACT_LOGOUT_AUTH]() {
        //serverside token 삭제
        return new Promise(resolve => {
                ApiService.put('/v1/app/auth/logout', {}).then(response => {
                resolve(response)
            })
        });

    },
    [ACT_GET_AUTH]() {
        return new Promise(resolve => {
            ApiService.query('/v1/app/auth/', {}, true).then(response => resolve(response))
        });
    },
    [ACT_LOGIN_AUTH](context, params) {
        return new Promise(resolve => {
            ApiService.post('/v1/app/auth', params).then(response => resolve(response))
        });
    },
    [ACT_SIGNIN_AUTH](context, params) {
        return new Promise(resolve => {
            ApiService.post('/v1/app/auth/', params).then(response => resolve(response))
        });
    },
    [ACT_MLOGIN_SIGNIN_ADMIN_AUTH](context, params) {
        return new Promise(resolve => {
            ApiService.post('/v1/app/auth/admMlogin', params).then(response => resolve(response))
        });
    },
    [ACT_MLOGIN_SIGNIN_AUTH](context, params) {
        return new Promise(resolve => {
            ApiService.post('/v1/app/auth/mlogin', params).then(response => resolve(response))
        });
    },
    [ACT_BRIDGE_SSO_AUTH](context, auth) {
        return new Promise(resolve => {
            ApiService.post('/v1/app/bridges/sso', {auth}).then(response => resolve(response))
        });
    },
    [ACT_SSO_AUTH](context, auth) {
        return new Promise(resolve => {
            ApiService.post('/v1/app/auth/sso', {auth}).then(response => resolve(response))
        });
    },
    [ACT_SSO_AUTH_WITH](context, auth) {
        return new Promise(resolve => {
            ApiService.post('/v1/app/auth/sso/with', {auth}).then(response => resolve(response))
        });
    },
    [ACT_SSO_AUTH_CP](context, params) {
        return new Promise(resolve => {
            ApiService.post('/v1/app/auth/sso/cp', params).then(response => resolve(response))
        });
    },
    [ACT_IS_AUTH_ADMIN]() {
        return new Promise(resolve => {
            ApiService.get('/v1/app/auth/admin').then(response => resolve(response))
        });
    },

    [ACT_GET_ADMIN_AUTH](){
        return new Promise(resolve => {
            ApiService.post(`/v1/app/auth/admintoken`).then(response => resolve(response))
        });
    },

    [ACT_RENEW_AUTH]() {
        return new Promise(resolve => {
            ApiService.put('/v1/app/auth/').then(response => resolve(response))
        });
    },
    [ACT_KB_SSO_AUTH](context, code){
        return new Promise(resolve => {
            ApiService.post(`/v1/app/auth/kb/sso/${code}`).then(response => resolve(response))
        });
    },
    [ACT_KB_EHRD_SSO_AUTH](context, params){
        return new Promise(resolve => {
            ApiService.post(`/v1/app/auth/kb/ehrd/sso`, params).then(response => resolve(response))
        });
    },
    [ACT_SSL_SSO_AUTH](context, path){
        return new Promise(resolve => {
            ApiService.post(`/v1/app/auth/kb/ssl/sso`, path).then(response => resolve(response))
        });
    },
    [ACT_SSO_AUTH_ADMIN](context, viceYn){
        return new Promise(resolve => {
            ApiService.post(`/v1/app/auth/admin`, viceYn).then(response => resolve(response))
        });
    },
    [ACT_GET_AUTH_BY_BRDT](context, params){
        return new Promise(resolve => {
            ApiService.query(`/v1/app/auth/brdt`, params).then(response => resolve(response))
        });
    },
    [ACT_CHECK_AUTH_CERT](context, params){
        return new Promise(resolve => {
            ApiService.post(`/v1/app/auth/cert`, params).then(response => resolve(response))
        });
    },
    [ACT_UPDATE_AUTH_PWD](context, {lrnerId, params}){
        return new Promise(resolve => {
            ApiService.update(`/v1/app/auth`, lrnerId, params).then(response => resolve(response))
        });
    },
    [ACT_GET_USERS_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/users', params).then(response => resolve(response))
        });
    },
    [ACT_GET_AUTH_DEPT](){
        return new Promise(resolve => {
            ApiService.query('/v1/app/depts', {}).then(response => resolve(response));
        });
    },
    [ACT_GET_AUTH_INFO](){
        return new Promise(resolve => {
            ApiService.get('/v1/app/auth/info').then(response => resolve(response))
        });
    },
    [ACT_GET_SHORT_URL](context, shortUrl) {
        return new Promise(resolve => {
            ApiService.get('/v1/app/auth/shorts', shortUrl).then(response => resolve(response))
        });
    },
    [ACT_GET_STORE_LOGIN_INFO](context, params){
        return new Promise(resolve => {
            ApiService.query(`/v1/app/auth/kb/logininfo`, params).then(response => resolve(response));
        });
    },
    [ACT_GET_AUTH_PROFILE](){
        return new Promise(resolve => {
            ApiService.query(`/v1/app/auth/profile`).then(response => resolve(response));
        });
    },
    [ACT_INSERT_COM_USERS_SHIP_INFO](context, params){
        return new Promise(resolve => {
            ApiService.post(`/v1/app/auth/ship/info`, params).then(response => resolve(response))
        });
    },
    [ACT_UPDATE_COM_USERS_SHIP_INFO](context, {shipInfoSn, params}){
        return new Promise(resolve => {
            ApiService.put(`/v1/app/auth/ship/${shipInfoSn}/info`, params).then(response => resolve(response))
        });
    },
    [ACT_UPDATE_SHORT_URL](context, shortUrlSn){
        ApiService.update('/v1/app/auth/shorts', shortUrlSn);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};