<template>
  <div class="popup-container" :class="{ 'is-active' : modelValue }">
    <div class="popup" id="popup-hrdcolud-h0211">
      <div class="popup-inner">
        <header class="popup-header">
          <h3 class="title">HRD 클라우드 콘텐츠별 학습이력 </h3>
        </header>
        <div class="popup-content">
          <div class="popup-section section-01">
            <div class="section-header">
              <div class="header-side">
                <button class="kb-btn kb-btn-secondary" @click="func"><i class="icon-view"></i><strong class="text">최근학습 상세보기</strong></button>
              </div>
            </div>
            <div class="kb-form-fields field-01">
              <div class="kb-form-row">
                <div class="kb-form-column kb-form-column-title">
                  <label class="kb-form-label">
                    <span class="kb-form-label-text">콘텐츠명</span>
                  </label>
                </div>
                <div class="kb-form-column">
                  <div class="kb-form-text">{{ title }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="popup-section section-divider">
            <div class="kb-table kb-table-striped">
              <table>
                <colgroup>
                  <col/>
                  <col/>
                  <col/>
                  <col/>
                  <col style="width:320px;"/>
                </colgroup>
                <thead>
                <tr>
                  <th><span class="th-title">학습일시</span></th>
                  <th><span class="th-title">학습진도율</span></th>
                  <th><span class="th-title">학습완료여부</span></th>
                  <th><span class="th-title">획득마일리지</span></th>
                  <th><span class="th-title">비고</span></th>
                </tr>
                </thead>
                <tbody style="overflow:scroll;">
                <tr v-for="(item, idx) in items" :key="idx">
                  <td><span class="td-text">{{ getLrnDt(item, 'yyyy.MM.dd') }}</span></td>
                  <td>
                    <span class="td-text">{{ getProgressPercent(duration, item.accumPlaySec, cttTyCdDcd) }} %</span>

                  </td>
                  <td><span class="td-text">{{ item.cmptnYn == 'Y' ? '완료' : '미완료' }}</span></td>
                  <td><span class="td-text">{{ item.mlgScore }}</span></td>
                  <td>
                    <span class="td-text" :style="item.mlgTyCd === '1001' || item.mlgTyCd === '1003' || item.mlgTyCd === '1004' || item.mlgTyCd === '1005' ? {color:'#ff8000'} : {}">
                      {{ (item.mlgGiveRsn != null || item.mlgGiveRsn != '') ? item.mlgGiveRsn : '-' }} {{ getMlgGiveDt(item) }}
                    </span>
                  </td>
                </tr>
                <tr v-if="items.length === 0">
                  <td colspan="5" class="text-center"><span class="td-text">이력이 없습니다.</span></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
  </div>
</template>

<script>
import {getLrnDt} from '@/assets/js/modules/hrd/hrd-common';
import {timestampToDateFormat} from '@/assets/js/util';
import {getMlgGiveDt, getProgressPercent} from '@/assets/js/modules/hrd/hrd-history-common';

export default {
  name: 'HrdCloudHistory',
  props: {
    modelValue: Boolean,
    title: String,
    duration: Number,
    items: Array,
    func: Function,
    cttTyCdDcd: String
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {

    const closeModal = () => { emit('update:modelValue', false); }

    return {
      getProgressPercent,
      closeModal,
      getLrnDt,
      timestampToDateFormat,
      getMlgGiveDt
    }
  }
};
</script>