<template>
  <div class="popup-container" :class="{ 'is-active' : modelValue }" @click.stop="closeToggles(toggles)">
    <div class="popup" id="popup-tuition-support-p032">
      <div class="popup-inner">
        <div class="popup-header">
          <h3 class="title">학비 지원신청</h3>
        </div>
        <div class="popup-content">
          <div class="survey-container">
            <article class="popup-section section-form">
              <header class="section-header header-divider-v2">
                <h4 class="title">신청정보</h4>
              </header>
              <div class="section-content">
                <div class="kb-form-fields kb-form-fields-v2">
                  <Applicant />
                  <InputSelect
                      v-model:value1="param.schlDivCdDcd"
                      v-model:toggle1="toggles.schlDivCdDcds"
                      :options1="schlDivCdDcdOpt"
                      :disabled="isReadOnly"
                      title="학교 구분"
                      sequence-key1="cd"
                      name-key1="cdNm"
                      placeholder1="학교구분을 선택해주세요"
                      @update:toggle1="closeToggles(toggles, 'schlDivCdDcds')"
                  />
                  <InputSelect
                      v-model:value1="param.schlInDivCdDcd"
                      v-model:value2="param.termDivCdDcd"
                      v-model:toggle1="toggles.schlInDivCdDcds"
                      v-model:toggle2="toggles.termDivCdDcds"
                      title="학기(학년)재학 구분"
                      :options1="schlInDivCdDcdOpt"
                      :options2="termDivCdDcdOpt"
                      sequence-key1="cd"
                      sequence-key2="cd"
                      name-key1="cdNm"
                      name-key2="cdNm"
                      placeholder1="재학구분을 선택해주세요"
                      placeholder2="학기를 선택해주세요"
                      :disabled="isReadOnly"
                      @update:toggle1="closeToggles(toggles, 'schlInDivCdDcds')"
                      @update:toggle2="closeToggles(toggles, 'termDivCdDcds')"
                  />
                  <div v-if="isAcademicCreditBank" class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <strong class="kb-form-label-text">수강학점</strong>
                      </label>
                    </div>
                    <div class="kb-form-column">
                      <input
                          type="text"
                          class="kb-form-control"
                          v-model="param.regCrseCrdit"
                          :disabled="isReadOnly"
                          maxlength="10"
                          @input="numbersOnly"
                          @focusin="emptyNumberOnClick"
                          @focusout="emptyNumberOnClick"
                      />
                    </div>

                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <strong class="kb-form-label-text">학위취득예정시기</strong>
                      </label>
                    </div>
                    <div class="kb-form-column">
                      <input
                          type="text"
                          class="kb-form-control"
                          maxlength="20"
                          v-model="param.degAcqsSchedPerid"
                          :disabled="isReadOnly"
                      />
                    </div>
                  </div>
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <strong class="kb-form-label-text">학교명</strong>
                      </label>
                    </div>
                    <div class="kb-form-column column-whole kb-form-search">
                      <input type="text" v-model="school.scholNm" class="kb-form-control" placeholder="학교명 검색" disabled />
                      <button v-if="!isReadOnly" class="kb-form-search-btn" @click="collegeModal = true" :disabled="isReadOnly"><i class="icon-search"></i></button>
                    </div>
                  </div>
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <strong class="kb-form-label-text">전공</strong>
                      </label>
                    </div>
                    <div class="kb-form-column column-whole kb-form-search">
                      <input type="text" v-model="major.majorNm" class="kb-form-control" placeholder="전공 검색" disabled />
                      <button v-if="!isReadOnly" class="kb-form-search-btn" @click="majorModal = true" :disabled="isReadOnly"><i class="icon-search"></i></button>
                    </div>
                  </div>

                  <RowInputText v-model="param.majorNm2" title="학과" class-lst="column-whole" placeholder="학과를 입력해주세요." :disabled="isReadOnly"/>
                  <RowDatepicker
                      title="등록금 납부일"
                      v-model="param.tuitFeePayDd"
                      v-model:toggle="toggles.tuitFeePayDd"
                      :disabled="isReadOnly"
                  />
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <strong class="kb-form-label-text">직전학기 평점평균</strong>
                      </label>
                    </div>
                    <div class="kb-form-column kb-form-divider">
                      <input
                          type="text" class="kb-form-control"
                          v-model="param.grdAvg"
                          :disabled="isReadOnly"
                          @input="numbersOnly"
                          @focusin="emptyNumberOnClick"
                          @focusout="emptyNumberOnClick"
                      />
                      <div class="kb-form-bottomtext">
                        <span class="text-nowrap">* 최초로 학비지원을 신청할 경우 성적에 관계없이 지원 가능</span>
                      </div>
                    </div>
                    <div class="kb-form-column kb-form-dropdown-group ps-0">
                      <div class="kb-form-dropdown" :class="{'is-active': toggles.grdTots}">
                        <button class="kb-form-dropdown-btn" @click.stop="toggleGrdTot" :disabled="isReadOnly">
                          <span class="text">{{ param.grdTot }}점</span><i class="icon-arrow"></i>
                        </button>
                        <div class="kb-form-dropdown-options">
                          <div class="dropdown-option">
                            <ul class="dropdown-option-list">
                              <li v-for="(item, idx) in grdTots" class="dropdown-option-item" :key="idx">
                                <a href="javascript:" class="dropdown-option-link" @click.stop="selectGrdTot(idx)"><span class="dropdown-option-text">{{ item.nm }}</span></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <template v-if="param.schlInDivCdDcd === schlInDivCdDcds.SCHL_IN_DIV_CD_OLD">
                    <Upload
                        v-model="transcriptFiles"
                        title="직전학기 성적증명서"
                        placeholder="직전학기 성적증명서를 등록하세요"
                        btn-title="성적증명서 등록하기"
                        sequence-key="proofDataSn"
                        :extensions="extensions"
                        :max-qty="10"
                        :disabled="isReadOnly"
                    >
                      <template v-slot:last>
                        <p class="kb-form-bottomtext">
                          &nbsp; <span class="text-nowrap text-right">* 1장만 등록가능함, 증명서의 가장자리가 모두 보이도록 촬영</span>
                        </p>
                      </template>
                    </Upload>
                    <RowInputText v-model="param.inetProofNo" title="인터넷 증명서 발급번호" class-lst="column-whole" placeholder="진위확인코드(16자리) 등 증명서 진위여부 확인이 가능한 일련번호를 입력하세요." :disabled="isReadOnly">
                      <template v-slot:last>
                        <div class="kb-form-bottomtext">
                          <span class="text-nowrap">* 인터넷 증명서 발급번호(숫자,영문,- 만 입력가능)</span>
                        </div>
                      </template>
                    </RowInputText>
                  </template>
                </div>
              </div>
            </article>
            <article class="popup-section section-form">
              <header class="section-header">
                <h4 class="title">납부금 고지서 내역</h4>
              </header>
              <div class="section-content">
                <div class="kb-table kb-table-bordered-v2">
                  <table>
                    <thead>
                    <tr>
                      <th><strong class="th-title">납부금액</strong></th>
                      <th><strong class="th-title">감면금액</strong></th>
                    </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><strong class="td-text text-muted">지원금액</strong></td>
                        <td><strong class="td-text text-muted">장학금</strong></td>
                      </tr>
                      <tr>
                        <td>
                          <div class="kb-form-fields kb-form-fields-v2">
                            <RowInputText v-model="fees.sprtTuitFee" title="수업료" class-lst="column-auto" placeholder="수업료를 입력하세요." :is-number="true" :is-money="true" :is-krw="true" :disabled="isReadOnly"/>
                            <RowInputText v-model="fees.sprtSchlOperCost" :title="`학교운영지원비<br/> (육성/기성회비)`" class-lst="column-auto" placeholder="학교운영지원비(육성/기성회비)을 입력하세요." :is-number="true" :is-money="true" :is-krw="true" :disabled="isReadOnly" />
                            <RowInputText v-model="fees.sprtTotStdntFee" :title="`총학생회비 <br/> (자치회비)`" class-lst="column-auto" placeholder="총학생회비(자치회비)을 입력하세요." :is-number="true" :is-money="true" :is-krw="true" :disabled="isReadOnly" />
                            <RowInputText v-model="fees.sprtPraceCost" title="실험실습비 등" class-lst="column-auto" placeholder="실험실습비 등 입력하세요." :is-number="true" :is-money="true" :is-krw="true" :disabled="isReadOnly" />
                          </div>
                        </td>

                        <td class="align-top">
                          <div class="kb-form-fields kb-form-fields-v2">
                            <RowInputText v-model="reduce.redGradeBursAmt" title="성적" class-lst="column-auto" placeholder="성적을 입력하세요." :is-number="true" :is-money="true" :is-krw="true" :disabled="isReadOnly" />
                            <RowInputText v-model="reduce.redNtnBursAmt" title="국가지원" class-lst="column-auto" placeholder="국가지원을 입력하세요." :is-number="true" :is-money="true" :is-krw="true" :disabled="isReadOnly" />
                            <RowInputText v-model="reduce.etcBursAmt" class-lst="column-auto" placeholder="금액을 입력하세요." :is-number="true" :is-money="true" :is-krw="true" :disabled="isReadOnly">
                              <template v-slot:first>
                                <div class="kb-form-column kb-form-column-title">
                                  <input type="text" class="kb-form-control me-6" v-model="reduce.etcBursAmtNm" placeholder="장학금명"/>
                                </div>
                              </template>
                            </RowInputText>
<!--                            <div class="kb-form-row">-->
<!--                                <div class="kb-form-column kb-form-column-title">-->
<!--                                  <input type="text" class="kb-form-control" v-model="reduce.etcBursAmtNm" placeholder="기타 장학금명"-->
<!--                                         style="margin-right:20px"/>-->
<!--                                </div>-->
<!--                                <div class="kb-form-column column-auto">-->
<!--                                  <input type="text"  class="kb-form-control"  v-model="reduce.etcBursAmt" placeholder="금액을 입력하세요."-->
<!--                                         @keyup="updateEtcBursAmt"-->
<!--                                         @focusin="emptyNumberOnClick"-->
<!--                                         @focusout="emptyNumberOnClick"-->
<!--                                  />-->
<!--                                </div>-->
<!--                                <div class="kb-form-column column-side"><span class="kb-form-text">원</span></div>-->
<!--                            </div>-->
                          </div>
                        </td>

                      </tr>
<!--                      <tr>-->
<!--                        <td><strong class="td-text text-muted">지원제외금액</strong></td>-->
<!--                      </tr>-->
<!--                      <tr>-->
<!--                        <td>-->
<!--                          <div class="kb-form-fields kb-form-fields-v2">-->
<!--&lt;!&ndash;                            <RowInputText v-model="fees.nsprtStdntFee" title="학생회비" class-lst="column-auto" placeholder="학생회비를 입력하세요." :is-number="true" :is-krw="true" :disabled="isReadOnly" />&ndash;&gt;-->
<!--&lt;!&ndash;                            <RowInputText v-model="fees.nsprtBookCost" title="교재비" class-lst="column-auto" placeholder="교재비를 입력하세요." :is-number="true" :is-krw="true" :disabled="isReadOnly" />&ndash;&gt;-->
<!--&lt;!&ndash;                            <RowInputText v-model="fees.nsprtGradPrepAmt" title="졸업준비(앨범)금" class-lst="column-auto" placeholder="졸업준비(앨범)금을 입력하세요." :is-number="true" :is-krw="true" :disabled="isReadOnly" />&ndash;&gt;-->
<!--                            <RowInputText v-model="fees.nsprtEtcAmt" title="기타" class-lst="column-auto" placeholder="기타를 입력하세요." :is-number="true" :is-krw="true" :disabled="isReadOnly" />-->
<!--                          </div>-->
<!--                        </td>-->
<!--                      </tr>-->
                      <tr>
                        <td>
                          <div class="kb-form-fields kb-form-fields-v2">
                            <div class="kb-form-row">
                              <div class="kb-form-column kb-form-column-title">
                                <label class="kb-form-label">
                                  <strong class="kb-form-label-text">고지금액합계</strong>
                                </label>
                              </div>
                              <div class="kb-form-column column-auto">
                                <input type="text" class="kb-form-control" placeholder="" :value="`${numberComma(totalPayment)}`" readonly>
                              </div>
                              <div class="kb-form-column column-side">
                                <span class="kb-form-text">원</span>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="kb-form-fields kb-form-fields-v2">
                            <div class="kb-form-row">
                              <div class="kb-form-column kb-form-column-title">
                                <label class="kb-form-label">
                                  <strong class="kb-form-label-text">감면금액합계</strong>
                                </label>
                              </div>
                              <div class="kb-form-column column-auto">
                                <input type="text" class="kb-form-control" placeholder="" :value="`${numberComma(totalReduce)}`" readonly>
                              </div>
                              <div class="kb-form-column column-side">
                                <span class="kb-form-text">원</span>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="kb-form-fields kb-form-fields-v2 mt-4">
                <div class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label"><strong class="kb-form-label-text">지원대상 금액</strong></label>
                  </div>
                  <div class="kb-form-column column-auto">
                    <input type="text" class="kb-form-control" placeholder="" :value="`${numberComma(param.sprtExptAmt)}`" readonly>
                  </div>
                  <div class="kb-form-column column-side">
                    <span class="kb-form-text">원</span>
                  </div>
                </div>
                <div class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <strong class="kb-form-label-text">직전학기 학비지원 후 <br/>장학금 수혜 금액(차감)</strong>
                    </label>
                  </div>
                  <div class="kb-form-column column-auto">
                    <input type="text" class="kb-form-control" placeholder="" :value="`${numberComma(param.befTermBursAmt)}`" readonly>
                  </div>
                  <div class="kb-form-column column-side">
                    <span class="kb-form-text">원</span>
                  </div>
                </div>

                <div class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <strong class="kb-form-label-text">지원금액</strong>
                    </label>
                  </div>
                  <div class="kb-form-column column-auto">
                    <input type="text" class="kb-form-control" placeholder="" :value="`${numberComma(param.sprtAmt)}`" readonly>
                  </div>
                  <div class="kb-form-column column-side">
                    <span class="kb-form-text">원</span>
                  </div>
                </div>
                <Upload
                    v-model="tuitPayCertFiles"
                    title="등록금 납입증명서"
                    placeholder="교육기관에서 발급한 '등록금납입증명서'를 등록하세요."
                    btn-title="등록금 납입증명서 등록"
                    sequence-key="proofDataSn"
                    :extensions="extensions"
                    :max-qty="10"
                    :is-payment="true"
                    :key="`tuition`"
                    :disabled="isReadOnly"
                />
              </div>
            </div>
            </article>

            <article class="popup-section section-form">
              <header class="section-header header-divider-v2">
                <h4 class="title">결과 등록</h4>
              </header>
              <div class="section-content">
                <div class="kb-form-fields kb-form-fields-v2">
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <strong class="kb-form-label-text">학비지원 후<br/> 장학금 수혜여부</strong>
                      </label>
                    </div>
                    <div class="kb-form-column">
                      <div class="kb-form-check-group ps-0">
                        <div  class="kb-form-check kb-form-check-inline">
                          <input v-model="param.bursAmtBnfitYn"  type="radio" class="kb-form-check-input" name="chk_01" value="Y"/>
                          <label class="kb-form-check-label">
                            <span class="text">Y</span>
                          </label>
                          <input v-model="param.bursAmtBnfitYn" type="radio" class="kb-form-check-input" name="chk_01" value="N"/>
                          <label class="kb-form-check-label">
                            <span class="text">N</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Upload
                      v-model="schlScholarCertFiles"
                      :title="param.bursAmtBnfitYn === 'Y' ? '장학금 수혜 증명서' : '장학금 비수혜 증명서'"
                      :placeholder="`${param.bursAmtBnfitYn === 'Y' ? '장학금 수혜' : '장학금 비수혜'} 증명서를 등록해주세요.`"
                      btn-title="증명서 등록하기"
                      sequence-key="proofDataSn"
                      :extensions="extensions"
                      :max-qty="10"
                      :is-payment="true"
                  />
                  <template v-if="param.bursAmtBnfitYn === 'Y'">
                    <div class="kb-form-row">
                      <RowDatepicker
                          title="입금일"
                          v-model="param.dpstYmd"
                          v-model:toggle="toggles.dpstYmd"
                      />
                    </div>
                    <RowInputText v-model="param.dpstBankNm" title="입금은행명" placeholder="입금은행명을 입력해주세요."/>
                    <RowInputText v-model="param.dpstActno"  title="입금계좌번호" placeholder="입금계좌번호를 입력해주세요." />
                    <RowInputText v-model="param.bursAmtAmt" class-lst="column-auto" :isNumber="true" :is-money="true" :is-krw="true" title="장학금 금액" placeholder="장학금 금액을 입력하세요."/>
                  </template>

                </div>
              </div>
            </article>

            <article class="popup-section section-agree" v-if="param.rtnYn=='Y' || param.addAmtYn=='Y'">
              <header class="section-header header-divider-v2">
                <h4 class="title">환입 및 추가지원금액</h4>
              </header>
              <div class="section-content">
                <div class="kb-form-fields kb-form-fields-v2">
                  <RowInputText v-model="param.rtnAmt" title="환입금액"  :is-number="true" :is-money="true" :disabled="true" v-if="param.rtnYn=='Y'" />
                  <RowInputText v-model="param.addAmt" title="추가지원금액"  :is-number="true" :is-money="true" :disabled="true" v-if="param.addAmtYn=='Y'" />
                </div>
              </div>
            </article>

            <article v-if="data == null" class="popup-section section-agree">
              <UserAgreement
                  v-model:user-agreement="userAgreement"
                  @applyBtnEnable="applyBtnEnable"
              />
            </article>
          </div>
        </div>
        <SupportPopupButton
            :stt="data && data.stt"
            :aply-stt-cd-dcd="data && data.aplySttCdDcd"
            :apply-btn-enable="applyBtnEnable"
            :user-agreement="userAgreement"
            :apply="apply"
            :cancel="cancel"
            :close-modal="closeModal"
            type="school"
        />
      </div>
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
  </div>
  <ListModal v-if="collegeModal"
             v-model="collegeModal"
             v-model:selected="school"
             list-type="college"
  />
  <ListModal v-if="majorModal"
             v-model="majorModal"
             v-model:selected="major"
             list-type="major"
  />
</template>

<script>
import Applicant from '@/components/support/common/Applicant';
import InputSelect from '@/components/support/common/InputSelect';
import ListModal from '@/components/support/common/ListModal';
import RowDatepicker from '@/components/support/common/RowDatepicker';
import Upload from '@/components/support/common/Upload';
import RowInputText from '@/components/support/common/RowInputText';
// import InputText from '@/components/support/common/InputText';
import {supportTuitionSetup} from "@/assets/js/modules/support/support-tuition-setup";
import SupportPopupButton from "@/components/support/common/SupportPopupButton";
import UserAgreement from "@/components/support/common/UserAgreement";


export default {
  name: 'SupportTuitionModal',
  components: {SupportPopupButton, RowInputText , Upload, RowDatepicker, ListModal, InputSelect, Applicant, UserAgreement}, //, InputText
  props: {
    modelValue: Boolean,
    data: {type:Object, default:null},
    reList : Function
  },
  setup: supportTuitionSetup
};
</script>