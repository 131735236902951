<template>
  <!-- [begin::popup-container] -->
  <div class="popup-container" :class="{'is-active': isActiveModal}">
    <!-- popup -->
    <div class="popup" id="popup-mypage-z06">
      <!-- popup > popup-inner -->
      <div v-if="isLoading" class="position-absolute w-100 h-100 d-flex justify-content-center align-items-center z-3">
        <LoadingDiv />
      </div>
      <div class="popup-inner">
        <!-- popup-inner > popup-header -->
        <header class="popup-header">
          <h3 class="title">프로필 사진 변경</h3>
          <nav v-if="userProfileTyCdDcds.length > 0" class="popup-tabs mt-3">
            <template v-for="code in userProfileTyCdDcds" :key="code.cd">
              <button v-if="isValidProfile(code.cd)" class="tab" :class="{'is-active': activeProfileTy.cd == code.cd}" @click="activeProfileTy.cd = code.cd">{{code.cdNm}}</button>
            </template>
          </nav>
        </header>
        <!-- popup-inner > popup-section -->
        <div class="popup-section">
          <div class="image-list-container">
            <ul class="image-list">
              <li class="image-item">
                <div class="image-circle" :class="{ 'is-active': profileParam.empImgSn == 0 }" >
                  <div class="image"><a @click="profileParam.empImgSn = 0" class="kb-btn-image"><img :src="defaultImgUrl" @error="setGDefaultImg" /></a></div>
                </div>
              </li>
              <li class="image-item" v-for="(item, idx) in empImgList" :key="idx">
                <div class="image-circle" :class="{ 'is-active': profileParam.empImgSn == item.empImgSn }" >
                  <div class="image"><a @click="profileParam.empImgSn = item.empImgSn" class="kb-btn-image"><img :src="item.url" :alt="'프로필 이미지_' + idx" /></a></div>
                  <button class="kb-btn-delete" @click="deleteProfileImage(item.empImgSn)" ><i class="icon-delete"></i></button>
                </div>
              </li>
              <li class="image-item">
                <div class="kb-form-upload">
                  <label for="uploadFile" class="kb-form-upload-label">
                    <input type="file" class="kb-form-upload-input" id="uploadFile" accept=".jpg, .jpeg, .png, .svg" @change="newProfileImgFile" />
                    <i class="icon-add"></i><span class="text">직접등록</span>
                  </label>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div v-if="activeProfileTy.cd != mainProfileTyCdDcd" class="popup-section">
          <div class="kb-form-fields kb-form-fields-v2">
            <div class="kb-form-row">
              <div class="kb-form-column kb-form-column-title">
                <label class="kb-form-label"><strong class="kb-form-label-text">{{activeProfileTy.cdNm}} 닉네임(필명)</strong></label>
              </div>
              <div class="kb-form-column">
                <input v-model="profileParam.lrnerNickNm" type="text" class="kb-form-control" :placeholder="session.deptNm + ' ' + session.lrnerNm" />
              </div>
            </div>
            <div class="kb-form-bottomtext text-start">
              <i class="icon-notice" />필명 설정 유의사항<br>
              - 필명 설정 시 {{activeProfileTy.cdNm}} 과정 내에서는 필명으로만 활동하게 됩니다. (성명/부점명 비노출)<br>
              - 필명 변경 시 기존 학습노트 및 활동 이력에도 변경된 필명이 적용됩니다.
            </div>
          </div>
        </div>
        <!-- popup-inner > popup-buttons -->
        <div class="popup-buttons">
          <button class="kb-btn kb-btn-outline kb-btn-lg" @click="closeModal"><span class="text">취소</span></button>
          <button class="kb-btn kb-btn-primary kb-btn-lg" @click="setCurrentProfileImg" :disabled="isLoading"><span class="text">저장</span></button>
        </div>
      </div>
      <!-- popup > popup-close -->
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
    <!-- //popup -->
  </div>
  <!-- [end::popup-container] -->
</template>

<script>
import {myPortraitSetup} from "@/assets/js/modules/mypage/profile/my-portrait-setup";
import LoadingDiv from "@/components/common/LoadingDiv.vue";

export default {
  name: 'MyPortraitModal',
  props: {
    userProfileTyCdDcd: String,
  },
  components: {LoadingDiv},
  setup: myPortraitSetup
};
</script>