<template>
  <LxpMobileHeader :title="`${isSearch ? '' : '그룹 참여하기'}`" :caption="`${isSearch ? '' : paging.totalCount}`">
    <template v-slot:left>
      <div class="util util-back">
        <router-link :to="{name: 'GroupLearning'}" class="util-actions util-actions-back">
          <i class="icon-history-back"></i>
        </router-link>
      </div>
    </template>
    <template v-slot:right>
      <div v-if="!isSearch" class="util util-pencil me-2 mt-1">
        <router-link :to="{name: 'GroupLearningMng', params: {boardSn: 0}}" class="util-actions util-actions-yellow">
          생성
        </router-link>
      </div>
      <div v-if="isSearch" class="util util-search-input">
        <input v-model="search.crseInfo" type="text" :placeholder="`그룹명/부점명/리더명 검색`">
      </div>
      <div class="util util-search">
        <a v-if="!isSearch" href="javascript:" class="util-actions util-actions-search" @click="isSearch = true">
          <i class="icon-search"></i>
        </a>
        <a v-else href="javascript:" class="util-actions util-actions-search" @click="clickSearch">
          <i class="icon-search"></i>
        </a>
      </div>
    </template>
  </LxpMobileHeader>
  <main class="kb-main" id="kb-prep-group">
    <div class="main-header">
      <!-- main-header > header-snb -->
      <div class="header-snb">
        <nav class="snb">
          <ul class="snb-list">
            <li class="snb-item">
              <button :class="{'is-active' : activeLrnFld === 'ALL'}" @click="selectLrnFld('ALL', 0)">ALL</button>
            </li>
            <li v-for="(item, idx) in crseDistFlds" :key="idx" class="snb-item">
              <button @click="selectLrnFld(item.distCrseLrnFldNm, item.distCrseLrnFldSn)"
                      :class="{'is-active' : activeLrnFld === item.distCrseLrnFldNm}">
                {{item.distCrseLrnFldNm}}
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <!-- main-content -->
    <div class="main-content manage-group">
      <section class="content-section section-padding">
        <MobileGroupCard :items="items" />
        <!-- learningGroup-container -->
        <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>
        <!-- //learningGroup-container -->
      </section>
    </div>
  </main>
</template>

<script>
import LxpMobileHeader from "@/views/layout/lxp/header/mobile/LxpMobileHeader";
import MobileGroupCard from "@/components/prep/group/mobile/MobileGroupCard";
import {groupApplicationSetup} from "@/assets/js/modules/groupLearing/group-application-setup";
import CommonPaginationFront from "@/components/CommonPaginationFront";
export default {
  name: "MobileGroupApplication",
  components: {CommonPaginationFront, MobileGroupCard, LxpMobileHeader},
  setup: groupApplicationSetup
}
</script>