<template>
  <LxpMobileHeader title="개인별 답안지">
    <template v-slot:left>
      <div class="util util-back">
        <a href="javascript:" class="util-actions util-actions-back" @click="closeEvl">
          <i class="icon-history-back"></i>
        </a>
      </div>
    </template>
  </LxpMobileHeader>
  <main class="kb-main" id="kb-etest">
    <!-- main-content -->
    <div class="main-content etest-contents">
      <!-- entry-container -->
      <section class="entry-container">
        <header class="entry-header"><h3 class="title">응시 정보</h3></header>
        <div class="entry-contents">
          <dl class="content-dl">
            <dt class="title">평가명</dt>
            <dd class="description">{{ evl.lrnEvlNm }}</dd>
            <dt class="title">응시자정보</dt>
            <dd class="description">{{ session.lrnerNm }}({{ session.lrnerId }})  |  {{ session.deptNm }}</dd>
            <dt class="title">응시기간</dt>
            <dd class="description">{{ timestampToDateFormat(evl.evlExamBgngDt, 'yyyy.MM.dd hh:mm') }} - {{ timestampToDateFormat(evl.evlExamEndDt, 'yyyy.MM.dd hh:mm') }}</dd>
          </dl>
        </div>
      </section>
      <!-- sheet-container -->
      <section class="sheet-container">
        <div class="sheet-content">
          <!-- content-group -->
          <article v-for="(question, index) in questions" class="content-group" :data-result="getCorrectText(index)" :key="index">
<!--            <h3 class="content-title">{{ index + 1 }}. {{question.qstnNm}}</h3>-->
            <div class="d-flex align-content-between">
              <div><h3 class="content-title">{{ index + 1 }}.&nbsp;&nbsp;</h3></div>
              <div style="padding-top: 2px; font-size: 15px; font-weight: 700" v-html="question.qstnNm"></div>
            </div>
            <template v-if="['2019003', '2019002', '2019001', '2019005'].includes(question.qstnTyCdDcd)">
              <ul class="content-list">
                <li v-for="(option, idx) in question.options" class="content-item" :class="{'is-active': isOptionAnswer(option, index), 'is-correct': (option.crctAnsYn === 'Y')}" :key="`${index}-${idx}`">
                  <p class="text">{{idx + 1}}. {{ option.optNm }}</p>
<!--                  <div stle="font-size: 15px">{{idx + 1}}. {{ option.optNm }}</div>-->
                </li>
              </ul>
            </template>
            <template v-else>
              <div class="content-item" :class="{'is-active': isCorrect(index)}"><p class="text">{{getAnswer(index)}}</p></div>
              <div class="content-bottom">
                <p class="text">정답  | {{ question.crctAns }}</p>
              </div>
            </template>
            <div v-if="question.qstnDesc" class="content-bottom">
              <p class="text">해설  | {{ question.qstnDesc }}</p>
            </div>
          </article>
          <!-- content-group -->
        </div>
      </section>
    </div>
    <!-- //main-content -->
  </main>
</template>

<script>

import LxpMobileHeader from '@/views/layout/lxp/header/mobile/LxpMobileHeader';
import {evlAnswerSetup} from '@/assets/js/modules/evaluation/evl-setup';

export default {
  name: "MobileEvlAnswer",
  components: {LxpMobileHeader},
  props: {
    evl: Object,
  },
  emits: ['closeEvl'],
  setup: evlAnswerSetup
}
</script>
