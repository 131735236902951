import ApiService from "@/assets/js/api.service";

// ACTION
export const ACT_GET_CRSE_LRM_OBJ_DTL_DIST_LIST = 'actGetCrseLrmObjDtlDistList';
export const ACT_GET_HRD_NOW_ON_SECRET = 'actGetHrdNowOnSecret';
export const ACT_CLOUD_NOW_SSO = 'cloudNowSso';

const state = {
};

const getters = {
};

const mutations = {
};

const actions = {
    [ACT_GET_CRSE_LRM_OBJ_DTL_DIST_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/crses/dists/lrnObjDtlDists', params).then(response => resolve(response))
        });
    },
    [ACT_GET_HRD_NOW_ON_SECRET](context, params) {
        return new Promise(resolve => {
            ApiService.post('/v1/app/getnowonsecret', params).then(response => resolve(response));
        });
    },
    [ACT_CLOUD_NOW_SSO](context, params){
        return new Promise(resolve => {
            ApiService.post('/v1/app/auth/kb/cloudnow', params).then(response => resolve(response));
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};