import LxpLayout from "@/views/layout/lxp/LxpLayout";

const surveyRoutes = [
  {
    path: '/survey',
    component: LxpLayout,
    children: [
      {
        path: 'general/:distCrseSn',
        name: "SurveyGeneral",
        component: () => import('../../views/pages/survey/SurveyGeneral'),
      }
    ],
  }
];

export const setSurveyRoutes = (routes) => {
  routes.push(...surveyRoutes);
}