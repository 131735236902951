import ApiService from '@/assets/js/api.service';

export const ACT_GET_TRNCT_OPER_YMD_CHECK = 'actGetTrnctOperYmdCheck';
export const ACT_INSERT_TRNCT_APLY = 'actInsertTrnctAply'
export const ACT_GET_TRNCT_APLY = 'actGetTrnctAply';
export const ACT_GET_FCLT_RSVT_APLY = 'actGetFcltRsvtAply';
export const ACT_GET_TRNCT_MST_LIST = 'actGetTrnctMstList';

export const ACT_INSERT_INQUIRY = 'actInsertInquiry';
export const ACT_GET_TRNCT_NOTICE_LIST = 'actGetTrnctNoticeList';
export const ACT_GET_TRNCT_NOTICE = 'actGetTrnctNotice';
export const ACT_UPDATE_TRNCT_NOTICE_CNT = 'actUpdateTrnctNoticeCnt';
export const ACT_GET_USER_LIST = 'actGetUserList';
export const ACT_INSERT_FLCT_FSVT_APLY = 'actInsertFlctFsvtAply';

export const ACT_UPDATE_TRNCT_APLY = 'actUpdateTrnctAply';
export const ACT_UPDATE_FCLT_RSVT_APLY = 'actUpdateFcltAply';
export const ACT_CANCEL_TRNCT_PLAY = 'actCancelTrnctPlay';

export const ACT_GET_FCLT_APLY = 'actGetFcltAply';
export const ACT_CANCEL_FCLT_RSVT_APLY = 'actCancelFcltRsvtAply';
export const ACT_GET_TRNCT_OPER_YMD_CALENDER_CHECK = 'actGetTrnctOperYmdCalenderCheck';

export const ACT_INSERT_PEARL = 'actInsertPearl';
export const ACT_INSERT_PEARL_FILE_ATCH = 'actInsertPearlFileAtch';

export const ACT_SET_TRNCT_APLY_MOBILE_INFO = 'actSetTrnctApplyMobileDate'
export const ACT_INIT_TRNCT_APLY_MOBILE_INFO = 'actInitTrnctApplyMobileInfo';

export const ACT_SET_FCLT_APLY_MOBILE_INFO = 'actSetFcltApplyMobileDate'
export const ACT_INIT_FCLT_APLY_MOBILE_INFO = 'actInitFcltApplyMobileInfo';

export const ACT_INIT_TRNCT_POPUP_NOTICE = 'actInitTrnctPopupNotice';

const state = {
    mobileApplyInfo:{
        startDt: undefined,
        venue: 1,
        room: null,
        usePeriodTxt: '숙박날짜',
        usePeriodTxt2: '',

        stbyYn: 'N',
        usePerid: null,
        rsvtAplyDd : null, //입소일
        prsnBrcYn : 'Y', //개인부점여부
        aplcntId : null, //신청자ID
        aplcntNm : null, //신청자명
        aplcntDeptCd : null, //신청자 부서코드
        aplcntDeptNm : null, //신장자 부서명
        aplcntJbgd : null, //신청자직급
        trnctNm : null, //연수원명
        trnctMstSn : null,
        aplyPeopl : 1,  //신청인원
        mlePeopl : null,   //남성인원
        femPeopl : null,   //여성인원
        useRoomCnt : null, //사용객실수
        aplyRsn : null, //신청사유
        dmndCn : null, //요청내용
        carNo : null, //차량번호
        mblTelno : null, //휴대전화번호 서무직원번호
        mngrMemoCn : null, //관리자_메모_내용
        emerCt : null, //비상전화번호
        roomTy : null, //숙실형태
        aplyDtl : [
            {
                aplcntRel : 'A1', //신청자 관계
                cpnnNm : null, //동반자 명
            },
            {
                aplcntRel : null,
                cpnnNm : null,
            },
            {
                aplcntRel : null,
                cpnnNm : null,
            },
            {
                aplcntRel : null,
                cpnnNm : null,
            },
            {
                aplcntRel : null,
                cpnnNm : null,
            },
        ],
        testYn: "Y"
    },

    mobileSportsApplyInfo:{
        fcltDivCdDcd: '2094001',
        reserveDate: null,
        reserveDateTime: null,
        startDt: '',
        searchText: '',

        aplyYmd: null,
        aplcntId : null,
        aplcntNm : null, //신청자명
        aplcntDeptCd : null, //신청자 부서코드
        aplcntDeptNm : null, //신장자 부서명
        aplcntId2 : null,
        aplcntNm2 : null, //신청자명
        aplcntDeptCd2 : null, //신청자 부서코드
        aplcntDeptNm2 : null, //신장자 부서명
        grpNm: null,
        aplyPeopl: null,
        part01RsvtYn: 'N',
        part02RsvtYn: 'N',
        part03RsvtYn: 'N',
        part04RsvtYn: 'N',
        part05RsvtYn: 'N',
        mngrMemo: null,
        aplcntTelno: null,
        aplcntTelno2: null,
        displayTitle : null,
        brdt : null,
        brdt2 : null,
        grpDivCdDcd : '',
        grpDivNm : '단체구분'
    },
    popupNoti: {ctnt:''},
};

const getters = {

};

const mutations = {
    [ACT_SET_TRNCT_APLY_MOBILE_INFO](state, payload) {
        console.log("assign", payload);
        Object.assign(state.mobileApplyInfo, payload)
        console.log("after", state.mobileApplyInfo);
    },
    [ACT_INIT_TRNCT_APLY_MOBILE_INFO](state){
        state.mobileApplyInfo=[];
        state.mobileApplyInfo={
            startDt: undefined,
            venue: 1,
            room: null,
            usePeriodTxt: '숙박날짜',
            usePeriodTxt2: '',

            stbyYn: 'N',
            usePerid: null,
            rsvtAplyDd : null, //입소일
            prsnBrcYn : 'Y', //개인부점여부
            aplcntId : null, //신청자ID
            aplcntNm : null, //신청자명
            aplcntDeptCd : null, //신청자 부서코드
            aplcntDeptNm : null, //신장자 부서명
            aplcntJbgd : null, //신청자직급
            trnctNm : null, //연수원명
            trnctMstSn : null,
            aplyPeopl : 1,  //신청인원
            mlePeopl : null,   //남성인원
            femPeopl : null,   //여성인원
            useRoomCnt : null, //사용객실수
            aplyRsn : null, //신청사유
            dmndCn : null, //요청내용
            carNo : null, //차량번호
            mblTelno : null, //휴대전화번호 서무직원번호
            mngrMemoCn : null, //관리자_메모_내용
            emerCt : null, //비상전화번호
            roomTy : null, //숙실형태
            aplyDtl : [
                {
                    aplcntRel : 'A1', //신청자 관계
                    cpnnNm : null, //동반자 명
                },
                {
                    aplcntRel : null,
                    cpnnNm : null,
                },
                {
                    aplcntRel : null,
                    cpnnNm : null,
                },
                {
                    aplcntRel : null,
                    cpnnNm : null,
                },
                {
                    aplcntRel : null,
                    cpnnNm : null,
                },
            ],
            testYn: "Y"
        }
    },
    [ACT_SET_FCLT_APLY_MOBILE_INFO](state, payload) {
        Object.assign(state.mobileSportsApplyInfo, payload)
    },
    [ACT_INIT_FCLT_APLY_MOBILE_INFO](state){
        state.mobileSportsApplyInfo= [];
        state.mobileSportsApplyInfo={
            fcltDivCdDcd: '2094001',
            reserveDate: null,
            reserveDateTime: null,
            startDt: '',
            searchText: '',

            aplyYmd: null,
            aplcntId : null,
            aplcntNm : null, //신청자명
            aplcntDeptCd : null, //신청자 부서코드
            aplcntDeptNm : null, //신장자 부서명
            aplcntId2 : null,
            aplcntNm2 : null, //신청자명
            aplcntDeptCd2 : null, //신청자 부서코드
            aplcntDeptNm2 : null, //신장자 부서명
            grpNm: null,
            aplyPeopl: null,
            part01RsvtYn: 'N',
            part02RsvtYn: 'N',
            part03RsvtYn: 'N',
            part04RsvtYn: 'N',
            part05RsvtYn: 'N',
            mngrMemo: null,
            aplcntTelno: null,
            aplcntTelno2: null,
            displayTitle : null,
            brdt : null,
            brdt2 : null,
            grpDivCdDcd : '',
            grpDivNm : '단체구분'
        }
    },
    [ACT_INIT_TRNCT_POPUP_NOTICE](state, payload){
        // console.log("store check", payload);
        // state.popupNoti = {ctnt};
        Object.assign(state.popupNoti, payload);
    }

};

const actions = {
    [ACT_GET_TRNCT_OPER_YMD_CALENDER_CHECK](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/trnct/oper/ymd/calender/check', params).then(response => resolve(response))
        });
    },
    [ACT_GET_TRNCT_OPER_YMD_CHECK](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/trnct/oper/ymd/check', params).then(response => resolve(response))
        });
    },
    [ACT_INSERT_TRNCT_APLY](context, params){
        return new Promise(resolve => {
            ApiService.post(`/v1/app/trnct/aply`, params).then(response => resolve(response));
        });
    },
    [ACT_GET_TRNCT_APLY](context, params){
        return new Promise(resolve => {
            ApiService.query('/v1/app/trnct/aply', params).then(response => resolve(response));
        });
    },
    [ACT_GET_FCLT_RSVT_APLY](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/fclt/rsvt/aply', params).then(response => resolve(response))
        });
    },
    [ACT_GET_TRNCT_MST_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/trnct/fclt', params).then(response => resolve(response))
        });
    },
    [ACT_INSERT_INQUIRY](context, params){
        return new Promise(resolve => {
            ApiService.post(`/v1/app/trnct/inquiry`, params).then(response => resolve(response));
        });
    },
    [ACT_GET_TRNCT_NOTICE_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/trnct/ntcs', params).then(response => resolve(response))
        });
    },
    [ACT_GET_TRNCT_NOTICE](context, trnctNtcSn) {
        return new Promise(resolve => {
            ApiService.get('/v1/app/trnct/ntcs', trnctNtcSn).then(response => resolve(response))
        });
    },
    [ACT_UPDATE_TRNCT_NOTICE_CNT](context, {trnctNtcSn}) {
        return new Promise(resolve => {
            ApiService.put(`/v1/app/trnct/ntc/${trnctNtcSn}/cnt`, null).then(response => resolve(response))
        });
    },
    /*[ACT_GET_TRNCT_FILE_ATCH_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/help/files/atchs', params).then(response => resolve(response))
        });
    },*/
    [ACT_GET_USER_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/users', params).then(response => resolve(response))
        });
    },
    [ACT_INSERT_FLCT_FSVT_APLY](context, params){
        return new Promise(resolve => {
            ApiService.post(`/v1/app/fclt/rsvt/aply`, params).then(response => resolve(response));
        });
    },
    [ACT_UPDATE_TRNCT_APLY](context, params) {
        return new Promise(resolve => {
            ApiService.put(`/v1/app/trnct/aply`, params).then(response => resolve(response))
        });
    },
    [ACT_CANCEL_TRNCT_PLAY](context, params) {
        return new Promise(resolve => {
            ApiService.put(`/v1/app/trnct/aply/cancel`, params).then(response => resolve(response))
        });
    },

    [ACT_GET_FCLT_APLY](context, params){
        return new Promise(resolve => {
            ApiService.query('/v1/app/fclt/aply', params).then(response => resolve(response));
        });
    },

    [ACT_CANCEL_FCLT_RSVT_APLY](context, params) {
        return new Promise(resolve => {
            ApiService.put(`/v1/app/fclt/aply/cancel`, params).then(response => resolve(response))
        });
    },
    [ACT_UPDATE_FCLT_RSVT_APLY](context, params){
        return new Promise(resolve => {
            ApiService.put('/v1/app/fclt/rsvt/aply', params).then(response => resolve(response))
        });
    },
    [ACT_INSERT_PEARL](context, params) {
        return new Promise(resolve => {
            ApiService.post('/v1/app/trnct/pearl', params).then(response => resolve(response));
        });
    },
    [ACT_INSERT_PEARL_FILE_ATCH](context, {params, files}) {
        return new Promise(resolve => {
            ApiService.upload('/v1/app/trnct/file', files, params, true).then(response => resolve(response))});
    },

};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
