/*
Json을 string 으로 변환하여 기종별로 native 호출
- android : window.Android.call(jsonString);
- ios : window.location=” iOSNativeCall://call?[jsonString]
*/

import {isInAppAnd, isInAppFlutter, isInAppIOS} from "@/assets/js/navigationUtils";


/**
 * 1) 해당직원번호로 로그인 2 차인증까지 모두 성공한 직원번호를 저장하는 기능  호출되는 경우 내부저장소에 해당직원번호 저장 후 계속 자동로그인 사용 mode : store)
 * 2) 사용자가 메뉴에서 로그아웃을 선택하는 경우 mode clear 로 호출 해당정보 초기화 / clear userid, enctoken ,postUrl 은 null 값 호출 최종 정보 초기화
 **/
export const nativeLoginInfoCall = (jsonString) => {

    // mode 가 store 이고, userid가 null 인 경우 호출 차단
    let checkJson = JSON.parse(jsonString);
    if(!checkJson.mode){
        console.log("storeloginInfo fail. not mode");
        return;
    }
    if(!checkJson.userid&&checkJson.mode=="store"){
        console.log("storeloginInfo fail. not userid");
        return;
    }
    executeApps("storeLoginInfo", jsonString);
    // if(mobileUtils.Android() || isInAppAnd) {
    //     window.Android.storeLoginInfo(jsonString);
    // } else if(mobileUtils.iOS() || isInAppIOS) {
    //     window.location = `iOSNativeCall://storeLoginInfo?${jsonString}`;
    // }
}


export const nativeLoginInfoClear = () => {
    const clearObj = JSON.stringify({
        cmd : "storeLoginInfo",
        mode : 'clear',
        userid : null,
        encToken : null,
        postUrl : null,
    });

    executeApps("storeLoginInfo", clearObj);

    // if(mobileUtils.Android() || isInAppAnd) {
    //     window.Android.storeLoginInfo(clearObj)
    // } else if(mobileUtils.iOS() || isInAppIOS) {
    //     window.location = `iOSNativeCall://storeLoginInfo?${clearObj}`;
    // }
}


export const executeApps = (command, JobJson) => {

    let paramsJson = {};
    if(typeof JobJson === "string"){
        paramsJson = JSON.parse(JobJson);
    }else if(typeof JobJson === "object"){
        paramsJson = JobJson;
    }

    if(isInAppAnd){
        window.Android[command](JSON.stringify(paramsJson));
        console.log('android execute apps', command, paramsJson);
    }else if(isInAppIOS){
        window.location=`iOSNativeCall://${command}?${JSON.stringify(paramsJson)}`;
        console.log('ios execute apps', command, paramsJson);
    }else if(isInAppFlutter){
        window.flutter_inappwebview.callHandler(command, paramsJson);
        console.log('flutter execute apps', command, paramsJson);
    }else{
        console.error('apps execute device error', command, paramsJson);
    }
};
