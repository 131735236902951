<template>
  <div class="main-header" :class="headerClass">
    <!-- main-header > header-top -->
    <slot name="pre"></slot>

    <div class="header-top main-component">
      <div v-if="showBreadcrumb" class="header-column">
        <nav class="kb-breadcrumb">
          <ol v-if="currentMenus.length > 0" class="kb-breadcrumb-list">
            <li class="kb-breadcrumb-item"><a href="javascript:" class="kb-breadcrumb-link no-pointer"><span class="kb-breadcrumb-text">홈</span></a></li>
            <li v-for="(menu, idx) in currentMenus" class="kb-breadcrumb-item" :key="idx">
              <a href="javascript:" class="kb-breadcrumb-link no-pointer"><span class="kb-breadcrumb-text">{{ menu.menuNm }}</span></a>
            </li>
          </ol>
        </nav>
      </div>

      <div v-if="showBack" class="header-column">
        <a href="javascript:" class="page-nav" @click="headerGoBack">
          <i class="icon-kb-nav-arrow"></i>
          <span class="text">이전</span>
        </a>
      </div>

      <div v-if="showTotal" class="header-column">
        <div class="text-group">
          <div class="divider">
            <strong class="text">총 4건</strong>
          </div>
        </div>
      </div>

      <div v-if="showEdit" class="header-column">
        <button type="button" class="kb-btn kb-btn-secondary kb-btn-sm rounded-lg">
          <i class="icon-edit"></i>
          <span class="text">편집하기</span>
          <!-- TODO:: 버튼이 이상함 <span class="text">편집하기</span> -->
        </button>
      </div>

      <div v-if="showEditToLink" class="header-column">
        <router-link :to="{path: toLinkName }" class="kb-btn kb-btn-secondary kb-btn-sm rounded-lg">
          <i class="icon-edit"></i>
        </router-link>
      </div>

      <slot name="inner"></slot>

      <div v-if="showExcelDownload" class="header-column">
        <a href="javascript:" class="text-link"><span class="text">엑셀 다운로드</span><i class="icon-download"></i></a>
      </div>

      <div v-if="downloadInfo.length > 0" class="header-column">
        <a v-for="(item, idx) in downloadInfo" href="javascript:" class="text-link" @click="downloadFx(item.fileKey)" :key="idx">
          <span class="text">{{ item.title }}</span><i class="icon-download"></i>
        </a>
      </div>

      <div v-if="fileDownKey" class="header-column">
        <file-down-link :file-key="fileDownKey" :show-file-nm="fileDownOpt.showFileNm" :show-icon="fileDownOpt.showIcon" />
      </div>

    </div>

    <slot name="outer"></slot>

    <div v-if="showTitle" class="header-title">
      <h2 class="title">{{ currentMenuNm }}</h2>
    </div>
    <div v-else-if="showCustomTitle" class="header-title">
      <h2 class="title">{{ title }}</h2>
      <div class="subtexts" v-if="showPearl">
        <span class="subtitle">기간 : 2022.12.13(화) - 12.30(금) </span>
      </div>
    </div>

    <slot name="action"></slot>
  </div>
</template>
<script>
import {useStore} from 'vuex';
import {computed} from 'vue';
import {useRoute} from 'vue-router';
import {goBack} from '@/assets/js/modules/common/common';
import FileDownLink from '@/components/common/FileDownLink';

export default {
  name: 'LxpMainHeader',
  components: {FileDownLink},
  props: {
    showTitle: Boolean,
    showBreadcrumb: Boolean,
    showBack: Boolean,
    showTotal: Boolean,
    showExcelDownload: Boolean,
    showEdit: Boolean,
    showCustomTitle: Boolean,
    showEditToLink:Boolean,
    toLinkName: String,
    title: String,
    downloadInfo: {
      type: Array,
      default: () => {
        return [];
      }
    },
    downloadFx: Function,
    fileDownKey: String,
    fileDownOpt: {
      type: Object,
      default: () => {
        return {'showFileNm':false, 'showIcon':true}
      }
    },
    showVisitorCnt: Boolean,
    goBackFunc: Function,
    headerClass: String,
    showPearl : Boolean
  },
  component: [FileDownLink],
  setup(props){
    const store = useStore();
    const route = useRoute();

    const currentMenus = computed(() => {
      // const parentsPath = route.path.substring(0, route.path.lastIndexOf('/'));
      const currentMenus = [];
      for(let i = 0; i < store.state.menu.menus.length; i++){
        if(store.state.menu.menus[i].children && store.state.menu.menus[i].children.length > 0){
          for(let j = 0; j < store.state.menu.menus[i].children.length; j++){
            if(store.state.menu.menus[i].children[j].menuUrl && route.path && route.path.indexOf(store.state.menu.menus[i].children[j].menuUrl) > -1){
              currentMenus.push(store.state.menu.menus[i]);
              currentMenus.push(store.state.menu.menus[i].children[j]);
              break;
            }
          }
        }
        // if(store.state.menu.menus[i].menuUrl === parentsPath){
        //   currentMenus.push(store.state.menu.menus[i]);
        //   for(let j = 0; j < store.state.menu.menus[i].children.length; j++){
        //     if(store.state.menu.menus[i].children[j].menuUrl === route.path){
        //       currentMenus.push(store.state.menu.menus[i].children[j]);
        //       break;
        //     }
        //   }
        //   break;
        // }
      }
      return currentMenus;
    });

    const currentMenuNm = computed(() => {
      if(currentMenus.value.length > 0){
        return currentMenus.value[currentMenus.value.length -1].menuNm;
      }
      return ''
    });

    const headerGoBack = () => {
      if(props.goBackFunc instanceof Function) {
        props.goBackFunc();
      }else{
        goBack();
      }
    }

    return {
      currentMenus,
      currentMenuNm,
      headerGoBack

    }
  }
}
</script>
