import store from '@/store';
import {ACT_GET_EMP_CONSULT_REFUND_MAP, ACT_INSERT_EMP_CONSULT_MNG} from "@/store/modules/consult/consult";
import {getItem, lengthCheck, numberComma} from "@/assets/js/util";
import {useAlert} from "@/assets/js/modules/common/alert";
const {showMessage} = useAlert();

// 연수생관리대장 내 미처리 환입비용 존재 여부 확인
export const checkRefundConsult = (failMsg, successFunc) => {
    /**
     * failMsg 내 사용 가능한 변환문자열 *
     * 로그인 직원번호: #lrnerId#, 로그인 직원명: #lrnerNm#, 미환입 총 건수: #count#, 미환입 총 비용: #rtnAmt#, 미환입처리 차수명(다수일 경우 콤마로 구분): #crseNm#
     */
    store.dispatch(`consult/${ACT_GET_EMP_CONSULT_REFUND_MAP}`).then((res) => {
        if(lengthCheck(res)){
            const item = getItem(res);
            if(item.count > 0){
                failMsg = failMsg.replaceAll('#lrnerId#', item.lrnerId)
                            .replaceAll('#lrnerNm#', item.lrnerNm)
                            .replaceAll('#count#', item.count)
                            .replaceAll('#rtnAmt#', numberComma(item.rtnAmt))
                            .replaceAll('#crseNm#', item.crseNm);
                showMessage(failMsg);
                insertConsultNoti(item.cnsltMngSns, failMsg);
            }else{
                if(successFunc instanceof Function) successFunc();
            }
        }else{
            showMessage(`서버처리 중 일시적인 장애가 발생하였습니다.<br>잠시 후 다시 시도 해 주시기 바랍니다.`);
        }
    })
}

const insertConsultNoti = (cnsltMngSns, failMsg) => {
    store.dispatch(`consult/${ACT_INSERT_EMP_CONSULT_MNG}`, {
        cnsltMngSns: cnsltMngSns.length > 1 ? cnsltMngSns : [],
        cnsltMngSn: cnsltMngSns.length <= 1 ? cnsltMngSns[0] : 0,
        cnsltTitle: '시스템 안내',
        cnsltCn: failMsg
    });
}