import ApiService from '@/assets/js/api.service';

export const ACT_GET_LRN_CRSE_WORK_PHASE_LIST = 'getLrnCrseWorkPhaseList';
export const ACT_GET_LRN_CRSE_WORK_ACHV_LIST = 'getLrnCrseWorkAchvList';
export const ACT_GET_LRN_CRSE_WORK_ABILITY_GROUP_LIST = 'getLrnCrseWorkAbilityGroupList';
export const ACT_GET_JOB_LIST = 'getJobList';
export const ACT_GET_CDP_LIST = 'getCdpList';

export const ACT_GET_LRN_CRSE_WORK_GOAL_LIST = 'getLrnCrseWorkGoalList';
export const ACT_INSERT_LRN_CRSE_WORK_GOALS = 'insertLrnCrseWorkGoals';
export const ACT_UPDATE_LRN_CRSE_WORK_GOALS = 'updateLrnCrseWorkGoals';

export const ACT_GET_LRN_CRSE_WORK_ABILITY_GROUP_VICE_LIST = 'getLrnCrseWorkAbilityGroupViceList';
export const ACT_GET_LRN_CRSE_WORK_GOAL_VICE_LIST = 'getLrnCrseWorkGoalViceList';
export const ACT_GET_LRN_CRSE_WORK_GOAL_LIST_BY_LRNER_ID = 'getLrnCrseWorkGoalListByLrnerId';

export const ACT_GET_LRN_SCORE_LIST = 'getLrnScoreList';
export const ACT_GET_LRN_SCORE_VICE_LIST = 'getLrnScoreViceList';

const state = {};

const mutations = {};

const actions = {
  [ACT_GET_LRN_CRSE_WORK_PHASE_LIST](context, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/learns/works/phases', params).then(response => resolve(response));
    });
  },
  [ACT_GET_LRN_CRSE_WORK_ACHV_LIST](context, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/learns/works/achvs', params).then(response => resolve(response));
    });
  },
  [ACT_GET_LRN_CRSE_WORK_ABILITY_GROUP_LIST](context, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/learns/works/abilities/group', params || {}).then(response => resolve(response));
    });
  },
  [ACT_GET_LRN_CRSE_WORK_GOAL_LIST](){
    return new Promise(resolve => {
      ApiService.query('/v1/app/learns/works/goals', {}).then(response => resolve(response));
    });
  },
  [ACT_GET_LRN_CRSE_WORK_GOAL_LIST_BY_LRNER_ID](context, lrnerId){
    return new Promise(resolve => {
      ApiService.query(`/v1/app/learns/works/goals/${lrnerId}/vice`, {}).then(response => resolve(response));
    });
  },
  [ACT_INSERT_LRN_CRSE_WORK_GOALS](context, params){
    return new Promise(resolve => {
      ApiService.post('/v1/app/learns/works/goals', params).then(response => resolve(response));
    });
  },
  [ACT_UPDATE_LRN_CRSE_WORK_GOALS](context, {crseWorkGoalsSn, params}){
    return new Promise(resolve => {
      ApiService.update('/v1/app/learns/works/goals', crseWorkGoalsSn, params).then(response => resolve(response));
    });
  },

  [ACT_GET_LRN_CRSE_WORK_ABILITY_GROUP_VICE_LIST](context, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/learns/works/abilities/group/vice', params || {}).then(response => resolve(response));
    });
  },
  [ACT_GET_LRN_CRSE_WORK_GOAL_VICE_LIST](context, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/learns/works/goals/vice', params ? params :{}).then(response => resolve(response));
    });
  },
  [ACT_GET_LRN_SCORE_LIST](context, {contents, params}){
    return new Promise(resolve => {
      ApiService.query(`/v1/app/learns/${contents}/scores`, params).then(response => resolve(response));
    });
  },
  [ACT_GET_LRN_SCORE_VICE_LIST](context, {contents, params}){
    return new Promise(resolve => {
      ApiService.query(`/v1/app/learns/${contents}/scores/vice`, params || {}).then(response => resolve(response));
    });
  },
  [ACT_GET_JOB_LIST](){
    return new Promise(resolve => {
      ApiService.query('/v1/app/learns/jobs', {}).then(response => resolve(response));
    });
  },
  [ACT_GET_CDP_LIST](){
    return new Promise(resolve => {
      ApiService.query('/v1/app/learns/cdps', {}).then(response => resolve(response));
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
