import store from '@/store';
import {getCheckItems} from '@/assets/js/util';
import {
  ACT_GET_ALL_BADGE_LIST,
  ACT_GET_COM_BADGE_GET_LIST,
} from '@/store/modules/badge/badge';
import {MUT_SET_MY_HRDCLOUD_BADGE} from '@/store/modules/hrdcloud/hrdcloud';

export const hrdBadges = [
  // TODO:: 202307 클라우드이벤트 처리영역
    // 2022년 뱃지 목록 (현재는 프론트화면에서 보기위하여 2024년도랑 바꿔둔 상태)
  [
    {badgeTyCdDcd: "1050", division: "hrdcloud", badgeTyNm: "히든뱃지 실제 값", cdDesc: "히든뱃지 실제 값", hiddenYn:"Y"},
    {badgeTyCdDcd: "1001", division: "hrdcloud", badgeTyNm: "Welcome! 2024클라우드", cdDesc: " 클라우드 영상 최초 마일리지 취득"},
    {badgeTyCdDcd: "1002", division: "hrdcloud", badgeTyNm: "클라우드 마일리지 20", cdDesc: "클라우드 영상 취득마일리지 누적 20M"},
    {badgeTyCdDcd: "1003", division: "hrdcloud", badgeTyNm: "클라우드 마일리지 40", cdDesc: "클라우드 영상 취득마일리지 누적 40M"},
    {badgeTyCdDcd: "1004", division: "hrdcloud", badgeTyNm: "클라우드 마일리지 60", cdDesc: "클라우드 영상 취득마일리지 누적 60M"},
    {badgeTyCdDcd: "1005", division: "hrdcloud", badgeTyNm: "클라우드 마일리지 100", cdDesc: "클라우드 영상 취득마일리지 누적 100M"},
    {badgeTyCdDcd: "1006", division: "hrdcloud", badgeTyNm: "클라우드 마일리지 200", cdDesc: "클라우드 영상 취득마일리지 누적 200M"},
    {badgeTyCdDcd: "1007", division: "hrdcloud", badgeTyNm: "클라우드 마일리지 300", cdDesc: "클라우드 영상 취득마일리지 누적 300M"},
    {badgeTyCdDcd: "1008", division: "hrdcloud", badgeTyNm: "클라우드 마일리지 400", cdDesc: "클라우드 영상 취득마일리지 누적 400M"},
    {badgeTyCdDcd: "1009", division: "hrdcloud", badgeTyNm: "클라우드 마일리지 500", cdDesc: "클라우드 영상 취득마일리지 누적 500M"},
    // { badgeTyCdDcd : "1010", division : "hrdcloud", badgeTyNm : "클라우드 마일리지 600" , cdDesc : "클라우드 영상 취득마일리지 누적 600M" },
    {badgeTyCdDcd: "1011", division: "hrdcloud", badgeTyNm: "클라우드 챔피언", cdDesc: "클라우드 영상 취득마일리지 누적 600M"},
    {badgeTyCdDcd: "1012", division: "hrdcloud", badgeTyNm: "크리에이터[입문]", cdDesc: "클라우드 영상 최초 등재"},
    {badgeTyCdDcd: "1013", division: "hrdcloud", badgeTyNm: "크리에이터[실버]", cdDesc: "클라우드 영상 등재 누적 5건"},
    {badgeTyCdDcd: "1014", division: "hrdcloud", badgeTyNm: "크리에이터[골드]", cdDesc: "클라우드 영상 등재 누적 10건"},
    {badgeTyCdDcd: "1015", division: "hrdcloud", badgeTyNm: "크리에이터[다이아몬드]", cdDesc: "클라우드 영상 등재 누적 20건"},
    {badgeTyCdDcd: "1016", division: "hrdcloud", badgeTyNm: "1일1클의 시작(上)", cdDesc: "상반기 1주 연속 매일(주말/공휴일 포함) 학습완료 달성"},
    {badgeTyCdDcd: "1017", division: "hrdcloud", badgeTyNm: "1일1클의 시작(下)", cdDesc: "하반기 1주 연속 매일(주말/공휴일 포함) 학습완료 달성"},
    {badgeTyCdDcd: "1018", division: "hrdcloud", badgeTyNm: "1일1클의 정석(上)", cdDesc: "상반기 2주 연속 매일(주말/공휴일 포함) 학습완료 달성"},
    {badgeTyCdDcd: "1019", division: "hrdcloud", badgeTyNm: "1일1클의 정석(下)", cdDesc: "하반기 2주 연속 매일(주말/공휴일 포함) 학습완료 달성"},
    {badgeTyCdDcd: "1020", division: "hrdcloud", badgeTyNm: "1일1클의 고수(上)", cdDesc: "상반기 1달 매일(주말/공휴일 포함) 학습완료 달성"},
    {badgeTyCdDcd: "1021", division: "hrdcloud", badgeTyNm: "1일1클의 고수(下)", cdDesc: "하반기 1달 매일(주말/공휴일 포함) 학습완료 달성"},
    {badgeTyCdDcd: "1022", division: "hrdcloud", badgeTyNm: "1일1클의 초고수", cdDesc: "연간 3달 매일(주말/공휴일 포함) 학습완료 달성"},
    {badgeTyCdDcd: "1023", division: "hrdcloud", badgeTyNm: "상반기 개근상", cdDesc: "상반기 매영입일 학습완료 달성"},
    {badgeTyCdDcd: "1024", division: "hrdcloud", badgeTyNm: "하반기 개근상", cdDesc: "하반기 매영업일 학습완료 달성"},
    {badgeTyCdDcd: "1025", division: "hrdcloud", badgeTyNm: "개근 트로피", cdDesc: "연간 매영업일 학습완료 달성"},
    {badgeTyCdDcd: "1026", division: "hrdcloud", badgeTyNm: "댓글요정(上)", cdDesc: "상반기 남긴 댓글 누적 10건"},
    {badgeTyCdDcd: "1027", division: "hrdcloud", badgeTyNm: "댓글요정(下)", cdDesc: "하반기 남긴 댓글 누적 10건"},
    {badgeTyCdDcd: "1028", division: "hrdcloud", badgeTyNm: "좋아요는 좋아요(上)", cdDesc: "상반기 남긴 좋아요 누적 10건 "},
    {badgeTyCdDcd: "1029", division: "hrdcloud", badgeTyNm: "좋아요는 좋아요(下)", cdDesc: "하반기 남긴 좋아요 누적 10건 "},
    {badgeTyCdDcd: "1030", division: "hrdcloud", badgeTyNm: "러닝치어리더(上)", cdDesc: "상반기 남긴 좋아요 누적 100건"},
    {badgeTyCdDcd: "1031", division: "hrdcloud", badgeTyNm: "러닝치어리더(下)", cdDesc: "하반기 남긴 좋아요 누적 100건"},
    {badgeTyCdDcd: "1032", division: "hrdcloud", badgeTyNm: "러닝투게더(上)", cdDesc: "상반기 클라우드 영상 공유 누적 10건"},
    {badgeTyCdDcd: "1033", division: "hrdcloud", badgeTyNm: "러닝투게더(下)", cdDesc: "하반기 클라우드 영상 공유 누적 10건"},
    {badgeTyCdDcd: "1034", division: "hrdcloud", badgeTyNm: "콘텐츠 지킴이", cdDesc: "클라우드 영상 개선의견 제출 누적 10건"},
    {badgeTyCdDcd: "1035", division: "hrdcloud", badgeTyNm: "굿모닝 클라우드", cdDesc: "오전 4시~9시 학습완료 10건 달성"},
    {badgeTyCdDcd: "1036", division: "hrdcloud", badgeTyNm: "Early Bird", cdDesc: "오전 4시~9시 학습완료 50건 달성"},
    {badgeTyCdDcd: "1037", division: "hrdcloud", badgeTyNm: "식후 클라우드", cdDesc: "점심시간(11:00~14:00) 학습완료 10건 달성"},
    {badgeTyCdDcd: "1038", division: "hrdcloud", badgeTyNm: "클라우드 Holic", cdDesc: "점심시간(11:00~14:00) 학습완료 50건 달성"},
    {badgeTyCdDcd: "1039", division: "hrdcloud", badgeTyNm: "굿나잇 클라우드", cdDesc: "오후 8시~오전 4시 학습완료 10건 달성"},
    {badgeTyCdDcd: "1040", division: "hrdcloud", badgeTyNm: "Late Bird", cdDesc: "오후 8시~오전 4시 학습완료 50건 달성"},
    {badgeTyCdDcd: "1041", division: "hrdcloud", badgeTyNm: "Welcome! 클라우드 Easy", cdDesc: "클라우드 Easy 최초 마일리지 취득"},
    {badgeTyCdDcd: "1042", division: "hrdcloud", badgeTyNm: "클라우드 Easy 10", cdDesc: "클라우드 Easy 취득마일리지 누적 10M"},
    {badgeTyCdDcd: "1043", division: "hrdcloud", badgeTyNm: "클라우드 Easy 20", cdDesc: "클라우드 Easy 취득마일리지 누적 20M"}
  ],
  // 2023년 뱃지 목록
  [
    {badgeTyCdDcd: "1044", division: "hrdcloud", badgeTyNm: "클라우드 항해자", cdDesc: "직무/지식/KBTube 동영상학습 각 10마일리지 취득"},
    {badgeTyCdDcd: "1045", division: "hrdcloud", badgeTyNm: "Hot Tube  Summer", cdDesc: "Hot Tube 동영상학습 31마일리지 취득"},
    {badgeTyCdDcd: "1046", division: "hrdcloud", badgeTyNm: "모두의 은실종", cdDesc: "KB은행실무종합과정-종합정리/문제풀이 각 7건 학습완료"},
    {badgeTyCdDcd: "1047", division: "hrdcloud", badgeTyNm: "옥토버페스트", cdDesc: "지식클라우드 동영상학습 30마일리지 취득"},
    {badgeTyCdDcd: "1048", division: "hrdcloud", badgeTyNm: "클라우드배심원", cdDesc: "주요 직무분야 학습완료&난이도평가 20건"},
    {badgeTyCdDcd: "1049", division: "hrdcloud", badgeTyNm: "로드맵을 부탁해!", cdDesc: "HRD 클라우드 학습 로드맵 공모 참여"},
    {badgeTyCdDcd: "1001", division: "hrdcloud", badgeTyNm: "Welcome! 2023클라우드", cdDesc: " 클라우드 영상 최초 마일리지 취득"},
    {badgeTyCdDcd: "1002", division: "hrdcloud", badgeTyNm: "클라우드 마일리지 20", cdDesc: "클라우드 영상 취득마일리지 누적 20M"},
    {badgeTyCdDcd: "1003", division: "hrdcloud", badgeTyNm: "클라우드 마일리지 40", cdDesc: "클라우드 영상 취득마일리지 누적 40M"},
    {badgeTyCdDcd: "1004", division: "hrdcloud", badgeTyNm: "클라우드 마일리지 60", cdDesc: "클라우드 영상 취득마일리지 누적 60M"},
    {badgeTyCdDcd: "1005", division: "hrdcloud", badgeTyNm: "클라우드 마일리지 100", cdDesc: "클라우드 영상 취득마일리지 누적 100M"},
    {badgeTyCdDcd: "1006", division: "hrdcloud", badgeTyNm: "클라우드 마일리지 200", cdDesc: "클라우드 영상 취득마일리지 누적 200M"},
    {badgeTyCdDcd: "1007", division: "hrdcloud", badgeTyNm: "클라우드 마일리지 300", cdDesc: "클라우드 영상 취득마일리지 누적 300M"},
    {badgeTyCdDcd: "1008", division: "hrdcloud", badgeTyNm: "클라우드 마일리지 400", cdDesc: "클라우드 영상 취득마일리지 누적 400M"},
    {badgeTyCdDcd: "1009", division: "hrdcloud", badgeTyNm: "클라우드 마일리지 500", cdDesc: "클라우드 영상 취득마일리지 누적 500M"},
    // { badgeTyCdDcd : "1010", division : "hrdcloud", badgeTyNm : "클라우드 마일리지 600" , cdDesc : "클라우드 영상 취득마일리지 누적 600M" },
    {badgeTyCdDcd: "1011", division: "hrdcloud", badgeTyNm: "클라우드 챔피언", cdDesc: "클라우드 영상 취득마일리지 누적 600M"},
    {badgeTyCdDcd: "1012", division: "hrdcloud", badgeTyNm: "크리에이터[입문]", cdDesc: "클라우드 영상 최초 등재"},
    {badgeTyCdDcd: "1013", division: "hrdcloud", badgeTyNm: "크리에이터[실버]", cdDesc: "클라우드 영상 등재 누적 5건"},
    {badgeTyCdDcd: "1014", division: "hrdcloud", badgeTyNm: "크리에이터[골드]", cdDesc: "클라우드 영상 등재 누적 10건"},
    {badgeTyCdDcd: "1015", division: "hrdcloud", badgeTyNm: "크리에이터[다이아몬드]", cdDesc: "클라우드 영상 등재 누적 20건"},
    {badgeTyCdDcd: "1016", division: "hrdcloud", badgeTyNm: "1일1클의 시작(上)", cdDesc: "상반기 1주 연속 매일(주말/공휴일 포함) 학습완료 달성"},
    {badgeTyCdDcd: "1017", division: "hrdcloud", badgeTyNm: "1일1클의 시작(下)", cdDesc: "하반기 1주 연속 매일(주말/공휴일 포함) 학습완료 달성"},
    {badgeTyCdDcd: "1018", division: "hrdcloud", badgeTyNm: "1일1클의 정석(上)", cdDesc: "상반기 2주 연속 매일(주말/공휴일 포함) 학습완료 달성"},
    {badgeTyCdDcd: "1019", division: "hrdcloud", badgeTyNm: "1일1클의 정석(下)", cdDesc: "하반기 2주 연속 매일(주말/공휴일 포함) 학습완료 달성"},
    {badgeTyCdDcd: "1020", division: "hrdcloud", badgeTyNm: "1일1클의 고수(上)", cdDesc: "상반기 1달 매일(주말/공휴일 포함) 학습완료 달성"},
    {badgeTyCdDcd: "1021", division: "hrdcloud", badgeTyNm: "1일1클의 고수(下)", cdDesc: "하반기 1달 매일(주말/공휴일 포함) 학습완료 달성"},
    {badgeTyCdDcd: "1022", division: "hrdcloud", badgeTyNm: "1일1클의 초고수", cdDesc: "연간 3달 매일(주말/공휴일 포함) 학습완료 달성"},
    {badgeTyCdDcd: "1023", division: "hrdcloud", badgeTyNm: "상반기 개근상", cdDesc: "상반기 매일(주말/공휴일 포함) 학습완료 달성"},
    {badgeTyCdDcd: "1024", division: "hrdcloud", badgeTyNm: "하반기 개근상", cdDesc: "하반기 매일(주말/공휴일 포함) 학습완료 달성"},
    {badgeTyCdDcd: "1025", division: "hrdcloud", badgeTyNm: "연간 개근상", cdDesc: "연간 매일(주말/공휴일 포함) 학습완료 달성"},
    {badgeTyCdDcd: "1026", division: "hrdcloud", badgeTyNm: "댓글은 사랑입니다(上)", cdDesc: "상반기 남긴 댓글 누적 10건"},
    {badgeTyCdDcd: "1027", division: "hrdcloud", badgeTyNm: "댓글은 사랑입니다(下)", cdDesc: "하반기 남긴 댓글 누적 10건"},
    {badgeTyCdDcd: "1028", division: "hrdcloud", badgeTyNm: "좋아요는 좋아요(上)", cdDesc: "상반기 남긴 좋아요 누적 10건 "},
    {badgeTyCdDcd: "1029", division: "hrdcloud", badgeTyNm: "좋아요는 좋아요(下)", cdDesc: "하반기 남긴 좋아요 누적 10건 "},
    {badgeTyCdDcd: "1030", division: "hrdcloud", badgeTyNm: "러닝치어리더(上)", cdDesc: "상반기 남긴 좋아요 누적 100건"},
    {badgeTyCdDcd: "1031", division: "hrdcloud", badgeTyNm: "러닝치어리더(下)", cdDesc: "하반기 남긴 좋아요 누적 100건"},
    {badgeTyCdDcd: "1032", division: "hrdcloud", badgeTyNm: "러닝투게더(上)", cdDesc: "상반기 클라우드 영상 공유 누적 10건"},
    {badgeTyCdDcd: "1033", division: "hrdcloud", badgeTyNm: "러닝투게더(下)", cdDesc: "하반기 클라우드 영상 공유 누적 10건"},
    {badgeTyCdDcd: "1034", division: "hrdcloud", badgeTyNm: "콘텐츠 지킴이", cdDesc: "클라우드 영상 개선의견 제출 누적 10건"},
    {badgeTyCdDcd: "1035", division: "hrdcloud", badgeTyNm: "굿모닝 클라우드", cdDesc: "오전 5시~9시 학습완료 10건 달성"},
    {badgeTyCdDcd: "1036", division: "hrdcloud", badgeTyNm: "Early Bird", cdDesc: "오전 5시~9시 학습완료 50건 달성"},
    {badgeTyCdDcd: "1037", division: "hrdcloud", badgeTyNm: "식후 클라우드", cdDesc: "점심시간(11:00~14:00) 학습완료 10건 달성"},
    {badgeTyCdDcd: "1038", division: "hrdcloud", badgeTyNm: "클라우드 Holic", cdDesc: "점심시간(11:00~14:00) 학습완료 50건 달성"},
    {badgeTyCdDcd: "1039", division: "hrdcloud", badgeTyNm: "굿나잇 클라우드", cdDesc: "오후 8시~12시 학습완료 10건 달성"},
    {badgeTyCdDcd: "1040", division: "hrdcloud", badgeTyNm: "Late Bird", cdDesc: "오후 8시~12시 학습완료 50건 달성"},
    {badgeTyCdDcd: "1041", division: "hrdcloud", badgeTyNm: "Welcome! 클라우드 Easy", cdDesc: "클라우드 Easy 최초 마일리지 취득"},
    {badgeTyCdDcd: "1042", division: "hrdcloud", badgeTyNm: "클라우드 Easy 10", cdDesc: "클라우드 Easy 취득마일리지 누적 10M"},
    {badgeTyCdDcd: "1043", division: "hrdcloud", badgeTyNm: "클라우드 Easy 20", cdDesc: "클라우드 Easy 취득마일리지 누적 20M"}
  ],
  // 2024년 뱃지 목록 (현재는 프론트화면에서 보기위하여 2022년도랑 바꿔둔 상태)
  [
    {badgeTyCdDcd: "1044", division: "hrdcloud", badgeTyNm: "클라우드 항해자", cdDesc: "직무/지식/KBTube 동영상학습 각 10마일리지 취득"},
    {badgeTyCdDcd: "1045", division: "hrdcloud", badgeTyNm: "Hot Tube  Summer", cdDesc: "Hot Tube 동영상학습 31마일리지 취득"},
    {badgeTyCdDcd: "1046", division: "hrdcloud", badgeTyNm: "모두의 은실종", cdDesc: "KB은행실무종합과정-종합정리/문제풀이 각 7건 학습완료"},
    {badgeTyCdDcd: "1047", division: "hrdcloud", badgeTyNm: "옥토버페스트", cdDesc: "지식클라우드 동영상학습 30마일리지 취득"},
    {badgeTyCdDcd: "1048", division: "hrdcloud", badgeTyNm: "클라우드배심원", cdDesc: "주요 직무분야 학습완료&난이도평가 20건"},
    {badgeTyCdDcd: "1049", division: "hrdcloud", badgeTyNm: "로드맵을 부탁해!", cdDesc: "HRD 클라우드 학습 로드맵 공모 참여"},
    {badgeTyCdDcd: "1001", division: "hrdcloud", badgeTyNm: "Welcome! 2024클라우드", cdDesc: " 클라우드 최초 마일리지 취득"},
    {badgeTyCdDcd: "1002", division: "hrdcloud", badgeTyNm: "클라우드 마일리지 20", cdDesc: "클라우드 취득마일리지 누적 20M"},
    {badgeTyCdDcd: "1003", division: "hrdcloud", badgeTyNm: "클라우드 마일리지 40", cdDesc: "클라우드 취득마일리지 누적 40M"},
    {badgeTyCdDcd: "1004", division: "hrdcloud", badgeTyNm: "클라우드 마일리지 60", cdDesc: "클라우드 취득마일리지 누적 60M"},
    {badgeTyCdDcd: "1005", division: "hrdcloud", badgeTyNm: "클라우드 마일리지 100", cdDesc: "클라우드 취득마일리지 누적 100M"},
    {badgeTyCdDcd: "1006", division: "hrdcloud", badgeTyNm: "클라우드 마일리지 200", cdDesc: "클라우드 취득마일리지 누적 200M"},
    {badgeTyCdDcd: "1007", division: "hrdcloud", badgeTyNm: "클라우드 마일리지 300", cdDesc: "클라우드 취득마일리지 누적 300M"},
    {badgeTyCdDcd: "1008", division: "hrdcloud", badgeTyNm: "클라우드 마일리지 400", cdDesc: "클라우드 취득마일리지 누적 400M"},
    {badgeTyCdDcd: "1009", division: "hrdcloud", badgeTyNm: "클라우드 마일리지 500", cdDesc: "클라우드 취득마일리지 누적 500M"},
    // { badgeTyCdDcd : "1010", division : "hrdcloud", badgeTyNm : "클라우드 마일리지 600" , cdDesc : "클라우드 영상 취득마일리지 누적 600M" },
    {badgeTyCdDcd: "1011", division: "hrdcloud", badgeTyNm: "올해의 클라우더", cdDesc: "클라우드 취득마일리지 누적 600M"},
    {badgeTyCdDcd: "1012", division: "hrdcloud", badgeTyNm: "크리에이터[입문]", cdDesc: "클라우드 영상 최초 등재"},
    {badgeTyCdDcd: "1013", division: "hrdcloud", badgeTyNm: "크리에이터[실버]", cdDesc: "클라우드 영상 등재 누적 5건"},
    {badgeTyCdDcd: "1014", division: "hrdcloud", badgeTyNm: "크리에이터[골드]", cdDesc: "클라우드 영상 등재 누적 10건"},
    {badgeTyCdDcd: "1015", division: "hrdcloud", badgeTyNm: "크리에이터[다이아몬드]", cdDesc: "클라우드 영상 등재 누적 20건"},
    {badgeTyCdDcd: "1016", division: "hrdcloud", badgeTyNm: "1일1클의 시작(上)", cdDesc: "상반기 1주 연속 매일(주말/공휴일 포함) 학습완료 달성"},
    {badgeTyCdDcd: "1017", division: "hrdcloud", badgeTyNm: "1일1클의 시작(下)", cdDesc: "하반기 1주 연속 매일(주말/공휴일 포함) 학습완료 달성"},
    {badgeTyCdDcd: "1018", division: "hrdcloud", badgeTyNm: "1일1클의 정석(上)", cdDesc: "상반기 2주 연속 매일(주말/공휴일 포함) 학습완료 달성"},
    {badgeTyCdDcd: "1019", division: "hrdcloud", badgeTyNm: "1일1클의 정석(下)", cdDesc: "하반기 2주 연속 매일(주말/공휴일 포함) 학습완료 달성"},
    {badgeTyCdDcd: "1020", division: "hrdcloud", badgeTyNm: "1일1클의 고수(上)", cdDesc: "상반기 1달 매일(주말/공휴일 포함) 학습완료 달성"},
    {badgeTyCdDcd: "1021", division: "hrdcloud", badgeTyNm: "1일1클의 고수(下)", cdDesc: "하반기 1달 매일(주말/공휴일 포함) 학습완료 달성"},
    {badgeTyCdDcd: "1022", division: "hrdcloud", badgeTyNm: "1일1클의 초고수", cdDesc: "연간 3달 매일(주말/공휴일 포함) 학습완료 달성"},
    {badgeTyCdDcd: "1023", division: "hrdcloud", badgeTyNm: "상반기 개근상", cdDesc: "상반기 매일(주말/공휴일 포함) 학습완료 달성"},
    {badgeTyCdDcd: "1024", division: "hrdcloud", badgeTyNm: "하반기 개근상", cdDesc: "하반기 매일(주말/공휴일 포함) 학습완료 달성"},
    {badgeTyCdDcd: "1025", division: "hrdcloud", badgeTyNm: "연간 개근상", cdDesc: "연간 매일(주말/공휴일 포함) 학습완료 달성"},
    {badgeTyCdDcd: "1026", division: "hrdcloud", badgeTyNm: "댓글은 사랑입니다(上)", cdDesc: "상반기 남긴 댓글 누적 10건"},
    {badgeTyCdDcd: "1027", division: "hrdcloud", badgeTyNm: "댓글은 사랑입니다(下)", cdDesc: "하반기 남긴 댓글 누적 10건"},
    {badgeTyCdDcd: "1028", division: "hrdcloud", badgeTyNm: "좋아요는 좋아요(上)", cdDesc: "상반기 남긴 좋아요 누적 10건 "},
    {badgeTyCdDcd: "1029", division: "hrdcloud", badgeTyNm: "좋아요는 좋아요(下)", cdDesc: "하반기 남긴 좋아요 누적 10건 "},
    {badgeTyCdDcd: "1030", division: "hrdcloud", badgeTyNm: "러닝치어리더(上)", cdDesc: "상반기 남긴 좋아요 누적 100건"},
    {badgeTyCdDcd: "1031", division: "hrdcloud", badgeTyNm: "러닝치어리더(下)", cdDesc: "하반기 남긴 좋아요 누적 100건"},
    {badgeTyCdDcd: "1032", division: "hrdcloud", badgeTyNm: "러닝투게더(上)", cdDesc: "상반기 클라우드 영상 공유 누적 10건"},
    {badgeTyCdDcd: "1033", division: "hrdcloud", badgeTyNm: "러닝투게더(下)", cdDesc: "하반기 클라우드 영상 공유 누적 10건"},
    {badgeTyCdDcd: "1034", division: "hrdcloud", badgeTyNm: "콘텐츠 지킴이", cdDesc: "클라우드 영상 개선의견 제출 누적 10건"},
    {badgeTyCdDcd: "1035", division: "hrdcloud", badgeTyNm: "굿모닝 클라우드", cdDesc: "오전 5시~9시 학습완료 10건 달성"},
    {badgeTyCdDcd: "1036", division: "hrdcloud", badgeTyNm: "Early Bird", cdDesc: "오전 5시~9시 학습완료 50건 달성"},
    {badgeTyCdDcd: "1037", division: "hrdcloud", badgeTyNm: "식후 클라우드", cdDesc: "점심시간(11:00~14:00) 학습완료 10건 달성"},
    {badgeTyCdDcd: "1038", division: "hrdcloud", badgeTyNm: "클라우드 Holic", cdDesc: "점심시간(11:00~14:00) 학습완료 50건 달성"},
    {badgeTyCdDcd: "1039", division: "hrdcloud", badgeTyNm: "굿나잇 클라우드", cdDesc: "오후 8시~12시 학습완료 10건 달성"},
    {badgeTyCdDcd: "1040", division: "hrdcloud", badgeTyNm: "Late Bird", cdDesc: "오후 8시~12시 학습완료 50건 달성"},
    {badgeTyCdDcd: "1041", division: "hrdcloud", badgeTyNm: "Welcome! 클라우드 Easy", cdDesc: "클라우드 Easy 최초 마일리지 취득"},
    {badgeTyCdDcd: "1042", division: "hrdcloud", badgeTyNm: "클라우드 Easy 20", cdDesc: "클라우드 Easy 취득마일리지 누적 20M"},
    {badgeTyCdDcd: "1043", division: "hrdcloud", badgeTyNm: "클라우드 Easy 40", cdDesc: "클라우드 Easy 취득마일리지 누적 40M"}
  ]
];

export const hrdHiddenBadges = [
  // 히든뱃지를 획득하지 않았을 경우 문구 노출용
    [
        // 2022년
      { badgeTyCdDcd : "1050", division : "hrdcloud", badgeTyNm : "히든뱃지 1" , cdDesc : "첫번째 히든 뱃지입니다." },
    ],
    [
      // 2023년
      { badgeTyCdDcd : "1050", division : "hrdcloud", badgeTyNm : "히든뱃지 1" , cdDesc : "첫번째 히든 뱃지입니다."},
    ],
    [
      // 2024년
      { badgeTyCdDcd : "1050", division : "hrdcloud", badgeTyNm : "히든뱃지 1" , cdDesc : "첫번째 히든 뱃지입니다."},
    ]
];

export const getHrdBadgeList = () => {
  store.dispatch(`badge/${ACT_GET_COM_BADGE_GET_LIST}`, {}).then(res => {
    store.commit(`hrdcloud/${MUT_SET_MY_HRDCLOUD_BADGE}`, getCheckItems(res));
  }).catch(() => {});
};

export const getAllBadgeList = () => {
  store.dispatch(`badge/${ACT_GET_ALL_BADGE_LIST}`).then(res => {
    store.commit(`hrdcloud/${MUT_SET_MY_HRDCLOUD_BADGE}`, getCheckItems(res));
  }).catch(() => {});
};

