import {
    ACT_GET_BADGE_LIST,
    MUT_SET_MY_BADGE,
} from '@/store/modules/ssl/ssl-index';
import store from '../../../../store';
import {getCheckItems} from '@/assets/js/util';

export const sslBadges = [
    [
        // 2022년
        // { badgeTyCdDcd : '9999999', division : 'ssl', badgeTyNm : '히든뱃지 실제값', cdDesc : '히든뱃지 실제값', sort: 25, hiddenYn: "Y"},
        { badgeTyCdDcd : '2015001', division : 'ssl', badgeTyNm : '2022러닝2게더', cdDesc : '메이트 첫 결성', sort: 25},
        { badgeTyCdDcd : '2015002', division : 'ssl', badgeTyNm : '개근상上', cdDesc : '상반기 매영업일 출석체크 달성', sort: 4},
        { badgeTyCdDcd : '2015003', division : 'ssl', badgeTyNm : '먼쓸(SSL)리 베스트[개인]', cdDesc : '월간 상위 학습자 300위 달성', sort: 9},
        { badgeTyCdDcd : '2015004', division : 'ssl', badgeTyNm : '먼쓸(SSL)리 베스트[단체]', cdDesc : '월간 상위 학습그룹 50위 달성', sort: 11},
        { badgeTyCdDcd : '2015005', division : 'ssl', badgeTyNm : '바른생활 루틴이 10', cdDesc : '학습노트 작성 누적 10건', sort: 6},
        { badgeTyCdDcd : '2015006', division : 'ssl', badgeTyNm : '바른생활 루틴이 30', cdDesc : '학습노트 작성 누적 30건', sort: 7},
        { badgeTyCdDcd : '2015007', division : 'ssl', badgeTyNm : '바른생활 루틴이 50', cdDesc : '학습노트 작성 누적 50건', sort: 8},
        { badgeTyCdDcd : '2015008', division : 'ssl', badgeTyNm : '미션 쓸(SSL)![셀프러닝]', cdDesc : '학습노트 13건 작성 완료', sort: 19},
        { badgeTyCdDcd : '2015009', division : 'ssl', badgeTyNm : '미션 쓸(SSL)![그룹러닝]', cdDesc : '학습노트 13건 작성 완료', sort: 13},
        { badgeTyCdDcd : '2015010', division : 'ssl', badgeTyNm : '미션 쓸(SSL)![지식e러닝]', cdDesc : '학습노트 13건 작성 완료', sort: 21},
        { badgeTyCdDcd : '2015011', division : 'ssl', badgeTyNm : '미션 쓸(SSL)![클라우드러닝]', cdDesc : '학습노트 13건 작성 완료', sort: 23},
        { badgeTyCdDcd : '2015012', division : 'ssl', badgeTyNm : '미션 쓸(SSL)![북러닝上]', cdDesc : '상반기 독서노트 7건 작성 완료', sort: 15},
        { badgeTyCdDcd : '2015013', division : 'ssl', badgeTyNm : '미션 쓸(SSL)![북러닝下]', cdDesc : '하반기 독서노트 6건 작성 완료', sort: 17},
        { badgeTyCdDcd : '2015014', division : 'ssl', badgeTyNm : '이벤트스타', cdDesc : 'SSL 이벤트 선정', sort: 59},
        { badgeTyCdDcd : '2015015', division : 'ssl', badgeTyNm : '쓸(SSL)플루언서', cdDesc : '나를 구독한 사람 10명 이상', sort: 32},
        { badgeTyCdDcd : '2015016', division : 'ssl', badgeTyNm : '구독왕', cdDesc : '내가 구독한 사람 10명 이상', sort: 27},
        { badgeTyCdDcd : '2015017', division : 'ssl', badgeTyNm : '러닝치어리더', cdDesc : '남긴 좋아요 누적 100개', sort: 36},
        { badgeTyCdDcd : '2015018', division : 'ssl', badgeTyNm : '100회 조회', cdDesc : '열람한 노트 누적 100개', sort: 33},
        { badgeTyCdDcd : '2015019', division : 'ssl', badgeTyNm : '프로출첵러', cdDesc : '2주 단위 매영업일 출석체크 달성', sort: 3},
        { badgeTyCdDcd : '2015020', division : 'ssl', badgeTyNm : '배지챔피언', cdDesc : 'SSL 배지 10개 이상 취득', sort: 46},
        { badgeTyCdDcd : '2015021', division : 'ssl', badgeTyNm : '싹쓸(SSL)이', cdDesc : '메인 1개 + 선택 3개 모두 신청 완료', sort: 2},
        { badgeTyCdDcd : '2015022', division : 'ssl', badgeTyNm : '쓸(SSL)타트', cdDesc : '첫 학습 노트 작성', sort: 1},
        { badgeTyCdDcd : '2015023', division : 'ssl', badgeTyNm : '그룹모임', cdDesc : '그룹러닝 모임 26회 달성', sort: 54},
        { badgeTyCdDcd : '2015024', division : 'ssl', badgeTyNm : 'Live 본방사수', cdDesc : '라이브특강 첫 참여', showYears: ['2022'], sort: 58},
        { badgeTyCdDcd : '2015025', division : 'ssl', badgeTyNm : '오픈런 100', cdDesc : 'SSL 신청 선착순 100명', sort: 55},
        { badgeTyCdDcd : '2015026', division : 'ssl', badgeTyNm : '오픈런 500', cdDesc : 'SSL 신청 선착순 500명', sort: 56},
        { badgeTyCdDcd : '2015027', division : 'ssl', badgeTyNm : '오픈런 1000', cdDesc : 'SSL 신청 선착순 1000명', sort: 57},
        { badgeTyCdDcd : '2015028', division : 'ssl', badgeTyNm : '개근상下', cdDesc : '하반기 매영업일 출석체크 달성', sort: 5},
    ],
    [
        // 2023년
        { badgeTyCdDcd : '2015001', division : 'ssl', badgeTyNm : '2023러닝2게더', cdDesc : '메이트 첫 결성', sort: 25},
        { badgeTyCdDcd : '2015002', division : 'ssl', badgeTyNm : '개근상上', cdDesc : '상반기 매영업일 출석체크 달성', sort: 4},
        { badgeTyCdDcd : '2015003', division : 'ssl', badgeTyNm : '먼쓸(SSL)리 베스트[개인]', cdDesc : '월간 상위 학습자 100위 달성', sort: 9},
        { badgeTyCdDcd : '2015004', division : 'ssl', badgeTyNm : '먼쓸(SSL)리 베스트[단체]', cdDesc : '월간 상위 학습그룹 20위 달성', sort: 11},
        { badgeTyCdDcd : '2015005', division : 'ssl', badgeTyNm : '바른생활 루틴이 10', cdDesc : '학습노트 작성 누적 10건', sort: 6},
        { badgeTyCdDcd : '2015006', division : 'ssl', badgeTyNm : '바른생활 루틴이 30', cdDesc : '학습노트 작성 누적 30건', sort: 7},
        { badgeTyCdDcd : '2015007', division : 'ssl', badgeTyNm : '바른생활 루틴이 50', cdDesc : '학습노트 작성 누적 50건', sort: 8},
        { badgeTyCdDcd : '2015008', division : 'ssl', badgeTyNm : '미션 쓸(SSL)![셀프러닝]', cdDesc : '학습노트 6건 작성 완료', sort: 19},
        { badgeTyCdDcd : '2015009', division : 'ssl', badgeTyNm : '미션 쓸(SSL)![그룹러닝]', cdDesc : '(리더) 학습노트 6건 작성 완료', sort: 13},
        { badgeTyCdDcd : '2015010', division : 'ssl', badgeTyNm : '미션 쓸(SSL)![지식e러닝]', cdDesc : '학습노트 6건 작성 완료', sort: 21},
        { badgeTyCdDcd : '2015011', division : 'ssl', badgeTyNm : '미션 쓸(SSL)![클라우드러닝]', cdDesc : '학습노트 6건 작성 완료', sort: 23},
        { badgeTyCdDcd : '2015012', division : 'ssl', badgeTyNm : '미션 쓸(SSL)![북러닝上]', cdDesc : '상반기 독서노트 4건 작성 완료', sort: 15},
        { badgeTyCdDcd : '2015013', division : 'ssl', badgeTyNm : '미션 쓸(SSL)![북러닝下]', cdDesc : '하반기 독서노트 3건 작성 완료', sort: 17},
        // { badgeTyCdDcd : '2015014', division : 'ssl', badgeTyNm : '이벤트스타', cdDesc : 'SSL 이벤트 선정', showYears: ['2022'], sort: 59},
        { badgeTyCdDcd : '2015015', division : 'ssl', badgeTyNm : '쓸(SSL)플루언서', cdDesc : '나를 구독한 사람 50명 이상', sort: 32},
        { badgeTyCdDcd : '2015016', division : 'ssl', badgeTyNm : '구독구독', cdDesc : '내가 구독한 사람 10명 이상', sort: 27},
        { badgeTyCdDcd : '2015017', division : 'ssl', badgeTyNm : '치어리더', cdDesc : '남긴 좋아요 누적 100개', sort: 36},
        { badgeTyCdDcd : '2015018', division : 'ssl', badgeTyNm : '다독다독', cdDesc : '열람한 노트 누적 100개', sort: 33},

        { badgeTyCdDcd : '2015019', division : 'ssl', badgeTyNm : '프로출첵러', cdDesc : '2주 단위 매영업일 출석체크 달성', sort: 3},
        { badgeTyCdDcd : '2015020', division : 'ssl', badgeTyNm : '배지콜렉터', cdDesc : 'SSL 배지 10개 이상 취득', sort: 46},
        { badgeTyCdDcd : '2015021', division : 'ssl', badgeTyNm : '싹쓸(SSL)이', cdDesc : '신청기간 중 3개 과정 이상 신청', sort: 2},
        { badgeTyCdDcd : '2015022', division : 'ssl', badgeTyNm : '쓸(SSL)타트', cdDesc : '첫 학습 노트 작성', sort: 1},
        { badgeTyCdDcd : '2015023', division : 'ssl', badgeTyNm : '다들 고생 많았어', cdDesc : '그룹러닝 모임 24회 달성', sort: 54},
        // { badgeTyCdDcd : '2015024', division : 'ssl', badgeTyNm : 'Live 본방사수', cdDesc : '라이브특강 첫 참여', showYears: ['2022'], sort: 58},
        { badgeTyCdDcd : '2015025', division : 'ssl', badgeTyNm : '오픈런 100', cdDesc : 'SSL 신청 선착순 100명', sort: 55},
        { badgeTyCdDcd : '2015026', division : 'ssl', badgeTyNm : '오픈런 500', cdDesc : 'SSL 신청 선착순 500명', sort: 56},
        { badgeTyCdDcd : '2015027', division : 'ssl', badgeTyNm : '오픈런 1000', cdDesc : 'SSL 신청 선착순 1000명', sort: 57},
        { badgeTyCdDcd : '2015028', division : 'ssl', badgeTyNm : '개근상下', cdDesc : '하반기 매영업일 출석체크 달성', sort: 5},

        { badgeTyCdDcd : '2015029', division : 'ssl', badgeTyNm : '다독고수', cdDesc : '열람한 노트 누적 300개', sort: 34},
        { badgeTyCdDcd : '2015030', division : 'ssl', badgeTyNm : '다독왕', cdDesc : '열람한 노트 누적 500개', sort: 35},
        { badgeTyCdDcd : '2015031', division : 'ssl', badgeTyNm : '구독고수', cdDesc : '내가 구독한 사람 30명 이상', sort: 28},
        { badgeTyCdDcd : '2015032', division : 'ssl', badgeTyNm : '구독왕', cdDesc : '내가 구독한 사람 50명 이상', sort: 29},
        { badgeTyCdDcd : '2015033', division : 'ssl', badgeTyNm : '구독 좋아요 알림설정까지', cdDesc : '나를 구독한 사람 10명 이상', sort: 30},
        { badgeTyCdDcd : '2015034', division : 'ssl', badgeTyNm : '내가 제일 잘나가', cdDesc : '나를 구독한 사람 30명 이상', sort: 31},
        { badgeTyCdDcd : '2015035', division : 'ssl', badgeTyNm : '두근두근 첫만남', cdDesc : '그룹러닝 모임 1회 달성', sort: 49},
        { badgeTyCdDcd : '2015036', division : 'ssl', badgeTyNm : '우리 조금 친해졌을까', cdDesc : '그룹러닝 모임 5회 달성', sort: 50},
        { badgeTyCdDcd : '2015037', division : 'ssl', badgeTyNm : '모일 때마다 즐거워', cdDesc : '그룹러닝 모임 10회 달성', sort: 51},
        { badgeTyCdDcd : '2015038', division : 'ssl', badgeTyNm : '이 날만을 기다렸어', cdDesc : '그룹러닝 모임 15회 달성', sort: 52},
        { badgeTyCdDcd : '2015039', division : 'ssl', badgeTyNm : '이제 얼마 안남았어', cdDesc : '그룹러닝 모임 20회 달성', sort: 53},

        { badgeTyCdDcd : '2015040', division : 'ssl', badgeTyNm : '쓸인싸 ', cdDesc : '메이트 10명 이상 결성', sort: 26},
        { badgeTyCdDcd : '2015041', division : 'ssl', badgeTyNm : '응원단장', cdDesc : '남긴 좋아요 누적 300개', sort: 37},
        { badgeTyCdDcd : '2015042', division : 'ssl', badgeTyNm : '응원챔피언', cdDesc : '남긴 좋아요 누적 500개', sort: 38},
        { badgeTyCdDcd : '2015043', division : 'ssl', badgeTyNm : '고마워요 댓글요정', cdDesc : '남긴 댓글 누적 100개', sort: 39},
        { badgeTyCdDcd : '2015044', division : 'ssl', badgeTyNm : '당신은 댓글천사', cdDesc : '남긴 댓글 누적 300개', sort: 40},
        { badgeTyCdDcd : '2015045', division : 'ssl', badgeTyNm : '진정한 댓글장인', cdDesc : '남긴 댓글 누적 500개', sort: 41},
        { badgeTyCdDcd : '2015046', division : 'ssl', badgeTyNm : '퍼가요~♡', cdDesc : '담은 노트 10개 이상', sort: 42},
        { badgeTyCdDcd : '2015047', division : 'ssl', badgeTyNm : '먼쓸(SSL)리는 나의 것[개인]', cdDesc : '월간 상위 학습자 100위 달성(5회)', sort: 10},
        { badgeTyCdDcd : '2015048', division : 'ssl', badgeTyNm : '먼쓸(SSL)리는 나의 것[단체]', cdDesc : '월간 상위 학습그룹 20위 달성(5회)', sort: 12},
        { badgeTyCdDcd : '2015049', division : 'ssl', badgeTyNm : '졸업 쓸(SSL)![그룹러닝]', cdDesc : '(리더) 학습노트 24건 작성 완료', sort: 14},
        { badgeTyCdDcd : '2015050', division : 'ssl', badgeTyNm : '졸업 쓸(SSL)![북러닝上]', cdDesc : '상반기 독서노트 14건 작성 완료', sort: 16},
        { badgeTyCdDcd : '2015051', division : 'ssl', badgeTyNm : '졸업 쓸(SSL)![북러닝下]', cdDesc : '하반기 독서노트 10건 작성 완료', sort: 18},
        { badgeTyCdDcd : '2015052', division : 'ssl', badgeTyNm : '졸업 쓸(SSL)![셀프러닝]', cdDesc : '학습노트 24건 작성 완료', sort: 20},
        { badgeTyCdDcd : '2015053', division : 'ssl', badgeTyNm : '졸업 쓸(SSL)![지식e러닝]', cdDesc : '학습노트 24건 작성 완료', sort: 22},
        { badgeTyCdDcd : '2015054', division : 'ssl', badgeTyNm : '졸업 쓸(SSL)![클라우드러닝]', cdDesc : '학습노트 24건 작성 완료', sort: 24},
        { badgeTyCdDcd : '2015055', division : 'ssl', badgeTyNm : '공유할까요 쓸', cdDesc : '공유한 노트 누적 10개', sort: 43},
        { badgeTyCdDcd : '2015056', division : 'ssl', badgeTyNm : '함께할래요 쓸', cdDesc : '공유한 노트 누적 20개', sort: 44},
        { badgeTyCdDcd : '2015057', division : 'ssl', badgeTyNm : '함께다함께 쓸', cdDesc : '공유한 노트 누적 30개', sort: 45},
        { badgeTyCdDcd : '2015058', division : 'ssl', badgeTyNm : '배지마스터', cdDesc : 'SSL 배지 20개 이상 취득', sort: 47},
        { badgeTyCdDcd : '2015059', division : 'ssl', badgeTyNm : '배지KING', cdDesc : 'SSL 배지 30개 이상 취득', sort: 48}
    ]
]

export const sslHiddenBadges = [
    [
    // 2022년
        { badgeTyCdDcd : '9999999', division : 'ssl', badgeTyNm : '히든SSL뱃지 1', cdDesc : '히든SSL뱃지 1 입니다.', sort: 25},
    ],
    [
        // 2023년
        { badgeTyCdDcd : '9999999', division : 'ssl', badgeTyNm : '히든SSL뱃지 1', cdDesc : '히든SSL뱃지 1 입니다.', sort: 25},
    ],
    [
        // 2024년
        { badgeTyCdDcd : '9999999', division : 'ssl', badgeTyNm : '히든SSL뱃지 1', cdDesc : '히든SSL뱃지 1 입니다.', sort: 25},
    ]
]

// export const oldSSLBades = [...sslBadges];
// // 2023년 배지
// sslBadges.push(...[
//     { badgeTyCdDcd : '2015029', division : 'ssl', badgeTyNm : '다독고수', cdDesc : '열람한 노트 누적 300개', sort: 34},
//     { badgeTyCdDcd : '2015030', division : 'ssl', badgeTyNm : '다독왕', cdDesc : '열람한 노트 누적 500개', sort: 35},
//     { badgeTyCdDcd : '2015031', division : 'ssl', badgeTyNm : '구독고수', cdDesc : '내가 구독한 사람 30명 이상', sort: 28},
//     { badgeTyCdDcd : '2015032', division : 'ssl', badgeTyNm : '구독왕', cdDesc : '내가 구독한 사람 50명 이상', sort: 29},
//     { badgeTyCdDcd : '2015033', division : 'ssl', badgeTyNm : '구독 좋아요 알림설정까지', cdDesc : '나를 구독한 사람 10명 이상', sort: 30},
//     { badgeTyCdDcd : '2015034', division : 'ssl', badgeTyNm : '내가 제일 잘나가', cdDesc : '나를 구독한 사람 30명 이상', sort: 31},
//     { badgeTyCdDcd : '2015035', division : 'ssl', badgeTyNm : '두근두근 첫만남', cdDesc : '그룹러닝 모임 1회 달성', sort: 49},
//     { badgeTyCdDcd : '2015036', division : 'ssl', badgeTyNm : '우리 조금 친해졌을까', cdDesc : '그룹러닝 모임 5회 달성', sort: 50},
//     { badgeTyCdDcd : '2015037', division : 'ssl', badgeTyNm : '모일 때마다 즐거워', cdDesc : '그룹러닝 모임 10회 달성', sort: 51},
//     { badgeTyCdDcd : '2015038', division : 'ssl', badgeTyNm : '이 날만을 기다렸어', cdDesc : '그룹러닝 모임 15회 달성', sort: 52},
//     { badgeTyCdDcd : '2015039', division : 'ssl', badgeTyNm : '이제 얼마 안남았어', cdDesc : '그룹러닝 모임 20회 달성', sort: 53},
//     { badgeTyCdDcd : '2015040', division : 'ssl', badgeTyNm : '쓸인싸 ', cdDesc : '메이트 10명 이상 결성', sort: 26},
//     { badgeTyCdDcd : '2015041', division : 'ssl', badgeTyNm : '응원단장', cdDesc : '남긴 좋아요 누적 300개', sort: 37},
//     { badgeTyCdDcd : '2015042', division : 'ssl', badgeTyNm : '응원챔피언', cdDesc : '남긴 좋아요 누적 500개', sort: 38},
//     { badgeTyCdDcd : '2015043', division : 'ssl', badgeTyNm : '고마워요 댓글요정', cdDesc : '남긴 댓글 누적 100개', sort: 39},
//     { badgeTyCdDcd : '2015044', division : 'ssl', badgeTyNm : '당신은 댓글천사', cdDesc : '남긴 댓글 누적 300개', sort: 40},
//     { badgeTyCdDcd : '2015045', division : 'ssl', badgeTyNm : '진정한 댓글장인', cdDesc : '남긴 댓글 누적 500개', sort: 41},
//     { badgeTyCdDcd : '2015046', division : 'ssl', badgeTyNm : '퍼가요~♡', cdDesc : '담은 노트 10개 이상', sort: 42},
//     { badgeTyCdDcd : '2015047', division : 'ssl', badgeTyNm : '먼쓸(SSL)리는 나의 것[개인]', cdDesc : '월간 상위 학습자 100위 달성(5회)', sort: 10},
//     { badgeTyCdDcd : '2015048', division : 'ssl', badgeTyNm : '먼쓸(SSL)리는 나의 것[단체]', cdDesc : '월간 상위 학습그룹 20위 달성(5회)', sort: 12},
//     { badgeTyCdDcd : '2015049', division : 'ssl', badgeTyNm : '졸업 쓸(SSL)![그룹러닝]', cdDesc : '(리더) 학습노트 24건 작성 완료', sort: 14},
//     { badgeTyCdDcd : '2015050', division : 'ssl', badgeTyNm : '졸업 쓸(SSL)![북러닝上]', cdDesc : '상반기 독서노트 14건 작성 완료', sort: 16},
//     { badgeTyCdDcd : '2015051', division : 'ssl', badgeTyNm : '졸업 쓸(SSL)![북러닝下]', cdDesc : '하반기 독서노트 10건 작성 완료', sort: 18},
//     { badgeTyCdDcd : '2015052', division : 'ssl', badgeTyNm : '졸업 쓸(SSL)![셀프러닝]', cdDesc : '학습노트 24건 작성 완료', sort: 20},
//     { badgeTyCdDcd : '2015053', division : 'ssl', badgeTyNm : '졸업 쓸(SSL)![지식e러닝]', cdDesc : '학습노트 24건 작성 완료', sort: 22},
//     { badgeTyCdDcd : '2015054', division : 'ssl', badgeTyNm : '졸업 쓸(SSL)![클라우드러닝]', cdDesc : '학습노트 24건 작성 완료', sort: 24},
//     { badgeTyCdDcd : '2015055', division : 'ssl', badgeTyNm : '공유할까요 쓸', cdDesc : '공유한 노트 누적 10개', sort: 43},
//     { badgeTyCdDcd : '2015056', division : 'ssl', badgeTyNm : '함께할래요 쓸', cdDesc : '공유한 노트 누적 20개', sort: 44},
//     { badgeTyCdDcd : '2015057', division : 'ssl', badgeTyNm : '함께다함께 쓸', cdDesc : '공유한 노트 누적 30개', sort: 45},
//     { badgeTyCdDcd : '2015058', division : 'ssl', badgeTyNm : '배지마스터', cdDesc : 'SSL 배지 20개 이상 취득', sort: 47},
//     { badgeTyCdDcd : '2015059', division : 'ssl', badgeTyNm : '배지KING', cdDesc : 'SSL 배지 30개 이상 취득', sort: 48}
// ]);
//
// sslBadges.sort((a, b) => a.sort - b.sort);

export const getBadgeList = () => {
    // 현재 년도 기준으로 처리한다.
    store.dispatch(`ssl/${ACT_GET_BADGE_LIST}`, {year: new Date().getFullYear()}).then(res => {
        store.commit(`ssl/${MUT_SET_MY_BADGE}`, getCheckItems(res));
    }).catch(() => {});
}

