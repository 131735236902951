<template>
  <div class="board-view-comment view-component mt-lg-5">
    <div class="comment-write" v-if="isWrite">
      <form @submit.prevent class="kb-form-comment">
        <input type="text" v-model.trim="cmnt" class="kb-form-comment-input" placeholder="댓글 달기 .." :disabled="isDisabled" @keyup.enter="clickInsert" />
        <button class="kb-btn kb-btn-secondary kb-btn-comment" :disabled="isDisabled" @click.stop="clickInsert">
          <span class="text">등록</span>
        </button>
      </form>
    </div>
<!--    <div v-if="isWrite" class="comment-write">-->
<!--      <div class="kb-form-comment">-->
<!--        <input v-model.trim="cmnt" type="text" class="kb-form-comment-input" placeholder="댓글 달기" :disabled="isDisabled" @keyup.enter="clickInsert"/>-->
<!--        <button class="kb-btn-comment" :class="{'disabled': isDisabled}" :style="isDisabled ? {} : {backgroundColor: '#FFBC00'}" :disabled="isDisabled" @click="clickInsert">-->
<!--          <span class="text">댓글</span>-->
<!--        </button>-->
<!--      </div>-->
<!--    </div>-->
    <div class="comment-wrapper">
      <ul class="comment-list">
        <li v-if="comments.length == 0" class="comment-item"><p class="comment-delete-text">등록된 댓글이 없습니다.</p></li>
        <template v-for="(comment, idx) in comments" :key="idx">
          <li v-if="comment.stt == '99'" class="comment-item"><p class="comment-delete-text" :style="{'margin-left': `${comment.cmntLvl*2}rem`}">- 삭제된 댓글입니다 -</p></li>
          <li v-else class="comment-item">
          <article class="comment" :style="{'margin-left': `${comment.cmntLvl*2}rem`}">
            <div class="comment-avatar">
              <div class="avatar kb-mouse-cursor">
                <CommonTypeImage
                    :img-src="comment.imgUrl"
                    theme="profile"
                    :target="comment"
                    @clickImage="goLearner"
                />
              </div>
            </div>
            <div class="comment-contents">
              <div class="comment-data">
                <div class="commenter"><span v-if="comment.deptNm != ''">{{ comment.deptNm }} - </span>{{ comment.lrnerNm }}</div>
                <div class="comment-date">{{ timestampToDateFormat(comment.regDt, 'yyyy.MM.dd hh:mm') }}</div>
              </div>
              <template v-if="comment.editMode">
                <div  class="comment-form">
                  <div>
                    <input v-model.trim="comment.cmnt" type="text"  class="comment-field" @keyup.enter="clickUpdate(idx)"/>
                  </div>
                </div>
                <div class="comment-actions">
                  <button class="btn" @click="changeMode(idx)"><span class="text">취소</span></button>
                  <button class="btn" @click="clickUpdate(idx)"><span class="text">수정</span></button>
                </div>
              </template>
              <p v-else class="text">{{ comment.cmnt }}</p>
            </div>

            <div class="comment-actions">
              <div class="dropdown" :class="{ 'is-active' : comment.toggle }">
                <button class="dropdown-btn kb-btn-actions" @click.stop="comment.toggle=!comment.toggle"><i class="icon-menu"></i></button>
                <div class="dropdown-target">
                  <div class="dropdown-box">
                    <ul class="dropdown-option-list" @click.stop="comment.toggle=!comment.toggle">
                      <li class="dropdown-option-item" v-if="!comment.editMode && canCommentEdit(comment.regDt, 'college' === distCrseCd) && session.lrnerId === comment.lrnerId">
                        <a class="dropdown-option-link kb-mouse-cursor" @click="clickDelete(idx)"><span class="dropdown-option-text">삭제</span></a>
                      </li>
                      <li class="dropdown-option-item" v-if="isWrite">
                        <a class="dropdown-option-link kb-mouse-cursor" @click="addReply('add', idx)"><span class="dropdown-option-text">댓글 달기</span></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
<!--            <div v-if="!comment.editMode && canCommentEdit(comment.regDt, 'college' === distCrseCd)" class="comment-actions">-->
<!--              <button class="btn" @click="openCommentEditBox(idx)"><span class="text">댓글</span></button>-->
<!--              <template v-if="session.lrnerId === comment.lrnerId">-->
<!--                <button class="btn" @click="changeMode(idx)"><span class="text">수정</span></button>-->
<!--                <button class="btn" @click="clickDelete(idx)"><span class="text">삭제</span></button>-->
<!--              </template>-->
<!--            </div>-->
          </article>
          <div class="comment-write" v-if="comment.isReplying">
            <div class="kb-form-comment">
              <input type="text" v-model.trim="replyParam.cmnt" class="kb-form-comment-input" placeholder="댓글 달기 .." @keyup.enter="addReply('save', idx)" />
              <button class="kb-btn kb-btn-secondary kb-btn-comment" @click.stop="addReply('cancel', idx)">
                <span class="text">취소</span>
              </button>
              <button class="kb-btn kb-btn-secondary kb-btn-comment mx-1" @click.stop="addReply('save', idx)">
                <span class="text">등록</span>
              </button>
            </div>
          </div>
        </li>
        </template>
      </ul>
    </div>
  </div>
</template>
<script>

import {sslCommentSetup} from '@/assets/js/modules/ssl/setup/ssl-comment-setup';
import CommonTypeImage from '@/components/common/CommonTypeImage';

export default {
  name: 'SSLComment',
  components: {CommonTypeImage},
  props: {
    regDt: {
      type: Number,
      required: true
    },
    isWrite: {
      type: Boolean,
      default: true
    },
    socLrnPostSn: Number
  },
  setup: sslCommentSetup
}
</script>
