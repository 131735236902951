<template>
  <div class="main-content min-component">
    <div class="content-section">
      <header class="section-header">
        <h3 class="title">평가비 신청이력</h3>
      </header>
      <div class="history-table">
        <div class="list-top">
          <div class="top-column">
            <p class="title text-muted">총 {{paging.totalCount}}건</p>
          </div>
          <div class="top-column">
            <span class="text text-gold">신청 현황만 표시됩니다. </span>
          </div>
        </div>

        <template v-if="!isLoading && (evals && evals.length > 0)">
          <div class="kb-table kb-table-bordered">
          <table>
            <colgroup>
              <col style="width:120px;" />
              <col />
              <col />
              <col />
              <col />
              <col style="width:176px" />
              <col style="width:125px;" />
            </colgroup>
            <thead>
            <tr>
              <th><span class="th-title">관리번호</span></th>
              <th><span class="th-title">지원 분야</span></th>
              <th><span class="th-title">평가명</span></th>
              <th><span class="th-title">응시일</span></th>
              <th><span class="th-title">진행상태</span></th>
              <th><span class="th-title">지원 금액</span></th>
              <th><span class="th-title">&nbsp;</span></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(evaluation, idx) in evals" :key="idx">
              <td><span class="td-text">{{ evaluation.costEvlSn }}</span></td>
              <td><span class="td-text">{{ evaluation.lrnCostDivNm }}</span></td>
              <td><strong class="td-text">{{ evaluation.lrnCostFldNm }}</strong></td>
              <td><span class="td-text">{{ evaluation.evlExamDd }}</span></td>
              <td>
                <span class="td-text" :class="evaluation.aplySttCdDcd === '2106010' ? 'text-red' : 'text-muted'" >{{ evaluation.aplySttNm }}</span>
              </td>
              <td><span class="td-text">{{ evaluation.sprtAmt > 0 ? numberComma(evaluation.sprtAmt) : '-' }}</span>
                <span v-if="evaluation.rtnYn=='Y' && evaluation.addAmtYn=='Y'"><br>(환입 및 추가지원)</span>
                <span v-else-if="evaluation.rtnYn=='Y' && evaluation.addAmtYn!='Y'"><br>(환입)</span>
                <span v-else-if="evaluation.rtnYn!='Y' && evaluation.addAmtYn=='Y'"><br>(추가지원)</span>
              </td>
              <td class="cell-btn">
                <button class="kb-btn kb-btn-outline" @click="detailFunc('evaluation', evaluation.costEvlSn)"><strong class="text">상세</strong></button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        </template>

        <div v-else-if="!isLoading && (evals && evals.length === 0)" class="search-container">
          <div class="result-empty">
            <img src="../../assets/lxp/images/common/img_empty.png" alt="">
            <p class="text">신청이력이 없습니다.</p>
          </div>
        </div>
        <LoadingDiv v-if="isLoading"/>
      </div>
      <more-button
          :paging="paging"
          :more-func="moreFunc"
      />
    </div>
  </div>
</template>

<script>
import {numberComma, timestampToDateFormat} from '@/assets/js/util';
import LoadingDiv from '@/components/common/LoadingDiv';
import MoreButton from '@/components/support/common/moreButton';

export default {
  name: 'SupportStatusEvaluation',
  components: {MoreButton, LoadingDiv},
  props: {
    evals: Array,
    paging: Object,
    moreFunc: Function,
    isLoading: Boolean,
    detailFunc: Function
  },
  setup() {
    return {
      timestampToDateFormat, numberComma
    }
  }
};
</script>