import LxpLayout from "@/views/layout/lxp/LxpLayout";
import navigationUtils from "@/assets/js/navigationUtils";
import LxpMobileLayout from "@/views/layout/lxp/mobile/LxpMobileLayout";
const isMobile = navigationUtils.any();

const liveRoutes = [
    {
        path: '/live',
        component: isMobile ? LxpMobileLayout : LxpLayout,
        children: [
            {
                path: 'calendar',
                name: "LiveCalendarMng",
                component: () => isMobile ? import('../../views/pages/live/mobile/MobileLiveCalendarMng') : import('../../views/pages/live/LiveCalendarMng')
            },
            {
                path: 'notice/:distCrseSn',
                name: "LiveNotice",
                component: () => isMobile ? import('../../views/pages/live/mobile/MobileLiveNotice') : import('../../views/pages/live/LiveNotice')
            },
            {
                path: 'notice/:distCrseSn/:lrnPostSn',
                name: "LiveNoticeView",
                component: () => isMobile ? import('../../views/pages/live/mobile/MobileLiveNoticeView') : import('../../views/pages/live/LiveNoticeView')
            },
            {
                path: 'qr/:aesEnc',
                name: "LiveQrCheck",
                component: () => isMobile ? import('../../views/pages/live/mobile/MobileLiveQrCheck') : import('../../views/pages/live/LiveCalendarMng')
            },
        ],
    }
];

export const setLiveRoutes = (routes) => {
    routes.push(...liveRoutes);
}