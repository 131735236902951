<template>
  <div class="popup-container" :class="{ 'is-active' : modelValue.isActive }">
    <div class="popup" id="popup-hrdcloud-kbtube">
      <div class="popup-inner">
        <header class="popup-header">
          <h3 class="title">Hot Tube 등재</h3>
        </header>
        <div class="popup-content">
          <article class="popup-section">
            <div class="section-content">
              <div class="segment-box notice-segment"><p class="text text-gold">등록하신 동영상을 관리자가 심사중입니다. </p></div>
              <div class="kb-form-fields">
                <RowItem title="제목" :data="item.cttTitle"/>
                <RowItem title="분야" :data="item.cloudNmLst"/>
                <RowItem title="공개범위" :data="expsrServ"/>
                <RowItem title="영상 업로드" :show-text="false">
                  <template v-slot:action>
                    <div class="kb-form-column">
                      <ul class="file-list">
                        <li class="file-item"><span class="file-name">{{ item.fileNm }}</span>
                          <span v-if="item.cttFileSz > 0" class="file-size">{{ convertToStorageBytes(item.cttFileSz) }}</span></li>
                      </ul>
                    </div>
                    <div class="kb-form-column justify-content-end t2">
                      <div class="kb-form-preview">
                        <button class="text preview" @click="previewModal=true">미리보기</button>
                      </div>
                    </div>
                  </template>
                </RowItem>
                <div v-if="stringCheck(item.thumb)" class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <strong class="kb-form-label-text">썸네일 업로드</strong>
                    </label>
                  </div>
                  <div class="kb-form-column column-whole">
                    <article class="hrdcloud-card">
                      <div class="hrdcloud-image" style="color:black; outline:unset !important; text-align:center;">
                        <div class="image" style="background-color:black;">
                          <img :src="item.thumb" alt="임시이미지" style="width:unset !important;object-fit: cover;" />
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
                <RowItem v-if="stringCheck(item.orgFileNm)" title="첨부파일" :show-text="false">
                  <template v-slot:action>
                    <div class="kb-form-column">
                      <ul class="file-list">
                        <li class="file-item">
                          <span class="file-name" style=" text-decoration: underline; cursor:pointer !important" @click="downloadFile">{{ item.orgFileNm }}</span>
                          <span v-if="item.fileSz > 0" class="file-size">{{ convertToStorageBytes(item.fileSz) }}</span>
                        </li>
                      </ul>
                    </div>
                  </template>
                </RowItem>

                <div v-if="stringCheck(item.cttCn)" class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <strong class="kb-form-label-text">설명</strong>
                    </label>
                  </div>
                  <div class="kb-form-column column-whole py-3">
                    <div class="editor-content m-h-300 ms-0">
                      <div ref="pageEditor"></div>
                    </div>
                  </div>
                </div>

                <div v-if="stringCheck(item.tags)" class="kb-form-row editor-body-tag" style="margin-top:unset !important;">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <strong class="kb-form-label-text">태그</strong>
                    </label>
                  </div>
                  <div class="kb-form-column tag-list column-whole" style="padding: 22px 40px;">
                    <div v-for="(hashTag, idx) in item.tags" class="tag-item is-active" :key="idx">
                      <span class="tag-item-text">{{ hashTag }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="section-bottom bottom-notice">
              <p class="text text-icon">1. 제작한 영상을 <span style="color:#ff8000;line-height:unset !important;">MP4파일(최대 2GB)로 외부망에서 업로드</span> 해주세요.</p>
              <p class="text">2. 첨부파일(붙임자료)가 있는 경우 <span style="color:#ff8000;line-height:unset !important;">암호화 해제 후 업로드</span> 해 주세요.</p>
              <p class="text"> ㅇ 암호화된 파일은 외부망에서 열람 불가</p>
              <p class="text"> ㅇ PDF/PPT/EXCEL 파일 등록 가능(최대 10MB)</p>
              <p class="text">3. <span style="color:#ff8000;line-height:unset !important;">
                콘텐츠에 개인정보 및 중요정보가 포함되지 않도록 유의</span>하여 주시고, 개인정보 유출 등으로 인한 책임은 등록자에게 있음을 확인해주시기 바랍니다.
              </p>
            </div>
          </article>
        </div>
      </div>
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="modelValue.toggle"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
  </div>
  <Preview v-if="previewModal && item.etnUrl" v-model="previewModal" :item="item" />
</template>

<script>
import RowItem from '@/components/history/module/RowItem';
import Preview from '@/components/history/hrdcloud/Preview';
import {getKbTubeList} from '@/assets/js/modules/hrd/hrd-history-common';
import {convertToStorageBytes, getImg, setParams, stringCheck, winOpen} from '@/assets/js/util';
import {computed, onMounted, reactive, ref, toRefs} from 'vue';
import {useAlert} from '@/assets/js/modules/common/alert';
import ClassicEditor from "@/assets/js/editor/custom-ckeditor";
import {kbtubeEditorConfig} from "@/assets/js/editor/editor-config";

export default {
  name: 'KbTubePending',
  components: {Preview, RowItem},
  props: {
    modelValue: Object,
    cttMstSn: Number,
  },
  setup(props) {
    const {showLoading, hideLoading} = useAlert();
    const {cttMstSn} = toRefs(props);

    const previewModal = ref(false);

    const expsrServ = computed(() => {
      // if (item.expsrServ === '2053001,2053002,2053003,2053004')
      return '전직원';
    });

    const item = reactive({
      cttTitle: '',
      cloudNmLst: '',
      expsrServ: '',
      fileNm: '',
      etnUrl: '',
      itnUrl: '',
      orgFileNm: '',
      cttFileSz: 0,
      fileSz: 0,
      buketNm: '',
      thumb: '',
      tag: '',
      cttCn: '',
      tags: [],
      atchEtnUrl: '',
      atchFileDomain: null
    });

    const pageEditor = ref(null);
    let $_instance = null;

    const downloadFile = () => {
      if (stringCheck(item.atchEtnUrl)) {
        winOpen(`${process.env.VUE_APP_CLOUDFRONT_URL}/${item.atchEtnUrl}`, '_blank');
      }
    };

    let tryCount = 0;
    const setPageEditor = () => {
      if(tryCount > 10) return;
      if(pageEditor.value){
        ClassicEditor.create( pageEditor.value, kbtubeEditorConfig ).then(editor => {
          editor.isReadOnly = true;
          $_instance = editor;
          if(item.cttCn) $_instance.setData(item.cttCn);
        }).catch(error => {
          console.error(error);
        })
      }else{
        tryCount++;
        setPageEditor();
      }
    }

    onMounted(() => {
      showLoading();
      getKbTubeList({cttMstSn: cttMstSn.value}, null, {}, async (res) => {
        await setParams(item, res.items[0]);
        if (item.cloudNmLst && item.cloudNmLst.length > 0) {
          item.cloudNmLst = item.cloudNmLst.replaceAll(/,/g, ' > ');
        }
        item.thumb = stringCheck(item.thumb) ?  getImg(item.thumb) : null;
        item.tags = stringCheck(item.tag) ? item.tag.split(',') : [];

        if (item.atchFileDomain) {
          const atchFile = item.atchFileDomain
          item.orgFileNm = stringCheck(atchFile.orgFileNm) ? atchFile.orgFileNm : null;
          item.fileSz = atchFile.fileSz > 0 ? atchFile.fileSz : 0;
          item.atchEtnUrl = stringCheck(atchFile.etnUrl) ? atchFile.etnUrl : null;
        }
        await setPageEditor();
        hideLoading();
      },() => {hideLoading()});
    });

    return {
      item, expsrServ, previewModal, pageEditor,
      convertToStorageBytes, stringCheck,
      downloadFile
    }
  }
};
</script>