<template>
  <div class="content-section">
    <header class="section-header">
      <h3 class="title">학비 신청이력</h3>
    </header>
    <div class="history-table">
      <div class="list-top">
        <div class="top-column">
          <p class="title text-muted">총 {{ schlPaging.totalCount }}건</p>
        </div>
        <div class="top-column">
          <span class="text text-gold">신청 현황만 표시됩니다. </span>
        </div>
      </div>
      <template v-if="!isSchlLoading && (schools && schools.length > 0)">
        <div class="kb-table kb-table-bordered">
          <table>
          <colgroup>
            <col style="width:120px;" />
            <col />
            <col />
            <col />
            <col style="width:125px" />
            <col style="width:176px;" />
            <col style="width:125px;" />
          </colgroup>
          <thead>
          <tr>
            <th><span class="th-title">관리번호</span></th>
            <th><span class="th-title">학교구분</span></th>
            <th><span class="th-title">학교명</span></th>
            <th><span class="th-title">신청일</span></th>
            <th><span class="th-title">진행상태</span></th>
            <th><span class="th-title">지원 금액</span></th>
            <th><span class="th-title">&nbsp;</span></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(schl, idx) in schools" :key="idx">
            <td><span class="td-text">{{ schl.costTuitFeeSn }}</span></td>
            <td><span class="td-text">{{ schl.schlDivNm }}</span></td>
            <td><strong class="td-text">{{ schl.schlNm }}</strong></td>
            <td><span class="td-text">{{ timestampToDateFormat(schl.regDt, 'yyyy.MM.dd') }}</span></td>
            <CommonTdStt
                :stt="schl.stt"
                :aply-stt-cd-dcd="schl.aplySttCdDcd"
                :sub-text="schl.proofDivCdSchlCert > 0 ? '' : '(결과등록필요)'"
                support-type="school"
            />
            <td><span class="td-text" v-if="schl.aplySttCdDcd === '2106006'">{{ schl.sprtAmt > 0 ? numberComma(schl.sprtAmt) : '-' }}</span>
              <span class="td-text" v-else>-</span>
              <span v-if="schl.rtnYn=='Y' && schl.addAmtYn=='Y'" class="fs-7"><br>(환입 및 추가지원)</span>
              <span v-else-if="schl.rtnYn=='Y' && schl.addAmtYn!='Y'" class="fs-7"><br>(환입 {{numberComma(schl.rtnAmt)}})</span>
              <span v-else-if="schl.rtnYn!='Y' && schl.addAmtYn=='Y'" class="fs-7"><br>(추가지원 {{numberComma(schl.addAmt)}})</span>
            </td>
            <td class="cell-btn"><button class="kb-btn kb-btn-outline" @click="detailFunc('school', schl.costTuitFeeSn)"><strong class="text">상세</strong></button></td>
          </tr>
          </tbody>
        </table>
        </div>
        <more-button
            :paging="schlPaging"
            :more-func="moreFunc"
        />
      </template>

      <div v-else-if="!isSchlLoading && (schools && schools.length === 0)" class="search-container">
        <div class="result-empty">
          <img src="../../assets/lxp/images/common/img_empty.png" alt="">
          <p class="text">신청이력이 없습니다.</p>
        </div>
      </div>
      <LoadingDiv v-if="isSchlLoading" />
    </div>
  </div>

  <div class="content-section">
    <header class="section-header">
      <h3 class="title">부점장 학비 지원이력</h3>
    </header>
    <!-- history-table -->
    <div class="history-table">
      <!-- list-top -->
      <div class="list-top">
        <div class="top-column">
          <p class="title text-muted">총 {{ ofcrPaging.totalCount }}건</p>
        </div>
      </div>
      <template v-if="!isOfcrLoading && (officers && officers.length > 0)">
        <div class="kb-table kb-table-bordered">
          <table>
            <colgroup>
              <col style="width:120px"/>
              <col />
              <col />
              <col />
              <col style="width:125px" />
              <col style="width:176px;" />
              <col style="width:125px;" />
            </colgroup>
            <thead>
            <tr>
              <th><span class="th-title">관리번호</span></th>
              <th><span class="th-title">교육기관</span></th>
              <th><span class="th-title">과목명</span></th>
              <th><span class="th-title">신청일</span></th>
              <th><span class="th-title">진행상태</span></th>
              <th><span class="th-title">지원금액</span></th>
              <th><span class="th-title">&nbsp;</span></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(officer,idx) in officers" :key="idx">
              <td><span class="td-text">{{ officer.costOffcrSn }}</span></td>
              <td><strong class="td-text">{{ officer.eduInstNm }}</strong></td>
              <td><span class="td-text">{{ officer.offcrCrseNm }}</span></td>
              <td><span class="td-text">{{ timestampToDateFormat(officer.regDt, 'yyyy.MM.dd') }}</span></td>
              <td>
                <span class="td-text" :class="officer.aplySttCdDcd === '2106010' ? 'text-red' : 'text-muted'" >{{ officer.aplySttNm }}</span>
              </td>
              <td><span class="td-text" v-if="officer.aplySttCdDcd === '2106006'">{{ officer.sprtAmt > 0 ? numberComma(officer.sprtAmt) : '-' }}</span>
                <span class="td-text" v-else>-</span>
                <span v-if="officer.rtnYn=='Y' && officer.addAmtYn=='Y'" class="fs-7"><br>(환입 및 추가지원)</span>
                <span v-else-if="officer.rtnYn=='Y' && officer.addAmtYn!='Y'" class="fs-7"><br>(환입 {{numberComma(officer.rtnAmt)}})</span>
                <span v-else-if="officer.rtnYn!='Y' && officer.addAmtYn=='Y'" class="fs-7"><br>(추가지원 {{numberComma(officer.addAmt)}})</span>
              </td>
              <td class="cell-btn"><button class="kb-btn kb-btn-outline" @click="detailFunc('officer',officer.costOffcrSn)"><strong class="text">상세</strong></button></td>
            </tr>
            </tbody>
          </table>
          <more-button
              :paging="ofcrPaging"
              :more-func="ofcrMoreFunc"
          />
        </div>
      </template>

      <div v-else-if="!isOfcrLoading && (officers && officers.length === 0)" class="search-container">
        <div class="result-empty">
          <img src="../../assets/lxp/images/common/img_empty.png" alt="">
          <p class="text">신청이력이 없습니다.</p>
        </div>
      </div>
      <LoadingDiv v-if="isOfcrLoading"/>
    </div>
  </div>
</template>

<script>
import MoreButton from '@/components/support/common/moreButton';
import {numberComma, timestampToDateFormat} from '@/assets/js/util';
import LoadingDiv from '@/components/common/LoadingDiv';
import CommonTdStt from "@/components/support/common/CommonTdStt";

export default {
  name: 'SupportStatusSchool',
  components: {CommonTdStt, LoadingDiv, MoreButton},
  props: {
    schools: Array,
    officers: Array,
    schlPaging: Object,
    ofcrPaging: Object,
    moreFunc: Function,
    isSchlLoading: Boolean,
    isOfcrLoading: Boolean,
    detailFunc: Function
  },
  setup(props) {
    const ofcrMoreFunc = (paging) => {
      props.moreFunc(paging, 'officer');
    };

    return {
      timestampToDateFormat, numberComma, ofcrMoreFunc
    }
  }
};
</script>