<template>
  <article class="home-section section-card kb-mouse-cursor" :data-card="sslDivisionCd" @click="goLearn(sslDivisionCd, param.targetLrnTrgtGrpMstSn)">
    <header class="card-header">
      <h3 class="title">{{param.sslDivNm}}</h3>
    </header>
    <div class="card-contents">
      <div class="content-container" data-swiper="swiperCardContent">
        <div class="content-wrapper swiper-wrapper">
          <div class="content swiper-slide">
            <span class="content-title">학습노트 작성</span>
            <span class="content-text is-active" v-if="selfSocBoardCnt >= targetStandard.finish">{{ selfSocBoardCnt }}/{{ targetStandard.finish }}회</span>
            <template v-else>

              <span class="content-text is-active_ing" v-if="isMyLrnTrgt">{{ selfSocBoardCnt }}/{{ targetStandard.finish }}회</span>
              <!-- span class="content-text is-active_ing" v-else-if="selfSocBoardCnt > 0 && selfSocBoardCnt < targetStandard.finish">{{ selfSocBoardCnt }}/{{ targetStandard.finish }}회</span -->
              <span class="content-text is-active_ready" v-else>{{ selfSocBoardCnt }}/{{ targetStandard.finish }}회</span>
            </template>
            <span class="content-subtext">연간 최대 {{ targetStandard.limit }}회</span>
          </div>
        </div>
      </div>
    </div>
    <div class="card-visual">
      <div class="visual-container" data-swiper="swiperCardVisual">
        <div class="visual-wrapper swiper-wrapper">
          <div class="visual-item swiper-slide" :class="{'is-active': (selfSocBoardCnt > targetStandard.finish)}">
            <SSLStarGraph
                v-if="selfSocBoardCnt >= targetStandard.finish"
            />
            <SSLCircleGraph
                v-else
                :target-count="selfSocBoardCnt"
                :target-max-count=" targetStandard.finish"
            />
          </div>
        </div>
        <div class="visual-pagination swiper-pagination"></div>
      </div>
    </div>
  </article>
  <SSLEnroll
      v-if="enroll.toggle"
      v-model:toggle="enroll.toggle"
      :dist-crse-cd="enroll.key"
  />
</template>
<script>
import SSLCircleGraph from '@/components/ssl/graph/SSLCircleGraph';
import SSLStarGraph from '@/components/ssl/graph/SSLStarGraph';
// import {sslStatus1Setup} from '@/assets/js/modules/ssl/setup/ssl-status-setup';
import {sslStatusSetup} from '@/assets/js/modules/ssl/setup/ssl-status-setup';
import SSLEnroll from "@/components/ssl/common/SSLEnroll.vue";

export default {
  name: 'SSLSelfStatus',
  components: {SSLEnroll, SSLStarGraph, SSLCircleGraph},
  props: {
    sslDivisionCd:String,
  },
  setup: sslStatusSetup,
}
</script>
