<template>
  <div class="popup-container" :class="{ 'is-active' : modelValue.isActive }">
    <div class="popup" id="popup-hrdcloud-kbtube">
      <div class="popup-inner">
        <header class="popup-header">
          <h3 class="title">Hot Tube 등재</h3>
        </header>
        <div class="popup-content" @click="closeToggles(toggles)">
          <article class="popup-section">
            <div class="section-content">
              <div class="kb-form-fields">
                <div class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <strong class="kb-form-label-text">제목</strong><span class="color-red">*</span>
                    </label>
                  </div>
                  <div class="kb-form-column column-whole">
                    <input type="text" v-model.trim="cttMstParam.cttTitle" class="kb-form-control" placeholder="제목을 입력하세요."/>
                  </div>
                </div>
                <div class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <strong class="kb-form-label-text">분야</strong><span class="color-red">*</span>
                    </label>
                  </div>
                  <div class="kb-form-column kb-form-dropdown-group">
                    <!-- kb-form-dropdown -->
                    <div v-for="index in 4" :key="index" class="kb-form-dropdown" :class="{'is-active' : getToggle(index)} ">
                      <template v-if="checkCateList(index)">
                        <button class="kb-form-dropdown-btn" @click.stop="openToggle(index)">
                          <span class="text">{{ getParamCate(index) === '' ? setPlaceholder(index) : getParamCate(index) }}</span><i class="icon-arrow"></i>
                        </button>
                        <div class="kb-form-dropdown-options">
                          <div class="dropdown-option">
                            <ul class="dropdown-option-list">
                              <template v-if="index === 1">
                                <li v-for="(item, idx) in catesLvl2" :key="idx" class="dropdown-option-item">
                                  <a href="javascript:" class="dropdown-option-link"
                                     :class="{ 'is-active' : cttMstParam.category2 === item.hrdCloudCateSn }"
                                     @click.stop="selectCate(2, idx)">
                                    <span class="dropdown-option-text">{{ item.hrdCloudCateNm }}</span>
                                  </a>
                                </li>
                              </template>
                              <template v-if="index === 2">
                                <li v-for="(item, idx) in catesLvl3" :key="idx" class="dropdown-option-item">
                                  <a href="javascript:" class="dropdown-option-link"
                                     :class="{ 'is-active' : cttMstParam.category3 === item.hrdCloudCateSn }"
                                     @click.stop="selectCate(3, idx)">
                                    <span class="dropdown-option-text">{{ item.hrdCloudCateNm }}</span>
                                  </a>
                                </li>
                              </template>
                              <template v-if="index === 3">
                                <li v-for="(item, idx) in catesLvl4" :key="idx" class="dropdown-option-item">
                                  <a href="javascript:" class="dropdown-option-link"
                                     :class="{ 'is-active' : cttMstParam.category4 === item.hrdCloudCateSn }"
                                     @click.stop="selectCate(4, idx)">
                                    <span class="dropdown-option-text">{{ item.hrdCloudCateNm }}</span>
                                  </a>
                                </li>
                              </template>
                              <template v-if="index === 4">
                                <li v-for="(item, idx) in catesLvl5" :key="idx" class="dropdown-option-item">
                                  <a href="javascript:" class="dropdown-option-link"
                                     :class="{ 'is-active' : cttMstParam.category5 === item.hrdCloudCateSn }"
                                     @click.stop="selectCate(5, idx)">
                                    <span class="dropdown-option-text">{{ item.hrdCloudCateNm }}</span>
                                  </a>
                                </li>
                              </template>
                            </ul>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
                <div class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <strong class="kb-form-label-text">공개범위</strong>
                    </label>
                  </div>
                  <div class="kb-form-column">
                    <div class="kb-form-dropdown" data-offset="bottom-left">
                      <button class="kb-form-dropdown-btn"><span class="text">전직원</span>
      <!--                  <i class="icon-arrow"></i>-->
                      </button>
                      <div class="kb-form-dropdown-options">
                        <div class="dropdown-option">
                          <ul class="dropdown-option-list">
      <!--                      <li class="dropdown-option-item"><a href="" class="dropdown-option-link"><span class="dropdown-option-text">내부점</span></a></li>-->
      <!--                      <li class="dropdown-option-item"><a href="" class="dropdown-option-link"><span class="dropdown-option-text">소속 지역본부</span></a></li>-->
      <!--                      <li class="dropdown-option-item"><a href="" class="dropdown-option-link"><span class="dropdown-option-text">지역영업그룹(사업그룹)</span></a></li>-->
                            <li class="dropdown-option-item"><a href="javascript:" class="dropdown-option-link"><span class="dropdown-option-text">전직원</span></a></li>
      <!--                      <li class="dropdown-option-item"><a href="" class="dropdown-option-link"><span class="dropdown-option-text">전그룹사(W.I.T.H)</span></a></li>-->
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <strong class="kb-form-label-text">동영상 업로드</strong><span class="color-red">*</span>
                    </label>
                  </div>
                  <div class="kb-form-column">
                    <ul class="file-list">
                      <li class="file-item" v-if="cttFileInfo">
                        <span class="file-name">{{ cttFileInfo.name }}</span>
                        <span class="file-size">{{ convertToStorageBytes(cttFileInfo.size) }}</span>
                        <button class="kb-btn-file-delete" @click="removeFile('ctt')"><i class="icon-delete"></i></button>
                      </li>
                      <li v-else>
                        <span class="file-name-placeholder kb-form-text">동영상을 업로드해주세요.</span>
                      </li>
                    </ul>
                  </div>
                  <div class="kb-form-column justify-content-end t2">
                    <div class="kb-form-preview">
                      <button v-if="cttMstParam.etnUrl" class="text preview" @click="pcPreviewModal=true">미리보기</button>
                    </div>
                    <div class="kb-form-file">
                      <FileUpload v-model="cttFile" file-type="vod" />
                      <label for="inpFile" class="kb-btn-link"><span class="text">파일찾기</span></label>
                    </div>
                  </div>
                </div>

                <div class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <strong class="kb-form-label-text">썸네일 이미지</strong>
                    </label>
                  </div>
                  <div class="kb-form-column">
                    <ul class="file-list">
                      <li v-if="thumbSrc" class="file-item" >
                        <span class="file-name">
                          <article class="hrdcloud-card">
                            <div class="hrdcloud-image" style="color:black; outline:unset !important; text-align:center;">
                              <div class="image" style="background-color:black;">
                                <img :src="thumbSrc" alt="썸네일 이미지" style="width:unset !important; object-fit:cover;" />
                              </div>
                            </div>
                          </article>
                        </span>
                        <button class="kb-btn-file-delete" @click="removeFile('thumb')"><i class="icon-delete"></i></button>
                      </li>
                      <li v-else><span class="file-name-placeholder kb-form-text">썸네일을 업로드해주세요.(권장사이즈: 1200x675)</span></li>
                    </ul>
                  </div>
                  <div class="kb-form-column justify-content-end">
                    <div class="kb-form-file">
                      <FileUpload v-model="thumbFile" file-type="img" />
                      <label for="inpFile" class="kb-btn-link"><span class="text">파일찾기</span></label>
                    </div>
                  </div>
                </div>

                <div class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <strong class="kb-form-label-text">파일첨부</strong>
                    </label>
                  </div>
                  <div class="kb-form-column">
                    <ul class="file-list">
                      <li class="file-item" v-if="atchFileInfo">
                        <span class="file-name">{{ atchFileInfo.name }}</span>
                        <span class="file-size">{{ convertToStorageBytes(atchFileInfo.size) }}</span>
                        <button class="kb-btn-file-delete" @click="removeFile('atch')"><i class="icon-delete"></i></button>
                      </li>
                      <li v-else>
                        <span class="file-name-placeholder kb-form-text">파일을 업로드해주세요.</span>
                      </li>
                    </ul>
                  </div>
                  <div class="kb-form-column justify-content-end">
                    <div class="kb-form-file">
                      <FileUpload v-model="atchFile" file-type="kbtube" :size-limit="10" />
                      <label for="inpFile" class="kb-btn-link"><span class="text">파일찾기</span></label>
                    </div>
                  </div>
                </div>

                <div class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <strong class="kb-form-label-text">설명</strong>
                    </label>
                  </div>
                  <div class="kb-form-column column-whole py-3">
                    <div class="editor-content m-h-300 ms-0">
                      <div ref="pageEditor"></div>
                    </div>
                  </div>
                </div>

                <div class="kb-form-row editor-body-tag" style="margin-top:unset !important;">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <strong class="kb-form-label-text">해시태그</strong><span class="color-red">*</span>
                    </label>
                  </div>
                  <div class="kb-form-column tag-list" style="padding: 22px 40px;">
                    <div v-for="(hashTag, idx) in hashTags" class="tag-item is-active" :key="idx">
                      <span class="tag-item-text">{{ hashTag }}</span>
                      <button class="kb-btn-tag" @click="deleteHashTag(idx)">
                        <i class="icon-tag-delete"></i>
                      </button>
                    </div>
                    <div class="tag-item">
                      <input v-model.trim="hashTag" type="text" class="tag-item-input" placeholder="해시태그 입력 후 Enter"
                             @keyup.enter="addHashTag"
                      />
                    </div>
                  </div>
                </div>

                <div v-if="cttMstParam.stt == '78' && cttMstParam.atrzOpn" class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <strong class="kb-form-label-text">사유</strong>
                    </label>
                  </div>
                  <div class="kb-form-column">
                    <p class="kb-form-text">{{ cttMstParam.atrzOpn }}</p>
                  </div>
                </div>

              </div>
            </div>
            <div class="section-bottom bottom-notice">
              <p class="text text-icon">1. 제작한 영상을 <span style="color:#ff8000;line-height:unset !important;">MP4파일(최대 2GB)로 외부망에서 업로드</span> 해주세요.</p>
              <p class="text">2. 첨부파일(붙임자료)가 있는 경우 <span style="color:#ff8000;line-height:unset !important;">암호화 해제 후 업로드</span> 해 주세요.</p>
              <p class="text"> ㅇ 암호화된 파일은 외부망에서 열람 불가</p>
              <p class="text"> ㅇ PDF/PPT/EXCEL 파일 등록 가능(최대 10MB)</p>
              <p class="text">3. <span style="color:#ff8000;line-height:unset !important;">
                콘텐츠에 개인정보 및 중요정보가 포함되지 않도록 유의</span>하여 주시고, 개인정보 유출 등으로 인한 책임은 등록자에게 있음을 확인해주시기 바랍니다.
              </p>            </div>
          </article>
        </div>
        <div class="popup-buttons">
          <template v-if="isTranscoding">
            <button class="kb-btn kb-btn-outline kb-btn-lg" disabled><span class="text">인코딩중</span></button>
          </template>
          <template v-else>
            <button v-if="checkCttStt(cttMstParam.stt)" class="kb-btn kb-btn-secondary kb-btn-lg" @click="handleDeleteKbTube"><span class="text">삭제</span></button>
            <button class="kb-btn kb-btn-outline kb-btn-lg" @click="submitKbTube(0)"><span class="text">저장</span></button>
            <button class="kb-btn kb-btn-primary kb-btn-lg" @click="submitKbTube(1)"><span class="text">제출</span></button>
          </template>
        </div>
      </div>
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
    <LxpLoading v-if="isLoading" />
  </div>
  <Preview v-if="pcPreviewModal && cttMstParam.etnUrl" v-model="pcPreviewModal" :item="cttMstParam" />
</template>
<script>
import FileUpload from '@/components/history/module/FileUpload';
import Preview from '@/components/history/hrdcloud/Preview';
import LxpLoading from '@/components/common/LxpLoading';
import {kbtubeUploadSetup} from '@/assets/js/modules/kbtube/kbtube-upload-setup';

export default {
  name: 'KbTubeUpload',
  components: {LxpLoading, Preview, FileUpload},
  props: {
    modelValue: {
      type: Object,
      default: () => {
        return {
          isActive: false,
          toggle: () => {}
        }
      }
    },
    cttMstSn: {
      type: Number,
      default: 0
    }
  },
  setup: kbtubeUploadSetup
};
</script>