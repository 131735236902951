<template>
  <section class="content-section">
    <header class="section-header">
      <h4 class="title">결제정보</h4>
    </header>
    <div class="section-content">
      <div class="kb-form-fields kb-form-fields-v2 kb-form-fields-divider">
        <!-- kb-form-field:결제정보 -->
        <div class="kb-form-field field-first">
          <div class="kb-form-row">
            <div class="kb-form-check-group">
              <template v-if="!disabled">
                <div v-for="(item, idx) in stlmMthdCdDcdLst" class="kb-form-check" :key="idx">
                  <input type="radio"
                         class="kb-form-check-input" name="chk" :id="`chk_01_${idx}`"
                         v-model="param.stlmMthdCdDcd"
                         :value="item.cd">
                  <label :for="`chk_01_${idx}`" class="kb-form-check-label">
                    <span class="label-text">{{ item.cdNm }}</span>
                  </label>
                </div>
              </template>
              <template v-else>
                <input
                    type="radio" class="kb-form-check-input" name="chk_01" :id="`chk_01`"
                    v-model="param.stlmMthdCdDcd"
                    :value="param.stlmMthdCdDcd"
                    :disabled="disabled"
                />
              </template>
            </div>
          </div>
        </div>
        <!-- Start Card -->
        <template v-if="param.stlmMthdCdDcd === stlmMthdCdDcds.STLM_MTHD_CD_CARD">
          <!-- kb-form-field:카드종류 -->
          <div class="kb-form-field">
            <InputCustomSelect
                v-model:value1="param.cardKind"
                v-model:toggle1="payToggles.cardKinds"
                title="카드 종류"
                :options1="cardKinds"
                sequence-key1="nm"
                name-key1="nm"
                placeholder1="카드종류를 선택해주세요."
                :disabled="disabled"
                @update:toggle1="$emit('update:cardKindToggle',payToggles.cardKinds)"
            />
          </div>
          <!-- kb-form-field:카드번호 -->
          <div class="kb-form-field">
            <LabelCustomInputText v-model="param.cardNo" title="카드번호" :is-text-number="true"  placeholder="카드 번호를 입력하세요" :disabled="disabled" />
            <div class="kb-form-row">
              <p class="kb-form-subtext">반드시 실카드번호를 입력해주세요. (가상카드번호가 아닌 실카드번호)</p>
            </div>
          </div>
          <!-- kb-form-field:승인번호 -->
          <div class="kb-form-field">
            <LabelCustomInputText v-model="param.cardAprvNo" title="승인번호" :is-text-number="true"  placeholder="승인 번호를 입력하세요" :disabled="disabled" />
          </div>
          <!-- kb-form-field:승인 ・ 입금일 -->
          <div class="kb-form-field">
            <RowCustomDatepicker
                v-model="param.cardAprvDt"
                v-model:toggle="payToggles.cardAprvDt"
                :select-box="true"
                title="승인 ・ 입금일"
                :disabled="disabled"
                @update:toggle="$emit('update:cardAprvDtToggle',payToggles.cardAprvDt)"
            />
          </div>
          <!-- kb-form-field:가맹점명 -->
          <div class="kb-form-field">
            <LabelCustomInputText v-model.trim="param.frcsNm" title="가맹점명" placeholder="가맹점명을 입력하세요" :disabled="disabled"/>
          </div>
          <!-- kb-form-field:결제 영수증 -->
          <div class="kb-form-field">
            <MobileUpload
                v-if="isInit"
                v-model="receiptFiles"
                title="결제 영수증"
                placeholder="카드번호,승인번호, 승인일, 승인금액, 가맹점 정보가 기재된 결제영수증을 등록하세요."
                btn-title="결제 영수증 가져오기"
                :extensions="extensions"
                :max-qty="10"
                sequence-key="proofDataSn"
                :is-payment="true"
                :disabled="disabled"
                @update:modelValue="$emit('update:files',receiptFiles)"
            />
            <div class="kb-form-row">
              <p class="kb-form-subtext">* 영수증은 본인의 KB카드에 한함</p>
            </div>
          </div>
        </template>
        <template v-else>
          <LabelCustomInputText v-model="param.dpstBnkNm" title="입금은행명" placeholder="입금은행명을 입력하세요" :disabled="disabled"/>
          <LabelCustomInputText v-model="param.dpstActno" title="입금계좌번호" placeholder="입금계좌번호를 입력하세요" :disabled="disabled"/>
          <LabelCustomInputText v-model="param.dpstrNm" title="예금주" placeholder="예금주를 입력하세요" :disabled="disabled"/>
          <!-- kb-form-field:결제 영수증 -->
          <div class="kb-form-field">
            <MobileUpload
                v-if="isInit"
                v-model="receiptFiles"
                title="입금 영수증"
                placeholder="입금 영수증을 등록하세요."
                btn-title="입금 영수증 가져오기"
                :extensions="extensions"
                :max-qty="10"
                sequence-key="proofDataSn"
                :is-payment="true"
                :disabled="disabled"
                @update:modelValue="$emit('update:files',receiptFiles)"
            />
            <div class="kb-form-row">
              <p class="kb-form-subtext">* 영수증은 본인의 KB카드에 한함</p>
            </div>
          </div>
        </template>


      </div>
    </div>
  </section>
</template>

<script>
import {computed, onMounted, reactive, ref, watch} from 'vue';
import {getDcdOptions} from '@/assets/js/ui.init';
import {paymentDomain, stlmMthdCdDcds} from '@/assets/js/modules/support/support-common';
// import Upload from '@/components/support/common/Upload';
import {setParams} from '@/assets/js/util';
import LabelCustomInputText from "@/components/support/common/LabelCustomInputText";
import InputCustomSelect from "@/components/support/common/InputCustomSelect";
import MobileUpload from "@/components/support/common/MobileUpload";
import RowCustomDatepicker from "@/components/support/common/RowCustomDatepicker";
import {extensions} from "@/assets/js/modules/support/support-common";

export default {
  name: 'MobilePayment',
  components: {
    RowCustomDatepicker,
    MobileUpload,
    InputCustomSelect,
    LabelCustomInputText,
  },
  props: {
    modelValue: Object,
    cardKindsToggle: Boolean,
    cardAprvDtToggle: Boolean,
    files: Object,
    disabled: { type:Boolean,  default: false },
  },
  emits: ['update:modelValue', 'update:cardKindToggle', 'update:cardAprvDtToggle', 'update:files'],
  setup(props, {emit}) {
    const isInit = ref(false);
    const stlmMthdCdDcdLst = computed(() => getDcdOptions('2058'));
    const cardKinds = [ { nm:'KB국민 카드' }, { nm:'KB국민 체크카드'} ];
    const param = reactive({...paymentDomain});

    const payToggles = reactive({
      cardKinds: false,
      cardAprvDt: false
    });

    // const extensions = ref(['bmp','gif','jpe','jpeg','jpg','svg', 'png', 'tif']);

    const receiptFiles = ref({ files: [], removed: [], binaries: [] });

    watch(() => param, () => {
      emit('update:modelValue', {...param, cardKind: param.cardKind});
    },{deep:true});

    onMounted(() => {
      setParams(param, props.modelValue);
      receiptFiles.value = props.files;
      isInit.value = true;
    });

    return {
      stlmMthdCdDcdLst, payToggles, cardKinds, receiptFiles,
      stlmMthdCdDcds,
      param, isInit, extensions
    }
  }
};
</script>
