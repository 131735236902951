export const certificationConfigs = [
  {
    name: '개인금융',
    key: '개인금융',
    pass: 5,
     courses: [
    //   {name: 'KB 개인고객상담기초', code: '14724', media: '통신-금융연수원통신연수', host: '금융연수원', field: '자산관리'},
    //   {name: 'KB 자산관리를 위한 포트폴리오 설계', code: '14723', media: '통신-금융연수원통신연수', host: '금융연수원', field: '자산관리'},
    //   // {name: '자격증 특례인정(직무인증제-개인금융-자산관리)', code: '1999111', media: '기타', host: '금융연수원', field: '자산관리'},
    //   {name: 'KB 자산관리상담실무', code: '15039', media: '통신-금융연수원통신연수', host: '금융연수원', field: '자산관리'},
    //   {name: 'KB 무역외실무', code: '14741', media: '통신-금융연수원통신연수', host: '금융연수원', field: '외환(무역외)'},
    //   {name: 'KB 가계여신실무', code: '15160', media: '통신-금융연수원통신연수', host: '금융연수원', field: '가계여신'},
     ]
  },

  {
    name: '기업금융',
    key: '기업금융',
    pass: 5,
     courses: [
    //   {name: 'KB 기업여신실무', code: '15161', media: '통신-금융연수원통신연수', host: '금융연수원', field: '기업여신'},
    //   // {name: '자격증 특례인정(직무인증제-기업금융-기업여신)', code: '1999112', media: '기타', host: '금융연수원', field: '기업여신'},
    //   {name: 'KB 중소기업여신심사', code: '12933', media: '통신-금융연수원통신연수', host: '금융연수원', field: '기업여신'},
    //   {name: 'KB 수출입실무', code: '13114', media: '통신-금융연수원통신연수', host: '금융연수원', field: '외환(수출입)'},
    //   // {name: '자격증 특례인정(직무인증제-기업금융-외환(수출입)', code: '1999113', media: '기타', host: '금융연수원', field: '외환(수출입)'},
    //   {name: 'KB 신용장업무', code: '12422', media: '통신-금융연수원통신연수', host: '금융연수원', field: '외환(수출입)'},
    //   {name: 'KB 여신관리실무', code: '15164', media: '통신-금융연수원통신연수', host: '금융연수원', field: '여신관리'},
     ]
  }
];

export const downloadMyPageGuideFiles = [
  { page: 'myPage', fileKey: 'JM_2024_manual'},
];

