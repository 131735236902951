export const BOARD_MODE_KEY = 'ssl-board-mode';

export const MUT_SET_SUBSCRIBE = 'setSubscribe';
export const MUT_ADD_SUBSCRIBE_MY = 'addSubscribeMy';
export const MUT_REMOVE_SUBSCRIBE_MY = 'removeSubscribeMy';
export const MUT_SET_STATICS = 'setStatics';
export const MUT_REMOVE_STATICS = 'removeStatics';
export const MUT_SET_MY_ATTENDANCE_LIST = 'setMyAttendanceList';
export const MUT_SET_MY_ATTENDANCE = 'setMyAttendance';
export const MUT_SHOW_ATTENDANCE = 'showAttendance';
export const MUT_CLOSE_ATTENDANCE = 'closeAttendance';
export const MUT_SET_ROUTE_FROM = 'setRouteFrom';
export const MUT_INIT_ROUTE_FROM = 'initRouteFrom';


export const ACT_GET_MY_SOC_BOARD_COUNT = 'getMySocsBoardCount';
export const ACT_GET_SOC_BOARD_LIST = 'getSocsBoardList';
export const ACT_GET_SOC_BOARD_GROUP_LIST = 'getSocsBoardGroupList';
export const ACT_GET_MY_SOC_BOARD_LIST = 'getMySocsBoardList';
export const ACT_GET_SOC_ACT_BOARD_LIST = 'getSocsActBoardList';

export const ACT_GET_SOC_PAGE_LIST = 'getSocsPageList';
export const ACT_UPLOAD_SOC_BOARD_THUMBNAIL = 'uploadSocsBoardThumbnail';
export const ACT_INSERT_SOC_BOARD = 'insertSocsBoard';
export const ACT_UPDATE_SOC_BOARD = 'updateSocsBoard';
export const ACT_DELETE_SOC_BOARD = 'deleteSocsBoard';
export const ACT_UPDATE_SOC_BOARD_INQ_CNT = 'updateSocsBoardInqCnt';
export const ACT_GET_SOC_ACT_LIST = 'getSocsActList';
export const ACT_GET_SOC_ACT_STAT_LIST = 'getSocsActStatList'
export const ACT_INSERT_SOC_ACT = 'insertSocsAct';
export const ACT_GET_SOC_NTC = 'getSocNtc';
export const ACT_GET_SOC_NTC_LIST = 'getSocNtcList';
export const ACT_GET_SSL_NTC = 'actGetSslNtc';
export const ACT_GET_SSL_NTC_LIST = 'actGetSslNtcList';
export const ACT_UPDATE_SOC_NTC_INQ_CNT = 'updateSocNtcInqCnt';
export const ACT_DOWNLOAD_SOC_ATCH = 'downloadSocAtch';
export const ACT_GET_SOC_ACTS_ATTEND_LIST = 'getSocActsAttendList';
export const ACT_GET_SOC_ACTS_EXCELLENT_LIST = 'getSocActsExcellentList';
export const ACT_GET_SOC_CMNT_LIST = 'getSocCmntList';
export const ACT_INSERT_SOC_CMNT = 'insertSocCmnt';
export const ACT_UPDATE_SOC_CMNT = 'updateSocCmnt';
export const ACT_DELETE_SOC_CMNT = 'deleteSocCmnt';
export const ACT_GET_MY_SOC_STAT = 'getMySocStat';
export const ACT_GET_REL_LIST = 'getRelList';
export const ACT_GET_UNSPLASH_IMAGE_LIST = 'getUnsplashImageList';
export const ACT_GET_SOC_UV_LIST = 'getSocUvList';
export const ACT_GET_SOC_BOOK_LIST = 'getSocBookList';
export const ACT_GET_SOC_BOARD_WRITE_STAT_LIST = 'getSocBoardWriteStatList';
export const ACT_GET_SOC_BOARD_WRITE_STAT_VICE_LIST = 'getSocBoardWriteStatViceList';

export const ACT_GET_SOC_REL_GROUP_LIST = 'getSocRelGroupList';
export const ACT_GET_SOC_REL_GROUP_TARGET = 'getSocRelGroupTarget';
export const ACT_GET_SOC_MY_REL = 'getSocMyRel';
export const ACT_GET_SOC_REL_STAT = 'getSocRelStat';

export const ACT_ENROLL_SSL = 'enrollSsl';

export const ACT_GET_SOC_REL_WRITE = 'getSocRelWrite';
export const ACT_GET_SOC_REL_WRITE_LIST = 'getSocRelWriteList';
export const ACT_GET_SOC_REF_LIST = 'getSocRefList';
export const ACT_SHARE_SOC_BOARD = 'shareSocBoard';
export const ACT_GET_SOC_REL_HRD_WRITE = 'actGetSocRelHrdWrite';

// learn
export const ACT_GET_LEARN_TARGET = 'getLearnTarget';
export const ACT_GET_LEARN_TARGET_LIST = 'getLearnTargetList';
export const ACT_GET_MY_LEARN_TARGET_LIST = 'getMyLearnTargetList';

export const MUT_SET_MY_LEARN_TARGET_LIST = 'setMyLearnTargetList'
export const MUT_SET_LEARN_TARGET_LIST = 'setLearnTargetList'

export const MUT_TOGGLE_LRNERS = 'toggleLrners';
export const MUT_CLOSE_LRNERS = 'closeLrners';
export const MUT_SET_LRNER_KEYWORD = 'setLrnerKeyword';
export const MUT_SET_EXCELLENT_LRNERS = 'setExcellentLrners'

// badge
export const MUT_SHOW_BADGE = 'showBadge';
export const MUT_CLOSE_BADGE = 'closeBadge';
export const MUT_SET_MY_BADGE = 'setMyBadge';
export const MUT_INIT_MY_BADGE = 'setInitBadge';
export const ACT_GET_BADGE_LIST = 'getBadgeList';

// main-board
export const ACT_GET_SOC_CNT = 'getSocCnt';

export const ACT_GET_SOC_EXCELLENT_BOARD_LIST = 'getSocExcellentBoardList';

export const ACT_GET_SOC_SUBSCRIBE_BOARD_LIST = 'getSocSubscribeBoardList';

export const ACT_GET_GROUP_LRN_E_MNG = 'getGroupLrnEMng';

// rel
export const MUT_SET_MY_REL = 'setMyRel';
export const MUT_INIT_MY_REL = 'initMyRel';

export const MUT_SET_GROUP_LIST = 'setGroupList';
export const MUT_SET_SSL_ACL = 'setSSLAcl';

//apply
export const ACT_CANCEL_SSL = 'cancelSsl';
export const ACT_GET_SSL_APPLY_CNT = 'getSslApplyCnt';
export const ACT_GET_MY_BJUGBNM = 'getMyBjuGbNm';
export const MUT_SET_ENROLL_CNT = 'setEnrollCnt';

//actFeed
export const ACT_GET_ACT_FEED_LIST = 'getActFeedlist';

export const MUT_SET_POSTSN_LIST = 'setPagePostsnList';

//shareList
export const ACT_GET_SHARE_LIST = 'getSharelist';

export const ACT_UPDATE_BOOK_MAPNG = 'updateBookMapng';

