<template>
  <div class="popup-modal" :class="{'is-visible': modelValue}" data-popup="popup-attend-badge" @click="closeBadgeModal">
    <div class="popup">
      <div  v-if="selectedBadge" class="modal-box">
        <div class="sbadge-icon">
          <SSLBadgeTwo
              v-if="type === 'ssl'"
              :is-active="selectedBadge.isActive"
              :badge-ty-cd-dcd="selectedBadge.badgeTyCdDcd"
              :hidden-yn="selectedBadge.hiddenYn"
          />
          <HrdCloudBadge
              v-else
              :is-active="selectedBadge.isActive"
              :badge-ty-cd-dcd="selectedBadge.badgeTyCdDcd"
              :hidden-yn="selectedBadge.hiddenYn"
          />
        </div>
        <div class="content">
          <p class="title">{{ selectedBadge.badgeName }}</p>
          <p class="how">{{ selectedBadge.badgeDesc }}</p>
          <p v-if="selectedBadge.isActive" class="title complete">완료</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SSLBadgeTwo from '@/components/ssl/badge/SSLBadgeTwo';
import HrdCloudBadge from '@/components/history/hrdcloud/HrdCloudBadge';
import {watch} from 'vue';

export default {
  name: 'SSLBadgeViewModal',
  components: {HrdCloudBadge, SSLBadgeTwo},
  props: {
    modelValue: Boolean,
    selectedBadge: Object,
    type: {
      type: String,
      required: false,
      default: 'ssl'
    }
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const closeBadgeModal = () => {
      emit('update:modelValue', false);
    }

    watch(() => props.modelValue, () => {
      // console.log('[WATCH] Modal modelValue : ',props.modelValue);
    });

    watch(() => props.selectedBadge, () => {
      // console.log('[WATCH] Modal selectedBadge : ',props.selectedBadge);
    });

    return {
      closeBadgeModal
    }
  }
}
</script>
