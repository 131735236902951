<template>
  <div class="toast-container" :class="{'is-visible': toast.show}">
    <div v-if="toast.show" class="toast" v-html="toast.text"></div>
  </div>
</template>
<script>

import {computed} from "vue";
import {useStore} from "vuex";

export default {
  name: 'SSLMobileToast',
  props: {
  },
  setup() {
    const store = useStore();
    const toast = computed(() => store.state.common.toast);

    return {
      toast
    }

  }
}
</script>
