<template>
  <!-- [begin::popup-container] -->
  <div class="popup-container is-active">
    <!-- popup -->
    <div class="popup" id="popup-tuition-support-p057">
      <!-- popup > popup-inner -->
      <div class="popup-inner">
        <div class="popup-header">
          <h3 class="title" v-if="lrnCostDivSn == '4'">자격증 검색</h3>
          <h3 class="title" v-else>평가명 검색</h3>
        </div>
        <!-- popup-inner > popup-content -->
        <div class="popup-content">
          <div class="survey-container">
            <!-- popup-section:자격증 검색영역 -->
            <article class="popup-section section-form">
              <div class="section-content">
                <!-- kb-form-fields -->
                <div class="kb-form-fields">
                  <!-- kb-form-row:자격증명 -->
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label" v-if="lrnCostDivSn == '4'"><strong class="kb-form-label-text">자격증명</strong></label>
                      <label class="kb-form-label" v-else><strong class="kb-form-label-text">평가명</strong></label>
                    </div>
                    <div class="kb-form-column column-whole kb-form-search">
                      <input type="text" class="kb-form-control column-whole" v-model="search.lrnCostFldNm" placeholder="검색어를 입력하세요" @keydown.enter="handleEnter" />
                      <button class="kb-form-search-btn" @click="pagingFunc(1)"><i class="icon-search"></i></button>
                    </div>
                  </div>
                </div>
                <!-- //kb-form-fields -->
              </div>
            </article>
            <!-- popup-section:자격증 결과 영영 -->
            <div class="certificate-table-container">
              <!-- table -->
              <div class="kb-table kb-table-bordered">
                <template v-if="lrnCostDivSn == '4'">
                  <table>
                    <colgroup>
                      <col/>
                      <col style="width:129px;"/>
                      <col style="width:62px;"/>
                      <col style="width:56px"/>
                    </colgroup>
                    <thead>
                    <tr>
                      <th><span class="th-title">자격증명</span></th>
                      <th><span class="th-title">자격증코드</span></th>
                      <th><span class="th-title">&nbsp;</span></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="isLoading"><td colspan="8"><LoadingDiv /></td></tr>
                    <tr v-for="(item,idx) in items" :key="idx">
                      <td><strong class="td-text">{{ item.lrnCostFldNm }}</strong></td>
                      <td><span class="td-text">{{ item.lrnCostFldCd }}</span></td>
                      <td class="td-end">
                        <div class="kb-form-check"><input v-model="selectedItem" type="radio" class="kb-form-check-input" name="chk" :id="`chk_01_${idx}`" :value="idx"/></div>
                      </td>
                    </tr>
                    <tr v-if="items.length === 0">
                      <td style="text-align:center !important;" colspan="3"><span class="td-text">검색 결과가 없습니다.</span></td>
                    </tr>
                    </tbody>
                  </table>
                </template>
                <template v-else>
                  <table>
                    <colgroup>
                      <col/>
                      <col style="width:129px;"/>
                      <col style="width:56px"/>
                    </colgroup>
                    <thead>
                    <tr>
                      <th><span class="th-title">평가명</span></th>
                      <th><span class="th-title">&nbsp;</span></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="isLoading"><td colspan="8"><LoadingDiv /></td></tr>
                    <tr v-for="(item,idx) in items" :key="idx">
                      <td><strong class="td-text">{{ item.lrnCostFldNm }}</strong></td>
                      <td class="td-end">
                        <div class="kb-form-check"><input v-model="selectedItem" type="radio" class="kb-form-check-input" name="chk" :id="`chk_01_${idx}`" :value="idx"/></div>
                      </td>
                    </tr>
                    <tr v-if="items.length === 0">
                      <td style="text-align:center !important;" colspan="2"><span class="td-text">검색 결과가 없습니다.</span></td>
                    </tr>
                    </tbody>
                  </table>
                </template>
              </div>
              <!-- //table -->
            </div>
          </div>
        </div>

        <!-- popup-inner > popup-buttons -->
        <div class="popup-buttons">
          <button class="kb-btn kb-btn-outline kb-btn-lg" @click="closeModal"><span class="text">취소</span></button>
          <button class="kb-btn kb-btn-primary kb-btn-lg" @click="confirm"><span class="text">확인</span></button>
        </div>
      </div>
      <CommonPaginationFront style="height:50px;" :paging="paging" :page-func="pagingFunc"/>
      <!-- popup > popup-close -->
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
    <!-- //popup -->
  </div>
</template>

<script>
import {onBeforeMount, onBeforeUnmount, reactive, ref} from "vue";
import {initParams, numberComma} from "@/assets/js/util";
import {ACT_GET_LRN_COST_FLD} from "@/store/modules/support/support";
import LoadingDiv from "@/components/common/LoadingDiv";
import CommonPaginationFront from "@/components/CommonPaginationFront";
import {getListFunc} from "@/assets/js/ui.init";
import {useAlert} from '@/assets/js/modules/common/alert';

export default {
  name: "SearchLicenseModal",
  components: {LoadingDiv, CommonPaginationFront},
  props: {
    modelValue: Boolean,
    lrnCostDivSn: Number,
    selectedValue: Object
  },
emits:['update:modelValue', 'update:selectedValue'],
    setup(props, {emit}) {
      const {showMessage} = useAlert();
      const isLoading = ref(false);
      const search = reactive({
        lrnCostDivSn : props.lrnCostDivSn,
        lrnCostFldNm : ''
      });
      const items = ref([]);
      const selectedItem = ref(null);
      const paging = reactive({
        pageNo: 1,
        pageSize: 999999,
        totalCount: 0
      });

      const confirm = () => {
        if(selectedItem.value != null){
          emit('update:selectedValue',items.value[selectedItem.value]);
          closeModal();
        }else{
          showMessage('선택된 내역이 없습니다.');
        }
      };

      const closeModal = () => {
        initParams(search);
        emit('update:modelValue', false);
      };

      const cbFunc = () => {
        isLoading.value = false;
      };

      const pagingFunc = async (pageNo) => {
        if (isLoading.value) return;
        isLoading.value = true;
        paging.pageNo = pageNo;

        getListFunc(`support/${ACT_GET_LRN_COST_FLD}`,search,items, null, cbFunc, cbFunc);

      };

      const handleEnter = (e) => {
        if (e.isComposing || e.keyCode === 229) return;
        pagingFunc(1);
      };

      onBeforeMount(() => {
        //pagingFunc(1);
      });

      onBeforeUnmount(() => {
        initParams(search);
        // items.value = [];
      });

      return {
        isLoading, closeModal,
        search, items, selectedItem,
        paging, pagingFunc,
        handleEnter, confirm, numberComma
      };
    }
}
</script>

<style scoped>

</style>