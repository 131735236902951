import ApiService from "@/assets/js/api.service";

export const MUT_SET_MENUS = 'setMenus';

export const ACT_GET_MENU = 'getMenu';
export const ACT_GET_ROLE_MENU = 'getRoleMenu';
export const ACT_GET_FIRST_PAGE = 'getFirstPage';

const state = {
    menus: [],
};

const mutations = {
    [MUT_SET_MENUS](state, menus) {
        state.menus = menus;
    },
};

const actions = {
    [ACT_GET_MENU](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/menu/', params).then(response => resolve(response))
        });
    },
    [ACT_GET_ROLE_MENU]() {
        return new Promise(resolve => {
            ApiService.query(`/v1/app/menu-role`).then(response => resolve(response))
        });
    },
    [ACT_GET_FIRST_PAGE]() {
        return new Promise(resolve => {
            ApiService.query(`/v1/app/first-page`).then(response => resolve(response))
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
