<template>
  <article class="section-visit">
    <p class="visit">
      <span class="text">방문자수&nbsp;Today&nbsp;</span><span class="today">{{ numberComma(uv.todayCount) }}({{numberComma(uv.todayBoardCount)}}건)</span>
    </p>
    <p class="visit">
      <span class="text">All&nbsp;</span><span class="all">{{ numberComma(uv.totalCount) }}({{numberComma(uv.totalBoardCount)}}건)</span>
    </p>
  </article>
</template>
<script>
import {numberComma} from "@/assets/js/util";
import {computed, reactive} from "vue";
import {getDistCrseSns, getLrnTrgtGrpMstSn} from "@/assets/js/modules/ssl/ssl-common";
import {useRoute} from "vue-router";
import {getSSLUVList} from "@/assets/js/modules/ssl/ssl-home";


export default {
  name: 'SSLMobileUV',
  setup() {
    const route = useRoute();

    const lrnTrgtGrpMstSn = computed(() => getLrnTrgtGrpMstSn(getDistCrseSns(route.params.distCrseCd)));
    const uv = reactive({
      totalCount: 0,
      todayCount: 0,
      totalBoardCount: 0,
      todayBoardCount: 0
    });

    getSSLUVList(uv, lrnTrgtGrpMstSn.value);

    return {
      uv,
      numberComma

    }

  }
}
</script>
