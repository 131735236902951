import LxpLayout from '@/views/layout/lxp/LxpLayout';
import navigationUtils from '@/assets/js/navigationUtils';

const trainRoutes = [
    {
        path: '/train',
        component: LxpLayout,
        children: [
            {
                path: 'proceeding',
                name: "TrainProceeding",
                component: () => import('../../views/pages/train/TrainMain'),
            },
            {
                path: 'scheduled',
                name: "TrainScheduled",
                component: () => import('../../views/pages/train/TrainMain'),
            },
            {
                path: 'finished',
                name: "TrainFinished",
                component: () => import('../../views/pages/train/TrainMain'),
            },
            {
                path: 'regular',
                name: "TrainRegular",
                component: () => import('../../views/pages/train/TrainRegular'),
            },
            {
                path: 'digital',
                name: "TrainDigital",
                component: () => import('../../views/pages/train/TrainDigital'),
            },
            {
                path: 'star-link',
                name: "TrainStarLink",
                component: () => import('../../views/pages/train/TrainStarLink'),
            },
           {
                path: 'global',
                name: "TrainGlobal",
                component: () => import('../../views/pages/train/TrainGlobal'),
            },
        ],
    }
];

const mobileTrainRoutes = [
    {
        path: '/train',
        component: () => import('../../views/layout/lxp/mobile/LxpMobileLayout'),
        children: [
            {
                path: 'proceeding',
                name: "TrainProceeding",
                component: () => import('../../views/pages/train/mobile/MobileTrainMain'),
            },
            {
                path: 'scheduled',
                name: "TrainScheduled",
                component: () => import('../../views/pages/train/mobile/MobileTrainMain'),
            },
            {
                path: 'regular',
                name: "TrainRegular",
                component: () => import('../../views/pages/train/mobile/MobileTrainRegular'),
            },
            {
                path: 'digital',
                name: "TrainDigital",
                component: () => import('../../views/pages/train/mobile/MobileTrainDigital'),
            },
            {
                path: 'star-link',
                name: "TrainStarLink",
                component: () => import('../../views/pages/train/mobile/MobileTrainStarLink'),
            },
            {
                path: 'global',
                name: "TrainGlobal",
                component: () => import('../../views/pages/train/mobile/MobileTrainGlobal'),
            },
        ],
    }
];

export const setTrainRoutes = (routes) => {
    if (navigationUtils.any()) {
        routes.push(...mobileTrainRoutes);
    } else {
        routes.push(...trainRoutes);
    }

}
