<template>
  <div class="page-notice">
    <div v-if="notices.length > 0" class="page-component">
      <div class="notice-list-wrapper">
        <ul v-if="currentNotice != null" class="notice-list">
          <li class="notice-item">
            <router-link :to="{name: 'SSLNoticeView', params: {socLrnNtcSn: currentNotice.comNtcSn}}" class="notice-link">
              <span class="notice-date">{{ timestampToDateFormat(currentNotice.regDt, 'yyyy.MM.dd') }}</span>
              <span class="notice-text">{{ currentNotice.ntcTitle }}</span>
            </router-link>
          </li>
        </ul>
      </div>

      <div class="notice-more">
        <router-link :to="{name: 'SSLCrseNotice'}" class="notice-more-link">공지 전체보기</router-link>
      </div>
      <div class="notice-actions">
        <button class="kb-btn-notice kb-btn-prev" @click="slideButton(false)"><i class="icon-prev"></i></button>
        <button class="kb-btn-notice kb-btn-next" @click="slideButton(true)"><i class="icon-next"></i></button>
      </div>
    </div>
  </div>
</template>
<script>

import {timestampToDateFormat} from '@/assets/js/util';
import {computed, ref, watch} from 'vue';

export default {
  name: 'SSLMainNotice',
  components: {},
  props: {
    notices: Array,
  },
  setup(props) {
    const currentIdx = ref(0);
    const currentNotice = computed(() => {
      if(props.notices[currentIdx.value]){
        return props.notices[currentIdx.value];
      }
      return null;
    });

    const slideButton = (isNext) => {
      if(isNext){
        const nextIdx = currentIdx.value + 1;
        if(nextIdx > (props.notices.length - 1)){
          currentIdx.value = 0;
        }else{
          currentIdx.value = nextIdx;
        }
      }else{
        const prevIdx = currentIdx.value -1;
        if(prevIdx < 0){
          currentIdx.value = (props.notices.length - 1);
        }else{
          currentIdx.value = prevIdx;
        }
      }
    }

    watch(() => props.notices, () => {
      currentIdx.value = 0;
    });

    return {
      currentNotice,
      slideButton,
      timestampToDateFormat
    }
  }
}
</script>
