import ApiService from "@/assets/js/api.service";

// MUTATION
export const MUT_SET_CODE_LIST = 'setCodeList';
export const MUT_SET_CODE_IDX = 'setCodeIdx';
export const MUT_SET_CATE_LIST = 'setCateList';
// ACTION
export const ACT_GET_CODE_LIST = 'getCodeList';

export const ACT_GET_MEDIA_CATE_LIST = 'getMediaCateList';


const state = {
  codeExpiredTime: 0,
  codes: [],
  currentIdx: -1,

  cates: [],

};

const getters = {
  // getCodes(state){
  //     return state.codes;
  // }
};

const mutations = {
  [MUT_SET_CODE_LIST](state, codes){
    state.codes = codes;
    state.codeExpiredTime = new Date().getTime() + 1000 * 60 * 5;
  },
  [MUT_SET_CODE_IDX](state, idx){
    state.currentIdx = idx;
  },
  [MUT_SET_CATE_LIST](state, cates){
    state.cates = cates;
  },
};

const actions = {
  [ACT_GET_CODE_LIST]() {
    return new Promise(resolve => {
      ApiService.query('/v1/app/codes', {}).then(response => resolve(response))
    });
  },
  [ACT_GET_MEDIA_CATE_LIST](context, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/medias/cates', params).then(response => resolve(response));
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
