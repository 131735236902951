<template>
  <!-- page-intro -->
  <template v-if="isReady">
    <SSLView
        v-if="currentName === 'SSLView'"
        @acted="acted"
    />
    <template v-else>
      <div class="page-intro">
        <div class="intro-content">
          <div class="page-component">
            <h2 class="intro-title" :class="{'black-title': isBlack}">{{ mainName }}</h2>
            <div class="intro-buttons">
              <button class="kb-btn-intro" :class="{'black': isBlack}" @click="goMySSL">
                <span class="kb-btn-border">
                  <img v-if="isBlack" src="@/assets/lxp/images/ssl/main/ic_myssl_border_blk.svg" alt="">
                </span>
                <SSLMySvg/>
              </button>
              <button v-if="distCrseCd === 'group'" class="kb-btn-intro is-no-change" :class="{'black': isBlack}">
                <span class="kb-btn-border">
                  <router-link v-if="myGroup.distCrseSn > 0" :to="{name: 'GroupView', params: {distCrseSn: myGroup.distCrseSn}}" class="link is-active"><i class="icon-my-group"></i></router-link>
                  <router-link v-else :to="{name: 'GroupLearning'}" class="link is-active"><i class="icon-my-group"></i></router-link>
                </span>
              </button>
              <button v-else-if="distCrseCd === 'knowledge'" class="kb-btn-intro is-white">
                <span class="kb-btn-border">
                  <router-link :to="{name: 'KnowledgeELearning'}" class="link"><i class="icon-my-test"></i></router-link>
                </span>
              </button>
            </div>

            <div class="intro-links" :class="{'black': isBlack}">
              <SSLTrial
                  trial-class="intro-link"
                  text-class="text"
                  :show-arrow="true"
              />
              <SSLManual
                  manual-class="intro-link"
                  text-class="text"
                  :show-arrow="true"
              />
            </div>
          </div>
        </div>
        <div class="intro-background"></div>
      </div>
      <!-- //page-intro -->
      <!-- page-container -->
      <div class="page-container">
        <SSLMainNotice :notices="notices"/>

        <div class="page-body">
          <div class="page-component">
            <!-- page-aside -->
            <SSLMember
                v-if="!isNoneReward"
                :lrn-trgt-grp-mst-sn="lrnTrgtGrpMstSn"
            />
            <SSLBoard
                :is-loading="isLoading"
                :items="items"
                :paging="paging"
                :more-func="moreFunc"
                :search-func="searchFunc"
                :lrn-trgt-grp-mst-sn="lrnTrgtGrpMstSn"
                :view-modes="viewModes"
                :view-mode-idx="viewModeIdx"
                :init-keyword="keyword"
                @moveBoard="moveBoard"
            />
          </div>
        </div>
        <!-- page-footer -->
        <div class="page-footer">
          <div class="page-component"></div>
        </div>
      </div>
    <!-- //page-container -->
    </template>
  </template>

</template>

<script>
import SSLMember from '@/components/ssl/main/SSLMember';
import SSLBoard from '@/components/ssl/SSLBoard';
import SSLMySvg from '@/components/ssl/main/SSLMySvg';
import SSLMainNotice from '@/components/ssl/main/SSLMainNotice';
import SSLTrial from '@/components/ssl/common/SSLTrial';
import SSLManual from '@/components/ssl/common/SSLManual';
import SSLView from '@/views/ssl/SSLView';
import {sslMainSetup} from '@/assets/js/modules/ssl/setup/ssl-main-setup';

export default {
  name: 'SSLMain',
  components: {
    SSLView,
    SSLManual,
    SSLTrial,
    SSLMainNotice,
    SSLMySvg,
    SSLMember,
    SSLBoard
  },
  setup: sslMainSetup
}
</script>
