<template>
  <div v-if="!isMobile" class="popup-container" :class="{'is-active': modelValue}">
    <div class="popup" id="popup-hrdcloud-mileage">
      <div class="popup-inner">
          <header class="popup-header">
            <h3 class="title">독서 기록</h3>
          </header>
          <div class="popup-content">
            <div class="popup-tabs">
              <a href="javascript:" class="tab" :class="{ 'is-active' : selValue === '' }" @click="handleServ('')">전체</a>
              <a href="javascript:" class="tab" :class="{ 'is-active' : selValue === '2001038' }" @click="handleServ('2001038')">타이머</a>
              <a href="javascript:" class="tab" :class="{ 'is-active' : selValue === '2001039' }" @click="handleServ('2001039')">메모</a>
            </div>
            <div class="mileage-container">
              <div v-if="!isLoading" class="kb-table kb-table-striped">
                <table>
                  <colgroup>
                    <col style="width:200px;" />
                    <col style="width:100px"/>
                    <col style="width:130px"/>
                    <col style="width:70px"/>
                    <col style="width:300px" />
                  </colgroup>
                  <thead style="text-align: center;">
                  <tr>
                    <th><span class="th-title">도서명</span></th>
                    <th><span class="th-title">구분</span></th>
                    <th><span class="th-title">등록일시</span></th>
                    <th><span class="th-title">페이지</span></th>
                    <th><span class="th-title">기록(타이머, 메모)</span></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, idx) in items" :key="idx">
                    <td><span class="td-text">{{ item.bookNm }}</span></td>
                    <td><span class="td-text">{{ item.actTyNm }}</span></td>
                    <td v-if="item.actTyCdDcd === '2001038'"><span class="td-text">{{ timestampToDateFormat(item.bgngDt, 'yyyy.MM.dd hh:mm') }}</span></td>
                    <td v-else><span class="td-text">{{ timestampToDateFormat(item.regDt, 'yyyy.MM.dd hh:mm') }}</span></td>
                    <td><span class="td-text">{{item.page > 0 ? item.page : '0'}}</span></td>
                    <td v-if="item.actTyCdDcd === '2001038'"><span class="td-text">{{ getTimeFormatString(item.prgrsSec) }}</span></td>
                    <td v-else-if="getRefinedText(item.memo).length > 20" @click="showText = item.memo.replace(/(?:\r\n|\r|\n)/g, '<br>')" style="cursor:pointer;"><span class="td-text" v-html="getRefinedText(item.memo)"></span><span style="float: right;">></span></td>
                    <td v-else><span class="td-text" v-html="getRefinedText(item.memo)"></span></td>
                  </tr>
                  </tbody>
                </table>
                <div v-if="!items || (items && items.length === 0)" class="search-container">
                  <div class="result-empty">
                    <img src="../../../assets/lxp/images/common/img_empty.png" alt="">
                    <p class="text">작성한 독서 기록이 없습니다.</p>
                  </div>
                </div>
              </div>
              <CommonPaginationFront :paging="paging" :page-func="pagingFunc" />
            </div>
          </div>
      </div>
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
  </div>
  <template v-else>
    <HrdCloudMobileHeader :show-back="true" :go-back-func="closeModal" title="독서 기록"/>
    <main class="kb-main" id="kb-myssl">
      <div class="header-snb">
        <nav class="snb">
          <ul class="snb-list">
            <li class="snb-item">
              <button :class="{ 'is-active' : selValue === ''  }" @click="handleServ('')">전체</button>
            </li>
            <li class="snb-item">
              <button :class="{ 'is-active' : selValue === '2001038'  }" @click="handleServ('2001038')">타이머</button>
            </li>
            <li class="snb-item">
              <button :class="{ 'is-active' : selValue === '2001039' }" @click="handleServ('2001039')">메모</button>
            </li>
          </ul>
        </nav>
      </div>
      <div class="main-content main-component">
        <article class="content-section">
          <div class="section-content">
            <div class="content-item" style="margin: 10px;" v-for="(item,idx) in items"  :key="idx">
              <ul class="desc-type-title">
                <li>
                  <h6>도 &nbsp;서&nbsp; 명</h6>
                  <p>{{ item.bookNm  }}</p>
                </li>
                <li>
                  <h6>구&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;분</h6>
                  <p>{{ item.actTyNm  }}</p>
                </li>
                <li>
                  <h6>등 &nbsp;록 &nbsp;일</h6>
                  <p v-if="item.actTyCdDcd === '2001038'">{{ timestampToDateFormat(item.bgngDt, 'yyyy.MM.dd hh:mm') }}</p>
                  <p v-else>{{ timestampToDateFormat(item.regDt, 'yyyy.MM.dd hh:mm') }}</p>
                </li>
                <li>
                  <h6>페 &nbsp;이 &nbsp;지</h6>
                  <p>{{item.page > 0 ? item.page : '0'}}</p>
                </li>
                <li>
                  <h6>기&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;록</h6>
                  <p v-if="item.actTyCdDcd === '2001038'">{{ getTimeFormatString(item.prgrsSec) }}</p>
                  <p v-else-if="getRefinedText(item.memo).length > 20" @click="showText = item.memo.replace(/(?:\r\n|\r|\n)/g, '<br>')" style="cursor:pointer;"><span class="td-text" v-html="getRefinedText(item.memo)"></span><span style="float: right;">&nbsp;></span></p>
<!--                  <td v-if="item.actTyCdDcd === '2001038'"><span class="td-text">{{ getTimeFormatString(item.prgrsSec) }}</span></td>-->
<!--                  <td v-else-if="getRefinedText(item.memo).length > 20" @click="showText = item.memo.replace(/(?:\r\n|\r|\n)/g, '<br>')" style="cursor:pointer;"><span class="td-text" v-html="getRefinedText(item.memo)"></span><span style="float: right;">></span></td>-->
                  <p v-else><span class="td-text" v-html="getRefinedText(item.memo)"></span></p>
                </li>
              </ul>
            </div>
          </div>
        </article>
      </div>
      <div v-if="!items || (items && items.length === 0)" class="search-container">
        <div class="result-empty">
          <img src="../../../assets/lxp/images/common/img_empty.png" alt="">
          <p class="text">작성한 독서 기록이 없습니다.</p>
        </div>
      </div>
    </main>
  </template>
  <div v-if="showText !== ''" class="kb-alert-container is-active">
    <div class="kb-alert" style="width: 1300px;">
      <div class="kb-alert-contents">
        <p class="kb-alert-text" style="width: 100%; word-wrap: break-word;" v-html="showText"></p>
      </div>
      <div class="kb-alert-actions">
        <button class="kb-btn-alert" @click="showText = ''"><span class="text">확인</span></button>
      </div>
    </div>
  </div>
  <CommonPaginationFront style="margin-bottom: 100px;" :paging="paging" :page-func="pagingFunc" :page-list-size="isMobile ? 5 : 10"/>
</template>

<script>
import {getBgngEndDate, getItems, lengthCheck, timestampToDateFormat} from '@/assets/js/util';
import {computed, reactive, ref} from 'vue';
import CommonPaginationFront from '@/components/CommonPaginationFront';
// import {getMileageList} from '@/assets/js/modules/hrd/hrd-history-common';
// import {useAlert} from '@/assets/js/modules/common/alert';
import {ACT_GET_BOOK_HISTORY_LIST} from "@/store/modules/history/history";
import {useStore} from "vuex";
import {getRouteLrnTrgtGrpMstSn} from "@/assets/js/modules/ssl/ssl-common";
import {useRoute} from "vue-router";
import navigationUtils from "@/assets/js/navigationUtils";
import HrdCloudMobileHeader from "@/components/hrdcloud/mobile/HrdCloudMobileHeader.vue";

export default {
  name: 'BookHistory',
  components: {HrdCloudMobileHeader, CommonPaginationFront},
  props: {
    modelValue: Boolean,
    selectValue: String
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const isLoading = ref(false);
    const closeModal = () => {
      emit('update:modelValue');
    };
    const route = useRoute();
    const store = useStore();
    const isMobile = navigationUtils.any();

    const selValue = ref(props.selectValue);

    const items = ref([]);

    const showText = ref('');

    // const callback = () => {
    //   hideLoading();
    //   isLoading.value = false;
    // }
    const paging = reactive({
      pageNo: 1,
      pageSize: 10,
      totalCount: 0
    });

    const lrnTrgtGrpMstSn = computed(() => getRouteLrnTrgtGrpMstSn(route.params.lrnTrgtGrpMstSn));

    const getMlgList = () => {

      store.dispatch(`history/${ACT_GET_BOOK_HISTORY_LIST}`, {lrnTrgtGrpMstSn: lrnTrgtGrpMstSn.value, actTyCdDcd : selValue.value, ...paging}).then(res => {
        if(lengthCheck(res)){
          items.value = getItems(res);
          paging.totalCount = res && res.paging ? res.paging.totalCount : 0;
        }
      }).catch(() => {
        isLoading.value = false;
      });
      // if (isLoading.value) return;
      // isLoading.value = true;
      // showLoading();
      // getMileageList({
      //       year: props.year,
      //       mlgSaveCdDcd: selValue.value,
      //       mlgTyCd: (selValue.value === '12738921' ? '4001' : '1001')
      //     }, items, paging,
      //     callback, callback);
    };

    // const showText = (text) => {
    //   showText.value = true;
    // }

    const pagingFunc = (pageNo) => {
      paging.pageNo = pageNo;
      getMlgList();
    };

    const handleServ = (servTy) => {
      paging.pageNo = 1;
      selValue.value = servTy;
      getMlgList();
    };

    getMlgList();

    const getRefinedText = cn => {
      if (cn) {
        // \n을 <br>로 치환
        cn = cn.replace(/(?:\r\n|\r|\n)/g, '<br>');
        // return cn.replace(/(<([^>]+)>)/gi, "");
      }

      if (cn.length > 20) {
        return cn.substr(0, 20) + '...';
      }
      return cn;
    }

    const getTimeFormatString = (time) => {
      let hour = parseInt(String(time / (60 * 60)));
      let min = parseInt(String((time - (hour * 60 * 60)) / 60));
      let sec = time % 60;


      let timeValue = '';

      if (hour > 0) {
        timeValue = hour + "시간 ";
      }
      if (min > 0) {
        timeValue = timeValue + min + "분 ";
      }
      timeValue = timeValue + sec + "초";
      return timeValue;
    }

    return {
      items, paging, pagingFunc, isLoading,
      closeModal, getBgngEndDate, selValue,
      timestampToDateFormat, handleServ, getRefinedText, getTimeFormatString,isMobile,showText
    }
  }
};
</script>