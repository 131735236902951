import ApiService from '@/assets/js/api.service';

export const ACT_GET_LRN_TY_EVL_DIST = 'getLrnTyEvlDist';
export const ACT_GET_LRN_TY_EVL_DIST_LIST = 'getLrnTyEvlDistList';
export const ACT_GET_KNOWLEDGE_LRN_TY_EVL_DIST_LIST = 'getKnowledgeLrnTyEvlDistList';
export const ACT_INIT_LRN_TY_EVL_DIST = 'initLrnTyEvlDist';

export const ACT_GET_LRN_TY_EVL_QSTN_DIST_LIST = 'getLrnTyEvlQstnDistList';
export const ACT_GET_LRN_TY_EVL_QSTN_DIST_ANS_LIST = 'getLrnTyEvlQstnDistAnsList';
export const ACT_GET_LRN_TY_EVL_QSTN_DIST_MOCK_LIST = 'getLrnTyEvlQstnDistMockList';

export const ACT_GET_LRN_TY_EVL_RSLT_LIST = 'getLrnTyEvlRsltList';
export const ACT_GET_LRN_TY_EVL_QSTN_RSLT_LIST = 'getLrnTyEvlRsltQstnList';
export const ACT_INSERT_LRN_TY_EVL_RSLT = 'insertLrnTyEvlRslt';
export const ACT_UPDATE_LRN_TY_EVL_RSLT = 'updateLrnTyEvlRslts';
export const ACT_GET_LRN_TY_EVL_RSLT_SCORE = 'getLrnTyEvlRsltScore';

export const ACT_INSERT_LRN_TY_EVL_DIST = 'insertLrnTyEvlDistNoRand';
export const ACT_UPDATE_LRN_TY_EVL_FNSH = 'updateLrnTyEvlFnsh';
export const ACT_GET_EVL_SYNC_DT = 'getEvlSyncDt';

const state = {};

const mutations = {};

const actions = {
  [ACT_GET_LRN_TY_EVL_DIST](context, lrnTyEvlDistSn){
    return new Promise(resolve => {
      ApiService.get('/v1/app/lrns/evls', lrnTyEvlDistSn).then(response => resolve(response));
    });
  },
  [ACT_GET_LRN_TY_EVL_DIST_LIST](context, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/lrns/evls', params).then(response => resolve(response));
    });
  },
  [ACT_GET_KNOWLEDGE_LRN_TY_EVL_DIST_LIST](context, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/lrns/knowledge/evls', params).then(response => resolve(response));
    });
  },
  [ACT_INIT_LRN_TY_EVL_DIST](context, lrnTyEvlDistSn){
    return new Promise(resolve => {
      ApiService.post(`/v1/app/lrns/evls/${lrnTyEvlDistSn}/init`, {}).then(response => resolve(response));
    });
  },
  [ACT_GET_LRN_TY_EVL_QSTN_DIST_LIST](context, lrnTyEvlDistSn){
    return new Promise(resolve => {
      ApiService.query(`/v1/app/lrns/evls/${lrnTyEvlDistSn}/qstns`, {}).then(response => resolve(response));
    });
  },
  [ACT_GET_LRN_TY_EVL_QSTN_DIST_ANS_LIST](context, lrnTyEvlDistSn){
    return new Promise(resolve => {
      ApiService.query(`/v1/app/lrns/evls/${lrnTyEvlDistSn}/qstns/anses`, {}).then(response => resolve(response));
    });
  },
  [ACT_GET_LRN_TY_EVL_QSTN_DIST_MOCK_LIST](){
    return new Promise(resolve => {
      ApiService.query(`/v1/app/lrns/evls/qstns/mock`, {}).then(response => resolve(response));
    });
  },
  [ACT_GET_LRN_TY_EVL_RSLT_LIST](context, lrnTyEvlDistSn){
    return new Promise(resolve => {
      ApiService.query(`/v1/app/lrns/evls/${lrnTyEvlDistSn}/rslts`, {}).then(response => resolve(response));
    });
  },
  [ACT_GET_LRN_TY_EVL_QSTN_RSLT_LIST](context, lrnTyEvlDistSn){
    return new Promise(resolve => {
      ApiService.query(`/v1/app/lrns/evls/${lrnTyEvlDistSn}/rslts/qstns`, {}).then(response => resolve(response));
    });
  },
  [ACT_INSERT_LRN_TY_EVL_RSLT](context, lrnTyEvlDistSn){
    return new Promise(resolve => {
      ApiService.post(`/v1/app/lrns/evls/${lrnTyEvlDistSn}/rslts`, {}).then(response => resolve(response));
    });
  },
  [ACT_UPDATE_LRN_TY_EVL_RSLT](context, {lrnTyEvlDistSn, params}){
    return new Promise(resolve => {
      ApiService.put(`/v1/app/lrns/evls/${lrnTyEvlDistSn}/rslts`, params).then(response => resolve(response));
    });
  },
  [ACT_GET_LRN_TY_EVL_RSLT_SCORE](context, lrnTyEvlDistSn){
    return new Promise(resolve => {
      ApiService.query(`/v1/app/lrns/evls/${lrnTyEvlDistSn}/rslts/score`, {}).then(response => resolve(response));
    });
  },
  [ACT_INSERT_LRN_TY_EVL_DIST](context, params){
    return new Promise(resolve => {
      ApiService.post(`/v1/app/lrns/evls/make`, params).then(response => resolve(response));
    });
  },
  [ACT_UPDATE_LRN_TY_EVL_FNSH](context, {lrnTyEvlDistSn, params}){
    return new Promise(resolve => {
      ApiService.put(`/v1/app/lrns/evls/${lrnTyEvlDistSn}/fnshs`, params).then(response => resolve(response));
    });
  },
  [ACT_GET_EVL_SYNC_DT](context, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/lrns/evls/times/sync', params).then(response => resolve(response));
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
