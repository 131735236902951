import ApiService from "@/assets/js/api.service";

// ACTION
export const ACT_GET_MY_TRGT_LIST = 'actGetMyTrgtList';
export const ACT_BOARD_GET_QR = 'actBoardGetQr';
export const ACT_BOARD_GET_NTC_LIST = 'actBoardGetNtcList';
export const ACT_BOARD_GET_NTC = 'actBoardGetNtc';
export const ACT_INSERT_PLEDGE_AGREE = 'actInsertPledgeAgree';
export const ACT_INSERT_QR_ATTEND = 'actInsertQrAttend';
export const ACT_INSERT_AGREEMENT_CI = 'actInsertAgreementCI';
export const CHK_AGREEMENT_CI = 'chkAgreementCI';

const state = {
};

const mutations = {
};

const actions = {
    [CHK_AGREEMENT_CI](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/chk/agreement/ci', params).then(response => resolve(response))
        });
    },
    [ACT_GET_MY_TRGT_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/trgt/users', params).then(response => resolve(response))
        });
    },
    [ACT_BOARD_GET_QR](context, params) {
        return new Promise(resolve => {
            ApiService.query( '/v1/app/learns/boards/qr', params).then(response => resolve(response))
        });
    },
    [ACT_BOARD_GET_NTC_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query( '/v1/app/learns/boards/ntcs', params).then(response => resolve(response))
        });
    },
    [ACT_INSERT_QR_ATTEND](context, aesenc) {
        return new Promise(resolve => {
            ApiService.get( '/v1/app/learns/boards/qr/attend', aesenc).then(response => resolve(response))
        });
    },
    [ACT_BOARD_GET_NTC](context, lrnPostSn) {
        return new Promise(resolve => {
            ApiService.get( '/v1/app/learns/board/ntc', lrnPostSn).then(response => resolve(response))
        });
    },
    [ACT_INSERT_PLEDGE_AGREE](context, params) {
        return new Promise(resolve => {
            ApiService.post( '/v1/app/onclass/pledge', params).then(response => resolve(response))
        });
    },
    [ACT_INSERT_AGREEMENT_CI](context, params) {
        return new Promise(resolve => {
            ApiService.post( '/v1/app/agreement/ci', params).then(response => resolve(response))
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};

