<template>
  <!-- main-content -->
  <div class="main-content min-component">
    <!-- section: 2022년 주요자격증 시험 일정 안내 - 상품판매 필수자격증 -->
    <article class="content-section section-01">
      <header class="section-header">
        <div class="header-column">
          <h4 class="title">2024년 주요자격증 시험 일정 안내 - 상품판매 필수자격증</h4>
        </div>
      </header>
      <div class="guide-table">
        <div class="kb-table kb-table-striped">
          <table>
            <colgroup>
              <col style="width:250px" />
              <col style="width:100px" />
              <col style="width:148px" />
              <col style="width:auto" />
              <col style="width:148px" />
              <col style="width:290px" />
            </colgroup>
            <thead>
            <tr>
              <th class="span th-title">자격증명</th>
              <th class="span th-title">회차</th>
              <th class="span th-title">시험일</th>
              <th class="span th-title">원서접수 기간</th>
              <th class="span th-title">합격자 발표일</th>
              <th class="span th-title">주관처</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <th><strong class="th-title">펀드투자권유자문인력</strong></th>
              <td><span class="td-text">27회</span></td>
              <td><span class="td-text">02.25(일)</span></td>
              <td><span class="td-text">2024.01.29(월)~2024.02.02(금)</span></td>
              <td><span class="td-text">03.07(목)</span></td>
              <td><span class="td-text">금융투자협회 자격시험 접수센터</span></td>
            </tr>
            <tr>
              <th><strong class="th-title">펀드투자권유자문인력</strong></th>
              <td><span class="td-text">28회</span></td>
              <td><span class="td-text">06.30(일)</span></td>
              <td><span class="td-text">2024.06.03(월)~2024.06.07(금)</span></td>
              <td><span class="td-text">07.11(목)</span></td>
              <td><span class="td-text">금융투자협회 자격시험 접수센터</span></td>
            </tr>
            <tr>
              <th><strong class="th-title">펀드투자권유자문인력</strong></th>
              <td><span class="td-text">29회</span></td>
              <td><span class="td-text">12.08(일)</span></td>
              <td><span class="td-text">2024.11.11(월)~2024.11.15(금)</span></td>
              <td><span class="td-text">12.19(목)</span></td>
              <td><span class="td-text">금융투자협회 자격시험 접수센터</span></td>
            </tr>
            <tr>
              <th><strong class="th-title">파생상품 투자권유자문인력</strong></th>
              <td><span class="td-text">31회</span></td>
              <td><span class="td-text">02.04(일)</span></td>
              <td><span class="td-text">2024.01.08(월)~2024.01.12(금)</span></td>
              <td><span class="td-text">02.15(목)</span></td>
              <td><span class="td-text">금융투자협회 자격시험 접수센터</span></td>
            </tr>
            <tr>
              <th><strong class="th-title">파생상품 투자권유자문인력</strong></th>
              <td><span class="td-text">32회</span></td>
              <td><span class="td-text">05.19(일)</span></td>
              <td><span class="td-text">2024.04.22(월)~2024.04.26(금)</span></td>
              <td><span class="td-text">05.30(목)</span></td>
              <td><span class="td-text">금융투자협회 자격시험 접수센터</span></td>
            </tr>
            <tr>
              <th><strong class="th-title">파생상품 투자권유자문인력</strong></th>
              <td><span class="td-text">33회</span></td>
              <td><span class="td-text">11.24(일)</span></td>
              <td><span class="td-text">2024.10.28(월)~2024.11.01(금)</span></td>
              <td><span class="td-text">12.05(목)</span></td>
              <td><span class="td-text">금융투자협회 자격시험 접수센터</span></td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="kb-table-bottom">
          <div class="bottom-notice">
            <strong class="subtext">금융투자협회 자격시험접수센터 ㅣ 1600-9477</strong>
          </div>
        </div>
      </div>
    </article>
    <!-- section: 2022년 주요자격증 시험 일정 안내 - 직무관련 핵심자격증 -->
    <article class="content-section section-divider section-02">
      <header class="section-header">
        <h4 class="title">2024년 주요자격증 시험 일정 안내 - 직무관련 핵심자격증</h4>
      </header>
      <div class="guide-table">
        <div class="kb-table kb-table-striped">
          <table>
            <colgroup>
              <col style="width:250px" />
              <col style="width:100px" />
              <col style="width:148px" />
              <col style="width:auto" />
              <col style="width:148px" />
              <col style="width:290px" />
            </colgroup>
            <thead>
            <tr>
              <th class="span th-title">자격증명</th>
              <th class="span th-title">회차</th>
              <th class="span th-title">시험일</th>
              <th class="span th-title">원서접수 기간</th>
              <th class="span th-title">합격자 발표일</th>
              <th class="span th-title">주관처</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <th><strong class="th-title">외환전문역 I, II</strong></th>
              <td><span class="td-text">49회</span></td>
              <td><span class="td-text">03.23(토)</span></td>
              <td><span class="td-text">2024.02.13(화)~2024.02.20(화)</span></td>
              <td><span class="td-text">04.05(금)</span></td>
              <td><span class="td-text">한국금융연수원</span></td>
            </tr>
            <tr>
              <th><strong class="th-title">외환전문역 I, II</strong></th>
              <td><span class="td-text">50회</span></td>
              <td><span class="td-text">07.06(토)</span></td>
              <td><span class="td-text">2024.05.28(화)~2024.06.04(화)</span></td>
              <td><span class="td-text">07.19(금)</span></td>
              <td><span class="td-text">한국금융연수원</span></td>
            </tr>
            <tr>
              <th><strong class="th-title">외환전문역 I, II</strong></th>
              <td><span class="td-text">51회</span></td>
              <td><span class="td-text">11.23(토)</span></td>
              <td><span class="td-text">2024.10.15(화)~2024.10.22(화)</span></td>
              <td><span class="td-text">12.06(금)</span></td>
              <td><span class="td-text">한국금융연수원</span></td>
            </tr>
            <tr>
              <th><strong class="th-title">신용분석사</strong></th>
              <td><span class="td-text">58회</span></td>
              <td><span class="td-text">02.24(토)</span></td>
              <td><span class="td-text">2024.01.16(화)~2024.01.23(화)</span></td>
              <td><span class="td-text">03.08(금)</span></td>
              <td><span class="td-text">한국금융연수원</span></td>
            </tr>
            <tr>
              <th><strong class="th-title">신용분석사</strong></th>
              <td><span class="td-text">59회</span></td>
              <td><span class="td-text">06.15(토)</span></td>
              <td><span class="td-text">2024.05.07(화)~2024.05.14(화)</span></td>
              <td><span class="td-text">06.28(금)</span></td>
              <td><span class="td-text">한국금융연수원</span></td>
            </tr>
            <tr>
              <th><strong class="th-title">신용분석사</strong></th>
              <td><span class="td-text">60회</span></td>
              <td><span class="td-text">10.19(토)</span></td>
              <td><span class="td-text">2024.09.05(목)~2024.09.12(목)</span></td>
              <td><span class="td-text">11.01(금)</span></td>
              <td><span class="td-text">한국금융연수원</span></td>
            </tr>
            <tr>
              <th><strong class="th-title">자산관리사(은행FP)</strong></th>
              <td><span class="td-text">61회</span></td>
              <td><span class="td-text">04.13(토)</span></td>
              <td><span class="td-text">2024.03.05(화)~2024.03.12(화)</span></td>
              <td><span class="td-text">04.26(금)</span></td>
              <td><span class="td-text">한국금융연수원</span></td>
            </tr>
            <tr>
              <th><strong class="th-title">자산관리사(은행FP)</strong></th>
              <td><span class="td-text">62회</span></td>
              <td><span class="td-text">07.27(토)</span></td>
              <td><span class="td-text">2024.06.18(화)~2023.06.25(화)</span></td>
              <td><span class="td-text">08.09(금)</span></td>
              <td><span class="td-text">한국금융연수원</span></td>
            </tr>
            <tr>
              <th><strong class="th-title">자산관리사(은행FP)</strong></th>
              <td><span class="td-text">63회</span></td>
              <td><span class="td-text">11.09(토)</span></td>
              <td><span class="td-text">2024.10.01(화)~2024.10.08(화)</span></td>
              <td><span class="td-text">11.22(금)</span></td>
              <td><span class="td-text">한국금융연수원</span></td>
            </tr>
            <tr>
              <th><strong class="th-title">영업점컴플라이언스오피서(은행)</strong></th>
              <td><span class="td-text">31회</span></td>
              <td><span class="td-text">05.11(토)</span></td>
              <td><span class="td-text">2024.04.02(화)~2024.04.09(화)</span></td>
              <td><span class="td-text">05.24(금)</span></td>
              <td><span class="td-text">한국금융연수원</span></td>
            </tr>
            <tr>
              <th><strong class="th-title">영업점컴플라이언스오피서(은행)</strong></th>
              <td><span class="td-text">32회</span></td>
              <td><span class="td-text">09.07(토)</span></td>
              <td><span class="td-text">2024.07.30(화)~2024.08.06(화)</span></td>
              <td><span class="td-text">09.27(금)</span></td>
              <td><span class="td-text">한국금융연수원</span></td>
            </tr>
            <tr>
              <th><strong class="th-title">한국재무설계사(AFPK)</strong></th>
              <td><span class="td-text">87회</span></td>
              <td><span class="td-text">03.16(토)</span></td>
              <td><span class="td-text">2024.02.19(월)~2024.03.04(월)</span></td>
              <td><span class="td-text">04.05(금)</span></td>
              <td><span class="td-text">한국 FPSB</span></td>
            </tr>
            <tr>
              <th><strong class="th-title">한국재무설계사(AFPK)</strong></th>
              <td><span class="td-text">88회</span></td>
              <td><span class="td-text">08.24(토)</span></td>
              <td><span class="td-text">2024.07.29(월)~2024.08.12(월)</span></td>
              <td><span class="td-text">09.13(금)</span></td>
              <td><span class="td-text">한국 FPSB</span></td>
            </tr>
            <tr>
              <th><strong class="th-title">한국재무설계사(AFPK)</strong></th>
              <td><span class="td-text">89회</span></td>
              <td><span class="td-text">11.23(토)</span></td>
              <td><span class="td-text">2024.10.28(월)~2024.11.11(월)</span></td>
              <td><span class="td-text">12.13(금)</span></td>
              <td><span class="td-text">한국 FPSB</span></td>
            </tr>
            <tr>
              <th><strong class="th-title">국제공인 재무설계사(CFP)</strong></th>
              <td><span class="td-text">45회</span></td>
              <td><span class="td-text">05.18(토)~05.19(일)</span></td>
              <td><span class="td-text">2024.04.22(월)~2024.05.06(월)</span></td>
              <td><span class="td-text">06.07(금)</span></td>
              <td><span class="td-text">한국 FPSB</span></td>
            </tr>
            <tr>
              <th><strong class="th-title">국제공인 재무설계사(CFP)</strong></th>
              <td><span class="td-text">46회</span></td>
              <td><span class="td-text">10.19(토)~10.20(일)</span></td>
              <td><span class="td-text">2024.09.23(월)~2024.10.07(월)</span></td>
              <td><span class="td-text">11.08(금)</span></td>
              <td><span class="td-text">한국 FPSB</span></td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="kb-table-bottom">
          <div class="bottom-notice">
            <strong class="subtext">한국금융연수원 ㅣ 02-3700-1500</strong>
          </div>
          <div class="bottom-notice">
            <strong class="subtext">한국 FPSB ㅣ 02-3276-7610</strong>
          </div>
        </div>
      </div>
    </article>
  </div>
  <!-- //main-content -->
</template>

<script>
export default {
  name: 'SupportLcnsExamSchedule',
};
</script>