<template>
  <template v-if="isMobile">
    <HrdCloudHistoryMobileAdd />
    <HrdCloudHistoryMobileSubscribedModal />
    <HrdMobileBadgeModal />
    <HrdCloudMobileLeaderAddModal />
    <HrdCloudHistoryMobileShareModal />
  </template>
  <template v-else>
    <Add />
    <HrdCloudHistorySubscribedModal />
    <HrdBadgeModal />
    <HrdCloudLeaderAddModal />
    <HrdCloudHistoryShareModal />
  </template>
</template>

<script>
import Add from '@/components/history/hrdcloud/Add';
import {useStore} from 'vuex';
import {computed} from 'vue';
import HrdCloudHistoryMobileAdd from '@/components/history/mobile/HrdCloudHistoryMobileAdd.vue';
import HrdCloudHistorySubscribedModal from '@/components/history/HrdCloudHistorySubscribedModal.vue';
import HrdBadgeModal from '@/components/hrdcloud/HrdBadgeModal.vue';
import HrdMobileBadgeModal from '@/components/hrdcloud/mobile/HrdMobileBadgeModal.vue';
import HrdCloudLeaderAddModal from '@/components/hrdcloud/HrdCloudLeaderAddModal.vue';
import HrdCloudMobileLeaderAddModal from '@/components/hrdcloud/mobile/HrdCloudMobileLeaderAddModal.vue';
import HrdCloudHistoryMobileSubscribedModal from "@/components/history/mobile/HrdCloudHistoryMobileSubscribedModal.vue";
import HrdCloudHistoryShareModal from '@/components/history/HrdCloudHistoryShareModal.vue';
import HrdCloudHistoryMobileShareModal from '@/components/history/hrdcloud/mobile/HrdCloudHistoryMobileShareModal.vue';

export default {
  name: 'HrdCloudPopups',
  components: {
    HrdCloudHistoryMobileSubscribedModal,
    HrdCloudMobileLeaderAddModal,
    HrdCloudLeaderAddModal,
    HrdMobileBadgeModal,
    HrdBadgeModal,
    HrdCloudHistorySubscribedModal,
    HrdCloudHistoryMobileAdd,
    Add,
    HrdCloudHistoryShareModal,
    HrdCloudHistoryMobileShareModal
  },
  setup() {
    const store = useStore();
    const isMobile = computed(() => store.state.auth.isMobile);

    return {
      isMobile
    }
  }
};
</script>