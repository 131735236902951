<template>
  <div class="comment-container">
    <div class="comment-write">
      <form @submit.prevent class="kb-form-comment">
        <input type="text" v-model.trim="cmnt" class="kb-form-comment-input" placeholder="댓글 달기 .." @keyup.enter="handleNewComment" />
        <button class="kb-btn kb-btn-secondary kb-btn-comment" :disabled="isDisabled" @click.stop="handleNewComment">
          <span class="text">등록</span>
        </button>
      </form>
    </div>
    <div class="comment-wrapper">
<!--      <div :class="[ isMobile ? 'comment-list' : 'comment-list-top']">-->
<!--        <div class="top-column"></div>-->
<!--      </div>-->
      <ul v-if="comments.length > 0" class="comment-list">
        <li v-for="(comment,idx) in comments" :key="idx" class="comment-item">
          <article class="comment">
            <div class="comment-avatar">
              <div class="avatar">
                <ProfileImg :target="comment" :key="comment.cttCmntSn"/>
              </div>
            </div>
            <div class="comment-contents">
              <div class="content-metadata">
                <div class="metadata">{{ getLrner(comment) }}</div>
                <div class="metadata metadata-date">{{ timestampToDateFormat(comment.regDt, 'yyyy.MM.dd hh:mm') }}</div>
              </div>
              <div class="content-text">
                <template v-if="comment.editMode">
                  <div class="comment-form" style="margin-top:6px;">
                    <input v-model.trim="comment.cmnt" type="text" class="comment-field" style="width:100%;" @keyup.enter="handleModifyComment(idx)" />
                  </div>
                  <div class="comment-actions">
                    <button class="btn" @click.stop="changeMode(idx)"><span class="text">취소</span></button>
                    <button class="btn" @click.stop="handleModifyComment(idx)"><span class="text">수정</span></button>
                  </div>
                </template>
                <p v-else class="text">{{ comment.cmnt }}</p>
              </div>
            </div>
            <div v-if="session.lrnerId === comment.lrnerId" class="comment-actions">
              <div class="dropdown" :class="{ 'is-active' : comment.toggle }">
                <button class="dropdown-btn kb-btn-actions" @click.stop="comment.toggle=!comment.toggle"><i class="icon-menu"></i></button>
                <div class="dropdown-target">
                  <div class="dropdown-box">
                    <ul class="dropdown-option-list">
                      <li class="dropdown-option-item">
                        <a href="javascript:" class="dropdown-option-link"
                           @click.stop="changeMode(idx)"><span class="dropdown-option-text">수정</span></a></li>
                      <li class="dropdown-option-item">
                        <a href="javascript:" class="dropdown-option-link"
                           @click.stop="handleDeleteComment(idx)"><span class="dropdown-option-text">삭제</span></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </li>
      </ul>
      <div v-if="paging.hasMore" class="comment-list-bottom">
        <button class="kb-btn kb-btn-secondary" @click="showMore"><span class="text">댓글 더보기</span></button>
      </div>
    </div>
  </div>
</template>
<style scoped>
#kb[data-bg="dark"] .comment-container .comment-write .kb-form-comment-input {color:white;}
#kb[data-bg="dark"] .comment-container .comment-wrapper .comment-form .comment-field {color:white;}
</style>
<script>
import ProfileImg from '@/components/common/ProfileImg';
import {hrdCommentSetup} from '@/assets/js/modules/hrd/hrd-comment-setup';

export default {
  name: 'HrdCloudComment',
  components: {ProfileImg},
  props: {
    objSn: Number,
    effectNav: {
      required: false,
      type: Number,
      default: 0
    },
  },
  setup: hrdCommentSetup
};
</script>