<template>
  <!-- cloud-header -->
  <LxpMobileHeader title="정답설정">
    <template v-slot:left>
        <div class="util util-back">
            <a href="javascript:" class="util-actions util-actions-back" @click="closeLayer">
                <i class="icon-history-back"></i>
            </a>
        </div>
    </template>
  </LxpMobileHeader>
  <!-- //cloud-header -->
  <!-- cloud-main -->
  <main class="kb-main" id="kb-prep-quiz">
    <!-- main-view -->
    <div class="main-content">
      <div>
        <div class="view-contents">
          <!-- content-section -->
          <article class="content-section section-forms">
            <header class="section-header">
              <h3 class="header-title">정답설정</h3>
            </header>
            <div class="kb-form-check-group group-divider">
              <div class="kb-form-check" v-for="(item, idx) in evlQstnOpt" :key="idx">
                <input type="radio" class="kb-form-check-input" @click="ansCheck(idx)" name="optCheck" :value="idx+1" :checked="item.crctAnsYn === 'Y'" />
                <label class="kb-form-check-label">{{ item.optNm }}</label>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
    <div class="page-buttons">
        <button class="kb-btn kb-btn-primary" @click="goQstnAddLayer"><span class="text">설정완료</span></button>
    </div>
  </main>
  <!-- //cloud-main -->
</template>

<script>
import LxpMobileHeader from '@/views/layout/lxp/header/mobile/LxpMobileHeader.vue';

export default {
  name: "MobileOptionAnswerMng",
  components: {LxpMobileHeader},
  props: {
    isOptAnsrMngShow: Boolean,
    evlQstnOpt: Array,
    ansCheck: Function,
  },
  emits:['update:isOptAnsrMngShow'],
  setup(props, {emit}) {

    const closeLayer = () => {
      emit('update:isOptAnsrMngShow', false);
    }

    const goQstnAddLayer = () => {
      emit('update:isOptAnsrMngShow', false);
    }

    return {
      goQstnAddLayer,
      closeLayer,
    }
  }
}
</script>