import ApiService from '@/assets/js/api.service';

export const ACT_GET_TOT_LRNER_SCORE = 'getTotLrnerScore';
export const ACT_GET_TOT_LRNER_DTL_SCORE = 'getTotLrnerDtlScore';

const state = {};


const mutations = {};

const actions = {
  [ACT_GET_TOT_LRNER_SCORE](context, params) {
    return new Promise(resolve => {
      ApiService.query('/v1/app/new-onboarding/lrner-score', params).then(response => resolve(response))
    });
  },
  [ACT_GET_TOT_LRNER_DTL_SCORE](context, params) {
    return new Promise(resolve => {
      ApiService.query('/v1/app/new-onboarding/lrner-dtl-score', params).then(response => resolve(response))
    });
  },  
 
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
