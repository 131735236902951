<template>
  <!-- main-content -->
  <div class="main-content main-component">
    <div class="support-view-container">
      <div class="view-contents">
        <!-- section: 2022년 주요자격증 시험 일정 안내 - 상품판매 필수자격증 -->
        <section class="content-section">
          <div class="section-content">
            <div class="content-item bd-clear pd-top-clear">
              <div class="accordion" :class="{'is-active' : isActiveA}">
                <a href="javascript:" class="accordion-toggle-action" @click="clickToggle('partA')">
                  <div class="accordion-row">
                    <div class="accordion-column">
                      <div class="accordion-title big">2024년 상품판매 필수자격증 일정</div>
                    </div>
                    <div class="accordion-icon"><i class="icon-arrow"></i></div>
                  </div>
                </a>
                <div class="accordion-target">
                  <ul class="accordion-sub">
                    <li>
                      <div class="title-category">
                        <h3>27회차</h3>
                        <h4>펀드투자권유자문인력</h4>
                      </div>
                      <div class="context-list">
                        <article>
                          <p class="name">주관</p>
                          <p class="context">금융투자협회 자격시험 접수센터</p>
                        </article>
                        <article>
                          <p class="name">접수</p>
                          <p class="context">2024.01.29(월)~2024.02.02(금)</p>
                        </article>
                        <article>
                          <p class="name">시험일</p>
                          <p class="context">02.25(일)</p>
                        </article>
                        <article>
                          <p class="name">발표일</p>
                          <p class="context">03.07(목)</p>
                        </article>
                      </div>
                    </li>
                    <li>
                      <div class="title-category">
                        <h3>28회차</h3>
                        <h4>펀드투자권유자문인력</h4>
                      </div>
                      <div class="context-list">
                        <article>
                          <p class="name">주관</p>
                          <p class="context">금융투자협회 자격시험 접수센터</p>
                        </article>
                        <article>
                          <p class="name">접수</p>
                          <p class="context">2024.06.03(월)~2024.06.07(금)</p>
                        </article>
                        <article>
                          <p class="name">시험일</p>
                          <p class="context">06.30(일)</p>
                        </article>
                        <article>
                          <p class="name">발표일</p>
                          <p class="context">07.11(목)</p>
                        </article>
                      </div>
                    </li>
                    <li>
                      <div class="title-category">
                        <h3>29회차</h3>
                        <h4>펀드투자권유자문인력</h4>
                      </div>
                      <div class="context-list">
                        <article>
                          <p class="name">주관</p>
                          <p class="context">금융투자협회 자격시험 접수센터</p>
                        </article>
                        <article>
                          <p class="name">접수</p>
                          <p class="context">2024.11.11(월)~2024.11.15(금)</p>
                        </article>
                        <article>
                          <p class="name">시험일</p>
                          <p class="context">12.08(일)</p>
                        </article>
                        <article>
                          <p class="name">발표일</p>
                          <p class="context">12.19(목)</p>
                        </article>
                      </div>
                    </li>
                    <li>
                      <div class="title-category">
                        <h3>31회차</h3>
                        <h4>파생상품 투자권유자문인력</h4>
                      </div>
                      <div class="context-list">
                        <article>
                          <p class="name">주관</p>
                          <p class="context">금융투자협회 자격시험 접수센터</p>
                        </article>
                        <article>
                          <p class="name">접수</p>
                          <p class="context">2024.01.08(월)~2024.01.12(금)</p>
                        </article>
                        <article>
                          <p class="name">시험일</p>
                          <p class="context">02.04(일)</p>
                        </article>
                        <article>
                          <p class="name">발표일</p>
                          <p class="context">02.15(목)</p>
                        </article>
                      </div>
                    </li>
                    <li>
                      <div class="title-category">
                        <h3>32회차</h3>
                        <h4>펀드투자권유자문인력</h4>
                      </div>
                      <div class="context-list">
                        <article>
                          <p class="name">주관</p>
                          <p class="context">금융투자협회 자격시험 접수센터</p>
                        </article>
                        <article>
                          <p class="name">접수</p>
                          <p class="context">2024.04.22(월)~2024.04.26(금)</p>
                        </article>
                        <article>
                          <p class="name">시험일</p>
                          <p class="context">05.19(일)</p>
                        </article>
                        <article>
                          <p class="name">발표일</p>
                          <p class="context">05.30(목)</p>
                        </article>
                      </div>
                    </li>
                    <li>
                      <div class="title-category">
                        <h3>33회차</h3>
                        <h4>펀드투자권유자문인력</h4>
                      </div>
                      <div class="context-list">
                        <article>
                          <p class="name">주관</p>
                          <p class="context">금융투자협회 자격시험 접수센터</p>
                        </article>
                        <article>
                          <p class="name">접수</p>
                          <p class="context">2024.10.28(월)~2024.11.01(금)</p>
                        </article>
                        <article>
                          <p class="name">시험일</p>
                          <p class="context">11.24(일)</p>
                        </article>
                        <article>
                          <p class="name">발표일</p>
                          <p class="context">12.05(목)</p>
                        </article>
                      </div>
                    </li>
                    <li class="text-silver">
                      금융투자협회 자격시험접수센터 ㅣ 1600-9477
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- section: 2022년 주요자격증 시험 일정 안내 - 직무관련 핵심자격증 -->
        <section class="content-section">
          <div class="section-content">
            <div class="content-item bd-clear pd-top-clear">
              <div class="accordion" :class="{'is-active' : isActiveB}">
                <a href="javascript:" class="accordion-toggle-action" @click="clickToggle('partB')">
                  <div class="accordion-row">
                    <div class="accordion-column">
                      <div class="accordion-title big">2024년 직무관련 핵심 자격증 일정</div>
                    </div>
                    <div class="accordion-icon"><i class="icon-arrow"></i></div>
                  </div>
                </a>
                <div class="accordion-target">
                  <ul class="accordion-sub">
                    <li>
                      <div class="title-category">
                        <h3>49회차</h3>
                        <h4>외환전문역 I, II</h4>
                      </div>
                      <div class="context-list">
                        <article>
                          <p class="name">주관</p>
                          <p class="context">한국금융연수원</p>
                        </article>
                        <article>
                          <p class="name">접수</p>
                          <p class="context">2024.02.13(화)~2024.02.20(화)</p>
                        </article>
                        <article>
                          <p class="name">시험일</p>
                          <p class="context">03.23(토)</p>
                        </article>
                        <article>
                          <p class="name">발표일</p>
                          <p class="context">04.05(금)</p>
                        </article>
                      </div>
                    </li>
                    <li>
                      <div class="title-category">
                        <h3>50회차</h3>
                        <h4>외환전문역 I, II</h4>
                      </div>
                      <div class="context-list">
                        <article>
                          <p class="name">주관</p>
                          <p class="context">한국금융연수원</p>
                        </article>
                        <article>
                          <p class="name">접수</p>
                          <p class="context">2024.05.28(화)~2024.06.04(화)</p>
                        </article>
                        <article>
                          <p class="name">시험일</p>
                          <p class="context">07.06(토)</p>
                        </article>
                        <article>
                          <p class="name">발표일</p>
                          <p class="context">07.19(금)</p>
                        </article>
                      </div>
                    </li>
                    <li>
                      <div class="title-category">
                        <h3>51회차</h3>
                        <h4>외환전문역 I, II</h4>
                      </div>
                      <div class="context-list">
                        <article>
                          <p class="name">주관</p>
                          <p class="context">한국금융연수원</p>
                        </article>
                        <article>
                          <p class="name">접수</p>
                          <p class="context">2024.10.15(화)~2024.10.22(화)</p>
                        </article>
                        <article>
                          <p class="name">시험일</p>
                          <p class="context">11.23(토)</p>
                        </article>
                        <article>
                          <p class="name">발표일</p>
                          <p class="context">12.06(금)</p>
                        </article>
                      </div>
                    </li>
                    <li>
                      <div class="title-category">
                        <h3>58회차</h3>
                        <h4>신용분석사</h4>
                      </div>
                      <div class="context-list">
                        <article>
                          <p class="name">주관</p>
                          <p class="context">한국금융연수원</p>
                        </article>
                        <article>
                          <p class="name">접수</p>
                          <p class="context">2024.01.16(화)~2024.01.23(화)</p>
                        </article>
                        <article>
                          <p class="name">시험일</p>
                          <p class="context">02.24(토)</p>
                        </article>
                        <article>
                          <p class="name">발표일</p>
                          <p class="context">03.08(금)</p>
                        </article>
                      </div>
                    </li>
                    <li>
                      <div class="title-category">
                        <h3>59회차</h3>
                        <h4>신용분석사</h4>
                      </div>
                      <div class="context-list">
                        <article>
                          <p class="name">주관</p>
                          <p class="context">한국금융연수원</p>
                        </article>
                        <article>
                          <p class="name">접수</p>
                          <p class="context">2024.05.07(화)~2024.05.14(화)</p>
                        </article>
                        <article>
                          <p class="name">시험일</p>
                          <p class="context">06.10(토)</p>
                        </article>
                        <article>
                          <p class="name">발표일</p>
                          <p class="context">06.23(금)</p>
                        </article>
                      </div>
                    </li>
                    <li>
                      <div class="title-category">
                        <h3>60회차</h3>
                        <h4>신용분석사</h4>
                      </div>
                      <div class="context-list">
                        <article>
                          <p class="name">주관</p>
                          <p class="context">한국금융연수원</p>
                        </article>
                        <article>
                          <p class="name">접수</p>
                          <p class="context">2024.09.05(목)~2024.09.12(목)</p>
                        </article>
                        <article>
                          <p class="name">시험일</p>
                          <p class="context">10.19(토)</p>
                        </article>
                        <article>
                          <p class="name">발표일</p>
                          <p class="context">11.01(금)</p>
                        </article>
                      </div>
                    </li>
                    <li>
                      <div class="title-category">
                        <h3>61회차</h3>
                        <h4>자산관리사(은행FP)</h4>
                      </div>
                      <div class="context-list">
                        <article>
                          <p class="name">주관</p>
                          <p class="context">한국금융연수원</p>
                        </article>
                        <article>
                          <p class="name">접수</p>
                          <p class="context">2024.03.05(화)~2024.03.12(화)</p>
                        </article>
                        <article>
                          <p class="name">시험일</p>
                          <p class="context">04.13(토)</p>
                        </article>
                        <article>
                          <p class="name">발표일</p>
                          <p class="context">04.26(금)</p>
                        </article>
                      </div>
                    </li>
                    <li>
                      <div class="title-category">
                        <h3>62회차</h3>
                        <h4>자산관리사(은행FP)</h4>
                      </div>
                      <div class="context-list">
                        <article>
                          <p class="name">주관</p>
                          <p class="context">한국금융연수원</p>
                        </article>
                        <article>
                          <p class="name">접수</p>
                          <p class="context">2024.06.18(화)~2024.06.25(화)</p>
                        </article>
                        <article>
                          <p class="name">시험일</p>
                          <p class="context">07.27(토)</p>
                        </article>
                        <article>
                          <p class="name">발표일</p>
                          <p class="context">08.09(금)</p>
                        </article>
                      </div>
                    </li>
                    <li>
                      <div class="title-category">
                        <h3>63회차</h3>
                        <h4>자산관리사(은행FP)</h4>
                      </div>
                      <div class="context-list">
                        <article>
                          <p class="name">주관</p>
                          <p class="context">한국금융연수원</p>
                        </article>
                        <article>
                          <p class="name">접수</p>
                          <p class="context">2024.10.01(화)~2024.10.08(화)</p>
                        </article>
                        <article>
                          <p class="name">시험일</p>
                          <p class="context">11.09(토)</p>
                        </article>
                        <article>
                          <p class="name">발표일</p>
                          <p class="context">11.22(금)</p>
                        </article>
                      </div>
                    </li>
                    <li>
                      <div class="title-category">
                        <h3>31회차</h3>
                        <h4>영업점컴플라이언스오피서(은행)</h4>
                      </div>
                      <div class="context-list">
                        <article>
                          <p class="name">주관</p>
                          <p class="context">한국금융연수원</p>
                        </article>
                        <article>
                          <p class="name">접수</p>
                          <p class="context">2024.04.02(화)~2024.04.09(화)</p>
                        </article>
                        <article>
                          <p class="name">시험일</p>
                          <p class="context">05.11(토)</p>
                        </article>
                        <article>
                          <p class="name">발표일</p>
                          <p class="context">05.24(금)</p>
                        </article>
                      </div>
                    </li>
                    <li>
                      <div class="title-category">
                        <h3>32회차</h3>
                        <h4>영업점컴플라이언스오피서(은행)</h4>
                      </div>
                      <div class="context-list">
                        <article>
                          <p class="name">주관</p>
                          <p class="context">한국금융연수원</p>
                        </article>
                        <article>
                          <p class="name">접수</p>
                          <p class="context">2024.07.30(화)~2024.08.06(화)</p>
                        </article>
                        <article>
                          <p class="name">시험일</p>
                          <p class="context">09.07(토)</p>
                        </article>
                        <article>
                          <p class="name">발표일</p>
                          <p class="context">09.27(금)</p>
                        </article>
                      </div>
                    </li>
                    <li>
                      <div class="title-category">
                        <h3>87회차</h3>
                        <h4>한국재무설계사(AFPK)</h4>
                      </div>
                      <div class="context-list">
                        <article>
                          <p class="name">주관</p>
                          <p class="context">한국 FPSB</p>
                        </article>
                        <article>
                          <p class="name">접수</p>
                          <p class="context">2024.02.19(월)~2024.03.04(월)</p>
                        </article>
                        <article>
                          <p class="name">시험일</p>
                          <p class="context">03.16(토)</p>
                        </article>
                        <article>
                          <p class="name">발표일</p>
                          <p class="context">04.05(금)</p>
                        </article>
                      </div>
                    </li>
                    <li>
                      <div class="title-category">
                        <h3>88회차</h3>
                        <h4>한국재무설계사(AFPK)</h4>
                      </div>
                      <div class="context-list">
                        <article>
                          <p class="name">주관</p>
                          <p class="context">한국 FPSB</p>
                        </article>
                        <article>
                          <p class="name">접수</p>
                          <p class="context">2024.07.29(월)~2024.08.12(월)</p>
                        </article>
                        <article>
                          <p class="name">시험일</p>
                          <p class="context">07.24(토)</p>
                        </article>
                        <article>
                          <p class="name">발표일</p>
                          <p class="context">09.13(금)</p>
                        </article>
                      </div>
                    </li>
                    <li>
                      <div class="title-category">
                        <h3>89회차</h3>
                        <h4>한국재무설계사(AFPK)</h4>
                      </div>
                      <div class="context-list">
                        <article>
                          <p class="name">주관</p>
                          <p class="context">한국 FPSB</p>
                        </article>
                        <article>
                          <p class="name">접수</p>
                          <p class="context">2024.10.28(월)~2024.11.11(월)</p>
                        </article>
                        <article>
                          <p class="name">시험일</p>
                          <p class="context">11.23(토)</p>
                        </article>
                        <article>
                          <p class="name">발표일</p>
                          <p class="context">12.13(금)</p>
                        </article>
                      </div>
                    </li>
                    <li>
                      <div class="title-category">
                        <h3>45회차</h3>
                        <h4>국제공인 재무설계사(CFP)</h4>
                      </div>
                      <div class="context-list">
                        <article>
                          <p class="name">주관</p>
                          <p class="context">한국 FPSB</p>
                        </article>
                        <article>
                          <p class="name">접수</p>
                          <p class="context">2024.04.22(월)~2024.05.06(월)</p>
                        </article>
                        <article>
                          <p class="name">시험일</p>
                          <p class="context">05.18(토)~05.19(일)</p>
                        </article>
                        <article>
                          <p class="name">발표일</p>
                          <p class="context">06.07(금)</p>
                        </article>
                      </div>
                    </li>
                    <li>
                      <div class="title-category">
                        <h3>46회차</h3>
                        <h4>국제공인 재무설계사(CFP)</h4>
                      </div>
                      <div class="context-list">
                        <article>
                          <p class="name">주관</p>
                          <p class="context">한국 FPSB</p>
                        </article>
                        <article>
                          <p class="name">접수</p>
                          <p class="context">2024.09.23(월)~2024.10.07(월)</p>
                        </article>
                        <article>
                          <p class="name">시험일</p>
                          <p class="context">10.19(토)~10.20(일)</p>
                        </article>
                        <article>
                          <p class="name">발표일</p>
                          <p class="context">11.08(금)</p>
                        </article>
                      </div>
                    </li>
                    <li class="text-silver">
                      한국금융연수원 | 02-3700-1500<br>
                      한국 FPSB ㅣ 02-3276-7610
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
  <!-- //main-content -->
</template>

<script>
import {ref} from "vue";

export default {
  name: 'SupportLcnsExamMobileSchedule',
  setup() {
    const isActiveA = ref(true);
    const isActiveB = ref(true);

    const clickToggle = (part) => {
      if('partA'===part) isActiveA.value = !isActiveA.value;
      if('partB'===part) isActiveB.value = !isActiveB.value;
    }

    return {
      clickToggle,
      isActiveA, isActiveB
    }
  }
};
</script>